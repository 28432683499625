import React, { 

    useState, 
    useEffect, 
    //useRef 

} from 'react';




import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    //useHistory,   
    //useParams,



} from "react-router-dom";

//import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider';
//import useDeviceDetect from 'utils/hooks/DeviceDetect';
// import useKeyPress from 'utils/hooks/KeyPress';


// import { createPortal } from 'react-dom';
// import usePortal from 'utils/hooks/Portal';


// import Navigator from 'utils/components/Content/Navigator';
// import SideNavInfo from 'utils/components/Pages/SceneInfo/Trials/SideNav/SceneInfo';



//=====================================================


import {

    //Alfa,
    //Base,
    Area,
    Panel,
    PanelGraphic

} from '../_Styled';


import {

    ACCOUNT as ACCOUNT_GRAPHIC,
    EMPLOYEE as EMPLOYEE_GRAPHIC,
    EXPENSES as EXPENSES_GRAPHIC,
    OVERHEAD as OVERHEAD_GRAPHIC,
    OWNER as OWNER_GRAPHIC,
    SCHEDULE as SCHEDULE_GRAPHIC,

    CALCULATOR as CALCULATOR_GRAPHIC,
    DIRECT_LABOR_HOURS as HOURS_GRAPHIC,
    DIRECT_LABOR_RATES as RATES_GRAPHIC,
    HEALTHCARE as HEALTHCARE_GRAPHIC,
    IRA as IRA_GRAPHIC,
    PRICING as PRICING_GRAPHIC,
    //PRICING_DOLLAR_SIGN as PRICING_GRAPHIC,
    REPORTS as REPORTS_GRAPHIC,
    SETTINGS as SETTINGS_GRAPHIC,

    KNOWLEDGE_BASE as KNOWLEDGEBASE_GRAPHIC,


} from 'app-components/Zone/_SVGComps/ICONS';



// import './StyleSheets/loader.css';

// import { Lorem } from 'app-components/Prime/Data';

// import CreateMarkup from 'utils/components/Universal/CreateMarkup';
// import CreateLink from 'utils/components/Universal/CreateLink';



const Index = (props) => {



    // -- Window Dimensions -- //
    // const { 
    //     width,
    //     //height 
    // } = useWindowDimensions();


    // -- Device Detect -- //
    //const DeviceDetection = useDeviceDetect();
    //console.log("FFcostfield - DeviceDetection " + JSON.stringify(DeviceDetection));
    //let isMobile = DeviceDetection.isMobile;



    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();

    


    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);



    let mainpath;
    mainpath = pathname.split("/")[0];
    
    let subpath;
    subpath = pathname.split("/")[1];
    if(subpath === undefined) subpath = false;

    let sub2path;
    sub2path = pathname.split("/")[2];
    if(sub2path === undefined) sub2path = false;







    // -- redux -- //
    // const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);



    //=============================================
    // ref
    //=============================================

    //const refForm = useRef(null);

    /*
    const rowRefs = [];
    const setRowRef = (ref) => {

        if(ref && ref !== null && ref !== undefined)
        {
            //console.log("ref::::"+ref.id);
            var found;
            found = false;
            for(var i=0; i < rowRefs.length; i++)
            {
                if(rowRefs[i].id === ref.id) found = true;
            }
            if(!found) rowRefs.push(ref);
            console.log(rowRefs.length);
            console.log(rowRefs[0]);

        }

    };
    */



    //=============================================
    // state/effect - initial
    //=============================================

    const [isLoaded, setIsLoaded] = useState(false);
    const [currentData, setCurrentData] = useState({


        title:"Info. Panel",
        backgroundColor:"#141820",
        graphic:{

            backgroundImage : "/_resources/logos/lightbulb.svg",
            backgroundSize : "auto 100vh",
            backgroundPosition : "top -15px right -325px",

            width : "500px",
            height : "500px",

            transform : "rotate(0deg)"

        },

        image:{

            src : "/_resources/icons/lightbulb_lite.svg",
            w : "500",
            h : "500"
        }

    });

    useEffect(() => {

        if(!isLoaded)
        {
            setIsLoaded(true);

            // if(subpath && subpath !== undefined)
            // {
            //     alert(subpath);
            // }else{
            //     alert(mainpath);
            // }
        
        }


        if(props.panelStatus)
        {


            var isSub = false;
            var title,subtitle;
            var src = "/_resources/icons/lightbulb_lite.svg";
            var graphic = {

                call:"ACCOUNT",
                color:"lightgray",
                darklite:"dark"

            };

            var currentZone;
            //var zoneName;
            currentZone = mainpath;
            if(currentZone.match(/ /gi)) 
                currentZone = currentZone.replace(/ /gi,"");
            currentZone = currentZone.toLowerCase();

            if(subpath && subpath !== undefined)
            {
                isSub = true;
                //zoneName = subpath;
            }else{
                //zoneName = mainpath;
            }



            if(isSub)
            {
                subtitle = currentZone;
            }


            switch(currentZone)
            {
            case'calculator':

                title = "Calculator";

                graphic = {

                    call:"CALCULATOR",
                    color:"lightgray",
                    darklite:"dark"
    
                };

                if(isSub) isSub = false;

            break;
            case'pricing':

                title = "Pricing";
    
                graphic = {

                    call:"PRICING",
                    color:"lightgray",
                    darklite:"dark"
    
                };


            break; 
            case'settings':

                title = "Settings";

                graphic = {

                    call:"SETTINGS",
                    color:"lightgray",
                    darklite:"dark"
    
                };

                if(isSub)
                {
                    title = subpath;
                    subtitle = "Setting";
                }



            break;
            case'reports':

                title = "Reports";

                graphic = {

                    call:"REPORTS",
                    color:"lightgray",
                    darklite:"dark"
    
                };

                if(isSub)
                {
                    title = subpath;
                    subtitle = "Report";
                }


            break;
            case'knowledgebase':

                title = "Knowledge Base";

                graphic = {

                    call:"KNOWLEDGE_BASE",
                    color:"lightgray",
                    darklite:"dark"
    
                };

                if(isSub) isSub = false;

            break;
            default:


                title = "Info Panel";

                if(isSub) title = subpath;


            }




            setCurrentData({


                isSub:isSub,

                title:title,
                subtitle:subtitle,
 
                backgroundColor:"#141820",
                // graphic:{

                //     backgroundImage : "/_resources/icons/lightbulb.svg",
                //     backgroundSize : "auto 100vh",
                //     backgroundPosition : "top -15px right -325px",
                //     width : "500px",
                //     height : "500px",
                //     transform : "rotate(0deg)"
                // },

                graphic:graphic,

                image:{

                    src : src,
                    w : "500",
                    h : "500"
        
                }


            });

        
        }


    },
    [
        props,
        mainpath,subpath,
        zoneObj,
        isLoaded

    ])






    //const portalTarget = usePortal("scrollbox");
    //const portalTarget = usePortal("side2");


    // return createPortal(<div>
    return (<div>



        <Panel
        backgroundColor={currentData.backgroundColor}
        width={"100%"}
        height={"auto"}
        >


            <Area
            >

                {/* <div className="Bar">


                    <div className="XButton"
                    onClick={(v) => props.handlePanel({

                        call:'graphic'
        
                    })}
                    >
                        X
                
                    </div>


                    <div className="Button"
                    onClick={(v) => props.handlePanel({

                        call:'ads'
        
                    })}
                    >
                        Sponsors
                
                    </div>


                    <div className="Button"
                    onClick={(v) => props.handlePanel({

                        call:'howto'
        
                    })}
                    >
                        Info.
                
                    </div>


                </div>
                */}



                <div className="Bod">


                    <PanelGraphic
                    //backgroundColor={"purple"}                  
                    // backgroundImage={currentData.graphic.backgroundImage}
                    // backgroundPosition={currentData.graphic.backgroundPosition}
                    // backgroundSize={currentData.graphic.backgroundSize}                     
                    // width={currentData.graphic.width}
                    // height={currentData.graphic.height}
                    // //height={"auto"}
                    // transform={currentData.graphic.transform}
                    onClick={(v) => props.handlePanel({

                        call:'howto'
        
                    })}
                    >

                        <div className="PanelGraphicSection">

                            <div className={"PanelGraphicTitle"}>

                                {currentData.title}

                            </div>

                            {
                            currentData.isSub
                            ?
                                <div className={"PanelGraphicSubTitle"}>

                                    ( {currentData.subtitle} )

                                </div>

                            :null
                            }

                        </div>






                        {/* <div className="PanelGraphicSection">

                            <div className={"PanelGraphicImg"}>

                                <img
                                style={{

                                    flexShrink: 0,

                                    width:"auto",
                                    height:"auto",

                                    //minWidth:box.image.maxWidth,
                                    //minHeight: "100%",

                                    maxWidth:"250px",
                                    maxHeight:"auto",

                                    //marginTop:"0px",
                                    //marginLeft:"0px"

                                }}

                                    src={currentData.image.src} 
                                    width={currentData.image.w} 
                                    height={currentData.image.h} 
                                    alt="bookimg"
                                />

            
                            </div>

                        </div> */}



                        <div className="PanelGraphicSection">

                            <div className={"PanelGraphicImg"}>


                                {

                                currentData.graphic.call === 'CALCULATOR'
                                ?

                                    <CALCULATOR_GRAPHIC
                                    
                                        iconColor = {currentData.graphic.color}
                                        darklite = {currentData.graphic.darklite}

                                    />

                                : 
                                currentData.graphic.call === 'PRICING'
                                ?

                                    <PRICING_GRAPHIC
                                    
                                        iconColor = {currentData.graphic.color}
                                        darklite = {currentData.graphic.darklite}

                                    />

                                :  
                                currentData.graphic.call === 'KNOWLEDGE_BASE'
                                ?

                                    <KNOWLEDGEBASE_GRAPHIC
                                    
                                        iconColor = {currentData.graphic.color}
                                        darklite = {currentData.graphic.darklite}

                                    />

                                : 






                                //settings

                                currentData.graphic.call === 'SETTINGS'
                                ?

                                    <SETTINGS_GRAPHIC
                                    
                                        iconColor = {currentData.graphic.color}
                                        darklite = {currentData.graphic.darklite}

                                    />

                                : 

                                currentData.graphic.call === 'ACCOUNT'
                                ?

                                    <ACCOUNT_GRAPHIC
                                    
                                        iconColor = {currentData.graphic.color}
                                        darklite = {currentData.graphic.darklite}

                                    />

                                :  
                                currentData.graphic.call === 'SCHEDULE'
                                ?

                                    <SCHEDULE_GRAPHIC
                                    
                                        iconColor = {currentData.graphic.color}
                                        darklite = {currentData.graphic.darklite}

                                    />

                                :  
                                currentData.graphic.call === 'OVERHEAD'
                                ?

                                    <OVERHEAD_GRAPHIC
                                    
                                        iconColor = {currentData.graphic.color}
                                        darklite = {currentData.graphic.darklite}

                                    />

                                :  
                                currentData.graphic.call === 'EXPENSES'
                                ?

                                    <EXPENSES_GRAPHIC
                                    
                                        iconColor = {currentData.graphic.color}
                                        darklite = {currentData.graphic.darklite}

                                    />

                                :  
                                currentData.graphic.call === 'OWNERS'
                                ?

                                    <OWNER_GRAPHIC
                                    
                                        iconColor = {currentData.graphic.color}
                                        darklite = {currentData.graphic.darklite}

                                    />

                                :  
                                currentData.graphic.call === 'EMPLOYEES'
                                ?

                                    <EMPLOYEE_GRAPHIC
                                    
                                        iconColor = {currentData.graphic.color}
                                        darklite = {currentData.graphic.darklite}

                                    />




                                :




                                // reports

                                currentData.graphic.call === 'REPORTS'
                                ?

                                    <REPORTS_GRAPHIC
                                    
                                        iconColor = {currentData.graphic.color}
                                        darklite = {currentData.graphic.darklite}

                                    />

                                : 
                                currentData.graphic.call === 'DIRECT LABOR RATES'
                                ?

                                    <RATES_GRAPHIC
                                    
                                        iconColor = {currentData.graphic.color}
                                        darklite = {currentData.graphic.darklite}

                                    />

                                :  
                                currentData.graphic.call === 'DIRECT LABOR HOURS'
                                ?

                                    <HOURS_GRAPHIC
                                    
                                        iconColor = {currentData.graphic.color}
                                        darklite = {currentData.graphic.darklite}

                                    />

                                :  
                                currentData.graphic.call === 'HEALTHCARE'
                                ?

                                    <HEALTHCARE_GRAPHIC
                                    
                                        iconColor = {currentData.graphic.color}
                                        darklite = {currentData.graphic.darklite}


                                    />

                                :  
                                currentData.graphic.call === 'IRA'
                                ?

                                    <IRA_GRAPHIC
                                    
                                        iconColor = {currentData.graphic.color}
                                        darklite = {currentData.graphic.darklite}

                                    />

                                

                                :null
                                }


            
                            </div>

                        </div>





                        <div className={"PanelGraphicSection"}>

                            <div className={"PanelGraphicBtn"}>

                                VIEW INFO.

                            </div>

                        </div>



                    </PanelGraphic>


                </div>


            </Area>


        </Panel>



    </div>);
    // </div>, portalTarget);



}

export default Index;


