
export const VPreportList = {


    //#####################################################
    //#####################################################
    //FetchList

    GetList:{

        "Calculators":{

            getSearch:"calculator-"
        },
        "Pricing":{

            getSearch:"calculator-"
        },
        "Overhead":{

            getSearch:"overhead"
        },
        "Expenses":{

            getSearch:"expenses"
        },
        "Owners":{

            getSearch:"ownr-"
        },
        "Employees":{

            getSearch:"empl-"
        },
        "Healthcare":{

            getSearch:"healthcare"
        },
        "IRA":{

            getSearch:"ira"
        },
        "Direct Labor Rates":{

            getSearch:"Direct Labor Rates"
        },
        "Direct Labor Hours":{

            getSearch:"Direct Labor Hours"
        },





    },



    //#####################################################
    //#####################################################
    // Main

    main:[


        // {

        //     "print"		    :'standard',
        //     "name"		    :'Pricing',
        //     "value"		    :'View "Pricing" Reports',
        //     "required"	    :"",
        //     "default"	    :"",
        //     "sub"           :"Fetch",
        //     "items"         :[]

        // },


        {

            "print"		    :'standard',
            "name"		    :'Overhead',
            "value"		    :'View "Overhead Percentage" and "Expense/Sales Overview"',
            "required"	    :"",
            "default"	    :"",
            "sub"           :"Fetch",
            "items"         :[]

        },
        {

            "print"		    :'standard',
            "name"		    :'Expenses',
            "value"		    :'View Expense(s) Reports',
            "required"	    :"",
            "default"	    :"",
            "sub"           :"Fetch",
            "items"         :[]

 
        },




        //-----------------------------------------------
        {

            "print"		    :'standard',
            "name"		    :'Direct Labor Rates',
            "value"		    :'View High/Low, Owner(s) and Employee(s) Rates',
            "required"	    :"",
            "default"	    :"",
            "sub"           :"Fetch",
            "items"         :[]

        },
        {

            "print"		    :'standard',
            "name"		    :'Direct Labor Hours',
            "value"		    :'View the minimum daily billing hours required to make your financial goals',
            "required"	    :"",
            "default"	    :"",
            "sub"           :"Fetch",
            "items"         :[]

 
        },





        //--------------------------------------------------
        {

            "print"		    :'standard',
            "name"		    :'Healthcare',
            "value"		    :'View healthcare financial responsiblity for Owner(s) and Employee(s)',
            "required"	    :"",
            "default"	    :"",
            "sub"           :"Fetch",
            "items"         :[]

        },
        {

            "print"		    :'standard',
            "name"		    :'IRA',
            "value"		    :'View IRA contributions for Owner(s) and Employee(s)',
            "required"	    :"",
            "default"	    :"",
            "sub"           :"Fetch",
            "items"         :[]

 
        },




        //--------------------------------------------------
        {

            "print"		    :'standard',
            "name"		    :'Owners',
            "value"		    :'View Owner(s) Report',
            "required"	    :"",
            "default"	    :"",
            "sub"           :"Fetch",
            "items"         :[]

        },
        {

            "print"		    :'standard',
            "name"		    :'Employees',
            "value"		    :'View Employee(s) Report',
            "required"	    :"",
            "default"	    :"",
            "sub"           :"Fetch",
            "items"         :[]

 
        }





        

        //-------------------------------------------------------

        
        // {

        //     "print"		    :'standard',
        //     "name"		    :'Report Example',
        //     "value"		    :"Report_E",
        //     "required"	    :"",
        //     "default"	    :"",
        //     "sub"           :false,
        //     "items"         :[]

 
        // },





        // {

        //     "print"		    :'standard',
        //     "name"		    :'Test Reports',
        //     "value"		    :"",
        //     "required"	    :"",
        //     "default"	    :"",
        //     "sub"           :"Test_Reports",
        //     "items"         :[]

        // }



        //------------------------------------------------------------

        // {

        //     "print"		    :'standard',
        //     "name"		    :'Van Halen',
        //     "value"		    :"",
        //     "required"	    :"",
        //     "default"	    :"",
        //     "sub"           :"Van_Halen",
        //     "items"         :[]
        // },


        // {

        //     "print"		    :'standard',
        //     "name"		    :"Led Zeppelin",
        //     "value"		    :"",
        //     "required"	    :"",
        //     "default"	    :"",
        //     "sub"           :"Led_Zeppelin",
        //     "items"         :[]
        // },

        // {

        //     "print"		    :'standard',
        //     "name"		    :'Rush',
        //     "value"		    :"",
        //     "required"	    :"",
        //     "default"	    :"",
        //     "sub"           :"Rush",
        //     "items"         :[]

        // },









    ],












    //#####################################################
    //#####################################################
    // Subs


    Van_Halen:[

        {

            "print"		    :'standard',
            "name"		    :'David Lee Roth',
            "value"		    :"David_Lee_Roth",
            "required"	    :"",
            "default"	    :"",
            "sub"           :false,
            "items"         :[]
 
        },


        {

            "print"		    :'standard',
            "name"		    :'Eddie Van Halen',
            "value"		    :"Eddie_Van_Halen",
            "required"	    :"",
            "default"	    :"",
            "sub"           :false,
            "items"         :[]
 
        },

        {

            "print"		    :'standard',
            "name"		    :'Alex Van Halen',
            "value"		    :"Alex_Van_Halen",
            "required"	    :"",
            "default"	    :"",
            "sub"           :false,
            "items"         :[]
 
        },


        {

            "print"		    :'standard',
            "name"		    :'Michael Anthony',
            "value"		    :"Michael_Anthony",
            "required"	    :"",
            "default"	    :"",
            "sub"           :false,
            "items"         :[]
 
        }



    ],


    Led_Zeppelin:[

        {

            "print"		    :'standard',
            "name"		    :'Jimmy Page',
            "value"		    :"Jimmy_Page",
            "required"	    :"",
            "default"	    :"",
             "sub"           :false,
            "items"         :[]
 
        },


        {

            "print"		    :'standard',
            "name"		    :'Robert Plant',
            "value"		    :"Robert_Plant",
            "required"	    :"",
            "default"	    :"",
            "sub"           :false,
            "items"         :[]
 
        },

        {

            "print"		    :'standard',
            "name"		    :'John Bohnam',
            "value"		    :"John_Bohnam",
            "required"	    :"",
            "default"	    :"",
            "sub"           :false,
            "items"         :[]
 
        },


        {

            "print"		    :'standard',
            "name"		    :'John Paul Jones',
            "value"		    :"John_Paul_Jones",
            "required"	    :"",
            "default"	    :"",
            "sub"           :false,
            "items"         :[]
 
        }



    ],




    Rush:[

        {

            "print"		    :'standard',
            "name"		    :'Geddy Lee',
            "value"		    :"Geddy_Lee",
            "required"	    :"",
            "default"	    :"",
            "sub"           :false,
            "items"         :[]
 
        },


        {

            "print"		    :'standard',
            "name"		    :'Alex Leifson',
            "value"		    :"Alex_Leifson",
            "required"	    :"",
            "default"	    :"",
            "sub"           :false,
            "items"         :[]
 
        },

        {

            "print"		    :'standard',
            "name"		    :'Neil Peart',
            "value"		    :"Neil_Peart",
            "required"	    :"",
            "default"	    :"",
            "sub"           :false,
            "items"         :[]
 
        }



    ],




    Test_Reports:[

        {

            "print"		    :'standard',
            "name"		    :'Direct Labor Hours',
            "value"		    :"Direct_Labor_Hours",
            "required"	    :"",
            "default"	    :"",
            "sub"           :false,
            "items"         :[]
 
        },

        {

            "print"		    :'standard',
            "name"		    :'Direct Labor Rates',
            "value"		    :"Direct_Labor_Rates",
            "required"	    :"",
            "default"	    :"",
            "sub"           :false,
            "items"         :[]
 
        },

        {

            "print"		    :'standard',
            "name"		    :'Overhead',
            "value"		    :"Overhead",
            "required"	    :"",
            "default"	    :"",
            "sub"           :false,
            "items"         :[]
 
        },

        {

            "print"		    :'standard',
            "name"		    :'Healthcare',
            "value"		    :"Healthcare",
            "required"	    :"",
            "default"	    :"",
            "sub"           :false,
            "items"         :[]
 
        },

        {

            "print"		    :'standard',
            "name"		    :'IRA',
            "value"		    :"IRA",
            "required"	    :"",
            "default"	    :"",
            "sub"           :false,
            "items"         :[]
 
        },



        {

            "print"		    :'standard',
            "name"		    :'Owner',
            "value"		    :"Owner",
            "required"	    :"",
            "default"	    :"",
            "sub"           :false,
            "items"         :[]
 
        },

        {

            "print"		    :'standard',
            "name"		    :'Employee',
            "value"		    :"Employee",
            "required"	    :"",
            "default"	    :"",
            "sub"           :false,
            "items"         :[]
 
        },





    ]





};



