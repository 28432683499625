import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';


// import { 
//     useSelector, 
//     //useDispatch 
// } 
// from 'react-redux';
// //import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    //useHistory,   
    //useParams,



} from "react-router-dom";

// import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'


import { createPortal } from 'react-dom';
import usePortal from 'utils/hooks/Portal';



// import Navigator from 'utils/components/Content/Navigator';
// import SideNavInfo from 'utils/components/Pages/SceneInfo/Trials/SideNav/SceneInfo';




//=====================================================


import {

    ProcAlfa,
    ProcBase,
    ProcForm

} from '../../Styled';





const Index = (props) => {



    const portalTarget = usePortal("stage");



    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();

    
 
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);



    // -- window dims -- //
    // const { 
    //     width,
    //     height 
    // } = useWindowDimensions();



    // -- redux -- //
    // const dispatch = useDispatch();
    //const zoneObj = useSelector(state => state.zone);


    //=============================================
    // refs
    //=============================================

    const refForm= useRef(null);



 
    //=============================================
    // state/effect - display
    //=============================================

    const [isLoaded, setIsLoaded] = useState(false);
    const [isInit, setIsInit] = useState(false);
    const [favorite, setFavorite] = useState(false);
    const [fromTop, setFromTop] = useState("0px");
    const [fieldArr, setFieldArr] = useState([

        {
            clickerVal    :"5",
            innerColor    :"white",
            myText        :"5 Star"
        },

        {
            clickerVal    :"4",
            innerColor    :"white",
            myText        :"4 Star"
        },

        {
            clickerVal    :"3",
            innerColor    :"white",
            myText        :"3 Star"
        },

        {
            clickerVal    :"2",
            innerColor    :"white",
            myText        :"2 Star"
        },

        {
            clickerVal    :"1",
            innerColor    :"white",
            myText        :"1 Star"
        }



    ]);
    //const [fieldStyl, setFieldStyl] = useState({});


    const [clickerCall] = useState('rad');
    const [clickerChoice, setClickerChoice] = useState({

        clickerVal    :"5",
        innerColor    :"white",
        myText        :"5 Star"

    });



     useEffect(() => {

        if(!isLoaded)
        {
            setIsLoaded(true);

            if(props.data.favorite)
            {
                setFavorite(props.data.favorite);
                var favoriteNumber = parseInt(props.data.favorite) - 1;
                var reversedFieldArr = JSON.parse(JSON.stringify(fieldArr));
                reversedFieldArr.reverse();
                setClickerChoice(reversedFieldArr[favoriteNumber]);
            }else{
                setFavorite(5);
            }

            if(refForm.current.offsetHeight > 0)
            {
                var fromTop = 20;
                // if(props.styling)
                // {
                //     if(props.styling.top)
                //     {
                //         fromTop = props.styling.top.replace("px");
                //         fromTop = fromTop.replace("%");
                //         fromTop = fromTop.replace("em");
                //         fromTop = parseInt(fromTop) - (refForm.current.offsetHeight/3);
                //         if(fromTop < 0) fromTop = 0;
                //     }
                // }

                fromTop = (props.height - refForm.current.offsetHeight)/2.5;
                if(fromTop < 25) fromTop = 25;

                setFromTop(fromTop+"px");

            }


        }//isLoaded



        if(isLoaded && !isInit)
        {

            setIsInit(true);

            var newArr = [];
            var i;

            for(i=0; i < fieldArr.length; i++)
            {

                var object = fieldArr[i];

                switch(clickerCall)
                {
                case'chk':

                    if(clickerChoice.clickerVal === object.clickerVal)
                    {

                        var newColor = 'orangered';
                        if(object['innerColor'] === 'orangered') newColor = 'white';
                    
                        newArr.push({


                            "clickerVal"    :object['clickerVal'],
                            "innerColor"    :newColor,
                            "myText"        :object['myText']
                        });

                    }else{

                        newArr.push({

                            "clickerVal"    :object['clickerVal'],
                            "innerColor"    :object['innerColor'],
                            "myText"        :object['myText']

                        });

                    }//==



                break;
                case'rad':

                
                    if(clickerChoice.clickerVal === object.clickerVal)
                    {
                        newArr.push({


                            "clickerVal"    :object['clickerVal'],
                            "innerColor"    :'orangered',
                            "myText"        :object['myText']


                        });

                    }else{


                        newArr.push({

                            "clickerVal"    :object['clickerVal'],
                            "innerColor"    :"white",
                            "myText"        :object['myText']

                        });

                    }//==



                break;
                default:
                break;
                }//==

        
            }//==
            
            setFieldArr(newArr);
            //setFieldStyl({"bgColor":"transparent"});


        }//isInit
    


     },[
         props,
         isLoaded,isInit,
         clickerCall,
         clickerChoice,
         fieldArr,
         favorite
        
    ])








    // -- handle -- //


    function handleClick(indx) {


        /*

        console.log(JSON.stringify(props,null,2))


        {
        "indx": 8,
        "data": {

                "call": "rad",
                "required": true,
                "name": "typeofwork",
                "array": [
                    "Consulting",
                    "Full Stack",
                    "Frontend ",
                    "Backend",
                    "Not Sure"
                ],
                "choice": [
                    "Full Stack"
                ]
            }
        }

    
        alert("handleClick");

        */
   

   

        var favoriteNumber;
        var newArr = [];
        var i;


        for(i=0; i < fieldArr.length; i++)
        {

            var object = fieldArr[i];


            switch(clickerCall)
            {
            case'chk':

                if(indx === i)
                {

                    var newColor = 'orangered';
                    if(object['innerColor'] === 'orangered') newColor = 'white';
                
                    newArr.push({


                        "clickerVal"    :object['clickerVal'],
                        "innerColor"    :newColor,
                        "myText"        :object['myText']
                    });

                }else{

                    newArr.push({

                        "clickerVal"    :object['clickerVal'],
                        "innerColor"    :object['innerColor'],
                        "myText"        :object['myText']

                    });

                }//==



            break;
            case'rad':


                if(indx === i)
                {

                    favoriteNumber = parseInt(object.clickerVal);

                    setClickerChoice({

                        "clickerVal"    :object['clickerVal'],
                        "innerColor"    :'orangered',
                        "myText"        :object['myText']

                    });
                
                    newArr.push({


                        "clickerVal"    :object['clickerVal'],
                        "innerColor"    :'orangered',
                        "myText"        :object['myText']


                    });

                }else{

                    newArr.push({

                        "clickerVal"    :object['clickerVal'],
                        "innerColor"    :"white",
                        "myText"        :object['myText']

                    });

                }//==



            break;
            default:
            break;
            }//==

    
        }//==
        
        setFieldArr(newArr);
        setFavorite(favoriteNumber);


    }






    return createPortal(<div>


        <ProcAlfa zIndex={"23"}></ProcAlfa>
        <ProcBase zIndex={"24"}>


            <ProcBase zIndex={"2"}
            onClick={(v) => props.handle({

                call:'cancel'

            })}
            > 
            </ProcBase>

            <ProcForm 
                ref={refForm}
                top={fromTop}
                zIndex={"3"}
            >


                <div className="Message">

                    Favorite Rating

                </div>

                <div className="Frm">

                    <div className="container">

                    <input
                        type="hidden"
                        id={props.data.id+"_favorite"} 
                        name={props.data.id+"_favorite"}        
                        value={favorite}
                    />

                    {
                    fieldArr.map((clkData, j) => (


                        <div 
                        key={j}
                        className="clicker"
                        id={props.data.id+"_grid_clicker"+j}
                        >

                            <div 
                            className={clickerCall}
                            onClick={() => handleClick(j)}
                            >

                                <div 
                                className={clickerCall+"-inner"}
                                id={props.data.id+"_"+j}
                                style={{

                                    backgroundColor : clkData.innerColor 

                                }}
                                >
                                </div>

                            </div>



                            <input
                                type="hidden" 
                                id={props.data.id+"_"+j+"_color"} 
                                name={props.data.id+"_"+j+"_color"}        
                                value={clkData.innerColor}
                                //onChange={e => handleChange(idx, e)}
                            />


                            <div 
                            className="click-txt"
                            onClick={() => handleClick(j)}
                            >
                                {clkData.myText}

                            </div>


                            <input
                                type="hidden"
                                id={props.data.id+"_"+j+"_txt"} 
                                name={props.data.id+"_"+j+"_txt"}        
                                value={clkData.myText}
                            />


                        </div>


                    ))



                    }
                    </div>



                </div>


                <div 
                className="SaveButton"
                onClick={(v) => props.handle({

                    call:'favorite',
                    action:'process'
                    
                })}
                >
                    Save
                </div>

                <div 
                className="CancelButton"
                onClick={(v) => props.handle({

                    call:'cancel'

                })}
                >
                    Cancel

                </div>


            </ProcForm>


        </ProcBase>


    </div>, portalTarget);





}

export default Index;


