// import { 
//     useSelector, 
//     //useDispatch 
// } 
// from 'react-redux';
// //import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';



import {
    
    Calc

} from 'app-components/Zone/Calculator/Calculate';



const Index = (props) => {



    // -- redux -- //
    //const dispatch = useDispatch();
    //const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));


    const zoneObj = props.zoneObj;
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));
    //console.log("props: "+JSON.stringify(props, null, 2));

    var keyname;
    var calc = false;

    var cost_of_goods = [

        {
            "call": "good-1",
            "other": {
            "data": {
                "name": "",
                "brand": ""
            },
            "label": "--"
            },
            "title": "Vinyl",
            "rate": 0,
            "qtyhrs": 0
        }

    ];

    var calcby = {
        "call": "percent",
        "title": "Net Profit",
        "value": "25.0"
    };

    var overhead =  {
        "title": "Overhead",
        "value": "49.1",
        "id": "overhead"
    };

    var debits =  [
        {
            "title": "Commission",
            "value": 0,
            "id": "commission"
        },
        {
            "title": "Fees",
            "value": 0,
            "id": "fees"
        }
    ];

    var quantity = {
        "title": "Quantity",
        "value": "1",
        "id": "quantity"
    };





    if(zoneObj.calcs && zoneObj.calcs !== undefined)
    {


        var calcs = JSON.parse(JSON.stringify(zoneObj.calcs));
        for(keyname in calcs)
        {
            calc = calcs[keyname];
            if(calc.data.name === props.categoryName)
            {
                //alert(calc.data.name);

                if(calc.data.value.cost_of_goods)   cost_of_goods   = calc.data.value.cost_of_goods;
                if(calc.data.value.calcby)          calcby          = calc.data.value.calcby;    
                if(calc.data.value.overhead)        overhead        = calc.data.value.overhead;
                if(calc.data.value.debits)          debits          = calc.data.value.debits;
                if(calc.data.value.quantity)        quantity        = calc.data.value.quantity;
            

                break;

            }

        }

    }
    console.log("calc:::: "+JSON.stringify(calc, null, 2));
    // alert('calc');




    //----------------------------
    //Calculation
    //----------------------------

    let calculation;
    if(calc)
    {

        var input;
        input = {
    
            cost_of_goods   :cost_of_goods,
            overhead        :overhead,
            debits          :debits,
            calcby          :calcby,
            quantity        :quantity
    
        }
        calculation = Calc({

            input   : input

        });
        //console.log("calculation: "+JSON.stringify(calculation, null, 2));





    //============================================================

        //---------------------------
        //Price Percent
        //---------------------------

        var goods_total_amount = "0.00";
        var goods_total_percent = "0.0";

        var overhead_amount = "0.00";
        var overhead_percent = "0.0";

        var commission_amount = "0.00";
        var commission_percent = "0.0";

        var fees_amount = "0.00";
        var fees_percent = "0.0";

        var profit = "0.00";
        var profit_percent = "0.0";


        //----------------------------
        // Selling Price
        //----------------------------

        var grand_total = "0.00";
        var unit_qty = "0";
        var price_each = "0.00";



        //----------------------------
        //Breakeven
        //----------------------------

        var breakeven = "0.00"

        var be_goods_total_amount = "0.00"
        var be_zerobased_overhead_amount = "0.00"
        var be_commission_amount = "0.00"
        var be_fees_amount = "0.00"
        var be_profit = "0.00";




    //===============================================================

        //---------------------------
        //Price Percent
        //---------------------------

        goods_total_amount = calculation.output.goods_total_amount;
        goods_total_percent = calculation.output.goods_total_percent;

        overhead_amount = calculation.output.overhead_amount;
        overhead_percent = calculation.output.overhead_percent;

        commission_amount = calculation.output.commission_amount;
        commission_percent = calculation.output.commission_percent;

        fees_amount = calculation.output.fees_amount;
        fees_percent = calculation.output.fees_percent;

        profit = calculation.output.profit;
        profit_percent = calculation.output.profit_percent;


        //----------------------------
        // Selling Price
        //----------------------------

        grand_total = calculation.output.grand_total;
        unit_qty = calculation.output.unit_qty;
        price_each = calculation.output.price_each;


        //----------------------------
        //Breakeven
        //----------------------------

        breakeven = calculation.output.breakeven;

        be_goods_total_amount = parseFloat(calculation.output.goods_total_amount).toFixed(2);
        be_zerobased_overhead_amount = calculation.output.zerobased_overhead_amount;
        be_commission_amount = calculation.output.commission_amount;
        be_fees_amount = calculation.output.fees_amount;
        be_profit = "0.00";






    //=============================================================

        //---------------------------
        //Price Percent
        //---------------------------

        goods_total_amount = "$" + parseFloat(goods_total_amount).toFixed(2);
        goods_total_percent = parseFloat(goods_total_percent).toFixed(1)

        overhead_amount = "$" + parseFloat(overhead_amount).toFixed(2);
        overhead_percent = parseFloat(overhead_percent).toFixed(1)

        commission_amount = "$" + parseFloat(commission_amount).toFixed(2);
        commission_percent = parseFloat(commission_percent).toFixed(1);

        fees_amount = "$" + parseFloat(fees_amount).toFixed(2);
        fees_percent = parseFloat(fees_percent).toFixed(1)

        profit = "$" + parseFloat(profit).toFixed(2);
        profit_percent = parseFloat(profit_percent).toFixed(1)


        //----------------------------
        // Selling Price
        //----------------------------

        grand_total = "$" + parseFloat(grand_total).toFixed(2);
        unit_qty = unit_qty.toString();
        price_each = "$" + parseFloat(price_each).toFixed(2);



        //----------------------------
        //Breakeven
        //----------------------------

        breakeven = "$" + parseFloat(breakeven).toFixed(2);

        be_goods_total_amount = "$" + parseFloat(be_goods_total_amount).toFixed(2);
        be_zerobased_overhead_amount = "$" + parseFloat(be_zerobased_overhead_amount).toFixed(2);
        be_commission_amount = "$" + parseFloat(be_commission_amount).toFixed(2);
        be_fees_amount = "$" + parseFloat(be_fees_amount).toFixed(2);
        be_profit = "$" + parseFloat(be_profit).toFixed(2);


    }//==





    
    return {


        //---------------------------
        //Price Percent
        //---------------------------

        price_percent : {

            goods_total_amount : goods_total_amount,
            goods_total_percent : goods_total_percent,
    
            overhead_amount : overhead_amount,
            overhead_percent : overhead_percent,
    
            commission_amount : commission_amount,
            commission_percent : commission_percent,
    
            fees_amount : fees_amount,
            fees_percent : fees_percent,
    
            profit : profit,
            profit_percent : profit_percent,
        },


        //----------------------------
        // Selling Price
        //----------------------------

        selling_price : {

            total : grand_total,
            qty : unit_qty,
            price_each : price_each
        },


        //----------------------------
        //Breakeven
        //----------------------------

        breakeven : {

            total : breakeven,
            details : {

                goods_total_amount : be_goods_total_amount,
                zerobased_overhead_amount : be_zerobased_overhead_amount,
                commission_amount : be_commission_amount,
                fees_amount : be_fees_amount,
                profit : be_profit

            }
        }
        

    };

}



export default Index;
