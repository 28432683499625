import { LibNumber } from "library";


export const BuildOwnr = (props) => {



    // "ownr-1": {
    //     "name": "Owner A",
    //     "value": {
    //       "ownr_hours_weekly": "40.00",
    //       "ownr_sti_yearly_direct": "43457.14",
    //       "ownr_name": "Owner A",
    //       "ownr_sti_monthly_indirect": "2414.29",
    //       "ownr_labor_indirect": "Outside Sales,Manager",
    //       "ownr_hours_indirect_percent_whole": "40.00",
    //       "ownr_labor_percent_direct": "60.00",
    //       "ownr_taxes_monthly_direct": "1071.43",
    //       "ownr_ira_monthly_indirect": "33.33",
    //       "ownr_labor_percent": "60.00",
    //       "ownr_ira_monthly": "83.33",
    //       "ownr_vacation_weeks": "2.00",
    //       "ownr_ira_percent": "2.00",
    //       "ownr_sti_monthly_direct": "3621.43",
    //       "ownr_hours_indirect_percent": "0.40",
    //       "ownr_taxes_yearly": "21428.57",
    //       "ownr_sti_weekly_direct": "869.14",
    //       "ownr_salary_pretax": "50000.00",
    //       "ownr_flag": "<br/>The owner spends the majority of their time performing direct labor.<br/>40% of the owners salary will be considered an Overhead expense..<br/>Graphic Design, Production and Installation rates will use the owners Direct Labor rate.",
    //       "ownr_taxrate_percent": "30.00",
    //       "ownr_health_insurance_yearly": "6000.00",
    //       "ownr_indirect_hours_yearly": "880.00",
    //       "ownr_taxrate_reciprocal": "0.70",
    //       "ownr_salary_pretax_monthly": "4166.67",
    //       "ownr_working_weeks": "50.00",
    //       "ownr_hours_direct_percent": "0.60",
    //       "ownr_taxrate_decimal": "0.30",
    //       "ownr_hours_indirect": "16.00",
    //       "ownr_sti": "72428.57",
    //       "ownr_taxes_monthly_indirect": "714.29",
    //       "ownr_ira_yearly": "1000.00",
    //       "ownr_ira_monthly_direct": "50.00",
    //       "ownr_labor_direct": "Design,Production and Service",
    //       "ownr_taxes_monthly": "1785.71",
    //       "ownr_labor_percent_indirect": "40.00",
    //       "ownr_hours_direct_percent_whole": "60.00",
    //       "ownr_sti_weekly_indirect": "579.43",
    //       "ownr_billable_hours_weekly": "24.00",
    //       "ownr_ira_decimal": "0.02",
    //       "ownr_billable_hours_daily": "4.80",
    //       "ownr_st": "71428.57",
    //       "ownr_health_insurance_monthly": "500.00",
    //       "ownr_sti_weekly": "1448.57",
    //       "ownr_hours_direct": "24.00",
    //       "ownr_sti_hourly_direct_flag": "Okay",
    //       "ownr_flag_case": "greater",
    //       "ownr_sti_hourly_direct": "36.21",
    //       "ownr_salary_overhead": "1666.67",
    //       "ownr_sti_yearly_indirect": "28971.43"
    //     }
    // },




    // var ownr_hours_weekly = "";
    var ownr_sti_yearly_direct = 0;
    // var ownr_name = "";
    // var ownr_sti_monthly_indirect = "0.00";
    var ownr_stih_monthly_indirect = "0.00";
    var ownr_labor_indirect = "N/A";
    // var ownr_hours_indirect_percent_whole = "0.00";
    var ownr_labor_percent_direct = "0.00";
    // var ownr_taxes_monthly_direct = "0.00";
    // var ownr_ira_monthly_indirect = "0.00";
    // var ownr_labor_percent = "0.00";
    var ownr_ira_monthly = "0.00";
    var ownr_vacation_weeks = "0";
    // var ownr_ira_percent = "0.00";
    // var ownr_sti_monthly_direct = "0.00";
    // var ownr_hours_indirect_percent = "0.00";
    var ownr_taxes_yearly = "0.00";
    // var ownr_sti_weekly_direct = "0.00";
    var ownr_salary_pretax = "0.00";
    var ownr_flag = "";
    // var ownr_taxrate_percent = "0.00";
    var ownr_health_insurance_yearly = "0.00";
    // var ownr_indirect_hours_yearly = "0.00";
    // var ownr_taxrate_reciprocal = "0.00";
    // var ownr_salary_pretax_monthly = "0.00";
    // var ownr_working_weeks = "0.00";
    // var ownr_hours_direct_percent = "0.00";
    // var ownr_taxrate_decimal = "0.30";
    var ownr_hours_indirect = "0.0";
    // var ownr_sti = "0.00";
    // var ownr_taxes_monthly_indirect = "0.00";
    var ownr_ira_yearly = "0.00";
    // var ownr_ira_monthly_direct = "0.00";
    var ownr_labor_direct = "N/A";
    // var ownr_taxes_monthly = "0.00";
    var ownr_labor_percent_indirect = "0.00";
    // var ownr_hours_direct_percent_whole = "0.00";
    // var ownr_sti_weekly_indirect = "0.00";
    // var ownr_billable_hours_weekly = "0.00";
    // var ownr_ira_decimal = "0.00";
    // var ownr_billable_hours_daily = "0.00";
    // var ownr_st = "0.00";
    var ownr_health_insurance_monthly = "0.00";
    // var ownr_sti_weekly = "0.00";
    var ownr_hours_direct = "0.0";
    // var ownr_sti_hourly_direct_flag = "Okay";
    // var ownr_flag_case = "greater";
    var ownr_sti_hourly_direct = 0;
    // var ownr_salary_overhead = "0.00";
    var ownr_sti_yearly_indirect = 0;
    

    var ownr_direct_hours_weekly = "0.00";

    var ownr_total_hours_weekly = "0.0";

    var ownr_yearly_total = 0;







    if(props.data && props.data !== undefined)
    { 
        console.log("PROPS: "+JSON.stringify(props,null,2));


        ownr_salary_pretax                  = props.data.value.ownr_salary_pretax;
        ownr_sti_hourly_direct              = props.data.value.ownr_sti_hourly_direct;
        ownr_direct_hours_weekly            = props.data.value.ownr_billable_hours_weekly;

        ownr_labor_percent_direct           = props.data.value.ownr_labor_percent_direct;
        ownr_labor_percent_indirect         = props.data.value.ownr_labor_percent_indirect;

        ownr_labor_direct                   = props.data.value.ownr_labor_direct;
        ownr_labor_indirect                 = props.data.value.ownr_labor_indirect;


        ownr_hours_direct                   = props.data.value.ownr_hours_direct;
        ownr_hours_indirect                 = props.data.value.ownr_hours_indirect;


        ownr_stih_monthly_indirect          = props.data.value.ownr_stih_monthly_indirect;


        ownr_flag                           = props.data.value.ownr_flag;
    
        ownr_health_insurance_monthly       = props.data.value.ownr_health_insurance_monthly;

        ownr_ira_monthly                    = props.data.value.ownr_ira_monthly;

        ownr_vacation_weeks                 = props.data.value.ownr_vacation_weeks;
      


        ownr_health_insurance_yearly        = props.data.value.ownr_health_insurance_yearly;
        ownr_ira_yearly                     = props.data.value.ownr_ira_yearly;
        ownr_taxes_yearly                   = props.data.value.ownr_taxes_yearly;

        ownr_sti_yearly_direct              = props.data.value.ownr_sti_yearly_direct;
        ownr_sti_yearly_indirect            = props.data.value.ownr_sti_yearly_indirect;



    }//==




    ownr_salary_pretax = parseFloat(ownr_salary_pretax).toFixed(2);
    ownr_salary_pretax = "$"+LibNumber.PrintMoney(ownr_salary_pretax);

    ownr_sti_hourly_direct = parseFloat(ownr_sti_hourly_direct).toFixed(2);
    ownr_sti_hourly_direct = "$"+LibNumber.PrintMoney(ownr_sti_hourly_direct);

    ownr_direct_hours_weekly = parseFloat(ownr_direct_hours_weekly).toFixed(1);


    ownr_labor_percent_direct = parseFloat(ownr_labor_percent_direct).toFixed(1);
    ownr_labor_percent_indirect = parseFloat(ownr_labor_percent_indirect).toFixed(1);



    if(ownr_labor_direct === '0.00') ownr_labor_direct = "N/A";
    if(ownr_labor_indirect === '0.00') ownr_labor_indirect = "N/A";

    ownr_labor_direct = ownr_labor_direct.replace(/,/gi,", ");
    ownr_labor_indirect = ownr_labor_indirect.replace(/,/gi,", ");


    ownr_stih_monthly_indirect = parseFloat(ownr_stih_monthly_indirect).toFixed(2);
    ownr_stih_monthly_indirect = "$"+LibNumber.PrintMoney(ownr_stih_monthly_indirect);



    var regxFlagRepl = new RegExp("<br/>","gi");
    ownr_flag = ownr_flag.replace(regxFlagRepl,"");

    ownr_hours_direct = parseFloat(ownr_hours_direct).toFixed(1);
    ownr_hours_indirect = parseFloat(ownr_hours_indirect).toFixed(1);

    ownr_total_hours_weekly = parseFloat(ownr_hours_direct) + parseFloat(ownr_hours_indirect);
    ownr_total_hours_weekly = parseFloat(ownr_total_hours_weekly).toFixed(1);



    ownr_health_insurance_monthly = parseFloat(ownr_health_insurance_monthly).toFixed(2);
    ownr_health_insurance_monthly = "$"+LibNumber.PrintMoney(ownr_health_insurance_monthly);


    ownr_ira_monthly = parseFloat(ownr_ira_monthly).toFixed(2);
    ownr_ira_monthly = "$"+LibNumber.PrintMoney(ownr_ira_monthly);


    ownr_vacation_weeks = parseFloat(ownr_vacation_weeks).toFixed(0);
  




    ownr_health_insurance_yearly = parseFloat(ownr_health_insurance_yearly).toFixed(2);
    ownr_ira_yearly = parseFloat(ownr_ira_yearly).toFixed(2);
    ownr_taxes_yearly = parseFloat(ownr_taxes_yearly).toFixed(2);

    ownr_sti_yearly_direct = parseFloat(ownr_sti_yearly_direct).toFixed(2);
    ownr_sti_yearly_indirect = parseFloat(ownr_sti_yearly_indirect).toFixed(2);
    ownr_yearly_total = parseFloat(ownr_sti_yearly_direct) + parseFloat(ownr_sti_yearly_indirect) + parseFloat(ownr_health_insurance_yearly)
    ownr_yearly_total = parseFloat(ownr_yearly_total).toFixed(2);

    ownr_health_insurance_yearly = "$"+LibNumber.PrintMoney(ownr_health_insurance_yearly);
    ownr_ira_yearly = "$"+LibNumber.PrintMoney(ownr_ira_yearly);
    ownr_taxes_yearly = "$"+LibNumber.PrintMoney(ownr_taxes_yearly);

    ownr_sti_yearly_direct = "$"+LibNumber.PrintMoney(ownr_sti_yearly_direct);
    ownr_sti_yearly_indirect = "$"+LibNumber.PrintMoney(ownr_sti_yearly_indirect);
    ownr_yearly_total = "$"+LibNumber.PrintMoney(ownr_yearly_total);






    //--------------------------

    var ownr_call = "even";
    var ownr_graphic_call = 'ownr_indirect';

    if(ownr_labor_percent_direct === ownr_labor_percent_indirect)
    {

        //Even
        ownr_call = "even";     
        ownr_graphic_call = 'ownr_even';

    }

    else
    if(ownr_labor_percent_direct < 1)
    {

        //Full Indirect
        ownr_call = "full_indirect";  
        ownr_graphic_call = 'ownr_indirect';
 
    }

    else
    if(ownr_labor_percent_indirect < 1)
    {

        //Full Direct
        ownr_call = "full_direct";  
        ownr_graphic_call = 'ownr_direct';
      
    
    }

    else
    if(ownr_labor_percent_direct > ownr_labor_percent_indirect)
    {
        //Mostly Direct
        ownr_call = "mostly_direct";  
        ownr_graphic_call = 'ownr_direct_indirect';

    }

    else
    if(ownr_labor_percent_direct < ownr_labor_percent_indirect)
    {

        //Mostly Indirect
        ownr_call = "mostly_indirect";  
        ownr_graphic_call = 'ownr_indirect_direct';
     
    }




    var summary_ledger_value;
    switch(ownr_call)
    {
    case'full_indirect':

        summary_ledger_value = [

            {
                name:"Pretax Salary Yearly",
                value:ownr_salary_pretax
            },
            {
                name:"Indirect Labor Burden Monthly",
                value:ownr_stih_monthly_indirect

            }

        ];




    break;
    default:


        summary_ledger_value = [

            {
                name:"Pretax Salary Yearly",
                value:ownr_salary_pretax
            },

            {
                //name:"Direct Hourly Rate (STI Hourly Direct)",
                name:"Direct Hourly Rate",
                value:ownr_sti_hourly_direct
            },
            {
                //name:"Direct Hours per Week (Hours Direct)",
                name:"Direct Hours Weekly",
                value:ownr_direct_hours_weekly

            },
            {
                name:"Indirect Labor Burden Monthly",
                value:ownr_stih_monthly_indirect

            }

        ];





    }








    var reportItems = [



        //-----------------------------------
        // Report Box - Graphic and Ledger
        //-----------------------------------
    
        {
    
            "print"		    :'bar',
            "name"		    :'Summary',
            "value"		    :"",
            "required"	    :"",
            "default"	    :""
    
    
        },
        {
    
            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{
    
                backgroundColor:"transparent",
                height:"10px"
            },
            "required"	    :"",
            "default"	    :""
    
    
        },
        {
    
            "print"		    :'report_box',
            "name"		    :'Report Box',
            "value"		    :[
    
                {
    
                    boxCall:'graphic',
                    boxWidth:"35%",
                    boxHeight:200,
    
                    svgCall:ownr_graphic_call,
                    svgWidth:200,
                    svgHeight:200,
    
                },
    
                {
                    boxCall:'ledger',
                    boxWidth:"65%",
                    boxHeight:210,
                    value:summary_ledger_value
    
                }
    
    
    
            ],
            "required"	    :"",
            "default"	    :""
    
    
        },
        {
    
            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{
    
                backgroundColor:"transparent",
                height:"0px"
            },
            "required"	    :"",
            "default"	    :""
    
    
        },






        //-----------------------------------
        // Txt
        //-----------------------------------

        // {

        //     "print"		    :'bar',
        //     "name"		    :'Labor Hours',
        //     "value"		    :"",
        //     "required"	    :"",
        //     "default"	    :""


        // },
        {

            "print"		    :'txt',
            "name"		    :'Txt',
            "value"		    :[

                {

                    // boxCall:'txt',
                    // boxWidth:"100%",
                    // boxHeight:230,

                    className:"Note",
                    txt:'***NOTE: '+ownr_flag,
                    style:{

                        //fontSize:"24px",
                        textAlign:"center"

                    }

                }

            ],
            "required"	    :"",
            "default"	    :""


        },
        {

            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{

                backgroundColor:"transparent",
                height:"40px"
            },
            "required"	    :"",
            "default"	    :""


        },













        //-----------------------------------
        // BarChart - Direct / Indirect
        //-----------------------------------
    
        //bar
        {
    
            "print"		    :'bar',
            "name"		    :'Direct / Indirect',
            "value"		    :"",
            "required"	    :"",
            "default"	    :"",
    
        },
    
    
    
        //spacer
        {
    
            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{
    
                backgroundColor:"transparent",
                height:"30px"
            },
            "required"	    :"",
            "default"	    :""
    
        },
    
    
    
        //chart
        {
    
    
            "print"		    :'singleBarChart',
            "name"		    :'The Chart',
            "value"		    :[
    
                {
                    chartType:"horizontal",
                    chartLines:true,
                    data:[
    
                        {
                            name:'Direct Labor %',
                            value:"2500.00",
                            percent:ownr_labor_percent_direct,
                        },
    
                        {
                            name:'Indirect Labor %',
                            value:"2500.00",
                            percent:ownr_labor_percent_indirect,
                        }
    
                    ]
    
                }
            ],
            "required"	    :"",
            "default"	    :"",
    
    
    
        },
    
    
        //spacer
        {
    
            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{
    
                backgroundColor:"transparent",
                height:"20px"
            },
            "required"	    :"",
            "default"	    :""
    
        },
    
    
    



    
        //-----------------------------------
        // Ledger
        //-----------------------------------
    
        // {
    
        //     "print"		    :'bar',
        //     "name"		    :'Job Descriptions',
        //     "value"		    :"",
        //     "required"	    :"",
        //     "default"	    :""
    
    
        // },
        {
    
            "print"		    :'ledger',
            "name"		    :"Ledger",
            "value"		    :[
    
    
    
                {
                    name:"Direct",
                    value:ownr_labor_direct
    
                },
                {
                    name:"Indirect",
                    value:ownr_labor_indirect
    
                }
    
            ],
            "required"	    :"",
            "default"	    :""
    
    
        },
    
        {
    
            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{
    
                backgroundColor:"transparent",
                height:"50px"
            },
            "required"	    :"",
            "default"	    :""
    
    
        },
    














    
    
        //-----------------------------------
        // Ledger
        //-----------------------------------
    
        {
    
            "print"		    :'bar',
            "name"		    :'Labor Hours Weekly',
            "value"		    :"",
            "required"	    :"",
            "default"	    :""
    
    
        },
        {
    
            "print"		    :'ledger',
            "name"		    :"Ledger",
            "value"		    :[
    

                {
                    name:"Direct Labor Hours",
                    value:ownr_hours_direct + " hrs"
                },
    
                {
                    name:"Indirect Labor Hours",
                    value:ownr_hours_indirect + " hrs"
                },
                {
                    name:"Total Hours",
                    value:ownr_total_hours_weekly +" hrs"
                }
    
    
            ],
            "required"	    :"",
            "default"	    :""
    
    
        },
    
        {
    
            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{
    
                backgroundColor:"transparent",
                height:"50px"
            },
            "required"	    :"",
            "default"	    :""
    
    
        },
    
    
    
    
    
    
    
    
        //-----------------------------------
        // Ledger
        //-----------------------------------
    
        {
    
            "print"		    :'bar',
            "name"		    :'Healthcare',
            "value"		    :"",
            "required"	    :"",
            "default"	    :""
    
    
        },
        {
    
            "print"		    :'ledger',
            "name"		    :"Ledger",
            "value"		    :[
    
    
                {
                    name:"Health Insurance Monthly",
                    value:ownr_health_insurance_monthly
                },
    
            ],
            "required"	    :"",
            "default"	    :""
    
    
        },
    
        {
    
            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{
    
                backgroundColor:"transparent",
                height:"50px"
            },
            "required"	    :"",
            "default"	    :""
    
    
        },
    
    
    
    
        //-----------------------------------
        // Ledger
        //-----------------------------------
    
        {
    
            "print"		    :'bar',
            "name"		    :'IRA',
            "value"		    :"",
            "required"	    :"",
            "default"	    :""
    
    
        },
        {
    
            "print"		    :'ledger',
            "name"		    :"Ledger",
            "value"		    :[
    
    
                {
                    name:"Contribution Monthly",
                    value:ownr_ira_monthly
                },
    
            ],
            "required"	    :"",
            "default"	    :""
    
    
        },
    
        {
    
            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{
    
                backgroundColor:"transparent",
                height:"50px"
            },
            "required"	    :"",
            "default"	    :""
    
    
        },
    
    
    
    
    
    
        //-----------------------------------
        // Ledger
        //-----------------------------------
    
        {
    
            "print"		    :'bar',
            "name"		    :'Vacation / Personal Time',
            "value"		    :"",
            "required"	    :"",
            "default"	    :""
    
    
        },
        {
    
            "print"		    :'ledger',
            "name"		    :"Ledger",
            "value"		    :[
    
    
                {
                    name:"Weeks",
                    value:ownr_vacation_weeks
                }
    

            ],
            "required"	    :"",
            "default"	    :""
    
    
        },
    
        {
    
            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{
    
                backgroundColor:"transparent",
                height:"50px"
            },
            "required"	    :"",
            "default"	    :""
    
    
        },
    
    
    
    
    
    
        //-----------------------------------
        // Ledger
        //-----------------------------------
    
        {
    
            "print"		    :'bar',
            "name"		    :'Yearly Summary',
            "value"		    :"",
            "required"	    :"",
            "default"	    :""
    
    
        },
        {
    
            "print"		    :'ledger',
            "name"		    :"Ledger",
            "value"		    :[
    
    
                {
                    name:"Salary",
                    value:ownr_salary_pretax
                },
                {
                    name:"Health Insurance",
                    value:ownr_health_insurance_yearly
                },
                {
                    name:"IRA",
                    value:ownr_ira_yearly
                },
                {
                    name:"Taxes",
                    value:ownr_taxes_yearly
                },
                {
                    name:"Yearly Total",
                    value:ownr_yearly_total

                },

    

    
    
            ],
            "required"	    :"",
            "default"	    :""
    
    
        },
    
        {
    
            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{
    
                backgroundColor:"transparent",
                height:"50px"
            },
            "required"	    :"",
            "default"	    :""
    
    
        },
    
    
    
    

    
    ];


    return reportItems;
}



