import React, { 

    useState, 
    useEffect, 
    //useRef 

} from 'react';

import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,   
    //useParams


} from "react-router-dom";


import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider';


import BandImgs from './BandImgs';


const Index = (props) => {


    const { width } = useWindowDimensions()


    // -- router -- //
    const location = useLocation(); 
    //let match = useRouteMatch();
    //let params = useParams();


    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);






    const images = props.items;

 
    // -- state -- //
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [hashData, setHashData] = useState(false);


    useEffect(() => {




        setHashData(false);
        
        //console.log(JSON.stringify(images,null,2));
        //alert("Flex Height");
    
    
        /*
        alert(""
            +"\n"
            +`PUBLIC: ${process.env.PUBLIC_URL}`
            +"\n"
            +"WINDOW LOCATION ORIGIN: "+window.location.origin
            +"\n"
            +"WINDOW LOCATION HREF: "+window.location.href 
        );
        */
        
        
        const posArr = [];
        const tempArr = [];

        const Complete = (array) => {

            //alert('images finished loading');
            console.log(":::"+location.pathname+":::");
            console.log("Images Finished Loading (Fluid)")

            if(array.length > 0)
            {
                setIsLoaded(true);
                setItems(array);

            }else{
                setIsLoaded(true);
                setError({message:"Process Image (FAILED)"});
            }
        
        }




        if(items.length === 0)
        {

            images.map(box => (

                updateData(box)

            ))

            function updateData(box) {


                //console.log("ENV PUBLIC URL: "+process.env.PUBLIC_URL);
            
                var sourceurl = `${process.env.PUBLIC_URL}` + box.image.src;
                //var sourceurl = `${process.env.PUBLIC_URL}` + box.image.src;
                //console.log(sourceurl);
            
                var img = new Image();
                img.error = function(){
                    console.log("IMAGE ERROR");
                };
                img.onload = function(){
            
                    //console.log("IMAGE IS LOADED");
            
                    //console.log('My width is: ', img.naturalWidth);
                    //console.log('My height is: ', img.naturalHeight);
            
                    var imgW = img.naturalWidth;
                    var imgH = img.naturalHeight;
            
                
                    posArr.push(box.position);
                    tempArr.push({

                        position    :box.position,
            
                        id          :box.id,
                        title       :box.title,
                        text        :box.text,
                        bgColor     :box.bgColor,
                        image:{
            
                            src:box.image.src,
                            w:imgW,
                            h:imgH
                        }
            
                    })


                    if(tempArr.length === images.length)
                    {
                        posArr.sort();

                        //console.log("POS: "+posArr.length)
                        //console.log("TEMP: "+tempArr.length)
                        //console.log("TEST LEN: "+testData.length)
                    

                        var array = [];
                        for(var i=0; i < posArr.length; i++)
                        {
                            for(var j=0; j < tempArr.length; j++)
                            {
                                //console.log(posArr[i]+" === "+tempArr[j]['position']);

                                if(posArr[i] === tempArr[j]['position'])
                                {
                                
                                    array.push(tempArr[j]);
                                    //console.log("break");
                                    break;

                                }//==
            
                            }//==

                        }//==

                        Complete(array);

                    }//==

            
            
                };
                img.setAttribute("crossOrigin","anonymous");
                img.setAttribute("src",sourceurl);
            
            }



        }// -- if items



    },
    [

        location, 
        hashData,     
        items,      
        images

    ])








    // -- vars -- //

    var ssg;
    var gridMaxWidth;  
    ssg = false;
    gridMaxWidth = "1920px";
    if(location.search.match(/\?/gi))
    {
        var qr = location.search;
        if(qr.charAt(0) === '?') 
            qr = qr.substring(1,qr.length);

        if(qr === 'ssg=yes') 
        {
            ssg = true;
            gridMaxWidth = "1680px";

        }
    }


    var call;
    call = props.info.call;
    if(ssg) call = 'fixed';


    const info = JSON.parse(JSON.stringify(props.info));
    info.gridMaxWidth = gridMaxWidth;
    info.ssg = ssg;




    //alert(props.refid);

    if(props.refid 
    && props.refid !== undefined
    && props.refid !== null
    )
    {
        const ref = document.getElementById(props.refid);

        var refDecimal = (parseInt(ref.offsetWidth)/width);
        var newWidth  = width * refDecimal;
      
        //console.log(props.refid+"\n"+ref.offsetWidth+"\n"+refDecimal+"\n"+newWidth);

        info.thresh.refWidth = newWidth;
        info.gridClass = "B";
     
        if(call === 'fixed') 
        {
            info.cellClass = "A";
            if(info.thresh.across3 > 9999) 
            {
                info.cellClass = "B";
                if(props.refid.match(/pagerow/)) info.cellClass = "B2";

            }
            if(info.thresh.across2 > 9999) info.cellClass = "C";

        }

        if(items.length === 2) 
        {
            info.cellClass = "D";
            if(width < 550) 
            {
                info.cellClass = "C";
            }

        }

        if(items.length === 1) 
        {
            info.cellClass = "C";

        }


    }//==



 



    if(error) 
    {
        return (

            <div 
            style={{

                width:"100%",
                height:"auto",
                padding:"15px 0 15px 0",
                textAlign:"center",
                color:"white"

            }}
            >
                    Error: {error.message}

            </div>

        )



    } 
    else 
    if(!isLoaded) 
    {

        return (
        
             <div 
             style={{

                width:"100%",
                height:"auto",
                padding:"15px 0 15px 0",
                textAlign:"center",
                color:"white"

             }}
             >
                 Loading...

            </div>
        )


    } 
    else 
    {



        return (

            <div>

                {

                props.info.gridType === 'Band' || props.info.gridType === 'PageBand' ?

                    <BandImgs

                        handle  = {props.handle} 
                        items   = {items}
                        info    = {info}
                    
                    />

                :null

                }

            </div>

        )


    }



}
export default Index


