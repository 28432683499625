import React, { 

    //useState, 
    //useEffect, 
    //useRef 

} from 'react';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,   
    //useParams


} from "react-router-dom";

//import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider';

//import { useLiquidImageGrid } from 'utils/hooks/LiquidFormula/ImageGrid';
//import { useLiquidGrid } from 'utils/hooks/LiquidFormula/LiquidGrid';
import { useLiqGrid } from 'utils/hooks/LiquidFormula/LiqGrid';

import StylGrid from './Grid.module.css';


import CreateMarkup from 'utils/components/Universal/CreateMarkup';
//import CreateLink from 'utils/components/Universal/CreateLink';





const Index = (props) => {


    // -- router -- //
    const location = useLocation(); 
    //let match = useRouteMatch();
    //let params = useParams();


    //console.log(JSON.stringify(location,null,2));
 
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);


    // -- window dims -- //
    //const { height } = useWindowDimensions();



    // -- refs -- //

    const cellRefs = [];
    const setCellRef = (ref) => {

        if(ref && ref !== null && ref !== undefined)
        {
            //console.log("ref::::"+ref.id);
            var found;
            found = false;
            for(var i=0; i < cellRefs.length; i++)
            {
                if(cellRefs[i].id === ref.id) found = true;
            }
            if(!found) cellRefs.push(ref);
            //console.log(cellRefs.length);

        }

    };

    /*
    const maskRefs = [];
    const setMaskRef = (ref) => {

        if(ref && ref !== null && ref !== undefined)
        {
            //console.log("ref::::"+ref.id);
            var found;
            found = false;
            for(var i=0; i < maskRefs.length; i++)
            {
                if(maskRefs[i].id === ref.id) found = true;
            }
            if(!found) maskRefs.push(ref);
            //console.log(cellRefs.length);

        }

    };
    */






/*


    // -- handle -- //

    const handleOverOut = (hndl) => {


        //hndl.bgColor;
        //hndl.txtColor;
        //hndl.brColor;


        
        var cell = false;
        var mask = false;

        if(cellRefs.length > 0)
        {
            var cellRegex = new RegExp(hndl.id.toString(),"gi");
            for(var cellRef of cellRefs)
            {
                if(cellRef.id.match(cellRegex) 
                )
                {
                    cell = cellRef;
                    break;
                }
            }
        }

        if(maskRefs.length > 0)
        {
            var maskRegex = new RegExp(hndl.id.toString(),"gi");
            for(var maskRef of maskRefs)
            {
                if(maskRef.id.match(maskRegex) 
                )
                {
                    mask = maskRef;
                    break;
                }
            }
        }

    

        //console.log(hndl.gridType);

        switch(hndl.call)
        {
        case'over':
    
            switch(hndl.gridType)
            {
            case"ImageText":
           
                if(cell) cell.style.borderColor = "red";
            break;
            default:
    
                //if(mask) mask.style.backgroundColor = 'magenta';
            break;
            }//==

        break;
        default:

            switch(hndl.gridType)
            {
            case"ImageText":
    
                if(cell) cell.style.borderColor = "lightgray";
            break;
            default:
    
                //if(mask) mask.style.backgroundColor = 'transparent';
            break;
            }//==

        break;
        }   



    };


*/

    //console.log("components/Grid/FGrid/VidGrid - props.items..."+JSON.stringify(props.items,null,2));




    var gridObj;
    gridObj = useLiqGrid(props.items, props.info);
    //console.log("components/Grid/FGrid/VidGrid - vars..."+JSON.stringify(gridObj['items'],null,2));

    const liqItems = gridObj['items'];



    var gridClass;
    var cellClass;

    gridClass = StylGrid.Vids;
    cellClass = StylGrid.CellA;

    if(props.info.cellClass 
    && props.info.cellClass !== undefined
    ) 
    {
        switch(props.info.cellClass)
        {
        case'B':
            cellClass = StylGrid.CellB
        break;
        case'B2':
            cellClass = StylGrid.CellB2
        break;
        case'C':
            cellClass = StylGrid.CellC;
        break;
        case'D':
            cellClass = StylGrid.CellD;
        break;
        default:
        }
    }


    //console.log(cellClass+" "+gridClass);


    var headingStyl = false;
    var pgraphStyl = false;

    if(props.info.compStyl)
    {
        if(props.info.compStyl.heading) headingStyl = props.info.compStyl.heading;
        if(props.info.compStyl.pgraph) pgraphStyl = props.info.compStyl.pgraph;
    }






    return (


        <div>

        {props.info.gridType === 'ImageOnly' ?


            <div
            className={gridClass}
            style={{
                backgroundColor :props.info.gridBgColor,
                maxWidth        :props.info.gridMaxWidth
            }}
            >
                {liqItems.data.map((box, i) => (

                    <div
                    key={i}   
                    //ref={setCellRef}
                    //id={box.id.toString()}   
                    //className={StylGrid.Cell}
                    className={cellClass}
                    style={{

                        //backgroundColor:"yellow"
                    }}

                    onClick={() => props.handle({

                        call        :'showVidFlyr',
                        //data        :liqItems.data,
                        data        :[box],
                        gridType    :props.info.gridType,
                        position    :i,
                        id          :box.id
    
                    })}

                    >


                        <div 
                        className={StylGrid.Sizer}
                        style={{

                            //backgroundColor:"magenta",
                            width:liqItems.cell.thumb.width,
                            //height:liqItems.cell.thumb.height,
                            margin:liqItems.cell.thumb.margin,
                          

                        }}
                        >

                            <div
                            //ref={setMaskRef}
                            //id={"Mask_"+box.id.toString()}
                            className={StylGrid.Mask}
                            style={{

                                //backgroundColor:"darkmagenta",
                                //width:liqItems.cell.mask.width,
                                //height:liqItems.cell.mask.height,
                                //top:liqItems.cell.mask.top,
                                //left:liqItems.cell.mask.left
                                
                            }}
                            >
                                <div 
                                className={StylGrid.MaskBox}
                                >
                                </div>

                                <div 
                                className={StylGrid.Overlay}
                                >
                                </div>



                                {
                                props.refid.match(/page/gi)
                                ?

                                    <div
                                    className={StylGrid.CaptionB}
                                    >
                                        {box.title}

                                    </div> 

                                :

                                    <div
                                    className={StylGrid.Caption}
                                    >
                                        {box.title}

                                    </div>   

                                }

                                <div
                                className={StylGrid.Circle}
                                >
                                    <div
                                    className={StylGrid.ArrowBox}
                                    >
                                        <div
                                        className={StylGrid.Arrow}
                                        >
                                        </div>
                                    </div>
                                </div>




                            </div>



    
    

                            <div
                            className={StylGrid.Thmb}
                            >

                                <img
                                className={StylGrid.ImageOnly_Img}
                                style={{

                                    minWidth:box.image.maxWidth,
                                    maxHeight:box.image.maxHeight,

                                    marginTop:box.image.top,
                                    marginLeft:box.image.left

                                }}

                                    //src={window.location.origin + `${process.env.PUBLIC_URL}` + box.image.src} 
                                    //src={window.location.origin + box.image.src} 
                                    src={box.image.src}

                                    //width={box.image.w}
                                    //height={box.image.h} 

                                    alt='noimage'
                                />   

                            

                            </div>


                        </div>


                     </div>


            
                
                ))} 

            </div>



        :null}






        {props.info.gridType === 'ImageText' ?


            <div
            className={gridClass}
            style={{
                backgroundColor :props.info.gridBgColor,
                maxWidth        :props.info.gridMaxWidth
            }}
            >
                {liqItems.data.map((box, i) => (


                    <div
                    key={i}
                    //className={StylGrid.Cell}
                    className={cellClass}
                    >


                        <div
                        ref={setCellRef}
                        //id={box.id.toString()}
                        className={StylGrid.Container}
                        style={{

                            width:liqItems.cell.container.width,
                            height:liqItems.cell.container.height,
                            margin:liqItems.cell.container.margin 
                        }}

                        onClick={() => props.handle({

                            call        :'showVidFlyr',
                            data        :[box],
                            gridType    :props.info.gridType,
                            position    :i,
                            //id          :box.id
        
                        })}

                        >



                            <div 
                            className={StylGrid.ThmbSizer}
                            style={{

                                //backgroundColor:"blue",
                                width:liqItems.cell.thumb.width,
                                //height:liqItems.cell.thumb.height, //vh instead
                                margin:liqItems.cell.thumb.margin,
                            

                            }}
                            >

                                <div
                                className={StylGrid.Mask}
                                >

                                    <div 
                                    className={StylGrid.MaskBox}
                                    >
                                    </div>


                                    <div 
                                    className={StylGrid.Overlay}
                                    >
                                    </div>

                            
                                    <div
                                    className={StylGrid.Circle}
                                    >
                                        <div
                                        className={StylGrid.ArrowBox}
                                        >
                                            <div
                                            className={StylGrid.Arrow}
                                            >
                                            </div>
                                        </div>
                                    </div>


                                </div>



                                <div
                                className={StylGrid.Thmb}
                                >

                                    <img
                                    className={StylGrid.ImageText_Img}
                                    style={{

                                        minWidth:box.image.maxWidth,
                                        maxHeight:box.image.maxHeight,
    
                                        marginTop:box.image.top,
                                        marginLeft:box.image.left

                                    }}

                                        //src={window.location.origin + `${process.env.PUBLIC_URL}` + box.image.src} 
                                        //src={window.location.origin + box.image.src} 
                                        src={box.image.src}

                                        //width={box.image.w}
                                        //height={box.image.h} 

                                        alt='noimage'
                                    />   

                
                                </div>


                            </div>






                            {
                    
                            headingStyl
                            ?
                                <div 
                                className={StylGrid.Heading}
                                style={{

                                    "fontFamily"        : headingStyl.fontFamily,
                                    "fontSize"          : headingStyl.fontSize,
                                    "fontWeight"        : headingStyl.fontWeight,
                                    "textAlign"         : headingStyl.textAlign,
                                    "textDecoration"    : headingStyl.textDecoration,
                                    "color"             : headingStyl.color

                                }}
                                >
                                    {/* {box.title} */}
                                    <div dangerouslySetInnerHTML={CreateMarkup(box.title)} />


                                </div>

                            :

                                <div 
                                className={StylGrid.Heading}
                                >
                                    {/* {box.title} */}
                                    <div dangerouslySetInnerHTML={CreateMarkup(box.title)} />


                                </div>



                            }

                            

                            {

                            pgraphStyl
                            ?


                                <div 
                                className={StylGrid.Pgraph}
                                style={{

                                    "fontFamily"        : pgraphStyl.fontFamily,
                                    "fontSize"          : pgraphStyl.fontSize,
                                    "fontWeight"        : pgraphStyl.fontWeight,
                                    "textAlign"         : pgraphStyl.textAlign,
                                    "textDecoration"    : pgraphStyl.textDecoration,
                                    "color"             : pgraphStyl.color

                                }}
                                >
                                    {/* {box.text} */}
                                    <div dangerouslySetInnerHTML={CreateMarkup(box.text)} />


                                </div>

                            :


                                <div 
                                className={StylGrid.Pgraph}
                                >
                                    {/* {box.text} */}
                                    <div dangerouslySetInnerHTML={CreateMarkup(box.text)} />


                                </div>


                            }

                
                            


                        </div>


                    </div>


                


                ))} 

            </div>




        :null}


        </div>



    )




}

export default Index





// "heading": {
//     "fontFamily": "Arial, Helvetica, sans-serif",
//     "fontSize": "32px",
//     "fontWeight": "550",
//     "textAlign": "left",
//     "textDecoration": "none",
//     "color": "#00291cd"
//   },
//   "pgraph": {
//     "fontFamily": "Arial, Helvetica, sans-serif",
//     "fontSize": "24px",
//     "fontWeight": "500",
//     "textAlign": "left",
//     "textDecoration": "none",
//     "color": "black"
//   }
