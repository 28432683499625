import React, { 

    useState, 
    //useEffect, 
    //useRef 

} from 'react';


import {

    Base,
    Menu

} from '../Styled';




const Index = (props) => {


    //console.log("Stars - Favorite: "+props.favorite)



    //=============================================
    // state/effect - showMenu
    //=============================================

    //const [isLoaded, setIsLoaded] = useState(false);
    const [items] = useState([

        {
            value:"a-z",
            text:"A to Z"
        },
        {
            value:"z-a",
            text:"Z to A"
        },
        {
            value:"favorites-high-low",
            text:"Favorites (High to Low)"
        },
        {
            value:"favorites-low-high",
            text:"Favorites (Low to High)"
        }


    ]);

    // useEffect(() => {

    //     if(!isLoaded)
    //     {
    //         setIsLoaded(true);
    //     }
    // },
    // [

    //     isLoaded

    // ])





    return (<div>


        {/* <Alfa></Alfa> */}
        <Base
        onClick = {(v) => props.handle({

            call:"showMenu",
            data:false

        })}
        ></Base>

        <Menu>

            {items.map((item, i) => (
            
                <div 
                key={i}
                className={"Button"}
                onClick = {(v) => props.handle({

                    call:"clicked",
                    data:{

                        value:item.value,
                        text:item.text
                    }

                })}
                >
                    {item.text}

                </div>
            
            ))}

        </Menu>
                                                

    </div>)

}

export default Index;


