import React, { 

    useState, 
    useEffect, 
    //useRef 

} from 'react';

//import { useSelector, useDispatch } from 'react-redux';

//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';

import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,   
    //useParams


} from "react-router-dom";

import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'


//import { LibImg } from 'library';

import StylColumn from './StylModules/Column.module.css';



const Index = (props) => {


    // -- router -- //
    const location = useLocation(); 
    //let match = useRouteMatch();
    //let params = useParams();
 
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);


    // -- window dims -- //
    const { height } = useWindowDimensions();


    // -- redux -- //
    //const dispatch = useDispatch();
    //const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));
    


    // -- refs -- //

    const [thmbRefs] = useState([]);// useState to avoid warnings when using thmbRefs inside useEffect
    const setThmbRef = (ref) => {

        if(ref && ref !== null && ref !== undefined)
        {
            console.log("ref::::"+ref.id);
            var found;
            found = false;
            for(var i=0; i < thmbRefs.length; i++)
            {
                if(thmbRefs[i].id === ref.id) found = true;
            }
            if(!found) thmbRefs.push(ref);
            console.log(thmbRefs.length);

        }

    };





    /*

    // -- ref -- //
    const refA = useRef(null);
    const refB = useRef(null);
    const refC = useRef(null);

    */


    // -- state -- //

    const [thmbWidth, setThmbWidth] = useState(0);
    const [thmbHeight, setThmbHeight] = useState(225);
    const [isLoaded, setIsLoaded] = useState(false);


    useEffect(() => {


        if(thmbRefs.length > 0)
        {
            if(thmbRefs[0] && thmbRefs[0] !== undefined)
            {
                var thmbRef = thmbRefs[0];
                //console.log("THMB REF: "+thmbRef);

                var thmbW = thmbRef.offsetWidth;
                var thmbH;

                if(thmbRefs.length === 1)
                    thmbH = (62/100) * thmbW;
                else
                    thmbH = (64/100) * thmbW;


                console.log(thmbH);

                setThmbWidth(thmbW)          
                setThmbHeight(thmbH); 
                setIsLoaded(true);
           

            }
        }





    },[
        height,
        thmbRefs
    ])


    






    // -- vars -- //

    var columnClass;
    var boxClass;
    var boxMargin;

    columnClass = StylColumn.Band;
    boxMargin = "0px "+props.info.gutter.size+"px 0px "+props.info.gutter.size+"px";


 
    switch(props.info.gridType)
    {
    case'PageBand':

        boxClass = StylColumn.PageBandBox;
    break;
    default:

        boxClass = StylColumn.BandBox;
    }//==




    // -- func -- //

    const createMarkup = (txt) => {


        var html = '';
        var txtColor = 'black';
    
        html += '<span ';
            html += 'style="';
            html += 'color:'+txtColor+';';
        html += '">'
            
            html += txt;
        
        html += '</span>';

        return {__html: html};

    }







    // -- ModifyImageSizing -- //

    console.log(":::: ModifyImageSizing ::::");


    var images = props.items;
    console.log("::::::::IMAGES: "+images.length);
    const items = [];

    for(var img of images)
    {
        console.log(JSON.stringify(img,null,2));

        /*

        { 
            "position": 5,
            "id": 0.6053371981854699,
            "title": "Ferrari",
            "text": "Lorem, ipsum dolor sit amet consectetur adipisicing elit.
            "bgColor": "lemonchiffon",
            "image": {
              "src": "/_resources/images/ferrari-snow.jpg",
              "w": 2240,
              "h": 1400
            }
        }


        {
            "imgW": 263,
            "imgH": 164,
            "adjust": {
              "call": "fill_div",
              "marginTop": 0,
              "marginLeft": -3
            }
        }

        */


        /*
        var imgObj = LibImg.ModifyImageSize({

            call        :"fill_div",
            divWidth    :thmbWidth * 3,
            divHeight   :thmbHeight * 3,
            width       :img.image.w,
            height      :img.image.h

        });

        console.log(JSON.stringify(imgObj,null,2));

        img.image.w = imgObj.imgW;
        img.image.h = imgObj.imgH;
        img.image.top = -(thmbHeight/2);
        img.image.left = imgObj.adjust.marginLeft;
        */

        items.push(img);

    }







    if(!isLoaded) 
    {

        return (


            <div id={"Preset"}>

            
                <div
                className={StylColumn.Columns}
                >
                    <div
                    className={columnClass}
                    >
                        {props.items.map((item, i) => (

                            <div
                            key={i}
                            className={boxClass}
                            style={{

                                margin:boxMargin

                            }}
                            >
                                <div className={StylColumn.Thmb}
                                ref={setThmbRef}
                                id={"thmb_"+i}
                                >
                                </div>

                            </div>

                        ))}

                    </div>
            
                </div>

            </div>

        )


    } 
    else 
    {




        console.log("::::::::::::"+props.items.length);


        return (


            <div>

                <div
                className={StylColumn.Columns}
                >


                    <div
                    className={columnClass}
                    >
                        {items.map((item, i) => (


                            <div
                            key={i}
                            className={boxClass}
                            style={{

                                margin:boxMargin

                            }}
                            >


                                <div
                                className={StylColumn.Mask}
                                style={{

                                    //backgroundColor:"magenta",
                                    //width:liqItems.cell.mask.width,
                                    //height:liqItems.cell.mask.height,
                                    //top:liqItems.cell.mask.top,
                                    //left:liqItems.cell.mask.left
                                    
                                }}
                                >
                                    {props.info.mask.message.boolean ?
                                    
                                        <div 
                                        className={StylColumn.MaskBox}
                                        >
                                            <div
                                            className={StylColumn.MaskTxt}
                                            style={{

                                                backgroundColor:props.info.mask.message.backgroundColor,
                                                color:props.info.mask.message.textColor

                                            }}
                                            >
                                            
                                                <div dangerouslySetInnerHTML={createMarkup(

                                                    "<span>"+thmbWidth+"</span>"

                                                )} />

                                            </div>

                                        </div>
            
                                    :null}

                                </div>

                        
                                <div className={StylColumn.Thmb}
                                ref={setThmbRef}
                                id={"thmb_"+i}
                                style={{

                                    height:thmbHeight+"px"
                                }}
                                >

                        {

                                    <img
                                    className={StylColumn.Img}
                                    style={{


                                        height:"auto",

                                        minWidth:item.image.maxWidth,
                                        minHeight: "100%",

                                        maxWidth:"auto",
                                        maxHeight:item.image.maxHeight,

                                        marginTop:item.image.top,
                                        marginLeft:item.image.left

                                    }}

                                        src={window.location.origin + `${process.env.PUBLIC_URL}` + item.image.src} 
                                        //src={window.location.origin + box.image.src} 
                                        //width={box.image.w}
                                        //height={box.image.h} 
                                        alt='noimage'
                                    />   

                        }



                                </div>


                            </div>


                        ))}


                    </div>


            

                </div>


            </div>


                        


        )


    }






}

export default Index;











/*


                    {
                    columnClass === 'full' ?

                        props.items.length === 1 ?

                

                        :// === FULL MORE THAN ONE ===


                    :// === PAGE ===



                    }




*/