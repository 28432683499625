export const EMPLOYEE = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 500 500";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	let iconColor = props.iconColor || "rgb(0,0,0)";


	let darklite = props.darklite || "lite";
	if(darklite === 'dark')
	{

		return(
			<div>
			<svg xmlns="http://www.w3.org/2000/svg"
				width={svgWidth}
				height={svgHeight}
				viewBox={svgViewBox}
				preserveAspectRatio={svgPreserveAspectRatio}
				style={svgStyle}
			>
	
	
				<path 
					fill={iconColor}
					fillRule="evenodd"
					clipRule="evenodd"
					d="M249.997,278.904
					c-52.793,0-95.945-42.604-96.869-95.184h193.747C345.944,236.3,302.797,278.904,249.997,278.904L249.997,278.904z M352.553,289.275 v170.928h-205.11V289.275h45.663c16.041,11.558,35.693,18.377,56.894,18.377c21.203,0,40.854-6.819,56.894-18.377H352.553 L352.553,289.275z M391.109,289.484v170.719H450V348.374C450,315.987,423.502,289.484,391.109,289.484L391.109,289.484z M50,348.374v111.829h58.889V289.484C76.499,289.484,50,315.987,50,348.374L50,348.374z M222.439,102.596 c1.345,3.729,4.387,9.382,16.632,9.382h21.853c12.247,0,15.288-5.652,16.634-9.382l16.099-44.623
					c0.606-1.622,0.443-3.759-1.993-4.9c-12.836-5.473-26.906-8.503-41.664-8.503c-14.755,0-28.832,3.03-41.667,8.503 c-2.432,1.142-2.601,3.278-1.989,4.9L222.439,102.596L222.439,102.596z M303.341,68.525l-14.501,44.469 c-4.665,12.305-15.162,16.874-27.741,17.241h-22.197c-12.59-0.367-23.053-4.937-27.742-17.227l-14.501-44.483	c-28.644,16.463-49.052,45.731-53.325,79.902h213.335C352.393,114.257,331.981,84.988,303.341,68.525L303.341,68.525z M369.158,161.202h-11.664c0-0.008,0-0.018,0-0.027H142.501c0,0.01,0,0.02,0,0.027h-11.662v12.158c22.045,0,216.273,0,238.319,0
					V161.202L369.158,161.202z"
				/>
	   
	
			</svg>
			</div>
	
		);//return


	}
	else // lite
	{


		return(
			<div>
			<svg xmlns="http://www.w3.org/2000/svg"
				width={svgWidth}
				height={svgHeight}
				viewBox={svgViewBox}
				preserveAspectRatio={svgPreserveAspectRatio}
				style={svgStyle}
			>
	
	
				<path 
					fill={iconColor}
					fillRule="evenodd"
					clipRule="evenodd"
					d="M142.609,211.306h-20.744V148.12l13.109-0.03 c7.881-31.066,26.142-56.405,53.125-73.671c2.236-5.255,5.32-9.322,9.782-12.889c13.494-9.645,37.646-12.91,52.117-12.994 c15.678,0.09,38.691,3.357,52.145,13.027c4.445,3.555,7.523,7.621,9.756,12.857c26.938,17.271,45.256,42.641,53.123,73.668	c4.369,0,8.74,0.021,13.111,0.029v63.188h-20.738c-3.266,16.662-10.152,32.581-20.344,46.188l33.52,0.119 c42.863,1.08,75.494,33.719,76.572,76.58v117.271H52.856V334.19c1.095-42.859,33.698-75.496,76.57-76.578l33.519-0.117 C152.627,243.744,145.895,228.153,142.609,211.306L142.609,211.306z M249.997,274.989c-45.067,0-81.903-36.369-82.692-81.253 h165.392C331.903,238.62,295.069,274.989,249.997,274.989L249.997,274.989z M337.544,283.843v145.912H162.452V283.843h38.979 c13.693,9.865,30.47,15.688,48.567,15.688c18.1,0,34.875-5.822,48.566-15.688H337.544L337.544,283.843z M370.458,284.021v145.734	h50.271v-95.463C420.729,306.646,398.11,284.021,370.458,284.021L370.458,284.021z M79.27,334.292v95.463h50.271V284.021 C101.891,284.021,79.27,306.646,79.27,334.292L79.27,334.292z M226.473,124.483c1.148,3.184,3.745,8.009,14.198,8.009h18.654
					c10.455,0,13.051-4.825,14.199-8.009l13.742-38.093c0.518-1.384,0.379-3.208-1.701-4.183c-10.957-4.672-22.969-7.259-35.566-7.259 c-12.596,0-24.612,2.587-35.568,7.259c-2.076,0.975-2.221,2.799-1.698,4.183L226.473,124.483L226.473,124.483z M295.534,95.399 l-12.379,37.961c-3.982,10.504-12.943,14.404-23.682,14.718h-18.948c-10.747-0.313-19.679-4.214-23.682-14.706l-12.379-37.973 c-24.452,14.054-41.873,39.039-45.521,68.209h182.113C337.407,134.438,319.983,109.453,295.534,95.399L295.534,95.399z M351.72,174.513h-9.957c0-0.007,0-0.015,0-0.023H158.233c0,0.009,0,0.017,0,0.023h-9.955v10.379c18.819,0,184.621,0,203.441,0	V174.513L351.72,174.513z"
				/>
	
	
	   
	
			</svg>
			</div>
	
		);//return

		
	}





}