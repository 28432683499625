const PanelInfo = {



    "init":[],

    "ads":[

        {
            image: {
                "h": "608",
                "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/f1-1_sys20210407067841id_.jpg",
                "w": "1080",
                "id": "20210407067841"

            },
            color:"#07a2e3",
            heading:"Ad 1",
            pgraph:"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque."
            
        },

        {
            image: {
                "h": "608",
                "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/f1-1_sys20210407067841id_.jpg",
                "w": "1080",
                "id": "20210407067841"

            },


            color:"#07a2e3",
            heading:"Ad 2",
            pgraph:"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque."

        },

        {

            image: {
                "h": "608",
                "src": "https://s2-r-com.s3.amazonaws.com/admin/inventory/img/f1-1_sys20210407067841id_.jpg",
                "w": "1080",
                "id": "20210407067841"

            },

            color:"#07a2e3",
            heading:"Ad 3",
            pgraph:"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque."
            
        }

    ],


    

    // "ads_OFF":[

    //     {
    //         color:"#07a2e3",
    //         heading:"Ad 1",
    //         pgraph:"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque."

    //     },

    //     {
    //         color:"#07a2e3",
    //         heading:"Ad 2",
    //         pgraph:"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque."

    //     },

    //     {
    //         color:"#07a2e3",
    //         heading:"Ad 3",
    //         pgraph:"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque."
            
    //     }

    // ],







    "calc":[

        // {
        //     color:"yellow",
        //     heading:"Cost Of Goods",
        //     pgraph:"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque."

        // },

        // {
        //     color:"#00cc66",
        //     heading:"Calculate By",
        //     pgraph:"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque."

        // },

        // {

        //     color:"red",
        //     heading:"Overhead",
        //     pgraph:"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque."
            
        // },


        // {
        //     color:"orange",
        //     heading:"Commission",
        //     pgraph:"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque."
            
        // },


        // {
        //     color:"#454d5f",
        //     heading:"Quantity",
        //     pgraph:"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque."
            
        // }


    ],

    
    "readout":[

        // {
        //     color:"#07a2e3",
        //     heading:"Net Profit",
        //     pgraph:"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque."

        // },

        // {

        //     color:"#07a2e3",
        //     heading:"Selling Price",
        //     pgraph:"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque."

        // },

        // {
        //     color:"#07a2e3",
        //     heading:"Breakeven Price",
        //     pgraph:"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque."
            
        // }

    ],


    "howto":[

        {
            color:"#07a2e3",
            heading:"Net Profit",
            pgraph:"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque."
        },

        {

            color:"#07a2e3",
            heading:"Selling Price",
            pgraph:"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque."

        },

        {
            color:"#07a2e3",
            heading:"Breakeven Price",
            pgraph:"Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque."
            
        }

    ],


    
    "knowledge_base":[],
    "overhead":[],
    "account":[],




}
export default PanelInfo;