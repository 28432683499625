
import React, { 

    useState, 
    useEffect,
    useRef

} from 'react';

import CreateMarkup from '../../CreateMarkup';
import { Print } from './Styled';


// import { PrintSamples } from './Samples';


const Index = (props) => {


    // -- ref -- //
    const printRef = useRef(null)


    // -- state -- //
    const [printOut, setPrintOut] = useState(false);
    const [printType, setPrintType] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {


        if(!isLoaded)
        {

            setIsLoaded(true);

            //let printOut;
            //printOut = JSON.stringify(props.data,null,2);
            //printOut = printOut.replace(/\n/gi,"<br/>");
            //setPrintOut("Chart goes here!!");
            setPrintOut(false);



            //console.log("PRINT H: "+printRef.current.offsetHeight);
            props.handle({

                call:"adjustContentsHeight",
                data:{

                    ref:printRef,
                    h:printRef.current.offsetHeight

                }

            })




            console.log("PRINT PROPS: "+JSON.stringify(props,null,2));


            var printData;
            printData = 'Print - Default';
            if(props.data && props.data !== undefined)
            {
                if(Object.prototype.toString.call(props.data) === '[object Array]'
                || Object.prototype.toString.call(props.data) === '[object Object]'
                )
                {
                    printData = JSON.stringify(props.data,null,2);
                }
  
            }

            setPrintOut(printData);
            setPrintType('test');

        }


        // console.log("FLYR CHART INFO: "+JSON.stringify(chartInfo,null,2));
        // alert('Flyr Chart Info')

   
    
    },[

        props,
        isLoaded,
        printRef

    ])



    
 
    return (

        <Print
        ref={printRef}
        backgroundColor={'lightyellow'}
        >


            {
            printType === 'test'
            ?
                <div>

                    <pre>

                        {printOut}

                    </pre>

                </div>

            :


              <div dangerouslySetInnerHTML={CreateMarkup(printOut)} />
     
            }

     
        </Print>

    );




}


export default Index;



