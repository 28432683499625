
import React, { 

    useState, 
    useEffect, 
    useRef,
    //useMemo

} from 'react';


import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';



import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    Link,

    //useRouteMatch,
    useLocation,
    //useHistory,   
    //useParams,

} from "react-router-dom";
  

//import { useLiquidNavBar } from 'utils/myHooks/LiquidFormula/NavBar';
import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'


//import logo from 'grfx/logo.svg';
//import { fontSize } from 'pdfkit/js/mixins/fonts';


//import 'App.css';

import { 

    Header, 
    HeaderBg,
    HeaderMain, 
    HeaderLogo,
    //HeaderTitle,
    //HeaderArrowButton,

    NavBar,
    Hamburger
    

} from './StylAttrs';





const Index = (props) => {


    const primeHandle = props.databind.primeProps.handle;


    //.log("HEADER PROPS zoneObj:" + props.zoneObj);

    const { width } = useWindowDimensions()


    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();

 
 
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);


    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);


    //let zoneId;
    //zoneId = pathname.replace(/\//gi,"-");


    // -- redux -- //
    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));
  
  


    // -- ref -- //
    const refMain = useRef(null);
    const refLogo = useRef(null);
    const refNavbar = useRef(null);
    const refBurger = useRef(null);
    const refBun = useRef(null);


    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false)  
    const [winThresh, setWinThresh] = useState(10000)
    const [thresh] = useState({

        a: 700,
        b: 500,
        c: 350

    });

    const [headerGrfx, setHeaderGrfx] = useState(false);
    const [headerStyl, setHeaderStyl] = useState({

        backgroundColor: false,
        height:  75 + "px",

    });
    const [logoStyl, setLogoStyl] = useState({

        margin:false

    });
    const [hamburgerStyl, setHamburgerStyl] = useState({

        margin:false

    });




    useEffect(() => {

        if(!isLoaded)
        {
            setIsLoaded(true);

            var newThresh;

            if(width < thresh.c
            )
            {
                newThresh = thresh.c;
            }
            else
            if(width < thresh.b
            && width > thresh.c
            )
            {
                newThresh = thresh.b;
            }
            else
            if(width < thresh.a
            && width > thresh.b
            )
            {
            
                newThresh = thresh.a
            }
            else
            if(width > thresh.a)
            {
                newThresh = 10000;
            }



            if(winThresh !== newThresh)
            {

                //alert(newThresh);
                setWinThresh(newThresh);
            
            }


            // props.handle({

            //     call:'dispatch',
            //     data:{

            //         action:'UPDATE_ZONE',
            //         key:'headerHInt',
            //         value:200

            //     }

            // });


        }





        //---------------------------------------
        // setHeaderGrfx
        //---------------------------------------

        if(!headerGrfx)
        {
            if(zoneObj.settings 
            && zoneObj.settings !== undefined)
            {

                var burgerTop = 10;

                if(zoneObj.settings.graphic)
                {
                    if(zoneObj.settings.graphic.header)
                    {
                        // console.log("SRC: "+zoneObj.settings.graphic.header.image.src);  

                        // if(!zoneObj.settings.graphic.header.image.src.match(/http/gi))
                        // {           
                        //     alert('no http');
                        // }else{
                        //     alert('has http');
                        // }

                        setHeaderGrfx({

                            logo:{

                                src:zoneObj.settings.graphic.header.image.src,
                                w:zoneObj.settings.graphic.header.image.w,
                                h:zoneObj.settings.graphic.header.image.h
                            }

                        });


                        // alert(""
                            
                        //     +"\nMain Height: "+refMain.current.offsetHeight             
                        //     +"\nLogo Height: "+refLogo.current.offsetHeight
                        //     +"\nBurger Height: "+refBurger.current.offsetHeight

                        //     +"\nGraphic Height: "+zoneObj.settings.graphic.header.image.h
                        
                        // );

                        var logoTop = ( refMain.current.offsetHeight - parseInt(zoneObj.settings.graphic.header.image.h) )/2;
                        setLogoStyl({

                            margin: logoTop + "px 0 0 15px"

                        });

                        burgerTop = (refMain.current.offsetHeight - refBurger.current.offsetHeight)/2 - refBun.current.offsetTop;

                    }



                }


                var headerHInt;
                var headerMainColor = false;
                var headerOpacity = "1.0";

                if(zoneObj.settings.appearance)
                {
                    if(zoneObj.settings.appearance.header)
                    {
                        if(zoneObj.settings.appearance.header.headerMainColor)
                        {
                            headerMainColor = zoneObj.settings.appearance.header.headerMainColor;
                        }

                        headerHInt = zoneObj.settings.appearance.header.headerMainHeight;
                        if(headerHInt.match(/px/gi))
                        {
                            headerHInt = headerHInt.replace(/px/gi,"");
                        }
                        if(headerHInt < 75) headerHInt = 75;


                        if(zoneObj.settings.appearance.header.headerOpacity)
                        {
                            headerOpacity = zoneObj.settings.appearance.header.headerOpacity;
                        }
                        setHeaderStyl({

                            backgroundColor: headerMainColor,
                            height:  headerHInt + "px",
                            opacity: headerOpacity
                        });
                        setHamburgerStyl({

                            margin: burgerTop + "px 15px 0 0"

                        });

                    }

                }



            }


        }



    },[
        

        props,
        zoneObj,

        width,
        isLoaded,
        winThresh,
        thresh,
   
        refMain,
        refLogo,
        refNavbar,
        refBurger,
        refBun,

        headerGrfx
 


    ])

    








    //==================================
    // state/effect - navigation
    //==================================

    const [initNavigation, setInitNavigation] = useState(false);
    const [navigation, setNavigation] = useState([]);

    useEffect(() => {


        // -- effect -- //
 
        const effectNavigation = (eff) => {


            //console.log("Content: eff "+JSON.stringify(eff,null,2));

            //-------------------------------------
            // message
            //-------------------------------------

            // eff.props.databind.primeProps.handle({

            //     call            : 'showMessngr',
            //     isError         : false,
            //     errorType       : '',
            //     isTimed         : true,
            //     timedDuration   : 600,
            //     items : [

            //         {
            //             "call":"heading",
            //             "text":"Message" 
            //         },
            //         {
            //             "call":"span",
            //             //"text":"Update was Successfull!!" 
            //             "text":"Dynamic Navigation Is Set"
            //         }
                    
            //     ]

            // });


            //-----------------------------------------


            //-------------------------------------
            // setNavigation
            //-------------------------------------

            if(eff.settings)
            {
                if(eff.settings.headerNav)
                {
                    //console.log(JSON.stringify(eff.settings.headerNav,null,2))

                    if(Object.prototype.toString.call(eff.settings.headerNav) === '[object Array]')
                    {
                        var i;
                        var array = [];
                        var len;
                    
                        len = eff.settings.headerNav.length;
                        if(len > 4) len = 4;
                    
                        for(i=0; i < len; i++)
                        {
                            array.push(eff.settings.headerNav[i]);

                        }
                        setNavigation(array);
                        
                    }

                    //setNavigation(eff.settings.headerNav);

                }

            }




        }



        // -- cause -- //

        if(!initNavigation && zoneObj.settings !== undefined)
        {
            effectNavigation({

                props:props,
                settings:zoneObj.settings,
        
            });
            setInitNavigation(true);

        }

    


    },
    [
        props,
        zoneObj,
        isLoaded,
        initNavigation,
        navigation

    ])







    //==================================
    // state/effect - navbar
    //==================================

    const [navbarThreshold, setNavbarThreshold] = useState(false);
    const [navbarStyl, setNavbarStyl] = useState({

        left:false

    });


    useEffect(() => {


        // -- effect -- //
 
        const effectNavbar = (eff) => {


            //console.log("Content: eff "+JSON.stringify(eff,null,2));

            //-------------------------------------
            // message
            //-------------------------------------

            // eff.props.databind.primeProps.handle({

            //     call            : 'showMessngr',
            //     isError         : false,
            //     errorType       : '',
            //     isTimed         : true,
            //     timedDuration   : 600,
            //     items : [

            //         {
            //             "call":"heading",
            //             "text":"Message" 
            //         },
            //         {
            //             "call":"span",
            //             //"text":"Update was Successfull!!" 
            //             "text":"Dynamic Navigation"
            //         }
                    
            //     ]

            // });


   
            //-----------------------------------------

            if(eff.threshold && eff.threshold !== undefined)
            {
                var minusNum;
                minusNum = eff.navbar.current.offsetWidth;
                if(minusNum < 500) minusNum = 500;
                
                setNavbarStyl({

                    //left: "calc(100% - "+ (0) + "px)"
                    left: "-" + (minusNum*2) + "px"
        
                });
              

            }else{

                setNavbarStyl({

                    left: "calc(50% - "+eff.navbar.current.offsetWidth/2 + "px)"
        
                });
             
            }



        }



        // -- cause -- //

        var threshold;

        if(navigation.length > 0)
        {
            if(!navbarStyl.left)
            {
                if(refMain.current.offsetWidth > 100)
                {
                    if(width < 1200 && !navbarThreshold)
                    {
                        threshold = true;
                    }else{
                        threshold = false;
                    }
                    effectNavbar({

                        props:props,
                        main:refMain,       
                        navbar:refNavbar,
                        threshold:threshold
                    
                    });
                    setNavbarThreshold(threshold);


                }
            
            }

        }



        // -- cause by width -- //

        if(width > 1199 && navbarThreshold) 
        {

            effectNavbar({

                props:props,
                main:refMain,       
                navbar:refNavbar,
                threshold:false

            });
            setNavbarThreshold(false);


        }
        if(width < 1200 && !navbarThreshold)
        {
            //hide navbar
            if(refMain.current.offsetWidth > 100)
            {
                effectNavbar({

                    props:props,
                    main:refMain,       
                    navbar:refNavbar,
                    threshold:true
    
                });
                setNavbarThreshold(true);

            }

        }








    },
    [
  
        props,
        width,
        //zoneObj,
        isLoaded,

        initNavigation,
        navigation,

        navbarThreshold,
        navbarStyl,

        refMain,
        refNavbar
  
    ])







    if(!isLoaded)
    {


        return (


            <Header
            height={headerStyl.height}
            >


                {
                props.databind.scrollAtTop
                ?
                    <HeaderBg
                                    
                        backgroundColor={headerStyl.backgroundColor}
                        opacity={"0"}
                    
                    ></HeaderBg>

                :
                    <HeaderBg
                    
                        backgroundColor={headerStyl.backgroundColor}
                        opacity={"1"}

                    ></HeaderBg>

                }





                <HeaderMain
                ref={refMain}
                >

                    <Link 
                    to={""}
                    >
                        {
                        headerGrfx
                        ?

                            <HeaderLogo
                            ref={refLogo}
                            width={headerGrfx.logo.w + "px"}
                            height={headerGrfx.logo.h + "px"}
                            margin={logoStyl.margin}
                            >

                                <div>

                                    <img 
                                        src={headerGrfx.logo.src}
                                        w={headerGrfx.logo.w} 
                                        h={headerGrfx.logo.h}
                                        alt="logo" 
                                        // style={{
                                        //     width:headerGrfx.logo.w + "px",
                                        //     height:headerGrfx.logo.h + "px"
                                        // }} 
                                            
                                    />

                                </div>

                            </HeaderLogo>

                        :
                    
                            <HeaderLogo
                            ref={refLogo}
                            >
                            </HeaderLogo>
                        }
                        

                    </Link>



                    <NavBar
                    ref={refNavbar}        
                    left={navbarStyl.left}
                    >




                        <div className="Button">
                            
                            <div className="Effect">Home</div>
                            <div className="Text">Home</div>
                 
                        </div>

                        <div className="Button">
                    
                            <div className="Effect">Shop</div>
                            <div className="Text">Shop</div>
          
                        </div>


                    </NavBar>



                    <Hamburger
                    ref={refBurger}
                    margin={hamburgerStyl.margin}
                    >

                        <div ref={refBun} className='bun'></div>
                        <div className='bun'></div>
                        <div className='bun'></div>

                    </Hamburger>



                </HeaderMain>

            </Header>


        );




    }else{



        return (


            <Header
            height={headerStyl.height}
            >


                {
                props.databind.scrollAtTop
                ?
                    <HeaderBg
                                    
                        backgroundColor={headerStyl.backgroundColor}
                        opacity={"0"}
                    
                    ></HeaderBg>

                :
                    <HeaderBg
                    
                        backgroundColor={headerStyl.backgroundColor}
                        opacity={headerStyl.opacity}

                    ></HeaderBg>

                }




                <HeaderMain
                ref={refMain}
                >

                    <Link 
                    to={""}
                    >
                        {
                        headerGrfx
                        ?

                            <HeaderLogo
                            ref={refLogo}
                            width={headerGrfx.logo.w + "px"}
                            height={headerGrfx.logo.h + "px"}
                            margin={logoStyl.margin}
                            >

                                <div>

                                    <img 
                                        src={headerGrfx.logo.src}
                                        w={headerGrfx.logo.w} 
                                        h={headerGrfx.logo.h}
                                        alt="logo" 
                                        // style={{
                                        //     width:headerGrfx.logo.w + "px",
                                        //     height:headerGrfx.logo.h + "px"
                                        // }} 
                                            
                                    />

                                </div>

                            </HeaderLogo>

                        :
                    
                            <HeaderLogo
                            ref={refLogo}
                            >
                            </HeaderLogo>
                        }
                        

                    </Link>





                    <NavBar
                    ref={refNavbar}        
                    left={navbarStyl.left}
                    >

                    
                        {
                        navigation.length > 0
                        ?
                            navigation.map((itm, i) => (

                        
                                <Link 
                                key={i}
                                to={itm.to}
                                >
                                    <div className="Button">
                                        
                                        <div className="Effect">{itm.name}</div>
                                        <div className="Text">{itm.name}</div>
                            
                                    </div>
                                    
                                </Link>


                            ))

                        :null
                        }



                        {
                        navigation.length > 0
                        ?
                        
                            <div className="Button"
                                
                                onClick={() => primeHandle({

                                    call:"showMenu",
                                    data:[]

                                })}
                            
                            >
                        
                                <div className="Effect">More..</div>
                                <div className="Text">More..</div>
            
                            </div>


                        :null
                        }


                    </NavBar>




                    <Hamburger
                    ref={refBurger}

                        margin={hamburgerStyl.margin}
                        onClick={() => primeHandle({

                            call:"showMenu",
                            data:[]

                        })}
                    
                    >

                        <div ref={refBun} className='bun'></div>
                        <div className='bun'></div>
                        <div className='bun'></div>

                    </Hamburger>


                </HeaderMain>

            </Header>



        );


    }






}

export default Index;



