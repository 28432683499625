import React, { 

    useState, 
    useEffect, 
    //useRef 

} from 'react';




import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    //useHistory,   
    //useParams,



} from "react-router-dom";

//import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider';
//import useDeviceDetect from 'utils/hooks/DeviceDetect';
// import useKeyPress from 'utils/hooks/KeyPress';




// import { createPortal } from 'react-dom';
// import usePortal from 'utils/hooks/Portal';



// import Navigator from 'utils/components/Content/Navigator';
// import SideNavInfo from 'utils/components/Pages/SceneInfo/Trials/SideNav/SceneInfo';



//=====================================================


// import {

//     //Alfa,
//     //Base,
//     Area,
//     Panel

// } from './Styled';



// import { Lorem } from 'app-components/Prime/Data';


// import CreateMarkup from 'utils/components/Universal/CreateMarkup';
// import CreateLink from 'utils/components/Universal/CreateLink';


import CompAds from './Comp/Ads';
import CompGraphic from './Comp/Graphic';
import CompHowTo from './Comp/HowTo';




const Index = (props) => {



    // -- Window Dimensions -- //
    // const { 
    //     width,
    //     //height 
    // } = useWindowDimensions();


    // -- Device Detect -- //
    //const DeviceDetection = useDeviceDetect();
    //console.log("FFcostfield - DeviceDetection " + JSON.stringify(DeviceDetection));
    //let isMobile = DeviceDetection.isMobile;



    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();

    
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);



    let mainpath;
    mainpath = pathname.split("/")[0];
    
    let subpath;
    subpath = pathname.split("/")[1];
    if(subpath === undefined) subpath = false;

    let sub2path;
    sub2path = pathname.split("/")[2];
    if(sub2path === undefined) sub2path = false;

    // -- redux -- //
    // const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);



    //=============================================
    // ref
    //=============================================

    //const refForm = useRef(null);

    /*
    const rowRefs = [];
    const setRowRef = (ref) => {

        if(ref && ref !== null && ref !== undefined)
        {
            //console.log("ref::::"+ref.id);
            var found;
            found = false;
            for(var i=0; i < rowRefs.length; i++)
            {
                if(rowRefs[i].id === ref.id) found = true;
            }
            if(!found) rowRefs.push(ref);
            console.log(rowRefs.length);
            console.log(rowRefs[0]);

        }

    };
    */



    //=============================================
    // state/effect - initial
    //=============================================

    const [isLoaded, setIsLoaded] = useState(false);
    // const [styling, setStyling] = useState({

    //     backgroundColor:"#141820"

    // });
    // const [panelData, setPanelData] = useState([]);
    // // const [panelSize, setPanelSize] = useState({

    // //     width:"100%",
    // //     height:"auto"

    // // });

    // const [rowClass, setRowClass] = useState("Row");



    useEffect(() => {

        if(!isLoaded)
        {
            setIsLoaded(true);

            //alert(props.panelStatus.call);
        
        }

    },
    [
        props,
        location,
        mainpath,subpath,
        zoneObj,
        isLoaded
    ])











    //const portalTarget = usePortal("scrollbox");
    //const portalTarget = usePortal("side2");


    // return createPortal(<div>
    return (<div>


        {
        

        props.panelStatus.call === 'init'
        ?

            <CompHowTo 

                primeProps={props.primeProps}
                //handle={(v) => handleForm(v)}  
                data={props.panelStatus.data}
                width={props.width}
                refScrollbox={props.refScrollbox}
                refSide2={props.refSide2}

                handlePanel={(v) => props.handlePanel(v)}
                panelStatus={props.panelStatus}

                panelW={props.panelW}
                panelH={props.panelH}

            />




        :

        props.panelStatus.call === 'graphic' || props.panelStatus.call === 'close'
        ?

            <CompGraphic 

     
                primeProps={props.primeProps}
                //handle={(v) => handleForm(v)}  
                data={props.panelStatus.data}
                width={props.width}
                refScrollbox={props.refScrollbox}
                refSide2={props.refSide2}

                handlePanel={(v) => props.handlePanel(v)}
                panelStatus={props.panelStatus}

                panelW={props.panelW}
                panelH={props.panelH}


            />


        :

        props.panelStatus.call === 'ads'
        ?

            <CompAds 

                primeProps={props.primeProps}
                //handle={(v) => handleForm(v)}  
                data={props.panelStatus.data}
                width={props.width}
                refScrollbox={props.refScrollbox}
                refSide2={props.refSide2}

                handlePanel={(v) => props.handlePanel(v)}
                panelStatus={props.panelStatus}

                panelW={props.panelW}
                panelH={props.panelH}
                
            />


        :

        props.panelStatus.call === 'howto'
        ?

            <CompHowTo 

                primeProps={props.primeProps}
                //handle={(v) => handleForm(v)}  
                data={props.panelStatus.data}
                width={props.width}
                refScrollbox={props.refScrollbox}
                refSide2={props.refSide2}

                handlePanel={(v) => props.handlePanel(v)}
                panelStatus={props.panelStatus}

                panelW={props.panelW}
                panelH={props.panelH}

            />


        :


            // <CompGraphic 

        
            //     primeProps={props.primeProps}
            //     //handle={(v) => handleForm(v)}  
            //     data={props.panelStatus.data}
            //     width={props.width}
            //     refScrollbox={props.refScrollbox}
            //     refSide2={props.refSide2}

            //     handlePanel={(v) => props.handlePanel(v)}
            //     panelStatus={props.panelStatus}

            //     panelW={props.panelW}
            //     panelH={props.panelH}


            // />


            <CompHowTo 

                primeProps={props.primeProps}
                //handle={(v) => handleForm(v)}  
                data={props.panelStatus.data}
                width={props.width}
                refScrollbox={props.refScrollbox}
                refSide2={props.refSide2}

                handlePanel={(v) => props.handlePanel(v)}
                panelStatus={props.panelStatus}

                panelW={props.panelW}
                panelH={props.panelH}

            />

        
        }



    </div>);
    // </div>, portalTarget);





}

export default Index;


