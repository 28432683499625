// import { 
//     useSelector, 
//     //useDispatch 
// } 
// from 'react-redux';
// //import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';



// import { Settings } from 'app-components/Prime/Data.js';
// import ApiResources from 'utils/components/Pages/ApiResources';


const SceneInfo = (props) => {



    var R = [];
    //var index;

    var top;
    var bar;   
    var catalogItems;

    // var accountName;
    // var accountValue;



    //#############################################################
    //#############################################################

    top = {

        call:'top',
        name:"Styling",
        controls:[

            // {

            //     name:"Go Back",
            //     // form:{

            //     //     submitCall:'account',
            //     //     postTo:apiDomain+apiCall,
            //     //     data:VPaccount.company,
            //     //     flyr:{
            //     //         type:"form",
            //     //         styl:{
            //     //             maxWidth:475,
            //     //             fieldType:'field'
            //     //         }
            //     //     }
             

            //     // }

            //     hndl:{

            //         call:'readout',
            //         data:false
            //     }

            // }

        ]

    }


    bar = {

        call:'bar',
        items: [

            {

                name:"-"

            },

            {

                name:"-"

            }

        ]
        
    }



    // console.log("Props: "+JSON.stringify(props.goods,null,2));

    // catalogItems = [

    //     {
                
    //         heading:"Glossary",
    //         bullet:false,
    //         stripe:true,
    //         pgraph:"View, Edit or Create Custom Calculators",
    //         image:false,
    //         button:false,
    //         data:{

    //             id:"Glossary",
    //             name:"Glossary"

    //         }
        
    //     },

    //     {
                
    //         heading:"Formulas",
    //         bullet:false,
    //         stripe:true,
    //         pgraph:'View templates or save templates to "My Calculators".',
    //         image:false,
    //         button:false,
    //         data:{

    //             id:"Formulas",
    //             name:"Formulas"

    //         }
        
    //     },

    //     {
                
    //         heading:"Icons",
    //         bullet:false,
    //         stripe:true,
    //         pgraph:'View templates or save templates to "My Calculators".',
    //         image:false,
    //         button:false,
    //         data:{

    //             id:"Icons",
    //             name:"Icons"

    //         }
        
    //     }



    // ];




    var darklite = "dark";

    var iconColor;
    iconColor = "#7e77ad";//purple
    iconColor = "#4cc1bb";//teal
    iconColor = "#454d5f"; // "#c7c9cf"; //"#a2a6af";

    //grays
    iconColor = "#6a707e"; // #7c828f #8f949f 
    //iconColor = "#7c828f";
    //iconColor = "#8f949f";




    catalogItems = [


        {
                
            heading:"Profit Pie",
            bullet:false,
            stripe:true,
            pgraph:"View, Large Look",
            image:false,
            button:false,
            data:{

                id:"LargeLook1",
                name:"ProfitPie",
                graphic:{
                    color:iconColor,
                    darklite:darklite
                }

            }
        
        },


        {
                
            heading:"Gear",
            bullet:false,
            stripe:true,
            pgraph:"View, Large Look",
            image:false,
            button:false,
            data:{

                id:"LargeLook2",
                name:"Gear",
                graphic:{
                    color:iconColor,
                    darklite:darklite
                }

            }
        
        },


        {
                
            heading:"Light Bulb (Dark)",
            bullet:false,
            stripe:true,
            pgraph:"View, Large Look",
            image:false,
            button:false,
            data:{

                id:"LargeLook3",
                name:"Light Bulb ( Dark )",
                graphic:{
                    color:iconColor,
                    darklite:darklite
                }

            }
        
        },



        {
                
            heading:"Light Bulb (Lite)",
            bullet:false,
            stripe:true,
            pgraph:"View, Large Look",
            image:false,
            button:false,
            data:{

                id:"LargeLook4",
                name:"LightBulb (Lite)",
                graphic:{
                    color:iconColor,
                    darklite:darklite
                }

            }
        
        },




        {
                
            heading:"Large Look (Black-N-White)",
            bullet:false,
            stripe:true,
            pgraph:"View, Large Look - Black N White",
            image:false,
            button:false,
            data:{

                id:"LargeLook5",
                name:"Large Look (Black-N-White)",
                graphic:{
                    color:iconColor,
                    darklite:darklite
                }

            }
        
        },








        {
                
            heading:"ColorA",
            bullet:false,
            stripe:true,
            pgraph:"View, Edit or Create Custom Calculators",
            image:false,
            button:false,
            data:{

                id:"ColorA",
                name:"ColorA",
                graphic:{
                    color:iconColor,
                    darklite:darklite
                }

            }
        
        },




        {
                
            heading:"ColorB",
            bullet:false,
            stripe:true,
            pgraph:"View, Edit or Create Custom Calculators",
            image:false,
            button:false,
            data:{

                id:"ColorB",
                name:"ColorB",
                graphic:{
                    color:iconColor,
                    darklite:darklite
                }

            }
        
        },



        {
                
            heading:"ColorC",
            bullet:false,
            stripe:true,
            pgraph:"View, Edit or Create Custom Calculators",
            image:false,
            button:false,
            data:{

                id:"ColorC",
                name:"ColorC",
                graphic:{
                    color:iconColor,
                    darklite:darklite
                }

            }
        
        },






        {
                
            heading:"ColorD",
            bullet:false,
            stripe:true,
            pgraph:"View, Edit or Create Custom Calculators",
            image:false,
            button:false,
            data:{

                id:"ColorD",
                name:"ColorD",
                graphic:{
                    color:iconColor,
                    darklite:darklite
                }

            }
        
        },




    ];



    R.push([top,bar,{

        call:"items",
        items:catalogItems
    
    }]);




    return R;

}



export default SceneInfo;
