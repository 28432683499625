import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';



import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    //useHistory,   
    //useParams,


} from "react-router-dom";


// import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider';
// import useScreenOrientation from 'utils/hooks/ScreenOrientation';
// //import { useFetch }  from 'utils/hooks/Fetch';


import { Area } from 'utils/components/Pages/Styled';
import {

    Container
 
} from 'utils/components/Pages/AuthLogo/SignUpWalk/_Styled';



import { LibString } from 'library';



const Index = (props) => {



    // // -- window dims -- //
    // const { width, height } = useWindowDimensions();

    // // -- screen orientation -- //
    // const screenOrientation = useScreenOrientation()



    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();


    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);




    // let zoneId;
    // zoneId = pathname.replace(/\//gi,"-");


    // -- redux -- //
    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));



    // let currentZone = pathname;
    // currentZone = currentZone.replace(/\//gi,"-");
    // currentZone = currentZone.replace(/ /gi,"-");

    // //let zoneKey = currentZone + "_fetch";
    

    //------------------------------------------------------------------------------

    const refArea = useRef(null);
    const refContainer = useRef(null);


    // -- state -- //

    const [isLoaded, setIsLoaded] = useState(false);
    //const [databind, setDatabind] = useState(false);

    const [details, setDetails] = useState([]);

    useEffect(() => {


        var detailsArray;
        var array,object,string,arr,obj;

        if(!isLoaded)
        {
            setIsLoaded(true);


            //console.log("walkData: "+JSON.stringify(props.walkData,null,2));

            // walkData: {
            //     "Cred": [
            //       {
            //         "call": "header",
            //         "data": {
            //           "text": "Sign Up Walk"
            //         }
            //       },
            //       {
            //         "section": "fields",
            //         "data": [
            //           {
            //             "fieldtype": "text",
            //             "required": true,
            //             "class": "",
            //             "id": "email",
            //             "hint": "Email",
            //             "text": "powerdigitalmedia.com@gmail.com",
            //             "keyboardType": "email",
            //             "autocorrect": "false",
            //             "autocapitalizationType": "none"
            //           },
            //           {
            //             "fieldtype": "password",
            //             "required": true,
            //             "class": "",
            //             "id": "pword",
            //             "hint": "Password",
            //             "text": "van",
            //             "keyboardType": "",
            //             "autocorrect": "false",
            //             "autocapitalizationType": "none"
            //           }
            //         ]
            //       }
            //     ]
            //   }


            detailsArray = [];
            if(props.walkData && props.walkData !== undefined)
            {
                if(props.walkData.Cred && props.walkData.Cred !== undefined)
                {
                    array = props.walkData.Cred;
                    for(object of array)
                    {
                        //alert(object.section);
                        if(object.section === 'fields')
                        {
                            arr = object.data;
                            for(obj of arr)
                            {
                                //alert(obj.id+" "+hndl.data.id);
            
                                //alert(obj.id)

                                if(obj.id === 'pword')
                                {
                                    string = "Password : hidden";
                                    detailsArray.push(string);
                                }else{
                                    string = LibString.CapitalizeWords(obj.id)+" : "+obj.text;
                                    detailsArray.push(string);
                                }
           
                            }//for

                        }//if

                    }//for
                    
                }

                if(props.walkData.Company && props.walkData.Company !== undefined)
                {

                    array = props.walkData.Company;
                    for(object of array)
                    {
                        //alert(object.section);
                        if(object.section === 'fields')
                        {
                            arr = object.data;
                            for(obj of arr)
                            {
                                //alert(obj.id+" "+hndl.data.id);

                                string = LibString.CapitalizeWords(obj.id)+" : "+obj.text;
                                detailsArray.push(string);
                            
                            }//for

                        }//if

                    }//for
                    

                }
            }
            setDetails(detailsArray);

            

        }

        // if(!databind)
        // {
        //     setDatabind(props.databind);
        // }


        // if(props.databind.sceneArr.length > 0)
        // {


        //     console.log("AreaEffect --- props.databind.sceneArr: "+JSON.stringify(props.databind.sceneArr,null,2));
  

        // }




    },
    [
        props,
        isLoaded,
        //databind,
        zoneObj

    ])

    



    

    return (<div>

        <Area
        ref={refArea}
        //backgroundColor={'#191e28'}
        //height={"100vh"}
        >

            <Container
            ref={refContainer} 
            >


                <div className={'Bod'}>

                    <div className={'Heading'}>Review</div>
                    <div className={'Pgraph'}>Please review and submit</div>

                </div>


                <div className={'Bod'}>

                    <div className={'Nav'}>

                        <div className={'NavButton'}

                        // onClick={(v) => props.handle({

                        //     call:"changeSection",
                        //     data:""
                  
    
                        // })}

                        onClick={(v) => props.handle({

                            call:"submit",
                            data:props.sections[parseInt(props.currentSection)+1],
                  
    
                        })}
                        >
                            Submit
                            
                        </div>


                    </div>

                    <div className={'Nav'}>

                        <div className={'NavLink'}
                        onClick={(v) => props.handle({

                            call:"changeSection",
                            data:props.sections[parseInt(props.currentSection)-1]
                            
    
                        })}
                        >
                            {"<< Go Back"}
                            
                        </div>

                    </div>

                </div>



                <div className={'Review'}>

                    {details.map((line,i) => (

                        <div 
                        key={i}
                        className={'Line'}
                        >
                            {line}

                        </div>

                    ))}

                </div>



            </Container>


        </Area>

    </div>)





}

export default Index;


