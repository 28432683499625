

// var ownr_hours_weekly = "";
//var ownr_sti_yearly_direct = 0;
// var ownr_name = "";
// var ownr_sti_monthly_indirect = "0.00";
var ownr_stih_monthly_indirect = "0.00";
var ownr_labor_indirect = "N/A";
// var ownr_hours_indirect_percent_whole = "0.00";
var ownr_labor_percent_direct = "$0.00";
// var ownr_taxes_monthly_direct = "0.00";
// var ownr_ira_monthly_indirect = "0.00";
// var ownr_labor_percent = "0.00";
var ownr_ira_monthly = "$0.00";
// var ownr_vacation_weeks = "0";
// var ownr_ira_percent = "0.00";
// var ownr_sti_monthly_direct = "0.00";
// var ownr_hours_indirect_percent = "0.00";
var ownr_taxes_yearly = "$0.00";
// var ownr_sti_weekly_direct = "0.00";
var ownr_salary_pretax = "$0.00";
var ownr_flag = "";
// var ownr_taxrate_percent = "0.00";
var ownr_health_insurance_yearly = "$0.00";
// var ownr_indirect_hours_yearly = "0.00";
// var ownr_taxrate_reciprocal = "0.00";
// var ownr_salary_pretax_monthly = "0.00";
// var ownr_working_weeks = "0.00";
// var ownr_hours_direct_percent = "0.00";
// var ownr_taxrate_decimal = "0.30";
// var ownr_hours_indirect = "0.0";
// var ownr_sti = "0.00";
// var ownr_taxes_monthly_indirect = "0.00";
var ownr_ira_yearly = "$0.00";
// var ownr_ira_monthly_direct = "0.00";
var ownr_labor_direct = "N/A";
// var ownr_taxes_monthly = "0.00";
var ownr_labor_percent_indirect = "$0.00";
// var ownr_hours_direct_percent_whole = "0.00";
// var ownr_sti_weekly_indirect = "0.00";
// var ownr_billable_hours_weekly = "0.00";
// var ownr_ira_decimal = "0.00";
// var ownr_billable_hours_daily = "0.00";
// var ownr_st = "0.00";
var ownr_health_insurance_monthly = "$0.00";
// var ownr_sti_weekly = "0.00";
// var ownr_hours_direct = "0.0";
// var ownr_sti_hourly_direct_flag = "Okay";
// var ownr_flag_case = "greater";
var ownr_sti_hourly_direct = "$0.00";
// var ownr_salary_overhead = "0.00";
//var ownr_sti_yearly_indirect = 0;


var ownr_direct_hours_weekly = "$0.00";

//var ownr_total_hours_weekly = "0.0";

var ownr_yearly_total = "$0.00";






export const ownr = [



    //-----------------------------------
    // Report Box - Graphic and Ledger
    //-----------------------------------

    {

        "print"		    :'bar',
        "name"		    :'Summary',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"20px"
        },
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'report_box',
        "name"		    :'Report Box',
        "value"		    :[

            {

                boxCall:'graphic',
                boxWidth:"35%",
                boxHeight:200,

                svgCall:"owner",
                svgWidth:200,
                svgHeight:200,

            },

            {
                boxCall:'ledger',
                boxWidth:"65%",
                boxHeight:215,
                value:[


                    {
                        name:"Pretax Salary Yearly",
                        value:ownr_salary_pretax
                    },
        
                    {
                        //name:"Direct Hourly Rate (STI Hourly Direct)",
                        name:"Direct Hourly Rate",
                        value:ownr_sti_hourly_direct
                    },
                    {
                        //name:"Direct Hours per Week (Hours Direct)",
                        name:"Direct Hours Weekly",
                        value:ownr_direct_hours_weekly
        
                    },

                    {
                        name:"Indirect Labor Burden Monthly",
                        value:ownr_stih_monthly_indirect
        
                    }

                ]

            }



        ],
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"0px"
        },
        "required"	    :"",
        "default"	    :""


    },




    //-----------------------------------
    // Report Box - 
    //-----------------------------------

    // {

    //     "print"		    :'bar',
    //     "name"		    :'Hours / Billable',
    //     "value"		    :"",
    //     "required"	    :"",
    //     "default"	    :""


    // },
    // {

    //     "print"		    :'spacer',
    //     "name"		    :'',
    //     "value"		    :{

    //         backgroundColor:"transparent",
    //         height:"20px"
    //     },
    //     "required"	    :"",
    //     "default"	    :""


    // },
    {

        "print"		    :'report_box',
        "name"		    :'Report Box',
        "value"		    :[

            {

                boxCall:'blank',
                boxWidth:"10%",
                boxHeight:75,

                // svgCall:"employee",
                // svgWidth:230,
                // svgHeight:230,

            },

            {
                boxCall:'txt',
                boxWidth:"80%",
                boxHeight:75,
                value:[

                    {

                        // boxCall:'txt',
                        // boxWidth:"100%",
                        // boxHeight:230,
    
                        className:"Note",
                        txt:'***NOTE: '+ownr_flag,
                        style:{
        
                            //fontSize:"24px",
                            textAlign:"center"
        
                        }
    
                    }
                ]

            }



        ],
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"20px"
        },
        "required"	    :"",
        "default"	    :""


    },







    //-----------------------------------
    // BarChart - Direct / Indirect
    //-----------------------------------

    //bar
    {

        "print"		    :'bar',
        "name"		    :'Direct / Indirect',
        "value"		    :"",
        "required"	    :"",
        "default"	    :"",

    },



    //spacer
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"30px"
        },
        "required"	    :"",
        "default"	    :""

    },



    //chart
    {


        "print"		    :'singleBarChart',
        "name"		    :'The Chart',
        "value"		    :[

            {
                chartType:"horizontal",
                chartLines:true,
                data:[

                    {
                        name:'Direct Labor %',
                        value:"2500.00",
                        percent:ownr_labor_percent_direct,
                    },

                    {
                        name:'Indirect Labor %',
                        value:"2500.00",
                        percent:ownr_labor_percent_indirect,
                    }

                ]

            }
        ],
        "required"	    :"",
        "default"	    :"",



    },


    //spacer
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"20px"
        },
        "required"	    :"",
        "default"	    :""

    },







    //-----------------------------------
    // Ledger
    //-----------------------------------

    // {

    //     "print"		    :'bar',
    //     "name"		    :'Job Descriptions',
    //     "value"		    :"",
    //     "required"	    :"",
    //     "default"	    :""


    // },
    {

        "print"		    :'ledger',
        "name"		    :"Ledger",
        "value"		    :[



            {
                name:"Direct",
                value:ownr_labor_direct

            },
            {
                name:"Indirect",
                value:ownr_labor_indirect

            }

        ],
        "required"	    :"",
        "default"	    :""


    },

    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"50px"
        },
        "required"	    :"",
        "default"	    :""


    },

















    //-----------------------------------
    // Ledger
    //-----------------------------------

    {

        "print"		    :'bar',
        "name"		    :'Labor Hours Weekly',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'ledger',
        "name"		    :"Ledger",
        "value"		    :[


            {
                name:"Direct Labor Hours",
                value:"0.0 hrs"
            },

            {
                name:"Indirect Labor Hours",
                value:"0.0 hrs"
            },
            {
                name:"Total Hours",
                value:"0.0 hrs"
            }


        ],
        "required"	    :"",
        "default"	    :""


    },

    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"50px"
        },
        "required"	    :"",
        "default"	    :""


    },








    //-----------------------------------
    // Ledger
    //-----------------------------------

    {

        "print"		    :'bar',
        "name"		    :'Healthcare',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'ledger',
        "name"		    :"Ledger",
        "value"		    :[


            {
                name:"Health Insurance Monthly",
                value:ownr_health_insurance_monthly
            },

        ],
        "required"	    :"",
        "default"	    :""


    },

    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"50px"
        },
        "required"	    :"",
        "default"	    :""


    },




    //-----------------------------------
    // Ledger
    //-----------------------------------

    {

        "print"		    :'bar',
        "name"		    :'IRA',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'ledger',
        "name"		    :"Ledger",
        "value"		    :[


            {
                name:"Contribution Monthly",
                value:ownr_ira_monthly
            },

        ],
        "required"	    :"",
        "default"	    :""


    },

    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"50px"
        },
        "required"	    :"",
        "default"	    :""


    },






    //-----------------------------------
    // Ledger
    //-----------------------------------

    {

        "print"		    :'bar',
        "name"		    :'Vacation / Personal Time',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'ledger',
        "name"		    :"Ledger",
        "value"		    :[


            {
                name:"Weeks",
                value:"2"
            }


        ],
        "required"	    :"",
        "default"	    :""


    },

    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"50px"
        },
        "required"	    :"",
        "default"	    :""


    },






    //-----------------------------------
    // Ledger
    //-----------------------------------

    {

        "print"		    :'bar',
        "name"		    :'Yearly Summary',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'ledger',
        "name"		    :"Ledger",
        "value"		    :[


            {
                name:"Salary",
                value:ownr_salary_pretax
            },
            {
                name:"Health Insurance",
                value:ownr_health_insurance_yearly
            },
            {
                name:"IRA",
                value:ownr_ira_yearly
            },
            {
                name:"Taxes",
                value:ownr_taxes_yearly
            },
            {
                name:"Yearly Total",
                value:ownr_yearly_total

            },





        ],
        "required"	    :"",
        "default"	    :""


    },

    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"50px"
        },
        "required"	    :"",
        "default"	    :""


    },















    // //-----------------------------------
    // // Ledger
    // //-----------------------------------

    // {

    //     "print"		    :'bar',
    //     "name"		    :'Just The Numbers',
    //     "value"		    :"",
    //     "required"	    :"",
    //     "default"	    :""


    // },
    // {

    //     "print"		    :'ledger',
    //     "name"		    :"Ledger",
    //     "value"		    :[


    //         {
    //             name:"Name",
    //             value:"$100.00"
    //         },
    //         {
    //             name:"Pretax Salary Yearly",
    //             value:"$200.00"
    //         },



    //         {
    //             name:"Billable Rate (STI Hourly Direct)",
    //             value:"$300.00"
    //         },
    //         {
    //             name:"Billable Hours per Week (Hours Direct)",
    //             value:"$400.00"

    //         },




    //         {
    //             name:"Direct Labor",
    //             value:"$100.00"
    //         },
    //         {
    //             name:"Direct Labor %",
    //             value:"$200.00"
    //         },
    //         {
    //             name:"Direct Labor Hours per Week",
    //             value:"$300.00"
    //         },



    //         {
    //             name:"Indirect Labor",
    //             value:"$400.00"

    //         },
    //         {
    //             name:"Indirect Labor %",
    //             value:"$400.00"

    //         },
    //         {
    //             name:"Indirect Labor Hours per Week",
    //             value:"$100.00"
    //         },




    //         {
    //             name:"Hours Weekly Total",
    //             value:"$200.00"
    //         },
    //         {
    //             name:"Health Insurance Monthly",
    //             value:"$300.00"
    //         },
    //         {
    //             name:"IRA %",
    //             value:"$400.00"

    //         },




    //         {
    //             name:"Vacation Weeks",
    //             value:"$200.00"
    //         },
    //         {
    //             name:"Yearly Salary w/ Health Ins.",
    //             value:"$300.00"
    //         },
    //         {
    //             name:"Burden Yearly Total",
    //             value:"$400.00"

    //         },









    //     ],
    //     "required"	    :"",
    //     "default"	    :""


    // },

    // {

    //     "print"		    :'spacer',
    //     "name"		    :'',
    //     "value"		    :{

    //         backgroundColor:"transparent",
    //         height:"50px"
    //     },
    //     "required"	    :"",
    //     "default"	    :""


    // },



];




