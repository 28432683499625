
import React, { 


    //useState, 
    useEffect,
    //useRef

} from 'react';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    //useLocation,
    useHistory,   
    //useParams,

} from "react-router-dom";


import { LibForm } from 'library';



import ApiResources from 'utils/components/Pages/ApiResources';


export const FSprocess = ({

    basename,
    formArr,
    handle

}) => {



    var localport = '3001';
    var apiDomain;
    var hostname;
    //var hostname = window.location.hostname;//returns the domain name of the web host
    var protocol = window.location.protocol;//returns the web protocol used (http: or https:)


    if(window.location.hostname.match(/192.168/gi))
    {
        hostname = ApiResources.network[localport].vpc;
        apiDomain = protocol + "//" + hostname + "/";
    }
    else
    if(window.location.hostname.match(/localhost/gi))
    {
        hostname = ApiResources.local[localport].vpc;
        apiDomain = protocol + "//" + hostname + "/";
    }else{
        hostname = ApiResources.aws['x'].vpc;
        apiDomain = "https://" + hostname + "/";
    }//=


    // -- postTo -- //

    //let postTo = apiDomain+"auth-login";
    let postTo = apiDomain+"auth-account-login";




    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    //const location = useLocation();
    const history = useHistory();



    // -- state -- //
    //const [flyrHeight, setFlyrHeight] = useState(0)

    useEffect(() => {
        
        var nv = {}; 
        var okar = [];  
        var ear = [];

        var frm;
        var label;
        var field;
        var array;
        var err;

        var i;
        var j;

        var errMessage;
    

        if(apiDomain.match(/localhost/))
        {
            nv['site'] = apiDomain;
        }else{
            nv['site'] = window.location.hostname;
        }


        //console.log(JSON.stringify(formArr,null,2));
        //alert('submit check');

        for(i=0; i < formArr.length; i++)
        {
            
            frm = formArr[i];

            switch(frm.section)
            { 
            case'header':
            case'headline':
            case'bar':


                okar.push({

                    "section":frm.section,

                    "name":frm.section,
                    "value":frm.data.text

                })

                nv[frm.section] = frm.data.text;


            break; 
            case'checkboxes':
            case'radiobuttons':

                if(frm.data.required)
                {
                    if(frm.data.choice.length === 0)
                    {
                        ear.push({

                            "key"       :i,
                            "section"   :frm.section,
                            "message"   :frm.data.name+" (Please Choose)"
                    
                        });
                    }
                
                }//== if



                okar.push({

                    "section":frm.section,

                    "name":frm.data.name,
                    "value":frm.data.choice.join()

                })

                nv[frm.data.name] = frm.data.choice.join();



            break;
            case'slider':
            case'dualslider':

                if(frm.data.required)
                {
                   
                    if(frm.data.value < 0 || frm.data.value === ''
                    )
                    {
                        switch(frm.section)
                        {
                        case'dualslider': 
                            errMessage = frm.data.info.title + "/" + frm.data.info.titleB + "(Bad Value: "+frm.data.value+")" 
                        break;
                        default:
                            errMessage = frm.data.info.title + "(Bad Value: "+frm.data.value+")" 
                        }

                        ear.push({

                            "key"       :i,
                            "section"   :frm.section,
                            "message"   :errMessage
                    
                        });

                    }

                
                }//== if


                okar.push({

                    "section":frm.section,

                    "name":frm.data.id,
                    "value":frm.data.value

                })

                nv[frm.data.id] = frm.data.value;




            break;
            case'fields':


            
                array = frm.data;
                for(j=0; j < array.length; j++)
                {

                
                    field = array[j];
                    if(field.required)
                    {

                        //console.log(field.text+" === "+field.hint);


                        if(field.text === ''
                        || field.text === field.hint
                        || field.text === undefined
                        )
                        {

                            ear.push({

                                "key"       :i+"-"+j,
                                "section"   :frm.section,
                                "message"   :field.hint+" (Required)"
                        
                            });


                        }//== if
                        else
                        {

                            if(field.id.match(/email/gi)
                            )
                            {
                                err = LibForm.Validate_Email(field.text,field.hint)
                                if(err)
                                {
                                    ear.push({

                                        "key"       :i+"-"+j,
                                        "section"   :frm.section,
                                        "message"   :field.hint+" (Invalid)"
                                
                                    });
                                    
                                }
                            
                            }

                        }

                    }//== if




                    okar.push({

                        "section":"field",

                        "fieldtype":field.fieldtype,
                        "hint":field.hint, 

                        "name":field.id,
                        "value":field.text

                    })

                    nv[field.id] = field.text;

                }//== j


            break;
            case'labelfields':

                //console.log(JSON.stringify(frm.data,null,2));
                //alert("Check frm.data");
            
                array = frm.data;
                for(j=0; j < array.length; j++)
                {

            
                    label = array[j].label.text;
                    field = array[j].field;
                    if(field.required)
                    {
                        //console.log(field.text+" === "+field.hint);

                    
                        if(field.text === ''
                        || field.text === field.hint
                        || field.text === undefined
                        )
                        {
                            ear.push({

                                "key"       :i+"-"+j,
                                "section"   :frm.section,
                                "message"   :label+" (Required)"
                        
                            });
                        }

                    }//== if


                    okar.push({

                        "section":"labelfield",

                        "label":label,

                        "fieldtype":field.fieldtype,
                        "hint":field.hint, 

                        "name":field.id,
                        "value":field.text

                    })


                    nv[field.id] = field.text;


                }//== j



            break;
            default:
            break;
            }//==


        }//== i




        //console.log("OK ARR: "+JSON.stringify(okar,null,2));

        handle({call:"clear"}); //throws off messaging on Auth forms

        if(ear.length > 0)
        {
            //console.log("ERROR ARRAY: "+JSON.stringify(ear,null,2));
            //alert('hold');

            handle({

                call    :"error",
                type    :"input",
                errors  :ear

            });

        }
        else
        {


            //alert('POST TO: '+postTo);

            var skip = false;
            var defUrl = apiDomain+"form-submit";
            var ajaxUrl;
            ajaxUrl = defUrl;
            if(!postTo || postTo !== undefined) ajaxUrl = postTo;
            if(ajaxUrl === defUrl)
            {
                //alert("Using Default Url");
                skip = true;

            }//==


            //alert(ajaxUrl);
        
    
            if(skip)
            {


                console.log("VARS: "+JSON.stringify(nv,null,2));

                handle({

                    call       :"error",
                    type       :'process',
                    errors     :[{

                        "key"       :"0-process",
                        "section"   :"process",
                        "message"   :"Missing Valid Url"
    
                    }]
        
                });
                


            }else{

              
                var browserStore; 

                // -- get current browserStore -- //

                if(!localStorage.browserStore 
                || localStorage.browserStore === undefined)
                {
                    browserStore = {};
                }else{
                    browserStore = localStorage.browserStore;
                }
                if(Object.prototype.toString.call(browserStore) === '[object Object]'
                || Object.prototype.toString.call(browserStore) === '[object Array]'
                )
                {
                    browserStore = JSON.stringify(browserStore);
                }

                //alert(JSON.stringify(browserStore,null,2))

                var _VARS = JSON.stringify(nv);

                var splitter = "-|AJXPST|-"

                var amp = "&";

                var ajaxPost = ""
                + "ajaxArr[]=case" + splitter + "login" + amp
                + "ajaxArr[]=browserStore" + splitter + browserStore + amp
                + "ajaxArr[]=props" + splitter + _VARS

                fetch(ajaxUrl , {

                    method:"POST",
                    headers: new Headers({

                        'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type

                    }),
                    //body: "param1=value1&param2=value2" // <-- Post parameters

                    body: ajaxPost  // <-- Post parameters ajaxArr[]

                    //CORS
                    //mode:"no-cors", //will result in an opaque response - fix is to set cors on express
                    
                })

                
                .then(res => res.json())
                //.then((res) => {console.log(res.json())})
                .then(
                    (result) => {

                        console.log("Result: "+JSON.stringify(result,null,2));

                        var resp;
                        var pwordHash;
                        var isValid = false;
                        var errorMessage = "Authentication Failed: Unknown Error";

                        if(result.success)
                        {
                            resp = result.result;
                            if(!resp.authUser.error)
                            {//No Error
            
                                pwordHash = resp.pwordHash;
                                console.log("PWORD COMPARE: "+pwordHash.result.compare);
            
                                if(pwordHash.result.compare 
                                && pwordHash.result.compare !== undefined
                                )
                                {//add to browserStore
        

                                    //accessEmail
                                    if(resp.accessEmail && resp.accessEmail !== undefined)
                                    {
                                        localStorage.accessEmail = resp.accessEmail;
                                    }


                                    
                                    //before - check
                                    console.log("Before")
                                    if(localStorage.accessToken === resp.accessToken)
                                    {
                                        console.log("Access Tokens Match");
                                    }else{
                                        console.log("Access Tokens don't match");

                                    }
                                    if(localStorage.refreshToken === resp.refreshToken)
                                    {
                                        console.log("Refresh Tokens Match");
                                    }else{
                                        console.log("Refresh Tokens don't match");
                                    }



                                    //update
                                    if(resp.accessToken && resp.accessToken !== undefined)
                                    {
                                        localStorage.accessToken = resp.accessToken;
                                    }//==
            
                                    if(resp.refreshToken && resp.refreshToken !== undefined)
                                    {
                                        localStorage.refreshToken = resp.refreshToken;
                                    }//==  



                                    //after - check
                                    console.log('After')
                                    if(localStorage.accessToken === resp.accessToken)
                                    {
                                        console.log("Access Tokens Match");
                                    }else{
                                        console.log("Access Tokens don't match");

                                    }
                                    if(localStorage.refreshToken === resp.refreshToken)
                                    {
                                        console.log("Refresh Tokens Match");
                                    }else{
                                        console.log("Refresh Tokens don't match");
                                    }



                                    //var del = Delete_BrowserStore(['accessToken','refreshToken','token']);//keys
                                    //console.log(del);
            
                                    //var browserStore = Get_BrowserStore();
                                    //console.log("BROWSER STORE: "+JSON.stringify(browserStore));
                            

                                    isValid = true;


                                }else{

                                    errorMessage = "Authentication Failed: Check Email and Password";
                                }

                        
                            }else{

                                errorMessage = "Authentication Failed: No User Found";
                            }

                        }
                        else
                        {

                            errorMessage = "Authentication Failed: No result.success";

                        }

                        

                        if(isValid)
                        {

                            // -- complete -- //

                            // handle({

                            //     call       :"complete",
                            //     message    :"SUCCESS: Process was successfull!!",
                            //     //to         :"AuthArea"
                    
                            // });
                            

                            // -- change location -- //

                            setTimeout(function(){

                                var url = '';
                                //var url = "/";
                                //var url = basename;
                                //var url = basename + "AuthArea";
                                //var url = "/templates/AuthArea";
                                //history.replace(url);
                                //history.push(url);
                                //history.goBack();


                                url = window.location.protocol + "//";

                                if(window.location.hostname.match(/192.168/gi)
                                || window.location.hostname.match(/localhost/gi)
                                )
                                {
                                    url = url + window.location.hostname +":"+ window.location.port;
                                }else{
                                    url = url + window.location.hostname;
                                }//=

                                url = url + basename;
                                //alert("url:"+url);
                                window.location.replace(url);//regular dom


                            },500)


                        }else{

                            handle({

                                call:"error",
                                type:'process',
                                errors:[{

                                    "key"       :"0-process",
                                    "section"   :"process",
                                    "message"   :errorMessage
                
                                }],
                                message:errorMessage

                            });



                            // -- change location -- //

                            setTimeout(function(){

                                var url = '';
                                //var url = "/";
                                //var url = basename;
                                //var url = basename + "AuthArea";
                                //var url = "/templates/AuthArea";
                                //history.replace(url);
                                //history.push(url);
                                //history.goBack();


                                // url = window.location.protocol + "//";

                                // if(window.location.hostname.match(/192.168/gi)
                                // || window.location.hostname.match(/localhost/gi)
                                // )
                                // {
                                //     url = url + window.location.hostname +":"+ window.location.port;
                                // }else{
                                //     url = url + window.location.hostname;
                                // }//=

                                // url = url + "/ForgotPassword";
                                //alert("url:"+url);
                                //window.location.replace(url);//regular dom


                                //url = "/ForgotPassword";
                                url = "/AccountStatus";
                                history.push(url);

                

                            },2100)



                        }


  



                    },
                    (error) => {


                        console.log("ERROR: "+error);
            
                        handle({

                            call:"error",
                            type       :'process',
                            errors     :[{

                                "key"       :"0-process",
                                "section"   :"process",
                                "message"   :error.message
            
                            }],
                            message:error.message


                        });




                    }


                )



            }//skip




        
        }//==


   
    },[
        apiDomain,
        history,
        basename,
        postTo,
        formArr,
        handle
    ])


    return <div></div>


}



