

const VPRownrFilter = {

    "name"     : "Owner Filter",
    "value"    :{

        
        "ownr_name": {

            "print"		:'standard',
            "name"		:'Name',
            "value"		:"TEMP NAME",
            "required"	:true,
            "default"	:""
        },




        // -- time -- //


        "ownr_hours_weekly": {

            "print"		:'standard',
            "name"		:'Hours Weekly',
            "value"		:"40",
            "required"	:true,
            "default"	:""
        },
        "ownr_working_weeks": {

            "print"		:'standard',
            "name"		:'Working Weeks',
            "value"		:"40",
            "required"	:true,
            "default"	:""
        },
        "ownr_working_months": {

            "print"		:'standard',
            "name"		:'Working Months',
            "value"		:"12",
            "required"	:true,
            "default"	:""
        },
        "ownr_vacation_weeks": {

            "print"		:'standard',
            "name"		:'Vacation Weeks',
            "value"		:"2",
            "required"	:true,
            "default"	:""
        },
        "ownr_indirect_hours_yearly": {

            "print"		:'standard',
            "name"		:'Hours Yearly (Indirect)',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },





        // -- billable -- //

        "ownr_billable_hours_weekly": {

            "print"		:'standard',
            "name"		:'Billable Hours Weekly',
            "value"		:"40",
            "required"	:true,
            "default"	:""
        },

        "ownr_billable_hours_daily": {

            "print"		:'standard',
            "name"		:'Billable Hours Daily',
            "value"		:"40",
            "required"	:true,
            "default"	:""
        },

        "ownr_billable_hours_monthly": {

            "print"		:'standard',
            "name"		:'Billable Hours Monthly',
            "value"		:"40",
            "required"	:true,
            "default"	:""
        },


        "ownr_billable_hours_yearly": {

            "print"		:'standard',
            "name"		:'Billable Hours Yearly',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },






        // -- labor -- //

        "ownr_labor_direct": {

            "print"		:'standard',
            "name"		:'Labor Direct',
            "value"		:"0",
            "required"	:true,
            "default"	:""
        },

        "ownr_labor_indirect": {

            "print"		:'standard',
            "name"		:'Labor Indirect',
            "value"		:"0",
            "required"	:true,
            "default"	:""
        },



        // -- labor percent -- //

        "ownr_labor_percent": {

            "print"		:'percent',
            "name"		:'Labor %',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "ownr_labor_percent_direct": {

            "print"		:'percent',
            "name"		:'Direct Labor %',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "ownr_labor_percent_indirect": {

            "print"		:'percent',
            "name"		:'Indirect Labor %',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },





        // -- hours -- //

        "ownr_hours_direct": {

            "print"		:'standard',
            "name"		:'Hours Direct',
            "value"		:"0",
            "required"	:true,
            "default"	:""
        },

        "ownr_hours_indirect": {

            "print"		:'standard',
            "name"		:'Hours Indirect',
            "value"		:"0",
            "required"	:true,
            "default"	:""
        },



        // -- hours -- //

        "ownr_hours_direct_percent": {

            "print"		:'percent',
            "name"		:'Hours Direct %',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "ownr_hours_direct_percent_whole": {

            "print"		:'percent',
            "name"		:'Hours Direct Whole %',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },


        "ownr_hours_indirect_percent": {

            "print"		:'percent',
            "name"		:'Hours Indirect %',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "ownr_hours_indirect_percent_whole": {

            "print"		:'percent',
            "name"		:'Hours Indirect Whole %',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },





        // -- salary -- //


        "ownr_salary_pretax": {

            "print"		:'price',
            "name"		:'Salary Pretax',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "ownr_salary_pretax_monthly": {

            "print"		:'price',
            "name"		:'Salary Pretax Monthly',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "ownr_salary_overhead": {

            "print"		:'price',
            "name"		:'Salary Overhead',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },









        
        "ownr_taxrate_percent": {

            "print"		:'pecent',
            "name"		:'Tax Rate %',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "ownr_taxrate_decimal": {

            "print"		:'standard',
            "name"		:'Tax Rate Decimal',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "ownr_taxrate_reciprocal": {

            "print"		:'standard',
            "name"		:'Tax Rate Reciprocal',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        





        // -- taxes -- //

        "ownr_taxes_yearly": {

            "print"		:'price',
            "name"		:'Taxes Yearly',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "ownr_taxes_monthly": {

            "print"		:'price',
            "name"		:'Taxes Monthly',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "ownr_taxes_monthly_direct": {

            "print"		:'price',
            "name"		:'Taxes Monthly Direct',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "ownr_taxes_monthly_indirect": {

            "print"		:'standard',
            "name"		:'Taxex Monthly Indirect',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },

        





        // -- ira -- //


        "ownr_ira_percent": {

            "print"		:'percent',
            "name"		:'IRA Percent',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "ownr_ira_decimal": {

            "print"		:'standard',
            "name"		:'IRA Decimal',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },


        "ownr_ira_yearly": {

            "print"		:'price',
            "name"		:'IRA Yearly',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "ownr_ira_monthly": {

            "print"		:'price',
            "name"		:'IRA Monthly',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "ownr_ira_monthly_direct": {

            "print"		:'price',
            "name"		:'IRA Monthly Direct',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "ownr_ira_monthly_indirect": {

            "print"		:'price',
            "name"		:'IRA Monthly Indirect',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },




        // -- health ins. -- //
        
        "ownr_health_insurance_yearly": {

            "print"		:'price',
            "name"		:'Health Ins. Yearly',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "ownr_health_insurance_monthly": {

            "print"		:'price',
            "name"		:'Health Ins. Monthly',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },




        // -- flag -- //
        
        "ownr_flag_case": {

            "print"		:'standard',
            "name"		:'Flag Case',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "ownr_flag": {

            "print"		:'standard',
            "name"		:'Owner Flag',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },




        // -- sti -- //
        
        "ownr_sti": {

            "print"		:'price',
            "name"		:'STI',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },


        "ownr_sti_yearly_direct": {

            "print"		:'price',
            "name"		:'STI Yearly Direct',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "ownr_sti_yearly_indirect": {

            "print"		:'price',
            "name"		:'STI Yearly Indirect',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },


        "ownr_sti_monthly_direct": {

            "print"		:'price',
            "name"		:'STI Monthly Direct',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "ownr_sti_monthly_indirect": {

            "print"		:'price',
            "name"		:'STI Monthly Indirect',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },




        "ownr_sti_weekly": {

            "print"		:'price',
            "name"		:'STI Weekly',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "ownr_sti_weekly_direct": {

            "print"		:'price',
            "name"		:'STI Weekly Direct',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "ownr_sti_weekly_indirect": {

            "print"		:'price',
            "name"		:'STI Weekly Indirect',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },




        "ownr_sti_hourly_direct": {

            "print"		:'price',
            "name"		:'STI Hourly Direct',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "ownr_sti_hourly_direct_flag": {

            "print"		:'standard',
            "name"		:'STI Hourly Direct Flag',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },





        "ownr_st": {

            "print"		:'price',
            "name"		:'ST',
            "value"		:"",
            "required"	:true,
            "default"	:""
        }






    }


    
};




export {

    VPRownrFilter
}







/*

"ownr-1": {
    "name": "Thom M",
    "value": {

        "ownr_name": "Thom M",

        "ownr_hours_weekly": "40.00",

        "ownr_working_weeks": "50.00",
        "ownr_vacation_weeks": "2.00",

        "ownr_indirect_hours_yearly": "1680.00",


        "ownr_labor_direct": "Design,Production,Installation",
        "ownr_labor_indirect": "Sales,Business Management,Office",

        "ownr_labor_percent": "80.00",
        "ownr_labor_percent_direct": "80.00",
        "ownr_labor_percent_indirect": "20.00",


        "ownr_hours_direct_percent": "0.80",
        "ownr_hours_direct_percent_whole": "80.00",

        "ownr_hours_indirect_percent": "0.20",
        "ownr_hours_indirect_percent_whole": "20.00",
    



        "ownr_salary_pretax": "50000.00",
        "ownr_salary_pretax_monthly": "4166.67",
        "ownr_salary_overhead": "833.33",


        "ownr_taxrate_percent": "30.00",
        "ownr_taxrate_decimal": "0.30",
        "ownr_taxrate_reciprocal": "0.70",

        "ownr_taxes_yearly": "21428.57",     
        "ownr_taxes_monthly": "1785.71",
        "ownr_taxes_monthly_direct": "1428.57",
        "ownr_taxes_monthly_indirect": "357.14",


        "ownr_ira_yearly": "1000.00",
        "ownr_ira_monthly": "83.33",     
        "ownr_ira_monthly_direct": "66.67",
        "ownr_ira_monthly_indirect": "16.67",
        "ownr_ira_percent": "2.00",
        "ownr_ira_decimal": "0.02",

        "ownr_health_insurance_yearly": "6000.00",
        "ownr_health_insurance_monthly": "500.00",
      


        "ownr_flag_case": "greater",
        "ownr_flag": "<br/>The owner spends the majority of their time performing direct labor.<br/>20% of the owners salary will be considered an Overhead expense..<br/>Graphic Design, Production and Installation rates will use the owners Direct Labor rate.",




        "ownr_sti": "72428.57",  

        "ownr_sti_yearly_direct": "57942.86",
        "ownr_sti_yearly_indirect": "14485.71"

        "ownr_sti_monthly_direct": "4828.57",
        "ownr_sti_monthly_indirect": "1207.14",

        "ownr_sti_weekly": "1448.57",    
        "ownr_sti_weekly_direct": "1158.86",
        "ownr_sti_weekly_indirect": "289.71",

        "ownr_sti_hourly_direct": "36.21",

        "ownr_st": "71428.57",

   




    }
}

*/