export const DIRECT_LABOR_RATES = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 500 500";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	let iconColor = props.iconColor || "rgb(0,0,0)";

	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

			<path 
				fill={iconColor}
				d="M189.188,321.716c7.942-6.91,12.601-15.793,12.715-26.398 c-0.125-12.547-5.314-20.291-15.881-26.664c-6.065-3.547-12.51-6.121-19.154-8.365v72.018 C174.906,330.438,182.754,326.935,189.188,321.716L189.188,321.716z M111.984,143.592c-5.48,5.311-6.983,11.99-7.129,19.414
				c0.135,7.075,1.575,13.422,6.658,18.594c6.167,5.551,13.968,9.078,21.641,12.022v-61.021 C125.539,135.158,118.144,138.325,111.984,143.592L111.984,143.592z M203.052,96.548c10.409,4.773,18.875,11.361,26.684,19.674 c5.02,5.519,9.487,11.531,12.958,18.146c4.014,8.076,6.912,16.537,5.753,25.671c-1.71,9.355-7.685,16.322-16.552,20.479 c-8.374,3.43-17.887,3.347-26.214-0.2c-7.57-3.647-12.123-9.672-15.054-17.419c-2.5-7.303-4.975-13.764-10.374-19.519 c-4.424-4.408-9.389-7.404-15.126-9.815v69.489c11.638,2.987,23.247,6.156,34.637,9.993c12.285,4.193,22.55,9.887,32.5,18.232 c7.992,7.076,14.141,15.104,18.897,24.659c5.205,11.51,7.445,23.342,7.145,35.959c-0.428,10.67-2.709,20.762-6.951,30.564 c-6.058,13.357-14.792,24.281-26.421,33.211c-12.228,9.035-25.753,14.959-40.501,18.537c-6.397,1.467-12.783,2.441-19.305,3.154 v33.74c-0.067,12.299-1.709,25.375-17.619,25.115c-12.495-0.689-15.985-9.588-16.107-20.809v-37.744 c-26.809-3.391-52.326-12.729-71.667-32.17c-6.912-7.215-12.707-14.963-16.884-24.072c-3.446-7.941-5.708-16.146-6.028-24.852 c-0.28-12.9,8.105-23.24,20.093-27.439c9.256-2.758,19.653-2.033,28.018,2.977c4.997,3.393,8.296,7.324,10.429,13.008	c1.932,5.469,3.957,10.898,6.189,16.254c3.375,7.902,6.933,14.055,13.471,19.783c4.951,4.137,10.331,6.721,16.379,8.877v-78.957	c-13.516-3.645-26.837-7.788-39.699-13.353c-18.312-8.187-34.046-20.962-41.794-39.938c-3.531-9.203-4.961-18.687-5.183-28.512
				c-0.137-19.044,4.824-35.703,16.781-50.338c8.177-9.502,17.86-16.464,29.215-21.716c13.06-5.701,26.565-8.542,40.68-10.104v-13.68 c0.3-11.156,4.774-19.178,16.779-19.659c11.907,0.457,16.648,8.041,16.947,19.266v14.059 C178.057,88.643,191.089,91.258,203.052,96.548L203.052,96.548z M326.277,196.84h4.176c6.467,0.663,10.375,4.566,11.033,11.035 v76.476h56.879c6.463,0.662,10.365,4.578,11.023,11.041v4.17c-0.656,6.465-4.559,10.383-11.027,11.033h-68.176 c-9.385-0.375-14.492-5.52-14.949-14.922v-87.8C315.9,201.401,319.805,197.498,326.277,196.84L326.277,196.84z M328.279,432.294 c75.545-1.934,132.959-58.742,134.906-134.828c-1.941-75.875-59.494-132.902-134.91-134.813 c-41.6,1.054-77.724,18.882-102.088,47.391l0.853,0.512l1.12,0.69l1.119,0.708l1.115,0.726l1.097,0.734l1.091,0.747l1.094,0.77 l1.085,0.782l1.075,0.795l1.068,0.808l1.067,0.827l1.056,0.838l1.053,0.854l1.222,1.014l1.821,1.648l1.669,1.585l1.625,1.617 l1.557,1.623c19.337-22.172,47.753-35.942,80.354-36.767c59.719,1.51,105.4,46.46,106.943,106.91 c-1.551,60.699-47.258,105.393-106.947,106.916c-36.396-0.928-67.588-17.91-86.742-44.783l-1.078,1.004l-1.154,1.045l-1.166,1.02 l-1.185,1.012l-1.208,0.998l-1.22,0.98l-1.33,1.033l-1.4,1.023l-1.313,0.932l-1.321,0.91l-1.326,0.885l-1.334,0.867l-1.352,0.852 l-1.352,0.828l-1.356,0.803l-1.364,0.789l-1.38,0.77l-0.785,0.426C243.058,409.599,282.289,431.116,328.279,432.294 L328.279,432.294z"
			/>


		</svg>
		</div>

	);//return
	



}