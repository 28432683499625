import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';



import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    //useHistory,   
    //useParams,


} from "react-router-dom";


// import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider';
// import useScreenOrientation from 'utils/hooks/ScreenOrientation';
// //import { useFetch }  from 'utils/hooks/Fetch';


import { Area } from 'utils/components/Pages/Styled';
import {

    Container
 
} from 'utils/components/Pages/AuthLogo/SignUpWalk/_Styled';


import { LibString } from 'library';



const Index = (props) => {



    // // -- window dims -- //
    // const { width, height } = useWindowDimensions();

    // // -- screen orientation -- //
    // const screenOrientation = useScreenOrientation()



    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();


    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);




    // let zoneId;
    // zoneId = pathname.replace(/\//gi,"-");


    // -- redux -- //
    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));



    // let currentZone = pathname;
    // currentZone = currentZone.replace(/\//gi,"-");
    // currentZone = currentZone.replace(/ /gi,"-");

    // //let zoneKey = currentZone + "_fetch";
    

    //------------------------------------------------------------------------------

    const refArea = useRef(null);
    const refContainer = useRef(null);


    // -- state -- //

    const [isLoaded, setIsLoaded] = useState(false);
    //const [databind, setDatabind] = useState(false);
    const [signUpChoice, setSignUpChoice] = useState(false);
    const [details, setDetails] = useState([]);

    useEffect(() => {


        var detailsArray;
        var keyname;
        var object,string;
        var name,value;

        if(!isLoaded)
        {
            setIsLoaded(true);
            //setDetails([]);

            if(props.walkData && props.walkData !== undefined)
            {

                // ::: set details ::: //

                //console.log("walkData: "+JSON.stringify(props.walkData,null,2));

                detailsArray = [];
                if(props.walkData && props.walkData !== undefined)
                {
                    object = props.walkData;
                    for(keyname in object)
                    {
                        if(keyname !== 'signup_choice')
                        {

                            name = keyname;
                            if(name.match(/-/gi)) name = name.replace(/-/g," ");
                            if(name.match(/_/gi)) name = name.replace(/_/g," ");

                            value = object[keyname];
                            
                            string = LibString.CapitalizeWords(name) + " : " + value
                            detailsArray.push(string);                 
                        }


                    }//for
                    
                }
                setDetails(detailsArray);


                // ::: set signupChoice ::: //

                if(props.walkData.signup_choice) setSignUpChoice(props.walkData.signup_choice);

            
            }


            // ::: marked finished ::: //

            if(!props.walkFinished) props.handle({call:"finish"});



        }// == !isLoaded


        // if(!databind)
        // {
        //     setDatabind(props.databind);
        // }


        // if(props.databind.sceneArr.length > 0)
        // {


        //     console.log("AreaEffect --- props.databind.sceneArr: "+JSON.stringify(props.databind.sceneArr,null,2));
  

        // }


    },
    [
        props,
        isLoaded,
        //databind,
        zoneObj

    ])

    



    

    return (<div>

        <Area
        ref={refArea}
        //backgroundColor={'#191e28'}
        //height={"100vh"}
        >

            <Container
            ref={refContainer} 
            >


                <div className={'Bod'}>

                    <div className={'Heading'}>Sign Up Complete</div>
                    <div className={'Pgraph'}>Thank you for signing up</div>

                </div>


                <div className={'Bod'}>


                    {
                    signUpChoice === 'calc' ?

                        <div className={'Nav'}>

                            <div className={'NavButton'}

                            onClick={(v) => props.handle({

                                call:"url",
                                action:'replace',
                                url:"https://calc.theprofitcalculator.com"
                    
                            })}
                            // onClick={(v) => props.handle({

                            //     call:"submit",
                            //     data:props.sections[parseInt(props.currentSection)+1],
                    
                            // })}
                            >
                                Go To Calculator
                                
                            </div>


                        </div>


                    :
                    signUpChoice === 'app' ?


                        <div className={'Nav'}>

                            <div className={'NavButton'}

                            onClick={(v) => props.handle({

                                call:"url",
                                action:'replace',
                                url:"https://app.theprofitcalculator.com"
                    
                            })}
                            // onClick={(v) => props.handle({

                            //     call:"submit",
                            //     data:props.sections[parseInt(props.currentSection)+1],
                    
                            // })}
                            >
                                Go To App
                                
                            </div>


                        </div>


                    :null
                    }



                    {/* <div className={'Nav'}>

                        <div className={'NavLink'}
                        onClick={(v) => props.handle({

                            call:"changeSection",
                            data:props.sections[parseInt(props.currentSection)-1]
                            
    
                        })}
                        >
                            {"<< Go Back"}
                            
                        </div>

                    </div> */}

                </div>



                <div className={'Review'}>

                    {details.map((line,i) => (

                        <div 
                        key={i}
                        className={'Line'}
                        >
                            {line}

                        </div>

                    ))}

                </div>



            </Container>


        </Area>

    </div>)





}

export default Index;


