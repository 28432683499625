import React from 'react'


const useDeviceDetect = () => {

    const [isMobile, setMobile] = React.useState(false);
    const [userAgent, setUserAgent] = React.useState(false);

    React.useEffect(() => {

        const userAgent = typeof window.navigator === "undefined" ? "" : navigator.userAgent;
        const mobile = Boolean(
            userAgent.match(
                /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
            )
        );
        setMobile(mobile);
        setUserAgent(userAgent);

    }, []);

    return { 
        isMobile: isMobile, 
        userAgent: userAgent
    };
}

export default useDeviceDetect;


//https://medium.com/code-artistry/how-to-create-a-custom-usedevicedetect-react-hook-f5a1bfe64599