const DefaultFetchResponse = {


    "scene":{
        
        "name": "Home",
        "path": "/Users/tm/Work/PROJEX/reaxBuilds/reax1/src/app-components/Zone/Home/",
        "sceneName": "home",
        "sceneInfo": [
            {

                styl:{

                    call:"indent",
                    class:"IndentRow",
        
                    marginTop:"50px",
                    marginBottom:"0"
        
        
                },
                call:'TxtComponent',
                info:{
        
                    call:'flex', //fixed, flex
                    gridType: "TextBox", //Newspaper
                    gridBgColor:'transparent',
                    gridCall: "grid_cell",  //grid_cell or grid_column_cell
                    cellCall: "default",  //container or default 
                    modCall: 'fill_div',
                    thumb: {
        
                        call    : "default", //flex_height or default
                        diff    : 0
        
                    },    
                    cellBorder: {
                        "boolean"       :true,
                        "thickness"     :1,//px
                        "color"         :"gray"  
                    },
                    gutter: {
                        "boolean"   :true,
                        "size"      :20
                    },
                    thresh: {
                        "refWidth"    :false,   //number or false = default screen width
                        "across1"     :501,
                        "across2"     :801,
                        "across3"     :1050
                    }
        
        
        
                },
        
                items:[{
        
                    heading : "Default Page",
                    pgraph  : "This is a default page"
                }]


            }
        
        
        ],
        "sceneSettings": {
            "call": "settings",
            "data": {
                "duration": 8000,
                "fadetime": 4000
            },
            "multi": false
        }

    },



//settings-start
"settings":{
  "appearance": {
    "menu": {
      "menuColor": "white",
      "menuButtonBackgroundColorOver": "#0291cd",
      "menuButtonBackgroundColorOut": "transparent",
      "menuButtonTextColorOver": "#fff",
      "menuButtonTextColorOut": "black",
      "menuTabColor": "red",
      "menuTabTextColor": "white",
      "menuBorder":"2px solid #0291cd"
    },



    // "header": {

    //   "headerCall": "A",
      
    //   "headerMainColor": "#181b21",
    //   "headerMainHeight": "54px",

    //   "navbarColor": "#454f5f",
    //   "navbarHeight": "40px",

    //   "lineColor": "#454d5f",
    //   "lineHeight": "2px",

    //   "buttonBackgroundColorOver": "#818692",
    //   "buttonBackgroundColorOut": "transparent",
      
    //   "buttonTextColorOver":"#fcfcfc",
    //   "buttonTextColorOut": "#fcfcfc",

    //   "buttonBackgroundColorHold":"#0291cd",
    //   "buttonTextColorHold": "#fcfcfc",

    //   "logoColor": "black",
    //   "logoWidth": "260px",
    //   "logoHeight": "40px",
    //   "logoMinWidth": "40px",

    //   "hamburgerColorOver": "green",
    //   "hamburgerColorOut": "#fcfcfc"

    // },






    // "header": {

    //   "headerCall": "B",
      
    //   "headerMainColor": "#fff",
    //   "headerMainHeight": "54px",

    //   "navbarColor": "#181b21",
    //   "navbarHeight": "40px",

    //   "lineColor": "#181b21",
    //   "lineHeight": "2px",

    //   "buttonBackgroundColorOver": "#818692",
    //   "buttonBackgroundColorOut": "transparent",
      
    //   "buttonTextColorOver":"#fcfcfc",
    //   "buttonTextColorOut": "#fcfcfc",

    //   "buttonBackgroundColorHold":"#0291cd",
    //   "buttonTextColorHold": "#00ff00",

    //   "logoColor": "black",
    //   "logoWidth": "260px",
    //   "logoHeight": "40px",
    //   "logoMinWidth": "40px",

    //   "hamburgerColorOver": "green",
    //   "hamburgerColorOut": "#fcfcfc"

    // },




    "header": {

      "headerCall": "C",
      "headerOpacity":"0.9",
      
      "headerMainColor": "#001f36",
      "headerMainHeight": "75px",

      "navbarColor": "#181b21",
      "navbarHeight": "40px",

      "lineColor": "#181b21",
      "lineHeight": "2px",

      "buttonBackgroundColorOver": "#818692",
      "buttonBackgroundColorOut": "transparent",
      
      "buttonTextColorOver":"#fcfcfc",
      "buttonTextColorOut": "#fcfcfc",

      "buttonBackgroundColorHold":"#0291cd",
      "buttonTextColorHold": "#00ff00",

      "logoColor": "black",
      "logoWidth": "260px",
      "logoHeight": "40px",
      "logoMinWidth": "40px",

      "hamburgerColorOver": "green",
      "hamburgerColorOut": "#fcfcfc"

    },




    "general": {
      "buttonBackgroundOverColor": "red",
      "buttonBackgroundOutColor": "blue",
      "buttonBorderOverColor": "red",
      "buttonBorderOutColor": "blue",
      "buttonTextOverColor": "black",
      "buttonTextOutColor": "white",
      "linkOver": "orange",
      "linkOut": "#00FF000"
    },




    "headings":{

      "h1":{

        "fontFamily": 'Arial, Helvetica, sans-serif',
        "fontSize": "50px",
        "fontWeight": "600",
        "textAlign": "left",
        "textDecoration": "none",
        "color": "black"
    
      },

      "h2":{
        "fontFamily": 'Arial, Helvetica, sans-serif',
        "fontSize": "40px",
        "fontWeight": "600",
        "textAlign": "left",
        "textDecoration": "none",
        "color": "black"
      },

      "h3":{
        "fontFamily": 'Arial, Helvetica, sans-serif',
        "fontSize": "32px",
        "fontWeight": "600",
        "textAlign": "left",
        "textDecoration": "none",
        "color": "black"
      },

      "h4":{
        "fontFamily": 'Arial, Helvetica, sans-serif',
        "fontSize": "24px",
        "fontWeight": "600",
        "textAlign": "left",
        "textDecoration": "none",
        "color": "black"
      },

      "h5":{
        "fontFamily": 'Arial, Helvetica, sans-serif',
        "fontSize": "20px",
        "fontWeight": "600",
        "textAlign": "left",
        "textDecoration": "none",
        "color": "black"
      }


    },

    "pgraphs":{

      "p1":{
        "fontFamily": 'Arial, Helvetica, sans-serif',
        "fontSize": "20px",
        "fontWeight": "500",
        "textAlign": "left",
        "textDecoration": "none",
        "color": "black"
      },

      "p2":{
        "fontFamily": 'Arial, Helvetica, sans-serif',
        "fontSize": "18px",
        "fontWeight": "500",
        "textAlign": "left",
        "textDecoration": "none",
        "color": "black"
      },


      "p3":{
        "fontFamily": 'Arial, Helvetica, sans-serif',
        "fontSize": "16px",
        "fontWeight": "500",
        "textAlign": "left",
        "textDecoration": "none",
        "color": "black"
      },

      "p4":{
        "fontFamily": 'Arial, Helvetica, sans-serif',
        "fontSize": "14px",
        "fontWeight": "500",
        "textAlign": "left",
        "textDecoration": "none",
        "color": "black"
      },

      "p5":{
        "fontFamily": 'Arial, Helvetica, sans-serif',
        "fontSize": "12px",
        "fontWeight": "500",
        "textAlign": "left",
        "textDecoration": "none",
        "color": "black"
      }


    }


    


  },
  "graphic": {
    "logo1": {
      "image": {
        "h": "40",
        "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/PowerDigitalMedia/pdm_topbar7_sys20200408232671id_.png",
        "w": "260"
      },
      "id": "20200408232671",
      "text": "",
      "title": "pdm_topbar7",
      "bgColor": "black"
    },
    "icon": {
      "image": {
        "h": "250",
        "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/PowerDigitalMedia/powerbutton_250flat_sys20200414118543id_.png",
        "w": "250"
      },
      "id": "20200414118543",
      "text": "",
      "title": "powerbutton_250flat",
      "bgColor": "black"
    },


    // "header": {
    //   "image": {
    //     "h": "40",
    //     "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/PowerDigitalMedia/pdm_topbar7_sys20200408232671id_.png",
    //     "w": "260"
    //   },
    //   "id": "20200408232671",
    //   "text": "",
    //   "title": "pdm_topbar7",
    //   "bgColor": "black"
    // }



    // "header": {
    //   "image": {
    //     "h": "40",
    //     "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/PowerDigitalMedia/PowerDigitalMedia-Logo-Header-Style-White_Blue_sys20210325120700id_.png",
    //     "w": "260"
    //   },
    //   "id": "20210325120700",
    //   "text": "",
    //   "title": "PowerDigitalMedia-Logo-Header-Style-White_Blue",
    //   "bgColor": "black"

    // }


    "header": {
      "image": {
        "h": "40",
        "src": "https://powerdigitalmedia-net.s3.amazonaws.com/admin/inventory/img/PowerDigitalMedia/pdm_topbar_header_white_blue_sys20210326100313id_.png",
        "w": "260"
      },
      "id": "20210326100313",
      "text": "",
      "title": "pdm_topbar_header_white_blue",
      "bgColor": "black"

    }
    


    



  },


  "headerNav": [
    {
      "name": "Home",
      "to": "",
      "descr": "This link will go to Home"
    },
    {
      "name": "TrialPage",
      "to": "TrialPage",
      "descr": "This link will go to Text1"
    },
    {
      "name": "SVG",
      "to": "TrialPageSVG",
      "descr": "This link will go to Billboards"
    },
    {
      "name": "SlideShow",
      "to": "TrialPageSlideShow",
      "descr": "This link will go to Video1"
    },
    {
      "name": "FilmStrip 1",
      "to": "FilmStrip1",
      "descr": "This link will go to FilmStrip1"
    },
    {
      "name": "Collage 1",
      "to": "Collage1",
      "descr": "This link will go to Collage1"
    }
  ]


  // "headerNav": [
  //   {
  //     "name": "Home",
  //     "to": "",
  //     "descr": "This link will go to Home"
  //   },
  //   {
  //     "name": "Shop",
  //     "to": "TrialPage",
  //     "descr": "This link will go to TrialPage"
  //   }

  // ]



}
//settings-end








}

export default DefaultFetchResponse;





/*

admin/inventory/img/PowerDigitalMedia/


*/

