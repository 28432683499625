
import React, { 

    useState, 
    useEffect,
    useRef

} from 'react';






//import { useWindowDimensions } from 'utils/WindowDimensionsProvider';

//import 'app-style/alfabase.css';//alfa,base,tab,tab-x,flank

import { Alfa, Base } from 'app-styled/AlfaBase';
import { Box } from './Styled';


import FormComponent from 'utils/components/Form';

//import FormBuild from './FormBuild';
import FormComposer from './FormComposer';


import { LibElem } from 'library';

const Index = (props) => {



    // -- window dims -- //
    //const { width, height } = useWindowDimensions();


    // -- ref -- //

    //const baseRef = useRef(null)
    //const tabRef = useRef(null)
    const flyrRef = useRef(null)    


    // -- state -- //
    const [highZ, setHighZ] = useState(0)

    useEffect(() => {
        
        //setFlyrHeight(flyrRef.current.offsetHeight);
        if(highZ === 0)
        {
            var z = LibElem.HighestZ();
            setHighZ(z)

        }

    }, [highZ])




    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);
    const [formArr, setFormArr] = useState([]);
    const [problemArr, setProblemArr] = useState([]);

    useEffect(() => {


        if(!isLoaded)
        {

            var data = {

                "name": "Login",
                "value": {
                    "email": {
                        "print": "standard",
                        "name": "Email",
                        "value": '',
                        "required": true,
                        "default": ''
                    },
                    "pword": {
                        "print": "password",
                        "name": "Password",
                        "value": '',
                        "required": true,
                        "default": ''
                    }
            
                }
            
            };

            var styl = {

                "maxWidth": 350,
                "fieldType": "field"

            };


            var composed = FormComposer({

                data:data,
                styl:styl

            });

            //console.log("COMPOSED: "+JSON.stringify(composed,null,2));
            //alert('isLoaded');

            setIsLoaded(true);

            if(formArr.length === 0)
            {
                var newArr = [];
                var frm;
        
                for(var i=0; i < composed.length; i++)
                {
                    frm = composed[i];
                    newArr.push(frm);

                }//== i

                //console.log("New Arr: "+newArr);
                setFormArr(newArr);
                setProblemArr([]);


                //console.log("COMPOSED: "+JSON.stringify(newArr,null,2));
                //alert('no formArr');
        
            }


        }


    },[

        props,

        //dispatch,
        isLoaded,     
        //refA,

        //location,
        //hashData,
        //formRef,
        formArr,
        problemArr,
        //zoneObj


    ])








    /*


    // -- handle -- //


    const handle = (hndl) => {

        //console.log(hndl);


        hndl['zoneObj'] = zoneObj;
        hndl['handleState'] = (v) => {

            //if(v.call === 'handleDispatch') handleDispatch(v.value);
            if(v.call === 'handleCloseFlyr') handleCloseFlyr(v.value); 
            if(v.call === 'primeHandle') primeHandle(v.value); 

        };
        hndl['handleRoute'] = (v) => {

            switch(v.call)
            {
            case'goBack':
    
                history.goBack();
            break;
            case'reload':
    
                window.location.reload();//regular dom 
            break;
            case'redirect':
    
                history.replace(v.value);
            break;
            case'push':

                history.push(v.value);
            break;
            default:
            }
    
        }
        Handler(hndl);


    }


    const primeHandle = (hndl) => {

        //alert(hndl.handle);
        switch(hndl.handle)
        {
        case'messngr':

            props.handle({

                call:hndl.action

            })

        break;
        case'flyr':


            props.handle({

                call:hndl.action

            })

        break;
        case'removeHash':


            props.handle({

                call:hndl.action

            })

        break;
        default:
        }



    }

    
    const handleDispatch = (hndl) => {

        //if(hndl.action === 'DELETE_ZONE') alert('delete zone')
    
        dispatch(ModifyZoneStore({
    
            type:hndl.action,//UPDATE_ZONE or DELETE_ZONE
            item:{
                key     :hndl.key,
                value   :hndl.value
            }

        })); 

    }
    

    const handleCloseFlyr = (hndl) => {

        props.handle({

            call:'hide'

        })

    }


    */





    return ( 

        <div>

            <Alfa
            zIndex={highZ+1}
            ></Alfa>

            <Base 
            //ref={baseRef}
            zIndex={highZ+2}
            >


                <Box 
                id="flyr"
                ref={flyrRef}
                >

                    <FormComponent
                    
                        refid       = {"loginFlyr"}    
                        info        = {{}}  
                        items       = {formArr} 
                        handle      = {(v) => props.handle(v)} 
                        problemArr  = {problemArr}
                        border      = {"0"}

                    />


                </Box>



            </Base>

        </div>



    );



}



export default Index;





