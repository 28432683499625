import React, { 

    //useState, 
    useEffect,
    //useRef

} from 'react';



import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    useHistory,   
    //useParams,



} from "react-router-dom";



export const TimeoutHistory = (props) => {


    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    const history = useHistory();
    
    var pathname = location.pathname;
    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);


    useEffect(() => {

        const timer = setTimeout(() => {

            console.log(JSON.stringify(props.data,null,2));
            history.replace(props.data.url);

        }, props.data.duration);

        return () => clearTimeout(timer);

    }, [
        props,
        location,
        history
    ]);

    return <div></div>

}


/* 

https://upmostly.com/tutorials/settimeout-in-react-components-using-hooks


*/