import React, { 

    useState, 
    useEffect, 
    //useRef 

} from 'react';


import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';

import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation, 
    useHistory,  
    //useParams

} from "react-router-dom";


/*
import {

    //Header,
    Footer,
    //InfoBar

} from 'app-components/Prime/Commons';
*/


import {

    Stage,
    ScrollBox,
    Area,
    //AreaTitle

} from '../Styled';

//import StylScene from '../StylModules/Scene.module.css';
//import StylRows from '../StylModules/Rows.module.css';

import Loader from 'utils/components/Process/Loader';


export const AuthLogout = (props) => {


    // -- router -- //
    const location = useLocation(); 
    const history = useHistory();
    //let match = useRouteMatch();
    //let params = useParams();
 
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);



    // -- redux -- //
    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));
    

    // -- ref -- //
    //const refA = useRef(null);
    //const refB = useRef(null);
    //const refC = useRef(null);



    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);
    //const [stageTopHeight, setStageTopHeight] = useState(50);
    //const [stageHeight, setStageHeight] = useState(50)
    //const [scrollboxHeight, setScrollboxHeight] = useState(50);

    useEffect(() => {


        if(!isLoaded)
        {
            setIsLoaded(true);           
        }

        if(localStorage.accessEmail) localStorage.removeItem("accessEmail");
        if(localStorage.accessToken) localStorage.removeItem("accessToken");
        if(localStorage.refreshToken) localStorage.removeItem("refreshToken");
 
        var url;

        var backToLogin = true;
        if(backToLogin)
        {
            //alert("auth: "+auth);

            var basename = zoneObj.basename;

            // -- change location -- //

            url = '';
            // url = '/Login/';
            // //var url = basename + 'AuthArea/Login';
            // //var url = "/templates/AuthArea/FormLogin";
            // //history.replace(url);
            // //history.push(url);
            // //history.goBack();


            url = window.location.protocol + "//";

            if(window.location.hostname.match(/192.168/gi)
            || window.location.hostname.match(/localhost/gi)
            )
            {
                url = url + window.location.hostname +":"+ window.location.port;
            }else{
                url = url + window.location.hostname;
            }//=

            if(basename !== '')
            {
                url = url + basename + 'Login/';
            }else{
                url = url + "/Login/";
            }
            //alert("url:"+url);
            window.location.replace(url);//regular dom

        
        }



    },[

        location,
        history,
        zoneObj,
        isLoaded
    ])




    return (

        <Stage
        //backgroundColor={"#edeef1"}
        //height={stageHeight}
        >

            <ScrollBox
            //backgroundColor={"#edeef1"}
            //height={scrollboxHeight}
            >
                <Area
                maxWidth={1200+"px"}
                width={"96%"}
                >
                    <Loader 

                        call={"pageLoader"}
                        litedark={'dark'}
                        text={"Logging Out..."}

                    />

                </Area>

            </ScrollBox>


        </Stage>

    )




}



