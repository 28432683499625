import styled from "styled-components";


export const CatalogLineItem = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "white",
    border:             props.border || "1px solid lightgray",

    width:              props.width || "calc(100% - 17px)",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "16px 0 14px 15px",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "20px",
    fontWeight:         props.fontWeight  || "500",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration  || "none",
    textColor:          props.textColor || "black"


}))`


    background-color:${props => props.backgroundColor};
    border-bottom:${props => props.border};
    border-left:${props => props.border};
    border-right:${props => props.border};

    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;


    font-family:${props => props.fontFamily};
    font-size:${props => props.fontSize};
    font-weight:${props => props.fontWeight};
    text-align:${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.textColor};


    cursor:pointer;


    @media (max-width: 300px) {

        height:80px;
    }



    &:hover {

        background-color:#caffca;
        text-decoration:underline;
    }



`;


