import styled from "styled-components";




export const CatalogRow = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "white",
    border:             props.border || "1px solid lightgray",

    width:              props.width || "calc(100% - 2px)",
    height:             props.height || "40px",
    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "0 0 0 0"

}))`


    background-color:${props => props.backgroundColor};
    border:${props => props.border};

    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;


    cursor:pointer;

    &:hover {
    
        background-color:#caffca;
        text-decoration:underline;
    }



    @media (max-width: 550px) {

        height:65px;
    }




`;





export const CatalogRowBullet = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "white",
    border:             props.border || "1px solid lightgray",

    width:              props.width || "45px",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "0 0 8px 0",


    bulletColor:        props.bulletColor || "#edeef1",


}))`


    background-color:${props => props.backgroundColor};
    border-right:${props => props.border};

    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;


    @media (max-width: 550px) {

        border-right:0;

    }



    .Circle {

        background-color:${props => props.bulletColor};
        border-radius:25px;

        width:20px;
        height:20px;

        padding:0 0 0 0;
        margin:11px 0 0 12.5px;

        position:relative;
        float:left;

    }

    


`;


export const CatalogRowName = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "1px solid lightgray",

    width:              props.width || "calc(100% - 75px)",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "10px 0 5px 15px",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "20px",
    fontWeight:         props.fontWeight  || "500",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration  || "none",
    textColor:          props.textColor || "black"




    // border-bottom:${props => props.border};
    // border-left:${props => props.border};
    // border-right:${props => props.border};


}))`


    background-color:${props => props.backgroundColor};


    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;


    font-family:${props => props.fontFamily};
    font-size:${props => props.fontSize};
    font-weight:${props => props.fontWeight};
    text-align:${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.textColor};


    cursor:pointer;


    @media (max-width: 300px) {

        height:80px;
    }



`;





export const CatalogRowLine = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "2px solid black",

    width:              props.width || "calc(100% - 60px)",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "10px 0 5px 0",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "20px",
    fontWeight:         props.fontWeight  || "500",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration  || "none",
    textColor:          props.textColor || "black"


    // border-bottom:${props => props.border};
    // border-left:${props => props.border};
    // border-right:${props => props.border};


}))`


    background-color:${props => props.backgroundColor};


    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;


    font-family:${props => props.fontFamily};
    font-size:${props => props.fontSize};
    font-weight:${props => props.fontWeight};
    text-align:${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.textColor};


    cursor:pointer;


    @media (max-width: 300px) {

        height:80px;
    }





    .Percent {


        background-color:transparent;

        border-left:${props => props.border};
        border-right:${props => props.border};
        border-radius:15px;

        width:75px;
        height:auto;
        margin:2px 0 0 10px;
        padding:0 0 0 0;

        position:relative;
        float:left;

        overflow:hidden;
    
        font-family:${props => props.fontFamily};
        font-size:18px;
        font-weight:${props => props.fontWeight};
        text-align:center;
        text-decoration:${props => props.textDecoration};
        color:${props => props.textColor};

    }

    .Name {

        width:calc(60% - 80px);
        height:auto;
        margin:0 0 0 10px;
        padding:0 0 0 0;

        position:relative;
        float:left;

        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        text-align:${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.textColor};
 
    }


    .Value {

        background-color:transparent;

        width:calc(40% - 80px);
        height:auto;
        margin:0 0 0 0;
        padding:0 0 0 0;

        position:relative;
        float:right;

        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        text-align:right;
        text-decoration:${props => props.textDecoration};
        color:${props => props.textColor};


    }


    @media (max-width: 550px) {


        .Name {

            background-color:transparent;
    
            width:30%;
            position:relative;
            float:right;

            text-align:left;
        }


        .Value {

            background-color:transparent;
    
            width:30%;
            position:relative;
            float:right;

            text-align:left;
    
        }

    }




    @media (max-width: 375px) {


        .Name {

            background-color:transparent;
    
            width:50%;
            position:relative;
            float:right;

            text-align:left;
        }


        .Value {

            background-color:transparent;
    
            width:50%;
            position:relative;
            float:right;

            text-align:left;
    
        }

    }





`;




