
var empl_vacation_weeks = "0";
var empl_direct_indirect_ira_monthly_total = "$0.00";
// var empl_burden_hours_yearly;
// var empl_name;
var empl_direct_hourly_rate = "$0.00";

//var empl_labor_burden_hourly_rate = "$0.00";
var empl_indirect_labor_burden_monthly = "$0.00";


var empl_labor_direct = "N/A";
var empl_labor_percent_indirect = "$0.00";
// var empl_tax_hourly;
// var empl_indirect_ira_yearly;
var empl_hours_indirect = "0";
var empl_hours_direct = "0";
//var empl_yearly_salary_w_health_insurance = "0.00";
// var empl_tax_comp_percent;
// var empl_direct_ira_yearly;
// var empl_direct_hours_yearly;
// var empl_indirect_hours_yearly;
// var empl_working_weeks;
// var empl_labor_percent;
// var empl_health_insurance_weekly;
// var empl_indirect_salary_monthly;
var empl_direct_indirect_ira_yearly_total = "$0.00";
var empl_direct_indirect_salary_yearly_total = "$0.00";
var empl_health_insurance_monthly = "$0.00";
// var empl_direct_salary_monthly;
// var empl_indirect_ira_monthly;
// var empl_direct_tax_yearly;
// var empl_billable_hours_daily;
var empl_labor_percent_direct = "$0.00";
// var empl_direct_salary_yearly;
var empl_hourly_rate = "$0.00";
// var empl_indirect_salary_yearly;
// var empl_direct_tax_monthly;
// var empl_direct_labor_decimal;
var empl_health_insurance_yearly = "$0.00";
// var empl_ira_decimal;
// var empl_burden_monthly_total;
// var empl_hours_weekly;
// var empl_ira_percent;
var empl_labor_indirect = "N/A";
// var empl_health_insurance_hourly;
var empl_billable_hours_weekly = '0';
// var empl_ira_hourly;
// var empl_direct_indirect_salary_monthly_total;
var empl_burden_yearly_total = "$0.00"
// var empl_indirect_hourly_rate;
var empl_direct_indirect_tax_yearly_total = "$0.00";
// var empl_indirect_tax_monthly;
// var empl_direct_ira_monthly;
// var empl_indirect_tax_yearly;
// var empl_direct_indirect_tax_monthly_total;
// var empl_tax_comp_decimal;


var empl_total_hours_weekly = "0.0";


export const empl = [




    //-----------------------------------
    // Report Box - Graphic and Ledger
    //-----------------------------------

    {

        "print"		    :'bar',
        "name"		    :'Hours / Billable',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"20px"
        },
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'report_box',
        "name"		    :'Report Box',
        "value"		    :[

            {

                boxCall:'graphic',
                boxWidth:"35%",
                boxHeight:200,

                svgCall:"employee",
                svgWidth:200,
                svgHeight:200,

            },

            {
                boxCall:'ledger',
                boxWidth:"65%",
                boxHeight:215,
                value:[

                    {
                        name:"Hourly Rate",
                        value:empl_hourly_rate
                    },
                    {
                        name:"Direct Hourly Rate",
                        value:empl_direct_hourly_rate
                    },
                    {
                        name:"Direct Hours Per Week",
                        value:empl_billable_hours_weekly+" hrs"
                    },
                    {
                        name:"Indirect Labor Burden Monthly",
                        value:empl_indirect_labor_burden_monthly
                    }


                ]

            }



        ],
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"0px"
        },
        "required"	    :"",
        "default"	    :""


    },






    // //-----------------------------------
    // // Report Box - 
    // //-----------------------------------

    // // {

    // //     "print"		    :'bar',
    // //     "name"		    :'Hours / Billable',
    // //     "value"		    :"",
    // //     "required"	    :"",
    // //     "default"	    :""


    // // },
    // // {

    // //     "print"		    :'spacer',
    // //     "name"		    :'',
    // //     "value"		    :{

    // //         backgroundColor:"transparent",
    // //         height:"20px"
    // //     },
    // //     "required"	    :"",
    // //     "default"	    :""


    // // },
    // {

    //     "print"		    :'report_box',
    //     "name"		    :'Report Box',
    //     "value"		    :[

    //         {

    //             boxCall:'blank',
    //             boxWidth:"10%",
    //             boxHeight:75,

    //             // svgCall:"employee",
    //             // svgWidth:230,
    //             // svgHeight:230,

    //         },

    //         {
    //             boxCall:'txt',
    //             boxWidth:"80%",
    //             boxHeight:75,
    //             value:[

    //                 {

    //                     // boxCall:'txt',
    //                     // boxWidth:"100%",
    //                     // boxHeight:230,
    
    //                     className:"Note",
    //                     txt:'***NOTE: Direct Rate =  Rate + Taxes and IRA contributions',
    //                     style:{
        
    //                         //fontSize:"24px",
    //                         textAlign:"center"
        
    //                     }
    
    //                 }
    //             ]

    //         }



    //     ],
    //     "required"	    :"",
    //     "default"	    :""


    // },
    // {

    //     "print"		    :'spacer',
    //     "name"		    :'',
    //     "value"		    :{

    //         backgroundColor:"transparent",
    //         height:"20px"
    //     },
    //     "required"	    :"",
    //     "default"	    :""


    // },







    //-----------------------------------
    // Txt
    //-----------------------------------

    // {

    //     "print"		    :'bar',
    //     "name"		    :'Labor Hours',
    //     "value"		    :"",
    //     "required"	    :"",
    //     "default"	    :""


    // },
    {

        "print"		    :'txt',
        "name"		    :'Txt',
        "value"		    :[

            {

                // boxCall:'txt',
                // boxWidth:"100%",
                // boxHeight:230,

                className:"Note",
                txt:'***NOTE: Direct Rate =  Rate + Taxes and IRA contributions',
                style:{

                    //fontSize:"24px",
                    textAlign:"center"

                }

            }

        ],
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"40px"
        },
        "required"	    :"",
        "default"	    :""


    },












    //-----------------------------------
    // BarChart - Direct / Indirect
    //-----------------------------------

    //bar
    {

        "print"		    :'bar',
        "name"		    :'Direct / Indirect Labor',
        "value"		    :"",
        "required"	    :"",
        "default"	    :"",

    },



    //spacer
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"30px"
        },
        "required"	    :"",
        "default"	    :""

    },



    //chart
    {


        "print"		    :'singleBarChart',
        "name"		    :'The Chart',
        "value"		    :[

            {
                chartType:"horizontal",
                chartLines:true,
                data:[

                    {
                        name:'Direct Labor %',
                        value:"2500.00",
                        percent:empl_labor_percent_direct,
                    },

                    {
                        name:'Indirect Labor %',
                        value:"0.00",
                        percent:empl_labor_percent_indirect,
                    }

                ]

            }
        ],
        "required"	    :"",
        "default"	    :"",



    },


    //spacer
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"25px"
        },
        "required"	    :"",
        "default"	    :""

    },






    //-----------------------------------
    // Ledger
    //-----------------------------------

    // {

    //     "print"		    :'bar',
    //     "name"		    :'Job Descriptions',
    //     "value"		    :"",
    //     "required"	    :"",
    //     "default"	    :""


    // },
    {

        "print"		    :'ledger',
        "name"		    :"Ledger",
        "value"		    :[



            {
                name:"Direct",
                value:empl_labor_direct

            },
            {
                name:"Indirect",
                value:empl_labor_indirect

            }

        ],
        "required"	    :"",
        "default"	    :""


    },

    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"50px"
        },
        "required"	    :"",
        "default"	    :""


    },








    //-----------------------------------
    // Ledger
    //-----------------------------------

    {

        "print"		    :'bar',
        "name"		    :'Labor Hours',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'ledger',
        "name"		    :"Ledger",
        "value"		    :[



            {
                name:"Direct Labor",
                value:empl_hours_direct+" hrs"

            },
            {
                name:"Direct Labor Hours Weekly",
                value:empl_hours_direct+" hrs"
            },

            {
                name:"Indirect Labor",
                value:empl_hours_indirect+" hrs"

            },
            {
                name:"Indirect Labor Hours Weekly",
                value:empl_hours_indirect+" hrs"
            },
            {
                name:"Total Weekly Hours",
                value:empl_total_hours_weekly+" hrs"
            }


        ],
        "required"	    :"",
        "default"	    :""


    },

    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"50px"
        },
        "required"	    :"",
        "default"	    :""


    },








    //-----------------------------------
    // Ledger
    //-----------------------------------

    {

        "print"		    :'bar',
        "name"		    :'Healthcare',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'ledger',
        "name"		    :"Ledger",
        "value"		    :[


            {
                name:"Health Insurance Monthly",
                value:empl_health_insurance_monthly
            },

        ],
        "required"	    :"",
        "default"	    :""


    },

    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"50px"
        },
        "required"	    :"",
        "default"	    :""


    },





    //-----------------------------------
    // Ledger
    //-----------------------------------

    {

        "print"		    :'bar',
        "name"		    :'IRA',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'ledger',
        "name"		    :"Ledger",
        "value"		    :[


            {
                name:"Contribution Monthly",
                value:empl_direct_indirect_ira_monthly_total
            },

        ],
        "required"	    :"",
        "default"	    :""


    },

    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"50px"
        },
        "required"	    :"",
        "default"	    :""


    },





    

    //-----------------------------------
    // Ledger
    //-----------------------------------

    {

        "print"		    :'bar',
        "name"		    :'Vacation / Personal Time',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'ledger',
        "name"		    :"Ledger",
        "value"		    :[


            {
                name:"Weeks",
                value:empl_vacation_weeks
            }

        ],
        "required"	    :"",
        "default"	    :""


    },

    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"50px"
        },
        "required"	    :"",
        "default"	    :""


    },









    //-----------------------------------
    // Ledger
    //-----------------------------------

    {

        "print"		    :'bar',
        "name"		    :'Yearly Summary',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'ledger',
        "name"		    :"Ledger",
        "value"		    :[


            {
                name:"Salary",
                value: empl_direct_indirect_salary_yearly_total
            },
            {
                name:"Health Insurance",
                value:empl_health_insurance_yearly
            },
            {
                name:"IRA",
                value:empl_direct_indirect_ira_yearly_total
            },
            {
                name:"Taxes",
                value:empl_direct_indirect_tax_yearly_total
            },
            {
                name:"Yearly Total",
                value:empl_burden_yearly_total

            }


        ],
        "required"	    :"",
        "default"	    :""


    },

    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"50px"
        },
        "required"	    :"",
        "default"	    :""


    }



];







