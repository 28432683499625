export const SCHEDULE = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 500 500";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};

	let iconColor = props.iconColor || "rgb(0,0,0)";

	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>


			<path 
				fill={iconColor}
				fillRule="evenodd"
				clipRule="evenodd"
				d="M308.055,417.843c62.656-1.598,110.641-48.516,112.266-112.234	c-1.619-63.458-49.572-110.645-112.262-112.229c-62.689,1.584-110.644,48.771-112.263,112.229 C197.422,369.327,245.396,416.245,308.055,417.843L308.055,417.843z M308.006,447.144c79.301-2.029,139.572-61.664,141.615-141.535 c-2.037-79.649-62.453-139.514-141.621-141.52c-79.166,2.006-139.468,61.87-141.505,141.52 C168.539,385.479,228.704,445.114,308.006,447.144L308.006,447.144z M306.105,203.919h3.98c4.313,0,7.84,3.535,7.84,7.842v84.023 h63.449c4.303,0,7.83,3.539,7.83,7.846v3.98c0,4.318-3.527,7.836-7.83,7.836c-23.77,0-47.529,0-71.289,0	c-6.734-0.086-11.734-3.104-11.83-11.816v-91.869C298.256,207.445,301.785,203.919,306.105,203.919L306.105,203.919z M128.17,155.604h40.726v38.257H128.17V155.604L128.17,155.604z M183.498,155.604h40.725v16.6 c-10.234,6.162-19.668,13.431-28.171,21.657h-12.554V155.604L183.498,155.604z M128.17,208.67h40.726v20.195	c-3.298,5.784-6.247,11.814-8.832,18.063H128.17V208.67L128.17,208.67z M76.213,76.343h39.602v-8.052 c0-8.489,6.944-15.435,15.434-15.435c8.488,0,15.434,6.947,15.434,15.435v8.052h144.609v-8.052c0-8.489,6.943-15.435,15.432-15.435 c8.49,0,15.436,6.946,15.436,15.435v8.052h39.6c14.209,0,25.834,11.626,25.834,25.833v67.462 c-9.047-5.052-22.719-9.265-32.828-12.552v-28.358c0-11.427-9.545-20.772-21.213-20.772h-11.393v10.792 c0,8.488-6.947,15.434-15.436,15.434c-8.484,0-15.432-6.945-15.432-15.434v-10.792H146.682v10.792 c0,8.487-6.947,15.434-15.434,15.434c-8.487,0-15.434-6.945-15.434-15.434v-10.792h-11.394c-11.666,0-21.213,9.346-21.213,20.772 v160.479c0,11.428,9.547,20.773,21.213,20.773h43.108c0.401,10.283,1.657,20.262,3.716,29.867H76.213 c-14.206,0-25.834-11.625-25.834-25.834V102.176C50.379,87.968,62.004,76.343,76.213,76.343L76.213,76.343z"
			/>


		</svg>
		</div>

	);//return

	

}