
import React, { 

    useState, 
    useEffect,
    useRef

} from 'react';


import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import Report from 'utils/components/Report';

import CreateMarkup from '../../CreateMarkup';
import { Print } from './Styled';
//import SceneInfo from './SceneInfo';

import Build from './Build';


const Index = (props) => {



    // -- redux -- //
    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));

    // let revProps = {}
    // for(var propKey in props)
    // {
    //     //console.log(propKey+" : "+props[propKey]);
    //     revProps[propKey] = props[propKey];

    // };
    // revProps['zoneObj'] = zoneObj;
    // //console.log("prop prototype: "+Object.prototype.toString.call(props));



    //const TestPrint = SceneInfo();
    const PrintItems = Build(props);


    // -- ref -- //
    const refA = useRef(null);


    // -- state -- //
    const [printOut, setPrintOut] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);


    useEffect(() => {

        if(!isLoaded)
        {
            //console.log("FLYR REPORT PROPS: "+JSON.stringify(props,null,2));
            //console.log("DATA: "+JSON.stringify(props.data,null,2));
            //alert('checking data')

            setIsLoaded(true);

            //let printOut;
            //printOut = JSON.stringify(props.data,null,2);
            //printOut = printOut.replace(/\n/gi,"<br/>");
            setPrintOut(false);


            // console.log("REPORT PRINT H: "+refA.current.offsetHeight);
            // props.handle({

            //     call:"adjustContentsHeight",
            //     data:{

            //         ref:refA,
            //         h:refA.current.offsetHeight

            //     }

            // })

        }

      

    },[

        props,
        isLoaded,     
        refA,
        zoneObj,
        //TestPrint

    ])







    if(!isLoaded)
    {
        return (

            <Print
            ref={refA}
            backgrounColor={"lightyellow"}
            >
    
                <div dangerouslySetInnerHTML={CreateMarkup(printOut)} />
    
            </Print>

        );

    }else{

        return (

            <Print
            ref={refA}
            //backgroundColor="yellow"
            height={props.flyrH}
            >
                
                <Report
                
                    refid       = {"flyr"}    
                    info        = {{}}  
                    items       = {PrintItems} 
                    handle      = {(v) => props.handle(v)} 
                    border      = {"0"}
                />

            </Print>
    

        );

    }


 



}


export default Index;













// FLYR REPORT PROPS: {
//     "data": {
//       "name": "Business",
//       "value": {
//         "expense_ssenisub_insurance": {
//           "name": "Insurance",
//           "default": "",
//           "print": "price",
//           "value": "1100.00",
//           "required": true
//         },
//         "expense_ssenisub_accounting": {
//           "name": "Accounting",
//           "default": "",
//           "print": "price",
//           "value": "100.00",
//           "required": true
//         },
//         "expense_ssenisub_legal": {
//           "name": "Legal",
//           "default": "",
//           "print": "price",
//           "value": "200.00",
//           "required": true
//         },
//         "expense_ssenisub_licenses": {
//           "name": "Licenses",
//           "default": "",
//           "print": "price",
//           "value": "100.00",
//           "required": true
//         },
//         "expense_ssenisub_loans": {
//           "name": "Loans",
//           "default": "",
//           "print": "price",
//           "value": "1780.00",
//           "required": true
//         },
//         "expense_ssenisub_advertising": {
//           "name": "Advertising",
//           "default": "",
//           "print": "price",
//           "value": "200.00",
//           "required": true
//         },
//         "expense_ssenisub_website": {
//           "name": "Website",
//           "default": "",
//           "print": "price",
//           "value": "100.00",
//           "required": true
//         },
//         "expense_ssenisub_misc": {
//           "name": "Misc",
//           "default": "",
//           "print": "price",
//           "value": "20.00",
//           "required": true
//         },
//         "total": {
//           "name": "Total Monthly (Overhead $)",
//           "default": 0,
//           "print": "price",
//           "value": "3600.00",
//           "required": true
//         },
//         "total_percent": {
//           "name": "Total Monthly (Overhead %)",
//           "default": 0,
//           "print": "percent",
//           "value": 24.5,
//           "required": true
//         }
//       },
//       "order": [
//         "expense_ssenisub_insurance",
//         "expense_ssenisub_accounting",
//         "expense_ssenisub_legal",
//         "expense_ssenisub_licenses",
//         "expense_ssenisub_loans",
//         "expense_ssenisub_advertising",
//         "expense_ssenisub_website",
//         "expense_ssenisub_misc"
//       ]
//     },
//     "styl": {
//       "maxWidth": 550,
//       "fieldType": "composed"
//     },
//     "flyrH": "739px"
//   }




// Chart Sections: [
//     {
//       "name": "Rent / Mortgage",
//       "value": 78.9,
//       "data": {
//         "percent": 78.9,
//         "price": "5500.00"
//       }
//     },
//     {
//       "name": "Utilities",
//       "value": 15.8,
//       "data": {
//         "percent": 15.8,
//         "price": "1100.00"
//       }
//     },
//     {
//       "name": "Phone / Internet",
//       "value": 5,
//       "data": {
//         "percent": 5,
//         "price": "350.00"
//       }
//     },
//     {
//       "name": "Misc",
//       "value": 0.3,
//       "data": {
//         "percent": 0.3,
//         "price": "20.00"
//       }
//     }
//   ]



// TestPrint: [
//     {
//       "call": "top",
//       "name": "Expenses",
//       "controls": []
//     },
//     {
//       "call": "items",
//       "items": [
//         {
//           "print": "bar",
//           "name": "Percentages",
//           "value": "",
//           "required": "",
//           "default": ""
//         },
//         {
//           "print": "spacer",
//           "name": "",
//           "value": {
//             "backgroundColor": "transparent",
//             "height": "30px"
//           },
//           "required": "",
//           "default": ""
//         },
//         {
//           "print": "singleBarChart",
//           "name": "The Chart",
//           "value": [
//             {
//               "chartType": "horizontal",
//               "chartLines": true,
//               "data": [
//                 {
//                   "name": "Direct Labor %",
//                   "value": "2500.00",
//                   "percent": 80
//                 },
//                 {
//                   "name": "Indirect Labor %",
//                   "value": "2500.00",
//                   "percent": 20
//                 },
//                 {
//                   "name": "Direct Labor %",
//                   "value": "2500.00",
//                   "percent": 80
//                 },
//                 {
//                   "name": "Indirect Labor %",
//                   "value": "2500.00",
//                   "percent": 20
//                 },
//                 {
//                   "name": "Direct Labor %",
//                   "value": "2500.00",
//                   "percent": 80
//                 },
//                 {
//                   "name": "Indirect Labor %",
//                   "value": "2500.00",
//                   "percent": 20
//                 }
//               ]
//             }
//           ],
//           "required": "",
//           "default": ""
//         },
//         {
//           "print": "spacer",
//           "name": "",
//           "value": {
//             "backgroundColor": "transparent",
//             "height": "50px"
//           },
//           "required": "",
//           "default": ""
//         },
//         {
//           "print": "bar",
//           "name": "Amount",
//           "value": "",
//           "required": "",
//           "default": ""
//         },
//         {
//           "print": "ledger",
//           "name": "Ledger",
//           "value": [
//             {
//               "name": "Direct Labor",
//               "value": "$400.00"
//             },
//             {
//               "name": "Direct Labor Hours per Week",
//               "value": "$100.00"
//             },
//             {
//               "name": "Indirect Labor",
//               "value": "$400.00"
//             },
//             {
//               "name": "Indirect Labor Hours per Week",
//               "value": "$100.00"
//             },
//             {
//               "name": "Hours Weekly Total",
//               "value": "$200.00"
//             },
//             {
//               "name": "Indirect Labor Hours per Week",
//               "value": "$100.00"
//             },
//             {
//               "name": "Hours Weekly Total",
//               "value": "$200.00"
//             }
//           ],
//           "required": "",
//           "default": ""
//         },
//         {
//           "print": "spacer",
//           "name": "",
//           "value": {
//             "backgroundColor": "transparent",
//             "height": "50px"
//           },
//           "required": "",
//           "default": ""
//         }
//       ]
//     }
//   ]

