const LibGet =  {


	AspectRatio: (w,h) => {


		function GCD(x, y) {
		
            var z;

			while (y !== 0) 
			{
				z = x % y;
				x = y;
				y = z;
			}
			return x;
		
		}//

		var divisor;
        divisor = GCD(w,h);
        console.log(divisor);

		var aspect_w = w / divisor;
		var aspect_h = h / divisor;
		var aspect_ratio = Math.round(aspect_w)+":"+Math.round(aspect_h);
	
		return aspect_ratio;



        // function gcd( a, b ){
        //     return (a % b) ? gcd(b, a % b) : b;
        // }
        // function ratio( x, y ){

        //     var value;
        //     value = gcd(x, y);
        //     return (x/value)+':'+(y/value);
        // }


        // var ret;
        // ret = ratio(w,h);
        // return ret;



        // function gcd(a, b)
        // {
        //     var r;
        //     if (a === 0 || b === 0)
        //         return Math.abs( Math.max(Math.abs(a), Math.abs(b)) );
        
        //     r = a % b;
        //     return (r !== 0) ?
        //         gcd(b, r) :
        //         Math.abs(b);
        // }
        
        // var ret;
        // ret=gcd(w,h);
        // return ret;

		
	}


}

export default LibGet;


