import { LibNumber } from "library";

export const BuildEmpl = (props) => {



    // "empl-1": {
    //     "name": "Employee 1",
    //     "value": {
    //       "empl_vacation_weeks": "2.00",
    //       "empl_direct_indirect_ira_monthly_total": "72.00",
    //       "empl_burden_hours_yearly": "1920.00",
    //       "empl_name": "Employee 1",
    //       "empl_direct_hourly_rate": "18.45",
    //       "empl_labor_direct": "Van Halen,ACDC",
    //       "empl_labor_percent_indirect": "50.00",
    //       "empl_tax_hourly": "3.00",
    //       "empl_indirect_ira_yearly": "450.00",
    //       "empl_hours_indirect": "20.00",
    //       "empl_direct_ira_monthly": "34.50",
    //       "empl_indirect_tax_yearly": "3000.00",
    //       "empl_direct_indirect_tax_monthly_total": "480.00",
    //       "empl_tax_comp_decimal": "0.20",
    //       "empl_hours_direct": "20.00",
    //       "empl_yearly_salary_w_health_insurance": "31812.00",
    //       "empl_tax_comp_percent": "20.00",
    //       "empl_direct_ira_yearly": "414.00",
    //       "empl_direct_hours_yearly": "920.00",
    //       "empl_indirect_hours_yearly": "1000.00",
    //       "empl_working_weeks": "46.00",
    //       "empl_labor_percent": "50.00",
    //       "empl_health_insurance_weekly": "65.48",
    //       "empl_indirect_salary_monthly": "1250.00",
    //       "empl_direct_indirect_ira_yearly_total": "864.00",
    //       "empl_direct_indirect_salary_yearly_total": "28800.00",
    //       "empl_health_insurance_monthly": "251.00",
    //       "empl_direct_salary_monthly": "1150.00",
    //       "empl_indirect_ira_monthly": "37.50",
    //       "empl_direct_tax_yearly": "2760.00",
    //       "empl_billable_hours_daily": "5.00",
    //       "empl_labor_percent_direct": "50.00",
    //       "empl_direct_salary_yearly": "13800.00",
    //       "empl_hourly_rate": "15.00",
    //       "empl_indirect_salary_yearly": "15000.00",
    //       "empl_direct_tax_monthly": "230.00",
    //       "empl_direct_labor_decimal": "0.50",
    //       "empl_health_insurance_yearly": "3012.00",
    //       "empl_ira_decimal": "0.03",
    //       "empl_burden_monthly_total": "3203.00",
    //       "empl_hours_weekly": "40.00",
    //       "empl_ira_percent": "3.00",
    //       "empl_labor_indirect": "Inside Sales,Accounting",
    //       "empl_health_insurance_hourly": "1.64",
    //       "empl_billable_hours_weekly": "20.00",
    //       "empl_ira_hourly": "0.45",
    //       "empl_direct_indirect_salary_monthly_total": "2400.00",
    //       "empl_burden_yearly_total": "38436.00",
    //       "empl_indirect_hourly_rate": "20.09",
    //       "empl_direct_indirect_tax_yearly_total": "5760.00",
    //       "empl_indirect_tax_monthly": "250.00"
    //     }
    // },



    var empl_vacation_weeks = "0";
    var empl_direct_indirect_ira_monthly_total = 0;
    // var empl_burden_hours_yearly;
    // var empl_name;
    var empl_direct_hourly_rate = "0.00";
    var empl_labor_direct = "N/A";
    var empl_labor_percent_indirect = 0;

    //var empl_labor_burden_hourly_rate = "$0.00";
    var empl_indirect_labor_burden_monthly = "$0.00";

    // var empl_tax_hourly;
    // var empl_indirect_ira_yearly;
    var empl_hours_indirect = "0";
    var empl_hours_direct = "0";
    //var empl_yearly_salary_w_health_insurance = "0.00";
    // var empl_tax_comp_percent;
    // var empl_direct_ira_yearly;
    // var empl_direct_hours_yearly;
    // var empl_indirect_hours_yearly;
    // var empl_working_weeks;
    // var empl_labor_percent;
    // var empl_health_insurance_weekly;
    // var empl_indirect_salary_monthly;
    var empl_direct_indirect_ira_yearly_total = 0;
    var empl_direct_indirect_salary_yearly_total = 0;
    var empl_health_insurance_monthly = 0;
    // var empl_direct_salary_monthly;
    // var empl_indirect_ira_monthly;
    // var empl_direct_tax_yearly;
    // var empl_billable_hours_daily;
    var empl_labor_percent_direct = 0;
    // var empl_direct_salary_yearly;
    var empl_hourly_rate = "0.00";
    // var empl_indirect_salary_yearly;
    // var empl_direct_tax_monthly;
    // var empl_direct_labor_decimal;
    var empl_health_insurance_yearly = 0;
    // var empl_ira_decimal;
    // var empl_burden_monthly_total;
    // var empl_hours_weekly;
    // var empl_ira_percent;
    var empl_labor_indirect = "N/A";
    // var empl_health_insurance_hourly;
    var empl_billable_hours_weekly = '0';
    // var empl_ira_hourly;
    // var empl_direct_indirect_salary_monthly_total;
    var empl_burden_yearly_total = "0.00"
    // var empl_indirect_hourly_rate;
    var empl_direct_indirect_tax_yearly_total = 0;
    // var empl_indirect_tax_monthly;
    // var empl_direct_ira_monthly;
    // var empl_indirect_tax_yearly;
    // var empl_direct_indirect_tax_monthly_total;
    // var empl_tax_comp_decimal;


    var empl_total_hours_weekly = "0.00";


    var empl_flag = '';



    if(props.data && props.data !== undefined)
    { 
        console.log("PROPS: "+JSON.stringify(props,null,2));



        empl_vacation_weeks                     = props.data.value.empl_vacation_weeks;
        empl_direct_indirect_ira_monthly_total  = props.data.value.empl_direct_indirect_ira_monthly_total;
        // empl_burden_hours_yearly                = props.data.value.empl_burden_hours_yearly;
        // empl_name                               = props.data.value.empl_name;
        empl_direct_hourly_rate                 = props.data.value.empl_direct_hourly_rate;
        empl_labor_direct                       = props.data.value.empl_labor_direct;
        empl_labor_percent_indirect             = props.data.value.empl_labor_percent_indirect;
        // empl_tax_hourly                         = props.data.value.empl_tax_hourly;
        // empl_indirect_ira_yearly                = props.data.value.empl_indirect_ira_yearly;
        empl_hours_indirect                     = props.data.value.empl_hours_indirect;               
        empl_hours_direct                       = props.data.value.empl_hours_direct;


        empl_indirect_labor_burden_monthly      = props.data.value.empl_indirect_labor_burden_monthly;


        // empl_direct_ira_monthly                 = props.data.value.empl_direct_ira_monthly;
        // empl_indirect_tax_yearly                = props.data.value.empl_indirect_tax_yearly;
        // empl_direct_indirect_tax_monthly_total  = props.data.value.empl_direct_indirect_tax_monthly_total;
        // empl_tax_comp_decimal                   = props.data.value.empl_tax_comp_decimal;
        //empl_yearly_salary_w_health_insurance   = props.data.value.empl_yearly_salary_w_health_insurance;
        // empl_tax_comp_percent                   = props.data.value.empl_tax_comp_percent;
        // empl_direct_ira_yearly                  = props.data.value.empl_direct_ira_yearly;
        // empl_direct_hours_yearly                = props.data.value.empl_direct_hours_yearly;
        // empl_indirect_hours_yearly              = props.data.value.empl_indirect_hours_yearly;
        // empl_working_weeks                      = props.data.value.empl_working_weeks;
        // empl_labor_percent                      = props.data.value.empl_labor_percent;
        // empl_health_insurance_weekly            = props.data.value.empl_health_insurance_weekly;
        // empl_indirect_salary_monthly            = props.data.value.empl_indirect_salary_monthly;
        empl_direct_indirect_ira_yearly_total   = props.data.value.empl_direct_indirect_ira_yearly_total;
        empl_direct_indirect_salary_yearly_total = props.data.value.empl_direct_indirect_salary_yearly_total;
        empl_health_insurance_monthly           = props.data.value.empl_health_insurance_monthly;
        // empl_direct_salary_monthly              = props.data.value.empl_direct_salary_monthly;
        // empl_indirect_ira_monthly               = props.data.value.empl_indirect_ira_monthly;
        // empl_direct_tax_yearly                  = props.data.value.empl_direct_tax_yearly;
        // empl_billable_hours_daily               = props.data.value.empl_billable_hours_daily;
        empl_labor_percent_direct               = props.data.value.empl_labor_percent_direct;
        // empl_direct_salary_yearly               = props.data.value.empl_direct_salary_yearly;
        empl_hourly_rate                        = props.data.value.empl_hourly_rate;
        // empl_indirect_salary_yearly             = props.data.value.empl_indirect_salary_yearly;
        // empl_direct_tax_monthly                 = props.data.value.empl_direct_tax_monthly;
        // empl_direct_labor_decimal               = props.data.value.empl_direct_labor_decimal;
        empl_health_insurance_yearly            = props.data.value.empl_health_insurance_yearly;
        // empl_ira_decimal                        = props.data.value.empl_ira_decimal;
        // empl_burden_monthly_total               = props.data.value.empl_burden_monthly_total;
        // empl_hours_weekly                       = props.data.value.empl_hours_weekly;
        // empl_ira_percent                        = props.data.value.empl_ira_percent;
        empl_labor_indirect                     = props.data.value.empl_labor_indirect;
        // empl_health_insurance_hourly            = props.data.value.empl_health_insurance_hourly;
        empl_billable_hours_weekly              = props.data.value.empl_billable_hours_weekly;
        // empl_ira_hourly                         = props.data.value.empl_ira_hourly;
        // empl_direct_indirect_salary_monthly_total = props.data.value.empl_direct_indirect_salary_monthly_total;
        empl_burden_yearly_total                = props.data.value.empl_burden_yearly_total;
        // empl_indirect_hourly_rate               = props.data.value.empl_indirect_hourly_rate;
        empl_direct_indirect_tax_yearly_total   = props.data.value.empl_direct_indirect_tax_yearly_total;
        // empl_indirect_tax_monthly               = props.data.value.empl_indirect_tax_monthly;


        empl_flag                               = props.data.value.empl_flag
        

    }//==





    empl_hourly_rate = parseFloat(empl_hourly_rate).toFixed(2);
    empl_hourly_rate = "$"+LibNumber.PrintMoney(empl_hourly_rate);

    empl_direct_hourly_rate = parseFloat(empl_direct_hourly_rate).toFixed(2);
    empl_direct_hourly_rate = "$"+LibNumber.PrintMoney(empl_direct_hourly_rate);

    empl_billable_hours_weekly = parseFloat(empl_billable_hours_weekly).toFixed(1);

    empl_labor_percent_direct = parseFloat(empl_labor_percent_direct).toFixed(1);
    empl_labor_percent_indirect = parseFloat(empl_labor_percent_indirect).toFixed(1);


    if(empl_labor_direct === '0.00') empl_labor_direct = "N/A";
    if(empl_labor_indirect === '0.00') empl_labor_indirect = "N/A";

    empl_labor_direct = empl_labor_direct.replace(/,/gi,", ");
    empl_labor_indirect = empl_labor_indirect.replace(/,/gi,", ");


    empl_hours_direct = parseFloat(empl_hours_direct).toFixed(1);
    empl_hours_indirect = parseFloat(empl_hours_indirect).toFixed(1);

    empl_total_hours_weekly = parseFloat(empl_hours_direct) + parseFloat(empl_hours_indirect);
    empl_total_hours_weekly = parseFloat(empl_total_hours_weekly).toFixed(1);




    //TM:
    empl_indirect_labor_burden_monthly = parseFloat(empl_indirect_labor_burden_monthly).toFixed(2);
    empl_indirect_labor_burden_monthly = "$"+LibNumber.PrintMoney(empl_indirect_labor_burden_monthly);




    empl_health_insurance_monthly = parseFloat(empl_health_insurance_monthly).toFixed(2);
    empl_health_insurance_monthly = "$"+LibNumber.PrintMoney(empl_health_insurance_monthly);


    empl_direct_indirect_ira_monthly_total = parseFloat(empl_direct_indirect_ira_monthly_total).toFixed(2);
    empl_direct_indirect_ira_monthly_total = "$"+LibNumber.PrintMoney(empl_direct_indirect_ira_monthly_total);


    empl_vacation_weeks = parseFloat(empl_vacation_weeks).toFixed(0);
  


    empl_direct_indirect_salary_yearly_total = parseFloat(empl_direct_indirect_salary_yearly_total).toFixed(2);
    empl_direct_indirect_salary_yearly_total = "$"+LibNumber.PrintMoney(empl_direct_indirect_salary_yearly_total);

    empl_health_insurance_yearly = parseFloat(empl_health_insurance_yearly).toFixed(2);
    empl_health_insurance_yearly = "$"+LibNumber.PrintMoney(empl_health_insurance_yearly);

    empl_direct_indirect_ira_yearly_total = parseFloat(empl_direct_indirect_ira_yearly_total).toFixed(2);
    empl_direct_indirect_ira_yearly_total = "$"+LibNumber.PrintMoney(empl_direct_indirect_ira_yearly_total);

    empl_direct_indirect_tax_yearly_total = parseFloat(empl_direct_indirect_tax_yearly_total).toFixed(2);
    empl_direct_indirect_tax_yearly_total = "$"+LibNumber.PrintMoney(empl_direct_indirect_tax_yearly_total);


    empl_burden_yearly_total = parseFloat(empl_burden_yearly_total).toFixed(2);
    empl_burden_yearly_total = "$"+LibNumber.PrintMoney(empl_burden_yearly_total);





    //==================================================================


    var empl_call = "even";  
    var empl_graphic_call = 'empl_direct';

    if(empl_labor_percent_direct === empl_labor_percent_indirect)
    {

        //Even
        empl_call = "even";  
        empl_graphic_call = 'empl_even';


    }

    else
    if(empl_labor_percent_direct < 1)
    {

        //Full Indirect
        empl_call = "full_indirect";  
        empl_graphic_call = 'empl_indirect';

        
    }

    else
    if(empl_labor_percent_indirect < 1)
    {

        //Full Direct
        empl_call = "full_direct";  
        empl_graphic_call = 'empl_direct';

        
    }

    else
    if(empl_labor_percent_direct > empl_labor_percent_indirect)
    {
        //Mostly Direct
        empl_call = "mostly_direct";  
        empl_graphic_call = 'empl_direct_indirect';
    }

    else
    if(empl_labor_percent_direct < empl_labor_percent_indirect)
    {

        //Mostly Indirect
        empl_call = "mostly_indirect";  
        empl_graphic_call = 'empl_indirect_direct';

        
    }




    var summary_note = {};
    var summary_ledger_value;
    switch(empl_call)
    {
    case'full_indirect':

        summary_ledger_value = [

            {
                name:"Hourly Rate",
                value:empl_hourly_rate
            },
            {
                name:"Indirect Labor Burden Monthly",
                value:empl_indirect_labor_burden_monthly
            }
        ];


        summary_note['first'] = {


            spacer:{

                height:"40px"
            }

        };
        summary_note['second'] = {


            note:'',
            spacer:{

                height:"0px"
            }
        };


    break;
    default:


        summary_ledger_value = [

            {
                name:"Hourly Rate",
                value:empl_hourly_rate
            },

            {
                name:"Direct Hourly Rate",
                value:empl_direct_hourly_rate
            },
            {
                name:"Direct Hours Per Week",
                value:empl_billable_hours_weekly+" hrs"
            },
            {
                name:"Indirect Labor Burden Monthly",
                value:empl_indirect_labor_burden_monthly
            }


        ];



        summary_note['first'] = {


            spacer:{

                height:"0px"
            }

        };
        summary_note['second'] = {


            note:'Direct Rate =  Rate + Taxes and IRA contributions',
            spacer:{

                height:"40px"
            }
        };


    }






    var reportItems = [


        //-----------------------------------
        // Report Box - Graphic and Ledger
        //-----------------------------------

        {

            "print"		    :'bar',
            "name"		    :'Summary',
            "value"		    :"",
            "required"	    :"",
            "default"	    :""


        },
        {

            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{

                backgroundColor:"transparent",
                height:"10px"
            },
            "required"	    :"",
            "default"	    :""


        },
        {

            "print"		    :'report_box',
            "name"		    :'Report Box',
            "value"		    :[

                {

                    boxCall:'graphic',
                    boxWidth:"35%",
                    boxHeight:200,

                    svgCall:empl_graphic_call,
                    svgWidth:200,
                    svgHeight:200,

                },

                {
                    boxCall:'ledger',
                    boxWidth:"65%",
                    boxHeight:210,
                    value:summary_ledger_value

                }



            ],
            "required"	    :"",
            "default"	    :""


        },
        {

            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{

                backgroundColor:"transparent",
                height:"0px"
            },
            "required"	    :"",
            "default"	    :""


        },






        //-----------------------------------
        // Txt
        //-----------------------------------

        // {

        //     "print"		    :'bar',
        //     "name"		    :'Labor Hours',
        //     "value"		    :"",
        //     "required"	    :"",
        //     "default"	    :""


        // },
        {

            "print"		    :'txt',
            "name"		    :'Txt',
            "value"		    :[

                {

                    // boxCall:'txt',
                    // boxWidth:"100%",
                    // boxHeight:230,

                    className:"Note",
                    txt:'***NOTE: '+empl_flag,
                    style:{

                        //fontSize:"24px",
                        textAlign:"center"

                    }

                }

            ],
            "required"	    :"",
            "default"	    :""


        },
        {

            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{

                backgroundColor:"transparent",
                height:summary_note.first.spacer.height
            },
            "required"	    :"",
            "default"	    :""


        },





        //-----------------------------------
        // Txt
        //-----------------------------------

        // {

        //     "print"		    :'bar',
        //     "name"		    :'Labor Hours',
        //     "value"		    :"",
        //     "required"	    :"",
        //     "default"	    :""


        // },
        {

            "print"		    :'txt',
            "name"		    :'Txt',
            "value"		    :[

                {

                    // boxCall:'txt',
                    // boxWidth:"100%",
                    // boxHeight:230,

                    className:"Note",
                    txt:summary_note.second.note,
                    style:{

                        //fontSize:"24px",
                        textAlign:"center"

                    }

                }

            ],
            "required"	    :"",
            "default"	    :""


        },
        {

            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{

                backgroundColor:"transparent",
                height:summary_note.second.spacer.height
            },
            "required"	    :"",
            "default"	    :""


        },












        //-----------------------------------
        // BarChart - Direct / Indirect
        //-----------------------------------

        //bar
        {

            "print"		    :'bar',
            "name"		    :'Direct / Indirect Labor',
            "value"		    :"",
            "required"	    :"",
            "default"	    :"",

        },



        //spacer
        {

            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{

                backgroundColor:"transparent",
                height:"30px"
            },
            "required"	    :"",
            "default"	    :""

        },



        //chart
        {


            "print"		    :'singleBarChart',
            "name"		    :'The Chart',
            "value"		    :[

                {
                    chartType:"horizontal",
                    chartLines:true,
                    data:[

                        {
                            name:'Direct Labor %',
                            value:"2500.00",
                            percent:empl_labor_percent_direct,
                        },

                        {
                            name:'Indirect Labor %',
                            value:"0.00",
                            percent:empl_labor_percent_indirect,
                        }

                    ]

                }
            ],
            "required"	    :"",
            "default"	    :"",



        },


        //spacer
        {

            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{

                backgroundColor:"transparent",
                height:"25px"
            },
            "required"	    :"",
            "default"	    :""

        },






        //-----------------------------------
        // Ledger
        //-----------------------------------
    
        // {
    
        //     "print"		    :'bar',
        //     "name"		    :'Job Descriptions',
        //     "value"		    :"",
        //     "required"	    :"",
        //     "default"	    :""
    
    
        // },
        {
    
            "print"		    :'ledger',
            "name"		    :"Ledger",
            "value"		    :[
    
    
    
                {
                    name:"Direct",
                    value:empl_labor_direct
    
                },
                {
                    name:"Indirect",
                    value:empl_labor_indirect
    
                }
    
            ],
            "required"	    :"",
            "default"	    :""
    
    
        },
    
        {
    
            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{
    
                backgroundColor:"transparent",
                height:"50px"
            },
            "required"	    :"",
            "default"	    :""
    
    
        },
    







        //-----------------------------------
        // Ledger
        //-----------------------------------

        {

            "print"		    :'bar',
            "name"		    :'Labor Hours',
            "value"		    :"",
            "required"	    :"",
            "default"	    :""


        },
        {

            "print"		    :'ledger',
            "name"		    :"Ledger",
            "value"		    :[



                {
                    name:"Direct Labor",
                    value:empl_hours_direct+" hrs"

                },
                {
                    name:"Direct Labor Hours Weekly",
                    value:empl_hours_direct+" hrs"
                },

                {
                    name:"Indirect Labor",
                    value:empl_hours_indirect+" hrs"

                },
                {
                    name:"Indirect Labor Hours Weekly",
                    value:empl_hours_indirect+" hrs"
                },
                {
                    name:"Total Weekly Hours",
                    value:empl_total_hours_weekly+" hrs"
                }


            ],
            "required"	    :"",
            "default"	    :""


        },

        {

            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{

                backgroundColor:"transparent",
                height:"50px"
            },
            "required"	    :"",
            "default"	    :""


        },








        //-----------------------------------
        // Ledger
        //-----------------------------------

        {

            "print"		    :'bar',
            "name"		    :'Healthcare',
            "value"		    :"",
            "required"	    :"",
            "default"	    :""


        },
        {

            "print"		    :'ledger',
            "name"		    :"Ledger",
            "value"		    :[


                {
                    name:"Health Insurance Monthly",
                    value:empl_health_insurance_monthly
                },

            ],
            "required"	    :"",
            "default"	    :""


        },

        {

            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{

                backgroundColor:"transparent",
                height:"50px"
            },
            "required"	    :"",
            "default"	    :""


        },





        //-----------------------------------
        // Ledger
        //-----------------------------------

        {

            "print"		    :'bar',
            "name"		    :'IRA',
            "value"		    :"",
            "required"	    :"",
            "default"	    :""


        },
        {

            "print"		    :'ledger',
            "name"		    :"Ledger",
            "value"		    :[


                {
                    name:"Contribution Monthly",
                    value:empl_direct_indirect_ira_monthly_total
                },

            ],
            "required"	    :"",
            "default"	    :""


        },

        {

            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{

                backgroundColor:"transparent",
                height:"50px"
            },
            "required"	    :"",
            "default"	    :""


        },





        

        //-----------------------------------
        // Ledger
        //-----------------------------------

        {

            "print"		    :'bar',
            "name"		    :'Vacation / Personal Time',
            "value"		    :"",
            "required"	    :"",
            "default"	    :""


        },
        {

            "print"		    :'ledger',
            "name"		    :"Ledger",
            "value"		    :[


                {
                    name:"Weeks",
                    value:empl_vacation_weeks
                }

            ],
            "required"	    :"",
            "default"	    :""


        },

        {

            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{

                backgroundColor:"transparent",
                height:"50px"
            },
            "required"	    :"",
            "default"	    :""


        },









        //-----------------------------------
        // Ledger
        //-----------------------------------

        {

            "print"		    :'bar',
            "name"		    :'Yearly Summary',
            "value"		    :"",
            "required"	    :"",
            "default"	    :""


        },
        {

            "print"		    :'ledger',
            "name"		    :"Ledger",
            "value"		    :[


                {
                    name:"Salary",
                    value: empl_direct_indirect_salary_yearly_total
                },
                {
                    name:"Health Insurance",
                    value:empl_health_insurance_yearly
                },
                {
                    name:"IRA",
                    value:empl_direct_indirect_ira_yearly_total
                },
                {
                    name:"Taxes",
                    value:empl_direct_indirect_tax_yearly_total
                },
                {
                    name:"Yearly Total",
                    value:empl_burden_yearly_total

                }


            ],
            "required"	    :"",
            "default"	    :""


        },

        {

            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{

                backgroundColor:"transparent",
                height:"50px"
            },
            "required"	    :"",
            "default"	    :""


        }



    ];


    return reportItems;


}


