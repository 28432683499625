
import styled from "styled-components";
//import px2vw from "utils/px2vw";



export const MenuAlfa = styled.div.attrs(props => ({


    backgroundColor:    props.backgroundColor || "#1a1926",//#fcfcfc
    border:             props.border || "0",
    borderRadius:       props.borderRadius || "0",

    percent:           props.percent || "60", 
    decimal:           props.decimal || "0.6",

    zIndex:            props.zIndex || "10",

    width:            props.width || "100%", 
    height:           props.height || "100%",

    top:              props.top || "0px", 
    left:             props.left || "0px"


}))`
  

    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    border-radius:${props => props.borderRadius};

    width:${props => props.width};
    height:${props => props.height};

    padding :0 0 0 0; 
    margin  :0 0 0 0;

    position:absolute;   
    z-index:${props => props.zIndex};
    top:${props => props.top};
    left:${props => props.left};

    -ms-filter: "progid:pxImageTransform.Microsoft.Alpha(Opacity=${props => props.percent})";
    filter : alpha(opacity=${props => props.percent});
    -moz-opacity : ${props => props.decimal};
    -khtml-opacity : ${props => props.decimal};
    opacity : ${props => props.decimal};


`;






export const Menu = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#EDEEF1",

    height:             props.height || "100%",
    margin:             props.margin || "0 0 0 0",

}))`

    background-color:${props => props.backgroundColor};
    border-right:1px solid lightseagreen;

    max-width:300px;
    width:300px;
    height:${props => props.height};

    padding:40px 0 0 0;
    margin:${props => props.margin};

    position:absolute;
    top:0px;
    left:0px;
    z-index:11;

    @media (max-width: 1024px) {

        background-color:#EDEEF1;

        border:0;
        border-radius:0;

    }

`;





export const MenuControls = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "white",
    border:             props.border || "1px solid lightgray",

    width:              props.width || "100%",
    height:             props.height || "40px",
    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "0 0 0 0",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "20px",
    fontWeight:         props.fontWeight  || "500",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration  || "none",
    color:              props.color || "black"


}))`


    background-color:${props => props.backgroundColor};
    border-bottom:${props => props.border};

    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:absolute;
    top:0;
    right:0;
    z-index:12;

    overflow:hidden;


    .Title {

        background-color:transparent;

        width:auto;
        height:auto;
        padding:8px 0 8px 15px;
        margin:0 0 0 0;

        position:relative;
        float:left;

        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        text-align:${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};

    }




    .Button {

        background-color:transparent;

        width:auto;
        height:30px;
        padding:0 10px 0 10px;
        margin:2.5px 10px 0 0;
        position:relative;
        float:right;

        cursor:pointer;


        font-family:${props => props.fontFamily};
        font-size:30px;
        font-weight:${props => props.fontWeight};
        text-align:${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};

        &:hover{

            color:lightseagreen;

        }



    }

    .ButtonMask {

        background-color:transparent;

        width:30px;
        height:30px;
        padding:0 0 0 0;
        margin:0 0 0 0;
        position:absolute;
        top:0px;
        left:0px;
        z-index:1;

        cursor:pointer;

    }


    .Img {

        width:30px;
        height:30px;

    }


`;





// export const MenuBase = styled.div.attrs(props => ({


//     backgroundColor:    props.backgroundColor || "transparent",//#fcfcfc
//     border:             props.border || "0",
//     borderRadius:       props.borderRadius || "0",

//     width:            props.width || "80%", 
//     height:           props.height || "80%",

//     zIndex:           props.zIndex || "11", 
//     top:              props.top || "10%", 
//     left:             props.left || "10%"



// }))`
  

//     background-color:${props => props.backgroundColor};
//     border:${props => props.border};
//     border-radius:${props => props.borderRadius};


//     width:${props => props.width};
//     height:${props => props.height};

//     padding :0 0 0 0; 
//     margin  :0 0 0 0;


//     position:absolute;   
//     z-index:${props => props.zIndex};
//     top:${props => props.top};
//     left:${props => props.left};


//     overflow-x:hidden;
//     overflow-y:auto;


// `;




