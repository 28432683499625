import React, { 

    useState, 
    useEffect, 
    //useRef 

} from 'react';




import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    //useHistory,   
    //useParams,



} from "react-router-dom";

//import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider';
//import useDeviceDetect from 'utils/hooks/DeviceDetect';
// import useKeyPress from 'utils/hooks/KeyPress';




// import { createPortal } from 'react-dom';
// import usePortal from 'utils/hooks/Portal';



// import Navigator from 'utils/components/Content/Navigator';
// import SideNavInfo from 'utils/components/Pages/SceneInfo/Trials/SideNav/SceneInfo';



//=====================================================


import {

    //Alfa,
    //Base,
    Area,
    Panel,
    //Graphic

} from '../_Styled';



// import { Lorem } from 'app-components/Prime/Data';


import CreateMarkup from 'utils/components/Universal/CreateMarkup';
import CreateLink from 'utils/components/Universal/CreateLink';




const Index = (props) => {



    // -- Window Dimensions -- //
    // const { 
    //     width,
    //     //height 
    // } = useWindowDimensions();


    // -- Device Detect -- //
    //const DeviceDetection = useDeviceDetect();
    //console.log("FFcostfield - DeviceDetection " + JSON.stringify(DeviceDetection));
    //let isMobile = DeviceDetection.isMobile;



    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();

    
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);



    let mainpath;
    mainpath = pathname.split("/")[0];
    
    let subpath;
    subpath = pathname.split("/")[1];
    if(subpath === undefined) subpath = false;

    let sub2path;
    sub2path = pathname.split("/")[2];
    if(sub2path === undefined) sub2path = false;




    // -- redux -- //
    // const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);



    //=============================================
    // ref
    //=============================================

    //const refForm = useRef(null);

    /*
    const rowRefs = [];
    const setRowRef = (ref) => {

        if(ref && ref !== null && ref !== undefined)
        {
            //console.log("ref::::"+ref.id);
            var found;
            found = false;
            for(var i=0; i < rowRefs.length; i++)
            {
                if(rowRefs[i].id === ref.id) found = true;
            }
            if(!found) rowRefs.push(ref);
            console.log(rowRefs.length);
            console.log(rowRefs[0]);

        }

    };
    */



    //=============================================
    // state/effect - initial
    //=============================================

    const [isLoaded, setIsLoaded] = useState(false);
    const [styling, setStyling] = useState({

        backgroundColor:"#141820"

    });
    const [panelData, setPanelData] = useState([]);
    // const [panelSize, setPanelSize] = useState({

    //     width:"100%",
    //     height:"auto"

    // });

    const [rowClass, setRowClass] = useState("Row");



    useEffect(() => {

        if(!isLoaded)
        {
            setIsLoaded(true);

   

        }

        // console.log("props:"+JSON.stringify(props.panelStatus,null,2));
        // alert('props');

        //if(props) alert('has props');


        if(props.panelStatus)
        {

            var isSub = false;



            var mainZone;
            //var zoneName;
            mainZone = mainpath;
            if(mainZone.match(/ /gi)) 
                mainZone = mainZone.replace(/ /gi,"");
            mainZone = mainZone.toLowerCase();

            var subZone = subpath.toString();
            if(subZone.match(/ /gi)) 
                subZone = subZone.replace(/ /gi,"_");


            if(subpath && subpath !== undefined)
            {
                isSub = true;
                //zoneName = subpath;
            }else{
                //zoneName = mainpath;
            }



            //------------------------------------------------

            if(props.panelStatus.call === 'off')
            {
                setStyling({

                    backgroundColor:"transparent"
                });

            }else{

                setStyling({

                    backgroundColor:"#141820"
                });

            }





            //-------------------------------------------------

            if(props.panelStatus.call === 'howto'
            || props.panelStatus.call === 'ads' 
            || props.panelStatus.call === 'calc' 
            || props.panelStatus.call === 'readout'
            )
            {

                //alert('panelData');
                //setPanelData(props.panelStatus.data);


                // if(props.panelStatus.call === 'calc')
                // {
                 
                //     setRowClass("Row");

                //     if(zoneObj.info_panel)
                //     {
                //         if(zoneObj.info_panel.calc)
                //         {
        
                //             setPanelData(zoneObj.info_panel.calc);
        
                //         }
                //     }
        
        
        
                // }
                // else
                // if(props.panelStatus.call === 'readout')
                // {
    
                //     setRowClass("Row");

                //     if(zoneObj.info_panel)
                //     {
                //         if(zoneObj.info_panel.readout)
                //         {
           
                //             setPanelData(zoneObj.info_panel.readout);
        
                //         }
                //     }
        
        
                // }
                // else
                if(props.panelStatus.call === 'ads')
                {
    
                    setRowClass("AdRow");

                    if(zoneObj.info_panel)
                    {
                        if(zoneObj.info_panel.ads)
                        {
        
                            setPanelData(zoneObj.info_panel.ads);
        
                        }
                    }
        
                }
                else
                if(props.panelStatus.call === 'howto')
                {
    
                    setRowClass("Row");
                    // if(zoneObj.info_panel.howto)
                    // {
                    //     // console.log("Gotit: "+JSON.stringify(zoneObj.info_panel.howto,null,2));
                    //     // alert('Gotit '+Object.prototype.toString.call(zoneObj.info_panel.howto));
                    //     setPanelData(zoneObj.info_panel.howto);
                    // }


                    if(zoneObj.info_panel)
                    {

                        switch(mainZone)
                        {
                        case'calculator':

                            if(isSub) isSub = false;

                            if(zoneObj.info_panel.calc)
                            {
                                //console.log("Gotit: "+JSON.stringify(zoneObj.info_panel.calc,null,2));
                                //alert('Gotit '+Object.prototype.toString.call(zoneObj.info_panel.calc));
                                //setPanelData(zoneObj.info_panel.calc.howto);

                                if(location.hash === "#!Calculator~readout")
                                {

                                    setPanelData(zoneObj.info_panel.calc.readout);
                                }
                                else
                                {
                                    setPanelData(zoneObj.info_panel.calc.howto);
                                }




                            }

                        break;
                        case'pricing':


                            if(zoneObj.info_panel.pricing)
                            {
                                //console.log("Gotit: "+JSON.stringify(zoneObj.info_panel.pricing,null,2));
                                //alert('Gotit '+Object.prototype.toString.call(zoneObj.info_panel.pricing));
                                //setPanelData(zoneObj.info_panel.pricing);

                                if(location.hash === "#!Report")
                                {
                                    setPanelData(zoneObj.info_panel.calc.report); 
                                }
                                else
                                if(location.hash === "#!Calculator")
                                {

                                    setPanelData(zoneObj.info_panel.calc.howto);
                                }
                                else
                                if(location.hash === "#!Calculator~readout")
                                {

                                    setPanelData(zoneObj.info_panel.calc.readout);
                                }
                                else
                                {
                                    setPanelData(zoneObj.info_panel.pricing);
                                }


                            }


                        break; 
                        case'settings':

                            if(isSub)
                            {
                                switch(subZone.toLowerCase())
                                {
                                case'account':

                                    setPanelData(zoneObj.info_panel.appSettings.account);
                                break;
                                case'schedule':

                                    setPanelData(zoneObj.info_panel.appSettings.schedule);
                                break;
                                case'overhead':

                                    setPanelData(zoneObj.info_panel.appSettings.overhead);
                                break;
                                case'expenses':

                                    setPanelData(zoneObj.info_panel.appSettings.expenses);
                                break;
                                case'owners':

                                    setPanelData(zoneObj.info_panel.appSettings.owners);
                                break;
                                case'employees':

                                    setPanelData(zoneObj.info_panel.appSettings.employees);
                                break;
                                default:
                                    setPanelData(zoneObj.info_panel.appSettings.overview);
                                }
        
                            }else{

                                setPanelData(zoneObj.info_panel.appSettings.overview);
                            }

                        break;
                        case'reports':

                            if(isSub)
                            {
                                switch(subZone.toLowerCase())
                                {
                                case'overhead':

                                    setPanelData(zoneObj.info_panel.appReports.overhead);
                                break;
                                case'expenses':

                                    setPanelData(zoneObj.info_panel.appReports.expenses);
                                break;
                                case'healthcare':

                                    setPanelData(zoneObj.info_panel.appReports.healthcare);
                                break;
                                case'ira':

                                    setPanelData(zoneObj.info_panel.appReports.ira);
                                break;
                                case'direct_labor_rates':

                                    setPanelData(zoneObj.info_panel.appReports.rates);
                                break;
                                case'direct_labor_hours':

                                    setPanelData(zoneObj.info_panel.appReports.hours);
                                break;
                                case'owners':

                                    setPanelData(zoneObj.info_panel.appReports.owners);
                                break;
                                case'employees':

                                    setPanelData(zoneObj.info_panel.appReports.employees);
                                break;
                                default:
                                    setPanelData(zoneObj.info_panel.appReports.overview);
                                }
        
                            }else{

                                setPanelData(zoneObj.info_panel.appReports.overview);
                            }

                        break;
                        case'knowledgebase':


                            if(isSub) isSub = false;

                        break;
                        default:



                        }



                    }
        
                }
                else
                {

                    setPanelData(props.panelStatus.data);

                }




            }



        
        }





    },
    [
        props,
        location,
        mainpath,subpath,
        zoneObj,
        isLoaded

    ])











    //const portalTarget = usePortal("scrollbox");
    //const portalTarget = usePortal("side2");






    if(!isLoaded)
    {

        // return createPortal(<div>
        return (<div>

        </div>);
        // </div>, portalTarget);


    }else{


        // return createPortal(<div>
        return (<div>



            <Panel
            backgroundColor={styling.backgroundColor}
            //width={panelSize.width}
            //height={panelSize.height}
            width={"100%"}
            height={"auto"}
            >

                <Area
                >

                    <div className="Bar">


                        <div className="XButton"
                        onClick={(v) => props.handlePanel({

                            call:'close'
            
                        })}
                        >
                            X
                    
                        </div>



                        {
                        props.panelStatus.call === 'ads'
                        ?

                            <div className="ButtonHighlight">

                                <div className="Name">

                                    Sponsors
                                </div>
                                <div className="Underline"></div>

                            </div>


                        :

                            <div className="Button"
                            onClick={(v) => props.handlePanel({

                                call:'ads'
                
                            })}
                            >
                                Sponsors
                        
                            </div>
                        }




                        {
                        props.panelStatus.call === 'howto'
                        ?

                            <div className="ButtonHighlight">

                                <div className="Name">

                                    Info.
                                </div>
                                <div className="Underline"></div>

                            </div>


                        :

                            <div className="Button"
                            onClick={(v) => props.handlePanel({

                                call:'howto'
                
                            })}
                            >
                                Info.
                        
                            </div>

                        }



                    
                    </div>



                    <div className="Bod">


                        {/* <div className="Graphic"></div>  */}


                        {/* <div className="Heading">

                            {props.portalElement}

                        </div>
                        <div className="Pgraph">

                            {Lorem}

                        </div>

                        */}



                        {
                        props.panelStatus.call === 'ads'
                        ?

                            panelData.map((dat, i) => (

                                <div
                                key={i}
                                className={rowClass}
                                >

                                    <div className="Box">


                                        {
                                        dat.image
                                        ?

                                            <div className="Image" 
                                            style={{
                                                backgroundColor:"transparent"
                                            }}
                                            >

                                                <img
                                                style={{

                                                    flexShrink: 0,
                    
                                                    width:"auto",
                                                    height:"auto",
                    
                                                    //minWidth:box.image.maxWidth,
                                                    //minHeight: "100%",
                    
                                                    maxWidth:"100%",
                                                    maxHeight:"200px",
                    
                                                    marginTop:"0px",
                                                    marginLeft:"0px"
                    
                                                }}
                    
                                                    src={dat.image.src} 
                                                    width={dat.image.w} 
                                                    height={dat.image.h} 
                                                    alt="adimg"
                                                />

                                            </div>


                                        :null
                                        }

                                
                                        <div className="Heading"
                                        >
                                        
                                    
                                            <div dangerouslySetInnerHTML={CreateMarkup(dat.heading)} />

                                        </div>                          

                                
                                    </div>




                                    <div className="Pgraph">

                        
                                        <div dangerouslySetInnerHTML={CreateMarkup(dat.pgraph)} />

                                    </div> 





                                    {
                                    dat.bullet && dat.bullet !== undefined
                                    ?
                                        dat.bullet.length > 0
                                        ?
                                            <div className="List">

                                                {
                                                //Object.prototype.toString.call(dat.bullet)
                                                dat.bullet.map((itm, i) => (

                                                    <div key={i}
                                                    className="ListItem"
                                                    // onClick={() => handleUrl({

                                                    //     //primeHandle : (v) => props.handle(v),
                                                    
                                                    //     call        :'url',
                                                    //     data        :"Calcs"
                                                    
                                                    // })}
                                                    >
            
                                                        <div className="Bullet" 
                                                        // style={{
                                                        //     backgroundColor:dat.color
                                                        // }}
                                                        > 
                                                        </div>

                                                        <div className="Text">{itm}</div>

                                                    </div>

            
                                                ))
                                                }

                                            </div>

                                        :null

                                    :null
                                    }




                                    {
                                    dat.numbrd && dat.numbrd !== undefined
                                    ?
                                        dat.numbrd.length > 0
                                        ?

                                            <div className="List">

                                                {
                                                
                                                //Object.prototype.toString.call(dat.numbrd)

                                                dat.numbrd.map((itm, i) => (

                                                    <div key={i} 
                                                    className="ListItem"
                                                    >

                                                        <div className="Number">{i+1}.</div>
                                                        <div className="Text">{itm}</div>

                                                    </div>

                                                ))

                                                }

                                            </div>
                                                        

                                        :null


                                    :null
                                    }

        



                                    {
                                    dat.linx && dat.linx !== undefined
                                    ?
                                        dat.linx.length > 0
                                        ?

                                            <div className="Nav">

                                                {
                                                //Object.prototype.toString.call(dat.bullet)
                                                dat.linx.map((itm, i) => (


                                                    <div key={i}>

                                                        {
                                                        itm.value.match(/site.site/gi)
                                                        ?

                                                            <div
                                                            className="NavLink"
                                                            onClick={(v) => props.handlePanel({

                                                                call      :"url",
                                                                data      :itm.value

                                                            })}
                                                            >
                                                                {itm.name}

                                                            </div>


                                                        :


                                                            <div
                                                            className="NavLink"
                                                            onClick={(v) => props.handlePanel({

                                                                call      :"url",
                                                                data      :itm.value

                                                            })}
                                                            >

                                                                {itm.name}

                                                            </div>

                                                        }

                                                    </div>
            
                                                ))

                                                }

                                            </div>

                                        :null


                                    :null
                                    }



                                    {
                                    dat.buttons && dat.buttons !== undefined
                                    ?
                                        dat.buttons.length > 0
                                        ?

                                            <div className="Nav">

                                                {
                                                
                                                //Object.prototype.toString.call(dat.numbrd)

                                                dat.buttons.map((itm, i) => (

                                                    <div key={i}>

                                                        {
                                                        itm.value.match(/site.site/gi)
                                                        ?

                                                            <div
                                                            className="NavButton"
                                                            onClick={(v) => props.handlePanel({

                                                                call      :"url",
                                                                data      :itm.value

                                                            })}
                                                            >
                                                                {itm.name}

                                                            </div>


                                                        :

                                                            <a 
                                                            href={CreateLink({

                                                                name:itm.name,
                                                                value:itm.value

                                                            })}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            >

                                                                <div
                                                                className="NavButton"
                                                                >
                                                                    {itm.name}

                                                                </div>

                                                            </a>

                                                        }

                                                    </div>

                                                ))
                                                
                                                }

                                            </div>
                                                        

                                        :null

                                    :null
                                    }

        


                                </div>

                            ))



                        ://###################################################################

                        Object.prototype.toString.call(panelData) === '[object Array]'
                        ?

                            panelData.map((dat, i) => (

                                <div
                                key={i}
                                className={rowClass}
                                >

                                    {
                                    dat.heading && dat.heading !== ''
                                    ?

                                        <div
                                        className="Box"
                                        >
                                            <div className="Colorline" style={{backgroundColor:dat.color}}></div>
                                            <div className="Heading">

                            
                                                <div dangerouslySetInnerHTML={CreateMarkup(dat.heading)} />

                                            </div>

                                        </div>


                                    :null
                                    }


                                    {
                                    dat.pgraph && dat.pgraph !== ''
                                    ?


                                        <div className="Pgraph">

            
                                            <div dangerouslySetInnerHTML={CreateMarkup(dat.pgraph)} />

                                        </div> 

                                    :null
                                    }



                                    {
                                    dat.bullet && dat.bullet !== undefined
                                    ?
                                        dat.bullet.length > 0
                                        ?

                                            <div className="List">

                                                {
                                                //Object.prototype.toString.call(dat.bullet)
                                                dat.bullet.map((itm, i) => (

                                                    <div key={i}
                                                    className="ListItem"
                                                    // onClick={() => handleUrl({

                                                    //     //primeHandle : (v) => props.handle(v),
                                                    
                                                    //     call        :'url',
                                                    //     data        :"Calcs"
                                                    
                                                    // })}
                                                    >
            
                                                        <div className="Bullet" 
                                                        // style={{
                                                        //     backgroundColor:dat.color
                                                        // }}
                                                        > 
                                                        </div>

                                                        <div className="Text">{itm}</div>

                                                    </div>

            
                                                ))
                                                }

                                            </div>


                                        :null

                                    :null
                                    }




                                    {
                                    dat.numbrd && dat.numbrd !== undefined
                                    ?

                                        dat.numbrd.length > 0
                                        ?

                                            <div className="List">

                                                {
                                                
                                                //Object.prototype.toString.call(dat.numbrd)

                                                dat.numbrd.map((itm, i) => (

                                                    <div key={i} 
                                                    className="ListItem"
                                                    >

                                                        <div className="Number">{i+1}.</div>
                                                        <div className="Text">{itm}</div>

                                                    </div>

                                                ))

                                                }

                                            </div>
                                                        

                                        :null

                                    :null
                                    }






                                    {
                                    dat.linx && dat.linx !== undefined
                                    ?
                                        dat.linx.length > 0
                                        ?

                                            <div className="Nav">

                                                {
                                                //Object.prototype.toString.call(dat.bullet)
                                                dat.linx.map((itm, i) => (


                                                    <div key={i}>

                                                        {
                                                        itm.value.match(/site.site/gi)
                                                        ?

                                                            <div
                                                            className="NavLink"
                                                            onClick={(v) => props.handlePanel({

                                                                call      :"url",
                                                                data      :itm.value

                                                            })}
                                                            >
                                                                {itm.name}

                                                            </div>


                                                        :


                                                            <div
                                                            className="NavLink"
                                                            onClick={(v) => props.handlePanel({

                                                                call      :"url",
                                                                data      :itm.value

                                                            })}
                                                            >

                                                                {itm.name}

                                                            </div>

                                                        }

                                                    </div>
            
                                                ))

                                                }

                                            </div>

                                        :null


                                    :null
                                    }



                                    {
                                    dat.buttons && dat.buttons !== undefined
                                    ?
                                        dat.buttons.length > 0
                                        ?

                                            <div className="Nav">

                                                {
                                                
                                                //Object.prototype.toString.call(dat.numbrd)

                                                dat.buttons.map((itm, i) => (

                                                    <div key={i}>

                                                        {
                                                        itm.value.match(/site.site/gi)
                                                        ?

                                                            <div
                                                            className="NavButton"
                                                            onClick={(v) => props.handlePanel({

                                                                call      :"url",
                                                                data      :itm.value

                                                            })}
                                                            >
                                                                {itm.name}

                                                            </div>


                                                        :

                                                            <a 
                                                            href={CreateLink({

                                                                name:itm.name,
                                                                value:itm.value

                                                            })}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            >

                                                                <div
                                                                className="NavButton"
                                                                >
                                                                    {itm.name}

                                                                </div>

                                                            </a>

                                                        }

                                                    </div>

                                                ))
                                                
                                                }

                                            </div>
                                                        

                                        :null

                                    :null
                                    }




        

                                </div>


                            ))


                        :null
                        }



                    </div>

                </Area>

        
            </Panel>





        </div>);
        // </div>, portalTarget);

    }











}

export default Index;


