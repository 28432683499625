import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';



import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    //useHistory,   
    //useParams,


} from "react-router-dom";


// import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider';
// import useScreenOrientation from 'utils/hooks/ScreenOrientation';
// //import { useFetch }  from 'utils/hooks/Fetch';


import { Area } from 'utils/components/Pages/Styled';
import {

    Container
 
} from 'utils/components/Pages/AuthLogo/SignUpWalk/_Styled';

import FormComponent from 'utils/components/Form';


// import {Company as Composed} from '../Composers';

import FormBuilder from '../Compose/Builder';
import {Company as FormData} from '../Compose/FormData';



const Index = (props) => {



    const Composed = FormBuilder({array:FormData});


    // // -- window dims -- //
    // const { width, height } = useWindowDimensions();

    // // -- screen orientation -- //
    // const screenOrientation = useScreenOrientation()



    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();


    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);




    // let zoneId;
    // zoneId = pathname.replace(/\//gi,"-");


    // -- redux -- //
    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));



    // let currentZone = pathname;
    // currentZone = currentZone.replace(/\//gi,"-");
    // currentZone = currentZone.replace(/ /gi,"-");

    // //let zoneKey = currentZone + "_fetch";
    

    //------------------------------------------------------------------------------

    const refArea = useRef(null);
    const refContainer = useRef(null);


    // -- state -- //

    const [isLoaded, setIsLoaded] = useState(false);
    //const [databind, setDatabind] = useState(false);
    const [items, setItems] = useState([]);
    const [problemArr, setProblemArr] = useState([]);
    const [border, setBorder] = useState(false);

    useEffect(() => {

        if(!isLoaded)
        {
            setIsLoaded(true);

    
            if(props.items && props.items !== undefined)
            {
                setItems(props.items);   
            }else{
                setItems(Composed);         
            }

            setProblemArr([]);
            setBorder("0px solid #fff");

        }


        // if(!databind)
        // {
        //     setDatabind(props.databind);
        // }


        // if(props.databind.sceneArr.length > 0)
        // {


        //     console.log("AreaEffect --- props.databind.sceneArr: "+JSON.stringify(props.databind.sceneArr,null,2));
  

        // }




    },
    [
        props,
        isLoaded,
        //databind,
        zoneObj,
        Composed

    ])

    



    

    return (<div>

        <Area
        ref={refArea}
        //backgroundColor={'#191e28'}
        //height={"100vh"}
        >


            <Container
            ref={refContainer} 
            >
                <div className={'Bod'}>

                    <div className={'Heading'}>Company (Optional)</div>
                    <div className={'Pgraph'}>Please enter company info.</div>
                    <div className={'PrevNext'}>

     
                        <div className={'Button'}
                        onClick={(v) => props.handle({

                            call:"changeSection",
                            data:props.sections[parseInt(props.currentSection)-1]
                            
    
                        })}
                        >
                            {"< Prev"}
                            
                        </div>


                        <div className={'Button'}
                        style={{

                            float:"right"

                        }}
                        // onClick={(v) => props.handle({

                        //     call:"changeSection",
                        //     data:props.sections[parseInt(props.currentSection)+1]
                            
    
                        // })}

                        onClick={(v) => props.handle({

                            call:"submitCompany",
                            data:props.sections[parseInt(props.currentSection)+1],
                  
    
                        })}
                        >
                            {"Next >"}
                            
                        </div>


                    </div>



                </div>

                {
                props.errorArr.length > 0
                ?
                    <div className={'Err'}>

                        {props.errorArr.map((obj,i) => (

                            <div 
                            key={i}
                            className={'Line'}
                            >

                                {
                                //JSON.stringify(obj)
                                }

                                {obj.message}

                            </div>

                        ))}

                    </div>

                :null
                }




                <div className={'Frm'}>

                    <FormComponent 
                    
                        handleMovement={props.handleMovement}                   
                        handle={props.handle}
                        items={items}
                        problemArr={problemArr}
                        border={border}
                
                    
                    />

                </div>




                <div className={'Bod'}>

                    <div className={'Nav'}>


                        <div className={'NavButton'}

                        // onClick={(v) => props.handle({

                        //     call:"changeSection",
                        //     data:props.sections[parseInt(props.currentSection)+1],
                  
    
                        // })}

                        onClick={(v) => props.handle({

                            call:"submitCompany",
                            data:props.sections[parseInt(props.currentSection)+1],
                  
    
                        })}

                        >

                            Next
                            
                        </div>


                    </div>

                    <div className={'Nav'}>

                        <div className={'NavLink'}
                        onClick={(v) => props.handle({

                            call:"changeSection",
                            data:props.sections[parseInt(props.currentSection)-1]
                            
    
                        })}
                        >
                            {"<< Go Back"}
                            
                        </div>


                    </div>

                </div>


            </Container>




        </Area>

    </div>)





}

export default Index;


