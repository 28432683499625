import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';

/*
import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';
*/

import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    useHistory,   
    //useParams,



} from "react-router-dom";

import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'



import {

    //---------------
    // Stage
    //---------------

    Stage,
    ScrollBox,
    // Area,
    // AreaTitle,



    //---------------
    // Layout
    //---------------

    MenuAlfa,
    Menu,
    MenuControls,

    Topbar,

    Side,   
    Main,

    Rows,
    FullRow,
    IndentRow,

    // Box,

    // Tile,
    // TileName,
    // TileTop,
    // TileControls
    

} from 'app-components/Zone/_Styled';



// import StylScene from './_StylModules/Scene.module.css';
// // import StylPage from '../_StylModules/Page.module.css';
// // import StylRows from '../_StylModules/Rows.module.css';


import FGrid from 'utils/components/Grid/FGrid';
import VidGrid from 'utils/components/Grid/VidGrid';

import TxtComponent from 'utils/components/Content/Txt';
import ImgComponent from 'utils/components/Content/Img';
import CardComponent from 'utils/components/Card';
import Navigator from 'utils/components/Content/Navigator';

import SideNavInfo from 'utils/components/Pages/SceneInfo/Trials/SideNav/SceneInfo';


import SceneBuild from 'app-components/Zone/_Builder/SceneBuild';
import SceneInfo from './SceneInfo';



const Index = (props) => {



    
    const Scene = SceneBuild(SceneInfo());
    //console.log("ImaageGallery Scene:::: "+JSON.stringify(Scene,null,2));






    // -- window dims -- //
    const { 
        width,
        height 
    } = useWindowDimensions();


    // -- redux -- //
    //const zoneObj = useSelector(state => state.zone);

    /*
    var images = []; 
    if(zoneObj['images'] 
    && Object.prototype.toString.call(zoneObj['images'] === '[object Array]')
    )
    {
        images = zoneObj['images'];
    }
    */
 


    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    const history = useHistory();

    
 
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);




    // -- refs -- //

    /*
    const rowRefs = [];
    const setRowRef = (ref) => {

        if(ref && ref !== null && ref !== undefined)
        {
            //console.log("ref::::"+ref.id);
            var found;
            found = false;
            for(var i=0; i < rowRefs.length; i++)
            {
                if(rowRefs[i].id === ref.id) found = true;
            }
            if(!found) rowRefs.push(ref);
            console.log(rowRefs.length);
            console.log(rowRefs[0]);

        }

    };
    */


    // -- ref -- //
    const refScrollbox = useRef(null);
    const refTopbar = useRef(null); 
    const refSide = useRef(null);
    const refMain= useRef(null);



    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);
    const [styling,setStyling] = useState({

        topbar:{

            width:false,
            margin:false
        },     
        scrollbox:{
            
            width:false,
            height:false
        },
        main:{

            width:false,
            marginRight:false
        }

    });
    const [threshold] = useState(949);
    

    useEffect(() => {

    

        // if(!isLoaded)
        // {

            setIsLoaded(true);

            var mainWidth;
            var mainMargin;

            var mar;
            var maxMar;
            //alert(refMain.current.offsetWidth);
            if(refScrollbox.current.offsetWidth > 750)
            {

                mar = (refScrollbox.current.offsetWidth - refMain.current.offsetWidth)/2;
                if(refSide && refSide.current)
                {
                    maxMar = 200;
                    //console.log("mar"+mar);
                    if(mar > maxMar && refScrollbox.current.offsetWidth > refMain.current.offsetWidth+maxMar*2) mar = maxMar;
                    
                }
                //console.log("above");
                mainWidth = 750 + "px";
                mainMargin = "0 0 50px " +mar+"px";


                
            }else{


                if(refScrollbox.current.offsetWidth < 751)
                {
                    //console.log("above");
                    mainWidth = "100%";
                    mainMargin = "0 0 0 0";
    
                }else{

                    console.log("below");
                    mainWidth = "100%";
                    mainMargin = "0 0 50px 0px";

                }

            }



            var topbarObj;
            topbarObj = {

                width:"calc(100% - 0px)",
                margin:"0 0 50px 0"

            };
            var scrollboxObj;
            scrollboxObj = {

                width:"calc(100% - 0px)",
                height:"calc(100% - "+refTopbar.current.offsetHeight+"px)",
                margin:"0 0 50px 0"

            };
            if(refSide && refSide.current)
            {

                topbarObj = {
                
                    width:"calc(100% - "+refSide.current.offsetWidth+"px)",
                    margin:"0 0 0 "+refSide.current.offsetWidth+"px"
                }
                scrollboxObj = {
                
                    width:"calc(100% - "+refSide.current.offsetWidth+"px)",
                    height:"calc(100% - "+refTopbar.current.offsetHeight+"px)",
                    margin:"0 0 50px "+refSide.current.offsetWidth+"px"
                }

            }
            setStyling({

                topbar:topbarObj,
                scrollbox:scrollboxObj,
                main:{
        
                    width       :mainWidth,
                    margin      :mainMargin
                }
        
            });

        //}

        //console.log("scrollbox w: "+refScrollbox.current.offsetWidth);





    },
    [

        isLoaded,

        width,
        height,

        refSide,
        refMain,
        refScrollbox,
        refTopbar

    ])


    



    //=============================================
    // state/effect - showMenu
    //=============================================

    // -- state -- //
    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {

        if(width > threshold && showMenu)
        {
            setShowMenu(false);

        }
    },
    [

        width,
        threshold,
        showMenu
    ])





    //=============================================
    // state/effect - breadcrumbs
    //=============================================

    // -- state -- //
    const [breadcrumbs, setBreadcrumbs] = useState([]);


    useEffect(() => {

        // if(!isLoaded)
        // {
        //}
        //console.log("scrollbox w: "+refScrollbox.current.offsetWidth);
    
        var pathArr = pathname.split("/");
        var crumbs = ["Home","/"];
        var i;
        for(i=0; i < pathArr.length; i++)
        {
            if(i < pathArr.length-1)
            {
                crumbs.push(pathArr[i]);
                crumbs.push("/");
            }else{
                crumbs.push(pathArr[i]);
            }

        }
        setBreadcrumbs(crumbs);

    },
    [

        pathname,
        isLoaded

    ])






    //=============================================
    // handle
    //=============================================

    const handle = (hndl) => {


        switch(hndl.call)
        {
        case'breadcrumb':

            var pathArr = pathname.split("/");
            var url = "/";

            if(hndl.data !== 'Home')
            {
                var i;
                for(i=0; i < pathArr.length; i++)
                {
                    if(pathArr[i] === hndl.data)
                    {
                        url = url + hndl.data;
                        break;
                    }else{
                        url = url + pathArr[i]+"/";
                    }
                }

            }

            //if(url.charAt(url.length-1) === '/') 
                //url = url.substring(0,url.length-1);

            if(url.charAt(url.length-1) !== '/') url = url + "/";

            history.push(url);

        break;
        case'menu':

            switch(hndl.data)
            {
            case'close':
                setShowMenu(false);
            break;
            default:
                setShowMenu(true);
            }
        break;
        default:
        }


    }







    if(!isLoaded) 
    {

        //return <div className="area-title">Loading...</div>


        return (


            <Stage>

  
                <Side
                ref={refSide}
                >
                </Side>


                <Topbar
                ref={refTopbar}
                width={styling.topbar.width} 
                margin={styling.topbar.margin}
                >
                </Topbar>
                

                <ScrollBox
                ref={refScrollbox}
                width={styling.scrollbox.width} 
                height={styling.scrollbox.height} 
                margin={styling.scrollbox.margin}
                >

                    <Main
                    ref={refMain}
                    width={styling.main.width} 
                    margin = {styling.main.margin}
                    >

                    </Main>    

                </ScrollBox>


            </Stage>




        )



    } 
    else 
    {


        return (

            <Stage>


                {width < threshold && showMenu
                ?

                    <div>

                        <MenuAlfa
                        onClick={(v) => handle({

                            call:'menu',
                            data:"close"

                        })}
                        ></MenuAlfa>
                        <Menu
                        >
                            <MenuControls>

                                <div className="Title">

                                    {"Menu"}

                                </div>

                                <div 
                                className="Button"
                                onClick={(v) => handle({

                                    call:'menu',
                                    data:"close"
        
                                })}
                                >

                                    {"X"}
                                                                    
                                </div>

                            </MenuControls>

                            <Navigator

                                handle      = {(v) => props.handle(v)} 
                                data        = {SideNavInfo['dashboard_sidenav']}
                                refid       = {"dashboard_sidenav"}

                                viewHeight  = {false}
                                databind    = {props.databind}

                            />

                        </Menu>


                    </div>


                :null}


                {width > threshold
                ?

                    <Side
                    ref={refSide}
                    >
                        <Navigator

                            handle      = {(v) => props.handle(v)} 
                            data        = {SideNavInfo['dashboard_sidenav']}
                            refid       = {"dashboard_sidenav"}

                            viewHeight  = {false}
                            databind    = {props.databind}

                        />

                    </Side>


                :null}



                <Topbar
                ref={refTopbar}
                width={styling.topbar.width} 
                margin={styling.topbar.margin}
                >

                    <div
                    className={"Left"}
                    >


                        {
                        width < threshold
                        ?
                            <div
                            className={"MenuButton"}
                            onClick={(v) => handle({

                                call:'menu',
                                data:"open"

                            })}
                            >
                                <div className={"Slice"}></div>
                                <div className={"Slice"}></div>
                                <div className={"Slice"}></div>

                            </div>

                        :null
                        }





                        {breadcrumbs.map((crumb, i) => (

                            <div
                            key={i}
                            className={"Button"}
                            onClick={(v) => handle({

                                call:'breadcrumb',
                                data:crumb

                            })}
                            >
                                {crumb}

                            </div>

                        ))}





                    </div>


                    <div
                    className={"Right"}
                    >


                    </div>


                </Topbar>





                

                <ScrollBox
                ref={refScrollbox}
                width={styling.scrollbox.width} 
                height={styling.scrollbox.height} 
                margin={styling.scrollbox.margin}
                >

                    <Main
                    ref={refMain}
                    width={styling.main.width} 
                    margin = {styling.main.margin}
                    >

                        <div
                        ref={refMain}
                        >

                            <Rows>

                                {Scene.map((row, i) => (

                                    <div
                                    key={i}
                                    >

                                        {
                                        row.styl.call === 'indent' 
                                        ? 

                                            row.styl.class === 'IndentRowB' 
                                            ? 
                                                <IndentRow
                                                id={"pagerow_"+i}
                                                //backgroundColor={"red"}
                                                marginTop={row.styl.marginTop}
                                                marginBottom={row.styl.marginBottom}
                                                >

                                                    {

                                                    row.call === 'CardComponent' 
                                                    ? 
                                                        <CardComponent
                                                            handle = {(v) => props.handle(v)} 
                                                            info   = {row.info}         
                                                            items  = {row.items}
                                                            refid  = {"pagerow_"+i}

                                                        />

                                                    :
                                                    row.call === 'TxtComponent' 
                                                    ? 
                                                        <TxtComponent
                                                            handle = {(v) => props.handle(v)} 
                                                            info   = {row.info}         
                                                            items  = {row.items}
                                                            refid  = {"pagerow_"+i}

                                                        />

                                                    :
                                                    row.call === 'ImgComponent' 
                                                    ? 
                                                        <ImgComponent
                                                            handle = {(v) => props.handle(v)} 
                                                            info   = {row.info}         
                                                            items  = {row.items}
                                                            refid  = {"pagerow_"+i}

                                                        />

                                                    :
                                                    row.call === 'FGrid' 
                                                    ? 
                                                        <FGrid

                                                            handle = {(v) => props.handle(v)} 
                                                            info   = {row.info}          
                                                            items  = {row.items}
                                                            refid  = {"pagerow_"+i}


                                                        />

                                                    :
                                                    row.call === 'VidGrid' 
                                                    ? 
                                                        <VidGrid

                                                            handle = {(v) => props.handle(v)}  
                                                            info   = {row.info}          
                                                            items  = {row.items}
                                                            refid  = {"pagerow_"+i}


                                                        />

                                                    :

                                                        <div>No Row</div>

                                                    }  


                                                </IndentRow>




                                            ://========== Indent Row 



                                                <IndentRow
                                                id={"pagerow_"+i}
                                                //backgroundColor={"red"}
                                                marginTop={row.styl.marginTop}
                                                marginBottom={row.styl.marginBottom}
                                                >


                                                    {

                                                    row.call === 'CardComponent' 
                                                    ? 
                                                        <CardComponent
                                                            handle = {(v) => props.handle(v)}  
                                                            info   = {row.info}         
                                                            items  = {row.items}
                                                            refid  = {"pagerow_"+i}

                                                        />

                                                    :
                                                    row.call === 'TxtComponent' 
                                                    ? 
                                                        <TxtComponent
                                                            handle = {(v) => props.handle(v)}  
                                                            info   = {row.info}         
                                                            items  = {row.items}
                                                            refid  = {"pagerow_"+i}

                                                        />

                                                    :
                                                    row.call === 'ImgComponent' 
                                                    ? 
                                                        <ImgComponent
                                                            handle = {(v) => props.handle(v)}  
                                                            info   = {row.info}         
                                                            items  = {row.items}
                                                            refid  = {"pagerow_"+i}

                                                        />

                                                    :
                                                    row.call === 'FGrid' 
                                                    ? 
                                                        <FGrid

                                                            handle = {(v) => props.handle(v)}  
                                                            info   = {row.info}          
                                                            items  = {row.items}
                                                            refid  = {"pagerow_"+i}


                                                        />

                                                    :
                                                    row.call === 'VidGrid' 
                                                    ? 
                                                        <VidGrid

                                                            handle = {(v) => props.handle(v)}  
                                                            info   = {row.info}          
                                                            items  = {row.items}
                                                            refid  = {"pagerow_"+i}


                                                        />

                                                    :

                                                        <div>No Row</div>

                                    
                                                    }  


                                                </IndentRow>




                                        ://========= FULL ROW




                                            <FullRow
                                            id={"pagerow_"+i}
                                            //backgroundColor={"green"}
                                            marginTop={row.styl.marginTop}
                                            marginBottom={row.styl.marginBottom}
                                            >

                                                {
                                                
                                                row.call === 'CardComponent' 
                                                ? 
                                                    <CardComponent
                                                        handle = {(v) => props.handle(v)}  
                                                        info   = {row.info}         
                                                        items  = {row.items}
                                                        refid  = {"pagerow_"+i}

                                                    />

                                                :
                                                row.call === 'TxtComponent' 
                                                ? 
                                                    <TxtComponent
                                                        handle = {(v) => props.handle(v)}  
                                                        info   = {row.info}         
                                                        items  = {row.items}
                                                        refid  = {"pagerow_"+i}

                                                    />

                                                :
                                                row.call === 'ImgComponent' 
                                                ? 
                                                    <ImgComponent
                                                        handle = {(v) => props.handle(v)}  
                                                        info   = {row.info}         
                                                        items  = {row.items}
                                                        refid  = {"pagerow_"+i}

                                                    />

                                                :
                                                row.call === 'FGrid' 
                                                ? 
                                                    <FGrid

                                                        handle = {(v) => props.handle(v)}  
                                                        info   = {row.info}          
                                                        items  = {row.items}
                                                        refid  = {"pagerow_"+i}


                                                    />

                                                :
                                                row.call === 'VidGrid' 
                                                ? 
                                                    <VidGrid

                                                        handle = {(v) => props.handle(v)}  
                                                        info   = {row.info}          
                                                        items  = {row.items}
                                                        refid  = {"pagerow_"+i}


                                                    />

                                                :

                                                    <div>No Row</div>


                                                }  


                                            </FullRow>



                                        }


                                    </div>

                                ))}

                            </Rows>


                        </div>

                    </Main>  


                </ScrollBox>


        
            </Stage>

        )


    }

}

export default Index;


