const Index = {


    "type":"dataArray",
    "array":[





        {
            "styl":{

                fieldType:"heading"

            },
            "data":{

                "text"  :"Profit Calculator"
    
            }

        },



        //TM: 1
        //#############################################################
        //#############################################################
        // Selling Price


        {
            "styl":{

                fieldType:"graphictext"

            },
            "data":{

                "name": "Selling Price",
                "value":{

                    "graphic"      :{

                        "print"		:'svg',
                        "value"		:"price_tag",
                        "default"	:"",
                        "style"     :{
                            outerColor  : "rgb(255,255,255)",
	                        innerColor  : "rgb(30,35,45)",
                            signColor   : "#00e070" // #00cc66
                        }


                    },
                    "text"    :{

                        "print"		:'text',
                        "value"		:[                           
                        
                            {
                                "text":"Selling Price",
                                "color":"#ffffff"
                            },
                            {
                                "text":"$250.00",
                                "color":"#00e070"
                            }
                        ],
                        "default"	:""

                    }
  

                }
    
            }

        },



        {
            "styl":{

                fieldType:"spacer"

            },
            "data":{

                "name"		:"spacer",
                "color"   	:'#454D5F',
                "height"  	:'20px'
        
            }

        },  




        //TM: 3
        //#############################################################
        //#############################################################
        // SELLING PRICE - ledger


        {
            "styl":{

                fieldType:"ledger"

            },
            "data":{

                "name": "Ledger",
                "value":{

                    "total_price" : {

                        "print"		:'price',
                        "name"		:'Total Price',
                        "value"		:"100.00",
                        "required"	:true,
                        "default"	:"",
                        "linecolor" :"yellow"
        
                    },


                    "quantity" : {

                        "print"		:'text',
                        "name"		:'Quantity',
                        "value"		:"5",
                        "required"	:true,
                        "default"	:"",
                        "linecolor" :"yellow"
                    },



                    "price_each" : {

                        "print"		:'price',
                        "name"		:'Price Each',
                        "value"		:"15.00",
                        "required"	:true,
                        "default"	:"",
                        "linecolor" :"yellow"
                    }


                }

            },
            "styling":{

                lineColor:"lightgray"

            }

        },




        {
            "styl":{

                fieldType:"spacer"

            },
            "data":{

                "name"		:"spacer",
                "color"   	:'#454D5F',
                "height"  	:'75px'
        
            }

        },  


        // {
        //     "styl":{

        //         fieldType:"separator"

        //     },
        //     "data":{

        //         "name"		:"separator",
        //         "color"   	:'#454D5F',
        //         "height"  	:'10px'
        
        //     }

        // },




        









        //TM: 5
        //#############################################################
        //#############################################################
        // Net Profit 


        {
            "styl":{

                fieldType:"graphiclinetext"

            },
            "data":{

                "name": "Net Profit",
                "value":{

                    "graphic"      :{

                        "print"		:'svg',
                        "value"		:"profit_pie",
                        "default"	:"",
                        "style"     :{
                            pieceColor  : "rgb(0, 204, 99)",
	                        pieColor    : "rgb(255,255,255)"   
                        }

                    },
                    "text"    :{

                        "print"		:'text',
                        "value"		:[                           
                        
                            {
                                "text":"Net Profit",
                                "color":"#fff"
                            },
                            {
                                "text":"$125.00",
                                "color":"#00e070" // #00cc66
                            }
                        ],
                        "default"	:""

                    }

                }
    
            }

        },



        // {
        //     "styl":{

        //         fieldType:"spacer"

        //     },
        //     "data":{

        //         "name"		:"spacer",
        //         "color"   	:'#454D5F',
        //         "height"  	:'20px'
        
        //     }

        // },  





        //TM: 6
        //#############################################################
        //#############################################################
        // pricepercent - graphic ledger


        {
            "styl":{

                fieldType:"ledgerplus"

            },
            "data":{

                "name": "Price Percent",
                "value":{

                    "cost_of_goods" : {

                        "print"		:'text',
                        "name"		:'Cost Of Goods',
                        "value"		:{

                            "price"      :{

                                "print"		:'price',
                                "value"		:20,
                                "default"	:20

                            },
                            "percent"    :{

                                "print"		:'percent',
                                "value"		:1.5,
                                "default"	:1.5

                            }
   

                        },
                        "required"	:true,
                        "default"	:"",
                        "linecolor" :"yellow"
        
                    },


                    "overhead" : {

                        "print"		:'text',
                        "name"		:'Overhead',
                        "value"		:{

                            "price"      :{

                                "print"		:'price',
                                "value"		:20,
                                "default"	:20


                            },
                            "percent"    :{

                                "print"		:'percent',
                                "value"		:1.5,
                                "default"	:1.5

                            }
   

                        },
                        "required"	:true,
                        "default"	:"",
                        "linecolor" :"yellow"
                    },



                    "commission" : {

                        "print"		:'text',
                        "name"		:'Commission',
                        "value"		:{

                            "price"      :{

                                "print"		:'price',
                                "value"		:20,
                                "default"	:20


                            },
                            "percent"    :{

                                "print"		:'percent',
                                "value"		:1.5,
                                "default"	:1.5

                            }
   

                        },
                        "required"	:true,
                        "default"	:"",
                        "linecolor" :"yellow"
                    },



                    // "fees" : {

                    //     "print"		:'text',
                    //     "name"		:'Fees',
                    //     "value"		:{

                    //         "price"      :{

                    //             "print"		:'price',
                    //             "value"		:20,
                    //             "default"	:20


                    //         },
                    //         "percent"    :{

                    //             "print"		:'percent',
                    //             "value"		:1.5,
                    //             "default"	:1.5

                    //         }
   

                    //     },
                    //     "required"	:true,
                    //     "default"	:"",
                    //     "linecolor" :"yellow"
                    // },




                    "net_profit" : {

                        "print"		:'text',
                        "name"		:'Net Profit',
                        "value"		:{

                            "price"      :{

                                "print"		:'price',
                                "value"		:20,
                                "default"	:20


                            },
                            "percent"    :{

                                "print"		:'percent',
                                "value"		:1.5,
                                "default"	:1.5

                            }
   

                        },
                        "required"	:true,
                        "default"	:"",
                        "linecolor" :"yellow"
                    }


      

                }

            }

        },




        {
            "styl":{

                fieldType:"spacer"

            },
            "data":{

                "name"		:"spacer",
                "color"   	:'#454D5F',
                "height"  	:'75px'
        
            }

        },  


        // {
        //     "styl":{

        //         fieldType:"separator"

        //     },
        //     "data":{

        //         "name"		:"separator",
        //         "color"   	:'#454D5F',
        //         "height"  	:'10px'
        
        //     }

        // },








        //TM: 8
        //#############################################################
        //#############################################################
        // Breakeven


        {
            "styl":{

                fieldType:"graphiclinetext"

            },
            "data":{

                "name": "Breakeven Price",
                "value":{

                    "graphic"      :{

                        "print"		:'svg',
                        "value"		:"breakeven_tag",
                        "default"	:"",
                        "style"     :{
                            outerColor  : "rgb(255,255,255)",
	                        innerColor  : "rgb(30,35,45)",
                            signColor   : "#26d6cc"//"#20b2aa"
                        }

                    },
                    "text"    :{

                        "print"		:'text',
                        "value"		:[                           
                        
                            {
                                "text":"Breakeven Price",
                                "color":"#ffffff"
                            },
                            {
                                "text":"$100.00",
                                "color":"#26d6cc" //"#00ffff"
                            }
                        ],
                        "default"	:""

                    }
  

                }
    
            }

        },

        
        // {
        //     "styl":{

        //         fieldType:"spacer"

        //     },
        //     "data":{

        //         "name"		:"spacer",
        //         "color"   	:'#454D5F',
        //         "height"  	:'20px'
        
        //     }

        // },  







        //TM: 9
        //#############################################################
        //#############################################################
        // graphic - ledger



        {
            "styl":{

                fieldType:"ledger"

            },
            "data":{

                "name": "Ledger",
                "value":{

                    "cost_of_goods" : {

                        "print"		:'price',
                        "name"		:'Cost Of Goods',
                        "value"		:"100.00",
                        "required"	:true,
                        "default"	:"",
                        "linecolor" :"yellow"
        
                    },


                    "overhead" : {

                        "print"		:'price',
                        "name"		:'Overhead at 0% Net Profit',
                        "value"		:"5",
                        "required"	:true,
                        "default"	:"",
                        "linecolor" :"yellow"
                    },



                    "commission" : {

                        "print"		:'price',
                        "name"		:'Commission',
                        "value"		:"15.00",
                        "required"	:true,
                        "default"	:"",
                        "linecolor" :"yellow"
                    },


                    // "fees" : {

                    //     "print"		:'price',
                    //     "name"		:'Fees',
                    //     "value"		:"5",
                    //     "required"	:true,
                    //     "default"	:"",
                    //     "linecolor" :"yellow"
                    // },



                    "net_profit" : {

                        "print"		:'price',
                        "name"		:'Net Profit',
                        "value"		:"15.00",
                        "required"	:true,
                        "default"	:"",
                        "linecolor" :"yellow"
                    }


                }

            },
            "styling":{

                lineColor:"#00ffff"

            }

        },



        // {
        //     "styl":{

        //         fieldType:"separator"

        //     },
        //     "data":{

        //         "name"		:"separator",
        //         "color"   	:'#454D5F',
        //         "height"  	:'50px'
        
        //     }

        // },






        {
            "styl":{

                fieldType:"spacer"

            },
            "data":{

                "name"		:"spacer",
                "color"   	:'#454D5F',
                "height"  	:'40px'
        
            }

        },




        {
            "styl":{

                fieldType:"separator"

            },
            "data":{

                "name"		:"separator",
                "color"   	:'#454D5F',
                "height"  	:'30px'
        
            }

        },






        //#############################################################
        //#############################################################
        // button

        {
            "styl":{

                fieldType:"button"

            },
            "data":{

                "call"  :'edit',
                "data" :'',
                "text"  :'EDIT',


                // "class" :'button',
                // "funct"	:{

                //     "name":"Download",
                //     "object":false

                // },
                // "style":{

                //     bgImage         :"/sprites/calculator.png",
                //     bgPositionOver  :"-100px -0px",
                //     bgPositionOut   :"-150px -0px",
                //     bgColorOver     :"#00e070",
                //     bgColorOut      :"#191e28",
                //     txtColorOver    :"#191e28",
                //     txtColorOut     :"#00e070",
                //     brColorOver     :"#00e070",
                //     brColorOut      :"#00e070",

                // }

            }

        },


        {
            "styl":{

                fieldType:"button"

            },
            "data":{


                "call"  :'goBack',
                "data" :'',
                "text"  :'DONE',

                
                // "class" :'button',
                // "funct"	:{

                //     "name":"Cancel",
                //     "object":false

                // },


                // "style":{

                //     bgImage         :"/sprites/calculator.png",
                //     bgPositionOver  :"-100px -0px",
                //     bgPositionOut   :"-150px -0px",
                //     bgColorOver     :"#00e070",
                //     bgColorOut      :"#191e28",
                //     txtColorOver    :"#191e28",
                //     txtColorOut     :"#00e070",
                //     brColorOver     :"#00e070",
                //     brColorOut      :"#00e070",

                // }

                
            }

        },




        // {
        //     "styl":{

        //         fieldType:"separator"

        //     },
        //     "data":{

        //         "name"		:"separator",
        //         "color"   	:'#454D5F',
        //         "height"  	:'50px'
        
        //     }

        // },











        // //#############################################################
        // //#############################################################
        // // iconbutton


        // {
   
        //     "styl":{

        //         fieldType:"iconbutton"

        //     },
        //     "data"      :{

        //         "text"  :'Calculate',
        //         "class" :'icon-button',
        //         "funct"	:{

        //             "name":"FormSubmit",
        //             "object":false

        //         },
        //         "style":{

        //             bgImage         :"/sprites/calculator.png",
        //             bgPositionOver  :"-100px -0px",
        //             bgPositionOut   :"-150px -0px",
        //             bgColorOver     :"#00e070",
        //             bgColorOut      :"#191e28",
        //             txtColorOver    :"#191e28",
        //             txtColorOut     :"#00e070",
        //             brColorOver     :"#00e070",
        //             brColorOut      :"#00e070",


        //         }
                
        //     }

        // },





        // {
        //     "styl":{

        //         fieldType:"separator"

        //     },
        //     "data":{

        //         "name"		:"separator",
        //         "color"   	:'#454D5F',
        //         "height"  	:'50px'
        
        //     }

        // },








        //#############################################################
        //#############################################################
        // Debits

        // {
        //     "styl":{

        //         fieldType:"headline"

        //     },
        //     "data":{

        //         "text"  :"Debits"
    
        //     }

        // },





        // {
        //     "styl":{

        //         fieldType:"bluntfield"

        //     },
        //     "data":{

        //         "name"     : "BluntField Test",
        //         "value"    :{

        //             "commission" : {

        //                 "print"		:'percent',
        //                 "name"		:'Commission',
        //                 "value"		:"0.0",
        //                 "required"	:false,
        //                 "default"	:"",
        //                 "linecolor" :"orange",
        //             },

        //             "fees" : {

        //                 "print"		:'percent',
        //                 "name"		:'Fees',
        //                 "value"		:"0.0",
        //                 "required"	:false,
        //                 "default"	:"",
        //                 "linecolor" :"orange",
        //             }




        //         }

        //     }

        // },








        // {
        //     "styl":{

        //         fieldType:"button"

        //     },
        //     "data"      :{

        //         "text"  :'Add Another',
        //         "call":"add",
        //         "fieldCall":"bluntfield",
        //         "style":{

        //             width:"100%",
        //             fontSize:"16px",
                    
        //             bgColorOver:"transparent",
        //             txtColorOver:"orange",
        //             borderOver:"0",

        //             bgColorOut:"transparent",
        //             txtColorOut:"white",
        //             borderOut:"0",

        //         }
                
        //     }

        // },







        //#################################################################
        //#################################################################
        //iconbutton

        // {
        //     "styl":{

        //         fieldType:"separator"

        //     },
        //     "data":{

        //         "name"		:"separator",
        //         "color"   	:'#454D5F',
        //         "height"  	:'50px'
        
        //     }

        // },


        // {
   
        //     "styl":{

        //         fieldType:"iconbutton"

        //     },
        //     "data"      :{

        //         "text"  :'Calculate',
        //         "class" :'icon-button',
        //         "funct"	:{

        //             "name":"FormSubmit",
        //             "object":false

        //         },
        //         "style":{

        //             bgImage         :"/sprites/calculator.png",
        //             bgPositionOver  :"-100px -0px",
        //             bgPositionOut   :"-150px -0px",
        //             bgColorOver     :"#00e070",
        //             bgColorOut      :"#191e28",
        //             txtColorOver    :"#191e28",
        //             txtColorOut     :"#00e070",
        //             brColorOver     :"#00e070",
        //             brColorOut      :"#00e070",


        //         }
                
        //     }

        // },











        //#############################################################
        //#############################################################
        //button

        // {
        //     "styl":{

        //         fieldType:"button"

        //     },
        //     "data"      :{

        //         "text"  :'Test Dispatch',
        //         "class" :'button',
        //         "funct"	:{

        //             "name":"FormSubmit",
        //             "object":false

        //         }
                
        //     }

        // },



    






        // {
        //     "styl":{

        //         fieldType:"field"

        //     },
        //     "data":{

        //         "name"     : "Field Test",
        //         "value"    :{

        //             "company" : {

        //                 "print"		:'text',
        //                 "name"		:'Company',
        //                 "value"		:"",
        //                 "required"	:true,
        //                 "default"	:"Your Company"
        //             },
        //             "email" : {

        //                 "print"		:'text',
        //                 "name"		:'Email',
        //                 "value"		:"",
        //                 "required"	:true,
        //                 "default"	:"fake@gmail.com"
        //             },
        //             "address" : {

        //                 "print"		:'text',
        //                 "name"		:'Address',
        //                 "value"		:"",
        //                 "required"	:false,
        //                 "default"	:"123 Fake Street"
        //             },
        //             "city" : {

        //                 "print"		:'text',
        //                 "name"		:'City',
        //                 "value"		:"",
        //                 "required"	:false,
        //                 "default"	:"Anytown"
        //             },
        //             "state" : {

        //                 "print"		:'select',
        //                 "name"		:'State',
        //                 "value"		:"",
        //                 "required"	:false,
        //                 "default"	:"PA"
        //             },
        //             "country" : {

        //                 "print"		:'select',
        //                 "name"		:'Country',
        //                 "value"		:"",
        //                 "required"	:false,
        //                 "default"	:"US"
        //             },
        //             "zipcode" : {

        //                 "print"		:'text',
        //                 "name"		:'Zip Code',
        //                 "value"		:"",
        //                 "required"	:false,
        //                 "default"	:"55555"
        //             },
        //             "phone" : {

        //                 "print"		:'phone',
        //                 "name"		:'phone',
        //                 "value"		:"",
        //                 "required"	:false,
        //                 "default"	:"555-555-5555"
        //             }

        //         }

        //     }

        // },



        // {
        //     "styl":{

        //         fieldType:"separator"

        //     },
        //     "data":{

        //         "name"		:"separator",
        //         "color"   	:'#454D5F',
        //         "height"  	:'50px'
        
        //     }

        // },



        // {
        //     "styl":{

        //         fieldType:"labelfield"

        //     },
        //     "data":{

        //         "name"     : "LabelField Test",
        //         "value"    :{

        //             "company" : {

        //                 "print"		:'text',
        //                 "name"		:'Company',
        //                 "value"		:"",
        //                 "required"	:true,
        //                 "default"	:"Your Company"
        //             },
        //             "email" : {

        //                 "print"		:'text',
        //                 "name"		:'Email',
        //                 "value"		:"",
        //                 "required"	:true,
        //                 "default"	:"fake@gmail.com"
        //             },
        //             "address" : {

        //                 "print"		:'text',
        //                 "name"		:'Address',
        //                 "value"		:"",
        //                 "required"	:false,
        //                 "default"	:"123 Fake Street"
        //             }

      

        //         }

        //     }

        // }













    ]

}
    

export default Index;



