import React, { 

    useState, 
    useEffect, 
    //useRef 

} from 'react';


import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    //useHistory,   
    //useParams,



} from "react-router-dom";

// import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'


import { createPortal } from 'react-dom';
import usePortal from 'utils/hooks/Portal';





// import Navigator from 'utils/components/Content/Navigator';
// import SideNavInfo from 'utils/components/Pages/SceneInfo/Trials/SideNav/SceneInfo';




//=====================================================


import {

    Alfa,
    Base,
    Message

} from '../_Styled';






const Index = (props) => {



    const portalTarget = usePortal("stage");



    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();

    
 
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);



    // -- window dims -- //
    // const { 
    //     width,
    //     height 
    // } = useWindowDimensions();



    // -- redux -- //
    // const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);


 
    //=============================================
    // state/effect - display
    //=============================================

    const [isLoaded, setIsLoaded] = useState(false);
    const [actionCall, setActionCall] = useState(false);

    useEffect(() => {

        if(!isLoaded)
        {
            setIsLoaded(true);
            // console.log(JSON.stringify(props,null,2));
            // alert('generic message');
            if(props.actionCall && props.actionCall !== undefined)
            {
                setActionCall(props.actionCall);
            }

        }
  
    },
    [
        props,
        zoneObj,
        isLoaded,
    
    ])







    return createPortal(<div>


        <Alfa zIndex={"23"}></Alfa>
        <Base zIndex={"24"}>

            <Base zIndex={"2"}
            onClick={(v) => props.handle({

                call:'cancel'

            })}
            >
            </Base>

            <Message zIndex={"3"}>

                {
                props.call === 'showMessage'
                ?
                    <div className="Message"
                    style={{

                        paddingTop:"50px"
                    }}
                    >

                        {props.message}

                    </div>

                :
                props.call === 'showConfirm'
                ?


                    <div>


                        <div className="Message">

                            {/* {'Would you like to "Save" calculator settings?'} */}
                            {props.message}

                        </div>


                        {
                        actionCall
                        ?
                            <div 
                            className="SaveButton"
                            onClick={(v) => props.handle({

                                call:actionCall,//save or saveascalcs
                                action:'process'

                            })}
                            >
                                Submit
                            </div>

                        :null
                   
                        }



                        <div 
                        className="CancelButton"
                        onClick={(v) => props.handle({

                            call:'cancel'

                        })}
                        >
                            Cancel
                        </div>


                    </div>



                :

                    <div className="Message">

                        No Message

                    </div>

                }

            </Message>


        </Base>



    </div>, portalTarget);





}

export default Index;


