
import { useWindowDimensions } from '../../WindowDimensionsProvider';



import ExtraItems from './helpers/ExtraItems';
import BuildData from './helpers/BuildData';
import DistributeToColumns from './helpers/DistributeToColumns';
import FillColumns from './helpers/FillColumns';


export function useCollage(

    items, 
    info

) {

    //console.log("info: "+JSON.stringify(info,null,2));
    //if(info.gridCall === 'flyr') console.log("items::::: "+JSON.stringify(items,null,2));

    const { width } = useWindowDimensions()



    //#############################################
    //#############################################
    //#############################################

    var refWidth;
    var maxWidth;

    refWidth = width;
    if(info.thresh.refWidth) 
    {
        refWidth = info.thresh.refWidth;
        maxWidth = refWidth;
    }



    var totalRows;

    var totalCells;
    var across;
    var divider;

    var gridDif = 0;

    var gridWInt;
    var gridW;
    var gridMarl;

    var gutterSize;
  
    var columnWPerc;
    var columnWInt;
    var columnW;
    var columnMar;

    //var columnColors = ["red","blue","yellow","purple"];

    var cellWPerc;
    var cellWPercDif;
    var cellWInt;
    var cellW;
    var cellMar;

    var cellBorderDif; 
    var cellBorder;



    var containerWPerc;
    var containerWPercDif;
    var containerMar;
    var containerPad;


    var maskW;
    var maskH;
    var maskTop;
    var maskLeft;


    var thumbDif;

    var thumbWPerc;
    var thumbWPercDif;
    var thumbHPerc;

    var thumbWInt;
    var thumbW;
    var thumbHInt;
    var thumbH;
    var thumbMar;

    var thumbBorderThickness = 0;


    //var actualImgW;
    //var actualImgH;

    var imgW;
    var imgH;

    //var imgdims;


    var loopnum;
    //var array;
    var i;


    var count;
    //var number;


    //=============================================


    if(maxWidth)
    {

        gridWInt = maxWidth;
        gridW = gridWInt+"px";
        gridMarl = 0 + "px";

    }else{

        if(width < 1220)
        {
            gridWInt = (width - gridDif);
            gridW = gridWInt+"px";
            gridMarl = (gridDif/2) + "px";

        }else{

            //gridWInt = 1200;
            gridWInt = (width - gridDif);
            gridW = gridWInt+ "px";
            gridMarl = (width - gridWInt)/2 + "px";

        }//==

    }

 
    totalCells = info.cells.total;
    across = info.cells.across;

    //==============================================

    if(!across)
    {

        across = 4;

        if(refWidth < info.thresh.across1)
        {
            across = 1;
        }
        else 
        if(refWidth < info.thresh.across2)
        {
            across = 2;
        }
        else 
        if(refWidth < info.thresh.across3)
        {
            across = 3;
        }


    }//==



    /*

    if(items.length === 2) 
    {
        across = 2;
        if(info.cellClass === "C") across = 1;
    }

    if(items.length === 1) across = 1;

    if(info.gridCall === 'flyr') 
    {
        across = 1;

    }//=
    */






    if(items.length > 0)
    {

        if(info.view === 'fullScreen')
        {
            if(info.gridCall === 'grid_column_cell')
            {
                items = items.concat(items);
                items = items.concat(items);  
                items = items.concat(items); 
            }
        
        }
        if(info.gridCall !== 'grid_column_cell')
        {

            items = ExtraItems({
    
                info        :info,
                items       :items

            });

            //console.log(JSON.stringify(items,null,2));
            //alert('check');
        
            if(width < info.thresh.across1) 
            {
                // 1 across 1 image


                if(totalCells > 10)
                {
                    across = 3;
                    count = across * totalCells / info.cells.across;
                    loopnum = items.length-count;
                    for(i=0; i < loopnum; i++)
                    {
                        items.pop();
                    }
                    /*
                    if(items.length % across !== 0)
                    {
                        count = across * totalCells / info.cells.across;
                        loopnum = items.length-count;
                        for(i=0; i < loopnum; i++)
                        {
                            items.pop();
                        }
                    }
                    */
                }
                else
                if(items.length > 1)
                {
                    across = 3;
                    if(items.length > across)
                    {
                        //loopnum = items.length-across;
                        count = across * totalCells / info.cells.across;
                        loopnum = items.length-count;
                        for(i=0; i < loopnum; i++)
                        {
                            items.pop();
                        }

                    }
                }else{
                    across = 1;
                }

            }
            else
            if(width < info.thresh.across2) 
            {


                if(totalCells < 10 && items.length > 1)
                {
                    if(across > 3) across = 4;
                    else across = across - 1;

                    if(items.length > across && across < 5 && across > 2)
                    {
                        loopnum = items.length-across;
                        for(i=0; i < loopnum; i++)
                        {
                            items.pop();
                        }

                    }
                    // lose a row of images if greater than 4

                }
                else
                {

                    //across = across - 1;
                    if(across > 5)
                    {
                    
                        across = 5;
                        count = across * totalCells / info.cells.across;
                        loopnum = items.length-count;
                        for(i=0; i < loopnum; i++)
                        {
                            items.pop();
                        }
                        

                    }

                }


            }
       
        }

        totalRows = (items.length/across);

        //alert(""
        //+"\nTOTAL ROWS: "+totalRows
        //+"\n"
        //);
    }

    //==============================================







    // Every 1px == .25% 
    // - Example width: 24.75% / 1px gutter 
    // - Example 2 width:24.5% / 2px gutter;


    gutterSize = 0;
    if(info.gutter.boolean)
    {
        gutterSize = info.gutter.size;
    }



    //==================================================

    columnWInt = (gridWInt/across) - gutterSize;

    if(info.gutter.boolean)
    {
        switch(across)
        {
        case 4:

            columnWInt = (gridWInt/across) - (parseInt(gutterSize)/(across));
            columnWInt = columnWInt - (gutterSize/(across/3.2));
        break;
        case 3:

            columnWInt = (gridWInt/across) - (parseInt(gutterSize)/(across));
            columnWInt = columnWInt - (gutterSize/(across/2.2));
        break;
        case 2:

            columnWInt = (gridWInt/across) - (parseInt(gutterSize)/(across));
            columnWInt = columnWInt - (gutterSize/(across/1.2));
        break;
        default:

            divider = (across-1)+".2";
            divider = parseFloat(divider);

            cellWInt = (gridWInt/across) - (parseInt(gutterSize)/(across));
            cellWInt = cellWInt - (gutterSize/(across/divider));

        break;
        }

    }
    columnW = (columnWInt) + "px";
    columnMar = "0 0 0 0";
    if(info.gutter.boolean) columnMar = "0 "+(gutterSize/2)+"px 0 "+(gutterSize/2)+"px";
    
  




    //===============================================

    cellBorderDif = 0;
    if(info.cellBorder.boolean) cellBorderDif = (info.cellBorder.thickness * 2)

    cellBorder = info.cellBorder.thickness +"px solid "+info.cellBorder.color;

    cellWInt = (gridWInt/across) - gutterSize;



    thumbDif = info.thumb.diff;
    thumbWPercDif = 0;

    switch(info.gridCall)
    {
    case'grid_column_cell':
    //case'grid_cell':

        cellWInt = columnWInt;
        //cellMar = "0 0 "+(gutterSize)+"px 0";

        cellMar = (0)+"px "+(gutterSize/2)+"px "+(gutterSize)+"px "+(gutterSize/2)+"px";

        containerMar = (0)+"px "+(0)+"px "+(gutterSize/2)+"px "+(gutterSize/3)+"px";




        if(info.cellBorder.boolean)
        { 
            cellWInt = cellWInt - (info.cellBorder.thickness * 2.0);

            thumbWInt = (cellWInt - thumbDif) - (thumbBorderThickness*2);
            thumbMar = (thumbDif/2)+"px 0 "+(thumbDif/2)+"px "+(thumbDif/2)+"px";



        }else{

            switch(info.thumb.call)
            {
            case'flex_height':

                thumbWInt = cellWInt;
                thumbMar = "0 0 0 0"; 
                
                thumbWPercDif = 0;
            break;
            default:
                //thumbWPercDif = 1.7;
            break;
            }

        }


    break;
    default:




        if(info.gutter.boolean)
        {

            switch(across)
            {
            case 4:
    
                cellWInt = (gridWInt/across) - (parseInt(gutterSize)/(across));
                cellWInt = cellWInt - (gutterSize/(across/1.7));
            break;
            case 3:
    
                cellWInt = (gridWInt/across) - (parseInt(gutterSize)/(across));
                cellWInt = cellWInt - (gutterSize/(across/1.2));
            break;
            case 2:
    
                cellWInt = (gridWInt/across) - (parseInt(gutterSize)/(across));
                cellWInt = cellWInt - (gutterSize/(across*2));
            break;
            default:


                divider = 2.2;
                divider = parseFloat(divider);

                cellWInt = (gridWInt/across) - (parseInt(gutterSize)/(across));
                cellWInt = cellWInt - (gutterSize/(across/divider));


            break;
            }


        }


    
        cellMar = "0 0 "+(gutterSize/2)+"px "+(gutterSize/2)+"px";

        //if(info.gridCall === 'filmstrip') cellMar = "0 0 "+(gutterSize/2)+"px "+(gutterSize)+"px";


        containerMar = (0)+"px "+(0)+"px "+(gutterSize/2)+"px "+(gutterSize/3)+"px";
        containerPad = (thumbDif/2)+"px "+(0)+"px "+(15)+"px "+(0)+"px";

        
    
        thumbWInt = cellWInt - (thumbDif/2) - (thumbBorderThickness*2);
        //thumbMar = "0 0 "+(thumbDif/2)+"px "+(thumbDif/2)+"px";
        //thumbMar = (thumbDif/2)+"px 0 0 "+(thumbDif/2)+"px";
        thumbMar = (0)+"px "+(0)+"px "+(thumbDif)+"px "+(thumbDif/2)+"px";



    break;
    }


    cellW = (cellWInt - cellBorderDif) + "px";

    //=================================================

    thumbW = thumbWInt + "px";



    /*
    // -- billboard default -- //
    totalRows = parseInt(info.cells.total)/parseInt(info.cells.across);
    thumbHInt = parseInt(info.viewHeight)/parseInt(totalRows);
    if(!isNaN(thumbHInt))
    {
        thumbHInt = parseInt(info.viewHeight) / parseInt(totalRows);
        //console.log("hook it: "+thumbHInt);
    }
    */



    //console.log("info.view: "+info.view);
    //console.log("info.viewHeight: "+info.viewHeight);


    switch(info.view)
    {
    case'fullScreen':

        switch(info.gridType.toLowerCase())
        {
        case'flex':

            thumbHInt = (64/100) * thumbWInt;
        break;
        default:

            // -- billboard default -- //
            totalRows = parseInt(info.cells.total)/parseInt(info.cells.across);
            thumbHInt = parseInt(info.viewHeight)/parseInt(totalRows);

            //console.log("before hook it: "+thumbHInt);
            if(!isNaN(thumbHInt))
            {
                thumbHInt = parseInt(info.viewHeight) / parseInt(totalRows);
                //console.log("hook it: "+thumbHInt);
            }
        }

    break;
    default:

        thumbHInt = (64/100) * thumbWInt; //regular image default
        if(info.cells.call === 'vid') 
        {
            thumbHInt = (57/100) * thumbWInt;
        }
        if(info.cells.call === 'album') 
        {
            thumbHInt = (80/100) * thumbWInt;
        }

    }//switch
    
    thumbH = thumbHInt + "px";
    
    imgW = thumbWInt;
    imgH = thumbHInt;





    //=================================================

    columnWPerc = (columnWInt / gridWInt) * 100;
    if(columnWPerc % 1 !== 0) 
        columnWPerc = columnWPerc.toFixed(2);

    columnWPerc = columnWPerc + "%";


    switch(info.gridCall)
    {
    case'grid_column_cell':
    //case'grid_cell':

        cellWPerc = (cellWInt / columnWInt) * 100;
        if(cellWPerc % 1 !== 0) 
            cellWPerc = cellWPerc.toFixed(2);

        //cellWPerc = cellWPerc + "%";

    break;
    default:

        cellWPerc = (cellWInt / gridWInt) * 100;
        if(cellWPerc % 1 !== 0) 
            cellWPerc = cellWPerc.toFixed(2);
    
        //cellWPerc = cellWPerc + "%";

    break;
    }



    
    //cellWPerc = 100;
    cellWPercDif = 0;
    switch(across) 
    {
    case 6:
    case 7:
    
        cellWPercDif = 0.005;
    break;
    default:
    }
    


    if(info.gutter.boolean) 
        cellWPercDif = ( (parseFloat(info.gutter.size) ) * .20 );

    cellWPerc = cellWPerc - parseFloat(cellWPercDif);
    cellWPerc = cellWPerc.toFixed(4);
    cellWPerc = cellWPerc + "%";




    containerWPerc = 100;
    containerWPercDif = 0;

    if(info.gutter.boolean) 
        containerWPercDif = ( (info.gutter.size) * .20 );

    containerWPerc = containerWPerc - containerWPercDif;
    containerWPerc = containerWPerc.toFixed(2);
    containerWPerc = containerWPerc + "%";





    thumbWPerc = (thumbWInt / cellWInt) * 100;
    if(thumbWPerc % 1 !== 0) 
        thumbWPerc = thumbWPerc.toFixed(1);


    if(info.gridCall === 'grid_cell')
        thumbWPercDif = ( (info.thumb.diff/2) / thumbWInt ) * 100;

    thumbWPerc = thumbWPerc - thumbWPercDif;
    thumbWPerc = thumbWPerc.toFixed(1);
    thumbWPerc = thumbWPerc + "%";


    thumbHPerc = (thumbHInt / thumbWInt) * 100;
    if(thumbHPerc % 1 !== 0) 
        thumbHPerc = thumbHPerc.toFixed(1);

    thumbHPerc = thumbHPerc + "%";




    /*

    alert(""

    +"\nACROSS: "+across

    +"\ngridWInt: "+gridWInt
  
    +"\ncellWPerc: "+cellWPerc
    +"\ncellWInt: "+cellWInt

    +"\nthumbWPerc: "+thumbWPerc
    +"\nthumbWInt: "+thumbWInt
    +"\nthummbHInt: "+thumbHInt

    );

    */
    





    if(info.gridType === "ImageText") 
    {
        maskW = 100 + "%";

        //maskH = 100 + "%";
        //maskTop = 0 + "px";
        //maskLeft = 0 + "px"; 
        
        maskH = thumbHInt + "px";
        maskTop = (thumbDif/2) + "px";
        maskLeft = (thumbDif/2) + "px";

    }else{
        maskW = thumbWPerc;
        maskH = thumbHInt + "px";
        maskTop = (0) + "px";
        maskLeft = (thumbDif/2) + "px";
    }









    //#############################################
    //#############################################
    //#############################################

    const _VARS = {

        columnWPerc     : columnWPerc,
        columnHPerc     : "100%",
        cellWPerc       : cellWPerc,
        containerWPerc  : containerWPerc,
        thumbWPerc      : thumbWPerc,
        thumbHPerc      : thumbHPerc,

          
        info            :info,
        across          :across,
        maxWidth        :maxWidth,

        gridDif         :gridDif,

        gridWInt        :gridWInt,
        gridW           :gridW,
        gridMarl        :gridMarl,
    
    
        columnWInt      :columnWInt,
        columnW         :columnW,
        columnMar       :columnMar,

        cellWInt        :cellWInt,
        cellW           :cellW,
        cellMar         :cellMar,
    
        cellBorderDif   :cellBorderDif, 
        cellBorder      :cellBorder,


        maskW           :maskW,
        maskH           :maskH,
        maskTop         :maskTop,
        maskLeft        :maskLeft,

    
    
        thumbDif        :thumbDif,
        thumbWInt       :thumbWInt,
        thumbW          :thumbW,
        thumbHInt       :thumbHInt,
        thumbH          :thumbH,
        thumbMar        :thumbMar,
    

        imgW            :imgW,
        imgH            :imgH



    };










    //#############################################
    //#############################################
    //#############################################

    const R = {};

    R['grid'] = {

        "bgColor"       :"transparent",
        "width"         :gridW,
        "height"        :"auto",
        "margin"        :"10px 0 100px "+gridMarl

    };

    R['column'] = {

        "width"         :columnWPerc,
        "height"        :"auto",
        "margin"        :columnMar,

    };

    R['cell'] = {

        "bgColor"       :"lightcoral",

        "width"         :cellWPerc,
        "height"        :'auto',
        "margin"        :cellMar,

        "border"        :cellBorder,
        "paddingTop"    :thumbHPerc,



        "container" : {

            "width"    :containerWPerc,
            "margin"   :containerMar,
            "padding"  :containerPad,
            "border"   :cellBorder

        },


        "mask" : {

            "width"     :maskW,
            "height"    :maskH,
            "top"       :maskTop,
            "left"      :maskLeft

        },


        "thumb" : {

            "width"    :thumbWPerc,
            "height"   :thumbH,
            "margin"   :thumbMar


        }


    };

    //console.log(JSON.stringify(R,null,2));
 

    let RevData = BuildData({

        info        :info,
        items       :items,
        thumbWInt   :thumbWInt,
        thumbHInt   :thumbHInt,
        imgW        :imgW,
        imgH        :imgH

    });

    switch(info.gridCall)
    {
    case'grid_column_cell':
    //case'grid_cell':

        if(RevData.length > 0)
        {
            var ColData;
            ColData = DistributeToColumns(RevData,across);
            ColData = FillColumns({

                info        :info,
                items       :items,
                columns     :ColData

            });

            R['data'] = ColData;
        }else{
            R['data'] = RevData;
        }


    break;
    default:

        R['data'] = RevData;
    
    break;
    }



    return {

        "vars"      : _VARS,
        "items"     : R
    };


}



