export const FAVORITE_STAR = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 100 100";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

			{"rgb(253,185,19) #fdbb13"}
			<polygon 
				fill={props.fillColor}
				points="50,1 61.142,38.433 97.195,38.433 68.027,61.566 79.169,99 50,75.865 20.832,99 31.973,61.566 	2.805,38.433 38.859,38.433 50,1"
			/>

		</svg>
		</div>

	);//return
}