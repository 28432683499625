import { 

    VPaccount

} from 'app-components/Prime/VPdata/form.js';

import { 
	//LibForm, 
	LibBase 
} from 'library';


const BuildItems = (props) => {


	//var currentKey = props.currentKey;
	//alert(currentKey);


	/**
	 * 
	 * 	
		avoiding the react-redux mutation error 

		https://www.samanthaming.com/tidbits/70-3-ways-to-clone-objects
	
		JSON.stringify/parse only work with Number and String and Object literal without function or Symbol properties.
		deepClone work with all types, function and Symbol are copied by reference.

	 *
	 * 
	 */

	const zoneObj = Object.assign({}, props.zoneObj);
	//const zoneObj = JSON.parse(JSON.stringify(props.zoneObj));


	var array = LibBase.ParseIt(props.array);

	var processName = 'Alter Auth/Account';

	var accountItems = {};

	var object;

	var keys = [];
	var nv = [];

	var call;
	var isVerified = false;
	var errorMsg = '';


	if(array.length > 0)
	{
		for(object of array)
		{
			keys.push(object['name']);
            nv.push({

                name:object['name'],
                value:object['value']
            });
		}
	}

	//console.log("ARRAY: "+JSON.stringify(array,null,2));
	//console.log("NEW KEYS: "+JSON.stringify(keys,null,2));


	/*
	KEYS: [

		"name",
		"empl_hourly_rate",
		"headline",
		"empl_labor_direct",
		"headline",
		"empl_labor_indirect",
		"headline",
		"empl_burden_weekly_hours",
		"headline",
		"empl_tax_comp_percent",
		"headline",
		"empl_health_insurance_monthly",
		"empl_ira_percent",
		"empl_vacation_weeks"
		
	]

	*/


	// array: [
	// 	{
	// 	  "section": "field",
	// 	  "fieldtype": "text",
	// 	  "hint": "Company",
	// 	  "name": "company",
	// 	  "value": "PowerDigitalMedia"
	// 	},
	// 	{
	// 	  "section": "field",
	// 	  "fieldtype": "text",
	// 	  "hint": "Email",
	// 	  "name": "email",
	// 	  "value": "powerdigitalmedia.com@gmail.com"
	// 	},
	// 	{
	// 	  "section": "field",
	// 	  "fieldtype": "text",
	// 	  "hint": "Address",
	// 	  "name": "address",
	// 	  "value": "84 WInterberry Drive"
	// 	},
	// 	{
	// 	  "section": "field",
	// 	  "fieldtype": "text",
	// 	  "hint": "City",
	// 	  "name": "city",
	// 	  "value": "Downingtown"
	// 	},
	// 	{
	// 	  "section": "field",
	// 	  "fieldtype": "select",
	// 	  "hint": "State",
	// 	  "name": "state",
	// 	  "value": "PA"
	// 	},
	// 	{
	// 	  "section": "field",
	// 	  "fieldtype": "select",
	// 	  "hint": "Country",
	// 	  "name": "country",
	// 	  "value": "US"
	// 	},
	// 	{
	// 	  "section": "field",
	// 	  "fieldtype": "text",
	// 	  "hint": "Zip Code",
	// 	  "name": "zipcode",
	// 	  "value": "19335"
	// 	},
	// 	{
	// 	  "section": "field",
	// 	  "fieldtype": "phone",
	// 	  "hint": "Phone",
	// 	  "name": "phone",
	// 	  "value": "555-555-5555"
	// 	}
	//   ]
	



	//nv: [
	// 	{
	// 	  "name": "company",
	// 	  "value": "PowerDigitalMedia"
	// 	},
	// 	{
	// 	  "name": "email",
	// 	  "value": "powerdigitalmedia.com@gmail.com"
	// 	},
	// 	{
	// 	  "name": "address",
	// 	  "value": "84 WInterberry Drive"
	// 	},
	// 	{
	// 	  "name": "city",
	// 	  "value": "Downingtown"
	// 	},
	// 	{
	// 	  "name": "state",
	// 	  "value": "PA"
	// 	},
	// 	{
	// 	  "name": "country",
	// 	  "value": "US"
	// 	},
	// 	{
	// 	  "name": "zipcode",
	// 	  "value": "19335"
	// 	},
	// 	{
	// 	  "name": "phone",
	// 	  "value": "555-555-5555"
	// 	}
	//]





    var isOkay;
    isOkay = false;
    if(zoneObj.auth_account 
    && zoneObj.auth_account !== undefined
    )
    {
        if(Object.keys(zoneObj.auth_account).length > 0
        )
        {
            isOkay = true;
        }

    }
	//alert("isOkay: "+isOkay);
    if(isOkay)
    {
		var sk = VPaccount.login.value.email.value;
		switch(nv[0].name)
		{
		case'company':		call = 'company';	break;
		case'email':		call = 'email'; 	break;
		case'old_password':	call = 'password';	break;
		default:
		}
		processName = processName + "-" + call;
		accountItems['sk'] = sk;
		accountItems['call'] = call;
		for(object of nv)
		{
			accountItems[object.name] = object.value;
		}


		console.log(":::: Verify ::::");

		switch(nv[0].name)
		{
		case'company':
		
			//errorMsg = "Invalid email,phone,zipcode";
			isVerified = true;
		break;
		case'email':

			// errorMsg = "Not valid email(s)"
			if(accountItems.sk === accountItems.email)
			{
				isVerified = true;
			}
			else
			{			
				isVerified = false;	
				errorMsg = "Incorrect account email";		
			}
			
			if(accountItems.sk === accountItems.new_email)
			{
				isVerified = false;	
				errorMsg = "No Change";
			}
		break;
		case'old_password':

			//compare password and retype_password

			if(accountItems.password === accountItems.retype_password)
			{
				isVerified = true;
			}else{
				isVerified = false;
				errorMsg = "Passwords do NOT match";		
			}
		break;
		default:
		}






	}// -- if




	// if(LibArray.InArray(kn,keys))
	// {
	// 	index = keys.indexOf(kn);
	// 	newVal[kn].value = nv[index].value;
	// }
	
	
	// return {

	// 	array			:array,
	// 	accountItems	:accountItems,
	// 	//rawOutput		:rawOutput

	// };
	

	// console.log(JSON.stringify(IDLS,null,2));
	// return false;


	return {

		call			:props.call,
		currentKey		:props.currentKey,
		processName		:processName,
		isVerified		:isVerified,
		errorMsg		:errorMsg,
		accountItems	:accountItems

	};

	

}


export default BuildItems;

