import React, { 

    useState, 
    useEffect, 
    //useRef 

} from 'react';


import {

    //BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useLocation,
    useHistory,   
    //useParams,

} from "react-router-dom";


//import { useWindowDimensions } from 'utils/WindowDimensionsProvider';
//import NavGrid from 'utils/myBuilds/NavGrid/Tags';



import {

    Grid,
    Cell,
    Container,
    // Graphic,
    // Name,
    SubName

} from './Styled/Grid';



import { LibArray } from 'library';



import {

    //ACCOUNT as ACCOUNT_GRAPHIC,
    // EMPLOYEE as EMPLOYEE_GRAPHIC,
    // EXPENSES as EXPENSES_GRAPHIC,
    // OVERHEAD as OVERHEAD_GRAPHIC,
    // OWNER as OWNER_GRAPHIC,
    // SCHEDULE as SCHEDULE_GRAPHIC,

    CALCULATOR as CALCULATOR_GRAPHIC,
    // DIRECT_LABOR_HOURS as HOURS_GRAPHIC,
    // DIRECT_LABOR_RATES as RATES_GRAPHIC,
    // HEALTHCARE as HEALTHCARE_GRAPHIC,
    HOME as HOME_GRAPHIC,
    // IRA as IRA_GRAPHIC,
    KNOWLEDGE_BASE as KNOWLEDGE_GRAPHIC,
    LOGOUT as LOGOUT_GRAPHIC,
    PRICING as PRICING_GRAPHIC,  
    REPORTS as REPORTS_GRAPHIC,
    SETTINGS as SETTINGS_GRAPHIC   


} from 'app-components/Zone/_SVGComps/ICONS';





export const SideNav = (props) => {




    // -- router -- //
    let match = useRouteMatch();
    let location = useLocation();
    let history = useHistory();
    //let params = useParams();


    // console.log("MATCH: "+match.path);
    // console.log("LOC: "+JSON.stringify(location,null,2));
    // //console.log("PARAMS: "+JSON.stringify(params,null,2));



    // -- paths -- //

    let pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    // if(pathname.charAt(pathname.length-1) === '/') 
    //     pathname = pathname.substring(0,pathname.length-1);


    //console.log(pathname);

    let mainpath;
    mainpath = pathname.split("/")[0];

    let subpath;
    subpath = pathname.split("/")[1];
    if(subpath === undefined) subpath = false;

    let sub2path;
    sub2path = pathname.split("/")[2];
    if(sub2path === undefined) sub2path = false;


    // console.log("mainpath: "+mainpath+"\nsubpath: "+subpath+"\nsub2path: "+sub2path);

    // if(mainpath === 'Calculators') console.log("Calculators");



    // -- dims -- //
    //const { width, height } = useWindowDimensions();
    //console.log("STAGE WIDTH: "+width+"\nSTAGE HEIGHT: "+height);


    // -- ref -- //
    //const refA = useRef(null);
    //const refB = useRef(null);
    //const refC = useRef(null);





    //===============================================
    // state/effect - main
    //===============================================

    const [isLoaded, setIsLoaded] = useState(false);
    const [switchRoute, setSwitchRoute] = useState(false);
    const [navArr, setNavArr] = useState([]);
    const [highlights, setHighlights] = useState([]);
    const [currPath, setCurrPath] = useState(false);
    const [styling, setStyling] = useState({

        main:{

            backgroundColor:{

                over:'lightseagreen',
                out:false

            },
            textColor:{

                over:'white',
                out:false

            },
            fontWeight:{

                over:'550',
                out:false

            }

        },


        sub:{

            backgroundColor:{

                over:'white',
                out:"white"

            },
            textColor:{

                over:'black',
                out:false

            },
            fontWeight:{

                over:'550',
                out:false

            }

        },


        sub2:{

            backgroundColor:{

                over:'lightyellow',
                out:false

            },
            textColor:{

                over:'black',
                out:false

            },
            fontWeight:{

                over:'550',
                out:false

            }

        }



    });




    useEffect(() => {



        // console.log("Navigator - SideNav Comp - props.data: "+JSON.stringify(props.data,null,2));



        if(!isLoaded)
        {

            setIsLoaded(true);
            //alert(mainpath);
            // console.log(JSON.stringify(props.data,null,2));
            // alert('SideNav - props.data')

   
        }// isLoaded


        var count,item,update;

        if(props.data.items && props.data.items.length > 0)
        {
            //console.log("Navigator - SideNav Comp - props.data.items: "+JSON.stringify(props.data.items,null,2));


            //TM:
            // set here to constantly update for dynamic content
            setNavArr(props.data.items);



            update = false;
            if(navArr.length > 0)
            {
                count = 0;
                for(item of props.data.items)
                {
                    if(item.subs)
                    {
                        if(navArr[count])
                        {
                            if(item.subs.length !== navArr[count].subs.length)
                            {
                                //alert(item.subs.length+"\n"+navArr[count].subs.length);
                                update = true;
                                break;
                            }

                        }

                    }
                    count++;
                }

            }

        };



        if(currPath !== pathname || update) 
        {
    
            setCurrPath(pathname);

            // console.log("SideNav : "+pathname);


            if(mainpath === 'Calculators') 
            {

                setStyling({

                    main:{

                        backgroundColor:{
            
                            over:'#191e28',
                            out:false
            
                        },
                        textColor:{
            
                            over:'white',
                            out:false
            
                        },
                        fontWeight:{
            
                            over:'550',
                            out:false
            
                        }
            
                    },
            
            
                    sub:{
            
                        backgroundColor:{
            
                            over:'white',
                            out:"white"
            
                        },
                        textColor:{
            
                            over:'black',
                            out:false
            
                        },
                        fontWeight:{
            
                            over:'550',
                            out:false
            
                        }
            
                    },
            
            
                    sub2:{
            
                        backgroundColor:{
            
                            over:'lightyellow',
                            out:false
            
                        },
                        textColor:{
            
                            over:'black',
                            out:false
            
                        },
                        fontWeight:{
            
                            over:'550',
                            out:false
            
                        }
            
                    }
            

                });


            }else{

                if(props.data.styling && props.data.styling !== undefined)
                {
                    setStyling(props.data.styling);
                }

            }
            

                

            if(props.data.info && props.data.info !== undefined)
            {
                if(props.data.info.call
                && props.data.info.call === 'switchRoute'
                )
                {
                    setSwitchRoute(true);
                }

            }else{

                setSwitchRoute(props.data.withSwitchRoute);
            }




 


            if(props.data.items && props.data.items.length > 0)
            {

                //TM:
                // navArr set here doesn't update for dynamic content
                //setNavArr(props.data.items);


                // -- set highlights -- //

                var nav;
                var subnav;
                //var sub2nav;

                var regxNavName;
                var regxNavTo;
                // var regxSubnavTo
                // var regxSub2navTo


                var regxSubPath;
                regxSubPath = new RegExp(subpath,"gi");

                // var regxSub2Path;
                // regxSub2Path = new RegExp(sub2path,"gi");




                //alert(JSON.stringify(props.data.items,null,2));



                var hi = []
                for(nav of props.data.items)
                {

                    //console.log(pathname + ' ' + nav.to)
                    // if(pathname === nav.to)
                    // {
                    //     hi.push(nav.name);

                    // }else{

                    //     regxNavTo = new RegExp(nav.to,"gi")
                    //     if(nav.subs && nav.subs !== undefined)
                    //     {
                    //         if(pathname.match(regxNavTo)
                    //         && !LibArray.InArray(pathname,hi)
                    //         )
                    //         {
                    //             hi.push(nav.name);
                    //             for(subnav of nav.subs)
                    //             {
                    //                 if(pathname === subnav.to)
                    //                 {

                    //                     hi.push(subnav.name);

                    //                 }
                    //             }
        
                    //         }
                    //     }
                    // }



                    //console.log("pathname: "+pathname);

                    

                    // if(pathname.match(/calculator\/templates\//gi))
                    // {
                    //     if(!LibArray.InArray("Templates", hi))
                    //     {
                    //         hi.push('Templates');
                    //     }
                        
                    // }
                    // else
                    // if(pathname.match(/dashboard\/reports\//gi))
                    // {
                    //     if(!LibArray.InArray("Reports", hi))
                    //     {
                    //         hi.push('Reports');
                    //     }
                    //     else
                    //     {


                    //         //console.log("......."+pathname);
                    //         regxNavTo = new RegExp("Reports","gi");
                    //         if(nav.subs && nav.subs !== undefined)
                    //         {
                    //             if(pathname.match(regxNavTo)
                    //             && !LibArray.InArray(pathname,hi)
                    //             )
                    //             {
                    //                 if(!LibArray.InArray('"Reports',hi)) hi.push("Reports");
                    //                 for(subnav of nav.subs)
                    //                 {
                    //                     if(subpath 
                    //                     && subpath !== '' 
                    //                     && subnav.to.match(regxSubPath)
                    //                     )
                    //                     {
                    //                         hi.push(subnav.name);
                    //                     }
                                        
                    //                 }
            
                    //             }
                    //         }


                    //     } 
                        
                        
                    // }
                    // else
                    // {


                        regxNavName = new RegExp(nav.name,"gi");

                        //console.log(" ::::: "+pathname + "\n" + nav.to + "\n" + nav.name);
                        //if(pathname === nav.to)



                        //console.log("mainpath: "+mainpath);
                        //console.log("nav.name: "+nav.name);
                        //console.log("highlights: "+JSON.stringify(highlights,null,2));


                        // if(mainpath === '' &&
                        // LibArray.InArray(nav.name,highlights)
                        // )
                        // {
                        //     alert('mainpath');
                        // }

                        // if(mainpath === nav.to.replace(/\//gi,"") &&
                        // LibArray.InArray(nav.name,highlights)
                        // )
                        // {
                        //     alert('not mainpath')
                        // }
                        




                        if(pathname === nav.to)
                        {//main

                            //alert('main');         
                            hi.push(nav.name);

                        }
                        else
                        if(pathname.match(regxNavName))
                        {// main / sub


                            //alert('main / sub')

                            hi.push(nav.name);


                            //console.log("nav.subs: "+JSON.stringify(nav.subs,null,2));

                            regxNavTo = new RegExp(nav.to,"gi");
                            if(nav.subs && nav.subs !== undefined)
                            {
                                //console.log("::: "+JSON.stringify(nav.subs,null,2));
                                //console.log("pathname: "+pathname);

                                //alert('main/sub - subs')

                                if(pathname.match(regxNavTo)
                                && !LibArray.InArray(pathname,hi)
                                )
                                {
                                    //alert(JSON.stringify(hi,null,2));

                                    if(!LibArray.InArray(nav.name,hi)) hi.push(nav.name);
                                    for(subnav of nav.subs)
                                    {

                                        // alert(JSON.stringify(nav.subs,null,2));
                                        // alert(subpath+"\n"+subnav.name);
                                        //alert("subnav stuff: "+subpath + " " + subnav.to);

                                        if(subpath 
                                        && subpath !== '' 
                                        && subnav.to.match(regxSubPath)
                                        )
                                        {
                                            //alert(subnav.name)
                                            hi.push(subnav.name);
                                        }

  
                                    }

  
                                }


                            }



                        }
                        else
                        {

                            regxNavTo = new RegExp(nav.to,"gi");
                            if(nav.subs && nav.subs !== undefined)
                            {


                                //alert('nav.subs')


                                //console.log("::: "+JSON.stringify(nav.subs,null,2));
                                //console.log("pathname: "+pathname);

                                if(pathname.match(regxNavTo)
                                && !LibArray.InArray(pathname,hi)
                                )
                                {

                                    //console.log(pathname + "\n" + nav.to + "\n" + nav.name);

                                    if(!LibArray.InArray(nav.name,hi)) hi.push(nav.name);
                                    // for(subnav of nav.subs)
                                    // {
                                    //     if(pathname === subnav.to)
                                    //     {
        
                                    //         hi.push(subnav.name);
        
                                    //     }
                                    // }
                                    for(subnav of nav.subs)
                                    {

                                        //console.log("subnav stuff: "+subpath + " " + subnav.to);

                                        if(subpath 
                                        && subpath !== '' 
                                        && subnav.to.match(regxSubPath)
                                        )
                                        {
                                            hi.push(subnav.name);
                                        }

  
                                    }

  
                                }


                            }

                        } 


                        
                        
                    // }//if templates

            
                    //console.log(JSON.stringify(hi,null,2));
                    
                }
                setHighlights(hi);
            
            
            }

            //console.log(JSON.stringify(props.data,null,2));
            //alert('props.data');

        }//hasChanged






    },
    [
        props,
        isLoaded,
        pathname,
        currPath,
        mainpath,subpath,sub2path,
        highlights,
        navArr
    ])




    

    // //===============================================
    // // state/effect - currentPage
    // //===============================================

    // // -- state -- //
    // const [currentPage, setCurrentPage] = useState('na');

    // useEffect(() => {

    //     if(isLoaded && !currentPage)
    //     {
    //         var pathArr = pathname.split("/");
    //         console.log("Current Page: "+pathArr[pathArr.length-1]);
    
    //         setCurrentPage(pathArr[pathArr.length-1]);
  
    //     }

    // },
    // [
    //     props,
    //     pathname,
    //     isLoaded,
    //     currentPage
  
    // ])





    // //===============================================
    // // state/effect - data test
    // //===============================================

    // // -- state -- //
    // const [dataTest, setDataTest] = useState(false);

    // useEffect(() => {

    //     if(isLoaded && !dataTest)
    //     {
    //         setDataTest(props);

    //         console.log("====== NAVIGATOR (props) ======");

    //         console.log(pathname);
    //         var pathArr = pathname.split("/");
    //         console.log("Extracted Path 2: "+pathArr[pathArr.length-1]);

    //         console.log(JSON.stringify(props,null,2));




    //         console.log("====== NAVIGATOR (split data) ======");

    //         if(props.data.info && props.data.info !== undefined)
    //         {
    //             if(props.data.info.call
    //             && props.data.info.call === 'switchRoute'
    //             )
    //             {
    //                 console.log("setSwitchRoute --- here");
    //             }
    //             if(props.data.info.gridType 
    //             && props.data.info.gridType !== undefined
    //             )
    //             {
        
    //                 console.log("setLayout --- "+props.data.info.gridType);
    //             }
    
    //         }else{
    

    //             console.log("No Info");

    //             console.log("setSwitchRoute --- "+props.data.withSwitchRoute);
    //             console.log("setLayout --- "+props.data.layout);


    
    //         }
    
    //         if(props.data.items && props.data.items.length > 0)
    //         {
               
    //             console.log("setNavArr --- "+props.data.items);
             
    //         }else{
           
    //             console.log("NO Items");
    //             console.log("setNavArr --- "+props.data.items);
             
    //         }
    

    //     }

    // },
    // [
    //     props,
    //     dataTest,
    //     isLoaded,
    //     pathname
    // ])












    // -- handle -- //

    const handle = (hndl) => {

        //console.log(JSON.stringify(hndl));
        //alert(hndl.call);

        switch(hndl.call)
        {
        case'to':

            var to = hndl.val;
            if(to.charAt(0) !== '/') to = "/"+to;
            // regx = new RegExp(mainpath+"/","gi");
            // to = to.replace(regx,"");

            // alert(""

            //     +"\nmainpath: "+mainpath
            //     +"\nsubpath: "+subpath
            //     +"\nsub2path: "+sub2path
            //     +"\nval: "+hndl.val
            //     +"\nto: "+to

            // );

            history.push(to);
            if(props.handleZone && props.handleZone !== undefined)
            {
                props.handleZone({

                    call:"menu",
                    data:"close"

                });
            }
        


        break;
        case'messngr':


            if(hndl.name.match(/error/gi))
            {

                props.handle({

                    call       :"showMessngr",
                
                    isError    : true,
                    errorType  : 'process',
                    isTimed    : false,
                    items      : [

                        {
                            call:'span',
                            text: "ERROR : Huh"

                        }

                    ]
                

                });


            }else{

                props.handle({

                    call       :"showMessngr",
                
                    isError    : false,
                    errorType  : 'process',
                    isTimed    : true,
                    items      : [ 

                        {
                            call:'heading',
                            text: "SUCCESS"

                        },

                        {

                            call:'span',
                            text: "SUCCESS: Process was successfull!!"

                        }

                    ]
                
    
                });
    

            }


        

        break;
        case'loader':

  
            props.handle({

                call       :"showLoader",
             
                isError    : false,
                errorType  : 'process',
                isTimed    : true,
                items      : [

                    "SUCCESS: Process was successfull!!"

                ]
            

            });

        break;
        case'login':

  
            props.handle({

                call       :"showLogin",
             
                isError    : false,
                errorType  : 'process',
                isTimed    : true,
                items      : [

                    "SUCCESS: Process was successfull!!"

                ]
            

            });

        break;
        case'goBack':

            history.goBack();
        break;
        case'reload':

            window.location.reload();//regular dom 
        break;
        case'redirect':

            history.replace(hndl.val);
        break;
        case'push':

            history.push(hndl.val);
        break;
        default:

            if(hndl.data.isFunct)
            {
                alert("isFunct");
            }
            if(hndl.data.isUrl)
            {
                //alert("isUrl:: "+hndl.call);
                //window.location.replace(hndl.call);
                window.location.href = hndl.call

            }

        }



    }





    return (

        <div>
        {

        switchRoute === true
        ?

            <Switch>
            <Route path={match.path}>
            
                <div 
                style={{

                    backgroundColor:'powderblue',
                    width:"100%",
                    height:"500px",
                    padding:"15px 0 15px 0",
                    margin:"15px auto",
                    textAlign:"center",
                    color:"black"
                }}
                >
                    Navigator  SideNav - switchRoute
                
                </div> 

            </Route>
            </Switch>


        :
        switchRoute === false
        ?


            <Grid
            //backgroundColor="lightyellow"
            >

                {navArr.map((nav, i) => (



                    mainpath === '' &&
                    LibArray.InArray(nav.name,highlights)
                    ?


                        <Cell
                        key={i}
                        >

                            {
                            nav.isFunct || nav.isUrl
                            ?

                                <Container
                                backgroundColor={styling.main.backgroundColor.over}

                                nameTextColor={styling.main.textColor.over}
                                nameFontWeight={styling.main.fontWeight.over}

                                hover={false} 
                                onClick={() => handle({

                                    call:nav.to,
                                    data:nav,   
                                    name:nav.name


                                })}
                                >

                                    <div className="Graphic">


                                            {

                                            nav.name.toUpperCase() === "HOME"
                                            ?

                                                <HOME_GRAPHIC
                                                    
                                                    iconColor = {"white"}
                                                    darklite = {"dark"}

                                                />

                                            : 

                                            nav.name.toUpperCase() === "CALCULATOR"
                                            ?

                                                <CALCULATOR_GRAPHIC
                                                    
                                                    iconColor = {"white"}
                                                    darklite = {"dark"}

                                                />

                                            :

                                            nav.name.toUpperCase() === "SETTINGS"
                                            ?

                                                <SETTINGS_GRAPHIC
                                                    
                                                    iconColor = {"white"}
                                                    darklite = {"dark"}

                                                />

                                            :

                                            nav.name.toUpperCase() === "REPORTS"
                                            ?

                                                <REPORTS_GRAPHIC
                                                    
                                                    iconColor = {"white"}
                                                    darklite = {"dark"}

                                                />

                                            :
                                            nav.name.toUpperCase() === "KNOWLEDGE BASE"
                                            ?

                                                <KNOWLEDGE_GRAPHIC
                                                    
                                                    iconColor = {"white"}
                                                    darklite = {"dark"}

                                                />

                                            :
                                            nav.name.toUpperCase() === "LOGOUT"
                                            ?



                                                <LOGOUT_GRAPHIC
                                                    
                                                    iconColor = {"white"}
                                                    darklite = {"dark"}

                                                />



                                            :null

                                            }


                                    </div>
                                    <div className="Name">

                                        {nav.name}
                                    
                                    </div>

                                </Container>


                            :

                                <Link 
                                to={"/"+nav['to']}
                                >

                                    <Container
                                    backgroundColor={styling.main.backgroundColor.over}

                                    nameTextColor={styling.main.textColor.over}
                                    nameFontWeight={styling.main.fontWeight.over}

                                    hover={false} 
                                    >

                                        <div className="Graphic">

                                            {


                                            nav.name.toUpperCase() === "HOME"
                                            ?

                                                <HOME_GRAPHIC
                                                    
                                                    iconColor = {"white"}
                                                    darklite = {"dark"}

                                                />

                                            : 

                                            nav.name.toUpperCase() === "CALCULATOR"
                                            ?

                                                <CALCULATOR_GRAPHIC
                                                    
                                                    iconColor = {"white"}
                                                    darklite = {"dark"}

                                                />

                                            :

                                            nav.name.toUpperCase() === "PRICING"
                                            ?

                                                <PRICING_GRAPHIC
                                                    
                                                    iconColor = {"white"}
                                                    darklite = {"dark"}

                                                />

                                            :

                                            nav.name.toUpperCase() === "SETTINGS"
                                            ?

                                                <SETTINGS_GRAPHIC
                                                    
                                                    iconColor = {"white"}
                                                    darklite = {"dark"}

                                                />

                                            :

                                            nav.name.toUpperCase() === "REPORTS"
                                            ?

                                                <REPORTS_GRAPHIC
                                                    
                                                    iconColor = {"white"}
                                                    darklite = {"dark"}

                                                />

                                            :
                                            nav.name.toUpperCase() === "KNOWLEDGE BASE"
                                            ?

                                                <KNOWLEDGE_GRAPHIC
                                                    
                                                    iconColor = {"white"}
                                                    darklite = {"dark"}

                                                />

                                            :
                                            nav.name.toUpperCase() === "LOGOUT"
                                            ?



                                                <LOGOUT_GRAPHIC
                                                    
                                                    iconColor = {"white"}
                                                    darklite = {"dark"}

                                                />



                                            :null

                                            }

                                        </div>
                                        <div className="Name">

                                            {nav.name}
                                        
                                        </div>

                                    </Container>

                                </Link>

                            }





                            {
                            nav.subs && nav.subs !== undefined
                            ?

                                nav.subs.map((sub, j) => (


                                    //subpath === sub.name &&
                                    LibArray.InArray(sub.name,highlights)
                                    ?

                                        <Cell
                                        key={i+"-"+j}
                                        >
                                            {
                                            sub.isFunct || sub.isUrl
                                            ?

                                                <SubName
                                                backgroundColor={styling.sub.backgroundColor.over}
                                                color={styling.sub.textColor.over}
                                                fontWeight={styling.sub.fontWeight.over}
                                                hover={false}
                                                onClick={() => handle({

                                                    call:sub.to,
                                                    data:sub,
                                                    name:sub.name

                                                })}
                                                >

                                                    {sub.name}
                                                
                                                </SubName>

                                            :

                                                <Link 
                                                to={"/"+sub['to']}
                                                >
                                                    <SubName
                                                    backgroundColor={styling.sub.backgroundColor.over}
                                                    color={styling.sub.textColor.over}
                                                    fontWeight={styling.sub.fontWeight.over}
                                                    hover={false}  
                                                    >                      

                                                        {sub.name}
                                                
                                                    </SubName>

                                                </Link>

                                            }

                                        </Cell>


                                    :

                                        <Cell
                                        key={i+"-"+j}
                                        >
                                            {
                                            sub.isFunct || sub.isUrl
                                            ?

                                                <SubName
                                                backgroundColor={styling.sub.backgroundColor.out}
                                                color={styling.sub.textColor.out}
                                                fontWeight={styling.sub.fontWeight.out}
                                                hover={true}
                                                onClick={() => handle({

                                                    call:sub.to,
                                                    data:sub,
                                                    name:sub.name

                                                })}
                                                >

                                                    {sub.name}
                                                
                                                </SubName>

                                            :

                                                <Link 
                                                to={"/"+sub['to']}
                                                >
                                                    <SubName
                                                    backgroundColor={styling.sub.backgroundColor.out}
                                                    color={styling.sub.textColor.out}
                                                    fontWeight={styling.sub.fontWeight.out}
                                                    hover={true}  
                                                    >                      

                                                        {sub.name}
                                                
                                                    </SubName>

                                                </Link>

                                            }

                                        </Cell>


                                ))




                            :null
                            }



                        </Cell>



                    //=========================================================

                    : // mainpath not home

                    //==========================================================


                    mainpath === nav.to.replace(/\//gi,"") &&
                    LibArray.InArray(nav.name,highlights)
                    ?


                        <Cell
                        key={i}
                        >

                            {
                            nav.isFunct || nav.isUrl
                            ?


                                <Container
                                backgroundColor={styling.main.backgroundColor.over}

                                nameTextColor={styling.main.textColor.over}
                                nameFontWeight={styling.main.fontWeight.over}

                                hover={false} 
                                onClick={() => handle({

                                    call:nav.to,
                                    data:nav,   
                                    name:nav.name

                                })}
                                >

                                    <div className="Graphic">

                                            {


                                            nav.name.toUpperCase() === "HOME"
                                            ?

                                                <HOME_GRAPHIC
                                                    
                                                    iconColor = {"white"}
                                                    darklite = {"dark"}

                                                />

                                            : 



                                            nav.name.toUpperCase() === "CALCULATOR"
                                            ?

                                                <CALCULATOR_GRAPHIC
                                                    
                                                    iconColor = {"white"}
                                                    darklite = {"dark"}

                                                />

                                            :

                                            nav.name.toUpperCase() === "PRICING"
                                            ?

                                                <PRICING_GRAPHIC
                                                    
                                                    iconColor = {"white"}
                                                    darklite = {"dark"}

                                                />

                                            :

                                            nav.name.toUpperCase() === "SETTINGS"
                                            ?

                                                <SETTINGS_GRAPHIC
                                                    
                                                    iconColor = {"white"}
                                                    darklite = {"dark"}

                                                />

                                            :

                                            nav.name.toUpperCase() === "REPORTS"
                                            ?

                                                <REPORTS_GRAPHIC
                                                    
                                                    iconColor = {"white"}
                                                    darklite = {"dark"}

                                                />

                                            :
                                            nav.name.toUpperCase() === "KNOWLEDGE BASE"
                                            ?

                                                <KNOWLEDGE_GRAPHIC
                                                    
                                                    iconColor = {"white"}
                                                    darklite = {"dark"}

                                                />

                                            :
                                            nav.name.toUpperCase() === "LOGOUT"
                                            ?



                                                <LOGOUT_GRAPHIC
                                                    
                                                    iconColor = {"white"}
                                                    darklite = {"dark"}

                                                />



                                            :null

                                            }



                                    </div>
                                    <div className="Name">

                                        {nav.name}
                                    
                                    </div>

                                </Container>



                            :

                                <Link 
                                to={"/"+nav['to']}
                                >

                                    <Container
                                    backgroundColor={styling.main.backgroundColor.over}

                                    nameTextColor={styling.main.textColor.over}
                                    nameFontWeight={styling.main.fontWeight.over}

                                    hover={false} 
                                    >

                                        <div className="Graphic">


                                            {

                                            nav.name.toUpperCase() === "HOME"
                                            ?

                                                <HOME_GRAPHIC
                                                    
                                                    iconColor = {"white"}
                                                    darklite = {"dark"}

                                                />

                                            : 


                                            nav.name.toUpperCase() === "CALCULATOR"
                                            ?

                                                <CALCULATOR_GRAPHIC
                                                    
                                                    iconColor = {"white"}
                                                    darklite = {"dark"}

                                                />

                                            :

                                            nav.name.toUpperCase() === "PRICING"
                                            ?

                                                <PRICING_GRAPHIC
                                                    
                                                    iconColor = {"white"}
                                                    darklite = {"dark"}

                                                />

                                            :

                                            nav.name.toUpperCase() === "SETTINGS"
                                            ?

                                                <SETTINGS_GRAPHIC
                                                    
                                                    iconColor = {"white"}
                                                    darklite = {"dark"}

                                                />

                                            :

                                            nav.name.toUpperCase() === "REPORTS"
                                            ?

                                                <REPORTS_GRAPHIC
                                                    
                                                    iconColor = {"white"}
                                                    darklite = {"dark"}

                                                />

                                            :
                                            nav.name.toUpperCase() === "KNOWLEDGE BASE"
                                            ?

                                                <KNOWLEDGE_GRAPHIC
                                                    
                                                    iconColor = {"white"}
                                                    darklite = {"dark"}

                                                />

                                            :
                                            nav.name.toUpperCase() === "LOGOUT"
                                            ?



                                                <LOGOUT_GRAPHIC
                                                    
                                                    iconColor = {"white"}
                                                    darklite = {"dark"}

                                                />



                                            :null

                                            }




                                        </div>
                                        <div className="Name">

                                            {nav.name}
                                        
                                        </div>

                                    </Container>


                                </Link>

                            }





                            {
                            nav.subs && nav.subs !== undefined
                            ?

                                nav.subs.map((sub, j) => (


                                    //subpath === sub.name &&
                                    LibArray.InArray(sub.name,highlights)
                                    ?

                                        <Cell
                                        key={i+"-"+j}
                                        >
                                            {
                                            props.aboveThreshold === false
                                            ?
                                                <SubName
                                                backgroundColor={"darkblue"}
                                                color={styling.sub.textColor.out}
                                                fontWeight={styling.sub.fontWeight.out}
                                                hover={true}
                                                onClick={() => handle({

                                                    call:sub.to,
                                                    name:sub.name

                                                })}
                                                >

                                                    {sub.name}
                                                
                                                </SubName>

                                            :
                                            sub.isFunct || sub.isUrl
                                            ?

                                                <SubName
                                                backgroundColor={styling.sub.backgroundColor.over}
                                                color={styling.sub.textColor.over}
                                                fontWeight={styling.sub.fontWeight.over}
                                                hover={false}
                                                onClick={() => handle({

                                                    call:sub.to,
                                                    data:sub,
                                                    name:sub.name

                                                })}
                                                >

                                                    {sub.name}
                                                
                                                </SubName>

                                            :

                                                <Link 
                                                to={"/"+sub['to']}
                                                >
                                                    <SubName
                                                    backgroundColor={styling.sub.backgroundColor.over}
                                                    color={styling.sub.textColor.over}
                                                    fontWeight={styling.sub.fontWeight.over}
                                                    hover={false}  
                                                    >                      

                                                        {sub.name}
                                                
                                                    </SubName>

                                                </Link>

                                            }

                                        </Cell>


                                    :

                                        <Cell
                                        key={i+"-"+j}
                                        >
                                            {
                                            !props.aboveThreshold
                                            ?
                                                <SubName
                                                backgroundColor={styling.sub.backgroundColor.out}
                                                color={styling.sub.textColor.out}
                                                fontWeight={styling.sub.fontWeight.out}
                                                hover={true}
                                                onClick={() => handle({

                                                    call:'to',
                                                    val:sub.to,
                                                    name:sub.name

                                                })}
                                                >

                                                    {sub.name}
                                                
                                                </SubName>

                                            :
                                            sub.isFunct || sub.isUrl
                                            ?

                                                <SubName
                                                backgroundColor={styling.sub.backgroundColor.out}
                                                color={styling.sub.textColor.out}
                                                fontWeight={styling.sub.fontWeight.out}
                                                hover={true}
                                                onClick={() => handle({

                                                    call:sub.to,
                                                    data:sub,
                                                    name:sub.name

                                                })}
                                                >

                                                    {sub.name}
                                                
                                                </SubName>

                                            :

                                                <Link 
                                                to={"/"+sub['to']}
                                                >
                                                    <SubName
                                                    backgroundColor={styling.sub.backgroundColor.out}
                                                    color={styling.sub.textColor.out}
                                                    fontWeight={styling.sub.fontWeight.out}
                                                    hover={true}  
                                                    >                      

                                                        {"3: "+sub.name}
                                                
                                                    </SubName>

                                                </Link>

                                            }

                                        </Cell>


                                ))




                            :null
                            }



                        </Cell>



                    //=========================================================

                    : // NOT CURRENT PATHNAME

                    //==========================================================



                        <Cell
                        key={i}
                        >
                            {
                            nav.isFunct || nav.isUrl
                            ?

                                <Container
                                backgroundColor={styling.main.backgroundColor.out}

                                nameTextColor={styling.main.textColor.out}
                                nameFontWeight={styling.main.fontWeight.out}

                                hover={true} 
                                onClick={() => handle({

                                    call:nav.to,
                                    data:nav,   
                                    name:nav.name


                                })}
                                >

                                    <div className="Graphic">


                                        {



                                        nav.name.toUpperCase() === "HOME"
                                        ?

                                            <HOME_GRAPHIC
                                                
                                                iconColor = {"#191e28"}
                                                darklite = {"lite"}

                                            />

                                        : 


                                        nav.name.toUpperCase() === "CALCULATOR"
                                        ?

                                            <CALCULATOR_GRAPHIC
                                                
                                                iconColor = {"#191e28"}
                                                darklite = {"lite"}

                                            />

                                        :


                                        nav.name.toUpperCase() === "PRICING"
                                        ?

                                            <PRICING_GRAPHIC
                                                
                                                iconColor = {"#191e28"}
                                                darklite = {"lite"}

                                            />

                                        :

                                        nav.name.toUpperCase() === "SETTINGS"
                                        ?

                                            <SETTINGS_GRAPHIC
                                                
                                                iconColor = {"#191e28"}
                                                darklite = {"lite"}

                                            />

                                        :

                                        nav.name.toUpperCase() === "REPORTS"
                                        ?

                                            <REPORTS_GRAPHIC
                                                
                                                iconColor = {"#191e28"}
                                                darklite = {"lite"}

                                            />

                                        :
                                        nav.name.toUpperCase() === "KNOWLEDGE BASE"
                                        ?

                                            <KNOWLEDGE_GRAPHIC
                                                
                                                iconColor = {"#191e28"}
                                                darklite = {"lite"}

                                            />

                                        :
                                        nav.name.toUpperCase() === "LOGOUT"
                                        ?



                                            <LOGOUT_GRAPHIC
                                                
                                                iconColor = {"#191e28"}
                                                darklite = {"lite"}

                                            />



                                        :null

                                        }






                                    </div>
                                    <div className="Name">

                                        {nav.name}
                                    
                                    </div>

                                </Container>


                            :

                                <Link 
                                to={"/"+nav['to']}
                                >

                                    <Container
                                    backgroundColor={styling.main.backgroundColor.out}

                                    nameTextColor={styling.main.textColor.out}
                                    nameFontWeight={styling.main.fontWeight.out}

                                    hover={true} 
                                    >

                                        <div className="Graphic">


                                            {

                                            nav.name.toUpperCase() === "HOME"
                                            ?

                                                <HOME_GRAPHIC
                                                    
                                                    iconColor = {"#191e28"}
                                                    darklite = {"lite"}

                                                />

                                            : 

                                            nav.name.toUpperCase() === "CALCULATOR"
                                            ?

                                                <CALCULATOR_GRAPHIC
                                                    
                                                    iconColor = {"#191e28"}
                                                    darklite = {"lite"}

                                                />

                                            :

                                            nav.name.toUpperCase() === "PRICING"
                                            ?
    
                                                <PRICING_GRAPHIC
                                                    
                                                    iconColor = {"#191e28"}
                                                    darklite = {"lite"}
    
                                                />
    
                                            :

                                            nav.name.toUpperCase() === "SETTINGS"
                                            ?

                                                <SETTINGS_GRAPHIC
                                                    
                                                    iconColor = {"#191e28"}
                                                    darklite = {"lite"}

                                                />

                                            :

                                            nav.name.toUpperCase() === "REPORTS"
                                            ?

                                                <REPORTS_GRAPHIC
                                                    
                                                    iconColor = {"#191e28"}
                                                    darklite = {"lite"}

                                                />

                                            :
                                            nav.name.toUpperCase() === "KNOWLEDGE BASE"
                                            ?

                                                <KNOWLEDGE_GRAPHIC
                                                    
                                                    iconColor = {"#191e28"}
                                                    darklite = {"lite"}

                                                />

                                            :
                                            nav.name.toUpperCase() === "LOGOUT"
                                            ?



                                                <LOGOUT_GRAPHIC
                                                    
                                                    iconColor = {"#191e28"}
                                                    darklite = {"lite"}

                                                />



                                            :null

                                            }


                                        </div>
                                        <div className="Name">

                                            {nav.name}
                                        
                                        </div>

                                    </Container>

                                </Link>

                            }

                        </Cell>



                ))} 

            </Grid>



        :

            <div 
            style={{

                backgroundColor:'powderblue',
                width:"100%",
                height:"500px",
                padding:"15px 0 15px 0",
                margin:"15px auto",
                textAlign:"center",
                color:"black"
            }}
            >
                Navigator SideNav - null
            
            </div> 


        }
        </div>

    )


}//END















            // <Switch>

            //     <Route path={match.path}>


            //         <PrimeStage>

            //             <PrimeScrollBox>

            //                 <PrimeArea>

            //                     <Navigator 

            //                         navSwitchRoute={true}
            //                         layout={"XGrid"}
            //                         navArr={Data.navigation}

            //                     />

            //                 </PrimeArea>

            //             </PrimeScrollBox>

            //         </PrimeStage>
                    

            //     </Route>

            // </Switch>



