export const ACCOUNT = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 500 500";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};

	let iconColor = props.iconColor || "rgb(0,0,0)";


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

			<path 
				fill={iconColor}
				fillRule="evenodd"
				clipRule="evenodd"
				d="M140.962,414.571h218.074h55.393c11.521,0,20.947-9.426,20.947-20.947V177.157 c0-11.521-9.426-20.946-20.947-20.946h-7.682c0,0.007,0,0.014,0,0.021h-286.51v217.59c0,9.922-6.49,18.037-14.424,18.037h-0.001 c-7.934,0-14.424-8.119-14.424-18.037v-217.59c0-15.883,7.642-20.946,20.946-20.946h267.617	c-0.572-11.141-9.395-22.109-20.102-22.109h-166.84v-12.801c0-11.521-9.427-20.946-20.946-20.946H85.57	c-11.52,0-20.946,9.427-20.946,20.946v74.476v144.297v74.477c0,11.521,9.426,20.947,20.946,20.947H140.962L140.962,414.571z"
			/>


		</svg>
		</div>

	);//return



	// let darklite = props.darklite || "lite";
	// if(darklite === 'dark')
	// {
	// 	return(<div></div>)
	// }
	// else // lite
	// {
	
	// }




}