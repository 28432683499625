
const VPformTemplateOwnr = [



    //==========================================
    //titleline
    //==========================================
    {

        "section"   :"titleline",
        "data"      :{

            "text"  :"New Owner"// or name

        }


    },

    //==========================================
    //labelfields
    //==========================================
    {

        "section"   :"labelfields",
        "data"      :[


            //--------------------------------
            //
            //--------------------------------

            {

                label: {

                    "text"               	    :"Name"
                },

                field: {


                    "fieldtype"               	:"standard",
                    "required"					:true,

                    "class"                   	:'',
                    "id"                      	:'ownr_name',
                    "hint"                    	:"",

                    "text"                     	:"",



                    "keyboardType"            	:"",
                    "autocorrect"             	:"false",
                    "autocapitalizationType"  	:"none",


                    //"secure"                  :true,
                    //"returnKeyType"           :"done"


                },

            },





            //--------------------------------
            //
            //--------------------------------

            {

                label: {

                    "text"               	    :"Salary Pretax"
                },

                field: {


                    "fieldtype"               	:"price",
                    "required"					:true,

                    "class"                   	:'',
                    "id"                      	:'ownr_salary_pretax',
                    "hint"                    	:"",

                    "text"                     	:50000,



                    "keyboardType"            	:"",
                    "autocorrect"             	:"false",
                    "autocapitalizationType"  	:"none",


                    //"secure"                  :true,
                    //"returnKeyType"           :"done"


                }

            }

        ]

        
    },

    //==========================================
    //spacer
    //==========================================
    {
        "section"   :"spacer",
        "data"      :{

            "name"		:'spacer-one',
            "color"   	:'transparent',
            "height"  	:10

        }

    },





    //#####################################################
    //#####################################################

    //==========================================
    //bar
    //==========================================
    {

        "section"   :"headline",
        "data"      :{

            "text"  :'Tax'

        }


    },

    //==========================================
    //labelfields
    //==========================================
    {

        "section"   :"labelfields",
        "data"      :[


            //--------------------------------
            //
            //--------------------------------

            {

                label: {

                    "text"               	    :"Tax Rate %"
                },

                field: {


                    "fieldtype"               	:"percent",
                    "required"					:true,

                    "class"                   	:'',
                    "id"                      	:'ownr_taxrate_percent',
                    "hint"                    	:"",

                    "text"                     	:30.0,



                    "keyboardType"            	:"",
                    "autocorrect"             	:"false",
                    "autocapitalizationType"  	:"none",


                    //"secure"                  :true,
                    //"returnKeyType"           :"done"


                },

            }


        ]

        
    },

    //==========================================
    //spacer
    //==========================================
    {
        "section"   :"spacer",
        "data"      :{

            "name"		:'spacer-tax',
            "color"   	:'transparent',
            "height"  	:25

        }

    },












    //#####################################################
    //#####################################################

    //==========================================
    //bar
    //==========================================
    {

        "section"   :"headline",
        "data"      :{

            "text"  :'Time'

        }


    },

    //==========================================
    //labelfields
    //==========================================
    {

        "section"   :"labelfields",
        "data"      :[


            //--------------------------------
            //
            //--------------------------------

            {

                label: {

                    "text"               	    :"Hours Per Week"
                },

                field: {


                    "fieldtype"               	:"standard",
                    "required"					:true,

                    "class"                   	:'',
                    "id"                      	:'ownr_hours_weekly',
                    "hint"                    	:"",

                    "text"                     	:40,//create default from companyInfo



                    "keyboardType"            	:"",
                    "autocorrect"             	:"false",
                    "autocapitalizationType"  	:"none",


                    //"secure"                  :true,
                    //"returnKeyType"           :"done"


                },

            }


        ]

        
    },

    //==========================================
    //spacer
    //==========================================
    {
        "section"   :"spacer",
        "data"      :{

            "name"		:'spacer-dualslider',
            "color"   	:'transparent',
            "height"  	:30

        }

    },

    //==========================================
    //dualslider
    //==========================================
    {

        "section"   :"dualslider",
        "data"      :{
            
            required :true,
            id       :'ownr_labor_percent',
            value    :50,
            info     :{

                title           :'Direct Labor',
                titleB          :'Indirect Labor',
                bandColor       :'#0000cc',
                bandOpacity     :"0.5",
                useStickyX      :true,
                stickyIncrement :10,
                matchAB         :false

            }


        }


    },
    //==========================================
    //spacer
    //==========================================
    {
        "section"   :"spacer",
        "data"      :{

            "name"		:'spacer-dualslider',
            "color"   	:'transparent',
            "height"  	:30

        }

    },






    //#####################################################
    //#####################################################

    /*
    //==========================================
    //bar
    //==========================================
    {

        "section"   :"bar",
        "data"      :{

            "text"  :"ownroyee Type"

        }

    },
    */
    
    //==========================================
    //headline
    //==========================================
    {
        "section"   :"headline",
        "data"      :{

            "text"  :'Direct Labor'

        }
    },
    //==========================================
    //checkboxes
    //==========================================
    {
        "section"   :"checkboxes-edit",
        "data"      :{
            
            "call"      :'chk',
            "required"	:true,
            "name"      :'ownr_labor_direct',
            "array"     :["Design","Production","Installation"],
            "choice"    :[]

        }

    },


    //==========================================
    //headline
    //==========================================
    {
        "section"   :"headline",
        "data"      :{

            "text"  :'Indirect Labor'

        }

    },
    //==========================================
    //checkboxes
    //==========================================
    {

        "section"   :"checkboxes-edit",
        "data"      :{
            
            "call"      :'chk',
            "required"	:true,
            "name"      :'ownr_labor_indirect',
            "array"     :["Sales","Business Management","Office"],
            "choice"    :[]

        }

    },
    //==========================================
    //spacer
    //==========================================
    {
        "section"   :"spacer",
        "data"      :{

            "name"		:'spacer-checkboxes',
            "color"   	:'transparent',
            "height"  	:10

        }

    },








    //#####################################################
    //#####################################################

    //==========================================
    //benefits
    //==========================================
    {

        "section"   :"headline",
        "data"      :{

            "text"  :'Benefits'

        }


    },

    //==========================================
    //labelfields
    //==========================================
    {

        "section"   :"labelfields",
        "data"      :[


            //--------------------------------
            // Health Insurance
            //--------------------------------
            {

                label: {

                    "text"               	    :"Health Ins. Monthly"
                },

                field: {


                    "fieldtype"               	:"price",
                    "required"					:true,

                    "class"                   	:'',
                    "id"                      	:'ownr_health_insurance_monthly',
                    "hint"                    	:"",

                    "text"                     	:500.00,



                    "keyboardType"            	:"",
                    "autocorrect"             	:"false",
                    "autocapitalizationType"  	:"none",


                    //"secure"                  :true,
                    //"returnKeyType"           :"done"


                },

            },

            //--------------------------------
            // IRA
            //--------------------------------

            {

                label: {

                    "text"               	    :"IRA %"
                },

                field: {


                    "fieldtype"               	:"percent",
                    "required"					:true,

                    "class"                   	:'',
                    "id"                      	:'ownr_ira_percent',
                    "hint"                    	:"",

                    "text"                     	:2.0,



                    "keyboardType"            	:"",
                    "autocorrect"             	:"false",
                    "autocapitalizationType"  	:"none",


                    //"secure"                  :true,
                    //"returnKeyType"           :"done"


                },

            },


            //--------------------------------
            // Vacation Sick
            //--------------------------------

            {

                label: {

                    "text"               	    :"Vacation/Sick Weeks"
                },

                field: {


                    "fieldtype"               	:"standard",
                    "required"					:true,

                    "class"                   	:'',
                    "id"                      	:'ownr_vacation_weeks',
                    "hint"                    	:"",

                    "text"                     	:2,



                    "keyboardType"            	:"",
                    "autocorrect"             	:"false",
                    "autocapitalizationType"  	:"none",


                    //"secure"                  :true,
                    //"returnKeyType"           :"done"


                },

            }




        ]

        
    },
    //==========================================
    //spacer
    //==========================================
    {
        "section"   :"spacer",
        "data"      :{

            "name"		:'spacer-dualslider',
            "color"   	:'transparent',
            "height"  	:40

        }

    },




    
];


export {

    VPformTemplateOwnr

}







/*


    //----------------------
    //owner
    //----------------------

    "owner":{

        "name":"Owner",
        "value":{

            "owners_salary_pretax" : {

                "print"		:'price',
                "name"		:'Salary Pretax',
                "value"		:35000,
                "required"	:true,
                "default"	:35000
            },  
    
            "owners_taxrate_percent" : {
    
                "print"		:'percent',
                "name"		:'Tax Rate %',
                "value"		:30,
                "required"	:true,
                "default"	:30
            },  
    
    
            "owners_ira_percent" : {
    
                "print"		:'percent',
                "name"		:'IRA %',
                "value"		:2,
                "required"	:true,
                "default"	:2
            },  

            "owners_vacation_weeks" : {
    
                "print"		:'standard',
                "name"		:'Vacation Weeks',
                "value"		:2,
                "required"	:true,
                "default"	:2
            },  

            "owners_health_insurance_monthly" : {
    
                "print"		:'price',
                "name"		:'Health Insurance Monthly',
                "value"		:200,
                "required"	:true,
                "default"	:200
            },  
    
    
    
            "owners_hours_indirect" : {
    
                "print"		:'standard',
                "name"		:'Hours Indirect',
                "value"		:10,
                "required"	:true,
                "default"	:10
            },  
    
            "owners_hours_direct" : {
    
                "print"		:'standard',
                "name"		:'Hours Direct',
                "value"		:30,
                "required"	:true,
                "default"	:30
            },   
    

        }
    
    },




*/



