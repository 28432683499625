

export const CssChartSamples = {


    hrzBarChart:{
        
        chartType:"horizontal",
        chartLines:true,
        data:[

            {
                name:'Item 1',
                value:"2500.00",
                percent:55,
            },

            {
                name:'Item 2',
                value:"2500.00",
                percent:20,
            },

            {
                name:'Item 3',
                value:"2500.00",
                percent:30,
            },

            {
                name:'Item 4',
                value:"2500.00",
                percent:10,
            },

            {
                name:'Item 5',
                value:"2500.00",
                percent:5,
            }


        ]


    }



}

