import BuildCalculator from './calculator';
import BuildOverhead from './overhead';
import BuildExpenses from './expenses';
import BuildExpense from './expense';
import BuildOwnr from './ownr';
import BuildEmpl from './empl';

import BuildDirectLaborRates from './direct_labor_rates';
import BuildDirectLaborHours from './direct_labor_hours';
import BuildHealthcare from './healthcare';
import BuildIRA from './IRA';




const Build = (props) => {


    var array;

    var search = props.search;
    var mainCategoryName = props.mainCategoryName;
    var categoryName = props.categoryName;
    //var zoneObj = props.zoneObj;

    var regxCalculator = new RegExp("calculator-","gi");
    var regxOverhead = new RegExp("overhead","gi");
    var regxExpense = new RegExp("expense","gi");
    var regxEmp = new RegExp("emp","gi");
    var regxOwn = new RegExp("own","gi");
    var regxHealthcare = new RegExp("healthcare","gi");
    var regxIRA = new RegExp("ira","gi");
    var regxDirectLaborRates = new RegExp("direct labor rates","gi");
    var regxDirectLaborHours = new RegExp("direct labor hours","gi");



    if(search.match(regxCalculator))
    {
        array = BuildCalculator(props); 
    }
    else
    if(search.match(regxOverhead))
    {
        array = BuildOverhead(props); 
    }
    else
    if(search.match(regxExpense))
    {
        if(mainCategoryName)
        {
            props.find = categoryName;
            array = BuildExpense(props); 
        }else{
            array = BuildExpenses(props); 
        }

    }
    else
    if(search.match(regxOwn))
    {
        if(mainCategoryName)
        {
            props.find = categoryName;
            array = BuildOwnr(props); 
        }else{
            array = BuildOwnr(props); 
        }

    }
    else
    if(search.match(regxEmp))
    {
        if(mainCategoryName)
        {
            props.find = categoryName;
            array = BuildEmpl(props); 
        }else{
            array = BuildEmpl(props); 
        }

    }
    else
    if(search.match(regxHealthcare))
    {
        if(mainCategoryName)
        {
            props.find = categoryName;
            array = BuildHealthcare(props); 
        }else{
            array = BuildHealthcare(props); 
        }

    }
    else
    if(search.match(regxIRA))
    {
        if(mainCategoryName)
        {
            props.find = categoryName;
            array = BuildIRA(props); 
        }else{
            array = BuildIRA(props); 
        }

    }
    else
    if(search.match(regxDirectLaborRates))
    {
        if(mainCategoryName)
        {
            props.find = categoryName;
            array = BuildDirectLaborRates(props); 
        }else{
            array = BuildDirectLaborRates(props); 
        }

    }
    else
    if(search.match(regxDirectLaborHours))
    {
        if(mainCategoryName)
        {
            props.find = categoryName;
            array = BuildDirectLaborHours(props); 
        }else{
            array = BuildDirectLaborHours(props); 
        }

    }

    return array;
    

};
export default Build;