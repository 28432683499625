

const VPRhourlyRates = {

    "name"     : "Hourly Rates",
    "value"    :{

        "ownr_highest" : {

            "print"		:'price',
            "name"		:'ownr_highest',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },

        "ownr_lowest" : {

            "print"		:'price',
            "name"		:'ownr_lowest',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },

        "ownr_average" : {

            "print"		:'price',
            "name"		:'ownr_average',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },




        "empl_highest" : {

            "print"		:'price',
            "name"		:'empl_highest',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },

        "empl_lowest" : {

            "print"		:'price',
            "name"		:'empl_lowest',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },

        "empl_average" : {

            "print"		:'price',
            "name"		:'empl_average',
            "value"		:"",
            "required"	:true,
            "default"	:""
        }


    }


    
};





export {

    VPRhourlyRates

};












/*


  "result_items": {


    "idl-1": {

      "name": "Jane Doe",
      "value": {
        "idl_health_insurance_weekly": "115.38",
        "idl_burden_hours_yearly": "1560.00",
        "idl_vacation_weeks": "2.00",
        "idl_tax_comp_percent": "20.00",
        "idl_yearly_salary_w_tax_ira": "38064.00",
        "idl_ira_percent": "2.00",
        "idl_burden_tax_hourly": "4.00",
        "idl_hourly_rate": "20.00",
        "idl_burden_ira_yearly": "624.00",
        "idl_burden_ira_monthly": "52.00",
        "idl_burden_tax_yearly": "6240.00",
        "idl_health_insurance_hourly": "3.85",
        "idl_burden_rate_w_tax_ira_hi_hourly": "28.25",
        "idl_hourly_rate_w_tax_ira": "24.40",
        "idl_burden_ira_hourly": "0.40",
        "idl_burden_rate_w_tax_ira_hi_yearly": "44064.00",
        "idl_ira_decimal": "0.02",
        "idl_name": "Jane Doe",
        "idl_burden_rate_w_tax_ira_hi_monthly": "3672.00",
        "idl_burden_weekly_hours": "30.00",
        "idl_health_insurance_yearly": "6000.00",
        "idl_monthly_salary_w_tax_ira": "3172.00",
        "idl_tax_comp_decimal": "0.20",
        "idl_working_weeks": "50.00",
        "idl_health_insurance_monthly": "500.00",
        "idl_weekly_salary_w_tax_ira": "732.00",
        "idl_burden_tax_monthly": "520.00",
        "idl_rate_monthly": "2600.00",
        "idl_rate_yearly": "31200.00"
      }

    },
    "overhead": {
      "overhead": "40.51",
      "overhead_decimal": "0.41"
    },
    "owners": {
      "owners_sti": "72428.57",
      "owners_ira_yearly": "1000.00",
      "owners_hours_indirect_percent_whole": "25.00",
      "owners_sti_yearly_indirect": "18107.14",
      "owners_taxes_monthly": "1785.71",
      "owners_hours_direct_percent_whole": "75.00",
      "owners_hours_direct_percent": "0.75",
      "owners_taxrate_reciprocal": "0.70",
      "owners_flag": "<br/>The owner spends the majority of their time performing direct labor.<br/>25% of the owners salary will be considered an Overhead expense..<br/>Graphic Design, Production and Installation rates will use the owners Direct Labor rate.",
      "owners_billable_hours_weekly_direct": "30.00",
      "owners_sti_hourly_direct": "36.21",
      "owners_total_weekly_hours": "40.00",
      "owners_taxrate_decimal": "0.30",
      "owners_ira_monthly_indirect": "20.83",
      "owners_taxes_monthly_direct": "1339.29",
      "owners_salary_overhead": "1041.67",
      "owners_sti_monthly_direct": "4526.79",
      "owners_sti_monthly_indirect": "1508.93",
      "owners_hours_indirect_percent": "0.25",
      "owners_ira_decimal": "0.02",
      "owners_sti_weekly": "1448.57",
      "owners_taxes_monthly_indirect": "446.43",
      "owners_ira_monthly": "83.33",
      "owners_sti_yearly_direct": "54321.43",
      "owners_sti_weekly_direct": "1086.43",
      "owners_sti_weekly_indirect": "362.14",
      "owners_st": "71428.57",
      "owners_ira_monthly_direct": "62.50",
      "owners_taxes_yearly": "21428.57",
      "owners_working_weeks": "50.00",
      "owners_salary_pretax_monthly": "4166.67"
    },
    "totals": {
      "total_business": "1738.00",
      "total_idl_rate_yearly": "31200.00",
      "total_ira": "72.83",
      "total_weekly_hours": "40.00",
      "total_vehicles": "1010.00",
      "total_idl_burden_tax_monthly": "520.00",
      "total_facility": "2610.00",
      "total_tax": "966.43",
      "total": "11748.93",
      "total_health_insurance": "1000.00",
      "total_idl_health_insurance_monthly": "500.00",
      "total_equipsoftware": "710.00",
      "total_idl_rate_monthly": "2600.00",
      "total_idl_burden_ira_monthly": "52.00"
    }
  }


}












*/
