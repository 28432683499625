
export const Calc = (v) => {


	// console.log("Calc - input: "+JSON.stringify(v,null,2));
	// alert('calc input')


    const input = v.input;
	const output = {};

	var ins;
	var out = '';

    var cog;
    var good;
    var calcby;
    var overhead;
    var debits;
	var debit;
	var commission = 0;
	var fees = 0;

	var goods;
	var markup_multiplier = 0;
	var grand_total = 0;

	var keyname;


	var unit_qty = 1;


	// -- inputs -- //

    cog = input['cost_of_goods'];
	calcby = input['calcby'];
    overhead = input['overhead']['value'];
    debits = input['debits'];
	unit_qty = input['quantity']['value'];


	// if(v.calcObj)
	// {
	// 	console.log("calcObj:::::"+JSON.stringify(v.calcObj));

	// 	if(v.calcObj.overhead_percent 
	// 	)
	// 	{
	// 		var overhead_percent;
	// 		overhead_percent = parseFloat(v.calcObj.overhead_percent);
	// 		overhead_percent = overhead_percent.toFixed(1);
	// 		overhead = parseFloat(overhead_percent);

	// 	}

	// }


    ins = {

        "cost_of_goods"     : cog,
        "calcby"            : calcby,
        "overhead"          : overhead,
        "debits"        	: debits
    };


	output['overhead_percent'] = overhead;
	output['unit_qty'] = unit_qty;



    //#########################################################
    //#########################################################

    // -- debits -- //

    for(debit of debits)
    {

		if(debit.id === 'commission')
		{
			commission = debit.value;
			output['commission_percent'] = commission;

		}

		if(debit.id === 'fees')
		{
			fees = debit.value;
			output['fees_percent'] = fees;

		}


    }// -- for debits










    //#########################################################
    //#########################################################

    // -- goods -- //

	goods = {};
    var goods_total = 0;

    var qtyhrs;
    var rate;
    var math;

    for(good of cog)
    {
        qtyhrs = parseFloat(good.qtyhrs).toFixed(1);
        rate = parseFloat(good.rate).toFixed(2);

        math = qtyhrs * rate;
        math = math.toFixed(2);

        goods_total = parseFloat(goods_total) + parseFloat(math);
		goods[good.call] = good;

    }// -- for cog

	goods['total'] = goods_total;

	output['goods_total'] = goods_total;






	var fraction;
	var less;
	var just_less;	


    //#########################################################
	//#########################################################

	// -- zerobased stuff -- //

    var zerobased_percent_total;
    var zerobased_percent_leftover;

	var zerobased_markup_multiplier = 0;
	var zerobased_grand_total = 0;

	var zerobased_overhead_amount;
	var zerobased_overhead_dollar;
	

	//zerobased_percent_total = parseFloat(profit_percent) + parseFloat(overhead) + parseFloat(commission);
	//zerobased_percent_total = parseFloat(profit_percent) + parseFloat(overhead);
	zerobased_percent_total = parseFloat(overhead).toFixed(2);

	zerobased_percent_leftover = parseFloat(100 - zerobased_percent_total);
	if(parseFloat(zerobased_percent_leftover) < 0.09)
	{
	
		console.log("ALERT : Zerobased Percentages Exceed 99.9%");
		return false;

	}//===

	zerobased_markup_multiplier = (100 / parseFloat(zerobased_percent_leftover));
	//zerobased_markup_multiplier = markup_multiplier.toFixed(2);


	zerobased_grand_total = parseFloat(zerobased_markup_multiplier) * parseFloat(goods_total);
	zerobased_grand_total = zerobased_grand_total.toFixed(2);


	output['zerobased_percent_total'] = zerobased_percent_total;
	output['zerobased_percent_leftover'] = zerobased_percent_leftover;
	output['zerobased_markup_multiplier'] = zerobased_markup_multiplier;
	output['zerobased_grand_total'] = zerobased_grand_total;




	// -- zerobase_overhead_dollar -- //

	fraction = parseFloat(overhead).toFixed(1);

	//out += "\nFRACTION: " + fraction;

	less = (fraction/100) * parseFloat(zerobased_grand_total);
	less = less.toFixed(2);

	zerobased_overhead_amount = less;

	out += "\nZEROBASED OVERHEAD AMOUNT: $" + less;

	just_less = parseFloat(zerobased_grand_total) - parseFloat(less);
	just_less = just_less.toFixed(2);

	out += "\nPROFIT JUST LESS THE ZEROBASED OVERHEAD: $" + just_less;


	zerobased_overhead_dollar = zerobased_overhead_amount;
    

	out += "\nZEROBASED MARKUP MULTIPLIER : "+zerobased_markup_multiplier;
	out += "\nZEROBASED GRAND TOTAL : "+zerobased_grand_total;


	output['zerobased_overhead_amount'] = less;
	output['profit_less_zerobased_overhead'] = just_less;




	//#########################################################
	//#########################################################

	// -- calcby markup_multiplier -- //

    var percent_total;
    var percent_leftover;

	markup_multiplier = 0;
	grand_total = 0;
	

    switch(calcby.call)
    {
    case'price':

        markup_multiplier = parseFloat(calcby.value) / parseFloat(goods_total);
        //markup_multiplier = markup_multiplier.toFixed(2);

        if(isNaN(markup_multiplier) || !isFinite(markup_multiplier)) 
		{
			markup_multiplier = 0;
		}
		//if(parseFloat(markup_multiplier) <= 0) markup_multiplier = parseFloat(markup_multiplier) + 1;

    break;
    case'percent':

        percent_total = parseFloat(calcby.value) + parseFloat(overhead) + parseFloat(commission) + parseFloat(fees);
        percent_total = percent_total.toFixed(2);

        percent_leftover = parseFloat(100 - percent_total);
        if(parseFloat(percent_leftover) < 0.09)
        {
			console.log("ALERT : Percentages Exceed 99.9%");
			return false;

        }//===

        markup_multiplier = (100 / parseFloat(percent_leftover));
        //markup_multiplier = markup_multiplier.toFixed(2);
    break;
    default:

		markup_multiplier = parseFloat(calcby.value);
    break;
    }//==


	grand_total = parseFloat(markup_multiplier) * parseFloat(goods_total);
	grand_total = grand_total.toFixed(2);

	out += "\n";
	out += "\nCALCBY CALL: "+calcby.call;
	out += "\nCALCBY VALUE: "+calcby.value;
	out += "\nMARKUP MULTIPLIER : "+markup_multiplier;
	out += "\nGRAND TOTAL : "+grand_total;


	output['calcby_call'] = calcby.call;
	output['calcby_value'] = calcby.value;
	output['markup_multiplier'] = markup_multiplier;
	output['grand_total'] = grand_total;


    //#########################################################
    //#########################################################

	// -- out -- //

	out += "\nGRAND TOTAL : "+grand_total;
	out += "\n";

	output['grand_total'] = grand_total;

	var good_decimal;
	var good_percent;

	var good_qtyhrs_rate;



	for(keyname in goods)
	{
		good = goods[keyname];

		switch(keyname)
		{
		case'total':

			out += "\n";
			out += "TOTAL : "+good;
			out += "\n";

		break;
		default:


			good_qtyhrs_rate = parseFloat(good.qtyhrs) * parseFloat(good.rate);

			good_decimal = parseFloat(good_qtyhrs_rate) / parseFloat(grand_total);

			good_percent = parseFloat(good_decimal) * 100;
			good_percent = good_percent.toFixed(1);

			if(isNaN(good_decimal)) good_decimal = 0;
			if(isNaN(good_percent)) good_percent = 0;


			out += "\n";
			out += "GOOD : "+JSON.stringify(good,null,2);
			out += "\n";
			out += "GOOD QTYHRS RATE : "+good_qtyhrs_rate;
			out += "\n";
			out += "GOOD DECIMAL : "+good_decimal;
			out += "\n";
			out += "GOOD PERCENT : "+good_percent;
			out += "\n";


			goods[keyname]['qtyhrs_rate'] = good_qtyhrs_rate;
			goods[keyname]['decimal'] = good_decimal;
			goods[keyname]['percent'] = good_percent;

		}

	}//== 










	//############################################################
	//############################################################

	var profit;
	var gross_profit_percent;
	var profit_percent;
	var price_each;
	//var price_per_sqfoot;
	var goods_total_percent;
	var overhead_amount;
	var commission_amount;
	var fees_amount;
	var breakeven;	
	var breakeven_percent;




	//===========================================================

	profit = parseFloat(grand_total) - parseFloat(goods_total);
	profit = profit.toFixed(2);

	gross_profit_percent = parseFloat(profit) / parseFloat(grand_total);
	gross_profit_percent = (gross_profit_percent * 100);
	gross_profit_percent = gross_profit_percent.toFixed(1);
	if(isNaN(gross_profit_percent)) gross_profit_percent = 0;


		out += "\nGROSS PROFIT PERCENT: " + gross_profit_percent;
		out += "\nGROSS PROFIT: $" + profit;

		output['gross_profit_percent'] = gross_profit_percent;
		output['gross_profit'] = profit;



		//----------------------------
		//OVERHEAD
		//----------------------------
		fraction = parseFloat(overhead);
		fraction = fraction.toFixed(1);

		less = (fraction/100) * parseFloat(grand_total);
		less = less.toFixed(2);

		out += "\n\tLESS OVERHEAD: $" + less;

		output['less_overhead'] = less;

		profit = parseFloat(profit) - parseFloat(less);
		profit = profit.toFixed(2);


		//----------------------------
		//COMMISSION
		//----------------------------
		fraction = parseFloat(commission);
		fraction = fraction.toFixed(1);

		less = (fraction/100) * parseFloat(grand_total);
		less = less.toFixed(2);

		out += "\n\tLESS COMMISSION: $" + less;

		output['less_commission'] = less;

		profit = parseFloat(profit) - parseFloat(less);
		profit = profit.toFixed(2);



		//----------------------------
		//FEES
		//----------------------------
		fraction = parseFloat(fees);
		fraction = fraction.toFixed(1);

		less = (fraction/100) * parseFloat(grand_total);
		less = less.toFixed(2);

		out += "\n\tLESS FEES: $" + less;

		output['less_fees'] = less;

		profit = parseFloat(profit) - parseFloat(less);
		profit = profit.toFixed(2);





	if(parseInt(profit) === 0 && parseInt(grand_total) === 0)
	{
		profit_percent = 0;
		
	}else{

		profit_percent = parseFloat(profit) / parseFloat(grand_total);
		
		//alert(parseFloat(profit)+"\n"+parseFloat(grand_total));
		//profit_percent = profit_percent.toFixed(2);

		profit_percent = (profit_percent * 100);
		profit_percent = profit_percent.toFixed(1);
		
	}

	out += "\nPROFIT: $" + profit;
	out += "\nPROFIT PERCENTAGE: " + profit_percent + "%";

	output['profit'] = profit;
	output['profit_percent'] = profit_percent;
	//===========================================================



	out += "\n";
	


	//==========================================================
	//PRICE EACH - PRICE PER SQ FOOT
	
	price_each = parseFloat(grand_total) / parseInt(unit_qty);
	price_each = price_each.toFixed(2);

	// price_per_sqfoot = parseFloat(grand_total) / parseFloat(options.data.sqfoot.value);
	// price_per_sqfoot = price_per_sqfoot.toFixed(2);

	out += "\nPRICE EACH: $" + price_each;
	//out += "\nPRICE PER SQFOOT: $" + price_per_sqfoot;

	output['price_each'] = price_each;
	//==========================================================


	out += "\n";


	//==========================================================
	//JUST GOODS TOTAL

	if(parseInt(goods_total) === 0 && parseInt(grand_total) === 0)
	{

		out += "\nGOODS TOTAL PERCENT: "+0;
		out += "\nGOODS TOTAL AMOUNT: $" + 0;
		out += "\nPROFIT JUST LESS THE GOODS TOTAL: $" + 0;

		output['goods_total_percent'] = 0;
		output['goods_total_amount'] = 0;
		output['profit_less_goods_total'] = 0;

	}else{

		fraction = parseFloat(goods_total) / parseFloat(grand_total);
		fraction = (fraction * 100);
		fraction = fraction.toFixed(1);

		//out += "\nFRACTION: " + fraction;

		goods_total_percent = fraction;

		out += "\nGOODS TOTAL PERCENT: "+goods_total_percent;

		less = (fraction/100) * parseFloat(grand_total);
		less = less.toFixed(2);

		out += "\nGOODS TOTAL AMOUNT: $" + less;

		just_less = parseFloat(grand_total) - parseFloat(less);
		just_less = just_less.toFixed(2);

		out += "\nPROFIT JUST LESS THE GOODS TOTAL: $" + just_less;
		
		output['goods_total_percent'] = goods_total_percent;
		output['goods_total_amount'] = less;
		output['profit_less_goods_total'] = just_less;

	}


	//==========================================================


	out += "\n";



	
	//==========================================================
	//JUST OVERHEAD

	fraction = parseFloat(overhead).toFixed(1);

	//out += "\nFRACTION: " + fraction;

	less = (fraction/100) * parseFloat(grand_total);
	less = less.toFixed(2);

	overhead_amount = less;

	out += "\nOVERHEAD AMOUNT: $" + overhead_amount;

	just_less = parseFloat(grand_total) - parseFloat(less);
	just_less = just_less.toFixed(2);

	out += "\nPROFIT JUST LESS THE OVERHEAD: $" + just_less;

	output['overhead_amount'] = overhead_amount;
	output['profit_less_overhead'] = just_less;
	//==========================================================


 


	out += "\n";


	//==========================================================
	//JUST COMMISSION

	fraction = parseFloat(commission).toFixed(1);

	//out += "\nFRACTION: " + fraction;

	less = (fraction/100) * parseFloat(grand_total);
	less = less.toFixed(2);

	commission_amount = less;

	out += "\nCOMMISSION AMOUNT: $" + commission_amount;

	just_less = parseFloat(grand_total) - parseFloat(less);
	just_less = just_less.toFixed(2);

	out += "\nPROFIT JUST LESS THE COMMISSION: $" + just_less;

	output['commission_amount'] = commission_amount;
	output['profit_less_commission'] = just_less;
	//===========================================================




	out += "\n";


	//==========================================================
	//JUST FEES

	fraction = parseFloat(fees).toFixed(1);

	//out += "\nFRACTION: " + fraction;

	less = (fraction/100) * parseFloat(grand_total);
	less = less.toFixed(2);

	fees_amount = less;

	out += "\nFEES AMOUNT: $" + fees_amount;

	just_less = parseFloat(grand_total) - parseFloat(less);
	just_less = just_less.toFixed(2);

	out += "\nPROFIT JUST LESS THE FEES: $" + just_less;

	output['fees_amount'] = fees_amount;
	output['profit_less_fees'] = just_less;
	//===========================================================





	out += "\n";
	
	
	//==========================================================
	//BREAKEVEN

	breakeven_percent = 0;
	
	//breakeven = parseFloat(grand_total) - parseFloat(profit);//original

	out += "\nZEROBASED OVERHEAD DOLLAR: "+zerobased_overhead_dollar;
	out += "\nGOODS TOTAL: "+goods_total;
	out += "\nCOMMISSION AMOUNT: "+commission_amount;
	out += "\nFEES AMOUNT: "+commission_amount;



	breakeven = parseFloat(goods_total) + parseFloat(zerobased_overhead_dollar) + parseFloat(commission_amount) + parseFloat(fees_amount);
	breakeven = breakeven.toFixed(2);


	// breakeven = parseFloat(goods_total) + parseFloat(overhead_amount) + parseFloat(commission_amount) + parseFloat(fees_amount);
	// breakeven = breakeven.toFixed(2);

	
	out += "\nBREAKEVEN PERCENT: "+breakeven_percent+"%";
	out += "\nBREAKEVEN: $" + breakeven;

	output['zerobased_overhead_dollar'] = zerobased_overhead_dollar;
	output['goods_total'] = goods_total;
	output['commission_amount'] = commission_amount;

	output['breakeven_percent'] = breakeven_percent;
	output['breakeven'] = breakeven;
	//===========================================================





    //#########################################################
    //#########################################################

    // -- output -- //

    return {

        ins                 :ins,
        goods               :goods,
        markup_multiplier   :markup_multiplier,
		grand_total         :grand_total,
		output				:output,
		out					:out

    };


}





