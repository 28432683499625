
const SortItems = (props) => {


    var calcs;
    var keyname;

    var sortedItems = [];
    var sortCriteria = [];

    var items = props.items;
    var item;
    var crit;


    switch(props.data.value)
    {
    case'a-z':

        for(item of items)
        {
            sortCriteria.push(item.data.name);
        }
        sortCriteria.sort();

        for(crit of sortCriteria)
        {
            for(item of items)
            {
                if(crit === item.data.name)
                {
                    sortedItems.push(item);
                    break;
                    
                }
            }
            
        }

    break;
    case'z-a':

        for(item of items)
        {
            sortCriteria.push(item.data.name);
        }
        sortCriteria.sort();
        sortCriteria.reverse();

        for(crit of sortCriteria)
        {
            for(item of items)
            {
                if(crit === item.data.name)
                {
                    sortedItems.push(item);
                    break;
                    
                }
            }
            
        }


    break;
    case'favorites-low-high':

        sortCriteria = [1,2,3,4,5];

        for(crit of sortCriteria)
        {
            for(item of items)
            {
                if(crit === parseInt(item.data.favorite))
                {
                    sortedItems.push(item); 
                    //break;
                }
            }
            
        }

    break;
    case'favorites-high-low':

        sortCriteria = [5,4,3,2,1];

        for(crit of sortCriteria)
        {
            for(item of items)
            {
                if(crit === parseInt(item.data.favorite))
                {
                    sortedItems.push(item);
                    //break;
                    
                }
            }
            
        }

    break;
    default:


        if(props.zoneObj['calcs'])
        {
            calcs = JSON.parse(JSON.stringify(props.zoneObj['calcs']));
            for(keyname in calcs)
            {
                sortedItems.push({

                    heading:calcs[keyname].data.name,
                    bullet:false,
                    stripe:true,
                    pgraph:calcs[keyname].data.description,
                    image:false,
                    button:false,
                    data:{
        
                        id:keyname,
                        name:calcs[keyname].data.name,
                        value:calcs[keyname].data.value,
                        descr:calcs[keyname].data.description,
                        favorite:calcs[keyname].data.favorite
        
                    }

                });
            }

        }

    }



    return sortedItems;


}

export default SortItems;


