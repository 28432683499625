import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';


import {

    ReportTxt

} from '../Styled';




const Index = (props) => {


    // -- ref -- //
    const refA = useRef(null);


    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState({});
    const [items, setItems] = useState([]);


    useEffect(() => {


        var array = [];

        if(!isLoaded)
        {
            setIsLoaded(true);

            if(Object.prototype.toString.call(props.data.value) === '[object Array]')
            {
                //var object;
                array = props.data.value;
                setItems(array);
            }    
            

        }else{


            if(Object.prototype.toString.call(props.data.value) === '[object Array]')
            {
                //var object;
                array = props.data.value;
                setItems(array);
            }    
            


            var margin;
            var marginTop = "10px";
            var marginLeft = "10px";
            if(props.data.boxHeight 
            && props.data.boxHeight !== undefined
            )
            {
                marginTop = (props.data.boxHeight - refA.current.offsetHeight)/2 - (5) + "px";
                //marginLeft = "calc(50% - "+(refA.current.offsetWidth/2)+"px";
                //console.log("ledger marginTop: "+marginTop);

                margin = marginTop+" 0 0 "+marginLeft

            }

            if(props.lessThanThresh)
            {
                marginTop = "0px";
                marginLeft = "calc(50% - "+(refA.current.offsetWidth/2)+"px)";

                margin = marginTop+" 0 25px "+marginLeft

            }


            setData({

                id:props.id,
                margin:margin
         
            });

        }
        //console.log("txtData: "+JSON.stringify(props.data,null,2));

   
    },
    [
        props,
        isLoaded
    ])





    return (

        <ReportTxt
        ref={refA}
        id={data.id}
        margin={data.margin}
        //backgroundColor={'lightyellow'}
        >
            {items.map((itm, k) => (

                <div 
                key={k}
                className={itm.className}
                style={{

                    //fontSize:itm.style.fontSize,
                    textAlign:itm.style.textAlign
                }}
                >
                    {itm.txt}

                </div>

            ))}

        </ReportTxt>

    )


}

export default Index;


