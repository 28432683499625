/*
----------------------
Flex or Fixed
----------------------

MAX - 4 across

 "across1"     :501,        
 "across2"     :801,          
 "across3"     :1050


MAX - 3 across

 "across1"     :501,        
 "across2"     :801,          
 "across3"     :10000


MAX - 2 across

 "across1"     :501,        
 "across2"     :10000,          
 "across3"     :10000

 */




import { 
    Lorem, 
    //TestImages,
    //SpecialImages,
    //NavigationData 
} from 'app-components/Prime/Data';







const Newspaper = (props) => {



    var object = {




        styl:{

            call:"indent",
            class:"IndentRow",

            marginTop:"50px",
            marginBottom:"0"


        },
        call:'TxtComponent',
        info:{

            call:'flex', //fixed, flex
            gridType: "Newspaper", //Newspaper
            gridBgColor:'transparent',
            gridCall: "grid_cell",  //grid_cell or grid_column_cell
            cellCall: "default",  //container or default 
            modCall: 'fill_div',
            thumb: {

                call    : "default", //flex_height or default
                diff    : 0

            },    
            cellBorder: {
                "boolean"       :true,
                "thickness"     :1,//px
                "color"         :"gray"  
            },
            gutter: {
                "boolean"   :true,
                "size"      :20
            },
            thresh: {
                "refWidth"    :false,   //number or false = default screen width
                "across1"     :501,
                "across2"     :801,
                "across3"     :1050
            }



        },

        items:[{

            heading:"Newspaper Column Sample",
            pgraph:Lorem+" "+Lorem+" "+Lorem
        }]


            


    };



    object.styl.call = props.styl.call;
    object.styl.class = props.styl.class;
    object.styl.marginTop = props.styl.marginTop;
    object.styl.marginBottom = props.styl.marginBottom;




    if(props.info.thumb.diff) object.info.thumb.diff = props.info.thumb.diff;

    if(props.info.gutter.boolean)
    { 
        object.info.gutter.boolean = props.info.gutter.boolean;
        object.info.gutter.size = props.info.gutter.size;
    }

    if(props.info.thresh) object.info.thresh.across1 = props.info.thresh.across1;
    if(props.info.thresh) object.info.thresh.across2 = props.info.thresh.across2;
    if(props.info.thresh) object.info.thresh.across3 = props.info.thresh.across3;

    object.items = props.items;

    
    return object;


}





const SingleTextBox = (props) => {



    var object = {


        styl:{

            call:"indent",
            class:"IndentRowB",

            marginTop:"50px",
            marginBottom:"0"
        
        },
        call:'TxtComponent',
        info:{

            call:'flex', //fixed, flex
            gridType: "SingleTextBox", //SingleTextBox, TextBox, ImageTextBox, Newspaper, Classic
            gridBgColor:'transparent',
            gridCall: "grid_cell",  //grid_cell or grid_column_cell
            cellCall: "default",  //container or default 
            modCall: 'fill_div',
            thumb: {

                call    : "default", //flex_height or default
                diff    : 0

            },    
            cellBorder: {
                "boolean"       :true,
                "thickness"     :1,//px
                "color"         :"gray"  
            },
            gutter: {
                "boolean"   :true,
                "size"      :20
            },
            thresh: {
                "refWidth"    :false,   //number or false = default screen width
                "across1"     :501,
                "across2"     :801,
                "across3"     :1050
            }



        },

        
            
        items:[]

    };




    object.styl.call = props.styl.call;
    object.styl.class = props.styl.class;
    object.styl.marginTop = props.styl.marginTop;
    object.styl.marginBottom = props.styl.marginBottom;






    if(props.info.thumb.diff) object.info.thumb.diff = props.info.thumb.diff;

    if(props.info.gutter.boolean)
    { 
        object.info.gutter.boolean = props.info.gutter.boolean;
        object.info.gutter.size = props.info.gutter.size;
    }

    if(props.info.thresh) object.info.thresh.across1 = props.info.thresh.across1;
    if(props.info.thresh) object.info.thresh.across2 = props.info.thresh.across2;
    if(props.info.thresh) object.info.thresh.across3 = props.info.thresh.across3;

    object.items = props.items;

    
    return object;


}






const ImageTextBox = (props) => {



    var object = {


        styl:{

            call:"full",
            class:"FullRow",

            marginTop:"0px",
            marginBottom:"0"
        
        },
        call:'TxtComponent',
        info:{

            call:'flex', //fixed, flex
            gridType: "ImageTextBox", //SingleTextBox, TextBox, ImageTextBox, Newspaper, Classic
            gridBgColor:'transparent',
            gridCall: "grid_cell",  //grid_cell or grid_column_cell
            cellCall: "default",  //container or default 
            modCall: 'fill_div',
            thumb: {

                call    : "default", //flex_height or default
                diff    : 0

            },    
            cellBorder: {
                "boolean"       :true,
                "thickness"     :1,//px
                "color"         :"gray"  
            },
            gutter: {
                "boolean"   :true,
                "size"      :20
            },
            thresh: {
                "refWidth"    :false,   //number or false = default screen width
                "across1"     :501,
                "across2"     :801,
                "across3"     :1050
            }



        },

            
        items:[]

    };







    object.styl.call = props.styl.call;
    object.styl.class = props.styl.class;
    object.styl.marginTop = props.styl.marginTop;
    object.styl.marginBottom = props.styl.marginBottom;


    if(props.info.thumb.diff) object.info.thumb.diff = props.info.thumb.diff;

    if(props.info.gutter.boolean)
    { 
        object.info.gutter.boolean = props.info.gutter.boolean;
        object.info.gutter.size = props.info.gutter.size;
    }

    if(props.info.thresh) object.info.thresh.across1 = props.info.thresh.across1;
    if(props.info.thresh) object.info.thresh.across2 = props.info.thresh.across2;
    if(props.info.thresh) object.info.thresh.across3 = props.info.thresh.across3;

    object.items = props.items;

    
    return object;


}






const TextBox = (props) => {



    var object = {



        styl:{

            call:"full",
            class:"FullRow",

            marginTop:"50px",
            marginBottom:"0"
        
        },
        call:'TxtComponent',
        info:{

            call:'flex', //fixed, flex
            gridType: "TextBox", //SingleTextBox, TextBox, ImageTextBox, Newspaper, Classic
            gridBgColor:'transparent',
            gridCall: "grid_cell",  //grid_cell or grid_column_cell
            cellCall: "default",  //container or default 
            modCall: 'fill_div',
            thumb: {

                call    : "default", //flex_height or default
                diff    : 0

            },    
            cellBorder: {
                "boolean"       :true,
                "thickness"     :1,//px
                "color"         :"gray"  
            },
            gutter: {
                "boolean"   :true,
                "size"      :20
            },
            thresh: {
                "refWidth"    :false,   //number or false = default screen width
                "across1"     :501,
                "across2"     :801,
                "across3"     :1050
            }



        },

        
        items:[]

    };




    object.styl.call = props.styl.call;
    object.styl.class = props.styl.class;
    object.styl.marginTop = props.styl.marginTop;
    object.styl.marginBottom = props.styl.marginBottom;




    if(props.info.thumb.diff) object.info.thumb.diff = props.info.thumb.diff;

    if(props.info.gutter.boolean)
    { 
        object.info.gutter.boolean = props.info.gutter.boolean;
        object.info.gutter.size = props.info.gutter.size;
    }

    if(props.info.thresh) object.info.thresh.across1 = props.info.thresh.across1;
    if(props.info.thresh) object.info.thresh.across2 = props.info.thresh.across2;
    if(props.info.thresh) object.info.thresh.across3 = props.info.thresh.across3;

    object.items = props.items;

    
    return object;


}





export {

    Newspaper,
    SingleTextBox,
    ImageTextBox,
    TextBox
}

