export const IRA = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 500 500";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};

	let iconColor = props.iconColor || "rgb(0,0,0)";


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>


			<path 
				fill={iconColor}
				fillRule="evenodd"
				clipRule="evenodd"
				d="M354.074,193.927c-8.688,0-15.773,7.086-15.773,15.775	c0,8.688,7.086,15.774,15.773,15.774s15.775-7.087,15.775-15.774C369.85,201.013,362.762,193.927,354.074,193.927L354.074,193.927z M80.043,190.831c9.465,0,12.492,2.932,16.603,7.595c0.315,0.048,0.632,0.106,0.943,0.168
				c18.978-66.851,124.032-86.11,180.032-75.639c8.395-15.5,34.736-25.704,52.184-25.811c6.965-0.042,17.82,0.584,17.82,10.962	c0,10.376,0.434,34.019,0.434,34.019c15.941,2.54,31.977,31.217,38.477,42.844c6.135,10.98,21.594,22.389,33.348,24.987	c11.967-2.715,22.475,7.735,22.961,15.181c0,0,0,48.221,0,51.883s-0.609,9.156-8.545,9.766s-13.883,1.576-19.986,6.461 c-17.281,13.822-31.699,39.115-56.922,45.422c-4.883,1.219-9.766,6.713-9.766,14.648c0,7.934,0,18.313,0,24.414 c0,6.105,0.744,15.127-10.244,15.127c-10.986,0-24.088,0-32.482,0c-8.393,0-10.986-1.088-12.816-7.191
				c-1.832-6.104-14.65-26.855-14.65-26.855s-6.104-4.273-12.207-3.664c-6.104,0.611-55.423-0.609-59.696,0 c-4.272,0.611-13.142,0.289-18.023,9.445c-3.196,5.994-4.926,28.266-11.666,28.266c-7.071,0-26.331,0-34.876,0	c-8.545,0-12.563-5.971-12.563-12.074s-0.323-34.346-0.255-41.572c0.067-7.225-1.437-19.236-9.545-26.467 c-17.338-15.461-23.934-43.605-25.245-68.285c-3.714-0.005-7.222-0.95-10.311-2.613l-12.832,9.088
				c-4.157,2.941-9.35,2.846-11.516-0.242l-0.579-0.822c-2.163-3.082-0.56-7.992,3.593-10.934l7.04-4.985 c-6.662-4.021-11.156-9.354-11.156-19.979C57.596,203.346,70.577,190.831,80.043,190.831L80.043,190.831z M336.541,121.348v26.851 c0,0,1.617,6.146-4.854,5.823c-6.469-0.324-12.615,1.94-14.557,4.853c-1.941,2.911-8.088,0.646-7.764-4.853 c0.539-9.176,10-33.421,20.381-36.88C336.906,114.375,336.541,121.348,336.541,121.348L336.541,121.348z M78.694,225.084 c5.282-4.396,6.768-6.019,7.935-13.299c-1.922-6.044-6.861-8.421-12.436-3.264C68.618,213.679,75.119,221.396,78.694,225.084 L78.694,225.084z M168.221,164.348c-2.836,1.317-6.199,0.082-7.509-2.758c-1.309-2.839-0.071-6.211,2.765-7.528l0.045-0.021 l0.005,0.01c5.129-2.393,10.333-4.487,15.553-6.293c5.23-1.811,10.67-3.404,16.236-4.787c28.146-6.99,57.804-7.833,79.444-3.786 c3.072,0.574,5.098,3.533,4.523,6.605c-0.574,3.071-3.535,5.098-6.605,4.523c-20.209-3.779-48.064-2.952-74.658,3.653 c-5.08,1.262-10.188,2.766-15.259,4.521c-5.075,1.755-9.926,3.695-14.472,5.817l-0.023,0.022L168.221,164.348L168.221,164.348z"
			/>

				



		</svg>
		</div>

	);//return

	




}