import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';


import { 
    useSelector, 
    useDispatch 
} 
from 'react-redux';
import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';



import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    useHistory,   
    //useParams,



} from "react-router-dom";

import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'
import useBreadcrumbs from 'utils/hooks/Breadcrumbs';



import {

    //---------------
    // Stage
    //---------------

    Stage,
    ScrollBox,
    // Area,
    // AreaTitle,



    //---------------
    // Layout
    //---------------

    MenuAlfa,
    Menu,
    MenuControls,

    Topbar,Topbar2,

    Side,
    Side2, 
    Main,

    // Rows,
    // FullRow,
    // IndentRow,

    // Box,

    Fly,
    FlyContainer,
    FlyTray,

    // Tile,
    // TileName,
    // TileTop,
    // TileControls
    

} from 'app-components/Zone/_Styled';




// import {

//     Catalog,
//         CatalogTop,
//             CatalogTopName,
//             //CatalogTopControls,

//         //CatalogBar,
//         CatalogTileGlossary,


// } from 'utils/components/Catalog/Styled';




import Catalog from 'utils/components/Catalog';



// import StylScene from './_StylModules/Scene.module.css';
// // import StylPage from '../_StylModules/Page.module.css';
// // import StylRows from '../_StylModules/Rows.module.css';

// import FGrid from 'utils/components/Grid/FGrid';
// import VidGrid from 'utils/components/Grid/VidGrid';

// import TxtComponent from 'utils/components/Content/Txt';
// import ImgComponent from 'utils/components/Content/Img';
// import CardComponent from 'utils/components/Card';

import Navigator from 'utils/components/Content/Navigator';

//import SideNavInfo from 'utils/components/Pages/SceneInfo/Trials/SideNav/SceneInfo';

import { FetchKnowledgeBase as Fetch } from 'app-components/Zone/_Connect/KnowledgeBase/Fetch';
import Loading from 'app-components/Zone/_Generic/Loading';
// import Message from 'app-components/Zone/_Generic/Message';
// import Flanks from 'app-components/Zone/_Generic/Flanks';



import { FetchInfoPanel as FetchPanel } from 'app-components/Zone/_Connect/InfoPanel/Fetch';

import Panel from 'app-components/Zone/_InfoPanel';
import PanelInfo from 'app-components/Zone/_InfoPanel/Info';
import PanelLoading from 'app-components/Zone/_InfoPanel/Loading';



// import DefaultFetchResponse from 'utils/components/Pages/Default/FetchResponse';



// import { 
//     //LibArray,
//     //LibSystem 
// } from 'library';


// import SceneBuild from '../SceneBuild';
// import SceneInfo from './SceneInfo';


import SideNavInfo from '../SideNavInfo';
import SceneInfo from './SceneInfo';







const Index = (props) => {


    //const Scene = SceneBuild(SceneInfo());
 

    // -- window dims -- //
    const { 
        width,
        height 
    } = useWindowDimensions();



    // -- breadcrumbsHook -- //
    const breadcrumbsHook = useBreadcrumbs();
    const breadcrumbs = breadcrumbsHook.breadcrumbs;
    const handleBreadcrumbs = breadcrumbsHook.handle;



    // -- redux --//
    const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));


    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    const history = useHistory();

    
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);






    const Scene = SceneInfo({

        zoneObj:zoneObj

    });
    const SideNavData = SideNavInfo({

        zoneObj:zoneObj

    });




    //=============================================
    // refs
    //=============================================

    // const [rowRefs] = useState([]);
    // const setRowRef = (ref) => {

    //     if(ref && ref !== null && ref !== undefined)
    //     {
    //         //console.log("ref::::"+ref.id);
    //         var found;
    //         found = false;
    //         for(var i=0; i < rowRefs.length; i++)
    //         {
    //             if(rowRefs[i].id === ref.id) found = true;
    //         }
    //         if(!found) rowRefs.push(ref);
    //         //console.log(boxRefs.length);

    //     }

    // };


    // -- ref -- //
    const refScrollbox = useRef(null);
    const refTopbar = useRef(null); 
    const refSide = useRef(null);
    const refSide2 = useRef(null);
    const refMain= useRef(null);



    //=============================================
    // state/effect - thresholds
    //=============================================

    // -- state -- //
    const [thresholdSide1] = useState(1049);
    const [thresholdSide2, setThresholdSide2] = useState(true);
    const [showMenu, setShowMenu] = useState(false);
    const [panelStatus, setPanelStatus] = useState({

        show:false,
        call:'init',
        data:PanelInfo.init


    });


    useEffect(() => {

        if(width > thresholdSide1 && showMenu)
        {
            setShowMenu(false);

        }
        var aboveNum = 1400;
        if(thresholdSide2 && width < aboveNum) setThresholdSide2(false);
        if(!thresholdSide2 && width > aboveNum-1) setThresholdSide2(true);

    },
    [

        width,
        thresholdSide1,
        thresholdSide2,
        showMenu

    ])


    










    //=============================================
    // state/effect - isLoaded,styling
    //=============================================

    const [contentHeight, setContentHeight] = useState(0);


    const [isLoaded, setIsLoaded] = useState(false);
    const [styling,setStyling] = useState({

        topbar:{

            width:false,
            height:false,
            margin:false
        },     
        scrollbox:{
            
            width:false,
            height:false
        },
        main:{

            width:false,
            margin:false,
            padding:false
        },
        side2:{

            backgroundImage:false,
            backgroundPosition:false,
            backgroundSize:false, 
            width:"calc(100% - 850px)",
            height:false,
            transform:false

        },
        catalog:{

            top:{

                textColor:"#fff"
                
            },
            bar:{

                backgroundColor:"#454d5f"
                
            }
     
        }


    });



    useEffect(() => {

    

        // if(!isLoaded)
        // {

            setIsLoaded(true);

            var mainWidth, mainWidthInt;
            var mainMargin, mainPadding;

            // var side2BgImage = "/_resources/icons/lightbulb.svg";
            // var side2BgPosition = false;
            // var side2BgSize = false;
            // var side2Width, side2WidthInt;
            // side2Width = "0px";


            var side2BgImage = "/_resources/icons/lightbulb.svg";
            //var side2BgImage = false;
            var side2BgPosition = false;
            var side2BgSize = false;
            var side2Width;
            var side2WidthInt;
            var side2Height;
            var side2HeightInt;
            var side2Transform;

            side2Width = "0px";
            // side2Height = false;
            side2BgSize = false;
            side2BgPosition = false;
            side2Transform = false;



            var mar;
            var maxMar;
            //console.log("refMain.offsetHeight::: "+refMain.current.offsetHeight);

            // if(refSide2 && refSide2.current)
            // {
            //     if(refSide2.current.offsetHeight < refScrollbox.current.scrollHeight)
            //     {
            //         //console.log("refSide2.offsetHeight:::"+refSide2.current.offsetHeight); 
            //         alert("refScrollbox::: "+refScrollbox.current.scrollHeight+"\nrefSide2:: "+refSide2.current.offsetHeight);
            //         setCapturedHeight(refScrollbox.current.scrollHeight + "px");

            //     }
            // }


            if(refScrollbox.current.offsetWidth > 800)
            {


                mar = (refScrollbox.current.offsetWidth - refMain.current.offsetWidth)/2;
                if(refSide && refSide.current)
                {
                    maxMar = 50;
                    //console.log("mar"+mar);
                    if(mar > maxMar && refScrollbox.current.offsetWidth > refMain.current.offsetWidth+maxMar*2) mar = maxMar;
                    
                }
                //console.log("above");
                mainWidthInt = 750;
                mainWidth = mainWidthInt + "px";  
                mainMargin = "0 0 0 " +mar+"px";
                mainPadding = "0 0 0 0";



                // //-------------------

                // side2WidthInt = refScrollbox.current.offsetWidth - (mainWidthInt+150);
                // side2Width = side2WidthInt + "px";

                // // if(side2WidthInt > 300) 
                // //     side2Width = side2WidthInt + "px";

                // // if(side2WidthInt > 550) 
                // //     side2Width = "550px";


                // //side2BgSize = (refScrollbox.current.offsetHeight) + "px " + (refScrollbox.current.offsetHeight) + "px";

                // //side2BgSize = (side2Width * 2) + "px " + (side2Width * 2) + "px";

                // side2BgSize = "auto 100vh";

                // side2BgPosition = "bottom 20px right 100%";

                // //------------------



                //------------------
                
                //side2WidthInt = refScrollbox.current.offsetWidth - (refMain.current.offsetWidth+75);
                side2WidthInt = refScrollbox.current.offsetWidth - (mainWidthInt+mar+60);
                side2Width = side2WidthInt + "px";


                //side2HeightInt = refMain.current.offsetHeight;
                side2HeightInt = refScrollbox.current.scrollHeight;
                side2Height = side2HeightInt + "px";



                // if(side2WidthInt > 300) 
                //     side2Width = side2WidthInt + "px";

                // if(side2WidthInt > 550) 
                //     side2Width = "550px";


                //side2BgSize = (refScrollbox.current.offsetHeight) + "px " + (refScrollbox.current.offsetHeight) + "px";

                //side2BgSize = (side2Width * 2) + "px " + (side2Width * 2) + "px";

                if(side2WidthInt > 300) 
                {
                    if(panelStatus.call === 'off' || panelStatus.call === 'closed')
                    {
                        side2BgImage = "/_resources/logos/tpc_profit_pie.svg";

                    }
                }
                side2BgSize = "auto 100vh";
                side2BgPosition = "top -15px right -325px";

                side2Transform = "rotate(0deg)";


                //---------------------




            }else{


                if(refScrollbox.current.offsetWidth < 801)
                {
                    //console.log("above");
                    mainWidth = "92%";
                    mainMargin = "0 0 0 0";
                    mainPadding = "0 0 100px 4%";
                }else{
                    //console.log("below");
                    mainWidth = "92%";
                    mainMargin = "0 0 0 0";
                    mainPadding = "0 0 100px 4%";
                }


            }




            var scrollboxBackgroundColor = "#191e28";
            var catalog = {

                top:{
    
                    textColor:"#fff"
                    
                },
                bar:{
    
                    backgroundColor:"#454d5f"
                    
                }
         
            }


            var topbarObj;
            topbarObj = {

                width:"calc(100% - 0px)",
                height:refTopbar.current.offsetHeight+(3)+"px",
                margin:"0 0 0 0"

            };
            var scrollboxObj;
            scrollboxObj = {

                backgroundColor:scrollboxBackgroundColor,
                width:"calc(100% - 0px)",
                height:"calc(100% - "+refTopbar.current.offsetHeight+"px)",
                margin:"0 0 50px 0"

            };
            if(refSide && refSide.current)
            {

                topbarObj = {
                
                    width:"calc(100% - "+refSide.current.offsetWidth+"px)",
                    height:refTopbar.current.offsetHeight+"px",
                    margin:"0 0 0 "+refSide.current.offsetWidth+"px"
                }
                scrollboxObj = {
                
                    backgroundColor:scrollboxBackgroundColor,
                    width:"calc(100% - "+refSide.current.offsetWidth+"px)",
                    height:"calc(100% - "+refTopbar.current.offsetHeight+"px)",
                    margin:"0 0 50px "+refSide.current.offsetWidth+"px"
                }

            }


            setStyling({

                side:{

                    backgroundColor:"#191e28"

                },
                topbar:topbarObj,
                scrollbox:scrollboxObj,
                main:{
        
                    width       :mainWidth,
                    margin      :mainMargin,
                    padding     :mainPadding
                },
                side2:{

                    backgroundImage     :side2BgImage, 
                    backgroundPosition  :side2BgPosition,
                    backgroundSize      :side2BgSize,            
                    width               :side2Width,
                    height              :side2Height,
                    transform           :side2Transform

                },
                catalog:catalog
        
            });

        //}

        //console.log("scrollbox w: "+refScrollbox.current.offsetWidth);




        setContentHeight(refMain.current.offsetHeight);




    },
    [

        isLoaded,

        width,
        height,

        refSide,
        refMain,
        refScrollbox,
        refTopbar,

        panelStatus

    ])


    




    // //=============================================
    // // state/effect - showMenu
    // //=============================================

    // // -- state -- //
    // const [showMenu, setShowMenu] = useState(false);

    // useEffect(() => {

    //     if(width > thresholdSide1 && showMenu)
    //     {
    //         setShowMenu(false);

    //     }
    // },
    // [

    //     width,
    //     thresholdSide1,
    //     showMenu
    // ])





    // //=============================================
    // // state/effect - breadcrumbs
    // //=============================================

    // // -- state -- //
    // const [breadcrumbs, setBreadcrumbs] = useState([]);


    // useEffect(() => {

    //     // if(!isLoaded)
    //     // {
    //     //}
    //     //console.log("scrollbox w: "+refScrollbox.current.offsetWidth);
    
    //     var pathArr = pathname.split("/");
    //     var crumbs = ["Home","/"];
    //     var i;
    //     for(i=0; i < pathArr.length; i++)
    //     {
    //         if(i < pathArr.length-1)
    //         {
    //             crumbs.push(pathArr[i]);
    //             crumbs.push("/");
    //         }else{
    //             crumbs.push(pathArr[i]);
    //         }

    //     }
    //     setBreadcrumbs(crumbs);

    // },
    // [

    //     pathname,
    //     isLoaded

    // ])




    
    //=============================================
    // state/effect - isMobiPanel
    //=============================================

    const [isMobiPanel, setIsMobiPanel] = useState(false);
    //const [flyPanelButton, setFlyPanelButton] = useState(false);

    useEffect(() => {

        if(!thresholdSide2)
        {
            if(!isMobiPanel) 
            {
                setIsMobiPanel(true);
                setPanelStatus({

                    show:false,
                    call:'closed',
                    data:[]

                });

            }

        }else{

            if(isMobiPanel) 
            {
                setIsMobiPanel(false); 
                setPanelStatus({

                    show:true,
                    call:'graphic',
                    data:[]
    
                });
        
            }

        }

    },
    [
        location,
        thresholdSide2,
        isMobiPanel
    ])








    //=============================================
    // state/effect - fetch, alter
    //=============================================

    const [runFetch, setRunFetch] = useState(false);
    const [wasFetched, setWasFetched] = useState(false);
    const [fetchData] = useState({

        call:"fetchAll",
        recid:false//used if getting a specific

    });
    //const [runAlter, setRunAlter] = useState(false);
    //const [showMessngr, setShowMessngr] = useState(false);
    //const [Scene, setScene] = useState([]);

    useEffect(() => {

        // var array = [];
        // var top,bar,catalogItems;
        // var array;
        // var calcs,keyname;
        // var sortedItems;


        //console.log("Scene: "+JSON.stringify(Scene,null,2));

        if(Scene[1].items.length > 0 && !wasFetched)
        {
            //has scene info setScene is complete
            // console.log('Has Scene');
            // setScene(InitScene);
            setWasFetched(true);       
            setRunFetch(false);

        }
        else
        if(!zoneObj['knowledge_base'] || zoneObj['knowledge_base'] === undefined)
        {
            // run fetch - data is set on handleProcess
            // console.log('Running Fetch');
            if(!runFetch) setRunFetch(true);

        }else{

            // console.log("Has Scene - part 2");
            // console.log("wasFetched: "+wasFetched);
            if(runFetch) setRunFetch(false);

        }


    },
    [
        isLoaded,
        zoneObj,
        wasFetched,
        Scene,
        runFetch
   
    ]);











    //=============================================
    // handle
    //=============================================

    const handle = (hndl) => {


        switch(hndl.call)
        {
        // case'breadcrumb':

        //     var pathArr = pathname.split("/");
        //     var url = "/";

        //     if(hndl.data !== 'Home')
        //     {
        //         var i;
        //         for(i=0; i < pathArr.length; i++)
        //         {
        //             if(pathArr[i] === hndl.data)
        //             {
        //                 url = url + hndl.data;
        //                 break;
        //             }else{
        //                 url = url + pathArr[i]+"/";
        //             }
        //         }

        //     }

        //     //if(url.charAt(url.length-1) === '/') 
        //         //url = url.substring(0,url.length-1);

        //     if(url.charAt(url.length-1) !== '/') url = url + "/";

        //     history.push(url);

        // break;
        case'menu':

            switch(hndl.data)
            {
            case'close':
                setShowMenu(false);
            break;
            default:
                setShowMenu(true);
            }
        break;
        case'fetched':


            //alert('fetched');

            if(runFetch && !wasFetched)
            {
                setRunFetch(false);
                setWasFetched(true);

                // console.log("MESSAGE: "+hndl.message);
                // console.log("RESET: "+hndl.reset);
                // console.log("RESULT: "+JSON.stringify(hndl.result,null,2));

                // var msg = "";
                // msg = hndl.message;

                // if(Object.keys(hndl.result).length > 0)
                // {
                //     msg = msg + " - Has Data";
                // }else{
                //     msg = msg + " - Has No Data";
                // }

                // console.log(msg);


        
                // setShowMessngr({

                //     call:false,
                //     message:'',
                //     duration:false,
                //     alfaBgColor:"#191e28",
                //     data:{

                //         isError: false,
                //         errorType:'',
                //         isTimed:true,
                //         timedDuration: 2000,
                //         items:[

                //             {
                //                 "call":"heading",
                //                 "text":"::Fetched::" 
                //             },
                //             {
                //                 "call":"span",
                //                 //"text":"Update was Successfull!!" 
                //                 "text":msg
                //             }
                    
                //         ]

                //     },
                //     style:{

                //         headingBarColor:"#caffca"
                //     }
        
                // });


                // setTimeout(function(){


                //     setShowMessngr(false);


                // },2000);



                var knowledge_base = false;

                if(Object.keys(hndl.result).length > 0)
                {
                    if(hndl.result
                    && hndl.result !== undefined)
                    {
                        knowledge_base = hndl.result;
                    }
                }




                //----------------------------
                //dispatch
                //----------------------------

                if(knowledge_base)
                {

                    //console.log("TEMPLATE CALCS::: "+JSON.stringify(hndl.result.template_calcs,null,2));
                    //alert("dispatch template_calcs");
                
                    dispatch(ModifyZoneStore({

                        type:"UPDATE_ZONE",//UPDATE_ZONE or DELETE_ZONE
                        item:{
                            key     :"knowledge_base",
                            value   :knowledge_base
                        }
            
                    })); 

                }

            }//runFetch


        break;
        // case'fetched':


        //     alert('fetched');

        //     if(runFetch && !wasFetched)
        //     {
        //         setRunFetch(false);
        //         setWasFetched(true);

        //         console.log("MESSAGE: "+hndl.message);
        //         console.log("RESET: "+hndl.reset);
        //         console.log("RESULT: "+JSON.stringify(hndl.result,null,2));

        //         var msg = "";
        //         msg = hndl.message;

        //         if(Object.keys(hndl.result.knowledge_base).length > 0)
        //         {
        //             msg = msg + " - Has Data";
        //         }else{
        //             msg = msg + " - Has No Data";
        //         }

        //         console.log(msg);


        
        //         // setShowMessngr({

        //         //     call:false,
        //         //     message:'',
        //         //     duration:false,
        //         //     alfaBgColor:"#191e28",
        //         //     data:{

        //         //         isError: false,
        //         //         errorType:'',
        //         //         isTimed:true,
        //         //         timedDuration: 2000,
        //         //         items:[

        //         //             {
        //         //                 "call":"heading",
        //         //                 "text":"::Fetched::" 
        //         //             },
        //         //             {
        //         //                 "call":"span",
        //         //                 //"text":"Update was Successfull!!" 
        //         //                 "text":msg
        //         //             }
                    
        //         //         ]

        //         //     },
        //         //     style:{

        //         //         headingBarColor:"#caffca"
        //         //     }
        
        //         // });


        //         // setTimeout(function(){


        //         //     setShowMessngr(false);


        //         // },2000);



        //         var knowledge_base = false;

        //         if(Object.keys(hndl.result.knowledge_base).length > 0)
        //         {
        //             if(hndl.result.knowledge_base 
        //             && hndl.result.knowledge_base !== undefined)
        //             {
        //                 knowledge_base = hndl.result.knowledge_base;
        //             }
        //         }




        //         //----------------------------
        //         //dispatch
        //         //----------------------------

        //         if(knowledge_base)
        //         {

        //             //console.log("TEMPLATE CALCS::: "+JSON.stringify(hndl.result.template_calcs,null,2));
        //             //alert("dispatch template_calcs");
                
        //             dispatch(ModifyZoneStore({

        //                 type:"UPDATE_ZONE",//UPDATE_ZONE or DELETE_ZONE
        //                 item:{
        //                     key     :"knowledge_base",
        //                     value   :knowledge_base
        //                 }
            
        //             })); 

        //         }

        //     }//runFetch


        // break;
        case'contentHeight':



            // alert("contentHeight Check"
            
            //     +"\nScroll OffetHeight: "+refScrollbox.current.offsetHeight
            //     +"\nScroll ScrollHeight: "+refScrollbox.current.scrollHeight
            //     +"\ncontentHeight: "+hndl.data.contentHeight
            //     // +"\nArea OffetHeight: "+refArea.current.offsetHeight
            // );

            //reset viewWidth 
            // var vw = refScroll.current.clientWidth + "px";// offsetWidth includes width of scroll bar and clientWidth doesn't
            // //console.log("handleScroll - VW: "+vw);
            // setViewWidth(vw);

            // if(hndl.data.contentHeight > 0)
            // {
            //     if(hndl.data.contentHeight < refScroll.current.offsetHeight
            //     || hndl.data.contentHeight === refScroll.current.offsetHeight
            //     )
            //     {
            //         setScrollboxOverflow("auto");
            //     }else{
            //         setScrollboxOverflow("scroll");
            //     }

            // }//==


            if(hndl.data.contentHeight !== contentHeight)
            {
                var revHeight;
                revHeight = parseInt(hndl.data.contentHeight) + 90;
                setContentHeight(revHeight);
            }





        break;
        default:
        }


    }












    //=============================================
    // state/effect - term
    //=============================================


    const [currentTerm, setCurrentTerm] = useState(false);
    const [scrollerPosition, setScrollerPosition] = useState(false);

    useEffect(() => {

        if(isLoaded)
        {
            // if(currentTerm)
            // {
            //     //setHashData(false);
            //     setCurrentTerm(false);
            //     setScrollerPosition(false);

            // }
            // console.log(pathname.split("/").length);


            var patharr;
            var term;

            var tileId, tile;
            var item;
            var items = Scene[1].items;
            var count;


            if(scrollerPosition)
            {
                if(pathname.split("/").length === 2)
                {

                    setCurrentTerm(false);
                    setScrollerPosition(false);

        
                    count = 0;
                    for(item of items)
                    {   
                        term = item.term.replace(/ /g,"_");
                        document.getElementById(term+"-1-"+count).style.backgroundColor = "white";
                        count++;
        
                    }//== for

                    refScrollbox.current.scrollTop = 0;

    
                }
           
            }else{



                if(pathname.split("/").length === 3)
                {

        
                    patharr = pathname.split("/");
                    term = patharr[patharr.length-1];

                    setCurrentTerm(term);
    

                    // -- get tile id -- //

                    count = 0;
                    for(item of items)
                    {   
                        if(item.term === term)
                        {             
                            term = item.term.replace(/ /g,"_");
                            tileId = term+"-1-"+count;
                            break;
                        }
                        count++;

                    }//== for


                    // -- clean tiles -- //
                
                    count = 0;
                    for(item of items)
                    {   
                        term = item.term.replace(/ /g,"_");
                        if(document.getElementById(term+"-1-"+count))
                        {
                            document.getElementById(term+"-1-"+count).style.backgroundColor = "white";

                        }
                        count++;

                    }//== for


                    // -- set current -- //     

                    if(tileId)
                    {
                        if(document.getElementById(tileId))
                        {
                            tile = document.getElementById(tileId);
                            tile.style.backgroundColor = "#efffef"; //"#e9ffe9";
                            refScrollbox.current.scrollTop = tile.offsetTop;

                            setScrollerPosition(tile.offsetTop);
                        }

                    }

                }


            }


        }

    },
    [
        pathname,
        Scene,
        isLoaded,
        currentTerm,
        refScrollbox,
        scrollerPosition
   
    ]);










    //=============================================
    // handleClick
    //=============================================

    const handleClick = (hndl) => {


        var url;

        switch(hndl.call)
        {
        case'calc':


            //QUERY

            // var url = "";
            // //var url = pathname+"/Calculator/";
            // if(hndl.data.id)
            // {
            //     url = url + "?id=" + hndl.data.id + "&name="+hndl.data.name
            // }else{
            //     url = url + "?id=calc&name=The Profit Calculator"
            // }
            // if(url.match(/\/\//gi)) url = url.replace(/\/\//gi,"/");
            // history.push(url); 


            // //STATE
            // history.push("#!Calculator");
            // setCalcHash(true);
            // if(hndl.data.id)
            // {
            //     setCalcId(hndl.data.id);
            //     setCalcName(hndl.data.name);
            // }else{
            //     setCalcId("calc");
            //     setCalcName("The Profit Calculator");
            // }//==

        break;
        case'term':


            //QUERY
            url = "";
            //var url = pathname+"/Calculator/";
            if(hndl.data)
            {
                url = url + hndl.data;
            }
            if(url.match(/\/\//gi)) url = url.replace(/\/\//gi,"/");
            history.push(url); 

    



            // //STATE
            //history.push("#!term~"+hndl.data);
            //setHashData("#!term~"+hndl.data);         
            if(hndl.data) setCurrentTerm(hndl.data);

            //console.log("Scene[1]: "+JSON.stringify(Scene[1],null,2));


     
            var tileId = false;
            var tile;

            var term;
            var item;
            var items = Scene[1].items;
            var count;



            // -- get tile id -- //

            count = 0;
            for(item of items)
            {   
                if(item.term === hndl.data)
                {             
                    term = item.term.replace(/ /g,"_");
                    tileId = term+"-2-"+count;
                    break;
                }
                count++;

            }//== for



            // -- clean tiles -- //
        
            count = 0;
            for(item of items)
            {   
                term = item.term.replace(/ /g,"_");
                document.getElementById(term+"-1-"+count).style.backgroundColor = "white";
                count++;

            }//== for



            // -- set current -- //     

            if(tileId)
            {
                tile = document.getElementById(tileId);
                if(document.getElementById(tileId))
                {
                    tile.style.backgroundColor = "#efffef"; //"#e9ffe9";
                    refScrollbox.current.scrollTop = tile.offsetTop;

                    setScrollerPosition(tile.offsetTop);
                }
            }


        break;
        case'usage':


            //QUERY
            // var url = "";
            // //var url = pathname+"/Calculator/";
            // if(hndl.data.id)
            // {
            //     url = url + "?id=" + hndl.data.id + "&name="+hndl.data.name
            // }else{
            //     url = url + "?id=calc&name=The Profit Calculator"
            // }
            // if(url.match(/\/\//gi)) url = url.replace(/\/\//gi,"/");
            // history.push(url); 


            // //STATE
            // history.push("#!Calculator");
            // setCalcHash(true);
            // if(hndl.data.id)
            // {
            //     setCalcId(hndl.data.id);
            //     setCalcName(hndl.data.name);
            // }else{
            //     setCalcId("calc");
            //     setCalcName("The Profit Calculator");
            // }//==



            //alert(JSON.stringify(location,null,2));

            //QUERY
            url = "/";
            //var url = pathname+"/Calculator/";
            if(hndl.data)
            {

                switch(hndl.data)
                {
                case'Pricing':

                    url = url + hndl.data + "/List/";
                break;
                case'Overhead':

                    url = url + "Settings/" + hndl.data + "/";
                break;
                case'Direct Labor Rates':
                case'Expenses':
                case'Employees':
                case'Owners':

                    url = url + "Reports/" + hndl.data + "/";
                break;
                default:
                    url = url + hndl.data + "/";
                }

               
            }
            if(url.match(/\/\//gi)) url = url.replace(/\/\//gi,"/");
            history.push(url); 






        break;
        default:
        }

    };












    //=============================================
    // state/effect - fetch, alter
    //=============================================

    const [runFetchPanel, setRunFetchPanel] = useState(false);
    const [wasFetchedPanel, setWasFetchedPanel] = useState(false);
    const [fetchPanelData, setFetchPanelData] = useState({

        call:"fetchAll",
        callb:false//used if getting a specific

    });


    useEffect(() => {


        if(!zoneObj['info_panel'] || zoneObj['info_panel'] === undefined)
        {
            // run fetch - data is set on handleProcess
            //console.log('Running Fetch');
            if(!runFetchPanel) 
            {
                setRunFetchPanel(true);
                setFetchPanelData({

                    call:"fetchAll",
                    callb:false//used if getting a specific
            
                });           
            }

        }else{

            if(!zoneObj['info_panel'].ads || zoneObj['info_panel'].ads === undefined)
            {
                // console.log("Has Scene - part 2");
                // console.log("wasFetched: "+wasFetched);
                if(!runFetchPanel) 
                {
                    setRunFetchPanel(true);
                    setFetchPanelData({

                        call:"fetch",
                        callb:'ads'//used if getting a specific
                
                    });   
                }


            }
            else
            if(!zoneObj['info_panel'].howto || zoneObj['info_panel'].howto === undefined)
            {


                var info_panel = JSON.parse(JSON.stringify(zoneObj.info_panel));


                var howto = [];
                if(PanelInfo.howto)
                {
                    howto = PanelInfo.howto
                }
                info_panel.howto = howto;

                //----------------------------
                //dispatch
                //----------------------------

                if(info_panel)
                {
                    //console.log("KNOWLEDGE BASE::: "+JSON.stringify(hndl.result,null,2));
                    //alert("dispatch knowledge_base");
                
                    dispatch(ModifyZoneStore({

                        type:"UPDATE_ZONE",//UPDATE_ZONE or DELETE_ZONE
                        item:{
                            key     :"info_panel",
                            value   :info_panel
                        }
            
                    })); 

                }

            

            }
            else
            {

                if(runFetchPanel) 
                {
                    setRunFetchPanel(false);
                    setWasFetchedPanel(true);

                }

            }

        }


    },
    [
        zoneObj,dispatch,
        wasFetchedPanel,
        runFetchPanel

    ]);



    //=============================================
    // state/effect/handle - panelStatus
    //=============================================

    useEffect(() => {

        if(panelStatus.call !== 'closed')
        {
            if(!thresholdSide2)
            {

                if(panelStatus.call === 'init')
                {
                    setPanelStatus({

                        show:true,
                        call:'closed',
                        data:[]

                    });

                } 


            }else{

                if(panelStatus.call === 'init')
                //if(panelStatus.call !== 'graphic' && panelStatus.call !== 'ads')
                {
                    setPanelStatus({

                        show:true,
                        call:'graphic',
                        data:[]

                    });

                } 

            }


        }

    },
    [

        location,
        panelStatus,
        thresholdSide2

    ])



    const handlePanel = (hndl) => {

        //alert("hndl.call " + hndl.call);

        switch(hndl.call)
        {
        case'fetched':


            //alert('fetchedPanel');

            if(runFetchPanel && !wasFetchedPanel)
            {

                setRunFetchPanel(false);
                setWasFetchedPanel(true);

                console.log("MESSAGE: "+hndl.message);
                console.log("RESET: "+hndl.reset);
                console.log("RESULT: "+JSON.stringify(hndl.result,null,2));

        
                var msg = hndl.message;

                if(Object.keys(hndl.result).length > 0)
                {
                    msg = msg + " - Has Data";
                }else{
                    msg = msg + " - Has No Data";
                }

                console.log(msg);


                var info_panel = false;
                if(localStorage.editMode)
                {
                    // //EDIT
                    if(Object.keys(hndl.result).length > 0)
                    {
                        if(hndl.result 
                        && hndl.result !== undefined)
                        {
                            info_panel = hndl.result;
                        }
                    }
                    console.log("===== edit - fetched =====");
                    console.log("info_panel: "+JSON.stringify(info_panel.page,null,2));
                    //alert('edit - fetched');

                }else{

                    // //READ
                    if(Object.keys(hndl.result).length > 0)
                    {
                        if(hndl.result 
                        && hndl.result !== undefined)
                        {
                            info_panel = hndl.result;
                        }
                    }


                    console.log("===== read - fetched =====");
                    console.log("info_panel: "+JSON.stringify(info_panel,null,2));
                    //alert('read - fetched');


                }//editMode


              
                //----------------------------
                //dispatch
                //----------------------------

                if(info_panel)
                {

                    // console.log("INFO_PANEL::: "+JSON.stringify(hndl.result,null,2));
                    // alert("dispatch info_panel");
                
                    dispatch(ModifyZoneStore({

                        type:"UPDATE_ZONE",//UPDATE_ZONE or DELETE_ZONE
                        item:{
                            key     :"info_panel",
                            value   :info_panel
                        }
            
                    })); 

                }

            }//runFetchPanel



        break;
        case'url':

            // check url

            // var href = window.location.href;                //returns the href (URL) of the current page
            // var host = window.location.host;                //returns the domain with port number
            // var hostname = window.location.hostname;        //returns the domain name of the web host
            // var pathname = window.location.pathname;        //returns the path and filename of the current page
            // var protocol = window.location.protocol;        //returns the web protocol used (http: or https:)
            // var assign = window.location.assign;            //loads a new document

            var externalUrl = false;
            var url;
            url = hndl.data;

            var internalSite = "site.site/";

            var regxInternalSite = new RegExp(internalSite,"gi");
            var regxHost = new RegExp(window.location.host,"gi");
            if(url.match(regxInternalSite))
            {
                url = url.split(".site/")[1];
            }//==
            else
            if(!url.match(regxHost))
            {
                externalUrl = true;
            }//==
            //alert("handlePanel url " + url);



            if(externalUrl)
            {
                props.primeProps.handle({

                    call:'url',
                    data:{

                        action:'target',
                        url:url

                    }

                });

            }else{

                props.primeProps.handle({

                    call:'url',
                    data:{

                        action:'push',
                        url:url

                    }

                });

            }


        break;
        case'topbar':


            if(!thresholdSide2)
            {
                setPanelStatus({

                    show:true,
                    call:'howto',
                    data:PanelInfo.howto,
                    height:"auto"
    
                });
    

            }else{

                setPanelStatus({

                    show:true,
                    call:'graphic',
                    data:[]
    
                });

            }

        break;
        case'close':


            if(!thresholdSide2)
            {
                setPanelStatus({

                    show:false,
                    call:'closed',
                    data:[]

                });

            }else{

                setPanelStatus({

                    show:true,
                    call:'graphic',
                    data:[]
    
                });

            }


        break;
        case'ads':


            //alert("ADS: "+JSON.stringify(PanelInfo.ads));

            setPanelStatus({

                show:true,
                call:'ads',
                data:PanelInfo.ads,
                height:"auto"

            });

        break;
        case'howto':

            setPanelStatus({

                show:true,
                call:'howto',
                data:PanelInfo.howto,
                height:"auto"

            });

        break;
        case'graphic':

            setPanelStatus({

                show:true,
                call:'graphic',
                data:[]

            });

        break;
        default:


            if(panelStatus.call === 'closed')
            {

                setPanelStatus({

                    show:true,
                    call:'opened',
                    data:[]

                });


            }else{



                if(!thresholdSide2)
                {

                    //alert('thresholdSide2')

                    setPanelStatus({
    
                        show:false,
                        call:'closed',
                        data:[]
    
                    });
    
                }else{
    
                    setPanelStatus({
    
                        show:true,
                        call:'graphic',
                        data:[]
        
                    });
    
                }



            }




        }

    }






    //=============================================
    // handleScroll
    //=============================================

    const handleScroll = () => {

        if(refScrollbox.current.scrollHeight > refSide2.current.offsetHeight)
        {
            console.log("handleScroll - fix side2");
            console.log(refScrollbox.current.scrollHeight+"\n"+contentHeight+"\n"+refSide2.current.offsetHeight);
            setContentHeight(refScrollbox.current.scrollHeight);
        }
    }







    if(!isLoaded) 
    {

        //return <div className="area-title">Loading...</div>


        return (


            <Stage>

  
                <Side
                ref={refSide}
                >
                </Side>


                <Topbar
                ref={refTopbar}
                width={styling.topbar.width} 
                margin={styling.topbar.margin}
                >
                </Topbar>
                

                <ScrollBox
                ref={refScrollbox}
                width={styling.scrollbox.width} 
                height={styling.scrollbox.height} 
                margin={styling.scrollbox.margin}
                >

                    <Main
                    ref={refMain}
                    width={styling.main.width} 
                    margin = {styling.main.margin}
                    padding = {styling.main.padding}
                    >

                    </Main>    

                </ScrollBox>


            </Stage>




        )



    } 
    else 
    {


        return (

            <Stage>



                {
                //=========================================================
                runFetch
                ?
                    <div>

                        {/* <Loader 
                            alfaBgColor={"transparent"}
                            styling={{

                                box:{
                        
                                    backgroundColor:'#191e28',
                                    border:"lightgray",
                                    width:false,
                                    height:false,
                                    top:"120px",
                                    left:false
                        
                                },
                        
                                loader:{
                        
                                    class       :'sm-loader-circle',
                                    lowlight    : "0.5em solid rgba(250,250,250,0.2)",
                                    highlight   : "0.5em solid #fff"
                        
                                }
                        
                            }}
                        /> */}


                        <Loading />
                        <Fetch

                            primeProps={props.primeProps}
                            handle={handle}
                            //duration={2500}
                            data={fetchData}
                            // formArr={props.formArr}
                            // captured={props.captured}

                        />

                    </div>

                :null
                //=========================================================
                }





                {
                //=========================================================
                runFetchPanel
                ?
                    <div>

                        {/* <Loading /> */}
                        <FetchPanel

                            primeProps={props.primeProps}
                            handle={handlePanel}
                            //duration={2500}
                            data={fetchPanelData}
                            // formArr={props.formArr}
                            // captured={props.captured}

                        />

                    </div>

                :null
                //=========================================================
                }








                {width < thresholdSide1 && showMenu
                ?

                    <div>

                        <MenuAlfa
                        onClick={(v) => handle({

                            call:'menu',
                            data:"close"

                        })}
                        ></MenuAlfa>
                        <Menu
                        >
                            <MenuControls>

                                <div className="Title">

                                    {"Menu"}

                                </div>

                                <div 
                                className="Button"
                                onClick={(v) => handle({

                                    call:'menu',
                                    data:"close"
        
                                })}
                                >

                                    {"X"}
                                                                    
                                </div>

                            </MenuControls>

                            <Navigator

                                handle      = {(v) => handle(v)} 
                                data        = {SideNavData}
                                refid       = {"dashboard_sidenav"}

                                viewHeight  = {false}
                                databind    = {props.databind}

                            />

                        </Menu>


                    </div>


                :null}


                {width > thresholdSide1
                ?

                    <Side
                    ref={refSide}
                    >
                        <Navigator

                            handle      = {(v) => handle(v)} 
                            data        = {SideNavData}
                            refid       = {"dashboard_sidenav"}

                            viewHeight  = {false}
                            databind    = {props.databind}



                        />

                    </Side>


                :null}



                <Topbar2
                ref={refTopbar}
                width={styling.topbar.width} 
                margin={styling.topbar.margin}
                >

                    <div
                    className={"Left"}
                    >


                        {
                        width < thresholdSide1
                        ?
                            <div
                            className={"MenuButton"}
                            onClick={(v) => handle({

                                call:'menu',
                                data:"open"

                            })}
                            >
                                <div className={"Slice"}></div>
                                <div className={"Slice"}></div>
                                <div className={"Slice"}></div>

                            </div>

                        :null
                        }





                        {breadcrumbs.map((crumb, i) => (

                            <div
                            key={i}
                            className={"Button"}
                            onClick={(v) => handleBreadcrumbs({

                                call:'breadcrumb',
                                data:crumb

                            })}
                            >
                                {crumb}

                            </div>

                        ))}





                    </div>



                    {
                    width > 550
                    ?
                        <div
                        className={"Right"}
                        >

                            <div
                            className={"PanelButton"}
                            onClick={(v) => handlePanel({

                                call:'topbar',
                                data:[]

                            })}
                            >
                                INFO PANEL

                            </div>

                        </div>

                    :null
                    }



                </Topbar2>




                {
                width < 551
                ?
                    <FlyTray
                    top={styling.topbar.height}
                    right={"0px"}
                    >
                        <div
                        className={"PanelButton"}
                        onClick={(v) => handlePanel({

                            call:'topbar',
                            data:[]

                        })}
                        >
                            INFO.

                        </div>

                    </FlyTray>

                :null
                }



                

                <ScrollBox
                ref={refScrollbox}
                width={styling.scrollbox.width} 
                height={styling.scrollbox.height} 
                margin={styling.scrollbox.margin}
                //backgroundColor={"blue"}
                onScroll={handleScroll}
                >

                    <Main
                    ref={refMain}
                    width={styling.main.width} 
                    margin = {styling.main.margin}
                    padding = {styling.main.padding}
                    //backgroundColor={'lightblue'}
                    >


                        <Catalog 
                            handle={handle}
                            handleClick={handleClick}
                            data={Scene} 
                        />


                    </Main>  





                    {/* 
                    <Side2
                    backgroundImage={styling.side2.backgroundImage}
                    backgroundPosition={styling.side2.backgroundPosition}
                    backgroundSize={styling.side2.backgroundSize}                     
                    //backgroundColor={"#454d5f"}
                    width={styling.side2.width}
                    >
                    </Side2>
                    */}



                    { 
                    thresholdSide2
                    ?

                        //ON
                        <Side2
                        ref={refSide2}
                        id={'side2'}
                        //backgroundColor={"purple"}  
                        backgroundColor={"#141820"}                  
                        // backgroundImage={styling.side2.backgroundImage}
                        // backgroundPosition={styling.side2.backgroundPosition}
                        // backgroundSize={styling.side2.backgroundSize}                     
                        width={styling.side2.width}
                        height={contentHeight+(0)+"px"}
                        //height={styling.side2.height}
                        //height={capturedHeight+"px"}
                        //height={"auto"}
                        //transform={styling.side2.transform}
                        >

                            {runFetchPanel
                            ?
                                <PanelLoading />

                            :

                                panelStatus.call !== 'off' 
                                && panelStatus.call !== 'closed' 
                                ?

                                    <Panel

                                        primeProps={props.primeProps}
                                        //handle={(v) => handleForm(v)}  
                                        data={panelStatus.data}
                                        width={width}
                                        refScrollbox={refScrollbox}
                                        refSide2={refSide2}

                                        handlePanel={(v) => handlePanel(v)}
                                        panelStatus={panelStatus}

                                        panelW={styling.side2.width}
                                        panelH={styling.main.height}
                                      
                                    /> 

                                :null


                            }  


                        </Side2>


            

                    :
                    !thresholdSide2 && width > 1200
                    ?

                        //OFF
                  
                        <Side2
                        ref={refSide2}
                        id={'side2'}
                        //backgroundColor={"blue"}  
                        backgroundColor={"#141820"}                  
                        // backgroundImage={styling.side2.backgroundImage}
                        // backgroundPosition={styling.side2.backgroundPosition}
                        // backgroundSize={styling.side2.backgroundSize}                     
                        width={styling.side2.width}
                        height={contentHeight+(0)+"px"}
                        //height={styling.side2.height}
                        //height={capturedHeight+"px"}
                        transform={styling.side2.transform}
                        >
                        </Side2>



                    :null

                    }


                </ScrollBox>




                {
                !thresholdSide2
                ?

                    runFetchPanel
                    ?
                        <PanelLoading />

                    :
                        panelStatus.call !== 'off' 
                        && panelStatus.call !== 'closed' 
                        ?
                            
                            <Fly
                            backgroundColor={"#141820"}              
                            >

                                <FlyContainer
                                >

                                    <Panel

                                        primeProps={props.primeProps}
                                        //handle={(v) => handleForm(v)}  
                                        data={panelStatus.data}
                                        width={width}
                                        refScrollbox={refScrollbox}
                                        refSide2={refSide2}

                                        handlePanel={(v) => handlePanel(v)}
                                        panelStatus={panelStatus}

                                        panelW={styling.side2.width}
                                        panelH={contentHeight}

                                    /> 


                                </FlyContainer>


                            </Fly>


                        :null

                :null
                }

        
            </Stage>

        )


    }

}

export default Index;




