
export const Direct_Labor_Rates = [
 


    //-----------------------------------
    // ReportBox - Graphic and Ledger
    //-----------------------------------

    {

        "print"		    :'bar',
        "name"		    :'Labor Rates',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'report_box',
        "name"		    :'Report Box',
        "value"		    :[

            {

                boxCall:'graphic',
                boxWidth:"35%",
                boxHeight:200,

                svgCall:"billable",
                svgWidth:200,
                svgHeight:200,

            },

            {
                boxCall:'ledger',
                boxWidth:"65%",
                boxHeight:200,
                value:[

                    {
                        name:"Highest",
                        value:"$30.00"
                    },
                    {
                        name:"Lowest",
                        value:"$15.00"
                    },
                    {
                        name:"Average",
                        value:"$20.00"
                    }
    
                ]

            }



        ],
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"20px"
        },
        "required"	    :"",
        "default"	    :""


    },






    //-----------------------------------
    // Ledger
    //-----------------------------------

    {

        "print"		    :'bar',
        "name"		    :'Owner Labor Rates',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'ledger',
        "name"		    :"Ledger",
        "value"		    :[


            {
                name:"Highest",
                value:"$30.00"
            },
            {
                name:"Lowest",
                value:"$15.00"
            },
            {
                name:"Average",
                value:"$20.00"
            }


        ],
        "required"	    :"",
        "default"	    :""


    },

    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"50px"
        },
        "required"	    :"",
        "default"	    :""


    },





    //-----------------------------------
    // Ledger
    //-----------------------------------

    {

        "print"		    :'bar',
        "name"		    :'Employee Labor Rates',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'ledger',
        "name"		    :"Ledger",
        "value"		    :[


            {
                name:"Highest",
                value:"$30.00"
            },
            {
                name:"Lowest",
                value:"$15.00"
            },
            {
                name:"Average",
                value:"$20.00"
            }


        ],
        "required"	    :"",
        "default"	    :""


    },

    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"50px"
        },
        "required"	    :"",
        "default"	    :""


    }





];



