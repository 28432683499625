// import { 
//     useSelector, 
//     //useDispatch 
// } 
// from 'react-redux';
// //import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';



//import { Settings } from 'app-components/Prime/Data.js';

import { 

    //VPformTemplateOwnr,
    //VPformTemplateEmpl,
    //VPformTemplateExpense,

    //VPcompanyInfo, 
    VPexpenses,
    VPincome,   
    VPoverhead, 

    VPownrsIndirect,  
    //VPownrs,
    VPownr,

    //VPemplsYearly, 
    //VPemplsMonthly, 
    //VPemplsDirect,
    VPemplsIndirect,
    VPempl


} from 'app-components/Prime/VPdata/form.js';



// import { VPreportDefaults } from 'app-components/Prime/VPdata/reports';


// import { 

//     ChartColorsA, 
//     //ChartColorsB, 
//     //ChartColorsC 

// } from 'utils/components/Charts/ChartColors';


import { LibBase, LibNumber } from "library";


// import ApiResources from 'utils/components/Pages/ApiResources';


const Index = (props) => {



    //-------------------------------------------

    // var apiDomain;
    // var hostname;
    // //var hostname = window.location.hostname;//returns the domain name of the web host
    // var protocol = window.location.protocol;//returns the web protocol used (http: or https:)

    // if(window.location.hostname.match(/192.168/gi))
    // {
    //     hostname = ApiResources.network['3001'].vpc;
    //     apiDomain = protocol + "//" + hostname + "/";
    // }
    // else
    // if(window.location.hostname.match(/localhost/gi))
    // {
    //     hostname = ApiResources.local['3001'].vpc;
    //     apiDomain = protocol + "//" + hostname + "/";
    // }else{
    //     hostname = ApiResources.aws['x'].vpc;
    //     apiDomain = "https://" + hostname + "/";
    // }//=

    //-------------------------------------------

    //console.log("API DOMAIN: "+apiDomain);


    //const apiCall = 'alter-calc-test';
    //const apiCall = 'alter-calc-dash';




    // -- redux -- //
    //const dispatch = useDispatch();
    //const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));


    const zoneObj = props.zoneObj;




    var overhead_percent;
    //var overhead_percent_int = 0;
    var owners = {};   
    var employees = {};
    //var expenses = {};

    overhead_percent = 0.0 + "%";



    var index;

    var keyname;
    var kn;
    var item;
    var value;
    var newValue;

    var a,b,c,d;
    var total;
    var grand_total;


    //var chartSections;
    var object;
    // var percent;
    // var count;

    //var pgraph;


    var obj;

    //var chartColors = ChartColorsA;




    //clear VPoverhead
    for(keyname in VPoverhead)
    {
        if(keyname.match(/expense-/gi))
        {
            delete VPoverhead[keyname];
        }
    }

    var cards_overhead = LibBase.CloneIt(VPoverhead);
    var cards_owners = LibBase.CloneIt(VPownrsIndirect);
    var cards_employees = LibBase.CloneIt(VPemplsIndirect);


    //var templateExpenseForm;
    //var newTemplateExpenseForm;
    


    //var templateEmplForm;
    //var newTemplateEmplForm;
    
    //var template_empl;



    var piePerc;
    var pieSlice;
    var pieChart = {

        items: [ 

            {
                name:"Business",
                value:14
            },
            {
                name:"Facility",
                value:20
            }, 
            {
                name:"Equip. Software",
                value:30
            },      
            {
                name:"Vehicles",
                value:12
            },

            {
                name:"Owners",
                value:5
            },
            {
                name:"Employees",
                value:5
            },


        ],

        colors: [

            "#fde23e",
            "#f16e23", 
            "#57d9ff",
            "#937e88",
            "#20b2aa",
            "#ccff00"

        ],


        hole:{

            percent         :"0.0",
            name            :"Overhead",
            color           :"#181b21",
            outlineColor    :"#181b21",
            textColor       :"white"
        }



    };






    var isOkay;
    isOkay = false;
    if(zoneObj.calc_dash 
    && zoneObj.calc_dash !== undefined
    )
    {
        if(Object.keys(zoneObj.calc_dash.form_items).length > 0
        && Object.keys(zoneObj.calc_dash.result_items).length > 0
        )
        {
            isOkay = true;
        }

    }
    if(isOkay)
    {


        grand_total = zoneObj.calc_dash.result_items['totals'].total;
        grand_total = parseFloat(grand_total);


        //console.log("FORM ITEMS: "+JSON.stringify(zoneObj.calc_dash.form_items));

        index=0;
        for(keyname in zoneObj.calc_dash.form_items)
        {

            // -- owners -- //

            if(keyname.match(/ownr/gi))
            {

                //idl_employees[keyname] = zoneObj.calc_dash.form_items[keyname];

                
                //order card item based on Prime VPdata
                //item = zoneObj.calc_dash.form_items[keyname];
                item = LibBase.CloneIt(zoneObj.calc_dash.form_items[keyname]);//Deep Clone
          
          
                //value = VPowner.value;
                value = LibBase.CloneIt(VPownr.value);//Deep Clone
                newValue = {};
                for(kn in value)
                {
                    if(kn === 'ownr_call') 
                    {
                    }else{
                        newValue[kn] = item.value[kn];       
                    }
                    
                }
                owners[keyname] = {
                    name:item.name,
                    value:newValue
                };
                

            }




            // -- employees -- //

            else
            if(keyname.match(/empl/gi))
            {

                //idl_employees[keyname] = zoneObj.calc_dash.form_items[keyname];

                
                //order card item based on Prime VPdata
                //item = zoneObj.calc_dash.form_items[keyname];
                item = LibBase.CloneIt(zoneObj.calc_dash.form_items[keyname]);//Deep Clone
          
                //value = VPemployee.value;
                value = LibBase.CloneIt(VPempl.value);//Deep Clone
                newValue = {};
                for(kn in value)
                {
                    newValue[kn] = item.value[kn];
                }
                employees[keyname] = {
                    name:item.name,
                    value:newValue
                };
                



            }



            // -- overhead -- //

            else
            {
                
                //console.log("KEYNAME: "+keyname);

                item = LibBase.CloneIt(zoneObj.calc_dash.form_items[keyname]);//Deep Clone
                //console.log(JSON.stringify(item,null,2));


                if(keyname.match(/expense-/gi))
                {

                    // TM: order inputs here



                    // -- VPoverhead -- //

                    if(item.order)
                    {
                        newValue = {};
                        for(obj of item.order)
                        {
                            //console.log("order:  "+obj);
                            for(kn in item.value)
                            {
                                //console.log("kn:  "+kn);
                                if(obj === kn)
                                {
                                    //console.log("match: "+kn);
                                    newValue[kn] = item.value[kn];
                                }
                            }

                        }
                        //console.log(JSON.stringify(newValue,null,2));

                        VPoverhead[keyname] = {
                            name:item.name,
                            value:newValue,
                            order:item.order
                        };


                    }else{

                        VPoverhead[keyname] = {
                            name:item.name,
                            value:item.value
            
                        };

                    }






                    // -- card_overhead -- //

                    value = LibBase.CloneIt(item.value);//Deep Clone

                    if(item.order)
                    {
                        newValue = {};
                        for(obj of item.order)
                        {
                            //console.log("order:  "+obj);
                            for(kn in item.value)
                            {
                                //console.log("kn:  "+kn);
                                if(obj === kn)
                                {
                                    //console.log("match: "+kn);
                                    newValue[kn] = item.value[kn];
                                }
                            }

                        }
                        //console.log(JSON.stringify(newValue,null,2));


                        total = 0;
                        for(kn in newValue)
                        {
                            obj = newValue[kn];
                            total = parseFloat(total) + parseFloat(obj.value);
      
                        }
                        total = parseFloat(total);
                        total = total.toFixed(2);
                        // console.log("TOTALLLLLLLLLLLLL "+total);
                        newValue["total"] = total;
                        newValue["total_percent"] = 0;
    
                        cards_overhead[keyname] = {
                            name:item.name,
                            value:newValue,
                            order:item.order
                        };
    

                    }else{

                        total = 0;
                        for(kn in value)
                        {
                            obj = value[kn];
                            total = parseFloat(total) + parseFloat(obj.value);
      
                        }
                        total = parseFloat(total);
                        total = total.toFixed(2);
                        // console.log("TOTALLLLLLLLLLLLL "+total);
                        value["total"] = total;
                        value["total_percent"] = 0;
    
                        cards_overhead[keyname] = {
                            name:item.name,
                            value:value
                        };
    
                    }





                }else{

                    //value = VPoverhead[keyname].value;
                    value = LibBase.CloneIt(VPoverhead[keyname].value);//Deep Clone
                    newValue = {};
                    for(kn in value)
                    {
                        newValue[kn] = item.value[kn];
                    }

                    VPoverhead[keyname] = {
                        name:item.name,
                        value:newValue
                    };

                    cards_overhead[keyname] = {

                        name:item.name,
                        value:newValue

                    };                

                }


        
                // -- set totals for cards -- //

                if(keyname.match(/expense-/gi))
                {
                    total = 0;
                    newValue = 0;
                    for(kn in item.value)
                    {
                        //console.log(value[kn]);
                        if(item.value[kn] !== undefined)
                        {
                            newValue = item.value[kn].value;
                            if(!isNaN(newValue)) parseFloat(newValue);
                            else newValue = 0;
                            total = parseFloat(total) + parseFloat(newValue); 
                        }
    
                    }
                    total = parseFloat(total);        
                    total = total.toFixed(2);
                

                    piePerc = ( parseFloat(total)/parseFloat(grand_total) ) * 100;
                    if(isNaN(piePerc) || grand_total === 0) piePerc = 0;
                    piePerc = piePerc.toFixed(1);
                    piePerc = parseFloat(piePerc);



                    cards_overhead[keyname].value['total_percent'] = {
    
                        name: "Total Monthly (Overhead %)",
                        default: 0,
                        print: "percent",
                        value: piePerc,
                        required: true
    
                    };
                    cards_overhead[keyname].value['total'] = {
    
                        name: "Total Monthly (Overhead $)",
                        default: 0,
                        print: "price",
                        value: total,
                        required: true
    
                    };


                    // -- set pieChart data -- //

                    for(pieSlice of pieChart.items)
                    {
                        if(pieSlice.value !== piePerc)
                        {
                            pieChart.items[index].name = item.name;      
                            pieChart.items[index].value = piePerc;
                            break;
            
                        }
                       
                    }
                    index++;


                }//==




            }

        }// -- for





        //VPexpenses.value.monthly_expenses.value = grand_total;
        VPexpenses.value.monthly_expenses.value = grand_total;
        VPincome.value.projected_monthly_sales.value = zoneObj.calc_dash.form_items['settings'].value.projected_monthly_sales.value;


        overhead_percent = zoneObj.calc_dash.result_items['overhead'].overhead;
        overhead_percent = parseFloat(overhead_percent); 

        //overhead_percent_int = overhead_percent.toFixed(1);

        pieChart.hole.percent = overhead_percent.toFixed(1);

        overhead_percent = overhead_percent.toFixed(1) + "%";




        //console.log("IDL EMPLOYEES: "+JSON.stringify(idl_employees,null,2));

    
        /*
        for(keyname in zoneObj.calc_dash.result_items)
        {

            console.log("KEYNAME: "+keyname);

            item = zoneObj.calc_dash.result_items[keyname];
            console.log(JSON.stringify(item,null,2));

        
        }// -- for
        */





        //------------------------------------------
        // -- owners - totals - cards 
        //------------------------------------------

        //var total_ownr_salary_pretax_yearly_indirect
        var total_ownr_salary_pretax_monthly_indirect;
        var total_ownr_health_insurance_monthly;
        var total_ownr_ira_monthly_indirect;
        var total_ownr_taxes_monthly_indirect;  

        total_ownr_salary_pretax_monthly_indirect = zoneObj.calc_dash.result_items['totals'].total_ownr_salary_pretax_monthly_indirect;
        total_ownr_health_insurance_monthly = zoneObj.calc_dash.result_items['totals'].total_ownr_health_insurance_monthly;
        total_ownr_ira_monthly_indirect = zoneObj.calc_dash.result_items['totals'].total_ownr_ira_monthly_indirect;
        total_ownr_taxes_monthly_indirect = zoneObj.calc_dash.result_items['totals'].total_ownr_taxes_monthly_indirect;  

        //total_ownr_salary_pretax_yearly_indirect = parseFloat(total_ownr_salary_pretax_monthly_indirect) * 12;


        //if(isNaN(total_ownr_salary_pretax_yearly_indirect)) total_ownr_salary_pretax_yearly_indirect = 0;
        if(isNaN(total_ownr_salary_pretax_monthly_indirect)) total_ownr_salary_pretax_monthly_indirect = 0;
        if(isNaN(total_ownr_health_insurance_monthly)) total_ownr_health_insurance_monthly = 0;
        if(isNaN(total_ownr_ira_monthly_indirect)) total_ownr_ira_monthly_indirect = 0;
        if(isNaN(total_ownr_taxes_monthly_indirect)) total_ownr_taxes_monthly_indirect = 0;


        //cards_owners.value.ownr_salary_pretax_yearly_indirect.value = total_ownr_salary_pretax_yearly_indirect;
        cards_owners.value.ownr_salary_pretax_monthly_indirect.value = total_ownr_salary_pretax_monthly_indirect;
        cards_owners.value.ownr_health_insurance_monthly.value = total_ownr_health_insurance_monthly;
        cards_owners.value.ownr_ira_monthly_indirect.value = total_ownr_ira_monthly_indirect;
        cards_owners.value.ownr_taxes_monthly_indirect.value = total_ownr_taxes_monthly_indirect;



        // -- card total - indirect labor -- //

        total = 0;
        if(total_ownr_salary_pretax_monthly_indirect !== undefined
        && total_ownr_health_insurance_monthly !== undefined 
        && total_ownr_ira_monthly_indirect !== undefined
        && total_ownr_taxes_monthly_indirect !== undefined
        )
        {
            a = total_ownr_salary_pretax_monthly_indirect;
            b = total_ownr_health_insurance_monthly;
            c = total_ownr_ira_monthly_indirect;
            d = total_ownr_taxes_monthly_indirect;

            total = parseFloat(a) + parseFloat(b) + parseFloat(c) + parseFloat(d);

        }

        piePerc = ( parseFloat(total)/parseFloat(grand_total) ) * 100;
        if(isNaN(piePerc)) piePerc = 0;
        piePerc = piePerc.toFixed(1);
        piePerc = parseFloat(piePerc);

        cards_owners.value['total_percent'] = {
    
            name: "Total Monthly (Overhead %)",
            default: 0,
            print: "percent",
            value: piePerc,
            required: true

        };
        cards_owners.value['total'] = {

            name: "Total Monthly (Overhead $)",
            default: 0,
            print: "price",
            value: total,
            required: true

        };

        // -- set pieChart data -- //

        pieSlice = pieChart.items[pieChart.items.length-2];
        if(pieSlice.value !== piePerc)
        {
            pieSlice.name = "Owners";      
            pieSlice.value = piePerc;

        }
        









        //------------------------------------------
        // -- employees - totals - cards 
        //------------------------------------------

        //var total_empl_indirect_salary_yearly;
        var total_empl_indirect_salary_monthly;
        var total_empl_indirect_tax_monthly;
        var total_empl_indirect_ira_monthly;
        var total_empl_health_insurance_monthly;

        total_empl_indirect_salary_monthly = zoneObj.calc_dash.result_items['totals'].total_empl_indirect_salary_monthly;
        total_empl_indirect_tax_monthly = zoneObj.calc_dash.result_items['totals'].total_empl_indirect_tax_monthly;
        total_empl_indirect_ira_monthly = zoneObj.calc_dash.result_items['totals'].total_empl_indirect_ira_monthly;
        total_empl_health_insurance_monthly = zoneObj.calc_dash.result_items['totals'].total_empl_health_insurance_monthly;
   
        //total_empl_indirect_salary_yearly = parseFloat(total_empl_indirect_salary_monthly) * 12;

        //if(isNaN(total_empl_indirect_salary_yearly)) total_empl_indirect_salary_yearly = 0;
        if(isNaN(total_empl_indirect_salary_monthly)) total_empl_indirect_salary_monthly = 0;
        if(isNaN(total_empl_indirect_tax_monthly)) total_empl_indirect_tax_monthly = 0;
        if(isNaN(total_empl_indirect_ira_monthly)) total_empl_indirect_ira_monthly = 0;
        if(isNaN(total_empl_health_insurance_monthly)) total_empl_health_insurance_monthly = 0;
     
        //cards_employees.value.empl_indirect_salary_yearly.value = total_empl_indirect_salary_yearly;
        cards_employees.value.empl_indirect_salary_monthly.value = total_empl_indirect_salary_monthly;
        cards_employees.value.empl_indirect_tax_monthly.value = total_empl_indirect_tax_monthly;      
        cards_employees.value.empl_indirect_ira_monthly.value = total_empl_indirect_ira_monthly;
        cards_employees.value.empl_health_insurance_monthly.value = total_empl_health_insurance_monthly;







        // -- card total - indirect labor -- //

        total = 0;
        if(total_empl_indirect_salary_monthly !== undefined
        && total_empl_indirect_ira_monthly !== undefined
        && total_empl_indirect_tax_monthly !== undefined        
        && total_empl_health_insurance_monthly !== undefined 
        )
        {
            a = total_empl_indirect_salary_monthly;
            b = total_empl_indirect_ira_monthly;
            c = total_empl_indirect_tax_monthly;        
            d = total_empl_health_insurance_monthly;

            total = parseFloat(a) + parseFloat(b) + parseFloat(c) + parseFloat(d);

        }

        piePerc = ( parseFloat(total)/parseFloat(grand_total) ) * 100;
        if(isNaN(piePerc)) piePerc = 0;
        piePerc = piePerc.toFixed(1);
        piePerc = parseFloat(piePerc);

        cards_employees.value['total_percent'] = {
    
            name: "Total Monthly (Overhead %)",
            default: 0,
            print: "percent",
            value: piePerc,
            required: true

        };
        cards_employees.value['total'] = {

            name: "Total Monthly (Overhead $)",
            default: 0,
            print: "price",
            value: total,
            required: true

        };

        // -- set pieChart data -- //

        pieSlice = pieChart.items[pieChart.items.length-1];
        if(pieSlice.value !== piePerc)
        {
            pieSlice.name = "Employees";      
            pieSlice.value = piePerc;

        }
        


    }// -- if

    //console.log("pieChart: "+JSON.stringify(pieChart,null,2));

    //_overhead = VPoverhead;
    //console.log("_overhead B: "+JSON.stringify(_overhead,null,2));


    //console.log("VPoverhead: "+JSON.stringify(VPoverhead,null,2));
    //console.log("cards_overhead: "+JSON.stringify(cards_overhead,null,2));














    var R = [];


    //#############################################################
    //#############################################################

    // "ownr-1": {
    //     "name": "Owner A",
    //     "value": {
    //       "ownr_hours_weekly": "40.00",
    //       "ownr_sti_yearly_direct": "43457.14",
    //       "ownr_name": "Owner A",
    //       "ownr_sti_monthly_indirect": "2414.29",
    //       "ownr_labor_indirect": "Outside Sales,Manager",
    //       "ownr_hours_indirect_percent_whole": "40.00",
    //       "ownr_labor_percent_direct": "60.00",
    //       "ownr_taxes_monthly_direct": "1071.43",
    //       "ownr_ira_monthly_indirect": "33.33",
    //       "ownr_labor_percent": "60.00",
    //       "ownr_ira_monthly": "83.33",
    //       "ownr_vacation_weeks": "2.00",
    //       "ownr_ira_percent": "2.00",
    //       "ownr_sti_monthly_direct": "3621.43",
    //       "ownr_hours_indirect_percent": "0.40",
    //       "ownr_taxes_yearly": "21428.57",
    //       "ownr_sti_weekly_direct": "869.14",
    //       "ownr_salary_pretax": "50000.00",
    //       "ownr_flag": "<br/>The owner spends the majority of their time performing direct labor.<br/>40% of the owners salary will be considered an Overhead expense..<br/>Graphic Design, Production and Installation rates will use the owners Direct Labor rate.",
    //       "ownr_taxrate_percent": "30.00",
    //       "ownr_health_insurance_yearly": "6000.00",
    //       "ownr_indirect_hours_yearly": "880.00",
    //       "ownr_taxrate_reciprocal": "0.70",
    //       "ownr_salary_pretax_monthly": "4166.67",
    //       "ownr_working_weeks": "50.00",
    //       "ownr_hours_direct_percent": "0.60",
    //       "ownr_taxrate_decimal": "0.30",
    //       "ownr_hours_indirect": "16.00",
    //       "ownr_sti": "72428.57",
    //       "ownr_taxes_monthly_indirect": "714.29",
    //       "ownr_ira_yearly": "1000.00",
    //       "ownr_ira_monthly_direct": "50.00",
    //       "ownr_labor_direct": "Design,Production and Service",
    //       "ownr_taxes_monthly": "1785.71",
    //       "ownr_labor_percent_indirect": "40.00",
    //       "ownr_hours_direct_percent_whole": "60.00",
    //       "ownr_sti_weekly_indirect": "579.43",
    //       "ownr_billable_hours_weekly": "24.00",
    //       "ownr_ira_decimal": "0.02",
    //       "ownr_billable_hours_daily": "4.80",
    //       "ownr_st": "71428.57",
    //       "ownr_health_insurance_monthly": "500.00",
    //       "ownr_sti_weekly": "1448.57",
    //       "ownr_hours_direct": "24.00",
    //       "ownr_sti_hourly_direct_flag": "Okay",
    //       "ownr_flag_case": "greater",
    //       "ownr_sti_hourly_direct": "36.21",
    //       "ownr_salary_overhead": "1666.67",
    //       "ownr_sti_yearly_indirect": "28971.43"
    //     }
    // },






    // var ownr_hours_weekly = "";
    var ownr_sti_yearly_direct = 0;
    // var ownr_name = "";
    // var ownr_sti_monthly_indirect = "0.00";
    var ownr_stih_monthly_indirect = "0.00";
    var ownr_labor_indirect = "N/A";
    // var ownr_hours_indirect_percent_whole = "0.00";
    var ownr_labor_percent_direct = "0.00";
    // var ownr_taxes_monthly_direct = "0.00";
    // var ownr_ira_monthly_indirect = "0.00";
    // var ownr_labor_percent = "0.00";
    var ownr_ira_monthly = "0.00";
    var ownr_vacation_weeks = "0";
    // var ownr_ira_percent = "0.00";
    // var ownr_sti_monthly_direct = "0.00";
    // var ownr_hours_indirect_percent = "0.00";
    var ownr_taxes_yearly = "0.00";
    // var ownr_sti_weekly_direct = "0.00";
    var ownr_salary_pretax = "0.00";
    var ownr_flag = "";
    // var ownr_taxrate_percent = "0.00";
    var ownr_health_insurance_yearly = "0.00";
    // var ownr_indirect_hours_yearly = "0.00";
    // var ownr_taxrate_reciprocal = "0.00";
    // var ownr_salary_pretax_monthly = "0.00";
    // var ownr_working_weeks = "0.00";
    // var ownr_hours_direct_percent = "0.00";
    // var ownr_taxrate_decimal = "0.30";
    var ownr_hours_indirect = "0.0";
    // var ownr_sti = "0.00";
    // var ownr_taxes_monthly_indirect = "0.00";
    var ownr_ira_yearly = "0.00";
    // var ownr_ira_monthly_direct = "0.00";
    var ownr_labor_direct = "N/A";
    // var ownr_taxes_monthly = "0.00";
    var ownr_labor_percent_indirect = "0.00";
    // var ownr_hours_direct_percent_whole = "0.00";
    // var ownr_sti_weekly_indirect = "0.00";
    // var ownr_billable_hours_weekly = "0.00";
    // var ownr_ira_decimal = "0.00";
    // var ownr_billable_hours_daily = "0.00";
    // var ownr_st = "0.00";
    var ownr_health_insurance_monthly = "0.00";
    // var ownr_sti_weekly = "0.00";
    var ownr_hours_direct = "0.0";
    // var ownr_sti_hourly_direct_flag = "Okay";
    // var ownr_flag_case = "greater";
    var ownr_sti_hourly_direct = "0.00";
    // var ownr_salary_overhead = "0.00";
    var ownr_sti_yearly_indirect = 0;
    

    var ownr_direct_hours_weekly = "0.00";

    var ownr_total_hours_weekly = "0.0";

    var ownr_yearly_total = "0.00";




    if(zoneObj.calc_dash)
    {
        for(keyname in zoneObj.calc_dash.result_items)
        {
            object = zoneObj.calc_dash.result_items[keyname];
            // if(keyname.match(/ownr-/gi))
            // {

            //     value = object.value["ownr_health_insurance_monthly"];
            //     value = parseFloat(value);

            //     total_ownr_monthly = total_ownr_monthly + value;

            //     ownr_ledger_value.unshift({

            //         name:object.name,
            //         value:"$"+object.value["ownr_health_insurance_monthly"]

            //     });

            // }//if

            if(keyname.match(/ownr-/gi))
            {
                if(object.name === props.find)
                {
                    ownr_salary_pretax                  = object.value.ownr_salary_pretax;
                    ownr_sti_hourly_direct              = object.value.ownr_sti_hourly_direct;
                    ownr_direct_hours_weekly            = object.value.ownr_billable_hours_weekly;

                    ownr_labor_percent_direct           = object.value.ownr_labor_percent_direct;
                    ownr_labor_percent_indirect         = object.value.ownr_labor_percent_indirect;

                    ownr_labor_direct                   = object.value.ownr_labor_direct;
                    ownr_labor_indirect                 = object.value.ownr_labor_indirect;


                    ownr_hours_direct                   = object.value.ownr_hours_direct;
                    ownr_hours_indirect                 = object.value.ownr_hours_indirect;


                    ownr_stih_monthly_indirect          = object.value.ownr_stih_monthly_indirect;
 

                    ownr_flag                           = object.value.ownr_flag;
                
                    ownr_health_insurance_monthly       = object.value.ownr_health_insurance_monthly;
  
                    ownr_ira_monthly                    = object.value.ownr_ira_monthly;

                    ownr_vacation_weeks                 = object.value.ownr_vacation_weeks;
                  
                    //ownr_yearly_salary_w_health_insurance = object.value.ownr_yearly_salary_w_health_insurance;
                   
                    //ownr_burden_yearly_total = object.value.ownr_burden_yearly_total;

                    //ownr_hours_indirect                 = object.value.ownr_vacation_weeks;



                    ownr_health_insurance_yearly        = object.value.ownr_health_insurance_yearly;
                    ownr_ira_yearly                     = object.value.ownr_ira_yearly;
                    ownr_taxes_yearly                   = object.value.ownr_taxes_yearly;
         
                    ownr_sti_yearly_direct              = object.value.ownr_sti_yearly_direct;
                    ownr_sti_yearly_indirect            = object.value.ownr_sti_yearly_indirect;
    

                    break;


                }//if

            }//if

        }//for


    }//==


    ownr_salary_pretax = parseFloat(ownr_salary_pretax).toFixed(2);
    ownr_salary_pretax = "$"+LibNumber.PrintMoney(ownr_salary_pretax);

    ownr_sti_hourly_direct = parseFloat(ownr_sti_hourly_direct).toFixed(2);
    ownr_sti_hourly_direct = "$"+LibNumber.PrintMoney(ownr_sti_hourly_direct);

    ownr_direct_hours_weekly = parseFloat(ownr_direct_hours_weekly).toFixed(1);

    ownr_labor_percent_direct = parseFloat(ownr_labor_percent_direct).toFixed(1);
    ownr_labor_percent_indirect = parseFloat(ownr_labor_percent_indirect).toFixed(1);


    if(ownr_labor_direct === '0.00') ownr_labor_direct = "N/A";
    if(ownr_labor_indirect === '0.00') ownr_labor_indirect = "N/A";

    ownr_labor_direct = ownr_labor_direct.replace(/,/gi,", ");
    ownr_labor_indirect = ownr_labor_indirect.replace(/,/gi,", ");



    ownr_stih_monthly_indirect = parseFloat(ownr_stih_monthly_indirect).toFixed(2);
    ownr_stih_monthly_indirect = "$"+LibNumber.PrintMoney(ownr_stih_monthly_indirect);




    var regxFlagRepl = new RegExp("<br/>","gi");
    ownr_flag = ownr_flag.replace(regxFlagRepl,"");

    ownr_hours_direct = parseFloat(ownr_hours_direct).toFixed(1);
    ownr_hours_indirect = parseFloat(ownr_hours_indirect).toFixed(1);

    ownr_total_hours_weekly = parseFloat(ownr_hours_direct) + parseFloat(ownr_hours_indirect);
    ownr_total_hours_weekly = parseFloat(ownr_total_hours_weekly).toFixed(1);



    ownr_health_insurance_monthly = parseFloat(ownr_health_insurance_monthly).toFixed(2);
    ownr_health_insurance_monthly = "$"+LibNumber.PrintMoney(ownr_health_insurance_monthly);


    ownr_ira_monthly = parseFloat(ownr_ira_monthly).toFixed(2);
    ownr_ira_monthly = "$"+LibNumber.PrintMoney(ownr_ira_monthly);


    ownr_vacation_weeks = parseFloat(ownr_vacation_weeks).toFixed(0);
  




    ownr_health_insurance_yearly = parseFloat(ownr_health_insurance_yearly).toFixed(2);
    ownr_ira_yearly = parseFloat(ownr_ira_yearly).toFixed(2);
    ownr_taxes_yearly = parseFloat(ownr_taxes_yearly).toFixed(2);

    ownr_sti_yearly_direct = parseFloat(ownr_sti_yearly_direct).toFixed(2);
    ownr_sti_yearly_indirect = parseFloat(ownr_sti_yearly_indirect).toFixed(2);
    ownr_yearly_total = parseFloat(ownr_sti_yearly_direct) + parseFloat(ownr_sti_yearly_indirect) + parseFloat(ownr_health_insurance_yearly)
    ownr_yearly_total = parseFloat(ownr_yearly_total).toFixed(2);


    ownr_health_insurance_yearly = "$"+LibNumber.PrintMoney(ownr_health_insurance_yearly);
    ownr_ira_yearly = "$"+LibNumber.PrintMoney(ownr_ira_yearly);
    ownr_taxes_yearly = "$"+LibNumber.PrintMoney(ownr_taxes_yearly);

    ownr_sti_yearly_direct = "$"+LibNumber.PrintMoney(ownr_sti_yearly_direct);
    ownr_sti_yearly_indirect = "$"+LibNumber.PrintMoney(ownr_sti_yearly_indirect);
    ownr_yearly_total = "$"+LibNumber.PrintMoney(ownr_yearly_total);





    //alert(ownr_labor_percent_indirect);


    //--------------------------

    var ownr_call = "even";
    var ownr_graphic_call = 'ownr_indirect';

    if(ownr_labor_percent_direct === ownr_labor_percent_indirect)
    {

        //Even
        ownr_call = "even";     
        ownr_graphic_call = 'ownr_even';

    }

    else
    if(ownr_labor_percent_direct < 1)
    {

        //Full Indirect
        ownr_call = "full_indirect";  
        ownr_graphic_call = 'ownr_indirect';
 
    }

    else
    if(ownr_labor_percent_indirect < 1)
    {

        //Full Direct
        ownr_call = "full_direct";  
        ownr_graphic_call = 'ownr_direct';
      
    
    }

    else
    if(ownr_labor_percent_direct > ownr_labor_percent_indirect)
    {
        //Mostly Direct
        ownr_call = "mostly_direct";  
        ownr_graphic_call = 'ownr_direct_indirect';

    }

    else
    if(ownr_labor_percent_direct < ownr_labor_percent_indirect)
    {

        //Mostly Indirect
        ownr_call = "mostly_indirect";  
        ownr_graphic_call = 'ownr_indirect_direct';
     
    }






    var summary_ledger_value;
    switch(ownr_call)
    {
    case'full_indirect':

        summary_ledger_value = [

            {
                name:"Pretax Salary Yearly",
                value:ownr_salary_pretax
            },

            {
                name:"Indirect Labor Burden Monthly",
                value:ownr_stih_monthly_indirect

            }


        ]

    break;
    default:


        summary_ledger_value = [

            {
                name:"Pretax Salary Yearly",
                value:ownr_salary_pretax
            },

            {
                //name:"Direct Hourly Rate (STI Hourly Direct)",
                name:"Direct Hourly Rate",
                value:ownr_sti_hourly_direct
            },
            {
                //name:"Direct Hours per Week (Hours Direct)",
                name:"Direct Hours Weekly",
                value:ownr_direct_hours_weekly

            },

            {
                name:"Indirect Labor Burden Monthly",
                value:ownr_stih_monthly_indirect

            }


        ]

    }






    // var items = [];

    // keyname = 'ownr'; // "empl";

    // if(VPreportDefaults && VPreportDefaults !== undefined)
    // {
    //     for(item of VPreportDefaults[keyname])
    //     {
    //         items.push(item);
    //     }

    // }
    // //console.log("items::::::"+JSON.stringify(items,null,2));



    var reportItems = [



        //-----------------------------------
        // Report Box - Graphic and Ledger
        //-----------------------------------
    
        {
    
            "print"		    :'bar',
            "name"		    :"Summary",
            "value"		    :"",
            "required"	    :"",
            "default"	    :""
    
    
        },
        {
    
            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{
    
                backgroundColor:"transparent",
                height:"15px"
            },
            "required"	    :"",
            "default"	    :""
    
    
        },
        {
    
            "print"		    :'report_box',
            "name"		    :'Report Box',
            "value"		    :[
    
                {
    
                    boxCall:'graphic',
                    boxWidth:"30%",
                    boxHeight:200,
    
                    svgCall:ownr_graphic_call,
                    svgWidth:200,
                    svgHeight:200
    
                },
    
                {
                    boxCall:'ledger',
                    boxWidth:"70%",
                    boxHeight:200,
                    value:summary_ledger_value
    
                }
    
    
    
            ],
            "required"	    :"",
            "default"	    :""
    
    
        },
        {
    
            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{
    
                backgroundColor:"transparent",
                height:"0px"
            },
            "required"	    :"",
            "default"	    :""
    
    
        },




        //-----------------------------------
        // Report Box - 
        //-----------------------------------

        // {

        //     "print"		    :'bar',
        //     "name"		    :'Hours / Billable',
        //     "value"		    :"",
        //     "required"	    :"",
        //     "default"	    :""


        // },
        // {

        //     "print"		    :'spacer',
        //     "name"		    :'',
        //     "value"		    :{

        //         backgroundColor:"transparent",
        //         height:"20px"
        //     },
        //     "required"	    :"",
        //     "default"	    :""


        // },
        {

            "print"		    :'report_box',
            "name"		    :'Report Box',
            "value"		    :[

                {

                    boxCall:'blank',
                    boxWidth:"10%",
                    boxHeight:50,

                    // svgCall:"employee",
                    // svgWidth:230,
                    // svgHeight:230,

                },

                {
                    boxCall:'txt',
                    boxWidth:"80%",
                    boxHeight:50,
                    value:[

                        {

                            // boxCall:'txt',
                            // boxWidth:"100%",
                            // boxHeight:230,
        
                            className:"Note",
                            txt:'***NOTE: '+ownr_flag,
                            style:{
            
                                //fontSize:"24px",
                                textAlign:"center"
            
                            }
        
                        }
                    ]

                }



            ],
            "required"	    :"",
            "default"	    :""


        },
        {

            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{

                backgroundColor:"transparent",
                height:"40px"
            },
            "required"	    :"",
            "default"	    :""


        },







        //-----------------------------------
        // BarChart - Direct / Indirect
        //-----------------------------------
    
        //bar
        {
    
            "print"		    :'bar',
            "name"		    :'Direct / Indirect',
            "value"		    :"",
            "required"	    :"",
            "default"	    :"",
    
        },
    
    
    
        //spacer
        {
    
            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{
    
                backgroundColor:"transparent",
                height:"30px"
            },
            "required"	    :"",
            "default"	    :""
    
        },
    
    
    
        //chart
        {
    
    
            "print"		    :'singleBarChart',
            "name"		    :'The Chart',
            "value"		    :[
    
                {
                    chartType:"horizontal",
                    chartLines:true,
                    data:[
    
                        {
                            name:'Direct Labor %',
                            value:"2500.00",
                            percent:ownr_labor_percent_direct,
                        },
    
                        {
                            name:'Indirect Labor %',
                            value:"2500.00",
                            percent:ownr_labor_percent_indirect,
                        }
    
                    ]
    
                }
            ],
            "required"	    :"",
            "default"	    :"",
    
    
    
        },
    
    
        //spacer
        {
    
            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{
    
                backgroundColor:"transparent",
                height:"20px"
            },
            "required"	    :"",
            "default"	    :""
    
        },
    
    
    



    
        //-----------------------------------
        // Ledger
        //-----------------------------------
    
        // {
    
        //     "print"		    :'bar',
        //     "name"		    :'Job Descriptions',
        //     "value"		    :"",
        //     "required"	    :"",
        //     "default"	    :""
    
    
        // },
        {
    
            "print"		    :'ledger',
            "name"		    :"Ledger",
            "value"		    :[
    
    
    
                {
                    name:"Direct",
                    value:ownr_labor_direct
    
                },
                {
                    name:"Indirect",
                    value:ownr_labor_indirect
    
                }
    
            ],
            "required"	    :"",
            "default"	    :""
    
    
        },
    
        {
    
            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{
    
                backgroundColor:"transparent",
                height:"50px"
            },
            "required"	    :"",
            "default"	    :""
    
    
        },
    














    
    
        //-----------------------------------
        // Ledger
        //-----------------------------------
    
        {
    
            "print"		    :'bar',
            "name"		    :'Labor Hours Weekly',
            "value"		    :"",
            "required"	    :"",
            "default"	    :""
    
    
        },
        {
    
            "print"		    :'ledger',
            "name"		    :"Ledger",
            "value"		    :[
    

                {
                    name:"Direct Labor Hours",
                    value:ownr_hours_direct+" hrs"
                },
    
                {
                    name:"Indirect Labor Hours",
                    value:ownr_hours_indirect+" hrs"
                },
                {
                    name:"Total Hours",
                    value:ownr_total_hours_weekly+" hrs"
                }
    
    
            ],
            "required"	    :"",
            "default"	    :""
    
    
        },
    
        {
    
            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{
    
                backgroundColor:"transparent",
                height:"50px"
            },
            "required"	    :"",
            "default"	    :""
    
    
        },
    
    
    

    
    
    
        //-----------------------------------
        // Ledger
        //-----------------------------------
    
        {
    
            "print"		    :'bar',
            "name"		    :'Healthcare',
            "value"		    :"",
            "required"	    :"",
            "default"	    :""
    
    
        },
        {
    
            "print"		    :'ledger',
            "name"		    :"Ledger",
            "value"		    :[
    
    
                {
                    name:"Health Insurance Monthly",
                    value:ownr_health_insurance_monthly
                },
    
            ],
            "required"	    :"",
            "default"	    :""
    
    
        },
    
        {
    
            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{
    
                backgroundColor:"transparent",
                height:"50px"
            },
            "required"	    :"",
            "default"	    :""
    
    
        },
    
    
    
    
        //-----------------------------------
        // Ledger
        //-----------------------------------
    
        {
    
            "print"		    :'bar',
            "name"		    :'IRA',
            "value"		    :"",
            "required"	    :"",
            "default"	    :""
    
    
        },
        {
    
            "print"		    :'ledger',
            "name"		    :"Ledger",
            "value"		    :[
    
    
                {
                    name:"Contribution Monthly",
                    value:ownr_ira_monthly
                },
    
            ],
            "required"	    :"",
            "default"	    :""
    
    
        },
    
        {
    
            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{
    
                backgroundColor:"transparent",
                height:"50px"
            },
            "required"	    :"",
            "default"	    :""
    
    
        },
    
    
    
    
    
    
        //-----------------------------------
        // Ledger
        //-----------------------------------
    
        {
    
            "print"		    :'bar',
            "name"		    :'Vacation / Personal Time',
            "value"		    :"",
            "required"	    :"",
            "default"	    :""
    
    
        },
        {
    
            "print"		    :'ledger',
            "name"		    :"Ledger",
            "value"		    :[
    
    
                {
                    name:"Weeks",
                    value:ownr_vacation_weeks
                }
    

            ],
            "required"	    :"",
            "default"	    :""
    
    
        },
    
        {
    
            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{
    
                backgroundColor:"transparent",
                height:"50px"
            },
            "required"	    :"",
            "default"	    :""
    
    
        },
    
    
    
    
    
    
    
        //-----------------------------------
        // Ledger
        //-----------------------------------
    
        {
    
            "print"		    :'bar',
            "name"		    :'Yearly Summary',
            "value"		    :"",
            "required"	    :"",
            "default"	    :""
    
    
        },
        {
    
            "print"		    :'ledger',
            "name"		    :"Ledger",
            "value"		    :[
    
    
                {
                    name:"Salary",
                    value:ownr_salary_pretax
                },
                {
                    name:"Health Insurance",
                    value:ownr_health_insurance_yearly
                },
                {
                    name:"IRA",
                    value:ownr_ira_yearly
                },
                {
                    name:"Taxes",
                    value:ownr_taxes_yearly
                },
                {
                    name:"Yearly Total",
                    value:ownr_yearly_total

                },


                // {
                //     name:"STI Yearly Direct",
                //     value:ownr_sti_yearly_direct
                // },
                // {
                //     name:"STI Yearly Indirect",
                //     value:ownr_sti_yearly_indirect

                // }
    

    
    
            ],
            "required"	    :"",
            "default"	    :""
    
    
        },
    
        {
    
            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{
    
                backgroundColor:"transparent",
                height:"50px"
            },
            "required"	    :"",
            "default"	    :""
    
    
        }
    
    
    
    
    
    ];
    



    R.push({

        call:'report',
        items: reportItems

    });



        
    return R;


}



export default Index;
