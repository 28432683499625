const VPformTemplateExpense = [


    // //==========================================
    // //titleline
    // //==========================================
    // {

    //     "section"   :"titleline",
    //     "data"      :{

    //         "text"  :"New Expense"// or name

    //     }


    // },




    //==========================================
    //edittitle
    //==========================================
    {

        "section"   :"edittitle",
        "data"      :{

            "text"  :"New Expense"// or name

        }


    },


    //#####################################################
    //#####################################################

    // //==========================================
    // //benefits
    // //==========================================
    // {

    //     "section"   :"headline",
    //     "data"      :{

    //         "text"  :'Benefits'

    //     }


    // },

    //==========================================
    //labelfields
    //==========================================
    {

        "section"   :"editfields",
        "data"      :[


            //--------------------------------
            // Health Insurance
            //--------------------------------
            {

                label: {

                    "text"               	    :"Line Item"
                },

                field: {


                    "fieldtype"               	:"price",
                    "required"					:true,

                    "class"                   	:'',
                    "id"                      	:'expense_field_1',
                    "hint"                    	:"",

                    "text"                     	:"0.00",



                    "keyboardType"            	:"",
                    "autocorrect"             	:"false",
                    "autocapitalizationType"  	:"none",


                    //"secure"                  :true,
                    //"returnKeyType"           :"done"


                },

            },

            //--------------------------------
            // IRA
            //--------------------------------

            {

                label: {

                    "text"               	    :"Line Item"
                },

                field: {


                    "fieldtype"               	:"price",
                    "required"					:true,

                    "class"                   	:'',
                    "id"                      	:'expense_field_2',
                    "hint"                    	:"",

                    "text"                     	:"0.00",



                    "keyboardType"            	:"",
                    "autocorrect"             	:"false",
                    "autocapitalizationType"  	:"none",


                    //"secure"                  :true,
                    //"returnKeyType"           :"done"


                },

            },


            //--------------------------------
            // Vacation Sick
            //--------------------------------

            {

                label: {

                    "text"               	    :"Line Item"
                },

                field: {


                    "fieldtype"               	:"price",
                    "required"					:true,

                    "class"                   	:'',
                    "id"                      	:'expense_field_3',
                    "hint"                    	:"",

                    "text"                     	:"0.00",



                    "keyboardType"            	:"",
                    "autocorrect"             	:"false",
                    "autocapitalizationType"  	:"none",


                    //"secure"                  :true,
                    //"returnKeyType"           :"done"


                },

            }




        ]

        
    },
    //==========================================
    //button
    //==========================================
    {
        "section": "editlink",
        "data": {
            "text": "Add Another",
            "call": "add",
            "fieldCall": "editfield",
            "style": {

                "width": "100%",
                "fontSize": "16px",
                "margin": "0 0 0 0",

                "bgColorOver": "transparent",
                "txtColorOver": "orange",
                "brColorOver": "transparent",
                "borderOver": "0",

                "bgColorOut": "transparent",
                "txtColorOut": "white",
                "brColorOut": "transparent",
                "borderOut": "0"

            }
        }
    },





    
];


export {

    VPformTemplateExpense

}

