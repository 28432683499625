import styled from "styled-components";
//import px2vw from "utils/px2vw";



export const Alfa = styled.div.attrs(props => ({


    backgroundColor:    props.backgroundColor || "#1a1926",//#fcfcfc
    border:             props.border || "0",
    borderRadius:       props.borderRadius || "0",

    percent:           props.percent || "60", 
    decimal:           props.decimal || "0.6",


    width:            props.width || "100%", 
    height:           props.height || "100%",

    zIndex:           props.zIndex || "1",
    top:              props.top || "0px", 
    left:             props.left || "0px"


}))`
  

    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    border-radius:${props => props.borderRadius};

    width:${props => props.width};
    height:${props => props.height};

    padding :0 0 0 0; 
    margin  :0 0 0 0;

    position:absolute;   
    z-index:${props => props.zIndex};
    top:${props => props.top};
    left:${props => props.left};

    -ms-filter: "progid:pxImageTransform.Microsoft.Alpha(Opacity=${props => props.percent})";
    filter : alpha(opacity=${props => props.percent});
    -moz-opacity : ${props => props.decimal};
    -khtml-opacity : ${props => props.decimal};
    opacity : ${props => props.decimal};


`;


export const Base = styled.div.attrs(props => ({


    backgroundColor:    props.backgroundColor || "transparent",//#fcfcfc
    border:             props.border || "0",
    borderRadius:       props.borderRadius || "0",

    width:            props.width || "100%", 
    height:           props.height || "100%",

    zIndex:           props.zIndex || "2", 
    top:              props.top || "0", 
    left:             props.left || "0"


}))`
  

    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    border-radius:${props => props.borderRadius};


    width:${props => props.width};
    height:${props => props.height};

    padding :0 0 0 0; 
    margin  :0 0 0 0;


    position:absolute;   
    z-index:${props => props.zIndex};
    top:${props => props.top};
    left:${props => props.left};


    overflow-x:hidden;
    overflow-y:auto;


`;





export const Loader = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent", //"#191e28", //"#EDEEF1",

    width:              props.width || "400px",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 0",

    zIndex:             props.zIndex || "8",
    top:                props.top || "200px", 
    left:               props.left || "0px",


    messageFontFamily:         props.messageFontFamily || 'Arial, Helvetica, sans-serif',
    messageFontSize:           props.messageFontSize || "20px",
    messageFontWeight:         props.messageFontWeight  || "500",
    messageTextAlign:          props.messageTextAlign || "center",
    messageTextDecoration:     props.messageTextDecoration  || "none",
    messageTextColor:          props.messageTextColor || "black",

    // border-top:1px solid lightgray;
    // border-bottom:1px solid lightgray; 


}))`

    background-color:${props => props.backgroundColor};
    border-radius:0;

    min-height:200px;
    width:${props => props.width};
    height:${props => props.height};

    padding:0 0 50px 0;
    margin:${props => props.margin};

    position:absolute;
    z-index:${props => props.zIndex};
    top:${props => props.top};
    left:${props => props.left};



    @media (max-width: 100000px) {

        left:calc(0% + 500px);

    }
    @media (max-width: 1024px) {

        left:calc(50% - 200px);

    }
    @media (max-width: 400px) {

        width:300px;
        margin:0 0 0 calc(50% - 150px);


    }


    .Message {

        background-color:transparent;

        width:calc(100% - 20px);
        height:auto;
        padding:10px 0 10px 10px;
        margin:25px 0 20px 0;
        position:relative;
        float:left;

        cursor:default;

        font-family:${props => props.messageFontFamily};
        font-size:${props => props.messageFontSize};
        font-weight:${props => props.messageFontWeight};
        text-align:${props => props.messageTextAlign};
        text-decoration:${props => props.messageTextDecoration};
        color:${props => props.messageTextColor};

    }





`;







export const Message = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "white", //"#191e28", //"#EDEEF1",

    width:              props.width || "400px",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 0",

    zIndex:             props.zIndex || "8",
    top:                props.top || "100px", 
    left:               props.left || "calc(50% - 200px)",


    messageFontFamily:         props.messageFontFamily || 'Arial, Helvetica, sans-serif',
    messageFontSize:           props.messageFontSize || "20px",
    messageFontWeight:         props.messageFontWeight  || "500",
    messageTextAlign:          props.messageTextAlign || "center",
    messageTextDecoration:     props.messageTextDecoration  || "none",
    messageTextColor:          props.messageTextColor || "black",

    buttonFontFamily:         props.buttonFontFamily || 'Arial, Helvetica, sans-serif',
    buttonFontSize:           props.buttonFontSize || "16px",
    buttonFontWeight:         props.buttonFontWeight  || "500",
    buttonTextAlign:          props.buttonTextAlign || "center",
    buttonTextDecoration:     props.buttonTextDecoration  || "none",
    buttonTextColor:          props.buttonTextColor || "black"


    // border-top:1px solid lightgray;
    // border-bottom:1px solid lightgray; 



}))`

    background-color:${props => props.backgroundColor};
    border-radius: 4px;

    min-height:auto;
    width:${props => props.width};
    height:${props => props.height};

    padding:0 0 50px 0;
    margin:${props => props.margin};

    position:absolute;
    z-index:${props => props.zIndex};
    top:${props => props.top};
    left:${props => props.left};


    @media (max-width: 100000px) {

        left:calc(0% + 500px);

    }

    @media (max-width: 1024px) {

        left:calc(50% - 200px);

    }

    @media (max-width: 400px) {

        width:300px;
        margin:0 0 0 calc(50% - 150px);

    }





    .Message {

        background-color:transparent;

        width:calc(100% - 20px);
        height:auto;
        padding:10px 0 10px 10px;
        margin:25px 0 20px 0;
        position:relative;
        float:left;

        cursor:default;

        font-family:${props => props.messageFontFamily};
        font-size:${props => props.messageFontSize};
        font-weight:${props => props.messageFontWeight};
        text-align:${props => props.messageTextAlign};
        text-decoration:${props => props.messageTextDecoration};
        color:${props => props.messageTextColor};

    }



    .SaveButton {

        background-color:#fc0;
        border:1px solid #fc0;
        border-radius:5px;

        width:calc(100% - 150px);
        height:auto;
        padding:11px 0 8px 0;
        margin:5px 0 0 75px;
        position:relative;
        float:left;

        cursor:pointer;

        font-family:${props => props.buttonFontFamily};
        font-size:${props => props.buttonFontSize};
        font-weight:${props => props.buttonFontWeight};
        text-align:${props => props.buttonTextAlign};
        text-decoration:${props => props.buttonTextDecoration};
        color:#000;

        &:hover{

            background-color:lightgreen;
            border:1px solid lightgreen;
            color:#000;

        }


    }

    @media (max-width: 1024px) {

        .SaveButton {

            width:calc(100% - 75px);
            margin:5px 0 5px 37.5px;

        }

    }




    .CancelButton {

        background-color:#07a2e3;
        border:1px solid #07a2e3;
        border-radius:5px;

        width:calc(100% - 150px);
        height:auto;
        padding:11px 0 8px 0;
        margin:5px 0 0 75px;
        position:relative;
        float:left;

        cursor:pointer;

        font-family:${props => props.buttonFontFamily};
        font-size:${props => props.buttonFontSize};
        font-weight:${props => props.buttonFontWeight};
        text-align:${props => props.buttonTextAlign};
        text-decoration:${props => props.buttonTextDecoration};
        color:#fff;

        &:hover{

            background-color:#0291cd;
            border:1px solid #0291cd;
            color:#fff;

        }


    }

    @media (max-width: 1024px) {

        .CancelButton {

            width:calc(100% - 75px);
            margin:5px 0 5px 37.5px;

        }

    }


`;




