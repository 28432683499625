const Index = {


    "type":"dataArray",
    "array":[



        {
            "styl":{

                fieldType:"heading"

            },
            "data":{

                "text"  :"Profit Calculator"
    
            }

        },






        //#############################################################
        //#############################################################
        // Cost Of Goods


        // {
        //     "styl":{

        //         fieldType:"headline"

        //     },
        //     "data":{

        //         "text"  :"Cost Of Goods"
    
        //     }

        // },


        {
            "styl":{

                fieldType:"titlegrid"

            },
            "data":{

                array:[


                    {
                        name:"Qty/Hrs",
                        width:"31%",
                        float:"right"
                
                    },
                
                    {
                        name:"Rate",
                        width:"40%",
                        float:"right"
                
                    }
                
                
                ]

            }

        },


        {
            "styl":{

                fieldType:"costfield"

            },
            "data":{

                "name"     : "CostField Test",
                "value"    :{

                    "good-1" : {

                        "print"		:'text',
                        "name"		:'Good 1',
                        "value"		:{

                            "rate"      :{

                                "print"		:'price',
                                "value"		:20,
                                "default"	:20


                            },
                            "qtyhrs"    :{

                                "print"		:'percent',
                                "value"		:1.5,
                                "default"	:1.5

                            }
   

                        },
                        "required"	:true,
                        "default"	:"",
                        "linecolor" :"yellow",
                        "isLabor"   :false
        
                    },
                    "good-2" : {

                        "print"		:'text',
                        "name"		:'Good 2',
                        "value"		:{

                            "rate"      :{

                                "print"		:'price',
                                "value"		:20,
                                "default"	:20


                            },
                            "qtyhrs"    :{

                                "print"		:'percent',
                                "value"		:1.5,
                                "default"	:1.5

                            }

                        },
                        "required"	:true,
                        "default"	:"",
                        "linecolor" :"yellow",
                        "isLabor"   :false
                    },
                    "good-3" : {

                        "print"		:'text',
                        "name"		:'Good 3',
                        "value"		:{

                            "rate"      :{

                                "print"		:'price',
                                "value"		:20,
                                "default"	:20


                            },
                            "qtyhrs"    :{

                                "print"		:'percent',
                                "value"		:1.5,
                                "default"	:1.5

                            }

                        },
                        "required"	:false,
                        "default"	:"",
                        "linecolor" :"yellow",
                        "isLabor"   :false
                    }

      

                }

            }

        },




        // // TM:
        // // single button

        // {
        //     "styl":{

        //         fieldType:"button"

        //     },
        //     "data"      :{

        //         "text"  :'Add Another',
        //         "call":"add",
        //         "fieldCall":"costfield",
        //         "style":{

        //             width:"100%",
        //             fontSize:"16px",
                    
        //             bgColorOver:"transparent",
        //             txtColorOver:"orange",
        //             borderOver:"0",

        //             bgColorOut:"transparent",
        //             txtColorOut:"white",
        //             borderOut:"0",

        //         }
                
        //     }

        // },



        // {
        //     "styl":{

        //         fieldType:"separator"

        //     },
        //     "data":{

        //         "name"		:"separator",
        //         "color"   	:'#454D5F',
        //         "height"  	:'50px'
        
        //     }

        // },



        // TM:

        // grid


        {
            "styl":{

                fieldType:"grid"
            },
            "data":{
      
                "styling":{

                    "marginTop":"20px", 
                    "width":"100%",      
                
                    "class":"button",
                    "buttonMargin":"5px",
    
                    "fontSize":"16px"
                },
                "cells":[

                    {

                        "text":'Add Cost',
                        "call":"add",
                        "fieldCall":"costfield",
                        "fieldSource":false,
                        "style":{

                            bgColorOver:"transparent",
                            txtColorOver:"orange",
                            borderOver:"0",
        
                            bgColorOut:"transparent",
                            txtColorOut:"white",
                            borderOut:"0",
        
                        }
        
                    },

                    {

                        "text":'Add Labor',
                        "call":"add",
                        "fieldCall":"costfield",
                        "fieldSource":"labor",
                        "style":{

                            bgColorOver:"transparent",
                            txtColorOver:"orange",
                            borderOver:"0",
        
                            bgColorOut:"transparent",
                            txtColorOut:"white",
                            borderOut:"0",
        
                        }

                    }

                ]
                    
            }

        },



        {
            "styl":{

                fieldType:"separator"

            },
            "data":{

                "name"		:"separator",
                "color"   	:'#454D5F',
                "height"  	:'50px'
        
            }

        },









        //#############################################################
        //#############################################################
        // Calcby

        {
            "styl":{

                fieldType:"calcby"

            },
            "data"      :{
    
                label:  "Calculate By",
                array:  [
    
                    {
                        call:"markup",
                        bgPosition:"-0px -100px",
                        
                    },   
                
                
                    {
                        call:"price",
                        bgPosition:"-0px -50px",
                        
                
                    },
                
                    {
                        call:"percent",
                        bgPosition:"-0px -0px",
                        
                
                    }
            
    
                ]
    
            }

        },




        {
            "styl":{

                fieldType:"separator"

            },
            "data":{

                "name"		:"separator",
                "color"   	:'#454D5F',
                "height"  	:'50px'
        
            }

        },


        // {
        //     "styl":{

        //         fieldType:"spacer"

        //     },
        //     "data":{

        //         "name"		:"spacer",
        //         "color"   	:'#454D5F',
        //         "height"  	:'20px'
        
        //     }

        // },




        {
            "styl":{

                fieldType:"bluntfield"

            },
            "data":{

                "name"     : "BluntField Test",
                "value"    :{

                    "calcby" : {

                        "print"		:'percent',
                        "name"		:'Profit',
                        "value"		:"25.0",
                        "required"	:true,
                        "default"	:"",
                        "linecolor" :"#00ff00",
                    }

                }

            }

        },




        {
            "styl":{

                fieldType:"separator"

            },
            "data":{

                "name"		:"separator",
                "color"   	:'#454D5F',
                "height"  	:'50px'
        
            }

        },






        //#############################################################
        //#############################################################
        // Overhead

        {
            "styl":{

                fieldType:"bluntfield"

            },
            "data":{

                "name"     : "BluntField Test",
                "value"    :{

                    "overhead" : {

                        "print"		:'percent',
                        "name"		:'Overhead',
                        "value"		:"55.0",
                        "required"	:true,
                        "default"	:"",
                        "linecolor" :"red",
                    }

                }

            }

        },




        // {
        //     "styl":{

        //         fieldType:"separator"

        //     },
        //     "data":{

        //         "name"		:"separator",
        //         "color"   	:'#454D5F',
        //         "height"  	:'50px'
        
        //     }

        // },








        //#############################################################
        //#############################################################
        // Debits

        // {
        //     "styl":{

        //         fieldType:"headline"

        //     },
        //     "data":{

        //         "text"  :"Debits"
    
        //     }

        // },


        {
            "styl":{

                fieldType:"bluntfield"

            },
            "data":{

                "name"     : "BluntField Test",
                "value"    :{

                    "commission" : {

                        "print"		:'percent',
                        "name"		:'Commission',
                        "value"		:"0.0",
                        "required"	:false,
                        "default"	:"",
                        "linecolor" :"orange",
                    }

                    // "fees" : {

                    //     "print"		:'percent',
                    //     "name"		:'Fees',
                    //     "value"		:"0.0",
                    //     "required"	:false,
                    //     "default"	:"",
                    //     "linecolor" :"orange",
                    // }


                }

            }

        },

        // {
        //     "styl":{

        //         fieldType:"button"

        //     },
        //     "data"      :{

        //         "text"  :'Add Another',
        //         "call":"add",
        //         "fieldCall":"bluntfield",
        //         "style":{

        //             width:"100%",
        //             fontSize:"16px",
                    
        //             bgColorOver:"transparent",
        //             txtColorOver:"orange",
        //             borderOver:"0",

        //             bgColorOut:"transparent",
        //             txtColorOut:"white",
        //             borderOut:"0",

        //         }
                
        //     }

        // },





        {
            "styl":{

                fieldType:"separator"

            },
            "data":{

                "name"		:"separator",
                "color"   	:'#454D5F',
                "height"  	:'50px'
        
            }

        },






        //#############################################################
        //#############################################################
        // Quantity

        {

            "styl":{

                fieldType:"bluntfield"
            },
            "data":{

                "name"     : "BluntField Test",
                "value"    :{

                    "quantity" : {

                        "print"		:'wholenumber',
                        "name"		:'Quantity',
                        "value"		:"1",
                        "required"	:true,
                        "default"	:"",
                        "linecolor" :"#07a2e3",
                    }

                }

            }

        },




        //#################################################################
        //#################################################################
        //iconbutton

        {
            "styl":{

                fieldType:"separator"

            },
            "data":{

                "name"		:"separator",
                "color"   	:'#454D5F',
                "height"  	:'50px'
        
            }

        },


        {
   
            "styl":{

                fieldType:"iconbutton"

            },
            "data"      :{

                "text"  :'Calculate',
                "class" :'icon-button',
                "funct"	:{

                    "name":"FormSubmit",
                    "object":false

                },
                "style":{

                    bgImage         :"/sprites/calculator.png",
                    bgPositionOver  :"-100px -0px",
                    bgPositionOut   :"-150px -0px",
                    bgColorOver     :"#00e070",
                    bgColorOut      :"#191e28",
                    txtColorOver    :"#191e28",
                    txtColorOut     :"#00e070",
                    brColorOver     :"#00e070",
                    brColorOut      :"#00e070",


                }
                
            }

        },











        // #############################################################
        // #############################################################
        // button

        // {
        //     "styl":{

        //         fieldType:"button"

        //     },
        //     "data"      :{

        //         "text"  :'Calculate',
        //         "class" :'button',
        //         "funct"	:{

        //             "name":"FormSubmit",
        //             "object":false

        //         }
                
        //     }

        // },



    






        // {
        //     "styl":{

        //         fieldType:"field"

        //     },
        //     "data":{

        //         "name"     : "Field Test",
        //         "value"    :{

        //             "company" : {

        //                 "print"		:'text',
        //                 "name"		:'Company',
        //                 "value"		:"",
        //                 "required"	:true,
        //                 "default"	:"Your Company"
        //             },
        //             "email" : {

        //                 "print"		:'text',
        //                 "name"		:'Email',
        //                 "value"		:"",
        //                 "required"	:true,
        //                 "default"	:"fake@gmail.com"
        //             },
        //             "address" : {

        //                 "print"		:'text',
        //                 "name"		:'Address',
        //                 "value"		:"",
        //                 "required"	:false,
        //                 "default"	:"123 Fake Street"
        //             },
        //             "city" : {

        //                 "print"		:'text',
        //                 "name"		:'City',
        //                 "value"		:"",
        //                 "required"	:false,
        //                 "default"	:"Anytown"
        //             },
        //             "state" : {

        //                 "print"		:'select',
        //                 "name"		:'State',
        //                 "value"		:"",
        //                 "required"	:false,
        //                 "default"	:"PA"
        //             },
        //             "country" : {

        //                 "print"		:'select',
        //                 "name"		:'Country',
        //                 "value"		:"",
        //                 "required"	:false,
        //                 "default"	:"US"
        //             },
        //             "zipcode" : {

        //                 "print"		:'text',
        //                 "name"		:'Zip Code',
        //                 "value"		:"",
        //                 "required"	:false,
        //                 "default"	:"55555"
        //             },
        //             "phone" : {

        //                 "print"		:'phone',
        //                 "name"		:'phone',
        //                 "value"		:"",
        //                 "required"	:false,
        //                 "default"	:"555-555-5555"
        //             }

        //         }

        //     }

        // },



        // {
        //     "styl":{

        //         fieldType:"separator"

        //     },
        //     "data":{

        //         "name"		:"separator",
        //         "color"   	:'#454D5F',
        //         "height"  	:'50px'
        
        //     }

        // },



        // {
        //     "styl":{

        //         fieldType:"labelfield"

        //     },
        //     "data":{

        //         "name"     : "LabelField Test",
        //         "value"    :{

        //             "company" : {

        //                 "print"		:'text',
        //                 "name"		:'Company',
        //                 "value"		:"",
        //                 "required"	:true,
        //                 "default"	:"Your Company"
        //             },
        //             "email" : {

        //                 "print"		:'text',
        //                 "name"		:'Email',
        //                 "value"		:"",
        //                 "required"	:true,
        //                 "default"	:"fake@gmail.com"
        //             },
        //             "address" : {

        //                 "print"		:'text',
        //                 "name"		:'Address',
        //                 "value"		:"",
        //                 "required"	:false,
        //                 "default"	:"123 Fake Street"
        //             }

      

        //         }

        //     }

        // }













    ]

}
    

export default Index;



