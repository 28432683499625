import {

    Alfa

} from '../Styled';

const Index = (props) => {


    return (<div>


        <Alfa
        backgroundColor={"transparent"}
        height={props.height}
        onClick={(v) => props.handle({

            call:"showMenu",
            data:false

        })}
        ></Alfa>

    </div>)

}

export default Index;


