import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';



import { 

    VPaccount, 
    VPexpenses,
    VPincome,   
    VPoverhead, 

    //VPownrs,
    VPownr,

    //VPempls,
    VPempl,

    //VPindirectLabor,
    //VPidlEmployee

} from 'app-components/Prime/VPdata/form.js';


import { LibBase } from "library";




import ApiResources from 'utils/components/Pages/ApiResources';



const SceneInfo = () => {



    //-------------------------------------------

    var apiDomain;
    var hostname;
    //var hostname = window.location.hostname;//returns the domain name of the web host
    var protocol = window.location.protocol;//returns the web protocol used (http: or https:)

    if(window.location.hostname.match(/192.168/gi))
    {
        hostname = ApiResources.network['3001'].vpc;
        apiDomain = protocol + "//" + hostname + "/";
    }
    else
    if(window.location.hostname.match(/localhost/gi))
    {
        hostname = ApiResources.local['3001'].vpc;
        apiDomain = protocol + "//" + hostname + "/";
    }else{
        hostname = ApiResources.aws['x'].vpc;
        apiDomain = "https://" + hostname + "/";
    }//=

    //-------------------------------------------

    //console.log("API DOMAIN: "+apiDomain);


    //const apiCall = 'alter-calc-test';
    const apiCall = 'alter-calc-dash';



    // -- redux -- //
    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));





    //var calc_overhead = {};





    var keyname;
    var kn;
    var item;
    //var name;
    var value;
    var newValue;

    var isOkay;




    //================================================
    // account
    //================================================

    isOkay = false;
    if(zoneObj.account 
    && zoneObj.account !== undefined
    )
    {
        if(Object.keys(zoneObj.account).length > 0
        )
        {
            isOkay = true;
        }

    }
    if(isOkay)
    {

        //console.log("ACCOUNT: "+JSON.stringify(zoneObj.account));

        for(keyname in zoneObj.account)
        {

            //console.log("KEYNAME: "+keyname);

            item = zoneObj.account[keyname];
            //console.log(JSON.stringify(item,null,2));

            value = VPaccount[keyname].value;
            newValue = {};
            for(kn in value)
            {
                newValue[kn] = item.value[kn];
            }
            VPaccount[keyname] = {
                name:item.name,
                value:newValue
            };
 

        }// -- for


    }// -- if






    //================================================
    // calc_dash
    //================================================

    //var actual_monthly_expenses;
    //var projected_monthly_sales;
    //var overhead_percent;
    var owners = {};   
    var employees = {};

    //actual_monthly_expenses = VPmonthlyExpenses.value;
    //projected_monthly_sales = VPmonthlySales.value;
    //projected_monthly_sales = VPoverhead.settings.value.projected_monthly_sales;
    //overhead_percent = 75.5 + "%";



    isOkay = false;
    if(zoneObj.calc_dash 
    && zoneObj.calc_dash !== undefined
    )
    {
        if(Object.keys(zoneObj.calc_dash.form_items).length > 0
        && Object.keys(zoneObj.calc_dash.result_items).length > 0
        )
        {
            isOkay = true;
        }

    }
    if(isOkay)
    {


        //console.log("FORM ITEMS: "+JSON.stringify(zoneObj.calc_dash.form_items));

        for(keyname in zoneObj.calc_dash.form_items)
        {


            // -- owners -- //

            if(keyname.match(/ownr/gi))
            {

                //idl_employees[keyname] = zoneObj.calc_dash.form_items[keyname];

                
                //order card item based on Prime VPdata
                item = zoneObj.calc_dash.form_items[keyname];
          
                value = VPownr.value;
                newValue = {};
                for(kn in value)
                {
                    if(kn === 'ownr_call') 
                    {
                    }else{
                        newValue[kn] = item.value[kn];       
                    }
                    
                }
                owners[keyname] = {
                    name:item.name,
                    value:newValue
                };
                

            }




            // -- employees -- //

            else
            if(keyname.match(/empl/gi))
            {

                //idl_employees[keyname] = zoneObj.calc_dash.form_items[keyname];

                
                //order card item based on Prime VPdata
                //item = zoneObj.calc_dash.form_items[keyname];
                item = LibBase.CloneIt(zoneObj.calc_dash.form_items[keyname]);//Deep Clone
          
                value = VPempl.value;
                //value = LibBase.CloneIt(VPidlEmployee.value);
                newValue = {};
                for(kn in value)
                {
                    newValue[kn] = item.value[kn];
                }
                employees[keyname] = {
                    name:item.name,
                    value:newValue
                };
                



      
            }

            

            // -- overhead -- //

            else
            {
                //console.log("KEYNAME: "+keyname);

                item = zoneObj.calc_dash.form_items[keyname];
                //console.log(JSON.stringify(item,null,2));


                if(keyname.match(/expense-/gi))
                {
                    VPoverhead[keyname] = {
                        name:item.name,
                        value:item.value
                    };

                }else{

                    value = VPoverhead[keyname].value;
                    newValue = {};
                    for(kn in value)
                    {
                        newValue[kn] = item.value[kn];
                    }
                    VPoverhead[keyname] = {
                        name:item.name,
                        value:newValue
                    };

                }




            }

        }// -- for





        VPexpenses.value.monthly_expenses.value = zoneObj.calc_dash.result_items['totals'].total;
        VPincome.value.projected_monthly_sales.value = zoneObj.calc_dash.form_items['settings'].value.projected_monthly_sales.value;


        //overhead_percent = zoneObj.calc_dash.result_items['overhead'].overhead;
        //overhead_percent = parseFloat(overhead_percent) 
        //overhead_percent = overhead_percent.toFixed(1) + "%";



        //console.log("IDL EMPLOYEES: "+JSON.stringify(idl_employees,null,2));

    
        /*
        for(keyname in zoneObj.calc_dash.result_items)
        {

            console.log("KEYNAME: "+keyname);

            item = zoneObj.calc_dash.result_items[keyname];
            console.log(JSON.stringify(item,null,2));

        
        }// -- for
        */



        
    }// -- if

    //console.log("_overhead A: "+JSON.stringify(_overhead,null,2));

    //_overhead = VPoverhead;
    //console.log("_overhead B: "+JSON.stringify(_overhead,null,2));





    var R = [];
    //var index;


    //#############################################################
    //#############################################################

    //--------------------------
    // Catalog - Top
    //--------------------------

    R.push({

        call:'top',
        name:"Schedule",
        controls:[

            {

                name:"Edit Schedule",
                form:{

                    submitCall:'calculate',
                    postTo:apiDomain+apiCall,
                    data:VPoverhead['work_schedule'],
                    flyr:{
                        type:"form",
                        styl:{
                            maxWidth:600,
                            fieldType:'labelfield'
                        }
                    }
             

                }

            }

        ]

    });






    //#############################################################
    //#############################################################

    //--------------------------
    // Catalog - Bar
    //--------------------------

    R.push({

        call:'barNameValue',
        items: [

            {

                name:"Name"

            },

            {

                name:"Value"

            }

        ]
        
    });






    


    //#############################################################
    //#############################################################

    //--------------------------
    // Catalog - Items
    //--------------------------

    var catalogItems = [];


    var workSchedule = LibBase.CloneIt(VPoverhead['work_schedule']);//Deep Clone
    for(keyname in workSchedule.value)
    {

        catalogItems.push({
                        
            heading:workSchedule.value[keyname].name,
            bullet:false,
            stripe:true,
            pgraph:workSchedule.value[keyname].value,
            image:false,
            button:false,
            controls:{

                form:{

                    submitCall:'calculate',
                    postTo:apiDomain+apiCall,
                    data:workSchedule,
                    flyr:{
                        type:"form",
                        styl:{
                            maxWidth:600,
                            fieldType:'labelfield'
                        }
                    }

                },
                chart:false

            }
        
        });

    }



    R.push({

        call:"itemsNameValue",
        items:catalogItems

    });

    return R;





}



export default SceneInfo;
