
export const expense = [

    //-----------------------------------
    // Ledger
    //-----------------------------------

    {

        "print"		    :'bar',
        "name"		    :'Monthly Expense',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'ledger',
        "name"		    :"Ledger",
        "value"		    :[

            {
                name:"Total",
                value:"$10000.00"

            }

        ],
        "required"	    :"",
        "default"	    :""


    },

    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"10px"
        },
        "required"	    :"",
        "default"	    :""


    },



    
    // -----------------------------------
    // Report Box - Chart
    // -----------------------------------


    //bar
    // {

    //     "print"		    :'bar',
    //     "name"		    :'Expense',
    //     "value"		    :"",
    //     "required"	    :"",
    //     "default"	    :""


    // },

    //spacer
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"10px"
        },
        "required"	    :"",
        "default"	    :""


    },
    // //chart
    {


        "print":'svg_donut_chart',
        "name":'SVG Donut Chart',
        "value":{

            backgroundColor:"transparent",
            width:"100%",
            height:"300px", // set a fixed height or auto for full expanded
            ring:{

                size:"10",
                color:"#d2d3d4"

            },
            hole:{
                size:0.5,
                // color:"#191e28",
                // outlineColor:"#191e28",
                color:"#fff",
                outlineColor:"#fff",
                textColor:"#000",
                textSize:"27px"
            },
            items: [ 

                {
                    "name": "Rent / Mortgage ( 78.9% )",
                    "value": "$5,500.00",
                    "percent": "78.9"
                },
                {
                    "name": "Utilities ( 15.8% )",
                    "value": "$1,100.00",
                    "percent": "15.8"
                },
                {
                    "name": "Phone / Internet ( 5.0% )",
                    "value": "$350.00",
                    "percent": "5.0"
                },
                {
                    "name": "Misc ( 0.3% )",
                    "value": "$20.00",
                    "percent": "0.3"
                }

            ],
            colors: "ChartColorsA"



        },
        "required"	    :"",
        "default"	    :"",



    },
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"25px"
        },
        "required"	    :"",
        "default"	    :""


    },










    //-----------------------------------
    // Ledger
    //-----------------------------------

    // {

    //     "print"		    :'bar',
    //     "name"		    :'Amount',
    //     "value"		    :"",
    //     "required"	    :"",
    //     "default"	    :""


    // },
    {

        "print"		    :'ledger',
        "name"		    :"Ledger",
        "value"		    :[



            {
                name:"Direct Labor",
                value:"$400.00"

            },
            {
                name:"Direct Labor Hours per Week",
                value:"$100.00"
            },
            {
                name:"Indirect Labor",
                value:"$400.00"

            },
            {
                name:"Indirect Labor Hours per Week",
                value:"$100.00"
            },
            {
                name:"Hours Weekly Total",
                value:"$200.00"
            },
            {
                name:"Indirect Labor Hours per Week",
                value:"$100.00"
            },
            {
                name:"Hours Weekly Total",
                value:"$200.00"
            }




        ],
        "required"	    :"",
        "default"	    :"",
        "colors"        : "ChartColorsA"

    },

    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"50px"
        },
        "required"	    :"",
        "default"	    :""


    },























    // // -----------------------------------
    // // Report Box - Donut and Big Txt
    // // -----------------------------------

    // {

    //     "print"		    :'bar',
    //     "name"		    :'Donut and Big Txt',
    //     "value"		    :"",
    //     "required"	    :"",
    //     "default"	    :""


    // },
    // {

    //     "print"		    :'report_box',
    //     "name"		    :'Report Box',
    //     "value"		    :[

    //         {

    //             boxCall:'donutChart',
    //             boxWidth:"45%",
    //             boxHeight:250,
    //             value:{

    //                 donutType:                      "static",
    //                 donutSize:                      "200px",
    //                 donutHalfSize:                  "100px",
    //                 donutCenterSize:                "100px",
    //                 donutCenterTopLeft:             "50px",
    //                 donutCenterBackgroundColor:     "#edeef1", //"#edeef1", //"#394264",
    //                 donutCenterBorderRadius:        "100%",
    //                 donutSections:[

    //                     {
    //                         percent :50,
    //                         color   :"royalblue"
    //                         // rotate      :"0",
    //                         // itemRotate  :"76",
            
    //                     },
        
    //                     {
    //                         percent :40,
    //                         color   :"royalblue"
    //                         // rotate      :"215",
    //                         // itemRotate  :"113",
    //                     },

    //                     {
    //                         percent :10,
    //                         color   :"lightblue"
    //                         // rotate      :"0",
    //                         // itemRotate  :"76",
            
    //                     }

    
    //                 ]

    //             }
        


    //         },


    //         {

    //             boxCall:'txt',
    //             boxWidth:"55%",
    //             boxHeight:250,
    //             value:[

    //                 {
    //                     className:"Big",
    //                     txt:"45.5%",
    //                     style:{

    //                         //fontSize:"100px",
    //                         textAlign:"center"

    //                     }
    //                 },

    //                 {
    //                     className:"Note",
    //                     txt:"Expenses / Sales = Overhead Percentage",
    //                     style:{

    //                         //fontSize:"13px",
    //                         textAlign:"center"

    //                     }
    //                 }


    //             ]


    //         }



    //     ],
    //     "required"	    :"",
    //     "default"	    :""


    // },
    // {

    //     "print"		    :'spacer',
    //     "name"		    :'',
    //     "value"		    :{

    //         backgroundColor:"transparent",
    //         height:"0px"
    //     },
    //     "required"	    :"",
    //     "default"	    :""


    // },








    // //-----------------------------------
    // // BarChart - Direct VS Indirect
    // //-----------------------------------

    // //bar
    // {

    //     "print"		    :'bar',
    //     "name"		    :'Percentages',
    //     "value"		    :"",
    //     "required"	    :"",
    //     "default"	    :"",

    // },



    // //spacer
    // {

    //     "print"		    :'spacer',
    //     "name"		    :'',
    //     "value"		    :{

    //         backgroundColor:"transparent",
    //         height:"30px"
    //     },
    //     "required"	    :"",
    //     "default"	    :""

    // },



    // //chart
    // {


    //     "print"		    :'singleBarChart',
    //     "name"		    :'The Chart',
    //     "value"		    :[

    //         {
    //             chartType:"horizontal",
    //             chartLines:true,
    //             data:[

    //                 {
    //                     name:'Direct Labor %',
    //                     value:"2500.00",
    //                     percent:80,
    //                 },

    //                 {
    //                     name:'Indirect Labor %',
    //                     value:"2500.00",
    //                     percent:20,
    //                 },

    //                 {
    //                     name:'Direct Labor %',
    //                     value:"2500.00",
    //                     percent:80,
    //                 },

    //                 {
    //                     name:'Indirect Labor %',
    //                     value:"2500.00",
    //                     percent:20,
    //                 },

    //                 {
    //                     name:'Direct Labor %',
    //                     value:"2500.00",
    //                     percent:80,
    //                 },

    //                 {
    //                     name:'Indirect Labor %',
    //                     value:"2500.00",
    //                     percent:20,
    //                 }



    //             ]

    //         }
    //     ],
    //     "required"	    :"",
    //     "default"	    :"",



    // },


    // //spacer
    // {

    //     "print"		    :'spacer',
    //     "name"		    :'',
    //     "value"		    :{

    //         backgroundColor:"transparent",
    //         height:"50px"
    //     },
    //     "required"	    :"",
    //     "default"	    :""

    // },










    // // -----------------------------------
    // // Report Box - Donut
    // // -----------------------------------

    // {

    //     "print"		    :'bar',
    //     "name"		    :'Report',
    //     "value"		    :"",
    //     "required"	    :"",
    //     "default"	    :""


    // },
    // {

    //     "print"		    :'spacer',
    //     "name"		    :'',
    //     "value"		    :{

    //         backgroundColor:"transparent",
    //         height:"20px"
    //     },
    //     "required"	    :"",
    //     "default"	    :""


    // },
    // {

    //     "print"		    :'report_box',
    //     "name"		    :'Report Box',
    //     "value"		    :[

    //         {

    //             boxCall:'donutChart',
    //             boxWidth:"100%",
    //             boxHeight:275,
    //             value:{

    //                 donutType:                      "static",
    //                 donutSize:                      "250px",
    //                 donutHalfSize:                  "125px",
    //                 donutCenterSize:                "150px",
    //                 donutCenterTopLeft:             "50px",
    //                 donutCenterBackgroundColor:     "#394264", //"#edeef1", //"#394264",
    //                 donutCenterBorderRadius:        "100%",
    //                 donutSections:[

    //                     {
    //                         percent :"25",
    //                         color   :"royalblue"
    //                         // rotate      :"0",
    //                         // itemRotate  :"76",
            
    //                     },
        
    //                     {
    //                         percent :"25",
    //                         color   :"lightblue"
    //                         // rotate      :"215",
    //                         // itemRotate  :"113",
    //                     },


    //                     {
    //                         percent :"25",
    //                         color   :"lightseagreen"
    //                         // rotate      :"0",
    //                         // itemRotate  :"76",
            
    //                     },
        
    //                     {
    //                         percent :"25",
    //                         color   :"lightgreen"
    //                         // rotate      :"215",
    //                         // itemRotate  :"113",
    //                     }
        
    
    //                 ]

    //             }
        

    //         }


    //     ],
    //     "required"	    :"",
    //     "default"	    :""


    // },
    // {

    //     "print"		    :'spacer',
    //     "name"		    :'',
    //     "value"		    :{

    //         backgroundColor:"transparent",
    //         height:"0px"
    //     },
    //     "required"	    :"",
    //     "default"	    :""


    // },

















];


















