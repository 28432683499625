import React, { 

    useState, 
    //useEffect, 
    //useRef 

} from 'react';

import SVGChart from 'grfx/chart.svg';
import SVGChartOver from 'grfx/chart_over.svg';

import SVGPencil from 'grfx/pencil.svg';
import SVGPencilOver from 'grfx/pencil_over.svg';

import SVGTrash from 'grfx/trash.svg';
import SVGTrashOver from 'grfx/trash_over.svg';



//import { fontSize } from 'pdfkit/js/mixins/fonts';

import CreateMarkup from './CreateMarkup';

import FormatPrice from './FormatPrice';

import { LibString } from 'library';



//import StylColumn from './StylModules/Column.module.css';

import {

    Columns,

    //Full,
    //Page,
    Small,

    

    Box,
    Controls,

    Heading,
    Stripe,
    Pgraph,
    BulletHeading,

    Info,
    List,
    Thmb,


    //Single,
    //SingleBox,
    //SingleControls,

    SpecBox,
    SpecTop,
    SpecBottom,
    SpecControls,
    SpecHeading,
    SpecInput,

    DecorBox,
    DecorText,
    DecorStripe


} from './Styled';




import CanvasChart from 'utils/components/Charts/CanvasChart';




const CmpSmall = (props) => {




    // -- refs -- //

    const [boxRefs] = useState([]);
    const setBoxRef = (ref) => {

        if(ref && ref !== null && ref !== undefined)
        {
            //console.log("ref::::"+ref.id);
            var found;
            found = false;
            for(var i=0; i < boxRefs.length; i++)
            {
                if(boxRefs[i].id === ref.id) found = true;
            }
            if(!found) boxRefs.push(ref);
            //console.log(boxRefs.length);

        }

    };




    // -- handle -- //

    const handleOverOut = (hndl) => {


        switch(hndl.from)
        {
        case'controls':


            //console.log("controls");

            var i;
            var found;
            found = false;
            if(boxRefs.length > 0)
            {
                for(i=0; i < boxRefs.length; i++)
                {
                    if(boxRefs[i].id === props.refid+"_box"+hndl.indx)
                    {
                        found = true;
                        break;
                    }
                }
            
            }

            if(found)
            {
                var box_id = props.refid+"_box"+hndl.indx;
                var buttonName = hndl.buttonName;

                var btn;
                var img;

                //controls = document.getElementById(hndl.id+"_controls");
                btn = document.getElementById(box_id+"_controls_"+buttonName);
                img = document.getElementById(box_id+"_controls_"+buttonName+"_img");

                switch(hndl.call)
                {
                case'mouseEnter':
                case'mouseOver':

                    btn.style.backgroundColor = 'transparent';


                    if(buttonName.match(/chart/gi)
                    ) 
                    {
                        img.src = SVGChartOver;
                    } 
                    else
                    if(buttonName.match(/trash/gi)
                    ) 
                    {
                        img.src = SVGTrashOver;
                    } 
                    else
                    {
                        img.src = SVGPencilOver;
                    }
  

                break;
                case'mouseOut':

                    btn.style.backgroundColor = 'transparent';

                    if(buttonName.match(/chart/gi)
                    ) 
                    {
                        img.src = SVGChart;
                    }
                    else
                    if(buttonName.match(/trash/gi)
                    ) 
                    {
                        img.src = SVGTrash;
                    } 
                    else
                    {
                        img.src = SVGPencil;
                    }
                break;
                default:
                }

            }//==



        break;
        default:
        }



    }


    





    return (


        <div>
        <Columns>

        {
        props.info.gridType === 'Graphic' 
        ? 


            <Small>

                {props.items.map((item, i) => (

                    <Box
                    key={i}      
                    ref={setBoxRef}
                    id={props.refid+"_box"+i}
                    width={'100%'}
                    padding={"0 0 0 0"}
                    border={"0"}

                    >

                        {item.image
                        ?
                            <Thmb
                            id={"thmb_"+i}
                            >

                                <div 
                                className="Holder"
                                >
                                    <img
                                    className="Img"
                                    style={{


                                        height:"auto",

                                        minWidth:item.image.maxWidth,
                                        minHeight: "100%",

                                        maxWidth:"auto",
                                        maxHeight:item.image.maxHeight,

                                        marginTop:item.image.top,
                                        marginLeft:item.image.left

                                    }}

                                        src={window.location.origin + `${process.env.PUBLIC_URL}` + item.image.src} 
                                        //src={window.location.origin + box.image.src} 
                                        //width={box.image.w}
                                        //height={box.image.h} 
                                        alt='noimage'
                                    /> 


                                </div>  

                            </Thmb>

                        :null}


                    </Box>


                ))}


            </Small>






        :
        //#########################################################
        //#########################################################
        //#########################################################

        props.info.gridType === 'Info' 
        ? 

            <Small>

                {props.items.map((item, i) => (

                    <Box
                    key={i}      
                    ref={setBoxRef}
                    id={props.refid+"_box"+i}

                    margin={"0 0 0 0"}

                    >

                        <Controls    
                        id={props.refid+"_box"+i+"_controls"}
                        >

                            <div 
                            className="Button"
                            id={props.refid+"_box"+i+"_controls_button"}
                            >

                                <div 
                                className="ButtonMask"
                                id={props.refid+"_box"+i+"_controls_button_mask"}

                                    onClick={() => props.handle({

                                        primeHandle : (v) => props.handle(v),

                                        call        :'showFlyr',
                                        //type        :'form',
                                        type        :item.controls.form.flyr.type,
                                        data        :item.controls.form.data,
                                        styl        :item.controls.form.flyr.styl,

                                        gridType    :props.info.gridType,
                                        position    :i,
                                        id          :item.id
                    
                                    })}
                                    onMouseEnter={() => handleOverOut({

                                        indx        :i,   
                                        call        :'mouseEnter',
                                        from        :'controls',
                                        buttonName  :"button"

                                    })}
                                    onMouseOut={() => handleOverOut({

                                        indx        :i,       
                                        call        :'mouseOut',
                                        from        :'controls',
                                        buttonName  :'button'

                                    })}

                                >
                                </div>

                                <img
                                id={props.refid+"_box"+i+"_controls_button_img"}
                                className="Img"
                                src={SVGPencil} 
                                alt="logo" />



                            </div>


                        </Controls>




                        {item.heading 
                        ?

                            <Heading>

                                <div dangerouslySetInnerHTML={CreateMarkup(item.heading)} />

                            </Heading>

                        :
                        
                            <Heading>

                                <div dangerouslySetInnerHTML={CreateMarkup("Heading")} />

                            </Heading>

                        }


                        {item.stripe
                        ?

                            <Stripe></Stripe>

                        :null}



                        {item.pgraph
                        ?

                            <Info>

                      
                                {Object.keys(item.pgraph).map((key, j) => (

                                    <div
                                    key={j}
                                    className="Line"
                                    >
                                        <div
                                        className="Name"
                                        >
                                            <div dangerouslySetInnerHTML={CreateMarkup(`${LibString.CapitalizeWords(key)}`)} />     
                                        </div>

                                        <div
                                        className="Value"
                                        >
                                            <div dangerouslySetInnerHTML={CreateMarkup(item.pgraph[key])} />     
                                        </div>
                    

                                    </div>

                                    

                                    /*

                                    <div
                                    key={j}
                                    className="SingleLine"
                                    >
                                        <div dangerouslySetInnerHTML={CreateMarkup(item.pgraph[key])} />     
                                        
                                    </div>
                                    */


                                ))}


                            </Info>


                        :null}


                    </Box>


                ))}


            </Small>




        :
        //#########################################################
        //#########################################################
        //#########################################################

        props.info.gridType === 'NameValue' 
        ? 


            <Small>

                {props.items.map((item, i) => (

                    <Box
                    key={i}      
                    ref={setBoxRef}
                    id={props.refid+"_box"+i}

                    margin={"0 0 0 0"}

                    >

                        <Controls    
                        id={props.refid+"_box"+i+"_controls"}
                        >


                            {item.controls.chart
                            ?

                                <div 
                                className="Button"
                                id={props.refid+"_box"+i+"_controls_chartButton"}
                                >

                                    <div 
                                    className="ButtonMask"
                                    id={props.refid+"_box"+i+"_controls_chartButton_mask"}

                                        onClick={() => props.handle({

                                            primeHandle : (v) => props.handle(v),

                                            call        :'showFlyr',
                                            //type        :'chart',
                                            type        :item.controls.chart.flyr.type,
                                            data        :item.controls.chart.data,
                                            styl        :item.controls.chart.flyr.styl,
                                     
                                            gridType    :props.info.gridType,
                                            position    :i,
                                            id          :item.id
                        
                                        })}
                                        onMouseEnter={() => handleOverOut({

                                            indx        :i,   
                                            call        :'mouseEnter',
                                            from        :'controls',
                                            buttonName  :"chartButton"
    
                                        })}
                                        onMouseOut={() => handleOverOut({
    
                                            indx        :i,       
                                            call        :'mouseOut',
                                            from        :'controls',
                                            buttonName  :'chartButton'
    
                                        })}

                                    >
                                    </div>

                                    <img
                                    id={props.refid+"_box"+i+"_controls_chartButton_img"}
                                    className="Img"
                                    src={SVGChart} 
                                    alt="logo" />



                                </div>



                            :null}




                            
                            {item.controls.form
                            ?


                                <div 
                                className="Button"
                                id={props.refid+"_box"+i+"_controls_button"}
                                >

                                    <div 
                                    className="ButtonMask"
                                    id={props.refid+"_box"+i+"_controls_button_mask"}

                                        onClick={() => props.handle({

                                            primeHandle : (v) => props.handle(v),

                                            call        :'showFlyr',
                                            //type        :'form',
                                            type        :item.controls.form.flyr.type,
                                            data        :item.controls.form.data,
                                            styl        :item.controls.form.flyr.styl,
                                        
                                            gridType    :props.info.gridType,
                                            position    :i,
                                            id          :item.id
                        
                                        })}
                                        onMouseEnter={() => handleOverOut({

                                            indx        :i,   
                                            call        :'mouseEnter',
                                            from        :'controls',
                                            buttonName  :"button"
    
                                        })}
                                        onMouseOut={() => handleOverOut({
    
                                            indx        :i,       
                                            call        :'mouseOut',
                                            from        :'controls',
                                            buttonName  :'button'
    
                                        })}

                                    >
                                    </div>

                                    <img
                                    id={props.refid+"_box"+i+"_controls_button_img"}
                                    className="Img"
                                    src={SVGPencil} 
                                    alt="logo" />



                                </div>


                            :null}




                            {item.controls.delete
                            ?

                                <div 
                                className="Button"
                                id={props.refid+"_box"+i+"_controls_trashButton"}
                                >

                                    <div 
                                    className="ButtonMask"
                                    id={props.refid+"_box"+i+"_controls_trashButton_mask"}

                                        onClick={() => props.handle({

                                            primeHandle : (v) => props.handle(v),


                                            call        :'deleteItem',
                                            //type        :'chart',
                                            type        :item.controls.form.flyr.type,
                                            data        :item.controls.delete.data,
                                            styl        :item.controls.form.flyr.styl,

                                            gridType    :props.info.gridType,
                                            position    :i,
                                            id          :item.id
                        
                                        })}
                                        onMouseEnter={() => handleOverOut({

                                            indx        :i,   
                                            call        :'mouseEnter',
                                            from        :'controls',
                                            buttonName  :"trashButton"
    
                                        })}
                                        onMouseOut={() => handleOverOut({
    
                                            indx        :i,       
                                            call        :'mouseOut',
                                            from        :'controls',
                                            buttonName  :'trashButton'
    
                                        })}

                                    >
                                    </div>

                                    <img
                                    id={props.refid+"_box"+i+"_controls_trashButton_img"}
                                    className="Img"
                                    src={SVGTrash} 
                                    alt="logo" />



                                </div>



                            :null}




                        </Controls>





                        {item.heading 
                        ?

                            item.bullet
                            ?

                                <BulletHeading

                                    bulletColor={item.bullet.styl.color}

                                >

                                    <div className="Bullet"></div>
                                    <div className="Text">

                                        <div dangerouslySetInnerHTML={CreateMarkup(item.heading)} />

                                    </div>

                                </BulletHeading>


                            :

                                <Heading>

                                    <div dangerouslySetInnerHTML={CreateMarkup(item.heading)} />

                                </Heading>

                        :
                        
                            <Heading>

                                <div dangerouslySetInnerHTML={CreateMarkup("Heading")} />

                            </Heading>

                        
                        }


                        {item.stripe
                        ?

                            <Stripe></Stripe>

                        :null}



                        {item.pgraph
                        ?
                            <List>

                                {   
                                Object.prototype.toString.call(item.pgraph) === '[object Array]'
                                ?

                                    item.pgraph.map((line, j) => (

                                        <div
                                        key={j}
                                        className="Line"
                                        >
                                            <div
                                            className="Name"
                                            >
                                                <div dangerouslySetInnerHTML={CreateMarkup(line.name)} />     
                                            </div>

                                            <div
                                            className="Value"
                                            >
                                                <div dangerouslySetInnerHTML={CreateMarkup(line.value)} />     
                                            </div>
                        

                                        </div>

                                    ))




                                :
                                Object.prototype.toString.call(item.pgraph) === '[object Object]'
                                ?


                                    Object.keys(item.pgraph).map((key, j) => (

                                        <div
                                        key={j}
                                        className="Line"
                                        >

                                            {
                                            Object.prototype.toString.call(item.pgraph[key].value === undefined)
                                            ?


                                                <div>

                                                    <div
                                                    className="Name"
                                                    >
                                                        <div dangerouslySetInnerHTML={CreateMarkup(`${LibString.CapitalizeWords(item.pgraph[key].name)}`)} />     
                                                    </div>


                                                    {
                                                    item.pgraph[key].print === undefined
                                                    ?

                                                        <div
                                                        className="Value"
                                                        >
                                                            <div dangerouslySetInnerHTML={CreateMarkup(item.pgraph[key].value)} />     
                                                        </div>

                                                    :


                                                        item.pgraph[key].print === 'percent'
                                                        ?

                                                            <div
                                                            className="Value"
                                                            >
                                                                <div dangerouslySetInnerHTML={

                                                                    CreateMarkup(parseFloat(item.pgraph[key].value).toFixed(1) + "%")

                                                                } /> 

                                                            </div>

                                                        :
                                                        item.pgraph[key].print === 'price'
                                                        ?

                                                            <div
                                                            className="Value"
                                                            >
                                                                <div dangerouslySetInnerHTML={

                                                                    CreateMarkup(FormatPrice(parseFloat(item.pgraph[key].value)))

                                                                } /> 

                                                            </div>

                                                        :

                                                            <div
                                                            className="Value"
                                                            >

                                                                <div dangerouslySetInnerHTML={

                                                                    CreateMarkup(item.pgraph[key].value)

                                                                } /> 
   
                                                            </div>
                                                

                                                    }


                                                </div>


                                            :


                                                <div>

                                                    <div
                                                    className="Name"
                                                    >
                                                        <div dangerouslySetInnerHTML={CreateMarkup(`${LibString.CapitalizeWords(key)}`)} />     
                                                    </div>

                    
                                                    <div
                                                    className="Value"
                                                    >
                                                        <div dangerouslySetInnerHTML={CreateMarkup(item.pgraph[key])} />     
                                                    </div>

                                            

                                                </div>


                                            }

                                        </div>

                                    ))

                                :null
                                }


                            </List>

                        :null}


                    </Box>


                ))}


            </Small>



        :
        //#########################################################
        //#########################################################
        //#########################################################

        props.info.gridType === 'SpecInput' 
        ? 

            <Small>

                {props.items.map((item, i) => (

                    <SpecBox
                    key={i}      
                    ref={setBoxRef}
                    id={props.refid+"_box"+i}

                    margin={"0 0 0 0"}

                    >


                        <SpecTop>

                            {item.heading 
                            ?

                                <SpecHeading>

                                    <div dangerouslySetInnerHTML={CreateMarkup(item.heading)} />

                                </SpecHeading>

                            :
                            
                                <SpecHeading>

                                    <div dangerouslySetInnerHTML={CreateMarkup("Heading")} />

                                </SpecHeading>

                            }


                        </SpecTop>



                        <SpecBottom>

                            {item.pgraph
                            ?

                                item.controls 
                                ?


                                    <SpecInput
                                    
                                        width={"80%"}
                                    
                                    >

                                        {
                                        item.pgraph.print === 'percent'
                                        ?

                                            <div
                                            className="Value"
                                            >
                                                <div dangerouslySetInnerHTML={

                                                    CreateMarkup(parseFloat(item.pgraph.value).toFixed(1) + "%")

                                                } /> 

                                            </div>

                                        :
                                        item.pgraph.print === 'price'
                                        ?

                                            <div
                                            className="Value"
                                            >
                                        
                                                <div dangerouslySetInnerHTML={

                                                    CreateMarkup(FormatPrice(parseFloat(item.pgraph.value)))

                                                } /> 

                                    
                                            </div>

                                        :

                                            <div
                                            className="Value"
                                            >

                                                <div dangerouslySetInnerHTML={CreateMarkup(item.pgraph.value)} /> 


                                            </div>

                                        }
                                
                                    
                                    </SpecInput>


                                :

                                    <SpecInput
                                                                 
                                        width={"100%"}
                                    
                                    >
                                    
                                

                                        {
                                        item.pgraph.print === 'percent'
                                        ?

                                            <div
                                            className="Value"
                                            >
                                                <div dangerouslySetInnerHTML={

                                                    CreateMarkup(parseFloat(item.pgraph.value).toFixed(1) + "%")

                                                } /> 

                                            </div>

                                        :
                                        item.pgraph.print === 'price'
                                        ?

                                            <div
                                            className="Value"
                                            >
                                        
                                                <div dangerouslySetInnerHTML={

                                                    CreateMarkup(FormatPrice(parseFloat(item.pgraph.value)))

                                                } /> 

                                    
                                            </div>

                                        :

                                            <div
                                            className="Value"
                                            >

                                                <div dangerouslySetInnerHTML={CreateMarkup(item.pgraph.value)} /> 


                                            </div>

                                        }
                                
                                    
                                    </SpecInput>






                            :null}



                            {item.controls ?



                                <SpecControls    
                                id={props.refid+"_box"+i+"_controls"}
                                >


                                    <div 
                                    className="Button"
                                    id={props.refid+"_box"+i+"_controls_button"}
                                    >

                                        <div 
                                        className="ButtonMask"
                                        id={props.refid+"_box"+i+"_controls_button_mask"}

                                            onClick={() => props.handle({

                                                call        :'showFlyr',
                                                //type        :'form',
                                                type        :item.controls.form.flyr.type,
                                                data        :item.controls.form.data,
                                                styl        :item.controls.form.flyr.styl,

                                                gridType    :props.info.gridType,
                                                position    :i,
                                                id          :item.id
                            
                                            })}
                                            onMouseEnter={() => handleOverOut({

                                                indx        :i,   
                                                call        :'mouseEnter',
                                                from        :'controls',
                                                buttonName  :"button"
        
                                            })}
                                            onMouseOut={() => handleOverOut({
        
                                                indx        :i,       
                                                call        :'mouseOut',
                                                from        :'controls',
                                                buttonName  :'button'
        
                                            })}

                                        >
                                        </div>

                                        <img
                                        id={props.refid+"_box"+i+"_controls_button_img"}
                                        className="Img"
                                        src={SVGPencil} 
                                        alt="logo" />

                                    </div>


                                </SpecControls>


                            :null}





                        </SpecBottom>


                    </SpecBox>


                ))}


            </Small>




        :
        //#########################################################
        //#########################################################
        //#########################################################


        props.info.gridType === 'Decor' 
        ? 

            <Small>

                {props.items.map((item, i) => (

                    <DecorBox
                    key={i}      
                    ref={setBoxRef}
                    id={props.refid+"_box"+i}
                    backgroundColor={props.info.decor.boxStyle.backgroundColor}
                    border={props.info.decor.boxStyle.border}
                    width={props.info.decor.boxStyle.width}
                    padding={props.info.decor.boxStyle.padding}
                    margin={props.info.decor.boxStyle.margin}
                    >

                        {

                            props.info.decor.call === "Text"
                            ?

                                item.heading 
                                ?
                                
                                    <DecorText

                                    backgroundColor={props.info.decor.textStyle.backgroundColor}
                             
                                    margin={props.info.decor.textStyle.margin}
                                    padding={props.info.decor.textStyle.padding} 

                                    fontSize={props.info.decor.textStyle.fontSize}
                                    fontWeight={props.info.decor.textStyle.fontWeight} 
                                    fontColor={props.info.decor.textStyle.fontColor}        
                                    textAlign={props.info.decor.textStyle.textAlign}
                                    textDecoration={props.info.decor.textStyle.textDecoration} 

                                    >

                                        <div dangerouslySetInnerHTML={CreateMarkup(item.heading)} />

                                    </DecorText>

                                :
                            
                                    <DecorText

                                    backgroundColor={props.info.decor.textStyle.backgroundColor}

                                    margin={props.info.decor.textStyle.margin}
                                    padding={props.info.decor.textStyle.padding} 

                                    fontSize={props.info.decor.textStyle.fontSize}
                                    fontWeight={props.info.decor.textStyle.fontWeight} 
                                    fontColor={props.info.decor.textStyle.fontColor}        
                                    textAlign={props.info.decor.textStyle.textAlign}
                                    textDecoration={props.info.decor.textStyle.textDecoration} 
    
                                    >

                                        <div dangerouslySetInnerHTML={CreateMarkup("X Large Text")} />

                                    </DecorText>




                            :
                            props.info.decor.call === 'Stripe'
                            ?

                                <DecorStripe

                                backgroundColor={props.info.decor.stripeStyle.backgroundColor}
                                border={props.info.decor.stripeStyle.border}
                                borderRadius={props.info.decor.stripeStyle.borderRadius}

                                width={props.info.decor.stripeStyle.width}
                                height={props.info.decor.stripeStyle.height} 
                                margin={props.info.decor.stripeStyle.margin}
                                padding={props.info.decor.stripeStyle.padding} 
                            
                                >
                                </DecorStripe>

                
                            :
                            props.info.decor.call === 'Chart'
                            ?

                                <CanvasChart

                                    parentId = {props.refid+"_box"+i}
                                    id = {props.refid+"_box"+i+"_canvas"}

                                    backgroundColor = {props.info.decor.canvas.backgroundColor}
                                    border={props.info.decor.canvas.border}
                                    width={props.info.decor.canvas.width}
                                    margin={props.info.decor.canvas.margin}


                                    drawCheckerBoard = {props.info.decor.canvas.drawCheckerBoard}
                                    draw = {false}   
                                    render = {false}
                                    chart = {{

                                        call    :props.info.decor.canvas.chart.call,
                                        data    :props.info.decor.canvas.chart.data,
                                        colors  :props.info.decor.canvas.chart.colors,
                                        hole    :props.info.decor.canvas.chart.hole

                                    }}

                                />


                            :
                            null
         

                        }

                    </DecorBox>


                ))}


            </Small>




        :
        //#########################################################
        //#########################################################
        //#########################################################


            <Small>

                {props.items.map((item, i) => (


                    <Box
                    key={i}      
                    ref={setBoxRef}
                    id={props.refid+"_box"+i}

                    margin={"0 0 0 0"}


                    >

                        <Controls    
                        id={props.refid+"_box"+i+"_controls"}
                        >

                            <div 
                            className="Button"
                            id={props.refid+"_box"+i+"_controls_button"}
                            >

                                <div 
                                className="ButtonMask"
                                id={props.refid+"_box"+i+"_controls_button_mask"}

                                    onClick={() => props.handle({

                                        primeHandle : (v) => props.handle(v),

                                        call        :'showFlyr',
                                        //type        :'form',
                                        type        :item.controls.form.flyr.type,
                                        data        :item.controls.form.data,
                                        styl        :item.controls.form.flyr.styl,

                                        gridType    :props.info.gridType,
                                        position    :i,
                                        id          :item.id
                    
                                    })}
                                    onMouseEnter={() => handleOverOut({

                                        indx    :i,   
                                        call    :'mouseEnter',
                                        from    :'controls'

                                    })}
                                    onMouseOut={() => handleOverOut({

                                        indx    :i,       
                                        call    :'mouseOut',
                                        from    :'controls'

                                    })}

                                >
                                </div>

                                <img
                                id={props.refid+"_box"+i+"_controls_button_img"}
                                className="Img"
                                src={SVGPencil} 
                                alt="logo" />



                            </div>


                        </Controls>





                        {item.heading 
                        ?

                            <Heading>

                                <div dangerouslySetInnerHTML={CreateMarkup(item.heading)} />

                            </Heading>

                        :
                        
                            <Heading>

                                <div dangerouslySetInnerHTML={CreateMarkup("Heading")} />

                            </Heading>

                        
                        }


                        {item.stripe
                        ?

                            <Stripe></Stripe>

                        :null}



                        {item.image
                        ?
                            <Thmb
                            id={"thmb_"+i}
                            >

                                <div 
                                className="Holder"
                                >
                                    <img
                                    className="Img"
                                    style={{


                                        height:"auto",

                                        minWidth:item.image.maxWidth,
                                        minHeight: "100%",

                                        maxWidth:"auto",
                                        maxHeight:item.image.maxHeight,

                                        marginTop:item.image.top,
                                        marginLeft:item.image.left

                                    }}

                                        src={window.location.origin + `${process.env.PUBLIC_URL}` + item.image.src} 
                                        //src={window.location.origin + box.image.src} 
                                        //width={box.image.w}
                                        //height={box.image.h} 
                                        alt='noimage'
                                    /> 


                                </div>  

                            </Thmb>

                        :null}


                        {item.pgraph
                        ?
                            <Pgraph>

                                <div dangerouslySetInnerHTML={CreateMarkup(item.pgraph)} />

                            </Pgraph>

                        :null}


                    </Box>


                ))}


            </Small>


        }


        </Columns>
        </div>


    )



}

export default CmpSmall;



