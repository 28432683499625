/*
----------------------
Flex or Fixed
----------------------

MAX - 4 across

 "across1"     :501,        
 "across2"     :801,          
 "across3"     :1050


MAX - 3 across

 "across1"     :501,        
 "across2"     :801,          
 "across3"     :10000


MAX - 2 across

 "across1"     :501,        
 "across2"     :10000,          
 "across3"     :10000

 */




import { 
    //Lorem, 
    //TestImages,
    //SpecialImages,
    //NavigationData 
} from 'app-components/Prime/Data';








const Card = (props) => {



    var object = {


        styl:{

            call:"indent",
            class:"IndentRow",

            marginTop:"50px",
            marginBottom:"0"


        },
        call:'CardComponent',
        info:{

            call:'fixed', //fixed, flex
            gridType: "default", //Info, Graphic, NameValue
            gridBgColor:'transparent',
            gridCall: "grid_cell",  //grid_cell or grid_column_cell
            cellCall: "default",  //container or default 
            modCall: 'fill_div',
            thumb: {

                call    : "default", //flex_height or default
                diff    : 0

            },    
            cellBorder: {
                "boolean"       :true,
                "thickness"     :1,//px
                "color"         :"gray"  
            },
            gutter: {
                "boolean"   :true,
                "size"      :20
            },
            thresh: {
                "refWidth"    :false,   //number or false = default screen width
                "across1"     :501,
                "across2"     :801,
                "across3"     :1050
            }



        },

        items:[]
     


    };



    object.styl.call = props.styl.call;
    object.styl.class = props.styl.class;
    object.styl.marginTop = props.styl.marginTop;
    object.styl.marginBottom = props.styl.marginBottom;




    if(props.info.thumb.diff) object.info.thumb.diff = props.info.thumb.diff;

    if(props.info.gutter.boolean)
    { 
        object.info.gutter.boolean = props.info.gutter.boolean;
        object.info.gutter.size = props.info.gutter.size;
    }

    if(props.info.thresh) object.info.thresh.across1 = props.info.thresh.across1;
    if(props.info.thresh) object.info.thresh.across2 = props.info.thresh.across2;
    if(props.info.thresh) object.info.thresh.across3 = props.info.thresh.across3;

    object.items = props.items;

    
    return object;


}





const InfoCard = (props) => {



    var object = {


        styl:{

            call:"indent",
            class:"IndentRow",

            marginTop:"50px",
            marginBottom:"0"


        },
        call:'CardComponent',
        info:{

            call:'fixed', //fixed, flex
            gridType: "Info", //Info, Graphic, NameValue
            gridBgColor:'transparent',
            gridCall: "grid_cell",  //grid_cell or grid_column_cell
            cellCall: "default",  //container or default 
            modCall: 'fill_div',
            thumb: {

                call    : "default", //flex_height or default
                diff    : 0

            },    
            cellBorder: {
                "boolean"       :true,
                "thickness"     :1,//px
                "color"         :"gray"  
            },
            gutter: {
                "boolean"   :true,
                "size"      :20
            },
            thresh: {
                "refWidth"    :false,   //number or false = default screen width
                "across1"     :501,
                "across2"     :801,
                "across3"     :1050
            }



        },

        items:[]
     


    };



    object.styl.call = props.styl.call;
    object.styl.class = props.styl.class;
    object.styl.marginTop = props.styl.marginTop;
    object.styl.marginBottom = props.styl.marginBottom;




    if(props.info.thumb.diff) object.info.thumb.diff = props.info.thumb.diff;

    if(props.info.gutter.boolean)
    { 
        object.info.gutter.boolean = props.info.gutter.boolean;
        object.info.gutter.size = props.info.gutter.size;
    }

    if(props.info.thresh) object.info.thresh.across1 = props.info.thresh.across1;
    if(props.info.thresh) object.info.thresh.across2 = props.info.thresh.across2;
    if(props.info.thresh) object.info.thresh.across3 = props.info.thresh.across3;

    object.items = props.items;

    
    return object;


}









const GraphicCard = (props) => {



    var object = {


        styl:{

            call:"indent",
            class:"IndentRow",

            marginTop:"50px",
            marginBottom:"0"


        },
        call:'CardComponent',
        info:{

            call:'fixed', //fixed, flex
            gridType: "Graphic", //Info, Graphic, NameValue
            gridBgColor:'transparent',
            gridCall: "grid_cell",  //grid_cell or grid_column_cell
            cellCall: "default",  //container or default 
            modCall: 'fill_div',
            thumb: {

                call    : "default", //flex_height or default
                diff    : 0

            },    
            cellBorder: {
                "boolean"       :true,
                "thickness"     :1,//px
                "color"         :"gray"  
            },
            gutter: {
                "boolean"   :true,
                "size"      :20
            },
            thresh: {
                "refWidth"    :false,   //number or false = default screen width
                "across1"     :501,
                "across2"     :801,
                "across3"     :1050
            }



        },

        items:[]
     


    };



    object.styl.call = props.styl.call;
    object.styl.class = props.styl.class;
    object.styl.marginTop = props.styl.marginTop;
    object.styl.marginBottom = props.styl.marginBottom;




    if(props.info.thumb.diff) object.info.thumb.diff = props.info.thumb.diff;

    if(props.info.gutter.boolean)
    { 
        object.info.gutter.boolean = props.info.gutter.boolean;
        object.info.gutter.size = props.info.gutter.size;
    }

    if(props.info.thresh) object.info.thresh.across1 = props.info.thresh.across1;
    if(props.info.thresh) object.info.thresh.across2 = props.info.thresh.across2;
    if(props.info.thresh) object.info.thresh.across3 = props.info.thresh.across3;

    object.items = props.items;

    
    return object;


}







const NameValueCard = (props) => {



    var object = {


        styl:{

            call:"indent",
            class:"IndentRow",

            marginTop:"50px",
            marginBottom:"0"


        },
        call:'CardComponent',
        info:{

            call:'fixed', //fixed, flex
            gridType: "NameValue", //Info, Graphic, NameValue
            gridBgColor:'transparent',
            gridCall: "grid_cell",  //grid_cell or grid_column_cell
            cellCall: "default",  //container or default 
            modCall: 'fill_div',
            thumb: {

                call    : "default", //flex_height or default
                diff    : 0

            },    
            cellBorder: {
                "boolean"       :true,
                "thickness"     :1,//px
                "color"         :"gray"  
            },
            gutter: {
                "boolean"   :true,
                "size"      :20
            },
            thresh: {
                "refWidth"    :false,   //number or false = default screen width
                "across1"     :501,
                "across2"     :801,
                "across3"     :1050
            }



        },

        items:[]
     


    };



    object.styl.call = props.styl.call;
    object.styl.class = props.styl.class;
    object.styl.marginTop = props.styl.marginTop;
    object.styl.marginBottom = props.styl.marginBottom;




    if(props.info.thumb.diff) object.info.thumb.diff = props.info.thumb.diff;

    if(props.info.gutter.boolean)
    { 
        object.info.gutter.boolean = props.info.gutter.boolean;
        object.info.gutter.size = props.info.gutter.size;
    }

    if(props.info.thresh) object.info.thresh.across1 = props.info.thresh.across1;
    if(props.info.thresh) object.info.thresh.across2 = props.info.thresh.across2;
    if(props.info.thresh) object.info.thresh.across3 = props.info.thresh.across3;

    object.items = props.items;

    
    return object;


}





const SpecInputCard = (props) => {



    var object = {


        styl:{

            call:"indent",
            class:"IndentRow",

            marginTop:"50px",
            marginBottom:"0"


        },
        call:'CardComponent',
        info:{

            call:'fixed', //fixed, flex
            gridType: "SpecInput", //Info, Graphic, NameValue
            gridBgColor:'transparent',
            gridCall: "grid_cell",  //grid_cell or grid_column_cell
            cellCall: "default",  //container or default 
            modCall: 'fill_div',
            thumb: {

                call    : "default", //flex_height or default
                diff    : 0

            },    
            cellBorder: {
                "boolean"       :true,
                "thickness"     :1,//px
                "color"         :"gray"  
            },
            gutter: {
                "boolean"   :true,
                "size"      :20
            },
            thresh: {
                "refWidth"    :false,   //number or false = default screen width
                "across1"     :501,
                "across2"     :801,
                "across3"     :1050
            }



        },

        items:[]
     


    };



    object.styl.call = props.styl.call;
    object.styl.class = props.styl.class;
    object.styl.marginTop = props.styl.marginTop;
    object.styl.marginBottom = props.styl.marginBottom;




    if(props.info.thumb.diff) object.info.thumb.diff = props.info.thumb.diff;

    if(props.info.gutter.boolean)
    { 
        object.info.gutter.boolean = props.info.gutter.boolean;
        object.info.gutter.size = props.info.gutter.size;
    }

    if(props.info.thresh) object.info.thresh.across1 = props.info.thresh.across1;
    if(props.info.thresh) object.info.thresh.across2 = props.info.thresh.across2;
    if(props.info.thresh) object.info.thresh.across3 = props.info.thresh.across3;

    object.items = props.items;

    
    return object;


}





const DecorCard = (props) => {



    var object = {


        styl:{

            call:"indent",
            class:"IndentRow",

            marginTop:"50px",
            marginBottom:"0"


        },
        call:'CardComponent',
        info:{

            call:'fixed', //fixed, flex
            gridType: "Decor", 
            gridBgColor:'transparent',
            gridCall: "TextXLarge", 
            cellCall: "TextXLarge", 
            modCall: 'fill_div',

            decor:{

                call        :"Text",

                boxStyle    :{

                    backgroundColor: "transparent",
                    border:"1px solid lightgray",
                    width:"96%",
                    padding: "0 2% 0 2%",   
                    margin: "20px 20px 20px 20px"

                },

                textStyle   :{

                    backgroundColor:    "transparent",
                    margin:             "0 0 0 0",
                    padding:            "0 0 0 0",

                    fontSize:           "57px",
                    fontWeight:         "bold",
                    fontColor:          "black",
                    textAlign:          "center",
                    textDecoration:     "none"
                },

                stripeStyle   :{

                    backgroundColor:    "#454d5f",
                    border:             "1px solid red",
                    margin:             "0 0 0 0",
                    padding:            "0 0 0 0",
                    width:              "100%",
                    height:             "10px"

                },



                canvas:{

                    backgroundColor:"transparent",
                    border:"0",
                    borderRadius:"0",
                    width:"96%",
                    margin:"0 auto 0 auto",

                    drawCheckerBoard: false,
                    chart:{

                        call:'doughnut',
                        data: {
                    
                            "Classical Music": 10,
                            "Alternative Rock": 14,
                            "Pop": 20,
                            "Jazz": 12,
                            "Hard Rock": 30,
                            "LeftOver":14
                        },
                        colors: [
                    
                            "#fde23e",
                            "#f16e23", 
                            "#57d9ff",
                            "#937e88",
                            "#20b2aa",
                            "#ccff00"

                        ],
                        hole:{
                            color:"#181b21",
                            outlineColor:"#181b21",
                            textColor:"white"
                        }


                    }
    
    

                }






            },

            thumb: {

                call    : "default", //flex_height or default
                diff    : 0

            },    
            cellBorder: {
                "boolean"       :true,
                "thickness"     :1,//px
                "color"         :"gray"  
            },
            gutter: {
                "boolean"   :true,
                "size"      :20
            },
            thresh: {
                "refWidth"    :false,   //number or false = default screen width
                "across1"     :501,
                "across2"     :801,
                "across3"     :1050
            }



        },

        items:[]
     


    };



    object.styl.call = props.styl.call;
    object.styl.class = props.styl.class;
    object.styl.marginTop = props.styl.marginTop;
    object.styl.marginBottom = props.styl.marginBottom;


    object.info.decor = props.info.decor;


    if(props.info.thumb.diff) object.info.thumb.diff = props.info.thumb.diff;

    if(props.info.gutter.boolean)
    { 
        object.info.gutter.boolean = props.info.gutter.boolean;
        object.info.gutter.size = props.info.gutter.size;
    }

    if(props.info.thresh) object.info.thresh.across1 = props.info.thresh.across1;
    if(props.info.thresh) object.info.thresh.across2 = props.info.thresh.across2;
    if(props.info.thresh) object.info.thresh.across3 = props.info.thresh.across3;

    object.items = props.items;

    
    return object;


}





export {

    Card,
    InfoCard,
    GraphicCard,
    NameValueCard,
    SpecInputCard,
    DecorCard

}

