import React, { 

    useState, 
    useEffect, 
    //useRef 

} from 'react';


import {

    Star,
    SmallStar

} from './Styled';


import {
    FAVORITE_STAR
} from 'app-components/Zone/_SVGComps';


const Index = (props) => {


    //console.log("Stars - Favorite: "+props.favorite)



    //=============================================
    // state/effect - showMenu
    //=============================================

    const [starSize, setStarSize] = useState('large');

    const [styling] = useState({

        goldColor    :"rgb(253,185,19)",
        blueColor    :"#07a2e3",
        grayColor    :"#373e4c" // "#373e4c" // "#3e4556" // "#454d5f"

    });

    useEffect(() => {

        if(props.starSize && props.starSize !== undefined)
        {
            setStarSize(props.starSize);

        }
    },
    [

        props.starSize
    ])





    return (<div>


        {[1,2,3,4,5].map((num, i) => (




            starSize === 'small'
            ?



                <SmallStar
                key={i}
                >

                    {
                    i < props.favorite
                    ?

                        <FAVORITE_STAR 
                        
                            fillColor={styling.goldColor}
                        
                        />

                
                    :
                        <FAVORITE_STAR 
                        
                            fillColor={styling.grayColor}
                        
                        />

                    }

                </SmallStar>


            :


                <Star
                key={i}
                >

                    {
                    i < props.favorite
                    ?

                        <FAVORITE_STAR 
                        
                            fillColor={styling.goldColor}
                        
                        />

                
                    :
                        <FAVORITE_STAR 
                        
                            fillColor={styling.grayColor}
                        
                        />

                    }

                </Star>
                                                    

        ))}


    </div>)

}

export default Index;


