import { 

    useState, 
    useEffect, 
    //useRef 

} from 'react';


import {

    Canvas_DrawCheckeredBackground,
    //Canvas_ColorImage,
    //Canvas_GetElementPosition,
    //Canvas_GetEventLocation,
    //Canvas_RgbToHex,
    //Canvas_DrawImageFromWebUrl

} from './Funx';


import {

    Canvas_RenderImage

} from './Render';


import { BarChart, DoughnutChart, PieChart } from './Charts';


const Canv = (props) => {


    const [coordinates, setCoordinates] = useState({});
  
    useEffect(()=>{

        const canvasObj = props.canvasRef.current;
        const ctx = canvasObj.getContext('2d');

        var chartItems;
        var chartColors;

        if(props.drawCheckerBoard 
        && props.drawCheckerBoard !== undefined
        )
        {
            Canvas_DrawCheckeredBackground({

                canvas  : canvasObj,
                ctx     : ctx,
                w       : 10,
                h       : 10

            });
            
        }

        if(props.render 
        && props.render !== undefined
        )
        {
            Canvas_RenderImage({

                canvas          : canvasObj,
                ctx             : ctx,
                init            : true

            });
            
        }


        if(props.chart 
        && props.chart !== undefined
        )
        {

            switch(props.chart.call)
            {
            case'bar':


                var chartData;
                chartData = JSON.parse(JSON.stringify(props.chart.data));
                if(Object.prototype.toString.call(chartData.items) === '[object Array]')
                {
                    if(chartData.items.length > 0)
                    {
                        if(chartData.items[0].name !== 'hidden_max')
                        {
                            chartData.items.unshift({
                                name    :'hidden_max',
                                value   :props.chart.data.gridScaleMax
                            });
                            chartData.colors.unshift('transparent');
                        }
                    }
    
                }
                var barChart = new BarChart({

                    canvas          :canvasObj,
                    seriesName      :props.chart.data.name,
                    padding         :props.chart.data.barPadding,
                    gridScale       :props.chart.data.gridScaleIncr,
                    gridColor       :props.chart.data.gridColor,
                    data            :chartData.items,
                    colors          :chartData.colors
                    
                });
                barChart.draw();



            break;
            case'doughnut':

                // -- chart stuff -- //

                chartItems = [
                    {
                        name:"Default 1",
                        value:20
                    },
                    {
                        name:"Default 2",
                        value:80
                    }
                ];
                chartColors = [
                    "#07a2e3",
                    "#20b2aa",
                ];

                chartItems = props.chart.data.items || chartItems;
                chartColors = props.chart.data.colors || chartColors;
                  
        
                // -- hole -- //
    
                var holeSize = 0.6;
                var holeColor = "#454d5f";
                var holeOutlineColor = "transparent";

                holeSize = props.chart.data.hole.size || holeSize;
                holeColor = props.chart.data.hole.color || holeColor;
                holeOutlineColor = props.chart.data.hole.outlineColor || holeOutlineColor;
                  


                // -- doughnut -- //

                var doughnutChart = new DoughnutChart({

                    canvas              :canvasObj,
                    data                :chartItems,
                    colors              :chartColors,
                    holeSize            :holeSize,
                    holeColor           :holeColor,
                    holeOutlineColor    :holeOutlineColor

                });
                doughnutChart.draw();

                

            break;
            case'pie':

            
                var pieChart = new PieChart({

                    canvas  :canvasObj,
                    data    :props.chart.data.items,
                    colors  :props.chart.data.colors

                });
                pieChart.draw();
            
            break;
            default:
            }//==
        }
  

    },[
        props,
        coordinates
    ]); 

    return [ setCoordinates ];

}



export default Canv;


