// import { 
//     useSelector, 
//     //useDispatch 
// } 
// from 'react-redux';
// //import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';



//import { Settings } from 'app-components/Prime/Data.js';

import { 

    //VPformTemplateOwnr,
    //VPformTemplateEmpl,
    //VPformTemplateExpense,

    //VPcompanyInfo, 
    VPexpenses,
    VPincome,   
    VPoverhead, 

    VPownrsIndirect,  
    //VPownrs,
    VPownr,

    //VPemplsYearly, 
    //VPemplsMonthly, 
    //VPemplsDirect,
    VPemplsIndirect,
    VPempl


} from 'app-components/Prime/VPdata/form.js';



// import { VPreportDefaults } from 'app-components/Prime/VPdata/reports';


// import { 

//     ChartColorsA, 
//     //ChartColorsB, 
//     //ChartColorsC 

// } from 'utils/components/Charts/ChartColors';


import {
    LibBase, 
    // LibNumber 
} from "library";


// import ApiResources from 'utils/components/Pages/ApiResources';


const Index = (props) => {



    //-------------------------------------------

    // var apiDomain;
    // var hostname;
    // //var hostname = window.location.hostname;//returns the domain name of the web host
    // var protocol = window.location.protocol;//returns the web protocol used (http: or https:)

    // if(window.location.hostname.match(/192.168/gi))
    // {
    //     hostname = ApiResources.network['3001'].vpc;
    //     apiDomain = protocol + "//" + hostname + "/";
    // }
    // else
    // if(window.location.hostname.match(/localhost/gi))
    // {
    //     hostname = ApiResources.local['3001'].vpc;
    //     apiDomain = protocol + "//" + hostname + "/";
    // }else{
    //     hostname = ApiResources.aws['x'].vpc;
    //     apiDomain = "https://" + hostname + "/";
    // }//=

    //-------------------------------------------

    //console.log("API DOMAIN: "+apiDomain);


    //const apiCall = 'alter-calc-test';
    //const apiCall = 'alter-calc-dash';




    // -- redux -- //
    //const dispatch = useDispatch();
    //const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));


    const zoneObj = props.zoneObj;




    var overhead_percent;
    // var overhead_percent_int = 0;
    var owners = {};   
    var employees = {};
    //var expenses = {};

    overhead_percent = 0.0 + "%";



    var index;

    var keyname;
    var kn;
    var item;
    var value;
    var newValue;

    var a,b,c,d;
    var total;
    var grand_total;


    //var chartSections;
    // var object;
    // var percent;
    // var count;

    //var pgraph;


    var obj;

    //var chartColors = ChartColorsA;




    //clear VPoverhead
    for(keyname in VPoverhead)
    {
        if(keyname.match(/expense-/gi))
        {
            delete VPoverhead[keyname];
        }
    }

    var cards_overhead = LibBase.CloneIt(VPoverhead);
    var cards_owners = LibBase.CloneIt(VPownrsIndirect);
    var cards_employees = LibBase.CloneIt(VPemplsIndirect);


    //var templateExpenseForm;
    //var newTemplateExpenseForm;
    


    //var templateEmplForm;
    //var newTemplateEmplForm;
    
    //var template_empl;



    var piePerc;
    var pieSlice;
    var pieChart = {

        items: [ 

            {
                name:"Business",
                value:14
            },
            {
                name:"Facility",
                value:20
            }, 
            {
                name:"Equip. Software",
                value:30
            },      
            {
                name:"Vehicles",
                value:12
            },

            {
                name:"Owners",
                value:5
            },
            {
                name:"Employees",
                value:5
            },


        ],

        colors: [

            "#fde23e",
            "#f16e23", 
            "#57d9ff",
            "#937e88",
            "#20b2aa",
            "#ccff00"

        ],


        hole:{

            percent         :"0.0",
            name            :"Overhead",
            color           :"#181b21",
            outlineColor    :"#181b21",
            textColor       :"white"
        }



    };






    var isOkay;
    isOkay = false;
    if(zoneObj.calc_dash 
    && zoneObj.calc_dash !== undefined
    )
    {
        if(Object.keys(zoneObj.calc_dash.form_items).length > 0
        && Object.keys(zoneObj.calc_dash.result_items).length > 0
        )
        {
            isOkay = true;
        }

    }
    if(isOkay)
    {


        grand_total = zoneObj.calc_dash.result_items['totals'].total;
        grand_total = parseFloat(grand_total);


        //console.log("FORM ITEMS: "+JSON.stringify(zoneObj.calc_dash.form_items));

        index=0;
        for(keyname in zoneObj.calc_dash.form_items)
        {

            // -- owners -- //

            if(keyname.match(/ownr/gi))
            {

                //idl_employees[keyname] = zoneObj.calc_dash.form_items[keyname];

                
                //order card item based on Prime VPdata
                //item = zoneObj.calc_dash.form_items[keyname];
                item = LibBase.CloneIt(zoneObj.calc_dash.form_items[keyname]);//Deep Clone
          
          
                //value = VPowner.value;
                value = LibBase.CloneIt(VPownr.value);//Deep Clone
                newValue = {};
                for(kn in value)
                {
                    if(kn === 'ownr_call') 
                    {
                    }else{
                        newValue[kn] = item.value[kn];       
                    }
                    
                }
                owners[keyname] = {
                    name:item.name,
                    value:newValue
                };
                

            }




            // -- employees -- //

            else
            if(keyname.match(/empl/gi))
            {

                //idl_employees[keyname] = zoneObj.calc_dash.form_items[keyname];

                
                //order card item based on Prime VPdata
                //item = zoneObj.calc_dash.form_items[keyname];
                item = LibBase.CloneIt(zoneObj.calc_dash.form_items[keyname]);//Deep Clone
          
                //value = VPemployee.value;
                value = LibBase.CloneIt(VPempl.value);//Deep Clone
                newValue = {};
                for(kn in value)
                {
                    newValue[kn] = item.value[kn];
                }
                employees[keyname] = {
                    name:item.name,
                    value:newValue
                };
                



            }



            // -- overhead -- //

            else
            {
                
                //console.log("KEYNAME: "+keyname);

                item = LibBase.CloneIt(zoneObj.calc_dash.form_items[keyname]);//Deep Clone
                //console.log(JSON.stringify(item,null,2));


                if(keyname.match(/expense-/gi))
                {

                    // TM: order inputs here



                    // -- VPoverhead -- //

                    if(item.order)
                    {
                        newValue = {};
                        for(obj of item.order)
                        {
                            //console.log("order:  "+obj);
                            for(kn in item.value)
                            {
                                //console.log("kn:  "+kn);
                                if(obj === kn)
                                {
                                    //console.log("match: "+kn);
                                    newValue[kn] = item.value[kn];
                                }
                            }

                        }
                        //console.log(JSON.stringify(newValue,null,2));

                        VPoverhead[keyname] = {
                            name:item.name,
                            value:newValue,
                            order:item.order
                        };


                    }else{

                        VPoverhead[keyname] = {
                            name:item.name,
                            value:item.value
            
                        };

                    }






                    // -- card_overhead -- //

                    value = LibBase.CloneIt(item.value);//Deep Clone

                    if(item.order)
                    {
                        newValue = {};
                        for(obj of item.order)
                        {
                            //console.log("order:  "+obj);
                            for(kn in item.value)
                            {
                                //console.log("kn:  "+kn);
                                if(obj === kn)
                                {
                                    //console.log("match: "+kn);
                                    newValue[kn] = item.value[kn];
                                }
                            }

                        }
                        //console.log(JSON.stringify(newValue,null,2));


                        total = 0;
                        for(kn in newValue)
                        {
                            obj = newValue[kn];
                            total = parseFloat(total) + parseFloat(obj.value);
      
                        }
                        total = parseFloat(total);
                        total = total.toFixed(2);
                        // console.log("TOTALLLLLLLLLLLLL "+total);
                        newValue["total"] = total;
                        newValue["total_percent"] = 0;
    
                        cards_overhead[keyname] = {
                            name:item.name,
                            value:newValue,
                            order:item.order
                        };
    

                    }else{

                        total = 0;
                        for(kn in value)
                        {
                            obj = value[kn];
                            total = parseFloat(total) + parseFloat(obj.value);
      
                        }
                        total = parseFloat(total);
                        total = total.toFixed(2);
                        // console.log("TOTALLLLLLLLLLLLL "+total);
                        value["total"] = total;
                        value["total_percent"] = 0;
    
                        cards_overhead[keyname] = {
                            name:item.name,
                            value:value
                        };
    
                    }





                }else{

                    //value = VPoverhead[keyname].value;
                    value = LibBase.CloneIt(VPoverhead[keyname].value);//Deep Clone
                    newValue = {};
                    for(kn in value)
                    {
                        newValue[kn] = item.value[kn];
                    }

                    VPoverhead[keyname] = {
                        name:item.name,
                        value:newValue
                    };

                    cards_overhead[keyname] = {

                        name:item.name,
                        value:newValue

                    };                

                }


        
                // -- set totals for cards -- //

                if(keyname.match(/expense-/gi))
                {
                    total = 0;
                    newValue = 0;
                    for(kn in item.value)
                    {
                        //console.log(value[kn]);
                        if(item.value[kn] !== undefined)
                        {
                            newValue = item.value[kn].value;
                            if(!isNaN(newValue)) parseFloat(newValue);
                            else newValue = 0;
                            total = parseFloat(total) + parseFloat(newValue); 
                        }
    
                    }
                    total = parseFloat(total);        
                    total = total.toFixed(2);
                

                    piePerc = ( parseFloat(total)/parseFloat(grand_total) ) * 100;
                    if(isNaN(piePerc) || grand_total === 0) piePerc = 0;
                    piePerc = piePerc.toFixed(1);
                    piePerc = parseFloat(piePerc);



                    cards_overhead[keyname].value['total_percent'] = {
    
                        name: "Total Monthly (Overhead %)",
                        default: 0,
                        print: "percent",
                        value: piePerc,
                        required: true
    
                    };
                    cards_overhead[keyname].value['total'] = {
    
                        name: "Total Monthly (Overhead $)",
                        default: 0,
                        print: "price",
                        value: total,
                        required: true
    
                    };


                    // -- set pieChart data -- //

                    for(pieSlice of pieChart.items)
                    {
                        if(pieSlice.value !== piePerc)
                        {
                            pieChart.items[index].name = item.name;      
                            pieChart.items[index].value = piePerc;
                            break;
            
                        }
                       
                    }
                    index++;


                }//==




            }

        }// -- for





        //VPexpenses.value.monthly_expenses.value = grand_total;
        VPexpenses.value.monthly_expenses.value = grand_total;
        VPincome.value.projected_monthly_sales.value = zoneObj.calc_dash.form_items['settings'].value.projected_monthly_sales.value;


        overhead_percent = zoneObj.calc_dash.result_items['overhead'].overhead;
        overhead_percent = parseFloat(overhead_percent); 

        //overhead_percent_int = overhead_percent.toFixed(1);

        pieChart.hole.percent = overhead_percent.toFixed(1);

        overhead_percent = overhead_percent.toFixed(1) + "%";




        //console.log("IDL EMPLOYEES: "+JSON.stringify(idl_employees,null,2));

    
        /*
        for(keyname in zoneObj.calc_dash.result_items)
        {

            console.log("KEYNAME: "+keyname);

            item = zoneObj.calc_dash.result_items[keyname];
            console.log(JSON.stringify(item,null,2));

        
        }// -- for
        */





        //------------------------------------------
        // -- owners - totals - cards 
        //------------------------------------------

        //var total_ownr_salary_pretax_yearly_indirect
        var total_ownr_salary_pretax_monthly_indirect;
        var total_ownr_health_insurance_monthly;
        var total_ownr_ira_monthly_indirect;
        var total_ownr_taxes_monthly_indirect;  

        total_ownr_salary_pretax_monthly_indirect = zoneObj.calc_dash.result_items['totals'].total_ownr_salary_pretax_monthly_indirect;
        total_ownr_health_insurance_monthly = zoneObj.calc_dash.result_items['totals'].total_ownr_health_insurance_monthly;
        total_ownr_ira_monthly_indirect = zoneObj.calc_dash.result_items['totals'].total_ownr_ira_monthly_indirect;
        total_ownr_taxes_monthly_indirect = zoneObj.calc_dash.result_items['totals'].total_ownr_taxes_monthly_indirect;  

        //total_ownr_salary_pretax_yearly_indirect = parseFloat(total_ownr_salary_pretax_monthly_indirect) * 12;


        //if(isNaN(total_ownr_salary_pretax_yearly_indirect)) total_ownr_salary_pretax_yearly_indirect = 0;
        if(isNaN(total_ownr_salary_pretax_monthly_indirect)) total_ownr_salary_pretax_monthly_indirect = 0;
        if(isNaN(total_ownr_health_insurance_monthly)) total_ownr_health_insurance_monthly = 0;
        if(isNaN(total_ownr_ira_monthly_indirect)) total_ownr_ira_monthly_indirect = 0;
        if(isNaN(total_ownr_taxes_monthly_indirect)) total_ownr_taxes_monthly_indirect = 0;


        //cards_owners.value.ownr_salary_pretax_yearly_indirect.value = total_ownr_salary_pretax_yearly_indirect;
        cards_owners.value.ownr_salary_pretax_monthly_indirect.value = total_ownr_salary_pretax_monthly_indirect;
        cards_owners.value.ownr_health_insurance_monthly.value = total_ownr_health_insurance_monthly;
        cards_owners.value.ownr_ira_monthly_indirect.value = total_ownr_ira_monthly_indirect;
        cards_owners.value.ownr_taxes_monthly_indirect.value = total_ownr_taxes_monthly_indirect;



        // -- card total - indirect labor -- //

        total = 0;
        if(total_ownr_salary_pretax_monthly_indirect !== undefined
        && total_ownr_health_insurance_monthly !== undefined 
        && total_ownr_ira_monthly_indirect !== undefined
        && total_ownr_taxes_monthly_indirect !== undefined
        )
        {
            a = total_ownr_salary_pretax_monthly_indirect;
            b = total_ownr_health_insurance_monthly;
            c = total_ownr_ira_monthly_indirect;
            d = total_ownr_taxes_monthly_indirect;

            total = parseFloat(a) + parseFloat(b) + parseFloat(c) + parseFloat(d);

        }

        piePerc = ( parseFloat(total)/parseFloat(grand_total) ) * 100;
        if(isNaN(piePerc)) piePerc = 0;
        piePerc = piePerc.toFixed(1);
        piePerc = parseFloat(piePerc);

        cards_owners.value['total_percent'] = {
    
            name: "Total Monthly (Overhead %)",
            default: 0,
            print: "percent",
            value: piePerc,
            required: true

        };
        cards_owners.value['total'] = {

            name: "Total Monthly (Overhead $)",
            default: 0,
            print: "price",
            value: total,
            required: true

        };

        // -- set pieChart data -- //

        pieSlice = pieChart.items[pieChart.items.length-2];
        if(pieSlice.value !== piePerc)
        {
            pieSlice.name = "Owners";      
            pieSlice.value = piePerc;

        }
        








        //------------------------------------------
        // -- employees - totals - cards 
        //------------------------------------------

        //var total_empl_indirect_salary_yearly;
        var total_empl_indirect_salary_monthly;
        var total_empl_indirect_tax_monthly;
        var total_empl_indirect_ira_monthly;
        var total_empl_health_insurance_monthly;

        total_empl_indirect_salary_monthly = zoneObj.calc_dash.result_items['totals'].total_empl_indirect_salary_monthly;
        total_empl_indirect_tax_monthly = zoneObj.calc_dash.result_items['totals'].total_empl_indirect_tax_monthly;
        total_empl_indirect_ira_monthly = zoneObj.calc_dash.result_items['totals'].total_empl_indirect_ira_monthly;
        total_empl_health_insurance_monthly = zoneObj.calc_dash.result_items['totals'].total_empl_health_insurance_monthly;
   
        //total_empl_indirect_salary_yearly = parseFloat(total_empl_indirect_salary_monthly) * 12;

        //if(isNaN(total_empl_indirect_salary_yearly)) total_empl_indirect_salary_yearly = 0;
        if(isNaN(total_empl_indirect_salary_monthly)) total_empl_indirect_salary_monthly = 0;
        if(isNaN(total_empl_indirect_tax_monthly)) total_empl_indirect_tax_monthly = 0;
        if(isNaN(total_empl_indirect_ira_monthly)) total_empl_indirect_ira_monthly = 0;
        if(isNaN(total_empl_health_insurance_monthly)) total_empl_health_insurance_monthly = 0;
     
        //cards_employees.value.empl_indirect_salary_yearly.value = total_empl_indirect_salary_yearly;
        cards_employees.value.empl_indirect_salary_monthly.value = total_empl_indirect_salary_monthly;
        cards_employees.value.empl_indirect_tax_monthly.value = total_empl_indirect_tax_monthly;      
        cards_employees.value.empl_indirect_ira_monthly.value = total_empl_indirect_ira_monthly;
        cards_employees.value.empl_health_insurance_monthly.value = total_empl_health_insurance_monthly;







        // -- card total - indirect labor -- //

        total = 0;
        if(total_empl_indirect_salary_monthly !== undefined
        && total_empl_indirect_ira_monthly !== undefined
        && total_empl_indirect_tax_monthly !== undefined        
        && total_empl_health_insurance_monthly !== undefined 
        )
        {
            a = total_empl_indirect_salary_monthly;
            b = total_empl_indirect_ira_monthly;
            c = total_empl_indirect_tax_monthly;        
            d = total_empl_health_insurance_monthly;

            total = parseFloat(a) + parseFloat(b) + parseFloat(c) + parseFloat(d);

        }

        piePerc = ( parseFloat(total)/parseFloat(grand_total) ) * 100;
        if(isNaN(piePerc)) piePerc = 0;
        piePerc = piePerc.toFixed(1);
        piePerc = parseFloat(piePerc);

        cards_employees.value['total_percent'] = {
    
            name: "Total Monthly (Overhead %)",
            default: 0,
            print: "percent",
            value: piePerc,
            required: true

        };
        cards_employees.value['total'] = {

            name: "Total Monthly (Overhead $)",
            default: 0,
            print: "price",
            value: total,
            required: true

        };

        // -- set pieChart data -- //

        pieSlice = pieChart.items[pieChart.items.length-1];
        if(pieSlice.value !== piePerc)
        {
            pieSlice.name = "Employees";      
            pieSlice.value = piePerc;

        }
        


    }// -- if

    //console.log("pieChart: "+JSON.stringify(pieChart,null,2));

    //_overhead = VPoverhead;
    //console.log("_overhead B: "+JSON.stringify(_overhead,null,2));


    //console.log("VPoverhead: "+JSON.stringify(VPoverhead,null,2));
    //console.log("cards_overhead: "+JSON.stringify(cards_overhead,null,2));







    //#############################################################
    //#############################################################

 
    // "hours": {
    //     "direct": {
    //       "total_ownr_daily": 4.8,
    //       "grand_total_daily": 9.8,
    //       "total_ownr_weekly": 24,
    //       "total_empl_daily": 5,
    //       "total_empl_weekly": 20,
    //       "grand_total_weekly": 44
    //     }
    // },


    var grand_total_daily = "0";  
    var grand_total_weekly = "0";

    if(zoneObj.calc_dash)
    {
        grand_total_daily  = zoneObj.calc_dash.result_items['hours']['direct'].grand_total_daily;
        grand_total_weekly = zoneObj.calc_dash.result_items['hours']['direct'].grand_total_weekly;

    }//==

    grand_total_daily = parseFloat(grand_total_daily).toFixed(1)+"hrs";  
    grand_total_weekly = parseFloat(grand_total_weekly).toFixed(1)+"hrs";



    var R = [];


    // var items = [];

    // keyname = 'Direct_Labor_Hours'; // "empl";

    // if(VPreportDefaults && VPreportDefaults !== undefined)
    // {
    //     for(item of VPreportDefaults[keyname])
    //     {
    //         items.push(item);
    //     }

    // }
    // //console.log("items::::::"+JSON.stringify(items,null,2));



    var reportItems = [



        //-----------------------------------
        // ReportBox - Graphic and Big Txt
        //-----------------------------------

        {

            "print"		    :'bar',
            "name"		    :'Labor Hours',
            "value"		    :"",
            "required"	    :"",
            "default"	    :""


        },
        {

            "print"		    :'report_box',
            "name"		    :'Report Box',
            "value"		    :[

                {

                    boxCall:'graphic',
                    boxWidth:"40%",
                    boxHeight:230,

                    svgCall:"clock",
                    svgWidth:230,
                    svgHeight:230,

                },

                {

                    boxCall:'txt',
                    boxWidth:"55%",
                    boxHeight:230,
                    value:[

                        {
                            className:"XLarge",
                            txt:grand_total_daily,
                            style:{

                                //fontSize:"60px",
                                textAlign:"center"

                            }
                        },



                        {
                            className:"Note",
                            txt:"***NOTE This number reflects minimum billing hours daily",
                            style:{

                                //fontSize:"13px",
                                textAlign:"center"

                            }
                        }


                    ]


                }




            ],
            "required"	    :"",
            "default"	    :""


        },
        {

            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{

                backgroundColor:"transparent",
                height:"0px"
            },
            "required"	    :"",
            "default"	    :""


        },





        //-----------------------------------
        // Ledger
        //-----------------------------------

        // {

        //     "print"		    :'bar',
        //     "name"		    :'Owner Labor Rates',
        //     "value"		    :"",
        //     "required"	    :"",
        //     "default"	    :""


        // },
        {

            "print"		    :'ledger',
            "name"		    :"Ledger",
            "value"		    :[


                {
                    name:"Daily",
                    value:grand_total_daily
                },
                {
                    name:"Weekly",
                    value:grand_total_weekly
                }

            ],
            "required"	    :"",
            "default"	    :""


        },

        {

            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{

                backgroundColor:"transparent",
                height:"50px"
            },
            "required"	    :"",
            "default"	    :""


        },








        


        //-----------------------------------
        // Txt
        //-----------------------------------

        // {

        //     "print"		    :'bar',
        //     "name"		    :'Labor Hours',
        //     "value"		    :"",
        //     "required"	    :"",
        //     "default"	    :""


        // },
        {

            "print"		    :'txt',
            "name"		    :'Txt',
            "value"		    :[

                {

                    // boxCall:'txt',
                    // boxWidth:"100%",
                    // boxHeight:230,

                    className:"Note",
                    txt:'***NOTE: Use "Labor Hours" to determine your "Shop Rate"',
                    style:{

                        //fontSize:"24px",
                        textAlign:"center"

                    }

                }

            ],
            "required"	    :"",
            "default"	    :""


        },
        {

            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{

                backgroundColor:"transparent",
                height:"50px"
            },
            "required"	    :"",
            "default"	    :""


        },




    ];




    R.push({

        call:'report',
        items: reportItems

    });
        
    return R;

}



export default Index;
