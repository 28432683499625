import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';


import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';



import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    useHistory,   
    //useParams,



} from "react-router-dom";


import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'


import {

    Catalog,

        CatalogTop,
            CatalogTopName,
            CatalogTopControls,

        CatalogBar,
        CatalogBulletBar,
        CatalogMessage,
        CatalogPrint,
        CatalogSection,
        CatalogBox,

        CatalogLineItem,
        CatalogCalcItem,
        CatalogItem,
 
            CatalogItemName,
            CatalogItemValue,

            CatalogItemLineName,
            CatalogItemControls,

            CatalogItemBullet,       
            CatalogItemBulletLineName,


        CatalogRow,
 
            CatalogRowBullet,
            CatalogRowLine,        
            //CatalogRowName,


        // CatalogTile,
        // CatalogTileDark,
        // CatalogTileCalc,
        // CatalogTileTemplate,
        CatalogTileGlossary



} from 'utils/components/Catalog/Styled';





// -- Charts -- //

import CanvasChart from 'utils/components/Charts/CanvasChart';

import { 

    HrzBarChart, 
    VrtBarChart,

    StaticDonutChart

} from 'utils/components/Charts/CssChart';


import { 

    SvgDonutChart 

} from 'utils/components/Charts/SvgChart';


import { 

    Gauge1,
    Gauge2,
    Gauge3,
    Gauge4,
    TemperatureGauge

} from 'utils/components/Charts/Gauge';


// -- local comps -- //

import ActComp from 'utils/components/Report/Act';
import BloxComp from 'utils/components/Report/Blox';
import GraphicComp from 'utils/components/Report/Graphic';
import LedgerComp from 'utils/components/Report/Ledger';
import TxtComp from 'utils/components/Report/Txt';
import DonutChartComp from 'utils/components/Report/DonutChart';
import CanvasChartComp from 'utils/components/Report/CanvasChart';


import SVGChart from 'grfx/chart.svg';
import SVGChartOver from 'grfx/chart_over.svg';

import SVGPencil from 'grfx/pencil.svg';
import SVGPencilOver from 'grfx/pencil_over.svg';

import SVGTrash from 'grfx/trash.svg';
import SVGTrashOver from 'grfx/trash_over.svg';


const Index = (props) => {




    // -- window dims -- //
    const { 
        width,
        //height 
    } = useWindowDimensions();




    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    const history = useHistory();

    
 
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);





    // -- redux --//
    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));







    //=============================================
    // refs
    //=============================================

    const [rowRefs] = useState([]);
    const setRowRef = (ref) => {

        if(ref && ref !== null && ref !== undefined)
        {
            //console.log("ref::::"+ref.id);
            var found;
            found = false;
            for(var i=0; i < rowRefs.length; i++)
            {
                if(rowRefs[i].id === ref.id) found = true;
            }
            if(!found) rowRefs.push(ref);
            //console.log(boxRefs.length);

        }

    };

    // -- ref -- //
    const refA = useRef(null);



    //=============================================
    // state/effect - isLoaded
    //=============================================

    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);
    //const [isFlyr, setIsFlyr] = useState(false);
    const [catalogData, setCatalogData] = useState([]);
    const [catalogStyl, setCatalogStyl] = useState({

        width:false,
        margin:false

    });


    useEffect(() => {

        if(!isLoaded)
        {
            setIsLoaded(true);

        }else{


            var hasItems = false;
            if(props.refid && props.refid !== undefined)
            {
                if(props.refid === 'flyr')
                {
                    //setIsFlyr(true);
                    //hasItems = true;

                    setCatalogStyl({
                        width:"95%",
                        margin:"25px 0 0 2.5%"
                    });


                }
                else
                if(props.refid.match(/pagerow/gi))
                {
                    hasItems = true;

                    setCatalogStyl({
                        width:"95%",
                        margin:"25px 0 0 2.5%"
                    });

                }

            }

        
            if(hasItems)
            {

                if(Object.prototype.toString.call(props.items) === '[object Array]')
                {
                    // console.log("Flyr Report - items; "+JSON.stringify(props.items,null,2));
                    // alert('flyr')

                    setCatalogData(props.items);

                }

            }
            else
            {

                if(Object.prototype.toString.call(props.data) === '[object Array]')
                {
                    setCatalogData(props.data);

                }

            }

            

            if(props.refid === 'flyr')
            {

                //console.log("REPORT H: "+refA.current.offsetHeight);
                props.handle({

                    call:"adjustContentsHeight",
                    data:{

                        ref:refA,
                        h:refA.current.offsetHeight

                    }

                })


 
            // }else{


            //     if(Object.prototype.toString.call(props.handle) === '[object Function]')
            //     {
            //         props.handle({

            //             call:"contentHeight",
            //             data:{

            //                 ref:refA,
            //                 contentHeight:refA.current.offsetHeight

            //             }

            //         })

            //     }

            }

        }



        //console.log("Catalog - props: "+JSON.stringify(props,null,2));





    },
    [
        props,
        isLoaded,
        refA,
        catalogData

    ])




    //=============================================
    // state/effect - isAdminUser
    //=============================================

    const [isAdminUser, setAdminUser] = useState(false);

    useEffect(() => {

        if(!isLoaded)
        {
            if(zoneObj['auth_account'] 
            && zoneObj['auth_account'] !== undefined)
            {

                setAdminUser(false);

                // //console.log("auth_account -- "+JSON.stringify(zoneObj['auth_account'],null,2));
                // if(zoneObj['auth_account'].auth 
                // && zoneObj['auth_account'].auth !== undefined)
                // {
                //     if(zoneObj['auth_account'].auth.accountId === 'templates')
                //     {
                //         setAdminUser(true);
                //     }else{
                //         setAdminUser(false);
                //     }
    
                // }
            }

        }

    },
    [
        isLoaded,
        zoneObj
    ])







    //=============================================
    // state/effect - width
    //=============================================

    const [lessThanThresh, setLessThanThresh] = useState(false);

    useEffect(() => {

        if(isLoaded)
        {
            //if(width < 550 || props.refid === 'flyr')
            if(width < 550)
            {
                setLessThanThresh(true);
            }else{
                setLessThanThresh(false);
            }

        }

    },
    [
        props,
        isLoaded,
        width
    ])

    







    //=============================================
    // handle - over out
    //=============================================

    const handleOverOut = (hndl) => {


        switch(hndl.from)
        {
        case'controls':


            //console.log("controls");

            var row_id;
            var buttonName;
            var btn;
            var img;



            var i;
            var found;
            found = false;
            if(rowRefs.length > 0)
            {
                for(i=0; i < rowRefs.length; i++)
                {
                    if(rowRefs[i].id === "row"+hndl.indx)
                    {
                        found = true;
                        break;
                    }
                }
            
            }

            if(found)
            {
                row_id ="row"+hndl.indx;
                buttonName = hndl.buttonName;

                //controls = document.getElementById(hndl.id+"_controls");
                btn = document.getElementById(row_id+"_controls_"+buttonName);
                img = document.getElementById(row_id+"_controls_"+buttonName+"_img");

                switch(hndl.call)
                {
                case'mouseEnter':
                case'mouseOver':

                    btn.style.backgroundColor = 'transparent';


                    if(buttonName.match(/chart/gi)
                    ) 
                    {
                        img.src = SVGChartOver;
                    } 
                    else
                    if(buttonName.match(/trash/gi)
                    ) 
                    {
                        img.src = SVGTrashOver;
                    } 
                    else
                    {
                        img.src = SVGPencilOver;
                    }
  

                break;
                case'mouseOut':

                    btn.style.backgroundColor = 'transparent';

                    if(buttonName.match(/chart/gi)
                    ) 
                    {
                        img.src = SVGChart;
                    }
                    else
                    if(buttonName.match(/trash/gi)
                    ) 
                    {
                        img.src = SVGTrash;
                    } 
                    else
                    {
                        img.src = SVGPencil;
                    }
                break;
                default:
                }

            }//==



        break;
        case'button':


            //console.log(JSON.stringify(hndl,null,2));

            row_id = "row"+hndl.indx;
            buttonName = hndl.buttonName;

            btn = document.getElementById(row_id+"_"+buttonName);
            btn.style.backgroundColor = hndl.backgroundColor;
            btn.style.border = hndl.border;
            btn.style.color = hndl.fontColor;
            btn.style.cursor = 'pointer';


            /*
        
            switch(hndl.call)
            {
            case'mouseEnter':
            case'mouseOver':

                btn.style.backgroundColor = hndl.backgroundColor;
                btn.style.border = hndl.border;
                btn.style.fontColor = hndl.fontColor;
            break;
            case'mouseOut':

                btn.style.backgroundColor = hndl.backgroundColor;
                btn.style.border = hndl.border;
                btn.style.fontColor = hndl.fontColor;
            break;
            default:
            }

            */


        break;
        default:
        }



    }




    //=============================================
    // handleUrl
    //=============================================

    const handleUrl = (hndl) => {

        var url;
        switch(hndl.call)
        {
        case'push':

            //alert(JSON.stringify(hndl.data));
            url = hndl.data.category;
        break;
        default:
            url = hndl.data;
        }
        //alert(url);

        url = url.replace(/\//gi,"");
        url = "/" +pathname + "/" +url+ "/";
        history.push(url);

    }





    

    return (


        <Catalog
        ref={refA}
        //backgroundColor={"lightgreen"}
        width={catalogStyl.width}
        margin={catalogStyl.margin}
        >

            {catalogData.map((row, i) => (



                // <div
                // key={i}
                // style={{

                //     backgroundColor :"lightblue",
                //     width           :"100%",
                //     height          :"auto",
                //     margin          :"0 0 50px 0",
                //     padding         :"0 0 0 0",
                //     position        :"relative",
                //     float           :"left",
                //     fontSize        :"12px",
                //     color           :"black"
                // }}
                // >
                //     <pre>
                //     {

                //         JSON.stringify(row,null,2)

                //     }
                //     </pre>



                // </div>



                <div
                key={i}
                >
                    {

                    //######################################################
                    //######################################################
                    //top

                    row.call === 'top'
                    ?

                        <CatalogTop>

                            <CatalogTopName>

                                {row.name}

                            </CatalogTopName>



                            {
                            isAdminUser
                            ?
                                <CatalogTopControls>

                                    {row.controls.map((itm, j) => (

                                        <div key={i+"-"+j}>

                                            <div className="Button"
                                            onClick={() => handleUrl({

                                                //primeHandle : (v) => props.handle(v),
                                            
                                                call        :'url',
                                                data        :"Calcs"
                                            
                                            })}
                                            >

                                                {itm.name}

                                            </div>

                                        </div>

                                    ))}

                                </CatalogTopControls>

                            :


                                <CatalogTopControls>

                                    {row.controls.map((itm, j) => (

                                        <div key={i+"-"+j}>

                                            <div className="Button"
                                            onClick={() => props.handle({

                                                //primeHandle : (v) => props.handle(v),
                                            
                                                call        :'showFlyr',
                                                data        :itm.form
                                            
                                            })}
                                            >

                                                {itm.name}

                                            </div>

                                        </div>

                                    ))}

                                </CatalogTopControls>


                            }



                        </CatalogTop>

                    :
                    //######################################################
                    //######################################################
                    //bar

                    row.call === 'bar'
                    ?

                        <CatalogBar>

                            <div className="Name">

                                {row.items[0].name}

                            </div>



                            {row.items[1] !== undefined
                            ?

                                <div className="Actions">

                                    {row.items[1].name}

                                </div>


                            :null}



                        </CatalogBar>



                    :
                    //######################################################
                    //######################################################
                    //bulletBar

                    row.call === 'barNameValue'
                    ?

                        <CatalogBar
                        nameWidth={"calc(40% - 10px)"}
                        valueWidth={"calc(59% - 11px)"}
                        >

                            <div className="Name">

                                {row.items[0].name}

                            </div>

                            <div className="Value">

                                {row.items[1].name}

                            </div>

                        </CatalogBar>



                    :
                    //######################################################
                    //######################################################
                    //barLineItem

                    row.call === 'barLineItem'
                    ?

                        <CatalogBar>

                            <div className="LineName">

                                {row.items[0].name}

                            </div>

                            {/* <div className="Actions">

                                {row.items[1].name}

                            </div> */}

                        </CatalogBar>



                    :
                    //######################################################
                    //######################################################
                    //bulletBar

                    row.call === 'bulletBar'
                    ?

                        <CatalogBulletBar
                        //bulletColor={"red"}
                        >
                            <div className="Bullet">

                                <div className="Circle"></div>

                            </div>

                            <div className="LineName">

                                {row.items[0].name}

                            </div>


                            {row.items[1]
                            ?

                                <div className="Actions">

                                    {row.items[1].name}

                                </div>


                            :null}

                        </CatalogBulletBar>




                    :
                    //######################################################
                    //######################################################
                    //calcBar

                    row.call === 'barNameGrid'
                    ?

                        <CatalogBar
                        nameWidth={"calc(40% - 15px)"}
                        gridWidth={"calc(50% - 0px)"}
                        >

                            <div className="Name">

                                {row.items[0].name}

                            </div>

                            <div className="Grid">

                            
                                <div className="CellLeft">

                                    {row.items[1].name}

                                </div>

                                <div className="CellRight">

                                    {row.items[2].name}

                                </div>


                            </div>


                        </CatalogBar>



                    :
                    //######################################################
                    //######################################################
                    //spacer
                    
                    row.call === 'spacer'
                    ?

                        <CatalogSection
                        spacerBackgroundColor={row.value.backgroundColor}
                        spacerHeight={row.value.height}
                        >

                            <div className={"Spacer"}></div>

                        </CatalogSection>

                    

                    :
                    //######################################################
                    //######################################################
                    //print
                    
                    row.call === 'print'
                    ?

                        <CatalogPrint>

                            <pre>

                                {row.value}

                            </pre>

                        </CatalogPrint>

                    
                    :
                    //######################################################
                    //######################################################
                    //items
                    
                    row.call === 'items'
                    ?


                        row.items.length === 0
                        ?

                            <CatalogMessage>

                                {"No Items Found"}

                            </CatalogMessage>

                        :


                            row.items.map((itm, j) => (

                                <div 
                                ref={setRowRef}                              
                                key={i+"-"+j}
                                id={"row"+i+"-"+j}
                                >

                                    <CatalogItem>

                                        {
                                        itm.bullet
                                        ?
        
                                            <div>

                                                <CatalogItemBullet
                                                bulletColor={itm.bullet}
                                                >
                                                    <div className="Circle"></div>

                                                </CatalogItemBullet>


                                                <CatalogItemBulletLineName>

                                                    <div className="Text"
                                                    onClick={() => props.handle({

                                                        call        :'showFlyr',
                                                        //data        :itm.controls.chart
                                                        data        :itm.controls.report
                                    
                                                    })}
                                                    >

                                                        {itm.heading}

                                                    </div>

                                                </CatalogItemBulletLineName>


                                            </div>


                                        :

                                            <CatalogItemLineName>

                                                <div className="Text"
                                                onClick={() => props.handle({

                                                    call        :'showFlyr',
                                                    //data        :itm.controls.chart
                                                    data        :itm.controls.report
                                
                                                })}
                                                >

                                                    {itm.heading}

                                                </div>

                                            </CatalogItemLineName>

                                        }


                                        <CatalogItemControls>

                                            <div 
                                            className="Button"
                                            id={"row"+i+"-"+j+"_controls_chartButton"}
                                            >


                                                <div 
                                                className="ButtonMask"
                                                id={"row"+i+"-"+j+"_controls_chartButton_mask"}

                                                    // onClick={() => props.handle({

                                                    //     call        :'showFlyr',
                                                    //     data        :itm.controls.chart
                                    
                                                    // })}
                                                    onClick={() => props.handle({

                                                        call        :'showFlyr',
                                                        data        :itm.controls.report
                                    
                                                    })}
                                                    onMouseEnter={() => handleOverOut({

                                                        indx        :i+"-"+j,   
                                                        call        :'mouseEnter',
                                                        from        :'controls',
                                                        buttonName  :"chartButton"
                
                                                    })}
                                                    onMouseOut={() => handleOverOut({
                
                                                        indx        :i+"-"+j,       
                                                        call        :'mouseOut',
                                                        from        :'controls',
                                                        buttonName  :'chartButton'
                
                                                    })}

                                                >
                                                </div>


                                                <img
                                                id={"row"+i+"-"+j+"_controls_chartButton_img"}
                                                className="Img"
                                                src={SVGChart} 
                                                alt="delete" />


                                            </div>




                                            <div 
                                            className="Button"
                                            id={"row"+i+"-"+j+"_controls_pencilButton"}
                                            >

                                                <div 
                                                className="ButtonMask"
                                                id={"row"+i+"-"+j+"_controls_pencilButton_mask"}

                                                    onClick={() => props.handle({

                                                        call        :'showFlyr',
                                                        data        :itm.controls.form
                                    
                                                    })}
                                                    onMouseEnter={() => handleOverOut({

                                                        indx        :i+"-"+j,   
                                                        call        :'mouseEnter',
                                                        from        :'controls',
                                                        buttonName  :"pencilButton"
                
                                                    })}
                                                    onMouseOut={() => handleOverOut({
                
                                                        indx        :i+"-"+j,        
                                                        call        :'mouseOut',
                                                        from        :'controls',
                                                        buttonName  :'pencilButton'
                
                                                    })}

                                                >
                                                </div>


                                                <img
                                                id={"row"+i+"-"+j+"_controls_pencilButton_img"}
                                                className="Img"
                                                src={SVGPencil} 
                                                alt="delete" />


                                            </div>




                                            <div 
                                            className="Button"
                                            id={"row"+i+"-"+j+"_controls_trashButton"}
                                            style={{

                                                padding:"0 0 0 0"
                                            }}
                                            >


                                                <div 
                                                className="ButtonMask"
                                                id={"row"+i+"-"+j+"_controls_trashButton_mask"}

                                                    onClick={() => props.handle({

                                                        call        :'showConfirm',
                                                        data        :itm.controls.confirm
                                    
                                                    })}
                                                    onMouseEnter={() => handleOverOut({

                                                        indx        :i+"-"+j,    
                                                        call        :'mouseEnter',
                                                        from        :'controls',
                                                        buttonName  :"trashButton"
                
                                                    })}
                                                    onMouseOut={() => handleOverOut({
                
                                                        indx        :i+"-"+j,       
                                                        call        :'mouseOut',
                                                        from        :'controls',
                                                        buttonName  :'trashButton'
                
                                                    })}

                                                >
                                                </div>


                                                <img
                                                id={"row"+i+"-"+j+"_controls_trashButton_img"}
                                                className="Img"
                                                src={SVGTrash} 
                                                alt="delete" />


                                            </div>

                                        </CatalogItemControls>   


                                    </CatalogItem>

                                </div>

                            ))




                    :
                    //######################################################
                    //######################################################
                    //itemsNameValue

                    row.call === 'itemsNameValue'
                    ?

                        row.items.map((itm, j) => (

                            <div 
                            ref={setRowRef}                              
                            key={i+"-"+j}
                            id={"row"+i+"-"+j}
                            >

                                <CatalogItem>

                                    {
                                    itm.button
                                    ?
                    
                                        <CatalogItemName
                                        width={"calc(40% - 0px)"}
                                        onClick={() => props.handle({

                                            call        :'showFlyr',
                                            data        :itm.button
                        
                                        })}
                                        >
                                            <div className="LinkText"
                                            >

                                                {itm.heading}

                                            </div>

                                        </CatalogItemName>



                                    :


                                        <CatalogItemName
                                        width={"calc(40% - 0px)"}
                                        >
                                            <div className="PlainText"
                                            >

                                                {itm.heading}

                                            </div>

                                        </CatalogItemName>

                                    }




                                    {
                                    itm.button
                                    ?
                    
                                        <CatalogItemValue
                                        width={"calc(59% - 1px)"}
                                        >
                                            <div className="ValueText"
                                            >
                                                {
                                                itm.heading.toLowerCase() === 'password'
                                                ?
                                                    "Not Shown"
                                                :
                                                    itm.pgraph
                                                }

                                            </div>


                                            <div className="Button"
                                            onClick={() => props.handle({

                                                call        :'showFlyr',
                                                data        :itm.button
                            
                                            })}
                                            >
                                                {"Edit"}

                                            </div>

                                        </CatalogItemValue>



                                    :


                                        <CatalogItemValue
                                        width={"calc(59% - 1px)"}
                                        >
                                            <div className="PlainText"
                                            >
                                                {itm.pgraph}

                                            </div>

                                        </CatalogItemValue>

                                    }

                                </CatalogItem>

                            </div>

                        ))


                    

                    :
                    //######################################################
                    //######################################################
                    //itemsLine

                    row.call === 'itemsLineItem'
                    ?


                        row.items.map((itm, j) => (

                            <div 
                            ref={setRowRef}                              
                            key={i+"-"+j}
                            id={"row"+i+"-"+j}
                            >

                                <CatalogLineItem
                                onClick={() => handleUrl({

                                    call        :'push',
                                    data        :itm
                
                                })}
                                >

                                    {itm.category}

                                </CatalogLineItem>


                            </div>

                        ))




                    :
                    //######################################################
                    //######################################################
                    //calcItem

                    row.call === 'calcItem'
                    ?


                        row.items.map((itm, j) => (

                            <div 
                            ref={setRowRef}                              
                            key={i+"-"+j}
                            id={"row"+i+"-"+j}
                            >

                                <CatalogCalcItem
                                onClick={() => handleUrl({

                                    call        :'push',
                                    data        :itm
                
                                })}
                                >
                                    <div className="Name">

                                        {itm.category}

                                    </div>

                                    <div className="Grid">

                                   
                                        <div className="Left">

                                            {itm.price}

                                        </div>

                                        {/* <div className="Right">

                                            {itm.profit}

                                        </div> */}


                                        <div className="Right">

                                            <div className="Price">

                                                {itm.profit}

                                            </div>

                                            <div className="Percent">

                                                {itm.profit_percent}

                                            </div>

                                        </div>



                                    </div>




                                    {/* <div className="Grid">

                                        <div className="Cell">


                                            <div className="Label">

                                                PRICE:

                                            </div>
                                            <div className="Value">

                                                {itm.price}
                                                
                                            </div>

                                        </div>


                                        <div className="Cell">


                                            <div className="Label">

                                                PROFIT:

                                            </div>
                                            <div className="Value">

                                                {itm.profit}
                                                
                                            </div>

                                        </div>


                                    </div> */}






                                </CatalogCalcItem>


                            </div>

                        ))




                    :
                    //######################################################
                    //######################################################
                    //itemsBulletReportItem

                    row.call === 'itemsRow'
                    ?


                        row.items.map((itm, j) => (

                            <div 
                            ref={setRowRef}                              
                            key={i+"-"+j}
                            id={"row"+i+"-"+j}
                            >

                                <CatalogRow>

                                    <CatalogRowBullet
                                    bulletColor={itm.bullet}
                                    >
                                        <div className="Circle"></div>

                                    </CatalogRowBullet>



                                    <CatalogRowLine
                                    onClick={() => handleUrl({

                                        call        :'push',
                                        data        :itm
                    
                                    })}
                                    >
                                        <div className="Percent">

                                            {itm.percent}

                                        </div>


                                        <div className="Name">

                                            {itm.name}

                                        </div>


                                        <div className="Value">

                                            {itm.value}

                                        </div>


                                    </CatalogRowLine> 


                                    {/* <CatalogRowName
                                    onClick={() => handleUrl({

                                        call        :'push',
                                        data        :itm
                    
                                    })}
                                    >

                                        {itm.category}

                                    </CatalogRowName>  */}


                                </CatalogRow>



                            </div>

                        ))




                    :
                    //######################################################
                    //######################################################
                    //report

                    row.call === 'report'
                    ?


                        row.items.map((itm, j) => (

                            <div 
                            ref={setRowRef}                              
                            key={i+"-"+j}
                            id={"row"+i+"-"+j}
                            >

                                {

                                //######################################################
                                //######################################################
                                //bar
                            
                                itm.print === 'bar'
                                ?
                                    <CatalogBar>

                                        <div className="LineName">

                                            {itm.name}

                                        </div>

                                    </CatalogBar>


                                :

                                //######################################################
                                //######################################################
                                //spacer
                                
                                itm.print === 'spacer'
                                ?

                                    <CatalogSection
                                    spacerBackgroundColor={itm.value.backgroundColor}
                                    spacerHeight={itm.value.height}
                                    >

                                        <div className={"Spacer"}></div>

                                    </CatalogSection>

                                :
                                //######################################################
                                //######################################################
                                //TXT
        
                                itm.print === 'txt'
                                ?
                                    <CatalogSection>

                                        <TxtComp 
                                        
                                            id={"row"+i+"_txt"}
                                            data={itm} 
                                        />

                                    </CatalogSection>

                                :
                                //######################################################
                                //######################################################
                                //LEDGER
                                
                                itm.print === 'ledger'
                                ?

                                    <CatalogSection>

                                        <LedgerComp 
                                        
                                            id={"row"+i+"_ledger"}
                                            data={itm} 
                                        />

                                    </CatalogSection>

                           
                                :
                                //######################################################
                                //######################################################
                                //BLOX
                                
                                itm.print === 'blox'
                                ?

                                    <CatalogSection>

                                        <BloxComp 
                                        
                                            id={"row"+i+"_blox"}
                                            data={itm} 
                                        />

                                    </CatalogSection>

                           
                                :
                                //######################################################
                                //######################################################
                                //canvas chart
                                
                                itm.print === 'canvas_chart'
                                ?
                                    <CatalogSection
                                    id={"row"+i+"_section"}
                                    >

                                        <CanvasChart
                                        
                                            parentId ={"row"+i+"_section"}
                                            id={"row"+i+"_chart"}
                        
                                            backgroundColor = {itm.value[0].backgroundColor}
                                            border={itm.value[0].border}
                                            width={itm.value[0].width}
                                            maxHeight={itm.value[0].maxHeight}
                                            margin={itm.value[0].margin}
                        
                                            primeHandle={(v) => props.handle(v)}
                                            drawCheckerBoard = {itm.value[0].drawCheckerBoard}
                                            draw = {false}   
                                            render = {false}
                                            chart = {{
                                                call    :itm.value[0].chart.call,
                                                data    :itm.value[0].chart.data
                                            }}

                                        />

                                    </CatalogSection>

                                :
                                //######################################################
                                //######################################################
                                //svg chart
                                
                                itm.print === 'svg_donut_chart'
                                ?
                                    <CatalogSection
                                    id={"row"+i+"_section"}
                                    >

                                        <SvgDonutChart
                                        
                                            id={"row"+i+"_chart"}
                                            data={itm.value} 

                                        />

                                    </CatalogSection>

                                :
                                //######################################################
                                //######################################################
                                //gauge
                                
                                itm.print === 'gauge1'
                                ?
                                    <CatalogSection
                                    id={"row"+i+"_section"}
                                    >

                                        <Gauge1
                                        
                                            id={"row"+i+"_gauge"}
                                            data={itm.value} 

                                        />

                                    </CatalogSection>


                                :

                                itm.print === 'gauge2'
                                ?
                                    <CatalogSection
                                    id={"row"+i+"_section"}
                                    >

                                        <Gauge2
                                        
                                            id={"row"+i+"_gauge"}
                                            data={itm.value} 

                                        />

                                    </CatalogSection>


                                :

                                itm.print === 'gauge3'
                                ?
                                    <CatalogSection
                                    id={"row"+i+"_section"}
                                    >

                                        <Gauge3
                                        
                                            id={"row"+i+"_gauge"}
                                            data={itm.value} 

                                        />

                                    </CatalogSection>


                                :
                                itm.print === 'gauge4'
                                ?
                                    <CatalogSection
                                    id={"row"+i+"_section"}
                                    >

                                        <Gauge4
                                        
                                            id={"row"+i+"_gauge"}
                                            data={itm.value} 

                                        />

                                    </CatalogSection>


                                :
                                itm.print === 'temperature_gauge'
                                ?
                                    <CatalogSection
                                    id={"row"+i+"_section"}
                                    >

                                        <TemperatureGauge
                                        
                                            id={"row"+i+"_gauge"}
                                            data={itm.value} 

                                        />

                                    </CatalogSection>


                                :
                                //######################################################
                                //######################################################
                                //SINGLE

                                itm.print === 'single'
                                ?

                                    <CatalogSection>

                                        {
                                        itm.value.map((valObj, k) => (

                                            <div key={k}>

                                                {
                                                valObj.chartType === 'horizontal'
                                                ?
                                                    <CatalogBox
                                                    width={"100%"}
                                                    >
                                                        <HrzBarChart
                                                        chartLines={valObj.chartLines}
                                                        data={valObj.data}
                                                        >
                                                        </HrzBarChart>
                                        
                                                    </CatalogBox>

                                                :
                                                valObj.chartType === 'vertical'
                                                ?

                                                    <CatalogBox
                                                    width={"100%"}
                                                    >
                                                        <VrtBarChart
                                                        chartLines={valObj.chartLines}
                                                        data={valObj.data}
                                                        >
                                                        </VrtBarChart>
                                        
                                                    </CatalogBox>

                                                :null
                                                }

                                            </div>

                                        ))
                                        }

                                    </CatalogSection>


                                :
                                //######################################################
                                //######################################################
                                //GRAPHIC

                                itm.print === 'graphic'
                                ?

                                    <CatalogSection>

                                        {
                                        itm.value.map((valObj, k) => (

                                            <div key={k}>




                                                <CatalogBox
                                                //backgroundColor={'lightgray'}
                                                width={valObj.boxWidth}
                                                height={valObj.boxHeight+"px"}
                                                >
                                                    <GraphicComp
                                                        
                                                        id={"row"+i+"-"+j+"_graphic_img"}
                                                        data={valObj}
                                                    
                                                    />

                                                </CatalogBox>



                                            
                                            </div>

                                        ))
                                        }

                                    </CatalogSection>



                                :
                                //######################################################
                                //######################################################
                                //BAR CHARTS
                                
                                itm.print === 'singleBarChart'
                                ?

                                    <CatalogSection>

                                        {
                                        itm.value.map((valObj, k) => (

                                            <div key={k}>

                                                {
                                                valObj.chartType === 'horizontal'
                                                ?
                                                    <CatalogBox
                                                    width={"100%"}
                                                    >
                                                        <HrzBarChart
                                                        chartLines={valObj.chartLines}
                                                        data={valObj.data}
                                                        >
                                                        </HrzBarChart>
                                        
                                                    </CatalogBox>

                                                :
                                                valObj.chartType === 'vertical'
                                                ?

                                                    <CatalogBox
                                                    width={"100%"}
                                                    >
                                                        <VrtBarChart
                                                        chartLines={valObj.chartLines}
                                                        data={valObj.data}
                                                        >
                                                        </VrtBarChart>
                                        
                                                    </CatalogBox>

                                                :null
                                                }

                                            </div>

                                        ))
                                        }

                                    </CatalogSection>


                                :
                                itm.print === 'doubleBarChart'
                                ?


                                    <CatalogSection>

                                        {
                                        itm.value.map((valObj, k) => (

                                            <div key={k}>

                                                {
                                                valObj.chartType === 'horizontal'
                                                ?
                                                    <CatalogBox
                                                    width={"50%"}
                                                    >
                                                        <HrzBarChart
                                                        chartLines={valObj.chartLines}
                                                        data={valObj.data}
                                                        >
                                                        </HrzBarChart>
                                        
                                                    </CatalogBox>

                                                :
                                                valObj.chartType === 'vertical'
                                                ?

                                                    <CatalogBox
                                                    width={"50%"}
                                                    >
                                                        <VrtBarChart
                                                        chartLines={valObj.chartLines}
                                                        data={valObj.data}
                                                        >
                                                        </VrtBarChart>
                                        
                                                    </CatalogBox>

                                                :null
                                                }

                                            </div>

                                        ))
                                        }

                                    </CatalogSection>



                                :
                                //######################################################
                                //######################################################
                                //DONUT CHART
                                
                                itm.print === 'donutChart'
                                ?

                                    <CatalogSection>

                                        {
                                        itm.value.map((valObj, k) => (

                                            <div key={k}>

                                                {
                                                valObj.donutType === 'static'
                                                ?
                                                    <CatalogBox
                                                    width={"50%"}
                                                    >
                                                        <StaticDonutChart
                                                        data={valObj}
                                                        >
                                                        </StaticDonutChart>
                                        
                                                    </CatalogBox>

                                                :
                                                valObj.donutType === 'dynamic'
                                                ?

                                                    <CatalogBox
                                                    width={"50%"}
                                                    >
                                                        <StaticDonutChart
                                                        data={valObj}
                                                        >
                                                        </StaticDonutChart>
                                        
                                                    </CatalogBox>

                                                :null
                                                }

                                            </div>

                                        ))
                                        }

                                    </CatalogSection>

                                :
                                //######################################################
                                //######################################################
                                //report_box

                                itm.print === 'report_box'
                                ?

                                    <CatalogSection>

                                        {
                                        lessThanThresh
                                        ?

                                            itm.value.map((valObj, k) => (

                                                <div key={k}>

                                                    {
                                                    valObj.boxCall === 'blank'
                                                    ?

                                                        <CatalogBox
                                                        //backgroundColor={'lightyellow'}
                                                        width={"100%"}
                                                        height={"auto"}
                                                        //height={valObj.boxHeight+"px"}
                                                        >
          
                                                        </CatalogBox>


                                                    :
                                                    valObj.boxCall === 'graphic'
                                                    ?

                                                        <CatalogBox
                                                        //backgroundColor={'lightyellow'}
                                                        width={"100%"}
                                                        height={"auto"}
                                                        //height={valObj.boxHeight+"px"}
                                                        >
                                                            <GraphicComp 
                                                                
                                                                id={"row"+i+"-"+j+"_box_img"}
                                                                lessThanThresh={lessThanThresh}    
                                                                data={valObj}
                                                            
                                                            />

                                                        </CatalogBox>
                                                

                                                    :
                                                    valObj.boxCall === 'gauge1'
                                                    ?

                                                        <CatalogBox
                                                        id={"row"+i+"-"+j+"_box"}
                                                        //backgroundColor={'lavender'}
                                                        width={"100%"}
                                                        height={"auto"}
                                                        padding={"0 0 20px 0"}

                                                        >
                                                            <Gauge1
                                                                
                                                                refid={"row"+i+"-"+j+"_box"}
                                                                id={"row"+i+"-"+j+"_box_gauge"}
                                                                lessThanThresh={lessThanThresh}    
                                                                data={valObj.value}
                                                            
                                                            />


                                                        </CatalogBox>
                                                

                                                    :

                                                    valObj.boxCall === 'gauge2'
                                                    ?

                                                        <CatalogBox
                                                        id={"row"+i+"-"+j+"_box"}
                                                        //backgroundColor={'lavender'}
                                                        width={"100%"}
                                                        height={"auto"}
                                                        padding={"0 0 20px 0"}

                                                        >
                                                            <Gauge2
                                                                
                                                                refid={"row"+i+"-"+j+"_box"}
                                                                id={"row"+i+"-"+j+"_box_gauge"}
                                                                lessThanThresh={lessThanThresh}    
                                                                data={valObj.value}
                                                            
                                                            />


                                                        </CatalogBox>
                                                

                                                    :
                                                    valObj.boxCall === 'gauge3'
                                                    ?

                                                        <CatalogBox
                                                        id={"row"+i+"-"+j+"_box"}
                                                        //backgroundColor={'lavender'}
                                                        width={"100%"}
                                                        height={"auto"}
                                                        padding={"0 0 20px 0"}

                                                        >
                                                            <Gauge3
                                                                
                                                                refid={"row"+i+"-"+j+"_box"}
                                                                id={"row"+i+"-"+j+"_box_gauge"}
                                                                lessThanThresh={lessThanThresh}    
                                                                data={valObj.value}
                                                            
                                                            />


                                                        </CatalogBox>
                                                

                                                    :
                                                    valObj.boxCall === 'gauge4'
                                                    ?

                                                        <CatalogBox
                                                        id={"row"+i+"-"+j+"_box"}
                                                        //backgroundColor={'lavender'}
                                                        width={"100%"}
                                                        height={"auto"}
                                                        padding={"0 0 20px 0"}

                                                        >
                                                            <Gauge4
                                                                
                                                                refid={"row"+i+"-"+j+"_box"}
                                                                id={"row"+i+"-"+j+"_box_gauge"}
                                                                lessThanThresh={lessThanThresh}    
                                                                data={valObj.value}
                                                            
                                                            />


                                                        </CatalogBox>
                                                

                                                    :
                                                    valObj.boxCall === 'temperature_gauge'
                                                    ?

                                                        <CatalogBox
                                                        id={"row"+i+"-"+j+"_box"}
                                                        //backgroundColor={'lavender'}
                                                        width={"100%"}
                                                        height={"auto"}
                                                        padding={"0 0 20px 0"}

                                                        >
                                                            <TemperatureGauge
                                                                
                                                                refid={"row"+i+"-"+j+"_box"}
                                                                id={"row"+i+"-"+j+"_box_gauge"}
                                                                lessThanThresh={lessThanThresh}    
                                                                data={valObj.value}
                                                            
                                                            />


                                                        </CatalogBox>
                                                

                                                    :
                                                    valObj.boxCall === 'svg_donut_chart'
                                                    ?

                                                        <CatalogBox
                                                        id={"row"+i+"-"+j+"_box"}
                                                        //backgroundColor={'lavender'}
                                                        width={"100%"}
                                                        height={"auto"}
                                                        padding={"0 0 20px 0"}

                                                        >
                                                            <SvgDonutChart 
                                                                
                                                                refid={"row"+i+"-"+j+"_box"}
                                                                id={"row"+i+"-"+j+"_box_chart"}
                                                                lessThanThresh={lessThanThresh}    
                                                                data={valObj.value}
                                                            
                                                            />


                                                        </CatalogBox>
                                                

                                                    :
                                                    valObj.boxCall === 'canvas_chart'
                                                    ?

                                                        <CatalogBox
                                                        id={"row"+i+"-"+j+"_box"}
                                                        //backgroundColor={'lavender'}
                                                        width={"100%"}
                                                        height={"auto"}
                                                        padding={"0 0 20px 0"}

                                                        >
                                                            <CanvasChartComp 
                                                                
                                                                refid={"row"+i+"-"+j+"_box"}
                                                                id={"row"+i+"-"+j+"_box_chart"}
                                                                lessThanThresh={lessThanThresh}    
                                                                data={valObj}
                                                            
                                                            />


                                                        </CatalogBox>
                                                

                                                    :
                                                    valObj.boxCall === 'donutChart'
                                                    ?

                                                        <CatalogBox
                                                        //backgroundColor={'pink'}
                                                        width={"100%"}
                                                        height={"auto"}
                                                        padding={"0 0 20px 0"}
                                                        >
                                                            <DonutChartComp 
                                                                
                                                                id={"row"+i+"-"+j+"_box_chart"}
                                                                lessThanThresh={lessThanThresh}    
                                                                data={valObj}
                                                            
                                                            />

                                                        </CatalogBox>
                                                

                                                    :
                                                    valObj.boxCall === 'act'
                                                    ?

                                                        <CatalogBox
                                                        //backgroundColor={'lightgray'}
                                                        width={"100%"}
                                                        height={"auto"}
                                                        //height={valObj.boxHeight+"px"}
                                                        padding={"0 0 20px 0"}
                                                        >

                                                            <ActComp 

                                                                handle={props.handle}
                                                                id={"row"+i+"-"+j+"_box_act"}
                                                                lessThanThresh={lessThanThresh}    
                                                                data={valObj} 

                                                            />

                                                        </CatalogBox>

                                                    :
                                                    valObj.boxCall === 'txt'
                                                    ?

                                                        <CatalogBox
                                                        //backgroundColor={'lightgray'}
                                                        width={"100%"}
                                                        height={"auto"}
                                                        //height={valObj.boxHeight+"px"}
                                                        padding={"0 0 20px 0"}
                                                        >

                                                            <TxtComp 

                                                                id={"row"+i+"-"+j+"_box_txt"}
                                                                lessThanThresh={lessThanThresh}    
                                                                data={valObj} 

                                                            />

                                                        </CatalogBox>

                                                    :
                                                    valObj.boxCall === 'ledger'
                                                    ?

                                                        <CatalogBox
                                                        //backgroundColor={'lightgray'}
                                                        width={"100%"}
                                                        height={"auto"}
                                                        //height={valObj.boxHeight+"px"}
                                                        >

                                                            <LedgerComp 

                                                                id={"row"+i+"-"+j+"_box_ledger"}
                                                                lessThanThresh={lessThanThresh}    
                                                                data={valObj} 

                                                            />

                                                        </CatalogBox>

                                                    :null
                                                    }

                                                </div>

                                            ))


                                        :


                                            itm.value.map((valObj, k) => (

                                                <div key={k}>

                                                    {

                                                    valObj.boxCall === 'blank'
                                                    ?

                                                        <CatalogBox
                                                        //backgroundColor={'lightgray'}
                                                        width={valObj.boxWidth}
                                                        height={valObj.boxHeight+"px"}
                                                        >
                    
                                                        </CatalogBox>


                                                    :
                                                    valObj.boxCall === 'graphic'
                                                    ?

                                                        <CatalogBox
                                                        //backgroundColor={'lightgray'}
                                                        width={valObj.boxWidth}
                                                        height={valObj.boxHeight+"px"}
                                                        >
                                                            <GraphicComp 
                                                                
                                                                id={"row"+i+"-"+j+"_box_img"}
                                                                lessThanThresh={lessThanThresh}    
                                                                data={valObj}
                                                            
                                                            />

                                                        </CatalogBox>
                                                

                                                    :

                                                    valObj.boxCall === 'gauge1'
                                                    ?

                                                        <CatalogBox
                                                        id={"row"+i+"-"+j+"_box"}
                                                        //backgroundColor={'lavender'}
                                                        width={valObj.boxWidth}
                                                        height={valObj.boxHeight+"px"}
                                                        padding={"0 0 0 0"}

                                                        >
                                                            <Gauge1
                                                                
                                                                refid={"row"+i+"-"+j+"_box"}
                                                                id={"row"+i+"-"+j+"_box_gauge"}
                                                                lessThanThresh={lessThanThresh}    
                                                                data={valObj.value}
                                                            
                                                            />


                                                        </CatalogBox>

                                                    :
                                                    valObj.boxCall === 'gauge2'
                                                    ?

                                                        <CatalogBox
                                                        id={"row"+i+"-"+j+"_box"}
                                                        //backgroundColor={'lavender'}
                                                        width={valObj.boxWidth}
                                                        height={valObj.boxHeight+"px"}
                                                        padding={"0 0 0 0"}

                                                        >
                                                            <Gauge2
                                                                
                                                                refid={"row"+i+"-"+j+"_box"}
                                                                id={"row"+i+"-"+j+"_box_gauge"}
                                                                lessThanThresh={lessThanThresh}    
                                                                data={valObj.value}
                                                            
                                                            />


                                                        </CatalogBox>
                                                

                                                    :
                                                    valObj.boxCall === 'gauge3'
                                                    ?

                                                        <CatalogBox
                                                        id={"row"+i+"-"+j+"_box"}
                                                        //backgroundColor={'lavender'}
                                                        width={valObj.boxWidth}
                                                        height={valObj.boxHeight+"px"}
                                                        padding={"0 0 0 0"}

                                                        >
                                                            <Gauge3
                                                                
                                                                refid={"row"+i+"-"+j+"_box"}
                                                                id={"row"+i+"-"+j+"_box_gauge"}
                                                                lessThanThresh={lessThanThresh}    
                                                                data={valObj.value}
                                                            
                                                            />


                                                        </CatalogBox>
                                                

                                                    :
                                                    valObj.boxCall === 'gauge4'
                                                    ?

                                                        <CatalogBox
                                                        id={"row"+i+"-"+j+"_box"}
                                                        //backgroundColor={'lavender'}
                                                        width={valObj.boxWidth}
                                                        height={valObj.boxHeight+"px"}
                                                        padding={"0 0 0 0"}

                                                        >
                                                            <Gauge4
                                                                
                                                                refid={"row"+i+"-"+j+"_box"}
                                                                id={"row"+i+"-"+j+"_box_gauge"}
                                                                lessThanThresh={lessThanThresh}    
                                                                data={valObj.value}
                                                            
                                                            />


                                                        </CatalogBox>
                                                

                                                    :
                                                    valObj.boxCall === 'temperature_gauge'
                                                    ?

                                                        <CatalogBox
                                                        id={"row"+i+"-"+j+"_box"}
                                                        //backgroundColor={'lavender'}
                                                        width={valObj.boxWidth}
                                                        height={valObj.boxHeight+"px"}
                                                        padding={"0 0 0 0"}

                                                        >
                                                            <TemperatureGauge
                                                                
                                                                refid={"row"+i+"-"+j+"_box"}
                                                                id={"row"+i+"-"+j+"_box_gauge"}
                                                                lessThanThresh={lessThanThresh}    
                                                                data={valObj.value}
                                                            
                                                            />


                                                        </CatalogBox>
                                                

                                                    :
                                                    valObj.boxCall === 'svg_donut_chart'
                                                    ?

                                                        <CatalogBox
                                                        id={"row"+i+"-"+j+"_box"}
                                                        //backgroundColor={'lavender'}
                                                        width={valObj.boxWidth}
                                                        height={valObj.boxHeight+"px"}
                                                        padding={"0 0 0 0"}

                                                        >
                                                            <SvgDonutChart 
                                                                
                                                                refid={"row"+i+"-"+j+"_box"}
                                                                id={"row"+i+"-"+j+"_box_chart"}
                                                                lessThanThresh={lessThanThresh}    
                                                                data={valObj.value}
                                                            
                                                            />


                                                        </CatalogBox>
                                                

                                                    :
                                                    valObj.boxCall === 'canvas_chart'
                                                    ?

                                                        <CatalogBox
                                                        id={"row"+i+"-"+j+"_box"}
                                                        //backgroundColor={'lavender'}
                                                        width={valObj.boxWidth}
                                                        height={valObj.boxHeight+"px"}
                                                        padding={"0 0 0 0"}

                                                        >
                                                            {/* <ChartComp 
                                                                
                                                                refid={"row"+i+"-"+j+"_box"}
                                                                id={"row"+i+"-"+j+"_box_chart"}
                                                                lessThanThresh={lessThanThresh}    
                                                                data={valObj}
                                                            
                                                            /> */}


                                                            <CanvasChart
                                                                
                                                                parentId={"row"+i+"-"+j+"_box"}
                                                                id={"row"+i+"-"+j+"_box_chart"}
                                            
                                                                backgroundColor = {valObj.value.backgroundColor}
                                                                border={valObj.value.border}
                                                                width={valObj.value.width}
                                                                maxHeight={valObj.value.maxHeight}
                                                                margin={valObj.value.margin}
                                            
                                                                primeHandle={(v) => props.handle(v)}
                                                                drawCheckerBoard = {valObj.value.drawCheckerBoard}
                                                                draw = {false}   
                                                                render = {false}
                                                                chart = {{
                                                                    call    :valObj.value.chart.call,
                                                                    data    :valObj.value.chart.data
                                                                }}

                                                            />



                                                        </CatalogBox>
                                                

                                                    :
                                                    valObj.boxCall === 'donutChart'
                                                    ?

                                                        <CatalogBox
                                                        //backgroundColor={'lavender'}
                                                        width={valObj.boxWidth}
                                                        height={valObj.boxHeight+"px"}
                                                        >
                                                            <DonutChartComp 
                                                                
                                                                id={"row"+i+"-"+j+"_box_chart"}
                                                                lessThanThresh={lessThanThresh}    
                                                                data={valObj}
                                                            
                                                            />

                                                        </CatalogBox>
                                                

                                                    :
                                                    valObj.boxCall === 'act'
                                                    ?

                                                        <CatalogBox
                                                        //backgroundColor={'lightyellow'}
                                                        width={valObj.boxWidth}
                                                        height={valObj.boxHeight+"px"}
                                                        >

                                                            <ActComp 

                                                                handle={props.handle}
                                                                id={"row"+i+"-"+j+"_box_act"}
                                                                lessThanThresh={lessThanThresh}    
                                                                data={valObj} 

                                                            />

                                                        </CatalogBox>

                                                    :
                                                    valObj.boxCall === 'txt'
                                                    ?

                                                        <CatalogBox
                                                        //backgroundColor={'lightyellow'}
                                                        width={valObj.boxWidth}
                                                        height={valObj.boxHeight+"px"}
                                                        >

                                                            <TxtComp 

                                                                id={"row"+i+"-"+j+"_box_txt"}
                                                                lessThanThresh={lessThanThresh}    
                                                                data={valObj} 

                                                            />

                                                        </CatalogBox>

                                                    :
                                                    valObj.boxCall === 'ledger'
                                                    ?

                                                        <CatalogBox
                                                        //backgroundColor={'lightgray'}
                                                        width={valObj.boxWidth}
                                                        height={valObj.boxHeight+"px"}
                                                        >

                                                            <LedgerComp 

                                                                id={"row"+i+"-"+j+"_box_ledger"}
                                                                lessThanThresh={lessThanThresh}    
                                                                data={valObj} 
                                      

                                                            />

                                                        </CatalogBox>

                                                    :null
                                                    }

                                                </div>

                                            ))



                                        }

                                    </CatalogSection>



                            
                                :null
    
                                }




                            </div>

                        ))






                    :
                    //######################################################
                    //######################################################
                    //glossary

                    row.call === 'glossary'
                    ?

                        row.items.map((itm, j) => (



                            <CatalogTileGlossary
                            ref={setRowRef}                              
                            key={i+"-"+j}
                            //id={"row"+i+"-"+j}
                            id={itm.term.replace(/ /g,"_")+"-"+i+"-"+j}
                            >

                                <div className="Title"
                                onClick={(v) => props.handleClick({

                                    call:"term",
                                    data:itm.term

                                })}
                                >

                                    {itm.term}

                                </div>

                    
                                <div className="Descr"
                                >
                                    {itm.descr}

                                </div>


                                
                                {
                                itm.usage.length > 0
                                ?
                                    <div className="Usage"
                                    >
                                        <div 
                                        className="Title"
                                        >
                                            {"USAGE: "}

                                        </div>

                                        {itm.usage.map((btn, k) => (

                                            <div 
                                            key={k}
                                            className="Button"
                                            onClick={(v) => props.handleClick({

                                                call:"usage",
                                                data:btn

                                            })}
                                            >
                                                {btn}

                                            </div>

                                        ))}


                                    </div>


                                :null
                                } 
                                
                            
                            </CatalogTileGlossary>



                        ))



                    :null

                    }

                </div>


            ))}

        </Catalog>


    )



}

export default Index;


