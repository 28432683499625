import React, { 

    useState, 
    useEffect,
    //useRef

} from 'react';



import {

    Summary,
    SummaryTop,
    SummaryLine,
    SummaryButton

} from '../../Styled';



export const FScomplete = (props) => {



    //-------------------------------
    // state/effect - data
    //-------------------------------

    const [data, setData] = useState([]);

    useEffect(() => {


        console.log("FScomplete props - Forgot Password : "+JSON.stringify(props,null,2));


        

        // FScomplete: {
        //     "data": {
        //       "siteuser": "theprofitcalculator.com-tpc",
        //       "auth_account": {
        //         "error": false,
        //         "result": {
        //           "authUser": {
        //             "error": false,
        //             "result": {
        //               "error": false,
        //               "result": [
        //                 {
        //                   "passwordHash": "$2a$10$ZlCaJbgDVT2AAw1/wvwGf.pO7neig.5PEHtmoI7MWIudzVDyYvWCm",
        //                   "sites": [
        //                     "theprofitcalculator.com"
        //                   ],
        //                   "accountId": "tpc",
        //                   "createdAt": "2021-06-29 12:14:11",
        //                   "emailId": "tpc~dghlchjvzml0y2fsy3vsyxrvci5jb20",
        //                   "sk": "tpc@theprofitcalculator.com",
        //                   "refreshToken": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzaXRlIjoidGhlcHJvZml0Y2FsY3VsYXRvci5jb20iLCJuYW1lIjoidHBjfmRnaGxjaGp2em1sMHkyZnN5M3ZzeXhydmNpNWpiMjAiLCJlbWFpbCI6InRwY0B0aGVwcm9maXRjYWxjdWxhdG9yLmNvbSIsImlhdCI6MTYyNDk5ODE0M30.L4LnbtoNCyBmQ7KfKb_-ldnWwA3E5AQZoXmPiHFs8-A",
        //                   "pk": "theprofitcalculator.com"
        //                 }
        //               ]
        //             }
        //           },
        //           "accountUser": {
        //             "error": false,
        //             "result": {
        //               "error": false,
        //               "result": [
        //                 {
        //                   "sk": "tpc@theprofitcalculator.com",
        //                   "accountId": "tpc",
        //                   "pk": "theprofitcalculator.com",
        //                   "email": "tpc@theprofitcalculator.com",
        //                   "data": ""
        //                 }
        //               ]
        //             }
        //           }
        //         }
        //       },
        //       "checkAuthAccount": {
        //         "error": false,
        //         "summary": {
        //           "result": "SUCCESS",
        //           "message": "check - auth, account (SUCCESS)"
        //         }
        //       },
        //       "new_password": "H141q6sm9",
        //       "newPasswordHash": {
        //         "error": false,
        //         "result": {
        //           "generatedHash": "$2a$10$PsS3ldiJ6YjsM.qQEE0l5e6JRr7wtKOIwQO3RiD7GVfHWWKVpBj3S"
        //         }
        //       },
        //       "authUpdate": {
        //         "error": false,
        //         "result": {
        //           "items": [
        //             {
        //               "TableName": "auth",
        //               "Key": {
        //                 "pk": "theprofitcalculator.com",
        //                 "sk": "tpc@theprofitcalculator.com"
        //               },
        //               "Values": {
        //                 "passwordHash": "$2a$10$PsS3ldiJ6YjsM.qQEE0l5e6JRr7wtKOIwQO3RiD7GVfHWWKVpBj3S",
        //                 "refreshToken": ""
        //               }
        //             }
        //           ]
        //         }
        //       },
        //       "email_customer_error": "skipped",
        //       "email_customer_status": "skipped",
        //       "sendEmail": {
        //         "customer": {
        //           "error": "skipped",
        //           "status": "skipped",
        //           "message": "Customer Message Here"
        //         },
        //         "admin": {
        //           "error": "N/A",
        //           "status": "N/A",
        //           "message": "N/A"
        //         }
        //       }
        //     }
        //   }




        if(props.data && data.length === 0)
        {

            var array = [];
   
            // var authUser, auth;
            var accountUser, account;
            var email;
     
            // auth = false;
            account = false;
            email = "N/A";

            if(!props.data.auth_account.error)
            {
                // authUser = props.data.auth_account.result.authUser;
                // if(!authUser.error)
                // {
                //     if(!authUser.result.error 
                //     && authUser.result.result.length > 0
                //     )
                //     {
                //         auth = authUser.result.result[0]
                //     }
                // }

                accountUser = props.data.auth_account.result.accountUser;
                if(!accountUser.error)
                {
                    if(!accountUser.result.error 
                    && accountUser.result.result.length > 0
                    )
                    {
                        account = accountUser.result.result[0]
                    }
                }

                if(account)
                {
                    email = account.email
                }
        
            }


            
            array.push({

                text:"A message was just sent to..."

            });
            array.push({

                text:"EMAIL: " + email

            });

            array.push({

                text:""

            });


            setData(array);

        }




    },
    [
        props,
        data

    ])










    return (<div>


        <Summary>

            <SummaryTop>

                <div className="Title">

                    Reset Password

                </div>
        
            </SummaryTop>


            {data.map((obj, i) => (


                <SummaryLine
                key={i}
                >
                    <div className="Line">

                        {obj.text}

                    </div>
                </SummaryLine>

            ))}



            <SummaryButton
            onClick={() => props.handle({

                url: "/Login/"
        
            })}
            >
                Click Here To Login
        
            </SummaryButton>




        </Summary>


    </div>);

}





