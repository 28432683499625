import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


/*

import { 
    //Lorem, 
    TestImages,
    //TestVideos,
    //SpecialImages,
    //NavigationData 
} from 'app-components/Prime/Data';


// import {

//     //TextCard1,
//     //TextCard2,
//     //TextCard3,

//     //ImageTextCard1,
//     //ImageTextCard2,
//     //ImageTextCard3


// } from 'app-components/Zone/Builds/Samples/Card';

*/



// import { Settings } from 'app-components/Prime/Data.js';


import { 

    VPaccount, 
    VPexpenses,
    VPincome,   
    VPoverhead, 

    //VPownrs,
    VPownr,

    //VPempls,
    VPempl,

    //VPindirectLabor,
    //VPidlEmployee

} from 'app-components/Prime/VPdata/form.js';


import { LibBase } from "library";



// import ApiResources from 'utils/components/Pages/ApiResources';

//import Build from './Build';

import { Calc } from '../Calculator/Calculate';




const SceneInfo = (props) => {


    // console.log("SceneInfo Props: "+JSON.stringify(props,null,2));
    // alert("checking Props");
    // return false;


    // -- redux -- //
    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));


    //var calc_overhead = {};
    var keyname;
    var kn;
    var item;
    //var name;
    var value;
    var newValue;

    var isOkay;




    var calc = false;
    var calculation = false;


    var cost_of_goods = [
            
        {
            "call": "good-1",
            "other": {
            "data": {
                "name": "",
                "brand": ""
            },
            "label": "--"
            },
            "title": "Vinyl",
            "rate": 0,
            "qtyhrs": 0
        }

    ];

    var calcby = {
        "call": "percent",
        "title": "Net Profit",
        "value": "25.0"
    };

    var overhead =  {
        "title": "Overhead",
        "value": "49.1",
        "id": "overhead"
    };

    var debits =  [
        {
            "title": "Commission",
            "value": 0,
            "id": "commission"
        }
        // {
        //     "title": "Fees",
        //     "value": 0,
        //     "id": "fees"
        // }
    ];

    var quantity = {
        "title": "Quantity",
        "value": "1",
        "id": "quantity"
    };





    if(zoneObj.calcs && zoneObj.calcs !== undefined)
    {
        //console.log("calcs: "+JSON.stringify(zoneObj.calcs,null,2));
        calc = JSON.parse(JSON.stringify(zoneObj.calcs[props.calcId]));
        //console.log("calc: "+JSON.stringify(calc,null,2));
   
    }
    //return false;

    if(calc)
    {
        if(calc.data)
        {
            if(calc.data.value)
            {
                if(calc.data.value.cost_of_goods)   cost_of_goods   = calc.data.value.cost_of_goods;
                if(calc.data.value.calcby)          calcby          = calc.data.value.calcby;    
                if(calc.data.value.overhead)        overhead        = calc.data.value.overhead;
                if(calc.data.value.debits)          debits          = calc.data.value.debits;
                if(calc.data.value.quantity)        quantity        = calc.data.value.quantity;


                //--------------------------
                //current overhead 
                //--------------------------

                //console.log("BEFORE OVERHEAD: "+JSON.stringify(overhead,null,2));

                //console.log("zoneObj Keys: "+JSON.stringify(Object.keys(zoneObj.calc_dash.result_items)));
                if(zoneObj.calc_dash)
                {
                    if(zoneObj.calc_dash.result_items)
                    {
                        if(zoneObj.calc_dash.result_items.overhead)
                        {
                            overhead = zoneObj.calc_dash.result_items.overhead.overhead;
                            overhead = parseFloat(overhead).toFixed(1);
                            overhead = {
                                title   : "Overhead",
                                value   : overhead,
                                id      : "overhead"
                            }

                        }
                    }
                }
                //console.log("AFTER OVERHEAD: "+JSON.stringify(overhead,null,2));



                // -- calculate -- //
                calculation = Calc({

                    //input   : calc.data.value
                    input : {
                        cost_of_goods   :cost_of_goods,
                        overhead        :overhead,
                        debits          :debits,
                        calcby          :calcby,
                        quantity        :quantity

                    }
                });
                calculation = JSON.parse(JSON.stringify(calculation));

            
                //console.log(JSON.stringify(calculation,null,2));
                // console.log(calculation.out);
                
                var html = "";

                html += "############ INS ############";
                html += "\n";
                html += JSON.stringify(calculation.ins,null,2);
                html += "\n";
                html += "\n";
                html += "\n";

                html += "########### GOODS ###########";
                html += "\n";
                html += JSON.stringify(calculation.goods,null,2);
                html += "\n";
                html += "\n";
                html += "\n";

                html += "######### MARKUP MULTIPLIER #########";
                html += "\n";
                html += JSON.stringify(calculation.markup_multiplier,null,2);
                html += "\n";
                html += "\n";
                html += "\n";

                html += "########### GRAND TOTAL ###########";
                html += "\n";
                html += JSON.stringify(calculation.grand_total,null,2);
                html += "\n";
                html += "\n";
                html += "\n";

                html += "########### OUT ###########";
                html += "\n";
                html += calculation.out;


                console.log(html);


            }
        }
    }


    // return false;






    //================================================
    // account
    //================================================

    isOkay = false;
    if(zoneObj.account 
    && zoneObj.account !== undefined
    )
    {
        if(Object.keys(zoneObj.account).length > 0
        )
        {
            isOkay = true;
        }

    }
    if(isOkay)
    {

        //console.log("ACCOUNT: "+JSON.stringify(zoneObj.account));

        for(keyname in zoneObj.account)
        {

            //console.log("KEYNAME: "+keyname);

            item = zoneObj.account[keyname];
            //console.log(JSON.stringify(item,null,2));

            value = VPaccount[keyname].value;
            newValue = {};
            for(kn in value)
            {
                newValue[kn] = item.value[kn];
            }
            VPaccount[keyname] = {
                name:item.name,
                value:newValue
            };
 

        }// -- for


    }// -- if






    //================================================
    // calc_dash
    //================================================

    //var actual_monthly_expenses;
    //var projected_monthly_sales;
    //var overhead_percent;
    var owners = {};   
    var employees = {};

    //actual_monthly_expenses = VPmonthlyExpenses.value;
    //projected_monthly_sales = VPmonthlySales.value;
    //projected_monthly_sales = VPoverhead.settings.value.projected_monthly_sales;
    //overhead_percent = 75.5 + "%";


    var form_items;
    var result_items;


    isOkay = false;
    if(zoneObj.calc_dash 
    && zoneObj.calc_dash !== undefined
    )
    {
        if(Object.keys(zoneObj.calc_dash.form_items).length > 0
        && Object.keys(zoneObj.calc_dash.result_items).length > 0
        )
        {
            isOkay = true;
        }

    }
    if(isOkay)
    {


        form_items = JSON.parse(JSON.stringify(zoneObj.calc_dash.form_items));

        //console.log("FORM ITEMS: "+JSON.stringify(zoneObj.calc_dash.form_items));

        for(keyname in form_items)
        {


            // -- owners -- //

            if(keyname.match(/ownr/gi))
            {

                //idl_employees[keyname] = zoneObj.calc_dash.form_items[keyname];

                
                //order card item based on Prime VPdata
                item = form_items[keyname];
          
                value = VPownr.value;
                newValue = {};
                for(kn in value)
                {
                    if(kn === 'ownr_call') 
                    {
                    }else{
                        newValue[kn] = item.value[kn];       
                    }
                    
                }
                owners[keyname] = {
                    name:item.name,
                    value:newValue
                };
                

            }




            // -- employees -- //

            else
            if(keyname.match(/empl/gi))
            {

                //idl_employees[keyname] = zoneObj.calc_dash.form_items[keyname];

                
                //order card item based on Prime VPdata
                //item = zoneObj.calc_dash.form_items[keyname];
                item = LibBase.CloneIt(zoneObj.calc_dash.form_items[keyname]);//Deep Clone
          
                value = VPempl.value;
                //value = LibBase.CloneIt(VPidlEmployee.value);
                newValue = {};
                for(kn in value)
                {
                    newValue[kn] = item.value[kn];
                }
                employees[keyname] = {
                    name:item.name,
                    value:newValue
                };
                



      
            }

            

            // -- overhead -- //

            else
            {
                //console.log("KEYNAME: "+keyname);

                item = JSON.parse(JSON.stringify(zoneObj.calc_dash.form_items[keyname]));
                //console.log(JSON.stringify(item,null,2));


                if(keyname.match(/expense-/gi))
                {
                    VPoverhead[keyname] = {
                        name:item.name,
                        value:item.value
                    };

                }else{

                    value = VPoverhead[keyname].value;
                    newValue = {};
                    for(kn in value)
                    {
                        newValue[kn] = item.value[kn];
                    }
                    VPoverhead[keyname] = {
                        name:item.name,
                        value:newValue
                    };

                }




            }

        }// -- for





        result_items = JSON.parse(JSON.stringify(zoneObj.calc_dash.result_items));

        VPexpenses.value.monthly_expenses.value = result_items['totals'].total;
        VPincome.value.projected_monthly_sales.value = form_items['settings'].value.projected_monthly_sales.value;


        //overhead_percent = zoneObj.calc_dash.result_items['overhead'].overhead;
        //overhead_percent = parseFloat(overhead_percent) 
        //overhead_percent = overhead_percent.toFixed(1) + "%";



        //console.log("IDL EMPLOYEES: "+JSON.stringify(idl_employees,null,2));

    
        /*
        for(keyname in zoneObj.calc_dash.result_items)
        {

            console.log("KEYNAME: "+keyname);

            item = zoneObj.calc_dash.result_items[keyname];
            console.log(JSON.stringify(item,null,2));

        
        }// -- for
        */



        
    }// -- if

    //console.log("_overhead A: "+JSON.stringify(_overhead,null,2));

    //_overhead = VPoverhead;
    //console.log("_overhead B: "+JSON.stringify(_overhead,null,2));





    var R = [];
    //var index;



    var top;
    var bar;   
    var catalogItems;

    var accountName;
    var accountValue;





    //#############################################################
    //#############################################################

    top = {

        call:'top',
        name:"Report",
        controls:[

            {

                name:"Go Back",
                // form:{

                //     submitCall:'account',
                //     postTo:apiDomain+apiCall,
                //     data:VPaccount.company,
                //     flyr:{
                //         type:"form",
                //         styl:{
                //             maxWidth:475,
                //             fieldType:'field'
                //         }
                //     }
             

                // }

                hndl:{

                    call:'readout',
                    data:false
                }

            }

        ]

    }


    bar = {

        call:'bar',
        items: [

            {

                name:"Name"

            },

            {

                name:"Value"

            }

        ]
        
    }





    catalogItems = [];

    if(calculation)
    {
        for(keyname in calculation.output)
        {
            accountName = keyname;
            accountValue = calculation.output[keyname];

            catalogItems.push({
                            
                heading:accountName,
                bullet:false,
                stripe:true,
                pgraph:accountValue,
                image:false,
                button:false,
                controls:{

                    // form:{

                    //     submitCall:'account',
                    //     postTo:apiDomain+apiCall,
                    //     data:VPaccount.company,
                    //     flyr:{
                    //         type:"form",
                    //         styl:{
                    //             maxWidth:475,
                    //             fieldType:'field'
                    //         }
                    //     }

                    // },
                    // chart:false

                }
            
            });




        }// -- for


   


    }


    R.push([top,bar,{

        call:"items",
        items:catalogItems
    
    }]);   







    //#############################################################
    //#############################################################

    top = {

        call:'top',
        name:"Goods",
        controls:[

            {

                name:"Go Back",
                // form:{

                //     submitCall:'account',
                //     postTo:apiDomain+apiCall,
                //     data:VPaccount.company,
                //     flyr:{
                //         type:"form",
                //         styl:{
                //             maxWidth:475,
                //             fieldType:'field'
                //         }
                //     }
             

                // }

                hndl:{

                    call:'readout',
                    data:false
                }

            }

        ]

    }


    bar = {

        call:'bar',
        items: [

            {

                name:"Name"

            },

            {

                name:"Value"

            }

        ]
        
    }






    catalogItems = [];

    if(calculation)
    {
        console.log("Goods: "+JSON.stringify(calculation.goods,null,2));

        for(keyname in calculation.goods)
        {
            if(keyname.match(/good-/gi)
            )
            {

                accountName = keyname;
                //accountValue = JSON.stringify(calculation.goods[keyname]);
                accountValue = '';
                accountValue += calculation.goods[keyname].qtyhrs
                accountValue +=" | $"+calculation.goods[keyname].rate;
                accountValue +=" | $"+calculation.goods[keyname].qtyhrs_rate;
                accountValue +=" | "+calculation.goods[keyname].percent+"%";




                catalogItems.push({
                                
                    heading:accountName,
                    bullet:false,
                    stripe:true,
                    pgraph:accountValue,
                    image:false,
                    button:false,
                    controls:{

                        // form:{

                        //     submitCall:'account',
                        //     postTo:apiDomain+apiCall,
                        //     data:VPaccount.company,
                        //     flyr:{
                        //         type:"form",
                        //         styl:{
                        //             maxWidth:475,
                        //             fieldType:'field'
                        //         }
                        //     }

                        // },
                        // chart:false

                    }
                
                });


            }



        }// -- for






    }


    R.push([top,bar,{

        call:"items",
        items:catalogItems
    
    }]);


    // for(keyname in VPaccount.company.value)
    // {

    //     accountName = VPaccount.company.value[keyname].name;
    //     accountValue = VPaccount.company.value[keyname].value;
    //     if(accountValue === '')
    //     {
    //         accountValue = VPaccount.company.value[keyname].default;
    //     }


    //     //console.log(accountName+"\n"+accountValue);


    //     catalogItems.push({
                        
    //         heading:accountName,
    //         bullet:{

    //             styl:{

    //                 color:Settings.bullet.colors[0]
    //             }
                
    //         },
    //         stripe:true,
    //         pgraph:accountValue,
    //         image:false,
    //         button:false,
    //         controls:{

    //             form:{

    //                 submitCall:'account',
    //                 postTo:apiDomain+apiCall,
    //                 data:VPaccount.company,
    //                 flyr:{
    //                     type:"form",
    //                     styl:{
    //                         maxWidth:475,
    //                         fieldType:'field'
    //                     }
    //                 }

    //             },
    //             chart:false

    //         }
        
    //     });

    // }



    // R.push([top,bar,{

    //     call:"items",
    //     items:catalogItems
    
    // }]);



    return {

        calculation:calculation,
        info:R
    };

}



export default SceneInfo;







//TM: Refer


// calc: {
//     "recid": "20210611210143",
//     "siteuser": "theprofitcalculator.com-test",
//     "privacy": "public",
//     "data": {
//       "name": "Vehicle Wrap",
//       "description": "Box Truck 14'",
//       "value": {
//         "quantity": {
//           "title": "Quantity",
//           "value": 1,
//           "id": "quantity"
//         },
//         "cost_of_goods": [
//           {
//             "call": "good-1",
//             "other": {
//               "data": {
//                 "name": "",
//                 "brand": ""
//               },
//               "label": "--"
//             },
//             "title": "Vinyl",
//             "rate": 0,
//             "qtyhrs": 0
//           },
//           {
//             "call": "good-2",
//             "other": {
//               "data": {
//                 "name": "",
//                 "brand": ""
//               },
//               "label": "--"
//             },
//             "title": "Laminate",
//             "rate": 0,
//             "qtyhrs": 0
//           },
//           {
//             "call": "good-3",
//             "other": {
//               "data": {
//                 "name": "",
//                 "brand": ""
//               },
//               "label": "--"
//             },
//             "title": "Design",
//             "rate": 0,
//             "qtyhrs": 0
//           },
//           {
//             "call": "good-20210611206770",
//             "other": {
//               "data": {
//                 "name": "",
//                 "brand": ""
//               },
//               "label": "--"
//             },
//             "title": "Printing",
//             "rate": 0,
//             "qtyhrs": 0
//           },
//           {
//             "call": "good-20210611210386",
//             "other": {
//               "data": {
//                 "name": "",
//                 "brand": ""
//               },
//               "label": "--"
//             },
//             "title": "Laminating",
//             "rate": 0,
//             "qtyhrs": 0
//           },
//           {
//             "call": "good-20210611207398",
//             "other": {
//               "data": {
//                 "name": "",
//                 "brand": ""
//               },
//               "label": "--"
//             },
//             "title": "Cleaning",
//             "rate": 0,
//             "qtyhrs": 0
//           },
//           {
//             "call": "good-20210611205672",
//             "other": {
//               "data": {
//                 "name": "",
//                 "brand": ""
//               },
//               "label": "--"
//             },
//             "title": "Install",
//             "rate": 0,
//             "qtyhrs": 0
//           },
//           {
//             "call": "good-20210909093034",
//             "other": {
//               "data": {
//                 "name": "",
//                 "brand": ""
//               },
//               "label": "--"
//             },
//             "rate": "18.45",
//             "laborId": "empl-1",
//             "title": "Labor-Employee 1",
//             "qtyhrs": 0,
//             "isLabor": true
//           }
//         ],
//         "calcby": {
//           "call": "percent",
//           "title": "Net Profit",
//           "value": "25.0"
//         },
//         "debits": [
//           {
//             "title": "Commission",
//             "value": 0,
//             "id": "commission"
//           },
//           {
//             "title": "Fees",
//             "value": 0,
//             "id": "fees"
//           }
//         ],
//         "overhead": {
//           "title": "Overhead",
//           "value": "57.3",
//           "id": "overhead"
//         }
//       },
//       "favorite": "4"
//     },
//     "reckey": "rec-calculator-20210611210143",
//     "name": "calculator-20210611210143"
// }





// captured: {
//     "cost_of_goods": [
//       {
//         "call": "good-1",
//         "other": {
//           "data": {
//             "name": "",
//             "brand": ""
//           },
//           "label": "--"
//         },
//         "rate": "25.00",
//         "title": "Good 1",
//         "qtyhrs_rate": 25,
//         "decimal": 0.00675363548197995,
//         "qtyhrs": "1.0",
//         "percent": "0.7",
//         "isLabor": false
//       },
//       {
//         "call": "good-2",
//         "other": {
//           "data": {
//             "name": "",
//             "brand": ""
//           },
//           "label": "--"
//         },
//         "rate": "30.00",
//         "title": "Good 2",
//         "qtyhrs_rate": 30,
//         "decimal": 0.00810436257837594,
//         "qtyhrs": "1.0",
//         "percent": "0.8",
//         "isLabor": false
//       },
//       {
//         "call": "good-3",
//         "other": {
//           "data": {
//             "name": "",
//             "brand": ""
//           },
//           "label": "--"
//         },
//         "rate": "22.00",
//         "title": "Good 3",
//         "qtyhrs_rate": 44,
//         "decimal": 0.01188639844828471,
//         "qtyhrs": "2.0",
//         "percent": "1.2",
//         "isLabor": false
//       },
//       {
//         "call": "good-20210728167370",
//         "other": {
//           "data": {
//             "name": "",
//             "brand": ""
//           },
//           "label": "--"
//         },
//         "rate": "36.21",
//         "laborId": "ownr-1",
//         "title": "Labor-Owner A",
//         "qtyhrs_rate": 289.68,
//         "decimal": 0.07825572505679808,
//         "qtyhrs": "8.0",
//         "percent": "7.8",
//         "isLabor": true
//       },
//       {
//         "call": "good-20210813073412",
//         "other": {
//           "data": {
//             "name": "",
//             "brand": ""
//           },
//           "label": "--"
//         },
//         "rate": "18.45",
//         "laborId": "empl-1",
//         "title": "Labor-Employee 1",
//         "qtyhrs_rate": 0,
//         "decimal": 0,
//         "qtyhrs": "0.0",
//         "percent": "0.0",
//         "isLabor": true
//       }
//     ],
//     "calcby": {
//       "call": "percent",
//       "title": "Net Profit",
//       "value": "25.0"
//     },
//     "overhead": {
//       "id": "overhead",
//       "title": "Overhead",
//       "value": "57.3"
//     },
//     "debits": [
//       {
//         "title": "Commission",
//         "value": "0.0",
//         "id": "commission"
//       },
//       {
//         "title": "Fees",
//         "value": "0.0",
//         "id": "fees"
//       }
//     ],
//     "quantity": {
//       "title": "Quantity",
//       "value": "1",
//       "id": "quantity"
//     }
// }



