export const OVERHEAD = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 500 500";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};

	let iconColor = props.iconColor || "rgb(0,0,0)";


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>

			<path 
				fill={iconColor}
				fillRule="evenodd"
				clipRule="evenodd"
				d="M280.064,101.633c89.531,14.467,158.279,92.421,158.279,185.927 c0,45.982-16.637,88.199-44.184,120.963l-45.316-38.025c18.889-22.463,30.297-51.41,30.297-82.938	c0-60.781-42.367-111.979-99.076-125.59V101.633L280.064,101.633z M219.936,161.97c-56.708,13.61-99.074,64.809-99.074,125.59 c0,31.527,11.406,60.475,30.295,82.939l-45.315,38.023c-27.547-32.764-44.185-74.979-44.185-120.963 c0-93.506,68.749-171.46,158.279-185.927V161.97L219.936,161.97z M262.322,258.894c11.092,4.795,18.887,15.85,18.887,28.666 s-7.795,23.869-18.887,28.664v33.035c0,6.738-5.578,12.32-12.322,12.32c-6.776,0-12.321-5.543-12.321-12.32v-33.035 c-11.091-4.795-18.888-15.848-18.888-28.664s7.797-23.871,18.888-28.666V101.962l12.322-36.484l12.321,36.484V258.894 L262.322,258.894z"
			/>

		</svg>
		</div>

	);//return




}