import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';


import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';

import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation, 
    useHistory,  
    //useParams

} from "react-router-dom";


/*
import {

    //Header,
    Footer,
    //InfoBar

} from 'app-components/Prime/Commons';
*/


import {

    Stage,
    ScrollBox,
    Area,
    //AreaTitle

} from '../Styled';

//import StylScene from '../StylModules/Scene.module.css';
//import StylRows from '../StylModules/Rows.module.css';

import Loader from 'utils/components/Process/Loader';


import {LibCookie} from 'library';


import Logo from 'utils/components/Logo';



export const AuthLoginAccess = (props) => {


    // -- router -- //
    const location = useLocation(); 
    const history = useHistory();
    //let match = useRouteMatch();
    //let params = useParams();
 
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);



    // -- redux -- //
    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));
    

    // -- ref -- //
    const refHeader = useRef(null);
    const refArea = useRef(null);
    //const refC = useRef(null);



    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);
    //const [stageTopHeight, setStageTopHeight] = useState(50);
    //const [stageHeight, setStageHeight] = useState(50)
    //const [scrollboxHeight, setScrollboxHeight] = useState(50);

    useEffect(() => {


        if(!isLoaded)
        {
            setIsLoaded(true); 
            
            //--------------------------------------------------------------

            //var accessPoint;
            var accessEmail, accessToken, refreshToken;

                //accessPoint = false;
                accessEmail = false;
                accessToken = false;
                refreshToken = false;


            var hasCookie = false;
            var currentCookie = LibCookie.GetCookie("loginAccess");
            alert("currentCookie: "+currentCookie);
            if(currentCookie && currentCookie !== undefined)
            {
                currentCookie = currentCookie.split("-split-");

                //accessPoint = currentCookie[0];
                accessEmail = currentCookie[1];
                accessToken = currentCookie[2];
                refreshToken = currentCookie[3];

                hasCookie = true;

            }

            alert(""
            
                //+"\naccessPoint: "+accessPoint
                +"\naccessEmail: "+accessEmail
                +"\naccessToken: "+accessToken
                +"\nrefreshToken: "+refreshToken
        
            );



            // var test = true;
            // if(test)
            // {
            //     //accessPoint = currentCookie[0];
            //     accessEmail = "tom@theprofitcalculator.com";
            //     accessToken = "777777"
            //     refreshToken = "111111";

            //     hasCookie = true;
            // }


            if(hasCookie)
            {

                alert(""
                
                    //+"\naccessPoint: "+accessPoint
                    +"\naccessEmail: "+accessEmail
                    +"\naccessToken: "+accessToken
                    +"\nrefreshToken: "+refreshToken
            
                );


                //--------------
                //update
                //--------------

                if(accessEmail && accessEmail !== undefined)
                {
                    localStorage.accessEmail = accessEmail;
                }

                if(accessToken && accessToken !== undefined)
                {
                    localStorage.accessToken = accessToken;
                }

                if(refreshToken && refreshToken !== undefined)
                {
                    localStorage.refreshToken = refreshToken;
                }


                //--------------
                //delete cookie
                //--------------

                if(window.location.hostname === 'localhost')
                {

                    LibCookie.DeleteCookie({

                        name        :"loginAccess",
                        // value       :cookieValue,
                        // expiresType :"minutes",//days, hours, minutes
                        // expiresNum  :30,
                        path        :"/",
                        domain      :"",
                        //secure      :0

                    });


                }else{

                    LibCookie.DeleteCookie({

                        name        :"loginAccess",
                        // value       :cookieValue,
                        // expiresType :"minutes",//days, hours, minutes
                        // expiresNum  :2,
                        path        :"/",
                        domain      :"theprofitcalculator.com",
                        //secure      :0

                    });

                } 

    
            }

            //--------------------------------------------------------------
        

            var url;
            var backToLogin = true;
            if(backToLogin)
            {
                //alert("auth: "+auth);

                var basename = zoneObj.basename;

                // -- change location -- //

                url = '';
                // url = '/Login/';
                // //var url = basename + 'AuthArea/Login';
                // //var url = "/templates/AuthArea/FormLogin";
                // //history.replace(url);
                // //history.push(url);
                // //history.goBack();
                url = window.location.protocol + "//";

                if(window.location.hostname.match(/192.168/gi)
                || window.location.hostname.match(/localhost/gi)
                )
                {
                    url = url + window.location.hostname +":"+ window.location.port;
                }else{
                    url = url + window.location.hostname;
                }//=

                if(basename !== '')
                {
                    url = url + basename + '/';
                }else{
                    url = url + "/";
                }

                // if(basename !== '')
                // {
                //     url = url + basename + 'Login/';
                // }else{
                //     url = url + "/Login/";
                // }

                //alert("url:"+url);
                window.location.replace(url);//regular dom

            }

        }



    },[

        location,
        history,
        zoneObj,
        isLoaded
    ])




    return (

        <Stage
        //backgroundColor={"#edeef1"}
        //height={stageHeight}
        >

            <ScrollBox
            //backgroundColor={"#edeef1"}
            //height={scrollboxHeight}
            >


                <div
                ref={refHeader}
                id={'hdr'}
                style={{
                    backgroundColor:"transparent",
                    width:"100%",            
                    height:"auto",
                    margin:"0 0 0 0",
                    padding:"0 0 0 0",         
                    //position:"relative",
                    //floar:"left",

                }}
                >
                    {/* <Header 
                    
                        handle = {(v) => handle(v)}
                        databind = {{

                            primeProps  :props.primeProps,
                            headerCall  :headerCall,               
                            scrollAtTop :scrollAtTop, 
                            width       :viewWidth,
                            top         :headerBarTop,
                            placeholder :headerPlaceholder,
                            settings    :settings,

                            headerSizes     :headerSizes,
                            viewHeight      :viewHeight,
                            sceneArr        :sceneArr

                        }}


                        // headerCall={headerCall}                
                        // scrollAtTop={scrollAtTop}    
                        // width={viewWidth}
                        // top={headerBarTop}
                        // placeholder={headerPlaceholder} 
                        

                    /> */}


                    <Logo 
                        
                        call="theprofitcalculator"
                    
                    />


                </div>



                <Area
                ref={refArea}
                //backgroundColor={"darkblue"}
                maxWidth={1200+"px"}
                width={"96%"}
                height={"300px"}
                margin={"50px auto 0 auto"}
                >
                    <Loader 

                        call={"pageLoader"}
                        litedark={'dark'}
                        text={"Logging In"}

                    />

                </Area>

            </ScrollBox>


        </Stage>

    )




}



