import styled from "styled-components";


export const BarChart = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "1px solid lightgray",

    width:              props.width || "100%",
    height:             props.height || "100%",
    minHeight:          props.minHeight || "250px",

    

    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "0 0 0 0",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "20px",
    fontWeight:         props.fontWeight  || "500",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration  || "none",
    textColor:          props.textColor || "black"



    // border-bottom:${props => props.border};
    // border-left:${props => props.border};
    // border-right:${props => props.border};




    // cursor:pointer;



    // @media (max-width: 300px) {

    //     height:80px;
    // }



    // &:hover {

    //     background-color:#caffca;
    //     text-decoration:underline;
    // }


    // .Bars {

    //     background-color:transparent;
    //     width:100%;
    //     height:375px;
    //     position:absolute;
    //     top:0px;
    //     left:0px;
    //     z-index:3;

    // }
  



}))`


    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    height:${props => props.height};


    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;


    font-family:${props => props.fontFamily};
    font-size:${props => props.fontSize};
    font-weight:${props => props.fontWeight};
    text-align:${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.textColor};



`;







    //############################################################
    //############################################################

    export const Title = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "white",
        border:             props.border || "1px solid lightgray",

        width:              props.width || "100%",
        height:             props.height || "auto",

        margin:             props.margin || "0 0 0 0",
        padding:            props.padding || "10px 0 8px 0",

        fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
        fontSize:           props.fontSize || "20px",
        fontWeight:         props.fontWeight  || "500",
        textAlign:          props.textAlign || "center",
        textDecoration:     props.textDecoration  || "none",
        textColor:          props.textColor || "black"


    }))`


        background-color:${props => props.backgroundColor};


        width:${props => props.width};
        height:${props => props.height};

        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;
        float:left;

        overflow:hidden;

        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        text-align:${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.textColor};



    `;










    //############################################################
    //############################################################

    export const Content = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",
        border:             props.border || "1px solid lightgray",

        width:              props.width || "calc(100% - 20px)",
        height:             props.height || "auto",
        minHeight:          props.minHeight || "250px",

        margin:             props.margin || "0 0 0 10px",
        padding:            props.padding || "0 0 0 0"


    }))`


        background-color:${props => props.backgroundColor};


        width:${props => props.width};
        height:${props => props.height};
    
        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;
        float:left;

        overflow:hidden;

        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        text-align:${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.textColor};


    `;






    export const Markers = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",
        border:             props.border || "1px solid lightgray",

        width:              props.width || "30px",
        height:             props.height || "auto",
        minHeight:          props.minHeight || "250px",

        margin:             props.margin || "0 12px 0 0",
        padding:            props.padding || "0 0 0 0",


        fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
        fontSize:           props.fontSize || "14px",
        fontWeight:         props.fontWeight  || "500",
        textAlign:          props.textAlign || "right",
        textDecoration:     props.textDecoration  || "none",
        textColor:          props.textColor || "lightgray"


    }))`


        background-color:${props => props.backgroundColor};

        width:${props => props.width};
        height:${props => props.height};
    
        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;
        float:left;

        overflow:hidden;


        .MarkerItem {

            background-color:transparent;
            width:100%;
            height:15px;
            margin:0 0 0 0;
            padding:2px 0 3px 0;
            position:relative;
            float:left;

            font-family:${props => props.fontFamily};
            font-size:${props => props.fontSize};
            font-weight:${props => props.fontWeight};
            text-align:${props => props.textAlign};
            text-decoration:${props => props.textDecoration};
            color:${props => props.textColor};

        }

    `;






    export const List = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",
        border:             props.border || "1px solid lightgray",

        width:              props.width || "125px",
        height:             props.height || "auto",
        minHeight:          props.minHeight || "230px",

        margin:             props.margin || "0 20px 0 0",
        padding:            props.padding || "0 0 0 0",


        fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
        fontSize:           props.fontSize || "16px",
        fontWeight:         props.fontWeight  || "500",
        textAlign:          props.textAlign || "right",
        textDecoration:     props.textDecoration  || "none",
        textColor:          props.textColor || "black"


    }))`


        background-color:${props => props.backgroundColor};

        width:${props => props.width};
        height:${props => props.height};
    
        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;
        float:left;

        overflow:hidden;


        .ListItem {

            background-color:transparent;
            width:100%;
            height:auto;
            margin:0 0 0 0;
            padding:0 0 0 0;
            position:relative;
            float:left;

            font-family:${props => props.fontFamily};
            font-size:${props => props.fontSize};
            font-weight:${props => props.fontWeight};
            text-align:${props => props.textAlign};
            text-decoration:${props => props.textDecoration};
            color:${props => props.textColor};

        }

    `;


    export const Lines = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",
        borderTop:          props.borderTop || "0",
        borderRight:        props.borderRight || "0",

        width:              props.width || "calc(100% - 160px)",
        height:             props.height || "auto",
        minHeight:          props.minHeight || "230px",

        margin:             props.margin || "0 0 0 0",
        padding:            props.padding || "0 0 0 0",


        left:               props.left || "0px",
        top:                props.top || "0px"



    }))`


        background-color:${props => props.backgroundColor};
        border-top:${props => props.borderTop};
        border-right:${props => props.borderRight};

        width:${props => props.width};
        height:${props => props.height};
    
        padding:${props => props.padding};
        margin:${props => props.margin};


        position:absolute;
        top:${props => props.top};
        left:${props => props.left};
        z-index:3;

        overflow:hidden;



    `;


    export const Bars = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",
        border:             props.border || "1px solid lightgray",

        width:              props.width || "calc(100% - 160px)",
        height:             props.height || "auto",
        minHeight:          props.minHeight || "230px",

        margin:             props.margin || "0 0 0 0",
        padding:            props.padding || "0 0 0 0",

        left:               props.left || "0px",
        top:                props.top || "0px"

    }))`


        background-color:${props => props.backgroundColor};


        width:${props => props.width};
        height:${props => props.height};
        min-height:${props => props.minHeight};
    
        padding:${props => props.padding};
        margin:${props => props.margin};

        position:absolute;
        top:${props => props.top};
        left:${props => props.left};
        z-index:5;

        overflow:hidden;


        opacity:1.0;


    `;











    //############################################################
    //############################################################

    export const VerticalLine = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",
        border:             props.border || "1px solid lightgray",

        width:              props.width || "50px",
        height:             props.height || "100%",
        margin:             props.margin || "0 0 0 0",
        padding:            props.padding || "0 0 0 0",


        left:               props.left|| "0px",



    }))`

        background-color:${props => props.backgroundColor};
        border-left:${props => props.border};

        width:${props => props.width};
        height:${props => props.height};

        padding:${props => props.padding};
        margin:${props => props.margin};

        position:absolute;
        bottom:0px;
        left:${props => props.left};
        z-index:1;

    `;


    export const HorizontalLine = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",
        border:             props.border || "1px solid lightgray",

        width:              props.width || "100%",
        height:             props.height || "50px",
        margin:             props.margin || "0 0 0 0",
        padding:            props.padding || "0 0 0 0",

        bottom:             props.bottom || "0px",


    }))`

        background-color:${props => props.backgroundColor};
        border-bottom:${props => props.border};

        width:${props => props.width};
        height:${props => props.height};

        padding:${props => props.padding};
        margin:${props => props.margin};

        position:absolute;
        bottom:${props => props.bottom};
        left:0px;
        z-index:4;

    `;









    //############################################################
    //############################################################

    export const VerticalBar = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "lightseagreen",
        border:             props.border || "1px solid lightgray",

        width:              props.width || "100%",
        height:             props.height || "50%",
        margin:             props.margin || "0 0 0 0",
        padding:            props.padding || "0 0 0 0",

        fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
        fontSize:           props.fontSize || "20px",
        fontWeight:         props.fontWeight  || "500",
        textAlign:          props.textAlign || "left",
        textDecoration:     props.textDecoration  || "none",
        textColor:          props.textColor || "black",

        bottom:             props.bottom || "0px",
        left:               props.left || "31px"


    }))`


        background-color:transparent;


        width:${props => props.width};
        height:100%;
        min-height:25px;

        padding:${props => props.padding};
        margin:${props => props.margin};

        position:absolute;
        bottom:${props => props.bottom};
        left:${props => props.left};

        overflow:hidden;

        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        text-align:${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.textColor};



        .Track {

            background-color:transparent;
            width:${props => props.height};
            height:100%;
            position:relative;
            float:left;

        }

        .Track .Color {

            background-color:${props => props.backgroundColor};
            width:${props => props.width};
            height:${props => props.height};
            position:relative;
            float:left;

        }
        .Track .Filler {

            background-color:lightgray;
            width:${props => props.width};
            height:calc(100% - ${props => props.height});
            position:relative;
            float:left;

        }




    `;







    //############################################################
    //############################################################

    export const HorizontalBar = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "lightseagreen",
        border:             props.border || "1px solid lightgray",

        width:              props.width || "100%",
        height:             props.height || "100%",
        margin:             props.margin || "0 0 0 0",
        padding:            props.padding || "0 0 0 0",


        fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
        fontSize:           props.fontSize || "20px",
        fontWeight:         props.fontWeight  || "500",
        textAlign:          props.textAlign || "left",
        textDecoration:     props.textDecoration  || "none",
        textColor:          props.textColor || "black"



        // border-bottom:${props => props.border};
        // border-left:${props => props.border};
        // border-right:${props => props.border};




        // cursor:pointer;



        // @media (max-width: 300px) {

        //     height:80px;
        // }



        // &:hover {

        //     background-color:#caffca;
        //     text-decoration:underline;
        // }



    }))`


        background-color:transparent;

        width:100%;
        height:${props => props.height};
        min-height:3px;

        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;
        float:left;

        overflow:hidden;

        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        text-align:${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.textColor};



        .Track {

            background-color:transparent;
            width:100%;
            height:${props => props.height};
            position:relative;
            float:left;

        }
        .Track .Color {

            background-color:${props => props.backgroundColor};
            width:${props => props.width};
            height:${props => props.height};
            position:relative;
            float:left;

        }






    `;









    


    //############################################################
    //############################################################

    export const FlexVerticalContainer = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",
        border:             props.border || "1px solid lightgray",

        width:              props.width || "100%",
        height:             props.height || "auto",
        minHeight:          props.minHeight || "230px",

        margin:             props.margin || "0 0 0 0",
        padding:            props.padding || "0 0 0 0"

    }))`


        background-color:${props => props.backgroundColor};

        width:${props => props.width};
        height:${props => props.height};

        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;
        float:left;

        overflow:hidden;

        display: flex; 


    `;



        export const FlexVerticalTrack = styled.div.attrs(props => ({

            backgroundColor:    props.backgroundColor || "lightseagreen",
            border:             props.border || "1px solid lightgray",

            width:              props.width || "100%",
            height:             props.height || "auto",

            margin:             props.margin || "0 0 0 0",
            padding:            props.padding || "0 0 0 0"


            // For Odd Even

            // &:nth-of-type(odd){ 
            //     background: #85929E; 
            //     color: white; 
            // } 
            // &:nth-of-type(even){ 
            //     background: #A5DDEF; 
            //     color: green; 
            // } 


        }))`

            background-color:transparent;
            flex: 1; 

            &:nth-of-type(even){ 
                flex: 1;  
            }

            .Color {

                background-color:${props => props.backgroundColor};
                width:90%;
                height:${props => props.height};
                position:relative;
                float:left;

                margin-left:5%;

            }
            .Filler {

                background-color:transparent;
                width:90%;
                height:calc(100% - ${props => props.height});
                position:relative;
                float:left;

                margin-left:5%;

            }



        `;




