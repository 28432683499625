import React, { 

    useState, 
    useEffect, 
    //useRef

} from 'react';


import { 
    useSelector, 
    useDispatch 
} 
from 'react-redux';
import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';




import {
    //BrowserRouter as Router,
    // Switch,
    // Route,
    //Link,

    //useRouteMatch,
    useLocation, 
    useHistory,  
    //useParams


} from "react-router-dom";

import { useAuthentication } from 'utils/hooks/Authentication';






import Home from './Home';
//import PriceFormOrig from './PriceForm';

import {

    LoginAccess,
    Login,
    Logout,
    ForgotPassword, 
    Signup, 

    SignUpForm,
    SignUpWalk,
    CancelForm,
    DeleteForm,

    AccountStatus,

    AuthActions,  
    AuthPage,
    AuthRedirect


} from 'app-components/Zone/Auth';


import {

    // Mains
    //Calculators,
    //Calculator,
    // Account,
    // Schedule,
    // Overhead, 
    // Owners, 
    // Employees,
    // Expenses,
    
    // Reports,
    // ReportsCategory,

    // KnowledgeBase,

    // Templates,
    // TemplateCategory,

    Print,    
    Results,

    Sample,
    Trials,
    // Pricing,

    // Subs
    // PricingGuidelines,
    // PricingForm,



    //Originals
    // CompanyOrig,
    // CompanyOrigInfo,
    // CompanyOrigSchedule,

    // OwnersOrig,
    // OwnersOrigSummary,
    // OwnersOrigList,
    // OwnersOrigCatalog,

    // EmployeesOrig,
    // EmployeesOrigSummary,
    // EmployeesOrigList,
    // EmployeesOrigCatalog,

    // ExpensesOrig,
    // ExpensesOrigSummary,
    // ExpensesOrigList,
    // ExpensesOrigCharts,
    // ExpensesOrigCatalog


} from 'app-components/Zone/Scenes';





// import {

//     // Mains
//     Overview as CalculatorOverview,
//     Calculators,
//     Templates,

//     //Subs
//     TemplateCategory,


// } from 'app-components/Zone/Calculator';


import Calculator from 'app-components/Zone/Calculator';




// import {

//     // Mains
//     Overview as PricingOverview,
//     Calculators as PricingCalculators,
//     List as PricingList,
//     Templates as PricingTemplates,

//     //Subs
//     TemplateCategory as PricingTemplateCategory,


// } from 'app-components/Zone/Pricing';



import Pricing from 'app-components/Zone/Pricing';






import {

    // Mains
    Overview as SettingsOverview,
    Account,
    Schedule,
    Overhead, 
    Owners, 
    Employees,
    Expenses,
    // Reports,

    // //Subs
    // ReportsCategory,


} from 'app-components/Zone/Settings';



import {

    // Mains
    Overview as KnowledgeBaseOverview,
    Page as KnowledgeBasePage,
    Glossary,
    Icons

} from 'app-components/Zone/KnowledgeBase';



import {

    Main as Reports,
    Category as ReportsCategory

} from 'app-components/Zone/Reports';



import {

    // Mains
    Overview as StylingOverview,

    LargeLook1,
    LargeLook2,
    LargeLook3,
    LargeLook4,
    LargeLook5,

    ColorA,
    ColorB,
    ColorC,
    ColorD

} from 'app-components/Zone/Styling';




import Loader from 'utils/components/Process/Loader';
import Messngr from 'utils/components/Process//Messngr';
import Readr from 'utils/components/Process//Readr';


import { Calculate } from 'app-components/Zone/_Connect/Calculate';
import InitItems from 'app-components/Zone/_Connect/Calculate/InitItems';
import { 

    DefLocalItems,

} from 'app-components/Prime/VPdata/DefLocalItems.js';


import ApiResources from 'utils/components/Pages/ApiResources';


//------------------------
//Axios Stuff
//------------------------

import axios from "axios";

const queryString = params =>
    Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join("&")

const createUrl = (url, queryOptions) => {

    if(Object.keys(queryOptions).length > 0)
    {
        url = url + "?" + queryString(queryOptions);
    }
    return url

}

const createPostParams = (bodyOptions) => {

    var ret;
    var params = "";
    var count = 0;
    for(var keyname in bodyOptions)
    {
        if(count === 0)
        {
            params += keyname+"="+bodyOptions[keyname];
        } else {
            params += "&"+keyname+"="+bodyOptions[keyname];
        }
        count++;
    }

    ret = params;
    if(ret === "") ret = false;
    return ret;

}





const Index = (props) => {



    var apiDomain;
    var hostname;
    //var hostname = window.location.hostname;//returns the domain name of the web host
    var protocol = window.location.protocol;//returns the web protocol used (http: or https:)

    if(window.location.hostname.match(/192.168/gi))
    {
        hostname = ApiResources.network['3001'].vpc;
        apiDomain = protocol + "//" + hostname + "/";
    }
    else
    if(window.location.hostname.match(/localhost/gi))
    {
        hostname = ApiResources.local['3001'].vpc;
        apiDomain = protocol + "//" + hostname + "/";
    }else{
        hostname = ApiResources.aws['x'].vpc;
        apiDomain = "https://" + hostname + "/";
    }//=




    // -- router -- //
    const location = useLocation(); 
    const history = useHistory();
    //let match = useRouteMatch();
    //let params = useParams();


    //console.log("match.path" + match.path);



    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);




    // -- redux -- //
    const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));







    //====================================================
    //Authentication
    //====================================================

    const auth = useAuthentication();
    const [showAuth,setShowAuth] = useState(false);

    useEffect(() => {


        dispatch(ModifyZoneStore({

            type:"UPDATE_ZONE",//UPDATE_ZONE or DELETE_ZONE
            item:{
                key     :"prime_stage",
                value   :"dim"
            }
    
        })); 


        //console.log("auth: "+auth+"\nshowAuth: "+showAuth);

        if(!auth)
        {
            if(!showAuth) 
            {
                setShowAuth(true);
                history.push("/Login/");
            }

        }else{
            //console.log("Authenticated - " + location.pathname)

            if(showAuth) 
            {
                setShowAuth(false);
            }

        }


    
    },[

        location,
        history,
        dispatch,
        auth,
        showAuth

    ])








    //#########################################################
    //#########################################################

    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);
    const [isError, setIsError] = useState(false);
    const [message, setMessage] = useState("Loading...");

  
    useEffect(() => {
        

        const runAxios = (eff) => {


            let url = eff.resrc;
            let options = eff.options;


            let result;

            let axiosParams;
            if(options.method.match(/GET/gi))
            {
                axiosParams = {
                    method: "GET",
                    url: createUrl(url, options.query),
                }
                
            }
            else
            if(options.method.match(/POST/gi))
            {

                axiosParams = {

                    method: "POST",
                    url: createUrl(url, options.query),
                    data: createPostParams(options.body),
                    headers: {

                        //'Content-Type': "application/json",
                        'Content-Type'  : 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                        //'Authorization' : 'Bearer '+localStorage.accessToken
                    },
                    // responseType:'json'
                }

            }

            // Content-Type
            //     'application/x-www-form-urlencoded' 
            //     'application/json' 
            
            // responseType 
            //     'arraybuffer', 
            //     'blob', 
            //     'document', 
            //     'json', 
            //     'text', 
            //     'stream'


            axios(axiosParams)
            .then(response => {

                // console.log("response: "+JSON.stringify(response,null,2));
                // console.log("response.data: "+response.data);
               // alert(response.data);

                return response.data;

            })
            .then(data =>  {

                // console.log("Object prototype: "+Object.prototype.toString.call(data));
                // console.log("data.success: "+data.success);
                // console.log("data.result: "+data.result);
                // console.log("data.result: "+JSON.stringify(data.result,null,2));

                // alert('success');

                /*
                result={
                    "success": true,
                    "result": {
                        "tokenError": {
                            "name": "TokenExpiredError",
                            "message": "jwt expired",
                            "expiredAt": "2020-12-27T18:13:18.000Z"
                        },
                        "authorized": false,
                        "user": false
                    }
                }

                */
                //alert(result.success);



     

                // var foundOwnr;
                // foundOwnr = false;

                var runInit;
                runInit = false;


                var keyname;
                var value;

                if(data.success)
                {
                    result = data.result;
                    value = result.dash_items;



                    // set default account_items
                    // if(Object.keys(value.account_items).length === 0
                    // )
                    // {
                    //     for(keyname in DefLocalItems.account_items)
                    //     {
                    //         value.account_items[keyname] = DefLocalItems.account_items[keyname];
                    //     }
                    //     //alert("ACCOUNT ITEMS: "+JSON.stringify(value.account_items,null,2));

                    // }


                    console.log("auth_account...\n"+JSON.stringify(value.auth_account,null,2));
                    //alert('auth_account');


                    // auth_account...
                    // {
                    //     "auth": {
                    //         "passwordHash": "$2a$10$YOTEm42nneWgIqJH7tDAT.DzZnnKXaTMFW6RIaHBgO117WLXKk9dq",
                    //         "sites": [
                    //         "theprofitcalculator.com"
                    //         ],
                    //         "accountId": "20210623050347-1286",
                    //         "createdAt": "2021-06-23 01:03:48",
                    //         "emailId": "theprofitcalculator~z21hawwuy29t",
                    //         "sk": "theprofitcalculator@gmail.com",
                    //         "refreshToken": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzaXRlIjoidGhlcHJvZml0Y2FsY3VsYXRvci5jb20iLCJuYW1lIjoidGhlcHJvZml0Y2FsY3VsYXRvcn56MjFoYXd3dXkyOXQiLCJlbWFpbCI6InRoZXByb2ZpdGNhbGN1bGF0b3JAZ21haWwuY29tIiwiaWF0IjoxNjI0NDY0MTk2fQ.J8QbD5X3Ac_bTjzLxxr-y0QNrkIqRc9sOeY6-TeLQx8",
                    //         "pk": "theprofitcalculator.com"
                    //     },
                    //     "account": {
                    //         "sk": "theprofitcalculator@gmail.com",
                    //         "address": "",
                    //         "accountId": "20210623050347-1286",
                    //         "company": "",
                    //         "pk": "theprofitcalculator.com",
                    //         "email": "theprofitcalculator@gmail.com"
                    //     }
                    // }





                    // var authAccount = {};
                    // for(keyname in DefLocalItems.auth_account.auth)//auth
                    // {
                    //     authAccount[keyname] = DefLocalItems.auth_account.auth[keyname];
                    // }
                    // for(keyname in DefLocalItems.auth_account.account)//account
                    // {
                    //     authAccount[keyname] = DefLocalItems.auth_account.account[keyname];
                    // }


                    // // set auth_account
                    // if(Object.keys(value.auth_account).length > 0
                    // )
                    // {
                    //     if(value.auth_account.auth && value.auth_account.auth !== undefined)
                    //     {
 
                    //         authAccount.login.value.email.value = value.auth_account.auth.sk

                    //     }
                    //     if(value.auth_account.account && value.auth_account.account !== undefined)
                    //     {
                    //         authAccount.company.value.email.value = value.auth_account.account.email
                    //         if(value.auth_account.account.data !== '')
                    //         {
                    //             authAccount.company.value.company.value = value.auth_account.account.data.company
                    //             authAccount.company.value.address.value = value.auth_account.account.data.address
                    //             authAccount.company.value.city.value = value.auth_account.account.data.city
                    //             authAccount.company.value.state.value = value.auth_account.account.data.state
                    //             authAccount.company.value.country.value = value.auth_account.account.data.country
                    //             authAccount.company.value.zipcode.value = value.auth_account.account.data.zipcode
                    //             authAccount.company.value.phone.value = value.auth_account.account.data.phone
                    //         }

                    //     }

                    //     console.log("authAccount: "+JSON.stringify(authAccount,null,2));

                    // }




                    // set default form_items
                    if(Object.keys(value.form_items).length === 0
                    )
                    {
                        for(keyname in DefLocalItems.form_items)
                        {
                            value.form_items[keyname] = DefLocalItems.form_items[keyname];
                        }
                        //console.log("FORM ITEMS: "+JSON.stringify(value.form_items,null,2));

                    }else{

                        // foundOwnr = false;
                        // for(keyname in value.form_items)
                        // {
                        //     if(keyname === 'ownr-1') {foundOwnr = true; break;}
                        // }
                        // if(!foundOwnr) value.form_items['ownr-1'] = DefLocalItems.form_items['ownr-1'];
                    }





                    if(Object.keys(value.form_items).length > 0
                    )
                    {
                        if(Object.keys(value.result_items).length === 0
                        )
                        {
                            runInit = true;

                        }else{

                            //if(!foundOwnr) runInit = true;
                        }


                        if(runInit)
                        {

                            //alert('runInit');
                            var dash_items = InitItems({

                                call            :'init',
                                currentKey      :false,
                                form_items      :value.form_items,
                                result_items    :value.result_items  
                            });
                            value.result_items = dash_items.resultItems;

                            console.log("RESULT ITEMS: "+JSON.stringify(dash_items.resultItems,null,2));

                        }
                        
                    }

                    // console.log("VALUE FORM ITEMS: "+JSON.stringify(value.form_items,null,2));
                    // console.log("VALUE RESULT ITEMS: "+JSON.stringify(value.result_items,null,2));




                    // if(!runInit)
                    // {
                    //     alert('Verify on fetch');

                    // }



                    dispatch(ModifyZoneStore({

                        type:"UPDATE_ZONE",//UPDATE_ZONE or DELETE_ZONE
                        item:{
                            key     :"auth_account",
                            value   :value.auth_account
                        }
            
                    })); 

                    // dispatch(ModifyZoneStore({

                    //     type:"UPDATE_ZONE",//UPDATE_ZONE or DELETE_ZONE
                    //     item:{
                    //         key     :"account",
                    //         value   :authAccount
                    //     }
            
                    // })); 


                    dispatch(ModifyZoneStore({

                        type:"UPDATE_ZONE",//UPDATE_ZONE or DELETE_ZONE
                        item:{
                            key     :"calc_dash",
                            value   :{

                                form_items      :value.form_items,
                                result_items    :value.result_items
        
                            }
                        }
            
                    })); 


                    dispatch(ModifyZoneStore({

                        type:"UPDATE_ZONE",//UPDATE_ZONE or DELETE_ZONE
                        item:{
                            key     :"calcs",
                            value   :value.calcs
                        }
            
                    })); 



                    setIsError(false);

                }



            })
            .catch(error =>  {


                console.log("FAILED - Zone index.js");
                console.log("ERROR: "+error);
                //alert('error');


                dispatch(ModifyZoneStore({

                    type:"UPDATE_ZONE",//UPDATE_ZONE or DELETE_ZONE
                    item:{
                        key     :"auth_account",
                        value   :false
                    }
        
                })); 

                dispatch(ModifyZoneStore({

                    type:"UPDATE_ZONE",//UPDATE_ZONE or DELETE_ZONE
                    item:{
                        key     :"calc_dash",
                        value   :false
                    }
        
                })); 

                setIsError(true);
                setMessage("FAILED: "+error);


            });




        };
        
        
        //#############################################################################
        //#############################################################################

        /**
         * 
         * 
         * To block multiple hits to the server 
         * 
         * 1) Set response to "processing" or "not null" once fetch begins - this prevents a function rerun
         * 2) Keep loading set to true until fetch is complete
         * 3) On page component check that loading is "false" before calling dispatch
         * 
         * 
         * NOTE: There are two version async and non async - no differcene I see between the two
         * use the non async version
         * 
         * 
         */




        var runit = false;
        if(!isLoaded)
        {
            runit = true;
        }
        if(runit)
        {
            setIsLoaded(true);

            // hostname = ApiResources.aws['x'].vpc;
            // apiDomain = "https://" + hostname + "/";
            //var resrc = apiDomain + "fetch-siteplan";
            //var resrc = apiDomain + "fetch-blueprint";
            //var resrc = apiDomain + "fetch-calc-dash";
            //var resrc = apiDomain + "fetch-calculators"
            //var resrc = apiDomain + "fetch-zone-theprofitcalculator";
            var resrc = apiDomain + "tpc-fetch";



            var site = 'powerdigitalmedia.net';
            var email = false;

            if(window.location.hostname)
            {
                site = window.location.hostname;
            }
            if(localStorage.accessEmail && localStorage.accessEmail !== undefined)
            {
                email = localStorage.accessEmail;
            }


            // var href = window.location.href;                //returns the href (URL) of the current page
            // var host = window.location.host;                //returns the domain with port number
            // var hostname = window.location.hostname;        //returns the domain name of the web host
            // var pathname = window.location.pathname;        //returns the path and filename of the current page
            // var protocol = window.location.protocol;        //returns the web protocol used (http: or https:)
            // var assign = window.location.assign;            //loads a new document
            
            // alert(""
            // +"\nlocation: "+JSON.stringify(location,null,2)
            // +"\nhostname: "+window.location.hostname
            // +"\nsite: "+site
            // +"\nemail: "+email
            // +"\naccessEmail: "+localStorage.accessEmail
            // +"\naccessToken: "+localStorage.accessToken 
            // +"\nrefreshToken: " +localStorage.refreshToken
            // );

        
            runAxios({

                resrc:resrc,
                options:{

                    method:"POST",
                    body:{
                        site:site,
                        email:email,
                        tree:"app",
                        route:'app'
    
                    },
                    query:{}

                }

                    
            });


        }


    },
    [
        location,
        isLoaded,
        dispatch,
        zoneObj,
        apiDomain

    ])







    // -- handle -- //

    const handleErrorMessage = () => {


        //alert("Zone - handleErrorMessage");

        setIsLoaded(true);
        setIsError(false);
        setMessage("Moving Forward");
    
    };




    //#########################################################
    //#########################################################

    var currentZone = pathname;
    currentZone = currentZone.replace(/\//gi,"-");
    currentZone = currentZone.replace(/ /gi,"-");

    /*
    // -- view currentZone redux items -- //

    var regx = new RegExp(currentZone,"gi");
    for(var keyname in zoneObj)
    {
        if(keyname.match(regx))
        {
            console.log(keyname + " " +zoneObj[keyname]);
        }
    }
    */






    if(auth && auth === 'inProcess')
    {

        return (


            <Loader 
    
                handle={(v) => props.handle(v)}
                data={{

                    isError: false,
                    errorType: 'process',
                    isTimed: false,
                    items:[ 
            
                        "Authenticating..."
            
                    ]
            
                }}
            
            />


        )


  
    }
    else
    if(showAuth)
    {

        return (

            <div>

            {
            // location.pathname.match(/AuthActions/gi)  
            // ?

            //     <AuthActions
            //     controlsHeightInt={props.controlsHeightInt}
            //     scrollboxHeight={props.scrollboxHeight}
            //     handle = {(v) => props.handle(v)} 
            //     primeProps = {props}
            //     />
        
            // :
            // location.pathname.match(/AuthPage/gi)  
            // ?

            //     <AuthPage
            //     controlsHeightInt={props.controlsHeightInt}
            //     scrollboxHeight={props.scrollboxHeight}
            //     handle = {(v) => props.handle(v)} 
            //     primeProps = {props}
            //     />

            // :
            location.pathname.match(/LoginAccess/gi)  
            ?

                <LoginAccess
                controlsHeightInt={props.controlsHeightInt}
                scrollboxHeight={props.scrollboxHeight}
                handle = {(v) => props.handle(v)} 
                primeProps = {props}
                />

            :
            location.pathname.match(/Login/gi)  
            ?

                <Login
                controlsHeightInt={props.controlsHeightInt}
                scrollboxHeight={props.scrollboxHeight}
                handle = {(v) => props.handle(v)} 
                primeProps = {props}
                />

            :
            location.pathname.match(/ForgotPassword/gi)  
            ?

                <ForgotPassword
                controlsHeightInt={props.controlsHeightInt}
                scrollboxHeight={props.scrollboxHeight}
                handle = {(v) => props.handle(v)} 
                primeProps = {props}
                />
        
            :
            location.pathname.match(/AccountStatus/gi)  
            ?

                <AccountStatus
                controlsHeightInt={props.controlsHeightInt}
                scrollboxHeight={props.scrollboxHeight}
                handle = {(v) => props.handle(v)} 
                primeProps = {props}
                />
        
            :
            // location.pathname.match(/Logout/gi)  
            // ?

            //     <Logout
            //     controlsHeightInt={props.controlsHeightInt}
            //     scrollboxHeight={props.scrollboxHeight}
            //     handle = {(v) => props.handle(v)} 
            //     primeProps = {props}
            //     />
        
            // :
            // location.pathname.match(/SignUpForm/gi)  
            // ?

            //     <SignUpForm
            //     controlsHeightInt={props.controlsHeightInt}
            //     scrollboxHeight={props.scrollboxHeight}
            //     handle = {(v) => props.handle(v)} 
            //     primeProps = {props}
            //     />
        
            // :
            // location.pathname.match(/Signup/gi) && !location.pathname.match(/SignUpForm/gi) 
            // ?

            //     <Signup
            //     controlsHeightInt={props.controlsHeightInt}
            //     scrollboxHeight={props.scrollboxHeight}
            //     handle = {(v) => props.handle(v)} 
            //     primeProps = {props}
            //     />
        
            // :
            // location.pathname.match(/CancelForm/gi)  
            // ?

            //     <CancelForm
            //     controlsHeightInt={props.controlsHeightInt}
            //     scrollboxHeight={props.scrollboxHeight}
            //     handle = {(v) => props.handle(v)} 
            //     primeProps = {props}
            //     />
        
            // :
            // location.pathname.match(/DeleteForm/gi)  
            // ?

            //     <DeleteForm
            //     controlsHeightInt={props.controlsHeightInt}
            //     scrollboxHeight={props.scrollboxHeight}
            //     handle = {(v) => props.handle(v)} 
            //     primeProps = {props}
            //     />
        
            // :

                // <div style={{

                //     backgrundColor:"blue",
                //     width:"100%",
                //     height:"550px"


                // }}></div>



                <AuthRedirect
                controlsHeightInt={props.controlsHeightInt}
                scrollboxHeight={props.scrollboxHeight}
                handle = {(v) => props.handle(v)} 
                primeProps = {props}
                />



            }
            </div>


        );




    }
    else
    if(!isLoaded)
    {


        return ( 

            <div>
    
                <Loader 
    
                    handle={(v) => props.handle(v)}
                    data={{

                        isError: false,
                        errorType: 'process',
                        isTimed: false,
                        items:[ 
                
                            "LOADING..."
                
                        ]
                
                    }}
                
                />
    
            </div>
    
        );



    }
    else
    if(isError)
    {


        return ( 

            <div>

                <Messngr 

                    handle={(v) => handleErrorMessage(v)}
                    data={{

                        isError     : true,
                        errorType   : "",
                        isTimed     : false,
                        items       : [{

                            call:'span',
                            text:message
                        }]
                
                    }}
                
                />
            
            </div>
    
        );


    }
    else
    {




        return (


            <div>


                {zoneObj[currentZone + "_runCalculate"]
                ? 
                    <div>

                        <Loader/> 
                        <Calculate
        
                            handle={(v) => props.handle(v)}  
                            postTo={zoneObj[currentZone + "_postTo"]}  
                            call={zoneObj[currentZone + "_call"]} 
                            currentKey={zoneObj[currentZone + "_currentKey"]} 

                        /> 

                    </div>


                : null}

                {zoneObj[currentZone + "_runReadr"]
                ? 
                    
                    <Readr 

                        handle={(v) => props.handle(v)}
                        data={zoneObj[currentZone + "_readrData"]}
                    
                    />


                : null}


                {zoneObj[currentZone + "_runMessngr"]
                ? 

                    <Messngr 

                        handle={(v) => props.handle(v)}
                        data={zoneObj[currentZone + "_messngrData"]}
                    
                    />

                : null}



                




            {



                //#########################################################
                //#########################################################
                //#########################################################

                //-----------------
                // Auth Pages
                //-----------------


                location.pathname.match(/AuthActions/gi)  
                ?
    
                    <AuthActions
                    controlsHeightInt={props.controlsHeightInt}
                    scrollboxHeight={props.scrollboxHeight}
                    handle = {(v) => props.handle(v)} 
                    primeProps = {props}
                    />
            
                :
                location.pathname.match(/AuthPage/gi)  
                ?

                    <AuthPage
                    controlsHeightInt={props.controlsHeightInt}
                    scrollboxHeight={props.scrollboxHeight}
                    handle = {(v) => props.handle(v)} 
                    primeProps = {props}
                    />

                :
                location.pathname.match(/LoginAccess/gi)  
                ?
    
                    <LoginAccess
                    controlsHeightInt={props.controlsHeightInt}
                    scrollboxHeight={props.scrollboxHeight}
                    handle = {(v) => props.handle(v)} 
                    primeProps = {props}
                    />
    
                :
                // location.pathname.match(/Login/gi)  
                // ?

                //     <Login
                //     controlsHeightInt={props.controlsHeightInt}
                //     scrollboxHeight={props.scrollboxHeight}
                //     handle = {(v) => props.handle(v)} 
                //     primeProps = {props}
                //     />

                // :
                // location.pathname.match(/ForgotPassword/gi)  
                // ?
    
                //     <ForgotPassword
                //     controlsHeightInt={props.controlsHeightInt}
                //     scrollboxHeight={props.scrollboxHeight}
                //     handle = {(v) => props.handle(v)} 
                //     primeProps = {props}
                //     />
            
                // :
                location.pathname.match(/Logout/gi)  
                ?

                    <Logout
                    controlsHeightInt={props.controlsHeightInt}
                    scrollboxHeight={props.scrollboxHeight}
                    handle = {(v) => props.handle(v)} 
                    primeProps = {props}
                    />
            
                :
                location.pathname.match(/SignUpForm/gi)  
                ?

                    <SignUpForm
                    controlsHeightInt={props.controlsHeightInt}
                    scrollboxHeight={props.scrollboxHeight}
                    handle = {(v) => props.handle(v)} 
                    primeProps = {props}
                    />
            
                :
                location.pathname.match(/SignUpWalk/gi)  
                ?

                    <SignUpWalk
                    controlsHeightInt={props.controlsHeightInt}
                    scrollboxHeight={props.scrollboxHeight}
                    handle = {(v) => props.handle(v)} 
                    primeProps = {props}
                    />
            
                :
                location.pathname.match(/Signup/gi) 
                && !location.pathname.match(/SignUpForm/gi)
                && !location.pathname.match(/SignUpWalk/gi) 
                ?

                    <Signup
                    controlsHeightInt={props.controlsHeightInt}
                    scrollboxHeight={props.scrollboxHeight}
                    handle = {(v) => props.handle(v)} 
                    primeProps = {props}
                    />
            
                :
                location.pathname.match(/CancelForm/gi)  
                ?
    
                    <CancelForm
                    controlsHeightInt={props.controlsHeightInt}
                    scrollboxHeight={props.scrollboxHeight}
                    handle = {(v) => props.handle(v)} 
                    primeProps = {props}
                    />
            
                :
                location.pathname.match(/DeleteForm/gi)  
                ?
    
                    <DeleteForm
                    controlsHeightInt={props.controlsHeightInt}
                    scrollboxHeight={props.scrollboxHeight}
                    handle = {(v) => props.handle(v)} 
                    primeProps = {props}
                    />
            
                :





                //#########################################################
                //#########################################################
                //#########################################################

                //-----------------
                // Dynamic Pages
                //-----------------


                // location.pathname.match(/Calculator\/Templates\/.*\/.*\/.*\/.*\//gi) 
                // || location.pathname.match(/Calculator\/Templates\/.*\/.*\/.*\//gi) 
                // || location.pathname.match(/Calculator\/Templates\/.*\/.*\//gi) 
                // || location.pathname.match(/Calculator\/Templates\/.*\//gi) 
                // ?

                //     <TemplateCategory
                //     controlsHeightInt={props.controlsHeightInt}
                //     scrollboxHeight={props.scrollboxHeight}
                //     handle = {(v) => props.handle(v)} 
                //     primeProps = {props}
                //     />
            
                // :

                // location.pathname.match(/Pricing\/Templates\/.*\/.*\/.*\/.*\//gi) 
                // || location.pathname.match(/Pricing\/Templates\/.*\/.*\/.*\//gi) 
                // || location.pathname.match(/Pricing\/Templates\/.*\/.*\//gi) 
                // || location.pathname.match(/Pricing\/Templates\/.*\//gi) 
                // ?

                //     <PricingTemplateCategory
                //     controlsHeightInt={props.controlsHeightInt}
                //     scrollboxHeight={props.scrollboxHeight}
                //     handle = {(v) => props.handle(v)} 
                //     primeProps = {props}
                //     />
            
                // :




                // location.pathname.match(/Templates\/.*\/.*\/.*\/.*\//gi) 
                // || location.pathname.match(/Templates\/.*\/.*\/.*\//gi) 
                // || location.pathname.match(/Templates\/.*\/.*\//gi) 
                // || location.pathname.match(/Templates\/.*\//gi) 
                // ?

                //     <TemplateCategory
                //     controlsHeightInt={props.controlsHeightInt}
                //     scrollboxHeight={props.scrollboxHeight}
                //     handle = {(v) => props.handle(v)} 
                //     primeProps = {props}
                //     />
            
                // :


    

                location.pathname.match(/Reports\/.*\/.*\/.*\/.*\//gi) 
                || location.pathname.match(/Reports\/.*\/.*\/.*\//gi) 
                || location.pathname.match(/Reports\/.*\/.*\//gi) 
                || location.pathname.match(/Reports\/.*\//gi) 
                ?

                    <ReportsCategory
                    controlsHeightInt={props.controlsHeightInt}
                    scrollboxHeight={props.scrollboxHeight}
                    handle = {(v) => props.handle(v)} 
                    primeProps = {props}
                    />
            
                :








                //#########################################################
                //#########################################################
                //#########################################################

                //-----------------
                // Sub Pages
                //-----------------


                // -- Calculator -- //

                // location.pathname.match(/Calculators\/Calculator/gi)  
                // ?

                //     <Calculator
                //     controlsHeightInt={props.controlsHeightInt}
                //     scrollboxHeight={props.scrollboxHeight}
                //     handle = {(v) => props.handle(v)} 
                //     primeProps = {props}
                //     />
            
                // :


                // // -- Pricing -- //

                // location.pathname.match(/Pricing\/Guidelines/gi)  
                // ?

                //     <PricingGuidelines
                //     controlsHeightInt={props.controlsHeightInt}
                //     scrollboxHeight={props.scrollboxHeight}
                //     handle = {(v) => props.handle(v)} 
                //     primeProps = {props}
                //     />
            
                // :
                // location.pathname.match(/Pricing\/Form/gi)  
                // ?

                //     <PricingForm
                //     controlsHeightInt={props.controlsHeightInt}
                //     scrollboxHeight={props.scrollboxHeight}
                //     handle = {(v) => props.handle(v)} 
                //     primeProps = {props}
                //     />
            
                // :


                // -- Company -- //

                // location.pathname.match(/Company\/Info/gi)  
                // ?

                //     <CompanyInfo
                //     controlsHeightInt={props.controlsHeightInt}
                //     scrollboxHeight={props.scrollboxHeight}
                //     handle = {(v) => props.handle(v)} 
                //     primeProps = {props}
                //     />

                // :
                // location.pathname.match(/Company\/Schedule/gi)  
                // ?

                //     <CompanySchedule
                //     controlsHeightInt={props.controlsHeightInt}
                //     scrollboxHeight={props.scrollboxHeight}
                //     handle = {(v) => props.handle(v)} 
                //     primeProps = {props}
                //     />

                // :







                // -- Calculators - Group -- //

                // location.pathname.match(/Calculator\/Calculators/gi)   
                // ?

                //     <Calculators
                //     controlsHeightInt={props.controlsHeightInt}
                //     scrollboxHeight={props.scrollboxHeight}
                //     handle = {(v) => props.handle(v)} 
                //     primeProps = {props}
                //     />

                // :
                // location.pathname.match(/Calculator\/Templates/gi)  
                // ?

                //     <Templates
                //     controlsHeightInt={props.controlsHeightInt}
                //     scrollboxHeight={props.scrollboxHeight}
                //     handle = {(v) => props.handle(v)} 
                //     primeProps = {props}
                //     />

                // :






                // -- Pricing - Group -- //

                // location.pathname.match(/Pricing\/Calculators/gi)   
                // ?

                //     <PricingCalculators
                //     controlsHeightInt={props.controlsHeightInt}
                //     scrollboxHeight={props.scrollboxHeight}
                //     handle = {(v) => props.handle(v)} 
                //     primeProps = {props}
                //     />

                // :
                // location.pathname.match(/Pricing\/List/gi)   
                // ?

                //     <PricingList
                //     controlsHeightInt={props.controlsHeightInt}
                //     scrollboxHeight={props.scrollboxHeight}
                //     handle = {(v) => props.handle(v)} 
                //     primeProps = {props}
                //     />

                // :
                // location.pathname.match(/Pricing\/Templates/gi)  
                // ?

                //     <PricingTemplates
                //     controlsHeightInt={props.controlsHeightInt}
                //     scrollboxHeight={props.scrollboxHeight}
                //     handle = {(v) => props.handle(v)} 
                //     primeProps = {props}
                //     />

                // :






                // -- Dashboard - Group -- //

                location.pathname.match(/Settings\/Account/gi)  
                ?

                    <Account
                    controlsHeightInt={props.controlsHeightInt}
                    scrollboxHeight={props.scrollboxHeight}
                    handle = {(v) => props.handle(v)} 
                    primeProps = {props}
                    />

                :
                location.pathname.match(/Settings\/Schedule/gi)  
                ?

                    <Schedule
                    controlsHeightInt={props.controlsHeightInt}
                    scrollboxHeight={props.scrollboxHeight}
                    handle = {(v) => props.handle(v)} 
                    primeProps = {props}
                    />

                :
                location.pathname.match(/Settings\/Overhead/gi)  
                ?

                    <Overhead
                    controlsHeightInt={props.controlsHeightInt}
                    scrollboxHeight={props.scrollboxHeight}
                    handle = {(v) => props.handle(v)} 
                    primeProps = {props}
                    />

                :
                location.pathname.match(/Settings\/Owners/gi)  
                ?

                    <Owners
                    controlsHeightInt={props.controlsHeightInt}
                    scrollboxHeight={props.scrollboxHeight}
                    handle = {(v) => props.handle(v)} 
                    primeProps = {props}
                    />
            
                :
                location.pathname.match(/Settings\/Employees/gi)  
                ?

                    <Employees
                    controlsHeightInt={props.controlsHeightInt}
                    scrollboxHeight={props.scrollboxHeight}
                    handle = {(v) => props.handle(v)} 
                    primeProps = {props}
                    />
            
                :
                location.pathname.match(/Settings\/Expenses/gi)  
                ?

                    <Expenses
                    controlsHeightInt={props.controlsHeightInt}
                    scrollboxHeight={props.scrollboxHeight}
                    handle = {(v) => props.handle(v)} 
                    primeProps = {props}
                    />
            
                :







                // -- KnowledgeBase - Group -- //

                location.pathname.match(/Knowledge Base\/Glossary/gi)  
                ?

                    <Glossary
                    controlsHeightInt={props.controlsHeightInt}
                    scrollboxHeight={props.scrollboxHeight}
                    handle = {(v) => props.handle(v)} 
                    primeProps = {props}
                    />
                :
                location.pathname.match(/Knowledge Base\/Icons/gi)  
                ?

                    <Icons
                    controlsHeightInt={props.controlsHeightInt}
                    scrollboxHeight={props.scrollboxHeight}
                    handle = {(v) => props.handle(v)} 
                    primeProps = {props}
                    />
                :
                location.pathname.match(/Knowledge Base\/.*\/.*\/.*\/.*\//gi) 
                || location.pathname.match(/Knowledge Base\/.*\/.*\/.*\//gi) 
                || location.pathname.match(/Knowledge Base\/.*\/.*\//gi) 
                || location.pathname.match(/Knowledge Base\/.*\//gi) 
                ?

                    <KnowledgeBasePage
                    controlsHeightInt={props.controlsHeightInt}
                    scrollboxHeight={props.scrollboxHeight}
                    handle = {(v) => props.handle(v)} 
                    primeProps = {props}
                    />
            
                :









                // -- Page Looks - Group -- //

                location.pathname.match(/Styling\/LargeLook1/gi)  
                ?

                    <LargeLook1
                    controlsHeightInt={props.controlsHeightInt}
                    scrollboxHeight={props.scrollboxHeight}
                    handle = {(v) => props.handle(v)} 
                    primeProps = {props}
                    />
                :
                location.pathname.match(/Styling\/LargeLook2/gi)  
                ?

                    <LargeLook2
                    controlsHeightInt={props.controlsHeightInt}
                    scrollboxHeight={props.scrollboxHeight}
                    handle = {(v) => props.handle(v)} 
                    primeProps = {props}
                    />
                :
                location.pathname.match(/Styling\/LargeLook3/gi)  
                ?

                    <LargeLook3
                    controlsHeightInt={props.controlsHeightInt}
                    scrollboxHeight={props.scrollboxHeight}
                    handle = {(v) => props.handle(v)} 
                    primeProps = {props}
                    />
                :
                location.pathname.match(/Styling\/LargeLook4/gi)  
                ?

                    <LargeLook4
                    controlsHeightInt={props.controlsHeightInt}
                    scrollboxHeight={props.scrollboxHeight}
                    handle = {(v) => props.handle(v)} 
                    primeProps = {props}
                    />
                :
                location.pathname.match(/Styling\/LargeLook5/gi)  
                ?

                    <LargeLook5
                    controlsHeightInt={props.controlsHeightInt}
                    scrollboxHeight={props.scrollboxHeight}
                    handle = {(v) => props.handle(v)} 
                    primeProps = {props}
                    />
                :





                // -- Colors - Group -- //

                location.pathname.match(/Styling\/ColorA/gi)  
                ?

                    <ColorA
                    controlsHeightInt={props.controlsHeightInt}
                    scrollboxHeight={props.scrollboxHeight}
                    handle = {(v) => props.handle(v)} 
                    primeProps = {props}
                    />
                :
                location.pathname.match(/Styling\/ColorB/gi)  
                ?

                    <ColorB
                    controlsHeightInt={props.controlsHeightInt}
                    scrollboxHeight={props.scrollboxHeight}
                    handle = {(v) => props.handle(v)} 
                    primeProps = {props}
                    />
                :
                location.pathname.match(/Styling\/ColorC/gi)  
                ?

                    <ColorC
                    controlsHeightInt={props.controlsHeightInt}
                    scrollboxHeight={props.scrollboxHeight}
                    handle = {(v) => props.handle(v)} 
                    primeProps = {props}
                    />
                :
                location.pathname.match(/Styling\/ColorD/gi)  
                ?

                    <ColorD
                    controlsHeightInt={props.controlsHeightInt}
                    scrollboxHeight={props.scrollboxHeight}
                    handle = {(v) => props.handle(v)} 
                    primeProps = {props}
                    />
                :






                //#########################################################
                //#########################################################
                //#########################################################

                //-----------------
                // Test Pages
                //-----------------


                location.pathname.match(/Sample/gi)  
                ?

                    <Sample
                    controlsHeightInt={props.controlsHeightInt}
                    scrollboxHeight={props.scrollboxHeight}
                    handle = {(v) => props.handle(v)} 
                    primeProps = {props}
                    />
                :



                location.pathname.match(/Trials/gi)  
                ?

                    <Trials
                    controlsHeightInt={props.controlsHeightInt}
                    scrollboxHeight={props.scrollboxHeight}
                    handle = {(v) => props.handle(v)} 
                    primeProps = {props}
                    />
                :









                //#########################################################
                //#########################################################
                //#########################################################

                //-----------------
                // Main Pages
                //-----------------


                // -- Calculator - Single -- //

                // location.pathname.match(/Calculators/gi) && location.search
                // ?

                //     <Calculator
                //     controlsHeightInt={props.controlsHeightInt}
                //     scrollboxHeight={props.scrollboxHeight}
                //     handle = {(v) => props.handle(v)} 
                //     primeProps = {props}
                //     />
            
                // :


                // location.pathname.match(/Calculators\/Calculator~/gi)
                // ?

                //     <Calculator
                //     controlsHeightInt={props.controlsHeightInt}
                //     scrollboxHeight={props.scrollboxHeight}
                //     handle = {(v) => props.handle(v)} 
                //     primeProps = {props}
                //     />
            
                // :


                // -- Calculators -- //

                // location.pathname.match(/Calculators/gi)  
                // ?

                //     <Calculators
                //     controlsHeightInt={props.controlsHeightInt}
                //     scrollboxHeight={props.scrollboxHeight}
                //     handle = {(v) => props.handle(v)} 
                //     primeProps = {props}
                //     />

                // :

                // location.pathname.match(/Account/gi)  
                // ?

                //     <Account
                //     controlsHeightInt={props.controlsHeightInt}
                //     scrollboxHeight={props.scrollboxHeight}
                //     handle = {(v) => props.handle(v)} 
                //     primeProps = {props}
                //     />

                // :
                // location.pathname.match(/Schedule/gi)  
                // ?

                //     <Schedule
                //     controlsHeightInt={props.controlsHeightInt}
                //     scrollboxHeight={props.scrollboxHeight}
                //     handle = {(v) => props.handle(v)} 
                //     primeProps = {props}
                //     />

                // :
                // location.pathname.match(/Overhead/gi)  
                // ?

                //     <Overhead
                //     controlsHeightInt={props.controlsHeightInt}
                //     scrollboxHeight={props.scrollboxHeight}
                //     handle = {(v) => props.handle(v)} 
                //     primeProps = {props}
                //     />

                // :
                // location.pathname.match(/Owners/gi)  
                // ?

                //     <Owners
                //     controlsHeightInt={props.controlsHeightInt}
                //     scrollboxHeight={props.scrollboxHeight}
                //     handle = {(v) => props.handle(v)} 
                //     primeProps = {props}
                //     />
            
                // :
                // location.pathname.match(/Employees/gi)  
                // ?

                //     <Employees
                //     controlsHeightInt={props.controlsHeightInt}
                //     scrollboxHeight={props.scrollboxHeight}
                //     handle = {(v) => props.handle(v)} 
                //     primeProps = {props}
                //     />
            
                // :
                // location.pathname.match(/Expenses/gi)  
                // ?

                //     <Expenses
                //     controlsHeightInt={props.controlsHeightInt}
                //     scrollboxHeight={props.scrollboxHeight}
                //     handle = {(v) => props.handle(v)} 
                //     primeProps = {props}
                //     />
            
                // :
                location.pathname.match(/Reports/gi)  
                ?

                    <Reports
                    controlsHeightInt={props.controlsHeightInt}
                    scrollboxHeight={props.scrollboxHeight}
                    handle = {(v) => props.handle(v)} 
                    primeProps = {props}
                    />
            
                :

                // location.pathname.match(/KnowledgeBase/gi)  
                // ?

                //     <KnowledgeBase
                //     controlsHeightInt={props.controlsHeightInt}
                //     scrollboxHeight={props.scrollboxHeight}
                //     handle = {(v) => props.handle(v)} 
                //     primeProps = {props}
                //     />
                // :




                // location.pathname.match(/Templates/gi)  
                // ?

                //     <Templates
                //     controlsHeightInt={props.controlsHeightInt}
                //     scrollboxHeight={props.scrollboxHeight}
                //     handle = {(v) => props.handle(v)} 
                //     primeProps = {props}
                //     />
            
                // :


                location.pathname.match(/Print/gi)  
                ?

                    <Print
                    controlsHeightInt={props.controlsHeightInt}
                    scrollboxHeight={props.scrollboxHeight}
                    handle = {(v) => props.handle(v)} 
                    primeProps = {props}
                    />
                :
                location.pathname.match(/Results/gi)  
                ?

                    <Results
                    controlsHeightInt={props.controlsHeightInt}
                    scrollboxHeight={props.scrollboxHeight}
                    handle = {(v) => props.handle(v)} 
                    primeProps = {props}
                    />
                :
                
                // location.pathname.match(/Pricing/gi)  
                // ?

                //     <Pricing
                //     controlsHeightInt={props.controlsHeightInt}
                //     scrollboxHeight={props.scrollboxHeight}
                //     handle = {(v) => props.handle(v)} 
                //     primeProps = {props}
                //     />

                // :




                location.pathname.match(/Calculator/gi)  
                ?

                    <Calculator
                    controlsHeightInt={props.controlsHeightInt}
                    scrollboxHeight={props.scrollboxHeight}
                    handle = {(v) => props.handle(v)} 
                    primeProps = {props}
                    />
                :
                location.pathname.match(/Pricing/gi)  
                ?

                    <Pricing
                    controlsHeightInt={props.controlsHeightInt}
                    scrollboxHeight={props.scrollboxHeight}
                    handle = {(v) => props.handle(v)} 
                    primeProps = {props}
                    />
                :
                location.pathname.match(/Settings/gi)  
                ?

                    <SettingsOverview
                    controlsHeightInt={props.controlsHeightInt}
                    scrollboxHeight={props.scrollboxHeight}
                    handle = {(v) => props.handle(v)} 
                    primeProps = {props}
                    />
                :
                location.pathname.match(/Knowledge Base/gi)  
                ?

                    <KnowledgeBaseOverview
                    controlsHeightInt={props.controlsHeightInt}
                    scrollboxHeight={props.scrollboxHeight}
                    handle = {(v) => props.handle(v)} 
                    primeProps = {props}
                    />

                :

                location.pathname.match(/Styling/gi)  
                ?

                    <StylingOverview
                    controlsHeightInt={props.controlsHeightInt}
                    scrollboxHeight={props.scrollboxHeight}
                    handle = {(v) => props.handle(v)} 
                    primeProps = {props}
                    />

                :



                    <Home 
                    controlsHeightInt={props.controlsHeightInt}
                    scrollboxHeight={props.scrollboxHeight}
                    handle = {(v) => props.handle(v)} 
                    primeProps = {props}
                    />

            
                }



            </div>
            

        );


    }


}

export default Index
