
import React, { 


    //useState, 
    useEffect,
    //useRef

} from 'react';

import { LibForm } from 'library';


import ApiResources from 'utils/components/Pages/ApiResources';


export const FSprocess = ({

    formArr,
    handle

}) => {

    var localport = '3001';
    var apiDomain;
    var hostname;
    //var hostname = window.location.hostname;//returns the domain name of the web host
    var protocol = window.location.protocol;//returns the web protocol used (http: or https:)


    if(window.location.hostname.match(/192.168/gi))
    {
        hostname = ApiResources.network[localport].vpc;
        apiDomain = protocol + "//" + hostname + "/";
    }
    else
    if(window.location.hostname.match(/localhost/gi))
    {
        hostname = ApiResources.local[localport].vpc;
        apiDomain = protocol + "//" + hostname + "/";
    }else{
        hostname = ApiResources.aws['x'].vpc;
        apiDomain = "https://" + hostname + "/";
    }//=



    // -- postTo -- //
    let postTo = apiDomain+"tpc-delete";


    
    // -- state -- //
    //const [flyrHeight, setFlyrHeight] = useState(0)

    useEffect(() => {
        
        var nv = {}; 
        var okar = [];  
        var ear = [];

        var frm;
        var label;
        var field;
        var array;
        var err;

        var i;
        var j;

        var errMessage;
    


        if(apiDomain.match(/localhost/))
        {
            nv['site'] = apiDomain;
        }else{
            nv['site'] = window.location.hostname;
        }

        //console.log(JSON.stringify(formArr,null,2));
        //alert('submit check');

        for(i=0; i < formArr.length; i++)
        {
            
            frm = formArr[i];

            switch(frm.section)
            { 
            case'header':
            case'headline':
            case'bar':


                okar.push({

                    "section":frm.section,

                    "name":frm.section,
                    "value":frm.data.text

                })

                nv[frm.section] = frm.data.text;


            break; 
            case'checkboxes':
            case'radiobuttons':

                if(frm.data.required)
                {
                    if(frm.data.choice.length === 0)
                    {
                        ear.push({

                            "key"       :i,
                            "section"   :frm.section,
                            "message"   :frm.data.name+" (Please Choose)"
                    
                        });
                    }
                
                }//== if



                okar.push({

                    "section":frm.section,

                    "name":frm.data.name,
                    "value":frm.data.choice.join()

                })

                nv[frm.data.name] = frm.data.choice.join();



            break;
            case'slider':
            case'dualslider':

                if(frm.data.required)
                {
                   
                    if(frm.data.value < 0 || frm.data.value === ''
                    )
                    {
                        switch(frm.section)
                        {
                        case'dualslider': 
                            errMessage = frm.data.info.title + "/" + frm.data.info.titleB + "(Bad Value: "+frm.data.value+")" 
                        break;
                        default:
                            errMessage = frm.data.info.title + "(Bad Value: "+frm.data.value+")" 
                        }

                        ear.push({

                            "key"       :i,
                            "section"   :frm.section,
                            "message"   :errMessage
                    
                        });

                    }

                
                }//== if


                okar.push({

                    "section":frm.section,

                    "name":frm.data.id,
                    "value":frm.data.value

                })

                nv[frm.data.id] = frm.data.value;




            break;
            case'fields':


            
                array = frm.data;
                for(j=0; j < array.length; j++)
                {

                
                    field = array[j];
                    if(field.required)
                    {

                        //console.log(field.text+" === "+field.hint);


                        if(field.text === ''
                        || field.text === field.hint
                        || field.text === undefined
                        )
                        {

                            ear.push({

                                "key"       :i+"-"+j,
                                "section"   :frm.section,
                                "message"   :field.hint+" (Required)"
                        
                            });


                        }//== if
                        else
                        {

                            if(field.id.match(/email/gi)
                            )
                            {
                                err = LibForm.Validate_Email(field.text,field.hint)
                                if(err)
                                {
                                    ear.push({

                                        "key"       :i+"-"+j,
                                        "section"   :frm.section,
                                        "message"   :field.hint+" (Invalid)"
                                
                                    });
                                    
                                }
                            
                            }

                        }

                    }//== if




                    okar.push({

                        "section":"field",

                        "fieldtype":field.fieldtype,
                        "hint":field.hint, 

                        "name":field.id,
                        "value":field.text

                    })

                    nv[field.id] = field.text;

                }//== j


            break;
            case'labelfields':




                //console.log(JSON.stringify(frm.data,null,2));
                //alert("Check frm.data");
            

                array = frm.data;
                for(j=0; j < array.length; j++)
                {

            
                    label = array[j].label.text;
                    field = array[j].field;
                    if(field.required)
                    {
                        //console.log(field.text+" === "+field.hint);

                    
                        if(field.text === ''
                        || field.text === field.hint
                        || field.text === undefined
                        )
                        {
                            ear.push({

                                "key"       :i+"-"+j,
                                "section"   :frm.section,
                                "message"   :label+" (Required)"
                        
                            });
                        }

                    }//== if


                    okar.push({

                        "section":"labelfield",

                        "label":label,

                        "fieldtype":field.fieldtype,
                        "hint":field.hint, 

                        "name":field.id,
                        "value":field.text

                    })


                    nv[field.id] = field.text;


                }//== j



            break;
            default:
            break;
            }//==


        }//== i




        //console.log("OK ARR: "+JSON.stringify(okar,null,2));

        handle({call:"clear",data:"",message:"Clear"});

        if(ear.length > 0)
        {
            //console.log("ERROR ARRAY: "+JSON.stringify(ear,null,2));
            //alert('hold');


            handle({

                call       :"error",
                type       :'process',
                errors     :ear

            });


        }
        else
        {


            //alert('POST TO: '+postTo);

            var skip = false;
            var defUrl = apiDomain+"form-submit";
            var ajaxUrl;
            ajaxUrl = defUrl;
            if(!postTo || postTo !== undefined) ajaxUrl = postTo;
            if(ajaxUrl === defUrl)
            {
                //alert("Using Default Url");
                skip = true;

            }//==
        
    
            if(skip)
            {

                console.log("VARS: "+JSON.stringify(nv,null,2));

                handle({

                    call       :"error",
                    type       :'process',
                    errors     :[{

                        "key"       :"0-process",
                        "section"   :"process",
                        "message"   :"Missing Valid Url"
    
                    }]
        
                });

            

            }else{

              
                var browserStore; 
                if(!localStorage.browserStore 
                || localStorage.browserStore === undefined)
                {
                    browserStore = {};
                }else{
                    browserStore = localStorage.browserStore;
                }
                if(Object.prototype.toString.call(browserStore) === '[object Object]'
                || Object.prototype.toString.call(browserStore) === '[object Array]'
                )
                {
                    browserStore = JSON.stringify(browserStore);
                }

                var _VARS = JSON.stringify(nv);

                var splitter = "-|AJXPST|-"

                var amp = "&";

                var ajaxPost = ""
                + "ajaxArr[]=case" + splitter + "signup" + amp
                + "ajaxArr[]=browserStore" + splitter + browserStore + amp
                + "ajaxArr[]=props" + splitter + _VARS

                fetch(ajaxUrl , {

                    method:"POST",
                    headers: new Headers({

                        'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type

                    }),
                    //body: "param1=value1&param2=value2" // <-- Post parameters

                    body: ajaxPost  // <-- Post parameters ajaxArr[]

                    //CORS
                    //mode:"no-cors", //will result in an opaque response - fix is to set cors on express
                    
                })

                
                .then(res => res.json())
                //.then((res) => {console.log(res.json())})
                .then(

                    (result) => {

                        console.log("Delete Form - FSprocess: "+JSON.stringify(result,null,2));

                        // Delete Form - FSprocess: {
                        //     "result": {
                        //       "auth_account": {
                        //         "error": false,
                        //         "result": {
                        //           "authUser": {
                        //             "error": false,
                        //             "result": {
                        //               "Items": [],
                        //               "Count": 0,
                        //               "ScannedCount": 5
                        //             }
                        //           },
                        //           "accountUser": {
                        //             "error": false,
                        //             "result": {
                        //               "Items": [],
                        //               "Count": 0,
                        //               "ScannedCount": 4
                        //             }
                        //           }
                        //         }
                        //       },
                        //       "check": {
                        //         "error": true,
                        //         "summary": {
                        //           "result": "FAILED",
                        //           "message": "check - auth, account (FAILED)"
                        //         }
                        //       },
                        //       "auth_account_delete": {
                        //         "error": "Skipped - No Account to Delete",
                        //         "result": {}
                        //       },
                        //       "delete": {
                        //         "error": true,
                        //         "summary": {
                        //           "result": "FAILED",
                        //           "message": "delete - auth, account (FAILED)"
                        //         }
                        //       }
                        //     }
                        //   }



 
                        // var heading = {

                        //     text: "Delete Account",
                        //     bgColor:"#caffca",
                        //     txtColor:"#000"
                                           
                        // };
                        // var message = "Delete Account was successfull!!"; 
                        // var isValid = true;

                        var resp = result.result;
                        console.log("DELETE RESP: "+JSON.stringify(resp,null,2));
                        // DELETE RESP: {
                        //     "auth_account": {
                        //       "error": "skipped - No Auth Account",
                        //       "result": {
                        //         "authUser": {
                        //           "error": false,
                        //           "result": {
                        //             "error": false,
                        //             "result": []
                        //           }
                        //         },
                        //         "accountUser": {
                        //           "error": false,
                        //           "result": {
                        //             "error": false,
                        //             "result": []
                        //           }
                        //         }
                        //       }
                        //     },
                        //     "check": {
                        //       "error": false,
                        //       "summary": {
                        //         "result": "SUCCESS",
                        //         "message": "check - auth, account (SUCCESS)"
                        //       }
                        //     },
                        //     "stored_items": {
                        //       "error": "skipped",
                        //       "result": {}
                        //     },
                        //     "delete_items": [],
                        //     "recordsDelete": {
                        //       "error": "skipped",
                        //       "result": {}
                        //     },
                        //     "auth_account_delete": {
                        //       "error": false,
                        //       "result": {
                        //         "authUser": {
                        //           "error": false,
                        //           "result": {
                        //             "Items": [],
                        //             "Count": 0,
                        //             "ScannedCount": 12
                        //           }
                        //         },
                        //         "accountUser": {
                        //           "error": false,
                        //           "result": {
                        //             "Items": [],
                        //             "Count": 0,
                        //             "ScannedCount": 11
                        //           }
                        //         },
                        //         "check": {
                        //           "error": true,
                        //           "errorType": "no_auth_account",
                        //           "summary": {
                        //             "result": "FAILED",
                        //             "message": "check - auth, account (FAILED)"
                        //           }
                        //         },
                        //         "authDelete": {
                        //           "error": "skipped",
                        //           "result": {}
                        //         },
                        //         "accountDelete": {
                        //           "error": "skipped",
                        //           "result": {}
                        //         }
                        //       }
                        //     },
                        //     "delete": {
                        //       "error": false,
                        //       "summary": {
                        //         "result": "SUCCESS",
                        //         "message": "delete - auth, account (SUCCESS)"
                        //       }
                        //     }
                        //   }






                        var msg = '';
                        if(resp.auth_account.error)
                        {

                            msg = msg + "\nNo Auth Account Exists"
                            
                        }
                        if(resp.delete_items.length === 0)
                        {

                            msg = msg + "\nNo Records";
                        }


                        //var msg = 'DELETE:';
                        if(resp.auth_account_delete.result)
                        {
                            var deleteError = resp.auth_account_delete.error;
                            if(deleteError)
                            {
                                if(deleteError.match(/skipped/gi))
                                {
                                    // heading = {

                                    //     text: "Delete Account",
                                    //     bgColor:"#fc0",
                                    //     txtColor:"#000"
                                                    
                                    // };
                                    msg = msg + '\nNo Account Exists';
             
                                }
                                    
                            
                            }else{


                                var authUser = resp.auth_account_delete.result.authUser;
                                var accountUser = resp.auth_account_delete.result.accountUser;

                                if(authUser.error)              msg = msg + "\nauthUser (ERROR)";
                                if(accountUser.error)           msg = msg + "\naccountUser (ERROR)"; 
                                if(authUser.result.Count)       msg = msg + "\nauthUser (None)"; 
                                if(accountUser.result.Count)    msg = msg + "\naccountUser (None)";             

  
                            }

                        }

                        // if(resp.delete_items.length > 0)    msg = msg + " Has Items";
                        // if(resp.recordsDelete.error)        msg = msg + " recordsDelete (ERROR)";
                        // if(!resp.recordsDelete.error)       msg = msg + " recordsDelete (SUCCESS)";


                        // handle({

                        //     call:"error",
                        //     type :'process',
                        //     errors:ear
                    
                        // });




                        if(msg !== '')
                        {

                            handle({

                                call:"processComplete",
                                data:msg
                        
                            });  


                        }else{

                            handle({

                                call:"processComplete",
                                data:resp
                        
                            });  
                    
                        }




                

                        // if(!isValid)
                        // {

                        //     // handle({

                        //     //     call:"error",
                        //     //     type :'process',
                        //     //     errors:[{
    
                        //     //         "key"       :"0-process",
                        //     //         "section"   :"process",
                        //     //         "message"   :message
                
                        //     //     }],
                        //     //     message:message
    
                        //     // });






                        //     // handle({

                        //     //     call:"processComplete",
                        //     //     data:{

                        //     //         isError         : false,
                        //     //         errorType       : '',
                        //     //         isTimed         : false,
                        //     //         timedDuration   : 2000,
                        //     //         items           : [
                            
                        //     //             {
                        //     //                 "call":"heading",
                        //     //                 "text":heading.text,
                        //     //                 "bgColor":heading.bgColor,
                        //     //                 "txtColor":heading.txtColor,
                        //     //             },
                        //     //             {
                        //     //                 "call":"span",
                        //     //                 "text":message
                        //     //             }
                                
                        //     //         ]

                        //     //     },
                        //     //     message:message

                        //     // });  


                        // }
                        



                        // alert(message);







                    },
                    (error) => {


                        console.log(error);
            
                        handle({

                            call:"error",
                            type :'process',
                            errors:[{

                                "key"       :"0-process",
                                "section"   :"process",
                                "message"   :error.message
            
                            }],
                            message:"Process Error: "+error

                        });


                    }


                )



            }//skip




        
        }//==



    },[
        apiDomain,
        postTo,
        formArr,
        handle
    ])




    return <div></div>


}



