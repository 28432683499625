import styled from "styled-components";


export const Msg = styled.div.attrs(props => ({


    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "0",

    width:              props.width || "calc(100% - 20px)",
    height:             props.height || "auto",
    margin:             props.margin || "40px 0 0 10px",
    padding:            props.padding || "26px 0 24px 0",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "24px",
    fontWeight:         props.fontWeight  || "500",
    textAlign:          props.textAlign || "center",
    textDecoration:     props.textDecoration  || "none",
    textColor:          props.textColor || "#454d5f"




}))`


    background-color:${props => props.backgroundColor};
    border:${props => props.border};

    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;


    font-family:${props => props.fontFamily};
    font-size:${props => props.fontSize};
    font-weight:${props => props.fontWeight};
    text-align:${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.textColor};


    cursor:pointer;


    @media (max-width: 300px) {

        height:80px;
    }


`;
