import styled from "styled-components";


//#############################################################
//#############################################################


export const CatalogTileCalc = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#191e28",
    border:             props.border || "1px solid #454d5f",

    width:              props.width || "calc(100% - 0px)",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "0 0 5px 0",


    titleFontFamily:         props.titleFontFamily || 'Arial, Helvetica, sans-serif',
    titleFontSize:           props.titleFontSize || "20px",
    titleFontWeight:         props.titleFontWeight  || "550",
    titleTextAlign:          props.titleFextAlign || "left",
    titleTextDecoration:     props.titleTextDecoration  || "none",
    titleTextColor:          props.titleTextColor || "white", //#20b2aa #00cc66


    descrFontFamily:         props.descrFontFamily || 'Arial, Helvetica, sans-serif',
    descrFontSize:           props.descrFontSize || "14px",
    descrFontWeight:         props.descrFontWeight  || "500",
    descrTextAlign:          props.descrTextAlign || "left",
    descrTextDecoration:     props.descrTextDecoration  || "none",
    descrTextColor:          props.descrTextColor || "#a2a6af",


    priceFontFamily:         props.priceFontFamily || 'Arial, Helvetica, sans-serif',
    priceFontSize:           props.priceFontSize || "16px",
    priceFontWeight:         props.priceFontWeight  || "500",
    priceTextAlign:          props.priceTextAlign || "left",
    priceTextDecoration:     props.priceTextDecoration  || "none",
    priceTextColor:          props.priceTextColor || "#00cc66",



    letterFontFamily:         props.letterFontFamily || 'Verdana, Arial, Helvetica, sans-serif',
    letterFontSize:           props.letterFontSize || "28px",
    letterFontWeight:         props.letterFontWeight  || "500",
    letterTextAlign:          props.letterTextAlign || "center",
    letterTextDecoration:     props.letterTextDecoration  || "none",
    letterColor:              props.letterColor || "white",


    buttonFontFamily:         props.buttonFontFamily || 'Arial, Helvetica, sans-serif',
    buttonFontSize:           props.buttonFontSize || "14px",
    buttonFontWeight:         props.buttonFontWeight  || "500",
    buttonTextAlign:          props.buttonTextAlign || "center",
    buttonTextDecoration:     props.buttonTextDecoration  || "none",
    buttonTextColor:          props.buttonTextColor || "white"


    // word-wrap: break-word;

    // #EDEEF1


}))`


    background-color:${props => props.backgroundColor};
    border-bottom:${props => props.border};
    border-radius:0px;

    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;
    cursor:default;

    @media (max-width: 600px) {

        height:auto;
        padding-bottom:50px;
    }


    &:hover {

        background-color:#141820;
        border-bottom:1px solid #454d5f;
    }



    .Controls {

        background-color:transparent;

        width:auto;
        height:35px;
        padding:0 0 0 0;
        margin:0 0 0 0;
        position:absolute;
        z-index:3;        
        top:14px;
        right:0px;

        cursor:default;
    }

        .Controls .Button {

            background-color:transparent;
            border:1px solid transparent;
            border-radius:10px;
    
            width:25px;
            height:auto;
            padding:5px 3px 5px 3px;
            margin:0 8px 0 0;
            position:relative;
            float:left;
    
            cursor:pointer;
    
            font-family:${props => props.buttonFontFamily};
            font-size:${props => props.buttonFontSize};
            font-weight:${props => props.buttonFontWeight};
            text-align:${props => props.buttonTextAlign};
            text-decoration:${props => props.buttonTextDecoration};
            color:${props => props.buttonTextColor};


            &:hover {

                background-color:#454d5f;
                border:1px solid #454d5f;
        
            }

            
        }

        .Controls .Button:hover > .Dot {
            
            background-color:white; 
        }
    

            .Controls .Button .Dot {

                background-color:gray;
                border-radius:50%;
        
                width:7px;
                height:7px;
                padding:0 0 0 0;
                margin:4px auto 4px auto;
                
                cursor:pointer;
                
            }







    .Ctrls {

        background-color:transparent;
        width:100px;
        height:auto;
        padding:0 0 0 0;
        margin:0 0 0 0;
        position:absolute;
        z-index:2px;
        top:14px;
        right:55px;
    }

    @media (max-width: 600px) {


        .Ctrls {

            top:calc(100% - 60px);
            right:0px;

        }


    }

        .Ctrls .Favorite {

            background-color:transparent;

            width:auto;
            height:auto;
            padding:0 0 0 0;
            margin:0 0 0 0;
            position:relative;
            float:left;

            cursor:pointer;

        }
        .Ctrls .EditButton {

            background-color:transparent;

            width:auto;
            height:auto;
            padding:5px 0 5px 1px;
            margin:9px 0 0 0;
            position:relative;
            float:left;

            cursor:pointer;

            font-family:Arial, Helvetica, sans-serif;
            font-size:13px;
            font-weight:500;
            text-align:center;
            text-decoration:none;
            color:#07a2e3;

            &:hover {

                color:#00e070;
                text-decoration:underline;
            }

        }










    .Band {

        background-color:#454d5f;

        width:100%;
        height:30px;
        padding:0 0 0 0;
        margin:0 0 0 0;
        position:absolute;
        z-index:1px;
        bottom:0px;
        left:0px;

        cursor:pointer;

    }


    .Grid {

        background-color:transparent;

        width:auto;
        height:auto;
        padding:0 0 0 0;
        margin:0 0 0 0;
        position:absolute;
        z-index:2px;
        bottom:12px;
        right:55px;

        cursor:pointer;

    }
    @media (max-width: 600px) {


        .Grid {

            top:calc(100% - 30px);
            right:7px;

        }


    }

        .Grid .Button {

            background-color:transparent;

            width:auto;
            height:auto;
            padding:5px 2px 5px 5px;
            margin:0 0 0 0;
            position:relative;
            float:right;

            cursor:pointer;

            font-family:Arial, Helvetica, sans-serif;
            font-size:13px;
            font-weight:500;
            text-align:center;
            text-decoration:none;
            color:#07a2e3;

            &:hover {

                color:#00e070;
                text-decoration:underline;
          
            }

            
        }











    .Left {

        background-color:transparent;
        border-radius:7px;

        width:55px;
        height:55px;
        padding:0 0 0 0;
        margin:10px 0 10px 10px;
        position:relative;
        float:left;

        cursor:pointer;
    }

    .Right {

        background-color:transparent;

        width:calc(100% - 275px);
        height:auto;
        padding:0 0 0 0;
        margin:7px 0 0 0;
        position:relative;
        float:left;

        cursor:pointer;

    }

    &:hover > .Right .Title {
            
        text-decoration:underline; 
    }




    @media (max-width: 600px) {

        .Left {

            width:60px;
            height:60px;

        }

        .Right {

            width:calc(100% - 150px);
        }


    }





    .GraphicTile {

        background-color:transparent;

        width:70%;
        height:70%;
        padding:0 0 0 0;
        margin:15% 0 0 15%;
        position:relative;
        float:left;
        cursor:pointer;
    }



    .Graphic {

        background-color:transparent;

        width:100%;
        height:100%;
        padding:0 0 0 0;
        margin:0 0 0 0;
        position:relative;
        float:left;
        cursor:pointer;
    }



    .Circle {

        background-color:#00cc66;
        border-radius:100%;

        width:90%;
        height:90%;
        padding:0 0 0 0;
        margin:5% 0 0 5%;
        position:relative;
        float:left;
        cursor:pointer;
    }






        .Circle .Letter {

            background-color:transparent;

            width:100%;
            height:auto;
            padding:0 0 0 0;
            margin:calc(50% - 16px) 0 0 0;

            cursor:pointer;

            font-family:${props => props.letterFontFamily};
            font-size:${props => props.letterFontSize};
            font-weight:${props => props.letterFontWeight};
            text-align:${props => props.letterTextAlign};
            text-decoration:${props => props.letterTextDecoration};
            color:${props => props.letterColor};


        }




    .TNP {

        background-color:transparent;

        width:100%;
        height:auto;
        padding:0 0 4px 0;
        margin:0 0 0 0;
        position:relative;
        float:left;

        cursor:pointer;

        font-family:${props => props.titleFontFamily};
        font-size:${props => props.titleFontSize};
        font-weight:${props => props.titleFontWeight};
        text-align:${props => props.titleTextAlign};
        text-decoration:${props => props.titleTextDecoration};
        color:${props => props.titleTextColor};


    }


        .TNP .Title {

            background-color:transparent;

            width:auto;
            height:auto;
            padding:0 0 4px 0;
            margin:10px 0 0 15px;
            position:relative;
            float:left;

            cursor:pointer;

            font-family:${props => props.titleFontFamily};
            font-size:${props => props.titleFontSize};
            font-weight:${props => props.titleFontWeight};
            text-align:${props => props.titleTextAlign};
            text-decoration:${props => props.titleTextDecoration};
            color:${props => props.titleTextColor};


        }



        .TNP .Price {

            background-color:transparent;

            width:auto;
            height:auto;
            padding:0 0 4px 0;
            margin:7px 10px 0 12px;
            position:relative;
            float:left;

            cursor:pointer;

            font-family:${props => props.priceFontFamily};
            font-size:${props => props.priceFontSize};
            font-weight:${props => props.priceFontWeight};
            text-align:${props => props.priceTextAlign};
            text-decoration:${props => props.priceTextDecoration};
            color:${props => props.priceTextColor};
    
        }



        .TNP .NameValue {

            background-color:transparent;

            width:99%;
            height:auto;
            padding:0 0 0 0;
            margin:8px 0 0 14.5px;
            position:relative;
            float:left;

        }


            .TNP .NameValue .Name{

                background-color:transparent;

                width:auto;
                height:auto;
                padding:0 0 4px 0;
                margin:0 0 0 0;
                position:relative;
                float:left;

                cursor:pointer;

                font-family:${props => props.priceFontFamily};
                font-size:${props => props.priceFontSize};
                font-weight:550;
                text-align:${props => props.priceTextAlign};
                text-decoration:${props => props.priceTextDecoration};
                color:lightgray;
        
            }


            .TNP .NameValue .Value{

                background-color:transparent;

                width:auto;
                height:auto;
                padding:0 0 4px 0;
                margin:0 0 0 10px;
                position:relative;
                float:left;

                cursor:pointer;

                font-family:${props => props.priceFontFamily};
                font-size:${props => props.priceFontSize};
                font-weight:550;
                text-align:${props => props.priceTextAlign};
                text-decoration:${props => props.priceTextDecoration};
                color:${props => props.priceTextColor};
        
            }




    .Descr {

        background-color:transparent;

        width:100%;
        height:auto;
        padding:0 0 10px 0;
        margin:0 12px 7px 15px;
        position:relative;
        float:left;

        cursor:pointer;

        font-family:${props => props.descrFontFamily};
        font-size:${props => props.descrFontSize};
        font-weight:${props => props.descrFontWeight};
        text-align:${props => props.descrTextAlign};
        text-decoration:${props => props.descrTextDecoration};
        color:${props => props.descrTextColor};


        word-wrap: break-word;


    }

    @media (max-width: 600px) {

        .Descr {

            margin:0 12px 15px 15px;
    
        }

    }




    .PricePerc {

        background-color:transparent;

        width:150px;
        height:50px;
        padding:0 0 4px 0;
        margin:0 0 0 0;

        position:absolute;
        top:calc(50% - 18px);
        right:0;
        z-index:5;

        cursor:pointer;

        font-family:${props => props.priceFontFamily};
        font-size:${props => props.priceFontSize};
        font-weight:${props => props.priceFontWeight};
        text-align:${props => props.priceTextAlign};
        text-decoration:${props => props.priceTextDecoration};
        color:${props => props.priceTextColor};

        word-wrap: break-word;

    }



        .PricePerc .Tag{

            background-color:transparent;

            width:40px;
            height:40px;
            padding:0 0 4px 0;
            margin:0 0 0 0;

            position:relative;
            float:right;

            cursor:pointer;

        }


        .PricePerc .Text {

            background-color:transparent;

            width:auto;
            height:auto;
            padding:12px 0 4px 0;
            margin:0 0 0 7px;

            position:relative;
            float:right;

            cursor:pointer;

            font-family:${props => props.priceFontFamily};
            font-size:${props => props.priceFontSize};
            font-weight:${props => props.priceFontWeight};
            text-align:${props => props.priceTextAlign};
            text-decoration:${props => props.priceTextDecoration};
            color:${props => props.priceTextColor};

            word-wrap: break-word;

        }





    @media (max-width: 600px) {

        .Title {

            margin-top:7px;
            font-size:20px;
            
        }

        .Descr {

            font-size:16px;
            
        }


        .PricePerc {

            font-size:18px;
        }
    



    }













`;




















//#############################################################
//#############################################################


export const CatalogTileTemplate = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#edeef1",
    border:             props.border || "1px solid black",

    width:              props.width || "calc(100% - 2px)",
    height:             props.height || "auto",
    margin:             props.margin || "7px 0 0 0",
    padding:            props.padding || "0 0 2.5px 0",


    titleFontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    titleFontSize:           props.fontSize || "24px",
    titleFontWeight:         props.fontWeight  || "550",
    titleTextAlign:          props.textAlign || "left",
    titleTextDecoration:     props.textDecoration  || "none",
    titleColor:              props.color || "#454d5f",

    descrFontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    descrFontSize:           props.fontSize || "18px",
    descrFontWeight:         props.fontWeight  || "500",
    descrTextAlign:          props.textAlign || "left",
    descrTextDecoration:     props.textDecoration  || "none",
    descrColor:              props.color || "black",


    letterFontFamily:         props.letterFontFamily || 'Verdana, Arial, Helvetica, sans-serif',
    letterFontSize:           props.letterFontSize || "32px",
    letterFontWeight:         props.letterFontWeight  || "500",
    letterTextAlign:          props.letterTextAlign || "center",
    letterTextDecoration:     props.letterTextDecoration  || "none",
    letterColor:              props.letterColor || "#fff",


    buttonFontFamily:         props.buttonFontFamily || 'Arial, Helvetica, sans-serif',
    buttonFontSize:           props.buttonFontSize || "14px",
    buttonFontWeight:         props.buttonFontWeight  || "500",
    buttonTextAlign:          props.buttonTextAlign || "center",
    buttonTextDecoration:     props.buttonTextDecoration  || "none",
    buttonTextColor:          props.buttonTextColor || "black"


    // word-wrap: break-word;

    // #EDEEF1


}))`


    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    border-radius:0px;

    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;
    cursor:default;

    @media (max-width: 600px) {

        height:auto;
        padding-bottom:40px;
    }

    &:hover {

        background-color:#edeef1;
        border:1px solid #07a2e3;

    }



    .Controls {

        background-color:transparent;

        width:auto;
        height:35px;
        padding:0 0 0 0;
        margin:0 0 0 0;
        position:absolute;
        z-index:2;        
        top:10px;
        right:0px;

        cursor:default;
    }

        .Controls .Button {

            background-color:transparent;
            border:1px solid transparent;
            border-radius:10px;
    
            width:25px;
            height:auto;
            padding:5px 3px 5px 3px;
            margin:0 8px 0 0;
            position:relative;
            float:left;
    
            cursor:pointer;
    
            font-family:${props => props.buttonFontFamily};
            font-size:${props => props.buttonFontSize};
            font-weight:${props => props.buttonFontWeight};
            text-align:${props => props.buttonTextAlign};
            text-decoration:${props => props.buttonTextDecoration};
            color:${props => props.buttonTextColor};


            &:hover {

                background-color:#454d5f;
                border:1px solid #454d5f;
        
            }

            
        }

        .Controls .Button:hover > .Dot {
            
            background-color:white; 
        }
    

            .Controls .Button .Dot {

                background-color:gray;
                border-radius:50%;
        
                width:7px;
                height:7px;
                padding:0 0 0 0;
                margin:4px auto 4px auto;
                
                cursor:pointer;
                
            }







    .Favorite {

        background-color:transparent;

        width:auto;
        height:auto;
        padding:0 0 0 0;
        margin:0 0 0 0;
        position:absolute;
        z-index:1px;
        top:22px;
        right:65px;

        cursor:pointer;

    }


    @media (max-width: 600px) {


        .Favorite {

            top:calc(100% - 30px);
            right:13px;

        }


    }








    .Left {

        background-color:transparent;

        width:75px;
        height:75px;
        padding:0 0 0 0;
        margin:10px 0 10px 10px;
        position:relative;
        float:left;

        cursor:pointer;
    }


    .Right {

        background-color:transparent;

        width:calc(100% - 275px);
        height:auto;
        padding:0 0 0 0;
        margin:10px 0 0 0;
        position:relative;
        float:left;

        cursor:pointer;

    }

    .Right:hover > .Title {
            
        text-decoration:underline; 
    }




    @media (max-width: 600px) {

        .Left {

            width:60px;
            height:60px;

        }

        .Right {

            width:calc(100% - 150px);
        }


    }




    .Graphic {

        background-color:transparent;

        width:100%;
        height:100%;
        padding:0 0 0 0;
        margin:0 0 0 0;
        position:relative;
        float:left;
        cursor:pointer;
    }



    .Circle {

        background-color:#000;
        border-radius:50%;

        width:90%;
        height:90%;
        padding:0 0 0 0;
        margin:5% 0 0 5%;
        position:relative;
        float:left;
        cursor:pointer;
    }


        .Circle .Letter {

            background-color:transparent;

            width:100%;
            height:auto;
            padding:0 0 0 0;
            margin:calc(50% - 19px) 0 0 0;

            cursor:pointer;

            font-family:${props => props.letterFontFamily};
            font-size:${props => props.letterFontSize};
            font-weight:${props => props.letterFontWeight};
            text-align:${props => props.letterTextAlign};
            text-decoration:${props => props.letterTextDecoration};
            color:${props => props.letterColor};


        }






    .Title {

        background-color:transparent;

        width:100%;
        height:auto;
        padding:0 0 4px 0;
        margin:10px 10px 0 15px;
        position:relative;
        float:left;

        cursor:pointer;

        font-family:${props => props.titleFontFamily};
        font-size:${props => props.titleFontSize};
        font-weight:${props => props.titleFontWeight};
        text-align:${props => props.titleTextAlign};
        text-decoration:${props => props.titleTextDecoration};
        color:${props => props.titleColor};




    }

    .Descr {

        background-color:transparent;

        width:100%;
        height:auto;
        padding:0 0 4px 0;
        margin:0 10px 0 15px;
        position:relative;
        float:left;

        cursor:pointer;

        font-family:${props => props.descrFontFamily};
        font-size:${props => props.descrFontSize};
        font-weight:${props => props.descrFontWeight};
        text-align:${props => props.descrTextAlign};
        text-decoration:${props => props.descrTextDecoration};
        color:${props => props.descrColor};


        word-wrap: break-word;


    }




    @media (max-width: 600px) {

        .Title {

            margin-top:7px;
            font-size:20px;
            
        }

        .Descr {

            font-size:16px;
            
        }

    }





`;


