export const Direct_Labor_Hours = [



        // //-----------------------------------
        // // ReportBox - Graphic and Ledger
        // //-----------------------------------

        // {

        //     "print"		    :'bar',
        //     "name"		    :'Percentage',
        //     "value"		    :"",
        //     "required"	    :"",
        //     "default"	    :""


        // },
        // {

        //     "print"		    :'graphic_txt',
        //     "name"		    :'Graphic and Text',
        //     "value"		    :[

        //         {

        //             boxCall:'graphic',
        //             boxWidth:"45%",
        //             boxHeight:220,

        //             svgCall:"billable",
        //             svgWidth:220,
        //             svgHeight:220,

        //         },

        //         {

        //             boxCall:'txt',
        //             boxWidth:"55%",
        //             boxHeight:220,
        //             value:[

        //                 {
        //                     className:"Big",
        //                     txt:"45.5%",
        //                     style:{

        //                         fontSize:"100px",
        //                         textAlign:"center"

        //                     }
        //                 }

        //             ]


        //         }




        //     ],
        //     "required"	    :"",
        //     "default"	    :""


        // },
        // {

        //     "print"		    :'spacer',
        //     "name"		    :'',
        //     "value"		    :{

        //         backgroundColor:"transparent",
        //         height:"0px"
        //     },
        //     "required"	    :"",
        //     "default"	    :""


        // },








        //-----------------------------------
        // ReportBox - Graphic and Big Txt
        //-----------------------------------

        {

            "print"		    :'bar',
            "name"		    :'Labor Hours',
            "value"		    :"",
            "required"	    :"",
            "default"	    :""


        },
        {

            "print"		    :'report_box',
            "name"		    :'Report Box',
            "value"		    :[

                {

                    boxCall:'graphic',
                    boxWidth:"40%",
                    boxHeight:230,

                    svgCall:"clock",
                    svgWidth:230,
                    svgHeight:230,

                },

                {

                    boxCall:'txt',
                    boxWidth:"55%",
                    boxHeight:250,
                    value:[

                        {
                            className:"XLarge",
                            txt:"10hrs",
                            style:{

                                //fontSize:"60px",
                                textAlign:"center"

                            }
                        },



                        {
                            className:"Note",
                            txt:"***NOTE This number reflects minimum billing hours daily",
                            style:{

                                //fontSize:"13px",
                                textAlign:"center"

                            }
                        }


                    ]


                }




            ],
            "required"	    :"",
            "default"	    :""


        },
        {

            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{

                backgroundColor:"transparent",
                height:"0px"
            },
            "required"	    :"",
            "default"	    :""


        },





        //-----------------------------------
        // Ledger
        //-----------------------------------

        // {

        //     "print"		    :'bar',
        //     "name"		    :'Owner Labor Rates',
        //     "value"		    :"",
        //     "required"	    :"",
        //     "default"	    :""


        // },
        {

            "print"		    :'ledger',
            "name"		    :"Ledger",
            "value"		    :[


                {
                    name:"Daily",
                    value:"10.0 hrs"
                },
                {
                    name:"Weekly",
                    value:"50.0 hrs"
                }

            ],
            "required"	    :"",
            "default"	    :""


        },

        {

            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{

                backgroundColor:"transparent",
                height:"50px"
            },
            "required"	    :"",
            "default"	    :""


        },








        


        //-----------------------------------
        // Txt
        //-----------------------------------

        // {

        //     "print"		    :'bar',
        //     "name"		    :'Labor Hours',
        //     "value"		    :"",
        //     "required"	    :"",
        //     "default"	    :""


        // },
        {

            "print"		    :'txt',
            "name"		    :'Txt',
            "value"		    :[

                {

                    // boxCall:'txt',
                    // boxWidth:"100%",
                    // boxHeight:230,

                    className:"Note",
                    txt:'***NOTE: Use "Labor Hours" to determine your "Shop Rate"',
                    style:{

                        //fontSize:"24px",
                        textAlign:"center"

                    }

                }

            ],
            "required"	    :"",
            "default"	    :""


        },
        {

            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{

                backgroundColor:"transparent",
                height:"50px"
            },
            "required"	    :"",
            "default"	    :""


        },




];





