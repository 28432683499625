

const VPaccount = {



    //---------------------
    //login
    //---------------------

    "login":{

        "name"     : "Edit Login",
        "value"    :{
    
            "email" : {
    
                "print"		:'text',
                "name"		:'Email',
                "value"		:"",
                "required"	:true,
                "default"	:""
            },
            "password" : {
    
                "print"		:'text',
                "name"		:'Password',
                "value"		:"",
                "required"	:true,
                "default"	:""
            }

    
        }
    

    },




    // //---------------------
    // //company
    // //---------------------

    // "company":{

    //     "name"     : "Company",
    //     "value"    :{
    
    //         "company" : {
    
    //             "print"		:'text',
    //             "name"		:'Company',
    //             "value"		:"",
    //             "required"	:true,
    //             "default"	:"Your Company"
    //         },
    //         "email" : {
    
    //             "print"		:'text',
    //             "name"		:'Email',
    //             "value"		:"",
    //             "required"	:true,
    //             "default"	:"fake@gmail.com"
    //         },
    //         "address" : {
    
    //             "print"		:'text',
    //             "name"		:'Address',
    //             "value"		:"",
    //             "required"	:false,
    //             "default"	:"123 Fake Street"
    //         },
    //         "city" : {
    
    //             "print"		:'text',
    //             "name"		:'City',
    //             "value"		:"",
    //             "required"	:false,
    //             "default"	:"Anytown"
    //         },
    //         "state" : {
    
    //             "print"		:'select',
    //             "name"		:'State',
    //             "value"		:"",
    //             "required"	:false,
    //             "default"	:"PA"
    //         },
    //         "country" : {
    
    //             "print"		:'select',
    //             "name"		:'Country',
    //             "value"		:"",
    //             "required"	:false,
    //             "default"	:"US"
    //         },
    //         "zipcode" : {
    
    //             "print"		:'text',
    //             "name"		:'Zip Code',
    //             "value"		:"",
    //             "required"	:false,
    //             "default"	:"55555"
    //         },
    //         "phone" : {
    
    //             "print"		:'phone',
    //             "name"		:'Phone',
    //             "value"		:"",
    //             "required"	:false,
    //             "default"	:"555-555-5555"
    //         },
    
    
    //     }

    // },




    //---------------------
    //company
    //---------------------

    "company":{

        "name"     : "Company",
        "value"    :{
    
            "company" : {
    
                "print"		:'text',
                "name"		:'Company',
                "value"		:"",
                "required"	:true,
                "default"	:""
            },
            "email" : {
    
                "print"		:'text',
                "name"		:'Email',
                "value"		:"",
                "required"	:true,
                "default"	:""
            },
            "address" : {
    
                "print"		:'text',
                "name"		:'Address',
                "value"		:"",
                "required"	:false,
                "default"	:""
            },
            "city" : {
    
                "print"		:'text',
                "name"		:'City',
                "value"		:"",
                "required"	:false,
                "default"	:""
            },
            "state" : {
    
                "print"		:'select',
                "name"		:'State',
                "value"		:"",
                "required"	:false,
                "default"	:""
            },
            "country" : {
    
                "print"		:'select',
                "name"		:'Country',
                "value"		:"",
                "required"	:false,
                "default"	:"US"
            },
            "zipcode" : {
    
                "print"		:'text',
                "name"		:'Zip Code',
                "value"		:"",
                "required"	:false,
                "default"	:""
            },
            "phone" : {
    
                "print"		:'phone',
                "name"		:'Phone',
                "value"		:"",
                "required"	:false,
                "default"	:""
            },
    
    
        }

    }







};



export {

    VPaccount

}