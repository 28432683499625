
// objects are dynamically added on the main index

export const CalcInputs =  {


    "cost_of_goods": [

        // {
        //     'call'      :'good-1', 
        //     'title'     :'Good 1', 
        //     'rate'      :0.00, 
        //     'qtyhrs'    :0.0,  
        //     'isLabor    :false 
        //     'other'     :{'label':'--', 'data':{'brand':'','name':''}},
            
        // },

        // {
        //     'call'      :'good-2', 
        //     'title'     :'Good 2', 
        //     'rate'      :0.00, 
        //     'qtyhrs'    :0.0, 
        //     'isLabor    :false 
        //     'other'     :{'label':'--', 'data':{'brand':'','name':''}},
            
        // },

        // {
        //     'call'      :'good-3',
        //     'title'     :'Good 3', 
        //     'rate'      :0.00, 
        //     'qtyhrs'    :0.0,  
        //     'isLabor    :false 
        //     'other'     :{'label':'--','data':{'brand':'','name':''}},
            
        // }

    ],



    
    // -- calcby -- //

    "calcby": {

        "call"      :'percent',
        'title'     :'Net Profit', //Net Profit, Selling Price, Markup Multiplier
        'value'     :0.0
        
    },

    // -- overhead -- //

    "overhead":{

        'id'        :'overhead',
        'title'     :'Overhead', 
        'value'     :0.0
    },



    // -- debits -- //

    "debits": [

        // {
        //     'id'        :'commission',    
        //     'title'     :'Commission', 
        //     'value'     :0.0

        // },

        // {

        //     'id'        :'fees',       
        //     'title'     :'Fees', 
        //     'value'     :0.0

        // }

    ],



    // -- quantity -- //

    "quantity":{

        'id'        :'quantity',
        'title'     :'Quantity', 
        'value'     :1
    }




}











