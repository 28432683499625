import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';


import {

    ReportBlox,
        ReportBlock,
        //ReportItem,
            // ReportItemName,
            // ReportItemValue


} from '../Styled';



const Index = (props) => {



    // -- ref -- //
    const refA = useRef(null);


    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState({});
    const [items, setItems] = useState([]);


    useEffect(() => {

        if(!isLoaded)
        {

            setIsLoaded(true);
            setItems(props.data.value);

        }
        else
        {
            setItems(props.data.value);



            //console.log("COGGGGG:::::: "+JSON.stringify(props.data.value,null,2));


            var margin;
            var marginTop;
            var marginBottom;
            var marginLeft;

            marginTop = "0px";
            marginBottom = "0px";
            marginLeft = "0px";
            if(props.data.boxHeight 
            && props.data.boxHeight !== undefined
            )
            {
                marginTop = (props.data.boxHeight - refA.current.offsetHeight)/2 - (5) + "px";
            }

            if(props.lessThanThresh)
            {
                marginTop = "0px";
                marginBottom = "30px";
            }

            margin = marginTop+" 0 "+marginBottom+" "+marginLeft;

            setData({

                id:props.id,
                margin:margin
         
            });

      

        }
        //console.log("ledgerData: "+JSON.stringify(props.data,null,2));


    },
    [
        props,
        isLoaded
        //rowRefs
    ])





    return (


        <ReportBlox
        ref={refA}
        id={data.id}
        margin={data.margin}
        //backgroundColor={'lightblue'}
        >
            {items.map((itm, i) => (


                <ReportBlock                          
                key={i}
                id={"row_"+i}
                >
         
                    <div className="Name">

                        {itm.name}

                    </div>

                    <div className="Group">

                        {itm.group.map((grp, j) => (

                            <div key={j}>
                                <div className="Key">{grp.key}</div>
                                <div className="Value">{": " + grp.value}</div>  
                            </div> 

                        ))}

                        {/* <div className="Key">{"Rate"}</div>
                        <div className="Value">{": $36.21"}</div>

                        <div className="Key">{"Total"}</div>
                        <div className="Value">{": $100.00"}</div>

                        <div className="Key">{"Percentage"}</div>
                        <div className="Value">{": 25%"}</div> */}


                    </div>

                </ReportBlock>

            ))}

        </ReportBlox>


    )


}

export default Index;


