import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';


import {

    ReportItemName,
    ReportItemValue

} from '../../Styled';



import { LibNumber } from 'library';



const Index = (props) => {


    // -- ref -- //
    const refA = useRef(null);
    const refB = useRef(null);
 

    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);
    const [valueDif, setValueDif] = useState(0);
    const [value, setValue] = useState("");

    useEffect(() => {

        if(!isLoaded)
        {
            setIsLoaded(true);

            if(props.data.value !== undefined)
            {
                if(props.data.value.toString().match(/\$/gi))
                {
                    setValue(LibNumber.PrintMoney(props.data.value));
                    //setValue(props.data.value);
                }else{
                    setValue(props.data.value);
                }

            }else{
                setValue(props.data.value);
            }


        }else{


            if(props.data.value && props.data.value !== undefined)
            {
                if(props.data.value.toString().match(/\$/gi))
                {
                    setValue(LibNumber.PrintMoney(props.data.value));
                    //setValue(props.data.value);
                }else{
                    setValue(props.data.value);
                }

            }else{
                setValue(props.data.value);
            }



            if(refA.current.offsetHeight > refB.current.offsetHeight)
            {
                var dif = (refA.current.offsetHeight - refB.current.offsetHeight);
                setValueDif(dif);
            }else{
                setValueDif(0);
            }

 
        }





    },
    [
        props,
        isLoaded,
        refA,refB
    ])






    return (<div>


        <ReportItemName
        ref={refA}
        width={"calc(55% - 2px)"}
        >
            <div className="PlainText"
            >
                {props.data.name}

            </div>

        </ReportItemName>

        <ReportItemValue
        ref={refB}
        width={"calc(45% - 1px)"}
        margin={valueDif+"px 0 0 0"}
        >
            <div className="PlainText"
            >
                {value}

            </div>

        </ReportItemValue>


    </div>)


    



}

export default Index;


