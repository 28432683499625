import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    //useHistory,   
    //useParams,



} from "react-router-dom";




import { 
    VPreportList, 
    VPreportDefaults 
} from 'app-components/Prime/VPdata/reports';



import { 
    LibArray, 
    //LibNumber 
} from "library";

// import { 

//     ChartColorsA, 
//     ChartColorsB, 
//     ChartColorsC 

// } from 'utils/components/Charts/ChartColors';


import Build from './Build';


const SceneInfo = (props) => {



    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();

 
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);



    //-----------------------------------------
    //-----------------------------------------

    var pathArr = pathname.split("/");
    var pathCount = pathArr.length;

    var currIndex = parseInt(pathCount)-1;
    var categoryName = pathArr[currIndex];

    //var categoryIndex = parseInt(pathCount)-2;
    //var nextIndex = categoryIndex + 1;

    var categoryCount = currIndex;


    var mainCategoryName = false;
    if(categoryCount > 1)
    {
        //console.log("categoryCount: "+categoryCount);

        var mainIndex = parseInt(pathCount)-2;
        mainCategoryName = pathArr[mainIndex];

        //console.log("mainCategoryName: "+mainCategoryName);
        
    }




    var R = [];
    // var index;


    var keyname;
    //var kn;
    var items, item;
    var array;
    // var value;
    // // var group, groupName;
    // // var newValue;

    // // var a,b,c,d;
    // var total;
    // //var grand_total;


    // var chartSections;
    var object;


    // var name;
    // var percent;
    // var price;
    // var bullet,bulletColors;

    // //var pgraph;

    // var count;


    // var chartColors = [


    //     "royalblue",
    //     "lightblue",
    //     "lightslateblue",

    //     "lightseagreen",
    //     "lightgreen",

    //     "purple",
    //     "lavender",
    //     "magenta",
    //     "pink"


    // ];





    //#############################################################
    //#############################################################

    //--------------------------
    // Catalog - Top
    //--------------------------

    R.push({

        call:'top',
        name:categoryName, // Created from pathname
        controls:[

            // {

            //     name:"Create Template",
            //     // form:{

            //     //     submitCall:'calculate',
            //     //     postTo:apiDomain+apiCall,
            //     //     data:newTemplateExpenseForm,
            //     //     flyr:{

            //     //         hashbang:"#!new",
            //     //         type:"form",
            //     //         styl:{
            //     //             maxWidth:500,
            //     //             fieldType:'composed'   //multi,field,labelfield
            //     //         }

            //     //     }   

            //     // }

            // }

        ]

    });












    // //#############################################################
    // //#############################################################

    // //--------------------------
    // // Catalog - Bar
    // //--------------------------

    // R.push({

    //     call:'bar',
    //     items: [

    //         {

    //             name:"Title 1"

    //         },

    //         {

    //             name:"Actions"

    //         }

    //     ]
        
    // });
    // //#############################################################
    // //#############################################################

    // //--------------------------
    // // Catalog - Items
    // //--------------------------


    // items = [];

    // if(VPreports && VPreports !== undefined)
    // {
    //     for(item of VPreports['defaultItems'])
    //     {
    //         items.push(item);
    //     }

    // }

    // R.push({

    //     call:"items",
    //     items:items

    // });






    var getKeys,getKey;
    var getSearch = false;


    //#############################################################
    //#############################################################

    //--------------------------
    // Catalog - Items
    //--------------------------

    items = [];
    keyname = 'test';


    var regxMain = new RegExp("Test Reports/","gi");
    if(pathname.match(regxMain))
    {

        switch(categoryName)
        {
        case'Direct Labor Hours':

            keyname = "Direct_Labor_Hours";
        break; 
        case'Direct Labor Rates':

            keyname = 'Direct_Labor_Rates';
        break;  
        case'Overhead':

            keyname = "Overhead";
        break; 
        case'Healthcare':

            keyname = 'Healthcare';
        break;  
        case'IRA':

            keyname = 'IRA';
        break;  
        case'Owner':

            keyname = "ownr";
        break; 
        case'Employee':

            keyname = 'empl';
        break;
        case'Calculator':

            keyname = 'calculator';
        break;
        default:
        }


    }else{

        getKeys = Object.keys(VPreportList.GetList);
        for(getKey of getKeys)
        {

            if(getKey === mainCategoryName)
            {
                getSearch = VPreportList.GetList[getKey].getSearch;
            }
            else
            if(getKey === categoryName)
            {
                getSearch = VPreportList.GetList[getKey].getSearch;

            }//if


        }//for 

    }


    //console.log("getSearch: "+getSearch+"\ncategoryName: "+categoryName+"\nmainCategoryName: "+mainCategoryName);



    if(getSearch 
    && LibArray.InArray(getKeys,categoryName))
    {
        
        console.log("getSearch: "+getSearch+"\ncategoryName: "+categoryName);
        array = Build({

            search:getSearch,
            mainCategoryName:mainCategoryName,
            categoryName:categoryName,
            zoneObj:props.zoneObj

        });

        for(object of array)
        {
            R.push(object);

        }


    }else{


        if(VPreportDefaults && VPreportDefaults !== undefined)
        {

            //var newValue = [];
            for(item of VPreportDefaults[keyname])
            {
                // console.log("item: "+JSON.stringify(item,null,2));
                // if(item.print === 'report_box')
                // {
                //     newValue.push(object);
                // }
                items.push(item);

            }

            R.push({

                call:"report",
                items:items
        
            });
        
    
        }//if defaults


    }

    return R;
}



export default SceneInfo;
