
//---------------------------
//Price Percent
//---------------------------

var goods_total_amount = "0.00";
var goods_total_percent = "0.0";

var overhead_amount = "0.00";
var overhead_percent = "0.0";

var commission_amount = "0.00";
var commission_percent = "0.0";

// var fees_amount = "0.00";
// var fees_percent = "0.0";

var profit = "0.00";
var profit_percent = "0.0";


//----------------------------
// Selling Price
//----------------------------

var grand_total = "0.00";
var unit_qty = "0";
var price_each = "0.00";



//----------------------------
//Breakeven
//----------------------------

var breakeven = "0.00"

var be_goods_total_amount = "0.00"
var be_zerobased_overhead_amount = "0.00"
var be_commission_amount = "0.00"
//var be_fees_amount = "0.00"
var be_profit = "0.00";



var cogs_blox = [];



export const calculator = [


    //-----------------------------------
    // Report Box - Graphic and Ledger
    //-----------------------------------

    {

        "print"		    :'bar',
        "name"		    :'Summary',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"20px"
        },
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'report_box',
        "name"		    :'Report Box',
        "value"		    :[

            {

                boxCall:'graphic',
                boxWidth:"30%",
                boxHeight:250,

                svgCall:"price_tag",
                svgWidth:230,
                svgHeight:230,

            },

            {
                boxCall:'ledger',
                boxWidth:"70%",
                boxHeight:270,
                value:[


                    {
                        name:"Selling Price",
                        value:grand_total
                    },

                    {
                        name:"Quantity",
                        value:unit_qty
                    },

                    {
                        name:"Price Each",
                        value:price_each
                    },

                    {
                        name:"Net Profit",
                        value:profit
                    },

    
                    {
                        name:"Net Profit %",
                        value:profit_percent+"%"
                    },

                    {
                        name:"Breakeven",
                        value:breakeven
        
                    }

    

                ]

            }



        ],
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"30px"
        },
        "required"	    :"",
        "default"	    :""


    },













    //-----------------------------------
    // Ledger
    //-----------------------------------

    {

        "print"		    :'bar',
        "name"		    :'Details',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'ledger',
        "name"		    :"Ledger",
        "value"		    :[



            // {
            //     name:"Cost Of Goods",
            //     value:"3.0%",
            //     percent:"20",
            //     bullet:{color:"#ff0"}

            // },
            // {
            //     name:"Overhead",
            //     value:"$1500.00",
            //     percent:"20",
            //     bullet:{color:"#f00"}

            // },
            // {
            //     name:"Commission",
            //     value:"$125.00",
            //     percent:"20",
            //     bullet:{color:"orange"}

            // },
            // {
            //     name:"Fees",
            //     value:"$1500.00",
            //     percent:"20",
            //     bullet:{color:"orange"}//#687484

            // },
            // {
            //     name:"Net Profit",
            //     value:"$1000.00",
            //     percent:"20",
            //     bullet:{color:"#00ff00"}

            // }





            {
                name:"Cost Of Goods",
                value:goods_total_amount,
                percent:goods_total_percent,
                //bullet:{color:"#ff0"}

            },
            {
                name:"Overhead",
                value:overhead_amount,
                percent:overhead_percent,
                //bullet:{color:"#f00"}

            },
            {
                name:"Commission",
                value:commission_amount,
                percent:commission_percent,
                //bullet:{color:"orange"}

            },
            // {
            //     name:"Fees",
            //     value:fees_amount,
            //     percent:fees_percent,
            //     //bullet:{color:"orange"}//#687484

            // },
            {
                name:"Net Profit",
                value:profit,
                percent:profit_percent,
                //bullet:{color:"#00ff00"}

            }






        ],
        "required"	    :"",
        "default"	    :"",
        "colors"        : "ChartColorsA"

    },

    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"50px"
        },
        "required"	    :"",
        "default"	    :""


    },














    //-----------------------------------
    // Ledger
    //-----------------------------------

    {

        "print"		    :'bar',
        "name"		    :'Selling Price',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'ledger',
        "name"		    :"Ledger",
        "value"		    :[


            {
                name:"Total Price",
                value:grand_total
            },
            {
                name:"Quantity",
                value:unit_qty
            },
            {
                name:"Price Each",
                value:price_each
            }


        ],
        "required"	    :"",
        "default"	    :"",
        "colors"        : "ChartColorsA"

    },

    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"50px"
        },
        "required"	    :"",
        "default"	    :""


    },










    //-----------------------------------
    // Ledger
    //-----------------------------------

    {

        "print"		    :'bar',
        "name"		    :'Breakeven Details',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'ledger',
        "name"		    :"Ledger",
        "value"		    :[



            // {
            //     name:"Cost Of Goods",
            //     value:"3.0%",
            //     percent:"20",
            //     bullet:{color:"#fc0"}

            // },
            // {
            //     name:"Overhead at 0% Net Profit",
            //     value:"$1500.00",
            //     percent:"20",
            //     bullet:{color:"#c00"}

            // },
            // {
            //     name:"Commission",
            //     value:"$125.00",
            //     percent:"20",
            //     bullet:{color:"orange"}

            // },
            // {
            //     name:"Fees",
            //     value:"$1500.00",
            //     percent:"20",
            //     bullet:{color:"orange"}//#687484

            // },
            // {
            //     name:"Net Profit",
            //     value:"$1000.00",
            //     percent:"20",
            //     bullet:{color:"#00ce00"}

            // }



            {
                name:"Cost Of Goods",
                value:be_goods_total_amount,
                percent:"20",
                //bullet:{color:"aqua"}

            },
            {
                name:"Overhead at 0% Net Profit",
                value:be_zerobased_overhead_amount,
                percent:"20",
                //bullet:{color:"aqua"}

            },
            {
                name:"Commission",
                value:be_commission_amount,
                percent:"20",
                //bullet:{color:"aqua"}

            },
            // {
            //     name:"Fees",
            //     value:be_fees_amount,
            //     percent:"20",
            //     //bullet:{color:"aqua"}//#687484

            // },
            {
                name:"Net Profit",
                value:be_profit,
                percent:"20",
                //bullet:{color:"aqua"}

            }








        ],
        "required"	    :"",
        "default"	    :"",
        "colors"        : "ChartColorsA"

    },

    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"50px"
        },
        "required"	    :"",
        "default"	    :""


    },






    //-----------------------------------
    // Blox
    //-----------------------------------

    {

        "print"		    :'bar',
        "name"		    :'Cost Of Goods',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'blox',
        "name"		    :"Blox",
        "value"		    :cogs_blox,
        "required"	    :"",
        "default"	    :"",
        "colors"        : "ChartColorsA"

    },

    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"50px"
        },
        "required"	    :"",
        "default"	    :""


    },






];



