import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';



import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    useHistory,   
    //useParams,


} from "react-router-dom";


import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider';
import useScreenOrientation from 'utils/hooks/ScreenOrientation';
//import { useFetch }  from 'utils/hooks/Fetch';


import {

    Stage,
    ScrollBox,
    Area,
    //AreaTitle

} from '../Styled';



import {

    LibArray

} from 'library';


import Messngr from 'utils/components/Process/Messngr';
import Readr from 'utils/components/Process/Readr';
import Loader from 'utils/components/Process/Loader';
//import Fetch from 'utils/components/Process/Fetch';

// import Header from "utils/components/Common/Header";
// import HeaderBar from "utils/components/Common/HeaderBar";
// // import Footer from "utils/components/Common/Footer";
// // import InfoBar from "utils/components/Common/InfoBar";


// import DefaultFetchResponse from '../../Default/FetchResponse';


//import StylScene from '../StylModules/Scene.module.css';
// import StylRows from '../StylModules/Rows.module.css';


import Choice from "./Choice";
import {

    Cred as StepCred,
    Company as StepCompany,
    Review as StepReview,
    Complete as StepComplete

} from "./Steps";


import { CheckCred } from 'utils/components/Pages/AuthLogo/SignUpWalk/_Ajax/CheckCred';
import { Submit } from 'utils/components/Pages/AuthLogo/SignUpWalk/_Ajax/Submit';



// import {SessionStore} from 'utils/components/Storage';



// import {

//     Cred as ComposedCred,
//     Company as ComposedCompany,

// } from './Steps/Composers';



import {

    Cred as ComposedCred,
    Company as ComposedCompany,

} from './Steps/Compose/FormData';




import Logo from 'utils/components/Logo';




export const SignUpWalk = (props) => {



    // -- window dims -- //
    const { width, height } = useWindowDimensions();

    // -- screen orientation -- //
    const screenOrientation = useScreenOrientation()



    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    const history = useHistory();


    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);




    // let zoneId;
    // zoneId = pathname.replace(/\//gi,"-");


    // -- redux -- //
    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));



    let currentZone = pathname;
    currentZone = currentZone.replace(/\//gi,"-");
    currentZone = currentZone.replace(/ /gi,"-");

    // //let zoneKey = currentZone + "_fetch";
    

    //------------------------------------------------------------------------------

    //const refArea = useRef(null);


    // -- ref -- //
    const refHeader = useRef(null);
    const refScroll = useRef(null);
    const refArea = useRef(null);




    //=================================================
    // state/effect
    //=================================================


    // --- stage,scrollbox --- //


    const [currentOrientation, setCurrentOrientation] = useState(false);


    const [headerCall] = useState("NA");//A,B,C

    // const [headerCall] = useState(DefaultFetchResponse.settings.appearance.header.headerCall);//A,B,C

    const [isLoaded, setIsLoaded] = useState(false);

    // const [scrollAtTop, setScrollAtTop] = useState(true);

    // const [scrollboxDif, setScrollboxDif] = useState(50); 
    // const [scrollboxOverflow, setScrollboxOverflow] = useState("scroll"); 

    //const [offsetClientDif, setOffsetClientDif] = useState(15);
  
    //const [viewWidth, setViewWidth] = useState(100);
    //const [viewHeight, setViewHeight] = useState(100);
 
    //const [headerSizes, setHeaderSizes] = useState(false);
    // const [headerPlaceholder, setHeaderPlaceholder] = useState(true);
   
    // const [headerBarTop, setHeaderBarTop] = useState(0);   

    // const [settings, setSettings] = useState(false);
    // const [sceneArr, setSceneArr] = useState([]);





    useEffect(() => {


        //var vw;
        //var vh;

        // var dif;
        // dif = 0;
        // if(props.primeProps.primeDif)
        // {
        //     if(props.primeProps.primeDif > 0) 
        //     {
        //         dif = props.primeProps.primeDif + 0;
            
        //     }     

        // }
        // //alert(dif);
        // setScrollboxDif(dif);

        // vh = height - dif + "px";
        // setViewHeight(vh);

        // offsetWidth includes width of scroll bar and clientWidth doesn't
        //vw = refScroll.current.clientWidth - 0 + "px";
        //setViewWidth(vw);

        // var ocDif = refScroll.current.offsetWidth - refScroll.current.clientWidth;
        // setOffsetClientDif(ocDif);




        // console.log("Scroll Height Check"
            
        //     +"\nScroll ScrollHeight: "+refScroll.current.scrollHeight
        //     +"\nArea OffetHeight: "+refArea.current.offsetHeight
        // );


        // var hdrBarOut;
        // switch(headerCall)
        // {
        // case'A':

        //     hdrBarOut = "0px";
        // break;
        // case'B':

        //     hdrBarOut = "0px";
        // break;
        // case'C':

        //     hdrBarOut = "0px";
        // break;
        // default:
        //     hdrBarOut = "0px";
        // }

        // if(!scrollAtTop) setHeaderBarTop("0px");
        // else setHeaderBarTop(hdrBarOut);




        // var resp;
        // var sett;
        // if(!isLoaded)
        // {
        //     setIsLoaded(true);

        //     //----------------------------------
        //     // Default Scene
        //     //----------------------------------

        //     if(sceneArr.length === 0)
        //     {
        //         if(props.primeProps.blueprint 
        //         && props.primeProps.blueprint !== undefined
        //         )
        //         {
        //             //console.log("blueprint: "+JSON.stringify(props.primeProps.blueprint,null,2));
        //             //alert("check blueprint")

        //             setSceneArr(SceneInfo);
        //         }else{
        //             setSceneArr(SceneInfo);
        //         }

        //     }




        //     //----------------------------------
        //     // Default Settings 
        //     //----------------------------------
          
        //     if(zoneObj["settings"])
        //     {
        //         if(!settings)
        //         {
        //             //alert('settings');
        //             resp = zoneObj["settings"];
        //             //console.log(resp);
        //             //alert(Object.prototype.toString.call(resp));
        //             if(resp )
        //             {
        //                 sett = JSON.parse(JSON.stringify(resp));//avoids the react-redux mutation error
        //                 //console.log(JSON.stringify(sett));
        //                 setSettings(sett);
        //             }

        //         }


        //     }else{

        //         //console.log("headerNav: "+JSON.stringify(DefaultFetchResponse.settings.headerNav));
        //         //alert('hold')

        //         setSettings(DefaultFetchResponse.settings);

        //         props.primeProps.handle({

        //             call:'dispatch',
        //             data:{

        //                 action      :'UPDATE_ZONE',
        //                 key         :'settings',
        //                 value       :DefaultFetchResponse.settings,
                        
        //             },
        //             usePrefix:false // will add currentZone to the zoneObj

        //         });

        //     }

        //     // //console.log("HeaderNavInfo: "+JSON.stringify(HeaderNavInfo));
        //     // setHeaderNavArr(HeaderNavInfo);


        // }else{



        //     // if(zoneObj["settings"] && !settings)
        //     // {
        //     //     //alert('settings');
        //     //     resp = zoneObj["settings"];
        //     //     // console.log(JSON.stringify(resp,null,2));
        //     //     // alert(Object.prototype.toString.call(resp));
        //     //     if(resp && resp !== undefined)
        //     //     {
        //     //         sett = JSON.parse(JSON.stringify(resp));//avoids the react-redux mutation error
        //     //         // console.log("SETTINGS: "+JSON.stringify(sett));
        //     //         setSettings(sett);
              
        //     //     }

        //     // }


        //     // if(headerCall === 'C')
        //     // {
        //     //     let siOne;
        //     //     siOne = sceneArr[0];
        //     //     if(siOne.call === 'Billboard')
        //     //     {
        //     //         //alert("is ---- "+siOne.call);
        //     //         setHeaderPlaceholder(false);
        //     //     }else{
        //     //         setHeaderPlaceholder(true);
        //     //     }
    
        //     // }
            
        // }





        if(!currentOrientation)
        {
            setCurrentOrientation(screenOrientation);
        }
        else
        if(currentOrientation !== screenOrientation
        )
        {
            // alert('window.location.reload');
            //window.location.reload(false);

            //console.log('update state method - changes viewWidth to trigger changes below');
            setCurrentOrientation(screenOrientation);
           
        }


        // console.log(""

        //     +"\nwidth..."+width
        //     +"\nheight..."+height

        //     +"\nscreen.width..."+screen.width
        //     +"\nscreen.height..."+screen.height

        //     +"\nscreenAvailW..."+screen.availWidth
        //     +"\nscreenAvailH..."+screen.availHeight

        //     +"\nscreenOrientation..."+screenOrientation


        // ); 
        
    },
    [
        isLoaded,
        props,
        width,height,
        zoneObj,
        pathname,
        currentZone,
        //fetchd,

        refScroll,
        refArea,

        headerCall,
        //scrollAtTop,
        //settings,
        //sceneArr,
        screenOrientation,
        currentOrientation



    ])






    //=================================================
    // state/effect
    //=================================================

    // --- source stuff --- //


    //const [isLoaded, setIsLoaded] = useState(false);
    //const [databind, setDatabind] = useState(false);
    const [walkChoice, setWalkChoice] = useState(false);
    const [walkSection, setWalkSection] = useState(false);   
    const [walkSections, setWalkSections] = useState({
        app:[],
        calc:[]
    }); 
    const [walkData, setWalkData] = useState(false);
    const [walkComplete, setWalkComplete] = useState(false);
    const [walkFinished, setWalkFinished] = useState(false);
  
    const [runCheckCred, setRunCheckCred] = useState(false);
    const [runSubmit, setRunSubmit] = useState(false);

    const [formArr, setFormArr] = useState([]);
    const [errorArr, setErrorArr] = useState([]);

    const [showMessngr, setShowMessngr] = useState(false);
    const [showReadr, setShowReadr] = useState(false);



    useEffect(() => {


        var scrlbxcolor;
        //scrlbxcolor = "#fcfcfc";
        scrlbxcolor = "#191e28";


        if(!isLoaded)
        {
            setIsLoaded(true);
            setWalkChoice(false);
            setWalkSection(false);
            setWalkSections({

                app:[
                    "Cred","Company","Review"
                ],
                calc:[
                    "Cred","Company","Review"
                ]

            });
           
            if(location.hash !== '') history.replace("/SignUpWalk");

            setFormArr([]);
            
        
        }

        // if(!databind)
        // {
        //     setDatabind(props.databind);
        // }


        // if(props.databind.sceneArr.length > 0)
        // {


        //     console.log("AreaEffect --- props.databind.sceneArr: "+JSON.stringify(props.databind.sceneArr,null,2));
  

        // }

        if(isLoaded)
        {

            if(location.hash === '')
            { 
                setWalkData(false);
                setErrorArr([]);
            }


            if(location.hash === '' && walkSection)
            {
                //alert(location.hash+" "+walkSection);
                setWalkChoice(false);
                setWalkSection(false);
      
                document.getElementById('scrollbox').style.backgroundColor = scrlbxcolor;
  
            }else{

                //console.log("change color for section")

                document.getElementById('scrollbox').style.backgroundColor = scrlbxcolor;

                if(location.hash.match(/cred/gi) 
                || location.hash.match(/company/gi) 
                || location.hash.match(/review/gi) 
                || location.hash.match(/complete/gi) 
                ){
            
                    document.getElementById('scrollbox').style.backgroundColor = scrlbxcolor;

                }
        
            }


        }



        if(walkComplete && walkFinished)
        {
            //console.log(":::FINISHED:::");

            // if(location.hash.match(/cred/gi) 
            // || location.hash.match(/company/gi) 
            // || location.hash.match(/review/gi) 
            // ){

            //     console.log(":::FINISHED:::");
            //     setIsLoaded(false);
            //     setWalkData(false);
            //     setWalkComplete(false);
                
            // }

            if(walkSection)
            {
                setWalkData(false);
                setErrorArr([]);
            }

            if(location.hash.match(/cred/gi) 
            || location.hash.match(/company/gi) 
            || location.hash.match(/review/gi) 
            ){
                //console.log("Back To Sign Up Choice");
                setIsLoaded(false);
                setWalkComplete(false);
                setWalkFinished(false);
            }


        }


  
    },
    [
        props,
        location,history,
        zoneObj,    
        isLoaded,
        walkSection,walkComplete,walkFinished

    ])

    







    //=================================================
    // handleScroll
    //=================================================

    // const handleScroll = () => {


    //     //console.log("handleScroll... ");

    //     if(headerSizes)
    //     {
    //         var scrollDif;
        
    //         switch(headerCall)
    //         {
    //         case'A':
    
    //             scrollDif = 0;
    //             //scrollDif = headerSizes.navH;
    //         break;
    //         case'B':

    //             scrollDif = 0;
    //         break;
    //         case'C':

    //             scrollDif = 0;
    //         break;
    //         default:
    //             scrollDif = 10;
    //         }



    //         // console.log("Scroll Stuff"
                    
    //         //     +"\nscrollDif: "+scrollDif
    //         //     +"\nscrollTop: "+refScroll.current.scrollTop
    //         //     +"\neaderSizes: "+JSON.stringify(headerSizes,null,2)
                
    //         // );



    //         if(refScroll.current.scrollTop > (scrollDif))
    //         {
    //             if(scrollAtTop)
    //             {
                    
    //                 // console.log("Show Mini Header"
                    
    //                 //     +"\nScroll: "+refScroll.current.offsetTop
    //                 //     +"\nheaderSizes: "+JSON.stringify(headerSizes,null,2)
                        
    //                 // );

    //                 //make sure viewWidth is set to clientWidth
    //                 var vw = refScroll.current.clientWidth + "px";// offsetWidth includes width of scroll bar and clientWidth doesn't
    //                 //console.log("handleScroll - VW: "+vw);
    //                 setViewWidth(vw);

    //                 setScrollAtTop(false);
    //             }





    //         }else{

    //             // console.log("No Mini Header"

    //             //     +"\nScroll: "+refScroll.current.offsetTop
    //             //     +"\nheaderHeight: "+headerHeight
                
    //             // );

    //             setScrollAtTop(true);

    //         }


    //     }


    // }












    //=================================================
    // handle
    //=================================================

    const handle = (hndl) => {


        //alert(hndl.call);


        var key;
        var array,object,arr,obj;
        var revArray,revArr;
        var currentData;
        var displayMessage,msg,msgBgColor,msgTxtColor;

        var sections, section, currIndex, nextIndex;
    

        switch(hndl.call)
        {
        case'makeChoice':

            history.push("#!"+hndl.data.hash);   
            setWalkChoice(hndl.data.choice);      
            setWalkSection(hndl.data.hash);
        break; 
        case'changeSection':

            //alert(hndl.data);

            if(hndl.data === '') 
            {
                history.replace("/SignUpWalk");
                setWalkChoice(false);      
                setWalkSection(false);
            }else{
                history.push("#!"+hndl.data);
                setWalkSection(hndl.data);
            }


        break;
        case'submitCred':

            //alert(hndl.data);

            if(runCheckCred)
            {
                //alert("Stop Run CheckCred");

                setRunCheckCred(false);


                // console.log(JSON.stringify(hndl.data.summary,null,2));
                // alert('check summary');

                // console.log(JSON.stringify(hndl.data.account,null,2));
                // alert('check account');


                displayMessage = false;
                msg = false;
                switch(hndl.success)
                {
                case'SUCCESS':
                break;
                default:

                    displayMessage = true;

                    msg = hndl.message;
                    msgBgColor  = "#fc0";
                    msgTxtColor = "black";

                };
                if(hndl.success && hndl.data.summary.exists)
                {
                    displayMessage = true;

                    msg = "User already exists";
                    msgBgColor  = "#fc0";
                    msgTxtColor = "black";
                }
                if(displayMessage && msg)
                {

                    setShowMessngr({

                        call            :"showMessngr",
                        isError         : false,
                        errorType       : 'na',
                        isTimed         : true,
                        timedDuration   : 1800,
                        items           : [
    
                            {
                                call        :"heading",
                                text        :"Alert",
                                bgColor     :msgBgColor,
                                txtColor    :msgTxtColor

                            },
                            {
                                "call":"span",
                                "text":msg
                            }
    
                        ],

    
                    });


                }else{

                    // if(hndl.data)
                    // {
                    //     setWalkData(false);
                    // }

                    console.log("User Doesn't Exist");



                    // --- next --- //

                    sections = walkSections[walkChoice];
                    object = location.hash.replace(/#!/gi,"");

                    console.log("sections: "+JSON.stringify(sections,null,2));
                    console.log("find: "+object);

                    if(LibArray.InArray(object,sections))
                    {
                        currIndex = sections.indexOf(object);
                        nextIndex = parseInt(currIndex) + 1;
                        if(parseInt(nextIndex) === sections.length)
                        {
                            nextIndex = parseInt(currIndex);
                        }
                    }

                    section = walkSections[walkChoice][nextIndex];
                    //alert("section: "+section);

                    history.push("#!"+section);
                    setWalkSection(section);
        
                }



            }else{

                //alert("Run CheckCred")
                array = JSON.parse(JSON.stringify(ComposedCred));
                if(walkData) 
                {
                    if(walkData['Cred'] && walkData['Cred'] !== undefined)
                    {
                        array = walkData['Cred'];
                    }
                }
                //alert(JSON.stringify(array,null,2));

                setFormArr(array);
                setRunCheckCred(true);
            }

        break;
        case'submitCompany':
        

            array = JSON.parse(JSON.stringify(ComposedCompany));
            if(walkData) 
            {
                if(walkData['Company'] && walkData['Company'] !== undefined)
                {
                    array = walkData['Company'];
                }
            }
            object = JSON.parse(JSON.stringify(walkData));
            object['Company'] = array;

            // console.log("submitCompany");
            // console.log(JSON.stringify(object,null,2));
            // alert('submitCompany');

            setWalkData(object);



            // --- next --- //

            sections = walkSections[walkChoice];
            object = location.hash.replace(/#!/gi,"");

            console.log("sections: "+JSON.stringify(sections,null,2));
            console.log("find: "+object);

            if(LibArray.InArray(object,sections))
            {
                currIndex = sections.indexOf(object);
                nextIndex = parseInt(currIndex) + 1;
                if(parseInt(nextIndex) === sections.length)
                {
                    nextIndex = parseInt(currIndex);
                }
            }

            section = walkSections[walkChoice][nextIndex];
            //alert("section: "+section);

            history.push("#!"+section);
            setWalkSection(section);



        break;
        case'submit':

            //alert("submit");

            if(runSubmit)
            {
                //alert("Stop Run Submit");

                setRunSubmit(false);

                // console.log(JSON.stringify(hndl.data,null,2));
                // alert('submit - summary');


                displayMessage = false;
                msg = false;
                switch(hndl.success)
                {
                case'SUCCESS':

                    //displayMessage = true;

                    // console.log("::: SUBMIT SUCCESS :::");
                    // console.log(JSON.stringify(hndl.data,null,2));
                    // alert('submit - summary');
                break;
                default:

                    displayMessage = true;

                    msg = hndl.message;
                    msgBgColor  = "#fc0";
                    msgTxtColor = "black";

                };
                if(displayMessage && msg)
                {

                    setShowMessngr({

                        call            :"showMessngr",
                        isError         : false,
                        errorType       : 'na',
                        isTimed         : true,
                        timedDuration   : 1800,
                        items           : [
    
                            {
                                call        :"heading",
                                text        :"Alert",
                                bgColor     :msgBgColor,
                                txtColor    :msgTxtColor

                            },
                            {
                                "call":"span",
                                "text":msg
                            }
    
                        ],

    
                    });



                }else{


                    // console.log("::: SUBMIT SUCCESS :::");
                    // console.log(JSON.stringify(hndl.data,null,2));
                    // alert('submit - summary');


                    setWalkData(false);
                    console.log("submit - complete");
                    currentData = {

                        signup_choice:walkChoice
         
                    };

                    // for(key in hndl.data.summary)
                    // {
                    //     currentData[key] = hndl.data.summary[key]
                    // }

                    currentData['account-id']    = hndl.data.summary.accountId;
                    currentData['created-at']    = hndl.data.summary.createdAt;
                    currentData['login-email']   = hndl.data.summary['email'];
                    currentData['message']       = hndl.data.summary['status'];

                    // console.log("::: capture - currentData :::")
                    // console.log(JSON.stringify(currentData,null,2));
                    // alert('currentData');

                    setWalkComplete(currentData);
                    history.replace("SignUpWalk");


    


                    // --- next --- //

                    // sections = walkSections[walkChoice];
                    // object = location.hash.replace(/#!/gi,"");

                    // console.log("sections: "+JSON.stringify(sections,null,2));
                    // console.log("find: "+object);


                    // if(LibArray.InArray(object,sections))
                    // {
                    //     currIndex = sections.indexOf(object);
                    //     nextIndex = parseInt(currIndex) + 1;
                    //     //alert(nextIndex);
                    //     if(parseInt(nextIndex) === sections.length)
                    //     {
                    //         nextIndex = parseInt(currIndex);
                    //     }
                    // }
                    // section = walkSections[walkChoice][nextIndex];
                    // //alert("section: "+section);

                    // history.push("#!"+section);
                    // setWalkSection(section);


        
                }



            }else{



                array = [];
                if(walkData) 
                {

                    key = 'Cred';
                    if(walkData[key] && walkData[key] !== undefined)
                    {
                        if(walkData[key].length > 0) array = array.concat(walkData[key]);

                        // for(object of walkData[key])
                        // {
                        //     if(object.section === 'fields')
                        //     {
                        //         //array.push(object);
                        //         for(obj of object.data)
                        //         {
                        //             array.push({

                        //                 name:obj.id,
                        //                 value:obj.text
                        //             });
                        //         }

                        //     }//if

                        // }//for

                    }

                    key = 'Company';
                    if(walkData[key] && walkData[key] !== undefined)
                    {

                        if(walkData[key].length > 0) array = array.concat(walkData[key]);

                        // for(object of walkData[key])
                        // {
                        //     if(object.section === 'fields')
                        //     {
                        //         //array.push(object);
                        //         for(obj of object.data)
                        //         {
                        //             array.push({

                        //                 name:obj.id,
                        //                 value:obj.text
                        //             });
                        //         }

                        //     }//if
                            
                        // }//for                   
                    }


                }

                // console.log("::: RUN SUBMIT :::");
                // console.log(JSON.stringify(array,null,2));
                // alert("Run Submit");

                setFormArr(array);
                setRunSubmit(true);
            }



        break;
        case'finish':

            //alert('finished');
            setWalkFinished(true);
        break;
        case'error':

            console.log("Errors: "+JSON.stringify(hndl.errors,null,2));

            // "key"       :i+"-"+j,
            // "section"   :frm.section,
            // "name"      :field.id,
            // "message"   :label+" (Required)"

            if(hndl.message)
            {
                setShowMessngr({

                    call            :"showMessngr",
                    isError         : false,
                    errorType       : 'na',
                    isTimed         : true,
                    timedDuration   : 1800,
                    items           : [

                        {
                            "call":"heading",
                            "text":"Alert" 
                        },
                        {
                            "call":"span",
                            "text":hndl.message
                        }

                    ]

                });


            }else{


                setErrorArr(hndl.errors);
                setFormArr([]);
                if(runCheckCred) setRunCheckCred(false);


                // array = [];
                // for(object of hndl.errors)
                // {
                //     if(object.name === 'email') object.name = "Email";
                //     if(object.name === 'pword') object.name = "Password";

                //     array.push({

                //         call:"span",
                //         text:LibString.CapitalizeWords(object.name) + " is not valid"

                //     });

                // }//for

                // if(array.length === 0)
                // {

                //     array = [

                //         // {
                //         //     "call":"heading",
                //         //     "text":"Alert" 
                //         // },
                //         {
                //             "call":"span",
                //             "text":"An error has occured" 
                //         }
        
                //     ];

                // }

                // setShowMessngr({

                //     call            :"showMessngr",
                //     isError         : true,
                //     errorType       : hndl.type,
                //     isTimed         : true,
                //     timedDuration   : 1800,
                //     items           : array
                //     // items           : [

                //     //     // {
                //     //     //     "call":"heading",
                //     //     //     "text":"Alert" 
                //     //     // },
                //     //     {
                //     //         "call":"span",
                //     //         "text":"Overhead should not exceed 75%" 
                //     //     }

                //     // ]

                // });

            }


        break;
        case'capture':

            if(errorArr.length > 0) setErrorArr([]);

            //alert('capture');

            // console.log("fieldCall: "+hndl.fieldCall);
            // console.log("key: "+hndl.key);
            // console.log("id: "+hndl.data.id);
            // console.log("newValue: "+hndl.newValue);
            // console.log("data: "+JSON.stringify(hndl.data,null,2));

            // fieldCall: field
            // key: 1-0
            // id: email
            // newValue: Tom
            // data: {
            //   "fieldtype": "text",
            //   "required": true,
            //   "class": "",
            //   "id": "email",
            //   "hint": "Email",
            //   "text": "",
            //   "keyboardType": "email",
            //   "autocorrect": "false",
            //   "autocapitalizationType": "none"
            // }

            switch(walkChoice)
            {
            case'app':

                if(walkData)
                {
                    currentData = walkData;
                }else{
                    currentData = {};
                }
                //alert(JSON.stringify(currentData,null,2));


                switch(walkSection)
                {
                case'Cred':


                    // console.log(JSON.stringify(ComposedCred,null,2));
                    // alert('ComposedCreds')

                    // [
                    //     {
                    //       "call": "header",
                    //       "data": {
                    //         "text": "Sign Up Walk"
                    //       }
                    //     },
                    //     {
                    //       "section": "fields",
                    //       "data": [
                    //         {
                    //           "fieldtype": "text",
                    //           "required": true,
                    //           "class": "",
                    //           "id": "email",
                    //           "hint": "Email",
                    //           "text": "",
                    //           "keyboardType": "email",
                    //           "autocorrect": "false",
                    //           "autocapitalizationType": "none"
                    //         },
                    //         {
                    //           "fieldtype": "password",
                    //           "required": true,
                    //           "class": "",
                    //           "id": "pword",
                    //           "hint": "Password",
                    //           "text": "",
                    //           "keyboardType": "",
                    //           "autocorrect": "false",
                    //           "autocapitalizationType": "none"
                    //         }
                    //       ]
                    //     }
                    // ]

            
                    key = walkSection;
                    revArray = [];
                    array = JSON.parse(JSON.stringify(ComposedCred));
                    if(walkData) 
                    {
                        if(walkData[key] && walkData[key] !== undefined)
                        {
                            if(walkData[key].length > 0) array = walkData[key];
                        }
                    }
                    for(object of array)
                    {
                        //alert(object.section);
                        if(object.section === 'fields')
                        {
                            revArr = [];
                            arr = object.data;
                            for(obj of arr)
                            {
                                //alert(obj.id+" "+hndl.data.id);
                                if(obj.id === hndl.data.id)
                                {
                                    obj.text = hndl.newValue;
                                
                                }//if
                                revArr.push(obj);
                            }//for

                            object.data = revArr;

                        }//if

                        revArray.push(object);

                    }//for
                    
                    
                    currentData[key] = revArray;

            

                    // switch(hndl.fieldCall)
                    // {
                    // case'field':

                    //     hndl.data.text = hndl.newValue;
                    //     currentData[hndl.data.id] = hndl.data; 
                    // break;
                    // default:
                    // }


                break;
                case'Company':

                    //alert('Company');
                    key = walkSection;
                    revArray = [];
                    array = JSON.parse(JSON.stringify(ComposedCompany));
                    //console.log(JSON.stringify(array,null,2));
    
                    if(walkData) 
                    {
                        if(walkData[key] && walkData[key] !== undefined)
                        {
                            if(walkData[key].length > 0) array = walkData[key];
                        }
                    }
                    // console.log(JSON.stringify(array,null,2));
                    // alert("key: "+key);

                    for(object of array)
                    {
                        //alert(object.section);
                        if(object.section === 'fields')
                        {
                            revArr = [];
                            arr = object.data;
                            for(obj of arr)
                            {
                                //alert(obj.id+" "+hndl.data.id);
                                if(obj.id === hndl.data.id)
                                {
                                    obj.text = hndl.newValue;
                                
                                }//if
                                revArr.push(obj);
                            }//for

                            object.data = revArr;

                        }//if

                        revArray.push(object);

                    }//for
                    


                    currentData[key] = revArray;

                break;
                default:
                }



            break;
            case'calc':


                if(walkData)
                {
                    currentData = walkData;
                }else{
                    currentData = {};
                }
                //alert(JSON.stringify(currentData,null,2));


                switch(walkSection)
                {
                case'Cred':


                    // console.log(JSON.stringify(ComposedCred,null,2));
                    // alert('ComposedCreds')

                    // [
                    //     {
                    //       "call": "header",
                    //       "data": {
                    //         "text": "Sign Up Walk"
                    //       }
                    //     },
                    //     {
                    //       "section": "fields",
                    //       "data": [
                    //         {
                    //           "fieldtype": "text",
                    //           "required": true,
                    //           "class": "",
                    //           "id": "email",
                    //           "hint": "Email",
                    //           "text": "",
                    //           "keyboardType": "email",
                    //           "autocorrect": "false",
                    //           "autocapitalizationType": "none"
                    //         },
                    //         {
                    //           "fieldtype": "password",
                    //           "required": true,
                    //           "class": "",
                    //           "id": "pword",
                    //           "hint": "Password",
                    //           "text": "",
                    //           "keyboardType": "",
                    //           "autocorrect": "false",
                    //           "autocapitalizationType": "none"
                    //         }
                    //       ]
                    //     }
                    // ]

            
                    key = walkSection;
                    revArray = [];
                    array = JSON.parse(JSON.stringify(ComposedCred));
                    if(walkData) 
                    {
                        if(walkData[key] && walkData[key] !== undefined)
                        {
                            if(walkData[key].length > 0) array = walkData[key];
                        }
                    }
                    for(object of array)
                    {
                        //alert(object.section);
                        if(object.section === 'fields')
                        {
                            revArr = [];
                            arr = object.data;
                            for(obj of arr)
                            {
                                //alert(obj.id+" "+hndl.data.id);
                                if(obj.id === hndl.data.id)
                                {
                                    obj.text = hndl.newValue;
                                 
                                }//if
                                revArr.push(obj);
                            }//for

                            object.data = revArr;

                        }//if

                        revArray.push(object);

                    }//for
                    
                    
                    currentData[key] = revArray;

            

                    // switch(hndl.fieldCall)
                    // {
                    // case'field':

                    //     hndl.data.text = hndl.newValue;
                    //     currentData[hndl.data.id] = hndl.data; 
                    // break;
                    // default:
                    // }


                break;
                case'Company':

                    //alert('Company');
                    key = walkSection;
                    revArray = [];
                    array = JSON.parse(JSON.stringify(ComposedCompany));
                    //console.log(JSON.stringify(array,null,2));
      
                    if(walkData) 
                    {
                        if(walkData[key] && walkData[key] !== undefined)
                        {
                            if(walkData[key].length > 0) array = walkData[key];
                        }
                    }
                    // console.log(JSON.stringify(array,null,2));
                    // alert("key: "+key);

                    for(object of array)
                    {
                        //alert(object.section);
                        if(object.section === 'fields')
                        {
                            revArr = [];
                            arr = object.data;
                            for(obj of arr)
                            {
                                //alert(obj.id+" "+hndl.data.id);
                                if(obj.id === hndl.data.id)
                                {
                                    obj.text = hndl.newValue;
                                 
                                }//if
                                revArr.push(obj);
                            }//for

                            object.data = revArr;

                        }//if

                        revArray.push(object);

                    }//for
                    


                    currentData[key] = revArray;

                break;
                default:
                }



            break;
            default:
            }

            // console.log("::: capture - currentData :::")
            // console.log(JSON.stringify(currentData,null,2));
            // alert('currentData');
            setWalkData(currentData);

        break;
        case'showReadr':
        //case'output':

            setRunCheckCred(false);
            setRunSubmit(false);

            if(showReadr) 
            {
                setShowReadr(false);
            }else{
                setShowReadr(hndl.data);         
            }

        break;
        case'timeout':
        case'hide':

            setShowReadr(false);
            setShowMessngr(false);



            if(location.hash === '#!Review')
            {

                // --- next --- //

                sections = walkSections[walkChoice];
                object = location.hash.replace(/#!/gi,"");

                console.log("sections: "+JSON.stringify(sections,null,2));
                console.log("find: "+object);

                if(LibArray.InArray(object,sections))
                {
                    currIndex = sections.indexOf(object);
                    nextIndex = parseInt(currIndex) + 1;
                    //alert(nextIndex+"\n"+sections.length);
                    if(parseInt(nextIndex) === sections.length)
                    {
                        nextIndex = parseInt(currIndex);
                    }
                }
                section = walkSections[walkChoice][nextIndex];
                // alert("section: "+section);

                history.push("#!"+section);
                setWalkSection(section);


            }



        break;
        case'url':


            //alert('url');


            switch(hndl.action)
            {
            case'reload':

                window.location.reload();//regular dom 
            break;
            case'replace':

                window.location.replace(hndl.url);//regular dom 
            break;
            case'target':

                // window.location.replace(hndl.url);//regular dom 
            break;
            case'redirect':
        
                history.replace(hndl.url);
            break;
            case'push':

                history.push(hndl.url);
            break;
            default:
            }



        break;
        default:
        }

    };





    // const handleDispatch = (hndl) => {

    //     //if(hndl.action === 'DELETE_ZONE') alert('delete zone')

    //     dispatch(ModifyZoneStore({
    
    //         type:hndl.action,//UPDATE_ZONE or DELETE_ZONE
    //         item:{
    //             key     :hndl.key,
    //             value   :hndl.value
    //         }

    //     })); 

    // }

    


    // const handleUrl = (hndl) => {


    //     // var url;
    //     // if(pathname.match(/\//gi)) 
    //     // {
    //     //     url = pathname.split("/")[0];
    //     // }else{
    //     //     url = pathname;
    //     // }
    //     // // url = zoneObj.basename + url + "/" + hndl.url;
    //     // url = zoneObj.basename + hndl.url;
        

    //     /*
    //     alert(""
    //     +"\nLOC PATHNAME: "+location.pathname
    //     +"\nPATHNAME: "+pathname
    //     +"\nHNDL URL: "+hndl.url
    //     +"\nURL: "+url
    //     );
    //     */

    //    //window.location.href = url;


       
    //    history.push(hndl.url);
   
   
       
    // } 






    const handleMovement = (hndl) => {

        
        console.log("handleMovement - not using here");

    }









    if(!isLoaded) 
    {

        //return <div className="area-title">Loading...</div>


        return (<div>



            <Stage>

                {/* <HeaderBar
                    
                    handle = {(v) => handle(v)}
                    headerCall={headerCall}                
                    scrollAtTop={scrollAtTop}    
                    width={viewWidth}
                    top={headerBarTop}

                /> */}


                <ScrollBox
                ref={refScroll}
                id={"scrollbox"}
                height={"100vh"}
                //height={"calc(100% - "+(scrollboxDif)+"px)"}
                //overflow={scrollboxOverflow}
                //onScroll={handleScroll}
                >



                    {/* <HeaderBar
                        
                        handle = {(v) => handle(v)}
                        databind = {{

                            primeProps      :props.primeProps,
                            headerCall      :headerCall,               
                            scrollAtTop     :scrollAtTop, 
                            width           :viewWidth,
                            top             :headerBarTop,
                            offsetClientDif :offsetClientDif,
                            settings        :settings,
                            
                            headerSizes     :headerSizes,
                            viewHeight      :viewHeight,
                            sceneArr        :sceneArr

                        }}
                        
                                      

                    /> */}


                    <div
                    ref={refHeader}
                    id={'hdr'}
                    style={{
                        backgroundColor:"darkblue",
                        width:"100%",            
                        height:"auto",
                        margin:"0 0 0 0",
                        padding:"0 0 0 0",         
                        position:"relative",
                        floar:"left",

                    }}
                    >

                        {/* <Header 
                            
                            handle = {(v) => handle(v)}
                            databind = {{

                                primeProps  :props.primeProps,
                                headerCall  :headerCall,               
                                scrollAtTop :scrollAtTop, 
                                width       :viewWidth,
                                top         :headerBarTop,
                                placeholder :headerPlaceholder,
                                settings    :settings,
                                
                                headerSizes     :headerSizes,
                                viewHeight      :viewHeight,
                                sceneArr        :sceneArr


                            }}
                            
        

                        /> */}


                    </div>



                    <Area
                    ref={refArea}
                    maxWidth={1680+"px"}
                    width={"96%"}
                    height={"auto"}
                    //backgroundColor={"powderblue"}
                    >

                        <div id={"LoadingNPreset"}>

                            {/* <div 
                            style={{

                                width:"100%",
                                height:"auto",
                                padding:"15px 0 15px 0",
                                margin:"50px auto",
                                textAlign:"center",
                                color:"black"
                            }}
                            >

                                Loading...
                          
                            </div> */}


                            <Loader 
                                call={"pageLoader"}
                                litedark={"dark"}
                            />
 
                            {/* {sceneArr.map((row, i) => (

                                <div
                                key={i}
                                >
                                    <div
                                    //ref={setRowRef}
                                    id={"row_"+i}
                                    >
                                    </div>

                                </div>

                            ))} */}

                        </div>

                    </Area>


                </ScrollBox>


            </Stage>




        </div>)



    } 
    else 
    {



        return (<div>


            {
            runCheckCred 
            ?

                <div>
                    <Loader />
                    <CheckCred 

                        handle={handle}
                        formArr={formArr}

                    />
                </div>


            :null
            }

            {
            runSubmit 
            ?

                <div>
                    <Loader />
                    <Submit 
                    
                        handle={handle}
                        formArr={formArr}
                        walkChoice={walkChoice}

                    />
                </div>


            :null
            }




            {
            showMessngr ? 

                <Messngr
                    
                    handle={(v) => handle(v)}    
                    data={showMessngr}     
        
                /> 

            : null
            }



            {
            showReadr ? 

                <Readr
                    
                    handle={(v) => handle(v)}    
                    data={showReadr}     
        
                /> 

            : null
            }







            <Stage>

                {/* <HeaderBar
                    
                    handle = {(v) => handle(v)}
                    headerCall={headerCall}                
                    scrollAtTop={scrollAtTop}    
                    width={viewWidth}
                    top={headerBarTop}

                /> */}


                <ScrollBox
                ref={refScroll}
                id={"scrollbox"}
                height={"100vh"}
                //height={"calc(100% - "+(scrollboxDif)+"px)"}
                //overflow={scrollboxOverflow}
                //onScroll={handleScroll}
                >

                    {/* <HeaderBar
                        
                        handle = {(v) => handle(v)}
                        databind = {{

                            primeProps      :props.primeProps,
                            headerCall      :headerCall,               
                            scrollAtTop     :scrollAtTop, 
                            width           :viewWidth,
                            top             :headerBarTop,
                            offsetClientDif :offsetClientDif,
                            settings        :settings,

                            headerSizes     :headerSizes,
                            viewHeight      :viewHeight,
                            sceneArr        :sceneArr


                        }}

                        
                    /> */}

                    <div
                    ref={refHeader}
                    id={'hdr'}
                    style={{
                        backgroundColor:"transparent",
                        width:"100%",            
                        height:"auto",
                        margin:"0 0 0 0",
                        padding:"0 0 0 0",         
                        //position:"relative",
                        //floar:"left",

                    }}

                    >
                        {/* <Header 
                        
                            handle = {(v) => handle(v)}
                            databind = {{

                                primeProps  :props.primeProps,
                                headerCall  :headerCall,               
                                scrollAtTop :scrollAtTop, 
                                width       :viewWidth,
                                top         :headerBarTop,
                                placeholder :headerPlaceholder,
                                settings    :settings,

                                headerSizes     :headerSizes,
                                viewHeight      :viewHeight,
                                sceneArr        :sceneArr

                            }}


                            // headerCall={headerCall}                
                            // scrollAtTop={scrollAtTop}    
                            // width={viewWidth}
                            // top={headerBarTop}
                            // placeholder={headerPlaceholder} 
                            

                        /> */}


                        {/* <Logo 

                            call="theprofitcalculator"
                        
                        /> */}

                        <div
                        style={{
                            backgroundColor:"gray",
                            width:"auto",            
                            height:"auto",
                            margin:"0 auto 0 auto",
                            padding:"0 0 0 0",         
                            position:"relative",
                            floar:"left"


                        }}

                        onClick={(v) => handle({
                            call:'url', 
                            action:"push", 
                            url:"/"
                        })}

                        >

                            <Logo 

                                call="theprofitcalculator"

                            />

                        </div>




                    </div>







                    {
                    walkComplete 
                    ?

                        <StepComplete

                            handle={(v) => handle(v)}
                            walkChoice={walkChoice}                 
                            walkData={walkComplete}
                            walkFinished={walkFinished}


                        />

                    :
                    walkChoice === 'app' 
                    ?


                        location.hash.match(/#!Cred/)
                        ?

                            <StepCred

                                handleMovement={(v) => handleMovement(v)}
                                handle={(v) => handle(v)}
                                sections={walkSections[walkChoice]}
                                currentSection={0}
                                items={walkData['Cred']}
                                errorArr={errorArr}
                            />
                        :
                        location.hash.match(/#!Company/)
                        ?

                            <StepCompany

                                handleMovement={(v) => handleMovement(v)}
                                handle={(v) => handle(v)}
                                sections={walkSections[walkChoice]}
                                currentSection={1}
                                items={walkData['Company']}
                                errorArr={errorArr}
                            />
                            

                        :
                        location.hash.match(/#!Review/)
                        ?


                            <StepReview

                                handle={(v) => handle(v)}
                                sections={walkSections[walkChoice]}
                                currentSection={2}

                                walkChoice={walkChoice}
                                walkData={walkData}

                            />

                        // :
                        // location.hash.match(/#!Complete/)
                        // ?

                        //     <StepComplete

                        //         handle={(v) => handle(v)}
                        //         sections={walkSections[walkChoice]}
                        //         currentSection={3}

                        //         walkChoice={walkChoice}                 
                        //         walkData={walkComplete}
                        //         walkFinished={walkFinished}


                        //     />


                        :null
                    
                            
                    :
                    walkChoice === 'calc' 
                    ?

                            

                        location.hash.match(/#!Cred/)
                        ?

                            <StepCred

                                handleMovement={(v) => handleMovement(v)}
                                handle={(v) => handle(v)}
                                sections={walkSections[walkChoice]}
                                currentSection={0}
                                items={walkData['Cred']}
                                errorArr={errorArr}
                            />
                        :
                        location.hash.match(/#!Company/)
                        ?

                            <StepCompany

                                handleMovement={(v) => handleMovement(v)}
                                handle={(v) => handle(v)}
                                sections={walkSections[walkChoice]}
                                currentSection={1}
                                items={walkData['Company']}
                                errorArr={errorArr}
                            />
                            

                        :
                        location.hash.match(/#!Review/)
                        ?

                            <StepReview

                                handle={(v) => handle(v)}
                                sections={walkSections[walkChoice]}
                                currentSection={2}

                                walkChoice={walkChoice}
                                walkData={walkData}

                            />


                        // :
                        // location.hash.match(/#!Complete/)
                        // ?

                        //     <StepComplete

                        //         handle={(v) => handle(v)}
                        //         sections={walkSections[walkChoice]}
                        //         currentSection={3}

                        //         walkChoice={walkChoice}                    
                        //         walkData={walkComplete}
                        //         walkFinished={walkFinished}

                        //     />



                        :null
                

                            
                
                    :// make choice

                        // <div></div>

                        <Choice 
                        
                            handle={(v) => handle(v)}
                            sections={walkSections['app']}
                        
                        />

                    }



                </ScrollBox>

            </Stage>




        </div>)


    }


}



