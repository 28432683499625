
import styled from "styled-components";
//import px2vw from "utils/px2vw";

/*
https://styled-components.com/docs


https://stackoverflow.com/questions/55906668/converting-css-with-nested-classes-to-styled-components-problem


Over 200 classes were generated for component styled.div with the id of "sc-AxirZ".
Consider using the attrs method, together with a style object for frequently changed styles.
Example:

const Component = styled.div.attrs({
    style: ({ background }) => {
        background,
    },
})`width: 100%;`

<Component />




calc(100% - 300px)


TM - Notes 

- ScrollBox calc(100% - 120px) 120px equals the HeaderNav height + InfoBar height
- Use vh - viewport height - to set the stage height


*/




export const Stage = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#191e28",
    width:              props.width || "100%",
    height:             props.height || "100vh",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0"


  }))`

    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};


    position:relative;
    float:left;

    display:block;
    overflow:hidden;

`;



export const ScrollBox = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#191e28",
    width:              props.width || "100%",
    height:             props.height || "calc(100% - 127px)",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",
    overflow:           props.overflow || "scroll" // scroll or auto


  }))`

    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};


    position:relative;   
    float:left;

    overflow-x:hidden;
    overflow-y:${props => props.overflow};

`;



export const Area = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    maxWidth:           props.maxWidth || "10000px",
    width:              props.width || "100%",
    height:             props.height || "auto",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 auto 0 auto"


  }))`

    background-color:${props => props.backgroundColor};
    max-width:${props => props.maxWidth};
    width:${props => props.width};
    height:${props => props.height};
    min-height:50px;
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;  
    overflow:hidden;

`;



export const AreaTitle = styled.div.attrs(props => ({

    //backgroundColor:    props.backgroundColor || "#edeef1", "#1A1926"
    backgroundColor:    props.backgroundColor || "transparent",
    width:              props.width || "100%",
    height:             props.height || "auto",
    padding:            props.padding || "8px 0 10px 0",
    margin:             props.margin  || "0 0 0 0"


  }))`

    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};


    position:relative;   
    float:left;

    display:block;
    overflow:hidden;

    font-family:"Verdana", sans-serif;      
    font-size: 32px;
    font-weight: normal;
    text-align: center;
    text-decoration:none;
    color:#00cc66;

    border:0px solid lightgray;
`;




export const PrintOut = styled.div.attrs(props => ({

  backgroundColor:    props.backgroundColor || "lightyellow",
  width:              props.width || "calc(90% - 30px)",
  height:             props.height || "auto",
  padding:            props.padding || "15px 15px 15px 15px",
  margin:             props.margin  || "0 0 100px calc(50% - 45%)"


}))`

  background-color:${props => props.backgroundColor};

  width:${props => props.width};
  height:${props => props.height};
  min-height:400px;

  padding:${props => props.padding};
  margin:${props => props.margin};


  position:relative;   
  float:left;

  display:block;
  overflow:hidden;

  font-family:"Verdana", sans-serif;      
  font-size: 16px;
  font-weight: normal;
  text-align: left;
  text-decoration:none;
  color:#1a1926;

  border:1px solid lightgray;



  .Title{

    width:100%;
    height:auto;
    margin:0 0 0 0;

    font-family:"Verdana", sans-serif;      
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    text-decoration:none;
    color:#0291cd;

  }


  .Text{

    width:98%;
    height:auto;
    margin:10px 0 25px 1.5%;

    font-family:"Verdana", sans-serif;      
    font-size: 16px;
    font-weight: normal;
    text-align: left;
    text-decoration:none;
    color:#1A1926;

  }




`;




export const Group = styled.div.attrs(props => ({

  backgroundColor:    props.backgroundColor || "lightseagreen",
  width:              props.width || "100%",
  height:             props.height || "auto",
  padding:            props.padding || "0 0 0 0",
  margin:             props.margin  || "0 0 0 0"


}))`

  background-color:${props => props.backgroundColor};
  width:${props => props.width};
  height:${props => props.height};
  padding:${props => props.padding};
  margin:${props => props.margin};

  font-size: calc(10px + 2vmin);
  color: white;


  position:relative;
  float:left;

`;


export const Member = styled.div.attrs(props => ({

  backgroundColor:    props.backgroundColor || "powderblue",
  width:              props.width || "100%",
  height:             props.height || "auto",
  padding:            props.padding || "0 0 0 0",
  margin:             props.margin  || "0 0 0 0"


}))`

  background-color:${props => props.backgroundColor};
  width:${props => props.width};
  height:${props => props.height};
  padding:${props => props.padding};
  margin:${props => props.margin};

  font-size: calc(10px + 2vmin);
  color: white;


  position:relative;
  float:left;

`;







export const Summary = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#fcfcfc",
    border:             props.border || "1px solid lightgray",
    borderRadius:       props.borderRadius || "7px",
    width:              props.width || "80%",
    height:             props.height || "auto",
    padding:            props.padding || "0 0 50px 0",
    margin:             props.margin  || "15px 0 100px 10%",

  }))`

    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    border-radius:${props => props.borderRadius};

    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    display:block;
    overflow:hidden;


    @media (min-width:1050px) {

        width:700px;
        margin:20px 0 50px calc(50% - 350px);
    }



    @media (max-width: 750px) {

        backgroundColor:#fcfcfc;
        border:0;
        border-radius:0;

        width:100%;
        margin:0 0 50px 0;
    }






`;





export const SummaryTop = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "1px solid lightgray",

    width:              props.width || "100%",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "0 0 3px 0",



    //title

    titleFontFamily:         props.titleFontFamily || 'Arial, Helvetica, sans-serif',
    titleFontSize:           props.titleFontSize || "32px",
    titleFontWeight:         props.titleFontWeight  || "550",
    titleTextAlign:          props.titleTextAlign || "left",
    titleTextDecoration:     props.titleTextDecoration  || "none",
    titleTextColor:          props.titleTextColor || "#454d5f"



    //border-bottom:${props => props.border};


}))`


    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;


    @media (max-width: 475px) {

        font-size:30px;
        margin-top:7px;
    }



    .Title {

        background-color:transparent;

        width:auto:
        height:auto;

        margin:0 0 0 20px;
        padding:11px 0 7px 0;

        position:relative;
        float:left;

        font-family:${props => props.titleFontFamily};
        font-size:${props => props.titleFontSize};
        font-weight:${props => props.titleFontWeight};
        text-align:${props => props.titleTextAlign};
        text-decoration:${props => props.titleTextDecoration};
        color:${props => props.titleTextColor};


    }





`;






export const SummaryLine = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "1px solid lightgray",

    width:              props.width || "100%",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "0 0 0 0",


    lineFontFamily:         props.lineFontFamily || 'Arial, Helvetica, sans-serif',
    lineFontSize:           props.lineFontSize || "18px",
    lineFontWeight:         props.lineFontWeight  || "500",
    lineTextAlign:          props.lineTextAlign || "left",
    lineTextDecoration:     props.lineTextDecoration  || "none",
    lineTextColor:          props.lineTextColor || "#000",



    //border-bottom:${props => props.border};


}))`


    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;

    font-family:${props => props.lineFontFamily};
    font-size:${props => props.lineFontSize};
    font-weight:${props => props.lineFontWeight};
    text-align:${props => props.lineTextAlign};
    text-decoration:${props => props.lineTextDecoration};
    color:${props => props.lineTextColor};


    @media (max-width: 475px) {

        font-size:30px;
        margin-top:7px;
    }


    .Line {

        background-color:transparent;

        width:auto:
        height:auto;

        margin:0 0 0 20px;
        padding:11px 0 7px 0;

        position:relative;
        float:left;

        font-family:${props => props.lineFontFamily};
        font-size:${props => props.lineFontSize};
        font-weight:${props => props.lineFontWeight};
        text-align:${props => props.lineTextAlign};
        text-decoration:${props => props.lineTextDecoration};
        color:${props => props.lineTextColor};


    }




`;







export const SummaryButton = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "1px solid lightgray",

    width:              props.width || "auto",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 20px",
    padding:            props.padding || "0 0 3px 0",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "18px",
    fontWeight:         props.fontWeight  || "550",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration  || "underline",
    textColor:          props.textColor || "#0291cd"


    //border-bottom:${props => props.border};


}))`


    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;


    font-family:${props => props.fontFamily};
    font-size:${props => props.fontSize};
    font-weight:${props => props.fontWeight};
    text-align:${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.textColor};


    cursor:pointer;


    &:hover {

        color:#07a2e3;
        text-decoration:underline;


    }



`;


