import React, { 

    // useState, 
    // useEffect, 
    // useRef 

} from 'react';



// import { 
//     useSelector, 
//     //useDispatch 
// } 
// from 'react-redux';
// //import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


// import {
//     //BrowserRouter as Router,
//     //Switch,
//     //Route,
//     //Link,

//     //useRouteMatch,
//     useLocation,
//     //useHistory,   
//     //useParams,


// } from "react-router-dom";

// import { useWindowDimensions } from 'utils/WindowDimensionsProvider';



// import {

//     Stage,
//     ScrollBox,
//     Area,
//     //AreaTitle

// } from './Styled';






import { Auth } from "utils/components/Pages/AuthLogo";


const Index = (props) => {


    return (

        <Auth
        
            primeProps={props.primeProps}
        
        />

    )


}

export default Index;


