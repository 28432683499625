import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';


// import { 
//     useSelector, 
//     //useDispatch 
// } 
// from 'react-redux';
// //import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    useHistory,   
    //useParams,



} from "react-router-dom";

import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'



import {

    Catalog,
    CatalogTop,
        CatalogTopName,
        CatalogTopControls,
    
    CatalogBar,
    
    CatalogItem,
        CatalogItemName,
        CatalogItemValue
        //CatalogItemControls,
    

} from 'utils/components/Catalog/Styled';




// import StylScene from '../../_StylModules/Scene.module.css';
// // import StylPage from '../../_StylModules/Page.module.css';
// // import StylRows from '../../_StylModules/Rows.module.css';


// import FGrid from 'utils/components/Grid/FGrid';
// import VidGrid from 'utils/components/Grid/VidGrid';

// import TxtComponent from 'utils/components/Content/Txt';
// import ImgComponent from 'utils/components/Content/Img';
// import CardComponent from 'utils/components/Card';
// import Navigator from 'utils/components/Content/Navigator';

// import SideNavInfo from 'utils/components/Pages/SceneInfo/Trials/SideNav/SceneInfo';



// import SceneBuild from 'app-components/Zone/_Builder/SceneBuild';
import SceneInfo from './SceneInfo';



//=====================================================

import 'app-style/formstuff.css';
//import './StyleSheets/Readout.css';
import 'app-components/Zone/_StyleSheets/Readout.css';



// import { CalcInputs } from '../Calculate';
// import { 
//     //FormComposeMain, 
//     FormComposeOptions

// } from './FormCompose';


import InitReadout from './InitReadout';
import FormComposer from '../FormComposer';



import { 
    FFheading,
    FFheader, 
    FFheadline,
    FFspacer,
    //FFtitleline,
    FFtitlegrid,
    FFseparator,

    FFbutton,
    FFiconbutton,

    FFgraphictext,
    FFpricepercent,
    FFledger,
    FFledgerplus


} from './Fields';




// import CalcMenu from '../CalcMenu';



const Index = (props) => {



    //const Scene = SceneBuild(SceneInfo());
    const Scene = SceneInfo(props.data);
    // console.log("Employee Scene:::: "+JSON.stringify(Scene,null,2));



    // -- window dims -- //
    const { 
        width,
        //height 
    } = useWindowDimensions();


    // -- redux -- //
    // const zoneObj = useSelector(state => state.zone);


    /*
    var images = []; 
    if(zoneObj['images'] 
    && Object.prototype.toString.call(zoneObj['images'] === '[object Array]')
    )
    {
        images = zoneObj['images'];
    }
    */
 


    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    const history = useHistory();

    
 
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);



    // -- refs -- //

    /*
    const rowRefs = [];
    const setRowRef = (ref) => {

        if(ref && ref !== null && ref !== undefined)
        {
            //console.log("ref::::"+ref.id);
            var found;
            found = false;
            for(var i=0; i < rowRefs.length; i++)
            {
                if(rowRefs[i].id === ref.id) found = true;
            }
            if(!found) rowRefs.push(ref);
            console.log(rowRefs.length);
            console.log(rowRefs[0]);

        }

    };
    */



    //=============================================
    // refs
    //=============================================

    const [rowRefs] = useState([]);
    const setRowRef = (ref) => {

        if(ref && ref !== null && ref !== undefined)
        {
            //console.log("ref::::"+ref.id);
            var found;
            found = false;
            for(var i=0; i < rowRefs.length; i++)
            {
                if(rowRefs[i].id === ref.id) found = true;
            }
            if(!found) rowRefs.push(ref);
            //console.log(boxRefs.length);

        }

    };




    //=============================================
    // state/effect - isLoaded
    //=============================================

    const [isLoaded, setIsLoaded] = useState(false);
    const [formArr, setFormArr] = useState([]);

    useEffect(() => {

        if(!isLoaded)
        {

            setIsLoaded(true);

            var frm;
            //var dat;

            //var keyname;
            // var rateColumn;
            // var qtyhrsColumn;
        
            var newArr = [];
            var i;
            //var j;
            var index;


            var zeroColor = "#454d5f";
            var greaterColor = "#00e070";
            var lessColor = "#c00";
            var evenColor = "#26d6cc";
            // var evenColor = "#00e070";


            // if(Object.keys(captured).length === 0) alert('not captured')

            if(formArr.length === 0)
            {


                //-----------------------------------------------

                if(props.calcId === 'calc') 
                {
                    InitReadout.array[0].data.text = props.calcName;
                }else{
                    InitReadout.array[0].data.text = props.calcName;
                }

                //-----------------------------------------------

                console.log("props.data.output: "+JSON.stringify(props.data.output,null,2));



                //-----------------------------------------------
                //NET PROFIT

                index = 1;

                InitReadout.array[index].data.value.text.value[1].text = "$"+props.data.output.profit;
                InitReadout.array[index].data.value.graphic.style.pieceColor = greaterColor;
                InitReadout.array[index].data.value.text.value[1].color = greaterColor;
                

                // graphic
                if(parseFloat(props.data.output.profit) === 0)
                {
                    InitReadout.array[index].data.value.graphic.style.pieceColor = zeroColor;
                }
                if(parseFloat(props.data.output.profit) < 0)
                {
                    InitReadout.array[index].data.value.graphic.style.pieceColor = lessColor;
                }

                // text
                if(parseFloat(props.data.output.profit) === 0)
                {
                    InitReadout.array[index].data.value.text.value[1].color = zeroColor;
                }
                if(parseFloat(props.data.output.profit) < 0)
                {
                    InitReadout.array[index].data.value.text.value[1].color = lessColor;
                }




                //---------------------------------------------------
                // PRICE PERCENT


                // bullet - ledger

                index = 3;

                InitReadout.array[index].data.value['cost_of_goods'].value.price.value = props.data.output.goods_total_amount;
                InitReadout.array[index].data.value['cost_of_goods'].value.percent.value = props.data.output.goods_total_percent;

                InitReadout.array[index].data.value['overhead'].value.price.value = props.data.output.overhead_amount;
                InitReadout.array[index].data.value['overhead'].value.percent.value = props.data.output.overhead_percent;

                InitReadout.array[index].data.value['commission'].value.price.value = props.data.output.commission_amount;
                InitReadout.array[index].data.value['commission'].value.percent.value = props.data.output.commission_percent;

                // InitReadout.array[index].data.value['fees'].value.price.value = props.data.output.fees_amount;
                // InitReadout.array[index].data.value['fees'].value.percent.value = props.data.output.fees_percent;

                InitReadout.array[index].data.value['net_profit'].value.price.value = props.data.output.profit;
                InitReadout.array[index].data.value['net_profit'].value.percent.value = props.data.output.profit_percent;




                //-----------------------------------------------------
                //SELLING PRICE

                index = 5;

                InitReadout.array[index].data.value.text.value[1].text = "$"+props.data.output.grand_total;
                InitReadout.array[index].data.value.graphic.style.signColor = greaterColor;
                InitReadout.array[index].data.value.text.value[1].color = greaterColor;
                

                // graphic
                if(parseFloat(props.data.output.grand_total) === 0)
                {
                    InitReadout.array[index].data.value.graphic.style.signColor = zeroColor;
                }

                if(parseFloat(props.data.output.profit) < 0)
                {
                    InitReadout.array[index].data.value.graphic.style.signColor = lessColor;
                }




                // grand_total
                if(parseFloat(props.data.output.grand_total) === 0
                && parseFloat(props.data.output.profit) === 0
                )
                {
                    InitReadout.array[index].data.value.text.value[1].color = zeroColor;
                }

                if(parseFloat(props.data.output.profit) < 0
                )
                {
                    InitReadout.array[index].data.value.text.value[1].color = lessColor;
                }

                if(parseFloat(props.data.output.grand_total) < 0)
                {
                    InitReadout.array[index].data.value.text.value[1].color = lessColor;
                }



                //breakeven
                if(parseFloat(props.data.output.grand_total) === parseFloat(props.data.output.breakeven)
                )
                {
                    // graphic
                    InitReadout.array[index].data.value.graphic.style.signColor = evenColor;
                    
                    // grand_total
                    InitReadout.array[index].data.value.text.value[1].color = evenColor;
      
                }



            
                // evenColor
                // if(parseFloat(props.data.output.grand_total) > 0
                // && parseFloat(props.data.output.grand_total) === parseFloat(props.data.output.breakeven)
                // )
                // {
                //     InitReadout.array[index].data.value.graphic.style.signColor = evenColor;
                //     InitReadout.array[index].data.value.text.value[1].color = evenColor;
                // }





                //-----------------------------------------------------

                //ledger

                index = 7;

                InitReadout.array[index].data.value['total_price'].value = props.data.output.grand_total;
                InitReadout.array[index].data.value['quantity'].value = props.data.output.unit_qty;
                InitReadout.array[index].data.value['price_each'].value = props.data.output.price_each;





                //------------------------------------------------------
                //BREAKEVEN

                //breakeven

                index = 10;

                InitReadout.array[index].data.value.text.value[1].text = "$"+props.data.output.breakeven;


                //ledger

                index = 12;


                InitReadout.array[index].data.value['cost_of_goods'].value = parseFloat(props.data.output.goods_total_amount).toFixed(2);
                InitReadout.array[index].data.value['overhead'].value = props.data.output.zerobased_overhead_amount;
                InitReadout.array[index].data.value['commission'].value = props.data.output.commission_amount;
                // InitReadout.array[index].data.value['fees'].value = props.data.output.fees_amount;
                InitReadout.array[index].data.value['net_profit'].value = "0.00";






                //-----------------------------------------------

                var initArr = FormComposer(InitReadout);
                //console.log("FormComposer::::"+JSON.stringify(initArr,null,2));

                for(i=0; i < initArr.length; i++)
                {
                    //console.log(object['section'] + "\n" + JSON.stringify(object['data'],null,2));
                    frm = initArr[i];
                    newArr.push(frm);
        
                }//== i

                setFormArr(newArr);






            }

        }//isLoaded
  
    },
    [
        props,
        location,
        isLoaded,
        formArr
    ])







    //=============================================
    // state/effect - display, size and position
    //=============================================

    const refForm = useRef(null);

    //const [showCalcMenu, setShowCalcMenu] = useState(false);
    const [showCatalog] = useState(false);

    const [threshold] = useState(1050);
    const [formStyle, setFormStyle] = useState({

        backgroundColor : "#191E28", 
        width           : "650px",
        //height          : dfHeight, 
        margin          : "10px 0 150px 30px",
        padding         : "0 0 50px 0",
        //padding         : "0 20px 50px 20px",
        //border          : "1px solid #191E28",
        border          : "1px solid lightgray",
        borderRadius    : "10px"

    });
 
    useEffect(() => {


        var dfWidthInt;
        var dfWidth;
    
        //var dfHeightInt;
        //var dfHeight;
    
        //var dfMarlInt;
        var dfMarl;
        var dfBorder;
    
        dfWidthInt = 650;
        dfWidth = dfWidthInt + "px";
        dfBorder = "0px solid #1a1926";
    
        dfMarl = "0px";
        //dfMarl = "calc(50% - "+(dfWidthInt+40)/2+"px)";
        //if(width > 1200) dfMarl = 150 + "px";


        formStyle.margin = "10px 0 150px "+dfMarl;
        //formStyle.padding = "0 20px 50px 20px";
        formStyle.padding = "0 0 50px 0";
        formStyle.borderRadius = "10px";
    

            if(width < threshold)
            {
                dfWidthInt = 650;
                dfWidth = dfWidthInt + "px";
                dfBorder = "0px solid white";

                dfMarl = "0px";
                dfMarl = "calc(50% - "+(dfWidthInt)/2+"px)";
                //if(width > 1200) dfMarl = 150 + "px";


                // dfMarlInt = ( width - formWidthInt )/2; 
                // dfMarl = dfMarlInt + "px";


           
            }

            if(width < 700)
            {
                dfWidthInt = width - 20;
                dfWidth = dfWidthInt + "px";
                dfBorder = "0px solid white";

                // dfMarlInt = 25;
                // dfMarl = dfMarlInt + "px";

                dfMarl = "0px";
                dfMarl = "calc(50% - "+(dfWidthInt)/2+"px)";
                //if(width > 1200) dfMarl = 150 + "px";


                formStyle.margin = "0 0 100px 0";
                //formStyle.padding = "0 10px 50px 10px";
                formStyle.padding = "0 0 50px 0";
                formStyle.borderRadius = "0px";
    
            }



        formStyle.width = dfWidth;
        formStyle.border = dfBorder;
        setFormStyle(formStyle);  



    },[

        width,
        threshold,
        refForm,
        formStyle

    ]);









    

    //=============================================
    // handleForm
    //=============================================

    const handleForm = (hndl) => {


        //alert('handleForm'+JSON.stringify(hndl,null,2));

        switch(hndl.call)
        {
        case'save':

            props.handle({

                call  :"showCalcMenu",
                data  :false
            
            })
        break;
        case'details':

            alert(hndl.call);
        break;
        case'download':

            alert(hndl.call);
        break;
        case'done':

            //alert(hndl.call);
            //history.goBack();

            // props.handle({

            //     call        :"readout",
            //     data        :false
            
            // });


            props.handle({

                call:"history",
                data:{
                    action:"doubleBack"
                }
            
            });

        break;
        default:



            if(hndl.call === 'showCalcMenu')
            { 
                // alert('showCalcMenu')
                // if(hndl.data)
                // {
                //     if(location.hash === "#!calcmenu")
                //     { 
                //          history.push(location.search);
                //     }else{

                //         if(location.hash !== "#!calcmenu") 
                //         {
                //             history.push(location.search+"#!calcmenu");
                //         }
                //     }
                // }else{
                //     history.push(location.search);  
                // }
        
                //setShowCalcMenu(hndl.data);

            }


            //hndl['location'] = location;
            hndl['formArr'] = formArr;
            //hndl['captured'] = captured;
            hndl['handleState'] = (v) => {

                // if(v.call === 'hashData')           setHashData(v.val);
                // // if(v.call === 'showOptions')        setShowOptions(v.val);     
                // if(v.call === 'processSubmit')      setProcessSubmit(v.val);
                // if(v.call === 'processComplete')    setProcessComplete(v.val);
                // if(v.call === 'formArr')            setFormArr(v.val);
                // if(v.call === 'problemArr')         setProblemArr(v.val);
                // if(v.call === 'captured')           setCaptured(v.val);
                // if(v.call === 'calcby')             setCalcby(v.val);
                // if(v.call === 'activeOptions')      setActiveOptions(v.val);
                // if(v.call === 'readout')            setShowReadout(v.val);

                // if(v.call === 'runDispatch')        handleDispatch(v.val);


            };
            hndl['handleRoute'] = (v) => {
        
                switch(v.call)
                {
                case'goBack':
        
                    history.goBack();
                break;
                case'reload':
        
                    window.location.reload();//regular dom 
                break;
                case'redirect':
        
                    history.replace(v.val);
                break;
                case'push':
        
                    history.push(v.val);
                break;
                default:
                }
        
            }

            //Handler(hndl);

        }// == switch





    }







    if(!isLoaded) 
    {

        //return <div className="area-title">Loading...</div>

        return (

            <div></div>

        )


    } 
    else 
    {


        return (


            <div>


                <div 
                ref={refForm}
                key={"readout"}
                className="Readout"
                style={{

                    // backgroundColor : "#191E28", 
                    // width           : dfWidth,
                    // //height          : dfHeight, 
                    // margin          : "10px 0 50px "+dfMarl,
                    // padding         : "0 20px 50px 20px",
                    // border          : dfBorder,
                    // borderRadius    : "10px"


                    backgroundColor : formStyle.backgroundColor, 
                    width           : formStyle.width,
                    //height          : formStyle.height, 
                    margin          : formStyle.margin,
                    padding         : formStyle.padding,
                    border          : formStyle.border,
                    borderRadius    : formStyle.borderRadius

                }}

                >




                    {
                    width < 550
                    ?

                        <div 
                        className='controlPanel'
                        style={{

                            marginTop:"10px"
                        }}
                        >

                            <div
                            id={"menuButton"}
                            className="menuButton"
                            onClick={() => props.handle({

                                call        :"showCalcMenu",
                                data        :false
                            
                            })}
                            >
                                {"SAVE"}

                            </div>



                            <div
                            id={"menuButton"}
                            className="menuButton"
                            onClick={() => props.handle({

                                call        :"readout",
                                data        :false
                            
                            })}
                            >
                                {/* <div className='container'>

                                    <div className='arrow'>

                                        <span className="left"></span>

                                    </div>
                                
                                </div> */}

                                BACK

                            </div>



                        </div>



                    :



                        <div className='controlPanel'>

                            <div
                            id={"menuButton"}
                            className="menuButton"
                            onClick={() => props.handle({

                                call        :"showCalcMenu",
                                data        :false
                            
                            })}
                            >
                                {"SAVE"}

                            </div>



                            <div
                            id={"menuButton"}
                            className="menuButton"
                            onClick={() => props.handle({

                                call        :"readout",
                                data        :false
                            
                            })}
                            >
                                {/* <div className='container'>

                                    <div className='arrow'>

                                        <span className="left"></span>

                                    </div>
                                
                                </div> */}

                                BACK

                            </div>


                        </div>

                    }








                    <div>      
                    {formArr.map((frm, i) => (


                        <div
                        key={i}
                        id={"formSection_"+i}
                        style={{

                            //backgroundColor : "lightblue",
                            width           : "100%",
                            height          : "auto",
                            position        : "relative",
                            float           : "left"

                        }}
                        >






                            {
                            //------------------------------------
                            //heading ( data = {} )
                            //------------------------------------
                            frm.section === "heading" 
                            ? 

                                <FFheading

                                    indx={i}
                                    data={frm.data} 

                                />

                            : null
                            }



                            {
                            //------------------------------------
                            //header ( data = {} )
                            //------------------------------------
                            frm.section === "header" 
                            ? 

                                <FFheader 

                                    indx={i}
                                    data={frm.data} 

                                />

                            : null
                            }


                            {
                            //------------------------------------
                            //headline ( data = {} )
                            //------------------------------------
                            frm.section === "headline" 
                            ? 

                                <FFheadline 

                                    indx={i}
                                    data={frm.data} 

                                />

                            : null
                            }


                            {
                            //------------------------------------
                            //spacer ( data = {} )
                            //------------------------------------
                            frm.section === "spacer" 
                            ? 

                                <FFspacer

                                    indx={i}
                                    data={frm.data} 

                                />

                            : null
                            }




                            {
                            //------------------------------------
                            //titlegrid ( data = {} )
                            //------------------------------------
                            frm.section === "titlegrid" 
                            ? 

                                <FFtitlegrid

                                    indx={i}
                                    data={frm.data} 

                                />

                            : null
                            }



                            {
                            //------------------------------------
                            //separator ( data = {} )
                            //------------------------------------
                            frm.section === "separator" 
                            ? 

                                <FFseparator

                                    indx={i}
                                    data={frm.data} 

                                />

                            : null
                            }







                            {
                            //------------------------------------
                            //button ( data = {} )
                            //------------------------------------
                            frm.section === "button" 
                            ? 

                                <FFbutton

                                    indx={i}
                                    data={frm.data} 
                                    handle={(v)=>handleForm(v)}   

                                />

                            : null
                            }




                            {
                            //------------------------------------
                            //iconbutton ( data = {} )
                            //------------------------------------
                            frm.section === "iconbutton" 
                            ? 

                                <FFiconbutton

                                    indx={i}
                                    data={frm.data} 
                                    handle={(v)=>handleForm(v)} 
            
                                />

                            : null
                            }




                            {
                            //------------------------------------
                            //graphictext
                            //------------------------------------
                            frm.section === "graphictext" 
                            ? 

                                <FFgraphictext

                                    indx={i}
                                    data={frm.data} 
                                    //handle={(v)=>handleForm(v)}   

                                />

                            : null
                            }



                            {
                            //------------------------------------
                            //pricepercent
                            //------------------------------------
                            frm.section === "pricepercent" 
                            ? 

                                <FFpricepercent

                                    indx={i}
                                    data={frm.data} 
                                    //handle={(v)=>handleForm(v)} 
            
                                />

                            : null
                            }


                            {
                            //------------------------------------
                            //ledger
                            //------------------------------------
                            frm.section === "ledger" 
                            ? 

                                <FFledger

                                    indx={i}
                                    data={frm.data} 
                                    styling={frm.styling}
                                    //handle={(v)=>handleForm(v)} 
            
                                />

                            : null
                            }

                            {
                            //------------------------------------
                            //ledgerplus
                            //------------------------------------
                            frm.section === "ledgerplus" 
                            ? 

                                <FFledgerplus

                                    indx={i}
                                    data={frm.data} 
                                    styling={frm.styling}
                                    //handle={(v)=>handleForm(v)} 
            
                                />

                            : null
                            }





                        </div>


                    ))}
                    </div>


                </div>




                {
                showCatalog
                ?



                    <div
                    style={{

                        backgroundColor:"transparent",
                        width:"90%",
                        height:"auto",
                        margin:"0 0 0 5%",
                        padding:"0 0 200px 0",
                        position:"relative",
                        float:"left"

                    }}
                    >         
                    {Scene.map((row, i) => (


                        <div key={i}>
                        <Catalog>

                            {row.map((section, j) => (

                                <div
                                key={i+"-"+j}
                                >
                                    {

                                    section.call === 'top'
                                    ?

                                        <CatalogTop>

                                            <CatalogTopName
                                            color={"lightgray"}
                                            >

                                                {section.name}

                                            </CatalogTopName>

                                            <CatalogTopControls
                                            buttonMinWidth={"125px"}
                                            >

                                                {section.controls.map((itm, k) => (

                                                    <div key={i+"-"+j+"-"+k}>

                                                        <div className="Button"
                                                        onClick={() => props.handle({

                                                            call        :itm.hndl.call,
                                                            data        :itm.hndl.data
                                                        
                                                        })}
                                                        >

                                                            {itm.name}

                                                        </div>

                                                    </div>

                                                ))}

                                            </CatalogTopControls>

                                        </CatalogTop>

                                    :
                                    section.call === 'bar'
                                    ?

                                        <CatalogBar
                                        nameWidth={"calc(40% - 10px)"}
                                        valueWidth={"calc(59% - 11px)"}
                                        >

                                            <div className="Name">

                                                {section.items[0].name}

                                            </div>

                                            <div className="Value">

                                                {section.items[1].name}

                                            </div>

                                        </CatalogBar>

                                    :
                                    section.call === 'items'
                                    ?


                                        section.items.map((itm, k) => (

                                            <div 
                                            ref={setRowRef}                              
                                            key={i+"-"+j+"-"+k}
                                            id={"row"+i+"-"+j+"-"+k}
                                            >

                                                <CatalogItem>

                                                    <CatalogItemName
                                                    width={"calc(40% - 0px)"}
                                                    // onClick={() => props.handle({

                                                    //     call        :'showFlyr',
                                                    //     data        :itm.controls.form
                                    
                                                    // })}
                                                    >
                                                        <div className="PlainText"
                                                        >

                                                            {itm.heading}

                                                        </div>

                                                    </CatalogItemName>


                                                    <CatalogItemValue
                                                    width={"calc(59% - 1px)"}
                                                    // onClick={() => props.handle({

                                                    //     call        :'showFlyr',
                                                    //     data        :itm.controls.form
                                    
                                                    // })}
                                                    >
                                                        <div className="PlainText"
                                                        >
                                                            {itm.pgraph}

                                                        </div>

                                                    </CatalogItemValue>


                                                </CatalogItem>

                                            </div>

                                        ))


                                    :null

                                    }

                                </div>


                            ))}

                        </Catalog>
                        </div>

                    ))} 
                    </div>
                            



                :null
                }




            </div>


        )

    }

}

export default Index;


