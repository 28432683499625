


const SceneInfo = {





    //##########################################################
    //##########################################################
    //##########################################################

    // generic


    generic: {

        "styl": {
            "call": "full",
            "class": "FullRow",
            "marginTop": "0px",
            "marginBottom": "0px"
        },
        "call": "Navigator",
        "info": {
            "call": "default",//switchRoute, default
            "gridType": "SideNav",
            "gridBgColor": "transparent",
            "gridCall": "grid_cell",
            "cellCall": "default",
            "modCall": "fill_div",
            "thumb": {
                "call": "default",
                "diff": "0"
            },
            "cellBorder": {
                "boolean": true,
                "thickness": 1,
                "color": "gray"
            },
            "gutter": {
                "boolean": "0",
                "size": "0"
            },
            "thresh": {
                "refWidth": false,
                "across1": "501",
                "across2": "801",
                "across3": "1051"
            }
        },
        "items": [



            {

                "to"    :"ErrorPage",     
                "name"  :"Error Page",
                "descr" :"Error Page - used to check static only react build"
    
            },

            {

                "to"    :"Dashboard",     
                "name"  :"Dashboard",
                "descr" :"Dashboard - VinylProfit Dashboard"
    
            },




            {

                "to"    :"FetchPage",     
                "name"  :"Fetch Page",
                "descr" :"Fetch Page - used to check static only react build"
    
            },



            {

                "to"    :"StaticPage",     
                "name"  :"Static Page",
                "descr" :"Static Page - used to check static only react build"
    
            },

            {

                "to"    :"TrialPage",     
                "name"  :"Trial Page",
                "descr" :"Uses this section to manually build/check Scenes"
    
            },


            {

                "to"    :"TrialPageLandingPage",     
                "name"  :"Trial Page - Landing Page",
                "descr" :"Uses this section to manually build/check Scenes"
    
            },




            {

                "to"    :"TrialPageBillboard",     
                "name"  :"Trial Page Billboard",
                "descr" :"Uses this section to manually build/check Scenes"
    
            },


            {

                "to"    :"TrialPageSVG",     
                "name"  :"Trial Page SVG",
                "descr" :"Uses this section to manually build/check Scenes"
    
            },



            {

                "to"    :"TrialPageSlideshow",     
                "name"  :"Trial Page Slideshow",
                "descr" :"Uses this section to manually build/check Scenes"
    
            },





            {

                "to"    :"TrialPageNav",     
                "name"  :"Trial Page Nav",
                "descr" :"Uses this section to manually build/check Scenes"
    
            },

    

            {
                "to"    :"FormSSG",    
                "name"  :"SSG Form",
                "descr" :'Use this form to run SSG'
    
            },


            {

                "to"    :"SocketPage",     
                "name"  :"Socket Page",
                "descr" :"Testing sockets"
    
            },
    


    
            {
    
                "to"    :"Template01",     
                "name"  :"Template01",
                "descr" :"Uses this section to check validity of react utils and components"
    
            },
    
            {
    
                "to"    :"Playground",     
                "name"  :"Playground",
                "descr" :"This section includes react samples and tests"
    
            },
    
    
    
    
            {
    
                "to"    :"SinglePage",     
                "name"  :"Single Page",
                "descr" :"This is a single page resource"
    
            },
    
            {
    
                "to"    :"MultiPage",     
                "name"  :"Multi Page",
                "descr" :"This is a multi page resource"
    
            },
    
    
            {
                "to"    :"FormSample",    
                "name"  :"Sample Form",
                "descr" :'This is a form resource'
    
            },
    
    
            {
    
                "to"    :"AuthArea",     
                "name"  :"Auth Area",
                "descr" :"Template for authenticating every page within this section of the application"
    
            }
    

   
        ]

    },







    //##########################################################
    //##########################################################
    //##########################################################

    // dashboard_sidenav


    dashboard_sidenav: {

        "styl": {
            "call": "full",
            "class": "FullRow",
            "marginTop": "0px",
            "marginBottom": "0px"
        },
        "call": "Navigator",
        "info": {
            "call": "default",//switchRoute, default
            "gridType": "SideNav",
            "gridBgColor": "transparent",
            "gridCall": "grid_cell",
            "cellCall": "default",
            "modCall": "fill_div",
            "thumb": {
                "call": "default",
                "diff": "0"
            },
            "cellBorder": {
                "boolean": true,
                "thickness": 1,
                "color": "gray"
            },
            "gutter": {
                "boolean": "0",
                "size": "0"
            },
            "thresh": {
                "refWidth": false,
                "across1": "501",
                "across2": "801",
                "across3": "1051"
            }


        },
        "styling":{

            main:{

                backgroundColor:{

                    over:'#191e28',
                    out:false

                },
                textColor:{

                    over:'#fff',
                    out:false

                },
                fontWeight:{

                    over:'550',
                    out:false

                }

            },


            sub:{

                backgroundColor:{

                    //over:'#caffca',
                    over:'#454d5f',
                    out:"white"

                },
                textColor:{

                    over:'white',
                    out:false

                },
                fontWeight:{

                    over:'550',
                    out:false

                }

            },


            sub2:{

                backgroundColor:{

                    over:'lightyellow',
                    out:false

                },
                textColor:{

                    over:'black',
                    out:false

                },
                fontWeight:{

                    over:'550',
                    out:false

                }

            }

        },
        "items": [



            {

                "to"    :"",     
                "name"  :"Home",
                "descr" :""
    
            },



            {

                "to"    :"Calculator/",     
                "name"  :"Calculator",
                "descr" :"",
                // "subs"  :[

                //     {

                //         "to"    :"Calculator/Calculators/",     
                //         "name"  :"Calculators",
                //         "descr" :""
                //     },
                //     {

                //         "to"    :"Calculator/Templates/",     
                //         "name"  :"Templates",
                //         "descr" :""
                //     }


                // ]

    
            },



            {

                "to"    :"Pricing/",     
                "name"  :"Pricing",
                "descr" :"",
                // "subs"  :[

                //     {

                //         "to"    :"Pricing/Calculators/",     
                //         "name"  :"Calculators",
                //         "descr" :""
                //     },
                //     {

                //         "to"    :"Pricing/List/",     
                //         "name"  :"List",
                //         "descr" :""
                //     },
                //     // {

                //     //     "to"    :"Pricing/Templates/",     
                //     //     "name"  :"Templates",
                //     //     "descr" :""
                //     // }


                // ]

    
            },





            // {

            //     "to"    :"Pricing/",     
            //     "name"  :"Pricing",
            //     "descr" :"",
            //     "subs"  :[

            //         {

            //             "to"    :"Pricing/Calculators/",     
            //             "name"  :"Calculators",
            //             "descr" :""
            //         },
            //         {

            //             "to"    :"Pricing/List/",     
            //             "name"  :"List",
            //             "descr" :""
            //         },
            //         // {

            //         //     "to"    :"Pricing/Templates/",     
            //         //     "name"  :"Templates",
            //         //     "descr" :""
            //         // }


            //     ]

    
            // },




            {

                "to"    :"Settings/",     
                "name"  :"Settings",
                "descr" :"",
                "subs"  :[

                    {

                        "to"    :"Settings/Account/",     
                        "name"  :"Account",
                        "descr" :""
            
                    },
                    {
    
                        "to"    :"Settings/Schedule/",     
                        "name"  :"Schedule",
                        "descr" :""
            
                    },
                    {
    
                        "to"    :"Settings/Overhead/",     
                        "name"  :"Overhead",
                        "descr" :""
            
                    },
                    {
    
                        "to"    :"Settings/Expenses/",     
                        "name"  :"Expenses",
                        "descr" :""
            
                    },
                    {
    
                        "to"    :"Settings/Owners/",     
                        "name"  :"Owners",
                        "descr" :""
            
                    },
                    {
    
                        "to"    :"Settings/Employees/",     
                        "name"  :"Employees",
                        "descr" :""
            
                    }


                ]

    
            },




            {

                "to"    :"Reports/",     
                "name"  :"Reports",
                "descr" :"",
                "subs"  :[


                    // {

                    //     "to"    :"Reports/Pricing/",     
                    //     "name"  :"Pricing",
                    //     "descr" :""
                    // },


                    {

                        "to"    :"Reports/Overhead/",     
                        "name"  :"Overhead",
                        "descr" :""
                    },

                    {

                        "to"    :"Reports/Expenses/",     
                        "name"  :"Expenses",
                        "descr" :""
                    },

                    {

                        "to"    :"Reports/Direct Labor Rates/",     
                        "name"  :"Direct Labor Rates",
                        "descr" :""
                    },

                    {

                        "to"    :"Reports/Direct Labor Hours/",     
                        "name"  :"Direct Labor Hours",
                        "descr" :""
                    },

                    {

                        "to"    :"Reports/Healthcare/",     
                        "name"  :"Healthcare",
                        "descr" :""
                    },

                    {

                        "to"    :"Reports/IRA/",     
                        "name"  :"IRA",
                        "descr" :""
                    },


                    {

                        "to"    :"Reports/Owners/",     
                        "name"  :"Owners",
                        "descr" :""
                    },

                    {

                        "to"    :"Reports/Employees/",     
                        "name"  :"Employees",
                        "descr" :""
                    },


                    // {

                    //     "to"    :"Reports/Calculators/",     
                    //     "name"  :"Calculators",
                    //     "descr" :""
                    // }


                ]

    
            },





            {

                "to"    :"Knowledge Base/",     
                "name"  :"Knowledge Base",
                "descr" :"",
                "subs"  :[

                    {

                        "to"    :"Knowledge Base/Glossary/",     
                        "name"  :"Glossary",
                        "descr" :""
                    },

                    // {

                    //     "to"    :"Knowledge Base/Icons/",     
                    //     "name"  :"Icons",
                    //     "descr" :""
                    // },

                    // {

                    //     "to"    :"Knowledge Base/Formulas/",     
                    //     "name"  :"Formulas",
                    //     "descr" :""
                    // },



                ]

    
            },



            {

                "to"    :"Logout/",     
                "name"  :"Logout",
                "descr" :""
    
            }




   
        ]

    },







    //##########################################################
    //##########################################################
    //##########################################################

    // dashboard_sidenav


    dashboard_sidenav_admin: {

        "styl": {
            "call": "full",
            "class": "FullRow",
            "marginTop": "0px",
            "marginBottom": "0px"
        },
        "call": "Navigator",
        "info": {
            "call": "default",//switchRoute, default
            "gridType": "SideNav",
            "gridBgColor": "transparent",
            "gridCall": "grid_cell",
            "cellCall": "default",
            "modCall": "fill_div",
            "thumb": {
                "call": "default",
                "diff": "0"
            },
            "cellBorder": {
                "boolean": true,
                "thickness": 1,
                "color": "gray"
            },
            "gutter": {
                "boolean": "0",
                "size": "0"
            },
            "thresh": {
                "refWidth": false,
                "across1": "501",
                "across2": "801",
                "across3": "1051"
            }


        },
        "styling":{

            main:{

                backgroundColor:{

                    over:'#191e28',
                    out:false

                },
                textColor:{

                    over:'#fff',
                    out:false

                },
                fontWeight:{

                    over:'550',
                    out:false

                }

            },


            sub:{

                backgroundColor:{

                    //over:'#caffca',
                    over:'#454d5f',
                    out:"white"

                },
                textColor:{

                    over:'white',
                    out:false

                },
                fontWeight:{

                    over:'550',
                    out:false

                }

            },


            sub2:{

                backgroundColor:{

                    over:'lightyellow',
                    out:false

                },
                textColor:{

                    over:'black',
                    out:false

                },
                fontWeight:{

                    over:'550',
                    out:false

                }

            }

        },
        "items": [



            {

                "to"    :"",     
                "name"  :"Home",
                "descr" :""
    
            },



            {

                "to"    :"Calculator/",     
                "name"  :"Calculator",
                "descr" :"",
                // "subs"  :[

                //     {

                //         "to"    :"Calculator/Calculators/",     
                //         "name"  :"Calculators",
                //         "descr" :""
                //     },
                //     {

                //         "to"    :"Calculator/Templates/",     
                //         "name"  :"Templates",
                //         "descr" :""
                //     }


                // ]

    
            },



            {

                "to"    :"Pricing/",     
                "name"  :"Pricing",
                "descr" :"",
                // "subs"  :[

                //     {

                //         "to"    :"Pricing/Calculators/",     
                //         "name"  :"Calculators",
                //         "descr" :""
                //     },
                //     {

                //         "to"    :"Pricing/List/",     
                //         "name"  :"List",
                //         "descr" :""
                //     },
                //     // {

                //     //     "to"    :"Pricing/Templates/",     
                //     //     "name"  :"Templates",
                //     //     "descr" :""
                //     // }


                // ]

    
            },





            // {

            //     "to"    :"Pricing/",     
            //     "name"  :"Pricing",
            //     "descr" :"",
            //     "subs"  :[

            //         {

            //             "to"    :"Pricing/Calculators/",     
            //             "name"  :"Calculators",
            //             "descr" :""
            //         },
            //         {

            //             "to"    :"Pricing/List/",     
            //             "name"  :"List",
            //             "descr" :""
            //         },
            //         // {

            //         //     "to"    :"Pricing/Templates/",     
            //         //     "name"  :"Templates",
            //         //     "descr" :""
            //         // }


            //     ]

    
            // },




            {

                "to"    :"Settings/",     
                "name"  :"Settings",
                "descr" :"",
                "subs"  :[

                    {

                        "to"    :"Settings/Account/",     
                        "name"  :"Account",
                        "descr" :""
            
                    },
                    {
    
                        "to"    :"Settings/Schedule/",     
                        "name"  :"Schedule",
                        "descr" :""
            
                    },
                    {
    
                        "to"    :"Settings/Overhead/",     
                        "name"  :"Overhead",
                        "descr" :""
            
                    },
                    {
    
                        "to"    :"Settings/Expenses/",     
                        "name"  :"Expenses",
                        "descr" :""
            
                    },
                    {
    
                        "to"    :"Settings/Owners/",     
                        "name"  :"Owners",
                        "descr" :""
            
                    },
                    {
    
                        "to"    :"Settings/Employees/",     
                        "name"  :"Employees",
                        "descr" :""
            
                    },

                   
                    // {

                    //     "to"    :"Settings/Reports/",     
                    //     "name"  :"Reports",
                    //     "descr" :"",
                    //     "subs"  :[


                    //         {

                    //             "to"    :"Settings/Reports/Calculators/",     
                    //             "name"  :"Calculators",
                    //             "descr" :""
                    //         },


                    //         {

                    //             "to"    :"Settings/Reports/Overhead/",     
                    //             "name"  :"Overhead",
                    //             "descr" :""
                    //         },

                    //         {

                    //             "to"    :"Settings/Reports/Expenses/",     
                    //             "name"  :"Expenses",
                    //             "descr" :""
                    //         },

                    //         {

                    //             "to"    :"Settings/Reports/Direct Labor Rates/",     
                    //             "name"  :"Direct Labor Rates",
                    //             "descr" :""
                    //         },

                    //         {

                    //             "to"    :"Settings/Reports/Direct Labor Hours/",     
                    //             "name"  :"Direct Labor Hours",
                    //             "descr" :""
                    //         },

                    //         {

                    //             "to"    :"Settings/Reports/Healthcare/",     
                    //             "name"  :"Healthcare",
                    //             "descr" :""
                    //         },

                    //         {

                    //             "to"    :"Settings/Reports/IRA/",     
                    //             "name"  :"IRA",
                    //             "descr" :""
                    //         },


                    //         {

                    //             "to"    :"Settings/Reports/Owners/",     
                    //             "name"  :"Owners",
                    //             "descr" :""
                    //         },

                    //         {

                    //             "to"    :"Settings/Reports/Employees/",     
                    //             "name"  :"Employees",
                    //             "descr" :""
                    //         }



                    //     ]

            
                    // },
 

    

                ]

    
            },




            {

                "to"    :"Reports/",     
                "name"  :"Reports",
                "descr" :"",
                "subs"  :[


                    // {

                    //     "to"    :"Reports/Pricing/",     
                    //     "name"  :"Pricing",
                    //     "descr" :""
                    // },


                    {

                        "to"    :"Reports/Overhead/",     
                        "name"  :"Overhead",
                        "descr" :""
                    },

                    {

                        "to"    :"Reports/Expenses/",     
                        "name"  :"Expenses",
                        "descr" :""
                    },

                    {

                        "to"    :"Reports/Direct Labor Rates/",     
                        "name"  :"Direct Labor Rates",
                        "descr" :""
                    },

                    {

                        "to"    :"Reports/Direct Labor Hours/",     
                        "name"  :"Direct Labor Hours",
                        "descr" :""
                    },

                    {

                        "to"    :"Reports/Healthcare/",     
                        "name"  :"Healthcare",
                        "descr" :""
                    },

                    {

                        "to"    :"Reports/IRA/",     
                        "name"  :"IRA",
                        "descr" :""
                    },


                    {

                        "to"    :"Reports/Owners/",     
                        "name"  :"Owners",
                        "descr" :""
                    },

                    {

                        "to"    :"Reports/Employees/",     
                        "name"  :"Employees",
                        "descr" :""
                    },


                    // {

                    //     "to"    :"Reports/Calculators/",     
                    //     "name"  :"Calculators",
                    //     "descr" :""
                    // }


                ]

    
            },





            {

                "to"    :"Knowledge Base/",     
                "name"  :"Knowledge Base",
                "descr" :"",
                "subs"  :[

                    {

                        "to"    :"Knowledge Base/Glossary/",     
                        "name"  :"Glossary",
                        "descr" :""
                    },

                    {

                        "to"    :"Knowledge Base/Icons/",     
                        "name"  :"Icons",
                        "descr" :""
                    },

                    // {

                    //     "to"    :"Knowledge Base/Formulas/",     
                    //     "name"  :"Formulas",
                    //     "descr" :""
                    // },



                ]

    
            },



            {

                "to"    :"Logout/",     
                "name"  :"Logout",
                "descr" :""
    
            },







            //----------------------------------------------

            {

                "to"    :"Styling/",     
                "name"  :"Styling",
                "descr" :"",
                "subs"  :[



                    {

                        "to"    :"Styling/LargeLook1/",     
                        "name"  :"LargeLook1",
                        "descr" :""
                    },

                    {

                        "to"    :"Styling/LargeLook2/",     
                        "name"  :"LargeLook2",
                        "descr" :""
                    },


                    {

                        "to"    :"Styling/LargeLook3/",     
                        "name"  :"LargeLook3",
                        "descr" :""
                    },


                    {

                        "to"    :"Styling/LargeLook4/",     
                        "name"  :"LargeLook4",
                        "descr" :""
                    },



                    {

                        "to"    :"Styling/LargeLook5/",     
                        "name"  :"LargeLook5",
                        "descr" :""
                    },



                    {

                        "to"    :"Styling/ColorA/",     
                        "name"  :"ColorA",
                        "descr" :""
                    },

                    {

                        "to"    :"Styling/ColorB/",     
                        "name"  :"ColorB",
                        "descr" :""
                    },

                    {

                        "to"    :"Styling/ColorC/",     
                        "name"  :"ColorC",
                        "descr" :""
                    },

                    {

                        "to"    :"Styling/ColorD/",     
                        "name"  :"ColorD",
                        "descr" :""
                    }


                ]

    
            },




            {

                "to"    :"Results/",     
                "name"  :"Results",
                "descr" :""
    
            },


            {

                "to"    :"Print/",     
                "name"  :"Print",
                "descr" :""
    
            },



            // {

            //     "to"    :"SignUpForm/",     
            //     "name"  :"Sign Up Form",
            //     "descr" :""
    
            // },


            {

                "to"    :"SignUpWalk/",     
                "name"  :"Sign Up Walk",
                "descr" :""
    
            },



            {

                "to"    :"LoginAccess/",     
                "name"  :"Login Access",
                "descr" :""
    
            },




            {

                "to"    :"AuthActions/",     
                "name"  :"AuthActions",
                "descr" :""
    
            },


            {

                "to"    :"AuthPage/",     
                "name"  :"AuthPage",
                "descr" :""
    
            },








            // {

            //     "to"    :"Templates/",     
            //     "name"  :"Templates",
            //     "descr" :""
    
            // },




            // {

            //     "to"    :"Pricing/",     
            //     "name"  :"Pricing",
            //     "descr" :"",
            //     "subs"  :[

            //         {

            //             "to"    :"Pricing/Guidelines/",     
            //             "name"  :"Guidelines",
            //             "descr" :""
            //         },

            //         {

            //             "to"    :"Pricing/Form/",     
            //             "name"  :"Pricing Form",
            //             "descr" :""
            //         }


            //     ]
    
            // },



            // {

            //     "to"    :"Sample/",     
            //     "name"  :"Sample",
            //     "descr" :""
    
            // },



            // {

            //     "to"    :"Trials/",     
            //     "name"  :"Trials",
            //     "descr" :""
    
            // },






            // {

            //     "to"    :"Login/",     
            //     "name"  :"Login",
            //     "descr" :""
    
            // },


            // {

            //     "to"    :"ForgotPassword/",     
            //     "name"  :"Forgot Password",
            //     "descr" :""
    
            // },


            // {

            //     "to"    :"Logout/",     
            //     "name"  :"Logout",
            //     "descr" :""
    
            // },

            // {

            //     "to"    :"Signup/",     
            //     "name"  :"Signup",
            //     "descr" :""
    
            // },







            // {

            //     "to"    :"PriceFormOrig/",     
            //     "name"  :"Price Form (Original)",
            //     "descr" :"",
            //     "subs"  :[]
    
            // },



            // {

            //     "to"    :"CompanyOrig/",     
            //     "name"  :"CompanyOrig",
            //     "descr" :"",
            //     "subs"  :[

            //         {

            //             "to"    :"CompanyOrig/Info/",     
            //             "name"  :"Info",
            //             "descr" :""
            //         },

            //         {

            //             "to"    :"CompanyOrig/Schedule/",     
            //             "name"  :"Schedule",
            //             "descr" :""
            //         }


            //     ]
    
            // },




            // {

            //     "to"    :"OwnersOrig/",     
            //     "name"  :"OwnersOrig",
            //     "descr" :"",
            //     "subs"  :[

            //         {

            //             "to"    :"OwnersOrig/Summary/",     
            //             "name"  :"Summary",
            //             "descr" :""
            //         },

            //         {

            //             "to"    :"OwnersOrig/List/",     
            //             "name"  :"List",
            //             "descr" :""
            //         },

            //         {

            //             "to"    :"OwnersOrig/Catalog/",     
            //             "name"  :"Catalog",
            //             "descr" :""
            //         }


            //     ]

    
            // },

            // {

            //     "to"    :"EmployeesOrig/",     
            //     "name"  :"EmployeesOrig",
            //     "descr" :"",
            //     "subs"  :[

            //         {

            //             "to"    :"EmployeesOrig/Summary/",     
            //             "name"  :"Summary",
            //             "descr" :""
            //         },

            //         {

            //             "to"    :"EmployeesOrig/List/",     
            //             "name"  :"List",
            //             "descr" :""
            //         },

            //         {

            //             "to"    :"EmployeesOrig/Catalog/",     
            //             "name"  :"Catalog",
            //             "descr" :""
            //         }



            //     ]
    
            // },




            // {

            //     "to"    :"ExpensesOrig/",     
            //     "name"  :"ExpensesOrig",
            //     "descr" :"",
            //     "subs"  :[



            //         {

            //             "to"    :"ExpensesOrig/Summary/",     
            //             "name"  :"Summary",
            //             "descr" :""
            //         },


            //         {

            //             "to"    :"ExpensesOrig/List/",     
            //             "name"  :"List",
            //             "descr" :""
            //         },

            //         {

            //             "to"    :"ExpensesOrig/Charts/",     
            //             "name"  :"Charts",
            //             "descr" :""
            //         },

            //         {

            //             "to"    :"ExpensesOrig/Catalog/",     
            //             "name"  :"Catalog",
            //             "descr" :""
            //         }



            //         // {

            //         //     "to"    :"ExpensesOrig/Facility",     
            //         //     "name"  :"Facility",
            //         //     "descr" :""
            //         // },

            //         // {

            //         //     "to"    :"ExpensesOrig/Business",     
            //         //     "name"  :"Business",
            //         //     "descr" :""
            //         // },


            //         // {

            //         //     "to"    :"ExpensesOrig/Vehicles",     
            //         //     "name"  :"Vehicles",
            //         //     "descr" :""
            //         // },

            //         // {

            //         //     "to"    :"ExpensesOrig/EquipSoftware",     
            //         //     "name"  :"Equip. Software",
            //         //     "descr" :""
            //         // }



            //     ]
    
            // }






   
        ]

    }















};


export default SceneInfo;








