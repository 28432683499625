import React, { 

    useState, 
    useEffect,
    //useRef

} from 'react';



import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


//import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider';

import {

    //FScomplete, 
    //FSerror,
    FSloading,
    FSprocess

} from 'utils/components/Form/FormSubmit';

import Messngr from 'utils/components/Process/Messngr';
import Readr from 'utils/components/Process/Readr';


// import { Account as DashboardAccount } from 'app-components/Zone/_Connect/Account';
import { Alter as DashboardAuthAccount } from 'app-components/Zone/_Connect/AuthAccount/Alter';
import { Calculate as DashboardCalculate } from 'app-components/Zone/_Connect/Calculate';


import { SIform, SIresult } from './SceneInfo';



const Index = (props) => {


    // -- window dims -- //
    //const { width, height } = useWindowDimensions();



    // -- redux -- //
    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));



    //---------------------------------------------
    // state/effect - error messagenr
    //---------------------------------------------

    const [isLoaded, setIsLoaded] = useState(false);
    //const [currentFlyr, setCurrentFlyr] = useState(false);

    const [formArr, setFormArr] = useState([]);
    const [problemArr, setProblemArr] = useState([]);

    const [runSubmit, setRunSubmit] = useState(false);
    const [runComplete, setRunComplete] = useState(false);
    const [runError, setRunError] = useState(false);

    const [errorType, setErrorType] = useState(false);

    const [runAccount, setRunAccount] = useState(false);
    const [runCalculate, setRunCalculate] = useState(false);
    const [runOutput, setRunOutput] = useState(false);

    const [output, setOutput] = useState(false);
    const [postTo, setPostTo] = useState(false);
    const [currentKey, setCurrentKey] = useState(false);
    const [completeMessage, setCompleteMessage] = useState(false);

    const [readr_data, setReadrData] = useState(false);
    // const [skip, setSkip] = useState(false);



    useEffect(() => {


        var currentFlyr = zoneObj.currentFlyr;

        var problemArr;
        var postTo;
        var completeMessage;
        var output;
        
        problemArr = zoneObj[currentFlyr + "_problemArr"];
        postTo = zoneObj[currentFlyr + "_postTo"];
        completeMessage = zoneObj[currentFlyr + "_completeMessage"];


        if(Object.prototype.toString.call(problemArr).match(/undefined/gi))
        {
            problemArr = [];
        }
        //console.log("PROBLEM ARR: "+JSON.stringify(problemArr,null,2));
    
        if(Object.prototype.toString.call(postTo).match(/undefined/gi))
        {
            postTo = false;
        }
        //console.log("POST TO: "+postTo);
    
    
        if(Object.prototype.toString.call(completeMessage).match(/undefined/gi))
        {
            completeMessage = "Update Successfull!!";
        }
        //console.log("COMPLETE MESSAGE: "+completeMessage);
    
    

        output = zoneObj[currentFlyr+"_output"];
        if(output !== undefined)
        {
            var scene;
            //var array;
            var object;
            //var item;
    
            var readr_data;
            var readr_value;
    
        
            var keyname;
            var html;
            var str;
    
            switch(output.call)
            {
            case'cards':
    
                readr_value = [];
    
                scene = SIform({
                    form_items: output.data.formItems,
                    result_items: output.data.resultItems
                });
    
                for(object of scene)
                {
                    if(object.keyname !== undefined)
                    {
                        readr_value.push({
                            
                            name:object.name,
                            value:object.value
                        
                        });
    
        
                        
                    }
                }
    

                scene = SIresult({
                    result_items: output.data.resultItems
                });
    
                for(object of scene)
                {
                    if(object.keyname !== undefined)
                    {
                        readr_value.push({
                            
                            name:object.name,
                            value:object.value
                        
                        });
                        
                    }
                }
    
                readr_data = {
                    data:{
                        name:"Output",
                        value:readr_value,
                        actions:{
                            add:false,
                            delete:false,        
                            edit:false,
                            chart:false
                        }
                    },
                    flyr:{
                        type:'cards',
                        styl:{
                            maxWidth:750
                        }
                    }
                }
    
    
            break;
            default:
    
    
                //&nbsp; 
                //&thinsp; 
                //&ensp;
                //&emsp;
    
    
                //readr_value = JSON.stringify(output.data,null,2);
                //readr_value = readr_value.replace(/\n/gi,"<br/>&nbsp;");
    
    
    
                html = '';
    
                for(keyname in output.data)
                {
                    console.log("")
                    console.log("===============")
                    console.log(keyname);
                    console.log("===============")
    
                    html += "<h2>"+keyname+"</h2>";
    
                    str = output.data[keyname];
    
                    if(Object.prototype.toString.call(str) === '[object Object]' 
                    || Object.prototype.toString.call(str) === '[object Array]'
                    )
                    {
                        str = JSON.stringify(str,null,2);
                        str = str.replace(/\n/gi,"<br/>&nbsp;");
                    }
    
                    html += str;
        
    

                }
                readr_value = html;
                readr_data = {
                    data:{
                        name:"Output",
                        value:readr_value
                    },
                    flyr:{
                        type:'print',
                        styl:{
                            maxWidth:750
                        }
                    }
                }
    
    
            break;
            }

            setReadrData(readr_data);
    
        }
    
    
      

        setFormArr(zoneObj[currentFlyr + "_formArr"]);
        setProblemArr(problemArr);

        setRunSubmit(zoneObj[currentFlyr + "_runSubmit"]);
        setRunComplete(zoneObj[currentFlyr + "_runComplete"]);
        setRunError(zoneObj[currentFlyr + "_runError"]);
        setErrorType(zoneObj[currentFlyr + "_errorTYpe"]);

        setRunAccount(zoneObj[currentFlyr + "_runAccount"]);
        setRunCalculate(zoneObj[currentFlyr + "_runCalculate"]);

        setRunOutput(zoneObj[currentFlyr + "_runOutput"]);
        setOutput(output);

        setPostTo(postTo);
        setCurrentKey(zoneObj[currentFlyr + "_currentKey"]);
        setCompleteMessage([
    
            {
                "call":"heading",
                "text":"Success" 
            },
            {
                "call":"span",
                //"text":"Update was Successfull!!" 
                "text":completeMessage
            }
    
        ]);


        if(!isLoaded)
        {
            setIsLoaded(true);
    
        }

    },
    [

        zoneObj,isLoaded
  

    ])






    //---------------------------------------------
    // state/effect - view zoneOBj
    //---------------------------------------------

    // -- state -- //
    const [zoneWasViewed, setZoneWasViewed] = useState(false);
    //const [viewCount, setViewCount] = useState(parseInt(0));

    useEffect(() => {


        //alert(animateOut);
        //console.log(boardNumber+" "+props.data.boardNumber);


        if(!zoneWasViewed)
        {
            // console.log("transition..."+boardNumber+" --- "+props.data.boardNumber);

            // var transInterval = setInterval(() => {

            //     console.log("Billboard Overlay - transInterval");
          

            // }, 200);

            // return () => { 

            //     clearInterval(transInterval); 
            //     setBoardNumber(props.data.boardNumber)
            // }


            // console.log("===== zoneObj =====");
            // console.log(JSON.stringify(zoneObj,null,2));

            setZoneWasViewed(true);




            // alert("components/Flyr/Comps/Form/FormFly/ variables...."
        
            //     +"\nrunAccount: "+runAccount
            //     +"\nrunCalculate: "+runCalculate
            //     +"\nrunSubmit: "+runSubmit
            //     +"\nrunComplete: "+runComplete
            //     +"\nrunError: "+runError
            //     +"\nerrorType: "+errorType
            //     +"\nrunOutput: "+runOutput
            //     +"\noutput: "+output
            
            // )


    
        }else{

            //Delay before starting animation
            // var timer;
            // timer = setTimeout(() => {

            //     setZoneWasViewed(false);

            // }, 500);
            // return () => clearTimeout(timer);




            // if(runError && viewCount < 1)
            // {


            //     alert("components/Flyr/Comps/Form/FormFly/ variables...."
            
            //         +"\nrunAccount: "+runAccount
            //         +"\nrunCalculate: "+runCalculate
            //         +"\nrunSubmit: "+runSubmit
            //         +"\nrunComplete: "+runComplete
            //         +"\nrunError: "+runError
            //         +"\nerrorType: "+errorType
            //         +"\nrunOutput: "+runOutput
            //         +"\noutput: "+output
                
            //     )

            //     var newCount = parseInt(viewCount) + 1;
            //     setViewCount(newCount)


            // }


        } 


    },
    [

        zoneObj,
        zoneWasViewed,

        runSubmit,      
        runComplete,       
        runError,    
        errorType,  
    
        runAccount,
        runCalculate,      
        runOutput,    
        output,
        
        //viewCount
    

    ])










    //---------------------------------------------
    // state/effect - error messagenr
    //---------------------------------------------


    // -- state -- //
    const [errorItems, setErrorItems] = useState([]);
    const [errorEval, setErrorEval] = useState('');


    useEffect(() => {


        //alert(animateOut);
        //console.log(boardNumber+" "+props.data.boardNumber);


        var obj,items;
        var problemStr;

        if(isLoaded && errorItems.length !== problemArr.length)
        {

            //console.log("problemArr: "+JSON.stringify(problemArr,null,2));
            problemStr = '-';
            for(obj of problemArr)
            {
                console.log(obj.name);
                problemStr = problemStr + obj.name + '-';
    
            }
            // console.log("problemStr: "+problemStr);
            // console.log("errorEval: "+errorEval);

            if(problemStr !== errorEval)
            {

                items = [];
                for(obj of problemArr)
                {
    
                    items.push({
    
                        call:'span',
                        text:obj['message']
                    })
    
                }
                setErrorEval(problemStr);
                setErrorItems(items);
                // console.log("revised - errorItems: "+JSON.stringify(items,null,2));
                // alert('check error items');

            }

        }


    },
    [

        isLoaded,
        problemArr,
        errorItems,
        errorEval         
    
    ])







    // const getRandomColor = () => {

    //     var colors = ['pink','aqua','green','blue','red','yellow'];
    //     var randnum;

    //     function generateRandomInteger(min, max) {
    //         return Math.floor(min + Math.random()*(max + 1 - min))
    //     }
    //     randnum = generateRandomInteger(0,colors.length-1);
    //     console.log(randnum+" "+colors[randnum]);
    //     return colors[randnum];

    // }







    if(!isLoaded)
    {

        return (

            <div style={{

                backgroundColor:"transparent",
                width:"200px",
                height:"200px",
                position:'absolute',
                top:"calc(50% - 100px)",
                left:"calc(50% - 100px)",
                zIndex:"1000"


            }}></div>

        );


    }else{



        return (

            <div>



            {runAccount
            ? 
                <div>

                    <FSloading
                        formArr={formArr}    
                        handle={(v) => props.handle(v)}   
                    /> 

                    {/* <DashboardAccount
    
                        handle={(v) => props.handle(v)} 
                        currentKey={currentKey} 
                        postTo={postTo}       
                        formArr={formArr}

    
                    />  */}

                    <DashboardAuthAccount
                        
                        handle={(v) => props.handle(v)} 
                        currentKey={currentKey} 
                        postTo={postTo}       
                        formArr={formArr}


                    /> 


                </div>


            : null} 

            {runCalculate
            ? 
                <div>

                    <FSloading
                        formArr={formArr}    
                        handle={(v) => props.handle(v)}   
                    /> 

                    <DashboardCalculate
    
                        handle={(v) => props.handle(v)} 
                        currentKey={currentKey} 
                        postTo={postTo}       
                        formArr={formArr}

    
                    /> 

                </div>


            : null}


            {runOutput
            ? 
                
                <Readr 

                    handle={(v) => props.handle(v)}
                    data={readr_data}
                
                />


            : null}


            {runSubmit
            ? 

                <div>

                    <FSloading
                        formArr={formArr}
                        handle={(v) => props.handle(v)}   
                    /> 

                    <FSprocess
                        postTo={postTo}
                        formArr={formArr}
                        handle={(v) => props.handle(v)}   
                    /> 

                </div>


            : null}


            {runError
            ? 


                // <div style={{

                //     backgroundColor:"orange",
                //     width:"200px",
                //     height:"200px",
                //     position:'absolute',
                //     top:"calc(50% - 100px)",
                //     left:"calc(50% - 100px)",
                //     zIndex:"1000"


                // }}></div>


                // <FSerror
                //     errorType={errorType}
                //     errorArr={problemArr}
                //     handle={(v) => props.handle(v)}   
                // /> 


                <Messngr 

                    handle={(v) => props.handle(v)}
                    data={{

                        isError     : true,
                        errorType   : errorType,
                        isTimed     : false,
                        items       : errorItems

                    }}
                
                />


            : null}


            {runComplete
            ? 

                <Messngr 

                    handle={(v) => props.handle(v)}
                    data={{

                        isError         : false,
                        errorType       : '',
                        isTimed         : true,
                        timedDuration   : 1200,
                        items           : completeMessage

                    }}
                
                />


            : null}


            </div>

                    
        );


    }



}

export default Index;











// const currentFlyr = zoneObj.currentFlyr;
// var formArr = [];
// var problemArr = [];

// var runSubmit;
// var runComplete;
// var runError;
// var errorType;

// var runAccount;
// var runCalculate;
// var runOutput;
// var output;
// var postTo;
// var currentKey;
// var completeMessage;


// formArr             = zoneObj[currentFlyr + "_formArr"];
// problemArr          = zoneObj[currentFlyr + "_problemArr"]; 

// runSubmit           = zoneObj[currentFlyr + "_runSubmit"];
// runComplete         = zoneObj[currentFlyr + "_runComplete"];
// runError            = zoneObj[currentFlyr + "_runError"];
// errorType           = zoneObj[currentFlyr + "_errorType"];

// runAccount          = zoneObj[currentFlyr + "_runAccount"];
// runCalculate        = zoneObj[currentFlyr + "_runCalculate"];
// runOutput           = zoneObj[currentFlyr + "_runOutput"];
// output              = zoneObj[currentFlyr + "_output"];

// postTo              = zoneObj[currentFlyr + "_postTo"];
// currentKey          = zoneObj[currentFlyr + "_currentKey"];

// completeMessage     = zoneObj[currentFlyr + "_completeMessage"];



// if(Object.prototype.toString.call(problemArr).match(/undefined/gi))
// {
//     problemArr = [];
// }
// //console.log("PROBLEM ARR: "+JSON.stringify(problemArr,null,2));

// if(Object.prototype.toString.call(postTo).match(/undefined/gi))
// {
//     postTo = false;
// }
// //console.log("POST TO: "+postTo);


// if(Object.prototype.toString.call(completeMessage).match(/undefined/gi))
// {
//     completeMessage = "Update Successfull!!";
// }
// //console.log("COMPLETE MESSAGE: "+completeMessage);




// //console.log("OUTPUT: "+JSON.stringify(output,null,2));



// var completeMsg;
// completeMsg = [

//     {
//         "call":"heading",
//         "text":"Success" 
//     },
//     {
//         "call":"span",
//         //"text":"Update was Successfull!!" 
//         "text":completeMessage
//     }

// ];






// if(output !== undefined)
// {
//     var scene;
//     //var array;
//     var object;
//     //var item;

//     var readr_data;
//     var readr_value;


//     var keyname;
//     var html;
//     var str;


//     /*
//     var ak;
//     var bk;
//     var ck;
//     //var dk;

//     var objA;
//     var objB;
//     var objC;
//     var objD;
//     */
    



//     switch(output.call)
//     {
//     case'cards':

//         readr_value = [];

//         scene = SIform({
//             form_items: output.data.formItems,
//             result_items: output.data.resultItems
//         });

//         for(object of scene)
//         {
//             if(object.keyname !== undefined)
//             {
//                 readr_value.push({
                    
//                     name:object.name,
//                     value:object.value
                
//                 });


                
//             }
//         }



//         scene = SIresult({
//             result_items: output.data.resultItems
//         });

//         for(object of scene)
//         {
//             if(object.keyname !== undefined)
//             {
//                 readr_value.push({
                    
//                     name:object.name,
//                     value:object.value
                
//                 });
                
//             }
//         }






//         /*

//         for(keyname in output.data)
//         {
//             console.log("")
//             console.log("===============")
//             console.log(keyname);
//             console.log("===============")

//             objA = output.data[keyname];

//             //sonstruct if string
//             if(Object.prototype.toString.call(objA) === '[object String]')
//             {

//                 console.log("A...  "+objA)
    
//             }
//             else
//             {


//                 for(ak in objA)
//                 {
//                     objB = objA[ak];

//                     //construct if string
//                     if(Object.prototype.toString.call(objB) === '[object String]')
//                     {
//                         console.log("B... "+ak+": "+objB)


//                         readr_value[ak] = objB;

//                     }
//                     else
//                     {

//                         console.log("")
//                         console.log("--------------")
//                         console.log(ak);
//                         console.log("--------------")


//                         for(bk in objB)
//                         {

//                             objC = objB[bk];
                         
             
                    
//                             //construct if string
//                             if(Object.prototype.toString.call(objC) === '[object String]')
//                             {
//                                 console.log("C... "+bk+": "+objC)

//                                 //readr_value[ck] = objC;


            
//                             }
//                             else
//                             {

//                                 for(ck in objC)
//                                 {

//                                     objD = objC[ck];

//                                     //construct if string
//                                     if(Object.prototype.toString.call(objD) === '[object String]')
//                                     {
//                                         console.log("D... "+ck+": "+objD);

//                                         //readr_value[ck] = objD


//                                     }
//                                     else
//                                     {

//                                         console.log("D...");
//                                         console.log(JSON.stringify(objD,null,2));

//                                         //readr_value[ck] = objD;

//                                     }





//                                 }


//                             }




//                         }


//                     }


//                 }
 
//             }
            

//         }

//         */

        

//         readr_data = {
//             data:{
//                 name:"Output",
//                 value:readr_value,
//                 actions:{
//                     add:false,
//                     delete:false,        
//                     edit:false,
//                     chart:false
//                 }
//             },
//             flyr:{
//                 type:'cards',
//                 styl:{
//                     maxWidth:750
//                 }
//             }
//         }


//     break;
//     default:


//         //&nbsp; 
//         //&thinsp; 
//         //&ensp;
//         //&emsp;


//         //readr_value = JSON.stringify(output.data,null,2);
//         //readr_value = readr_value.replace(/\n/gi,"<br/>&nbsp;");



//         html = '';

//         for(keyname in output.data)
//         {
//             console.log("")
//             console.log("===============")
//             console.log(keyname);
//             console.log("===============")


//             html += "<h2>"+keyname+"</h2>";

//             str = output.data[keyname];

//             if(Object.prototype.toString.call(str) === '[object Object]' 
//             || Object.prototype.toString.call(str) === '[object Array]'
//             )
//             {
//                 str = JSON.stringify(str,null,2);
//                 str = str.replace(/\n/gi,"<br/>&nbsp;");
//             }

//             html += str;




//             /*
//             //sonstruct if string
//             if(Object.prototype.toString.call(objA) === '[object String]')
//             {

//                 console.log("A...  "+objA)
    
//             }
//             else
//             {


//                 for(ak in objA)
//                 {
//                     objB = objA[ak];

//                     //construct if string
//                     if(Object.prototype.toString.call(objB) === '[object String]')
//                     {
//                         console.log("B... "+ak+": "+objB)


//                         readr_value[ak] = objB;

//                     }
//                     else
//                     {

//                         console.log("")
//                         console.log("--------------")
//                         console.log(ak);
//                         console.log("--------------")


//                         for(bk in objB)
//                         {

//                             objC = objB[bk];
                         
             
                    
//                             //construct if string
//                             if(Object.prototype.toString.call(objC) === '[object String]')
//                             {
//                                 console.log("C... "+bk+": "+objC)

//                                 //readr_value[ck] = objC;


            
//                             }
//                             else
//                             {

//                                 for(ck in objC)
//                                 {

//                                     objD = objC[ck];

//                                     //construct if string
//                                     if(Object.prototype.toString.call(objD) === '[object String]')
//                                     {
//                                         console.log("D... "+ck+": "+objD);

//                                         //readr_value[ck] = objD


//                                     }
//                                     else
//                                     {

//                                         console.log("D...");
//                                         console.log(JSON.stringify(objD,null,2));

//                                         //readr_value[ck] = objD;

//                                     }





//                                 }


//                             }




//                         }


//                     }


//                 }
 
//             }

//             */
            

//         }

        
//         readr_value = html;


//         readr_data = {
//             data:{
//                 name:"Output",
//                 value:readr_value
//             },
//             flyr:{
//                 type:'print',
//                 styl:{
//                     maxWidth:750
//                 }
//             }
//         }


//     break;
//     }

// }





