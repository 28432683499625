
import styled from "styled-components";
//import px2vw from "utils/px2vw";





/*

Over 200 classes were generated for component styled.div with the id of "sc-AxirZ".
Consider using the attrs method, together with a style object for frequently changed styles.
Example:

const Component = styled.div.attrs({
    style: ({ background }) => {
        background,
    },
})`width: 100%;`

<Component />


*/

  



const Rows = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent"

}))`

    background-color:${props => props.backgroundColor};

    width: 100%;
    height:auto;
    padding:0 0 0 0;
    margin:0 0 0 0;

    position:relative;
    float:left;


`;


const FullRow = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",

    marginTop:          props.marginTop || "0px",
    marginBottom:       props.marginBottom || "0px",
    marginLeft:         props.marginLeft || "0px",
    marginRight:        props.marginRight || "0px"

}))`

    background-color:${props => props.backgroundColor};

    width:100%;
    height:auto;
    min-height:15px;

    margin-top: ${props => props.marginTop};
    margin-bottom: ${props => props.marginBottom};
    margin-left: ${props => props.marginLeft};
    margin-right: ${props => props.marginRight};

    position:relative;
    float:left;


`;


const IndentRow = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",

    marginTop:          props.marginTop || "0px",
    marginBottom:       props.marginBottom || "0px",
    marginLeft:         props.marginLeft || "2.5%",
    marginRight:        props.marginRight || "0px"


    // @media (max-width: 1250px) {

    //     width: 86%;
    //     margin-left:7%;

    // }


}))`

    background-color:${props => props.backgroundColor};

    width:95%;
    height:auto;
    min-height:15px;

    margin-top: ${props => props.marginTop};
    margin-bottom: ${props => props.marginBottom};
    margin-left: ${props => props.marginLeft};
    margin-right: ${props => props.marginRight};

    position:relative;
    float:left;


    @media (max-width: 100000px) {

        margin-left:0px;

    }

    @media (max-width: 1125px) {

        width: 90%;
        margin-left:5%;
    
    }

    @media (max-width: 1100px) {

        width: 88%;
        margin-left:6%;
    
    }

    @media (max-width: 1080px) {

        width: 86%;
        margin-left:7%;
    
    }

    @media (max-width: 950px) {

        width: 100%;
        margin-left:0px;
    
    }

    @media (max-width: 875px) {

        width: 86%;
        margin-left:7%;
    
    }



    
`;



const IndentRowB = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",

    marginTop:          props.marginTop || "0px",
    marginBottom:       props.marginBottom || "0px",
    marginLeft:         props.marginLeft || "6%",
    marginRight:        props.marginRight || "0px"


    // @media (max-width: 1250px) {

    //     width: 86%;
    //     margin-left:7%;

    // }




}))`

    background-color:${props => props.backgroundColor};

    width:88%;
    height:auto;
    min-height:150px;

    margin-top: ${props => props.marginTop};
    margin-bottom: ${props => props.marginBottom};
    margin-left: ${props => props.marginLeft};
    margin-right: ${props => props.marginRight};

    position:relative;
    float:left;



    @media (max-width: 100000px) {

        margin-left:0px;

    }

    @media (max-width: 1100px) {

        width: 92%;
        margin-left:4%;

    }
    

`;









export {

    Rows,
    FullRow,
    IndentRow,
    IndentRowB

}












