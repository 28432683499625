import styled from "styled-components";

/*
https://styled-components.com/docs

    //Stretching
    flex: 1 1 300px;
    
    //No Stretching
    flex: 0 1 450px;


*/

export const LogoContainer = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",//#fcfcfc
    //border:             props.border || "0px solid #fcfcfc",
    //borderRadius:       props.borderRadius || "10px",

    width:              props.width || "550px", 
    height:             props.height || "auto",

    padding:            props.padding|| "0 0 0 0",
    margin:             props.margin || "30px 0 15px calc(50% - 275px)",

}))`

    background-color:${props => props.backgroundColor};


    width   :${props => props.width};
    height  :${props => props.height};

    padding :${props => props.padding}; 
    margin  :${props => props.margin};

    position:relative;
    float:left;



    .Icon {

        background-color:transparent;
        width:60px;
        height:60px;
        padding:0 0 0 0;
        margin:0 0 0 0;
        position:relative;
        float:left;

    }

    .Text {

        background-color:transparent;
        width:475px;
        height:55px;
        padding:0 0 0 0;
        margin:12px 0 0 15px;
        position:relative;
        float:left;

    }

    
`;




