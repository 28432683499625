import styled from "styled-components";
//import px2vw from "utils/px2vw";


/*

Over 200 classes were generated for component styled.div with the id of "sc-AxirZ".
Consider using the attrs method, together with a style object for frequently changed styles.
Example:

const Component = styled.div.attrs({
    style: ({ background }) => {
        background,
    },
})`width: 100%;`

<Component />


*/



export const Topbar = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",

    width:              props.width || "100%",
    height:             props.height || "38px",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",


    colorOver:          props.colorOver || "#0291cd"


    //border-bottom:1px solid lightgray;
  
  
}))`
  
    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};
  
    position:relative;   
    float:left;


    .Left{

        background-color:transparent;
        min-width:100px;
        width:auto;
        height:100%;
        padding:0 0 0 0;
        margin:0 0 0 10px;

        position:relative;
        float:left;


    }

    .Right{

        background-color:transparent;
        min-width:100px;
        width:auto;
        height:100%;
        padding:0 0 0 0;
        margin:0 0 0 0;

        position:relative;
        float:right;

    }


    .Button{

        background-color:transparent;
        min-width:10px;
        width:auto;
        height:auto;
        padding:12px 0 10px 0;
        margin:0 3px 0 3px;

        position:relative;
        float:left;

        display:block;
        overflow:hidden;
    
        font-family:Arial, Helvetica, sans-serif;      
        font-size: 14px;
        font-weight: normal;
        text-align: center;
        text-decoration:none;
        color:#000;

        cursor:pointer;

        &:hover{

            text-decoration:underline;
            color:${props => props.colorOver};

        }
    
    }


    .MenuButton{

        background-color:transparent;
        width:30px;
        height:30px;
        padding:0 0 0 0;
        margin:9px 25px 0 5px;

        position:relative;
        float:left;

        display:block;
        overflow:hidden;
    
        font-family:Arial, Helvetica, sans-serif;      
        font-size: 14px;
        font-weight: normal;
        text-align: center;
        text-decoration:none;
        color:#000;

        cursor:pointer;

    
    }

    .Slice{

        background-color:black;
        border-radius:2px;
        width:100%;
        height:5px;
        padding:0 0 0 0;
        margin:0 0 5px 0;

        position:relative;
        float:left;

        cursor:pointer;

    }


    .PanelButton{

        background-color:transparent;

        width:auto;
        height:30px;
        padding:0 10px 0 10px;
        margin:12px 25px 0 5px;

        position:relative;
        float:right;

        display:block;
        overflow:hidden;
    
        font-family:Arial, Helvetica, sans-serif;      
        font-size: 16px;
        font-weight: normal;
        text-align: center;
        text-decoration:none;
        color:#000;

        cursor:pointer;


        &:hover{

            color:#0291cd;

        }

    }





    @media (max-width: 950px) {

        background-color:#EDEEF1;
        height:44px;


        .Button{

            padding:15px 0 14px 0;
            color:black;

            &:hover{

                text-decoration:underline;
                color:${props => props.colorOver};
    
            }
    
        }

    }




  
`;





export const Topbar2 = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#edeef1", // "#191e28", //"#141820",

    width:              props.width || "100%",
    height:             props.height || "38px",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",


    colorOver:          props.colorOver || "#0291cd"


    //border-bottom:1px solid lightgray;
  
  
}))`
  
    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};
  
    position:relative;   
    float:left;


    .Left{

        background-color:transparent;
        min-width:100px;
        width:auto;
        height:100%;
        padding:0 0 0 0;
        margin:0 0 0 10px;

        position:relative;
        float:left;


    }

    .Right{

        background-color:transparent;
        min-width:100px;
        width:auto;
        height:100%;
        padding:0 0 0 0;
        margin:0 0 0 0;

        position:relative;
        float:right;

    }


    .Button{

        background-color:transparent;
        min-width:10px;
        width:auto;
        height:auto;
        padding:12px 0 10px 0;
        margin:0 3px 0 3px;

        position:relative;
        float:left;

        display:block;
        overflow:hidden;
    
        font-family:Arial, Helvetica, sans-serif;      
        font-size: 14px;
        font-weight: normal;
        text-align: center;
        text-decoration:none;
        color:#000;

        cursor:pointer;

        &:hover{

            text-decoration:underline;
            color:${props => props.colorOver};

        }
    
    }


    .MenuButton{

        background-color:transparent;
        width:30px;
        height:30px;
        padding:0 0 0 0;
        margin:9px 25px 0 5px;

        position:relative;
        float:left;

        display:block;
        overflow:hidden;
    
        font-family:Arial, Helvetica, sans-serif;      
        font-size: 14px;
        font-weight: normal;
        text-align: center;
        text-decoration:none;
        color:#000;

        cursor:pointer;

    
    }

    .Slice{

        background-color:black;
        border-radius:2px;
        width:100%;
        height:5px;
        padding:0 0 0 0;
        margin:0 0 5px 0;

        position:relative;
        float:left;

        cursor:pointer;

    }


    .PanelButton{

        background-color:transparent;

        width:auto;
        height:30px;
        padding:0 10px 0 10px;
        margin:12px 25px 0 5px;

        position:relative;
        float:right;

        display:block;
        overflow:hidden;
    
        font-family:Arial, Helvetica, sans-serif;      
        font-size: 16px;
        font-weight: normal;
        text-align: center;
        text-decoration:none;
        color:#000;

        cursor:pointer;


        &:hover{

            color:#0291cd;

        }

    }





    @media (max-width: 950px) {

        background-color:#EDEEF1;
        height:44px;


        .Button{

            padding:15px 0 14px 0;
            color:black;

            &:hover{

                text-decoration:underline;
                color:${props => props.colorOver};
    
            }
    
        }

    }




  
`;





  