import React, { 

    useState, 
    useEffect,
    //useRef

} from 'react';


//import 'app-style/price-form/style.css';
//import '../../StyleSheets/Readout.css';
import 'app-components/Zone/_StyleSheets/Readout.css';


const FFledger = (props) => {




    //=============================================
    // state/effect - isLoaded
    //=============================================

    const [isLoaded, setIsLoaded] = useState(false);
    const [formArr, setFormArr] = useState([]);

    useEffect(() => {

        if(!isLoaded)
        {

            setIsLoaded(true);

            var keyname;
            var newArr = [];
            //var i;
            //var j;

            //console.log("props: "+JSON.stringify(props,null,2));
            if(props.data)
            {
                if(props.data.value)
                {
                    var value = props.data.value;
                    for(keyname in value)
                    {
                        //console.log(JSON.stringify(value[keyname],null,2));
                        newArr.push(value[keyname]);

                    }//endfor
                }
            }
            //console.log("newArr: "+JSON.stringify(newArr,null,2));
            setFormArr(newArr);

        }
  
    },
    [
        props,
        isLoaded,
        formArr
    ])







    return (


        formArr.map((frm, i) => (
       
            <div
            key={props.indx+"_ffledger_"+i} 
            className = "ledger"
            style={{

                borderColor:props.styling.lineColor
            }}
            >
                <div
                className = "name"
                >

                    {frm.name}

                </div>

                <div
                className = "value"
                >
                    {
                    frm.print === 'price'
                    ?
                        "$"+frm.value

                    :
                    frm.print === 'percent'
                    ?
                        frm.value+"%"
                
                    :
                    frm.print === 'text'
                    ?
                        frm.value
                
                    :null
                    }

                </div>

            </div>
    

        ))


    )

}


export {
    
    FFledger
};





/*

{Object.keys(categories).map(keyName => (
    //<option value={key}>{tifs[key]}</option>
    <div key={keyName}>


        <ControlPanelGroup
        id={"controlPanelGroup-"+keyName}
        >

            {categories[keyName].map((item, i) => (
            <div key={keyName+"-"+i}>

                <ControlPanelTile
                ref={setTileRef}  
                //id={"controlPanelTile-"+keyName+"-"+i}
                id={"controlPanelTile-"+i}
                //backgroundColor={item.backgroundColor}
                //float={"left"}
                onClick={(v) => handle({
                    call:'setCategories',
                    data:{
                        call:'sub',
                        indx:i,
                        item:item
                    }
             
                })}
                >
                    <div className="Grfx">


                        <GRFX_BUTTONS
    
                            call={item.thumbComponent}
                        
                        />

                    </div>

                    <div className="Text">

                        {item.name}

                    </div>
                
                </ControlPanelTile>

            </div>
            ))}

        </ControlPanelGroup>

    </div>

))}


*/
