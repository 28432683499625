import styled from "styled-components";

export const Catalog = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    //border:             props.border || "1px solid lightgray",

    width:              props.width || "100%",
    height:             props.height || "auto",

    margin:             props.margin || "30px 0 0 0",
    padding:            props.padding || "0 0 0 0",

    minHeight:          props.minHeight || "50px",

}))`


    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    height:${props => props.height};
    min-height:${props => props.minHeight};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:visible;


    @media (max-width: 1100px) {

        width:90%;
        margin:25px 0 25px 5%;

    }

    @media (max-width: 600px) {

        width:calc(100% - 20px);
        margin:25px 0 25px 10px;
    
    }



`;





//#############################################################
//#############################################################

export const CatalogSection = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "1px solid lightgray",

    width:              props.width || "100%",
    height:             props.height || "auto",
    minHeight:          props.minHeight || "100px",


    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "0 0 0 0"

}))`

    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    height:${props => props.height};


    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;



    cursor:default;

    @media (max-width: 300px) {

        height:80px;
    }



    .Spacer {

        background-color:${props => props.spacerBackgroundColor};
        width:${props => props.spacerWidth};
        height:${props => props.spacerHeight};

        margin:0 0 0 0;
        padding:0 0 0 0;

        position:relative;
        float:left;
    
    }





`;


//#############################################################
//#############################################################


export const CatalogBox = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "1px solid lightgray",

    width:              props.width || "calc(50% - 0px)",
    height:             props.height || "auto",
    minHeight:          props.minHeight || "25px",

    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "0 0 0 0",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "20px",
    fontWeight:         props.fontWeight  || "500",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration  || "none",
    textColor:          props.textColor || "black"



    // border-bottom:${props => props.border};
    // border-left:${props => props.border};
    // border-right:${props => props.border};




    // cursor:pointer;

    // @media (max-width: 300px) {

    //     height:80px;
    // }

    // &:hover {

    //     background-color:#caffca;
    //     text-decoration:underline;
    // }





}))`


    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    height:${props => props.height};
    min-height:${props => props.minHeight};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;

    font-family:${props => props.fontFamily};
    font-size:${props => props.fontSize};
    font-weight:${props => props.fontWeight};
    text-align:${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.textColor};



`;






//#############################################################
//#############################################################

export const CatalogMessage = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "white",
    border:             props.border || "1px solid lightgray",

    width:              props.width || "calc(100% - 2px)",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "26px 0 24px 0",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "24px",
    fontWeight:         props.fontWeight  || "500",
    textAlign:          props.textAlign || "center",
    textDecoration:     props.textDecoration  || "none",
    textColor:          props.textColor || "#454d5f"




}))`


    background-color:${props => props.backgroundColor};
    border-bottom:${props => props.border};
    border-left:${props => props.border};
    border-right:${props => props.border};

    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;


    font-family:${props => props.fontFamily};
    font-size:${props => props.fontSize};
    font-weight:${props => props.fontWeight};
    text-align:${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.textColor};


    cursor:pointer;


    @media (max-width: 300px) {

        height:80px;
    }



`;






//#############################################################
//#############################################################

export const CatalogPrint = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#191e28",
    border:             props.border || "2px solid #07a2e3",

    width:              props.width || "calc(100% - 14px)",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "10px 0 10px 10px",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "16px",
    fontWeight:         props.fontWeight  || "500",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration  || "none",
    textColor:          props.textColor || "#edeef1"




}))`


    background-color:${props => props.backgroundColor};
    border-top:${props => props.border};
    border-bottom:${props => props.border};
    border-left:${props => props.border};
    border-right:${props => props.border};

    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;


    font-family:${props => props.fontFamily};
    font-size:${props => props.fontSize};
    font-weight:${props => props.fontWeight};
    text-align:${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.textColor};



`;


