// import { 
//     useSelector, 
//     //useDispatch 
// } 
// from 'react-redux';
// //import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';



//import { Settings } from 'app-components/Prime/Data.js';

import { 

    //VPformTemplateOwnr,
    //VPformTemplateEmpl,
    //VPformTemplateExpense,

    //VPcompanyInfo, 
    VPexpenses,
    VPincome,   
    VPoverhead, 

    VPownrsIndirect,  
    //VPownrs,
    VPownr,

    //VPemplsYearly, 
    //VPemplsMonthly, 
    //VPemplsDirect,
    VPemplsIndirect,
    VPempl


} from 'app-components/Prime/VPdata/form.js';



// import { VPreportDefaults } from 'app-components/Prime/VPdata/reports';


// import { 

//     ChartColorsA, 
//     //ChartColorsB, 
//     //ChartColorsC 

// } from 'utils/components/Charts/ChartColors';


import { 
    LibBase, 
    LibNumber 
} from "library";


// import ApiResources from 'utils/components/Pages/ApiResources';


const Index = (props) => {



    //-------------------------------------------

    // var apiDomain;
    // var hostname;
    // //var hostname = window.location.hostname;//returns the domain name of the web host
    // var protocol = window.location.protocol;//returns the web protocol used (http: or https:)

    // if(window.location.hostname.match(/192.168/gi))
    // {
    //     hostname = ApiResources.network['3001'].vpc;
    //     apiDomain = protocol + "//" + hostname + "/";
    // }
    // else
    // if(window.location.hostname.match(/localhost/gi))
    // {
    //     hostname = ApiResources.local['3001'].vpc;
    //     apiDomain = protocol + "//" + hostname + "/";
    // }else{
    //     hostname = ApiResources.aws['x'].vpc;
    //     apiDomain = "https://" + hostname + "/";
    // }//=

    //-------------------------------------------

    //console.log("API DOMAIN: "+apiDomain);


    //const apiCall = 'alter-calc-test';
    //const apiCall = 'alter-calc-dash';




    // -- redux -- //
    //const dispatch = useDispatch();
    //const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));


    const zoneObj = props.zoneObj;
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));







    var overhead_percent;
    //var overhead_percent_int = 0;
    var owners = {};   
    var employees = {};
    //var expenses = {};

    overhead_percent = 0.0 + "%";



    var index;

    var keyname;
    var kn;
    var item;
    var value;
    var newValue;

    var a,b,c,d;
    var total;
    var grand_total;


    //var chartSections;
    var object;
    // var percent;
    // var count;

    //var pgraph;


    var obj;

    // var chartColors = ChartColorsA;




    //clear VPoverhead
    for(keyname in VPoverhead)
    {
        if(keyname.match(/expense-/gi))
        {
            delete VPoverhead[keyname];
        }
    }

    var cards_overhead = LibBase.CloneIt(VPoverhead);
    var cards_owners = LibBase.CloneIt(VPownrsIndirect);
    var cards_employees = LibBase.CloneIt(VPemplsIndirect);


    //var templateExpenseForm;
    //var newTemplateExpenseForm;
    


    //var templateEmplForm;
    //var newTemplateEmplForm;
    
    //var template_empl;



    var piePerc;
    var pieSlice;
    var pieChart = {

        items: [ 

            {
                name:"Business",
                value:14
            },
            {
                name:"Facility",
                value:20
            }, 
            {
                name:"Equip. Software",
                value:30
            },      
            {
                name:"Vehicles",
                value:12
            },

            {
                name:"Owners",
                value:5
            },
            {
                name:"Employees",
                value:5
            },


        ],

        colors: [

            "#fde23e",
            "#f16e23", 
            "#57d9ff",
            "#937e88",
            "#20b2aa",
            "#ccff00"

        ],


        hole:{

            percent         :"0.0",
            name            :"Overhead",
            color           :"#181b21",
            outlineColor    :"#181b21",
            textColor       :"white"
        }



    };






    var isOkay;
    isOkay = false;
    if(zoneObj.calc_dash 
    && zoneObj.calc_dash !== undefined
    )
    {
        if(Object.keys(zoneObj.calc_dash.form_items).length > 0
        && Object.keys(zoneObj.calc_dash.result_items).length > 0
        )
        {
            isOkay = true;
        }

    }
    if(isOkay)
    {


        grand_total = zoneObj.calc_dash.result_items['totals'].total;
        grand_total = parseFloat(grand_total);


        //console.log("FORM ITEMS: "+JSON.stringify(zoneObj.calc_dash.form_items));

        index=0;
        for(keyname in zoneObj.calc_dash.form_items)
        {

            // -- owners -- //

            if(keyname.match(/ownr/gi))
            {

                //idl_employees[keyname] = zoneObj.calc_dash.form_items[keyname];

                
                //order card item based on Prime VPdata
                //item = zoneObj.calc_dash.form_items[keyname];
                item = LibBase.CloneIt(zoneObj.calc_dash.form_items[keyname]);//Deep Clone
          
          
                //value = VPowner.value;
                value = LibBase.CloneIt(VPownr.value);//Deep Clone
                newValue = {};
                for(kn in value)
                {
                    if(kn === 'ownr_call') 
                    {
                    }else{
                        newValue[kn] = item.value[kn];       
                    }
                    
                }
                owners[keyname] = {
                    name:item.name,
                    value:newValue
                };
                

            }




            // -- employees -- //

            else
            if(keyname.match(/empl/gi))
            {

                //idl_employees[keyname] = zoneObj.calc_dash.form_items[keyname];

                
                //order card item based on Prime VPdata
                //item = zoneObj.calc_dash.form_items[keyname];
                item = LibBase.CloneIt(zoneObj.calc_dash.form_items[keyname]);//Deep Clone
          
                //value = VPemployee.value;
                value = LibBase.CloneIt(VPempl.value);//Deep Clone
                newValue = {};
                for(kn in value)
                {
                    newValue[kn] = item.value[kn];
                }
                employees[keyname] = {
                    name:item.name,
                    value:newValue
                };
                



            }



            // -- overhead -- //

            else
            {
                
                //console.log("KEYNAME: "+keyname);

                item = LibBase.CloneIt(zoneObj.calc_dash.form_items[keyname]);//Deep Clone
                //console.log(JSON.stringify(item,null,2));


                if(keyname.match(/expense-/gi))
                {

                    // TM: order inputs here



                    // -- VPoverhead -- //

                    if(item.order)
                    {
                        newValue = {};
                        for(obj of item.order)
                        {
                            //console.log("order:  "+obj);
                            for(kn in item.value)
                            {
                                //console.log("kn:  "+kn);
                                if(obj === kn)
                                {
                                    //console.log("match: "+kn);
                                    newValue[kn] = item.value[kn];
                                }
                            }

                        }
                        //console.log(JSON.stringify(newValue,null,2));

                        VPoverhead[keyname] = {
                            name:item.name,
                            value:newValue,
                            order:item.order
                        };


                    }else{

                        VPoverhead[keyname] = {
                            name:item.name,
                            value:item.value
            
                        };

                    }






                    // -- card_overhead -- //

                    value = LibBase.CloneIt(item.value);//Deep Clone

                    if(item.order)
                    {
                        newValue = {};
                        for(obj of item.order)
                        {
                            //console.log("order:  "+obj);
                            for(kn in item.value)
                            {
                                //console.log("kn:  "+kn);
                                if(obj === kn)
                                {
                                    //console.log("match: "+kn);
                                    newValue[kn] = item.value[kn];
                                }
                            }

                        }
                        //console.log(JSON.stringify(newValue,null,2));


                        total = 0;
                        for(kn in newValue)
                        {
                            obj = newValue[kn];
                            total = parseFloat(total) + parseFloat(obj.value);
      
                        }
                        total = parseFloat(total);
                        total = total.toFixed(2);
                        // console.log("TOTALLLLLLLLLLLLL "+total);
                        newValue["total"] = total;
                        newValue["total_percent"] = 0;
    
                        cards_overhead[keyname] = {
                            name:item.name,
                            value:newValue,
                            order:item.order
                        };
    

                    }else{

                        total = 0;
                        for(kn in value)
                        {
                            obj = value[kn];
                            total = parseFloat(total) + parseFloat(obj.value);
      
                        }
                        total = parseFloat(total);
                        total = total.toFixed(2);
                        // console.log("TOTALLLLLLLLLLLLL "+total);
                        value["total"] = total;
                        value["total_percent"] = 0;
    
                        cards_overhead[keyname] = {
                            name:item.name,
                            value:value
                        };
    
                    }





                }else{

                    //value = VPoverhead[keyname].value;
                    value = LibBase.CloneIt(VPoverhead[keyname].value);//Deep Clone
                    newValue = {};
                    for(kn in value)
                    {
                        newValue[kn] = item.value[kn];
                    }

                    VPoverhead[keyname] = {
                        name:item.name,
                        value:newValue
                    };

                    cards_overhead[keyname] = {

                        name:item.name,
                        value:newValue

                    };                

                }


        
                // -- set totals for cards -- //

                if(keyname.match(/expense-/gi))
                {
                    total = 0;
                    newValue = 0;
                    for(kn in item.value)
                    {
                        //console.log(value[kn]);
                        if(item.value[kn] !== undefined)
                        {
                            newValue = item.value[kn].value;
                            if(!isNaN(newValue)) parseFloat(newValue);
                            else newValue = 0;
                            total = parseFloat(total) + parseFloat(newValue); 
                        }
    
                    }
                    total = parseFloat(total);        
                    total = total.toFixed(2);
                

                    piePerc = ( parseFloat(total)/parseFloat(grand_total) ) * 100;
                    if(isNaN(piePerc) || grand_total === 0) piePerc = 0;
                    piePerc = piePerc.toFixed(1);
                    piePerc = parseFloat(piePerc);



                    cards_overhead[keyname].value['total_percent'] = {
    
                        name: "Total Monthly (Overhead %)",
                        default: 0,
                        print: "percent",
                        value: piePerc,
                        required: true
    
                    };
                    cards_overhead[keyname].value['total'] = {
    
                        name: "Total Monthly (Overhead $)",
                        default: 0,
                        print: "price",
                        value: total,
                        required: true
    
                    };


                    // -- set pieChart data -- //

                    for(pieSlice of pieChart.items)
                    {
                        if(pieSlice.value !== piePerc)
                        {
                            pieChart.items[index].name = item.name;      
                            pieChart.items[index].value = piePerc;
                            break;
            
                        }
                       
                    }
                    index++;


                }//==




            }

        }// -- for





        //VPexpenses.value.monthly_expenses.value = grand_total;
        VPexpenses.value.monthly_expenses.value = grand_total;
        VPincome.value.projected_monthly_sales.value = zoneObj.calc_dash.form_items['settings'].value.projected_monthly_sales.value;


        overhead_percent = zoneObj.calc_dash.result_items['overhead'].overhead;
        overhead_percent = parseFloat(overhead_percent); 

        //overhead_percent_int = overhead_percent.toFixed(1);

        pieChart.hole.percent = overhead_percent.toFixed(1);

        overhead_percent = overhead_percent.toFixed(1) + "%";




        //console.log("IDL EMPLOYEES: "+JSON.stringify(idl_employees,null,2));

    
        /*
        for(keyname in zoneObj.calc_dash.result_items)
        {

            console.log("KEYNAME: "+keyname);

            item = zoneObj.calc_dash.result_items[keyname];
            console.log(JSON.stringify(item,null,2));

        
        }// -- for
        */





        //------------------------------------------
        // -- owners - totals - cards 
        //------------------------------------------

        //var total_ownr_salary_pretax_yearly_indirect
        var total_ownr_salary_pretax_monthly_indirect;
        var total_ownr_health_insurance_monthly;
        var total_ownr_ira_monthly_indirect;
        var total_ownr_taxes_monthly_indirect;  

        total_ownr_salary_pretax_monthly_indirect = zoneObj.calc_dash.result_items['totals'].total_ownr_salary_pretax_monthly_indirect;
        total_ownr_health_insurance_monthly = zoneObj.calc_dash.result_items['totals'].total_ownr_health_insurance_monthly;
        total_ownr_ira_monthly_indirect = zoneObj.calc_dash.result_items['totals'].total_ownr_ira_monthly_indirect;
        total_ownr_taxes_monthly_indirect = zoneObj.calc_dash.result_items['totals'].total_ownr_taxes_monthly_indirect;  

        //total_ownr_salary_pretax_yearly_indirect = parseFloat(total_ownr_salary_pretax_monthly_indirect) * 12;


        //if(isNaN(total_ownr_salary_pretax_yearly_indirect)) total_ownr_salary_pretax_yearly_indirect = 0;
        if(isNaN(total_ownr_salary_pretax_monthly_indirect)) total_ownr_salary_pretax_monthly_indirect = 0;
        if(isNaN(total_ownr_health_insurance_monthly)) total_ownr_health_insurance_monthly = 0;
        if(isNaN(total_ownr_ira_monthly_indirect)) total_ownr_ira_monthly_indirect = 0;
        if(isNaN(total_ownr_taxes_monthly_indirect)) total_ownr_taxes_monthly_indirect = 0;


        //cards_owners.value.ownr_salary_pretax_yearly_indirect.value = total_ownr_salary_pretax_yearly_indirect;
        cards_owners.value.ownr_salary_pretax_monthly_indirect.value = total_ownr_salary_pretax_monthly_indirect;
        cards_owners.value.ownr_health_insurance_monthly.value = total_ownr_health_insurance_monthly;
        cards_owners.value.ownr_ira_monthly_indirect.value = total_ownr_ira_monthly_indirect;
        cards_owners.value.ownr_taxes_monthly_indirect.value = total_ownr_taxes_monthly_indirect;



        // -- card total - indirect labor -- //

        total = 0;
        if(total_ownr_salary_pretax_monthly_indirect !== undefined
        && total_ownr_health_insurance_monthly !== undefined 
        && total_ownr_ira_monthly_indirect !== undefined
        && total_ownr_taxes_monthly_indirect !== undefined
        )
        {
            a = total_ownr_salary_pretax_monthly_indirect;
            b = total_ownr_health_insurance_monthly;
            c = total_ownr_ira_monthly_indirect;
            d = total_ownr_taxes_monthly_indirect;

            total = parseFloat(a) + parseFloat(b) + parseFloat(c) + parseFloat(d);

        }

        piePerc = ( parseFloat(total)/parseFloat(grand_total) ) * 100;
        if(isNaN(piePerc)) piePerc = 0;
        piePerc = piePerc.toFixed(1);
        piePerc = parseFloat(piePerc);

        cards_owners.value['total_percent'] = {
    
            name: "Total Monthly (Overhead %)",
            default: 0,
            print: "percent",
            value: piePerc,
            required: true

        };
        cards_owners.value['total'] = {

            name: "Total Monthly (Overhead $)",
            default: 0,
            print: "price",
            value: total,
            required: true

        };

        // -- set pieChart data -- //

        pieSlice = pieChart.items[pieChart.items.length-2];
        if(pieSlice.value !== piePerc)
        {
            pieSlice.name = "Owners";      
            pieSlice.value = piePerc;

        }
        








        //------------------------------------------
        // -- employees - totals - cards 
        //------------------------------------------

        //var total_empl_indirect_salary_yearly;
        var total_empl_indirect_salary_monthly;
        var total_empl_indirect_tax_monthly;
        var total_empl_indirect_ira_monthly;
        var total_empl_health_insurance_monthly;

        total_empl_indirect_salary_monthly = zoneObj.calc_dash.result_items['totals'].total_empl_indirect_salary_monthly;
        total_empl_indirect_tax_monthly = zoneObj.calc_dash.result_items['totals'].total_empl_indirect_tax_monthly;
        total_empl_indirect_ira_monthly = zoneObj.calc_dash.result_items['totals'].total_empl_indirect_ira_monthly;
        total_empl_health_insurance_monthly = zoneObj.calc_dash.result_items['totals'].total_empl_health_insurance_monthly;
   
        //total_empl_indirect_salary_yearly = parseFloat(total_empl_indirect_salary_monthly) * 12;

        //if(isNaN(total_empl_indirect_salary_yearly)) total_empl_indirect_salary_yearly = 0;
        if(isNaN(total_empl_indirect_salary_monthly)) total_empl_indirect_salary_monthly = 0;
        if(isNaN(total_empl_indirect_tax_monthly)) total_empl_indirect_tax_monthly = 0;
        if(isNaN(total_empl_indirect_ira_monthly)) total_empl_indirect_ira_monthly = 0;
        if(isNaN(total_empl_health_insurance_monthly)) total_empl_health_insurance_monthly = 0;
     
        //cards_employees.value.empl_indirect_salary_yearly.value = total_empl_indirect_salary_yearly;
        cards_employees.value.empl_indirect_salary_monthly.value = total_empl_indirect_salary_monthly;
        cards_employees.value.empl_indirect_tax_monthly.value = total_empl_indirect_tax_monthly;      
        cards_employees.value.empl_indirect_ira_monthly.value = total_empl_indirect_ira_monthly;
        cards_employees.value.empl_health_insurance_monthly.value = total_empl_health_insurance_monthly;







        // -- card total - indirect labor -- //

        total = 0;
        if(total_empl_indirect_salary_monthly !== undefined
        && total_empl_indirect_ira_monthly !== undefined
        && total_empl_indirect_tax_monthly !== undefined        
        && total_empl_health_insurance_monthly !== undefined 
        )
        {
            a = total_empl_indirect_salary_monthly;
            b = total_empl_indirect_ira_monthly;
            c = total_empl_indirect_tax_monthly;        
            d = total_empl_health_insurance_monthly;

            total = parseFloat(a) + parseFloat(b) + parseFloat(c) + parseFloat(d);

        }

        piePerc = ( parseFloat(total)/parseFloat(grand_total) ) * 100;
        if(isNaN(piePerc)) piePerc = 0;
        piePerc = piePerc.toFixed(1);
        piePerc = parseFloat(piePerc);

        cards_employees.value['total_percent'] = {
    
            name: "Total Monthly (Overhead %)",
            default: 0,
            print: "percent",
            value: piePerc,
            required: true

        };
        cards_employees.value['total'] = {

            name: "Total Monthly (Overhead $)",
            default: 0,
            print: "price",
            value: total,
            required: true

        };

        // -- set pieChart data -- //

        pieSlice = pieChart.items[pieChart.items.length-1];
        if(pieSlice.value !== piePerc)
        {
            pieSlice.name = "Employees";      
            pieSlice.value = piePerc;

        }
        


    }// -- if

    //console.log("pieChart: "+JSON.stringify(pieChart,null,2));

    //_overhead = VPoverhead;
    //console.log("_overhead B: "+JSON.stringify(_overhead,null,2));


    //console.log("VPoverhead: "+JSON.stringify(VPoverhead,null,2));
    //console.log("cards_overhead: "+JSON.stringify(cards_overhead,null,2));







    //#############################################################
    //#############################################################

    // "empl-1": {
    //     "name": "Employee 1",
    //     "value": {
    //       "empl_vacation_weeks": "2.00",
    //       "empl_direct_indirect_ira_monthly_total": "72.00",
    //       "empl_burden_hours_yearly": "1920.00",
    //       "empl_name": "Employee 1",
    //       "empl_direct_hourly_rate": "18.45",
    //       "empl_labor_direct": "Van Halen,ACDC",
    //       "empl_labor_percent_indirect": "50.00",
    //       "empl_tax_hourly": "3.00",
    //       "empl_indirect_ira_yearly": "450.00",
    //       "empl_hours_indirect": "20.00",
    //       "empl_direct_ira_monthly": "34.50",
    //       "empl_indirect_tax_yearly": "3000.00",
    //       "empl_direct_indirect_tax_monthly_total": "480.00",
    //       "empl_tax_comp_decimal": "0.20",
    //       "empl_hours_direct": "20.00",
    //       "empl_yearly_salary_w_health_insurance": "31812.00",
    //       "empl_tax_comp_percent": "20.00",
    //       "empl_direct_ira_yearly": "414.00",
    //       "empl_direct_hours_yearly": "920.00",
    //       "empl_indirect_hours_yearly": "1000.00",
    //       "empl_working_weeks": "46.00",
    //       "empl_labor_percent": "50.00",
    //       "empl_health_insurance_weekly": "65.48",
    //       "empl_indirect_salary_monthly": "1250.00",
    //       "empl_direct_indirect_ira_yearly_total": "864.00",
    //       "empl_direct_indirect_salary_yearly_total": "28800.00",
    //       "empl_health_insurance_monthly": "251.00",
    //       "empl_direct_salary_monthly": "1150.00",
    //       "empl_indirect_ira_monthly": "37.50",
    //       "empl_direct_tax_yearly": "2760.00",
    //       "empl_billable_hours_daily": "5.00",
    //       "empl_labor_percent_direct": "50.00",
    //       "empl_direct_salary_yearly": "13800.00",
    //       "empl_hourly_rate": "15.00",
    //       "empl_indirect_salary_yearly": "15000.00",
    //       "empl_direct_tax_monthly": "230.00",
    //       "empl_direct_labor_decimal": "0.50",
    //       "empl_health_insurance_yearly": "3012.00",
    //       "empl_ira_decimal": "0.03",
    //       "empl_burden_monthly_total": "3203.00",
    //       "empl_hours_weekly": "40.00",
    //       "empl_ira_percent": "3.00",
    //       "empl_labor_indirect": "Inside Sales,Accounting",
    //       "empl_health_insurance_hourly": "1.64",
    //       "empl_billable_hours_weekly": "20.00",
    //       "empl_ira_hourly": "0.45",
    //       "empl_direct_indirect_salary_monthly_total": "2400.00",
    //       "empl_burden_yearly_total": "38436.00",
    //       "empl_indirect_hourly_rate": "20.09",
    //       "empl_direct_indirect_tax_yearly_total": "5760.00",
    //       "empl_indirect_tax_monthly": "250.00"
    //     }
    // },



    var empl_vacation_weeks = "0";
    var empl_direct_indirect_ira_monthly_total = 0;
    // var empl_burden_hours_yearly;
    // var empl_name;
    var empl_direct_hourly_rate = "0.00";
    var empl_labor_direct = "N/A";
    var empl_labor_percent_indirect = 0;


    //var empl_labor_burden_hourly_rate = "$0.00";
    var empl_indirect_labor_burden_monthly = "$0.00";

    // var empl_tax_hourly;
    // var empl_indirect_ira_yearly;
    var empl_hours_indirect = "0";
    var empl_hours_direct = "0";
    //var empl_yearly_salary_w_health_insurance = "0.00";
    // var empl_tax_comp_percent;
    // var empl_direct_ira_yearly;
    // var empl_direct_hours_yearly;
    // var empl_indirect_hours_yearly;
    // var empl_working_weeks;
    // var empl_labor_percent;
    // var empl_health_insurance_weekly;
    // var empl_indirect_salary_monthly;
    var empl_direct_indirect_ira_yearly_total = 0;
    var empl_direct_indirect_salary_yearly_total = 0;
    var empl_health_insurance_monthly;
    // var empl_direct_salary_monthly;
    // var empl_indirect_ira_monthly;
    // var empl_direct_tax_yearly;
    // var empl_billable_hours_daily;
    var empl_labor_percent_direct = 0;
    // var empl_direct_salary_yearly;
    var empl_hourly_rate = "0.00";
    // var empl_indirect_salary_yearly;
    // var empl_direct_tax_monthly;
    // var empl_direct_labor_decimal;
    var empl_health_insurance_yearly = 0;
    // var empl_ira_decimal;
    // var empl_burden_monthly_total;
    // var empl_hours_weekly;
    // var empl_ira_percent;
    var empl_labor_indirect = "N/A";
    // var empl_health_insurance_hourly;
    var empl_billable_hours_weekly = '0';
    // var empl_ira_hourly;
    // var empl_direct_indirect_salary_monthly_total;
    var empl_burden_yearly_total = "0.00"
    // var empl_indirect_hourly_rate;
    var empl_direct_indirect_tax_yearly_total = 0;
    // var empl_indirect_tax_monthly;
    // var empl_direct_ira_monthly;
    // var empl_indirect_tax_yearly;
    // var empl_direct_indirect_tax_monthly_total;
    // var empl_tax_comp_decimal;


    var empl_flag = '';

  


    var empl_total_hours_weekly = "0.00";




    if(zoneObj.calc_dash)
    {
        for(keyname in zoneObj.calc_dash.result_items)
        {
            object = zoneObj.calc_dash.result_items[keyname];
            // if(keyname.match(/ownr-/gi))
            // {

            //     value = object.value["ownr_health_insurance_monthly"];
            //     value = parseFloat(value);

            //     total_ownr_monthly = total_ownr_monthly + value;

            //     ownr_ledger_value.unshift({

            //         name:object.name,
            //         value:"$"+object.value["ownr_health_insurance_monthly"]

            //     });

            // }//if

            if(keyname.match(/empl-/gi))
            {
                if(object.name === props.find)
                {

                    empl_vacation_weeks                     = object.value.empl_vacation_weeks;
                    empl_direct_indirect_ira_monthly_total  = object.value.empl_direct_indirect_ira_monthly_total;
                    // empl_burden_hours_yearly                = object.value.empl_burden_hours_yearly;
                    // empl_name                               = object.value.empl_name;
                    empl_direct_hourly_rate                 = object.value.empl_direct_hourly_rate;
                    empl_labor_direct                       = object.value.empl_labor_direct;
                    empl_labor_percent_indirect             = object.value.empl_labor_percent_indirect;

                    // empl_tax_hourly                         = object.value.empl_tax_hourly;
                    // empl_indirect_ira_yearly                = object.value.empl_indirect_ira_yearly;
                    empl_hours_indirect                     = object.value.empl_hours_indirect;               
                    empl_hours_direct                       = object.value.empl_hours_direct;

                    empl_indirect_labor_burden_monthly      = object.value.empl_indirect_labor_burden_monthly;


                    // empl_direct_ira_monthly                 = object.value.empl_direct_ira_monthly;
                    // empl_indirect_tax_yearly                = object.value.empl_indirect_tax_yearly;
                    // empl_direct_indirect_tax_monthly_total  = object.value.empl_direct_indirect_tax_monthly_total;
                    // empl_tax_comp_decimal                   = object.value.empl_tax_comp_decimal;
                    //empl_yearly_salary_w_health_insurance   = object.value.empl_yearly_salary_w_health_insurance;
                    // empl_tax_comp_percent                   = object.value.empl_tax_comp_percent;
                    // empl_direct_ira_yearly                  = object.value.empl_direct_ira_yearly;
                    // empl_direct_hours_yearly                = object.value.empl_direct_hours_yearly;
                    // empl_indirect_hours_yearly              = object.value.empl_indirect_hours_yearly;
                    // empl_working_weeks                      = object.value.empl_working_weeks;
                    // empl_labor_percent                      = object.value.empl_labor_percent;
                    // empl_health_insurance_weekly            = object.value.empl_health_insurance_weekly;
                    // empl_indirect_salary_monthly            = object.value.empl_indirect_salary_monthly;
                    empl_direct_indirect_ira_yearly_total   = object.value.empl_direct_indirect_ira_yearly_total;
                    empl_direct_indirect_salary_yearly_total = object.value.empl_direct_indirect_salary_yearly_total;
                    empl_health_insurance_monthly           = object.value.empl_health_insurance_monthly;
                    // empl_direct_salary_monthly              = object.value.empl_direct_salary_monthly;
                    // empl_indirect_ira_monthly               = object.value.empl_indirect_ira_monthly;
                    // empl_direct_tax_yearly                  = object.value.empl_direct_tax_yearly;
                    // empl_billable_hours_daily               = object.value.empl_billable_hours_daily;
                    empl_labor_percent_direct               = object.value.empl_labor_percent_direct;
                    // empl_direct_salary_yearly               = object.value.empl_direct_salary_yearly;
                    empl_hourly_rate                        = object.value.empl_hourly_rate;
                    // empl_indirect_salary_yearly             = object.value.empl_indirect_salary_yearly;
                    // empl_direct_tax_monthly                 = object.value.empl_direct_tax_monthly;
                    // empl_direct_labor_decimal               = object.value.empl_direct_labor_decimal;
                    empl_health_insurance_yearly            = object.value.empl_health_insurance_yearly;
                    // empl_ira_decimal                        = object.value.empl_ira_decimal;
                    // empl_burden_monthly_total               = object.value.empl_burden_monthly_total;
                    // empl_hours_weekly                       = object.value.empl_hours_weekly;
                    // empl_ira_percent                        = object.value.empl_ira_percent;
                    empl_labor_indirect                     = object.value.empl_labor_indirect;
                    // empl_health_insurance_hourly            = object.value.empl_health_insurance_hourly;
                    empl_billable_hours_weekly              = object.value.empl_billable_hours_weekly;
                    // empl_ira_hourly                         = object.value.empl_ira_hourly;
                    // empl_direct_indirect_salary_monthly_total = object.value.empl_direct_indirect_salary_monthly_total;
                    empl_burden_yearly_total                = object.value.empl_burden_yearly_total;
                    // empl_indirect_hourly_rate               = object.value.empl_indirect_hourly_rate;
                    empl_direct_indirect_tax_yearly_total   = object.value.empl_direct_indirect_tax_yearly_total;
                    // empl_indirect_tax_monthly               = object.value.empl_indirect_tax_monthly;
                    

                    empl_flag                               = object.value.empl_flag;


                    break;


                }//if

            }//if

        }//for


    }//==



    empl_hourly_rate = parseFloat(empl_hourly_rate).toFixed(2);
    empl_hourly_rate = "$"+LibNumber.PrintMoney(empl_hourly_rate);

    empl_direct_hourly_rate = parseFloat(empl_direct_hourly_rate).toFixed(2);
    empl_direct_hourly_rate = "$"+LibNumber.PrintMoney(empl_direct_hourly_rate);

    empl_billable_hours_weekly = parseFloat(empl_billable_hours_weekly).toFixed(1);

    empl_labor_percent_direct = parseFloat(empl_labor_percent_direct).toFixed(1);
    empl_labor_percent_indirect = parseFloat(empl_labor_percent_indirect).toFixed(1);


    if(empl_labor_direct === '0.00') empl_labor_direct = "N/A";
    if(empl_labor_indirect === '0.00') empl_labor_indirect = "N/A";

    empl_labor_direct = empl_labor_direct.replace(/,/gi,", ");
    empl_labor_indirect = empl_labor_indirect.replace(/,/gi,", ");


    empl_hours_direct = parseFloat(empl_hours_direct).toFixed(1);
    empl_hours_indirect = parseFloat(empl_hours_indirect).toFixed(1);

    empl_total_hours_weekly = parseFloat(empl_hours_direct) + parseFloat(empl_hours_indirect);
    empl_total_hours_weekly = parseFloat(empl_total_hours_weekly).toFixed(1);



    //TM:
    empl_indirect_labor_burden_monthly = parseFloat(empl_indirect_labor_burden_monthly).toFixed(2);
    empl_indirect_labor_burden_monthly = "$"+LibNumber.PrintMoney(empl_indirect_labor_burden_monthly);



    empl_health_insurance_monthly = parseFloat(empl_health_insurance_monthly).toFixed(2);
    empl_health_insurance_monthly = "$"+LibNumber.PrintMoney(empl_health_insurance_monthly);


    empl_direct_indirect_ira_monthly_total = parseFloat(empl_direct_indirect_ira_monthly_total).toFixed(2);
    empl_direct_indirect_ira_monthly_total = "$"+LibNumber.PrintMoney(empl_direct_indirect_ira_monthly_total);


    empl_vacation_weeks = parseFloat(empl_vacation_weeks).toFixed(0);
  

    empl_direct_indirect_salary_yearly_total = parseFloat(empl_direct_indirect_salary_yearly_total).toFixed(2);
    empl_direct_indirect_salary_yearly_total = "$"+LibNumber.PrintMoney(empl_direct_indirect_salary_yearly_total);

    empl_health_insurance_yearly = parseFloat(empl_health_insurance_yearly).toFixed(2);
    empl_health_insurance_yearly = "$"+LibNumber.PrintMoney(empl_health_insurance_yearly);

    empl_direct_indirect_ira_yearly_total = parseFloat(empl_direct_indirect_ira_yearly_total).toFixed(2);
    empl_direct_indirect_ira_yearly_total = "$"+LibNumber.PrintMoney(empl_direct_indirect_ira_yearly_total);

    empl_direct_indirect_tax_yearly_total = parseFloat(empl_direct_indirect_tax_yearly_total).toFixed(2);
    empl_direct_indirect_tax_yearly_total = "$"+LibNumber.PrintMoney(empl_direct_indirect_tax_yearly_total);


    // empl_yearly_salary_w_health_insurance = parseFloat(empl_yearly_salary_w_health_insurance).toFixed(2);
    // empl_yearly_salary_w_health_insurance = "$"+LibNumber.PrintMoney(empl_yearly_salary_w_health_insurance);

    empl_burden_yearly_total = parseFloat(empl_burden_yearly_total).toFixed(2);
    empl_burden_yearly_total = "$"+LibNumber.PrintMoney(empl_burden_yearly_total);







    //==================================================================


    var empl_call = "even";  
    var empl_graphic_call = 'employee';

    if(empl_labor_percent_direct === empl_labor_percent_indirect)
    {

        //Even
        empl_call = "even";  
        empl_graphic_call = 'empl_even';


    }

    else
    if(empl_labor_percent_direct < 1)
    {

        //Full Indirect
        empl_call = "full_indirect";  
        empl_graphic_call = 'empl_indirect';

        
    }

    else
    if(empl_labor_percent_indirect < 1)
    {

        //Full Direct
        empl_call = "full_direct";  
        empl_graphic_call = 'empl_direct';

        
    }

    else
    if(empl_labor_percent_direct > empl_labor_percent_indirect)
    {
        //Mostly Direct
        empl_call = "mostly_direct";  
        empl_graphic_call = 'empl_direct_indirect';
    }

    else
    if(empl_labor_percent_direct < empl_labor_percent_indirect)
    {

        //Mostly Indirect
        empl_call = "mostly_indirect";  
        empl_graphic_call = 'empl_indirect_direct';

        
    }




    var summary_note = {};
    var summary_ledger_value;
    switch(empl_call)
    {
    case'full_indirect':

        summary_ledger_value = [

            {
                name:"Hourly Rate",
                value:empl_hourly_rate
            },
            {
                name:"Indirect Labor Burden Monthly",
                value:empl_indirect_labor_burden_monthly
            }


        ];



        summary_note['first'] = {


            spacer:{

                height:"50px"
            }


        };
        summary_note['second'] = {


            note:'',
            spacer:{

                height:"0px"
            }
        };


    break;
    default:


        summary_ledger_value = [

            {
                name:"Hourly Rate",
                value:empl_hourly_rate
            },

            {
                name:"Direct Hourly Rate",
                value:empl_direct_hourly_rate
            },
            {
                name:"Direct Hours Per Week",
                value:empl_billable_hours_weekly+" hrs"
            },
            {
                name:"Indirect Labor Burden Monthly",
                value:empl_indirect_labor_burden_monthly
            }



        ];



        summary_note['first'] = {


            spacer:{

                height:"0px"
            }

        };
        summary_note['second'] = {


            note:'Direct Rate =  Rate + Taxes and IRA contributions',
            spacer:{

                height:"50px"
            }
        };



    }








    var R = [];

    // var items = [];

    // keyname = 'empl'; // "empl";

    // if(VPreportDefaults && VPreportDefaults !== undefined)
    // {
    //     for(item of VPreportDefaults[keyname])
    //     {
    //         items.push(item);
    //     }

    // }
    // //console.log("items::::::"+JSON.stringify(items,null,2));


    var reportItems = [


        //-----------------------------------
        // Report Box - Graphic and Ledger
        //-----------------------------------

        {

            "print"		    :'bar',
            "name"		    :'Summary',
            "value"		    :"",
            "required"	    :"",
            "default"	    :""


        },
        {

            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{

                backgroundColor:"transparent",
                height:"15px"
            },
            "required"	    :"",
            "default"	    :""


        },
        {

            "print"		    :'report_box',
            "name"		    :'Report Box',
            "value"		    :[

                {

                    boxCall:'graphic',
                    boxWidth:"35%",
                    boxHeight:200,

                    svgCall:empl_graphic_call,
                    svgWidth:200,
                    svgHeight:200,

                },

                {
                    boxCall:'ledger',
                    boxWidth:"65%",
                    boxHeight:200,
                    value:summary_ledger_value

                }



            ],
            "required"	    :"",
            "default"	    :""


        },
        {

            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{

                backgroundColor:"transparent",
                height:"20px"
            },
            "required"	    :"",
            "default"	    :""


        },





        //-----------------------------------
        // Txt
        //-----------------------------------

        // {

        //     "print"		    :'bar',
        //     "name"		    :'Labor Hours',
        //     "value"		    :"",
        //     "required"	    :"",
        //     "default"	    :""


        // },
        {

            "print"		    :'txt',
            "name"		    :'Txt',
            "value"		    :[

                {

                    // boxCall:'txt',
                    // boxWidth:"100%",
                    // boxHeight:230,

                    className:"Note",
                    txt:'***NOTE: '+empl_flag,
                    style:{

                        //fontSize:"24px",
                        textAlign:"center"

                    }

                }

            ],
            "required"	    :"",
            "default"	    :""


        },
        {

            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{

                backgroundColor:"transparent",
                height:summary_note.first.spacer.height
            },
            "required"	    :"",
            "default"	    :""


        },





        //-----------------------------------
        // Txt
        //-----------------------------------

        // {

        //     "print"		    :'bar',
        //     "name"		    :'Labor Hours',
        //     "value"		    :"",
        //     "required"	    :"",
        //     "default"	    :""


        // },
        {

            "print"		    :'txt',
            "name"		    :'Txt',
            "value"		    :[

                {

                    // boxCall:'txt',
                    // boxWidth:"100%",
                    // boxHeight:230,

                    className:"Note",
                    txt:summary_note.second.note,
                    style:{

                        //fontSize:"24px",
                        textAlign:"center"

                    }

                }

            ],
            "required"	    :"",
            "default"	    :""


        },
        {

            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{

                backgroundColor:"transparent",
                height:summary_note.second.spacer.height
            },
            "required"	    :"",
            "default"	    :""


        },









        //-----------------------------------
        // BarChart - Direct / Indirect
        //-----------------------------------

        //bar
        {

            "print"		    :'bar',
            "name"		    :'Direct / Indirect Labor',
            "value"		    :"",
            "required"	    :"",
            "default"	    :"",

        },



        //spacer
        {

            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{

                backgroundColor:"transparent",
                height:"30px"
            },
            "required"	    :"",
            "default"	    :""

        },



        //chart
        {


            "print"		    :'singleBarChart',
            "name"		    :'The Chart',
            "value"		    :[

                {
                    chartType:"horizontal",
                    chartLines:true,
                    data:[

                        {
                            name:'Direct Labor %',
                            value:"2500.00",
                            percent:empl_labor_percent_direct,
                        },

                        {
                            name:'Indirect Labor %',
                            value:"0.00",
                            percent:empl_labor_percent_indirect,
                        }

                    ]

                }
            ],
            "required"	    :"",
            "default"	    :"",



        },


        //spacer
        {

            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{

                backgroundColor:"transparent",
                height:"25px"
            },
            "required"	    :"",
            "default"	    :""

        },






        //-----------------------------------
        // Ledger
        //-----------------------------------
    
        // {
    
        //     "print"		    :'bar',
        //     "name"		    :'Job Descriptions',
        //     "value"		    :"",
        //     "required"	    :"",
        //     "default"	    :""
    
    
        // },
        {
    
            "print"		    :'ledger',
            "name"		    :"Ledger",
            "value"		    :[
    
    
    
                {
                    name:"Direct",
                    value:empl_labor_direct
    
                },
                {
                    name:"Indirect",
                    value:empl_labor_indirect
    
                }
    
            ],
            "required"	    :"",
            "default"	    :""
    
    
        },
    
        {
    
            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{
    
                backgroundColor:"transparent",
                height:"50px"
            },
            "required"	    :"",
            "default"	    :""
    
    
        },
    







        //-----------------------------------
        // Ledger
        //-----------------------------------

        {

            "print"		    :'bar',
            "name"		    :'Labor Hours Weekly',
            "value"		    :"",
            "required"	    :"",
            "default"	    :""


        },
        {

            "print"		    :'ledger',
            "name"		    :"Ledger",
            "value"		    :[


                {
                    name:"Direct Labor Hours",
                    value:empl_hours_direct+" hrs"
                },
                {
                    name:"Indirect Labor Hours",
                    value:empl_hours_indirect+" hrs"
                },
                {
                    name:"Total Hours",
                    value:empl_total_hours_weekly+" hrs"
                }


            ],
            "required"	    :"",
            "default"	    :""


        },

        {

            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{

                backgroundColor:"transparent",
                height:"50px"
            },
            "required"	    :"",
            "default"	    :""


        },








        //-----------------------------------
        // Ledger
        //-----------------------------------

        {

            "print"		    :'bar',
            "name"		    :'Healthcare',
            "value"		    :"",
            "required"	    :"",
            "default"	    :""


        },
        {

            "print"		    :'ledger',
            "name"		    :"Ledger",
            "value"		    :[


                {
                    name:"Health Insurance Monthly",
                    value:empl_health_insurance_monthly
                },

            ],
            "required"	    :"",
            "default"	    :""


        },

        {

            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{

                backgroundColor:"transparent",
                height:"50px"
            },
            "required"	    :"",
            "default"	    :""


        },





        //-----------------------------------
        // Ledger
        //-----------------------------------

        {

            "print"		    :'bar',
            "name"		    :'IRA',
            "value"		    :"",
            "required"	    :"",
            "default"	    :""


        },
        {

            "print"		    :'ledger',
            "name"		    :"Ledger",
            "value"		    :[


                {
                    name:"Contribution Monthly",
                    value:empl_direct_indirect_ira_monthly_total
                },

            ],
            "required"	    :"",
            "default"	    :""


        },

        {

            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{

                backgroundColor:"transparent",
                height:"50px"
            },
            "required"	    :"",
            "default"	    :""


        },






        //-----------------------------------
        // Ledger
        //-----------------------------------

        {

            "print"		    :'bar',
            "name"		    :'Vacation / Personal Time',
            "value"		    :"",
            "required"	    :"",
            "default"	    :""


        },
        {

            "print"		    :'ledger',
            "name"		    :"Ledger",
            "value"		    :[


                {
                    name:"Weeks",
                    value:empl_vacation_weeks
                }

            ],
            "required"	    :"",
            "default"	    :""


        },

        {

            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{

                backgroundColor:"transparent",
                height:"50px"
            },
            "required"	    :"",
            "default"	    :""


        },







        //-----------------------------------
        // Ledger
        //-----------------------------------

        {

            "print"		    :'bar',
            "name"		    :'Yearly Summary',
            "value"		    :"",
            "required"	    :"",
            "default"	    :""


        },
        {

            "print"		    :'ledger',
            "name"		    :"Ledger",
            "value"		    :[


                {
                    name:"Salary",
                    value: empl_direct_indirect_salary_yearly_total
                },
                {
                    name:"Health Insurance",
                    value:empl_health_insurance_yearly
                },
                {
                    name:"IRA",
                    value:empl_direct_indirect_ira_yearly_total
                },
                {
                    name:"Taxes",
                    value:empl_direct_indirect_tax_yearly_total
                },
                {
                    name:"Yearly Total",
                    value:empl_burden_yearly_total

                }


            ],
            "required"	    :"",
            "default"	    :""


        },

        {

            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{

                backgroundColor:"transparent",
                height:"50px"
            },
            "required"	    :"",
            "default"	    :""


        }




    ];




    R.push({

        call:'report',
        items: reportItems

    });
        
    return R;

}



export default Index;
