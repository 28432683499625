import React, { 

    // useState, 
    // useEffect,
    //useRef

} from 'react';


//import 'app-style/price-form/style.css';
//import '../../StyleSheets/PriceForm.css';
import 'app-components/Zone/_StyleSheets/PriceForm.css';


export const FFbutton = (props) => {


    //var _FUNC = props.data.funct['name'] + "()";
    //var _FUNC = props.data.funct['name'] + "('"+props.data.funct['object']+"')";
    //var _RUN = new Function(_FUNC);

    // const [buttonStyle, setButtonStyle] = useState({});

   //----------------------------------------------------------

    // Note: the empty deps array [] means
    // this useEffect will run once
    // similar to componentDidMount()


    // useEffect(() => {


    //     if(Object.keys(buttonStyle.length === 0)
    //     )
    //     {

    //         setButtonStyle({

    //             width       :props.data.style.width,
    //             fontSize    :props.data.style.fontSize,

    //             bgColor     :props.data.style.bgColorOut,
    //             txtColor    :props.data.style.txtColorOut,
    //             border      :props.data.style.borderOut

    //         });

    //         console.log("buttonStyle: "+JSON.stringify(buttonStyle,null,2));

    //     }



    //  },[props,buttonStyle])





    // const handleSetStyle = (v) => {


    //     switch(v.call)
    //     {
    //     case'over':

    //         setButtonStyle({

    //             width       :props.data.style.width,
    //             fontSize    :props.data.style.fontSize,

    //             bgColor     :props.data.style.bgColorOver,
    //             txtColor    :props.data.style.txtColorOver,
    //             border      :props.data.style.borderOver

    //         });

    //     break;
    //     case'out':

    //         setButtonStyle({

    //             width       :props.data.style.width,
    //             fontSize    :props.data.style.fontSize,

    //             bgColor     :props.data.style.bgColorOut,
    //             txtColor    :props.data.style.txtColorOut,
    //             border      :props.data.style.borderOut

    //         });

    //     break;
    //     default:
    //     }



    // }



    return (

        <div 
        key={props.indx+"_ffbutton"} 
        className = 'FFbutton'
        // style={{

        //     width               :buttonStyle.width,
        //     fontSize            :buttonStyle.fontSize,

        //     backgroundColor     :buttonStyle.bgColor,
        //     color               :buttonStyle.txtColor,
        //     border              :buttonStyle.border

        // }}
        onClick={() => props.handle({

            call        :props.data.call,
            data        :{

                index:props.indx,
                fieldCall:props.data.fieldCall

            }

        })}

        // onMouseEnter={() => handleSetStyle({

        //     call        :"over"

        // })}

        // onMouseOut={() => handleSetStyle({

        //     call        :"out"

        // })}

        >

            {props.data.text}
            
        </div>

    )

}











