import React, { 

    useState, 
    useEffect,
    useRef

} from 'react';


//import 'app-style/price-form/style.css';
//import '../../StyleSheets/Readout.css';
import 'app-components/Zone/_StyleSheets/Readout.css';




//import SVGLogo from '../../../Svgs/logo.svg';
import SVGProfitPie from '../../../Svgs/profit_pie.svg';
import SVGPriceTag from '../../../Svgs/price_tag.svg';
import SVGBreakevenTag from '../../../Svgs/breakeven_tag.svg';

import {

    PROFIT_PIE,
    PRICE_TAG,
    BREAKEVEN_TAG

} from 'app-components/Zone/_SVGComps';




const FFgraphictext = (props) => {



    //=============================================
    // ref
    //=============================================

    const refGraphic = useRef(null);
    const refText = useRef(null); 



    //=============================================
    // state/effect - isLoaded
    //=============================================

    const [isLoaded, setIsLoaded] = useState(false);
    const [svgCompCall, setSvgCompCall] = useState(false);
    const [styling, setStyling] = useState({

        graphic:{

            svg:SVGProfitPie,

            outerColor : false,
            innerColor : false,
            signColor  : false,

            pieceColor : false,
            pieColor   : false
    
        },

        text:{

            height:"auto",
            paddingTop:"0px"
        }

    });

    useEffect(() => {

        var graphicHeight;
        var textHeight; 
        var dif;



        if(!isLoaded)
        {

            setIsLoaded(true);

            var svgComp = "PROFIT_PIE";
            switch(props.data.value.graphic.value)
            {
            case'price_tag':

                svgComp = "PRICE_TAG";
                styling.graphic.svg = SVGPriceTag;
            break;
            case'breakeven_tag':

                svgComp = "BREAKEVEN_TAG";
                styling.graphic.svg = SVGBreakevenTag;
            break;
            default:

                styling.graphic.svg = SVGProfitPie;
            }
            setSvgCompCall(svgComp);


            graphicHeight = refGraphic.current.offsetHeight;
            textHeight = refText.current.offsetHeight;
            if(graphicHeight > textHeight)
            {
                dif = graphicHeight - textHeight;
                styling.text.paddingTop = (dif/2) + "px";
            
            }

            switch(props.data.value.graphic.value)
            {
            case'price_tag':
            case'breakeven_tag':

                styling.graphic.outerColor = props.data.value.graphic.style.outerColor;
                styling.graphic.innerColor = props.data.value.graphic.style.innerColor;
                styling.graphic.signColor = props.data.value.graphic.style.signColor;
            break;
            default:
                styling.graphic.pieceColor = props.data.value.graphic.style.pieceColor;
                styling.graphic.pieColor = props.data.value.graphic.style.pieColor;
            }


            setStyling(styling);


        }else{

     
            // graphicHeight = refGraphic.current.offsetHeight;
            // textHeight = refText.current.offsetHeight;
            // if(graphicHeight > textHeight)
            // {
            //     dif = graphicHeight - textHeight;
            //     styling.text.paddingTop = (dif/2) + "px";
            //     setStyling(styling);

            // }

            // switch(props.data.value.graphic.value)
            // {
            // case'price_tag':
            // case'breakeven_tag':

            //     styling.graphic.outerColor = props.data.value.graphic.style.outerColor;
            //     styling.graphic.innerColor = props.data.value.graphic.style.innerColor;
            //     styling.graphic.signColor = props.data.value.graphic.style.signColor;
            // break;
            // default:
            //     styling.graphic.pieceColor = props.data.value.graphic.style.pieceColor;
            //     styling.graphic.pieColor = props.data.value.graphic.style.pieColor;
            // }

            // // console.log("FFgraphictext - props: "+JSON.stringify(props.data.value.graphic,null,2));



            // console.log("FFgraphictext - styling "+JSON.stringify(styling,null,2));

            
        }
  
    },
    [
        props,
        isLoaded,
        styling
       
    ])






    if(!isLoaded)
    {

        return (

            <div
            key={props.indx+"_ffgraphictext"} 
            className = "graphictext"
            >

                <div
                ref={refGraphic}
                className = "graphic"
                >
                    
                    {/* <div className = "svg">

                        <img
                        id={"svg-"+props.indx}
                        className="icon"
                        src={styling.graphic.svg} 
                        alt="del" />

                    </div> */}


                    <div className = "svg">

                        {
                        svgCompCall === 'PROFIT_PIE'
                        ?

                            <PROFIT_PIE 
        
                                pieceColor = {styling.graphic.pieceColor}
                                pieColor = {styling.graphic.pieColor}

                            />

                        :
                        svgCompCall === 'PRICE_TAG'
                        ?
                            <PRICE_TAG 
                            
                                outerColor = {styling.graphic.outerColor}
                                innerColor = {styling.graphic.innerColor}
                                signColor = {styling.graphic.signColor}
                            
                            />
                        :
                        svgCompCall === 'BREAKEVEN_TAG'
                        ?

                            <BREAKEVEN_TAG 
                            
                                outerColor = {styling.graphic.outerColor}
                                innerColor = {styling.graphic.innerColor}
                                signColor = {styling.graphic.signColor}
                            
                            />


                        :null
                        }


                    </div>


                </div>

                <div
                ref={refText}
                className = "text"
                style={{

                    height:styling.text.height,
                    paddingTop:styling.text.paddingTop

                }}
                >

                    {props.data.value.text.value.map((line, i) => (
                        
                        <div
                        key={i}
                        className = "line"
                        style={{

                            color:line.color
                        }}
                        >
                            {line.text}

                        </div>

                    ))}


                </div>

                
            </div>

        )




    }else{

        return (

            <div
            key={props.indx+"_ffgraphictext"} 
            className = "graphictext"
            >

                <div
                ref={refGraphic}
                className = "graphic"
                >
                    
                    {/* <div className = "svg">

                        <img
                        id={"svg-"+props.indx}
                        className="icon"
                        src={styling.graphic.svg} 
                        alt="del" />

                    </div> */}


                    <div className = "svg">

                        {
                        svgCompCall === 'PROFIT_PIE'
                        ?

                            <PROFIT_PIE 
        
                                pieceColor = {styling.graphic.pieceColor}
                                pieColor = {styling.graphic.pieColor}

                            />

                        :
                        svgCompCall === 'PRICE_TAG'
                        ?
                            <PRICE_TAG 
                            
                                outerColor = {styling.graphic.outerColor}
                                innerColor = {styling.graphic.innerColor}
                                signColor = {styling.graphic.signColor}
                            
                            />
                        :
                        svgCompCall === 'BREAKEVEN_TAG'
                        ?

                            <BREAKEVEN_TAG 
                            
                                outerColor = {styling.graphic.outerColor}
                                innerColor = {styling.graphic.innerColor}
                                signColor = {styling.graphic.signColor}
                            
                            />


                        :null
                        }


                    </div>


                </div>

                <div
                ref={refText}
                className = "text"
                style={{

                    height:styling.text.height,
                    paddingTop:styling.text.paddingTop

                }}
                >

                    {props.data.value.text.value.map((line, i) => (
                        
                        <div
                        key={i}
                        className = "line"
                        style={{

                            color:line.color
                        }}
                        >
                            {line.text}

                        </div>

                    ))}


                </div>

                
            </div>

        )



    }







}


export {
    
    FFgraphictext
};



