import styled from "styled-components";


export const Fly = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#141820",
    width:              props.width || "100%",
    height:             props.height || "100vh",
    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "0 0 0 0",

}))`

    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    height:${props => props.height};
    min-height:250px;

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:absolute;
    top:0px;
    left:0px;
    z-index:7;

    overflow-x:hidden;
    overflow-y:auto;

`;




export const FlyContainer = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#141820",
    width:              props.width || "100%",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "0 0 200px 0",

}))`

    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    height:${props => props.height};
   
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;



`;





export const FlyTray = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#edeef1",
    border:             props.border || "#454d5f",
    borderRadius:       props.borderRadius || "12px",

    width:              props.width || "40px",
    height:             props.height || "auto",
    padding:            props.padding || "10px 10px 10px 10px",
    margin:             props.margin  || "0 0 0 0",

    zIndex:             props.zIndex || "5",
    top:                props.top || "25px",
    right:              props.right  || "15px"



    // writing-mode: vertical-lr;
    // text-orientation: upright;

    // bottom:${props => props.bottom};
    // right:${props => props.right};


}))`

    background-color:${props => props.backgroundColor};

    border-top:${props => props.border};
    border-bottom:${props => props.border};
    border-left:${props => props.border};

    border-top-left-radius:${props => props.borderRadius};
    border-bottom-left-radius:${props => props.borderRadius};


    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:absolute;  
    z-index:${props => props.zIndex};
    top:${props => props.top};
    right:${props => props.right};


    .VerticalTextButton{

        background-color:#0291cd;
        border-radius:2px;

        width:30px;
        height:auto;
        margin:0 5px 0 0;
        padding:7px 0 7px 10px;

        font-family:Arial, Helvetica, sans-serif;      
        font-size: 16px;
        font-weight: normal;
        text-align: center;
        text-decoration:none;
        color:white;

        writing-mode: vertical-lr;
        text-orientation: sideways;


        cursor:pointer;

    }


    .PanelButton{

        background-color:transparent;
        border-radius:2px;

        width:30px;
        height:auto;
        margin:0 4px 0 0;
        padding:9px 0 5px 0;

        font-family:Arial, Helvetica, sans-serif;      
        font-size: 16px;
        font-weight: normal;
        text-align: center;
        text-decoration:none;
        color:black;

        cursor:pointer;

    }






`;





  


