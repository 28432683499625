import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';



import CanvasChart from 'utils/components/Charts/CanvasChart';


import { LibGet } from 'library';


const Index = (props) => {


    // -- ref -- //
    const refA = useRef(null);


    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);
    const [canvasData, setCanvasData] = useState({});
    
    

    useEffect(() => {

        if(!isLoaded)
        {
            setIsLoaded(true);

        }else{

            //setData(props.data);

            var containerWidth;
            var marginTop = "0px";
            var marginLeft = "0px";
            if(props.data.boxHeight 
            && props.data.boxHeight !== undefined
            )
            {
                // var boxHeightInt = props.data.boxHeight;
                // var donutSizeInt = props.data.boxHeight.replace(/px/gi,"");

                // marginTop = (boxHeightInt - donutSizeInt)/2 - (5) + "px";
                // //marginLeft = "calc(50% - "+(refA.current.offsetWidth/2)+"px";
                // //console.log("DonutChart -  marginTop: "+marginTop);

            }

            if(props.lessThanThresh)
            {
                marginTop = "0px";
                marginLeft = "calc(50% - "+(refA.current.offsetWidth/2)+"px)";
                
            }

            containerWidth = "100%";
            marginLeft = "0%";
            if(!props.lessThanThresh)
            {
                containerWidth = "75%";
                marginLeft = "12.5%";
            }

            setData({

                id:props.id,
                margin:marginTop+" 0 0 "+marginLeft,
                width:containerWidth
         
            });


        }
        console.log("chartData: "+JSON.stringify(props.data,null,2));

        console.log("refA width: "+refA.current.offsetWidth);
        console.log("refA height: "+refA.current.offsetHeight);

        console.log("w/h : " + refA.current.offsetHeight / refA.current.offsetWidth);

        console.log("AspectRatio : "+LibGet.AspectRatio(refA.current.offsetWidth,refA.current.offsetHeight));


        var canvData = props.data.value;


        // var chartMarginTop = 15;
        // var heightDif = (chartMarginTop - 5);

        // var canvData = {

        //     backgroundColor:"transparent",
        //     border:"0",
        //     borderRadius:"0",
        //     width:"80%",
        //     margin:chartMarginTop+"px auto 0 auto",
        //     maxHeight:props.data.boxHeight - heightDif,
        //     drawCheckerBoard: false,
        //     chart:{
        //         call:'doughnut',
        //         data: {
     
        //             items: [ 
        
        //                 {
        //                     name:"Classical Music",
        //                     value:20
        //                 },
        //                 {
        //                     name:"Hard Rock",
        //                     value:80
        //                 }
        //             ],
            
        //             colors: [
            
        //                 "#07a2e3",
        //                 "#20b2aa",


        //                 "#fde23e",
        //                 "#f16e23", 
        //                 "#57d9ff",
        //                 "#937e88",
        //                 "#20b2aa",
        //                 "#ccff00",

        //                 "lightblue",
        //                 "lightgreen",
        //                 "yellow",
        //                 "pink",
        //                 "lavender"
            
        //             ],
        //             hole:{
        //                 size:0.6,
        //                 // color:"#191e28",
        //                 // outlineColor:"#191e28",
        //                 color:"#fff",
        //                 outlineColor:"#fff",
        //                 textColor:"#000",
        //                 textSize:"35px"
        //             }


        //         }


        
        //     }


        // }

        setCanvasData(canvData)

     
    },
    [
        props,
        isLoaded
    ])




    if(!isLoaded)
    {
        return (<div ref={refA}></div>)
    }else{
        return (

            <div
            ref={refA}
            style={{
                width:data.width,
                height:"auto",
                margin:data.margin
            }}
            >
                <CanvasChart
   
                    parentId = {props.refid}
                    id = {props.refid+"_canvas"}

                    backgroundColor = {canvasData.backgroundColor}
                    border={canvasData.border}
                    width={canvasData.width}
                    maxHeight={canvasData.maxHeight}
                    margin={canvasData.margin}

                    primeHandle={(v) => props.handle(v)}
                    drawCheckerBoard = {canvasData.drawCheckerBoard}
                    draw = {false}   
                    render = {false}
                    chart = {{
                        call    :canvasData.chart.call,
                        data    :canvasData.chart.data
                    }}

                >
                </CanvasChart>

            </div>
        

        )

    }


}

export default Index;


