import styled from "styled-components";
//import px2vw from "utils/px2vw";


export const Panel = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#141820", //"#191e28", //"#EDEEF1",

    width:              props.width || "550px",
    height:             props.height || "100vh",
    margin:             props.margin || "0 0 0 0",

    zIndex:             props.zIndex || "20", 
    top:                props.top || "0px", 
    right:              props.right || "0px",




    // border-top:1px solid lightgray;
    // border-bottom:1px solid lightgray; 

    // #90ee90 #caffca


    // position:absolute;
    // z-index:${props => props.zIndex};
    // top:${props => props.top};
    // left:${props => props.left};


    // position:absolute;
    // z-index:${props => props.zIndex};
    // top:${props => props.top};
    // right:${props => props.right};


}))`

    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    height:${props => props.height};

    padding:0 0 200px 0;
    margin:${props => props.margin};


    position:relative;
    float:right;

    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-width:1500px;


    @media (max-width: 1000px) {

        width:100%;
        right:0px;
    }
    @media (max-width: 1024px) {

        border:0;
        border-radius:0;

        width:${props => props.width};
        left:${props => props.left};


    }





`;



export const PanelGraphic = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",

    backgroundImage:    props.backgroundImage || "/_resources/logos/tpc_profit_pie_gray.svg",
    backgroundRepeat:   props.backgroundRepeat || "no-repeat",
    backgroundPosition: props.backgroundPosition || "bottom 0px right 100%",
    backgroundSize:     props.backgroundSize || "98vh 98vh",

    transform:          props.transform  || "rotate(0deg)",
    

    width:              props.width || "100%",
    height:             props.height || "auto",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "100px auto 0 auto",
    

    titleFontFamily:         props.titleFontFamily || 'Arial, Helvetica, sans-serif',
    titleFontSize:           props.titleFontSize || "40px",
    titleFontWeight:         props.titleFontWeight  || "550",
    titleTextAlign:          props.titleTextAlign || "center",
    titleTextDecoration:     props.titleTextDecoration  || "none",
    titleTextColor:          props.titleTextColor || "lightgray",


    subtitleFontFamily:         props.subtitleFontFamily || 'Arial, Helvetica, sans-serif',
    subtitleFontSize:           props.subtitleFontSize || "18px",
    subtitleFontWeight:         props.subtitleFontWeight  || "550",
    subtitleTextAlign:          props.subtitleTextAlign || "center",
    subtitleTextDecoration:     props.subtitleTextDecoration  || "none",
    subtitleTextColor:          props.subtitleTextColor || "lightgray",
    
    

    buttonFontFamily:         props.buttonFontFamily || 'Arial, Helvetica, sans-serif',
    buttonFontSize:           props.buttonFontSize || "16px",
    buttonFontWeight:         props.buttonFontWeight  || "500",
    buttonTextAlign:          props.buttonTextAlign || "center",
    buttonTextDecoration:     props.buttonTextDecoration  || "none",
    buttonTextColor:          props.buttonTextColor || "lightgray",


    // background-image:url(${props => props.backgroundImage});
    // background-repeat:${props => props.backgroundRepeat};
    // background-position:${props => props.backgroundPosition};
    // background-size:${props => props.backgroundSize};


    //box-shadow:10px 0px #00cc66;

    // display:block;

}))`


    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    overflow:visible;


    .PanelGraphicSection {

        background-color:transparent;
        width:100%;
        height:auto;
        padding:0 0 0 0;
        margin:0 0 0 0;
        position:relative;
        float:left;
    }


    .PanelGraphicTitle {

        background-color:transparent;
        width:100%;
        height:auto;
        padding:0 0 4px 0;
        margin:0 auto 0 auto;
        position:relative;

        cursor:default;

        font-family:${props => props.titleFontFamily};
        font-size:${props => props.titleFontSize};
        font-weight:${props => props.titleFontWeight};
        text-align:${props => props.titleTextAlign};
        text-decoration:${props => props.titleTextDecoration};
        color:${props => props.titleTextColor};

    }

    .PanelGraphicSubTitle {

        background-color:transparent;
        width:100%;
        height:auto;
        padding:0 0 4px 0;
        margin:0 auto 0 auto;
        position:relative;

        cursor:default;

        font-family:${props => props.subitleFontFamily};
        font-size:${props => props.subtitleFontSize};
        font-weight:${props => props.subtitleFontWeight};
        text-align:${props => props.subtitleTextAlign};
        text-decoration:${props => props.subtitleTextDecoration};
        color:${props => props.subtitleTextColor};

    }





    .PanelGraphicImg {

        background-color:transparent;
        width:250px;
        height:250px;
        padding:0 0 0 0;
        margin:0 auto 0 auto;
        position:relative;
        overflow:visible;
    }


    .PanelGraphicBtn {

        background-color:transparent;
        border-radius:15px;
        width:250px;
        height:auto;
        padding:0 0 0 0;
        margin:7px auto 0 auto;
        position:relative;
        overflow:visible;

        font-family:${props => props.buttonFontFamily};
        font-size:${props => props.buttonFontSize};
        font-weight:${props => props.buttonFontWeight};
        text-align:${props => props.buttonTextAlign};
        text-decoration:${props => props.buttonTextDecoration};
        color:${props => props.buttonTextColor};

        
    }




`;






