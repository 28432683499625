// import { 
//     useSelector, 
//     //useDispatch 
// } 
// from 'react-redux';
// //import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';



//import { Settings } from 'app-components/Prime/Data.js';



import { VPreportDefaults } from 'app-components/Prime/VPdata/reports';


// import { 

//     ChartColorsA, 
//     //ChartColorsB, 
//     //ChartColorsC 

// } from 'utils/components/Charts/ChartColors';


// import { 
//     LibBase, 
//     LibNumber 
// } from "library";


// import ApiResources from 'utils/components/Pages/ApiResources';



import {
    
    Calc

} from 'app-components/Zone/Calculator/Calculate';



const Index = (props) => {



    //-------------------------------------------

    // var apiDomain;
    // var hostname;
    // //var hostname = window.location.hostname;//returns the domain name of the web host
    // var protocol = window.location.protocol;//returns the web protocol used (http: or https:)

    // if(window.location.hostname.match(/192.168/gi))
    // {
    //     hostname = ApiResources.network['3001'].vpc;
    //     apiDomain = protocol + "//" + hostname + "/";
    // }
    // else
    // if(window.location.hostname.match(/localhost/gi))
    // {
    //     hostname = ApiResources.local['3001'].vpc;
    //     apiDomain = protocol + "//" + hostname + "/";
    // }else{
    //     hostname = ApiResources.aws['x'].vpc;
    //     apiDomain = "https://" + hostname + "/";
    // }//=

    //-------------------------------------------

    //console.log("API DOMAIN: "+apiDomain);


    //const apiCall = 'alter-calc-test';
    //const apiCall = 'alter-calc-dash';




    // -- redux -- //
    //const dispatch = useDispatch();
    //const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));


    const zoneObj = props.zoneObj;
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));
    //console.log("props: "+JSON.stringify(props, null, 2));

    var keyname;
    var calc = false;

    var cost_of_goods = [

        {
            "call": "good-1",
            "other": {
            "data": {
                "name": "",
                "brand": ""
            },
            "label": "--"
            },
            "title": "Vinyl",
            "rate": 0,
            "qtyhrs": 0
        }

    ];

    var calcby = {
        "call": "percent",
        "title": "Net Profit",
        "value": "25.0"
    };

    var overhead =  {
        "title": "Overhead",
        "value": "49.1",
        "id": "overhead"
    };

    var debits =  [
        {
            "title": "Commission",
            "value": 0,
            "id": "commission"
        },
        {
            "title": "Fees",
            "value": 0,
            "id": "fees"
        }
    ];

    var quantity = {
        "title": "Quantity",
        "value": "1",
        "id": "quantity"
    };





    if(zoneObj.calcs && zoneObj.calcs !== undefined)
    {


        var calcs = JSON.parse(JSON.stringify(zoneObj.calcs));
        for(keyname in calcs)
        {
            calc = calcs[keyname];
            if(calc.data.name === props.categoryName)
            {
                //alert(calc.data.name);

                if(calc.data.value.cost_of_goods)   cost_of_goods   = calc.data.value.cost_of_goods;
                if(calc.data.value.calcby)          calcby          = calc.data.value.calcby;    
                if(calc.data.value.overhead)        overhead        = calc.data.value.overhead;
                if(calc.data.value.debits)          debits          = calc.data.value.debits;
                if(calc.data.value.quantity)        quantity        = calc.data.value.quantity;
            

                break;

            }

        }

    }
    console.log("calc:::: "+JSON.stringify(calc, null, 2));
    // alert('calc');




    //----------------------------
    //Calculation
    //----------------------------

    var R = [];

    let calculation;
    if(calc)
    {

        var input;
        input = {
    
            cost_of_goods   :cost_of_goods,
            overhead        :overhead,
            debits          :debits,
            calcby          :calcby,
            quantity        :quantity
    
        }
        calculation = Calc({

            input   : input

        });
        console.log("calculation: "+JSON.stringify(calculation, null, 2));





    //============================================================

        //---------------------------
        //Price Percent
        //---------------------------

        var goods_total_amount = "0.00";
        var goods_total_percent = "0.0";

        var overhead_amount = "0.00";
        var overhead_percent = "0.0";

        var commission_amount = "0.00";
        var commission_percent = "0.0";

        var fees_amount = "0.00";
        var fees_percent = "0.0";

        var profit = "0.00";
        var profit_percent = "0.0";


        //----------------------------
        // Selling Price
        //----------------------------

        var grand_total = "0.00";
        var unit_qty = "0";
        var price_each = "0.00";



        //----------------------------
        //Breakeven
        //----------------------------

        var breakeven = "0.00"

        var be_goods_total_amount = "0.00"
        var be_zerobased_overhead_amount = "0.00"
        var be_commission_amount = "0.00"
        var be_fees_amount = "0.00"
        var be_profit = "0.00";




    //===============================================================

        //---------------------------
        //Price Percent
        //---------------------------

        goods_total_amount = calculation.output.goods_total_amount;
        goods_total_percent = calculation.output.goods_total_percent;

        overhead_amount = calculation.output.overhead_amount;
        overhead_percent = calculation.output.overhead_percent;

        commission_amount = calculation.output.commission_amount;
        commission_percent = calculation.output.commission_percent;

        fees_amount = calculation.output.fees_amount;
        fees_percent = calculation.output.fees_percent;

        profit = calculation.output.profit;
        profit_percent = calculation.output.profit_percent;


        //----------------------------
        // Selling Price
        //----------------------------

        grand_total = calculation.output.grand_total;
        unit_qty = calculation.output.unit_qty;
        price_each = calculation.output.price_each;


        //----------------------------
        //Breakeven
        //----------------------------

        breakeven = calculation.output.breakeven;

        be_goods_total_amount = parseFloat(calculation.output.goods_total_amount).toFixed(2);
        be_zerobased_overhead_amount = calculation.output.zerobased_overhead_amount;
        be_commission_amount = calculation.output.commission_amount;
        be_fees_amount = calculation.output.fees_amount;
        be_profit = "0.00";






    //=============================================================

        //---------------------------
        //Price Percent
        //---------------------------

        goods_total_amount = "$" + parseFloat(goods_total_amount).toFixed(2);
        goods_total_percent = parseFloat(goods_total_percent).toFixed(1)

        overhead_amount = "$" + parseFloat(overhead_amount).toFixed(2);
        overhead_percent = parseFloat(overhead_percent).toFixed(1)

        commission_amount = "$" + parseFloat(commission_amount).toFixed(2);
        commission_percent = parseFloat(commission_percent).toFixed(1);

        fees_amount = "$" + parseFloat(fees_amount).toFixed(2);
        fees_percent = parseFloat(fees_percent).toFixed(1)

        profit = "$" + parseFloat(profit).toFixed(2);
        profit_percent = parseFloat(profit_percent).toFixed(1)


        //----------------------------
        // Selling Price
        //----------------------------

        grand_total = "$" + parseFloat(grand_total).toFixed(2);
        unit_qty = unit_qty.toString();
        price_each = "$" + parseFloat(price_each).toFixed(2);



        //----------------------------
        //Breakeven
        //----------------------------

        breakeven = "$" + parseFloat(breakeven).toFixed(2);

        be_goods_total_amount = "$" + parseFloat(be_goods_total_amount).toFixed(2);
        be_zerobased_overhead_amount = "$" + parseFloat(be_zerobased_overhead_amount).toFixed(2);
        be_commission_amount = "$" + parseFloat(be_commission_amount).toFixed(2);
        be_fees_amount = "$" + parseFloat(be_fees_amount).toFixed(2);
        be_profit = "$" + parseFloat(be_profit).toFixed(2);




        // var initCogs = [

        //     {
                    // "call": "good-1",
                    // "other": {
                    // "data": {
                    //     "name": "",
                    //     "brand": ""
                    // },
                    // "label": "--"
                    // },
                    // "rate": "25.00",
                    // "title": "Good 1",
                    // "qtyhrs_rate": 25,
                    // "decimal": 0.007297011727757249,
                    // "qtyhrs": "1.0",
                    // "percent": "0.7",
                    // "isLabor": false
    
        //     },

    
        // ];


        var rate,percent,total;
        var cogs_blox = [];
        for(keyname in calculation.goods)
        {
       
            if(keyname.match(/good-/gi))
            {

                console.log(keyname);

                rate = calculation.goods[keyname].rate; 
                percent = calculation.goods[keyname].percent;            
                total = calculation.goods[keyname].qtyhrs_rate; 

                rate = "$" + parseFloat(rate).toFixed(2);
                percent = parseFloat(percent).toFixed(1);
                total = "$" + parseFloat(total).toFixed(2);
        
                cogs_blox.push({

                    name:calculation.goods[keyname].title,
                    group:[

                        {
                            key:"Qty/Hrs",
                            value:calculation.goods[keyname].qtyhrs
                        },

                        {
                            key:"Rate",
                            value:rate
                        },

                        {

                            key:"Total",
                            value:total
                        },

                        {

                            key:"Percentage",
                            value:percent
                        },

                        {

                            key:"isLabor",
                            value:calculation.goods[keyname].isLabor
                        }
    
                    ]


                });




        
            }
            
        }

        

        var reportItems = [




            //-----------------------------------
            // Report Box - Graphic and Ledger
            //-----------------------------------

            {

                "print"		    :'bar',
                "name"		    :'Summary',
                "value"		    :"",
                "required"	    :"",
                "default"	    :""


            },
            {

                "print"		    :'spacer',
                "name"		    :'',
                "value"		    :{

                    backgroundColor:"transparent",
                    height:"20px"
                },
                "required"	    :"",
                "default"	    :""


            },
            {

                "print"		    :'report_box',
                "name"		    :'Report Box',
                "value"		    :[

                    {

                        boxCall:'graphic',
                        boxWidth:"30%",
                        boxHeight:250,

                        svgCall:"price_tag",
                        svgWidth:230,
                        svgHeight:230,

                    },

                    {
                        boxCall:'ledger',
                        boxWidth:"70%",
                        boxHeight:270,
                        value:[


                            {
                                name:"Selling Price",
                                value:grand_total
                            },


                            {
                                name:"Quantity",
                                value:unit_qty
                            },

                            {
                                name:"Price Each",
                                value:price_each
                            },

                            {
                                name:"Net Profit",
                                value:profit
                            },

                
                            {
                                name:"Net Profit %",
                                value:profit_percent+"%"
                            },


                            {
                                name:"Breakeven",
                                value:breakeven
                
                            }

                        ]

                    }



                ],
                "required"	    :"",
                "default"	    :""


            },
            {

                "print"		    :'spacer',
                "name"		    :'',
                "value"		    :{

                    backgroundColor:"transparent",
                    height:"30px"
                },
                "required"	    :"",
                "default"	    :""


            },













            //-----------------------------------
            // Ledger
            //-----------------------------------

            {

                "print"		    :'bar',
                "name"		    :'Details',
                "value"		    :"",
                "required"	    :"",
                "default"	    :""


            },
            {

                "print"		    :'ledger',
                "name"		    :"Ledger",
                "value"		    :[



                    // {
                    //     name:"Cost Of Goods",
                    //     value:"3.0%",
                    //     percent:"20",
                    //     bullet:{color:"#ff0"}

                    // },
                    // {
                    //     name:"Overhead",
                    //     value:"$1500.00",
                    //     percent:"20",
                    //     bullet:{color:"#f00"}

                    // },
                    // {
                    //     name:"Commission",
                    //     value:"$125.00",
                    //     percent:"20",
                    //     bullet:{color:"orange"}

                    // },
                    // {
                    //     name:"Fees",
                    //     value:"$1500.00",
                    //     percent:"20",
                    //     bullet:{color:"orange"}//#687484

                    // },
                    // {
                    //     name:"Net Profit",
                    //     value:"$1000.00",
                    //     percent:"20",
                    //     bullet:{color:"#00ff00"}

                    // }





                    {
                        name:"Cost Of Goods",
                        value:goods_total_amount,
                        percent:goods_total_percent,
                        //bullet:{color:"#ff0"}

                    },
                    {
                        name:"Overhead",
                        value:overhead_amount,
                        percent:overhead_percent,
                        //bullet:{color:"#f00"}

                    },
                    {
                        name:"Commission",
                        value:commission_amount,
                        percent:commission_percent,
                        //bullet:{color:"orange"}

                    },
                    {
                        name:"Fees",
                        value:fees_amount,
                        percent:fees_percent,
                        //bullet:{color:"orange"}//#687484

                    },
                    {
                        name:"Net Profit",
                        value:profit,
                        percent:profit_percent,
                        //bullet:{color:"#00ff00"}

                    }






                ],
                "required"	    :"",
                "default"	    :"",
                "colors"        : "ChartColorsA"

            },

            {

                "print"		    :'spacer',
                "name"		    :'',
                "value"		    :{

                    backgroundColor:"transparent",
                    height:"50px"
                },
                "required"	    :"",
                "default"	    :""


            },














            //-----------------------------------
            // Ledger
            //-----------------------------------

            {

                "print"		    :'bar',
                "name"		    :'Selling Price',
                "value"		    :"",
                "required"	    :"",
                "default"	    :""


            },
            {

                "print"		    :'ledger',
                "name"		    :"Ledger",
                "value"		    :[


                    {
                        name:"Total Price",
                        value:grand_total
                    },
                    {
                        name:"Quantity",
                        value:unit_qty
                    },
                    {
                        name:"Price Each",
                        value:price_each
                    }


                ],
                "required"	    :"",
                "default"	    :"",
                "colors"        : "ChartColorsA"

            },

            {

                "print"		    :'spacer',
                "name"		    :'',
                "value"		    :{

                    backgroundColor:"transparent",
                    height:"50px"
                },
                "required"	    :"",
                "default"	    :""


            },










            //-----------------------------------
            // Ledger
            //-----------------------------------

            {

                "print"		    :'bar',
                "name"		    :'Breakeven Details',
                "value"		    :"",
                "required"	    :"",
                "default"	    :""


            },
            {

                "print"		    :'ledger',
                "name"		    :"Ledger",
                "value"		    :[



                    // {
                    //     name:"Cost Of Goods",
                    //     value:"3.0%",
                    //     percent:"20",
                    //     bullet:{color:"#fc0"}

                    // },
                    // {
                    //     name:"Overhead at 0% Net Profit",
                    //     value:"$1500.00",
                    //     percent:"20",
                    //     bullet:{color:"#c00"}

                    // },
                    // {
                    //     name:"Commission",
                    //     value:"$125.00",
                    //     percent:"20",
                    //     bullet:{color:"orange"}

                    // },
                    // {
                    //     name:"Fees",
                    //     value:"$1500.00",
                    //     percent:"20",
                    //     bullet:{color:"orange"}//#687484

                    // },
                    // {
                    //     name:"Net Profit",
                    //     value:"$1000.00",
                    //     percent:"20",
                    //     bullet:{color:"#00ce00"}

                    // }



                    {
                        name:"Cost Of Goods",
                        value:be_goods_total_amount,
                        percent:"20",
                        //bullet:{color:"aqua"}

                    },
                    {
                        name:"Overhead at 0% Net Profit",
                        value:be_zerobased_overhead_amount,
                        percent:"20",
                        //bullet:{color:"aqua"}

                    },
                    {
                        name:"Commission",
                        value:be_commission_amount,
                        percent:"20",
                        //bullet:{color:"aqua"}

                    },
                    {
                        name:"Fees",
                        value:be_fees_amount,
                        percent:"20",
                        //bullet:{color:"aqua"}//#687484

                    },
                    {
                        name:"Net Profit",
                        value:be_profit,
                        percent:"20",
                        //bullet:{color:"aqua"}

                    }








                ],
                "required"	    :"",
                "default"	    :"",
                "colors"        : "ChartColorsA"

            },

            {

                "print"		    :'spacer',
                "name"		    :'',
                "value"		    :{

                    backgroundColor:"transparent",
                    height:"50px"
                },
                "required"	    :"",
                "default"	    :""


            },






            //-----------------------------------
            // Blox
            //-----------------------------------

            {

                "print"		    :'bar',
                "name"		    :'Cost Of Goods',
                "value"		    :"",
                "required"	    :"",
                "default"	    :""


            },
            {

                "print"		    :'blox',
                "name"		    :"Blox",
                "value"		    :cogs_blox,
                "required"	    :"",
                "default"	    :"",
                "colors"        : "ChartColorsA"

            },

            {

                "print"		    :'spacer',
                "name"		    :'',
                "value"		    :{

                    backgroundColor:"transparent",
                    height:"50px"
                },
                "required"	    :"",
                "default"	    :""


            },


        ];



        R.push({

            call:'report',
            items: reportItems

        });





    }
    else // default
    {


        var items = [];
        var item;

        keyname = 'calculator'; // "empl";

        if(VPreportDefaults && VPreportDefaults !== undefined)
        {
            for(item of VPreportDefaults[keyname])
            {
                items.push(item);
            }

        }
        //console.log("items::::::"+JSON.stringify(items,null,2));


        R.push({

            call:'report',
            items: items

        });



    }
       
    
    return R;

}



export default Index;
