import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';



import { 
    useSelector, 
    useDispatch 
} 
from 'react-redux';
import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    useHistory,   
    //useParams,


} from "react-router-dom";


import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider';
import useScreenOrientation from 'utils/hooks/ScreenOrientation';
//import { useFetch }  from 'utils/hooks/Fetch';


import {

    Stage,
    ScrollBox,
    Area,
    //AreaTitle

} from '../Styled';





//import Messngr from 'utils/components/Process/Messngr';
import Loader from 'utils/components/Process/Loader';
//import Fetch from 'utils/components/Process/Fetch';

// import Header from "utils/components/Common/Header";
// import HeaderBar from "utils/components/Common/HeaderBar";
// import Footer from "utils/components/Common/Footer";
// import InfoBar from "utils/components/Common/InfoBar";


import DefaultFetchResponse from '../../Default/FetchResponse';


//import StylScene from '../StylModules/Scene.module.css';
import StylRows from '../StylModules/Rows.module.css';

//import SceneBuild from '../SceneBuild';
import SceneInfo from './SceneInfo';


import TxtComponent from 'utils/components/Content/Txt';
import FormComponent from 'utils/components/Form';

/*
import {

    FScomplete, 
    FSerror,
    FSloading,
    //FSmessage,
    //FSprintout,
    FSprocess

} from 'utils/myComponents/Form/FormSubmit';
*/

import Handler from 'utils/components/Form/Handler';

//import FormCompose from './FormCompose';
import FormBuild from './FormBuild';
import {

    //FScomplete, 
    FSerror,
    //FSloading,
    FSprocess

} from './FormSubmit';



import Logo from 'utils/components/Logo';


// import {LibCookie} from 'library';




import useAccessHook from './AccessHook';



export const AuthLogin = (props) => {



    const FormCompose = FormBuild();


    // -- window dims -- //
    const { width, height } = useWindowDimensions();

    // -- screen orientation -- //
    const screenOrientation = useScreenOrientation()


    // -- AccessHook -  -- //
    const accessHook = useAccessHook();
    const accessStatus = accessHook.status;
    const loginAccess = accessHook.loginAccess;
    const handleLoginAccess = accessHook.handle;





    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    const history = useHistory();


    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);




    let zoneId;
    zoneId = pathname.replace(/\//gi,"-");


    // -- redux -- //
    const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));



    let currentZone = pathname;
    currentZone = currentZone.replace(/\//gi,"-");
    currentZone = currentZone.replace(/ /gi,"-");

    //let zoneKey = currentZone + "_fetch";
    


    // -- ref -- //
    const refHeader = useRef(null);
    const refScroll = useRef(null);
    const refArea = useRef(null);

    //const refB = useRef(null);
    //const refC = useRef(null);



    //-------------------------------
    // state/effect - loginAccess
    //-------------------------------
    const [accessRedirect, setAccessRedirect] = useState(false);

    useEffect(() => {


        console.log("accessStatus: "+accessStatus);        
        console.log("loginAccess: "+loginAccess);
        
        if(loginAccess && !accessRedirect)
        {
            console.log("Has Login Access");
            handleLoginAccess({

                call:"redirect",
                url:"https://app.theprofitcalculator.com"
            });
            setAccessRedirect(true);

        }else{
            console.log("No Login Access");

        }

    },
    [
        accessStatus,
        loginAccess,
        handleLoginAccess,
        accessRedirect

    ])





    //-------------------------------
    // state/effect
    //-------------------------------

    const [currentOrientation, setCurrentOrientation] = useState(false);


    const [headerCall] = useState("NA");//A,B,C

    // const [headerCall] = useState(DefaultFetchResponse.settings.appearance.header.headerCall);//A,B,C


    const [isLoaded, setIsLoaded] = useState(false);


    const [scrollAtTop, setScrollAtTop] = useState(true);

    const [scrollboxDif, setScrollboxDif] = useState(50); 
    const [scrollboxOverflow, setScrollboxOverflow] = useState("scroll"); 

    const [offsetClientDif, setOffsetClientDif] = useState(15);
  
    const [viewWidth, setViewWidth] = useState(100);
    // const [viewHeight, setViewHeight] = useState(100);
 
    const [headerSizes, setHeaderSizes] = useState(false);
    // const [headerPlaceholder, setHeaderPlaceholder] = useState(true);
   
    // const [headerBarTop, setHeaderBarTop] = useState(0);   

    const [settings, setSettings] = useState(false);
    const [sceneArr, setSceneArr] = useState([]);



    useEffect(() => {


        var vw;
        //var vh;

        var dif;
        dif = 0;
        if(props.primeProps.primeDif)
        {
            if(props.primeProps.primeDif > 0) 
            {
                dif = props.primeProps.primeDif + 0;
            
            }     

        }
        //alert(dif);
        setScrollboxDif(dif);

        // vh = height - dif + "px";
        // setViewHeight(vh);

        // offsetWidth includes width of scroll bar and clientWidth doesn't
        vw = refScroll.current.clientWidth - 0 + "px";
        setViewWidth(vw);

        var ocDif = refScroll.current.offsetWidth - refScroll.current.clientWidth;
        setOffsetClientDif(ocDif);




        // console.log("Scroll Height Check"
            
        //     +"\nScroll ScrollHeight: "+refScroll.current.scrollHeight
        //     +"\nArea OffetHeight: "+refArea.current.offsetHeight
        // );


        // var hdrBarOut;
        // switch(headerCall)
        // {
        // case'A':

        //     hdrBarOut = "0px";
        // break;
        // case'B':

        //     hdrBarOut = "0px";
        // break;
        // case'C':

        //     hdrBarOut = "0px";
        // break;
        // default:
        //     hdrBarOut = "0px";
        // }

        // if(!scrollAtTop) setHeaderBarTop("0px");
        // else setHeaderBarTop(hdrBarOut);


        var resp;
        var sett;
        if(!isLoaded)
        {
            setIsLoaded(true);


            //----------------------------------
            // Default Scene
            //----------------------------------

            if(sceneArr.length === 0)
            {
                if(props.primeProps.blueprint 
                && props.primeProps.blueprint !== undefined
                )
                {
                    //console.log("blueprint: "+JSON.stringify(props.primeProps.blueprint,null,2));
                    //alert("check blueprint")

                    setSceneArr(SceneInfo);
                }else{
                    setSceneArr(SceneInfo);
                }

            }




            //----------------------------------
            // Default Settings 
            //----------------------------------
          
            if(zoneObj["settings"])
            {
                if(!settings)
                {
                    //alert('settings');
                    resp = zoneObj["settings"];
                    //console.log(resp);
                    //alert(Object.prototype.toString.call(resp));
                    if(resp )
                    {
                        sett = JSON.parse(JSON.stringify(resp));//avoids the react-redux mutation error
                        //console.log(JSON.stringify(sett));
                        setSettings(sett);
                    }

                }


            }else{

                //console.log("headerNav: "+JSON.stringify(DefaultFetchResponse.settings.headerNav));
                //alert('hold')

                setSettings(DefaultFetchResponse.settings);

                props.primeProps.handle({

                    call:'dispatch',
                    data:{

                        action      :'UPDATE_ZONE',
                        key         :'settings',
                        value       :DefaultFetchResponse.settings,
                        
                    },
                    usePrefix:false // will add currentZone to the zoneObj

                });

            }

            // //console.log("HeaderNavInfo: "+JSON.stringify(HeaderNavInfo));
            // setHeaderNavArr(HeaderNavInfo);


        }else{



            // if(zoneObj["settings"] && !settings)
            // {
            //     //alert('settings');
            //     resp = zoneObj["settings"];
            //     // console.log(JSON.stringify(resp,null,2));
            //     // alert(Object.prototype.toString.call(resp));
            //     if(resp && resp !== undefined)
            //     {
            //         sett = JSON.parse(JSON.stringify(resp));//avoids the react-redux mutation error
            //         // console.log("SETTINGS: "+JSON.stringify(sett));
            //         setSettings(sett);
              
            //     }

            // }


            // if(headerCall === 'C')
            // {
            //     let siOne;
            //     siOne = sceneArr[0];
            //     if(siOne.call === 'Billboard')
            //     {
            //         //alert("is ---- "+siOne.call);
            //         setHeaderPlaceholder(false);
            //     }else{
            //         setHeaderPlaceholder(true);
            //     }
    
            // }
            
        }





        if(!currentOrientation)
        {
            setCurrentOrientation(screenOrientation);
        }
        else
        if(currentOrientation !== screenOrientation
        )
        {
            // alert('window.location.reload');
            //window.location.reload(false);

            //console.log('update state method - changes viewWidth to trigger changes below');
            setCurrentOrientation(screenOrientation);
           
        }


        // console.log(""

        //     +"\nwidth..."+width
        //     +"\nheight..."+height

        //     +"\nscreen.width..."+screen.width
        //     +"\nscreen.height..."+screen.height

        //     +"\nscreenAvailW..."+screen.availWidth
        //     +"\nscreenAvailH..."+screen.availHeight

        //     +"\nscreenOrientation..."+screenOrientation


        // ); 
        
    },
    [
        isLoaded,
        props,
        width,height,
        zoneObj,
        pathname,
        currentZone,
        //fetchd,

        refScroll,
        refArea,

        headerCall,
        scrollAtTop,
        settings,
        sceneArr,
        screenOrientation,
        currentOrientation,




    ]);







    // //-------------------------------
    // // state/effect - contentCall
    // //-------------------------------

    // const [contentCall, setContentCall] = useState(false);

    // useEffect(() => {


    //     if(!contentCall || contentCall !== pathname)
    //     {

    //         setContentCall(pathname);

    //     }


    // },
    // [
    //     pathname,
    //     contentCall

    // ])







    

    //-------------------------------
    // state/effect - hashScroll
    //-------------------------------

    const [currentHash, setCurrentHash] = useState(0);
    const [currentScrollPosition, setCurrentScrollPosition] = useState(0);
    const [contentHeight, setContentHeight] = useState(0);


    useEffect(() => {


        // -- effect -- //
 
        const effectHashScroll = (eff) => {


            console.log("Content: eff "+JSON.stringify(eff,null,2));

            var newHash = eff.newHash;

            var pos;
            var scrollbox;
            var row;

            var rowNum; 
            rowNum = newHash.split("#row")[1];
            
            if(document.getElementById("row_"+rowNum)
            && document.getElementById("row_"+rowNum) !== undefined
            )
            {
                scrollbox = document.getElementById("scrollbox");
                row = document.getElementById("row_"+rowNum);  
                
                pos = row.offsetTop;

                console.log(""

                    +"\nscrollbox.scrollHeight: "+parseInt(scrollbox.scrollHeight)


                    +"\nrow.offsestHeight: "+parseInt(row.offsetHeight)
                    +"\nrow.offsetTop: "+parseInt(row.offsetTop)

                    +"\nscrollTop: "+scrollbox.scrollTop
                    // +"\nscrollMax: "+scrollMax
                    // +"\nscrollDif: "+scrollDif 
                    
                    +"\npos: "+pos
                    
                );



                // alert(""
                //     +"\nROW SCROLL H: "+parseInt(scrollbox.scrollHeight)
                //     +"\nROW OFFSET H: "+parseInt(row.offsetHeight)

                //     +"\nscrollTop: "+scrollbox.scrollTop
                //     +"\nscrollMax: "+scrollMax
                //     +"\nscrollDif: "+scrollDif

                //     +"\npos: "+pos
                    
                // );
        

                if(row !== undefined)
                {
                    setCurrentHash(newHash)
                    setCurrentScrollPosition(pos);
                    scrollbox.scrollTo({
                        top: pos, 
                        left: 0, 
                        behavior: 'smooth' 
                    });

                }

            }


        }



        // -- cause -- //

        //console.log('location: '+JSON.stringify(location,null,2));

        var causeHashScroll = false;
        if(contentHeight > 0)
        {
            if(location.hash && location.hash !== undefined)
            {
                if(currentHash !== location.hash)
                {
                    if(location.hash.match(/#row/gi))
                    {
                        causeHashScroll = true;

                    } 
                } 
                else
                if(currentScrollPosition !== document.getElementById("scrollbox").scrollTop)
                {
                    if(location.hash.match(/#row/gi))
                    {
                        causeHashScroll = true;

                    } 
                }  

            }      
        }
        //console.log("causeHashScroll: "+causeHashScroll);
        if(causeHashScroll)
        {
            effectHashScroll({

                newHash:location.hash

            });
        
        }


    },
    [
        location,
        contentHeight,
        currentHash,
        currentScrollPosition
    ])









    // -- handle -- //

    //** Note used for header and headerbar */

    const handle = (hndl) => {


        switch(hndl.call)
        {
        case'headerSizes':

            setHeaderSizes(hndl.data);
            //setHeaderW(hndl.data.groupW);
            //setHeaderH(hndl.data.groupH);

        break;
        case'contentHeight':

            // console.log("contentHeight Check"
            
            //     +"\nScroll OffetHeight: "+refScroll.current.offsetHeight
            //     +"\nScroll ScrollHeight: "+refScroll.current.scrollHeight
            //     +"\ncontentHeight: "+hndl.data.contentHeight
            //     +"\nArea OffetHeight: "+refArea.current.offsetHeight
            // );

            //reset viewWidth 
            var vw = refScroll.current.clientWidth + "px";// offsetWidth includes width of scroll bar and clientWidth doesn't
            //console.log("handleScroll - VW: "+vw);
            setViewWidth(vw);

            if(hndl.data.contentHeight > 0)
            {
                if(hndl.data.contentHeight < refScroll.current.offsetHeight
                || hndl.data.contentHeight === refScroll.current.offsetHeight
                )
                {
                    setScrollboxOverflow("auto");
                }else{
                    setScrollboxOverflow("scroll");
                }

            }//==


            if(hndl.data.contentHeight !== contentHeight)
            {
                setContentHeight(hndl.data.contentHeight);
            }



        break;
        case'scrollup':
        case'scrolldown':


            var pos;
            var scrollbox;
            var row;
            var dif = 0;
            var backToZero = false;

            var scrollMax;
            var scrollDif;



            console.log("HNDL: "+JSON.stringify(hndl,null,2));

            scrollbox = document.getElementById("scrollbox");
            row = document.getElementById(hndl.refid);


            switch(hndl.call)
            {
            case'scrollup':

                pos = row.offsetTop - hndl.scrollTop - dif;
                if(scrollbox.scrollTop === 0)
                {
                    pos = scrollbox.scrollHeight;
                }

            break;
            default:



                scrollMax = (parseInt(scrollbox.scrollHeight) - parseInt(row.offsetHeight));
                scrollDif = scrollMax - scrollbox.scrollTop;

                // alert(""
                //     +"\nROW SCROLL H: "+parseInt(scrollbox.scrollHeight)
                //     +"\nROW OFFSET H: "+parseInt(row.offsetHeight)

                //     +"\nscrollTop: "+scrollbox.scrollTop
                //     +"\nscrollMax: "+scrollMax
                //     +"\nscrollDif: "+scrollDif
                    
                // );

                pos = row.offsetTop + hndl.scrollTop + dif; 
                
                if(scrollbox.scrollTop === scrollMax)
                {
                    backToZero = true;
                }
                if(scrollbox.scrollTop < scrollMax
                && scrollDif < parseInt(row.offsetHeight)
                )
                {
                    backToZero = true; 
                }


                if(backToZero)
                {
                    pos = 0;
                }
    
            }


            //scrollbox.style.scrollBehavior = 'smooth'; //CSS ; scroll-behavior: smooth;
            //scrollbox.scrollTop = hndl.scrollTop;
            scrollbox.scrollTo({
                top: pos, 
                left: 0, 
                behavior: 'smooth' 
            });
            //refA.current.scrollIntoView({behavior: 'smooth'});

            /*
            if(!'scrollBehavior' in document.documentElement.style) 
            {
                //var scrollup_onclick = "javascript:elemlib.ScrollToTop('scrollbox','no','0');";
            }else{
                //var scrollup_onclick = "javascript:elemlib.ScrollToTop('scrollbox','supported','0');";
            }//==
            */




        break;
        default:
        }




    }





    // -- handleScroll -- //

    const handleScroll = () => {


        //console.log("handleScroll... ");

        if(headerSizes)
        {
            var scrollDif;
        
            switch(headerCall)
            {
            case'A':
    
                scrollDif = 0;
                //scrollDif = headerSizes.navH;
            break;
            case'B':

                scrollDif = 0;
            break;
            case'C':

                scrollDif = 0;
            break;
            default:
                scrollDif = 10;
            }



            // console.log("Scroll Stuff"
                    
            //     +"\nscrollDif: "+scrollDif
            //     +"\nscrollTop: "+refScroll.current.scrollTop
            //     +"\neaderSizes: "+JSON.stringify(headerSizes,null,2)
                
            // );



            if(refScroll.current.scrollTop > (scrollDif))
            {
                if(scrollAtTop)
                {
                    
                    // console.log("Show Mini Header"
                    
                    //     +"\nScroll: "+refScroll.current.offsetTop
                    //     +"\nheaderSizes: "+JSON.stringify(headerSizes,null,2)
                        
                    // );

                    //make sure viewWidth is set to clientWidth
                    var vw = refScroll.current.clientWidth + "px";// offsetWidth includes width of scroll bar and clientWidth doesn't
                    //console.log("handleScroll - VW: "+vw);
                    setViewWidth(vw);

                    setScrollAtTop(false);
                }





            }else{

                // console.log("No Mini Header"

                //     +"\nScroll: "+refScroll.current.offsetTop
                //     +"\nheaderHeight: "+headerHeight
                
                // );

                setScrollAtTop(true);

            }


        }


    }





    //================================================
    // state/effect - form
    //================================================

    // -- ref -- //
    const formRef = useRef(null);

    // -- state -- //
    //const [formWidthInt, setFormWidthInt] = useState(450);
    const [hashData, setHashData] = useState(false);
    const [processSubmit, setProcessSubmit] = useState(false); 
    const [processError, setProcessError] = useState(false);   
    //const [processComplete, setProcessComplete] = useState(false); 
    const [formArr, setFormArr] = useState([]); 
    const [problemArr, setProblemArr] = useState([]);
    const [errorType, setErrorType] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);

    useEffect(() => {


        
        if(formArr.length === 0)
        {
            var newArr = [];
            for(var i=0; i < FormCompose.length; i++)
            {
                //console.log(object['section'] + "\n" + JSON.stringify(object['data'],null,2));
                var frm = FormCompose[i];
                newArr.push(frm);
    
            }//== i

            setFormArr(newArr);

        }
        

        //setFormWidthInt(formRef.current.offsetWidth);
        if(hashData === "#!" && location.hash === "")
        {
            setHashData(false);
            setProcessSubmit(false);  
        } 


        //console.log(JSON.stringify(problemArr,null,2));
        //alert('problemArr')




        if(zoneObj['currentZone'] === zoneId)
        {

            if(!zoneObj['problemArr'] 
            || zoneObj['problemArr'] === undefined
            )
            {
                if(problemArr.length > 0
                )
                {
                    //alert('dispatch');
                    dispatch(ModifyZoneStore({
        
                        type:"UPDATE_ZONE",
                        item:{
                            key     :"problemArr",
                            value   :problemArr
                        }
        
                    })); 
                    
                }

            }
            else
            if(zoneObj['problemArr'].length > 0)
            {

                setProblemArr(zoneObj['problemArr']);
                
            }




        }else{



            //alert('dispatch');
            dispatch(ModifyZoneStore({

                type:"UPDATE_ZONE",
                item:{
                    key     :"currentZone",
                    value   :zoneId
                }

            })); 
                   
            
            //alert('dispatch');
            dispatch(ModifyZoneStore({

                type:"DELETE_ZONE",
                item:{
                    key     :"problemArr",
                    value   :[]
                }

            })); 


        }




    },[

        location,
        hashData,
        formRef,
        formArr,
        problemArr,
        dispatch,
        zoneObj,
        zoneId,
        FormCompose
    ])







    const handleForm = (hndl) => {


        // switch(hndl.call)
        // {
        // case'submit':

        //     hndl.call = 'pcocessSubmit';
        // break;
        // case'complete':

        //     hndl.call = 'pcocessComplete';
        // break;
        // default:
        // }



        // if(processSubmit) 
        // {
        //     alert('isSubmitting: '+hndl.call)
        
        // }


        // if(hndl.call === 'processError')
        // {
        //     handleMsg(hndl);
        // }



        hndl['location'] = location;
        hndl['formArr'] = formArr;
        hndl['handleState'] = (v) => {


            //if(v.call === 'formWidthInt') setFormWidthInt(v.value);
            if(v.call === 'hashData') setHashData(v.value);   
            if(v.call === 'processSubmit') setProcessSubmit(v.value);
            if(v.call === 'processError') setProcessError(v.value);
            //if(v.call === 'processComplete') setProcessComplete(v.value);
            if(v.call === 'formArr') setFormArr(v.value);
            if(v.call === 'problemArr')setProblemArr(v.value);
            if(v.call === 'errorType') setErrorType(v.value);
            if(v.call === 'handleDispatch') handleDispatch(v.value);
            if(v.call === 'handleUrl') handleUrl(v.value);
            

        };
        hndl['handleRoute'] = (v) => {
    
            switch(v.call)
            {
            case'goBack':
    
                history.goBack();
            break;
            case'reload':
    
                window.location.reload();//regular dom 
            break;
            case'redirect':
    
                history.replace(v.val);
            break;
            case'push':
    
                history.push(v.val);
            break;
            default:

                var iSaySo = false;
                if(iSaySo) handle();
            }
    
        }

        if(processSubmit && hndl.call === 'clear') 
        {
            //alert('isSubmitting: '+hndl.call)
            setIsProcessing(true);
        }
        else
        if(processError)
        {
            setIsProcessing(false);
        }


        Handler(hndl);

    }







    // const [showMsg,setShowMsg] = useState(false);
    // const [msgData,setMsgData] = useState(false);

    // const handleMsg = (hndl) => {


    //     var array;
    //     switch(hndl.call)
    //     {
    //     case'error':

    //         array = [

    //             {
    //                 "call":"heading",
    //                 "text":"ERROR" 
    //             },
    //             {
    //                 "call":"span",
    //                 "text":hndl.message 
    //             }

    //         ];
    //         setMsgData({

    //             isError:false,
    //             errorType:'',
    //             isTimed:true,
    //             timeDuration:1500,
    //             items:array

    //         });
    //         setShowMsg(true);


    //     break;
    //     case'complete':

    //         // let isError         = (data.isError || false);
    //         // let errorType       = (data.errorType || '');
    //         // let isTimed         = (data.isTimed || true);
    //         // let timedDuration   = (data.timedDuration || 2000);
    //         // let array           = (data.items || defMessage);

    //         array = [

    //             {
    //                 "call":"heading",
    //                 "text":"Message" 
    //             },
    //             {
    //                 "call":"span",
    //                 "text":hndl.message
    //             }

    //         ];
    //         setMsgData({

    //             isError:false,
    //             errorType:'',
    //             isTimed:true,
    //             timeDuration:1500,
    //             items:array

    //         });
    //         setShowMsg(true);


    //     break;
    //     default: 

    //         //alert(hndl);
    //         setShowMsg(false);

    //     }


    // }






    const handleDispatch = (hndl) => {

        //if(hndl.action === 'DELETE_ZONE') alert('delete zone')

        dispatch(ModifyZoneStore({
    
            type:hndl.action,//UPDATE_ZONE or DELETE_ZONE
            item:{
                key     :hndl.key,
                value   :hndl.value
            }

        })); 

    }

    


    const handleUrl = (hndl) => {


        // var url;
        // if(pathname.match(/\//gi)) 
        // {
        //     url = pathname.split("/")[0];
        // }else{
        //     url = pathname;
        // }
        // // url = zoneObj.basename + url + "/" + hndl.url;
        // url = zoneObj.basename + hndl.url;
        

        /*
        alert(""
        +"\nLOC PATHNAME: "+location.pathname
        +"\nPATHNAME: "+pathname
        +"\nHNDL URL: "+hndl.url
        +"\nURL: "+url
        );
        */

       //window.location.href = url;



       console.log(offsetClientDif+"\n"+viewWidth);
       
       history.push(hndl.url);
   
   
       
    } 






    //TM:

    //=============================================
    // handleMovement
    //=============================================

    function handleMovement(hndl) {

        //console.log("hndl.event.key: "+hndl.event.key);

        // var formArr = [];
        // var currentFlyr = false;
        // if(zoneObj['currentFlyr'] && zoneObj['currentFlyr'] !== undefined)
        // {
        //     currentFlyr = zoneObj['currentFlyr'];
        //     formArr = zoneObj[currentFlyr+"_formArr"];
        // }


        // //var activeLabelId = false;
        // var elem = false;
        // var formObj, dataObj;
        // var find;
        // var found = false;
        // //var nextTab = 10000;
        // var count;

        // var ijArr;
        // var el;
        // var fromTop;


        // if(hndl.event.key.toUpperCase() === 'ENTER')
        // {
        //     //console.log(JSON.stringify(formArr,null,2));

        //     if(currentFlyr)
        //     {
    

        //         // console.log("formArr: "+JSON.stringify(formArr,null,2));
        //         // alert("MOVE TO THE NEXT"

        //         //     +"\nhndl.index: "+hndl.index
        //         //     +"\nhndl.section: "+hndl.section
        //         //     +"\nhndl.event.key: "+hndl.event.key
        //         //     +"\nhndl.event.target.id: "+hndl.event.target.id
        //         //     +"\nhndl.event.target.value: "+hndl.event.target.value
        //         //     +"\nhndl.event.target.tabIndex: "+hndl.event.target.tabIndex

        //         // );




        //         //-----------------------------
        //         // find
        //         //-----------------------------

        //         for(formObj of formArr)
        //         {
        //             //console.log("SECTION: "+formObj.section);

        //             if(formObj.section === hndl.section)
        //             {
                        
        //                 if(hndl.section === 'fields')
        //                 {
        //                     for(dataObj of formObj.data)
        //                     {

        //                         if(hndl.event.target.id.match(/phone_/gi))
        //                         {
        //                             //alert(dataObj.label.id +" === "+hndl.event.target.id);
        //                             document.getElementById(hndl.event.target.id).blur();
        //                             find = parseInt(hndl.event.target.tabIndex) + 1;
        //                             break;
                                
        //                         }
        //                         else
        //                         if(dataObj.id === hndl.event.target.id)
        //                         { 
        //                             //alert(dataObj.label.id +" === "+hndl.event.target.id);
        //                             document.getElementById(dataObj.id).blur();
        //                             find = parseInt(hndl.event.target.tabIndex) + 1;
        //                             break;
        //                         }

        //                     }

        //                 }
        //                 else
        //                 if(hndl.section === 'labelfields')
        //                 {
        //                     for(dataObj of formObj.data)
        //                     {
        //                         if(dataObj.field.id === hndl.event.target.id)
        //                         { 
        //                             //alert(dataObj.label.id +" === "+hndl.event.target.id);
        //                             document.getElementById(dataObj.field.id).blur();
        //                             find = parseInt(hndl.event.target.tabIndex) + 1;
        //                             break;
        //                         }

        //                     }

        //                 }
        //                 else
        //                 if(hndl.section === 'editfields')
        //                 {

        //                     for(dataObj of formObj.data)
        //                     {
        //                         //alert(dataObj.field.id +" === "+hndl.event.target.id);          
        //                         if(dataObj.field.id === hndl.event.target.id)
        //                         { 
        //                             document.getElementById(dataObj.field.id).blur();
        //                             find = parseInt(hndl.event.target.tabIndex) + 1;
        //                             break;
        //                         }

        //                     }

        //                 }
        //                 else
        //                 if(hndl.section === 'costfields')
        //                 {
        //                     for(dataObj of formObj.data)
        //                     {
        //                         if(dataObj.label.id === hndl.event.target.id)
        //                         { 
        //                             //alert(dataObj.label.id +" === "+hndl.event.target.id);
        //                             document.getElementById(dataObj.label.id).blur();
        //                             find = parseInt(hndl.event.target.tabIndex) + 1;
        //                             break;
        //                         }
        //                         if(dataObj.rate.id === hndl.event.target.id)
        //                         { 
        //                             document.getElementById(dataObj.rate.id).blur();
        //                             find = parseInt(hndl.event.target.tabIndex) + 1;
        //                             break;
        //                         }
        //                         if(dataObj.qtyhrs.id === hndl.event.target.id)
        //                         {
        //                             document.getElementById(dataObj.qtyhrs.id).blur();
        //                             find = parseInt(hndl.event.target.tabIndex) + 1;
        //                             break;
        //                         }

        //                     }  
                        
        //                 }
        //                 else
        //                 if(hndl.section === 'bluntfields')
        //                 {
        //                     for(dataObj of formObj.data)
        //                     {
        //                         //console.log("dataObj:::"+JSON.stringify(dataObj,null,2));
        //                         if(dataObj.field.id === hndl.event.target.id)
        //                         {
        //                             document.getElementById(dataObj.field.id).blur();
        //                             find = parseInt(hndl.event.target.tabIndex) + 1;
        //                             break;
        //                         }
        //                     }  

        //                 }
        //                 // else
        //                 // if(hndl.section === 'button')
        //                 // {

        //                 //     // found = true;
        //                 //     // break;

        //                 // }


        //             }

        //             if(find) break;
        //         }





        //         //-----------------------------
        //         // found
        //         //-----------------------------

        //         if(find)
        //         {

        //             //alert(find);

        //             count = 0;
        //             for(formObj of formArr)
        //             {
        //                 //console.log("SECTION: "+formObj.section);



        //                 if(formObj.section === 'fields')
        //                 {
        //                     if(Object.prototype.toString.call(formObj.data) === '[object Array]')
        //                     {

        //                         for(dataObj of formObj.data)
        //                         {
        //                             //console.log("dataObj:::"+JSON.stringify(dataObj,null,2));

        //                             if(dataObj)
        //                             {
        //                                 if(parseInt(dataObj.tabIndex) === find)
        //                                 {
        //                                     found = {
        //                                         section:formObj.section,
        //                                         data:dataObj,
        //                                         index:count
        //                                     };
        //                                     break;
        //                                 }

        //                             }

        //                         }  


        //                     }

        //                 }
        //                 else
        //                 if(formObj.section === 'labelfields')
        //                 {
        //                     if(Object.prototype.toString.call(formObj.data) === '[object Array]')
        //                     {

        //                         for(dataObj of formObj.data)
        //                         {
        //                             //console.log("dataObj:::"+JSON.stringify(dataObj,null,2));

        //                             if(dataObj.field)
        //                             {
        //                                 if(parseInt(dataObj.field.tabIndex) === find)
        //                                 {
        //                                     found = {
        //                                         section:formObj.section,
        //                                         data:dataObj.field,
        //                                         index:count
        //                                     };
        //                                     break;
        //                                 }

        //                             }

        //                         }  


        //                     }

        //                 }
        //                 else
        //                 if(formObj.section === 'editfields')
        //                 {
        //                     if(Object.prototype.toString.call(formObj.data) === '[object Array]')
        //                     {

        //                         for(dataObj of formObj.data)
        //                         {
        //                             //console.log("dataObj:::"+JSON.stringify(dataObj,null,2));

        //                             if(dataObj.field)
        //                             {
        //                                 if(parseInt(dataObj.field.tabIndex) === find)
        //                                 {
        //                                     found = {
        //                                         section:formObj.section,
        //                                         data:dataObj.field,
        //                                         index:count
        //                                     };
        //                                     break;
        //                                 }

        //                             }

        //                         }  


        //                     }

        //                 }
        //                 else
        //                 if(formObj.section === 'costfields')
        //                 {

        //                     if(Object.prototype.toString.call(formObj.data) === '[object Array]')
        //                     {

        //                         for(dataObj of formObj.data)
        //                         {

        //                             if(dataObj.label)
        //                             {
        //                                 if(parseInt(dataObj.label.tabIndex) === find)
        //                                 {
        //                                     found = {
        //                                         section:formObj.section,
        //                                         data:dataObj.label,
        //                                         index:count
        //                                     };
        //                                     break;
        //                                 }

        //                             }
        //                             if(dataObj.rate)
        //                             {
        //                                 if(parseInt(dataObj.rate.tabIndex) === find)
        //                                 {
        //                                     found = {
        //                                         section:formObj.section,
        //                                         data:dataObj.rate,
        //                                         index:count
        //                                     };
        //                                     break;
        //                                 }

        //                             }

        //                             if(dataObj.qtyhrs)
        //                             {
        //                                 if(parseInt(dataObj.qtyhrs.tabIndex) === find)
        //                                 {
        //                                     found = {
        //                                         section:formObj.section,
        //                                         data:dataObj.qtyhrs,
        //                                         index:count
        //                                     };
        //                                     break;
        //                                 }


        //                             }



        //                         }  

        //                     }

                        
        //                 }
        //                 else
        //                 if(formObj.section === 'bluntfields')
        //                 {

        //                     if(Object.prototype.toString.call(formObj.data) === '[object Array]')
        //                     {
        //                         for(dataObj of formObj.data)
        //                         {
        //                             //console.log("dataObj:::"+JSON.stringify(dataObj,null,2));

        //                             if(dataObj.field)
        //                             {
        //                                 if(parseInt(dataObj.field.tabIndex) === find)
        //                                 {
        //                                     found = {
        //                                         section:formObj.section,
        //                                         data:dataObj.field,
        //                                         index:count
        //                                     };
        //                                     break;
        //                                 }

        //                             }

        //                         }  

        //                     }

        //                 }
        //                 else
        //                 if(formObj.section === 'button')
        //                 {

        //                     // found = true;
        //                     // break;

        //                 }

        //                 count++;

        //                 if(found) break;

        
        //             }


        //             if(found)
        //             {
        //                 //console.log("found: "+JSON.stringify(found,null,2));

        //                 switch(found.section)
        //                 {
        //                 case'fields':
        //                 case'labelfields':
        //                 case'editfields':

        //                     if(found.data.id)
        //                     {
        //                         //alert("bluntfield: "+JSON.stringify(found.data,null,2));
        //                         elem = document.getElementById(found.data.id);

        //                     }


        //                 break;
        //                 case'costfields':

        //                     if(found.data.id.match(/label/gi))
        //                     { 
        //                         //console.log("costfields label - "+JSON.stringify(found.data,null,2));
        //                         //elem = document.getElementById(found.data.id);
        //                         // activeLabelId = found.data.id;

        //                     }
        //                     if(found.data.id.match(/rate/gi))
        //                     { 
        //                         //console.log("costfields rate - "+JSON.stringify(found.data,null,2));
        //                         elem = document.getElementById(found.data.id);

        //                     }
        //                     if(found.data.id.match(/qtyhrs/gi))
        //                     {
        //                         //console.log("costfields - qtyhrs: "+JSON.stringify(found.data,null,2));
        //                         elem = document.getElementById(found.data.id);

        //                     }

        //                 break;
        //                 case'bluntfields':


        //                     if(found.data.id)
        //                     {
        //                         //alert("bluntfield: "+JSON.stringify(found.data,null,2));
        //                         elem = document.getElementById(found.data.id);

        //                     }

        //                 break;
        //                 default:
        //                 }

        //                 if(elem) 
        //                 {
        //                     elem.focus();
        //                     //console.log("offsetTop: "+elem.offsetTop);

        //                     //alert(elem.id);

        //                     ijArr = hndl.index.split("-");


        //                     switch(found.section)
        //                     {
        //                     case'fields':
        //                     case'labelfields':
        //                     case'editfields':
        //                     case'costfields':

        //                         el = document.getElementById("formSection_"+found.index);
        //                         fromTop = el.offsetTop;
        //                         if(ijArr[1] !== 0)
        //                         {
        //                             fromTop = fromTop + (25 * ijArr[1]);
        //                         }
        //                         //alert(el.offsetTop+" "+fromTop)
        //                         baseRef.current.scrollTop = fromTop; 

        //                     break;
        //                     case'bluntfields':

                            
        //                         el = document.getElementById("formSection_"+found.index);
        //                         if(elem.id === 'calcby') 
        //                         {
        //                             fromTop = el.offsetTop - 150;
        //                             //alert(el.offsetTop+" "+fromTop)
        //                         }else{
        //                             fromTop = el.offsetTop - 40;
        //                             //alert(el.offsetTop+" "+fromTop)
        //                         }
        //                         baseRef.current.scrollTop = fromTop; 
                            

        //                     break;
        //                     default:
        //                     }


        //                 }//==
                    
        //             }//found


        //         }//find


        //         // alert("MOVE TO THE NEXT"

        //         //     +"\nhndl.section: "+hndl.section
        //         //     +"\nhndl.event.key: "+hndl.event.key
        //         //     +"\nhndl.event.target.id: "+hndl.event.target.id
        //         //     +"\nhndl.event.target.value: "+hndl.event.target.value
        //         //     +"\nhndl.event.target.tabIndex: "+hndl.event.target.tabIndex

        //         //     +"\nfind:"+find
        //         //     +"\nfound: "+JSON.stringify(found,null,2)

        //         // );


        //     }//currentFlyr 


        // }//keypress ENTER



        // if(e.key === 'y')
        // {
        // 	alert('The sky is your starting point!')
        // }
        // else 
        // if(e.key === 'n') 
        // {
        //     alert('The sky is your limit👀')
        // }



    }//handleMovement










    if(!isLoaded || accessStatus === 'processing') 
    {

        //return <div className="area-title">Loading...</div>


        return (


            <Stage>

                {/* <HeaderBar
                    
                    handle = {(v) => handle(v)}
                    headerCall={headerCall}                
                    scrollAtTop={scrollAtTop}    
                    width={viewWidth}
                    top={headerBarTop}

                /> */}


                <ScrollBox
                ref={refScroll}
                id={"scrollbox"}
                height={"calc(100% - "+(scrollboxDif)+"px)"}
                overflow={scrollboxOverflow}
                onScroll={handleScroll}
                >



                    {/* <HeaderBar
                        
                        handle = {(v) => handle(v)}
                        databind = {{

                            primeProps      :props.primeProps,
                            headerCall      :headerCall,               
                            scrollAtTop     :scrollAtTop, 
                            width           :viewWidth,
                            top             :headerBarTop,
                            offsetClientDif :offsetClientDif,
                            settings        :settings,
                            
                            headerSizes     :headerSizes,
                            viewHeight      :viewHeight,
                            sceneArr        :sceneArr

                        }}
                        
                                      

                    /> */}


                    <div
                    ref={refHeader}
                    id={'hdr'}
                    style={{
                        backgroundColor:"darkblue",
                        width:"100%",            
                        height:"auto",
                        margin:"0 0 0 0",
                        padding:"0 0 0 0",         
                        position:"relative",
                        floar:"left",

                    }}
                    >

                        {/* <Header 
                            
                            handle = {(v) => handle(v)}
                            databind = {{

                                primeProps  :props.primeProps,
                                headerCall  :headerCall,               
                                scrollAtTop :scrollAtTop, 
                                width       :viewWidth,
                                top         :headerBarTop,
                                placeholder :headerPlaceholder,
                                settings    :settings,
                                
                                headerSizes     :headerSizes,
                                viewHeight      :viewHeight,
                                sceneArr        :sceneArr


                            }}
                            
        

                        /> */}


                    </div>



                    <Area
                    ref={refArea}
                    maxWidth={1680+"px"}
                    width={"96%"}
                    height={"auto"}
                    //backgroundColor={"powderblue"}
                    >

                        <div id={"LoadingNPreset"}>

                            {/* <div 
                            style={{

                                width:"100%",
                                height:"auto",
                                padding:"15px 0 15px 0",
                                margin:"50px auto",
                                textAlign:"center",
                                color:"black"
                            }}
                            >

                                Loading...
                          
                            </div> */}


                            <Loader 
                                call={"pageLoader"}
                                litedark={"dark"}
                            />
 
                            {sceneArr.map((row, i) => (

                                <div
                                key={i}
                                >
                                    <div
                                    //ref={setRowRef}
                                    id={"row_"+i}
                                    >
                                    </div>

                                </div>

                            ))}

                        </div>

                    </Area>


                </ScrollBox>



            </Stage>




        )



    } 
    else 
    {



        return (<div>



            {/* {showMsg
            ? 

                <Messngr

                    data={msgData}
                    handle  ={(v) => handleForm(v)}  
                /> 


            : null} */}


            {processSubmit 
            ? 
                <div>

                    {/* <FSloading

                        formArr={formArr}
                        handle={(v) => props.handle(v)} // prime.handle  
                    />  */}

                    <FSprocess

                        basename = {zoneObj.basename}
                        formArr ={formArr}
                        handle  ={(v) => handleForm(v)}  

                    /> 

                </div>

            : null}


            {processError 
            ? 
                <FSerror

                    errorType={errorType}
                    errorArr={problemArr}
                    handle={(v) => handleForm(v)} // prime.handle  
            
                /> 

            : null}


            {/* {processComplete 
            ? 
                <FScomplete

                    formArr={formArr}
                    handle={(v) => handleForm(v)}   
            
                /> 

            : null} */}




            <Stage>

                {/* <HeaderBar
                    
                    handle = {(v) => handle(v)}
                    headerCall={headerCall}                
                    scrollAtTop={scrollAtTop}    
                    width={viewWidth}
                    top={headerBarTop}

                /> */}


                <ScrollBox
                ref={refScroll}
                id={"scrollbox"}
                height={"calc(100% - "+(scrollboxDif)+"px)"}
                overflow={scrollboxOverflow}
                onScroll={handleScroll}
                >

                    {/* <HeaderBar
                        
                        handle = {(v) => handle(v)}
                        databind = {{

                            primeProps      :props.primeProps,
                            headerCall      :headerCall,               
                            scrollAtTop     :scrollAtTop, 
                            width           :viewWidth,
                            top             :headerBarTop,
                            offsetClientDif :offsetClientDif,
                            settings        :settings,

                            headerSizes     :headerSizes,
                            viewHeight      :viewHeight,
                            sceneArr        :sceneArr


                        }}

                        
                    /> */}

                    <div
                    ref={refHeader}
                    id={'hdr'}
                    style={{
                        backgroundColor:"transparent",
                        width:"100%",            
                        height:"auto",
                        margin:"0 0 0 0",
                        padding:"0 0 0 0",         
                        //position:"relative",
                        //floar:"left",

                    }}
                    >
                        {/* <Header 
                        
                            handle = {(v) => handle(v)}
                            databind = {{

                                primeProps  :props.primeProps,
                                headerCall  :headerCall,               
                                scrollAtTop :scrollAtTop, 
                                width       :viewWidth,
                                top         :headerBarTop,
                                placeholder :headerPlaceholder,
                                settings    :settings,

                                headerSizes     :headerSizes,
                                viewHeight      :viewHeight,
                                sceneArr        :sceneArr

                            }}


                            // headerCall={headerCall}                
                            // scrollAtTop={scrollAtTop}    
                            // width={viewWidth}
                            // top={headerBarTop}
                            // placeholder={headerPlaceholder} 
                            

                        /> */}


                        <Logo 
                            
                            call="theprofitcalculator"
                        
                        />



                    </div>


                    <Area
                    ref={refArea}
                    //backgroundColor={'lightgreen'}
                    >



                    {
                    processSubmit || isProcessing
                    ? 
                        <div>

                            <Loader 

                                call={"pageLoader"}
                                litedark={"dark"}
                                text={"Logging In..."}
    
                            />

                        </div>

                    :





                        <div
                        className={StylRows.Rows}
                        >
                            {SceneInfo.map((row, i) => (

                                <div
                                key={i}
                                >

                                    {
                                    row.styl.call === 'indent' 
                                    ? 

                                        row.styl.class === 'IndentRowB' 
                                        ? 
                                            <div
                                            id={"row_"+i}
                                            className={StylRows.IndentRowB}
                                            style={{

                                                //backgroundColor:"red",
                                                marginTop:row.styl.marginTop,
                                                marginBottom:row.styl.marginBottom,
                                                minHeight:"150px"
                                                
                                            }}
                                            >


                                                {

                                                row.call === 'TxtComponent' 
                                                ? 
                                                    <TxtComponent

                                                        refid       = {"pagerow_"+i}    
                                                        info        = {row.info}  
                                                        items       = {row.items}       
                                                        handle      = {(v) => props.handle(v)} 
                                                        

                                                    />

                                                :
                                                row.call === 'FormComponent' 
                                                ? 
                                                    <FormComponent
                        
                                                        refid       = {"pagerow_"+i}    
                                                        info        = {row.info}  
                                                        items       = {formArr} 
                                                        handle      = {(v) => handleForm(v)} 
                                                        problemArr  = {problemArr}


                                                        handleMovement = {(v) => handleMovement(v)} 

                                                    />

                                                :

                                                    <div>No Row</div>

                                                }  


                                            

                                            </div>



                                        ://========== Indent Row 

                                            <div
                                            id={"row_"+i}
                                            className={StylRows.IndentRow}
                                            style={{

                                                //backgroundColor:"blue",
                                                marginTop:row.styl.marginTop,
                                                marginBottom:row.styl.marginBottom,
                                                minHeight:"150px"
                                                
                                            }}
                                            >

                                                {

                                                row.call === 'TxtComponent' 
                                                ? 
                                                    <TxtComponent

                                                        refid       = {"pagerow_"+i}    
                                                        info        = {row.info}  
                                                        items       = {row.items}       
                                                        handle      = {(v) => props.handle(v)} 
                                                        

                                                    />

                                                :
                                                row.call === 'FormComponent' 
                                                ? 
                                                    <FormComponent
                        
                                                        refid       = {"pagerow_"+i}    
                                                        info        = {row.info}  
                                                        items       = {formArr} 
                                                        handle      = {(v) => handleForm(v)} 
                                                        problemArr  = {problemArr}

                                                        handleMovement = {(v) => handleMovement(v)} 


                                                    />

                                                :

                                                    <div>No Row</div>

                                                }  


                                            </div>




                                    ://========= FULL ROW


                                        <div
                                        id={"row_"+i}
                                        className={StylRows.FullRow}
                                        style={{

                                            //backgroundColor:"green",
                                            marginTop:row.styl.marginTop,
                                            marginBottom:row.styl.marginBottom,
                                            minHeight:"150px"
                                            
                                        }}
                                        >
                                            {

                                            row.call === 'TxtComponent' 
                                            ? 
                                                <TxtComponent

                                                    refid       = {"pagerow_"+i}    
                                                    info        = {row.info}  
                                                    items       = {row.items}       
                                                    handle      = {(v) => props.handle(v)} 
                                                    

                                                />

                                            :
                                            row.call === 'FormComponent' 
                                            ? 
                                                <FormComponent
                    
                                                    refid       = {"pagerow_"+i}    
                                                    info        = {row.info}  
                                                    items       = {formArr} 
                                                    handle      = {(v) => handleForm(v)} 
                                                    problemArr  = {problemArr}

                                                    handleMovement = {(v) => handleMovement(v)} 

                                                />

                                            :

                                                <div>No Row</div>

                                            }  


                                        </div>


                                    }


                                </div>



                            ))}

                        </div>





                    }

                    </Area>

                </ScrollBox>

            </Stage>

        </div>)


    }


}



