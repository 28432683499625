import styled from "styled-components";


export const Report = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "white",
    border:             props.border || "1px solid lightgray",

    width:              props.width || "100%",
    height:             props.height || "auto",



    margin:             props.margin || "40px 0 0 0",
    padding:            props.padding || "0 0 25px 0",

    minHeight:          props.minHeight || "150px"

}))`


    background-color:${props => props.backgroundColor};
  
    width:${props => props.width};
    max-width:700px;


    height:${props => props.height};
    min-height:${props => props.minHeight};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:visible;


    @media (max-width: 1100px) {

        width:90%;
        margin:25px 0 25px 5%;

    }

    @media (max-width: 600px) {

        width:calc(100% - 20px);
        margin:25px 0 25px 10px;

    }



`;



//#############################################################
//#############################################################


export const ReportRow = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "1px solid lightgray",

    width:              props.width || "100%",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "0 0 0 0"

}))`


    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;


`;






//#############################################################
//#############################################################


export const ReportTop = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "1px solid lightgray",

    width:              props.width || "100%",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 10px 0",
    padding:            props.padding || "0 0 0 0"

}))`


    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;





`;




    export const ReportTopName = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",
        border:             props.border || "1px solid lightgray",

        width:              props.width || "auto",
        height:             props.height || "auto",
        margin:             props.margin || "0 0 0 0",
        padding:            props.padding || "0 0 0 0",



        fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
        fontSize:           props.fontSize || "40px",
        fontWeight:         props.fontWeight  || "550",
        textAlign:          props.textAlign || "left",
        textDecoration:     props.textDecoration  || "none",
        color:              props.color || "black"


    }))`


        background-color:${props => props.backgroundColor};

        width:${props => props.width};
        height:${props => props.height};
  
        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;
        float:left;

        overflow:hidden;

        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        text-align:${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};


        @media (max-width: 475px) {

            font-size:30px;
            margin-top:7px;
        }


    `;



    export const ReportTopControls = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",
        border:             props.border || "1px solid lightgray",

        width:              props.width || "auto",
        height:             props.height || "auto",
        margin:             props.margin || "0 0 0 0",
        padding:            props.padding || "0 0 0 0",


        fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
        fontSize:           props.fontSize || "18px",
        fontWeight:         props.fontWeight  || "550",
        textAlign:          props.textAlign || "center",
        textDecoration:     props.textDecoration  || "none",
        color:              props.color || "white",


        buttonMinWidth:     props.buttonMinWidth || "125px"

    }))`


        background-color:${props => props.backgroundColor};

        width:${props => props.width};
        height:${props => props.height};

        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;
        float:right;

        overflow:hidden;




        .Button {

            background-color:#191e28;
            border:1px solid #191e28;
            border-radius:7px;
    
            min-width:${props => props.buttonMinWidth};
            width:auto;
            height:auto;
            padding:9px 15px 8px 15px;
            margin:4px 0 0 0;
            position:relative;
            float:right;
    
            cursor:pointer;
    
            font-family:${props => props.fontFamily};
            font-size:${props => props.fontSize};
            font-weight:${props => props.fontWeight};
            text-align:${props => props.textAlign};
            text-decoration:${props => props.textDecoration};
            color:${props => props.color};
    
            &:hover{
    
                background-color:#fc0;
                color:black;
            
            }



            @media (max-width: 475px) {

                font-size:16px;
                margin-top:7px;
                min-width:100px;
            }


    
    
    
        }
    
        .ButtonMask {
    
            background-color:transparent;
    
            width:30px;
            height:30px;
            padding:0 0 0 0;
            margin:0 0 0 0;
            position:absolute;
            top:0px;
            left:0px;
            z-index:1;
    
            cursor:pointer;
    
        }
    
    
        .Img {
    
            width:30px;
            height:30px;
    
        }
    


    `;





    export const ReportTopGraphic = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",
        border:             props.border || "1px solid lightgray",
    
        width:              props.width || "auto",
        height:             props.height || "auto",
        margin:             props.margin || "0 0 0 0",
        padding:            props.padding || "0 0 0 0",
    
    
    }))`
    
    
        background-color:${props => props.backgroundColor};
    
        width:${props => props.width};
        height:${props => props.height};
    
        padding:${props => props.padding};
        margin:${props => props.margin};
    
        position:relative;
        float:right;
    
        overflow:hidden;
    

        .Img {
        
            width:${props => props.width};
            height:${props => props.height};
    
        }
    
    
    `;
    








//#############################################################
//#############################################################


export const ReportBar = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#EDEEF1",
    border:             props.border || "1px solid lightgray",

    width:              props.width || "calc(100% - 2px)",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "0 0 0 0",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "18px",
    fontWeight:         props.fontWeight  || "550",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration  || "none",
    color:              props.color || "black",



    nameWidth:          props.nameWidth || "calc(70% - 10px)",
    valueWidth:         props.valueWidth || "calc(49% - 11px)",
    actionsWidth:       props.actionsWidth || "calc(25% - 11px)",



    leftWidth:          props.leftWidth || "calc(30% - 10px)",
    rightWidth:         props.rightWidth || "calc(69% - 11px)",



}))`


    background-color:${props => props.backgroundColor};
    border:${props => props.border};

    width:${props => props.width};
    height:${props => props.height};
 
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;



    .Name {

        background-color:transparent;

        width:${props => props.nameWidth};
        height:auto;
        padding:13px 0 11px 10px;
        margin:0 0 0 0;

        position:relative;
        float:left;

        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        text-align:${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};




    }

    .Value {

        background-color:transparent;
        border-left:1px solid lightgray;

        width:${props => props.valueWidth};
        height:auto;
        padding:13px 0 11px 10px;
        margin:0 0 0 0;

        position:relative;
        float:right;

        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        text-align:${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};



    }




    .LineName {

        background-color:transparent;

        width:${props => props.nameWidth};
        height:auto;
        padding:13px 0 11px 10px;
        margin:0 0 0 0;

        position:relative;
        float:left;

        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        text-align:${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};


        @media (max-width: 800px) {

            width:calc(100% - 180px);
        }

        @media (max-width: 475px) {

            width:calc(100% - 160px);
        }


    }


    .Actions {

        background-color:transparent;
        border-left:1px solid lightgray;

        width:${props => props.actionsWidth};
        height:auto;
        padding:13px 0 11px 10px;
        margin:0 0 0 0;

        position:relative;
        float:right;

        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        text-align:${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};


        @media (max-width: 800px) {

            width:115px;
        }

        @media (max-width: 475px) {

            width:115px;
        }



    }





    .Left {

        background-color:transparent;

        width:${props => props.leftWidth};
        height:auto;
        padding:13px 0 11px 10px;
        margin:0 0 0 0;

        position:relative;
        float:left;

        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        text-align:${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};




    }

    .Right {

        background-color:transparent;
    
        width:${props => props.rightWidth};
        height:auto;
        padding:13px 0 11px 0;
        margin:0 0 0 15px;

        position:relative;
        float:left;

        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        text-align:${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};



    }





`;







//#############################################################
//#############################################################


export const ReportMessage = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "white",
    border:             props.border || "1px solid lightgray",

    width:              props.width || "calc(100% - 2px)",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "26px 0 24px 0",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "24px",
    fontWeight:         props.fontWeight  || "500",
    textAlign:          props.textAlign || "center",
    textDecoration:     props.textDecoration  || "none",
    textColor:          props.textColor || "#454d5f"


    // border-bottom:${props => props.border};
    // border-left:${props => props.border};
    // border-right:${props => props.border};



}))`


    background-color:${props => props.backgroundColor};


    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;


    font-family:${props => props.fontFamily};
    font-size:${props => props.fontSize};
    font-weight:${props => props.fontWeight};
    text-align:${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.textColor};


    cursor:pointer;


    @media (max-width: 300px) {

        height:80px;
    }




`;







//#############################################################
//#############################################################


export const ReportSection = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "white",
    border:             props.border || "1px solid lightgray",

    width:              props.width || "100%",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "0 0 0 0",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "20px",
    fontWeight:         props.fontWeight  || "500",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration  || "none",
    textColor:          props.textColor || "black",


    spacerBackgroundColor:    props.spacerBackgroundColor || "transparent",
    spacerWidth:              props.spacerWidth || "100%",
    spacerHeight:             props.spacerHeight || "auto",


    // border-bottom:${props => props.border};
    // border-left:${props => props.border};
    // border-right:${props => props.border};


}))`


    background-color:${props => props.backgroundColor};


    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;


    font-family:${props => props.fontFamily};
    font-size:${props => props.fontSize};
    font-weight:${props => props.fontWeight};
    text-align:${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.textColor};


    cursor:default;

    @media (max-width: 300px) {

        height:80px;
    }





    .Spacer {

        background-color:${props => props.spacerBackgroundColor};
        width:${props => props.spacerWidth};
        height:${props => props.spacerHeight};

        margin:0 0 0 0;
        padding:0 0 0 0;

        position:relative;
        float:left;
    
    }



`;





//#############################################################
//#############################################################


export const ReportBox = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "1px solid lightgray",

    width:              props.width || "calc(50% - 0px)",
    height:             props.height || "auto",
    minHeight:          props.minHeight || "25px",

    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "0 0 0 0",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "20px",
    fontWeight:         props.fontWeight  || "500",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration  || "none",
    textColor:          props.textColor || "black"



    // border-bottom:${props => props.border};
    // border-left:${props => props.border};
    // border-right:${props => props.border};




    // cursor:pointer;

    // @media (max-width: 300px) {

    //     height:80px;
    // }

    // &:hover {

    //     background-color:#caffca;
    //     text-decoration:underline;
    // }





}))`


    background-color:${props => props.backgroundColor};


    width:${props => props.width};
    height:${props => props.height};
    min-height:${props => props.minHeight};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;


    font-family:${props => props.fontFamily};
    font-size:${props => props.fontSize};
    font-weight:${props => props.fontWeight};
    text-align:${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.textColor};



`;








//#############################################################
//#############################################################


export const ReportGraphic = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "1px solid lightgray",

    width:              props.width || "100%",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "0 0 0 0",


}))`


    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;


    .Img {
    
        width:${props => props.width};
        height:${props => props.height};

    }


`;




//#############################################################
//#############################################################


export const ReportTxt = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "1px solid lightgray",

    width:              props.width || "calc(100% - 30px)",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 15px",
    padding:            props.padding || "0 0 0 0",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "24px",
    fontWeight:         props.fontWeight  || "500",
    textAlign:          props.textAlign || "center",
    textDecoration:     props.textDecoration  || "none",
    textColor:          props.textColor || "#454d5f",





    headingFontFamily:         props.headingFontFamily || 'Arial, Helvetica, sans-serif',
    headingFontSize:           props.headingFontSize || "24px",
    headingFontWeight:         props.headingFontWeight  || "500",
    headingTextAlign:          props.headingTextAlign || "left",
    headingTextDecoration:     props.headingTextDecoration  || "none",
    headingTextColor:          props.headingTextColor || "#454d5f",


    pgraphFontFamily:         props.pgraphFontFamily || 'Arial, Helvetica, sans-serif',
    pgraphFontSize:           props.pgraphFontSize || "16px",
    pgraphFontWeight:         props.pgraphFontWeight  || "500",
    pgraphTextAlign:          props.pgraphTextAlign || "left",
    pgraphTextDecoration:     props.pgraphTextDecoration  || "none",
    pgraphTextColor:          props.pgraphTextColor || "#454d5f",


    noteFontFamily:         props.noteFontFamily || 'Arial, Helvetica, sans-serif',
    noteFontSize:           props.noteFontSize || "13px",
    noteFontWeight:         props.noteFontWeight  || "500",
    noteTextAlign:          props.noteTextAlign || "left",
    noteTextDecoration:     props.noteTextDecoration  || "none",
    noteTextColor:          props.noteTextColor || "#454d5f",


    bigFontFamily:         props.bigFontFamily || 'Arial, Helvetica, sans-serif',
    bigFontSize:           props.bigFontSize || "100px",
    bigFontWeight:         props.bigFontWeight  || "550",
    bigTextAlign:          props.bigTextAlign || "center",
    bigTextDecoration:     props.bigTextDecoration  || "none",
    bigTextColor:          props.bigTextColor || "#454d5f",

    xLargeFontFamily:         props.xLargeFontFamily || 'Arial, Helvetica, sans-serif',
    xLargeFontSize:           props.xLargeFontSize || "80px",
    xLargeFontWeight:         props.xLargeFontWeight  || "550",
    xLargeTextAlign:          props.xLargeTextAlign || "center",
    xLargeTextDecoration:     props.xLargeTextDecoration  || "none",
    xLargeTextColor:          props.xLargeTextColor || "#454d5f",

    largeFontFamily:         props.largeFontFamily || 'Arial, Helvetica, sans-serif',
    largeFontSize:           props.largeFontSize || "60px",
    largeFontWeight:         props.largeFontWeight  || "550",
    largeTextAlign:          props.largeTextAlign || "center",
    largeTextDecoration:     props.largeTextDecoration  || "none",
    largeTextColor:          props.largeTextColor || "#454d5f",
    
    mediumFontFamily:         props.mediumFontFamily || 'Arial, Helvetica, sans-serif',
    mediumFontSize:           props.mediumFontSize || "50px",
    mediumFontWeight:         props.mediumFontWeight  || "550",
    mediumTextAlign:          props.mediumTextAlign || "center",
    mediumTextDecoration:     props.mediumTextDecoration  || "none",
    mediumTextColor:          props.mediumTextColor || "#454d5f",

    smallFontFamily:         props.smallFontFamily || 'Arial, Helvetica, sans-serif',
    smallFontSize:           props.smallFontSize || "20px",
    smallFontWeight:         props.smallFontWeight  || "550",
    smallTextAlign:          props.smallTextAlign || "center",
    smallTextDecoration:     props.smallTextDecoration  || "none",
    smallTextColor:          props.smallTextColor || "#454d5f"



    // border-bottom:${props => props.border};
    // border-left:${props => props.border};
    // border-right:${props => props.border};


}))`


    background-color:${props => props.backgroundColor};


    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;


    font-family:${props => props.fontFamily};
    font-size:${props => props.fontSize};
    font-weight:${props => props.fontWeight};
    text-align:${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.textColor};


    cursor:default;



    .Heading {


        width:100%;
        height:auto;
        margin:0 auto 0 auto;


        font-family:${props => props.headingFontFamily};
        font-size:${props => props.headingFontSize};
        font-weight:${props => props.headingFontWeight};
        text-align:${props => props.headingTextAlign};
        text-decoration:${props => props.headingTextDecoration};
        color:${props => props.headingTextColor};


    }

    .Pgraph {

        width:100%;
        height:auto;
        margin:0 auto 0 auto;

        font-family:${props => props.pgraphFontFamily};
        font-size:${props => props.pgraphFontSize};
        font-weight:${props => props.pgraphFontWeight};
        text-align:${props => props.pgraphTextAlign};
        text-decoration:${props => props.pgraphTextDecoration};
        color:${props => props.pgraphTextColor};
    

        line-height:1.5;
     
        
    }

    .Note {

        width:100%;
        height:auto;
        margin:10px auto 0 auto;

        font-family:${props => props.noteFontFamily};
        font-size:${props => props.noteFontSize};
        font-weight:${props => props.noteFontWeight};
        text-align:${props => props.noteTextAlign};
        text-decoration:${props => props.noteTextDecoration};
        color:${props => props.noteTextColor};


        line-height:1.4;
     
     
    }



    .Big {

        background-color:transparent;

        width:100%;
        height:auto;
        margin:0 auto 0 auto;

        font-family:${props => props.bigFontFamily};
        font-size:${props => props.bigFontSize};
        font-weight:${props => props.bigFontWeight};
        text-align:${props => props.bigTextAlign};
        text-decoration:${props => props.bigTextDecoration};
        color:${props => props.bigTextColor};

    }



    .XLarge {

        background-color:transparent;

        width:100%;
        height:auto;
        margin:0 auto 0 auto;

        font-family:${props => props.xLargeFontFamily};
        font-size:${props => props.xLargeFontSize};
        font-weight:${props => props.xLargeFontWeight};
        text-align:${props => props.xLargeTextAlign};
        text-decoration:${props => props.xLargeTextDecoration};
        color:${props => props.xLargeTextColor};

    }


    .Large {

        background-color:transparent;

        width:100%;
        height:auto;
        margin:0 auto 0 auto;

        font-family:${props => props.largeFontFamily};
        font-size:${props => props.largeFontSize};
        font-weight:${props => props.largeFontWeight};
        text-align:${props => props.largeTextAlign};
        text-decoration:${props => props.largeTextDecoration};
        color:${props => props.largeTextColor};

    }


    .Medium {

        background-color:transparent;
    
        width:100%;
        height:auto;
        margin:0 auto 0 auto;
    
        font-family:${props => props.mediumFontFamily};
        font-size:${props => props.mediumFontSize};
        font-weight:${props => props.mediumFontWeight};
        text-align:${props => props.mediumTextAlign};
        text-decoration:${props => props.mediumTextDecoration};
        color:${props => props.mediumTextColor};
    
    }



    .Small {

        background-color:transparent;
    
        width:100%;
        height:auto;
        margin:0 auto 0 auto;
    
        font-family:${props => props.smallFontFamily};
        font-size:${props => props.smallFontSize};
        font-weight:${props => props.smallFontWeight};
        text-align:${props => props.smallTextAlign};
        text-decoration:${props => props.smallTextDecoration};
        color:${props => props.smallTextColor};
    
    }
    
    



    @media (max-width: 700px) {

        .Big {

            background-color:transparent;
            font-size:80px;
    
        }


        .XLarge {

            background-color:transparent;
            font-size:60px;
    
        }


        .Large {

            background-color:transparent;
            font-size:40px;
    
        }


        .Medium {

            background-color:transparent;
            font-size:40px;
    
        }

        .Small {

            background-color:transparent;
            font-size:20px;
    
        }



    }
    





`;







//#############################################################
//#############################################################


export const ReportLineItem = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "white",
    border:             props.border || "1px solid lightgray",

    width:              props.width || "calc(100% - 17px)",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "16px 0 14px 15px",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "20px",
    fontWeight:         props.fontWeight  || "500",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration  || "none",
    textColor:          props.textColor || "black"


}))`


    background-color:${props => props.backgroundColor};
    border-bottom:${props => props.border};
    border-left:${props => props.border};
    border-right:${props => props.border};

    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;


    font-family:${props => props.fontFamily};
    font-size:${props => props.fontSize};
    font-weight:${props => props.fontWeight};
    text-align:${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.textColor};


    cursor:pointer;


    @media (max-width: 300px) {

        height:80px;
    }



    &:hover {

        background-color:#caffca;
        text-decoration:underline;
    }



`;






//#############################################################
//#############################################################



export const ReportBlox = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "white",

    width:              props.width || "100%",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 10px 10px",
    padding:            props.padding || "0 0 0 0",


}))`

    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};


    position:relative;
    float:left;

    overflow:hidden;


`;



    export const ReportBlock = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "white",
        border:             props.border || "1px solid lightgray",

        width:              props.width || "calc(100% - 2px)",
        height:             props.height || "auto",
        margin:             props.margin || "0 0 0 0",
        padding:            props.padding || "0 0 0 0",


        fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
        fontSize:           props.fontSize || "22px",
        fontWeight:         props.fontWeight  || "550",
        textAlign:          props.textAlign || "left",
        textDecoration:     props.textDecoration  || "none",
        color:              props.color || "black",



        lineFontFamily:         props.lineFontFamily || 'Arial, Helvetica, sans-serif',
        lineFontSize:           props.lineFontSize || "16px",
        lineFontWeight:         props.lineFontWeight  || "500",
        lineTextAlign:          props.lineTextAlign || "left",
        lineTextDecoration:     props.lineTextDecoration  || "none",
        lineTextColor:          props.lineTextColor || "black",



    }))`


        background-color:${props => props.backgroundColor};
        border-right:${props => props.border};
        border-left:${props => props.border};
        border-bottom:${props => props.border};




        width:${props => props.width};
        height:${props => props.height};

        min-height:60px;

        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;
        float:left;

        overflow:hidden;



        .Name {


            background-color:lightyellow;
            border-bottom:${props => props.border};

            width:calc(100% - 15px);
            height:auto;
            padding:15px 0 13px 15px;
            margin:0 10px 7px 0;
            position:relative;
            float:left;

            font-family:${props => props.fontFamily};
            font-size:${props => props.fontSize};
            font-weight:${props => props.fontWeight};
            text-align:${props => props.textAlign};
            text-decoration:${props => props.textDecoration};
            color:${props => props.color};


        }



        .Group {

            background-color:transparent;

            width:80%;
            height:auto;

            padding:0 0 7px 0;
            margin:0 0 10px 30px;

            position:relative;
            float:left;

        }

            .Group .Key {

                background-color:transparent;

                width:150px;
                height:auto;
                padding:4px 7px 4px 0;
                margin:4px 0 0 0;
                position:relative;
                float:left;

                font-family:${props => props.lineFontFamily};
                font-size:${props => props.lineFontSize};
                font-weight:550;
                text-align:${props => props.keyTextAlign};
                text-decoration:${props => props.lineTextDecoration};
                color:${props => props.lineTextColor};



            }


            .Group .Value {

                background-color:transparent;

                width:calc(100% - 200px);
                height:auto;
                padding:4px 7px 4px 0;
                margin:4px 0 0 0;
                position:relative;
                float:left;

                font-family:${props => props.lineFontFamily};
                font-size:${props => props.lineFontSize};
                font-weight:${props => props.lineFontWeight};
                text-align:${props => props.lineTextAlign};
                text-decoration:${props => props.lineTextDecoration};
                color:${props => props.lineTextColor};




            }


            @media (max-width: 375px) {

               
                .Group {

                    width:calc(100% - 15px);
                    margin:0 0 10px 15px;
        
                }
        
                    .Group .Key {
        
                        width:100px;
                    }
    
                    .Group .Value {
        
                        width:calc(100% - 125px);

    
                    }

            }


    `;









//#############################################################
//#############################################################


export const ReportLedger = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "white",

    width:              props.width || "calc(100% - 20px)",
    height:             props.height || "auto",
    margin:             props.margin || "10px 0 10px 10px",
    padding:            props.padding || "0 0 0 0",


}))`

    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};


    position:relative;
    float:left;

    overflow:hidden;


`;









//#############################################################
//#############################################################


export const ReportBullet = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "gray",
    border:             props.border || "0",
    borderRadius:       props.borderRadius || "26px",

    width:              props.width || "24px",
    height:             props.height || "24px",
    margin:             props.margin || "10px 0 0 0",
    padding:            props.padding || "0 0 0 0",

    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "18px",
    fontWeight:         props.fontWeight  || "500",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration  || "none",
    color:              props.color || "black",


	// width: 50px;
	// height: 50px;
	// padding:0 0 0 0;
	// margin:14px 0 0 15px;
	// float:left;		
	// overflow:hidden;

	// border-radius: 25px;
	// -webkit-border-radius: 25px;
	// -moz-border-radius: 25px;



}))`


        background-color:${props => props.backgroundColor};
        border:${props => props.border};
        border-radius:${props => props.borderRadius};

        width:${props => props.width};
        height:${props => props.height};
    
        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;
        float:left;

        overflow:hidden;



        .Text {

            background-color:transparent;

            width:auto;
            height:auto;
            padding:11px 10px 4px 10px;
            margin:0 10px 0 0;
            position:relative;
            float:left;

            cursor:pointer;

            font-family:${props => props.fontFamily};
            font-size:${props => props.fontSize};
            font-weight:${props => props.fontWeight};
            text-align:${props => props.textAlign};
            text-decoration:${props => props.textDecoration};
            color:${props => props.color};

            &:hover{

                color:#191e28;
                text-decoration:underline;

            }


        }


`;




//#############################################################
//#############################################################


export const ReportPercent = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "2px solid black",
    borderRadius:       props.borderRadius || "18px",

    width:              props.width || "70px",
    height:             props.height || "auto",
    margin:             props.margin || "12px 7px 0 7px",
    padding:            props.padding || "0 0 0 0",

    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "18px",
    fontWeight:         props.fontWeight  || "500",
    textAlign:          props.textAlign || "center",
    textDecoration:     props.textDecoration  || "none",
    color:              props.color || "black",


	// width: 50px;
	// height: 50px;
	// padding:0 0 0 0;
	// margin:14px 0 0 15px;
	// float:left;		
	// overflow:hidden;

	// border-radius: 25px;
	// -webkit-border-radius: 25px;
	// -moz-border-radius: 25px;



}))`


        background-color:${props => props.backgroundColor};
        border-right:${props => props.border};
        border-left:${props => props.border};
        border-radius:${props => props.borderRadius};

        width:${props => props.width};
        height:${props => props.height};
    
        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;
        float:left;

        overflow:hidden;



        .Text {

            background-color:transparent;

            width:100%;
            height:auto;
            padding:0 0 0 0;
            margin:0 0 0 0;
            position:relative;
            float:left;

            font-family:${props => props.fontFamily};
            font-size:${props => props.fontSize};
            font-weight:${props => props.fontWeight};
            text-align:${props => props.textAlign};
            text-decoration:${props => props.textDecoration};
            color:${props => props.color};


        }


`;






//#############################################################
//#############################################################


export const ReportItem = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "1px solid gray",

    width:              props.width || "100%",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 7px 0",
    padding:            props.padding || "0 0 0 0",


    // border:${props => props.border};


    // @media (max-width: 575px) {

    //     height:80px;
    // }



}))`


    background-color:${props => props.backgroundColor};
    border-bottom:${props => props.border};


    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;


`;




    export const ReportItemName = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",
        border:             props.border || "1px solid lightgray",

        width:              props.width || "calc(30% - 12px)",
        height:             props.height || "auto",
        margin:             props.margin || "0 0 0 0",
        padding:            props.padding || "0 0 0 0",

        fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
        fontSize:           props.fontSize || "18px",
        fontWeight:         props.fontWeight  || "500",
        textAlign:          props.textAlign || "left",
        textDecoration:     props.textDecoration  || "none",
        color:              props.color || "black",


        linkFontFamily:         props.linkFontFamily || 'Arial, Helvetica, sans-serif',
        linkFontSize:           props.linkFontSize || "18px",
        linkFontWeight:         props.linkFontWeight  || "500",
        linkTextAlign:          props.linkTextAlign || "center",
        linkTextDecoration:     props.linkTextDecoration  || "underline",
        linkTextColor:          props.linkTextColor || "#07a2e3"


    }))`


        background-color:${props => props.backgroundColor};
   
        width:${props => props.width};
        height:${props => props.height};
     
        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;
        float:left;

        overflow:hidden;

 


        .Text {

            background-color:transparent;
    
            width:auto;
            height:auto;
            padding:11px 10px 4px 10px;
            margin:0 10px 0 0;
            position:relative;
            float:left;
    
            cursor:pointer;
    
            font-family:${props => props.fontFamily};
            font-size:${props => props.fontSize};
            font-weight:${props => props.fontWeight};
            text-align:${props => props.textAlign};
            text-decoration:${props => props.textDecoration};
            color:${props => props.color};
    
            &:hover{
    
                color:#191e28;
                text-decoration:underline;
    
            }
    

        }



        .PlainText {

            background-color:transparent;
    
            width:auto;
            height:auto;
            padding:11px 0px 4px 0px;
            margin:0 10px 0 0;
            position:relative;
            float:left;
    
            cursor:default;
    
            font-family:${props => props.fontFamily};
            font-size:${props => props.fontSize};
            font-weight:${props => props.fontWeight};
            text-align:${props => props.textAlign};
            text-decoration:${props => props.textDecoration};
            color:${props => props.color};


            word-wrap: break-word;

    

        }


        .LinkText {

            background-color:transparent;
    
            width:auto;
            height:auto;
            padding:11px 10px 4px 10px;
            margin:0 10px 0 0;
            position:relative;
            float:left;
    
            cursor:pointer;
    
            font-family:${props => props.linkFontFamily};
            font-size:${props => props.linkFontSize};
            font-weight:${props => props.linkFontWeight};
            text-align:${props => props.linkTextAlign};
            text-decoration:${props => props.linkTextDecoration};
            color:${props => props.linkTextColor};
    
            &:hover{
    
                color:#191e28;
                text-decoration:underline;
    
            }
    

        }






    `;



    export const ReportItemValue = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",
        border:             props.border || "1px solid lightgray",

        width:              props.width || "calc(49% - 1px)",
        height:             props.height || "auto",
        margin:             props.margin || "0 0 0 0",
        padding:            props.padding || "0 0 0 0",


        fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
        fontSize:           props.fontSize || "18px",
        fontWeight:         props.fontWeight  || "500",
        textAlign:          props.textAlign || "right",
        textDecoration:     props.textDecoration  || "none",
        color:              props.color || "black",


        buttonFontFamily:         props.buttonFontFamily || 'Arial, Helvetica, sans-serif',
        buttonFontSize:           props.buttonFontSize || "16px",
        buttonFontWeight:         props.buttonFontWeight  || "500",
        buttonTextAlign:          props.buttonTextAlign || "center",
        buttonTextDecoration:     props.buttonTextDecoration  || "underline",
        buttonTextColor:          props.buttonTextColor || "#07a2e3"


        //border-left:${props => props.border};


    }))`


        background-color:${props => props.backgroundColor};


        width:${props => props.width};
        height:${props => props.height};

        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;
        float:right;

        overflow:hidden;


        .Text {

            background-color:transparent;
    
            width:auto;
            height:auto;
            padding:11px 10px 4px 10px;
            margin:0 10px 0 0;
            position:relative;
            float:left;
    
            cursor:pointer;
    
            font-family:${props => props.fontFamily};
            font-size:${props => props.fontSize};
            font-weight:${props => props.fontWeight};
            text-align:${props => props.textAlign};
            text-decoration:${props => props.textDecoration};
            color:${props => props.color};
    
            &:hover{
    
                color:#191e28;
                text-decoration:underline;
    
            }
    

        }


        .PlainText {

            background-color:transparent;
    
            width:calc(100% - 0px);
            height:auto;
            padding:11px 0 4px 0;
            margin:0 0 0 0;
            position:relative;
            float:left;
    
            cursor:default;
    
            font-family:${props => props.fontFamily};
            font-size:${props => props.fontSize};
            font-weight:${props => props.fontWeight};
            text-align:${props => props.textAlign};
            text-decoration:${props => props.textDecoration};
            color:${props => props.color};


            word-wrap: break-word;
    
    
        }




        .ValueText {

            background-color:transparent;
    
            width:calc(100% - 25px);
            height:auto;
            padding:11px 10px 4px 10px;
            margin:0 10px 0 0;
            position:relative;
            float:left;
    
            cursor:default;
    
            font-family:${props => props.fontFamily};
            font-size:${props => props.fontSize};
            font-weight:${props => props.fontWeight};
            text-align:${props => props.textAlign};
            text-decoration:${props => props.textDecoration};
            color:${props => props.color};


            word-wrap: break-word;
    
    
        }



        .Button {

            background-color:transparent;
    
            width:40px;
            height:auto;
            padding:9px 0 6px 0;
            margin:0 0 0 0;

            position:absolute;
            right:7px;
            bottom:2px;
            z-index:2;
    
            cursor:pointer;
    
            font-family:${props => props.buttonFontFamily};
            font-size:${props => props.buttonFontSize};
            font-weight:${props => props.buttonFontWeight};
            text-align:${props => props.buttonTextAlign};
            text-decoration:${props => props.buttonTextDecoration};
            color:${props => props.buttonTextColor};


            &:hover{
        
                color:#191e28;
            
            }

        }











    `;




    export const ReportItemLineName = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",
        border:             props.border || "1px solid lightgray",

        width:              props.width || "70%",
        height:             props.height || "100%",
        margin:             props.margin || "0 0 0 0",
        padding:            props.padding || "0 0 0 0",

        fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
        fontSize:           props.fontSize || "18px",
        fontWeight:         props.fontWeight  || "500",
        textAlign:          props.textAlign || "left",
        textDecoration:     props.textDecoration  || "none",
        color:              props.color || "black"

    }))`


        background-color:${props => props.backgroundColor};

        width:${props => props.width};
        height:${props => props.height};
     
        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;
        float:left;

        overflow:hidden;



        @media (max-width: 800px) {

            width:calc(100% - 180px);
        }

        @media (max-width: 475px) {

            width:calc(100% - 140px);
        }
    




        .Text {

            background-color:transparent;
    
            width:auto;
            height:auto;
            padding:11px 10px 4px 10px;
            margin:0 10px 0 0;
            position:relative;
            float:left;
    
            cursor:pointer;
    
            font-family:${props => props.fontFamily};
            font-size:${props => props.fontSize};
            font-weight:${props => props.fontWeight};
            text-align:${props => props.textAlign};
            text-decoration:${props => props.textDecoration};
            color:${props => props.color};
    
            &:hover{
    
                color:#191e28;
                text-decoration:underline;
    
            }
    

        }



        .PlainText {

            background-color:transparent;
    
            width:auto;
            height:auto;
            padding:11px 10px 4px 10px;
            margin:0 10px 0 0;
            position:relative;
            float:left;
    
            cursor:default;
    
            font-family:${props => props.fontFamily};
            font-size:${props => props.fontSize};
            font-weight:${props => props.fontWeight};
            text-align:${props => props.textAlign};
            text-decoration:${props => props.textDecoration};
            color:${props => props.color};


            word-wrap: break-word;
    

        }





    `;




    export const ReportItemControls = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",
        border:             props.border || "1px solid lightgray",

        width:              props.width || "calc(25% - 1px)",
        height:             props.height || "100%",
        margin:             props.margin || "0 0 0 0",
        padding:            props.padding || "0 0 0 0",


        fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
        fontSize:           props.fontSize || "16px",
        fontWeight:         props.fontWeight  || "550",
        textAlign:          props.textAlign || "left",
        textDecoration:     props.textDecoration  || "none",
        color:              props.color || "black"


    }))`


        background-color:${props => props.backgroundColor};
        border-left:${props => props.border};

        width:${props => props.width};
        height:${props => props.height};

        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;
        float:right;

        overflow:hidden;


        @media (max-width: 800px) {

            width:125px;
        }
    




        .Button {

            background-color:transparent;
    
            width:auto;
            height:35px;
            padding:0 4.5px 0 4.5px;
            margin:2.5px 0 0 0;
            position:relative;
            float:left;
    
            cursor:pointer;
    
    
            font-family:${props => props.fontFamily};
            font-size:${props => props.fontSize};
            font-weight:${props => props.fontWeight};
            text-align:${props => props.textAlign};
            text-decoration:${props => props.textDecoration};
            color:${props => props.color};
    
            &:hover{
    
                color:#191e28;
    
            }
    
    
    
        }
    
        .ButtonMask {
    
            background-color:transparent;
    
            width:35px;
            height:35px;
            padding:0 0 0 0;
            margin:0 0 0 0;
            position:absolute;
            top:0px;
            left:0px;
            z-index:1;
    
            cursor:pointer;
    
        }
    
    
        .Img {
    
            width:35px;
            height:35px;
    
        }
    




    `;











//#############################################################
//#############################################################


export const ReportAct = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "1px solid lightgray",

    width:              props.width || "100%",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "0 0 0 0",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "24px",
    fontWeight:         props.fontWeight  || "500",
    textAlign:          props.textAlign || "center",
    textDecoration:     props.textDecoration  || "none",
    textColor:          props.textColor || "#454d5f",



    spacerMarginBottom:         props.spacerMarginBottom || '20px',



    headingFontFamily:         props.headingFontFamily || 'Arial, Helvetica, sans-serif',
    headingFontSize:           props.headingFontSize || "20px",
    headingFontWeight:         props.headingFontWeight  || "500",
    headingTextAlign:          props.headingTextAlign || "left",
    headingTextDecoration:     props.headingTextDecoration  || "none",
    headingTextColor:          props.headingTextColor || "#454d5f",


    pgraphFontFamily:         props.pgraphFontFamily || 'Arial, Helvetica, sans-serif',
    pgraphFontSize:           props.pgraphFontSize || "16px",
    pgraphFontWeight:         props.pgraphFontWeight  || "500",
    pgraphTextAlign:          props.pgraphTextAlign || "left",
    pgraphTextDecoration:     props.pgraphTextDecoration  || "none",
    pgraphTextColor:          props.pgraphTextColor || "#454d5f",


    noteFontFamily:         props.noteFontFamily || 'Arial, Helvetica, sans-serif',
    noteFontSize:           props.noteFontSize || "13px",
    noteFontWeight:         props.noteFontWeight  || "500",
    noteTextAlign:          props.noteTextAlign || "center",
    noteTextDecoration:     props.noteTextDecoration  || "none",
    noteTextColor:          props.noteTextColor || "#454d5f",


    bigFontFamily:         props.bigFontFamily || 'Arial, Helvetica, sans-serif',
    bigFontSize:           props.bigFontSize || "100px",
    bigFontWeight:         props.bigFontWeight  || "550",
    bigTextAlign:          props.bigTextAlign || "center",
    bigTextDecoration:     props.bigTextDecoration  || "none",
    bigTextColor:          props.bigTextColor || "#454d5f",

    xLargeFontFamily:         props.xLargeFontFamily || 'Arial, Helvetica, sans-serif',
    xLargeFontSize:           props.xLargeFontSize || "80px",
    xLargeFontWeight:         props.xLargeFontWeight  || "550",
    xLargeTextAlign:          props.xLargeTextAlign || "center",
    xLargeTextDecoration:     props.xLargeTextDecoration  || "none",
    xLargeTextColor:          props.xLargeTextColor || "#454d5f",

    largeFontFamily:         props.largeFontFamily || 'Arial, Helvetica, sans-serif',
    largeFontSize:           props.largeFontSize || "60px",
    largeFontWeight:         props.largeFontWeight  || "550",
    largeTextAlign:          props.largeTextAlign || "center",
    largeTextDecoration:     props.largeTextDecoration  || "none",
    largeTextColor:          props.largeTextColor || "#454d5f",
    
    mediumFontFamily:         props.mediumFontFamily || 'Arial, Helvetica, sans-serif',
    mediumFontSize:           props.mediumFontSize || "50px",
    mediumFontWeight:         props.mediumFontWeight  || "550",
    mediumTextAlign:          props.mediumTextAlign || "center",
    mediumTextDecoration:     props.mediumTextDecoration  || "none",
    mediumTextColor:          props.mediumTextColor || "#454d5f",

    smallFontFamily:         props.smallFontFamily || 'Arial, Helvetica, sans-serif',
    smallFontSize:           props.smallFontSize || "20px",
    smallFontWeight:         props.smallFontWeight  || "550",
    smallTextAlign:          props.smallTextAlign || "center",
    smallTextDecoration:     props.smallTextDecoration  || "none",
    smallTextColor:          props.smallTextColor || "#454d5f"



    // border-bottom:${props => props.border};
    // border-left:${props => props.border};
    // border-right:${props => props.border};


}))`


    background-color:${props => props.backgroundColor};


    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;


    font-family:${props => props.fontFamily};
    font-size:${props => props.fontSize};
    font-weight:${props => props.fontWeight};
    text-align:${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.textColor};


    cursor:default;




    .Spacer {

        width:100%;
        height:20px;
        margin:0 auto 0 auto;
        padding:0 0 0 0;
     
    }





    .Heading {


        width:100%;
        height:auto;
        margin:0 auto 0 auto;


        font-family:${props => props.headingFontFamily};
        font-size:${props => props.headingFontSize};
        font-weight:${props => props.headingFontWeight};
        text-align:${props => props.headingTextAlign};
        text-decoration:${props => props.headingTextDecoration};
        color:${props => props.headingTextColor};


    }

    .Pgraph {

        width:100%;
        height:auto;
        margin:0 auto 0 auto;

        font-family:${props => props.pgraphFontFamily};
        font-size:${props => props.pgraphFontSize};
        font-weight:${props => props.pgraphFontWeight};
        text-align:${props => props.pgraphTextAlign};
        text-decoration:${props => props.pgraphTextDecoration};
        color:${props => props.pgraphTextColor};

        line-height:1.5;
    

        
    }





    .Note {


        width:100%;
        height:auto;
        margin:0 auto 20px auto;
        padding:0 0 0 0;


        font-family:${props => props.noteFontFamily};
        font-size:${props => props.noteFontSize};
        font-weight:${props => props.noteFontWeight};
        text-align:${props => props.noteTextAlign};
        text-decoration:${props => props.noteTextDecoration};
        color:${props => props.noteTextColor};

        line-height:1.5;
     
    }



    .Big {

        background-color:transparent;

        width:100%;
        height:auto;
        margin:0 auto 0 auto;

        font-family:${props => props.bigFontFamily};
        font-size:${props => props.bigFontSize};
        font-weight:${props => props.bigFontWeight};
        text-align:${props => props.bigTextAlign};
        text-decoration:${props => props.bigTextDecoration};
        color:${props => props.bigTextColor};

    }



    .XLarge {

        background-color:transparent;

        width:100%;
        height:auto;
        margin:0 auto 0 auto;

        font-family:${props => props.xLargeFontFamily};
        font-size:${props => props.xLargeFontSize};
        font-weight:${props => props.xLargeFontWeight};
        text-align:${props => props.xLargeTextAlign};
        text-decoration:${props => props.xLargeTextDecoration};
        color:${props => props.xLargeTextColor};

    }


    .Large {

        background-color:transparent;

        width:100%;
        height:auto;
        margin:0 auto 0 auto;

        font-family:${props => props.largeFontFamily};
        font-size:${props => props.largeFontSize};
        font-weight:${props => props.largeFontWeight};
        text-align:${props => props.largeTextAlign};
        text-decoration:${props => props.largeTextDecoration};
        color:${props => props.largeTextColor};

    }


    .Medium {

        background-color:transparent;
    

        width:100%;
        height:auto;
        margin:10px auto 0 auto;
        padding:0 0 0 0;


    
        font-family:${props => props.mediumFontFamily};
        font-size:${props => props.mediumFontSize};
        font-weight:${props => props.mediumFontWeight};
        text-align:${props => props.mediumTextAlign};
        text-decoration:${props => props.mediumTextDecoration};
        color:${props => props.mediumTextColor};
    
    }



    .Small {

        background-color:transparent;
    

        width:100%;
        height:auto;
        margin:10px auto 0 auto;
        padding:0 0 0 0;


    
        font-family:${props => props.smallFontFamily};
        font-size:${props => props.smallFontSize};
        font-weight:${props => props.smallFontWeight};
        text-align:${props => props.smallTextAlign};
        text-decoration:${props => props.smallTextDecoration};
        color:${props => props.smallTextColor};
    
    }
    
    



    @media (max-width: 700px) {

        .Big {

            background-color:transparent;
            font-size:80px;
    
        }


        .XLarge {

            background-color:transparent;
            font-size:60px;
    
        }


        .Large {

            background-color:transparent;
            font-size:40px;
    
        }


        .Medium {

            background-color:transparent;
            font-size:40px;
    
        }

        .Small {

            background-color:transparent;
            font-size:20px;
    
        }



    }





    


    .ButtonContainer {

        background-color:transparent;
        width:100%;
        height:50px;
        padding:0 0 0 0;
        margin:0 auto 15px auto;
 
    }

        .Button {

            background-color:transparent;
            width:100%;
            height:50px;
            padding:0 0 0 0;
            margin:0 0 0 0;
            position:relative;
            float:left;
    
            font-family:${props => props.fontFamily};
            font-size:${props => props.fontSize};
            font-weight:${props => props.fontWeight};
            text-align:${props => props.textAlign};
            text-decoration:${props => props.textDecoration};
            color:${props => props.color};

            &:hover{

                color:#191e28;

            }

        }


            .ButtonMask {

                background-color:transparent;

                width:50px;
                height:50px;
                padding:0 0 0 0;
                margin:0 0 0 0;
                position:absolute;
                top:0px;
                left:calc(50% - 25px);
                z-index:1;

                cursor:pointer;

            }

            .Img {

                width:50px;
                height:50px;

            }





`;






    export const ReportPrint = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "lightyellow",//#fcfcfc

        width:              props.width || "100%", 
        height:             props.height || "auto",

        padding:            props.padding|| "0 0 100px 0",
        margin:             props.margin || "10px auto 0 auto",


        fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
        fontSize:           props.fontSize || "16px",
        fontWeight:         props.fontWeight  || "500",
        textAlign:          props.textAlign || "left",
        textDecoration:     props.textDecoration  || "none",
        textColor:          props.textColor || "#454d5f",
    


    }))`

        background-color:${props => props.backgroundColor};

        width   :${props => props.width};
        height  :${props => props.height};

        min-height: 400px;

        padding :${props => props.padding}; 
        margin  :${props => props.margin};


        position:relative;  
        
        
        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        text-align:${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.textColor};


    `;












