import styled from "styled-components";
//import px2vw from "utils/px2vw";


export const Panel = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#141820", //"#191e28", //"#EDEEF1",

    width:              props.width || "550px",
    height:             props.height || "100vh",
    margin:             props.margin || "0 0 0 0",

    zIndex:             props.zIndex || "20", 
    top:                props.top || "0px", 
    right:              props.right || "0px",




    // border-top:1px solid lightgray;
    // border-bottom:1px solid lightgray; 

    // #90ee90 #caffca


    // position:absolute;
    // z-index:${props => props.zIndex};
    // top:${props => props.top};
    // left:${props => props.left};


    // position:absolute;
    // z-index:${props => props.zIndex};
    // top:${props => props.top};
    // right:${props => props.right};


}))`

    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    height:${props => props.height};

    padding:0 0 200px 0;
    margin:${props => props.margin};


    position:relative;
    float:right;

    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-width:1500px;


    @media (max-width: 1000px) {

        width:100%;
        right:0px;
    }
    @media (max-width: 1024px) {

        background-color:white;

        border:0;
        border-radius:0;

        width:${props => props.width};
        left:${props => props.left};


    }



`;




export const Loader = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent", //"#191e28", //"#EDEEF1",

    width:              props.width || "300px",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 0",

    zIndex:             props.zIndex || "8",
    top:                props.top || "200px", 
    left:               props.left || "0px",


    messageFontFamily:         props.messageFontFamily || 'Arial, Helvetica, sans-serif',
    messageFontSize:           props.messageFontSize || "20px",
    messageFontWeight:         props.messageFontWeight  || "500",
    messageTextAlign:          props.messageTextAlign || "center",
    messageTextDecoration:     props.messageTextDecoration  || "none",
    messageTextColor:          props.messageTextColor || "black",

    // border-top:1px solid lightgray;
    // border-bottom:1px solid lightgray; 

    // left:calc(0% + 500px);

}))`

    background-color:${props => props.backgroundColor};
    border-radius:0;

    min-height:200px;
    width:${props => props.width};
    height:${props => props.height};

    padding:0 0 50px 0;
    margin:${props => props.margin};

    position:absolute;
    z-index:${props => props.zIndex};
    top:${props => props.top};
    left:${props => props.left};



    @media (max-width: 100000px) {

        left:calc(50% - 150px);

    }
    @media (max-width: 1024px) {

        left:calc(50% - 150px);

    }


    .Message {

        background-color:transparent;

        width:calc(100% - 20px);
        height:auto;
        padding:10px 0 10px 10px;
        margin:25px 0 20px 0;
        position:relative;
        float:left;

        cursor:default;

        font-family:${props => props.messageFontFamily};
        font-size:${props => props.messageFontSize};
        font-weight:${props => props.messageFontWeight};
        text-align:${props => props.messageTextAlign};
        text-decoration:${props => props.messageTextDecoration};
        color:${props => props.messageTextColor};

    }





`;





