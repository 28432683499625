
//######################################################################
//######################################################################
//######################################################################
//defaults


//=====================================================
//=====================================================

//-------------------------
//fields
//-------------------------

//var def_name = '';
var def_email = '';
//var def_pword = '';
//var def_company = '';	
//var def_phone = '';
//var def_message = '';
//var def_band = '';



var use_defaults = true;


//=====================================================
//=====================================================

if(use_defaults)
{


    //-------------------------
    //fields
    //-------------------------

    //def_name = 'Tom M';
    //def_email = 'tpc@theprofitcalculator.com';
    //def_pword = 'MixelPlix_2021';
    //def_company = '';
    //def_phone = "555-444-3333";
    //def_message = 'This is a test message';
    //def_band = '';


    //-------------------------
    //captcha
    //-------------------------

    //def_captcha = "1234";//dynamically retrieved




}//==










const Composer = [


    

    {

        "call":"header",
        "data":{

            "text":"Forgot Password"
        }

    },






    //##########################################################################
    //##########################################################################
    //##########################################################################
    //customer info


    
    // //==========================================
    // //bar
    // //==========================================
    // {

    //     "call"   :"bar",
    //     "data"      :{

    //         "text"  :"ENTER ACCOUNT EMAIL"

    //     }


    // },


    //==========================================
    //headline
    //==========================================
    {

        "call"   :"statement", //same as headline no top margin
        "data"      :{

            "text"  :'Please enter your account email'

        }


    },

  
    
    
    //==========================================
    //fields
    //==========================================
    {

        "call"   :"fields",
        "data"      :[



            //--------------------------------
            //
            //--------------------------------
            {


                "fieldtype"               	:"text",
                "required"					:true,

                "class"                   	:'',
                "id"                      	:'email',
                "hint"                    	:"Email",

                "text"                     	:def_email,



                "keyboardType"            	:"email",
                "autocorrect"             	:"false",
                "autocapitalizationType"  	:"none",


                //"secure"                  :true,
                //"returnKeyType"           :"done"


            },


            // //--------------------------------
            // //password
            // //--------------------------------
            // {


            //     "fieldtype"               	:"password",
            //     "required"					:true,

            //     "class"                   	:'',
            //     "id"                      	:'pword',
            //     "hint"                    	:"Password",

            //     "text"                     	:def_pword,



            //     "keyboardType"            	:"",
            //     "autocorrect"             	:"false",
            //     "autocapitalizationType"  	:"none",


            //     //"secure"                  :true,
            //     //"returnKeyType"           :"done"


            // }
            

        ]

        

    },



    {
        "call"   :"spacer",
        "data"      :{

            "name"		:'info-end',
            "color"   	:'transparent',
            "height"  	:20

        }

    },





/*

    //##########################################################################
    //##########################################################################
    //##########################################################################
    //captcha


    //==========================================
    //headline
    //==========================================
    {

        "call"   :"headline",
        "data"      :{

            "text"  :'Enter Confirmation Code'

        }


    },


    //==========================================
    //captcha
    //==========================================

    {
        "call"   :"captcha",
        "data"      :{

            "required"	:required_captcha,
            "capnum"	:def_captcha

        }


    },

    {
        "call"   :"spacer",
        "data"      :{

            "name"		:"before-button",
            "color"   	:'transparent',
            "height"  	:0

        }

    },


    

*/




    //##########################################################################
    //##########################################################################
    //##########################################################################
    //button

    {
        "call"   :"spacer",
        "data"      :{

            "name"		:'button-begin',
            "color"   	:'transparent',
            "height"  	:20

        }

    },


    {
        "call"   :"button",
        "data"      :{

            "text"  :'Submit',
            "class" :'btn-submit',
            "funct"	:{

                "name":"FormSubmit",
                "object":false

            },
            "action":'submit'
            
        }

    },




    //##########################################################################
    //##########################################################################
    //##########################################################################
    //link

    {
        "call"   :"spacer",
        "data"      :{

            "name"		:'before-link',
            "color"   	:'transparent',
            "height"  	:20

        }

    },


    // {
    //     "call"   :"link",
    //     "data"      :{

    //         "text"  :'Sign Up',
    //         "class" :'btn-link',
    //         "funct"	:{

    //             "name":"handleUrl",
    //             "object":{

    //                 "url":"/SignUpForm/"

    //             }

    //         },
    //         "action":'url',
    //         "url":"/SignUpForm/"
            
    //     }

    // },


    {
        "call"   :"link",
        "data"      :{

            "text"  :'Login',
            "class" :'btn-link',
            "funct"	:{

                "name":"handleUrl",
                "object":{

                    "url":"/Login/"

                }

            },
            "action":'url',
            "url":"/Login/"
            
        }

    },








    

    
    {
        "call"   :"spacer",
        "data"      :{

            "name"		:"bottom",
            "color"   	:'transparent',
            "height"  	:10

        }

    }




]


export default Composer;
