const ComposeLabelFields = (props) => {


    var R = [];
    var def;
    var val;
    var text;

    var tabIndex = 100; 




    switch(Object.prototype.toString.call(props))
    {
    case'[object Array]':
    break;
    case'[object Object]':


        
        for(var keyname in props)
        {

            def = props[keyname].default;
            val = props[keyname].value;

            // if(isNaN(def)) def = 0;
            // if(isNaN(val)) val = 0;

            switch(props[keyname].print)
            {
            case'price':
        
                def = parseFloat(def);
                val = parseFloat(val);

                def = def.toFixed(2);
                val = val.toFixed(2);

                if(isNaN(def)) def = 0;
                if(isNaN(val)) val = 0;


    
            break;
            case'percent':

                def = parseFloat(def);
                val = parseFloat(val);

                def = def.toFixed(1);
                val = val.toFixed(1);

                if(isNaN(def)) def = 0;
                if(isNaN(val)) val = 0;

            break;
            default:
            }

            if(val !== '') text = val;
            else text = def;
            

       
            R.push({

                label:{

                    text    :props[keyname].name

                },
                field:{

                    //"fieldtype"               :"text",
                    "fieldtype"                 :props[keyname].print,
                    "required"					:props[keyname].required,

                    "class"                   	:'',
                    "id"                      	:keyname,
                    "hint"                    	:"",

                    "text"                     	:text,

                    "keyboardType"            	:"",
                    "autocorrect"             	:"false",
                    "autocapitalizationType"  	:"none",

                    "tabIndex"                  :tabIndex

                    //"secure"                  :true,
                    //"returnKeyType"           :"done"

                }


            });

            //console.log("labelfield - "+keyname + "-" + tabIndex);
            tabIndex = parseInt(tabIndex) + 1;
  


        }//loop







    break;
    case'[object String]':
    break;
    default:
    }


    return R;


}
export default ComposeLabelFields;