import React, { 

    useState, 
    useEffect,
    //useRef

} from 'react';


//import 'app-style/price-form/style.css';
//import '../../StyleSheets/PriceForm.css';
import 'app-components/Zone/_StyleSheets/PriceForm.css';


export const FFgrid = (props) => {


    //var _FUNC = props.data.funct['name'] + "()";
    //var _FUNC = props.data.funct['name'] + "('"+props.data.funct['object']+"')";
    //var _RUN = new Function(_FUNC);

    // const [buttonStyle, setButtonStyle] = useState({});

   //----------------------------------------------------------



    //=============================================
    // refs
    //=============================================

    const [cellRefs] = useState([]);
    const setCellRef = (ref) => {

        if(ref && ref !== null && ref !== undefined)
        {
            //console.log("ref::::"+ref.id);
            var found;
            found = false;
            for(var i=0; i < cellRefs.length; i++)
            {
                if(cellRefs[i].id === ref.id) found = true;
            }
            if(!found) cellRefs.push(ref);
            //console.log(boxRefs.length);
        }

    };



    //======================================================
    // stete/effect - isLoaded
    //======================================================

    const [isLoaded, setIsLoaded] = useState(false);
    const [cells, setCells] = useState([]);

    const [containerSize, setContainerSize] = useState(false);

    useEffect(() => {

        if(!isLoaded)
        {
            setIsLoaded(true);
            //console.log("props: "+JSON.stringify(props,null,2));
            setCells(props.data.cells);

        }

        if(isLoaded && !containerSize)
        {
        
            var contWidth = 0;
            var contHeight;
            var i;
            var ref;
            for(i=0; i < cellRefs.length; i++)
            {
                ref = cellRefs[i];
                //console.log(document.getElementById(ref.id).offsetWidth);
                contWidth = contWidth + ref.offsetWidth; 
                contHeight = ref.offsetHeight;
                   
            }

            if(props.data.styling.class === 'button') 
            {
                contWidth = contWidth + 20;
            }

            contWidth = contWidth + "px";
            contHeight = contHeight + "px";
            //alert(contWidth+" x "+contHeight);
            setContainerSize({

                width:contWidth,
                height:contHeight

            });

        }


     },[
         props,
         isLoaded,
         cellRefs,
         containerSize
    ])





    return (

        <div 
        key={props.indx+"_ffgrid"} 
        className = 'grid'
        >
            <div 
            className={"container"}
            style={{

                //backgroundColor:"blue",
                width:containerSize.width,
                height:containerSize.height

            }}
            >

                {cells.map((cell,i) => (


                    cell.fieldSource === 'labor'
                    ?

                        <div 
                        ref={setCellRef} 
                        key={i}
                        id={props.indx+"_ffgrid_cell_"+i} 
                        className={props.data.styling.class}
                        style={{}}
                        onClick={() => props.handle({

                            call:"showLaborMenu",
                            data:{

                                index:props.indx,
                                fieldCall:cell.fieldCall
                            }

                        })}
                        >
                            {cell.text}
                            
                        </div>


                    :


                        <div 
                        ref={setCellRef} 
                        key={i}
                        id={props.indx+"_ffgrid_cell_"+i} 
                        className={props.data.styling.class}
                        style={{}}
                        onClick={() => props.handle({

                            call:cell.call,
                            data :{

                                index:props.indx,
                                fieldCall:cell.fieldCall

                            }

                        })}
                        >
                            {cell.text}
                            
                        </div>
                        

                ))}


            </div>

        </div>

    )



 

}











