import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';


import { 
    useSelector, 
    useDispatch 
} 
from 'react-redux';
import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';



import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    useHistory,   
    //useParams,



} from "react-router-dom";

import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'
import useBreadcrumbs from 'utils/hooks/Breadcrumbs';






import {

    //---------------
    // Stage
    //---------------

    Stage,
    ScrollBox,
    // Area,
    // AreaTitle,



    //---------------
    // Layout
    //---------------

    MenuAlfa,
    Menu,
    MenuControls,

    Topbar,

    Side,Side2,  
    Main,

    // Rows,
    // FullRow,
    // IndentRow,

    // Box,

    Fly,
    FlyContainer,
    FlyTray,


    // Tile,
    // TileName,
    // TileTop,
    // TileControls
    

} from 'app-components/Zone/_Styled';



import {

    Catalog,
        CatalogTop,
            CatalogTopName,
            CatalogTopControls,

        CatalogBar,
        CatalogTileCalc,


} from 'utils/components/Catalog/Styled';




// import StylScene from './_StylModules/Scene.module.css';
// // import StylPage from '../_StylModules/Page.module.css';
// // import StylRows from '../_StylModules/Rows.module.css';





// import FGrid from 'utils/components/Grid/FGrid';
// import VidGrid from 'utils/components/Grid/VidGrid';

// import TxtComponent from 'utils/components/Content/Txt';
// import ImgComponent from 'utils/components/Content/Img';
// import CardComponent from 'utils/components/Card';
import Navigator from 'utils/components/Content/Navigator';

import SideNavInfo from 'utils/components/Pages/SceneInfo/Trials/SideNav/SceneInfo';


import { AlterCalcs } from 'app-components/Zone/_Connect/Calcs/Alter';
import { FetchCalcs } from 'app-components/Zone/_Connect/Calcs/Fetch';


import { FetchInfoPanel as FetchPanel } from 'app-components/Zone/_Connect/InfoPanel/Fetch';

import Panel from 'app-components/Zone/_InfoPanel';
import PanelInfo from 'app-components/Zone/_InfoPanel/Info';
import PanelLoading from 'app-components/Zone/_InfoPanel/Loading';


import Loading from 'app-components/Zone/_Generic/Loading';
import Message from 'app-components/Zone/_Generic/Message';
import Flanks from 'app-components/Zone/_Generic/Flanks';


import ProcessMenu from './ProcessMenu';
import FavoriteForm from './ProcessMenu/Forms/FavoriteForm';
import RenameForm from './ProcessMenu/Forms/RenameForm';
import SaveForm from './ProcessMenu/Forms/SaveForm';


import Calculator from './Calculator';
import Report from './Report';



// import SceneBuild from 'app-components/Zone/_Builder/SceneBuild';
// import SceneInfo from './SceneInfo';

import SceneInfo from './SceneInfo';


//import SVGProfitPie from './Calculator/Svgs/profit_pie.svg';

// import { LibElem } from 'library';

import { 
    LibDate,
    LibNumber 
} from 'library';


import {

    Flanks as SortByFlanks,
    LabelField as SortByLabelField,
    Menu as SortByMenu

} from './SortBy';
import SortItems from './SortBy/SortItems'


import Stars from './Stars';


// import {

//     //PROFIT_PIE,
//     PRICE_TAG,
//     //BREAKEVEN_TAG

// } from 'app-components/Zone/_SVGComps';


// import {

//     CALCULATOR as CALC_GRAPHIC

// } from 'app-components/Zone/_SVGComps';


import {
    
    Calc

} from 'app-components/Zone/Calculator/Calculate';






import CreateMarkup from 'utils/components/Universal/CreateMarkup';

const CreateHtmlDisplay = (itm) => {


    var paraColor = "#a2a6af";

    var zeroColor = "#6a707e";
    var greaterColor = "#00cc66";
    var lessColor = "#f00";
    var breakevenColor = "#26d6cc";

    var priceColor = greaterColor;
    var netProfitColor = greaterColor;
    var netProfitPercColor = greaterColor;

    if(itm.netProfit < 0)
    {
        priceColor = lessColor;
        netProfitColor = lessColor;
        netProfitPercColor = lessColor;
    }
    else
    if(itm.price === 0)
    {
        priceColor = zeroColor;
        netProfitColor = zeroColor;
        netProfitPercColor = zeroColor;
    }
    else
    if(itm.price > 0 && itm.price === itm.breakevenPrice)
    {
        priceColor = breakevenColor;
        netProfitColor = zeroColor;
        netProfitPercColor = zeroColor;
    }
    

    var R = '';

        R += "<span style='color:"+paraColor+";'>(&nbsp;</span>";
        R += "<span style='color:"+priceColor+";'>"+itm.priceDisplay+"</span>";
        R += "<span style='color:"+paraColor+";'>&nbsp;)</span>";

        R += "&nbsp;";

        R += "<span style='color:"+paraColor+";'>(&nbsp;</span>";
        R += "<span style='color:"+netProfitColor+";'>"+itm.netProfitDisplay+"</span>";
        R += "<span style='color:"+paraColor+";'>&nbsp;)</span>";

        R += "&nbsp;";

        R += "<span style='color:"+paraColor+";'>(&nbsp;</span>";
        R += "<span style='color:"+netProfitPercColor+";'>"+itm.netProfitPercDisplay+"</span>";
        R += "<span style='color:"+paraColor+";'>&nbsp;)</span>";

    return R;

};






const Index = (props) => {



    //const Scene = SceneBuild(SceneInfo());
    const InitScene = SceneInfo(props.data);
    // console.log("Employee Scene:::: "+JSON.stringify(Scene,null,2));



    // -- window dims -- //
    const { 
        width,
        height 
    } = useWindowDimensions();


    // -- breadcrumbsHook -- //
    const breadcrumbsHook = useBreadcrumbs();
    const breadcrumbs = breadcrumbsHook.breadcrumbs;
    const handleBreadcrumbs = breadcrumbsHook.handle;




    // -- redux -- //
    const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));




    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    const history = useHistory();

    

    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);




    // let mainpath;
    // mainpath = pathname.split("/")[0];
    
    // let subpath;
    // subpath = pathname.split("/")[1];
    // if(subpath === undefined) subpath = false;

    // let sub2path;
    // sub2path = pathname.split("/")[2];
    // if(sub2path === undefined) sub2path = false;





    //=============================================
    // refs
    //=============================================

    const [rowRefs] = useState([]);
    const setRowRef = (ref) => {

        if(ref && ref !== null && ref !== undefined)
        {
            //console.log("ref::::"+ref.id);
            var found;
            found = false;
            for(var i=0; i < rowRefs.length; i++)
            {
                if(rowRefs[i].id === ref.id) found = true;
            }
            if(!found) rowRefs.push(ref);
            //console.log(boxRefs.length);

        }

    };



    // -- ref -- //
    const refStage = useRef(null);
    const refScrollbox = useRef(null);
    const refTopbar = useRef(null); 
    const refSide = useRef(null);
    const refSide2 = useRef(null);
    const refMain= useRef(null);
    const refContent= useRef(null);



    //=============================================
    // state/effect - thresholds
    //=============================================

    // -- state -- //
    const [thresholdSide1] = useState(1049);
    const [thresholdSide2, setThresholdSide2] = useState(true);
    const [showMenu, setShowMenu] = useState(false);
    const [panelStatus, setPanelStatus] = useState({

        show:false,
        call:'init',
        data:PanelInfo.init


    });

    useEffect(() => {

        if(width > thresholdSide1 && showMenu)
        {
            setShowMenu(false);

        }
        var aboveNum = 1400;
        if(thresholdSide2 && width < aboveNum) setThresholdSide2(false);
        if(!thresholdSide2 && width > aboveNum-1) setThresholdSide2(true);

    },
    [

        width,
        thresholdSide1,
        thresholdSide2,
        showMenu

    ])


    



    //=============================================
    // state 
    //=============================================

    const [isLoaded, setIsLoaded] = useState(false);
    //const [wasReplaced, setWasReplaced] = useState(false);

    const [runFetch, setRunFetch] = useState(false);
    const [wasFetched, setWasFetched] = useState(false);
    const [fetchData] = useState({

        call:"fetchAll",
        recid:false//used if getting a specific

    });
    const [runAlter, setRunAlter] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [showRenameForm, setShowRenameForm] = useState(false);
    const [showSaveForm, setShowSaveForm] = useState(false);
    const [showFavoriteForm, setShowFavoriteForm] = useState(false);


    const [Scene, setScene] = useState(false);
    const [sortByData, setSortByData] = useState(false);

    const [hasCalcs, setHasCalcs] = useState(false); 
    const [calcId, setCalcId] = useState('');
    const [calcName, setCalcName] = useState('');

    const [calcHash, setCalcHash] = useState(false);
    const [reportHash, setReportHash] = useState(false); 
    const [fromReport, setFromReport] = useState(false);
    //const [styleAfterHash, setStyleAfterHash] = useState(false); 


    useEffect(() => {


        var top,bar,catalogItems;
        var array;
        var calcs,keyname;
        var sortedItems;

        var heading, largeHeading;
        var dif;

        var calculator, calculation;
        var price, netProfit, netProfitPerc, breakevenPrice;
        var priceDisplay, netProfitDisplay, netProfitPercDisplay, htmlDisplay;

        var cost_of_goods = [
            
            {
                "call": "good-1",
                "other": {
                "data": {
                    "name": "",
                    "brand": ""
                },
                "label": "--"
                },
                "title": "Vinyl",
                "rate": 0,
                "qtyhrs": 0
            }
    
        ];
    
        var calcby = {
            "call": "percent",
            "title": "Net Profit",
            "value": "25.0"
        };
    
        var overhead =  {
            "title": "Overhead",
            "value": "49.1",
            "id": "overhead"
        };
    
        var debits =  [
            {
                "title": "Commission",
                "value": 0,
                "id": "commission"
            }
            // {
            //     "title": "Fees",
            //     "value": 0,
            //     "id": "fees"
            // }
        ];
    
        var quantity = {
            "title": "Quantity",
            "value": "1",
            "id": "quantity"
        };
    


    


        if(!isLoaded)
        {

   
            setIsLoaded(true);

            // setRunFetch(false);
            // setWasFetched(false);

            setRunAlter(false);
            setShowMessage(false);
            setShowRenameForm(false);
            setShowFavoriteForm(false);

            if(location.hash !== '')
            {
                history.replace("");
                history.push("Pricing/");

            }


            // console.log(showMessage);
            // console.log(showRenameForm);
            // console.log(showFavoriteForm);
            // console.log(calcId);



            if(!zoneObj['calcs'] && !wasFetched)
            {
                //setRunFetch(true);

                array = [];      
                top = InitScene[0][0];
                bar = InitScene[0][1];
                array.push([top,bar,{

                    call:"items",
                    items:[]
                
                }]);
                setScene(array);

            }else{

                //console.log("has zoned calcs");

                array = [];
                top = InitScene[0][0];
                bar = InitScene[0][1];
                catalogItems = [];
                calcs = JSON.parse(JSON.stringify(zoneObj['calcs']));
                for(keyname in calcs)
                {
                    catalogItems.push({

                        heading:calcs[keyname].data.name,
                        bullet:false,
                        stripe:true,
                        pgraph:calcs[keyname].data.description,
                        image:false,
                        button:false,
                        data:{
            
                            id:keyname,
                            name:calcs[keyname].data.name,
                            value:calcs[keyname].data.value,
                            descr:calcs[keyname].data.description,
                            favorite:calcs[keyname].data.favorite

                        }

                    });
                }

                sortedItems = SortItems({

                    data:{
                        value:"favorites-high-low",
                        text:"Favorites (High to Low)"
                    },
                    items:catalogItems,
                    zoneObj:zoneObj
    
                });
                array.push([top,bar,{

                    call:"items",
                    items:sortedItems
                
                }]);
                //console.log(JSON.stringify(array,null,2));
                setScene(array);
                setSortByData({
                    value:"favorites-high-low",
                    text:"Favorites (High to Low)"
                });
           
        
      
            }

        }



        // runs on fetch
        if(wasFetched && zoneObj['calcs'])
        {
            console.log("wasFetched zone calcs");

            setWasFetched(false);

            array = [];
            top = InitScene[0][0];
            bar = InitScene[0][1];
            catalogItems = [];
            calcs = JSON.parse(JSON.stringify(zoneObj['calcs']));
            for(keyname in calcs)
            {

                // catalogItems.push({

                //     heading:calcs[keyname].data.name,
                //     bullet:false,
                //     stripe:true,
                //     pgraph:calcs[keyname].data.description,
                //     image:false,
                //     button:false,
                //     data:{
        
                //         id:keyname,
                //         name:calcs[keyname].data.name,
                //         value:calcs[keyname].data.value,
                //         descr:calcs[keyname].data.description,
                //         favorite:calcs[keyname].data.favorite
        
                //     }

                // });





                largeHeading = false;
                heading = calcs[keyname].data.name;
                if(heading.length > 25) largeHeading = true;
                if(heading.length > 41) 
                {
                    dif = heading.length - 44;
                    heading = heading.substring(0,heading.length-dif);
                    heading = heading + "..."
                }

                calculator = calcs[keyname];
                if(calculator)
                {
                    if(calculator.data)
                    {
                        if(calculator.data.value)
                        {
                            if(calculator.data.value.cost_of_goods)   cost_of_goods   = calculator.data.value.cost_of_goods;
                            if(calculator.data.value.calcby)          calcby          = calculator.data.value.calcby;    
                            if(calculator.data.value.overhead)        overhead        = calculator.data.value.overhead;
                            if(calculator.data.value.debits)          debits          = calculator.data.value.debits;
                            if(calculator.data.value.quantity)        quantity        = calculator.data.value.quantity;


                            //--------------------------
                            //current overhead 
                            //--------------------------

                            //console.log("BEFORE OVERHEAD: "+JSON.stringify(overhead,null,2));

                            //console.log("zoneObj Keys: "+JSON.stringify(Object.keys(zoneObj.calc_dash.result_items)));
                            if(zoneObj.calc_dash)
                            {
                                if(zoneObj.calc_dash.result_items)
                                {
                                    if(zoneObj.calc_dash.result_items.overhead)
                                    {
                                        overhead = zoneObj.calc_dash.result_items.overhead.overhead;
                                        overhead = parseFloat(overhead).toFixed(1);
                                        overhead = {
                                            title   : "Overhead",
                                            value   : overhead,
                                            id      : "overhead"
                                        }
            
                                    }
                                }
                            }
                            //console.log("AFTER OVERHEAD: "+JSON.stringify(overhead,null,2));


                        
                            calculation = Calc({
                            
                                input:{
                        
                                    cost_of_goods   :cost_of_goods,
                                    overhead        :overhead,
                                    debits          :debits,
                                    calcby          :calcby,
                                    quantity        :quantity

                                }
                        
                            });
                            // console.log("calculation: "+JSON.stringify(calculation, null, 2));
                            // alert("Calculation");




                            price = calculation.grand_total;
                            price = parseFloat(price).toFixed(2);

                            netProfit = calculation.output.profit;
                            netProfit = parseFloat(netProfit).toFixed(2);

                            netProfitPerc = calculation.output.profit_percent;
                            netProfitPerc = parseFloat(netProfitPerc).toFixed();

                            breakevenPrice = calculation.output.breakeven;
                            breakevenPrice = parseFloat(breakevenPrice).toFixed(2);


                            priceDisplay = "$"+LibNumber.PrintMoney(price);
                            netProfitDisplay = "$"+LibNumber.PrintMoney(netProfit);
                            netProfitPercDisplay = netProfitPerc + "%";

                            htmlDisplay = CreateHtmlDisplay({

                                price:parseFloat(price),
                                netProfit:parseFloat(netProfit),
                                breakevenPrice:parseFloat(breakevenPrice),
                                priceDisplay:priceDisplay,
                                netProfitDisplay:netProfitDisplay,
                                netProfitPercDisplay:netProfitPercDisplay,
  
                            });
                        


                        }
                    }
                }


                // console.log("CALCULATOR: "+JSON.stringify(calculator.data.value,null,2));
            
        
                if(calculation)
                {

                    catalogItems.push({

                        
                        heading:heading,
                        bullet:false,
                        stripe:true,
                        pgraph:calcs[keyname].data.description,
                        image:false,
                        button:false,
                        data:{
            
                            id:keyname,
                            name:calcs[keyname].data.name,
                            value:calcs[keyname].data.value,
                            descr:calcs[keyname].data.description,
                            favorite:calcs[keyname].data.favorite
                    
                        },
                        price:parseFloat(price),
                        netProfit:parseFloat(netProfit),
                        breakevenPrice:parseFloat(breakevenPrice),
                        priceDisplay:priceDisplay,
                        netProfitDisplay:netProfitDisplay,
                        netProfitPercDisplay:netProfitPercDisplay,
                        largeHeading:largeHeading,
                        htmlDisplay:htmlDisplay
                
    
                    });



                }else{

                    catalogItems.push({


                        heading:heading,
                        bullet:false,
                        stripe:true,
                        pgraph:calcs[keyname].data.description,
                        image:false,
                        button:false,
                        data:{
            
                            id:keyname,
                            name:calcs[keyname].data.name,
                            value:calcs[keyname].data.value,
                            descr:calcs[keyname].data.description,
                            favorite:calcs[keyname].data.favorite
                    
                        },
                        price:0,
                        breakevenPrice:0,
                        priceDisplay:"na",
                        netProfitDisplay:"na",
                        netProfitPercDisplay:"na",
                        largeHeading:largeHeading,
                        htmlDisplay:"<span>na</span>"
                        
            

                    });


                }

                




            }


            if(sortByData)
            {

                sortedItems = SortItems({

                    data:sortByData,
                    items:catalogItems,
                    zoneObj:zoneObj
    
                });


            }else{


                sortedItems = SortItems({

                    data:{
                        value:"favorites-high-low",
                        text:"Favorites (High to Low)"
                    },
                    items:catalogItems,
                    zoneObj:zoneObj
    
                });
                setSortByData({
                    value:"favorites-high-low",
                    text:"Favorites (High to Low)"
                });


            }
            array.push([top,bar,{

                call:"items",
                items:sortedItems
            
            }]);
            //console.log(JSON.stringify(array,null,2));
            setScene(array);

        }




        if(zoneObj['calcs'] && !hasCalcs)
        {

            setHasCalcs(true);

            array = [];
            top = InitScene[0][0];
            bar = InitScene[0][1];
            catalogItems = [];
            calcs = JSON.parse(JSON.stringify(zoneObj['calcs']));
            for(keyname in calcs)
            {



                largeHeading = false;
                heading = calcs[keyname].data.name;
                if(heading.length > 25) largeHeading = true;
                if(heading.length > 41) 
                {
                    dif = heading.length - 44;
                    heading = heading.substring(0,heading.length-dif);
                    heading = heading + "..."
                }
                calculator = calcs[keyname];
                if(calculator)
                {
                    if(calculator.data)
                    {
                        if(calculator.data.value)
                        {
                            if(calculator.data.value.cost_of_goods)   cost_of_goods   = calculator.data.value.cost_of_goods;
                            if(calculator.data.value.calcby)          calcby          = calculator.data.value.calcby;    
                            if(calculator.data.value.overhead)        overhead        = calculator.data.value.overhead;
                            if(calculator.data.value.debits)          debits          = calculator.data.value.debits;
                            if(calculator.data.value.quantity)        quantity        = calculator.data.value.quantity;




                            //--------------------------
                            //current overhead 
                            //--------------------------

                            //console.log("BEFORE OVERHEAD: "+JSON.stringify(overhead,null,2));
                            //console.log("zoneObj Keys: "+JSON.stringify(Object.keys(zoneObj.calc_dash.result_items)));
                            if(zoneObj.calc_dash)
                            {
                                if(zoneObj.calc_dash.result_items)
                                {
                                    if(zoneObj.calc_dash.result_items.overhead)
                                    {
                                        overhead = zoneObj.calc_dash.result_items.overhead.overhead;
                                        overhead = parseFloat(overhead).toFixed(1);
                                        overhead = {
                                            title   : "Overhead",
                                            value   : overhead,
                                            id      : "overhead"
                                        }
            
                                    }
                                }
                            }
                            //console.log("AFTER OVERHEAD: "+JSON.stringify(overhead,null,2));



                        
                            calculation = Calc({
                            
                                input:{
                        
                                    cost_of_goods   :cost_of_goods,
                                    overhead        :overhead,
                                    debits          :debits,
                                    calcby          :calcby,
                                    quantity        :quantity

                                }
                        
                            });
                            //console.log("calculation: "+JSON.stringify(calculation, null, 2));

                            price = calculation.grand_total;
                            price = parseFloat(price).toFixed(2);

                            netProfit = calculation.output.profit;
                            netProfit = parseFloat(netProfit).toFixed(2);

                            netProfitPerc = calculation.output.profit_percent;
                            netProfitPerc = parseFloat(netProfitPerc).toFixed();

                            breakevenPrice = calculation.output.breakeven;
                            breakevenPrice = parseFloat(breakevenPrice).toFixed(2);


                            priceDisplay = "$"+LibNumber.PrintMoney(price);
                            netProfitDisplay = "$"+LibNumber.PrintMoney(netProfit);
                            netProfitPercDisplay = netProfitPerc + "%";


                            htmlDisplay = CreateHtmlDisplay({

                                price:parseFloat(price),
                                netProfit:parseFloat(netProfit),
                                breakevenPrice:parseFloat(breakevenPrice),
                                priceDisplay:priceDisplay,
                                netProfitDisplay:netProfitDisplay,
                                netProfitPercDisplay:netProfitPercDisplay,
  
                            });
                        



                        }
                    }
                }


                //console.log("CALCULATOR: "+JSON.stringify(calculator.data.value,null,2));
            
        
                if(calculation)
                {

                    catalogItems.push({

                        
                        heading:heading,
                        bullet:false,
                        stripe:true,
                        pgraph:calcs[keyname].data.description,
                        image:false,
                        button:false,
                        data:{
            
                            id:keyname,
                            name:calcs[keyname].data.name,
                            value:calcs[keyname].data.value,
                            descr:calcs[keyname].data.description,
                            favorite:calcs[keyname].data.favorite
                    
                        },
                        price:parseFloat(price),
                        netProfit:parseFloat(netProfit),
                        breakevenPrice:parseFloat(breakevenPrice),
                        priceDisplay:priceDisplay,
                        netProfitDisplay:netProfitDisplay,
                        netProfitPercDisplay:netProfitPercDisplay,
                        largeHeading:largeHeading,
                        htmlDisplay:htmlDisplay
                
    
                    });



                }else{

                    catalogItems.push({


                        heading:heading,
                        bullet:false,
                        stripe:true,
                        pgraph:calcs[keyname].data.description,
                        image:false,
                        button:false,
                        data:{
            
                            id:keyname,
                            name:calcs[keyname].data.name,
                            value:calcs[keyname].data.value,
                            descr:calcs[keyname].data.description,
                            favorite:calcs[keyname].data.favorite
                    
                        },
                        price:0,
                        breakevenPrice:0,
                        priceDisplay:"na",
                        netProfitDisplay:"na",
                        netProfitPercDisplay:"na",
                        largeHeading:largeHeading,
                        htmlDisplay:"<span>na</span>"
                        
            

                    });


                }

                
        

            }


            if(sortByData)
            {

                sortedItems = SortItems({

                    data:sortByData,
                    items:catalogItems,
                    zoneObj:zoneObj
    
                });


            }else{


                sortedItems = SortItems({

                    data:{
                        value:"favorites-high-low",
                        text:"Favorites (High to Low)"
                    },
                    items:catalogItems,
                    zoneObj:zoneObj
    
                });
                setSortByData({
                    value:"favorites-high-low",
                    text:"Favorites (High to Low)"
                });


            }
            array.push([top,bar,{

                call:"items",
                items:sortedItems
            
            }]);
            //console.log("SCENE ARRAY: "+JSON.stringify(array,null,2));
            setScene(array);



        }



        // -- clear hash -- //

        if(isLoaded && calcHash)
        {
            if(location.hash === '')
            {
                setCalcHash(false);
            }

            if(location.hash === '#!calcmenu')
            {
                history.goBack();
               
            }

        }


        if(isLoaded && reportHash)
        {
            if(location.hash === '')
            {
                setReportHash(false);
            }
        }


        // if(rowRefs.length === 0)
        // {
        //     alert('no refs');
        // }else{

        //     for(var rowRef of rowRefs)
        //     {
        //         console.log(rowRef.id);
        //     }
        
        // }

        // if(isLoaded && !calcHash)
        // {

        //     if(location.hash === '#!Calculator')
        //     {
        //         history.goBack();
               
        //     }

        // }

 


        // if(!wasReplaced && location.hash === '')
        // {
        //     //alert('back to pricing');
        //     //setIsLoaded(false);
        //     setWasReplaced(true);
        //     history.replace();

        // }

        

    },
    [
        location,history,

        isLoaded,
        //wasReplaced,
        zoneObj,
        wasFetched,
        InitScene,Scene,
        sortByData,

        hasCalcs,

        showMessage,
        showRenameForm,
        showFavoriteForm,
        calcId,calcName,calcHash,
        reportHash


    ]);








    //=============================================
    // state/effect - isLoaded,styling
    //=============================================

    const [contentHeight, setContentHeight] = useState(0);
    const [styling,setStyling] = useState({

        topbar:{

            width:false,
            height:false,
            margin:false
        },     
        scrollbox:{
            
            width:false,
            height:false
        },
        main:{

            width:false,
            margin:false,
            padding:false
        },
        side2:{

            backgroundImage:false,
            backgroundPosition:false,
            backgroundSize:false, 
            width:"calc(100% - 850px)",
            height:false,
            transform:false

        },
        catalog:{

            top:{

                textColor:"#fff"
                
            },
            bar:{

                backgroundColor:"#454d5f"
                
            }
     
        }


    });



    useEffect(() => {

    

        // if(!isLoaded)
        // {

            setIsLoaded(true);

            var mainWidth, mainWidthInt;
            var mainMargin, mainPadding;

            // var side2BgImage = "/_resources/icons/lightbulb.svg";
            // var side2BgPosition = false;
            // var side2BgSize = false;
            // var side2Width, side2WidthInt;
            // side2Width = "0px";


            var side2BgImage = "/_resources/icons/lightbulb.svg";
            //var side2BgImage = false;
            var side2BgPosition = false;
            var side2BgSize = false;
            var side2Width;
            var side2WidthInt;
            var side2Height;
            var side2HeightInt;
            var side2Transform;

            side2Width = "0px";
            // side2Height = false;
            side2BgSize = false;
            side2BgPosition = false;
            side2Transform = false;



            var mar;
            var maxMar;
            //console.log("refMain.offsetHeight::: "+refMain.current.offsetHeight);

            // if(refSide2 && refSide2.current)
            // {
            //     if(refSide2.current.offsetHeight < refScrollbox.current.scrollHeight)
            //     {
            //         //console.log("refSide2.offsetHeight:::"+refSide2.current.offsetHeight); 
            //         alert("refScrollbox::: "+refScrollbox.current.scrollHeight+"\nrefSide2:: "+refSide2.current.offsetHeight);
            //         setCapturedHeight(refScrollbox.current.scrollHeight + "px");

            //     }
            // }


            if(refScrollbox.current.offsetWidth > 800)
            {


                mar = (refScrollbox.current.offsetWidth - refMain.current.offsetWidth)/2;
                if(refSide && refSide.current)
                {
                    maxMar = 50;
                    //console.log("mar"+mar);
                    if(mar > maxMar && refScrollbox.current.offsetWidth > refMain.current.offsetWidth+maxMar*2) mar = maxMar;
                    
                }
                //console.log("above");
                mainWidthInt = 750;
                if(location.hash.match(/#!Calculator/gi) 
                || location.hash.match(/#!Readout/gi)
                || location.hash.match(/#!Report/gi)) mainWidthInt = 650;


                mainWidth = mainWidthInt + "px";  
                mainMargin = "0 0 0 " +mar+"px";
                mainPadding = "0 0 0 0";



                // //-------------------

                // side2WidthInt = refScrollbox.current.offsetWidth - (mainWidthInt+150);
                // side2Width = side2WidthInt + "px";

                // // if(side2WidthInt > 300) 
                // //     side2Width = side2WidthInt + "px";

                // // if(side2WidthInt > 550) 
                // //     side2Width = "550px";


                // //side2BgSize = (refScrollbox.current.offsetHeight) + "px " + (refScrollbox.current.offsetHeight) + "px";

                // //side2BgSize = (side2Width * 2) + "px " + (side2Width * 2) + "px";

                // side2BgSize = "auto 100vh";

                // side2BgPosition = "bottom 20px right 100%";

                // //------------------



                //------------------
                
                //side2WidthInt = refScrollbox.current.offsetWidth - (refMain.current.offsetWidth+75);
                side2WidthInt = refScrollbox.current.offsetWidth - (mainWidthInt+mar+60);
                side2Width = side2WidthInt + "px";


                //side2HeightInt = refMain.current.offsetHeight;
                side2HeightInt = refScrollbox.current.scrollHeight;
                side2Height = side2HeightInt + "px";



                // if(side2WidthInt > 300) 
                //     side2Width = side2WidthInt + "px";

                // if(side2WidthInt > 550) 
                //     side2Width = "550px";


                //side2BgSize = (refScrollbox.current.offsetHeight) + "px " + (refScrollbox.current.offsetHeight) + "px";

                //side2BgSize = (side2Width * 2) + "px " + (side2Width * 2) + "px";

                if(side2WidthInt > 300) 
                {
                    if(panelStatus.call === 'off' || panelStatus.call === 'closed')
                    {
                        side2BgImage = "/_resources/logos/tpc_profit_pie.svg";

                    }
                }
                side2BgSize = "auto 100vh";
                side2BgPosition = "top -15px right -325px";

                side2Transform = "rotate(0deg)";


                //---------------------




            }else{


                if(refScrollbox.current.offsetWidth < 801)
                {
                    //console.log("above");
                    mainWidth = "92%";
                    mainMargin = "0 0 0 0";
                    mainPadding = "0 0 100px 4%";
                }else{
                    //console.log("below");
                    mainWidth = "92%";
                    mainMargin = "0 0 0 0";
                    mainPadding = "0 0 100px 4%";
                }


            }






            //---------------------------------------------------

            // var contentWidth;
            // var contentMargin;

            // if(width > 1150)
            // {
            //     //console.log("above");
            //     contentWidth = 750 + "px";
            //     contentMargin = "0 0 50px 50px";
            // }else{

            //     if(width > 1100)
            //     {
            //         contentWidth = "90%";
            //         contentMargin = "0 0 50px 5%";
            //     }
            //     else
            //     if(width < 600)
            //     {
            //         contentWidth = "96%";
            //         contentMargin = "0 0 50px 2%";

            //     }else{
            //         contentWidth = "100%";
            //         contentMargin = "0 0 50px 0";
            //     }
            // }



            var scrollboxBackgroundColor = "#fff";
            if(calcHash) scrollboxBackgroundColor = "#fff"; //"#191e28";
            






            //----------------------------------------------------

            var catalog = {

                top:{
    
                    textColor:"#fff"
                    
                },
                bar:{
    
                    backgroundColor:"#454d5f"
                    
                }
         
            }


            var topbarObj;
            topbarObj = {

                width:"calc(100% - 0px)",
                height:refTopbar.current.offsetHeight+(3)+"px",
                margin:"0 0 0 0"

            };
            var scrollboxObj;
            scrollboxObj = {

                backgroundColor:scrollboxBackgroundColor,
                width:"calc(100% - 0px)",
                height:"calc(100% - "+refTopbar.current.offsetHeight+"px)",
                margin:"0 0 50px 0"

            };
            if(refSide && refSide.current)
            {

                topbarObj = {
                
                    width:"calc(100% - "+refSide.current.offsetWidth+"px)",
                    height:refTopbar.current.offsetHeight+"px",
                    margin:"0 0 0 "+refSide.current.offsetWidth+"px"
                }
                scrollboxObj = {
                
                    backgroundColor:scrollboxBackgroundColor,
                    width:"calc(100% - "+refSide.current.offsetWidth+"px)",
                    height:"calc(100% - "+refTopbar.current.offsetHeight+"px)",
                    margin:"0 0 50px "+refSide.current.offsetWidth+"px"
                }

            }


            setStyling({

                side:{

                    backgroundColor:"#191e28"

                },
                topbar:topbarObj,
                scrollbox:scrollboxObj,
                main:{
        
                    width       :mainWidth,
                    margin      :mainMargin,
                    padding     :mainPadding
                },
                side2:{

                    backgroundImage     :side2BgImage, 
                    backgroundPosition  :side2BgPosition,
                    backgroundSize      :side2BgSize,            
                    width               :side2Width,
                    height              :side2Height,
                    transform           :side2Transform

                },
                catalog:catalog,
                // content:{
        
                //     width       :contentWidth,
                //     margin      :contentMargin

                // }

            });

        //}

        //console.log("scrollbox w: "+refScrollbox.current.offsetWidth);

        setContentHeight(refMain.current.offsetHeight);


    },
    [

        location,

        isLoaded,

        width,
        height,

        refTopbar,
        refScrollbox,
        refSide,
        refMain,
        refContent,

        panelStatus,

        calcHash

    ])
















    //=============================================
    // state/effect - hash check
    //=============================================

    useEffect(() => {


        var currentHash;
        var regxCalculator = new RegExp("#!Calculator","gi");
        var regxReport = new RegExp("#!Report","gi");

        // console.log("::::: "+location.hash);

        if(location.hash !== '')
        {

            currentHash = location.hash;

            console.log(Object.prototype.toString.call(currentHash));
            console.log(currentHash.match(regxReport));

            if(currentHash.match(regxCalculator) && reportHash)
            {
                setCalcHash(true);
                setReportHash(false);
                //setStyleAfterHash(true);
                //alert("fromReport: "+fromReport)

            }

            if(currentHash.match(regxReport) && calcHash)
            {
                //alert('reportHash');

                setCalcHash(false);
                setReportHash(true);
                //setStyleAfterHash(true);

            }



            if(currentHash.match(regxReport))
            {
               
                setFromReport(true);

            }


            // if(calcHash || reportHash)
            // {
            //     refScrollbox.current.scrollTop = 0;
            // }

        }else{

            // if(!calcHash && !reportHash && !styleAfterHash) 
            // {
            //     refScrollbox.current.scrollTop = 0;
            //     //alert(refScrollbox.current.scrollTop);

            // }


            if(fromReport) setFromReport(false);

        }




    },
    [

        location,pathname,
        isLoaded,
        calcHash,
        reportHash,fromReport
        //styleAfterHash

    ]);










    //=============================================
    // state/effect - isMobiPanel
    //=============================================

    const [isMobiPanel, setIsMobiPanel] = useState(false);

    useEffect(() => {

        if(!thresholdSide2)
        {
            if(!isMobiPanel) 
            {
                setIsMobiPanel(true);
                setPanelStatus({

                    show:false,
                    call:'closed',
                    data:[]

                });

            }

        }else{

            if(isMobiPanel) 
            {
                setIsMobiPanel(false); 
                setPanelStatus({

                    show:true,
                    call:'graphic',
                    data:[]
    
                });
        
            }

        }

    },
    [

        location,
        thresholdSide2,
        isMobiPanel

    ])











    //=============================================
    // handle
    //=============================================

    const handle = (hndl) => {


        var calcs,resultCalcs;
        var keyname;

        switch(hndl.call)
        {
        case'breadcrumb':

            var pathArr = pathname.split("/");
            var url = "/";

            if(hndl.data !== 'Home')
            {
                var i;
                for(i=0; i < pathArr.length; i++)
                {
                    if(pathArr[i] === hndl.data)
                    {
                        url = url + hndl.data;
                        break;
                    }else{
                        url = url + pathArr[i]+"/";
                    }
                }

            }

            //if(url.charAt(url.length-1) === '/') 
                //url = url.substring(0,url.length-1);

            if(url.charAt(url.length-1) !== '/') 
                url = url + "/";

            //alert(url);
            
            history.push(url);

        break;
        case'menu':

            switch(hndl.data)
            {
            case'close':
                setShowMenu(false);
            break;
            default:
                setShowMenu(true);
            }
        break;
        case'fetched'://SUCCESS

            //alert('fetched');
            //console.log(hndl.message);
            // setShowMessage({

            //     call:"showMessage",
            //     message:"Calcs were fetched",
            //     duration:1500

            // });
            setRunFetch(false);
            setWasFetched(true);



            if(fetchData.call === 'fetch' && zoneObj['calcs'])
            {
                calcs = JSON.parse(JSON.stringify(zoneObj['calcs']));
                resultCalcs = JSON.parse(JSON.stringify(hndl.result.calcs));
                for(keyname in resultCalcs)
                {
                    calcs[keyname] = resultCalcs[keyname]
                }
            }else{

                calcs = JSON.parse(JSON.stringify(hndl.result.calcs));
                //calcs = hndl.result.calcs;
            }

            if(zoneObj['calcs'] && zoneObj['calcs'] !== undefined)
            {

                dispatch(ModifyZoneStore({

                    type:"DELETE_ZONE",//UPDATE_ZONE or DELETE_ZONE
                    item:{
                        key     :"calcs",
                        value   :false
                    }
        
                })); 

            }
            dispatch(ModifyZoneStore({

                type:"UPDATE_ZONE",//UPDATE_ZONE or DELETE_ZONE
                item:{
                    key     :"calcs",
                    value   :calcs
                }
    
            })); 



        break;
        case'error':
    
            //alert("fetch error...\n"+JSON.stringify(hndl));
            setShowMessage({

                call:"showMessage",
                message:hndl.message,
                duration:1500

            });

        break;
        case'done':

            setShowMessage(false);
            setRunFetch(false);
            setWasFetched(true);
        break;
        default:
        }


    };


    //=============================================
    // handleClick
    //=============================================

    const handleClick = (hndl) => {


        switch(hndl.call)
        {
        case'calc':



            //alert(JSON.stringify(hndl,null,2));

            //QUERY

            // var url = "";
            // //var url = pathname+"/Calculator/";
            // if(hndl.data.id)
            // {
            //     url = url + "?id=" + hndl.data.id + "&name="+hndl.data.name
            // }else{
            //     url = url + "?id=calc&name=The Profit Calculator"
            // }
            // if(url.match(/\/\//gi)) url = url.replace(/\/\//gi,"/");
            // history.push(url); 


            //STATE
            history.push("#!Calculator");
            setCalcHash(true);
            if(hndl.data.id)
            {
                setCalcId(hndl.data.id);
                setCalcName(hndl.data.name);
            }else{
                setCalcId("calc");
                setCalcName("The Profit Calculator");
            }//==

        break;
        case'report':


            //QUERY

            // var url = "";
            // //var url = pathname+"/Calculator/";
            // if(hndl.data.id)
            // {
            //     url = url + "?id=" + hndl.data.id + "&name="+hndl.data.name
            // }else{
            //     url = url + "?id=calc&name=The Profit Calculator"
            // }
            // if(url.match(/\/\//gi)) url = url.replace(/\/\//gi,"/");
            // history.push(url); 


            //STATE
            history.push("#!Report");
            setReportHash(true);
            if(hndl.data.id)
            {
                setCalcId(hndl.data.id);
                setCalcName(hndl.data.name);
            }else{
                setCalcId("calc");
                setCalcName("The Profit Calculator");
            }//==

        break;
        default:
        }


        //setWasReplaced(false);

        setContentHeight(0);
        //document.getElementById('scrollbox').scrollTop = 0;
        refScrollbox.current.scrollTop = 0;

    };




    //=============================================
    // SortBy = state - handle - effect
    //=============================================

    const [showSortByMenu, setShowSortByMenu] = useState(false);

    const handleSortBy = (hndl) => {

   
        //alert('sortby: '+JSON.stringify(hndl.data));
        //console.log(JSON.stringify(Scene,null,2));


        switch(hndl.call)
        {
        case'showMenu':

            if(showSortByMenu)
            {
                if(location.hash === "#!sortmenu") 
                {
                    history.goBack();
                }
                setShowSortByMenu(false);

            }else{

                setShowSortByMenu(true);
                if(location.hash !== "#!sortmenu") 
                {
                    history.push("#!sortmenu");
                }

            }

        break;
        default:

            if(showSortByMenu)
            {
                if(location.hash === "#!sortmenu") 
                {
                    history.goBack();
                }
                setShowSortByMenu(false);
            }

            var revScene = [];
            var top = Scene[0][0];
            var bar = Scene[0][1];
            var items = Scene[0][2].items;
            var sortedItems = SortItems({

                data:hndl.data,
                items:items,
                zoneObj:zoneObj

            });
            if(sortedItems.length > 0)
            {
                revScene.push([top,bar,{

                    call:"items",
                    items:sortedItems
                
                }]);
                setScene(revScene);
                setSortByData(hndl.data);

            }


        }//switch


    }

    useEffect(() => {

        if(location.hash !== "#!sortmenu" && showSortByMenu)
        { 
            setShowSortByMenu(false);

        }
    },
    [
        isLoaded,
        sortByData,       
        location,
        showSortByMenu

    ]);







    //=============================================
    // state/effect - processMenu
    //=============================================

    const [showProcessMenu, setShowProcessMenu] = useState(false);
    const [processMenuStyl, setProcessMenuStyl] = useState(false);
    const [processMenuData, setProcessMenuData] = useState(false);
    const [processFrom, setProcessFrom] = useState(false);

    useEffect(() => {

        if(location.hash !== "#!calcmenu" && showProcessMenu)
        { 
            setShowProcessMenu(false);
            setProcessMenuStyl(false);
            setProcessMenuData(false);
        }

        if(location.hash !== "#!calcmenu" 
        && location.hash !== "#!favorite" 
        && showFavoriteForm
        )
        { 
            setShowProcessMenu(false);
            setProcessMenuStyl(false);
            setProcessMenuData(false);
            setShowFavoriteForm(false);
            setProcessFrom(false);
        }

    },
    [
        location,
        showProcessMenu,
        showFavoriteForm
    ])

    const handleProcessMenu = (hndl) => {

        // console.log("READOUT::::::"+JSON.stringify(props.data,null,2));
        //alert(JSON.stringify(hndl,null,2));


        const onoff = (hndl) => {


            if(processFrom)
            {
                if(location.hash === "#!"+processFrom) 
                {
                    history.goBack();
                }
                setProcessFrom(false);
            }
            else
            if(showProcessMenu) 
            {
                setShowProcessMenu(false);
                setProcessMenuStyl(false);
                setProcessMenuData(false);

                if(location.hash === "#!calcmenu") 
                {
                    history.goBack();
                }

            }
            else
            {


                if(location.hash !== "#!calcmenu") 
                {
                    history.push("#!calcmenu");
                }
        
                var fromTop = "62px";
                if(hndl.rowId !== 'calc')
                {
                
                    var currHeight = 0;
                    var totalHeight = 62;
                    //var calcTile = document.getElementById('calcTile');
                    var ref;
                    for(var rowRef of rowRefs)
                    {
                        ref = document.getElementById(rowRef.id);
                        totalHeight = totalHeight + ref.offsetHeight; 

                        //alert(totalHeight);
                        if(rowRef.id === hndl.rowId)
                        {
                            //ref = rowRefs[i];
                            currHeight = ref.offsetHeight;
                            break;
                        } 
                    }
                    //alert(totalHeight);
                    fromTop = totalHeight - currHeight + "px";



                    // if(ref)
                    // {

                    //     var diff = 23;
                    //     if(width < 575) diff = 30;
                    //     alert(ref.id+" "+ref.offsetTop);

                    //     var rect = ref.getBoundingClientRect();
                    //     //console.log(rect.top, rect.right, rect.bottom, rect.left);
                    //     var y = rect.top - (calcTile.offsetHeight/2) - diff 
                    //     fromTop = y + "px";

                    //     // var y = LibElem.GetOffset( document.getElementById(hndl.rowId) ).top;//returns obj top and left
                    //     // y = y - (calcTile.offsetHeight/2) - diff;

                    //     // fromTop = y + "px";

                  
                    // }
                    
                }


                console.log(JSON.stringify(hndl.data,null,2));
                //alert('hndl');

                setShowProcessMenu(true);
                setProcessMenuStyl({

                    top:fromTop

                });
                setProcessMenuData(hndl.data);

            }


        }




        var calcs,calc;
        var message;


        var saveName;
        var saveDescr;
        var errorMessage;
        var errorMessageDupe;


        //alert(hndl.call);

        switch(hndl.call)
        {
        case'refetch':

            dispatch(ModifyZoneStore({

                type:"DELETE_ZONE",//UPDATE_ZONE or DELETE_ZONE
                item:{
                    key     :"calcs",
                    value   :false
                }

            })); 

            setRunFetch(true);
            setWasFetched(false);

        break;
    //===============================================================
        case'complete':
        case'error':
        
            //console.log(hndl.message);
        
            setRunAlter(false); 
            
            switch(hndl.call) 
            {
            case'error':
                setShowMessage({
        
                    call:"showMessage",
                    message:hndl.message,
                    duration:false
        
                }); 
                //setRunTimeout(1500); 
            break;
            default:
                
                // setShowMessage({
        
                //     call:"showMessage",
                //     message:hndl.message,
                //     duration:false
        
                // }); 
                //setRunTimeout(20);  
            
            }
        
            setShowRenameForm(false);
            setShowSaveForm(false);
            setShowFavoriteForm(false);
            onoff(hndl);
        
            //alert("ProcMenu: hndl.reset : "+hndl.reset);
            if(hndl.reset)
            {

                //alert('fetch again');
                dispatch(ModifyZoneStore({

                    type:"DELETE_ZONE",//UPDATE_ZONE or DELETE_ZONE
                    item:{
                        key     :"calcs",
                        value   :false
                    }
    
                })); 
    
                setRunFetch(true);
                setWasFetched(false);
        
            }

        break;
        case'done':
            setRunAlter(false);
            setShowMessage(false);
            setShowRenameForm(false);
            setShowSaveForm(false);
            setShowFavoriteForm(false);
            setCalcId('');
            onoff(hndl);
        break;
        case'cancel':
            setShowMessage(false);
            setShowRenameForm(false);
            setShowSaveForm(false);           
            setShowFavoriteForm(false);
            onoff(hndl);
            if(location.hash === "#!favorite") 
            {
                history.goBack();
            }
        break;
        case'rename':
        
            switch(hndl.action)
            {
            case'process':
        
                calcs = JSON.parse(JSON.stringify(zoneObj['calcs']));
        
                if(calcs[calcId] && calcs[calcId] !== undefined)
                {
        
                    saveName = document.getElementById("save_name");
                    saveDescr = document.getElementById("save_descr");
                    errorMessage = document.getElementById("error_message");
        
                    if(saveName.value === '' || saveDescr.value === '')
                    {
        
                        message = '';
                        if(saveName.value === '' || saveName.value === 'Calculator Name') 
                        {
                            message = message + 'Please type "Name"';
                            saveName.style.backgroundColor = 'lightyellow';
                        }
                        if(saveDescr.value === '' || saveDescr.value === 'Calculator Description') 
                        {
        
                            if(message !== '') 
                                message = 'Please type "Description"';
                            else 
                                message = message + ' and "Description"';
        
                            saveDescr.style.backgroundColor = 'lightyellow';
                        }
        
        
                        errorMessage.style.display = "inline";
                        errorMessage.innerHtml = message;
        
                        // setShowMessage({
        
                        //     call:"showMessage",
                        //     message:message,
                        //     duration:false
            
                        // }); 
        
        
                    }else{
        
        
                        calc = calcs[calcId];
                        calc.data.name = saveName.value;
                        calc.data.description = saveDescr.value;
        
        
                        // console.log("Calc: "+JSON.stringify(calc,null,2));
                        // alert("calc");
        
                        setRunAlter({
        
                            action_call :"save",
                            action_name :"Edit/Rename Calculator",
                            item:calc
        
                        });
                        setCalcId('');
                        setShowRenameForm(false);  
                
                    }
                    
        
                }
        
            break;
            default:
                setCalcId(hndl.calcId);
                setShowRenameForm(true);
                // setMessageData({
        
                //     call:"showConfirm",
                //     message:"Confirm Message"
        
                // });

            }
        
        break;
        // case'saveas':
        
        //     switch(hndl.action)
        //     {
        //     case'process':
        
        //         calcs = JSON.parse(JSON.stringify(zoneObj['calcs']));
        
        //         if(calcs[calcId] && calcs[calcId] !== undefined)
        //         {
        
        //             saveName = document.getElementById("save_name");
        //             saveDescr = document.getElementById("save_descr");
        //             errorMessage = document.getElementById("error_message");
        
        //             if(saveName.value === '' || saveDescr.value === '')
        //             {
        
        //                 message = '';
        //                 if(saveName.value === '' || saveName.value === 'Calculator Name') 
        //                 {
        //                     message = message + 'Please type "Name"';
        //                     saveName.style.backgroundColor = 'lightyellow';
        //                 }
        //                 if(saveDescr.value === '' || saveDescr.value === 'Calculator Description') 
        //                 {
        
        //                     if(message !== '') 
        //                         message = 'Please type "Description"';
        //                     else 
        //                         message = message + ' and "Description"';
        
        //                     saveDescr.style.backgroundColor = 'lightyellow';
        //                 }
        
        
        //                 errorMessage.style.display = "inline";
        //                 errorMessage.innerHtml = message;
        
        //                 // setShowMessage({
        
        //                 //     call:"showMessage",
        //                 //     message:message,
        //                 //     duration:false
            
        //                 // }); 
        
        
        //             }else{
        
        
        //                 calc = calcs[calcId];
        //                 calc.data.name = saveName.value;
        //                 calc.data.description = saveDescr.value;
        
        
        //                 // console.log("Calc: "+JSON.stringify(calc,null,2));
        //                 // alert("calc");
        
        //                 setRunAlter({
        
        //                     action_call :"saveas",
        //                     action_name :"Edit/Rename Calculator",
        //                     item:calc
        
        //                 });
        //                 setCalcId('');
        //                 setShowSaveForm(false);  
                
        //             }
                    
        
        //         }
        
        //     break;
        //     default:
        //         setCalcId(hndl.calcId);
        //         setShowSaveForm(true);
        //         // setMessageData({
        
        //         //     call:"showConfirm",
        //         //     message:"Confirm Message"
        
        //         // });
        
        //     }
        
        // break;
        case'saveas':


            switch(hndl.action)
            {
            case'process':

                calcs = JSON.parse(JSON.stringify(zoneObj['calcs']));
        
                saveName = document.getElementById("save_name");
                saveDescr = document.getElementById("save_descr");
                errorMessage = document.getElementById("error_message");
                errorMessageDupe = document.getElementById("error_message_duplicate");


                saveName.style.backgroundColor = 'white';
                saveDescr.style.backgroundColor = 'white';
                errorMessage.style.display = "none";
                errorMessageDupe.style.display = "none";

                
                if(saveName.value === '' || saveDescr.value === '')
                {

                    message = '';
                    if(saveName.value === '' || saveName.value === 'Calculator Name') 
                    {
                        message = message + 'Please type "Name"';
                        saveName.style.backgroundColor = 'lightyellow';
                    }
                    if(saveDescr.value === '' || saveDescr.value === 'Calculator Description') 
                    {

                        if(message !== '') 
                            message = 'Please type "Description"';
                        else 
                            message = message + ' and "Description"';

                        saveDescr.style.backgroundColor = 'lightyellow';
                    }
                    errorMessage.style.display = "inline";
                    //errorMessage.innerHtml = message;

                    // setShowMessage({

                    //     call:"showMessage",
                    //     message:message,
                    //     duration:false

                    // }); 


                }
                else
                {
                    let okay = true;
                    if(zoneObj.calcs && zoneObj.calcs !== undefined)
                    {
                        var calcKey;
                        for(calcKey in zoneObj.calcs)
                        {
                            calc = zoneObj.calcs[calcKey];
                            if(calc.data.name.toUpperCase() === saveName.value.toUpperCase())
                            {
                                okay = false;
                                break;
                            }
            
                        }
            
                    }
                    if(!okay)
                    {
            
                        //alert('not okay')

                        message = message + 'Duplicate "Name"';
                        saveName.style.backgroundColor = 'lightyellow';
                
                        errorMessageDupe.style.display = "inline";
                        //errorMessageDupe.innerHtml = message;

            
                    }else{
            

                        //----------------------------------------------------------
                        var timestamp;
                        var randnum;
                        var new_recid;
                    

                        timestamp = LibDate.Timestamp('noformat');
                        randnum = Math.floor(Math.random() * 9000 + 1000);

                        new_recid = parseInt(timestamp) + randnum;
                        new_recid = new_recid.toString();
                        new_recid = new_recid.replace(/-/g,'');
                        //-----------------------------------------------------------


                        calc = calcs[calcId];
                        calc.data.name = saveName.value;
                        calc.data.description = saveDescr.value;
        
        
                      
                        var new_name, new_descr, new_value;

                        new_name = saveName.value;
                        new_descr = saveDescr.value;
                        new_value = JSON.parse(JSON.stringify(calc.data.value));

                        //console.log("Calc: "+JSON.stringify(calc,null,2));

                        // console.log("Alter Stuff: "+JSON.stringify({

                        //     new_recid:new_recid,
                        //     new_name:new_name,
                        //     new_descr:new_descr,
                        //     new_value:new_value

                        // },null, 2));

                    
                        // alert("calc");

        
                        setRunAlter({
        
                            action_call :"saveas",
                            action_name :"Calculator Save As",
                            item:{
                                reckey:"rec-calculator-"+new_recid,
                                recid:new_recid,
                                name:"calculator-"+new_recid,
                                data:{
                                    name:new_name,
                                    value:new_value,
                                    favorite:5,
                                    description:new_descr
                                }
                            
                            }
        
                        });
                        setCalcId('');
                        setShowSaveForm(false);  



                    }


                }//==

        

            break;
            default:
                setCalcId(hndl.calcId);
                setShowSaveForm(true);
                // setMessageData({
        
                //     call:"showConfirm",
                //     message:"Confirm Message"
        
                // });
        
            }



        break;
        case'favorite':


            switch(hndl.action)
            {
            case'process':


                //alert(calcId);
        
                calcs = JSON.parse(JSON.stringify(zoneObj['calcs']));
                if(calcs[calcId] && calcs[calcId] !== undefined)
                {
        
                    calc = calcs[calcId];
        
                    var saveFavorite = document.getElementById(calcId+"_favorite").value;
        
                    calc.data.favorite = saveFavorite;
        
                    // console.log("Calc: "+JSON.stringify(calc,null,2));
                    // alert("calc favorite");
        
                    setRunAlter({
        
                        action_call :"save",
                        action_name :calc.data.name + " favorite saved",
                        item:calc
        
                    });
                    setCalcId('');
                    setShowFavoriteForm(false);  
                    if(location.hash === "#!favorite") 
                    {
                        history.goBack();
                    }
                    
                }
        
            break;
            default:
                setCalcId(hndl.calcId);
                if(hndl.from !== undefined) setProcessFrom(hndl.from);
                setShowFavoriteForm(true);
                // setShowMessage({
        
                //     call:"showConfirm",
                //     message:"Confirm Message"
        
                // });


                if(hndl.from === 'stars')
                {

                    setProcessMenuData(hndl.data);
                    if(location.hash !== "#!favorite") 
                    {
                        history.push("#!favorite");
                    }

                }

        
            }
        
        break;
        case'delete':


            //alert('delete');
        
            switch(hndl.action)
            {
            case'process':

                //alert('process delete');
        
                calcs = JSON.parse(JSON.stringify(zoneObj['calcs']));
                if(calcs[calcId] && calcs[calcId] !== undefined)
                {
        
                    calc = calcs[calcId];
        
                    // console.log("Calc: "+JSON.stringify(calc,null,2));
                    // alert("calc delete");
        
                    setRunAlter({
        
                        action_call :"delete",
                        action_name :calc.data.name + " deleted",
                        item:calc
        
                    });
                    setCalcId('');
                    setShowMessage(false);
                    //console.log("captured: "+JSON.stringify(props.captured,null,2));
        
                }
            
            break;
            default:


                if(location.hash !== "#!calcmenu") history.push("#!calcmenu");

                setCalcId(hndl.calcId);

                message = 'Would you like to "Delete" this Calculator?';
                calcs = JSON.parse(JSON.stringify(zoneObj['calcs']));
                if(calcs[hndl.calcId] && calcs[hndl.calcId] !== undefined)
                {
                    calc = calcs[hndl.calcId];
                    message = 'Would you like to "Delete" calculator '+calc.data.name+'?';
                };
                setShowMessage({
        
                    actionCall:"delete",       
                    call:"showConfirm",
                    message:message,
                    duration:false

                }); 
      
            }
        break;
        case'readr':


            setRunAlter(false);
            setShowMessage(false);
            setShowRenameForm(false);
            setShowFavoriteForm(false);
            onoff(hndl);
        
            props.primeProps.handle({
        
                call:"showReadr",
                data :{
        
                    data:{
                        name:"Readr",
                        value:hndl.data,
                        justReadr:true
                    },
                    flyr:{
                        type:'print',
                        styl:{
                            maxWidth:750
                        }
                    }
        
                }
        
            });
        
        
        break;
        case'edit':

            //alert(JSON.stringify(hndl,null,2));

            //history.replace('/Pricing/');
            //history.goBack();
           

            calcs = JSON.parse(JSON.stringify(zoneObj['calcs']));
            if(calcs[hndl.calcId] && calcs[hndl.calcId] !== undefined)
            {
                calc = calcs[hndl.calcId];

                handleClick({

                    call:"calc",
                    data:{

                        id:hndl.calcId,
                        name:calc.data.name
                    }
                });  
                
                
            }else{


                alert("Calc. Not Available");
            
            }// == 


        break;
        default:
            
            onoff(hndl);
        }//switch


    };









    //=============================================
    // Report= state - handle - effect
    //=============================================

    //const [showReport, setReport] = useState(false);

    const handleReport = (hndl) => {

        switch(hndl.call)
        {
        case'calc':

            //alert('handleReport - calc: '+JSON.stringify(hndl.data));
            handleClick({
                call:'calc',
                data:{
                    id:hndl.data.calcId,
                    name:hndl.data.calcName
                }
            });
        break;
        case'goBack':

            //alert('goBack');
            history.goBack();
            refScrollbox.current.scrollTop = 0;
            setContentHeight(0);
        break;
        default:
        }//switch

    }











    //=============================================
    // state/effect - fetch, alter
    //=============================================

    const [runFetchPanel, setRunFetchPanel] = useState(false);
    const [wasFetchedPanel, setWasFetchedPanel] = useState(false);
    const [fetchPanelData, setFetchPanelData] = useState({

        call:"fetchAll",
        callb:false//used if getting a specific

    });

    useEffect(() => {


        if(!zoneObj['info_panel'] || zoneObj['info_panel'] === undefined)
        {
            // run fetch - data is set on handleProcess
            //console.log('Running Fetch');
            if(!runFetchPanel) 
            {
                setRunFetchPanel(true);
                setFetchPanelData({

                    call:"fetchAll",
                    callb:false//used if getting a specific
            
                });           
            }

        }else{

            if(!zoneObj['info_panel'].ads || zoneObj['info_panel'].ads === undefined)
            {
                // console.log("Has Scene - part 2");
                // console.log("wasFetched: "+wasFetched);
                if(!runFetchPanel) 
                {
                    setRunFetchPanel(true);
                    setFetchPanelData({

                        call:"fetch",
                        callb:'ads'//used if getting a specific
                
                    });   
                }


            }
            else
            if(!zoneObj['info_panel'].howto || zoneObj['info_panel'].howto === undefined)
            {


                var info_panel = JSON.parse(JSON.stringify(zoneObj.info_panel));


                var howto = [];
                if(PanelInfo.howto)
                {
                    howto = PanelInfo.howto
                }
                info_panel.howto = howto;

                //----------------------------
                //dispatch
                //----------------------------

                if(info_panel)
                {
                    //console.log("KNOWLEDGE BASE::: "+JSON.stringify(hndl.result,null,2));
                    //alert("dispatch knowledge_base");
                
                    dispatch(ModifyZoneStore({

                        type:"UPDATE_ZONE",//UPDATE_ZONE or DELETE_ZONE
                        item:{
                            key     :"info_panel",
                            value   :info_panel
                        }
            
                    })); 

                }

            

            }
            else
            {

                if(runFetchPanel) 
                {
                    setRunFetchPanel(false);
                    setWasFetchedPanel(true);

                }

            }

        }


    },
    [
        zoneObj,dispatch,
        wasFetchedPanel,
        runFetchPanel

    ]);



    //=============================================
    // state/effect/handle - panelStatus
    //=============================================

    useEffect(() => {

        if(panelStatus.call !== 'closed')
        {
            if(!thresholdSide2)
            {

                if(panelStatus.call === 'init')
                {
                    setPanelStatus({

                        show:true,
                        call:'closed',
                        data:[]

                    });

                } 


            }else{

                if(panelStatus.call === 'init')
                //if(panelStatus.call !== 'graphic' && panelStatus.call !== 'ads')
                {

                    setPanelStatus({

                        show:true,
                        call:'howto',
                        data:[]

                    });


                } 




            }


        }

    },
    [

        zoneObj,
        location,
        panelStatus,
        thresholdSide2


    ])



    const handlePanel = (hndl) => {

        //alert("hndl.call " + hndl.call);

        switch(hndl.call)
        {
        case'fetched':


            //alert('fetchedPanel');

            if(runFetchPanel && !wasFetchedPanel)
            {

                setRunFetchPanel(false);
                setWasFetchedPanel(true);

                console.log("MESSAGE: "+hndl.message);
                console.log("RESET: "+hndl.reset);
                console.log("RESULT: "+JSON.stringify(hndl.result,null,2));

        
                var msg = hndl.message;

                if(Object.keys(hndl.result).length > 0)
                {
                    msg = msg + " - Has Data";
                }else{
                    msg = msg + " - Has No Data";
                }

                console.log(msg);


                var info_panel = false;
                if(localStorage.editMode)
                {
                    // //EDIT
                    if(Object.keys(hndl.result).length > 0)
                    {
                        if(hndl.result 
                        && hndl.result !== undefined)
                        {
                            info_panel = hndl.result;
                        }
                    }
                    //console.log("===== edit - fetched =====");
                    //console.log("info_panel: "+JSON.stringify(info_panel,null,2));
                    //alert('edit - fetched');


                }else{

                    // //READ
                    if(Object.keys(hndl.result).length > 0)
                    {
                        if(hndl.result 
                        && hndl.result !== undefined)
                        {
                            info_panel = hndl.result;
                        }
                    }


                    console.log("===== read - fetched =====");
                    console.log("info_panel: "+JSON.stringify(info_panel,null,2));
                    //alert('read - fetched');


                }//editMode

            


              
                //----------------------------
                //dispatch
                //----------------------------

                if(info_panel)
                {

                    // console.log("INFO_PANEL::: "+JSON.stringify(hndl.result,null,2));
                    // alert("dispatch info_panel");
                
                    dispatch(ModifyZoneStore({

                        type:"UPDATE_ZONE",//UPDATE_ZONE or DELETE_ZONE
                        item:{
                            key     :"info_panel",
                            value   :info_panel
                        }
            
                    })); 

                }

            }//runFetchPanel



        break;
        case'url':

            // check url

            // var href = window.location.href;                //returns the href (URL) of the current page
            // var host = window.location.host;                //returns the domain with port number
            // var hostname = window.location.hostname;        //returns the domain name of the web host
            // var pathname = window.location.pathname;        //returns the path and filename of the current page
            // var protocol = window.location.protocol;        //returns the web protocol used (http: or https:)
            // var assign = window.location.assign;            //loads a new document

            var externalUrl = false;
            var url;
            url = hndl.data;

            var internalSite = "site.site/";

            var regxInternalSite = new RegExp(internalSite,"gi");
            var regxHost = new RegExp(window.location.host,"gi");
            if(url.match(regxInternalSite))
            {
                url = url.split(".site/")[1];
            }//==
            else
            if(!url.match(regxHost))
            {
                externalUrl = true;
            }//==
            //alert("handlePanel url " + url);



            if(externalUrl)
            {
                props.primeProps.handle({

                    call:'url',
                    data:{

                        action:'target',
                        url:url

                    }

                });

            }else{

                props.primeProps.handle({

                    call:'url',
                    data:{

                        action:'push',
                        url:url

                    }

                });

            }


        break;
        case'topbar':


            if(!thresholdSide2)
            {
                setPanelStatus({

                    show:true,
                    call:'howto',
                    data:PanelInfo.howto,
                    height:"auto"
    
                });
    

            }else{

                setPanelStatus({

                    show:true,
                    call:'graphic',
                    data:[]
    
                });

            }

        break;
        case'close':


            if(!thresholdSide2)
            {
                setPanelStatus({

                    show:false,
                    call:'closed',
                    data:[]

                });

            }else{

                setPanelStatus({

                    show:true,
                    call:'graphic',
                    data:[]
    
                });

            }


        break;
        case'ads':


            //alert("ADS: "+JSON.stringify(PanelInfo.ads));

            setPanelStatus({

                show:true,
                call:'ads',
                data:PanelInfo.ads,
                height:"auto"

            });

        break;
        case'howto':

            setPanelStatus({

                show:true,
                call:'howto',
                data:PanelInfo.howto,
                height:"auto"

            });

        break;
        case'graphic':

            setPanelStatus({

                show:true,
                call:'graphic',
                data:[]

            });

        break;
        default:


            if(panelStatus.call === 'closed')
            {

                setPanelStatus({

                    show:true,
                    call:'opened',
                    data:[]

                });


            }else{



                if(!thresholdSide2)
                {

                    //alert('thresholdSide2')

                    setPanelStatus({
    
                        show:false,
                        call:'closed',
                        data:[]
    
                    });
    
                }else{
    
                    setPanelStatus({
    
                        show:true,
                        call:'graphic',
                        data:[]
        
                    });
    
                }



            }




        }

    }




    //=============================================
    // handleScroll
    //=============================================

    const handleScroll = () => {


        if(refScrollbox.current.scrollHeight > refSide2.current.offsetHeight)
        {
            console.log("handleScroll - fix side2");
            console.log(refScrollbox.current.scrollHeight+"\n"+contentHeight+"\n"+refSide2.current.offsetHeight);
            setContentHeight(refScrollbox.current.scrollHeight);
        }


        // Adjust to refMain
        if(refScrollbox.current.scrollHeight > refMain.current.offsetHeight)
        {
            if(refMain.current.scrollHeight > refSide.current.offsetHeight
            || refMain.current.scrollHeight === refSide.current.offsetHeight
            )
            {
                console.log("handleScroll - revert to refMain");
                console.log(refScrollbox.current.scrollHeight+"\n"+contentHeight+"\n"+refSide2.current.offsetHeight);
                setContentHeight(refMain.current.scrollHeight);

            }
        }


    }














    if(!isLoaded) 
    {

        //return <div className="area-title">Loading...</div>


        return (

            <Stage
            id={"stage"}
            ref={refStage}
            >

                <Side
                ref={refSide}
                >
                </Side>


                <Topbar
                ref={refTopbar}
                width={styling.topbar.width} 
                margin={styling.topbar.margin}
                >
                </Topbar>
                

                <ScrollBox
                ref={refScrollbox}
                width={styling.scrollbox.width} 
                height={styling.scrollbox.height} 
                margin={styling.scrollbox.margin}
                >

                    <Main
                    ref={refMain}
                    // width={styling.main.width} 
                    // margin = {styling.main.margin}
                    >


                    </Main>    

                </ScrollBox>


            </Stage>




        )



    } 
    else 
    {


        return (

            <Stage
            id={"stage"}
            ref={refStage}
            >



                {
                //=========================================================
                runFetchPanel
                ?
                    <div>

                        {/* <Loading /> */}
                        <FetchPanel

                            primeProps={props.primeProps}
                            handle={handlePanel}
                            //duration={2500}
                            data={fetchPanelData}
                            // formArr={props.formArr}
                            // captured={props.captured}

                        />

                    </div>

                :null
                //=========================================================
                }






                {width < thresholdSide1 && showMenu
                ?

                    <div>

                        <MenuAlfa
                        onClick={(v) => handle({

                            call:'menu',
                            data:"close"

                        })}
                        ></MenuAlfa>
                        <Menu
                        >
                            <MenuControls>

                                <div className="Title">

                                    {"Menu"}

                                </div>

                                <div 
                                className="Button"
                                onClick={(v) => handle({

                                    call:'menu',
                                    data:"close"
        
                                })}
                                >

                                    {"X"}
                                                                    
                                </div>

                            </MenuControls>

                            <Navigator

                                handle      = {(v) => props.handle(v)} 
                                data        = {SideNavInfo['dashboard_sidenav']}
                                refid       = {"dashboard_sidenav"}

                                viewHeight  = {false}
                                databind    = {props.databind}

                            />

                        </Menu>


                    </div>


                :null}


                {width > thresholdSide1
                ?

                    <Side
                    ref={refSide}
                    //backgroundColor={styling.side.backgroundColor}
                    //border={"0px solid lightgray"}
                    >
                        <Navigator

                            handle      = {(v) => props.handle(v)} 
                            data        = {SideNavInfo['dashboard_sidenav']}
                            refid       = {"dashboard_sidenav"}

                            viewHeight  = {false}
                            databind    = {props.databind}

                        />

                    </Side>


                :null}








                <Topbar
                ref={refTopbar}
                width={styling.topbar.width} 
                margin={styling.topbar.margin}
                >

                    <div
                    className={"Left"}
                    >


                        {
                        width < thresholdSide1
                        ?
                            <div
                            className={"MenuButton"}
                            onClick={(v) => handle({

                                call:'menu',
                                data:"open"

                            })}
                            >
                                <div className={"Slice"}></div>
                                <div className={"Slice"}></div>
                                <div className={"Slice"}></div>

                            </div>

                        :null
                        }





                        {breadcrumbs.map((crumb, i) => (

                            <div
                            key={i}
                            className={"Button"}
                            onClick={(v) => handleBreadcrumbs({

                                call:'breadcrumb',
                                data:crumb

                            })}
                            >
                                {crumb}

                            </div>

                        ))}





                    </div>


                    {
                    width > 550
                    ?
                        <div
                        className={"Right"}
                        >

                            <div
                            className={"PanelButton"}
                            onClick={(v) => handlePanel({

                                call:'topbar',
                                data:[]

                            })}
                            >
                                INFO PANEL

                            </div>

                        </div>

                    :null
                    }



                </Topbar>




                {
                width < 551
                ?
                    <FlyTray
                    top={styling.topbar.height}
                    right={"0px"}
                    >
                        <div
                        className={"PanelButton"}
                        onClick={(v) => handlePanel({

                            call:'topbar',
                            data:[]

                        })}
                        >
                            INFO.

                        </div>

                    </FlyTray>

                :null
                }










                {
                //==================================================================
                runFetch
                ?
                    <div>

                        <Loading />
                        <FetchCalcs 

                            primeProps={props.primeProps}
                            handle={handle}
                            //duration={2500}
                            data={fetchData}
                            // formArr={props.formArr}
                            // captured={props.captured}

                        />

                    </div>

                :null
                }

                {
                runAlter
                ?

                    <div>

                        <Loading />
                        <AlterCalcs 

                            primeProps={props.primeProps}
                            handle={handleProcessMenu}
                            duration={2500}
                            data={runAlter}
                        />

                    </div>

                :null
                }

                {
                showMessage
                ?
                    <Message
                        
                        handle={handleProcessMenu}
                        actionCall={showMessage.actionCall}
                        call={showMessage.call}
                        message={showMessage.message}
                        duration={showMessage.duration}
                        styling={processMenuStyl}
                        data={processMenuData}
                        width={width}                    
                        height={height}

                    />

                :null
                }



                {
                showSaveForm
                ?
                    <SaveForm 

                        handle={handleProcessMenu}
                        styling={processMenuStyl}
                        data={processMenuData}
                        width={width}                    
                        height={height}

                    />

                :null
                }



                {
                showRenameForm
                ?
                    <RenameForm 

                        handle={handleProcessMenu}
                        styling={processMenuStyl}
                        data={processMenuData}
                        width={width}                    
                        height={height}

                    
                    />

                :null
                }

                {
                showFavoriteForm
                ?
                    <FavoriteForm 

                        handle={handleProcessMenu}
                        call={"rad"}
                        styling={processMenuStyl}
                        data={processMenuData}
                        width={width}                    
                        height={height}
                    
                    />


                :null
                //=================================================================
                }



                <ScrollBox
                ref={refScrollbox}
                id={'scrollbox'}
                backgroundColor={"#191e28"}
                //backgroundColor={styling.scrollbox.backgroundColor} 
                width={styling.scrollbox.width} 
                height={styling.scrollbox.height} 
                margin={styling.scrollbox.margin}
                onScroll={handleScroll}
                >



                    {
                    showSortByMenu
                    ?
                        <SortByFlanks
                        
                            primeProps={props.primeProps}
                            handle={handleSortBy}
                 
                        />

                    :null
                    }


                    {
                    showProcessMenu
                    ?
                        <Flanks
                        
                            primeProps={props.primeProps}
                            handle={handleProcessMenu}
                            data={processMenuData}
                            height={refMain.current.offsetHeight+"px"}
                        
                        />

                    :null
                    }


                    {
                    calcHash
                    ?

                        <Main
                        ref={refMain}
                        //backgroundColor={"lightgreen"}
                        width={styling.main.width} 
                        margin = {styling.main.margin}
                        padding = {styling.main.padding}
                        >

                            <Calculator 
                            handle={handleProcessMenu}
                            calcId={calcId}
                            calcName={calcName}
                            refStage={refStage}
                            refScrollbox={refScrollbox}
                            fromReport={fromReport}
                            />

                        </Main>


                    :
                    reportHash
                    ?

                        <Main
                        ref={refMain}
                        //backgroundColor={"lightblue"}
                        width={styling.main.width} 
                        margin = {styling.main.margin}
                        padding = {styling.main.padding}
                        >

                            <Report 
                            handle={handleReport}
                            data={{

                                calcId:calcId,
                                calcName:calcName
                            }}
                            refStage={refStage}
                            refScrollbox={refScrollbox}
                            />


                        </Main>


                    :



                        <Main
                        ref={refMain}
                        //backgroundColor={"gray"}
                        width={styling.main.width} 
                        margin = {styling.main.margin}
                        padding = {styling.main.padding}
                        >


                            <div
                            ref={refContent}
                            style={{

                                backgroundColor:"transparent",
                                width:"100%",
                                height:"auto",
                                //width:styling.content.width,
                                //margin:styling.content.margin,
                                //margin:"0 0 0 0",
                                padding:"0 0 200px 0",
                                position:"relative",
                                float:"left"

                            }}
                            >         
                            {Scene.map((row, i) => (


                                <div key={i}>
                                <Catalog
                                //backgroundColor={'lightyellow'}
                                minHeight={'450px'}
                                >

                                    {
                                    showSortByMenu
                                    ?
                                        <SortByMenu 
                                        
                                            primeProps={props.primeProps}
                                            handle={handleSortBy}
            
                                        />

                                    :null
                                    }


                                    {
                                    showProcessMenu
                                    ?
                                        <ProcessMenu 
                                        
                                            primeProps={props.primeProps}
                                            handle={handleProcessMenu}
                                            styling={processMenuStyl}
                                            data={processMenuData}
                                            isShowing={{

                                                message:showMessage,
                                                renameForm:showRenameForm,
                                                saveForm:showSaveForm,
                                                favoriteForm:showFavoriteForm,
                                                alter:runAlter,
                                                fetch:runFetch

                                            }}

                                        />

                                    :null
                                    }

                

                                    {row.map((section, j) => (

                                        <div
                                        key={i+"-"+j}
                                        >
                                            {

                                            section.call === 'top'
                                            ?

                                                <CatalogTop>

                                                    <CatalogTopName
                                                    //backgroundColor={"purple"}
                                                    color={"#fff"}
                                                    >

                                                        {section.name}

                                                    </CatalogTopName>


                                                    {/* <CatalogTopControls
                                                    buttonMinWidth={"125px"}
                                                    >

                                                        {section.controls.map((itm, k) => (

                                                            <div key={i+"-"+j+"-"+k}>

                                                                <div className="Button"
                                                                onClick={() => props.handle({

                                                                    call        :itm.hndl.call,
                                                                    data        :itm.hndl.data
                                                                
                                                                })}
                                                                >

                                                                    {itm.name}

                                                                </div>

                                                            </div>

                                                        ))}

                                                    </CatalogTopControls> */}



                    
                                                    <CatalogTopControls>

                                                        <SortByLabelField 
                                                            handle={handleSortBy}
                                                            data={sortByData}
                                                        />

                                                    </CatalogTopControls>



                                                </CatalogTop>

                                            :
                                            section.call === 'bar'
                                            ?

                                                <div>

                                                    <CatalogBar
                                                    backgroundColor={"#454d5f"}
                                                    border={"0"}
                                                    width={"100%"}
                                                    height={"1px"}
                                                    >

                                                        {/* <SortByLabelField 
                                                            handle={handleSortBy}
                                                            data={sortByData}
                                                        /> */}

                                                    </CatalogBar>

                                                </div>

                                            :
                                            section.call === 'items'
                                            ?



                                                <div>

                                                    
                                                    {/* 
                                                    <CatalogTileCalc
                                                    id={"calcTile"}
                                                    >

                                                        <div className="Left"
                                                        onClick={(v) => handleClick({


                                                            call:"calc",
                                                            data:{
        
                                                                id:false,
                                                                name:"The Profit Calculator"
                                                            }
        
        
                                                        })}
                                                        >

                                                            <div className="Graphic"
                                                            >
                                                                <img
                                                                id={"svg-"+i+"-"+j}
                                                                src={SVGProfitPie} 
                                                                alt="del" />


                                                            </div>

                                                        </div>


                                                        <div className="Right"
                                                        onClick={(v) => handleClick({


                                                            call:"calc",
                                                            data:{
        
                                                                id:false,
                                                                name:"The Profit Calculator"
                                                            }
        
        
                                                        })}
                                                        >



                                                            <div className="Title"
                                                            >

                                                                {"The Profit Calculator"}

                                                            </div>

                                                
                                                            <div className="Descr"
                                                            >
                                                                {"This is the default calculator"}

                                                            </div>


                                                        </div>
                                                        

                                                    </CatalogTileCalc>
                                                    */}



                                                    {section.items.map((itm, k) => (

        
                                                        <CatalogTileCalc

                                                        ref={setRowRef}                              
                                                        key={i+"-"+j+"-"+k}
                                                        id={"row"+i+"-"+j+"-"+k}
                                                        >

                                                            <div className="Controls">
                                                                <div className="Button"
                                                                onClick = {(v) => handleProcessMenu({

                                                                    rowId:"row"+i+"-"+j+"-"+k,
                                                                    id:itm.data.id,
                                                                    name:itm.data.name,
                                                                    data:itm.data

                                                                })}
                                                                >

                                                                    <div className="Dot"></div>
                                                                    <div className="Dot"></div>
                                                                    <div className="Dot"></div>

                                                                </div>

                                                            </div>




                                                            <div className="Ctrls">


                                                                <div className="Favorite"
                                                                //style={{top:"12px"}}
                                                                onClick = {(v) => handleProcessMenu({

                                                                    call:'favorite',
                                                                    action:'default',
                                                                    name:itm.data.name,
                                                                    data:itm.data,
                                                                    calcId:itm.data.id,
                                                                    from:"stars"

                                                                })}
                                                                >

                                                                    <Stars
                                                                    starSize={"small"}
                                                                    favorite={itm.data.favorite}
                                                                    />

                                                                </div>



                                                                <div className="EditButton"
                                                                onClick={(v) => handleClick({

                                                                    call:"calc",
                                                                    data:{
                
                                                                        id:itm.data.id,
                                                                        name:itm.data.name
                                                                    }
                
                                                                })}
                                                                >

                                                                    EDIT PRICING

                                                                </div>

                                                            </div>




                                                            {/* <div className="Grid"
                                                            >

                                                                <div className="Button"
                                                                onClick={(v) => handleProcessMenu({

                                                                    call:'delete',
                                                                    action:'default',
                                                                    calcId:itm.data.id
                                            
                                            
                                                                })}
                                                                >

                                                                    DELETE

                                                                </div>

                                                                <div className="Button"
                                                                onClick={(v) => handleClick({

                                                                    call:"calc",
                                                                    data:{
                
                                                                        id:itm.data.id,
                                                                        name:itm.data.name
                                                                    }
                
                
                                                                })}
                                                                >

                                                                    EDIT

                                                                </div>

                                                                
                                                            </div> */}





                                                            <div className="Left"
                                                            onClick={(v) => handleClick({


                                                                call:"report",
                                                                data:{
            
                                                                    id:itm.data.id,
                                                                    name:itm.data.name
                                                                }
            
            
                                                            })}
                                                            >

                                                                {/* <div className="Graphic"
                                                                >

                                                                    <img
                                                                    id={"svg-"+i+"-"+j+"-"+k}
                                                                    src={SVGProfitPie} 
                                                                    alt="del" />


                                                                </div> */}



                                                                {/* 
                                                                <div
                                                                className="Circle"
                                                                >
                                                                    <div className="Letter">

                                                                        { itm.data.name.charAt(0) }

                                                                    </div>

                                                                </div>
                                                                */}



                                                                {

                                                                itm.netProfit < 0
                                                                ?
                                                                    <div
                                                                    className="Circle"
                                                                    style={{backgroundColor:"#6a707e"}}
                                                                    >
                                                                        <div className="Letter"
                                                                        //style={{color:"#f00"}}
                                                                        style={{color:"#fff"}}
                                                                        >

                                                                            { itm.data.name.charAt(0) }

                                                                        </div>

                                                                    </div>


                                                                :
                                                                itm.price === 0
                                                                ?

                                                                    <div
                                                                    className="Circle"
                                                                    style={{backgroundColor:"#6a707e"}}
                                                                    >
                                                                        <div className="Letter"
                                                                        style={{color:"#fff"}}
                                                                        >

                                                                            { itm.data.name.charAt(0) }

                                                                        </div>

                                                                    </div>



                                                                :
                                                                itm.price > 0 && itm.price === itm.breakevenPrice
                                                                ?

                                                                    <div
                                                                    className="Circle"
                                                                    style={{backgroundColor:"#6a707e"}}
                                                                    >
                                                                        <div className="Letter"
                                                                        //style={{color:"#26d6cc"}}
                                                                        style={{color:"#fff"}}
                                                                        >

                                                                            { itm.data.name.charAt(0) }

                                                                        </div>

                                                                    </div>


                                                                :

                                                                    <div
                                                                    className="Circle"
                                                                    //style={{backgroundColor:"#26d6cc"}}
                                                                    >
                                                                        <div className="Letter">

                                                                            { itm.data.name.charAt(0) }

                                                                        </div>

                                                                    </div>


                                                                }





                                                                {/* <div className="Graphic"
                                                                >
                                                                    <CALC_GRAPHIC
                                                                    
                                                                        // svgWidth = {"100px"}
                                                                        // svgHeight = {"100px"}
                                                                        // svgViewBox = {}
                                                                        // svgPreserveAspectRatio = {}
                                                                        // svgStyle = {}
                                                                    
                                                                        backgroundColor = {"#00cc66"}
                                                                        buttonColor = {"#191e28"}
                                                                        // pieceColor = {}
                                                                        // pieColor = {}

                                                                    />

                                                                </div> */}




                                                            </div>






                                                            <div className="Right"
                                                            onClick={(v) => handleClick({


                                                                call:"report",
                                                                data:{
            
                                                                    id:itm.data.id,
                                                                    name:itm.data.name
                                                                }
            
            
                                                            })}
                                                            >



                                                                {/* PRICE PROFIT PERCENT - ONE COLOR */}


                                                                {/* <div className="TNP"
                                                                >

                                                                    <div className="Title"
                                                                    style={{width:"100%"}}
                                                                    >

                                                                        {itm.heading}

                                                                    </div>


                                                                    {

                                                                    itm.netProfit < 0
                                                                    ?
                                                                        <div className="Price"
                                                                        style={{color:"#c00"}}
                                                                        >

                                                                        {
                                                                            "( "
                                                                            +itm.priceDisplay
                                                                            + " ) ( "
                                                                            + itm.netProfitDisplay 
                                                                            +" ) ( "
                                                                            + itm.netProfitPercDisplay 
                                                                            +" )"
                                                                        }

                                                                        </div>

                                                                    :
                                                                    itm.price === 0
                                                                    ?
                                                                        <div className="Price"
                                                                        style={{color:"#6a707e"}}
                                                                        >

                                                                        {
                                                                            "( "
                                                                            +itm.priceDisplay
                                                                            + " ) ( "
                                                                            + itm.netProfitDisplay 
                                                                            +" ) ( "
                                                                            + itm.netProfitPercDisplay 
                                                                            +" )"
                                                                        }


                                                                        </div>

                                                                    :
                                                                    itm.price > 0 && itm.price === itm.breakevenPrice
                                                                    ?


                                                                        <div className="Price"
                                                                        style={{color:"#26d6cc"}}
                                                                        >

                                                                        {
                                                                            "( "
                                                                            +itm.priceDisplay
                                                                            + " ) ( "
                                                                            + itm.netProfitDisplay 
                                                                            +" ) ( "
                                                                            + itm.netProfitPercDisplay 
                                                                            +" )"
                                                                        }

                                                                        </div>

                                                                    :

                                                                        <div className="Price"
                                                                        >

                                                                        {
                                                                            "( "
                                                                            +itm.priceDisplay
                                                                            + " ) ( "
                                                                            + itm.netProfitDisplay 
                                                                            +" ) ( "
                                                                            + itm.netProfitPercDisplay 
                                                                            +" )"
                                                                        }

                                                                        </div>


                                                                    }

                                                                </div> */}





                                                                {/* PRICE PROFIT PERCENT - PARENTHESIS COLOR */}


                                                                <div className="TNP"
                                                                >

                                                                    <div className="Title"
                                                                    style={{width:"100%"}}
                                                                    >

                                                                        {itm.heading}

                                                                    </div>


                                                                    {

                                                                    itm.netProfit < 0
                                                                    ?
                                                                        <div className="Price"
                                                                        //style={{color:"#f00"}}
                                                                        style={{color:"#a2a6af"}}
                                                                        >

                                                                        {/* {
                                                                            "( "
                                                                            +itm.priceDisplay
                                                                            + " ) ( "
                                                                            + itm.netProfitDisplay 
                                                                            +" ) ( "
                                                                            + itm.netProfitPercDisplay 
                                                                            +" )"
                                                                        } */}


                                                                            <div dangerouslySetInnerHTML={CreateMarkup(itm.htmlDisplay,"default")} />



                                                                        </div>

                                                                    :
                                                                    itm.price === 0
                                                                    ?
                                                                        <div className="Price"
                                                                        //style={{color:"#6a707e"}}
                                                                        style={{color:"#a2a6af"}}
                                                                        >

                                                                        {/* {
                                                                            "( "
                                                                            +itm.priceDisplay
                                                                            + " ) ( "
                                                                            + itm.netProfitDisplay 
                                                                            +" ) ( "
                                                                            + itm.netProfitPercDisplay 
                                                                            +" )"
                                                                        } */}


                                                                            <div dangerouslySetInnerHTML={CreateMarkup(itm.htmlDisplay,"default")} />



                                                                        </div>

                                                                    :
                                                                    itm.price > 0 && itm.price === itm.breakevenPrice
                                                                    ?


                                                                        <div className="Price"
                                                                        //style={{color:"#26d6cc"}}
                                                                        style={{color:"#a2a6af"}}
                                                                        >

                                                                        {/* {
                                                                            "( "
                                                                            +itm.priceDisplay
                                                                            + " ) ( "
                                                                            + itm.netProfitDisplay 
                                                                            +" ) ( "
                                                                            + itm.netProfitPercDisplay 
                                                                            +" )"
                                                                        } */}


                                                                            <div dangerouslySetInnerHTML={CreateMarkup(itm.htmlDisplay,"default")} />


                                                                        </div>

                                                                    :

                                                                        <div className="Price"
                                                                        style={{color:"#a2a6af"}}
                                                                        >

                                                                        {/* {
                                                                            "( "
                                                                            +itm.priceDisplay
                                                                            + " ) ( "
                                                                            + itm.netProfitDisplay 
                                                                            +" ) ( "
                                                                            + itm.netProfitPercDisplay 
                                                                            +" )"
                                                                        } */}


                                                                            {/* <div dangerouslySetInnerHTML={itm.htmlDisplay} /> */}

                    

                                                                            <div dangerouslySetInnerHTML={CreateMarkup(itm.htmlDisplay,"default")} />



                                                                        </div>


                                                                    }

                                                                </div>









                                                                {/* NAME VALUE */}


                                                                {/* 

                                                                <div className="TNP">

                                                                    <div className="Title"
                                                                    style={{width:"100%"}}
                                                                    >

                                                                        {itm.heading}

                                                                    </div>

                                                                    {

                                                                    itm.netProfit < 0
                                                                    ?
                                                                        <div className="NameValue"
                                                                        >

                                                                            <div className="Name"
                                                                            //style={{color:"#c00"}}
                                                                            >
                                                                                { "PRICE:"}

                                                                            </div>

                                                                            <div className="Value"
                                                                            style={{color:"#c00"}}
                                                                            >
                                                                             
                                                                                { itm.priceDisplay }

                                                                            </div>

                                                                        </div>

                                                                    :
                                                                    itm.price === 0
                                                                    ?
                  
                                                                        <div className="NameValue"
                                                                        >

                                                                            <div className="Name"
                                                                            //style={{color:"#c00"}}
                                                                            >
                                                                                { "PRICE:"}

                                                                            </div>

                                                                            <div className="Value"
                                                                            style={{
                                                                                //marginTop:"7px",
                                                                                color:"#6a707e"
                                                                            }}
                                                                            >
                                                                                
                                                                                { itm.priceDisplay }

                                                                            </div>

                                                                        </div>


                                                                    :
                                                                    itm.price > 0 && itm.price === itm.breakevenPrice
                                                                    ?


                                                                        <div className="NameValue"
                                                                        >

                                                                            <div className="Name"
                                                                            //style={{color:"#c00"}}
                                                                            >
                                                                                { "PRICE:"}

                                                                            </div>

                                                                            <div className="Value"
                                                                            style={{color:"#26d6cc"}}
                                                                            >
                                                                                
                                                                                { itm.priceDisplay }

                                                                            </div>

                                                                        </div>


                                                                    :
            
                                                                        <div className="NameValue"
                                                                        >

                                                                            <div className="Name"
                                                                            //style={{color:"#c00"}}
                                                                            >
                                                                                { "PRICE:"}

                                                                            </div>

                                                                            <div className="Value"
                                                                            //style={{color:"#26d6cc"}}
                                                                            >
                                                                                
                                                                                { itm.priceDisplay }

                                                                            </div>

                                                                        </div>


                                                                    }

                                                                </div> 
                                                            
                                                                */}











                                                                {/* {
                                                                itm.largeHeading
                                                                ?

                                                                    <div className="TNP"
                                                                    >

                                                                        <div className="Title"
                                                                        style={{width:"100%"}}
                                                                        >

                                                                            {itm.heading}

                                                                        </div>

                                                                        {

                                                                        itm.netProfit < 0
                                                                        ?
                                                                            <div className="Price"
                                                                            style={{color:"#c00"}}
                                                                            >

                                                                                {"( " + itm.priceDisplay + " )"}

                                                                            </div>

                                                                        :
                                                                        itm.price === 0
                                                                        ?
                                                                            <div className="Price"
                                                                            style={{
                                                                                marginTop:"7px",
                                                                                color:"#6a707e"
                                                                            }}
                                                                            >

                                                                                {"( " + itm.priceDisplay + " )"}

                                                                            </div>

                                                                        :
                                                                        itm.price > 0 && itm.price === itm.breakevenPrice
                                                                        ?


                                                                            <div className="Price"
                                                                            style={{
                                                                                marginTop:"7px",
                                                                                color:"#26d6cc"
                                                                            }}
                                                                            >

                                                                                {"( " + itm.priceDisplay + " )"}

                                                                            </div>

                                                                        :
                                                                            <div className="Price"
                                                                            style={{
                                                                                marginTop:"7px"
                                                                            }}
                                                                            >

                                                                                {"( " + itm.priceDisplay + " )"}

                                                                            </div>


                                                                        }

                                                                    </div>


                                                                  
                                                                :null
                                                                } */}




                                                                {/* {
                                                                !itm.largeHeading
                                                                ?

                                                                    <div className="TNP"
                                                                    >

                                                                        <div className="Title"
                                                                        >

                                                                            {itm.heading}

                                                                        </div>




                                                                        {

                                                                        itm.netProfit < 0
                                                                        ?
                                                                            <div className="Price"
                                                                            style={{color:"#c00"}}
                                                                            >

                                                                                {"( " + itm.priceDisplay + " )"}

                                                                            </div>

                                                                        :
                                                                        itm.price === 0
                                                                        ?
                                                                            <div className="Price"
                                                                            style={{color:"#6a707e"}}
                                                                            >

                                                                                {"( " + itm.priceDisplay + " )"}

                                                                            </div>

                                                                        :
                                                                        itm.price > 0 && itm.price === itm.breakevenPrice
                                                                        ?


                                                                            <div className="Price"
                                                                            style={{color:"#26d6cc"}}
                                                                            >

                                                                                {"( " + itm.priceDisplay + " )"}

                                                                            </div>

                                                                        :

                                                                            <div className="Price"
                                                                            >

                                                                                {"( " + itm.priceDisplay + " )"}

                                                                            </div>


                                                                        }






                                                                    </div>


                                                                  
                                                                :null
                                                                } */}






                                                                {/* 
                                                                <div className="Title"
                                                                >

                                                                    {itm.heading}

                                                                </div> */}

                                                    
                                                                <div className="Descr"
                                                                >
                                                                    {itm.pgraph}

                                                                </div>


                                                                {/* <div className="PricePerc"
                                                                >
                            
                                                                    <div className="Text"
                                                                    >
                                                                        $150,000
                                                                    </div>
                                                            
                                                                    <div className="Tag"
                                                                    >

                                                        
                                                                        <PRICE_TAG 
                            
                                                                            outerColor = {"#00cc66"}
                                                                            innerColor = {"#191e28"}
                                                                            signColor = {"#00cc66"}
                                                                        
                                                                        />

                                                        
                                                                    </div>        

                                                                </div> */}



                                                            </div>


                                                        </CatalogTileCalc>

                                                    

                                                    ))}


                                                </div>


                                            :null

                                            }

                                        </div>


                                    ))}

                                </Catalog>
                                </div>

                            ))} 
                            </div>
        
                                
                        </Main>  

                    }


            
                    { 
                    thresholdSide2
                    ?

                        //ON
                        <Side2
                        ref={refSide2}
                        id={'side2'}
                        //backgroundColor={"purple"}  
                        backgroundColor={"#141820"}                  
                        // backgroundImage={styling.side2.backgroundImage}
                        // backgroundPosition={styling.side2.backgroundPosition}
                        // backgroundSize={styling.side2.backgroundSize}                     
                        width={styling.side2.width}
                        height={contentHeight+(0)+"px"}
                        //height={styling.side2.height}
                        //height={capturedHeight+"px"}
                        //height={"auto"}
                        //transform={styling.side2.transform}
                        >

                            {runFetchPanel
                            ?
                                <PanelLoading />

                            :

                                panelStatus.call !== 'off' 
                                && panelStatus.call !== 'closed' 
                                ?

                                    <Panel

                                        primeProps={props.primeProps}
                                        //handle={(v) => handleForm(v)}  
                                        data={panelStatus.data}
                                        width={width}
                                        refScrollbox={refScrollbox}
                                        refSide2={refSide2}

                                        handlePanel={(v) => handlePanel(v)}
                                        panelStatus={panelStatus}

                                        panelW={styling.side2.width}
                                        panelH={styling.main.height}
                                    
                                    /> 

                                :null


                            }  


                        </Side2>


            

                    :
                    !thresholdSide2 && width > 1200
                    ?

                        //OFF
                
                        <Side2
                        ref={refSide2}
                        id={'side2'}
                        //backgroundColor={"blue"}  
                        backgroundColor={"#141820"}                  
                        // backgroundImage={styling.side2.backgroundImage}
                        // backgroundPosition={styling.side2.backgroundPosition}
                        // backgroundSize={styling.side2.backgroundSize}                     
                        width={styling.side2.width}
                        height={contentHeight+(0)+"px"}
                        //height={styling.side2.height}
                        //height={capturedHeight+"px"}
                        transform={styling.side2.transform}
                        >
                        </Side2>



                    :null

                    }


                </ScrollBox>




                {
                !thresholdSide2
                ?

                    runFetchPanel
                    ?
                        <PanelLoading />

                    :
                        panelStatus.call !== 'off' 
                        && panelStatus.call !== 'closed' 
                        ?
                            
                            <Fly
                            backgroundColor={"#141820"}              
                            >

                                <FlyContainer
                                >

                                    <Panel

                                        primeProps={props.primeProps}
                                        //handle={(v) => handleForm(v)}  
                                        data={panelStatus.data}
                                        width={width}
                                        refScrollbox={refScrollbox}
                                        refSide2={refSide2}

                                        handlePanel={(v) => handlePanel(v)}
                                        panelStatus={panelStatus}

                                        panelW={styling.side2.width}
                                        panelH={contentHeight}

                                    /> 


                                </FlyContainer>


                            </Fly>


                        :null

                :null
                }



            </Stage>

        )


    }

}

export default Index;



