var DefLocalItems = {


    "account_items":{
        "company":{
            "name"     : "Company Info",
            "value"    :{
        
                "company" : {
        
                    "print"		:'text',
                    "name"		:'Company',
                    "value"		:"",
                    "required"	:true,
                    "default"	:""
                },
                "email" : {
        
                    "print"		:'text',
                    "name"		:'Email',
                    "value"		:"",
                    "required"	:true,
                    "default"	:""
                },
                "address" : {
        
                    "print"		:'text',
                    "name"		:'Address',
                    "value"		:"",
                    "required"	:false,
                    "default"	:""
                },
                "city" : {
        
                    "print"		:'text',
                    "name"		:'City',
                    "value"		:"",
                    "required"	:false,
                    "default"	:""
                },
                "state" : {
        
                    "print"		:'select',
                    "name"		:'State',
                    "value"		:"",
                    "required"	:false,
                    "default"	:""
                },
                "country" : {
        
                    "print"		:'select',
                    "name"		:'Country',
                    "value"		:"",
                    "required"	:false,
                    "default"	:"US"
                },
                "zipcode" : {
        
                    "print"		:'text',
                    "name"		:'Zip Code',
                    "value"		:"",
                    "required"	:false,
                    "default"	:""
                },
                "phone" : {
        
                    "print"		:'phone',
                    "name"		:'Phone',
                    "value"		:"",
                    "required"	:false,
                    "default"	:""
                },
        
        
            }
        
    
        }

    },

    "auth_account":{


        "auth":{

            "login":{
                "name"     : "Login Info",
                "value"    :{
            

                    "email" : {
            
                        "print"		:'text',
                        "name"		:'Email',
                        "value"		:"",
                        "required"	:true,
                        "default"	:""
                    },
                    "passowrd" : {
            
                        "print"		:'password',
                        "name"		:'Password',
                        "value"		:"",
                        "required"	:true,
                        "default"	:""
                    }

                }

            }


        },
        "account":{

            "company":{
                "name"     : "Company Info",
                "value"    :{
            
                    "company" : {
            
                        "print"		:'text',
                        "name"		:'Company',
                        "value"		:"",
                        "required"	:true,
                        "default"	:""
                    },
                    "email" : {
            
                        "print"		:'text',
                        "name"		:'Email',
                        "value"		:"",
                        "required"	:true,
                        "default"	:""
                    },
                    "address" : {
            
                        "print"		:'text',
                        "name"		:'Address',
                        "value"		:"",
                        "required"	:false,
                        "default"	:""
                    },
                    "city" : {
            
                        "print"		:'text',
                        "name"		:'City',
                        "value"		:"",
                        "required"	:false,
                        "default"	:""
                    },
                    "state" : {
            
                        "print"		:'select',
                        "name"		:'State',
                        "value"		:"",
                        "required"	:false,
                        "default"	:""
                    },
                    "country" : {
            
                        "print"		:'select',
                        "name"		:'Country',
                        "value"		:"",
                        "required"	:false,
                        "default"	:"US"
                    },
                    "zipcode" : {
            
                        "print"		:'text',
                        "name"		:'Zip Code',
                        "value"		:"",
                        "required"	:false,
                        "default"	:""
                    },
                    "phone" : {
            
                        "print"		:'phone',
                        "name"		:'Phone',
                        "value"		:"",
                        "required"	:false,
                        "default"	:""
                    },
            
            
                }

            }

        }

    },


    "form_items": {

        
        // "business": {
        //     "name": "Business",
        //     "value": {
        //     "biz_insurance": {
        //         "name": "Insurance",
        //         "default": 1000,
        //         "print": "price",
        //         "value": "200.00",
        //         "required": true
        //     },
        //     "biz_misc": {
        //         "name": "Misc.",
        //         "default": 15,
        //         "print": "price",
        //         "value": "10.00",
        //         "required": true
        //     },
        //     "biz_accounting": {
        //         "name": "Accounting",
        //         "default": 100,
        //         "print": "price",
        //         "value": "105.50",
        //         "required": true
        //     },
        //     "biz_advertising": {
        //         "name": "Advertising",
        //         "default": 200,
        //         "print": "price",
        //         "value": "100.00",
        //         "required": true
        //     },
        //     "biz_licenses": {
        //         "name": "Licenses",
        //         "default": 1000,
        //         "print": "price",
        //         "value": "50.00",
        //         "required": true
        //     },
        //     "biz_website": {
        //         "name": "Website",
        //         "default": 100,
        //         "print": "price",
        //         "value": "100.00",
        //         "required": true
        //     },
        //     "biz_legal": {
        //         "name": "Legal",
        //         "default": 200,
        //         "print": "price",
        //         "value": "100.00",
        //         "required": true
        //     },
        //     "biz_loans": {
        //         "name": "Loans",
        //         "default": 1985,
        //         "print": "price",
        //         "value": "1078.00",
        //         "required": true
        //     }
        //     }
        // },
        // "equipment_software": {
        //     "name": "Equip. / Software",
        //     "value": {
        //     "es_plotter": {
        //         "name": "Plotter",
        //         "default": 100,
        //         "print": "price",
        //         "value": "100.00",
        //         "required": true
        //     },
        //     "es_digital_printer": {
        //         "name": "Digital Printer",
        //         "default": 300,
        //         "print": "price",
        //         "value": "300.00",
        //         "required": true
        //     },
        //     "es_software_subscriptions": {
        //         "name": "Software Subscriptions.",
        //         "default": 235,
        //         "print": "price",
        //         "value": "100.00",
        //         "required": true
        //     },
        //     "es_misc": {
        //         "name": "Misc.",
        //         "default": 15,
        //         "print": "price",
        //         "value": "10.00",
        //         "required": true
        //     },
        //     "es_laminator": {
        //         "name": "Laminator",
        //         "default": 100,
        //         "print": "price",
        //         "value": "100.00",
        //         "required": true
        //     },
        //     "es_computers": {
        //         "name": "Computers",
        //         "default": 500,
        //         "print": "price",
        //         "value": "100.00",
        //         "required": true
        //     }
        //     }
        // },
        // "facility": {
        //     "name": "Facility",
        //     "value": {
        //     "fac_rent_mortgage": {
        //         "name": "Rent/Mortgage",
        //         "default": 3500,
        //         "print": "price",
        //         "value": "2000.00",
        //         "required": true
        //     },
        //     "fac_misc": {
        //         "name": "Misc.",
        //         "default": 15,
        //         "print": "price",
        //         "value": "10.00",
        //         "required": true
        //     },
        //     "fac_utilities": {
        //         "name": "Utilities",
        //         "default": 735,
        //         "print": "price",
        //         "value": "350.00",
        //         "required": true
        //     },
        //     "fac_phone_internet": {
        //         "name": "Phone/Internet",
        //         "default": 350,
        //         "print": "price",
        //         "value": "250.00",
        //         "required": true
        //     }
        //     }
        // },


        /*
        "owner": {
            "name": "Owner",
            "value": {
            "owners_taxrate_percent": {
                "name": "Tax Rate %",
                "default": "",
                "print": "percent",
                "value": "30.0",
                "required": true
            },
            "owners_hours_direct": {
                "name": "Hours Direct",
                "default": "",
                "print": "standard",
                "value": 30,
                "required": true
            },
            "owners_salary_pretax": {
                "name": "Salary Pretax",
                "default": "",
                "print": "price",
                "value": "50000.00",
                "required": true
            },
            "owners_hours_indirect": {
                "name": "Hours Indirect",
                "default": "",
                "print": "standard",
                "value": 10,
                "required": true
            },
            "owners_ira_percent": {
                "name": "IRA %",
                "default": "",
                "print": "percent",
                "value": "2.0",
                "required": true
            },
            "owners_health_insurance_monthly": {
                "name": "Health Insurance Monthly",
                "default": "",
                "print": "price",
                "value": "500.00",
                "required": true
            },
            "owners_vacation_weeks": {
                "name": "Vacation Weeks",
                "default": "",
                "print": "standard",
                "value": 2,
                "required": true
            }
            }
        },
        */

        //----------------------
        //ownr-1
        //----------------------

        "ownr-1": {

            "name": "Owner 1",
            "value": {
            "ownr_hours_weekly": {
                "name": "Hours Weekly",
                "default": "",
                "print": "standard",
                "value": 40,
                "required": true
            },
            "ownr_labor_direct": {
                "name": "Direct Labor",
                "default": "",
                "print": "checkboxes-edit",
                "value": "Design",
                "required": true
            },
            "ownr_name": {
                "name": "Name",
                "default": "",
                "print": "standard",
                "value": "Owner 1",
                "required": true
            },
            "ownr_labor_indirect": {
                "name": "Indirect Labor",
                "default": "",
                "print": "checkboxes-edit",
                "value": "",
                "required": true
            },
            "ownr_salary_pretax": {
                "name": "Salary Pretax",
                "default": "",
                "print": "price",
                "value": "50000.00",
                "required": true
            },
            "ownr_health_insurance_monthly": {
                "name": "Health Insurance Monthly",
                "default": "",
                "print": "price",
                "value": "500.00",
                "required": true
            },
            "ownr_labor_percent": {
                "name": "Direct/Indirect Labor %",
                "default": "",
                "print": "dualslider",
                "value": "70",
                "required": true
            },
            "ownr_taxrate_percent": {
                "name": "Tax Comp %",
                "default": "",
                "print": "percent",
                "value": "30.0",
                "required": true
            },
            "ownr_vacation_weeks": {
                "name": "Vacation Weeks",
                "default": "",
                "print": "standard",
                "value": 2,
                "required": true
            },
            "ownr_ira_percent": {
                "name": "IRA %",
                "default": "",
                "print": "percent",
                "value": "2.0",
                "required": true
            }
            }
        },

        "settings": {
            "name": "Settings",
            "value": {
                "projected_monthly_sales": {
                    "name": "Projected Monthly Sales",
                    "default": 50000,
                    "print": "price",
                    "value": "28000.00",
                    "required": true
                },
                "billable_hours_percentage": {
                    "name": "Billable Hours Percentage",
                    "default": "",
                    "print": "hidden",
                    "value": 100,
                    "required": true
                }
            }
        },
        "work_schedule": {
            "name": "Work Schedule",
            "value": {
            "work_weeks": {
                "name": "Weeks Per Year",
                "default": 52,
                "print": "standard",
                "value": 52,
                "required": true
            },
            "work_days": {
                "name": "Days Per Week",
                "default": 5,
                "print": "standard",
                "value": 5,
                "required": true
            },
            "work_hours": {
                "name": "Hours Per Day",
                "default": 8,
                "print": "standard",
                "value": "8",
                "required": true
            }
            }
        }
    },


    
    "result_items": {

        "overhead": {
            "overhead": "27.27",
            "overhead_decimal": "0.27"
        },
        "totals": {
            "total_business": "1743.50",
            "total_ira": "25.00",
            "total_ownr_health_insurance_monthly": "500.00",
            "total_weekly_hours": "40.00",
            "total_vehicles": "1010.00",
            "total_empl_rate_yearly": "0.00",
            "total_facility": "2610.00",
            "total_tax": "535.71",
            "total_empl_burden_tax_monthly": "0.00",
            "total": "7634.21",
            "total_ownr_taxes_monthly_indirect": "535.71",
            "total_ownr_ira_monthly_indirect": "25.00",
            "total_empl_burden_ira_monthly": "0.00",
            "total_ownr_salary_pretax_monthly_indirect": "4166.67",
            "total_empl_rate_monthly": "0.00",
            "total_health_insurance": "500.00",
            "total_empl_health_insurance_monthly": "0.00",
            "total_equipsoftware": "710.00"
        }
    }
}





export {



    DefLocalItems



}


