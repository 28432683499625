import React, { 

    useState, 
    useEffect,
    //useRef

} from 'react';


//import 'app-style/price-form/style.css';
//import '../../StyleSheets/PriceForm.css';
import 'app-components/Zone/_StyleSheets/PriceForm.css';


export const FFcalcby = (props) => {


    //var _FUNC = props.data.funct['name'] + "()";
    //var _FUNC = props.data.funct['name'] + "('"+props.data.funct['object']+"')";
    //var _RUN = new Function(_FUNC);

    //const dispatch = useDispatch();
    //const zoneStore = useSelector(state => state.zone);


    const [buttons, setButtons] = useState([]);
    
    useEffect(() => {


        var bgPositionHold;
        var bgPositionOut;

        
        //var sourceArr = props.data.array;
        //var arr = sourceArr.slice();


        var newArr = [];   
        var newObj; 
        var sourceObj;
        var targetObj;

        for(sourceObj of props.data.array)
        {
            targetObj = {};
            newObj = Object.assign(targetObj, sourceObj);//target, source

            switch(newObj.call)
            {
            case'percent':

                bgPositionHold = "-50px -0px";
                bgPositionOut = "-0px -0px";
            break;
            case'price':

                bgPositionHold = "-50px -50px";
                bgPositionOut = "-0px -50px";
            break;
            case'markup':

                bgPositionHold = "-50px -100px";
                bgPositionOut = "-0px -100px";
            break;
            default:
            break;
            }


        
            if(newObj.call === props.calcby)
            {

                newObj['bgPosition'] = bgPositionHold;
            }
            else
            {
                newObj['bgPosition'] = bgPositionOut;
            }

            newArr.push(newObj);

        }

        //console.log(JSON.stringify(newArr,null,2));
        //alert('check buttons');

        setButtons(newArr);


     },[props])
     


     





    //################################################
    //################################################

    const handleSetStyle = (v) => {


        var call;
        var bgPositionOver;
        var bgPositionOut;
        //var bgPositionHold;

        if(v.target.id.match(/percent/gi)
        )
        {
            call = 'percent';
            bgPositionOver = "-100px -0px";
            bgPositionOut = "-0px -0px";
            //bgPositionHold = "-100px -0px";
            
        }
        else
        if(v.target.id.match(/price/gi)
        )
        {
            call = 'price';
            bgPositionOver = "-100px -50px";
            bgPositionOut = "-0px -50px";
            //bgPositionHold = "-100px -50px";
            
        }
        else
        if(v.target.id.match(/markup/gi)
        )
        {
            call = 'markup';
            bgPositionOver = "-100px -100px";
            bgPositionOut = "-0px -100px";
            //bgPositionHold = "-100px -100px";
          
        }


        if(props.calcby !== call)
        //if(zoneObj['priceForm']['calcby'] !== call)
        {

            var stilo = '';
            if(v.call === 'over')
            { 
                //stilo += "background-color:#00ff00;";
                stilo += "background-position:"+bgPositionOver
                //stilo += "color:#00ff00;";
                //stilo += "border:2px solid #00FFFF;";

                v.target.style = stilo

            }else{

                //stilo += "background-color:#191e28;";
                stilo += "background-position:"+bgPositionOut
                //stilo += "color:#191e28;";
                //stilo += "border:2px solid #00ff00;";

                v.target.style = stilo;

            }//==


        }



    }








    return (


        <div className = "frm">
        <div className = "calcby">

            <div 
            className="label"
            >
                {props.data.label}

            </div>


            <div>
            {buttons.map((obj, k) => (

        
                <div 
                key={"ffcalcbtn_"+k} 
                id={"ffcalcbtn_"+obj.call}
                className = "graphic-button"
                style={{

                    //backgroundPosition: "-0px -50px"
                    backgroundPosition: obj.bgPosition
                    

                }}

                onClick={() => props.handle({

                    call        :'calcby',
                    choice      :obj.call,
                    data        :props.data

                })}

                onMouseOver = {event => {
                    // event properties must be copied to use async
                    let target = event.target;
                    handleSetStyle({

                        target      :target,
                        call        :"over"
                    })

                }}

                onMouseOut = {event => {
                    // event properties must be copied to use async
                    let target = event.target;
                    handleSetStyle({

                        target      :target,
                        call        :"out"
                    })

                }}
                >
                </div>


            ))}
            </div>

        </div>
        </div>





    )


}











