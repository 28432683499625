
export const ExpensesIndirects = [



    //-----------------------------------
    // Report Box
    //-----------------------------------

    {

        "print"		    :'bar',
        "name"		    :'Expenses / Indirects',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },

    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"30px"
        },
        "required"	    :"",
        "default"	    :""


    },


    {

        "print"		    :'report_box',
        "name"		    :'Report Box',
        "value"		    :[

            {

                boxCall:'svg_donut_chart',
                boxWidth:"45%",
                boxHeight:215,
                value:{


                    backgroundColor:"transparent",
                    width:"100%",
                    height:"215px", // match this to boxHeight
                    ring:{
    
                        size:"10",
                        color:"#d2d3d4"
    
                    },
                    hole:{
                        size:0.5,
                        // color:"#191e28",
                        // outlineColor:"#191e28",
                        color:"#edeef1",
                        outlineColor:"#edeef1",
                        textColor:"#000",
                        textSize:"27px"
                    },
                    items: [ 


                        {
                            "name": "Monthly Expenses ( 20% )",
                            "value": "$1,100.00",
                            "percent": "20"
                        },
                        {
                            "name": "Projected Sales ( 80% )",
                            "value": "$5,500.00",
                            "percent": "80"
                        }


                    ],
                    colors: "ChartColorsA"
    
    

                }
        
        

            },

            {

                boxCall:'txt',
                boxWidth:"50%",
                boxHeight:225,
                value:[

                    {
                        className:"Medium",
                        txt:"$0.00",
                        style:{

                            //fontSize:"100px",
                            textAlign:"center"

                        }
                    },

                    {
                        className:"Note",
                        txt:"Expenses + Indirects (Monthly)",
                        style:{

                            //fontSize:"13px",
                            textAlign:"center"

                        }
                    }


                ]


            }



        ],
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"15px"
        },
        "required"	    :"",
        "default"	    :""


    },






    //-----------------------------------
    // Ledger
    //-----------------------------------

    // {

    //     "print"		    :'bar',
    //     "name"		    :'Amount',
    //     "value"		    :"",
    //     "required"	    :"",
    //     "default"	    :""


    // },
    {

        "print"		    :'ledger',
        "name"		    :"Ledger",
        "value"		    :[


            {
                name:"Expenses",
                value:"$400.00"

            },
            {
                name:"Owner's Indirects",
                value:"$100.00"
            },
            {
                name:"Employee's Indirects",
                value:"$100.00"
            }


        ],
        "required"	    :"",
        "default"	    :"",
        "colors"        : "ChartColorsA"

    },

    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"20px"
        },
        "required"	    :"",
        "default"	    :""


    },






    //-----------------------------------
    // Txt
    //-----------------------------------

    // {

    //     "print"		    :'bar',
    //     "name"		    :'Labor Hours',
    //     "value"		    :"",
    //     "required"	    :"",
    //     "default"	    :""


    // },
    {

        "print"		    :'txt',
        "name"		    :'Txt',
        "value"		    :[

            {

                // boxCall:'txt',
                // boxWidth:"100%",
                // boxHeight:230,

                className:"Note",
                txt:'***NOTE: Indirects = Any indirect portion of the Owners and Employees Salaries including Healthcare, IRA contributions etc.',
                style:{

                    //fontSize:"24px",
                    textAlign:"center"

                }

            }

        ],
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"20px"
        },
        "required"	    :"",
        "default"	    :""


    },






];


















