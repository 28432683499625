
import React, { 

    useState, 
    useEffect, 
    // useRef,
    // //useMemo

} from 'react';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    //useHistory,   
    //useParams,


} from "react-router-dom";


import { AuthLoginAccess } from './LoginAccess';
import { AuthLogin } from './Login';
import { AuthLogout } from './Logout';
import { AuthForgotPassword } from './ForgotPassword';
import { AuthSignup } from './Signup';
import { AuthAccountStatus } from './AccountStatus';

import { SignUpForm } from './SignUpForm';
import { SignUpWalk } from './SignUpWalk';
import { CancelForm } from './CancelForm';
import { DeleteForm } from './DeleteForm';

import { AuthActions } from './Actions';
import { AuthPage } from './Page';
import { AuthRedirect } from './Redirect';

export const Auth = (props) => {

    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();

    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);


    //const refContent = useRef(null);

    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {

        if(!isLoaded)
        {
            setIsLoaded(true);
            //console.log("isnt loaded - "+refContent.current.offsetHeight);
        }

    },
    [
        isLoaded
    ])



    return (
    
        <div
        >

            {
            pathname.match(/SignUpForm/gi)
            ?

                <SignUpForm
            
                    primeProps={props.primeProps}
    
                />


            :
            pathname.match(/SignUpWalk/gi)
            ?

                <SignUpWalk
            
                    primeProps={props.primeProps}
    
                />


            :
            pathname.match(/CancelForm/gi)
            ?

                <CancelForm
            
                    primeProps={props.primeProps}
    
                />


            :
            pathname.match(/DeleteForm/gi)
            ?

                <DeleteForm
            
                    primeProps={props.primeProps}
    
                />


            :
            pathname.match(/signup/gi)
            ?

                <AuthSignup
            
                    primeProps={props.primeProps}
    
                />


            :
            pathname.match(/loginAccess/gi)
            ?

                <AuthLoginAccess
            
                    primeProps={props.primeProps}
    
                />


            : 
            pathname.match(/login/gi)
            ?

                <AuthLogin
            
                    primeProps={props.primeProps}
    
                />


            :   
            pathname.match(/logout/gi)
            ?

                <AuthLogout
            
                    primeProps={props.primeProps}
    
                />


            :
            pathname.match(/ForgotPassword/gi)
            ?

                <AuthForgotPassword
            
                    primeProps={props.primeProps}
    
                />


            :
            pathname.match(/AccountStatus/gi)
            ?

                <AuthAccountStatus

                    primeProps={props.primeProps}
    
                />


            :
            pathname.match(/AuthActions/gi)
            ?

                <AuthActions

                    primeProps={props.primeProps}
    
                />


            :
            pathname.match(/AuthPage/gi)
            ?

                <AuthPage

                    primeProps={props.primeProps}
    
                />


            :
            pathname.match(/AuthRedirect/gi)
            ?

                <AuthRedirect

                    primeProps={props.primeProps}
    
                />


            :

                <AuthRedirect

                    primeProps={props.primeProps}

                />

            }

        </div>


    );

}



