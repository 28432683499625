import { 

    useState, 
    useEffect, 
    //useRef

} from 'react';


import ApiResources from './ApiResources';


export const useAuthentication = (props) => {


    var localport = '3001';
    var apiDomain;
    var hostname;
    //var hostname = window.location.hostname;//returns the domain name of the web host
    var protocol = window.location.protocol;//returns the web protocol used (http: or https:)


    if(window.location.hostname.match(/192.168/gi))
    {
        hostname = ApiResources.network[localport].vpc;
        apiDomain = protocol + "//" + hostname + "/";
    }
    else
    if(window.location.hostname.match(/localhost/gi))
    {
        hostname = ApiResources.local[localport].vpc;
        apiDomain = protocol + "//" + hostname + "/";
    }else{
        hostname = ApiResources.aws['x'].vpc;
        apiDomain = "https://" + hostname + "/";
    }//=




    //props.url

    // -- state -- //
    const [auth, setAuth] = useState(false);
    const [isComplete, setIsComplete] = useState(false);

    useEffect(() => {
    
        if(!auth)
        {
            //console.log("Authentication...");

            var url;
            //url = props.url;
            url = apiDomain+"auth-check";
            
    
            /*
            var _VARS = {
                call:"auth-check"
            };
            var splitter = "-|AJXPST|-"
            var amp = "&";
            var ajaxPost = ""
            + "ajaxArr[]=case" + splitter + "auth" + amp;
            + "ajaxArr[]=vars" + splitter + _VARS;
            */


            fetch(url , {

                method:"POST",
                headers: new Headers({

                    'Content-Type'  : 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                    'Authorization' : 'Bearer '+localStorage.accessToken

                }),
                //body: "param1=value1&param2=value2" // <-- Post parameters
                //body: ajaxPost  // <-- Post parameters ajaxArr[]

                body: "token="+localStorage.refreshToken // <-- Post parameters


                //CORS
                //mode:"no-cors", //will result in an opaque response - fix is to set cors on express
                
            })

            .then(res => res.json())
            //.then((res) => {console.log(res.json())})
            .then(

                (result) => {

                    console.log("RESULT: "+JSON.stringify(result,null,2));
                    //alert('success');

                    /*
                    result={
                        "success": true,
                        "result": {
                            "tokenError": {
                                "name": "TokenExpiredError",
                                "message": "jwt expired",
                                "expiredAt": "2020-12-27T18:13:18.000Z"
                            },
                            "authorized": false,
                            "user": false
                        }
                    }

                    */


                    //alert(result.success);


                    var bool;
                    bool = false;
                    if(result.success)
                    {
                        var resp = result.result;
                        if(resp.authorized) bool = true;

                    }
                    //alert('Success: '+bool);
                    setAuth(bool);
                    setIsComplete(true);

                },
                (error) => {

                    //console.log("FAILED");
                    //alert('failed');
                    
                    console.log(error);
                    setAuth(false);
                    setIsComplete(true);
                

                }


                
            )


        }// -- !auth


    }, [
        props,
        apiDomain,
        auth
    ]);



    if(isComplete)
    {
        return auth; 
    }else{
        return "inProcess";
    }


}




