import {

    Canvas_GetMousePos,
    Canvas_RgbToHex

} from '../Cmp/Funx';


import { LibArray } from 'library';



const Handler = (props) => {

    //console.log(JSON.stringify(props,null,2));
    //console.log("Hander Call: "+props.call);
    console.log(props.call)

    var canvas;
    var ctx;
    var mousePos;
    var pixelData;
    var msg;
    var rgb;
    var hex;
    var currentCoord;
    var sliceIndx;
    var sliceData;
    var slicePercent;
    var sliceName;
    var index;


    switch(props.chart.call)
    {
    case'doughnut':

        switch(props.call)
        {
        case'clearCanvas':
        break;
        case'chartReset':


            slicePercent = "0.0";
            if(props.chart.data.hole.percent !== undefined) 
                slicePercent = props.chart.data.hole.percent;

            sliceName = "0.0";
            if(props.chart.data.hole.name !== undefined) 
                sliceName = props.chart.data.hole.name;




            props.handleState({

                call:'currentSlice',
                value:{

                    "percent"               :slicePercent+"%",
                    "name"                  :sliceName,
                    "holeOutlineColor"      :props.chart.data.hole.color
                }
    
            });

            // props.handleState({

            //     call:'currentSlice',
            //     value:{

            //         "percent"               :'',
            //         "name"                  :'',
            //         "holeOutlineColor"      :props.chart.data.hole.color
            //     }
    
            // });



        break;
        case'click':


            canvas = props.canvas.current;
            ctx = canvas.getContext('2d');

            mousePos = Canvas_GetMousePos(canvas,props.event);

            msg = "Valid Color";

            // Get the data of the pixel according to the location generate by the getEventLocation function
            pixelData = ctx.getImageData(mousePos.x, mousePos.y, 1, 1).data; 

            // If transparency on the image
            if((pixelData[0] === 0) && (pixelData[1] === 0) && (pixelData[2] === 0) && (pixelData[3] === 0))
            {
                //coord += " (Transparent color detected, cannot be converted to HEX)";
                msg = "Invalid Color";
                
            }
            rgb = pixelData[0]+","+pixelData[1]+","+pixelData[2];
            hex = "#" + ("000000" + Canvas_RgbToHex({

                r:pixelData[0], 
                g:pixelData[1], 
                b:pixelData[2]

            })).slice(-6);



            //console.log(Object.prototype.toString.call(props.chart.colors));
            //console.log(props.chart.colors);
            //console.log(props.chart.data);
            //console.log(LibArray.InArray(hex, props.chart.colors));


            if(LibArray.InArray(hex, props.chart.data.colors)
            )
            {
                sliceIndx = props.chart.data.colors.indexOf(hex);
                sliceData = Object.keys(props.chart.data.items);


                sliceName = props.chart.data.items[sliceIndx].name;
                slicePercent = props.chart.data.items[sliceIndx].value;

                index = sliceData[sliceIndx];
                sliceName = props.chart.data.items[index].name
                slicePercent = props.chart.data.items[index].value;


                // console.log("SLICE NAME:::::::::"+sliceName);
                // console.log("SLICE PERCENT: "+slicePercent);

                props.handleState({

                    call:'currentSlice',
                    value:{

                        "percent"           :slicePercent+"%",
                        "name"              :sliceName,
                        "holeOutlineColor"  :hex,
                        "rgb"               :rgb,
                        "msg"               :msg
                        
                    }
        
                });

            }else{

                //Not on the doughnut


                slicePercent = "0.0";
                if(props.chart.data.hole.percent !== undefined) 
                    slicePercent = props.chart.data.hole.percent;

                sliceName = "0.0";
                if(props.chart.data.hole.name !== undefined) 
                    sliceName = props.chart.data.hole.name;



                props.handleState({

                    call:'currentSlice',
                    value:{

                        // "percent"               :slicePercent+"%",
                        // "name"                  :sliceName,
                        "percent"               :"",
                        "name"                  :"",
                        "holeOutlineColor"      :props.chart.data.hole.color,
                        "rgb"                   :rgb,
                        "msg"                   :msg

                    }
        
                });

            }
            

            /*
            console.log(""

                +"\nMOUSE POS: " + JSON.stringify(mousePos,null,2)

                +"\nCLIENT X: " + props.event.clientX
                +"\nCLIENT Y: " + props.event.clientY 

                +"\nRGB: " + rgb
                +"\nHEX: " + hex
                +"\nMSG: " + msg

            );
            */
            
        
            currentCoord = { 

                x: mousePos.x, 
                y: mousePos.y 

            };

            //console.log("CURRENT COORD: "+currentCoord);

            // add the newest mouse location to an array in state
            //setCoordinates([...coordinates, currentCoord]);


            props.handleState({

                call:'updateCoords',
                value:currentCoord

            });




        break;
        case'mouseMove':


            canvas = props.canvas.current;
            ctx = canvas.getContext('2d');

            mousePos = Canvas_GetMousePos(canvas,props.event);

            msg = "Valid Color";

            // Get the data of the pixel according to the location generate by the getEventLocation function
            pixelData = ctx.getImageData(mousePos.x, mousePos.y, 1, 1).data; 

            // If transparency on the image
            if((pixelData[0] === 0) && (pixelData[1] === 0) && (pixelData[2] === 0) && (pixelData[3] === 0))
            {
                //coord += " (Transparent color detected, cannot be converted to HEX)";
                msg = "Invalid Color";
                
            }
            rgb = pixelData[0]+","+pixelData[1]+","+pixelData[2];
            hex = "#" + ("000000" + Canvas_RgbToHex({

                r:pixelData[0], 
                g:pixelData[1], 
                b:pixelData[2]

            })).slice(-6);



            //console.log(Object.prototype.toString.call(props.chart.colors));
            //console.log(props.chart.colors);
            //console.log(props.chart.data);
            //console.log(LibArray.InArray(hex, props.chart.colors));


            if(LibArray.InArray(hex, props.chart.data.colors)
            )
            {
                sliceIndx = props.chart.data.colors.indexOf(hex);
                //sliceData = Object.keys(props.chart.data);

                sliceName = props.chart.data.items[sliceIndx].name;
                slicePercent = props.chart.data.items[sliceIndx].value;


                // index = sliceData[sliceIndx];
                // sliceName = props.chart.data.items[index].name
                // slicePercent = props.chart.data.items[index].value;


                console.log("SLICE NAME:::::::::"+sliceName);
                console.log("SLICE PERCENT: "+slicePercent);

                props.handleState({

                    call:'currentSlice',
                    value:{

                        "percent"           :slicePercent+"%",
                        "name"              :sliceName,
                        "holeOutlineColor"  :hex,
                        "rgb"               :rgb,
                        "msg"               :msg
                        
                    }
        
                });

            }else{



                slicePercent = "0.0";
                if(props.chart.data.hole.percent !== undefined) 
                    slicePercent = props.chart.data.hole.percent;

                sliceName = "0.0";
                if(props.chart.data.hole.name !== undefined) 
                    sliceName = props.chart.data.hole.name;


                // props.handleState({

                //     call:'currentSlice',
                //     value:{

                //         "percent"               :slicePercent+"%",
                //         "name"                  :sliceName,
                //         "holeOutlineColor"      :props.chart.data.hole.color,
                //         "rgb"                   :rgb,
                //         "msg"                   :msg

                //     }
        
                // });

                // props.handleState({

                //     call:'currentSlice',
                //     value:false
        
                // });


                props.handleState({

                    call:'currentSlice',
                    value:{

                        "percent"               :'',
                        "name"                  :'',
                        "holeOutlineColor"      :props.chart.data.hole.color,
                        "rgb"                   :rgb,
                        "msg"                   :msg
                    }
        
                });



            }
            

            /*
            console.log(""

                +"\nMOUSE POS: " + JSON.stringify(mousePos,null,2)

                +"\nCLIENT X: " + props.event.clientX
                +"\nCLIENT Y: " + props.event.clientY 

                +"\nRGB: " + rgb
                +"\nHEX: " + hex
                +"\nMSG: " + msg

            );
            */
            
        
            currentCoord = { 

                x: mousePos.x, 
                y: mousePos.y 

            };

            //console.log("CURRENT COORD: "+currentCoord);

            // add the newest mouse location to an array in state
            //setCoordinates([...coordinates, currentCoord]);


            props.handleState({

                call:'updateCoords',
                value:currentCoord

            });




            /*

            var eventLocation = Canvas_GetEventLocation(this,e);
            var coord = "x=" + eventLocation.x + ", y=" + eventLocation.y;
            
            // Get the data of the pixel according to the location generate by the getEventLocation function
            var context = this.getContext('2d');

            console.log("COORD: "+coord);

            var pixelData = context.getImageData(eventLocation.x, eventLocation.y, 1, 1).data; 
            // If transparency on the image
            if((pixelData[0] == 0) && (pixelData[1] == 0) && (pixelData[2] == 0) && (pixelData[3] == 0))
            {
                coord += " (Transparent color detected, cannot be converted to HEX)";
            }

            var rgb = pixelData[0]+","+pixelData[1]+","+pixelData[2];
            var hex = "#" + ("000000" + Canvas_RgbToHex(pixelData[0], pixelData[1], pixelData[2])).slice(-6);

            console.log("COLOR: "+rgb+" "+hex);

            zoneObj['pie_chart_color'] = hex;





            //console.log(JSON.stringify(canvas_colors_array,null,2));

            //----------------------------------------------------------

            var data_index = 100;
            for(var i=0; i < canvas_colors_array.length; i++)
            {
                console.log(canvas_colors_array[i]+" == "+hex);

                var a = canvas_colors_array[i].toLowerCase();
                var b = hex.toLowerCase();
                if(a == b)
                {
                    var data_index = i;
                    break;
                }//===

            }//for i
            //=======


            //console.log("DATA INDEX: "+data_index);
            //console.log(JSON.stringify(canvas_data_object,null,2));



            var name = '%';
            var value = '';
            var font_size = "35px";

            var count = -1;
            for(var kn in canvas_data_object)
            {
            
                var count = count + 1;
                //console.log(data_index+" "+kn+" "+count);

                if(data_index == count)
                {
                    var name = kn;

                    var value = canvas_data_object[kn];
                    var value = parseFloat(value);
                    var value = value.toFixed(1);
                    var value = value+"%";

                    var font_size = "20px";
                    break;
                };

            }//for i
            //=======



            zoneObj['pie_chart_click_object'] = {

                    "color"		:hex,
                    "name"		:name,
                    "value"		:value
            };


            //-----------------------------------------------------------

            //var canvas_w_int = 250;
            //var canvas_h_int = 250;

            var piemsg_w_int = (canvas_w_int)/2.7;

            //------------------------------------------------------------
            var MESSAGE = name+"<br/>"+value;



            var suffix = '_canvas_piechart_output';

            var section_id = "section" + suffix;
            var section = document.getElementById(section_id);

            var piemsg_id = section_id + "msg";

                if(document.getElementById(piemsg_id))
                {
                    var e = document.getElementById(piemsg_id);
                    section.removeChild(e);

                }//==

                var piemsg = document.createElement('div');
                piemsg.setAttribute("id",piemsg_id);

            section.appendChild(piemsg);


            piemsg.innerHTML = MESSAGE;


            var piemsg = document.getElementById(piemsg_id);


                var css = '';
                css += "background-color : transparent;";
                //css += "background-image : url("+logo_src+");";
                css += "width       	 : "+piemsg_w_int+"px;";
                css += "height      	 : auto;";

                css += "padding     	 : 0 0 0 0;";
                css += "margin      	 : 0 0 0 0;";

                //css += "position         : relative;";
                //css += "float     	   : left;";

                css += "position       	: absolute;";
                css += "top     		: 0px;";
                css += "left     		: 0px;";
                css += "z-index 		: 3;";


                css += "display			: inline;";

                    
                css += "font-family  	 : Arial, Helvetica, sans-serif;";
                css += "font-size    	 : "+font_size+";";
                css += "font-weight  	 : normal;";
                css += "color       	 : #FFF;";
                css += "text-align   	 : center;";

                //css += "border 		 : 1px solid black;";
                //css += "border-radius  : 5px;";

            piemsg.style.cssText = css;



            var piemsg_tmar_int = (section.offsetHeight - piemsg.offsetHeight)/2;
            var piemsg_lmar_int = (section.offsetWidth - piemsg.offsetWidth)/2;

            piemsg.style.top = piemsg_tmar_int + 'px';
            piemsg.style.left = piemsg_lmar_int + (2) + 'px';

            //-----------------------------------------------------------

            */



        break;
        default:
        break;
        }//==



    break;
    default:
    }//== props.chart.call



}


export default Handler;

