import { VPreportDefaults } from 'app-components/Prime/VPdata/reports';

import { LibNumber } from "library";

import { 

    ChartColorsA, 
    ChartColorsB, 
    ChartColorsC 

} from 'utils/components/Charts/ChartColors';


export const BuildOverhead = (props) => {

    var keyname;
    var kn;
    var items, item;
    var array;
    var value;
    // var group, groupName;
    // var newValue;

    // var a,b,c,d;
    var total;
    var grand_total;


    var chartSections;
    var object;


    var name;
    var percent;
    var price;
    var bullet,bulletColors;

    var pgraph;

    var count;


    // var obj;


    var chartColors = [


        "royalblue",
        "lightblue",
        "lightslateblue",

        "lightseagreen",
        "lightgreen",

        "purple",
        "lavender",
        "magenta",
        "pink"


    ];








    //#############################################################
    //#############################################################
    //build chartSections

    chartSections = [];
    if(props.data && props.data !== undefined)
    { 
        pgraph = props.data.value;
 
        //console.log("PGRPAH: "+JSON.stringify(pgraph,null,2));
        if(pgraph['total'] && pgraph['total'] !== undefined)
        {

            total = 0;
            grand_total = pgraph['total'].value;
            if(!isNaN(grand_total)) grand_total = parseFloat(grand_total);

            for(kn in pgraph)
            {

                object = pgraph[kn];

                if(!isNaN(object.value)) value = parseFloat(object.value);
                else value = object.value;

                switch(kn)
                {
                case'total':
                case'total_percent':
                break;
                default:

                    total = parseFloat(value);

                    percent = ( parseFloat(total)/parseFloat(grand_total) ) * 100;
                    percent = percent.toFixed(1);
                    percent = parseFloat(percent);

                    total = total.toFixed(2);


                    // -- set chart sections (cssBarChart) -- //
                    
                    chartSections.push({

                        name    :object.name,
                        value   :percent,
                        data: {
                            percent :percent,
                            price   :total
                        }


                    });      
                    


                }

            }


        }


    }//==

    console.log("Chart Sections: "+JSON.stringify(chartSections,null,2));








    //#############################################################
    //#############################################################

    items = [];

    keyname = 'OverheadExpensesIndirects'; 

    if(VPreportDefaults && VPreportDefaults !== undefined)
    {
        console.log("Flyr Report Build - Default Items: "+JSON.stringify(VPreportDefaults[keyname],null,2));

        for(item of VPreportDefaults[keyname])
        {

            switch(item.print)
            {
            case'singleBarChart':

                // {
                //     "print": "singleBarChart",
                //     "name": "The Chart",
                //     "value": [
                //       {
                //         "chartType": "horizontal",
                //         "chartLines": true,
                //         "data": [
                //           {
                //             "name": "Direct Labor %",
                //             "value": "2500.00",
                //             "percent": 80
                //           },
                //           {
                //             "name": "Indirect Labor %",
                //             "value": "2500.00",
                //             "percent": 20
                //           },
                //           {
                //             "name": "Direct Labor %",
                //             "value": "2500.00",
                //             "percent": 80
                //           },
                //           {
                //             "name": "Indirect Labor %",
                //             "value": "2500.00",
                //             "percent": 20
                //           },
                //           {
                //             "name": "Direct Labor %",
                //             "value": "2500.00",
                //             "percent": 80
                //           },
                //           {
                //             "name": "Indirect Labor %",
                //             "value": "2500.00",
                //             "percent": 20
                //           }
                //         ]
                //       }
                //     ],
                //     "required": "",
                //     "default": ""
                //  },

            

                //item.value[0].data = array;

                array = [];
                for(object of chartSections)
                {
                    percent = object.data.percent;
                    percent = parseFloat(percent);
                    percent = percent.toFixed(1);
                    
                    price = object.data.price;
                    price = LibNumber.PrintMoney(price);
                    if(!price.match(/\$/gi)) price = "$"+price;

                    name = object.name;
        
                    array.push({

                        name:       name,
                        value:      price,
                        percent:    percent

                    });

                }

                item.value[0].data = array;



            break;
            case'svg_donut_chart':



                // "print"		    :'svg_donut_chart',
                // "name"		    :'SVG Donut Chart',
                // "value"		    :{
        
                //     backgroundColor:"transparent",
                //     width:"100%",
                //     height:"auto", // set a fixed height to control overflow
                //     ring:{
        
                //         size:"8",
                //         color:"#d2d3d4"
        
                //     },
                //     hole:{
                //         size:0.5,
                //         // color:"#191e28",
                //         // outlineColor:"#191e28",
                //         color:"#fff",
                //         outlineColor:"#fff",
                //         textColor:"#000",
                //         textSize:"27px"
                //     },
                //     items: [ 
        
                //         // {
                //         //     name:"Classical Music",
                //         //     value:50
                //         // },
                //         // {
                //         //     name:"Hard Rock",
                //         //     value:25
                //         // },
                //         // {
                //         //     name:"Soft Rock",
                //         //     value:25
                //         // }
        
                //         {
                //             "name": "Rent / Mortgage ( 78.9% )",
                //             "value": "$5,500.00",
                //             "percent": "78.9"
                //         },
                //         {
                //             "name": "Utilities ( 15.8% )",
                //             "value": "$1,100.00",
                //             "percent": "15.8"
                //         },
                //         {
                //             "name": "Phone / Internet ( 5.0% )",
                //             "value": "$350.00",
                //             "percent": "5.0"
                //         },
                //         {
                //             "name": "Misc ( 0.3% )",
                //             "value": "$20.00",
                //             "percent": "0.3"
                //         }
        
        
        
                //     ],
                //     colors: [
            
        
            
                //         "#caffca",
        
                //         "#07a2e3",
                //         "#20b2aa",
        
                //         "#fde23e",
                //         "#f16e23", 
                //         "#57d9ff",
                //         "#937e88",
                //         "#20b2aa",
                //         "#ccff00",
        
                //         "lightblue",
                //         "lightgreen",
                //         "yellow",
                //         "pink",
                //         "lavender"
            
                //     ]
        
        
        
                // },



                count = 0;
                array = [];
                for(object of chartSections)
                {
                    percent = object.data.percent;
                    percent = parseFloat(percent);
                    percent = percent.toFixed(1);
                    percent = parseFloat(percent);
                    
                    price = object.data.price;
                    price = LibNumber.PrintMoney(price);
                    if(!price.match(/\$/gi)) price = "$"+price;

                    name = object.name;
            
                    array.push({

                        name:       name,
                        value:      price,
                        percent:    percent

                    });

                    count++;

                }

                item.value.items = array;


            
            break;
            case'report_box':


        
                //item.value[0].data = array;

                switch(item.value[0].boxCall)
                {
                case'svg_donut_chart':



                    // {

                    //     boxCall:'svg_donut_chart',
                    //     boxWidth:"45%",
                    //     boxHeight:225,
                    //     value:{
        
        
                    //         backgroundColor:"transparent",
                    //         width:"100%",
                    //         height:"225px", // match this to boxHeight
                    //         ring:{
            
                    //             size:"10",
                    //             color:"#d2d3d4"
            
                    //         },
                    //         hole:{
                    //             size:0.5,
                    //             // color:"#191e28",
                    //             // outlineColor:"#191e28",
                    //             color:"#edeef1",
                    //             outlineColor:"#edeef1",
                    //             textColor:"#000",
                    //             textSize:"27px"
                    //         },
                    //         items: [ 
        
        
                    //             {
                    //                 "name": "Rent / Mortgage ( 80% )",
                    //                 "value": "$5,500.00",
                    //                 "percent": "80"
                    //             },
                    //             {
                    //                 "name": "Utilities ( 20% )",
                    //                 "value": "$1,100.00",
                    //                 "percent": "20"
                    //             }
        
            
        
                    //         ],
                    //         colors: [
                    
        
                
                    //             "royalblue",
                    //             "lightgreen",
                    //             "lightseagreen",
                    //             "#caffca",
                
                    //             "#07a2e3",
                    //             "#20b2aa",
                
                    //             "#fde23e",
                    //             "#f16e23", 
                    //             "#57d9ff",
                    //             "#937e88",
                    //             "#20b2aa",
                    //             "#ccff00",
                
                    //             "lightblue",
                    //             "lightgreen",
                    //             "yellow",
                    //             "pink",
                    //             "lavender"
                    
                    //         ]
            
            
        
                    //     }
                
                
        
                    // },
        




                    count = 0;
                    array = [];
                    for(object of chartSections)
                    {
                        percent = object.data.percent;
                        percent = parseFloat(percent);
                        percent = percent.toFixed(1);
                        percent = parseFloat(percent);
                        
                        price = object.data.price;
                        price = LibNumber.PrintMoney(price);
                        if(!price.match(/\$/gi)) price = "$"+price;

                        name = object.name;
                
                        array.push({

                            name:       name,
                            value:      price,
                            percent:    percent

                        });

                        count++;

                    }

                    item.value[0].value.items = array;




                break;
                case'chart':


                    //  {
                    //     "boxCall": "donutChart",
                    //     "boxWidth": "100%",
                    //     "boxHeight": 275,
                    //     "value":{
            
                    //         backgroundColor:"transparent",
                    //         border:"0",
                    //         borderRadius:"0",
                    //         width:"80%",
                    //         margin:15+"px auto 0 auto",
                    //         maxHeight:15 - 5,
                    //         drawCheckerBoard: false,
                    //         chart:{
                    //             call:'doughnut',
                    //             data: {
                        
                    //                 items: [ 
                        
                    //                     {
                    //                         name:"Classical Music",
                    //                         value:20
                    //                     },
                    //                     {
                    //                         name:"Hard Rock",
                    //                         value:80
                    //                     }
                    //                 ],
                            
                    //                 colors: [
                            
                    //                     "#07a2e3",
                    //                     "#20b2aa",
                
                
                    //                     "#fde23e",
                    //                     "#f16e23", 
                    //                     "#57d9ff",
                    //                     "#937e88",
                    //                     "#20b2aa",
                    //                     "#ccff00",
                
                    //                     "lightblue",
                    //                     "lightgreen",
                    //                     "yellow",
                    //                     "pink",
                    //                     "lavender"
                            
                    //                 ],
                    //                 hole:{
                    //                     size:0.6,
                    //                     // color:"#191e28",
                    //                     // outlineColor:"#191e28",
                    //                     color:"#fff",
                    //                     outlineColor:"#fff",
                    //                     textColor:"#000",
                    //                     textSize:"35px"
                    //                 }
                
                
                    //             }
                
                
                        
                    //         }
                
                    
                    //     }


                    // }



                    count = 0;
                    array = [];
                    for(object of chartSections)
                    {
                        percent = object.data.percent;
                        percent = parseFloat(percent);
                        percent = percent.toFixed(1);
                        percent = parseFloat(percent);
                        
                        price = object.data.price;
                        price = LibNumber.PrintMoney(price);
                        if(!price.match(/\$/gi)) price = "$"+price;

                        name = object.name;
            
                        array.push({

                            name:       name,
                            value:      percent

                        });

                        count++;

                    }

                    item.value[0].value.chart.data.items = array;

                
                break;
                case'donutChart':




                    // {
                    //     "print": "report_box",
                    //     "name": "Report Box",
                    //     "value": [
                        //     {
                        //         "boxCall": "donutChart",
                        //         "boxWidth": "100%",
                        //         "boxHeight": 275,
                        //         "value": {
                            //         "donutType": "static",
                            //         "donutSize": "250px",
                            //         "donutHalfSize": "125px",
                            //         "donutCenterSize": "150px",
                            //         "donutCenterTopLeft": "50px",
                            //         "donutCenterBackgroundColor": "#394264",
                            //         "donutCenterBorderRadius": "100%",
                            //         "donutSections": [
                            //             {
                            //             "percent": "25",
                            //             "color": "royalblue"
                            //             },
                            //             {
                            //             "percent": "25",
                            //             "color": "lightblue"
                            //             },
                            //             {
                            //             "percent": "25",
                            //             "color": "lightseagreen"
                            //             },
                            //             {
                            //             "percent": "25",
                            //             "color": "lightgreen"
                            //             }
                            //         ]
                        //         }
                        //     }
                    //     ],
                    //     "required": "",
                    //     "default": ""
                    // },


                    count = 0;
                    array = [];
                    for(object of chartSections)
                    {
                        percent = object.data.percent;
                        percent = parseFloat(percent);
                        percent = percent.toFixed(1);
                        
                        price = object.data.price;
                        price = LibNumber.PrintMoney(price);
                        if(!price.match(/\$/gi)) price = "$"+price;

                        name = object.name;
            
                        array.push({

                            name:       name,
                            value:      price,
                            percent:    percent,
                            color:      chartColors[count]

                        });

                        count++;

                    }

                    item.value[0].value.donutSections = array;


                break;
                default:
                }



                console.log(JSON.stringify(array));


            break;
            case'ledger':



                // {
                //     "print": "ledger",
                //     "name": "Ledger",
                //     "value": [
                //     {
                //         "name": "Direct Labor",
                //         "value": "$400.00"
                //     },
                //     {
                //         "name": "Direct Labor Hours per Week",
                //         "value": "$100.00"
                //     },
                //     {
                //         "name": "Indirect Labor",
                //         "value": "$400.00"
                //     },
                //     {
                //         "name": "Indirect Labor Hours per Week",
                //         "value": "$100.00"
                //     },
                //     {
                //         "name": "Hours Weekly Total",
                //         "value": "$200.00"
                //     },
                //     {
                //         "name": "Indirect Labor Hours per Week",
                //         "value": "$100.00"
                //     },
                //     {
                //         "name": "Hours Weekly Total",
                //         "value": "$200.00"
                //     }
                //     ],
                //     "required": "",
                //     "default": ""
                // },



                if(item.value[0].name.match(/total/gi))
                {
                    total = 0;
                    for(object of chartSections)
                    {
                        price = object.data.price;
                        price = price.replace(/\$/gi,"");
                        price = parseFloat(price);
                        
                        total = total + price;

                    }
                    total = total.toFixed(2);
                    total = LibNumber.PrintMoney(total);
                    if(!total.match(/\$/gi)) total = "$"+total;

                    item.value[0].value = total;

                }else{



                    if(item.colors && item.colors !== undefined)
                    {

                        //bullet ledger

                        switch(item.colors)
                        {
                        case'ChartColorsA':
        
                            bulletColors = ChartColorsA;
                        break;
                        case'ChartColorsB':
        
                            bulletColors = ChartColorsB;
                        break;
                        case'ChartColorsC':
        
                            bulletColors = ChartColorsC;
                        break;
                        default:
                        }
        

                    }

                    array = [];
                    count = 0;
                    for(object of chartSections)
                    {
                        percent = object.data.percent;
                        percent = parseFloat(percent);
                        percent = percent.toFixed(1);
                        
                        price = object.data.price;
                        price = LibNumber.PrintMoney(price);
                        if(!price.match(/\$/gi)) price = "$"+price;

                        name = object.name;
                        //name = "( "+percent+"% ) "+object.name;
                        value = price;
                        bullet = {

                            color:bulletColors[count]
                        }
            
                        array.push({

                            name:       name,
                            value:      value,
                            percent:    percent,
                            bullet:     bullet

                        });


                        count++;

                    }
                    item.value = array;


                }


            break;
            default:
            }

            items.push(item);

        }

    }

    
    return items;

}






