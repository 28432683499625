





/*

        
"totals": {

    "total": "28952.81",

    "total_ownrs": "10944.05",
    "total_ownrs_direct": "4166.67",
    "total_ownrs_indirect": "6777.38",

    "total_empls": "7413.76", 
    "total_empls_direct": "3377.34",       
    "total_empls_indirect": "4036.42",


    "total_weekly_hours"                        : "40.00",
 
    "total_ira"                                 : "61.07",
    "total_tax"                                 : "801.14",
    "total_health_insurance"                    : "700.00",

    "total_business"                            : "1743.50",
    "total_facility"                            : "2610.00",
    "total_equipsoftware"                       : "710.00",
    "total_vehicles"                            : "1010.00",




    "total_ownr_health_insurance_monthly"       : "500.00",

    "total_ownr_taxes_monthly_direct"         : "357.14",
    "total_ownr_ira_monthly_direct"           : "16.67",
    "total_ownr_salary_pretax_monthly_direct" : "4166.67",
 
    "total_ownr_taxes_monthly_indirect"         : "357.14",
    "total_ownr_ira_monthly_indirect"           : "16.67",
    "total_ownr_salary_pretax_monthly_indirect" : "4166.67",
 


    "total_empl_health_insurance_monthly"       : "200.00",

    "total_empl_direct_ira_monthly"           : "44.40",
    "total_empl_direct_salary_monthly"        : "2220.00",
    "total_empl_direct_tax_monthly"           : "444.00",

    "total_empl_indirect_ira_monthly"           : "44.40",
    "total_empl_indirect_salary_monthly"        : "2220.00",
    "total_empl_indirect_tax_monthly"           : "444.00",



}





    "total_ira": "196.62",
    "total_weekly_hours": "40.00",
    "total_vehicles": "2565.00",
    "total_empl_indirect_ira_monthly": "71.62",
    "total_facility": "4615.00",
    "total_ownr_salary_pretax_monthly": "4166.67",



    "total": "28952.81",

    "total_ownrs": "10944.05",
    "total_ownrs_direct": "4166.67",
    "total_ownrs_indirect": "6777.38",

    "total_empls": "7413.76", 
    "total_empls_direct": "3377.34",       
    "total_empls_indirect": "4036.42",



    "total_health_insurance": "1152.00",
    "total_empl_health_insurance_monthly": "452.00",

    "total_empl_indirect_tax_monthly": "585.47",
    "total_business": "2150.00",

    "total_ownr_health_insurance_monthly": "700.00",
    "total_empl_indirect_salary_monthly": "2927.33",

    "total_tax": "2371.18",
    "total_empl_direct_ira_monthly": "67.74",
    "total_ownr_taxes_monthly_direct": "0.00",
    "total_ownr_taxes_monthly_indirect": "1785.71",
    "total_ownr_ira_monthly_indirect": "125.00",
    "total_ownr_salary_pretax_monthly_indirect": "4166.67",

    "total_equipsoftware": "1265.00",
    "total_empl_direct_salary_monthly": "2758.00",
    "total_empl_direct_tax_monthly": "551.60",
    "total_ownr_ira_monthly_direct": "0.00"
  











*/


const VPRtotals = {

    "name"     : "Totals",
    "value"    :{



        "total" : {

            "print"		:'price',
            "name"		:'total',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },




        "total_ownrs" : {

            "print"		:'price',
            "name"		:'total_ownrs',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "total_ownrs_direct" : {

            "print"		:'price',
            "name"		:'total_ownrs_direct',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "total_ownrs_indirect" : {

            "print"		:'price',
            "name"		:'total_ownrs_indirect',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },





        "total_empls" : {

            "print"		:'price',
            "name"		:'total_empls',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "total_empls_direct" : {

            "print"		:'price',
            "name"		:'total_empls_direct',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "total_empls_indirect" : {

            "print"		:'price',
            "name"		:'total_empls_indirect',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },








        "total_weekly_hours" : {

            "print"		:'standard',
            "name"		:'total_weekly_hours',
            "value"		:"",
            "required"	:false,
            "default"	:""
        },



        "total_health_insurance" : {

            "print"		:'price',
            "name"		:'total_health_insurance',
            "value"		:"",
            "required"	:false,
            "default"	:""
        },
        "total_ira" : {

            "print"		:'price',
            "name"		:'total_ira',
            "value"		:"",
            "required"	:false,
            "default"	:""
        },
        "total_tax" : {

            "print"		:'price',
            "name"		:'total_tax',
            "value"		:"",
            "required"	:false,
            "default"	:""
        },



        



        //---------------------
        //business costs
        //---------------------


        "total_business" : {

            "print"		:'price',
            "name"		:'total_business',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "total_facility" : {

            "print"		:'price',
            "name"		:'Facility',
            "value"		:"",
            "required"	:false,
            "default"	:""
        },
        "total_vehicles" : {

            "print"		:'price',
            "name"		:'Vehicles',
            "value"		:"",
            "required"	:false,
            "default"	:""
        },
        "total_equipsoftware" : {

            "print"		:'price',
            "name"		:'Equip/Software',
            "value"		:"",
            "required"	:false,
            "default"	:""
        },







        //---------------------
        //ownrs
        //---------------------

        "total_ownr_salary_pretax_monthly" : {

            "print"		:'price',
            "name"		:'total_ownr_salary_pretax_monthly',
            "value"		:"",
            "required"	:false,
            "default"	:""
        },
        "total_ownr_salary_pretax_monthly_indirect" : {

            "print"		:'price',
            "name"		:'total_ownr_salary_pretax_monthly_indirect',
            "value"		:"",
            "required"	:false,
            "default"	:""
        },


        "total_ownr_tax_monthly_direct" : {

            "print"		:'price',
            "name"		:'total_ownr_tax_monthly_direct',
            "value"		:"",
            "required"	:false,
            "default"	:""
        },
        "total_ownr_tax_monthly_indirect" : {

            "print"		:'price',
            "name"		:'total_ownr_tax_monthly_indirect',
            "value"		:"",
            "required"	:false,
            "default"	:""
        },


        "total_ownr_ira_monthly_direct" : {

            "print"		:'price',
            "name"		:'total_ownr_ira_monthly_direct',
            "value"		:"",
            "required"	:false,
            "default"	:""
        },
        "total_ownr_ira_monthly_indirect" : {

            "print"		:'price',
            "name"		:'total_ownr_ira_monthly_indirect',
            "value"		:"",
            "required"	:false,
            "default"	:""
        },



        "total_ownr_health_insurance_monthly" : {

            "print"		:'price',
            "name"		:'total_ownr_health_insurance_monthly',
            "value"		:"",
            "required"	:false,
            "default"	:""
        },





        //---------------------
        //empls
        //---------------------

        "total_empl_direct_salary_monthly" : {

            "print"		:'price',
            "name"		:'total_empl_direct_salary_monthly',
            "value"		:"",
            "required"	:false,
            "default"	:""
        },
        "total_empl_indirect_salary_monthly" : {

            "print"		:'price',
            "name"		:'total_empl_indirect_salary_monthly',
            "value"		:"",
            "required"	:false,
            "default"	:""
        },



        "total_empl_direct_tax_monthly" : {

            "print"		:'price',
            "name"		:'total_empl_direct_tax_monthly',
            "value"		:"",
            "required"	:false,
            "default"	:""
        },
        "total_empl_indirect_tax_monthly" : {

            "print"		:'price',
            "name"		:'total_empl_indirect_tax_monthly',
            "value"		:"",
            "required"	:false,
            "default"	:""
        },



        "total_empl_direct_ira_monthly" : {

            "print"		:'price',
            "name"		:'total_empl_direct_ira_monthly',
            "value"		:"",
            "required"	:false,
            "default"	:""
        },
        "total_empl_indirect_ira_monthly" : {

            "print"		:'price',
            "name"		:'total_empl_indirect_ira_monthly',
            "value"		:"",
            "required"	:false,
            "default"	:""
        },




        
        "total_empl_health_insurance_monthly" : {

            "print"		:'price',
            "name"		:'total_empl_health_insurance_monthly',
            "value"		:"",
            "required"	:false,
            "default"	:""
        }



    }


    
};





export {

    VPRtotals

};












/*


  "result_items": {


    "idl-1": {

      "name": "Jane Doe",
      "value": {
        "idl_health_insurance_weekly": "115.38",
        "idl_burden_hours_yearly": "1560.00",
        "idl_vacation_weeks": "2.00",
        "idl_tax_comp_percent": "20.00",
        "idl_yearly_salary_w_tax_ira": "38064.00",
        "idl_ira_percent": "2.00",
        "idl_burden_tax_hourly": "4.00",
        "idl_hourly_rate": "20.00",
        "idl_burden_ira_yearly": "624.00",
        "idl_burden_ira_monthly": "52.00",
        "idl_burden_tax_yearly": "6240.00",
        "idl_health_insurance_hourly": "3.85",
        "idl_burden_rate_w_tax_ira_hi_hourly": "28.25",
        "idl_hourly_rate_w_tax_ira": "24.40",
        "idl_burden_ira_hourly": "0.40",
        "idl_burden_rate_w_tax_ira_hi_yearly": "44064.00",
        "idl_ira_decimal": "0.02",
        "idl_name": "Jane Doe",
        "idl_burden_rate_w_tax_ira_hi_monthly": "3672.00",
        "idl_burden_weekly_hours": "30.00",
        "idl_health_insurance_yearly": "6000.00",
        "idl_monthly_salary_w_tax_ira": "3172.00",
        "idl_tax_comp_decimal": "0.20",
        "idl_working_weeks": "50.00",
        "idl_health_insurance_monthly": "500.00",
        "idl_weekly_salary_w_tax_ira": "732.00",
        "idl_burden_tax_monthly": "520.00",
        "idl_rate_monthly": "2600.00",
        "idl_rate_yearly": "31200.00"
      }

    },
    "overhead": {
      "overhead": "40.51",
      "overhead_decimal": "0.41"
    },
    "owners": {
      "owners_sti": "72428.57",
      "owners_ira_yearly": "1000.00",
      "owners_hours_indirect_percent_whole": "25.00",
      "owners_sti_yearly_indirect": "18107.14",
      "owners_taxes_monthly": "1785.71",
      "owners_hours_direct_percent_whole": "75.00",
      "owners_hours_direct_percent": "0.75",
      "owners_taxrate_reciprocal": "0.70",
      "owners_flag": "<br/>The owner spends the majority of their time performing direct labor.<br/>25% of the owners salary will be considered an Overhead expense..<br/>Graphic Design, Production and Installation rates will use the owners Direct Labor rate.",
      "owners_billable_hours_weekly_direct": "30.00",
      "owners_sti_hourly_direct": "36.21",
      "owners_total_weekly_hours": "40.00",
      "owners_taxrate_decimal": "0.30",
      "owners_ira_monthly_indirect": "20.83",
      "owners_taxes_monthly_direct": "1339.29",
      "owners_salary_overhead": "1041.67",
      "owners_sti_monthly_direct": "4526.79",
      "owners_sti_monthly_indirect": "1508.93",
      "owners_hours_indirect_percent": "0.25",
      "owners_ira_decimal": "0.02",
      "owners_sti_weekly": "1448.57",
      "owners_taxes_monthly_indirect": "446.43",
      "owners_ira_monthly": "83.33",
      "owners_sti_yearly_direct": "54321.43",
      "owners_sti_weekly_direct": "1086.43",
      "owners_sti_weekly_indirect": "362.14",
      "owners_st": "71428.57",
      "owners_ira_monthly_direct": "62.50",
      "owners_taxes_yearly": "21428.57",
      "owners_working_weeks": "50.00",
      "owners_salary_pretax_monthly": "4166.67"
    },
    "totals": {
      "total_business": "1738.00",
      "total_idl_rate_yearly": "31200.00",
      "total_ira": "72.83",
      "total_weekly_hours": "40.00",
      "total_vehicles": "1010.00",
      "total_idl_burden_tax_monthly": "520.00",
      "total_facility": "2610.00",
      "total_tax": "966.43",
      "total": "11748.93",
      "total_health_insurance": "1000.00",
      "total_idl_health_insurance_monthly": "500.00",
      "total_equipsoftware": "710.00",
      "total_idl_rate_monthly": "2600.00",
      "total_idl_burden_ira_monthly": "52.00"
    }
  }


}












*/
