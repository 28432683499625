/*
----------------------
Flex or Fixed
----------------------

MAX - 4 across

 "across1"     :501,        
 "across2"     :801,          
 "across3"     :1050


MAX - 3 across

 "across1"     :501,        
 "across2"     :801,          
 "across3"     :10000


MAX - 2 across

 "across1"     :501,        
 "across2"     :10000,          
 "across3"     :10000

 */




import { 
    //Lorem, 
    //TestImages,
    //SpecialImages,
    //NavigationData 
} from 'app-components/Prime/Data';









const FixedImageOnly = (props) => {



    var object = {

        styl:{

            call:"full",
            class:"FullRow",

            marginTop:"0px",
            marginBottom:"0px"

        },
        call:'FGrid',
        info:{

            call:'fixed', //fixed, flex

            gridType: "ImageOnly", //ImageOnly, ImageText
            gridBgColor:'transparent',
            gridCall: "grid_cell",  //grid_cell or grid_column_cell
            cellCall: "container",  //container or default 

            modCall: 'fill_div',
            thumb: {

                call    : "default", //flex_height or default
                diff    : 4

            },    
            cellBorder: {
                "boolean"       :false,
                "thickness"     :0,//px
                "color"         :"lightgray"  
            },
            gutter: {
                "boolean"   :false,
                "size"      :0
            },
            thresh: {
                "refWidth"    :false,   //number or false = default screen width
                "across1"     :501,
                "across2"     :801,   // number or 99999 to max out
                "across3"     :10000
            },

            mask:{

                boolean:true,
                message:{

                    boolean:true,
                    backgroundColor:"#4CAF50",
                    textColor:"white"

                }

            }



        },

        items:[]


    };



    object.styl.call = props.styl.call;
    object.styl.class = props.styl.class;
    object.styl.marginTop = props.styl.marginTop;
    object.styl.marginBottom = props.styl.marginBottom;



    if(props.info.thumb.diff) object.info.thumb.diff = props.info.thumb.diff;

    if(props.info.cellBorder && props.info.cellBorder !== undefined)
    {
        if(props.info.cellBorder.boolean)
        { 
            object.info.cellBorder.boolean = props.info.cellBorder.boolean;
            object.info.cellBorder.thickness = props.info.cellBorder.thickness;
            object.info.cellBorder.color = props.info.cellBorder.color;
        }
    }

    if(props.info.gutter.boolean)
    { 
        object.info.gutter.boolean = props.info.gutter.boolean;
        object.info.gutter.size = props.info.gutter.size;
    }

    if(props.info.thresh) object.info.thresh.across1 = props.info.thresh.across1;
    if(props.info.thresh) object.info.thresh.across2 = props.info.thresh.across2;
    if(props.info.thresh) object.info.thresh.across3 = props.info.thresh.across3;

    object.items = props.items;




    return object;


}





const FixedImageText = (props) => {



    var object = {



        styl:{

            call:"full",
            class:"FullRow",

            marginTop:"0px",
            marginBottom:"0px"
    
        },
        call:'FGrid',
        info:{

            call:'fixed', //fixed, flex

            gridType: "ImageText", //ImageOnly, ImageText
            gridBgColor:'transparent',
            gridCall: "grid_cell",  //grid_cell or grid_column_cell
            cellCall: "container",  //container or default 

            modCall: 'fill_div',
            thumb: {

                call    : "default", //flex_height or default
                diff    : 10

            },    
            cellBorder: {
                "boolean"       :false,
                "thickness"     :0,//px
                "color"         :"lightgray"  
            },
            gutter: {
                "boolean"   :true,
                "size"      :10
            },
            thresh: {
                "refWidth"    :false,   //number or false = default screen width
                "across1"     :501,
                "across2"     :801,
                "across3"     :1050
            },

            mask:{

                boolean:true,
                message:{

                    boolean:true,
                    backgroundColor:"#4CAF50",
                    textColor:"white"

                }

            }




        },

        items:[]


    };


    object.styl.call = props.styl.call;
    object.styl.class = props.styl.class;
    object.styl.marginTop = props.styl.marginTop;
    object.styl.marginBottom = props.styl.marginBottom;





    if(props.info.thumb.diff) object.info.thumb.diff = props.info.thumb.diff;


    if(props.info.cellBorder && props.info.cellBorder !== undefined)
    {
        if(props.info.cellBorder.boolean)
        { 
            object.info.cellBorder.boolean = props.info.cellBorder.boolean;
            object.info.cellBorder.thickness = props.info.cellBorder.thickness;
            object.info.cellBorder.color = props.info.cellBorder.color;
        }
    }

    if(props.info.gutter.boolean)
    { 
        object.info.gutter.boolean = props.info.gutter.boolean;
        object.info.gutter.size = props.info.gutter.size;
    }

    if(props.info.thresh) object.info.thresh.across1 = props.info.thresh.across1;
    if(props.info.thresh) object.info.thresh.across2 = props.info.thresh.across2;
    if(props.info.thresh) object.info.thresh.across3 = props.info.thresh.across3;

    object.items = props.items;


    return object;


}










const FlexImageOnly = (props) => {



    var object = {


        styl:{

            call:"full",
            class:"FullRow",

            marginTop:"0px",
            marginBottom:"0"
    
        },
        call:'FGrid',
        info:{

            call:'flex', //fixed, flex

            gridType: "ImageOnly", //ImageOnly, ImageText
            gridBgColor:'transparent',
            gridCall: "grid_column_cell",  //grid_cell or grid_column_cell
            cellCall: "container",  //container or default 

            modCall: 'fill_div',
            thumb: {

                call    : "flex_height", //flex_height or default
                diff    : 0

            },    
            cellBorder: {
                "boolean"       :false,
                "thickness"     :0,//px
                "color"         :"lightgray"  
            },
            gutter: {
                "boolean"   :false,
                "size"      :5
            },
            thresh: {
                "refWidth"    :false,   //number or false = default screen width
                "across1"     :501,
                "across2"     :801,
                "across3"     :1050
            },
            mask:{

                boolean:true,
                message:{

                    boolean:true,
                    backgroundColor:"#4CAF50",
                    textColor:"white"

                }

            }


        },

        items:[]

    };



    object.styl.call = props.styl.call;
    object.styl.class = props.styl.class;
    object.styl.marginTop = props.styl.marginTop;
    object.styl.marginBottom = props.styl.marginBottom;





    if(props.info.thumb.diff) object.info.thumb.diff = props.info.thumb.diff;

    
    if(props.info.cellBorder && props.info.cellBorder !== undefined)
    {
        if(props.info.cellBorder.boolean)
        { 
            object.info.cellBorder.boolean = props.info.cellBorder.boolean;
            object.info.cellBorder.thickness = props.info.cellBorder.thickness;
            object.info.cellBorder.color = props.info.cellBorder.color;
        }
    }
    

    if(props.info.gutter.boolean)
    { 
        object.info.gutter.boolean = props.info.gutter.boolean;
        object.info.gutter.size = props.info.gutter.size;
    }

    if(props.info.thresh) object.info.thresh.across1 = props.info.thresh.across1;
    if(props.info.thresh) object.info.thresh.across2 = props.info.thresh.across2;
    if(props.info.thresh) object.info.thresh.across3 = props.info.thresh.across3;

    object.items = props.items;





    return object;


}



const FlexImageText = (props) => {



    var object = {


        styl:{

            call:"full",
            class:"FullRow",

            marginTop:"0px",
            marginBottom:"0"
    
        },
        call:'FGrid',
        info:{

            call:'flex', //fixed, flex

            gridType: "ImageText", //ImageOnly, ImageText
            gridBgColor:'transparent',
            gridCall: "grid_column_cell",  //grid_cell or grid_column_cell
            cellCall: "container",  //container or default 

            modCall: 'fill_div',
            thumb: {

                call    : "flex_height", //flex_height or default
                diff    : 0

            },    
            cellBorder: {
                "boolean"       :false,
                "thickness"     :0,//px
                "color"         :"lightgray"  
            },
            gutter: {
                "boolean"   :true,
                "size"      :5
            },
            thresh: {
                "refWidth"    :false,   //number or false = default screen width
                "across1"     :501,
                "across2"     :801,
                "across3"     :1050
            },
            mask:{

                boolean:true,
                message:{

                    boolean:true,
                    backgroundColor:"#4CAF50",
                    textColor:"white"

                }

            }


        },

        items:[]


    };



    object.styl.call = props.styl.call;
    object.styl.class = props.styl.class;
    object.styl.marginTop = props.styl.marginTop;
    object.styl.marginBottom = props.styl.marginBottom;


    if(props.info.thumb.diff) object.info.thumb.diff = props.info.thumb.diff;


    
    if(props.info.cellBorder && props.info.cellBorder !== undefined)
    {
        if(props.info.cellBorder.boolean)
        { 
            object.info.cellBorder.boolean = props.info.cellBorder.boolean;
            object.info.cellBorder.thickness = props.info.cellBorder.thickness;
            object.info.cellBorder.color = props.info.cellBorder.color;
        }
    }
    


    if(props.info.gutter.boolean)
    { 
        object.info.gutter.boolean = props.info.gutter.boolean;
        object.info.gutter.size = props.info.gutter.size;
    }

    if(props.info.thresh) object.info.thresh.across1 = props.info.thresh.across1;
    if(props.info.thresh) object.info.thresh.across2 = props.info.thresh.across2;
    if(props.info.thresh) object.info.thresh.across3 = props.info.thresh.across3;

    object.items = props.items;


    return object;


}





const FilmStrip = (props) => {



    var object = {




        styl:{

            call:"full",
            class:"FullRow",

            marginTop:"0px",
            marginBottom:"0px"
    
        },
        call:'FGrid',
        info:{

            call:'filmstrip', //fixed, flex, filmstrip
            gridType: "ImageOnly", //ImageOnly, ImageText
            gridBgColor:'transparent',
            gridCall: "filmstrip",  //grid_column_cell, grid_cell, flyr, filmstrip
            modCall: 'fitTo',
            thumb: {

                call    : "flex_height", //flex_height or default
                diff    : 0

            },    
            cellBorder: {
                "boolean"       :false,
                "thickness"     :0,//px
                "color"         :"lightgray"  
            },
            gutter: {
                "boolean"   :true,
                "size"      :0
            },
            thresh: {
                "refWidth"    :false,   //number or false = default screen width
                "across1"     :10000,
                "across2"     :10000,
                "across3"     :10000
            
            },
            mask:{

                boolean:false,
                message:{

                    boolean:true,
                    backgroundColor:"#4CAF50",
                    textColor:"white"

                }

            }
        


        },


        items:[]

    };


    object.styl.call = props.styl.call;
    object.styl.class = props.styl.class;
    object.styl.marginTop = props.styl.marginTop;
    object.styl.marginBottom = props.styl.marginBottom;




    if(props.info.thumb.diff) object.info.thumb.diff = props.info.thumb.diff;

    if(props.info.cellBorder && props.info.cellBorder !== undefined)
    {
        if(props.info.cellBorder.boolean)
        { 
            object.info.cellBorder.boolean = props.info.cellBorder.boolean;
            object.info.cellBorder.thickness = props.info.cellBorder.thickness;
            object.info.cellBorder.color = props.info.cellBorder.color;
        }
    }

    if(props.info.gutter.boolean)
    { 
        object.info.gutter.boolean = props.info.gutter.boolean;
        object.info.gutter.size = props.info.gutter.size;
    }

    if(props.info.thresh) object.info.thresh.across1 = props.info.thresh.across1;
    if(props.info.thresh) object.info.thresh.across2 = props.info.thresh.across2;
    if(props.info.thresh) object.info.thresh.across3 = props.info.thresh.across3;

    object.items = props.items;


    return object;


}




const Band = (props) => {



    var object = {


        styl:{

            call:"full",
            class:"FullRow",

            marginTop:"12px",
            marginBottom:"0"
        
        },
        call:'ImgComponent',
        info:{

            call:'page', //
            gridType: "PageBand", //Band, PageBand
            gridBgColor:'transparent',
            gridCall: "grid_cell",  //grid_cell or grid_column_cell
            cellCall: "default",  //container or default 
            modCall: 'fill_div',
            thumb: {

                call    : "default", //flex_height or default
                diff    : 0

            },    
            cellBorder: {
                "boolean"       :false,
                "thickness"     :0,//px
                "color"         :"lightgray"  
            },
            gutter: {
                "boolean"   :false,
                "size"      :0
            },
            thresh: {
                "refWidth"    :false,   //number or false = default screen width
                "across1"     :501,
                "across2"     :801,
                "across3"     :1050
            },
            mask:{//caption

                boolean:true,
                message:{

                    boolean:true,
                    backgroundColor:"#4CAF50",
                    textColor:"white",
                    text:""

                }

            }


        },


        items:[]


    };



    object.styl.call = props.styl.call;
    object.styl.class = props.styl.class;
    object.styl.marginTop = props.styl.marginTop;
    object.styl.marginBottom = props.styl.marginBottom;





    if(props.info.thumb.diff) object.info.thumb.diff = props.info.thumb.diff;

    if(props.info.cellBorder && props.info.cellBorder !== undefined)
    {
        if(props.info.cellBorder.boolean)
        { 
            object.info.cellBorder.boolean = props.info.cellBorder.boolean;
            object.info.cellBorder.thickness = props.info.cellBorder.thickness;
            object.info.cellBorder.color = props.info.cellBorder.color;
        }
    }

    if(props.info.gutter.boolean)
    { 
        object.info.gutter.boolean = props.info.gutter.boolean;
        object.info.gutter.size = props.info.gutter.size;
    }

    if(props.info.thresh) object.info.thresh.across1 = props.info.thresh.across1;
    if(props.info.thresh) object.info.thresh.across2 = props.info.thresh.across2;
    if(props.info.thresh) object.info.thresh.across3 = props.info.thresh.across3;

    object.items = props.items;

    
    return object;


}





export {

    FixedImageOnly,
    FixedImageText,
    FlexImageOnly,
    FlexImageText,
    FilmStrip,
    Band

}


