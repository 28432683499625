import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';


import {

    ReportAct

} from '../Styled';




import SVGChart from 'grfx/chart.svg';
import SVGChartOver from 'grfx/chart_over.svg';

import SVGPencil from 'grfx/pencil.svg';
import SVGPencilOver from 'grfx/pencil_over.svg';

import SVGTrash from 'grfx/trash.svg';
import SVGTrashOver from 'grfx/trash_over.svg';



const Index = (props) => {






    //=============================================
    // refs
    //=============================================

    const [rowRefs] = useState([]);
    const setRowRef = (ref) => {

        if(ref && ref !== null && ref !== undefined)
        {
            //console.log("ref::::"+ref.id);
            var found;
            found = false;
            for(var i=0; i < rowRefs.length; i++)
            {
                if(rowRefs[i].id === ref.id) found = true;
            }
            if(!found) rowRefs.push(ref);
            //console.log(boxRefs.length);

        }

    };



    // -- ref -- //
    const refA = useRef(null);


    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState({});
    const [items, setItems] = useState([]);


    useEffect(() => {


        var array = [];

        if(!isLoaded)
        {
            setIsLoaded(true);

            if(Object.prototype.toString.call(props.data.value) === '[object Array]')
            {
                //var object;
                array = props.data.value;
                setItems(array);
            }    
            

        }else{


            if(Object.prototype.toString.call(props.data.value) === '[object Array]')
            {
                //var object;
                array = props.data.value;
                setItems(array);
            }    
            


            var margin;
            var marginTop = "20px";
            var marginLeft = "0px";
            if(props.data.boxHeight 
            && props.data.boxHeight !== undefined
            )
            {
                //marginTop = (props.data.boxHeight - refA.current.offsetHeight)/2 - (5) + "px";
                //marginLeft = "calc(50% - "+(refA.current.offsetWidth/2)+"px";
                //console.log("ledger marginTop: "+marginTop);

                margin = marginTop+" 0 0 "+marginLeft

            }

            if(props.lessThanThresh)
            {
                //marginTop = "0px";
                marginLeft = "calc(50% - "+(refA.current.offsetWidth/2)+"px)";

                margin = marginTop+" 0 25px "+marginLeft

            }


            setData({

                id:props.id,
                margin:margin
         
            });

        }
        //console.log("txtData: "+JSON.stringify(props.data,null,2));

   
    },
    [
        props,
        isLoaded
    ])







    //=============================================
    // handle - over out
    //=============================================

    const handleOverOut = (hndl) => {


        switch(hndl.from)
        {
        case'controls':


            //console.log("controls");

            var row_id;
            var buttonName;
            var btn;
            var img;



            var i;
            var found;
            found = false;
            if(rowRefs.length > 0)
            {
                for(i=0; i < rowRefs.length; i++)
                {
                    if(rowRefs[i].id === data.id+"_"+hndl.indx)
                    {
                        found = true;
                        break;
                    }
                }
            
            }

            if(found)
            {
                //row_id ="row"+hndl.indx;
                row_id = data.id+"_"+hndl.indx;
                buttonName = hndl.buttonName;

                //controls = document.getElementById(hndl.id+"_controls");
                btn = document.getElementById(row_id+"_controls_"+buttonName);
                img = document.getElementById(row_id+"_controls_"+buttonName+"_img");

                switch(hndl.call)
                {
                case'mouseEnter':
                case'mouseOver':

                    btn.style.backgroundColor = 'transparent';


                    if(buttonName.match(/chart/gi)
                    ) 
                    {
                        img.src = SVGChartOver;
                    } 
                    else
                    if(buttonName.match(/trash/gi)
                    ) 
                    {
                        img.src = SVGTrashOver;
                    } 
                    else
                    {
                        img.src = SVGPencilOver;
                    }
  

                break;
                case'mouseOut':

                    btn.style.backgroundColor = 'transparent';

                    if(buttonName.match(/chart/gi)
                    ) 
                    {
                        img.src = SVGChart;
                    }
                    else
                    if(buttonName.match(/trash/gi)
                    ) 
                    {
                        img.src = SVGTrash;
                    } 
                    else
                    {
                        img.src = SVGPencil;
                    }
                break;
                default:
                }

            }//==



        break;
        case'button':


            //console.log(JSON.stringify(hndl,null,2));

            //row_id ="row"+hndl.indx;
            row_id = data.id+"_"+hndl.indx;
            buttonName = hndl.buttonName;

            btn = document.getElementById(row_id+"_"+buttonName);
            btn.style.backgroundColor = hndl.backgroundColor;
            btn.style.border = hndl.border;
            btn.style.color = hndl.fontColor;
            btn.style.cursor = 'pointer';


            /*
        
            switch(hndl.call)
            {
            case'mouseEnter':
            case'mouseOver':

                btn.style.backgroundColor = hndl.backgroundColor;
                btn.style.border = hndl.border;
                btn.style.fontColor = hndl.fontColor;
            break;
            case'mouseOut':

                btn.style.backgroundColor = hndl.backgroundColor;
                btn.style.border = hndl.border;
                btn.style.fontColor = hndl.fontColor;
            break;
            default:
            }

            */


        break;
        default:
        }



    }







    return (

        <ReportAct
        ref={refA}
        id={data.id}
        margin={data.margin}
        //backgroundColor={'lightyellow'}
        >
            {items.map((itm, i) => (

                <div key={i}>
                {
                itm.controls
                ?

                    <div 
                    className="ButtonContainer">

                        <div 
                        ref={setRowRef}    
                        className="Button"
                        id={data.id+"_"+i+"_controls_button"}
                        >

                            {
                            itm.controls.report
                            ?

                                <div 
                                className="ButtonMask"
                                id={data.id+"_"+i+"_controls_button_mask"}

                                    // onClick={() => props.handle({

                                    //     call        :'showFlyr',
                                    //     data        :itm.controls.chart
                    
                                    // })}
                                    onClick={() => props.handle({

                                        call        :'showFlyr',
                                        data        :itm.controls.report
                    
                                    })}
                                    onMouseEnter={() => handleOverOut({

                                        indx        :i,   
                                        call        :'mouseEnter',
                                        from        :'controls',
                                        buttonName  :"button"

                                    })}
                                    onMouseOut={() => handleOverOut({

                                        indx        :i,       
                                        call        :'mouseOut',
                                        from        :'controls',
                                        buttonName  :'button'

                                    })}

                                >
                                </div>

                            :


                                <div 
                                className="ButtonMask"
                                id={data.id+"_"+i+"_controls_button_mask"}

                                    // onClick={() => props.handle({

                                    //     call        :'showFlyr',
                                    //     data        :itm.controls.chart
                    
                                    // })}
                                    onClick={() => props.handle({

                                        call        :'showFlyr',
                                        data        :itm.controls.form
                    
                                    })}
                                    onMouseEnter={() => handleOverOut({

                                        indx        :i,   
                                        call        :'mouseEnter',
                                        from        :'controls',
                                        buttonName  :"button"

                                    })}
                                    onMouseOut={() => handleOverOut({

                                        indx        :i,       
                                        call        :'mouseOut',
                                        from        :'controls',
                                        buttonName  :'button'

                                    })}

                                >
                                </div>




                            }




                            {
                            itm.svg === 'chart'
                            ?

                                <img
                                id={data.id+"_"+i+"_controls_button_img"}
                                className="Img"
                                src={SVGChart} 
                                alt="action" />


                            :
                            itm.svg === 'pencil'
                            ?

                                <img
                                id={data.id+"_"+i+"_controls_button_img"}
                                className="Img"
                                src={SVGPencil} 
                                alt="action" />


                            :null

                        
                            }


                        </div>


                    </div>



                :



                    itm.className === 'Spacer'
                    ?

                        <div 
                        className={itm.className}
                        style={{

                            height:itm.style.height
                            
                        }}
                        >
                        </div>

                    :


                        <div 
                        className={itm.className}
                        style={{

                            //fontSize:itm.style.fontSize,
                            //textAlign:itm.style.textAlign
                        }}
                        >
                            {itm.txt}

                        </div>



                }
                </div>


            ))}


        </ReportAct>

    )


}

export default Index;


