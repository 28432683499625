import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';


import { 
    useSelector, 
    useDispatch 
} 
from 'react-redux';
import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';



import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    useHistory,   
    //useParams,



} from "react-router-dom";

import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider';
import useBreadcrumbs from 'utils/hooks/Breadcrumbs';




import {

    //---------------
    // Stage
    //---------------

    Stage,
    ScrollBox,
    // Area,
    // AreaTitle,
    Tray,



    //---------------
    // Layout
    //---------------

    MenuAlfa,
    Menu,
    MenuControls,

    Topbar,

    Side,Side2,
    Main,



    // Rows,
    // FullRow,
    // IndentRow,

    // Box,

    Fly,
    FlyContainer,
    FlyTray,


    // Tile,
    // TileName,
    // TileTop,
    // TileControls
    

} from 'app-components/Zone/_Styled';


import {

    Catalog,
        CatalogTop,
            CatalogTopName,
            //CatalogTopControls,

        CatalogBar,
        CatalogTileDark,


} from 'utils/components/Catalog/Styled';


// import {

//     ACCOUNT as ACCOUNT_GRAPHIC,
//     EMPLOYEE as EMPLOYEE_GRAPHIC,
//     EXPENSES as EXPENSES_GRAPHIC,
//     OVERHEAD as OVERHEAD_GRAPHIC,
//     OWNER as OWNER_GRAPHIC,
//     SCHEDULE as SCHEDULE_GRAPHIC,

//     // CALCULATOR as CALCULATOR_GRAPHIC,
//     // DIRECT_LABOR_HOURS as HOURS_GRAPHIC,
//     // DIRECT_LABOR_RATES as RATES_GRAPHIC,
//     // HEALTHCARE as HEALTHCARE_GRAPHIC,
//     // IRA as IRA_GRAPHIC,
//     // REPORTS as REPORTS_GRAPHIC,
//     // SETTINGS as SETTINGS_GRAPHIC

// } from 'app-components/Zone/_SVGComps/ICONS';


// import StylScene from './_StylModules/Scene.module.css';
// // import StylPage from '../_StylModules/Page.module.css';
// // import StylRows from '../_StylModules/Rows.module.css';





// import FGrid from 'utils/components/Grid/FGrid';
// import VidGrid from 'utils/components/Grid/VidGrid';

// import TxtComponent from 'utils/components/Content/Txt';
// import ImgComponent from 'utils/components/Content/Img';
// import CardComponent from 'utils/components/Card';
import Navigator from 'utils/components/Content/Navigator';
//import SideNavInfo from 'utils/components/Pages/SceneInfo/Trials/SideNav/SceneInfo';


// import { 
//     //LibArray,
//     LibSystem 
// } from 'library';


import { FetchKnowledgeBase as Fetch } from 'app-components/Zone/_Connect/KnowledgeBase/Fetch';
import Loading from 'app-components/Zone/_Generic/Loading';
// import Message from 'app-components/Zone/_Generic/Message';
// import Flanks from 'app-components/Zone/_Generic/Flanks';



import { FetchInfoPanel as FetchPanel } from 'app-components/Zone/_Connect/InfoPanel/Fetch';

import Panel from 'app-components/Zone/_InfoPanel';
import PanelInfo from 'app-components/Zone/_InfoPanel/Info';
import PanelLoading from 'app-components/Zone/_InfoPanel/Loading';
// import PanelInfo from './PanelInfo';



// import SceneBuild from 'app-components/Zone/_Builder/SceneBuild';
// import SceneInfo from './SceneInfo';


import SideNavInfo from '../SideNavInfo';
import SceneInfo from './SceneInfo';



// -- library -- //

import { 
    //LibArray,
    LibElem,
    //LibString
} from 'library';




const Index = (props) => {



    //const Scene = SceneBuild(SceneInfo());
    // const InitScene = SceneInfo(props.data);
    // console.log("Overview InitScene:::: "+JSON.stringify(InitScene,null,2));


    // -- window dims -- //
    const { 
        width,
        height 
    } = useWindowDimensions();


    // -- breadcrumbsHook -- //
    const breadcrumbsHook = useBreadcrumbs();
    const breadcrumbs = breadcrumbsHook.breadcrumbs;
    const handleBreadcrumbs = breadcrumbsHook.handle;



    // -- redux -- //
    const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));


    //const Scene = SceneBuild(SceneInfo());
    const InitScene = SceneInfo({

        zoneObj:zoneObj

    });
    //console.log("Overview InitScene:::: "+JSON.stringify(InitScene,null,2));



    const SideNavData = SideNavInfo({

        zoneObj:zoneObj

    });
    //console.log("Overview SideNavData:::: "+JSON.stringify(SideNavData,null,2));






    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    const history = useHistory();

    

    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);




    let mainpath;
    mainpath = pathname.split("/")[0];

    let subpath;
    subpath = pathname.split("/")[1];
    if(subpath === undefined) subpath = false;

    let sub2path;
    sub2path = pathname.split("/")[2];
    if(sub2path === undefined) sub2path = false;





    //=============================================
    // refs
    //=============================================

    const [rowRefs] = useState([]);
    const setRowRef = (ref) => {

        if(ref && ref !== null && ref !== undefined)
        {
            //console.log("ref::::"+ref.id);
            var found;
            found = false;
            for(var i=0; i < rowRefs.length; i++)
            {
                if(rowRefs[i].id === ref.id) found = true;
            }
            if(!found) rowRefs.push(ref);
            //console.log(boxRefs.length);

        }

    };



    // -- ref -- //
    const refStage = useRef(null);
    const refScrollbox = useRef(null);
    const refTopbar = useRef(null); 
    const refSide = useRef(null);
    const refSide2 = useRef(null);
    const refMain= useRef(null);



    //=============================================
    // state 
    //=============================================

    const [isLoaded, setIsLoaded] = useState(false);
 
    useEffect(() => {

        if(!isLoaded)
        {
            setIsLoaded(true);
        }


    },
    [
        isLoaded
    ])





    
    //=============================================
    // state/effect - thresholds
    //=============================================

    // -- state -- //
    const [thresholdSide1] = useState(1049);
    const [thresholdSide2, setThresholdSide2] = useState(true);
    const [showMenu, setShowMenu] = useState(false);
    const [panelStatus, setPanelStatus] = useState({

        show:false,
        call:'init',
        data:PanelInfo.init


    });


    useEffect(() => {

        if(width > thresholdSide1 && showMenu)
        {
            setShowMenu(false);

        }
        var aboveNum = 1400;
        if(thresholdSide2 && width < aboveNum) setThresholdSide2(false);
        if(!thresholdSide2 && width > aboveNum-1) setThresholdSide2(true);

    },
    [

        width,
        thresholdSide1,
        thresholdSide2,
        showMenu

    ])


    



    //=============================================
    // state 
    //=============================================

    const [styling,setStyling] = useState({

        topbar:{

            width:false,
            height:false,
            margin:false
        },     
        scrollbox:{
            
            backgroundColor:"#191e28",
            width:false,
            height:false
        },
        main:{

            width:false,
            marginRight:false
        },
        side2:{

            backgroundImage:false,
            backgroundPosition:false,
            backgroundSize:false, 
            width:"calc(100% - 850px)",
            height:false,
            transform:false

        },

        catalog:{

            top:{

                textColor:"#fff"
                
            },
            bar:{

                backgroundColor:"#454d5f"
                
            }
     
        }


    });


    useEffect(() => {


        // if(!isLoaded)
        // {

            var mainWidth, mainWidthInt;
            var mainMargin;

            // var side2BgImage = "/_resources/icons/lightbulb.svg";
            // var side2BgPosition = false;
            // var side2BgSize = false;
            // var side2Width, side2WidthInt;
            // side2Width = "0px";


            var side2BgImage = "/_resources/icons/lightbulb.svg";
            //var side2BgImage = false;
            var side2BgPosition = false;
            var side2BgSize = false;
            var side2Width;
            var side2WidthInt;
            var side2Height;
            var side2HeightInt;
            var side2Transform;

            side2Width = "0px";
            // side2Height = false;
            side2BgSize = false;
            side2BgPosition = false;
            side2Transform = false;



            var mar;
            var maxMar;
            //alert(refMain.current.offsetWidth);
            if(refScrollbox.current.offsetWidth > 800)
            {


                mar = (refScrollbox.current.offsetWidth - refMain.current.offsetWidth)/2;
                if(refSide && refSide.current)
                {
                    maxMar = 50;
                    //console.log("mar"+mar);
                    if(mar > maxMar && refScrollbox.current.offsetWidth > refMain.current.offsetWidth+maxMar*2) mar = maxMar;
                    
                }
                //console.log("above");
                mainWidthInt = 750;
                mainWidth = mainWidthInt + "px";
                mainMargin = "0 0 0 " +mar+"px";



                // //-------------------

                // side2WidthInt = refScrollbox.current.offsetWidth - (mainWidthInt+150);
                // side2Width = side2WidthInt + "px";

                // // if(side2WidthInt > 300) 
                // //     side2Width = side2WidthInt + "px";

                // // if(side2WidthInt > 550) 
                // //     side2Width = "550px";


                // //side2BgSize = (refScrollbox.current.offsetHeight) + "px " + (refScrollbox.current.offsetHeight) + "px";

                // //side2BgSize = (side2Width * 2) + "px " + (side2Width * 2) + "px";

                // side2BgSize = "auto 100vh";

                // side2BgPosition = "bottom 20px right 100%";

                // //------------------



                //------------------
                
                //side2WidthInt = refScrollbox.current.offsetWidth - (refMain.current.offsetWidth+75);
                side2WidthInt = refScrollbox.current.offsetWidth - (mainWidthInt+mar+60);
                side2Width = side2WidthInt + "px";


                side2HeightInt = refMain.current.offsetHeight;
                side2Height = side2HeightInt + "px";



                // if(side2WidthInt > 300) 
                //     side2Width = side2WidthInt + "px";

                // if(side2WidthInt > 550) 
                //     side2Width = "550px";


                //side2BgSize = (refScrollbox.current.offsetHeight) + "px " + (refScrollbox.current.offsetHeight) + "px";

                //side2BgSize = (side2Width * 2) + "px " + (side2Width * 2) + "px";

                if(side2WidthInt > 300) 
                {
                    if(panelStatus.call === 'off' || panelStatus.call === 'closed')
                    {
                        side2BgImage = "/_resources/logos/tpc_profit_pie.svg";

                    }
                }
                side2BgSize = "auto 100vh";
                side2BgPosition = "top -15px right -325px";

                side2Transform = "rotate(0deg)";


                //---------------------




            }else{


                if(refScrollbox.current.offsetWidth < 801)
                {
                    //console.log("above");
                    mainWidth = "92%";
                    mainMargin = "0 0 0 4%";
                }else{
                    //console.log("below");
                    mainWidth = "92%";
                    mainMargin = "0 0 50px 4%";
                }


            }




            var scrollboxBackgroundColor = "#191e28";
            var catalog = {

                top:{
    
                    textColor:"#fff"
                    
                },
                bar:{
    
                    backgroundColor:"#454d5f"
                    
                }
         
            }


            var topbarObj;
            topbarObj = {

                width:"calc(100% - 0px)",
                height:refTopbar.current.offsetHeight+(3)+"px",
                margin:"0 0 0 0"

            };
            var scrollboxObj;
            scrollboxObj = {

                backgroundColor:scrollboxBackgroundColor,
                width:"calc(100% - 0px)",
                height:"calc(100% - "+refTopbar.current.offsetHeight+"px)",
                margin:"0 0 50px 0"

            };
            if(refSide && refSide.current)
            {

                topbarObj = {
                
                    width:"calc(100% - "+refSide.current.offsetWidth+"px)",
                    height:refTopbar.current.offsetHeight+(3)+"px",
                    margin:"0 0 0 "+refSide.current.offsetWidth+"px"
                }
                scrollboxObj = {
                
                    backgroundColor:scrollboxBackgroundColor,
                    width:"calc(100% - "+refSide.current.offsetWidth+"px)",
                    height:"calc(100% - "+refTopbar.current.offsetHeight+"px)",
                    margin:"0 0 50px "+refSide.current.offsetWidth+"px"
                }

            }


            setStyling({

                side:{

                    backgroundColor:"#191e28"

                },
                topbar:topbarObj,
                scrollbox:scrollboxObj,
                main:{
        
                    width       :mainWidth,
                    margin      :mainMargin
                },
                side2:{

                    backgroundImage     :side2BgImage, 
                    backgroundPosition  :side2BgPosition,
                    backgroundSize      :side2BgSize,            
                    width               :side2Width,
                    //height              :refMain.current.offsetHeight + "px",
                    height              :side2Height,
                    transform           :side2Transform

                },
                catalog:catalog
        
            });

        //}

        //console.log("scrollbox w: "+refScrollbox.current.offsetWidth);





    },
    [

        isLoaded,

        width,
        height,

        refSide,
        refMain,
        refScrollbox,
        refTopbar,

        panelStatus,

        mainpath

    ])


    

    //=============================================
    // state/effect - showMenu
    //=============================================

    // -- state -- //
    // const [showMenu, setShowMenu] = useState(false);

    // useEffect(() => {

    //     if(width > threshold && showMenu)
    //     {
    //         setShowMenu(false);

    //     }
    // },
    // [

    //     width,
    //     threshold,
    //     showMenu
    // ])







    //=============================================
    // state/effect - isMobiPanel
    //=============================================

    const [isMobiPanel, setIsMobiPanel] = useState(false);

    useEffect(() => {

        if(!thresholdSide2)
        {
            if(!isMobiPanel) 
            {
                setIsMobiPanel(true);
                setPanelStatus({

                    show:false,
                    call:'closed',
                    data:[]

                });

            }

        }else{

            if(isMobiPanel) 
            {
                setIsMobiPanel(false); 
                setPanelStatus({

                    show:true,
                    call:'graphic',
                    data:[]
    
                });
        
            }

        }

    },
    [

        location,
        thresholdSide2,
        isMobiPanel

    ])









    //=============================================
    // state/effect - fetch, alter
    //=============================================

    const [runFetch, setRunFetch] = useState(false);
    const [wasFetched, setWasFetched] = useState(false);
    const [fetchData] = useState({

        call:"fetchAll",
        callb:false//used if getting a specific

    });
    //const [runAlter, setRunAlter] = useState(false);
    //const [showMessngr, setShowMessngr] = useState(false);
    //const [Scene, setScene] = useState([]);

    useEffect(() => {

        // var array = [];
        // var top,bar,catalogItems;
        // var array;
        // var calcs,keyname;
        // var sortedItems;


        //console.log("Scene: "+JSON.stringify(Scene,null,2));


    

        //console.log("InitScene: "+InitScene[2]);

        if(InitScene[2].items.length > 3 && !wasFetched)
        {
            //has scene info setScene is complete
            //console.log('Has Scene');
            //setScene(InitScene);
            setWasFetched(true);       
            setRunFetch(false);

        }
        else
        if(!zoneObj['knowledge_base'] || zoneObj['knowledge_base'] === undefined)
        {
            // run fetch - data is set on handleProcess
            //console.log('Running Fetch');
            if(!runFetch) setRunFetch(true);

        }else{

            // console.log("Has Scene - part 2");
            // console.log("wasFetched: "+wasFetched);
            if(runFetch) setRunFetch(false);

        }


    },
    [
        isLoaded,
        zoneObj,
        wasFetched,
        InitScene,SideNavData,
        runFetch

   
    ]);







    //=============================================
    // state/effect/handle - editMode stuff
    //=============================================

    const [editMode, setEditMode] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [highZ, setHighZ] = useState(99);

    useEffect(() => {

        if(!isLoaded)
        {
            // alert(zoneKey);
            //console.log("isn't loaded");
            setIsLoaded(true);
            var highZindex = LibElem.HighestZ();
            setHighZ(highZindex);


            if(localStorage.editMode)
            {
                setEditMode(true);
            } 
        

        }



        if(zoneObj.knowledge_base)
        {
            //alert('has kb')
            if(zoneObj.knowledge_base.settings) 
            {
                //alert('has settings')
                //alert(zoneObj.knowledge_base.settings.isAdmin+"\nIP ADDRESS: "+zoneObj.knowledge_base.settings.ipAddress)
                if(zoneObj.knowledge_base.settings.isAdmin && !isAdmin)
                {
                    //alert('no admin');

                    setIsAdmin(true); 

                } 
            }
        }


    },
    [

        zoneObj,
        isLoaded,
        editMode,
        isAdmin,
        highZ

    ]);


    const handleEditMode = (hndl) => {

        if(editMode)
        {
            localStorage.removeItem('editMode');
            setEditMode(false);
        }
        else
        {
            localStorage.setItem("editMode",true);
            setEditMode("edit");
        }

        //window.location.reload();
        history.go(0);
 
    };








    //=============================================
    // state/effect - 
    //=============================================

    //const [contentHeight, setContentHeight] = useState(0);



    //=============================================
    // handle
    //=============================================

    const handle = (hndl) => {

        switch(hndl.call)
        {
        // case'breadcrumb':

        //     var pathArr = pathname.split("/");
        //     var url = "/";

        //     if(hndl.data !== 'Home')
        //     {
        //         var i;
        //         for(i=0; i < pathArr.length; i++)
        //         {
        //             if(pathArr[i] === hndl.data)
        //             {
        //                 url = url + hndl.data;
        //                 break;
        //             }else{
        //                 url = url + pathArr[i]+"/";
        //             }
        //         }

        //     }

        //     //if(url.charAt(url.length-1) === '/') 
        //         //url = url.substring(0,url.length-1);

        //     if(url.charAt(url.length-1) !== '/') 
        //         url = url + "/";

        //     //alert(url);


        //     if(hndl.data === 'Home')
        //     {
        //         url = "http://theprofitcalculator.com";
        //         window.location.replace(url);
        //     }else{
        //         history.push(url);
        //     }


        // break;
        case'menu':

            switch(hndl.data)
            {
            case'close':
                setShowMenu(false);
            break;
            default:
                setShowMenu(true);
            }
        break;
        case'fetched':


            //alert('fetched');


            if(runFetch && !wasFetched)
            {
                setRunFetch(false);
                setWasFetched(true);

                // console.log("MESSAGE: "+hndl.message);
                // console.log("RESET: "+hndl.reset);
                // console.log("RESULT: "+JSON.stringify(hndl.result,null,2));


                // var msg = hndl.message;

                // if(Object.keys(hndl.result).length > 0)
                // {
                //     msg = msg + " - Has Data";
                // }else{
                //     msg = msg + " - Has No Data";
                // }

                // //console.log(msg);


        
                // setShowMessngr({

                //     call:false,
                //     message:'',
                //     duration:false,
                //     alfaBgColor:"#191e28",
                //     data:{

                //         isError: false,
                //         errorType:'',
                //         isTimed:true,
                //         timedDuration: 2000,
                //         items:[

                //             {
                //                 "call":"heading",
                //                 "text":"::Fetched::" 
                //             },
                //             {
                //                 "call":"span",
                //                 //"text":"Update was Successfull!!" 
                //                 "text":msg
                //             }
                    
                //         ]

                //     },
                //     style:{

                //         headingBarColor:"#caffca"
                //     }
        
                // });


                // setTimeout(function(){


                //     setShowMessngr(false);


                // },2000);


                var knowledge_base = false;
                if(localStorage.editMode)
                {

                    //EDIT
                    if(Object.keys(hndl.result).length > 0)
                    {
                        if(hndl.result 
                        && hndl.result !== undefined)
                        {
                            knowledge_base = hndl.result;
                        }
                    }
                    // console.log("===== edit - fetched =====");
                    // console.log("knowledge_base: "+JSON.stringify(knowledge_base.page,null,2));
                    // //alert('edit - fetched');



                }else{


                    //READ
                    if(Object.keys(hndl.result).length > 0)
                    {
                        if(hndl.result 
                        && hndl.result !== undefined)
                        {
                            knowledge_base = hndl.result;

                            var scene,object;
                            var key,value;

                            scene = knowledge_base.scene;

                            switch(Object.prototype.toString.call(scene))
                            {
                            case'[object Array]':
            
                                for(object of scene)
                                {
                                    if(!object.currentScene.sceneCompName.match(/Navigator/gi))
                                    {
                                        key = object.currentScene.sceneCompName;
                                        key = "knowledge_base_"+key;  
                    
                                        value = {scene:object};

                                        dispatch(ModifyZoneStore({

                                            type:"UPDATE_ZONE",//UPDATE_ZONE or DELETE_ZONE
                                            item:{
                                                key     :key,
                                                value   :value
                                            }
                                
                                        })); 

                                        // console.log("####################\n"+JSON.stringify(object,null,2));
                                        // alert('checking zoneStoreKey '+key)
                                        // break;
                                    }
                                  
                                }
            
                            break;
                            default:
                            }

                        }
                    }


                    // console.log("===== read - fetched =====");
                    // console.log("knowledge_base: "+JSON.stringify(knowledge_base,null,2));
                    // //alert('read - fetched');


                }




                //----------------------------
                //dispatch
                //----------------------------

                if(knowledge_base)
                {

                    //console.log("KNOWLEDGE BASE::: "+JSON.stringify(hndl.result,null,2));
                    //alert("dispatch knowledge_base");
                
                    dispatch(ModifyZoneStore({

                        type:"UPDATE_ZONE",//UPDATE_ZONE or DELETE_ZONE
                        item:{
                            key     :"knowledge_base",
                            value   :knowledge_base
                        }
            
                    })); 

                }

            }//runFetch



        break;
        case'contentHeight':


            // if(location.hash !== "" && !hndl.data.showReadout && hndl.data.height < 500)
            // {
            //     history.push("");
            // }
            // var newHeight = hndl.data.height+225;
            // if(newHeight < refStage.current.offsetHeight)
            // {
            //     newHeight = refStage.current.offsetHeight;
            // }
            // if(thresholdSide2 || width > 1200) setContentHeight(newHeight);


            // alert(newHeight);

        break;
        default:
        }


    }


    //=============================================
    // handleClick
    //=============================================

    const handleClick = (hndl) => {


        switch(hndl.call)
        {
        case'calc':


            //--------------
            //QUERY
            //--------------

            // var url = "";
            // //var url = pathname+"/Calculator/";
            // if(hndl.data.id)
            // {
            //     url = url + "?id=" + hndl.data.id + "&name="+hndl.data.name
            // }else{
            //     url = url + "?id=calc&name=The Profit Calculator"
            // }
            // if(url.match(/\/\//gi)) url = url.replace(/\/\//gi,"/");
            // history.push(url); 



            //---------------
            //STATE
            //---------------

            // history.push("#!Calculator");
            // setCalcHash(true);
            // if(hndl.data.id)
            // {
            //     setCalcId(hndl.data.id);
            //     setCalcName(hndl.data.name);
            // }else{
            //     setCalcId("calc");
            //     setCalcName("The Profit Calculator");
            // }//==

        break;
        default:

            var url = "";
            url = hndl.data.id+"/";
            history.push(url); 
        }

    }









    //=============================================
    // state/effect - fetch, alter
    //=============================================

    const [runFetchPanel, setRunFetchPanel] = useState(false);
    const [wasFetchedPanel, setWasFetchedPanel] = useState(false);
    const [fetchPanelData, setFetchPanelData] = useState({

        call:"fetchAll",
        callb:false//used if getting a specific

    });


    useEffect(() => {


        if(!zoneObj['info_panel'] || zoneObj['info_panel'] === undefined)
        {
            // run fetch - data is set on handleProcess
            //console.log('Running Fetch');
            if(!runFetchPanel) 
            {
                setRunFetchPanel(true);
                setFetchPanelData({

                    call:"fetchAll",
                    callb:false//used if getting a specific
            
                });           
            }

        }else{

            if(!zoneObj['info_panel'].ads || zoneObj['info_panel'].ads === undefined)
            {
                // console.log("Has Scene - part 2");
                // console.log("wasFetched: "+wasFetched);
                if(!runFetchPanel) 
                {
                    setRunFetchPanel(true);
                    setFetchPanelData({

                        call:"fetch",
                        callb:'ads'//used if getting a specific
                
                    });   
                }


            }
            else
            if(!zoneObj['info_panel'].howto || zoneObj['info_panel'].howto === undefined)
            {


                var info_panel = JSON.parse(JSON.stringify(zoneObj.info_panel));


                var howto = [];
                if(PanelInfo.howto)
                {
                    howto = PanelInfo.howto
                }
                info_panel.howto = howto;

                //----------------------------
                //dispatch
                //----------------------------

                if(info_panel)
                {
                    //console.log("KNOWLEDGE BASE::: "+JSON.stringify(hndl.result,null,2));
                    //alert("dispatch knowledge_base");
                
                    dispatch(ModifyZoneStore({

                        type:"UPDATE_ZONE",//UPDATE_ZONE or DELETE_ZONE
                        item:{
                            key     :"info_panel",
                            value   :info_panel
                        }
            
                    })); 

                }

            

            }
            else
            {

                if(runFetchPanel) 
                {
                    setRunFetchPanel(false);
                    setWasFetchedPanel(true);

                }

            }

        }


    },
    [
        zoneObj,dispatch,
        wasFetchedPanel,
        runFetchPanel

    ]);



    //=============================================
    // state/effect/handle - panelStatus
    //=============================================

    useEffect(() => {

        if(panelStatus.call !== 'closed')
        {

            if(!thresholdSide2)
            {

                if(panelStatus.call === 'init')
                {
                    setPanelStatus({

                        show:true,
                        call:'closed',
                        data:[]

                    });

                } 


            }else{


                if(panelStatus.call === 'init')
                //if(panelStatus.call !== 'graphic' && panelStatus.call !== 'ads')
                {
                    setPanelStatus({

                        show:true,
                        call:'graphic',
                        data:[]

                    });

                } 

            }






        }

    },
    [

        location,
        panelStatus,
        thresholdSide2

    ])



    const handlePanel = (hndl) => {

        //alert("hndl.call " + hndl.call);

        switch(hndl.call)
        {
        case'fetched':


            //alert('fetchedPanel');

            if(runFetchPanel && !wasFetchedPanel)
            {

                setRunFetchPanel(false);
                setWasFetchedPanel(true);

                // console.log("MESSAGE: "+hndl.message);
                // console.log("RESET: "+hndl.reset);
                // console.log("RESULT: "+JSON.stringify(hndl.result,null,2));

        
                // var msg = hndl.message;

                // if(Object.keys(hndl.result).length > 0)
                // {
                //     msg = msg + " - Has Data";
                // }else{
                //     msg = msg + " - Has No Data";
                // }

                // console.log(msg);


                var info_panel = false;
                if(localStorage.editMode)
                {
                    // //EDIT
                    if(Object.keys(hndl.result).length > 0)
                    {
                        if(hndl.result 
                        && hndl.result !== undefined)
                        {
                            info_panel = hndl.result;
                        }
                    }
                    // console.log("===== edit - fetched =====");
                    // console.log("info_panel: "+JSON.stringify(info_panel.page,null,2));
                    // //alert('edit - fetched');

                }else{

                    // //READ
                    if(Object.keys(hndl.result).length > 0)
                    {
                        if(hndl.result 
                        && hndl.result !== undefined)
                        {
                            info_panel = hndl.result;
                        }
                    }


                    // console.log("===== read - fetched =====");
                    // console.log("info_panel: "+JSON.stringify(info_panel,null,2));
                    // //alert('read - fetched');


                }//editMode


              
                //----------------------------
                //dispatch
                //----------------------------

                if(info_panel)
                {

                    // console.log("INFO_PANEL::: "+JSON.stringify(hndl.result,null,2));
                    // alert("dispatch info_panel");
                
                    dispatch(ModifyZoneStore({

                        type:"UPDATE_ZONE",//UPDATE_ZONE or DELETE_ZONE
                        item:{
                            key     :"info_panel",
                            value   :info_panel
                        }
            
                    })); 

                }

            }//runFetchPanel



        break;
        case'url':

            // check url

            // var href = window.location.href;                //returns the href (URL) of the current page
            // var host = window.location.host;                //returns the domain with port number
            // var hostname = window.location.hostname;        //returns the domain name of the web host
            // var pathname = window.location.pathname;        //returns the path and filename of the current page
            // var protocol = window.location.protocol;        //returns the web protocol used (http: or https:)
            // var assign = window.location.assign;            //loads a new document

            var externalUrl = false;
            var url;
            url = hndl.data;

            var internalSite = "site.site/";

            var regxInternalSite = new RegExp(internalSite,"gi");
            var regxHost = new RegExp(window.location.host,"gi");
            if(url.match(regxInternalSite))
            {
                url = url.split(".site/")[1];
            }//==
            else
            if(!url.match(regxHost))
            {
                externalUrl = true;
            }//==
            //alert("handlePanel url " + url);



            if(externalUrl)
            {
                props.primeProps.handle({

                    call:'url',
                    data:{

                        action:'target',
                        url:url

                    }

                });

            }else{

                props.primeProps.handle({

                    call:'url',
                    data:{

                        action:'push',
                        url:url

                    }

                });

            }


        break;
        case'topbar':


            if(!thresholdSide2)
            {
                setPanelStatus({

                    show:true,
                    call:'howto',
                    data:PanelInfo.howto,
                    height:"auto"
    
                });
    

            }else{

                setPanelStatus({

                    show:true,
                    call:'graphic',
                    data:[]
    
                });

            }

        break;
        case'close':


            if(!thresholdSide2)
            {
                setPanelStatus({

                    show:false,
                    call:'closed',
                    data:[]

                });

            }else{

                setPanelStatus({

                    show:true,
                    call:'graphic',
                    data:[]
    
                });

            }


        break;
        case'ads':


            //alert("ADS: "+JSON.stringify(PanelInfo.ads));

            setPanelStatus({

                show:true,
                call:'ads',
                data:PanelInfo.ads,
                height:"auto"

            });

        break;
        case'howto':

            setPanelStatus({

                show:true,
                call:'howto',
                data:PanelInfo.howto,
                height:"auto"

            });

        break;
        case'graphic':

            setPanelStatus({

                show:true,
                call:'graphic',
                data:[]

            });

        break;
        default:



            //alert(panelStatus.call);

            if(panelStatus.call === 'closed')
            {

                setPanelStatus({

                    show:true,
                    call:'opened',
                    data:[]

                });


            }else{



                if(!thresholdSide2)
                {

                    //alert('thresholdSide2')

                    setPanelStatus({
    
                        show:false,
                        call:'closed',
                        data:[]
    
                    });
    
                }else{
    
                    setPanelStatus({
    
                        show:true,
                        call:'graphic',
                        data:[]
        
                    });
    
                }



            }


        }

    }







    if(!isLoaded) 
    {

        //return <div className="area-title">Loading...</div>


        return (

            <Stage
            id={"stage"}
            ref={refStage}
            >

                <Side
                ref={refSide}
                >
                </Side>


                <Topbar
                ref={refTopbar}
                width={styling.topbar.width} 
                margin={styling.topbar.margin}
                >
                </Topbar>
                

                <ScrollBox
                ref={refScrollbox}
                width={styling.scrollbox.width} 
                height={styling.scrollbox.height} 
                margin={styling.scrollbox.margin}
                >

                    <Main
                    ref={refMain}
                    width={styling.main.width} 
                    margin = {styling.main.margin}
                    >


                    </Main>    

                </ScrollBox>


            </Stage>




        )



    } 
    else 
    {


        return (

            <Stage
            id={"stage"}
            ref={refStage}
            >




                {
                //=========================================================
                runFetch
                ?
                    <div>

                        {/* <Loader 
                            alfaBgColor={"transparent"}
                            styling={{

                                box:{
                        
                                    backgroundColor:'#191e28',
                                    border:"lightgray",
                                    width:false,
                                    height:false,
                                    top:"120px",
                                    left:false
                        
                                },
                        
                                loader:{
                        
                                    class       :'sm-loader-circle',
                                    lowlight    : "0.5em solid rgba(250,250,250,0.2)",
                                    highlight   : "0.5em solid #fff"
                        
                                }
                        
                            }}
                        /> */}


                        <Loading />
                        <Fetch

                            primeProps={props.primeProps}
                            handle={handle}
                            //duration={2500}
                            data={fetchData}
                            // formArr={props.formArr}
                            // captured={props.captured}

                        />

                    </div>

                :null
                //=========================================================
                }




                {
                //=========================================================
                runFetchPanel
                ?
                    <div>

                        {/* <Loading /> */}
                        <FetchPanel

                            primeProps={props.primeProps}
                            handle={handlePanel}
                            //duration={2500}
                            data={fetchPanelData}
                            // formArr={props.formArr}
                            // captured={props.captured}

                        />

                    </div>

                :null
                //=========================================================
                }








                {width < thresholdSide1 && showMenu
                ?

                    <div>

                        <MenuAlfa
                        onClick={(v) => handle({

                            call:'menu',
                            data:"close"

                        })}
                        ></MenuAlfa>
                        <Menu
                        >
                            <MenuControls>

                                <div className="Title">

                                    {"Menu"}

                                </div>

                                <div 
                                className="Button"
                                onClick={(v) => handle({

                                    call:'menu',
                                    data:"close"
        
                                })}
                                >

                                    {"X"}
                                                                    
                                </div>

                            </MenuControls>

                            <Navigator

                                handle      = {(v) => props.handle(v)} 
                                data        = {SideNavData}
                                refid       = {"dashboard_sidenav"}

                                viewHeight  = {false}
                                databind    = {props.databind}

                            />

                        </Menu>


                    </div>


                :null}


                {width > thresholdSide1
                ?

                    <Side
                    ref={refSide}
                    //backgroundColor={styling.side.backgroundColor}
                    //border={"0px solid lightgray"}
                    >
                        <Navigator

                            handle      = {(v) => props.handle(v)} 
                            data        = {SideNavData}
                            refid       = {"dashboard_sidenav"}

                            viewHeight  = {false}
                            databind    = {props.databind}

                        />

                    </Side>


                :null}



                <Topbar
                ref={refTopbar}
                width={styling.topbar.width} 
                margin={styling.topbar.margin}
                >

                    <div
                    className={"Left"}
                    >


                        {
                        width < thresholdSide1
                        ?
                            <div
                            className={"MenuButton"}
                            onClick={(v) => handle({

                                call:'menu',
                                data:"open"

                            })}
                            >
                                <div className={"Slice"}></div>
                                <div className={"Slice"}></div>
                                <div className={"Slice"}></div>

                            </div>

                        :null
                        }





                        {breadcrumbs.map((crumb, i) => (

                            <div
                            key={i}
                            className={"Button"}
                            onClick={(v) => handleBreadcrumbs({

                                call:'breadcrumb',
                                data:crumb

                            })}
                            >
                                {crumb}

                            </div>

                        ))}





                    </div>


                    {
                    width > 550
                    ?
                        <div
                        className={"Right"}
                        >

                            <div
                            className={"PanelButton"}
                            onClick={(v) => handlePanel({

                                call:'topbar',
                                data:[]

                            })}
                            >
                                INFO PANEL

                            </div>

                        </div>

                    :null
                    }



                </Topbar>




                {
                width < 551
                ?
                    <FlyTray
                    top={styling.topbar.height}
                    right={"0px"}
                    >
                        <div
                        className={"PanelButton"}
                        onClick={(v) => handlePanel({

                            call:'topbar',
                            data:[]

                        })}
                        >
                            INFO.

                        </div>

                    </FlyTray>

                :null
                }





                <ScrollBox
                ref={refScrollbox}
                //backgroundColor={"#191e28"}
                backgroundColor={styling.scrollbox.backgroundColor} 
                width={styling.scrollbox.width} 
                height={styling.scrollbox.height} 
                margin={styling.scrollbox.margin}
                >



                    <Main
                    ref={refMain}
                    // backgroundColor={"lightblue"}
                    width={styling.main.width} 
                    margin = {styling.main.margin}
                    >



                        <div
                        style={{

                            backgroundColor:"transparent",
                            width:"100%",
                            height:"auto",
                            margin:"0 0 0 0",
                            padding:"0 0 200px 0",
                            position:"relative",
                            float:"left"

                        }}
                        > 


                            <Catalog
                            //backgroundColor={'lightyellow'}
                            minHeight={'450px'}
                            >

                                {InitScene.map((section, i) => (

                                    <div
                                    key={i}
                                    >
                                        {

                                        section.call === 'top'
                                        ?

                                            <CatalogTop>

                                                <CatalogTopName
                                                color={styling.catalog.top.textColor} 
                                                >

                                                    {section.name}

                                                </CatalogTopName>

                                            </CatalogTop>

                                        :
                                        section.call === 'bar'
                                        ?

                                            <div>

                                                <CatalogBar
                                                backgroundColor={styling.catalog.bar.backgroundColor}
                                                border={"0"}
                                                width={"100%"}
                                                height={"1px"}
                                                >
                                                </CatalogBar>

                                            </div>

                                        :
                                        section.call === 'items'
                                        ?

                                            <div>

                                                {section.items.map((itm, j) => (

    
                                                    <CatalogTileDark

                                                    ref={setRowRef}                              
                                                    key={i+"-"+j}
                                                    id={"row"+i+"-"+j}

                                                    onClick={(v) => handleClick({


                                                        call:"default",
                                                        data:{
    
                                                            id:itm.data.id,
                                                            name:itm.data.name
                                                        }
    
    
                                                    })}
                                                    >

                                                        <div className="Left"
                                                        // onClick={(v) => handleClick({


                                                        //     call:"default",
                                                        //     data:{
        
                                                        //         id:itm.data.id,
                                                        //         name:itm.data.name
                                                        //     }
        
        
                                                        // })}
                                                        >

                                                            {/*
                                                            <div className="Graphic"
                                                            >

                                                                <img
                                                                id={"svg-"+i+"-"+j+"-"+k}
                                                                src={SVGProfitPie} 
                                                                alt="del" />


                                                            </div> 
                                                            */}


                                                            {/* 
                                                            <div
                                                            className="Circle"
                                                            >
                                                                <div className="Letter">

                                                                    {itm.data.name.charAt(0)}


                                                                </div>

                                                            </div>
                                                            */}



                                                            <div className="Graphic">


                                                                {

                                                                // itm.data.id.toUpperCase() === 'ACCOUNT'
                                                                // ?

                                                                //     <ACCOUNT_GRAPHIC
                                                                    
                                                                //         iconColor = {itm.data.graphic.color}
                                                                //         darklite = {itm.data.graphic.darklite}

                                                                //     />

                                                                // :  
                                                                // itm.data.id.toUpperCase() === 'SCHEDULE'
                                                                // ?

                                                                //     <SCHEDULE_GRAPHIC
                                                                    
                                                                //         iconColor = {itm.data.graphic.color}
                                                                //         darklite = {itm.data.graphic.darklite}

                                                                //     />

                                                                // :  
                                                                // itm.data.id.toUpperCase() === 'OVERHEAD'
                                                                // ?

                                                                //     <OVERHEAD_GRAPHIC
                                                                    
                                                                //         iconColor = {itm.data.graphic.color}
                                                                //         darklite = {itm.data.graphic.darklite}

                                                                //     />

                                                                // :  
                                                                // itm.data.id.toUpperCase() === 'EXPENSES'
                                                                // ?

                                                                //     <EXPENSES_GRAPHIC
                                                                    
                                                                //         iconColor = {itm.data.graphic.color}
                                                                //         darklite = {itm.data.graphic.darklite}

                                                                //     />

                                                                // :  
                                                                // itm.data.id.toUpperCase() === 'OWNERS'
                                                                // ?

                                                                //     <OWNER_GRAPHIC
                                                                    
                                                                //         iconColor = {itm.data.graphic.color}
                                                                //         darklite = {itm.data.graphic.darklite}

                                                                //     />

                                                                // :  
                                                                // itm.data.id.toUpperCase() === 'EMPLOYEES'
                                                                // ?

                                                                //     <EMPLOYEE_GRAPHIC
                                                                    
                                                                //         iconColor = {itm.data.graphic.color}
                                                                //         darklite = {itm.data.graphic.darklite}

                                                                //     />


                                                                // :

                                                                    <div
                                                                    className="Circle"
                                                                    style={{
                                                                        backgroundColor:itm.bullet.backgroundColor
                                                                    }}
                                                                    >
                                                                        <div className="Letter"
                                                                        style={{
                                                                            color:itm.bullet.textColor
                                                                        }}
                                                                        >
        
                                                                            {itm.data.name.charAt(0)}
        
        
                                                                        </div>
        
                                                                    </div>
        



                                                                }


                                                            </div>









                                                        </div>


                                                        <div className="Right"
                                                        // onClick={(v) => handleClick({


                                                        //     call:"default",
                                                        //     data:{
        
                                                        //         id:itm.data.id,
                                                        //         name:itm.data.name
                                                        //     }
        
        
                                                        // })}
                                                        >

                                                            <div className="Title"
                                                            >

                                                                {itm.heading}

                                                            </div>

                                                
                                                            <div className="Descr"
                                                            >
                                                                {itm.pgraph}

                                                            </div>


                                                        </div>


                                                    </CatalogTileDark>

                                                

                                                ))}


                                            </div>


                                        :null

                                        }

                                    </div>

                                ))}

                            </Catalog>
                        

                        </div>
   
                            
                    </Main> 



                    {/* 
                    <Side2
                    backgroundImage={styling.side2.backgroundImage}
                    backgroundPosition={styling.side2.backgroundPosition}
                    backgroundSize={styling.side2.backgroundSize}                     
                    //backgroundColor={"#454d5f"}
                    width={styling.side2.width}
                    >
                    </Side2>
                    */}



                    { 
                    thresholdSide2
                    ?

                        //ON
                        <Side2
                        ref={refSide2}
                        id={'side2'}
                        //backgroundColor={"purple"}  
                        backgroundColor={"#141820"}                  
                        // backgroundImage={styling.side2.backgroundImage}
                        // backgroundPosition={styling.side2.backgroundPosition}
                        // backgroundSize={styling.side2.backgroundSize}                     
                        width={styling.side2.width}
                        height={styling.side2.height}
                        //height={"auto"}
                        //transform={styling.side2.transform}
                        >

                            {runFetchPanel
                            ?
                                <PanelLoading />

                            :

                                panelStatus.call !== 'off' 
                                && panelStatus.call !== 'closed' 
                                ?

                                    <Panel

                                        primeProps={props.primeProps}
                                        //handle={(v) => handleForm(v)}  
                                        data={panelStatus.data}
                                        width={width}
                                        refScrollbox={refScrollbox}
                                        refSide2={refSide2}

                                        handlePanel={(v) => handlePanel(v)}
                                        panelStatus={panelStatus}

                                        panelW={styling.side2.width}
                                        panelH={styling.main.height}
                                      
                                    /> 

                                :null


                            }  


                        </Side2>


            

                    :
                    !thresholdSide2 && width > 1200
                    ?

                        //OFF
                  
                        <Side2
                        ref={refSide2}
                        id={'side2'}
                        //backgroundColor={"blue"}  
                        backgroundColor={"#141820"}                  
                        // backgroundImage={styling.side2.backgroundImage}
                        // backgroundPosition={styling.side2.backgroundPosition}
                        // backgroundSize={styling.side2.backgroundSize}                     
                        width={styling.side2.width}
                        //height={contentHeight+(400)+"px"}
                        height={styling.side2.height}
                        transform={styling.side2.transform}
                        >
                        </Side2>



                    :null

                    }


                </ScrollBox>




                {
                !thresholdSide2
                ?

                    runFetchPanel
                    ?
                        <PanelLoading />

                    :
                        panelStatus.call !== 'off' 
                        && panelStatus.call !== 'closed' 
                        && panelStatus.call !== 'graphic'
                        ?
                            
                            <Fly
                            backgroundColor={"#141820"}              
                            >

                                <FlyContainer
                                >

                                    <Panel

                                        primeProps={props.primeProps}
                                        //handle={(v) => handleForm(v)}  
                                        data={panelStatus.data}
                                        width={width}
                                        refScrollbox={refScrollbox}
                                        refSide2={refSide2}

                                        handlePanel={(v) => handlePanel(v)}
                                        panelStatus={panelStatus}

                                        panelW={styling.side2.width}
                                        panelH={styling.side2.height}

                                    /> 


                                </FlyContainer>


                            </Fly>


                        :null

                :null
                }





                {
                isAdmin ?

                    <Tray
                    zIndex={highZ}
                    >

                        <div className={"Button"}
                        onClick={(v) => handleEditMode({

                            call:"change"

                        })}
                        >
                            {
                            editMode ?

                                "MODE: Edit"
                            :
                                "MODE: Read"
                            }
                        
                        </div>

                    </Tray>

                :null
                }





            </Stage>

        )


    }

}

export default Index;


