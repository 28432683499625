
import React from 'react';


import Messngr from 'utils/components/Process/Messngr';


export const FScomplete = (props) => {


    //console.log(JSON.stringify(props.errorArr,null,2));
    //alert('FSerror')


    // -- display -- //

    var successArr;
    successArr = [

        {
            "call":"heading",
            "text":"Success" 
        },
        {
            "call":"span",
            "text":"Message Was Sent Successfully!!" 
        }

    ];


    let data = {

        isError         : false,
        errorType       : '',
        isTimed         : true,
        timedDuration   : 2000,
        items           : successArr

    };


    return ( 

        <div>

            <Messngr 

                handle={(v) => props.handle(v)}
                data={data}
            
            />
           
        </div>

    );




}





