import React, { 

    useState, 
    useEffect,
    //useRef

} from 'react';


//import 'app-style/price-form/style.css';
//import '../../StyleSheets/Readout.css';
import 'app-components/Zone/_StyleSheets/Readout.css';




// const FFheading = (props) => {

//     return (

//         <div
//         key={props.indx+"_ffheading"} 
//         className = "heading"
//         >

//             {props.data.text}
            
//         </div>

//     )

// }




// -- calcHeading -- //

const FFheading = (props) => {

    //=============================================
    // state/effect - logoWidth
    //=============================================

    const[fontSize, setFontSize] = useState("30px");

    useEffect(() => {

        if(props.data.text.length > 20)
        {
            setFontSize("24px");
        }

    },
    [
        props

    ])


    return (

        <div
        key={props.indx+"_ffheading"} 
        className = "calcHeading"
        >
            <div
            className = "title"
            style={{

                fontSize:fontSize
            }}
            >

                {props.data.text}
                
            </div>

        </div>

    )
    
}






const FFheader = (props) => {

    return (

        <div
        key={props.indx+"_ffheader"} 
        className = "header"
        >

            {props.data.text}
            
        </div>

    )

}



const FFheadline = (props) => {

    return (

        <div
        key={props.indx+"_ffheadline"} 
        className = "headline"
        >

            {props.data.text}
            
        </div>

    )

}



const FFspacer = (props) => {

    return (

        <div 
        key={props.indx+"_ffspacer"} 
        className = "spacer"
        style={{

            //backgroundColor     :"yellow",
            width               :"100%",
            height              :props.data.height,
            margin              :"0 0 0 0"

        }}

        >

            {props.data.text}
            
        </div>

    )

}




const FFseparator = (props) => {

    return (

        <div 
        key={props.indx+"_ffseparator"} 
        className = "separator"
        style={{

            backgroundColor:props.data.color,
            
        }}
        > 
        </div>

    )

}






const FFtitleline = (props) => {

    return (

        <div 
        key={props.indx+"_fftitleline"} 
        className = "titleline"
        >

            {props.data.text}
            
        </div>

    )

}





const FFtitlegrid = (props) => {

    //console.log("##################");
    //console.log("##################");
    //console.log(JSON.stringify(props.data,null,2));




    /*
    //----------------------------------------------------------

    // Note: the empty deps array [] means
    // this useEffect will run once
    // similar to componentDidMount()
    useEffect(() => {


 
     },[props])
 
 
     //------------------------------------------------------------
     */



    //=======================================================
    //=======================================================

    return (


        <div 
        key={props.ij}
        className="titlegrid"
        >
        {props.data.array.map((obj, k) => (


            <div 
            key={k}
            className="cell"
            style={{

                width:obj.width,
                float:obj.float

            }}
            >

                {obj.name}

            </div>


        ))}
        </div>


    

    )



}



export {
    
    FFheading,
    FFheader, 
    FFheadline,
    FFspacer,
    FFseparator,  
    FFtitleline,
    FFtitlegrid

};


