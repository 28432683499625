import { 

    useState,
    useEffect 

} from 'react';

import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    useHistory,   
    //useParams,

} from "react-router-dom";


import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';




import { 
    //LibArray,
    LibSystem 
} from 'library';



const useBreadcrumbs = (props) => {


    // -- redux -- //
    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));


    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    const history = useHistory();

 
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);




    //=============================================
    // state/effect - breadcrumbs
    //=============================================

    const [breadcrumbs, setBreadcrumbs] = useState([]);

    useEffect(() => {


        var isKnowledgeBase = false;
        var pathArr = pathname.split("/");
        var crumbs = ["Home","/"];
        var i;

        if(pathname === '')
        {
            crumbs = ["Home","/"];
            crumbs.push("Calculator");
          
        }else{


            // console.log("breadcrumbsHook - pathname: "+pathname);

            var regxKnowledgeBase = new RegExp("Knowledge Base","gi");
            if(pathname.match(regxKnowledgeBase))
            {
                isKnowledgeBase = true;
            }



            crumbs = ["Home","/"];
            for(i=0; i < pathArr.length; i++)
            {
                if(i < pathArr.length-1)
                {
                    crumbs.push(pathArr[i]);
                    crumbs.push("/");


                }
                else
                if(isKnowledgeBase)
                {

                    //alert('isKnowledgeBase')

                    var categoryName = pathArr[i];
                    var keyname;
                    var pages;
                    var page
                    var pageName = categoryName;
                    var knowledge_base;

                    if(zoneObj['knowledge_base'] && zoneObj['knowledge_base'] !== undefined)
                    {
                        knowledge_base = JSON.parse(JSON.stringify(zoneObj['knowledge_base']));
                        pages = knowledge_base.pages;

                        for(keyname in pages)
                        {
                            page = pages[keyname];
                            if(page.tree === categoryName)
                            {
                                pageName = page.name;
                                pageName = LibSystem.RemoveExt(pageName);
                                pageName = LibSystem.FileDisplay(pageName);

                                //word = 'ThisIsTheString4Split'.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
                                break;
                            }
                        }
                    }

                    //alert(pageName);
                    // pageName = pageName.replace(/\./gi,"");

                    //alert(pageName);

                    if(!pageName.match(/\./gi)) 
                        pageName = pageName.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");

                    //alert(pageName);

                    crumbs.push(pageName);

                }
                else
                {
                    crumbs.push(pathArr[i]);
                }
    
            }

        }


        //alert(JSON.stringify(crumbs));
        setBreadcrumbs(crumbs);
        
    }, [

        zoneObj,
        pathname

    ])



    //=============================================
    // handle
    //=============================================

    const handle = (hndl) => {


        switch(hndl.call)
        {
        case'breadcrumb':

            var pathArr = pathname.split("/");
            var url = "/";

            if(hndl.data !== 'Home' && hndl.data !== "Calculator")
            {
                var i;
                for(i=0; i < pathArr.length; i++)
                {
                    if(pathArr[i] === hndl.data)
                    {
                        url = url + hndl.data;
                        break;
                    }else{
                        url = url + pathArr[i]+"/";
                    }
                }

            }
            if(url.charAt(url.length-1) !== '/') url = url + "/";
            //if(url.charAt(url.length-1) === '/') 
                //url = url.substring(0,url.length-1);


            if(hndl.data === 'Home')
            {
                url = "http://theprofitcalculator.com";
                //window.location.replace(url);
                window.location.href = url;
                //history.replace(url);
            }else{
                history.push(url);
            }
     

        break;
        default:

            //alert(JSON.stringify(hndl,null,2));
        }

    }



    return {

        breadcrumbs:breadcrumbs,
        handle:handle

    };
  
};
  
export default useBreadcrumbs;






// //=============================================
// // state/effect - breadcrumbs
// //=============================================

// // -- state -- //
// const [breadcrumbs, setBreadcrumbs] = useState([]);


// useEffect(() => {

//     // if(!isLoaded)
//     // {
//     //}
//     //console.log("scrollbox w: "+refScrollbox.current.offsetWidth);

//     var pathArr = pathname.split("/");
//     var crumbs = ["Home","/"];
//     var i;
//     for(i=0; i < pathArr.length; i++)
//     {
//         if(i < pathArr.length-1)
//         {
//             crumbs.push(pathArr[i]);
//             crumbs.push("/");
//         }else{

//             //crumbs.push(pathArr[i]);

//             var categoryName = pathArr[i];
//             var keyname;
//             var pages;
//             var page
//             var pageName = categoryName;
//             var knowledge_base;


//             if(zoneObj['knowledge_base'] && zoneObj['knowledge_base'] !== undefined)
//             {
//                 knowledge_base = JSON.parse(JSON.stringify(zoneObj['knowledge_base']));
//                 pages = knowledge_base.pages;

//                 for(keyname in pages)
//                 {
//                     page = pages[keyname];
//                     if(page.tree === categoryName)
//                     {
//                         pageName = page.name;
//                         pageName = LibSystem.RemoveExt(pageName);
//                         pageName = LibSystem.FileDisplay(pageName);
//                         break;
//                     }
//                 }
//             }


//             crumbs.push(pageName);


//         }

//     }
//     setBreadcrumbs(crumbs);

// },
// [

//     pathname,
//     isLoaded,
//     zoneObj

// ])
