// import React, { 

//     useState, 
//     useEffect, 
//     //useRef 

// } from 'react';


// import { 
//     useSelector, 
//     //useDispatch 
// } 
// from 'react-redux';
// // import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';

//import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'


import {

    //ProcAlfa,
    ProcBase,
    ProcMenu   


} from './Styled';




const Index = (props) => {





    return (<div>


        {
        !props.isShowing.message && 
        !props.isShowing.renameForm && 
        !props.isShowing.saveForm && 
        !props.isShowing.favoriteForm &&
        !props.isShowing.alter &&
        !props.isShowing.fetch
        ?

            <div>

                <ProcBase
                onClick={(v) => props.handle({

                    call:"showProcessMenu",
                    data:false,
                    calcId:props.data.id

                })}
                ></ProcBase>
                <ProcMenu
                id="ProcessMenu"
                top={props.styling.top}
                >

                    <div 
                    className="Button"
                    onClick={(v) => props.handle({

                        call:'favorite',
                        action:'default',
                        calcId:props.data.id


                    })}
                    >
                        Set Favorite

                    </div>




                    <div 
                    className="Button"
                    onClick={(v) => props.handle({

                        call:'edit',
                        action:'default',
                        calcId:props.data.id


                    })}
                    >
                        Edit

                    </div>


                    <div 
                    className="Button"
                    onClick={(v) => props.handle({

                        call:'saveas',
                        action:'default',
                        calcId:props.data.id


                    })}
                    >
                        Save As New

                    </div>


                    <div 
                    className="Button"
                    onClick={(v) => props.handle({

                        call:'rename',
                        action:'default',
                        calcId:props.data.id

                    })}
                    >
                        Rename

                    </div>

                    <div 
                    className="Button"
                    onClick={(v) => props.handle({

                        call:'delete',
                        action:'default',
                        calcId:props.data.id


                    })}
                    >
                        Delete

                    </div>


                    {/* <div 
                    className="Button"
                    onClick={(v) => props.handle({

                        call:'readr',
                        action:'default',
                        calcId:props.data.id


                    })}
                    >
                        Report

                    </div> */}


                    
                </ProcMenu>

            </div>


        :null
        }



    </div>)




}

export default Index;


