import React, { 

    useState, 
    useEffect, 
    //useRef 

} from 'react';




import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    //useHistory,   
    //useParams,



} from "react-router-dom";

//import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider';
//import useDeviceDetect from 'utils/hooks/DeviceDetect';
// import useKeyPress from 'utils/hooks/KeyPress';




// import { createPortal } from 'react-dom';
// import usePortal from 'utils/hooks/Portal';



// import Navigator from 'utils/components/Content/Navigator';
// import SideNavInfo from 'utils/components/Pages/SceneInfo/Trials/SideNav/SceneInfo';



//=====================================================


import {

    Panel,
    Loader

} from './Styled';

import './StyleSheets/loader.css';



// import { Lorem } from 'app-components/Prime/Data';


// import CreateMarkup from 'utils/components/Universal/CreateMarkup';
// import CreateLink from 'utils/components/Universal/CreateLink';




const Index = (props) => {



    // -- Window Dimensions -- //
    // const { 
    //     width,
    //     //height 
    // } = useWindowDimensions();


    // -- Device Detect -- //
    //const DeviceDetection = useDeviceDetect();
    //console.log("FFcostfield - DeviceDetection " + JSON.stringify(DeviceDetection));
    //let isMobile = DeviceDetection.isMobile;



    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();

    
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);



    // -- redux -- //
    // const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);



    //=============================================
    // ref
    //=============================================

    //const refForm = useRef(null);

    /*
    const rowRefs = [];
    const setRowRef = (ref) => {

        if(ref && ref !== null && ref !== undefined)
        {
            //console.log("ref::::"+ref.id);
            var found;
            found = false;
            for(var i=0; i < rowRefs.length; i++)
            {
                if(rowRefs[i].id === ref.id) found = true;
            }
            if(!found) rowRefs.push(ref);
            console.log(rowRefs.length);
            console.log(rowRefs[0]);

        }

    };
    */



    //=============================================
    // state/effect - initial
    //=============================================

    const [isLoaded, setIsLoaded] = useState(false);
    const [styling, setStyling] = useState({

        backgroundColor:"#141820"

    });

    useEffect(() => {

        if(!isLoaded)
        {
            setIsLoaded(true);
        
        }

        if(props.panelStatus)
        {
            if(props.panelStatus.call === 'off')
            {
                setStyling({

                    backgroundColor:"transparent"
                });

            }else{

                setStyling({

                    backgroundColor:"#141820"
                });

            }

        }


    },
    [
        props,
        //location,
        zoneObj,
        isLoaded

    ])






    //const portalTarget = usePortal("scrollbox");
    //const portalTarget = usePortal("side2");


    // return createPortal(<div>
    return (<div>



        <Panel
        backgroundColor={styling.backgroundColor}
        //width={panelSize.width}
        //height={panelSize.height}
        width={"100%"}
        height={"auto"}
        >

            <Loader>

                <div 
                id="loader"
                className="med-loader-circle" 
                style={{

                    borderTop          : "0.7em solid rgba(250,250,250,0.2)",
                    borderRight        : "0.7em solid rgba(250,250,250,0.2)",
                    borderBottom       : "0.7em solid rgba(250,250,250,0.2)",
                    borderLeft         : "0.7em solid #FFF"

                }}
                >
                </div>


            </Loader>


        </Panel>



    </div>);
    // </div>, portalTarget);



}

export default Index;


