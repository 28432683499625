
export const Healthcare = [

    //-----------------------------------
    // Graphic and Ledger
    //-----------------------------------

    {

        "print"		    :'bar',
        "name"		    :'Total',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"20px"
        },
        "required"	    :"",
        "default"	    :""


    },

    {

        "print"		    :'report_box',
        "name"		    :'Report Box',
        "value"		    :[

            {

                boxCall:'graphic',
                boxWidth:"30%",
                boxHeight:150,

                svgCall:"healthcare",
                svgWidth:150,
                svgHeight:150,

            },

            {
                boxCall:'ledger',
                boxWidth:"70%",
                boxHeight:150,
                value:[

                    {
                        name:"Total Yearly",
                        value:"$1500.00"
                    },

                    {
                        name:"Total Monthly",
                        value:"$1500.00"
                    }

                ]

            }


        ],
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"40px"
        },
        "required"	    :"",
        "default"	    :""


    },










    //-----------------------------------
    // Ledger
    //-----------------------------------

    {

        "print"		    :'bar',
        "name"		    :"Owner's",
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'ledger',
        "name"		    :"Ledger",
        "value"		    :[


            {
                name:"Owner 1",
                value:"$500.00"
            },
            {
                name:"Owner 2",
                value:"$500.00"
            },
            {
                name:"Total",
                value:"$1000.00"
            }


        ],
        "required"	    :"",
        "default"	    :""


    },

    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"50px"
        },
        "required"	    :"",
        "default"	    :""


    },





    //-----------------------------------
    // Ledger
    //-----------------------------------

    {

        "print"		    :'bar',
        "name"		    :"Employee's",
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'ledger',
        "name"		    :"Ledger",
        "value"		    :[


            {
                name:"Employee 1",
                value:"$250.00"
            },
            {
                name:"Employee 2",
                value:"$250.00"
            },
            {
                name:"Total",
                value:"$500.00"
            }

        ],
        "required"	    :"",
        "default"	    :""


    },

    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"50px"
        },
        "required"	    :"",
        "default"	    :""


    },




];

















