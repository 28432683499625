export const HEALTHCARE = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 500 500";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};

	let iconColor = props.iconColor || "rgb(0,0,0)";


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>


			<path 
				fill={iconColor}
				d="M250,72.571c97.724,0,177.429,79.695,177.429,177.436 c0,97.715-79.705,177.422-177.429,177.422c-97.719,0-177.429-79.707-177.429-177.422C72.571,152.267,152.281,72.571,250,72.571 L250,72.571z M205.387,126.282v79.098h-79.099v89.309h79.099v79.025h89.292v-79.025h79.023V205.38h-79.023v-79.098H205.387 L205.387,126.282z"
			/>


		</svg>
		</div>

	);//return

	



}