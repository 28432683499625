
import styled from "styled-components";


/*


*/




const DecorBox = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "lightyellow",//#fcfcfc
    border:             props.border || "0",
    borderRadius:       props.borderRadius || "0",

    width:              props.width || "96%", 
    height:             props.height || "auto",

    margin:            props.margin || "20px 20px 20px 20px",
    padding:           props.padding || "0 2% 0 2%",


}))`

    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    border-radius:${props => props.borderRadius};


    min-height:50px;

    width   :${props => props.width};
    height  :${props => props.height};
    margin  :${props => props.margin};
    padding  :${props => props.padding};


    flex-grow:1;

    position:relative;

    @media screen and (max-width: 800px) {

        width:100%;
        flex-grow:0;
        margin:20px 0 20px 0;

    }


`;



    const DecorText = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "lightgray",
        border:             props.border || "0",
        borderRadius:       props.borderRadius || "7px",

        width:              props.width || "100%",

        float:              props.float || "left",
        margin:             props.margin || "0 0 0 0",
        padding:            props.padding || "0 0 0 0",



        fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
        fontSize:           props.fontSize || "57px",
        fontWeight:         props.fontWeight || "bold",
        fontColor:          props.fontColor || "black",
        textAlign:          props.textAlign || "center",
        textDecoration:     props.textDecoration || "none",


        smallFontSize:      props.smallFontSize || "47px",


    }))`

        background-color:${props => props.backgroundColor};
        border:${props => props.border};
        border-radius:${props => props.borderRadius};

        width:${props => props.width};
        height:auto;
        margin:${props => props.margin};
        padding:${props => props.padding};

        position:relative;
        float:${props => props.float};

        font-family: ${props => props.fontFamily};
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        text-align: ${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.fontColor};
        line-height: 1.3;


        @media (max-width: 575px) {


            font-size:${props => props.smallFontSize};
    
        }



    `;



    const DecorStripe = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "blue",//#fcfcfc
        border:             props.border || "#00ff00",
        borderRadius:       props.borderRadius || "10px",
    
        width:              props.width || "100%", 
        height:             props.height || "10px",
    
        margin:            props.margin || "20px 0 20px 0",
        padding:           props.padding || "0 0 0 0",
    
    
    }))`
    
        background-color:${props => props.backgroundColor};
        border:${props => props.border};
        border-radius:${props => props.borderRadius};
    
        min-height:2px;
    
        width   :${props => props.width};
        height  :${props => props.height};
        margin  :${props => props.margin};
        padding  :${props => props.padding};
    
        position:relative;
        float:left;

    `;
    
    
    














export {

    DecorBox,
    DecorText,
    DecorStripe

}
