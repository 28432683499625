
// import { 
//     useSelector, 
//     //useDispatch 
// } 
// from 'react-redux';
// //import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';



import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    //useHistory,   
    //useParams,

} from "react-router-dom";



import { VPreportList } from 'app-components/Prime/VPdata/reports';

import { LibArray } from "library";


const SceneInfo = (props) => {


    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();

 
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);



    //-----------------------------------------
    //-----------------------------------------

    var pathArr = pathname.split("/");
    var pathCount = pathArr.length;

    var currIndex = parseInt(pathCount)-1;
    var categoryName = pathArr[currIndex];

    // var categoryIndex = parseInt(pathCount)-2;
    // var nextIndex = categoryIndex + 1;

    var categoryCount = currIndex;



    // console.log(""
    //     +"categoryName: "+categoryName
    //     +"\ncategoryIndex: "+categoryIndex
    //     +"\ncategoryCount: "+categoryCount
    //     +"\nnextIndex: "+nextIndex
    // );







    var R = [];
    // var index;

    var catalogItems;

    var keyname;
    //var kn;
    var item;
    var array;
    //var value;
    var group, groupName;
    // var newValue;

    // var a,b,c,d;
    // var total;
    // var grand_total;


    // var chartSections;
    // var object;
    // var percent;

    // var pgraph;


    // var obj;


   




    // // -- redux --//
    // //const dispatch = useDispatch();
    // const zoneObj = useSelector(state => state.zone);
    // //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));


    // if(!zoneObj['reports'] || zoneObj['reports'] === undefined)
    // {

    //     var reports = JSON.parse(JSON.stringify(reports));

    //     // dispatch(ModifyZoneStore({

    //     //     type:"UPDATE_ZONE",//UPDATE_ZONE or DELETE_ZONE
    //     //     item:{
    //     //         key     :"reports",
    //     //         value   :reports
    //     //     }

    //     // })); 

    // }






    //#############################################################
    //#############################################################

    //--------------------------
    // Catalog - Top
    //--------------------------


    R.push({

        call:'top',
        name:categoryName,
        controls:[

            // {

            //     name:"Create Template",
            //     // form:{

            //     //     submitCall:'calculate',
            //     //     postTo:apiDomain+apiCall,
            //     //     data:newTemplateExpenseForm,
            //     //     flyr:{

            //     //         hashbang:"#!new",
            //     //         type:"form",
            //     //         styl:{
            //     //             maxWidth:500,
            //     //             fieldType:'composed'   //multi,field,labelfield
            //     //         }

            //     //     }   

            //     // }

            // }

        ]

    });



    //#############################################################
    //#############################################################

    //--------------------------
    // Catalog - Bar
    //--------------------------

    R.push({

        call:'bar',
        items: [

            // {

            //     name:"Name"

            // },

            // {

            //     name:"Actions"

            // }

        ]
        
    });







    //#############################################################
    //#############################################################

    //--------------------------
    // Catalog - Template Calcs
    //--------------------------

    catalogItems = [];


    // if(VPreportList && VPreportList !== undefined)
    // {

    //     array = [];


    //     //var count = 0;
    //     for(item of VPreportList)
    //     {
    //         //console.log("count---"+count);


    //         //if(count === nextIndex)
    //         //{
    //             if(item.category.match(/,/gi))
    //             {
    //                 value = item.category.split(",")[currIndex];
    //             }else{
    //                 value = item.category;
    //             }

    //             if(!LibArray.InArray(value,array))
    //             {
    //                 array.push(value);

    //             }

    //         //}//if count


    //         //count++;

    //     }



    //     array.sort();
    //     for(value of array)
    //     {
    //         catalogItems.push({

    //             category:value

    //         });
    //     }

    // }


    var iconColor, darklite;

    darklite = "dark";
    
    iconColor = "#7e77ad";//purple
    iconColor = "#4cc1bb";//teal
    iconColor = "#454d5f"; // "#c7c9cf"; //"#a2a6af";

    //grays
    iconColor = "#6a707e"; // #7c828f #8f949f 
    //iconColor = "#7c828f";
    //iconColor = "#8f949f";



    if(VPreportList && VPreportList !== undefined)
    {

        array = [];
        for(keyname in VPreportList)
        {

            if(categoryCount > 0)
            {
                groupName = categoryName.replace(/ /gi,"_");
                //groupName = groupName.toLowerCase();

                if(keyname === groupName)
                {
                    group = VPreportList[groupName];
                    for(item of group)
                    {
                        if(!LibArray.InArray(item.name,array))
                        {
                            array.push(item.name);
                        }
                    }

                }


            }else{

                group = VPreportList['main'];
                for(item of group)
                {


                    if(!LibArray.InArray(item.name,array))
                    {
                        // array.push({
                        //     name:item.name,
                        //     iconColor:iconColor
                        // });

                        array.push(item.name);
                        catalogItems.push({
                
                            heading:item.name,
                            bullet:false,
                            stripe:true,
                            //pgraph:'View "'+item.name+'" report(s)',
                            pgraph:item.value,
                            image:false,
                            button:false,
                            data:{
                
                                id:item.name,
                                name:item.name,
                                graphic:{
                                    color:iconColor,
                                    darklite:darklite
                                }
                
                            }
                        
                        });


                    }




                }
            }

     


        }

        //array.sort();
        // for(value of array)
        // {
        //     catalogItems.push({

        //         category:value

        //     });
        // }

        //catalogItems = catalogItems.concat(array);

    }



    R.push({

        call:"items",
        items:catalogItems

    });

    return R;


}



export default SceneInfo;
