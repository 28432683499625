
//import { Settings } from 'app-components/Prime/Data.js';

import { 

    //VPformTemplateOwnr,
    //VPformTemplateEmpl,
    //VPformTemplateExpense,

    //VPcompanyInfo, 
    VPexpenses,
    VPincome,   
    VPoverhead, 

    VPownrsIndirect,  
    //VPownrs,
    VPownr,

    //VPemplsYearly, 
    //VPemplsMonthly, 
    //VPemplsDirect,
    VPemplsIndirect,
    VPempl


} from 'app-components/Prime/VPdata/form.js';

import { VPreportDefaults } from 'app-components/Prime/VPdata/reports';


import { 

    ChartColorsA, 
    ChartColorsB, 
    ChartColorsC 

} from 'utils/components/Charts/ChartColors';


import { LibBase, LibNumber } from "library";


//import ApiResources from 'utils/components/Pages/ApiResources';




const Index = (props) => {



    //-------------------------------------------

    // var apiDomain;
    // var hostname;
    // //var hostname = window.location.hostname;//returns the domain name of the web host
    // var protocol = window.location.protocol;//returns the web protocol used (http: or https:)

    // if(window.location.hostname.match(/192.168/gi))
    // {
    //     hostname = ApiResources.network['3001'].vpc;
    //     apiDomain = protocol + "//" + hostname + "/";
    // }
    // else
    // if(window.location.hostname.match(/localhost/gi))
    // {
    //     hostname = ApiResources.local['3001'].vpc;
    //     apiDomain = protocol + "//" + hostname + "/";
    // }else{
    //     hostname = ApiResources.aws['x'].vpc;
    //     apiDomain = "https://" + hostname + "/";
    // }//=

    // //-------------------------------------------

    // //console.log("API DOMAIN: "+apiDomain);


    // //const apiCall = 'alter-calc-test';
    // const apiCall = 'alter-calc-dash';




    const zoneObj = props.zoneObj;




    var overhead_percent;
    var owners = {};   
    var employees = {};
    //var expenses = [];

    overhead_percent = 0.0 + "%";


    //var R = [];
    var index;

    //var search;
    var keyname;
    var kn;
    var item;
    var name;
    var value;
    var newValue;

    var a,b,c,d;
    var total;
    var grand_total;


    var chartSections;
    var array;
    var object;
    var price;
    var percent;
    var count;
    var bullet;

    var pgraph;


    var obj;

    var chartColors = ChartColorsA;


    //clear VPoverhead
    for(keyname in VPoverhead)
    {
        if(keyname.match(/expense-/gi))
        {
            delete VPoverhead[keyname];
        }
    }

    var cards_overhead = LibBase.CloneIt(VPoverhead);
    var cards_owners = LibBase.CloneIt(VPownrsIndirect);
    var cards_employees = LibBase.CloneIt(VPemplsIndirect);


    //var templateExpenseForm;
    //var newTemplateExpenseForm;
    


    //var templateEmplForm;
    //var newTemplateEmplForm;
    
    //var template_empl;



    var piePerc;
    var pieSlice;
    var pieChart = {

        items: [ 

            {
                name:"Business",
                value:14
            },
            {
                name:"Facility",
                value:20
            }, 
            {
                name:"Equip. Software",
                value:30
            },      
            {
                name:"Vehicles",
                value:12
            },

            {
                name:"Owners",
                value:5
            },
            {
                name:"Employees",
                value:5
            },


        ],

        colors: [

            "#fde23e",
            "#f16e23", 
            "#57d9ff",
            "#937e88",
            "#20b2aa",
            "#ccff00"

        ],


        hole:{

            percent         :"0.0",
            name            :"Overhead",
            color           :"#181b21",
            outlineColor    :"#181b21",
            textColor       :"white"
        }



    };






    var isOkay;
    isOkay = false;
    if(zoneObj.calc_dash 
    && zoneObj.calc_dash !== undefined
    )
    {
        if(Object.keys(zoneObj.calc_dash.form_items).length > 0
        && Object.keys(zoneObj.calc_dash.result_items).length > 0
        )
        {
            isOkay = true;
        }

    }
    if(isOkay)
    {


        grand_total = zoneObj.calc_dash.result_items['totals'].total;
        grand_total = parseFloat(grand_total);


        //console.log("FORM ITEMS: "+JSON.stringify(zoneObj.calc_dash.form_items));

        index=0;
        for(keyname in zoneObj.calc_dash.form_items)
        {

            // -- owners -- //

            if(keyname.match(/ownr/gi))
            {

                //idl_employees[keyname] = zoneObj.calc_dash.form_items[keyname];

                
                //order card item based on Prime VPdata
                //item = zoneObj.calc_dash.form_items[keyname];
                item = LibBase.CloneIt(zoneObj.calc_dash.form_items[keyname]);//Deep Clone
          
          
                //value = VPowner.value;
                value = LibBase.CloneIt(VPownr.value);//Deep Clone
                newValue = {};
                for(kn in value)
                {
                    if(kn === 'ownr_call') 
                    {
                    }else{
                        newValue[kn] = item.value[kn];       
                    }
                    
                }
                owners[keyname] = {
                    name:item.name,
                    value:newValue
                };
                

            }




            // -- employees -- //

            else
            if(keyname.match(/empl/gi))
            {

                //idl_employees[keyname] = zoneObj.calc_dash.form_items[keyname];

                
                //order card item based on Prime VPdata
                //item = zoneObj.calc_dash.form_items[keyname];
                item = LibBase.CloneIt(zoneObj.calc_dash.form_items[keyname]);//Deep Clone
          
                //value = VPemployee.value;
                value = LibBase.CloneIt(VPempl.value);//Deep Clone
                newValue = {};
                for(kn in value)
                {
                    newValue[kn] = item.value[kn];
                }
                employees[keyname] = {
                    name:item.name,
                    value:newValue
                };
                



            }



            // -- overhead -- //

            else
            {
                
                //console.log("KEYNAME: "+keyname);

                item = LibBase.CloneIt(zoneObj.calc_dash.form_items[keyname]);//Deep Clone
                //console.log(JSON.stringify(item,null,2));


                if(keyname.match(/expense-/gi))
                {

                    // TM: order inputs here



                    // -- VPoverhead -- //

                    if(item.order)
                    {
                        newValue = {};
                        for(obj of item.order)
                        {
                            //console.log("order:  "+obj);
                            for(kn in item.value)
                            {
                                //console.log("kn:  "+kn);
                                if(obj === kn)
                                {
                                    //console.log("match: "+kn);
                                    newValue[kn] = item.value[kn];
                                }
                            }

                        }
                        //console.log(JSON.stringify(newValue,null,2));

                        VPoverhead[keyname] = {
                            name:item.name,
                            value:newValue,
                            order:item.order
                        };


                    }else{

                        VPoverhead[keyname] = {
                            name:item.name,
                            value:item.value
            
                        };

                    }






                    // -- card_overhead -- //

                    value = LibBase.CloneIt(item.value);//Deep Clone

                    if(item.order)
                    {
                        newValue = {};
                        for(obj of item.order)
                        {
                            //console.log("order:  "+obj);
                            for(kn in item.value)
                            {
                                //console.log("kn:  "+kn);
                                if(obj === kn)
                                {
                                    //console.log("match: "+kn);
                                    newValue[kn] = item.value[kn];
                                }
                            }

                        }
                        //console.log(JSON.stringify(newValue,null,2));


                        total = 0;
                        for(kn in newValue)
                        {
                            obj = newValue[kn];
                            total = parseFloat(total) + parseFloat(obj.value);
      
                        }
                        total = parseFloat(total);
                        total = total.toFixed(2);
                        // console.log("TOTALLLLLLLLLLLLL "+total);
                        newValue["total"] = total;
                        newValue["total_percent"] = 0;
    
                        cards_overhead[keyname] = {
                            name:item.name,
                            value:newValue,
                            order:item.order
                        };
    

                    }else{

                        total = 0;
                        for(kn in value)
                        {
                            obj = value[kn];
                            total = parseFloat(total) + parseFloat(obj.value);
      
                        }
                        total = parseFloat(total);
                        total = total.toFixed(2);
                        // console.log("TOTALLLLLLLLLLLLL "+total);
                        value["total"] = total;
                        value["total_percent"] = 0;
    
                        cards_overhead[keyname] = {
                            name:item.name,
                            value:value
                        };
    
                    }





                }else{

                    //value = VPoverhead[keyname].value;
                    value = LibBase.CloneIt(VPoverhead[keyname].value);//Deep Clone
                    newValue = {};
                    for(kn in value)
                    {
                        newValue[kn] = item.value[kn];
                    }

                    VPoverhead[keyname] = {
                        name:item.name,
                        value:newValue
                    };

                    cards_overhead[keyname] = {

                        name:item.name,
                        value:newValue

                    };                

                }


        
                // -- set totals for cards -- //

                if(keyname.match(/expense-/gi))
                {
                    total = 0;
                    newValue = 0;
                    for(kn in item.value)
                    {
                        //console.log(value[kn]);
                        if(item.value[kn] !== undefined)
                        {
                            newValue = item.value[kn].value;
                            if(!isNaN(newValue)) parseFloat(newValue);
                            else newValue = 0;
                            total = parseFloat(total) + parseFloat(newValue); 
                        }
    
                    }
                    total = parseFloat(total);        
                    total = total.toFixed(2);
                

                    piePerc = ( parseFloat(total)/parseFloat(grand_total) ) * 100;
                    if(isNaN(piePerc) || grand_total === 0) piePerc = 0;
                    piePerc = piePerc.toFixed(1);
                    piePerc = parseFloat(piePerc);



                    cards_overhead[keyname].value['total_percent'] = {
    
                        name: "Total Monthly (Overhead %)",
                        default: 0,
                        print: "percent",
                        value: piePerc,
                        required: true
    
                    };
                    cards_overhead[keyname].value['total'] = {
    
                        name: "Total Monthly (Overhead $)",
                        default: 0,
                        print: "price",
                        value: total,
                        required: true
    
                    };


                    // -- set pieChart data -- //

                    for(pieSlice of pieChart.items)
                    {
                        if(pieSlice.value !== piePerc)
                        {
                            pieChart.items[index].name = item.name;      
                            pieChart.items[index].value = piePerc;
                            break;
            
                        }
                       
                    }
                    index++;


                }//==




            }

        }// -- for





        //VPexpenses.value.monthly_expenses.value = grand_total;
        VPexpenses.value.monthly_expenses.value = grand_total;
        VPincome.value.projected_monthly_sales.value = zoneObj.calc_dash.form_items['settings'].value.projected_monthly_sales.value;


        overhead_percent = zoneObj.calc_dash.result_items['overhead'].overhead;
        overhead_percent = parseFloat(overhead_percent); 

        pieChart.hole.percent = overhead_percent.toFixed(1);

        overhead_percent = overhead_percent.toFixed(1) + "%";




        //console.log("IDL EMPLOYEES: "+JSON.stringify(idl_employees,null,2));

    
        /*
        for(keyname in zoneObj.calc_dash.result_items)
        {

            console.log("KEYNAME: "+keyname);

            item = zoneObj.calc_dash.result_items[keyname];
            console.log(JSON.stringify(item,null,2));

        
        }// -- for
        */





        //------------------------------------------
        // -- owners - totals - cards 
        //------------------------------------------

        //var total_ownr_salary_pretax_yearly_indirect
        var total_ownr_salary_pretax_monthly_indirect;
        var total_ownr_health_insurance_monthly;
        var total_ownr_ira_monthly_indirect;
        var total_ownr_taxes_monthly_indirect;  

        total_ownr_salary_pretax_monthly_indirect = zoneObj.calc_dash.result_items['totals'].total_ownr_salary_pretax_monthly_indirect;
        total_ownr_health_insurance_monthly = zoneObj.calc_dash.result_items['totals'].total_ownr_health_insurance_monthly;
        total_ownr_ira_monthly_indirect = zoneObj.calc_dash.result_items['totals'].total_ownr_ira_monthly_indirect;
        total_ownr_taxes_monthly_indirect = zoneObj.calc_dash.result_items['totals'].total_ownr_taxes_monthly_indirect;  

        //total_ownr_salary_pretax_yearly_indirect = parseFloat(total_ownr_salary_pretax_monthly_indirect) * 12;


        //if(isNaN(total_ownr_salary_pretax_yearly_indirect)) total_ownr_salary_pretax_yearly_indirect = 0;
        if(isNaN(total_ownr_salary_pretax_monthly_indirect)) total_ownr_salary_pretax_monthly_indirect = 0;
        if(isNaN(total_ownr_health_insurance_monthly)) total_ownr_health_insurance_monthly = 0;
        if(isNaN(total_ownr_ira_monthly_indirect)) total_ownr_ira_monthly_indirect = 0;
        if(isNaN(total_ownr_taxes_monthly_indirect)) total_ownr_taxes_monthly_indirect = 0;


        //cards_owners.value.ownr_salary_pretax_yearly_indirect.value = total_ownr_salary_pretax_yearly_indirect;
        cards_owners.value.ownr_salary_pretax_monthly_indirect.value = total_ownr_salary_pretax_monthly_indirect;
        cards_owners.value.ownr_health_insurance_monthly.value = total_ownr_health_insurance_monthly;
        cards_owners.value.ownr_ira_monthly_indirect.value = total_ownr_ira_monthly_indirect;
        cards_owners.value.ownr_taxes_monthly_indirect.value = total_ownr_taxes_monthly_indirect;



        // -- card total - indirect labor -- //

        total = 0;
        if(total_ownr_salary_pretax_monthly_indirect !== undefined
        && total_ownr_health_insurance_monthly !== undefined 
        && total_ownr_ira_monthly_indirect !== undefined
        && total_ownr_taxes_monthly_indirect !== undefined
        )
        {
            a = total_ownr_salary_pretax_monthly_indirect;
            b = total_ownr_health_insurance_monthly;
            c = total_ownr_ira_monthly_indirect;
            d = total_ownr_taxes_monthly_indirect;

            total = parseFloat(a) + parseFloat(b) + parseFloat(c) + parseFloat(d);

        }

        piePerc = ( parseFloat(total)/parseFloat(grand_total) ) * 100;
        if(isNaN(piePerc)) piePerc = 0;
        piePerc = piePerc.toFixed(1);
        piePerc = parseFloat(piePerc);

        cards_owners.value['total_percent'] = {
    
            name: "Total Monthly (Overhead %)",
            default: 0,
            print: "percent",
            value: piePerc,
            required: true

        };
        cards_owners.value['total'] = {

            name: "Total Monthly (Overhead $)",
            default: 0,
            print: "price",
            value: total,
            required: true

        };

        // -- set pieChart data -- //

        pieSlice = pieChart.items[pieChart.items.length-2];
        if(pieSlice.value !== piePerc)
        {
            pieSlice.name = "Owners";      
            pieSlice.value = piePerc;

        }
        








        //------------------------------------------
        // -- employees - totals - cards 
        //------------------------------------------

        //var total_empl_indirect_salary_yearly;
        var total_empl_indirect_salary_monthly;
        var total_empl_indirect_tax_monthly;
        var total_empl_indirect_ira_monthly;
        var total_empl_health_insurance_monthly;

        total_empl_indirect_salary_monthly = zoneObj.calc_dash.result_items['totals'].total_empl_indirect_salary_monthly;
        total_empl_indirect_tax_monthly = zoneObj.calc_dash.result_items['totals'].total_empl_indirect_tax_monthly;
        total_empl_indirect_ira_monthly = zoneObj.calc_dash.result_items['totals'].total_empl_indirect_ira_monthly;
        total_empl_health_insurance_monthly = zoneObj.calc_dash.result_items['totals'].total_empl_health_insurance_monthly;
   
        //total_empl_indirect_salary_yearly = parseFloat(total_empl_indirect_salary_monthly) * 12;

        //if(isNaN(total_empl_indirect_salary_yearly)) total_empl_indirect_salary_yearly = 0;
        if(isNaN(total_empl_indirect_salary_monthly)) total_empl_indirect_salary_monthly = 0;
        if(isNaN(total_empl_indirect_tax_monthly)) total_empl_indirect_tax_monthly = 0;
        if(isNaN(total_empl_indirect_ira_monthly)) total_empl_indirect_ira_monthly = 0;
        if(isNaN(total_empl_health_insurance_monthly)) total_empl_health_insurance_monthly = 0;
     
        //cards_employees.value.empl_indirect_salary_yearly.value = total_empl_indirect_salary_yearly;
        cards_employees.value.empl_indirect_salary_monthly.value = total_empl_indirect_salary_monthly;
        cards_employees.value.empl_indirect_tax_monthly.value = total_empl_indirect_tax_monthly;      
        cards_employees.value.empl_indirect_ira_monthly.value = total_empl_indirect_ira_monthly;
        cards_employees.value.empl_health_insurance_monthly.value = total_empl_health_insurance_monthly;







        // -- card total - indirect labor -- //

        total = 0;
        if(total_empl_indirect_salary_monthly !== undefined
        && total_empl_indirect_ira_monthly !== undefined
        && total_empl_indirect_tax_monthly !== undefined        
        && total_empl_health_insurance_monthly !== undefined 
        )
        {
            a = total_empl_indirect_salary_monthly;
            b = total_empl_indirect_ira_monthly;
            c = total_empl_indirect_tax_monthly;        
            d = total_empl_health_insurance_monthly;

            total = parseFloat(a) + parseFloat(b) + parseFloat(c) + parseFloat(d);

        }

        piePerc = ( parseFloat(total)/parseFloat(grand_total) ) * 100;
        if(isNaN(piePerc)) piePerc = 0;
        piePerc = piePerc.toFixed(1);
        piePerc = parseFloat(piePerc);

        cards_employees.value['total_percent'] = {
    
            name: "Total Monthly (Overhead %)",
            default: 0,
            print: "percent",
            value: piePerc,
            required: true

        };
        cards_employees.value['total'] = {

            name: "Total Monthly (Overhead $)",
            default: 0,
            print: "price",
            value: total,
            required: true

        };

        // -- set pieChart data -- //

        pieSlice = pieChart.items[pieChart.items.length-1];
        if(pieSlice.value !== piePerc)
        {
            pieSlice.name = "Employees";      
            pieSlice.value = piePerc;

        }
        


    }// -- if

    //console.log("pieChart: "+JSON.stringify(pieChart,null,2));

    //_overhead = VPoverhead;
    //console.log("_overhead B: "+JSON.stringify(_overhead,null,2));


    //console.log("VPoverhead: "+JSON.stringify(VPoverhead,null,2));
    //console.log("cards_overhead: "+JSON.stringify(cards_overhead,null,2));







    //#############################################################
    //#############################################################

    //  {
    //     "name": "Facility",
    //     "value": {
    //       "expense_ytilicaf_rent_mortgage": {
    //         "name": "Rent / Mortgage",
    //         "default": "",
    //         "print": "price",
    //         "value": "5500.00",
    //         "required": true
    //       },
    //       "expense_ytilicaf_utilities": {
    //         "name": "Utilities",
    //         "default": "",
    //         "print": "price",
    //         "value": "1100.00",
    //         "required": true
    //       },
    //       "expense_ytilicaf_phone_internet": {
    //         "name": "Phone / Internet",
    //         "default": "",
    //         "print": "price",
    //         "value": "350.00",
    //         "required": true
    //       },
    //       "expense_ytilicaf_misc": {
    //         "name": "Misc",
    //         "default": "",
    //         "print": "price",
    //         "value": "20.00",
    //         "required": true
    //       },
    //       "total": {
    //         "name": "Total Monthly (Overhead $)",
    //         "default": 0,
    //         "print": "price",
    //         "value": "6970.00",
    //         "required": true
    //       },
    //       "total_percent": {
    //         "name": "Total Monthly (Overhead %)",
    //         "default": 0,
    //         "print": "percent",
    //         "value": 45.6,
    //         "required": true
    //       }
    //     },
    //     "order": [
    //       "expense_ytilicaf_rent_mortgage",
    //       "expense_ytilicaf_utilities",
    //       "expense_ytilicaf_phone_internet",
    //       "expense_ytilicaf_misc"
    //     ]
    //   },
    //   "styl": {
    //     "maxWidth": 550,
    //     "fieldType": "composed"
    //   },
    //   "flyrH": "837px"
    // }



    //var hasMoreThanOne = false;
    //keyname = 'expense'; 
    kn = 'expense-1';
    var knArr = [];
    if(zoneObj.calc_dash)
    {
        for(keyname in cards_overhead)
        {
            if(keyname.match(/expense-/gi))
            {
                if(cards_overhead[keyname])
                {
                    if(props.find === cards_overhead[keyname].name)
                    {
                        knArr.push(keyname);
                        break;
                    }
                }
            }
        }
    }
    console.log("knArr: " + JSON.stringify(knArr));
    console.log("kn: "+knArr[0]);
    if(knArr.length > 1)
    {
        //hasMoreThanOne = true;
    }else{
        if(knArr.length > 0) kn = knArr[0];
    }


    var R = [];
    var reportItems = [];
    var data;

    chartSections = [];
    var bulletColors = {};
    var donut_items = [];


    if(cards_overhead[kn] !== undefined) 
    {
        console.log("The Expense ::::::: "+JSON.stringify(cards_overhead[kn],null,2));

        data = cards_overhead[kn];


        //#############################################################
        //#############################################################
        //build chartSections

        if(data && data !== undefined)
        { 
            pgraph = data.value;
    
            //console.log("PGRPAH: "+JSON.stringify(pgraph,null,2));
            if(pgraph['total'] && pgraph['total'] !== undefined)
            {

                total = 0;
                grand_total = pgraph['total'].value;
                if(!isNaN(grand_total)) grand_total = parseFloat(grand_total);

                for(kn in pgraph)
                {

                    object = pgraph[kn];

                    if(!isNaN(object.value)) value = parseFloat(object.value);
                    else value = object.value;

                    switch(kn)
                    {
                    case'total':
                    case'total_percent':
                    break;
                    default:

                        total = parseFloat(value);

                        percent = ( parseFloat(total)/parseFloat(grand_total) ) * 100;
                        percent = percent.toFixed(1);
                        percent = parseFloat(percent);

                        total = total.toFixed(2);


                        // -- set chart sections (cssBarChart) -- //
                        
                        chartSections.push({

                            name    :object.name,
                            value   :percent,
                            data: {
                                percent :percent,
                                price   :total
                            }


                        });      
                        

                    }

                }

            }


        }//==

        console.log("Chart Sections: "+JSON.stringify(chartSections,null,2));





        //#############################################################
        //#############################################################
        //build bulletColors

        var display_grand_total = "$0.00"

        if(data && data !== undefined)
        { 
            pgraph = data.value;
    
            //console.log("PGRPAH: "+JSON.stringify(pgraph,null,2));
            if(pgraph['total'] && pgraph['total'] !== undefined)
            {

                total = 0;
                grand_total = pgraph['total'].value;
                if(!isNaN(grand_total)) grand_total = parseFloat(grand_total);

                display_grand_total = parseFloat(grand_total).toFixed(2);
                display_grand_total = "$" + LibNumber.PrintMoney(display_grand_total);


                count = 0;

                for(kn in pgraph)
                {

                    object = pgraph[kn];

                    if(!isNaN(object.value)) value = parseFloat(object.value);
                    else value = object.value;

                    switch(kn)
                    {
                    case'total':
                    case'total_percent':
                    break;
                    default:

                        total = parseFloat(value);

                        percent = ( parseFloat(total)/parseFloat(grand_total) ) * 100;
                        percent = percent.toFixed(1);
                        percent = parseFloat(percent);

                        total = total.toFixed(2);

                        // -- donut items -- //
                        donut_items.push({
                            "name": object.name,
                            "value": total,
                            "percent": percent,
                            "color": chartColors[count]
                        });

                        bulletColors[kn] = chartColors[count];

                        count++;

                    }

                }

            }


        }//==

        console.log("donut_items: "+JSON.stringify(donut_items,null,2));
        console.log("bulletColors: "+JSON.stringify(bulletColors,null,2));







        //#############################################################
        //#############################################################
        //defaults

        keyname = 'ReportExpense'; 


        if(VPreportDefaults && VPreportDefaults !== undefined)
        {
            console.log("Reports Build - Expense: "+JSON.stringify(VPreportDefaults[keyname],null,2));

            for(item of VPreportDefaults[keyname])
            {

                switch(item.print)
                {
                case'singleBarChart':


                    //item.value[0].data = array;

                    array = [];
                    for(object of chartSections)
                    {
                        percent = object.data.percent;
                        percent = parseFloat(percent);
                        percent = percent.toFixed(1);
                        
                        price = object.data.price;
                        price = LibNumber.PrintMoney(price);
                        if(!price.match(/\$/gi)) price = "$"+price;

                        name = object.name;
            
                        array.push({

                            name:       name,
                            value:      price,
                            percent:    percent

                        });

                    }

                    item.value[0].data = array;



                break;
                case'svg_donut_chart':



                    count = 0;
                    array = [];
                    for(object of chartSections)
                    {
                        percent = object.data.percent;
                        percent = parseFloat(percent);
                        percent = percent.toFixed(1);
                        percent = parseFloat(percent);
                        
                        price = object.data.price;
                        price = LibNumber.PrintMoney(price);
                        if(!price.match(/\$/gi)) price = "$"+price;

                        name = object.name;
                
                        array.push({

                            name:       name,
                            value:      price,
                            percent:    percent

                        });

                        count++;

                    }

                    item.value.items = array;


                
                break;
                case'report_box':


            
                    //item.value[0].data = array;


                    //----------------------
                    //box 1
                    //----------------------


                    switch(item.value[0].boxCall)
                    {
                    case'svg_donut_chart':


                        count = 0;
                        array = [];
                        for(object of chartSections)
                        {
                            percent = object.data.percent;
                            percent = parseFloat(percent);
                            percent = percent.toFixed(1);
                            percent = parseFloat(percent);
                            
                            price = object.data.price;
                            price = LibNumber.PrintMoney(price);
                            if(!price.match(/\$/gi)) price = "$"+price;

                            name = object.name;
                    
                            array.push({

                                name:       name,
                                value:      price,
                                percent:    percent

                            });

                            count++;

                        }

                        item.value[0].value.items = array;




                    break;
                    case'chart':


                        count = 0;
                        array = [];
                        for(object of chartSections)
                        {
                            percent = object.data.percent;
                            percent = parseFloat(percent);
                            percent = percent.toFixed(1);
                            percent = parseFloat(percent);
                            
                            price = object.data.price;
                            price = LibNumber.PrintMoney(price);
                            if(!price.match(/\$/gi)) price = "$"+price;

                            name = object.name;
                
                            array.push({

                                name:       name,
                                value:      percent

                            });

                            count++;

                        }

                        item.value[0].value.chart.data.items = array;

                    
                    break;
                    case'donutChart':



                        count = 0;
                        array = [];
                        for(object of chartSections)
                        {
                            percent = object.data.percent;
                            percent = parseFloat(percent);
                            percent = percent.toFixed(1);
                            
                            price = object.data.price;
                            price = LibNumber.PrintMoney(price);
                            if(!price.match(/\$/gi)) price = "$"+price;

                            name = object.name;
                
                            array.push({

                                name:       name,
                                value:      price,
                                percent:    percent,
                                color:      chartColors[count]

                            });

                            count++;

                        }

                        item.value[0].value.donutSections = array;


                    break;
                    default:
                    }




                    //-----------------------
                    // Box 2
                    //-----------------------


                    switch(item.value[1].boxCall)
                    {
                    case'txt':

                        array = [
        
                            {
                                className:"Medium",
                                txt:display_grand_total,
                                style:{
        
                                    //fontSize:"100px",
                                    textAlign:"center"
        
                                }
                            },
        
                            {
                                className:"Note",
                                txt:"Monthly Expense",
                                style:{
        
                                    //fontSize:"13px",
                                    textAlign:"center"
        
                                }
                            }
        
        
                        ];

                        item.value[1].value = array;
                        console.log(JSON.stringify(array));


                 
                    break;
                    default:
                    }





                break;
                case'ledger':




                    if(item.value[0].name.match(/total/gi))
                    {
                        total = 0;
                        for(object of chartSections)
                        {
                            price = object.data.price;
                            price = price.replace(/\$/gi,"");
                            price = parseFloat(price);
                            
                            total = total + price;

                        }
                        total = total.toFixed(2);
                        total = LibNumber.PrintMoney(total);
                        if(!total.match(/\$/gi)) total = "$"+total;

                        item.value[0].value = total;

                    }else{



                        if(item.colors && item.colors !== undefined)
                        {

                            //bullet ledger

                            switch(item.colors)
                            {
                            case'ChartColorsA':
            
                                bulletColors = ChartColorsA;
                            break;
                            case'ChartColorsB':
            
                                bulletColors = ChartColorsB;
                            break;
                            case'ChartColorsC':
            
                                bulletColors = ChartColorsC;
                            break;
                            default:
                            }
            

                        }

                        array = [];
                        count = 0;
                        for(object of chartSections)
                        {
                            percent = object.data.percent;
                            percent = parseFloat(percent);
                            percent = percent.toFixed(1);
                            
                            price = object.data.price;
                            price = LibNumber.PrintMoney(price);
                            if(!price.match(/\$/gi)) price = "$"+price;


                            name = object.name;
                            //name = "( "+percent+"% ) "+object.name;
                            value = price;
                            bullet = {

                                color:bulletColors[count]
                            }
                
                            array.push({

                                name:       name,
                                value:      value,
                                percent:    percent,
                                bullet:     bullet

                            });


                            count++;

                        }
                        item.value = array;


                    }


                break;
                default:
                }

                reportItems.push(item);

            }

        }



    }//==


    R.push({

        call:"report",
        items:reportItems

    });

    //console.log("reportItems: "+JSON.stringify(reportItems,null,2));

    return R;

}
export default Index;
































// keyname = 'expense'; 

// if(VPreportDefaults && VPreportDefaults !== undefined)
// {
//     console.log("Flyr Report Build - Expense: "+JSON.stringify(VPreportDefaults[keyname],null,2));

//     for(item of VPreportDefaults[keyname])
//     {

//         switch(item.print)
//         {
//         case'singleBarChart':

//             // {
//             //     "print": "singleBarChart",
//             //     "name": "The Chart",
//             //     "value": [
//             //       {
//             //         "chartType": "horizontal",
//             //         "chartLines": true,
//             //         "data": [
//             //           {
//             //             "name": "Direct Labor %",
//             //             "value": "2500.00",
//             //             "percent": 80
//             //           },
//             //           {
//             //             "name": "Indirect Labor %",
//             //             "value": "2500.00",
//             //             "percent": 20
//             //           },
//             //           {
//             //             "name": "Direct Labor %",
//             //             "value": "2500.00",
//             //             "percent": 80
//             //           },
//             //           {
//             //             "name": "Indirect Labor %",
//             //             "value": "2500.00",
//             //             "percent": 20
//             //           },
//             //           {
//             //             "name": "Direct Labor %",
//             //             "value": "2500.00",
//             //             "percent": 80
//             //           },
//             //           {
//             //             "name": "Indirect Labor %",
//             //             "value": "2500.00",
//             //             "percent": 20
//             //           }
//             //         ]
//             //       }
//             //     ],
//             //     "required": "",
//             //     "default": ""
//             //  },

        

//             //item.value[0].data = array;




//         break;
//         case'svg_donut_chart':



//             // "print"		    :'svg_donut_chart',
//             // "name"		    :'SVG Donut Chart',
//             // "value"		    :{
    
//             //     backgroundColor:"transparent",
//             //     width:"100%",
//             //     height:"auto", // set a fixed height to control overflow
//             //     ring:{
    
//             //         size:"8",
//             //         color:"#d2d3d4"
    
//             //     },
//             //     hole:{
//             //         size:0.5,
//             //         // color:"#191e28",
//             //         // outlineColor:"#191e28",
//             //         color:"#fff",
//             //         outlineColor:"#fff",
//             //         textColor:"#000",
//             //         textSize:"27px"
//             //     },
//             //     items: [ 
    
//             //         // {
//             //         //     name:"Classical Music",
//             //         //     value:50
//             //         // },
//             //         // {
//             //         //     name:"Hard Rock",
//             //         //     value:25
//             //         // },
//             //         // {
//             //         //     name:"Soft Rock",
//             //         //     value:25
//             //         // }
    
//             //         {
//             //             "name": "Rent / Mortgage ( 78.9% )",
//             //             "value": "$5,500.00",
//             //             "percent": "78.9"
//             //         },
//             //         {
//             //             "name": "Utilities ( 15.8% )",
//             //             "value": "$1,100.00",
//             //             "percent": "15.8"
//             //         },
//             //         {
//             //             "name": "Phone / Internet ( 5.0% )",
//             //             "value": "$350.00",
//             //             "percent": "5.0"
//             //         },
//             //         {
//             //             "name": "Misc ( 0.3% )",
//             //             "value": "$20.00",
//             //             "percent": "0.3"
//             //         }
    
    
    
//             //     ],
//             //     colors: [
        
    
        
//             //         "#caffca",
    
//             //         "#07a2e3",
//             //         "#20b2aa",
    
//             //         "#fde23e",
//             //         "#f16e23", 
//             //         "#57d9ff",
//             //         "#937e88",
//             //         "#20b2aa",
//             //         "#ccff00",
    
//             //         "lightblue",
//             //         "lightgreen",
//             //         "yellow",
//             //         "pink",
//             //         "lavender"
        
//             //     ]
    
    
    
//             // },





        
//         break;
//         case'report_box':


    
//             //item.value[0].data = array;

//             switch(item.value[0].boxCall)
//             {
//             case'svg_donut_chart':



//                 // {

//                 //     boxCall:'svg_donut_chart',
//                 //     boxWidth:"45%",
//                 //     boxHeight:225,
//                 //     value:{
    
    
//                 //         backgroundColor:"transparent",
//                 //         width:"100%",
//                 //         height:"225px", // match this to boxHeight
//                 //         ring:{
        
//                 //             size:"10",
//                 //             color:"#d2d3d4"
        
//                 //         },
//                 //         hole:{
//                 //             size:0.5,
//                 //             // color:"#191e28",
//                 //             // outlineColor:"#191e28",
//                 //             color:"#edeef1",
//                 //             outlineColor:"#edeef1",
//                 //             textColor:"#000",
//                 //             textSize:"27px"
//                 //         },
//                 //         items: [ 
    
    
//                 //             {
//                 //                 "name": "Rent / Mortgage ( 80% )",
//                 //                 "value": "$5,500.00",
//                 //                 "percent": "80"
//                 //             },
//                 //             {
//                 //                 "name": "Utilities ( 20% )",
//                 //                 "value": "$1,100.00",
//                 //                 "percent": "20"
//                 //             }
    
        
    
//                 //         ],
//                 //         colors: [
                
    
            
//                 //             "royalblue",
//                 //             "lightgreen",
//                 //             "lightseagreen",
//                 //             "#caffca",
            
//                 //             "#07a2e3",
//                 //             "#20b2aa",
            
//                 //             "#fde23e",
//                 //             "#f16e23", 
//                 //             "#57d9ff",
//                 //             "#937e88",
//                 //             "#20b2aa",
//                 //             "#ccff00",
            
//                 //             "lightblue",
//                 //             "lightgreen",
//                 //             "yellow",
//                 //             "pink",
//                 //             "lavender"
                
//                 //         ]
        
        
    
//                 //     }
            
            
    
//                 // },
    








//             break;
//             case'chart':


//                 //  {
//                 //     "boxCall": "donutChart",
//                 //     "boxWidth": "100%",
//                 //     "boxHeight": 275,
//                 //     "value":{
        
//                 //         backgroundColor:"transparent",
//                 //         border:"0",
//                 //         borderRadius:"0",
//                 //         width:"80%",
//                 //         margin:15+"px auto 0 auto",
//                 //         maxHeight:15 - 5,
//                 //         drawCheckerBoard: false,
//                 //         chart:{
//                 //             call:'doughnut',
//                 //             data: {
                    
//                 //                 items: [ 
                    
//                 //                     {
//                 //                         name:"Classical Music",
//                 //                         value:20
//                 //                     },
//                 //                     {
//                 //                         name:"Hard Rock",
//                 //                         value:80
//                 //                     }
//                 //                 ],
                        
//                 //                 colors: [
                        
//                 //                     "#07a2e3",
//                 //                     "#20b2aa",
            
            
//                 //                     "#fde23e",
//                 //                     "#f16e23", 
//                 //                     "#57d9ff",
//                 //                     "#937e88",
//                 //                     "#20b2aa",
//                 //                     "#ccff00",
            
//                 //                     "lightblue",
//                 //                     "lightgreen",
//                 //                     "yellow",
//                 //                     "pink",
//                 //                     "lavender"
                        
//                 //                 ],
//                 //                 hole:{
//                 //                     size:0.6,
//                 //                     // color:"#191e28",
//                 //                     // outlineColor:"#191e28",
//                 //                     color:"#fff",
//                 //                     outlineColor:"#fff",
//                 //                     textColor:"#000",
//                 //                     textSize:"35px"
//                 //                 }
            
            
//                 //             }
            
            
                    
//                 //         }
            
                
//                 //     }


//                 // }




            
//             break;
//             case'donutChart':




//                 // {
//                 //     "print": "report_box",
//                 //     "name": "Report Box",
//                 //     "value": [
//                     //     {
//                     //         "boxCall": "donutChart",
//                     //         "boxWidth": "100%",
//                     //         "boxHeight": 275,
//                     //         "value": {
//                         //         "donutType": "static",
//                         //         "donutSize": "250px",
//                         //         "donutHalfSize": "125px",
//                         //         "donutCenterSize": "150px",
//                         //         "donutCenterTopLeft": "50px",
//                         //         "donutCenterBackgroundColor": "#394264",
//                         //         "donutCenterBorderRadius": "100%",
//                         //         "donutSections": [
//                         //             {
//                         //             "percent": "25",
//                         //             "color": "royalblue"
//                         //             },
//                         //             {
//                         //             "percent": "25",
//                         //             "color": "lightblue"
//                         //             },
//                         //             {
//                         //             "percent": "25",
//                         //             "color": "lightseagreen"
//                         //             },
//                         //             {
//                         //             "percent": "25",
//                         //             "color": "lightgreen"
//                         //             }
//                         //         ]
//                     //         }
//                     //     }
//                 //     ],
//                 //     "required": "",
//                 //     "default": ""
//                 // },




//             break;
//             default:
//             }



     

//         break;
//         case'ledger':




//             // {
//             //     "print": "ledger",
//             //     "name": "Ledger",
//             //     "value": [
//             //     {
//             //         "name": "Direct Labor",
//             //         "value": "$400.00"
//             //     },
//             //     {
//             //         "name": "Direct Labor Hours per Week",
//             //         "value": "$100.00"
//             //     },
//             //     {
//             //         "name": "Indirect Labor",
//             //         "value": "$400.00"
//             //     },
//             //     {
//             //         "name": "Indirect Labor Hours per Week",
//             //         "value": "$100.00"
//             //     },
//             //     {
//             //         "name": "Hours Weekly Total",
//             //         "value": "$200.00"
//             //     },
//             //     {
//             //         "name": "Indirect Labor Hours per Week",
//             //         "value": "$100.00"
//             //     },
//             //     {
//             //         "name": "Hours Weekly Total",
//             //         "value": "$200.00"
//             //     }
//             //     ],
//             //     "required": "",
//             //     "default": ""
//             // },




//         break;
//         default:
//         }

      

//     }

// }



