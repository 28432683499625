
import React, { 

    useState, 
    useEffect,
    useRef

} from 'react';


//import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider';




import {

    LogoContainer

} from './Styled';



import {

    TPC_LOGO_TEXT,
    PROFIT_PIE

} from 'app-components/Zone/_SVGComps';




const Index = (props) => {



    // -- window dims -- //
    // const { 
    //     width, 
    //     //height 
    // } = useWindowDimensions();


    // -- ref -- //


    const refContainer = useRef(null)    


    // // -- state -- //
    // const [highZ, setHighZ] = useState(0)

    // useEffect(() => {
        
    //     //setFlyrHeight(flyrRef.current.offsetHeight);
    //     if(highZ === 0)
    //     {
    //         var z = LibElem.HighestZ();
    //         setHighZ(z)

    //     }

    // }, [highZ])



    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);



    useEffect(() => {
        
        if(!isLoaded)
        {
            setIsLoaded(true);

        }

        // var dif;
        // dif = (width - refContainer.current.offsetWidth)/2;



    }, [
        props,
        //width,
        isLoaded,
        refContainer
    ])







    if(!isLoaded)
    {

        return ( 

            <LogoContainer
            ref={refContainer}
            //marginLeft={marginLeft}
            >
            </LogoContainer>

        );


    }else{

        return ( 

            <div>

                {
                props.call === 'theprofitcalculator'
                ?

                    <LogoContainer
                    ref={refContainer}
                    //marginLeft={marginLeft}
                    >

                        <div className="Icon">

                            <PROFIT_PIE />

                        </div>

                        <div className="Text">

                            <TPC_LOGO_TEXT />

                        </div>     

                    </LogoContainer>


                :
                    <LogoContainer></LogoContainer>
                }


            </div>



        );
    }




}



export default Index;





