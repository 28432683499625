

export const Overhead = [



    //-----------------------------------
    // Report Box
    //-----------------------------------

    {

        "print"		    :'bar',
        "name"		    :'Percentage',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },

    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"10px"
        },
        "required"	    :"",
        "default"	    :""


    },


    {

        "print"		    :'report_box',
        "name"		    :'Report Box',
        "value"		    :[

            {

                boxCall:'gauge4',
                boxWidth:"45%",
                boxHeight:225,
                value:{


                    backgroundColor:"transparent",
                    gaugeSize:225,
                    margin:"0 0 0 0",


                    gaugeBackgroundColor : false, //"#454d5f",
                    gaugeBackgroundWidth : false, //85,
    
                    gaugePercentageColor : "#c00",
                    gaugePercentageWidth : false, //75,
                    gaugePercent : 60,
          
                    needleCenterColor : false, //"black",
                    needleColor : false, //"black",
                    needleSize : false, //"165",
                    needleRotation  : -100,


                    colors: "GaugeColorsB"


                }
          
        
            },


            {

                boxCall:'txt',
                boxWidth:"50%",
                boxHeight:225,
                value:[

                    {
                        className:"Big",
                        txt:"45.5%",
                        style:{

                            //fontSize:"100px",
                            textAlign:"center"

                        }
                    },

                    {
                        className:"Note",
                        txt:"Expenses / Sales = Overhead Percentage",
                        style:{

                            //fontSize:"13px",
                            textAlign:"center"

                        }
                    }


                ]


            }



        ],
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"0px"
        },
        "required"	    :"",
        "default"	    :""


    },









    //-----------------------------------
    // Report Box - Expenses / Sales
    //-----------------------------------

    {

        "print"		    :'bar',
        "name"		    :'Expenses / Sales',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },

    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"0px"
        },
        "required"	    :"",
        "default"	    :""


    },


    {

        "print"		    :'report_box',
        "name"		    :'Report Box',
        "value"		    :[



            {

                boxCall:'act',
                boxWidth:"50%",
                boxHeight:180,
                value:[

                    {
                        className:"Small",
                        txt:"Expenses + Indirects",
                        style:{

                            //fontSize:"100px",
                            textAlign:"center"

                        }
                    },

                    {
                        className:"Medium",
                        txt:"$0.00",
                        style:{

                            //fontSize:"100px",
                            textAlign:"center"

                        }
                    },


                    // {
                    //     controls:{

                    //         form:{

                    //             submitCall:'calculate',
                    //             postTo:apiDomain+"alter-calc-dash",
                    //             data:VPincome,
                    //             flyr:{
                    //                 type:"form",
                    //                 styl:{
                    //                     maxWidth:500,
                    //                     fieldType:'field'
                    //                 }
                    //             }
            
                    //         }

                    //     },
                    //     svg:'chart'
                    // },



                    {
                        className:"Spacer",
                        style:{

                            height:"7px"

                        }
                    },


                    {
                        className:"Note",
                        //txt:"Expenses + Owners Indirect + Employees Indirect",
                        txt:"Expenses + Indirects (Monthly)",
                        style:{

                            //fontSize:"13px",
                            textAlign:"center"

                        }
                    }


                ]


            },



            {

                boxCall:'act',
                boxWidth:"50%",
                boxHeight:180,
                value:[

                    {
                        className:"Small",
                        txt:"Projected Monthly Sales",
                        style:{

                            //fontSize:"100px",
                            textAlign:"center"

                        }
                    },

                    {
                        className:"Medium",
                        txt:"$0.00",
                        style:{

                            //fontSize:"100px",
                            textAlign:"center"

                        }
                    },


                    // {
                    //     controls:{

                    //         form:{

                    //             submitCall:'calculate',
                    //             postTo:apiDomain+"alter-calc-dash",
                    //             data:VPincome,
                    //             flyr:{
                    //                 type:"form",
                    //                 styl:{
                    //                     maxWidth:500,
                    //                     fieldType:'field'
                    //                 }
                    //             }
            
                    //         }


                    //     },
                    //     svg:'pencil'


                    // },


                    {
                        className:"Spacer",
                        style:{

                            height:"7px"

                        }
                    },


                    {
                        className:"Note",
                        txt:"Gross Sales (Monthly)",
                        style:{

                            //fontSize:"13px",
                            textAlign:"center"

                        }
                    }


                ]


            }




        ],
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"15px"
        },
        "required"	    :"",
        "default"	    :""


    },









    //-----------------------------------
    // Ledger
    //-----------------------------------

    {

        "print"		    :'bar',
        "name"		    :'Details',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'ledger',
        "name"		    :"Ledger",
        "value"		    :[


            {
                name:"Projected Monthly Sales",
                value:"$0.00",
                // bullet:{
                //     color:chartColors[1]
                // }
            },

            {
                name:"Expenses + Indirects",
                value:"$0.00",
                // bullet:{
                //     color:chartColors[0]
                // }
            },

            {
                name:"Total Monthly Expenses",
                value:"$0.00",
                // bullet:{
                //     color:chartColors[0]
                // }
            },

            {
                name:"Total Monthly Indirects",
                value:"$0.00",
                // bullet:{
                //     color:chartColors[0]
                // }
            }







        ],
        "required"	    :"",
        "default"	    :"",
        "colors"        :"ChartColorsC"


    },

    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"20px"
        },
        "required"	    :"",
        "default"	    :""


    },





    //-----------------------------------
    // Txt
    //-----------------------------------

    // {

    //     "print"		    :'bar',
    //     "name"		    :'Labor Hours',
    //     "value"		    :"",
    //     "required"	    :"",
    //     "default"	    :""


    // },
    {

        "print"		    :'txt',
        "name"		    :'Txt',
        "value"		    :[

            {

                // boxCall:'txt',
                // boxWidth:"100%",
                // boxHeight:230,

                className:"Note",
                txt:'***NOTE: Indirects = Any indirect portion of the Owners and Employees Salaries including Healthcare, IRA contributions etc.',
                style:{

                    //fontSize:"24px",
                    textAlign:"center"

                }

            }

        ],
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"50px"
        },
        "required"	    :"",
        "default"	    :""


    },







    // //-----------------------------------
    // // Report Box
    // //-----------------------------------

    // {

    //     "print"		    :'bar',
    //     "name"		    :'Percentage',
    //     "value"		    :"",
    //     "required"	    :"",
    //     "default"	    :""


    // },
    // {

    //     "print"		    :'report_box',
    //     "name"		    :'Report Box',
    //     "value"		    :[

    //         {

    //             boxCall:'donutChart',
    //             boxWidth:"45%",
    //             boxHeight:250,
    //             value:{

    //                 donutType:                      "static",
    //                 donutSize:                      "200px",
    //                 donutHalfSize:                  "100px",
    //                 donutCenterSize:                "100px",
    //                 donutCenterTopLeft:             "50px",
    //                 donutCenterBackgroundColor:     "#edeef1", //"#edeef1", //"#394264",
    //                 donutCenterBorderRadius:        "100%",
    //                 donutSections:[

    //                     {
    //                         percent :"50",
    //                         color   :"royalblue"
    //                         // rotate      :"0",
    //                         // itemRotate  :"76",
            
    //                     },
        
    //                     {
    //                         percent :"50",
    //                         color   :"lightblue"
    //                         // rotate      :"215",
    //                         // itemRotate  :"113",
    //                     }
        
    
    //                 ]

    //             }
        


    //         },


    //         {

    //             boxCall:'txt',
    //             boxWidth:"55%",
    //             boxHeight:250,
    //             value:[

    //                 {
    //                     className:"Big",
    //                     txt:"45.5%",
    //                     style:{

    //                         //fontSize:"100px",
    //                         textAlign:"center"

    //                     }
    //                 },

    //                 {
    //                     className:"Note",
    //                     txt:"Expenses / Sales = Overhead Percentage",
    //                     style:{

    //                         //fontSize:"13px",
    //                         textAlign:"center"

    //                     }
    //                 }


    //             ]


    //         }



    //     ],
    //     "required"	    :"",
    //     "default"	    :""


    // },
    // {

    //     "print"		    :'spacer',
    //     "name"		    :'',
    //     "value"		    :{

    //         backgroundColor:"transparent",
    //         height:"0px"
    //     },
    //     "required"	    :"",
    //     "default"	    :""


    // },








];





