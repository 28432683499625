import React, { 

    useState, 
    useEffect, 
    //useRef 

} from 'react';


import {

    ReportGraphic

} from '../Styled';


import SVGProfitPie from 'grfx/tpc_calc_profit_pie.svg';
import SVGPriceTag from 'grfx/tpc_calc_price_tag.svg';
import SVGBreakevenTag from 'grfx/tpc_calc_breakeven_tag.svg';


import SVGBillable from 'grfx/tpc_billable.svg';
import SVGCalendar from 'grfx/tpc_calendar.svg';
import SVGClock from 'grfx/tpc_clock.svg';
import SVGHealthcare from 'grfx/tpc_healthcare.svg';
import SVGIra from 'grfx/tpc_ira.svg';

import SVGOwner from 'grfx/tpc_ownr.svg';
import SVGEmployee from 'grfx/tpc_empl.svg';


import SVGOwnerEven from 'grfx/ownr_empl/ownr_even.svg';
import SVGOwnerDirect from 'grfx/ownr_empl/ownr_direct.svg';
import SVGOwnerDirectIndirect from 'grfx/ownr_empl/ownr_direct_indirect.svg';
import SVGOwnerIndirect from 'grfx/ownr_empl/ownr_indirect.svg';
import SVGOwnerIndirectDirect from 'grfx/ownr_empl/ownr_indirect_direct.svg';

import SVGEmployeeEven from 'grfx/ownr_empl/empl_even.svg';
import SVGEmployeeDirect from 'grfx/ownr_empl/empl_direct.svg';
import SVGEmployeeDirectIndirect from 'grfx/ownr_empl/empl_direct_indirect.svg';
import SVGEmployeeIndirect from 'grfx/ownr_empl/empl_indirect.svg';
import SVGEmployeeIndirectDirect from 'grfx/ownr_empl/empl_indirect_direct.svg';



const Index = (props) => {


    // -- ref -- //
    // const refA = useRef(null);


    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);


    useEffect(() => {

        if(!isLoaded)
        {
            setIsLoaded(true);

        }else{



            var svgSrc;

            switch(props.data.svgCall)
            {

                
            case'profit_pie':
                svgSrc = SVGProfitPie;
            break;
            case'price_tag':
                svgSrc = SVGPriceTag;
            break;
            case'breakeven_tag':
                svgSrc = SVGBreakevenTag;
            break;



            case'billable':
                svgSrc = SVGBillable;
            break;
            case'calendar':
                svgSrc = SVGCalendar;
            break;
            case'clock':
                svgSrc = SVGClock;
            break;
            case'owner':
                svgSrc = SVGOwner;
            break;
            case'employee':
                svgSrc = SVGEmployee;
            break;
            case'healthcare':
                svgSrc = SVGHealthcare;
            break;
            case'ira':
                svgSrc = SVGIra;
            break;



            case'ownr_even':
                svgSrc = SVGOwnerEven;
            break;
            case'ownr_direct':
                svgSrc = SVGOwnerDirect;
            break;
            case'ownr_direct_indirect':
                svgSrc = SVGOwnerDirectIndirect;
            break;
            case'ownr_indirect':
                svgSrc = SVGOwnerIndirect;
            break;
            case'ownr_indirect_direct':
                svgSrc = SVGOwnerIndirectDirect;
            break;


            case'empl_even':
                svgSrc = SVGEmployeeEven;
            break;
            case'empl_direct':
                svgSrc = SVGEmployeeDirect;
            break;
            case'empl_direct_indirect':
                svgSrc = SVGEmployeeDirectIndirect;
            break;
            case'empl_indirect':
                svgSrc = SVGEmployeeIndirect;
            break;
            case'empl_indirect_direct':
                svgSrc = SVGEmployeeIndirectDirect;
            break;


            default:
            }



            var margin;
            var marginTop;
            var marginLeft;
            if(props.lessThanThresh)
            {
                marginTop = "0px";
                marginLeft = "calc(50% - "+(props.data.svgWidth/2)+"px)";
            }else{
                marginTop = (props.data.boxHeight - props.data.svgHeight)/2+"px";
                marginLeft = "calc(50% - "+(props.data.svgWidth/2)+"px)"; 
            }
            margin = marginTop+" 0 0 "+marginLeft;

            setData({

                id:props.id,
                margin:margin,

                boxWidth:props.data.boxWidth,
                boxHeight:props.data.boxHeight,
                svgWidth:props.data.svgWidth,
                svgHeight:props.data.svgHeight,
                svgSrc:svgSrc

            });





        }
        //console.log("graphicData: "+JSON.stringify(props.data,null,2));
    },
    [
        props,
        isLoaded
    ])





    return (

        <ReportGraphic
        //backgroundColor={"lightblue"}
        width={data.svgWidth+"px"}
        height={data.svgHeight+"px"}
        margin={data.margin}
        >
            <img
                id={data.id}
                className="Img"
                src={data.svgSrc} 
                alt="graphic" 
            />

        </ReportGraphic>

    )


}

export default Index;


