// import { 
//     useSelector, 
//     //useDispatch 
// } 
// from 'react-redux';
// //import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    //useHistory,   
    //useParams,



} from "react-router-dom";


// import { Settings } from 'app-components/Prime/Data.js';
// import ApiResources from 'utils/components/Pages/ApiResources';



import SideNavObj from 'utils/components/Pages/SceneInfo/Trials/SideNav/SceneInfo';



import { 
    LibSystem
} from 'library';



const SideNavInfo = (props) => {




    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();

 
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);






    var R;

    if(SideNavObj['dashboard_sidenav'] 
    && Object.prototype.toString.call(SideNavObj['dashboard_sidenav']) === '[object Object]')
    {

        R = SideNavObj['dashboard_sidenav'];



        var patharr;
        var mainCategoryName;

        var sidenav;

        var item,subs;
        var currentItem;
        var newItems;
        var newSubs;


        var keyname,object;
        var pages,page,pageName,pageCaption,pageTo;



        if(localStorage.editMode)
        {

            if(props.zoneObj['knowledge_base'] 
            && props.zoneObj['knowledge_base']!== undefined)
            {
                if(props.zoneObj['knowledge_base'].pages 
                && props.zoneObj['knowledge_base'].pages !== undefined)
                {

                    patharr = pathname.split("/");
                    mainCategoryName = patharr[0];

                    sidenav = JSON.parse(JSON.stringify(SideNavObj['dashboard_sidenav']));


                    // -- extract currentItems -- //
            
                    for(item of sidenav.items)
                    {
                        if(mainCategoryName === item.name)
                        {
                            //alert(mainCategoryName+" "+item.name);
                            //currentSection
                            currentItem = item;
                            break;
                        }

                    }


                    // -- build dynamic subs -- //

                    pages = props.zoneObj['knowledge_base'].pages;
                    //console.log("Overview Pages:........... "+JSON.stringify(pages,null,2));


                    subs = [];
                    for(keyname in pages)
                    {
                        page = pages[keyname];

                        pageName = page.name;
                        pageName = LibSystem.RemoveExt(pageName);
                        pageName = LibSystem.FileDisplay(pageName);

                        pageCaption = LibSystem.FileDisplay(page.caption);

                        subs.push({

                            to:mainCategoryName+"/"+page.tree+"/",
                            name:pageName,
                            descr:pageCaption

                        });
                    }



                    // -- revise sidenav with newItems -- //

                    newSubs = currentItem.subs.concat(subs);
                    //alert(JSON.stringify(newSubs,null,2));

                    newItems = [];
                    for(item of sidenav.items)
                    {
                        if(mainCategoryName === item.name)
                        {

                            //alert(mainCategoryName+" "+item.name);

                            //currentSection
                            newItems.push({

                                to:currentItem.to,
                                name:currentItem.name,
                                descr:currentItem.descr,
                                subs:newSubs
                            });

                        }else{
                            newItems.push(item);
                        }

                    }
                    sidenav.items = newItems;
                    //console.log("Inside --- sidenav: "+JSON.stringify(sidenav,null,2));



                    R = sidenav;
            
                }

            }  
            
            
        }
        else
        {

            if(props.zoneObj['knowledge_base'] 
            && props.zoneObj['knowledge_base']!== undefined)
            {
                // console.log(JSON.stringify(props.zoneObj.knowledge_base,null,2));
                // alert("SceneInfo");
    
                var scene,navScene,si;

                if(props.zoneObj['knowledge_base'].scene 
                && props.zoneObj['knowledge_base'].scene !== undefined)
                {
                    //alert('has scene');
    
                    scene = props.zoneObj['knowledge_base'].scene;
    
                    switch(Object.prototype.toString.call(scene))
                    {
                    case'[object Array]':
    
                        for(object of scene)
                        {
                            if(object.currentScene.sceneCompName.match(/Navigator/gi))
                            {
                                navScene = object;
                                //console.log(JSON.stringify(navScene,null,2));
                                break;
                            }
                          
                        }
    
                    break;
                    default:
                        navScene = scene;
                    }
    
    
                    if(navScene.sceneInfo 
                    && navScene.sceneInfo !== undefined)
                    {
    
                        //alert('has sceneInfo')
    
                        si = navScene.sceneInfo;
                        if(si[0].items 
                        && si[0].items !== undefined
                        //&& Object.prototype.toString.call(props.zoneObj['knowledge_base'].scene.sceneInfo.items) === '[object Array]'
                        )
                        {
    


                            //console.log(JSON.stringify(props.zoneObj.knowledge_base.scene.sceneInfo[0].items,null,2));
                            //alert("has items");
    
                            // [
                            //     {
                            //       "name": "Introduction",
                            //       "to": "KnowledgeBase/Introduction",
                            //       "descr": "Intro to KnowledgeBase",
                            //       "category": "Knowledge Base"
                            //     },
                            //     {
                            //       "name": "Overhead",
                            //       "to": "KnowledgeBase/Overhead",
                            //       "descr": "Learn about Overhead",
                            //       "category": "Knowledge Base"
                            //     },
                            //     {
                            //       "name": "Cost Of Goods",
                            //       "to": "KnowledgeBase/CostOfGoods",
                            //       "descr": "Learn about CostOfGoods",
                            //       "category": "Knowledge Base"
                            //     },
                            //     {
                            //       "name": "Labor",
                            //       "to": "KnowledgeBase/Labor",
                            //       "descr": "Learn about Labor",
                            //       "category": "Knowledge Base"
                            //     }
                            // ]
    
        



                            patharr = pathname.split("/");
                            mainCategoryName = patharr[0];
        
                            sidenav = JSON.parse(JSON.stringify(SideNavObj['dashboard_sidenav']));
  

                            // -- extract currentItems -- //
                    
                            for(item of sidenav.items)
                            {
                                if(mainCategoryName === item.name)
                                {
                                    //currentSection
                                    currentItem = item;
                                    break;
                                }
        
                            }
        
        
                            // -- build dynamic subs -- //
        
                            //pages = props.zoneObj['knowledge_base'].pages;
                            pages = si[0].items;
                            //console.log("Overview Pages:........... "+JSON.stringify(pages,null,2));

        
                            subs = [];
                            for(page of pages)
                            {
                            
                                pageName = page.name;
                                pageName = LibSystem.RemoveExt(pageName);
                                pageName = LibSystem.FileDisplay(pageName);
        
                                pageCaption = LibSystem.FileDisplay(page.descr);

                                pageTo = page.category + "/" +page.to.replace(/KnowledgeBase\//gi,"") + "/";
        
                                subs.push({
        
                                    //to:mainCategoryName+"/"+page.tree+"/",
                                    to:pageTo,
                                    name:pageName,
                                    descr:pageCaption
        
                                });
                            }
        
        
        
                            // -- revise sidenav with newItems -- //
        
                            newSubs = currentItem.subs.concat(subs);
        
                            newItems = [];
                            for(item of sidenav.items)
                            {
                                if(mainCategoryName === item.name)
                                {
                                    //currentSection
                                    newItems.push({
        
                                        to:currentItem.to,
                                        name:currentItem.name,
                                        descr:currentItem.descr,
                                        subs:newSubs
                                    });
        
                                }else{
                                    newItems.push(item);
                                }
        
                            }
                            sidenav.items = newItems;
                            //console.log("Inside --- sidenav: "+JSON.stringify(sidenav,null,2));
        
        
                            R = sidenav;


            
                        }
        
        
                    }
    
    
            
                }
    
            }
    


        }




    }



    //console.log("SIDENAV:::: "+JSON.stringify(R,null,2));
        

    return R;

}



export default SideNavInfo;
