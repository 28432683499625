import React, { 

    useState, 
    useEffect,
    //useRef

} from 'react';

//import 'app-style/price-form/style.css';
//import '../../StyleSheets/PriceForm.css';
import 'app-components/Zone/_StyleSheets/PriceForm.css';

export const FFcheckbutton = (props) => {


    //var _FUNC = props.data.funct['name'] + "()";
    //var _FUNC = props.data.funct['name'] + "('"+props.data.funct['object']+"')";
    //var _RUN = new Function(_FUNC);

    

    // -- state -- //

    const [buttonStyle, setButtonStyle] = useState({});
    
    useEffect(() => {


        var bgPosition;
        if(props.active)
        {
            bgPosition = "-100px -0px";
        }else{
            bgPosition = "-0px -0px";
        }

        setButtonStyle({

            bgPosition : bgPosition

        });


        //alert(JSON.stringify(buttonStyle,null,2));


     },[props])
     



    const handleOverOut = (v) => {


        var stilo = '';
        if(v.call === 'over')
        { 
            //stilo += "background-color:#00ff00;";
            //stilo += "color:#00ff00;";

            stilo += "background-position:"+buttonStyle.bgPosition+";";
            stilo += "border:2px solid #00ff00;";

            v.target.style = stilo

        }else{

            //stilo += "background-color:#191e28;";
            //stilo += "color:#191e28;";
            
            stilo += "background-position:"+buttonStyle.bgPosition+";";
            stilo += "border:2px solid #fff;";

            v.target.style = stilo;

        }//==

    }



    return (


        <div className = "frm">
        <div className = "checkbutton">

            <div 
            className="label"
            >

                {props.data.label}

            </div>


            <div 
            id={"ffcheckbtn_"+props.indx}
            className = "graphic-button"
            style={{

                backgroundPosition : buttonStyle.bgPosition

            }}

            onClick={() => props.handle({

                call        :"button",
                actionCall  :"options",
                show        : true,
                props       : props

            })}

            
            onMouseOver = {event => {
                // event properties must be copied to use async
                let target = event.target;
                handleOverOut({

                    target      :target,
                    call        :"over"
                })

            }}

            onMouseOut = {event => {
                // event properties must be copied to use async
                let target = event.target;
                handleOverOut({

                    target      :target,
                    call        :"out"
                })

            }}
            



            >
            </div>


          
        </div>
        </div>






    )


}








