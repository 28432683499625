
import { VPaccount } from './form/account';
import { VPoverhead, VPexpense } from './form/overhead';


import { 

    VPownrsYearly,
    VPownrsMonthly,
    VPownrsDirect,
    VPownrsIndirect,
    VPownrs,
    VPownr

} from './form/ownr';


import { 

    VPemplsYearly, 
    VPemplsMonthly, 
    VPemplsDirect,
    VPemplsIndirect,
    VPempls,
    VPempl

} from './form/empl';


import { VPindirectLabor, VPidlEmployee } from './form/idl';
import { 
    VPformTemplateOwnr, 
    VPformTemplateEmpl,
    VPformTemplateExpense
} from './form/templates';




const VPexpenses = {

    "name"     : "Expenses",//just used for display
    "value"    :{

        "monthly_expenses" : {

            "print"		:'price',
            "name"		:'Actual Monthly Expenses',
            "value"		:0,
            "required"	:true,
            "default"	:0
        }
    }


};


const VPincome = {

    "name"     : "Projected Monthly Sales",//just used for display
    "value"    :{

        "headline" : {

            "print"		:'headline',
            "name"		:'Headline',
            "value"		:'Please input companies estimated monthly sales. Used to create your "Overhead Percentage"',
            "required"	:false,
            "default"	:"headline - type a value"
        },

        "projected_monthly_sales" : {

            "print"		:'price',
            "name"		:'Projected Monthly Sales',
            "value"		:50000,
            "required"	:true,
            "default"	:50000
        }

    }


};







// rec-dash-company


const VPrates = {


    "name":"Rates",
    "value":{

        "design" : {

            "print"		:'price',
            "name"		:'Design Hourly Rate',
            "value"		:18.25,
            "required"	:true,
            "default"	:18.25

        },  		
        
        "production" : {

            "print"		:'price',
            "name"		:'Prod. Hourly Rate',
            "value"		:19.35,
            "required"	:true,
            "default"	:19.35

        },  
        "install" : {

            "print"		:'price',
            "name"		:'Install Hourly Rate',
            "value"		:20.55,
            "required"	:true,
            "default"	:20.55
        }


    }


};



// displayOrder use to set the proper display order for a form or result display 
// this is create cause dynamo results may be skewed

const VPdisplayOrder = {

    card : {

        idl_employee:[
            
            //"idl_name",
            "idl_hourly_rate",
            "idl_burden_weekly_hours",
            "idl_vacation_weeks",
            "idl_health_insurance_monthly",
            "idl_ira_percent",
            "idl_tax_comp_percent"
        ]

    },

    form : {

        idl_employee:[

            "idl_name",
            "idl_hourly_rate",
            "idl_burden_weekly_hours",
            "idl_vacation_weeks",
            "idl_health_insurance_monthly",
            "idl_ira_percent",
            "idl_tax_comp_percent"
        ]

    },

    result : {}

}





export {


    VPformTemplateOwnr,
    VPformTemplateEmpl,
    VPformTemplateExpense,

    VPaccount,
    VPrates,
    VPexpenses,
    VPincome,
    
    VPoverhead,
    VPexpense,

    VPownrsYearly,
    VPownrsMonthly,
    VPownrsDirect,
    VPownrsIndirect,
    VPownrs,
    VPownr,

    VPemplsYearly, 
    VPemplsMonthly, 
    VPemplsDirect,
    VPemplsIndirect,
    VPempls,
    VPempl,

    VPindirectLabor,
    VPidlEmployee,

    VPdisplayOrder

};




