import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';


import {

    ReportLedger,
        ReportBullet,
        ReportPercent,
        ReportItem,
            // ReportItemName,
            // ReportItemValue


} from '../Styled';



import LineItem from './LineItem';



const Index = (props) => {



    // -- ref -- //
    const refA = useRef(null);


    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState({});
    const [items, setItems] = useState([]);


    useEffect(() => {

        if(!isLoaded)
        {

            setIsLoaded(true);
            setItems(props.data.value);

        }
        else
        {
            setItems(props.data.value);


            var margin;
            var marginTop;
            var marginBottom;
            var marginLeft;

            marginTop = "10px";
            marginBottom = "0px";
            marginLeft = "10px";
            if(props.data.boxHeight 
            && props.data.boxHeight !== undefined
            )
            {
                marginTop = (props.data.boxHeight - refA.current.offsetHeight)/2 - (5) + "px";
            }

            if(props.lessThanThresh)
            {
                marginTop = "0px";
                marginBottom = "30px";
            }

            margin = marginTop+" 0 "+marginBottom+" "+marginLeft;

            setData({

                id:props.id,
                margin:margin
         
            });

      

        }
        //console.log("ledgerData: "+JSON.stringify(props.data,null,2));


    },
    [
        props,
        isLoaded
        //rowRefs
    ])





    return (


        <ReportLedger
        ref={refA}
        id={data.id}
        margin={data.margin}
        //backgroundColor={'lightblue'}
        >
            {items.map((itm, k) => (

                itm.bullet && itm.bullet !== undefined
                ?

                    <div
                    key={k}
                    >

                        <ReportBullet
                        backgroundColor={itm.bullet.color}
                        >
                        </ReportBullet>

                        <ReportPercent>

                            <div className="Text">

                                {itm.percent+"%"}

                            </div>

                        </ReportPercent>

                        <ReportItem                           
                        id={"row_"+k}
                        width={"calc(100% - 115px)"}
                        >
                            <LineItem 
                                
                                data={itm} 
                            />

                        </ReportItem>

                    </div>

                 
                :
                itm.percent && itm.percent !== undefined
                ?

                    <div
                    key={k}
                    >
                        <ReportPercent
                        margin={"12px 7px 0 0px"}
                        >

                            <div className="Text">

                                {itm.percent+"%"}

                            </div>

                        </ReportPercent>

                        <ReportItem                           
                        id={"row_"+k}
                        width={"calc(100% - 83px)"}
                        >
                            <LineItem 
                                
                                data={itm} 
                            />

                        </ReportItem>

                    </div>

                 
                :


                    <ReportItem                           
                    key={k}
                    id={"row_"+k}
                    >
                        <LineItem data={itm} />

                    </ReportItem>



            ))}

        </ReportLedger>


    )


}

export default Index;


