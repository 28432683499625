export const EXPENSES = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 500 500";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};

	let iconColor = props.iconColor || "rgb(0,0,0)";


	let darklite = props.darklite || "lite";
	if(darklite === 'dark')
	{

		return(
			<div>
			<svg xmlns="http://www.w3.org/2000/svg"
				width={svgWidth}
				height={svgHeight}
				viewBox={svgViewBox}
				preserveAspectRatio={svgPreserveAspectRatio}
				style={svgStyle}
			>
	
				<path 
					fill={iconColor}
					fillRule="evenodd"
					clipRule="evenodd"
					d="M89.562,425.584 c-21.814,0-36.707-17.697-36.704-34.545l0.023-141.377c0.004-20.352,3.153-28.541,18.966-41.381l18.576-15.083l7.018,30.767
					l-14.259,15.083l84.204,69.63l-41.563,39.402c-6.744,6.395-16.279,14.141-8.097,24.289l114.97-87.441 c5.395-4.102,11.846-4.227,17.304-4.205c5.459-0.021,11.91,0.104,17.305,4.205l114.969,87.441 c8.184-10.148-1.352-17.895-8.096-24.289l-41.563-39.402l84.203-69.63l-14.258-15.083l7.018-30.767l18.576,15.083 c15.813,12.84,18.963,21.029,18.965,41.381l0.023,141.377c0.004,16.848-14.889,34.545-36.703,34.545H89.562L89.562,425.584z M187.22,302.391c0.017-48.039-0.037-109.806-0.003-154.563c11.888-6.162,23.102-11.234,30.227-22.131 c3.487-5.332,4.157-11.975,9.176-16.732h46.762c5.02,4.758,5.689,11.4,9.178,16.732c7.125,10.896,18.338,15.969,30.227,22.131 c0.033,44.756-0.021,106.523-0.004,154.561l-40.307-30.656c-6.605-4.844-14.002-5.924-21.975-5.924 c-8.359,0.027-16,0.797-22.978,5.928L187.22,302.391L187.22,302.391z M250.001,135.723c7.251,0,13.163,5.914,13.163,13.164 c0,7.251-5.912,13.164-13.163,13.164s-13.165-5.913-13.165-13.164C236.836,141.637,242.75,135.723,250.001,135.723L250.001,135.723 z M250,189.274c21.771,0,39.527,14.415,39.527,32.089S271.771,253.451,250,253.451c-21.77,0-39.525-14.414-39.525-32.088 S228.23,189.274,250,189.274L250,189.274z M184.365,83.057c-16.327,0-23.917,6.094-23.917,23.924v182.631l-14.293-11.262 l-22.951-146.715l23.21-4.857l-0.03-27.278l-36.642,7.61c-14.588,3.031-16.912,15.252-14.118,28.175l28.87,126.438l-6.983-5.361 L87.178,137.11c-3.6-17.642,1.503-33.966,20.705-38.438l47.514-9.891c5.285-11.125,17.826-14.235,28.918-14.365h131.371 c11.092,0.13,23.633,3.24,28.918,14.365l47.514,9.891c19.203,4.473,24.305,20.797,20.705,38.438L382.49,256.361l-6.984,5.361	l28.871-126.438c2.793-12.923,0.469-25.144-14.119-28.175l-36.641-7.61l-0.031,27.278l23.211,4.857L353.846,278.35l-14.293,11.262 V106.98c0-17.83-7.59-23.924-23.918-23.924H184.365L184.365,83.057z"
				/>
	
			</svg>
			</div>
	
		);//return
	


	}
	else // lite
	{


		return(
			<div>
			<svg xmlns="http://www.w3.org/2000/svg"
				width={svgWidth}
				height={svgHeight}
				viewBox={svgViewBox}
				preserveAspectRatio={svgPreserveAspectRatio}
				style={svgStyle}
			>
	
	
				<path 
					fill={iconColor}
					fillRule="evenodd"
					clipRule="evenodd"
					d="M250,178.834c21.77,0,39.525,14.414,39.525,32.088	S271.77,243.011,250,243.011c-21.771,0-39.526-14.415-39.526-32.089S228.229,178.834,250,178.834L250,178.834z M250.861,125.282	c7.25,0,13.164,5.914,13.164,13.164c0,7.251-5.914,13.164-13.164,13.164c-7.251,0-13.165-5.913-13.165-13.164 C237.696,131.196,243.61,125.282,250.861,125.282L250.861,125.282z M274.629,264.68l38.152,29.018 c-0.018-48.152,0.037-111.332,0.004-156.311c-11.889-6.162-23.102-11.233-30.229-22.13c-3.486-5.331-4.156-11.974-9.176-16.732	h-46.762c-5.02,4.759-5.689,11.401-9.176,16.732c-7.125,10.896-18.339,15.968-30.228,22.13	c-0.033,44.979,0.021,108.16,0.003,156.313l38.147-29.014c7.231-5.314,15.478-6.674,24.634-6.643 C258.953,258.012,267.207,259.24,274.629,264.68L274.629,264.68z M338.693,277.328L353.604,265l22.332-143.806l-23.209-4.857 l0.031-27.278l36.641,7.611c14.59,3.029,16.912,15.25,14.119,28.174L375.975,246.5l22.963-18.99l-9.766-10.33l12.848-56.326 l33.883,27.512c17.539,14.44,23.334,28.299,23.457,50.821l0.023,141.581c-0.734,26.67-22.748,45.957-48.789,46.617H89.406 c-26.032-0.66-48.068-19.949-48.789-46.621l0.023-141.574c0.113-22.519,5.923-36.392,23.459-50.825l33.882-27.511l12.847,56.326 l-9.766,10.33l22.965,18.99L96.483,124.844c-2.794-12.924-0.47-25.145,14.118-28.174l36.642-7.611l0.03,27.278l-23.209,4.857 L146.397,265l14.91,12.328V96.54c0-17.831,7.59-23.925,23.917-23.925h129.551c16.328,0,23.918,6.093,23.918,23.925V277.328 L338.693,277.328z M89.562,415.145c-21.814,0-36.707-17.699-36.704-34.545l0.023-141.379c0.004-20.351,3.153-28.541,18.966-41.38
					l18.576-15.083l7.018,30.767l-14.259,15.083l84.204,69.631l-41.563,39.402c-6.744,6.395-16.279,14.141-8.097,24.289l114.97-87.441 c5.395-4.104,11.846-4.227,17.304-4.207c5.459-0.02,11.91,0.104,17.305,4.207l114.969,87.441	c8.184-10.148-1.352-17.895-8.096-24.289l-41.563-39.402l84.203-69.631l-14.258-15.083l7.018-30.767l18.576,15.083 c15.813,12.839,18.963,21.029,18.965,41.38l0.023,141.379c0.004,16.846-14.889,34.545-36.703,34.545H89.562L89.562,415.145z"
				/>
	
	
			</svg>
			</div>
	
		);//return



		// return(
		// 	<div>
		// 	<svg xmlns="http://www.w3.org/2000/svg"
		// 		width={svgWidth}
		// 		height={svgHeight}
		// 		viewBox={svgViewBox}
		// 		preserveAspectRatio={svgPreserveAspectRatio}
		// 		style={svgStyle}
		// 	>
	
	
		// 		<path 
		// 			fill={iconColor}
		// 			d="M89.562,421.265c-21.814,0-36.707-17.699-36.704-34.547l0.023-141.377 c0.004-20.352,3.153-28.541,18.966-41.38l18.576-15.084l7.018,30.68l-14.259,15.082l84.204,69.63l-41.563,39.402 c-6.744,6.395-16.279,14.141-8.097,24.291l114.97-87.443c5.395-4.102,11.846-4.227,17.304-4.205
		// 			c5.459-0.021,11.91,0.104,17.305,4.205l114.969,87.443c8.184-10.15-1.352-17.896-8.096-24.291l-41.563-39.402l84.203-69.63 l-14.258-15.082l7.018-30.768l18.576,15.084c15.813,12.839,18.963,21.028,18.965,41.38l0.023,141.377 c0.004,16.848-14.889,34.547-36.703,34.547H89.562L89.562,421.265z M96.483,130.964l29.2,113.479l21.449,15.935l-23.068-133.063 l23.209-4.857l-0.03-27.278l-36.642,7.61C96.014,105.819,93.689,118.04,96.483,130.964L96.483,130.964z M185.225,78.735 c-16.328,0-23.917,6.093-23.917,23.925v168.231l25.908,20.51c0.026-48.98-0.035-100.696,0-147.895
		// 			c11.889-6.162,23.103-11.233,30.228-22.13c3.486-5.332,4.156-11.975,9.176-16.732h46.762c5.02,4.758,5.689,11.4,9.176,16.732 c7.127,10.896,18.34,15.968,30.229,22.13c0.035,47.198-0.027,98.914,0,147.895l25.908-20.51V102.66 c0-17.832-7.588-23.925-23.918-23.925H185.225L185.225,78.735z M403.518,130.964l-29.201,113.479l-21.447,15.935l23.066-133.063 l-23.209-4.857l0.031-27.278l36.641,7.61C403.988,105.819,406.311,118.04,403.518,130.964L403.518,130.964z M250.861,131.402 c7.25,0,13.164,5.913,13.164,13.164s-5.914,13.164-13.164,13.164c-7.251,0-13.165-5.913-13.165-13.164 S243.61,131.402,250.861,131.402L250.861,131.402z M250,184.954c21.77,0,39.525,14.414,39.525,32.088S271.77,249.131,250,249.131
		// 			c-21.771,0-39.526-14.415-39.526-32.089S228.229,184.954,250,184.954L250,184.954z"
		// 		/>
	
	
	
		// 	</svg>
		// 	</div>
	
		// );//return


		
	}




}