import {test} from './defaults/test';
import {expense} from './defaults/expense';
import {ownr} from './defaults/ownr';
import {empl} from './defaults/empl';
import {Direct_Labor_Hours} from './defaults/Direct_Labor_Hours';
import {Direct_Labor_Rates} from './defaults/Direct_Labor_Rates';
import {Overhead} from './defaults/Overhead';
import {ExpensesIndirects} from './defaults/Overhead/ExpensesIndirects';
import {ExpensesIndirects as ExpensesIndirectsReport} from './defaults/report/Overhead/ExpensesIndirects';
import {Healthcare} from './defaults/Healthcare';
import {IRA} from './defaults/IRA';
import {calculator} from './defaults/calculator';

import {Overhead as ReportOverhead} from './defaults/report/Overhead';
import {Expense as ReportExpense} from './defaults/report/Expense';



export const VPreportDefaults = {

    test:test,

    calculator:calculator,
    overhead:Overhead,
    expense:expense,
    ownr:ownr,
    empl:empl,

    Overhead:Overhead,
    OverheadExpensesIndirects:ExpensesIndirects, 
    OverheadReportExpensesIndirects:ExpensesIndirectsReport, 

    Direct_Labor_Hours:Direct_Labor_Hours,
    Direct_Labor_Rates:Direct_Labor_Rates,
    Healthcare:Healthcare,
    IRA:IRA,


    // Report
    ReportOverhead:ReportOverhead,
    ReportExpense:ReportExpense


};

