import styled from "styled-components";


export const Star = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    //border:             props.border || "1px solid lightgray",

    width:              props.width || "100%",
    height:             props.height || "auto",
    margin:             props.margin || "40px 0 0 0",
    padding:            props.padding || "0 0 100px 0",

    minHeight:          props.minHeight || "150px"

}))`


    background-color:transparent;

    width:20px;
    height:20px;
    padding:0 0 0 0;
    margin:0 2px 0 0;
    position:relative;
    float:left;

`;






export const SmallStar = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    // border:             props.border || "1px solid lightgray",

    width:              props.width || "100%",
    height:             props.height || "auto",
    margin:             props.margin || "20px 0 0 0",
    padding:            props.padding || "0 0 100px 0",

    minHeight:          props.minHeight || "150px"

}))`


    background-color:transparent;

    width:16px;
    height:16px;
    padding:0 0 0 0;
    margin:0 2px 0 0;
    position:relative;
    float:left;

`;




