import { useEffect } from 'react';
/**
 * useKeyPress
 * @param {string} key - the name of the key to respond to, compared against event.key
 * @param {function} action - the action to perform on key press
 */
export default function useKeyPress(key, action) {

    useEffect(() => {

        function onKeyup(e) {

            if(e.key.toUpperCase() === key.toUpperCase()) action()
        }
        window.addEventListener('keyup', onKeyup);
        return () => window.removeEventListener('keyup', onKeyup);

    }, [key,action]);
}





//--------------------------------
//Usage
//--------------------------------

// import useKeyPress from 'src/hooks/useKeypress';

// const MyComponent = props => {

//     useKeyPress('Escape', () => {
//         alert('you pressed escape!')
//     });

//     return <h1>Example</h1>
// }