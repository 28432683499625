import styled from "styled-components";
//import styled, { keyframes } from 'styled-components';
//import px2vw from "utils/px2vw";


/*

Over 200 classes were generated for component styled.div with the id of "sc-AxirZ".
Consider using the attrs method, together with a style object for frequently changed styles.
Example:

const Component = styled.div.attrs({
    style: ({ background }) => {
        background,
    },
})`width: 100%;`

<Component />


*/



export const Side = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#EDEEF1",
    border:             props.border || "0px solid lightgray",

    height:             props.height || "100%",
    margin:             props.margin || "0 0 0 0",

}))`

    background-color:${props => props.backgroundColor};
    border-right:${props => props.border};

    max-width:300px;
    width:300px;
    height:${props => props.height};

    padding:0 0 0 0;
    margin:${props => props.margin};

    position:absolute;
    top:0px;
    left:0px;
    z-index:2;


    overflow:auto;

    @media (max-width: 1024px) {

        background-color:#EDEEF1;

        border:0;
        border-radius:0;

    }

`;





export const Main = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",

    width:              props.width || "calc(100% - 0px)",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "0 0 0 0",

    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "40px",
    fontWeight:         props.fontWeight  || "550",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration  || "none",
    color:              props.color || "black"



    //box-shadow:10px 0px #00cc66;

}))`


    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    height:${props => props.height};
  
    padding:${props => props.padding};
    margin:${props => props.margin};


    display: flex;
    flex-direction:column;
    min-height:100vh;

    position:relative;
    float:left;

    overflow:hidden;


 


    .Title {

        background-color:transparent;

        width:100%;
        height:auto;
        padding:10px 0 10px 10px;
        margin:0 0 0 0;

        position:relative;
        float:left;

        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        text-align:${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};

    }



`;





// export const Side2 = styled.div.attrs(props => ({

//     backgroundColor:    props.backgroundColor || "#454d5f",

//     width:              props.width || "calc(40% - 0px)",
//     height:             props.height || "100vh",
//     margin:             props.margin || "0 0 0 0",


//     fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
//     fontSize:           props.fontSize || "40px",
//     fontWeight:         props.fontWeight  || "550",
//     textAlign:          props.textAlign || "left",
//     textDecoration:     props.textDecoration  || "none",
//     color:              props.color || "black"



//     //box-shadow:10px 0px #00cc66;

// }))`


//     background-color:${props => props.backgroundColor};


//     width:${props => props.width};
//     height:${props => props.height};
//     min-height:400px;

//     padding:0 0 0 0;
//     margin:${props => props.margin};

//     position:relative;
//     float:right;

//     overflow:hidden;



// `;





export const Side2 = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",

    backgroundImage:    props.backgroundImage || "/_resources/icons/tpc_profit_pie_hidden.svg",
    backgroundRepeat:   props.backgroundRepeat || "no-repeat",
    backgroundPosition: props.backgroundPosition || "bottom 0px right 100%",
    backgroundSize:     props.backgroundSize || "98vh 98vh",
    
    width:              props.width || "calc(40% - 0px)",
    height:             props.height || "100vh",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",
    

    transform:          props.transform  || "rotate(0deg)",
    


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "40px",
    fontWeight:         props.fontWeight  || "550",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration  || "none",
    color:              props.color || "black"



    //box-shadow:10px 0px #00cc66;

    // display:block;

}))`


    background-color:${props => props.backgroundColor};
    background-image:url(${props => props.backgroundImage});
    background-repeat:${props => props.backgroundRepeat};
    background-position:${props => props.backgroundPosition};
    background-size:${props => props.backgroundSize};

    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    display: flex;
    flex-direction:column;
    min-height:100vh;

    position:relative;
    float:right;

    overflow:visible;

    transform:${props => props.transform};



`;






// export const Side2 = styled.div.attrs(props => ({

//     backgroundColor:    props.backgroundColor || "transparent",

//     backgroundImage:    props.backgroundImage || "/_resources/icons/tpc_profit_pie_hidden.svg",
//     backgroundRepeat:   props.backgroundRepeat || "no-repeat",
//     backgroundPosition: props.backgroundPosition || "bottom 0px right 100%",
//     backgroundSize:     props.backgroundSize || "98vh 98vh",
    
//     width:              props.width || "calc(40% - 0px)",
//     height:             props.height || "100vh",
//     padding:            props.padding || "0 0 0 0",
//     margin:             props.margin  || "0 0 0 0",
    

//     transform:          props.transform  || "rotate(0deg)",
    


//     fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
//     fontSize:           props.fontSize || "40px",
//     fontWeight:         props.fontWeight  || "550",
//     textAlign:          props.textAlign || "left",
//     textDecoration:     props.textDecoration  || "none",
//     color:              props.color || "black"



//     //box-shadow:10px 0px #00cc66;

//     // display:block;


//     // display: flex;
//     // flex-direction:column;


// }))`


//     background-color:transparent;

//     width:${props => props.width};
//     height:100%;
//     padding:${props => props.padding};
//     margin:${props => props.margin};

//     display: flex;
//     flex-direction:column;

//     position:relative;
//     float:right;

//     overflow:visible;


//     .Column {

//         background-color:${props => props.backgroundColor};
//         background-image:url(${props => props.backgroundImage});
//         background-repeat:${props => props.backgroundRepeat};
//         background-position:${props => props.backgroundPosition};
//         background-size:${props => props.backgroundSize};

//         width:${props => props.width};
//         height:100%;
//         padding:${props => props.padding};
//         margin:${props => props.margin};

//         position:fixed;
//         overflow:visible;

//         transform:${props => props.transform};

//     }




// `;







//######################################################
//######################################################

// TM: 


// const animateMain = (props) => keyframes`

//     from {
//         opacity: ${props.opacityFrom};
//     }

//     to {
//         opacity: ${props.opacityTo};
//     }


// `;

// export const AnimaMain = styled.div.attrs(props => ({

//     backgroundColor:    props.backgroundColor || "transparent",

//     width:              props.width || "calc(100% - 0px)",
//     height:             props.height || "auto",
//     margin:             props.margin || "0 0 0 0",
    
    
//     fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
//     fontSize:           props.fontSize || "40px",
//     fontWeight:         props.fontWeight  || "550",
//     textAlign:          props.textAlign || "left",
//     textDecoration:     props.textDecoration  || "none",
//     color:              props.color || "black",
    
    

//     //animation variables

//     out:                    props.out || false,
//     animationTime:          props.animationTime || '0.4s',
//     transitionTime:         props.transitionTime || '0.4s',

//     opacity:                props.opacity || "1.0",
//     // marginLeft:             props.marginLeft || "700px"





// }))`
  


//     background-color:${props => props.backgroundColor};

//     width:${props => props.width};
//     height:${props => props.height};
//     min-height:400px;

//     padding:0 0 0 0;
//     margin:${props => props.margin};

//     position:relative;
//     float:left;

//     overflow:hidden;
//     opacity:1.0;



//     .Title {

//         background-color:transparent;

//         width:100%;
//         height:auto;
//         padding:10px 0 10px 10px;
//         margin:0 0 0 0;

//         position:relative;
//         float:left;

//         font-family:${props => props.fontFamily};
//         font-size:${props => props.fontSize};
//         font-weight:${props => props.fontWeight};
//         text-align:${props => props.textAlign};
//         text-decoration:${props => props.textDecoration};
//         color:${props => props.color};

//     }



//     display: inline-block;
//     visibility: ${props => props.out ? 'hidden' : 'visible'};
//     transition: visibility ${props => props.transitionTime} linear;
//     animation: ${props => props.out
//         ? 
//             animateMain({

//                 opacityFrom:props.opacity,
//                 opacityTo:props.opacity,

//             }) 

//         :null    
//         } ${props => props.animationTime} linear;




// `;






