import React, { 

    // useState, 
    // useEffect, 
    // useRef 

} from 'react';


import { Auth } from "utils/components/Pages/AuthLogo";

const Index = (props) => {


    return (

        <Auth
        
            primeProps={props.primeProps}
        
        />

    )


}

export default Index;


