import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';

import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'


import { 

    BarChart,

    //Title,
    Content,
    List,
    Markers,
    Lines,
    Bars,
  
    // VerticalLine,
    // HorizontalLine

    FlexVerticalContainer


} from './Styled';



import { 

    handleCreateLines,
    handleCreateBars,
    handleCreateListItems,
    handleCreateMarkers

} from './Handles';






export const HrzBarChart = (props) => {


    // console.log("HrzBarChart")
    // console.log(JSON.stringify(props,null,2));


    // -- window dims -- //
    const { 
        width,
        //height 
    } = useWindowDimensions();


    //=============================================
    // refs
    //=============================================

    // -- ref -- //
    const refChart = useRef(null);
    //const refTitle = useRef(null);
    const refContent = useRef(null);
    const refList = useRef(null);    
    const refLines = useRef(null);
    const refBars = useRef(null);
 
    
    // const refSide = useRef(null);
    // const refMain= useRef(null);


    // const [rowRefs] = useState([]);
    // const setRowRef = (ref) => {

    //     if(ref && ref !== null && ref !== undefined)
    //     {
    //         //console.log("ref::::"+ref.id);
    //         var found;
    //         found = false;
    //         for(var i=0; i < rowRefs.length; i++)
    //         {
    //             if(rowRefs[i].id === ref.id) found = true;
    //         }
    //         if(!found) rowRefs.push(ref);
    //         //console.log(boxRefs.length);

    //     }

    // };


    //=============================================
    // state/effect - isLoaded
    //=============================================

    const [isLoaded, setIsLoaded] = useState(false);
    const [currentWidth, setCurrentWidth] = useState(false);  
    const [data, setData] = useState(false);
    const [items, setItems] = useState([]);



    useEffect(() => {

        if(!isLoaded)
        {
            setIsLoaded(true);
        }else{


            var array;
            var number;
            var i;
            var count = 0;
            
            array = props.data;

            var minHeight = 0;//default 230
            var listWidth = 0;//default 125

            if(Object.prototype.toString.call(array) === '[object Array]')
            {
                number = array.length;
            }else{
                number = 0;
            }

            var maxBarHeight = 40;
            var letterCount, newLetterCount;
            var text,charlen;


            if(!currentWidth || width !== currentWidth)
            {
    
                setCurrentWidth(width);

                var showLines = true;
                if(!props.chartLines) showLines = props.chartLines;


                //console.log("props"+JSON.stringify(props,null,2));

                var contentHeight = refContent.current.offsetHeight;

            
                //------ 

                var linesVrtGap = 50;
                var linesVertical = refLines.current.offsetWidth / linesVrtGap;

                var linesHrzGap = 50;
                var linesHorizontal = refLines.current.offsetHeight / linesHrzGap;

        

                //-------

                var barsVrtGap = 10;
                var barsVertical = refBars.current.offsetWidth / barsVrtGap;

                var barsHrzGap = 10;
                var barsHorizontal = refBars.current.offsetHeight / barsHrzGap;



                //--------

                for(i=0; i < number; i++)
                {

                    minHeight = minHeight + (maxBarHeight + barsHrzGap);

                    letterCount = array[i].name.length;

                    //alert(letterCount+" > "+count);
                    if(letterCount > count)
                    {
                        charlen = 16;
                        if(letterCount > charlen)
                        {
                            count = letterCount;
                            newLetterCount = (charlen+2) + 5;
                            listWidth = newLetterCount * 10.5

                            text = array[i].name;             
                            text = text.substring(0,charlen);    
                            text = text+"..";


                            array[i].name = text;


                        }else{

                            count = letterCount;
                            newLetterCount = parseInt(letterCount) + 5;
                            listWidth = newLetterCount * 10.5;
                        }

                    }

                }
                if(minHeight === 0) minHeight = 230;
                if(listWidth === 0) listWidth = 125;
                
                //console.log("minHeight: "+minHeight);
                // console.log("listWidth: "+listWidth);
                // alert(listWidth)



                //------

                var linesBorder = 0;
                if(showLines) linesBorder = "1px solid lightgray";

                var linesWidth = refContent.current.offsetWidth - (listWidth + 30);
                //var linesWidth = refLines.current.offsetWidth;
                var linesHeight = refLines.current.offsetHeight;
                var linesCount = 10;
            
        
                var barsWidth = refContent.current.offsetWidth - (listWidth + 30);
                var barsHeight = refBars.current.offsetHeight;


                var abLeft = (listWidth + 20) + "px";

            
                setData({


                    minHeight:minHeight,

                    contentHeight:contentHeight,

                    listWidth:listWidth,


                    abLeft:abLeft,


                    showLines:showLines,
                    linesBorder:linesBorder,
                    linesWidth:linesWidth,
                    linesHeight:linesHeight,
                    linesCount:linesCount,

                    barsWidth:barsWidth,
                    barsHeight:barsHeight,
             

                    linesHrzGap:linesHrzGap,
                    linesHorizontal:linesHorizontal,

                    linesVrtGap:linesVrtGap, 
                    linesVertical:linesVertical,


                    barsHrzGap:barsHrzGap,
                    barsHorizontal:barsHorizontal,

                    barsVrtGap:barsVrtGap, 
                    barsVertical:barsVertical,


                });

            }



            setItems(array);



        }


    },
    [
        props,

        width,currentWidth,


        isLoaded,
        //data,

        refChart,
        refContent,
        refList,
        refLines
    ])




    if(!isLoaded)
    {

        return(

            <div></div>
        )


    }else{


        return (

            <div>

                <BarChart
                ref={refChart}
                >

                    {/* <Title
                    ref={refTitle}
                    >
                    </Title> */}

                    <Content
                    ref={refContent}
                    >

                        <List
                        ref={refList}
                        width={data.listWidth+"px"}
                        height={data.minHeight+"px"}
                        //backgroundColor={'lightyellow'}
                        >

                            {handleCreateListItems({

                                maxHeight:data.minHeight,
                                array:items,
                                gap:data.barsHrzGap

                            })}
    

                        </List>


                        <Bars
                        ref={refBars}
                        left={data.abLeft}
                        //backgroundColor={'lightblue'}
                        width={data.barsWidth+"px"}
                        height={data.minHeight+"px"}
                        >

                            <div>
                                {handleCreateBars({

                                    maxHeight:data.minHeight,
                                    array:props.data,
                                    type:"horizontal",
                                    gap:data.barsHrzGap,
                                 
                                })}
                            </div>


                        </Bars>


                        <Lines
                        ref={refLines}
                        borderRight={data.linesBorder}
                        left={data.abLeft}
                        width={data.linesWidth+"px"}
                        height={data.minHeight+"px"}
                        >
                            {data.showLines
                            ?

                                <div>
                                    {handleCreateLines({

                                        maxWidth:data.linesWidth,
                                        number:data.linesCount,
                                        type:"vertical"

                                    })}
                                </div> 


                            :null}

                        </Lines>


                
                    </Content>

                </BarChart>


            </div>


        )    

    }





}












export const VrtBarChart = (props) => {

    // console.log("VrtBarChart");
    // console.log(JSON.stringify(props,null,2));


    // -- window dims -- //
    const { 
        width,
        //height 
    } = useWindowDimensions();


    //=============================================
    // refs
    //=============================================

    // -- ref -- //
    const refChart = useRef(null);
    //const refTitle = useRef(null);
    const refContent = useRef(null);
    const refMarkers = useRef(null);    
    const refLines = useRef(null);
    const refBars = useRef(null);


    // const [rowRefs] = useState([]);
    // const setRowRef = (ref) => {

    //     if(ref && ref !== null && ref !== undefined)
    //     {
    //         //console.log("ref::::"+ref.id);
    //         var found;
    //         found = false;
    //         for(var i=0; i < rowRefs.length; i++)
    //         {
    //             if(rowRefs[i].id === ref.id) found = true;
    //         }
    //         if(!found) rowRefs.push(ref);
    //         //console.log(boxRefs.length);

    //     }

    // };

    //=============================================
    // state/effect - isLoaded
    //=============================================

    const [isLoaded, setIsLoaded] = useState(false);
    const [currentWidth, setCurrentWidth] = useState(false);  
    const [data, setData] = useState(false);
    const [items, setItems] = useState([]);



    useEffect(() => {

        const effResize = () => {

            var chartHeight = 200;

            var difW = 12;
            //var difH = (refMarkers.current.offsetHeight - refLines.current.offsetHeight);
            var difH = 20;

            var contentWidth = refContent.current.offsetWidth;
            var markersWidth = refMarkers.current.offsetWidth;
            //var markersHeight = refMarkers.current.offsetHeight;

            var markersHeight = chartHeight + 20;

            var linesWidth = "calc(98% - "+(markersWidth+difW)+"px)";
            var linesHeight = (markersHeight - difH);
   
            var barsWidth = "calc(98% - "+(markersWidth+difW)+"px)";
            var barsHeight = (markersHeight - difH);


            var abTop = difH/2 + "px";
            var abLeft = (markersWidth+difW) + "px";

            //------ 

            var linesVrtGap = 50;
            var linesVertical = refLines.current.offsetWidth / linesVrtGap;

            var linesHrzGap = 50;
            var linesHorizontal = refLines.current.offsetHeight / linesHrzGap;


            //-------

            var barsVrtGap = 10;
            var barsVertical = refBars.current.offsetWidth / barsVrtGap;

            var barsHrzGap = 10;
            var barsHorizontal = refBars.current.offsetHeight / barsHrzGap;



            var markers = [

                {
                    name:'100',
                    value:"100"
                },

                {
                    name:'80',
                    value:"80"
               
                },


                {
                    name:'60',
                    value:"60"
                },

                {
                    name:'40',
                    value:"40"
               
                },


                {
                    name:'20',
                    value:"20"
                },

                {
                    name:'0',
                    value:"0"
               
                }

            ]





            setItems(props.data);

            setData({

                chartHeight:chartHeight,

                contentWidth:contentWidth,

                markers:markers,
                markersWidth:markersWidth,
                markersHeight:markersHeight,

                linesWidth:linesWidth,
                linesHeight:linesHeight,
            
                barsWidth:barsWidth,
                barsHeight:barsHeight,


                abLeft:abLeft,
                abTop:abTop,


                linesHrzGap:linesHrzGap,
                linesHorizontal:linesHorizontal,

                linesVrtGap:linesVrtGap, 
                linesVertical:linesVertical,


                barsHrzGap:barsHrzGap,
                barsHorizontal:barsHorizontal,

                barsVrtGap:barsVrtGap, 
                barsVertical:barsVertical,


            });


            setCurrentWidth(width);


        }



        if(!isLoaded)
        {
            setIsLoaded(true);
        }else{
  
            if(!currentWidth || width !== currentWidth)
            {
                effResize();
            }        
        
        }

    },
    [
        props,
        width,currentWidth,
        isLoaded,data,
        refChart,
        refMarkers
    ])






    if(!isLoaded)
    {

        return(

            <div></div>
        )


    }else{


        return (

            <div>

                <BarChart
                ref={refChart}
                >

                    {/* <Title
                    ref={refTitle}
                    >
                    </Title> */}

                    <Content
                    ref={refContent}
                    //width={"100%"}
                    >


                        <Markers
                        ref={refMarkers}
                        //backgroundColor={"black"}
                        height={data.markersHeight+"px"}
                        >

                            {handleCreateMarkers({

                                maxHeight:data.chartHeight,
                                array:data.markers,
                                gap:0

                        
                            })}
    

                        </Markers>


                        <Lines
                        ref={refLines}
                        width={data.linesWidth}
                        height={data.linesHeight+"px"}
                        borderTop={"1px solid lightgray"}
                        top={data.abTop}
                        left={data.abLeft}
                        >

                            <div>
                                {handleCreateLines({

                                    maxWidth:data.linesWidth,
                                    maxHeight:data.linesHeight,
                                    type:"horizontal",
                                    number:5

                                })}
                            </div> 


                        </Lines>



                        <Bars
                        ref={refBars}
                        width={data.barsWidth}
                        height={data.barsHeight+"px"}
                        left={data.abLeft}
                        top={data.abTop}
                        >

                            {/* <div>
                                {handleCreateBars({

                                    maxHeight:data.barsHeight,
                                    array:props.data,
                                    type:"horizontal",
                                    gap:data.barsHrzGap

                                })}
                            </div> */}


                    
                            <FlexVerticalContainer
                            height={data.barsHeight+"px"}
                            >

                                {handleCreateBars({

                                    maxWidth:data.barsWidth,
                                    maxHeight:0,
                                    array:items,
                                    type:"flex-vertical",
                                    gap:data.barsVrtGap

                                })}

                            </FlexVerticalContainer>
                            
                        </Bars>


                
                    </Content>


                </BarChart>



            </div>


        )    

    }




    
}




/*

{[...Array(10)].map((e, i) => {
    
})}


*/

// {Array.from(Array(10), (e, i) => {
    
// })}



// let linesHorizontal = Array.apply(null, {length: 4}).map(Number.call, Number);
// let linesVertical = Array.apply(null, {length: 5}).map(Number.call, Number);

