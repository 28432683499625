import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';



import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    //useHistory,   
    //useParams,


} from "react-router-dom";


// import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider';
// import useScreenOrientation from 'utils/hooks/ScreenOrientation';
// //import { useFetch }  from 'utils/hooks/Fetch';


import { Area } from 'utils/components/Pages/Styled';
import {

    GutterGrid as Grid,
    GutterCel as Cell,
    GutterCelCenter as Center,
    //GutterCelGraphic as Graphic,
    GutterCelTitle as Title,
    GutterCelName as Name,
    GutterCelDescr as Descr


 
} from './Styled/GutterGrid';





const Index = (props) => {



    // // -- window dims -- //
    // const { width, height } = useWindowDimensions();

    // // -- screen orientation -- //
    // const screenOrientation = useScreenOrientation()



    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();


    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);




    // let zoneId;
    // zoneId = pathname.replace(/\//gi,"-");


    // -- redux -- //
    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));



    // let currentZone = pathname;
    // currentZone = currentZone.replace(/\//gi,"-");
    // currentZone = currentZone.replace(/ /gi,"-");

    // //let zoneKey = currentZone + "_fetch";
    

    //------------------------------------------------------------------------------

    const refArea = useRef(null);
    const refContainer = useRef(null);


    // -- state -- //

    const [isLoaded, setIsLoaded] = useState(false);
    //const [databind, setDatabind] = useState(false);
    const [dataArray, setDataArray] = useState([]);


    useEffect(() => {

        if(!isLoaded)
        {
            setIsLoaded(true);
            setDataArray([

                {
                    call:'calc',
                    title:"Calc",
                    name:"Calculator",
                    descr:"Sign Up for just the calculator"

                },
                {
                    call:'app',
                    title:"App",
                    name:"App",
                    descr:"Sign Up for the application"

                }

            ]);
 
        }

        // if(!databind)
        // {
        //     setDatabind(props.databind);
        // }


        // if(props.databind.sceneArr.length > 0)
        // {


        //     console.log("AreaEffect --- props.databind.sceneArr: "+JSON.stringify(props.databind.sceneArr,null,2));
  

        // }



  

    },
    [
        props,
        isLoaded,
        //databind,
        zoneObj

    ])

    




    return (<div>

        <Area
        ref={refArea}
        backgroundColor={'#191e28'}
        //height={"100vh"}
        >
            <Grid
            ref={refContainer} 
            >

                {
                dataArray.map((obj, i) => (


                    <Cell
                    key={i}
                    onClick={(v) => props.handle({

                        call:"makeChoice",
                        data:{
                            hash:props.sections[0],
                            choice:obj.call
                        }

                    })}
                    >

                        <Center>
                            <Title>{obj.title}</Title>
                            <Name>{obj.name}</Name>
                            <Descr>{obj.descr}</Descr>
                        </Center>


                    </Cell>


                ))
                }


            </Grid>

        </Area>

    </div>)


}

export default Index;


