// import { 
//     useSelector, 
//     //useDispatch 
// } 
// from 'react-redux';
// //import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';



// import { Settings } from 'app-components/Prime/Data.js';
// import ApiResources from 'utils/components/Pages/ApiResources';




import { 
    LibSystem
} from 'library';



const SceneInfo = (props) => {


    var R = [];
    //var index;



    var top;
    var bar;   
    var catalogItems;

    // var accountName;
    // var accountValue;



    //#############################################################
    //#############################################################

    top = {

        call:'top',
        name:"Knowledge Base",
        controls:[

            // {

            //     name:"Go Back",
            //     // form:{

            //     //     submitCall:'account',
            //     //     postTo:apiDomain+apiCall,
            //     //     data:VPaccount.company,
            //     //     flyr:{
            //     //         type:"form",
            //     //         styl:{
            //     //             maxWidth:475,
            //     //             fieldType:'field'
            //     //         }
            //     //     }
             

            //     // }

            //     hndl:{

            //         call:'readout',
            //         data:false
            //     }

            // }

        ]

    }
    R.push(top);


    bar = {

        call:'bar',
        items: [

            {

                name:"-"

            },

            {

                name:"-"

            }

        ]
        
    }
    R.push(bar);



    var bulletColor = "#454d5f";
    //var bulletColor = "#322a61"; //"#191e28";
    var letterColor = "#fff";

    // console.log("Props: "+JSON.stringify(props.goods,null,2));

    catalogItems = [

        {
                
            heading:"Glossary",
            bullet:{

                backgroundColor:bulletColor,
                textColor:letterColor

            },
            stripe:true,
            pgraph:"View, Edit or Create Custom Calculators",
            image:false,
            button:false,
            data:{

                id:"Glossary",
                name:"Glossary"

            }
        
        }


        // {
                
        //     heading:"Icons",
        //     bullet:{

        //         backgroundColor:bulletColor,
        //         textColor:letterColor

        //     },
        //     stripe:true,
        //     pgraph:'View templates or save templates to "My Calculators".',
        //     image:false,
        //     button:false,
        //     data:{

        //         id:"Icons",
        //         name:"Icons"

        //     }
        
        // }
        
    

        // {
                
        //     heading:"Formulas",
        //     bullet:{

        //         backgroundColor:bulletColor,
        //         textColor:letterColor

        //     },
        //     stripe:true,
        //     pgraph:'View templates or save templates to "My Calculators".',
        //     image:false,
        //     button:false,
        //     data:{

        //         id:"Formulas",
        //         name:"Formulas"

        //     }
        
        // }




    ];




    var keyname,object;
    var pages,page,pageName,pageCaption,pageTo;


    if(localStorage.editMode)
    {//EDIT

        if(props.zoneObj['knowledge_base'] 
        && props.zoneObj['knowledge_base']!== undefined)
        {
            if(props.zoneObj['knowledge_base'].pages 
            && props.zoneObj['knowledge_base'].pages !== undefined)
            {


                pages = props.zoneObj['knowledge_base'].pages;
                //console.log("Overview Pages:........... "+JSON.stringify(pages,null,2));

                //alert('knowledge base pages')

                for(keyname in pages)
                {
                    page = pages[keyname];

                    pageName = page.name;

                    pageName = LibSystem.FileDisplay(pageName);     
                    pageName = LibSystem.RemoveExt(pageName);

                    pageCaption = LibSystem.FileDisplay(page.caption);


                    catalogItems.push({
                    
                        heading:pageName,
                        bullet:{
            
                            backgroundColor:bulletColor,
                            textColor:letterColor
            
                        },
                        stripe:true,
                        pgraph:pageCaption,
                        image:false,
                        button:false,
                        data:{
            
                            id:page.tree,
                            name:pageName
            
                        }
                    
                    });

                }

        
            }

        }



    }
    else
    {//READ



        if(props.zoneObj['knowledge_base'] 
        && props.zoneObj['knowledge_base']!== undefined)
        {

            // console.log(JSON.stringify(props.zoneObj.knowledge_base,null,2));
            // alert("SceneInfo");


            var scene,navScene,si;

            if(props.zoneObj['knowledge_base'].scene 
            && props.zoneObj['knowledge_base'].scene !== undefined)
            {
                //alert('has scene');

                scene = props.zoneObj['knowledge_base'].scene;

                switch(Object.prototype.toString.call(scene))
                {
                case'[object Array]':

                    for(object of scene)
                    {
                        if(object.currentScene.sceneCompName.match(/Navigator/gi))
                        {
                            navScene = object;
                            //console.log(JSON.stringify(navScene,null,2));
                            break;
                        }
                      
                    }

                break;
                default:
                    navScene = scene;
                }


                if(navScene.sceneInfo 
                && navScene.sceneInfo !== undefined)
                {

                    //alert('has sceneInfo')

                    si = navScene.sceneInfo;
                    if(si[0].items 
                    && si[0].items !== undefined
                    //&& Object.prototype.toString.call(props.zoneObj['knowledge_base'].scene.sceneInfo.items) === '[object Array]'
                    )
                    {

                        //console.log(JSON.stringify(props.zoneObj.knowledge_base.scene.sceneInfo[0].items,null,2));
                        //alert("has items");

                        // [
                        //     {
                        //       "name": "Introduction",
                        //       "to": "KnowledgeBase/Introduction",
                        //       "descr": "Intro to KnowledgeBase",
                        //       "category": "Knowledge Base"
                        //     },
                        //     {
                        //       "name": "Overhead",
                        //       "to": "KnowledgeBase/Overhead",
                        //       "descr": "Learn about Overhead",
                        //       "category": "Knowledge Base"
                        //     },
                        //     {
                        //       "name": "Cost Of Goods",
                        //       "to": "KnowledgeBase/CostOfGoods",
                        //       "descr": "Learn about CostOfGoods",
                        //       "category": "Knowledge Base"
                        //     },
                        //     {
                        //       "name": "Labor",
                        //       "to": "KnowledgeBase/Labor",
                        //       "descr": "Learn about Labor",
                        //       "category": "Knowledge Base"
                        //     }
                        // ]

    

                        pages = si[0].items;
                        //console.log("Overview Pages:........... "+JSON.stringify(pages,null,2));

                        //alert('knowledge base')

                        for(page of pages)
                        {
                           
                            pageName = page.name;
                            pageName = LibSystem.FileDisplay(pageName);     
                            pageName = LibSystem.RemoveExt(pageName);

                            pageCaption = LibSystem.FileDisplay(page.descr);

                            pageTo = page.to;
                            pageTo = pageTo.replace(/KnowledgeBase\//gi,"");

                            //alert(pageTo);


                            catalogItems.push({
                            
                                heading:pageName,
                                bullet:{
                    
                                    backgroundColor:bulletColor,
                                    textColor:letterColor
                    
                                },
                                stripe:true,
                                pgraph:pageCaption,
                                image:false,
                                button:false,
                                data:{
                    
                                    id:pageTo,
                                    name:pageTo,
                                    graphic:{

                                        color:"purple",
                                        darklite:"dark"

                                    }
                    
                                }
                            
                            });

                        }
                        
        
                    }
    
    
                }




        
            }

        }






    }//editMode










    R.push({
        call:"items",
        items:catalogItems
    });



    // R.push([top,bar,{

    //     call:"items",
    //     items:catalogItems
    
    // }]);


    return R;

}



export default SceneInfo;
