
//#################################################################
//#################################################################
// MODULE
module.exports = {

	




	//==========================================================
	// timestamp


	Timestamp: function(_CASE) {


		var R = '';

			function pad(n){
				return n < 10 ? "0" + n : n
			};
	
			var d=new Date();
			var dash = "-";
			var colon = ":";
			var eastern_timezone = 4;//minus from hours
			//var western_timezone = 7;//minus from hours


			if(_CASE === 'ymd')
			{

					
				R = d.getFullYear()+dash+
					pad(d.getMonth()+1)+dash+
					pad(d.getDate());

				return R;

			}
			else
			if(_CASE === 'format')
			{

				R = d.getFullYear()+dash+
					pad(d.getMonth()+1)+dash+
					pad(d.getDate())+" "+
					pad(d.getHours())+colon+
					pad(d.getMinutes())+colon+
					pad(d.getSeconds());


				return R;

			}
			else
			if(_CASE === 'noformat')
			{

				R = d.getFullYear()+dash+
				pad(d.getMonth()+1)+dash+
				pad(d.getDate())+dash+
				pad(d.getHours())+dash+
				pad(d.getMinutes())+dash+
				pad(d.getSeconds());


				R = R.toString();
				R = R.replace(/-/g,'');
				R = parseInt(R);

				return R;

			}
			else
			if(_CASE === 'noformat_to_hour')
			{


				R = d.getFullYear()+dash+
					pad(d.getMonth()+1)+dash+
					pad(d.getDate())+dash+
					pad(d.getHours());

				R = R.toString();
				R = R.replace(/-/g,'');
				R = parseInt(R);

				return R;


			}
			else
			if(_CASE === 'format_utc')
			{


				R = d.getUTCFullYear()+dash+
					pad(d.getUTCMonth()+1)+dash+
					pad(d.getUTCDate())+" "+
					pad(d.getUTCHours())+colon+
					pad(d.getUTCMinutes())+colon+
					pad(d.getUTCSeconds());

				return R;


			}
			else
			if(_CASE === 'noformat_utc')
			{


				R = d.getUTCFullYear()+dash+
					pad(d.getUTCMonth()+1)+dash+
					pad(d.getUTCDate())+dash+
					pad(d.getUTCHours())+dash+
					pad(d.getUTCMinutes())+dash+
					pad(d.getUTCSeconds());


				R = R.toString();
				R = R.replace(/-/g,'');
				R = parseInt(R);

				return R;



			}
			else
//===============================================================================






//eastern






//===============================================================================
			if(_CASE === 'format_eastern')
			{


				R = d.getFullYear()+dash+
					pad(d.getMonth()+1)+dash+
					pad(d.getDate())+" "+
					pad(d.getHours() - eastern_timezone )+colon+
					pad(d.getMinutes())+colon+
					pad(d.getSeconds());


				return R;



			}
			else
			if(_CASE === 'noformat_eastern')
			{


				
				R = d.getFullYear()+dash+
					pad(d.getMonth()+1)+dash+
					pad(d.getDate())+dash+
					pad(d.getHours() - eastern_timezone )+dash+
					pad(d.getMinutes())+dash+
					pad(d.getSeconds());

				R = R.toString();
				R = R.replace(/-/g,'');
				R = parseInt(R);

				return R;



			}
			else
			if(_CASE === 'noformat_to_hour_eastern')
			{
	
	

				R = d.getFullYear()+dash+
					pad(d.getMonth()+1)+dash+
					pad(d.getDate())+dash+
					pad(d.getHours() - eastern_timezone );


				R = R.toString();
				R = R.replace(/-/g,'');
				R = parseInt(R);

				return R;

	
			}
			else
			if(_CASE === 'format_utc_eastern')
			{
	
				R = d.getUTCFullYear()+dash+
					pad(d.getUTCMonth()+1)+dash+
					pad(d.getUTCDate())+" "+
					pad(d.getUTCHours() - eastern_timezone )+colon+
					pad(d.getUTCMinutes())+colon+
					pad(d.getUTCSeconds());
	

				return R;



			}
			else
			if(_CASE === 'noformat_utc_eastern')
			{
	

				R = d.getUTCFullYear()+dash+
					pad(d.getUTCMonth()+1)+dash+
					pad(d.getUTCDate())+dash+
					pad(d.getUTCHours() - eastern_timezone )+dash+
					pad(d.getUTCMinutes())+dash+
					pad(d.getUTCSeconds());



				R = R.toString();
				R = R.replace(/-/g,'');
				R = parseInt(R);


				return R;




			}
			else
			{
//================================================================================





//default





//================================================================================
		

				R = d.getFullYear()+dash+
					pad(d.getMonth()+1)+dash+
					pad(d.getDate())+dash+
					pad(d.getHours())+dash+
					pad(d.getMinutes())+dash+
					pad(d.getSeconds());

				R = R.toString();
				R = R.replace(/-/g,'');
				R = parseInt(R);


				return R;


		
			}
		
	

			//(change "get.." to "getUTC.." if UTC time is desired)
		


	},//func
	//========================================================================











	//========================================================================
	// format date

	FormatDate: function(str,format,abbr) {

		
		var r = false;

		var arr;

		var full_format;
		var abbr_format;

		var time;
		var timezone;
		var weekday;
		var day;
		var month;
		var year;

		var i;


		
			//########################################################
		
			var date = new Date();
			if(str !== '') date = new Date(str);//could be blank
			
			
			var full_months = [
							"January", "February", "March", "April", 
							"May", "June", "July", "August", 
							"September", "October", "November", "December"
							];
		
			var abbr_months = [
							"Jan", "Feb", "Mar", "Apr", 
							"May", "June", "July", "Aug", 
							"Sept", "Oct", "Nov", "Dec"
							];
		
			var numb_months = [
							"1", "2", "3", "4", 
							"5", "6", "7", "8", 
							"9", "10", "11", "12"
							];
		
		
			var full_days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
		
			var abbr_days = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
		
								 
			//###########################################################
		
			switch(format)
			{
			case'raw':
		
				format = str;
				r = format;
		
		
			break;
			case'dmdy':
				
				full_format = (full_days[date.getDay()] + ', ' +
									full_months[date.getMonth()] + ' ' +
									date.getDate() + ', ' +
									(date.getYear() < 200 ? date.getYear() + 1900 : date.getYear()));
		
		
				abbr_format = (abbr_days[date.getDay()] + ', ' +
									abbr_months[date.getMonth()] + ' ' +
									date.getDate() + ', ' +
									(date.getYear() < 200 ? date.getYear() + 1900 : date.getYear()));
			
				
				r = full_format;
				if(abbr) 
				{
					r = abbr_format;
				}//##
		
			break;
			case'slash':
		
				format = numb_months[date.getMonth()]  + "/" + (date.getDate()) + "/" + date.getFullYear();
				r = format;
		
			break;
			case'text':
				
				full_format = full_months[date.getMonth()] + " " + (date.getDate()) + " " + date.getFullYear();
				abbr_format = abbr_months[date.getMonth()] + " " + (date.getDate()) + " " + date.getFullYear();
		
				r = full_format;
				if(abbr) 
				{
					r = abbr_format;
				}//##
		
			break;
			case'weekday':
		
			
				full_format = full_days[date.getDay()];
				abbr_format = abbr_days[date.getDay()];
		
				r = full_format;
				if(abbr) 
				{
					r = abbr_format;
				}//##
		
			
			break;
			case'month':
		
			
				full_format = full_months[date.getMonth()];
				abbr_format = abbr_months[date.getMonth()];
		
				r = full_format;
				if(abbr) 
				{
					r = abbr_format;
				}//##
		
			
			break;
			case'year':
		
				r = date.getFullYear();
		
			break;
			case'time':
		
					
				str = str.toString();
				arr = str.split(" ");
		
				r = arr[4];
		
		/*
				for(key in arr)
				{
					if(key < arr.length - 1) 
						alrt += "\n"+key+": "+arr[key];
			
				}//###
		
		*/
		
			break;
			case'sysid':


				/**************


				getDay() - Returns the day of the week (0-6). 0 is Sunday, 6 is Saturday.
				getHours() - Returns the hour of the day (0-23).
				getMinutes() - Returns the minute (0-59).
				getSeconds() - Returns the second (0-59).
				getMilliseconds()

				*************/

				r = date.getFullYear()  + date.getDate();
				r += numb_months[date.getMonth()];
				r +=  date.getDay();
				r += date.getHours(); 
				r += date.getMinutes(); 
				r += date.getSeconds();







			break;
			case'rss':
		
		
				//###############
				//DAY AND DATE
				format = (abbr_days[date.getDay()] + ', ' +
							  date.getDate() + ' ' +
							  abbr_months[date.getMonth()] + ' ' +
							 (date.getYear() < 200 ? date.getYear() + 1900 : date.getYear()));
		
				//###############
				//TIME
				str = str.toString();
				arr = str.split(" ");
				time = arr[4];
		
		
				//#############################################
		
				//PHP ref $pubDate= date("D, d M Y H:i:s T", strtotime($pubDate));
		
				//http://stackoverflow.com/questions/11993258/what-is-the-correct-format-for-rss-feed-pubdate
		
				/*
				Change the date-time to contain a four digit year,no comments, 
				and either US or numeric timezone indicators, all single spaced. Here are examples:
				
				<pubDate>Wed, 02 Oct 2002 08:00:00 EST</pubDate>
				
				<pubDate>Wed, 02 Oct 2002 13:00:00 GMT</pubDate>
				
				<pubDate>Wed, 02 Oct 2002 15:00:00 +0200</pubDate>
				*/
				
				timezone = "GMT";
		
				//##############################################
		
		
				r = format+" "+time+" "+timezone;
			
			break;
			case'rss_split':
		
				
				str = str.toString();
				arr = str.split(" ");
		
				//#########################
		
				weekday = arr[0];
				day = arr[1];
				month = arr[2];
				year = arr[3];
				time = arr[4];
				timezone = arr[5];
		
				//#########################################
		
				weekday = weekday.replace(/,/g,"");
		
				//########################################
		
				if(!abbr)
				{
					//###################################
					//WEEKDAY 
		
					var windx = 'na';
					for(i=0; i < abbr_days.length; i++)
					{
						if(weekday === abbr_days[i])
						{
							windx = i;
							break;
						}//if
			
					}//###
		
					if(windx !== 'na')
					{
						for(i=0; i < full_days.length; i++)
						{
							if(windx === i)
							{
								weekday = full_days[i];
								break;
							}//if
				
						}//###
		
					}//###
		
					//###################################
		
					//###################################
					//MONTH
		
					var mindx = 'na';
					for(i=0; i < abbr_months.length; i++)
					{
						if(month === abbr_months[i])
						{
							mindx = i;
							break;
						}//if
			
					}//###
		
					if(mindx !== 'na')
					{
						for(i=0; i < full_months.length; i++)
						{
							if(mindx === i)
							{
								month = full_months[i];
								break;
							}//if
				
						}//###
		
					}//###
		
					//####################################
		
				}//if
				//###
		
		
				//########################################
				
				r = {
				'weekday':weekday,
				'day':day,
				'month':month,
				'year':year,
				'time':time,
				'timezone':timezone
				};
		
			break;
			case'rss_to_numbers':
		
				//DATE : Sun, 23 Oct 2015
				//TIME : 07:32:17
				//TIMEZONE : GMT
				//STR : Sun, 23 Oct 2015 07:32:17 GMT
		
		
				//###################
				//SET
		
				arr = str.split(" ");
		
				var wstr = arr[0];
				var dnum = arr[1];
				var mstr = arr[2];
				var ynum = arr[3];
				var tstr = arr[4];
				//var zstr = arr[5];
		
				var tarr = tstr.split(":");
		
				var h = tarr[0];
				var m = tarr[1];
				var s = tarr[2];
		
		
				//###################
				//CONSTRUCT
		
				year = ynum;
		
				month = 0;
				for(i=0; i < abbr_months.length; i++)
				{
					if(mstr === abbr_months[i])
					{
						month = i+1;
						break;
					}//if
		
				}//###
		
		
				day = dnum.replace(/,/g,"");
		
		
				wstr = wstr.replace(/,/g,"");
				weekday = 0;
				for(i=0; i < abbr_days.length; i++)
				{
					if(wstr === abbr_days[i])
					{
						weekday = i+1;
						break;
					}//if
		
				}//###
		
		
		
		/*
			
				var hr = h.substring(0, 1);
				if(parseInt(hr) == 0) 	
				{
					var h =  hr.substring(1, 2);
				}//if
		
		
		
				var sec = s.substring(0, 1);
				if(parseInt(sec) == 0)
				{ 
					var s =  sec.substring(1, 2);
		
				}//if
		*/
		
		
		
		
		
				//###################
				//FORMAT
		
				format = {
				"year":year,
				"month":month,
				"day":day,
				"weekday":weekday,
				"h":h,
				"m":m,
				"s":s
				};
		
		
				r = format;
		
		
			break;
			default:
			}//##
		
			//############################################################
		
		
		
		return r;



	}//func
	//========================================================================





}//####





//======================================================================


			/**************************

				getFullYear() - Returns the 4-digit year
				getMonth() - Returns a zero-based integer (0-11) representing the month of the year.
				getDate() - Returns the day of the month (1-31).
				getDay() - Returns the day of the week (0-6). 0 is Sunday, 6 is Saturday.
				getHours() - Returns the hour of the day (0-23).
				getMinutes() - Returns the minute (0-59).
				getSeconds() - Returns the second (0-59).
				getMilliseconds()


				Date.now()

			**************************/

