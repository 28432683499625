import React, { 

    useState, 
    useEffect, 
    //useRef 

} from 'react';




import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    //useHistory,   
    //useParams,



} from "react-router-dom";

// import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'



import { createPortal } from 'react-dom';
import usePortal from 'utils/hooks/Portal';






// import Navigator from 'utils/components/Content/Navigator';
// import SideNavInfo from 'utils/components/Pages/SceneInfo/Trials/SideNav/SceneInfo';




//=====================================================


import {

    Alfa,
    Base,
    Menu

} from './Styled';





const Index = (props) => {



    const portalTarget = usePortal("stage");


    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();

    
 
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);



    // -- window dims -- //
    // const { 
    //     width,
    //     height 
    // } = useWindowDimensions();



    // -- redux -- //
    // const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);



    //=============================================
    // ref
    //=============================================

    //const refForm = useRef(null);

    /*
    const rowRefs = [];
    const setRowRef = (ref) => {

        if(ref && ref !== null && ref !== undefined)
        {
            //console.log("ref::::"+ref.id);
            var found;
            found = false;
            for(var i=0; i < rowRefs.length; i++)
            {
                if(rowRefs[i].id === ref.id) found = true;
            }
            if(!found) rowRefs.push(ref);
            console.log(rowRefs.length);
            console.log(rowRefs[0]);

        }

    };
    */



    //=============================================
    // state/effect - laborData
    //=============================================

    const [isLoaded, setIsLoaded] = useState(false);
    const[laborData, setLaborData] = useState([]);
    useEffect(() => {


        if(!isLoaded)
        {
            setIsLoaded(true);
        
  
            //let obj = false;
            if(zoneObj.calc_dash && laborData.length === 0)
            {
                var resultItems = JSON.parse(JSON.stringify(zoneObj.calc_dash.result_items));
                // console.log("resultItems: "+JSON.stringify(resultItems,null,2));


                var newLaborData;

                var highest = 0;
                var lowest = 9999999;
                
                var ownrs = [];
                var empls = [];

                var keyname;
                var item;

                var matchOwnr = new RegExp("ownr-","gi");
                var matchEmpl = new RegExp("empl-","gi");


                for(keyname in resultItems)
                {
                    item = resultItems[keyname];
                    if(keyname.match(matchOwnr))
                    {

                        // if(item.value.ownr_sti_hourly_direct
                        // && item.value.ownr_sti_hourly_direct !== null
                        // )
                        // {
                        //     switch(item.value.ownr_flag_case)
                        //     {
                        //     case'greater':
                        //     case'even':

                        //         ownrs.push({

                        //             id:keyname,
                        //             name:item.name,
                        //             value:item.value.ownr_sti_hourly_direct

                        //         });

                        //     break;
                        //     default:
                        //     }

                        // }


                        if(item.value.ownr_sti_hourly_direct
                        && item.value.ownr_sti_hourly_direct !== null
                        )
                        {
                            if(parseFloat(item.value.ownr_sti_hourly_direct) > 0)
                            {

                                if(highest < item.value.ownr_sti_hourly_direct)
                                {
                                    highest = item.value.ownr_sti_hourly_direct;
                                }

                                if(lowest > item.value.ownr_sti_hourly_direct)
                                {
                                    lowest = item.value.ownr_sti_hourly_direct;
                                }


                                ownrs.push({

                                    id:keyname,
                                    name:item.name,
                                    value:item.value.ownr_sti_hourly_direct

                                });

                            }

                        }


                    }

                    if(keyname.match(matchEmpl))
                    {

                        if(parseFloat(item.value.empl_direct_hourly_rate) > 0)
                        {

                            if(highest < item.value.empl_direct_hourly_rate)
                            {
                                highest = item.value.empl_direct_hourly_rate;
                            }

                            if(lowest > item.value.empl_direct_hourly_rate)
                            {
                                lowest = item.value.empl_direct_hourly_rate;
                            }


                            empls.push({

                                id:keyname,
                                name:item.name,
                                value:item.value.empl_direct_hourly_rate
    
                            });

                        }

                    }

                }



                highest = parseFloat(highest).toFixed(2);
                lowest = parseFloat(lowest).toFixed(2);



            
                var rates = [

                    {
                        id:'highest',
                        name:"Highest",
                        value:highest

                    },
                    {

                        id:'lowest',
                        name:"Lowest",
                        value:lowest
                    } 

                ];




                if(rates.length > 0
                && ownrs.length > 0
                && empls.length > 0
                )
                {
                    newLaborData = [
                    
                        {
                            label:"Rates",
                            array:rates
                        },

                        {
                            label:"Owners Rates",
                            array:ownrs
                        },
                        {
                            label:"Employee Rates",
                            array:empls
                        }
                    ];          
                }
                else
                {


                    if(ownrs.length > 0 && empls.length === 0)
                    {

                        newLaborData = [
                    
                            {
                                label:"Rates",
                                array:rates
                            },
    
                            {
                                label:"Owners Rates",
                                array:ownrs
                            }
               
                        ];


                    }
                    else
                    if(ownrs.length === 0 && empls.length > 0)
                    {

                        newLaborData = [
                    
                            {
                                label:"Rates",
                                array:rates
                            },
    
                            {
                                label:"Employee Rates",
                                array:empls
                            }
                        ];


                    }
                    else
                    if(ownrs.length === 0 && empls.length === 0)
                    {

                        newLaborData = [
                    
                            {
                                label:"Rates",
                                array:rates
                            }
         
                        ];

                    }

                }

                setLaborData(newLaborData);



            }   
           
            

        }
 

    },
    [
        location,
        isLoaded,
        zoneObj,  
        laborData
       
    ])










    return createPortal(<div>


        <Alfa zIndex={"13"}></Alfa>
        <Base zIndex={"14"}
        >

            <Base zIndex={"1"}
            onClick={(v) => props.handle({

                call:'showLaborMenu'

            })}
            >
            </Base>

            <Menu zIndex={"2"}>


                <div className="Bar">

                    <div className="Title">


                        Labor Rates


                    </div>

                    <div className="Xbutton"
                    onClick={(v) => props.handle({

                        call:'showLaborMenu'

                    })}
                    >
                        X

                    </div>


                </div>

                <div className="Bod">

                    {laborData.map((dat, i) => (


                        <div key={i}>

                            <div className="Label">

                                {dat.label}

                            </div>
                            <div className="Grid">

                                {dat.array.map((cell, j) => (



                                    props.data.from === 'input'
                                    ?

                                        <div 
                                        key={j}
                                        className="Cell"
                                        // onClick={(v) => props.handle({

                                        //     call:'showLaborMenu'
                                
                                        // })}

                                        onClick={() => props.handle({

                                            call:'edit',
                                            from:'laborMenu',
                                            data :{
                
                                                index:props.data.index,
                                                fieldCall:'costfield',
                                                type:'labor',
                                                id:cell.id,
                                                name:cell.name,
                                                value:cell.value,
                                                scrollpos:props.data.scrollpos
                
                                            }
                
                                        })}

                                        >
                                            <div 
                                            className="Name"
                                            >

                                                {cell.name}
                                                
                                            </div>

                                            <div 
                                            className="Value"
                                            >

                                                {"$"+cell.value}

                                            </div>

                                        </div>



                                    :


                                        <div 
                                        key={j}
                                        className="Cell"
                                        // onClick={(v) => props.handle({

                                        //     call:'showLaborMenu'
                                
                                        // })}

                                        onClick={() => props.handle({

                                            call:'add',
                                            data :{
                
                                                index:props.data.index,
                                                fieldCall:'costfield',
                                                type:'labor',
                                                id:cell.id,
                                                name:cell.name,
                                                value:cell.value,
                                                scrollpos:props.data.scrollpos
                
                                            }
                
                                        })}

                                        >
                                            <div 
                                            className="Name"
                                            >

                                                {cell.name}
                                                
                                            </div>

                                            <div 
                                            className="Value"
                                            >

                                                {"$"+cell.value}

                                            </div>

                                        </div>



                                ))}                      

                            </div>

                        </div>

                    ))}

                </div>


            </Menu>


        </Base>


    </div>, portalTarget);


}

export default Index;


