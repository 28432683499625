import React, { 

    //useState, 
    //useEffect,
    //useRef

} from 'react';


import {

    PrintOut

} from '../../../Styled';




export const FScomplete = (props) => {


    //console.log(JSON.stringify(props.errorArr,null,2));
    //alert('FSerror')

    return ( 

        <PrintOut
        >
            <pre>

                {props.data}

            </pre>

        </PrintOut>

    );



}





