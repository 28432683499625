import React, { 

    useState, 
    useEffect, 
    //useRef 

} from 'react';


import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    //useHistory,   
    //useParams,



} from "react-router-dom";

// import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'



import { createPortal } from 'react-dom';
import usePortal from 'utils/hooks/Portal';






// import Navigator from 'utils/components/Content/Navigator';
// import SideNavInfo from 'utils/components/Pages/SceneInfo/Trials/SideNav/SceneInfo';




//=====================================================


import {

    Alfa,
    Base,
    Loader

} from '../_Styled';


import './StyleSheets/loader.css';


const Index = (props) => {



    const portalTarget = usePortal("stage");


    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();

    
 
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);



    // -- window dims -- //
    // const { 
    //     width,
    //     height 
    // } = useWindowDimensions();



    // -- redux -- //
    // const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);


 

    //=============================================
    // ref
    //=============================================

    //const refForm = useRef(null);

    /*
    const rowRefs = [];
    const setRowRef = (ref) => {

        if(ref && ref !== null && ref !== undefined)
        {
            //console.log("ref::::"+ref.id);
            var found;
            found = false;
            for(var i=0; i < rowRefs.length; i++)
            {
                if(rowRefs[i].id === ref.id) found = true;
            }
            if(!found) rowRefs.push(ref);
            console.log(rowRefs.length);
            console.log(rowRefs[0]);

        }

    };
    */




    //=============================================
    // state/effect - display
    //=============================================

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {

        if(!isLoaded)
        {
            setIsLoaded(true);

        }
  
    },
    [
        props,
        zoneObj,
        isLoaded,
    
    ])









    // //=============================================
    // // handle
    // //=============================================

    // const handle = (hndl) => {


    //     // switch(hndl.call)
    //     // {
    //     // case'save':
    //     // case'saveas':

    //     //     switch(hndl.call)
    //     //     {
    //     //     case'save':
              
    //     //         setShowConfirm(true);
    //     //     break;
    //     //     case'saveas':
               
    //     //         setShowSaveForm(true);
    //     //     break;
    //     //     default:
    //     //     }

    //     // break;
    //     // case'cancel':

    //     //     setShowConfirm(false);
    //     //     setShowSaveForm(false);
    //     // break;
    //     // default:
    //     // }

  
    // }






    return createPortal(<div>


        <Alfa zIndex={"23"}></Alfa>
        <Base zIndex={"24"}></Base>
        <Loader zIndex={"25"}>


            <div 
            id="loader"
            className="med-loader-circle" 
            style={{

                borderTop          : "0.7em solid rgba(250,250,250,0.2)",
                borderRight        : "0.7em solid rgba(250,250,250,0.2)",
                borderBottom       : "0.7em solid rgba(250,250,250,0.2)",
                borderLeft         : "0.7em solid #FFF"

            }}
            >
            </div>


        </Loader>


    </div>, portalTarget);


}

export default Index;


