import styled from "styled-components";


export const Tile = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "white",

    width:              props.width || "500px",
    height:             props.height || "auto",
    margin:             props.margin || "10px 10px 10px 10px",
    padding:            props.padding || "0 0 12px 0",

    border:             props.border || "1px solid lightgray"




    // width:${props => props.width};
    // height:${props => props.height};
    // min-height:250px;

    // padding:${props => props.padding};
    // margin:${props => props.margin}; 


}))`


    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    border-radius:2px;


    height:${props => props.height};
    min-height:250px;

    padding:${props => props.padding};


    position:relative;
    float:left;

    overflow:hidden;


    width:45%;
    margin:0 0 40px 2.5%;



    @media (max-width: 1050px) {

  
        width:45%;
        margin:0 1 40px 2.5%;

    }



    @media (max-width: 750px) {

  
        width:80%;
        margin:0 0px 40px 10%;

    }

    @media (max-width: 500px) {

  
        width:86%;
        margin:0 0 40px 5%;

    }




`;




export const TileName = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    // border:             props.border || "1px solid lightgray",

    width:              props.width || "100%",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "0 0 4px 0",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "20px",
    fontWeight:         props.fontWeight  || "550",
    textAlign:          props.textAlign || "center",
    textDecoration:     props.textDecoration  || "none",
    color:              props.color || "#454d5f"

 
}))`


    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    height:${props => props.height};


    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;


    font-family:${props => props.fontFamily};
    font-size:${props => props.fontSize};
    font-weight:${props => props.fontWeight};
    text-align:${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.color};


`;





export const TileText = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "yellow",
    border:             props.border || "1px solid lightgray",

    width:              props.width || "100%",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "7px 0 7px 0",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "20px",
    fontWeight:         props.fontWeight  || "550",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration  || "none",
    color:              props.color || "gray"

 
}))`


    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    height:${props => props.height};


    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;


    font-family:${props => props.fontFamily};
    font-size:${props => props.fontSize};
    font-weight:${props => props.fontWeight};
    text-align:${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.color};


`;






export const TileTop = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "1px solid lightgray",

    width:              props.width || "100%",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "0 0 0 0",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "16px",
    fontWeight:         props.fontWeight  || "500",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration  || "none",
    color:              props.color || "black"




    // background-color:#EDEEF1;

    // border-right:${props => props.border};      
    // border-bottom:${props => props.border};
    // border-radius:0 0 10px 0;


 
}))`


    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    height:${props => props.height};


    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;


    .Descr {

        background-color:transparent;

        width:auto;
        height:auto;
        padding:8px 10px 7px 8px;
        margin:0 0 0 0;

        position:relative;
        float:left;

        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        text-align:${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};

    }





`;




export const TileControls = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",

    width:              props.width || "auto",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "0 0 0 0",


    // position:absolute;
    // top:4px;
    // right:7px;
    // z-index:3;


}))`


    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:right;

    overflow:hidden;


    .Button {

        background-color:transparent;

        width:30px;
        height:30px;
        padding:0 0 0 0;
        margin:0 0 0 0;
        position:relative;
        float:left;

        cursor:pointer;


    }

    .ButtonMask {

        background-color:transparent;

        width:30px;
        height:30px;
        padding:0 0 0 0;
        margin:0 0 0 0;
        position:absolute;
        top:0px;
        left:0px;
        z-index:1;

        cursor:pointer;

    }


    .Img {

        width:30px;
        height:30px;

    }


`;





