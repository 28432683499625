import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';


// import { 
//     useSelector, 
//     //useDispatch 
// } 
// from 'react-redux';
// //import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';



import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    useHistory,   
    //useParams,



} from "react-router-dom";

import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'





import {

    //---------------
    // Stage
    //---------------

    Stage,
    ScrollBox,
    // Area,
    // AreaTitle,



    //---------------
    // Layout
    //---------------

    MenuAlfa,
    Menu,
    MenuControls,

    Topbar,

    Side,   
    Main,



    // Rows,
    // FullRow,
    // IndentRow,

    // Box,

    // Tile,
    // TileName,
    // TileTop,
    // TileControls
    

} from 'app-components/Zone/_Styled';


import {

    Catalog,
        CatalogTop,
            CatalogTopName,
            //CatalogTopControls,

        CatalogBar,
        CatalogTile,


} from 'utils/components/Catalog/Styled';


import {

    ACCOUNT as ACCOUNT_GRAPHIC,
    EMPLOYEE as EMPLOYEE_GRAPHIC,
    EXPENSES as EXPENSES_GRAPHIC,
    OVERHEAD as OVERHEAD_GRAPHIC,
    OWNER as OWNER_GRAPHIC,
    SCHEDULE as SCHEDULE_GRAPHIC,

    // CALCULATOR as CALCULATOR_GRAPHIC,
    // DIRECT_LABOR_HOURS as HOURS_GRAPHIC,
    // DIRECT_LABOR_RATES as RATES_GRAPHIC,
    // HEALTHCARE as HEALTHCARE_GRAPHIC,
    // IRA as IRA_GRAPHIC,
    // REPORTS as REPORTS_GRAPHIC,
    // SETTINGS as SETTINGS_GRAPHIC

} from 'app-components/Zone/_SVGComps/ICONS';


// import StylScene from './_StylModules/Scene.module.css';
// // import StylPage from '../_StylModules/Page.module.css';
// // import StylRows from '../_StylModules/Rows.module.css';





// import FGrid from 'utils/components/Grid/FGrid';
// import VidGrid from 'utils/components/Grid/VidGrid';

// import TxtComponent from 'utils/components/Content/Txt';
// import ImgComponent from 'utils/components/Content/Img';
// import CardComponent from 'utils/components/Card';
import Navigator from 'utils/components/Content/Navigator';

import SideNavInfo from 'utils/components/Pages/SceneInfo/Trials/SideNav/SceneInfo';


// import SceneBuild from 'app-components/Zone/_Builder/SceneBuild';
// import SceneInfo from './SceneInfo';

import SceneInfo from './SceneInfo';



const Index = (props) => {



    //const Scene = SceneBuild(SceneInfo());
    const InitScene = SceneInfo(props.data);
    // console.log("Employee Scene:::: "+JSON.stringify(Scene,null,2));



    // -- window dims -- //
    const { 
        width,
        height 
    } = useWindowDimensions();




    // -- redux -- //
    //const dispatch = useDispatch();
    //const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));




    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    const history = useHistory();

    

    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);




    let mainpath;
    mainpath = pathname.split("/")[0];

    let subpath;
    subpath = pathname.split("/")[1];
    if(subpath === undefined) subpath = false;

    let sub2path;
    sub2path = pathname.split("/")[2];
    if(sub2path === undefined) sub2path = false;





    //=============================================
    // refs
    //=============================================

    const [rowRefs] = useState([]);
    const setRowRef = (ref) => {

        if(ref && ref !== null && ref !== undefined)
        {
            //console.log("ref::::"+ref.id);
            var found;
            found = false;
            for(var i=0; i < rowRefs.length; i++)
            {
                if(rowRefs[i].id === ref.id) found = true;
            }
            if(!found) rowRefs.push(ref);
            //console.log(boxRefs.length);

        }

    };



    // -- ref -- //
    const refScrollbox = useRef(null);
    const refTopbar = useRef(null); 
    const refSide = useRef(null);
    const refMain= useRef(null);



    //=============================================
    // state 
    //=============================================

    const [isLoaded, setIsLoaded] = useState(false);
 
    useEffect(() => {

        if(!isLoaded)
        {
            setIsLoaded(true);
        }


    },
    [
        isLoaded
    ])





    
    //=============================================
    // state 
    //=============================================

    const [styling,setStyling] = useState({

        topbar:{

            width:false,
            margin:false
        },     
        scrollbox:{
            
            backgroundColor:false,
            width:false,
            height:false
        },
        main:{

            width:false,
            marginRight:false
        }

    });
    const [threshold] = useState(949);
    

    useEffect(() => {

    

        // if(!isLoaded)
        // {

            var mainWidth;
            var mainMargin;

            var mar;
            var maxMar;
            //alert(refMain.current.offsetWidth);
            if(refScrollbox.current.offsetWidth > 750)
            {

                mar = (refScrollbox.current.offsetWidth - refMain.current.offsetWidth)/2;
                if(refSide && refSide.current)
                {
                    maxMar = 50;
                    //console.log("mar"+mar);
                    if(mar > maxMar && refScrollbox.current.offsetWidth > refMain.current.offsetWidth+maxMar*2) mar = maxMar;
                    
                }
                //console.log("above");
                mainWidth = 750 + "px";
                mainMargin = "0 0 50px " +mar+"px";


                
            }else{


                if(refScrollbox.current.offsetWidth < 751)
                {
                    //console.log("above");
                    mainWidth = "100%";
                    mainMargin = "0 0 0 0";
    
                }else{

                    console.log("below");
                    mainWidth = "100%";
                    mainMargin = "0 0 50px 0px";

                }

            }




            var scrollboxBackgroundColor = "#fff";


            var topbarObj;
            topbarObj = {

                width:"calc(100% - 0px)",
                margin:"0 0 0 0"

            };
            var scrollboxObj;
            scrollboxObj = {

                backgroundColor:scrollboxBackgroundColor,
                width:"calc(100% - 0px)",
                height:"calc(100% - "+refTopbar.current.offsetHeight+"px)",
                margin:"0 0 50px 0"

            };
            if(refSide && refSide.current)
            {

                topbarObj = {
                
                    width:"calc(100% - "+refSide.current.offsetWidth+"px)",
                    margin:"0 0 0 "+refSide.current.offsetWidth+"px"
                }
                scrollboxObj = {
                
                    backgroundColor:scrollboxBackgroundColor,
                    width:"calc(100% - "+refSide.current.offsetWidth+"px)",
                    height:"calc(100% - "+refTopbar.current.offsetHeight+"px)",
                    margin:"0 0 50px "+refSide.current.offsetWidth+"px"
                }

            }


            setStyling({

                side:{

                    backgroundColor:"#191e28"

                },
                topbar:topbarObj,
                scrollbox:scrollboxObj,
                main:{
        
                    width       :mainWidth,
                    margin      :mainMargin
                }
        
            });

        //}

        //console.log("scrollbox w: "+refScrollbox.current.offsetWidth);





    },
    [

        isLoaded,

        width,
        height,

        refSide,
        refMain,
        refScrollbox,
        refTopbar,

        mainpath

    ])


    


    //=============================================
    // state/effect - showMenu
    //=============================================

    // -- state -- //
    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {

        if(width > threshold && showMenu)
        {
            setShowMenu(false);

        }
    },
    [

        width,
        threshold,
        showMenu
    ])





    //=============================================
    // state/effect - breadcrumbs
    //=============================================

    // -- state -- //
    const [breadcrumbs, setBreadcrumbs] = useState([]);


    useEffect(() => {

        // if(!isLoaded)
        // {
        //}
        //console.log("scrollbox w: "+refScrollbox.current.offsetWidth);
    
        var pathArr = pathname.split("/");
        var crumbs = ["Home","/"];
        var i;
        for(i=0; i < pathArr.length; i++)
        {
            if(i < pathArr.length-1)
            {
                crumbs.push(pathArr[i]);
                crumbs.push("/");
            }else{
                crumbs.push(pathArr[i]);
            }

        }
        setBreadcrumbs(crumbs);

    },
    [

        pathname,
        isLoaded

    ])










    //=============================================
    // handle
    //=============================================

    const handle = (hndl) => {



        switch(hndl.call)
        {
        case'breadcrumb':

            var pathArr = pathname.split("/");
            var url = "/";

            if(hndl.data !== 'Home')
            {
                var i;
                for(i=0; i < pathArr.length; i++)
                {
                    if(pathArr[i] === hndl.data)
                    {
                        url = url + hndl.data;
                        break;
                    }else{
                        url = url + pathArr[i]+"/";
                    }
                }

            }

            //if(url.charAt(url.length-1) === '/') 
                //url = url.substring(0,url.length-1);

            if(url.charAt(url.length-1) !== '/') 
                url = url + "/";

            //alert(url);
            
            history.push(url);

        break;
        case'menu':

            switch(hndl.data)
            {
            case'close':
                setShowMenu(false);
            break;
            default:
                setShowMenu(true);
            }
        break;
        default:
        }


    }


    //=============================================
    // handleClick
    //=============================================

    const handleClick = (hndl) => {


        switch(hndl.call)
        {
        case'calc':


            //--------------
            //QUERY
            //--------------

            // var url = "";
            // //var url = pathname+"/Calculator/";
            // if(hndl.data.id)
            // {
            //     url = url + "?id=" + hndl.data.id + "&name="+hndl.data.name
            // }else{
            //     url = url + "?id=calc&name=The Profit Calculator"
            // }
            // if(url.match(/\/\//gi)) url = url.replace(/\/\//gi,"/");
            // history.push(url); 



            //---------------
            //STATE
            //---------------

            // history.push("#!Calculator");
            // setCalcHash(true);
            // if(hndl.data.id)
            // {
            //     setCalcId(hndl.data.id);
            //     setCalcName(hndl.data.name);
            // }else{
            //     setCalcId("calc");
            //     setCalcName("The Profit Calculator");
            // }//==

        break;
        default:

            var url = "";
            url = hndl.data.id+"/";
            history.push(url); 
        }

    }












    if(!isLoaded) 
    {

        //return <div className="area-title">Loading...</div>


        return (

            <Stage>

                <Side
                ref={refSide}
                >
                </Side>


                <Topbar
                ref={refTopbar}
                width={styling.topbar.width} 
                margin={styling.topbar.margin}
                >
                </Topbar>
                

                <ScrollBox
                ref={refScrollbox}
                width={styling.scrollbox.width} 
                height={styling.scrollbox.height} 
                margin={styling.scrollbox.margin}
                >

                    <Main
                    ref={refMain}
                    width={styling.main.width} 
                    margin = {styling.main.margin}
                    >


                    </Main>    

                </ScrollBox>


            </Stage>




        )



    } 
    else 
    {


        return (

            <Stage>


                {width < threshold && showMenu
                ?

                    <div>

                        <MenuAlfa
                        onClick={(v) => handle({

                            call:'menu',
                            data:"close"

                        })}
                        ></MenuAlfa>
                        <Menu
                        >
                            <MenuControls>

                                <div className="Title">

                                    {"Menu"}

                                </div>

                                <div 
                                className="Button"
                                onClick={(v) => handle({

                                    call:'menu',
                                    data:"close"
        
                                })}
                                >

                                    {"X"}
                                                                    
                                </div>

                            </MenuControls>

                            <Navigator

                                handle      = {(v) => props.handle(v)} 
                                data        = {SideNavInfo['dashboard_sidenav']}
                                refid       = {"dashboard_sidenav"}

                                viewHeight  = {false}
                                databind    = {props.databind}

                            />

                        </Menu>


                    </div>


                :null}


                {width > threshold
                ?

                    <Side
                    ref={refSide}
                    //backgroundColor={styling.side.backgroundColor}
                    //border={"0px solid lightgray"}
                    >
                        <Navigator

                            handle      = {(v) => props.handle(v)} 
                            data        = {SideNavInfo['dashboard_sidenav']}
                            refid       = {"dashboard_sidenav"}

                            viewHeight  = {false}
                            databind    = {props.databind}

                        />

                    </Side>


                :null}



                <Topbar
                ref={refTopbar}
                width={styling.topbar.width} 
                margin={styling.topbar.margin}
                >

                    <div
                    className={"Left"}
                    >


                        {
                        width < threshold
                        ?
                            <div
                            className={"MenuButton"}
                            onClick={(v) => handle({

                                call:'menu',
                                data:"open"

                            })}
                            >
                                <div className={"Slice"}></div>
                                <div className={"Slice"}></div>
                                <div className={"Slice"}></div>

                            </div>

                        :null
                        }





                        {breadcrumbs.map((crumb, i) => (

                            <div
                            key={i}
                            className={"Button"}
                            onClick={(v) => handle({

                                call:'breadcrumb',
                                data:crumb

                            })}
                            >
                                {crumb}

                            </div>

                        ))}





                    </div>


                    <div
                    className={"Right"}
                    >


                    </div>


                </Topbar>





                <ScrollBox
                ref={refScrollbox}
                //backgroundColor={"#191e28"}
                //backgroundColor={styling.scrollbox.backgroundColor} 
                width={styling.scrollbox.width} 
                height={styling.scrollbox.height} 
                margin={styling.scrollbox.margin}
                >



                    <Main
                    ref={refMain}
                    //backgroundColor={"lightblue"}
                    width={styling.main.width} 
                    margin = {styling.main.margin}
                    >

                        <div
                        style={{

                            backgroundColor:"transparent",
                            width:"100%",
                            height:"auto",
                            margin:"0 0 0 0",
                            padding:"0 0 200px 0",
                            position:"relative",
                            float:"left"

                        }}
                        >         
                        {InitScene.map((row, i) => (


                            <div key={i}>
                            <Catalog
                            //backgroundColor={'lightyellow'}
                            minHeight={'450px'}
                            >

                                {row.map((section, j) => (

                                    <div
                                    key={i+"-"+j}
                                    >
                                        {

                                        section.call === 'top'
                                        ?

                                            <CatalogTop>

                                                <CatalogTopName
                                                //color={"#fff"}
                                                >

                                                    {section.name}

                                                </CatalogTopName>

                                            </CatalogTop>

                                        :
                                        section.call === 'bar'
                                        ?

                                            <div>

                                                <CatalogBar
                                                backgroundColor={"lightgray"}
                                                border={"0"}
                                                width={"100%"}
                                                height={"1px"}
                                                >
                                                </CatalogBar>

                                            </div>

                                        :
                                        section.call === 'items'
                                        ?



                                            <div>

                                                {section.items.map((itm, k) => (

    
                                                    <CatalogTile

                                                    ref={setRowRef}                              
                                                    key={i+"-"+j+"-"+k}
                                                    id={"row"+i+"-"+j+"-"+k}
                                                    >

                                                        <div className="Left"
                                                        style={{backgroundColor:itm.data.graphic.tileColor}}
                                                        onClick={(v) => handleClick({


                                                            call:"default",
                                                            data:{
        
                                                                id:itm.data.id,
                                                                name:itm.data.name
                                                            }
        
        
                                                        })}
                                                        >

                                                            {/* 
                                                            <div className="Graphic"
                                                            >

                                                                <img
                                                                id={"svg-"+i+"-"+j+"-"+k}
                                                                src={SVGProfitPie} 
                                                                alt="del" />


                                                            </div> 
                                                            */}


                                                            {/* 
                                                            <div
                                                            className="Circle"
                                                            >
                                                                <div className="Letter">

                                                                    {itm.data.name.charAt(0)}


                                                                </div>

                                                            </div>
                                                            */}






                                                            {/* <div className="Graphic"
                                                            //style={{backgroundColor:"magenta"}}
                                                            >


                                                                {

                                                                itm.data.id.toUpperCase() === 'ACCOUNT'
                                                                ?

                                                                    <ACCOUNT_GRAPHIC
                                                                    
                                                                        iconColor = {itm.data.graphic.iconColor}
                                                                        darklite = {itm.data.graphic.darklite}

                                                                    />

                                                                :  
                                                                itm.data.id.toUpperCase() === 'SCHEDULE'
                                                                ?

                                                                    <SCHEDULE_GRAPHIC
                                                                    
                                                                        iconColor = {itm.data.graphic.iconColor}
                                                                        darklite = {itm.data.graphic.darklite}

                                                                    />

                                                                :  
                                                                itm.data.id.toUpperCase() === 'OVERHEAD'
                                                                ?

                                                                    <OVERHEAD_GRAPHIC
                                                                    
                                                                        iconColor = {itm.data.graphic.iconColor}
                                                                        darklite = {itm.data.graphic.darklite}

                                                                    />

                                                                :  
                                                                itm.data.id.toUpperCase() === 'EXPENSES'
                                                                ?

                                                                    <EXPENSES_GRAPHIC
                                                                    
                                                                        iconColor = {itm.data.graphic.iconColor}
                                                                        darklite = {itm.data.graphic.darklite}

                                                                    />

                                                                :  
                                                                itm.data.id.toUpperCase() === 'OWNERS'
                                                                ?

                                                                    <OWNER_GRAPHIC
                                                                    
                                                                        iconColor = {itm.data.graphic.iconColor}
                                                                        darklite = {itm.data.graphic.darklite}

                                                                    />

                                                                :  
                                                                itm.data.id.toUpperCase() === 'EMPLOYEES'
                                                                ?

                                                                    <EMPLOYEE_GRAPHIC
                                                                    
                                                                        iconColor = {itm.data.graphic.iconColor}
                                                                        darklite = {itm.data.graphic.darklite}

                                                                    />


                                                                :

                                                                    <div
                                                                    className="Circle"
                                                                    >
                                                                        <div className="Letter">
        
                                                                            {itm.data.name.charAt(0)}
        
        
                                                                        </div>
        
                                                                    </div>
        



                                                                }


                                                            </div> */}




    
                                                            <div className="GraphicTile">


                                                                {

                                                                itm.data.id.toUpperCase() === 'ACCOUNT'
                                                                ?

                                                                    <ACCOUNT_GRAPHIC
                                                                    
                                                                        iconColor = {itm.data.graphic.iconColor}
                                                                        darklite = {itm.data.graphic.darklite}

                                                                    />

                                                                :  
                                                                itm.data.id.toUpperCase() === 'SCHEDULE'
                                                                ?

                                                                    <SCHEDULE_GRAPHIC
                                                                    
                                                                        iconColor = {itm.data.graphic.iconColor}
                                                                        darklite = {itm.data.graphic.darklite}

                                                                    />

                                                                :  
                                                                itm.data.id.toUpperCase() === 'OVERHEAD'
                                                                ?

                                                                    <OVERHEAD_GRAPHIC
                                                                    
                                                                        iconColor = {itm.data.graphic.iconColor}
                                                                        darklite = {itm.data.graphic.darklite}

                                                                    />

                                                                :  
                                                                itm.data.id.toUpperCase() === 'EXPENSES'
                                                                ?

                                                                    <EXPENSES_GRAPHIC
                                                                    
                                                                        iconColor = {itm.data.graphic.iconColor}
                                                                        darklite = {itm.data.graphic.darklite}

                                                                    />

                                                                :  
                                                                itm.data.id.toUpperCase() === 'OWNERS'
                                                                ?

                                                                    <OWNER_GRAPHIC
                                                                    
                                                                        iconColor = {itm.data.graphic.iconColor}
                                                                        darklite = {itm.data.graphic.darklite}

                                                                    />

                                                                :  
                                                                itm.data.id.toUpperCase() === 'EMPLOYEES'
                                                                ?

                                                                    <EMPLOYEE_GRAPHIC
                                                                    
                                                                        iconColor = {itm.data.graphic.iconColor}
                                                                        darklite = {itm.data.graphic.darklite}

                                                                    />


                                                                :

                                                                    <div
                                                                    className="Circle"
                                                                    >
                                                                        <div className="Letter">
        
                                                                            {itm.data.name.charAt(0)}
        
        
                                                                        </div>
        
                                                                    </div>
        



                                                                }


                                                            </div>


                                                        </div>


                                                    


                                                        <div className="Right"
                                                        onClick={(v) => handleClick({


                                                            call:"default",
                                                            data:{
        
                                                                id:itm.data.id,
                                                                name:itm.data.name
                                                            }
        
        
                                                        })}
                                                        >

                                                            <div className="Title"
                                                            >

                                                                {itm.heading}

                                                            </div>

                                                
                                                            <div className="Descr"
                                                            >
                                                                {itm.pgraph}

                                                            </div>


                                                        </div>


                                                    </CatalogTile>

                                                

                                                ))}


                                            </div>


                                        :null

                                        }

                                    </div>


                                ))}

                            </Catalog>
                            </div>

                        ))} 
                        </div>


                            
                            
                    </Main>  

                </ScrollBox>

            </Stage>

        )


    }

}

export default Index;


