
export const IRA = [


    //-----------------------------------
    // Graphic and Ledger
    //-----------------------------------

    {

        "print"		    :'bar',
        "name"		    :'Total',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"15px"
        },
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'report_box',
        "name"		    :'Report Box',
        "value"		    :[

            {

                boxCall:'graphic',
                boxWidth:"35%",
                boxHeight:190,

                svgCall:"ira",
                svgWidth:190,
                svgHeight:190,

            },

            {
                boxCall:'ledger',
                boxWidth:"65%",
                boxHeight:190,
                value:[

                    {
                        name:"Total Yearly",
                        value:"$1500.00"
                    },

                    {
                        name:"Total Monthly",
                        value:"$1500.00"
                    }


                ]

            }



        ],
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"40px"
        },
        "required"	    :"",
        "default"	    :""


    },







    //-----------------------------------
    // Ledger
    //-----------------------------------

    {

        "print"		    :'bar',
        "name"		    :"Owner's",
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'ledger',
        "name"		    :"Ledger",
        "value"		    :[


            {
                name:"Percentage",
                value:"3.0%"
            },
            {
                name:"Yearly",
                value:"$1500.00"
            },
            {
                name:"Monthly",
                value:"$125.00"
            }


        ],
        "required"	    :"",
        "default"	    :""


    },

    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"50px"
        },
        "required"	    :"",
        "default"	    :""


    },





    //-----------------------------------
    // Ledger
    //-----------------------------------

    {

        "print"		    :'bar',
        "name"		    :"Employee's",
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'ledger',
        "name"		    :"Ledger",
        "value"		    :[


            {
                name:"Percentage",
                value:"3.0%"
            },
            {
                name:"Yearly",
                value:"$1248.00"
            },
            {
                name:"Monthly",
                value:"$104.00"
            }


        ],
        "required"	    :"",
        "default"	    :""


    },

    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"50px"
        },
        "required"	    :"",
        "default"	    :""


    },





];



