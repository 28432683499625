
const LibSystem = {



    RemoveExt : function(filename){
    
        //x.split(".").slice(0, -1).join(".")
        //x = x.substr(0, x.lastIndexOf('.'))
        //x = x.replace(/(.*)\.[^.]+$/, "$1");
    
        var r = filename.replace(/\.[^/.]+$/, "");
        return r;
    
    },
    


    FileDisplay : function(filename){
    

        function StripSlashes(str) {
            str=str.replace(/\\'/g,'\'');
            str=str.replace(/\\"/g,'"');
            str=str.replace(/\\0/g,'\0');
            str=str.replace(/\\\\/g,'\\');
            return str;
        }
       
        function ApstroDisplay(str) {
        
            var regex = /-APSTRO-/gi;
            str = str.replace(regex, "'");
        
            return str;
        
        };
        


        
        var str;
        var regex,repl;
        var clean_filename;
            
        if(!filename || filename === '')
        {
            return filename;
        }else{
    
            //######################
            //REMOVE PLUS SIGN
            //######################
        
            str = filename;
            regex = /\+/gi;
            clean_filename = str.replace(regex, "");
                
        
            //###########################
            //REPLACE DOUBLE DASHES
            //###########################
        
            str = clean_filename;
            regex = /--/gi;
            clean_filename = str.replace(regex, " ");
        
        
        
        
            //##################################
            //REMOVE PAGE TYPE FROM STRING
            //##################################
                
            str = clean_filename;
            repl = /__pxpgx.*?xtype_/;//everything between __pxpgx and xtype_ is replaced
            clean_filename = str.replace(repl, "");
        
        
        
            //##################################
            //REMOVE SYSTEM ID FROM STRING
            //##################################
                
            str = clean_filename;
            repl = /_sys.*?id_/;//everything between _sys and id_ is replaced
            clean_filename = str.replace(repl, "");
        
        
        
            //########################
            //REPLACE SLASH QUOTE
            //########################
        
            //clean_filename = clean_filename.replace("\\'", "'", 'g');
        
            clean_filename = StripSlashes(clean_filename);
            clean_filename = ApstroDisplay(clean_filename);
                
            return clean_filename;
        
    
        }//###
    
    
    
    }
    



}

export default LibSystem;


