
/*
		total_empl_direct_indirect_salary_yearly   	:total_empl_direct_indirect_salary_yearly,
		total_empl_direct_indirect_tax_yearly   	:total_empl_direct_indirect_tax_yearly,
		total_empl_direct_indirect_ira_yearly   	:total_empl_direct_indirect_ira_yearly,
		
		total_empl_direct_indirect_salary_monthly   :total_empl_direct_indirect_salary_monthly,
		total_empl_direct_indirect_tax_monthly   	:total_empl_direct_indirect_tax_monthly,
		total_empl_direct_indirect_ira_monthly   	:total_empl_direct_indirect_ira_monthly,
		
		total_empl_burden_yearly           			:total_empl_burden_yearly,
		
*/

const VPemplsYearly = {


    "name":"Employees",
    "value":{


        "total_empl_direct_indirect_salary_yearly" : {

            "print"		:'price',
            "name"		:'Salary Yearly',
            "value"		:0,
            "required"	:true,
            "default"	:0
        },

        "total_empl_health_insurance_yearly" : {

            "print"		:'price',
            "name"		:'Health Ins. Yearly',
            "value"		:0,
            "required"	:true,
            "default"	:0
        },

        "total_empl_direct_indirect_ira_yearly" : {

            "print"		:'price',
            "name"		:'IRA Yearly',
            "value"		:0,
            "required"	:true,
            "default"	:0

        },

        "total_empl_direct_indirect_tax_yearly" : {

            "print"		:'price',
            "name"		:'Tax Yearly',
            "value"		:0,
            "required"	:true,
            "default"	:0

        }, 

        "total_empl_burden_yearly" : {

            "print"		:'price',
            "name"		:'Total',
            "value"		:0,
            "required"	:true,
            "default"	:0

        }, 



    }



};






const VPemplsMonthly = {


    "name":"Employees",
    "value":{

        "total_empl_direct_indirect_salary_monthly" : {

            "print"		:'price',
            "name"		:'Salary Monthly',
            "value"		:0,
            "required"	:true,
            "default"	:0
        },

        "total_empl_health_insurance_monthly" : {

            "print"		:'price',
            "name"		:'Health Ins. Monthly',
            "value"		:0,
            "required"	:true,
            "default"	:0
        },

        "total_empl_direct_indirect_ira_monthly" : {

            "print"		:'price',
            "name"		:'IRA Monthly',
            "value"		:0,
            "required"	:true,
            "default"	:0

        },

        "total_empl_direct_indirect_tax_monthly" : {

            "print"		:'price',
            "name"		:'Tax Monthly',
            "value"		:0,
            "required"	:true,
            "default"	:0

        }, 


        "total_empl_burden_monthly" : {

            "print"		:'price',
            "name"		:'Total',
            "value"		:0,
            "required"	:true,
            "default"	:0

        }, 



    }



};






const VPemplsDirect = {


    "name":"Employees",
    "value":{

        "empl_direct_salary_monthly" : {

            "print"		:'price',
            "name"		:'Salary Monthly (Direct)',
            "value"		:0,
            "required"	:true,
            "default"	:0
        },
        "empl_direct_ira_monthly" : {

            "print"		:'price',
            "name"		:'IRA Monthly (Direct)',
            "value"		:0,
            "required"	:true,
            "default"	:0

        },
        "empl_direct_tax_monthly" : {

            "print"		:'price',
            "name"		:'Tax Monthly (Direct)',
            "value"		:0,
            "required"	:true,
            "default"	:0

        }, 

    }



};





const VPemplsIndirect = {


    "name":"Employees",
    "value":{

        "empl_indirect_salary_monthly" : {

            "print"		:'price',
            "name"		:'Salary Monthly (Indirect)',
            "value"		:0,
            "required"	:true,
            "default"	:0
        },
        "empl_health_insurance_monthly" : {

            "print"		:'price',
            "name"		:'Health Ins. Monthly',
            "value"		:0,
            "required"	:true,
            "default"	:0
        },
        "empl_indirect_ira_monthly" : {

            "print"		:'price',
            "name"		:'IRA Monthly (Indirect)',
            "value"		:0,
            "required"	:true,
            "default"	:0

        },
        "empl_indirect_tax_monthly" : {

            "print"		:'price',
            "name"		:'Tax Monthly (Indirect)',
            "value"		:0,
            "required"	:true,
            "default"	:0

        } 


    }



};





// rec-calc-overhead-result-totals


const VPempls = {


    "name":"Employees",
    "value":{

        "empl_indirect_salary_yearly" : {

            "print"		:'price',
            "name"		:'Salary Yearly',
            "value"		:0,
            "required"	:true,
            "default"	:0

        },  		
        
        "empl_indirect_salary_monthly" : {

            "print"		:'price',
            "name"		:'Salary Monthly',
            "value"		:0,
            "required"	:true,
            "default"	:0
        },

        "empl_health_insurance_monthly" : {

            "print"		:'price',
            "name"		:'Health Ins. Monthly',
            "value"		:0,
            "required"	:true,
            "default"	:0
        },

        "empl_indirect_ira_monthly" : {

            "print"		:'price',
            "name"		:'IRA Monthly',
            "value"		:0,
            "required"	:true,
            "default"	:0

        },

        "empl_indirect_tax_monthly" : {

            "print"		:'price',
            "name"		:'Tax Monthly',
            "value"		:0,
            "required"	:true,
            "default"	:0

        }, 



    }



};












const VPempl = {


    "name": "New Employee",
    "value": {

        /*
        "empl_call":{//used for adding new = filter adds call stored

            "name": "Call",
            "print": "hidden",
            "default": "new",
            "value": "new",
            "required": false

        },
        */


        "empl_name": {
            "name": "Name",
            "print": "standard",
            "default": "",
            "value": "",
            "required": true

        },
        "empl_hourly_rate": {
            "name": "Hourly Rate",
            "print": "price",
            "default": 18.00,
            "value": 18,
            "required": true
        },


        
        // -- Employee Labor - Design,Production,Installation / Sales,Business Management,Office -- //

        "empl_labor_direct": { 
            "name": "Direct Labor",
            "print": "checkboxes-edit",
            "default": '',
            "value": '',
            "required": true
        },

        "empl_labor_indirect": { 
            "name": "Indirect Labor",
            "print": "checkboxes-edit",
            "default": '',
            "value": '',
            "required": true
        },




        // -- Time - Weekly Hours - DualSlider % -- //

        "empl_hours_weekly": {
            "name": "Hours Weekly",
            "print": "standard",
            "default": 40,
            "value": 40,
            "required": true
        },

        "empl_labor_percent": {
            "name": "Direct/Indirect Labor %",
            "print": "dualslider",//slider or dualslider - for dualslider value = valueA
            "default": 50,
            "value": 50,
            "required": true
        },




        // -- Tax -- //

        "empl_tax_comp_percent": {
            "name": "Tax Comp %",
            "print": "percent",
            "default": 20.0,
            "value": 20.0,
            "required": true
        },




        // -- Benefits -- //

        "empl_health_insurance_monthly": {
            "name": "Health Insurance Monthly",
            "print": "price",
            "default": 200.00,
            "value": 200.00,
            "required": true
        },
        "empl_ira_percent": {
            "name": "IRA %",
            "print": "percent",
            "default": 2.0,
            "value": 2.0,
            "required": true
        },
        "empl_vacation_weeks": {
            "name": "Vacation Weeks",
            "print": "standard",
            "default": 2,
            "value": 2,
            "required": true
        }


    }


};







export {

    VPemplsYearly, 
    VPemplsMonthly, 
    VPemplsDirect,
    VPemplsIndirect,
    VPempls,
    VPempl
};





/*
    "name": "Mark M",
    "value": {

      "empl_name": "Mark M",

      "empl_vacation_weeks": "2.00",

      "empl_labor_direct": "Design",
 
      "empl_labor_percent": "50.00",     
      "empl_labor_percent_indirect": "50.00",




      "empl_burden_hours_yearly": "2080.00",

      "empl_direct_hourly_rate": "21.96",

      "empl_tax_hourly": "3.60",
 
      "empl_hours_indirect": "20.00",


      "empl_tax_comp_decimal": "0.20",
      "empl_hours_direct": "20.00",

      "empl_tax_comp_percent": "20.00",

      "empl_working_weeks": "50.00",



        "empl_direct_hours_yearly": "1000.00",
        "empl_indirect_hours_yearly": "1080.00",

        "empl_direct_salary_yearly": "18000.00",
        "empl_indirect_salary_yearly": "19440.00",

        "empl_direct_salary_monthly": "1500.00",
        "empl_indirect_salary_monthly": "1620.00",

        "empl_direct_tax_yearly": "3600.00",
        "empl_indirect_tax_yearly": "3888.00",

        "empl_direct_ira_yearly": "360.00",
        "empl_indirect_ira_yearly": "388.80",

        "empl_direct_ira_monthly": "30.00",
        "empl_indirect_ira_monthly": "32.40",



        "empl_direct_indirect_salary_yearly_total": "37440.00",
        "empl_direct_indirect_salary_monthly_total": "3120.00",

        "empl_direct_indirect_tax_yearly_total": "7488.00",
        "empl_direct_indirect_tax_monthly_total": "624.00",

        "empl_direct_indirect_ira_yearly_total": "748.80",
        "empl_direct_indirect_ira_monthly_total": "62.40",

        "empl_health_insurance_weekly": "48.00",
        "empl_health_insurance_monthly": "200.00",
        "empl_health_insurance_yearly": "2400.00",


        "empl_yearly_salary_w_health_insurance": "39840.00",
        


      "empl_labor_percent_direct": "50.00",

      "empl_hourly_rate": "18.00",

      "empl_direct_tax_monthly": "300.00",
      "empl_direct_labor_decimal": "0.50",

      "empl_ira_decimal": "0.02",
      "empl_hours_weekly": "40.00",
      "empl_ira_percent": "2.00",
      "empl_labor_indirect": "Sales",
      "empl_health_insurance_hourly": "1.20",
      "empl_ira_hourly": "0.36",


      "empl_burden_yearly_total": "48076.80",
      "empl_indirect_hourly_rate": "23.16",

      "empl_indirect_tax_monthly": "324.00"


    }
 */
