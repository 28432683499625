// import { Settings } from 'app-components/Prime/Data.js';

import { 

    //VPformTemplateOwnr,
    //VPformTemplateEmpl,
    //VPformTemplateExpense,

    //VPcompanyInfo, 
    VPexpenses,
    VPincome,   
    VPoverhead, 

    VPownrsIndirect,  
    //VPownrs,
    VPownr,

    //VPemplsYearly, 
    //VPemplsMonthly, 
    //VPemplsDirect,
    VPemplsIndirect,
    VPempl


} from 'app-components/Prime/VPdata/form.js';



import { VPreportDefaults } from 'app-components/Prime/VPdata/reports';

import { 

    ChartColorsA, 
    ChartColorsB, 
    ChartColorsC 

} from 'utils/components/Charts/ChartColors';

import { LibBase, LibNumber } from "library";



//import ApiResources from 'utils/components/Pages/ApiResources';


const Index = (props) => {



    //-------------------------------------------

    // var apiDomain;
    // var hostname;
    // //var hostname = window.location.hostname;//returns the domain name of the web host
    // var protocol = window.location.protocol;//returns the web protocol used (http: or https:)

    // if(window.location.hostname.match(/192.168/gi))
    // {
    //     hostname = ApiResources.network['3001'].vpc;
    //     apiDomain = protocol + "//" + hostname + "/";
    // }
    // else
    // if(window.location.hostname.match(/localhost/gi))
    // {
    //     hostname = ApiResources.local['3001'].vpc;
    //     apiDomain = protocol + "//" + hostname + "/";
    // }else{
    //     hostname = ApiResources.aws['x'].vpc;
    //     apiDomain = "https://" + hostname + "/";
    // }//=

    // //-------------------------------------------

    // //console.log("API DOMAIN: "+apiDomain);


    //const apiCall = 'alter-calc-test';
    //const apiCall = 'alter-calc-dash';


    const zoneObj = props.zoneObj;


    var overhead_percent;
    var overhead_percent_int = 0;
    var owners = {};   
    var employees = {};
    //var expenses = {};

    overhead_percent = 0.0 + "%";



    var index;

    var keyname;
    var kn;
    var items, item;
    var array;
    var name;
    var value;
    var newValue;

    var a,b,c,d;
    var total;
    var grand_total;


    var chartSections;
    var object;
    var percent;
    var price;
    var count;
    var bullet,bulletColors;

    var pgraph;



    var obj;

    var chartColors = ChartColorsA;







    //clear VPoverhead
    for(keyname in VPoverhead)
    {
        if(keyname.match(/expense-/gi))
        {
            delete VPoverhead[keyname];
        }
    }

    var cards_overhead = LibBase.CloneIt(VPoverhead);
    var cards_owners = LibBase.CloneIt(VPownrsIndirect);
    var cards_employees = LibBase.CloneIt(VPemplsIndirect);


    //var templateExpenseForm;
    //var newTemplateExpenseForm;
    


    //var templateEmplForm;
    //var newTemplateEmplForm;
    
    //var template_empl;



    var piePerc;
    var pieSlice;
    var pieChart = {

        items: [ 

            {
                name:"Business",
                value:14
            },
            {
                name:"Facility",
                value:20
            }, 
            {
                name:"Equip. Software",
                value:30
            },      
            {
                name:"Vehicles",
                value:12
            },

            {
                name:"Owners",
                value:5
            },
            {
                name:"Employees",
                value:5
            },


        ],

        colors: [

            "#fde23e",
            "#f16e23", 
            "#57d9ff",
            "#937e88",
            "#20b2aa",
            "#ccff00"

        ],


        hole:{

            percent         :"0.0",
            name            :"Overhead",
            color           :"#181b21",
            outlineColor    :"#181b21",
            textColor       :"white"
        }



    };







    var isOkay;
    isOkay = false;
    if(zoneObj.calc_dash 
    && zoneObj.calc_dash !== undefined
    )
    {
        if(Object.keys(zoneObj.calc_dash.form_items).length > 0
        && Object.keys(zoneObj.calc_dash.result_items).length > 0
        )
        {
            isOkay = true;
        }

    }
    if(isOkay)
    {


        grand_total = zoneObj.calc_dash.result_items['totals'].total;
        grand_total = parseFloat(grand_total);


        //console.log("FORM ITEMS: "+JSON.stringify(zoneObj.calc_dash.form_items));

        index=0;
        for(keyname in zoneObj.calc_dash.form_items)
        {

            // -- owners -- //

            if(keyname.match(/ownr/gi))
            {

                //idl_employees[keyname] = zoneObj.calc_dash.form_items[keyname];

                
                //order card item based on Prime VPdata
                //item = zoneObj.calc_dash.form_items[keyname];
                item = LibBase.CloneIt(zoneObj.calc_dash.form_items[keyname]);//Deep Clone
          
          
                //value = VPowner.value;
                value = LibBase.CloneIt(VPownr.value);//Deep Clone
                newValue = {};
                for(kn in value)
                {
                    if(kn === 'ownr_call') 
                    {
                    }else{
                        newValue[kn] = item.value[kn];       
                    }
                    
                }
                owners[keyname] = {
                    name:item.name,
                    value:newValue
                };
                

            }




            // -- employees -- //

            else
            if(keyname.match(/empl/gi))
            {

                //idl_employees[keyname] = zoneObj.calc_dash.form_items[keyname];

                
                //order card item based on Prime VPdata
                //item = zoneObj.calc_dash.form_items[keyname];
                item = LibBase.CloneIt(zoneObj.calc_dash.form_items[keyname]);//Deep Clone
          
                //value = VPemployee.value;
                value = LibBase.CloneIt(VPempl.value);//Deep Clone
                newValue = {};
                for(kn in value)
                {
                    newValue[kn] = item.value[kn];
                }
                employees[keyname] = {
                    name:item.name,
                    value:newValue
                };
                



            }



            // -- overhead -- //

            else
            {
                
                //console.log("KEYNAME: "+keyname);

                item = LibBase.CloneIt(zoneObj.calc_dash.form_items[keyname]);//Deep Clone
                //console.log(JSON.stringify(item,null,2));


                if(keyname.match(/expense-/gi))
                {

                    // TM: order inputs here



                    // -- VPoverhead -- //

                    if(item.order)
                    {
                        newValue = {};
                        for(obj of item.order)
                        {
                            //console.log("order:  "+obj);
                            for(kn in item.value)
                            {
                                //console.log("kn:  "+kn);
                                if(obj === kn)
                                {
                                    //console.log("match: "+kn);
                                    newValue[kn] = item.value[kn];
                                }
                            }

                        }
                        //console.log(JSON.stringify(newValue,null,2));

                        VPoverhead[keyname] = {
                            name:item.name,
                            value:newValue,
                            order:item.order
                        };


                    }else{

                        VPoverhead[keyname] = {
                            name:item.name,
                            value:item.value
            
                        };

                    }






                    // -- card_overhead -- //

                    value = LibBase.CloneIt(item.value);//Deep Clone

                    if(item.order)
                    {
                        newValue = {};
                        for(obj of item.order)
                        {
                            //console.log("order:  "+obj);
                            for(kn in item.value)
                            {
                                //console.log("kn:  "+kn);
                                if(obj === kn)
                                {
                                    //console.log("match: "+kn);
                                    newValue[kn] = item.value[kn];
                                }
                            }

                        }
                        //console.log(JSON.stringify(newValue,null,2));


                        total = 0;
                        for(kn in newValue)
                        {
                            obj = newValue[kn];
                            total = parseFloat(total) + parseFloat(obj.value);
      
                        }
                        total = parseFloat(total);
                        total = total.toFixed(2);
                        // console.log("TOTALLLLLLLLLLLLL "+total);
                        newValue["total"] = total;
                        newValue["total_percent"] = 0;
    
                        cards_overhead[keyname] = {
                            name:item.name,
                            value:newValue,
                            order:item.order
                        };
    

                    }else{

                        total = 0;
                        for(kn in value)
                        {
                            obj = value[kn];
                            total = parseFloat(total) + parseFloat(obj.value);
      
                        }
                        total = parseFloat(total);
                        total = total.toFixed(2);
                        // console.log("TOTALLLLLLLLLLLLL "+total);
                        value["total"] = total;
                        value["total_percent"] = 0;
    
                        cards_overhead[keyname] = {
                            name:item.name,
                            value:value
                        };
    
                    }





                }else{

                    //value = VPoverhead[keyname].value;
                    value = LibBase.CloneIt(VPoverhead[keyname].value);//Deep Clone
                    newValue = {};
                    for(kn in value)
                    {
                        newValue[kn] = item.value[kn];
                    }

                    VPoverhead[keyname] = {
                        name:item.name,
                        value:newValue
                    };

                    cards_overhead[keyname] = {

                        name:item.name,
                        value:newValue

                    };                

                }


        
                // -- set totals for cards -- //

                if(keyname.match(/expense-/gi))
                {
                    total = 0;
                    newValue = 0;
                    for(kn in item.value)
                    {
                        //console.log(value[kn]);
                        if(item.value[kn] !== undefined)
                        {
                            newValue = item.value[kn].value;
                            if(!isNaN(newValue)) parseFloat(newValue);
                            else newValue = 0;
                            total = parseFloat(total) + parseFloat(newValue); 
                        }
    
                    }
                    total = parseFloat(total);        
                    total = total.toFixed(2);
                

                    piePerc = ( parseFloat(total)/parseFloat(grand_total) ) * 100;
                    if(isNaN(piePerc) || grand_total === 0) piePerc = 0;
                    piePerc = piePerc.toFixed(1);
                    piePerc = parseFloat(piePerc);



                    cards_overhead[keyname].value['total_percent'] = {
    
                        name: "Total Monthly (Overhead %)",
                        default: 0,
                        print: "percent",
                        value: piePerc,
                        required: true
    
                    };
                    cards_overhead[keyname].value['total'] = {
    
                        name: "Total Monthly (Overhead $)",
                        default: 0,
                        print: "price",
                        value: total,
                        required: true
    
                    };


                    // -- set pieChart data -- //

                    for(pieSlice of pieChart.items)
                    {
                        if(pieSlice.value !== piePerc)
                        {
                            pieChart.items[index].name = item.name;      
                            pieChart.items[index].value = piePerc;
                            break;
            
                        }
                       
                    }
                    index++;


                }//==




            }

        }// -- for





        //VPexpenses.value.monthly_expenses.value = grand_total;
        VPexpenses.value.monthly_expenses.value = grand_total;
        VPincome.value.projected_monthly_sales.value = zoneObj.calc_dash.form_items['settings'].value.projected_monthly_sales.value;


        overhead_percent = zoneObj.calc_dash.result_items['overhead'].overhead;
        overhead_percent = parseFloat(overhead_percent); 

        overhead_percent_int = overhead_percent.toFixed(1);

        pieChart.hole.percent = overhead_percent.toFixed(1);

        overhead_percent = overhead_percent.toFixed(1) + "%";




        //console.log("IDL EMPLOYEES: "+JSON.stringify(idl_employees,null,2));

    
        /*
        for(keyname in zoneObj.calc_dash.result_items)
        {

            console.log("KEYNAME: "+keyname);

            item = zoneObj.calc_dash.result_items[keyname];
            console.log(JSON.stringify(item,null,2));

        
        }// -- for
        */





        //------------------------------------------
        // -- owners - totals - cards 
        //------------------------------------------

        //var total_ownr_salary_pretax_yearly_indirect
        var total_ownr_salary_pretax_monthly_indirect;
        var total_ownr_health_insurance_monthly;
        var total_ownr_ira_monthly_indirect;
        var total_ownr_taxes_monthly_indirect;  

        total_ownr_salary_pretax_monthly_indirect = zoneObj.calc_dash.result_items['totals'].total_ownr_salary_pretax_monthly_indirect;
        total_ownr_health_insurance_monthly = zoneObj.calc_dash.result_items['totals'].total_ownr_health_insurance_monthly;
        total_ownr_ira_monthly_indirect = zoneObj.calc_dash.result_items['totals'].total_ownr_ira_monthly_indirect;
        total_ownr_taxes_monthly_indirect = zoneObj.calc_dash.result_items['totals'].total_ownr_taxes_monthly_indirect;  

        //total_ownr_salary_pretax_yearly_indirect = parseFloat(total_ownr_salary_pretax_monthly_indirect) * 12;


        //if(isNaN(total_ownr_salary_pretax_yearly_indirect)) total_ownr_salary_pretax_yearly_indirect = 0;
        if(isNaN(total_ownr_salary_pretax_monthly_indirect)) total_ownr_salary_pretax_monthly_indirect = 0;
        if(isNaN(total_ownr_health_insurance_monthly)) total_ownr_health_insurance_monthly = 0;
        if(isNaN(total_ownr_ira_monthly_indirect)) total_ownr_ira_monthly_indirect = 0;
        if(isNaN(total_ownr_taxes_monthly_indirect)) total_ownr_taxes_monthly_indirect = 0;


        //cards_owners.value.ownr_salary_pretax_yearly_indirect.value = total_ownr_salary_pretax_yearly_indirect;
        cards_owners.value.ownr_salary_pretax_monthly_indirect.value = total_ownr_salary_pretax_monthly_indirect;
        cards_owners.value.ownr_health_insurance_monthly.value = total_ownr_health_insurance_monthly;
        cards_owners.value.ownr_ira_monthly_indirect.value = total_ownr_ira_monthly_indirect;
        cards_owners.value.ownr_taxes_monthly_indirect.value = total_ownr_taxes_monthly_indirect;



        // -- card total - indirect labor -- //

        total = 0;
        if(total_ownr_salary_pretax_monthly_indirect !== undefined
        && total_ownr_health_insurance_monthly !== undefined 
        && total_ownr_ira_monthly_indirect !== undefined
        && total_ownr_taxes_monthly_indirect !== undefined
        )
        {
            a = total_ownr_salary_pretax_monthly_indirect;
            b = total_ownr_health_insurance_monthly;
            c = total_ownr_ira_monthly_indirect;
            d = total_ownr_taxes_monthly_indirect;

            total = parseFloat(a) + parseFloat(b) + parseFloat(c) + parseFloat(d);

        }

        piePerc = ( parseFloat(total)/parseFloat(grand_total) ) * 100;
        if(isNaN(piePerc)) piePerc = 0;
        piePerc = piePerc.toFixed(1);
        piePerc = parseFloat(piePerc);

        cards_owners.value['total_percent'] = {
    
            name: "Total Monthly (Overhead %)",
            default: 0,
            print: "percent",
            value: piePerc,
            required: true

        };
        cards_owners.value['total'] = {

            name: "Total Monthly (Overhead $)",
            default: 0,
            print: "price",
            value: total,
            required: true

        };

        // -- set pieChart data -- //

        pieSlice = pieChart.items[pieChart.items.length-2];
        if(pieSlice.value !== piePerc)
        {
            pieSlice.name = "Owners";      
            pieSlice.value = piePerc;

        }
        








        //------------------------------------------
        // -- employees - totals - cards 
        //------------------------------------------

        //var total_empl_indirect_salary_yearly;
        var total_empl_indirect_salary_monthly;
        var total_empl_indirect_tax_monthly;
        var total_empl_indirect_ira_monthly;
        var total_empl_health_insurance_monthly;

        total_empl_indirect_salary_monthly = zoneObj.calc_dash.result_items['totals'].total_empl_indirect_salary_monthly;
        total_empl_indirect_tax_monthly = zoneObj.calc_dash.result_items['totals'].total_empl_indirect_tax_monthly;
        total_empl_indirect_ira_monthly = zoneObj.calc_dash.result_items['totals'].total_empl_indirect_ira_monthly;
        total_empl_health_insurance_monthly = zoneObj.calc_dash.result_items['totals'].total_empl_health_insurance_monthly;
   
        //total_empl_indirect_salary_yearly = parseFloat(total_empl_indirect_salary_monthly) * 12;

        //if(isNaN(total_empl_indirect_salary_yearly)) total_empl_indirect_salary_yearly = 0;
        if(isNaN(total_empl_indirect_salary_monthly)) total_empl_indirect_salary_monthly = 0;
        if(isNaN(total_empl_indirect_tax_monthly)) total_empl_indirect_tax_monthly = 0;
        if(isNaN(total_empl_indirect_ira_monthly)) total_empl_indirect_ira_monthly = 0;
        if(isNaN(total_empl_health_insurance_monthly)) total_empl_health_insurance_monthly = 0;
     
        //cards_employees.value.empl_indirect_salary_yearly.value = total_empl_indirect_salary_yearly;
        cards_employees.value.empl_indirect_salary_monthly.value = total_empl_indirect_salary_monthly;
        cards_employees.value.empl_indirect_tax_monthly.value = total_empl_indirect_tax_monthly;      
        cards_employees.value.empl_indirect_ira_monthly.value = total_empl_indirect_ira_monthly;
        cards_employees.value.empl_health_insurance_monthly.value = total_empl_health_insurance_monthly;







        // -- card total - indirect labor -- //

        total = 0;
        if(total_empl_indirect_salary_monthly !== undefined
        && total_empl_indirect_ira_monthly !== undefined
        && total_empl_indirect_tax_monthly !== undefined        
        && total_empl_health_insurance_monthly !== undefined 
        )
        {
            a = total_empl_indirect_salary_monthly;
            b = total_empl_indirect_ira_monthly;
            c = total_empl_indirect_tax_monthly;        
            d = total_empl_health_insurance_monthly;

            total = parseFloat(a) + parseFloat(b) + parseFloat(c) + parseFloat(d);

        }

        piePerc = ( parseFloat(total)/parseFloat(grand_total) ) * 100;
        if(isNaN(piePerc)) piePerc = 0;
        piePerc = piePerc.toFixed(1);
        piePerc = parseFloat(piePerc);

        cards_employees.value['total_percent'] = {
    
            name: "Total Monthly (Overhead %)",
            default: 0,
            print: "percent",
            value: piePerc,
            required: true

        };
        cards_employees.value['total'] = {

            name: "Total Monthly (Overhead $)",
            default: 0,
            print: "price",
            value: total,
            required: true

        };

        // -- set pieChart data -- //

        pieSlice = pieChart.items[pieChart.items.length-1];
        if(pieSlice.value !== piePerc)
        {
            pieSlice.name = "Employees";      
            pieSlice.value = piePerc;

        }
        


    }// -- if

    //console.log("pieChart: "+JSON.stringify(pieChart,null,2));

    //_overhead = VPoverhead;
    //console.log("_overhead B: "+JSON.stringify(_overhead,null,2));


    //console.log("VPoverhead: "+JSON.stringify(VPoverhead,null,2));
    //console.log("cards_overhead: "+JSON.stringify(cards_overhead,null,2));






    // var actual_monthly_expenses;
    // actual_monthly_expenses = VPexpenses.value.monthly_expenses.value;
    // actual_monthly_expenses = parseFloat(actual_monthly_expenses).toFixed(2);
    // actual_monthly_expenses = "$"+LibNumber.PrintMoney(actual_monthly_expenses);


    bulletColors = {};
    var donut_items = [];

    var total_expenses = "0.00";
    var total_empls_indirect = "0.00";
    var total_ownrs_indirect = "0.00";
    var total_indirects = "0.00";
    var total_expenses_indirects = "0.00";

    var display_total_expenses = "0.00";
    // var display_total_ownrs_indirect = "0.00";
    // var display_total_empls_indirect = "0.00";
    var display_total_indirects = "0.00";
    var display_total_expenses_indirects = "0.00";

    if(zoneObj.calc_dash)
    {

        total_expenses = zoneObj.calc_dash.result_items['totals'].total_expenses;
        total_expenses = parseFloat(total_expenses).toFixed(2);

        total_ownrs_indirect = zoneObj.calc_dash.result_items['totals'].total_ownrs_indirect;
        total_ownrs_indirect = parseFloat(total_ownrs_indirect).toFixed(2);

        total_empls_indirect = zoneObj.calc_dash.result_items['totals'].total_empls_indirect;
        total_empls_indirect = parseFloat(total_empls_indirect).toFixed(2);


        total_indirects = parseFloat(total_ownrs_indirect) + parseFloat(total_empls_indirect);
        total_indirects = parseFloat(total_indirects).toFixed(2);


        total_expenses_indirects = parseFloat(total_expenses) + parseFloat(total_indirects);
        total_expenses_indirects = parseFloat(total_expenses_indirects).toFixed(2);

  
        count = 0;
        for(keyname in cards_overhead)
        {
            if(keyname.match(/expense-/gi))
            {
                object = cards_overhead[keyname];
                total = object.value.total.value;
                percent = (parseFloat(total) / parseFloat(total_expenses)) * 100;
                donut_items.push({
                    "name": object.name,
                    "value": object.value.total.value,
                    "percent": percent,
                    "color": chartColors[count]
                });
                //console.log("-----"+JSON.stringify(object,null,2));

                bulletColors[keyname] = chartColors[count];
                count++;
            }
      

        }
        //console.log("donut_items: "+JSON.stringify(donut_items,null,2));

        // total_expenses = "$"+LibNumber.PrintMoney(total_expenses);
        // total_ownrs_indirect = "$"+LibNumber.PrintMoney(total_ownrs_indirect);
        // total_empls_indirect = "$"+LibNumber.PrintMoney(total_empls_indirect);

        // total_indirects_money = "$"+LibNumber.PrintMoney(total_indirects);


        display_total_expenses = "$"+LibNumber.PrintMoney(total_expenses);
        // display_total_ownrs_indirect = "$"+LibNumber.PrintMoney(total_ownrs_indirect);
        // display_total_empls_indirect = "$"+LibNumber.PrintMoney(total_empls_indirect);
        display_total_indirects = "$"+LibNumber.PrintMoney(total_indirects);


        display_total_expenses_indirects = "$"+LibNumber.PrintMoney(total_expenses_indirects);


    }




    //============================================================================

    var actual_monthly_expenses, actual_monthly_expenses_decimal, actual_monthly_expenses_percent;
    var projected_monthly_sales, projected_monthly_sales_decimal, projected_monthly_sales_percent;
    var total_monthlys;

    var display_actual_monthly_expenses;
    var display_projected_monthly_sales;



    actual_monthly_expenses = VPexpenses.value.monthly_expenses.value;
    projected_monthly_sales = VPincome.value.projected_monthly_sales.value;
    total_monthlys = parseFloat(actual_monthly_expenses) + parseFloat(projected_monthly_sales);

    actual_monthly_expenses_decimal = parseFloat(actual_monthly_expenses) / parseFloat(total_monthlys);
    projected_monthly_sales_decimal = parseFloat(projected_monthly_sales) / parseFloat(total_monthlys);

    actual_monthly_expenses_percent = parseFloat(actual_monthly_expenses_decimal) * 100;
    projected_monthly_sales_percent = parseFloat(projected_monthly_sales_decimal) * 100;


    actual_monthly_expenses = parseFloat(actual_monthly_expenses).toFixed(2);
    projected_monthly_sales = parseFloat(projected_monthly_sales).toFixed(2);

    // actual_monthly_expenses = "$"+LibNumber.PrintMoney(actual_monthly_expenses);
    // projected_monthly_sales = "$"+LibNumber.PrintMoney(projected_monthly_sales);

    display_actual_monthly_expenses = "$"+LibNumber.PrintMoney(actual_monthly_expenses);
    display_projected_monthly_sales = "$"+LibNumber.PrintMoney(projected_monthly_sales);




    donut_items.push({

        "name": "Actual Monthly Expenses",
        "value": display_actual_monthly_expenses,
        "percent": actual_monthly_expenses_percent

    });   
    donut_items.push({

        "name": "Projected Monthly Sales",
        "value": display_projected_monthly_sales,
        "percent": projected_monthly_sales_percent

    });







    // var actual_monthly_expenses;
    // actual_monthly_expenses = VPexpenses.value.monthly_expenses.value;
    // actual_monthly_expenses = parseFloat(actual_monthly_expenses).toFixed(2);
    // actual_monthly_expenses = "$"+LibNumber.PrintMoney(actual_monthly_expenses);


    // var bulletColors = {};
    // var donut_items = [];
    // var total_expenses = "0.00";

    // if(zoneObj.calc_dash)
    // {
    //     total_expenses = zoneObj.calc_dash.result_items['totals'].total_expenses;
    //     total_expenses = parseFloat(total_expenses).toFixed(2);
  
    //     count = 0;
    //     for(keyname in cards_overhead)
    //     {
    //         if(keyname.match(/expense-/gi))
    //         {
    //             object = cards_overhead[keyname];
    //             total = object.value.total.value;
    //             percent = (parseFloat(total) / parseFloat(total_expenses)) * 100;
    //             donut_items.push({
    //                 "name": object.name,
    //                 "value": object.value.total.value,
    //                 "percent": percent,
    //                 "color": chartColors[count]
    //             });
    //             //console.log("-----"+JSON.stringify(object,null,2));

    //             bulletColors[keyname] = chartColors[count];
    //             count++;
    //         }
      

    //     }
    //     //console.log("donut_items: "+JSON.stringify(donut_items,null,2));

    //     total_expenses = "$"+LibNumber.PrintMoney(total_expenses);

    // }





    // var gaugeItems = [

    //     {

    //         "name": "Color ( 37.5% )",
    //         "value": "$1,100.00",
    //         "percent":overhead_percent_int       
    //     },
    //     {

    //         "name": "Blank ( 37.5% )",
    //         "value": "$1,100.00",
    //         "percent":(100 - overhead_percent_int)

    //     }

    // ];
    // if(overhead_percent_int)
    // {
    //     //alert(overhead_percent_int+"\n"+(100-overhead_percent_int));

    //     gaugeItems = [

    //         {

    //             "name": "Color ( 37.5% )",
    //             "value": "$1,100.00",
    //             "percent":overhead_percent_int       
    //         },
    //         {
    
    //             "name": "Blank ( 37.5% )",
    //             "value": "$1,100.00",
    //             "percent":(100 - overhead_percent_int)
    
    //         }

    //     ];

    // }




    // var expenses_indirects_report = {

    //     "name": "Expenses / Indirects",
    //     "value": {

    //         "ei_zyx_expenses": {
    //             "name": "Expenses",
    //             "default": "",
    //             "print": "price",
    //             "value": total_expenses,
    //             "required": true
    //         },
    //         "ei_zyx_ownrs_indirects": {
    //             "name": "Owner's Indirects",
    //             "default": "",
    //             "print": "price",
    //             "value": total_ownrs_indirect,
    //             "required": true
    //         },
    //         "ei_zyx_empls_indirects": {
    //             "name": "Employee's Indirects",
    //             "default": "",
    //             "print": "price",
    //             "value": total_empls_indirect,
    //             "required": true
    //         },

    //         "total": {
    //             "name": "Total Monthly (Overhead $)",
    //             "default": 0,
    //             "print": "price",
    //             "value": total_expenses_indirects,
    //             "required": true
    //         },
    //         "total_percent": {
    //             "name": "Total Monthly (Overhead %)",
    //             "default": 0,
    //             "print": "percent",
    //             "value": 100,
    //             "required": true
    //         }

    //     },
    //     "order": [
    //         "ei_zyx_expenses",
    //         "ei_zyx_ownrs_indirects",
    //         "ei_zyx_ownrs_indirects"
    //     ]


    // };






















    //#############################################################
    //#############################################################

    var reportItems = [
        


        //-----------------------------------
        // Report Box - Graphic / Percentage
        //-----------------------------------
        {

            "print"		    :'bar',
            "name"		    :'Percentage',
            "value"		    :"",
            "required"	    :"",
            "default"	    :""


        },

        {

            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{

                backgroundColor:"transparent",
                height:"10px"
            },
            "required"	    :"",
            "default"	    :""


        },


        {

            "print"		    :'report_box',
            "name"		    :'Report Box',
            "value"		    :[


                {

                    boxCall:'temperature_gauge',
                    boxWidth:"45%",
                    boxHeight:225,
                    value:{
    
                        backgroundColor:"transparent",
                        gaugeSize:225,
                        margin:"0 0 0 0",

                        gaugeBackgroundColor : false, //"#454d5f",
                        gaugeBackgroundWidth : false, //85,
        
                        gaugePercentageColor : "#c00",
                        gaugePercentageWidth : false, //75,
                        gaugePercent : overhead_percent_int ,
              
                        needleCenterColor : false, //"black",
                        needleColor : false, //"black",
                        needleSize : false, //"165",
                        needleRotation  : -100,


                        colors: "GaugeColorsB"
    
                    }
              
            
    
                },
    

                {

                    boxCall:'txt',
                    boxWidth:"50%",
                    boxHeight:225,
                    value:[

                        {
                            className:"Big",
                            txt:overhead_percent,
                            style:{

                                //fontSize:"100px",
                                textAlign:"center"

                            }
                        },

                        {
                            className:"Note",
                            txt:"Expenses / Sales = Overhead Percentage",
                            style:{

                                //fontSize:"13px",
                                textAlign:"center"

                            }
                        }


                    ]


                }



            ],
            "required"	    :"",
            "default"	    :""


        },
        {

            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{

                backgroundColor:"transparent",
                height:"15px"
            },
            "required"	    :"",
            "default"	    :""


        },














        //-----------------------------------
        // Report Box - Expenses / Sales
        //-----------------------------------

        {

            "print"		    :'bar',
            "name"		    :'Expenses / Sales',
            "value"		    :"",
            "required"	    :"",
            "default"	    :""


        },

        {

            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{

                backgroundColor:"transparent",
                height:"0px"
            },
            "required"	    :"",
            "default"	    :""


        },


        {

            "print"		    :'report_box',
            "name"		    :'Report Box',
            "value"		    :[



                {

                    boxCall:'act',
                    boxWidth:"50%",
                    boxHeight:175,
                    value:[

                        {
                            className:"Small",
                            //txt:"Actual Monthly Expenses",
                            txt:"Expenses + Indirects",
                            style:{

                                //fontSize:"100px",
                                textAlign:"center"

                            }
                        },

                        {
                            className:"Medium",
                            txt:display_actual_monthly_expenses,
                            style:{

                                //fontSize:"100px",
                                textAlign:"center"

                            }
                        },


                        // {
                        //     controls:{

                        //         // form:{

                        //         //     submitCall:'calculate',
                        //         //     postTo:apiDomain+"alter-calc-dash",
                        //         //     data:VPincome,
                        //         //     flyr:{
                        //         //         type:"form",
                        //         //         styl:{
                        //         //             maxWidth:500,
                        //         //             fieldType:'field'
                        //         //         }
                        //         //     }
                
                        //         // },

                        //         report:{

                        //             hashbang:false,//#!somename or false
                        //             call:'report',
                        //             keyname:"expenses_indirects",
                        //             data:expenses_indirects_report,
                        //             flyr:{
                        //                 type:"report",
                        //                 styl:{
                        //                     maxWidth:550,
                        //                     fieldType:'composed'
                        //                 }
                        //             }
            
                        //         },


                        //     },
                        //     svg:'chart'
                        // },




                        {
                            className:"Spacer",
                            style:{
    
                                height:"7px"
    
                            }
                        },



                        {
                            className:"Note",
                            //txt:"Expenses + Owners Indirect + Employees Indirect",
                            txt:"Expenses + Indirects (Monthly)",
                            style:{

                                //fontSize:"13px",
                                textAlign:"center"

                            }
                        }


                    ]


                },



                {

                    boxCall:'act',
                    boxWidth:"50%",
                    boxHeight:175,
                    value:[

                        {
                            className:"Small",
                            txt:"Projected Monthly Sales",
                            style:{

                                //fontSize:"100px",
                                textAlign:"center"

                            }
                        },

                        {
                            className:"Medium",
                            txt:display_projected_monthly_sales,
                            style:{

                                //fontSize:"100px",
                                textAlign:"center"

                            }
                        },


                        // {
                        //     controls:{

                        //         form:{

                        //             submitCall:'calculate',
                        //             postTo:apiDomain+"alter-calc-dash",
                        //             data:VPincome,
                        //             flyr:{
                        //                 type:"form",
                        //                 styl:{
                        //                     maxWidth:500,
                        //                     fieldType:'field'
                        //                 }
                        //             }
                
                        //         }


                        //     },
                        //     svg:'pencil'


                        // },



                        {
                            className:"Spacer",
                            style:{
    
                                height:"7px"
    
                            }
                        },



                        {
                            className:"Note",
                            txt:"Gross Sales (Monthly)",
                            style:{

                                //fontSize:"13px",
                                textAlign:"center"

                            }
                        }


                    ]


                }




            ],
            "required"	    :"",
            "default"	    :""


        },
        {

            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{

                backgroundColor:"transparent",
                height:"15px"
            },
            "required"	    :"",
            "default"	    :""


        },














        // //-----------------------------------
        // // Ledger
        // //-----------------------------------

        // {

        //     "print"		    :'bar',
        //     "name"		    :'Expenses / Sales',
        //     "value"		    :"",
        //     "required"	    :"",
        //     "default"	    :""


        // },
        // {

        //     "print"		    :'ledger',
        //     "name"		    :"Ledger",
        //     "value"		    :[


        //         {
        //             name:"Projected Monthly Sales",
        //             value:projected_monthly_sales
        //         }


        //     ],
        //     "required"	    :"",
        //     "default"	    :""


        // },

        // {

        //     "print"		    :'spacer',
        //     "name"		    :'',
        //     "value"		    :{

        //         backgroundColor:"transparent",
        //         height:"10px"
        //     },
        //     "required"	    :"",
        //     "default"	    :""


        // },




        // //-----------------------------------
        // // Txt
        // //-----------------------------------

        // // {

        // //     "print"		    :'bar',
        // //     "name"		    :'Labor Hours',
        // //     "value"		    :"",
        // //     "required"	    :"",
        // //     "default"	    :""


        // // },

        // {

        //     "print"		    :'spacer',
        //     "name"		    :'',
        //     "value"		    :{

        //         backgroundColor:"transparent",
        //         height:"20px"
        //     },
        //     "required"	    :"",
        //     "default"	    :""


        // },

        // {

        //     "print"		    :'txt',
        //     "name"		    :'Txt',
        //     "value"		    :[

        //         {

        //             // boxCall:'txt',
        //             // boxWidth:"100%",
        //             // boxHeight:230,

        //             className:"Note",
        //             txt:'Please input companies estimated monthly sales. Used to create your "Overhead Percentage"',
        //             style:{

        //                 //fontSize:"16px",
        //                 textAlign:"center"

        //             }

        //         }

        //     ],
        //     "required"	    :"",
        //     "default"	    :""


        // },
        // {

        //     "print"		    :'spacer',
        //     "name"		    :'',
        //     "value"		    :{

        //         backgroundColor:"transparent",
        //         height:"50px"
        //     },
        //     "required"	    :"",
        //     "default"	    :""


        // },










        //-----------------------------------
        // Ledger
        //-----------------------------------

        {

            "print"		    :'bar',
            "name"		    :'Details',
            "value"		    :"",
            "required"	    :"",
            "default"	    :""


        },
        {

            "print"		    :'ledger',
            "name"		    :"Ledger",
            "value"		    :[




                {
                    name:"Projected Monthly Sales",
                    value:display_projected_monthly_sales,
                    // bullet:{
                    //     color:chartColors[1]
                    // }
                },

                {
                    //name:"Actual Monthly Expenses",
                    name:"Expenses + Indirects",
                    value:display_actual_monthly_expenses,
                    // bullet:{
                    //     color:chartColors[0]
                    // }
                },

                {
                    name:"Total Monthly Expenses",
                    value:display_total_expenses,
                    // bullet:{
                    //     color:chartColors[0]
                    // }
                },

                {
                    name:"Total Monthly Indirects",
                    value:display_total_indirects,
                    // bullet:{
                    //     color:chartColors[0]
                    // }
                },


                // {
                //     name:"Total Monthly Owners Indirect",
                //     value:total_ownrs_indirect,
                //     // bullet:{
                //     //     color:chartColors[0]
                //     // }
                // },


                // {
                //     name:"Total Monthly Employees Indirect",
                //     value:total_empls_indirect,
                //     // bullet:{
                //     //     color:chartColors[0]
                //     // }
                // }






            ],
            "required"	    :"",
            "default"	    :""


        },

        // {

        //     "print"		    :'spacer',
        //     "name"		    :'',
        //     "value"		    :{

        //         backgroundColor:"transparent",
        //         height:"20px"
        //     },
        //     "required"	    :"",
        //     "default"	    :""


        // },


        

        //-----------------------------------
        // Txt
        //-----------------------------------

        // {

        //     "print"		    :'bar',
        //     "name"		    :'Labor Hours',
        //     "value"		    :"",
        //     "required"	    :"",
        //     "default"	    :""


        // },
        // {

        //     "print"		    :'txt',
        //     "name"		    :'Txt',
        //     "value"		    :[

        //         {

        //             // boxCall:'txt',
        //             // boxWidth:"100%",
        //             // boxHeight:230,

        //             className:"Note",
        //             txt:'***NOTE: Indirects = Any indirect portion of the Owners and Employees Salaries including Healthcare, IRA contributions etc.',
        //             style:{
    
        //                 //fontSize:"24px",
        //                 textAlign:"center"
    
        //             }

        //         }

        //     ],
        //     "required"	    :"",
        //     "default"	    :""


        // },
        {

            "print"		    :'spacer',
            "name"		    :'',
            "value"		    :{

                backgroundColor:"transparent",
                height:"45px"
            },
            "required"	    :"",
            "default"	    :""


        },






    ];







    //#############################################################
    //#############################################################
    //expenses_indirects_report


    var expenses_indirects_report = {

        "name": "Expenses / Indirects",
        "value": {

            "ei_zyx_expenses": {
                "name": "Expenses",
                "default": "",
                "print": "price",
                "value": total_expenses,
                "required": true
            },
            "ei_zyx_ownrs_indirects": {
                "name": "Owner's Indirects",
                "default": "",
                "print": "price",
                "value": total_ownrs_indirect,
                "required": true
            },
            "ei_zyx_empls_indirects": {
                "name": "Employee's Indirects",
                "default": "",
                "print": "price",
                "value": total_empls_indirect,
                "required": true
            },

            "total": {
                "name": "Total Monthly (Overhead $)",
                "default": 0,
                "print": "price",
                "value": total_expenses_indirects,
                "required": true
            },
            "total_percent": {
                "name": "Total Monthly (Overhead %)",
                "default": 0,
                "print": "percent",
                "value": 100,
                "required": true
            }

        },
        "order": [
            "ei_zyx_expenses",
            "ei_zyx_ownrs_indirects",
            "ei_zyx_ownrs_indirects"
        ]


    };


    //#############################################################
    //#############################################################
    //build chartSections

    chartSections = [];
    if(expenses_indirects_report && expenses_indirects_report !== undefined)
    { 
        pgraph = expenses_indirects_report.value;
 
        //console.log("PGRPAH: "+JSON.stringify(pgraph,null,2));
        if(pgraph['total'] && pgraph['total'] !== undefined)
        {

            total = 0;
            grand_total = pgraph['total'].value;
            if(!isNaN(grand_total)) grand_total = parseFloat(grand_total);

            for(kn in pgraph)
            {

                object = pgraph[kn];

                if(!isNaN(object.value)) value = parseFloat(object.value);
                else value = object.value;

                switch(kn)
                {
                case'total':
                case'total_percent':
                break;
                default:

                    total = parseFloat(value);

                    percent = ( parseFloat(total)/parseFloat(grand_total) ) * 100;
                    percent = percent.toFixed(1);
                    percent = parseFloat(percent);

                    total = total.toFixed(2);


                    // -- set chart sections (cssBarChart) -- //
                    
                    chartSections.push({

                        name    :object.name,
                        value   :percent,
                        data: {
                            percent :percent,
                            price   :total
                        }


                    });      
                    


                }

            }


        }


    }//==

    //console.log("Chart Sections: "+JSON.stringify(chartSections,null,2));








    //#############################################################
    //#############################################################

    items = [];


    if(isOkay)
    {


        keyname = 'OverheadReportExpensesIndirects'; 

        if(VPreportDefaults && VPreportDefaults !== undefined)
        {
            console.log("Category Report Build - Default Items: "+JSON.stringify(VPreportDefaults[keyname],null,2));

            for(item of VPreportDefaults[keyname])
            {

                switch(item.print)
                {
                case'singleBarChart':


                    //item.value[0].data = array;

                    array = [];
                    for(object of chartSections)
                    {
                        percent = object.data.percent;
                        percent = parseFloat(percent);
                        percent = percent.toFixed(1);
                        
                        price = object.data.price;
                        price = LibNumber.PrintMoney(price);
                        if(!price.match(/\$/gi)) price = "$"+price;

                        name = object.name;
            
                        array.push({

                            name:       name,
                            value:      price,
                            percent:    percent

                        });

                    }

                    item.value[0].data = array;



                break;
                case'svg_donut_chart':


                    count = 0;
                    array = [];
                    for(object of chartSections)
                    {
                        percent = object.data.percent;
                        percent = parseFloat(percent);
                        percent = percent.toFixed(1);
                        percent = parseFloat(percent);
                        
                        price = object.data.price;
                        price = LibNumber.PrintMoney(price);
                        if(!price.match(/\$/gi)) price = "$"+price;

                        name = object.name;
                
                        array.push({

                            name:       name,
                            value:      price,
                            percent:    percent

                        });

                        count++;

                    }

                    item.value.items = array;


                
                break;
                case'report_box':


                    //console.log('report_box ---- ' + item.value[0].boxCall);

            
                    //item.value[0].data = array;

                    switch(item.value[0].boxCall)
                    {
                    case'svg_donut_chart':


                        count = 0;
                        array = [];
                        for(object of chartSections)
                        {
                            percent = object.data.percent;
                            percent = parseFloat(percent);
                            percent = percent.toFixed(1);
                            percent = parseFloat(percent);
                            
                            price = object.data.price;
                            price = LibNumber.PrintMoney(price);
                            if(!price.match(/\$/gi)) price = "$"+price;

                            name = object.name;
                    
                            array.push({

                                name:       name,
                                value:      price,
                                percent:    percent

                            });

                            count++;

                        }

                        item.value[0].value.items = array;




                    break;
                    case'chart':


                        count = 0;
                        array = [];
                        for(object of chartSections)
                        {
                            percent = object.data.percent;
                            percent = parseFloat(percent);
                            percent = percent.toFixed(1);
                            percent = parseFloat(percent);
                            
                            price = object.data.price;
                            price = LibNumber.PrintMoney(price);
                            if(!price.match(/\$/gi)) price = "$"+price;

                            name = object.name;
                
                            array.push({

                                name:       name,
                                value:      percent

                            });

                            count++;

                        }

                        item.value[0].value.chart.data.items = array;

                    
                    break;
                    case'donutChart':



                        count = 0;
                        array = [];
                        for(object of chartSections)
                        {
                            percent = object.data.percent;
                            percent = parseFloat(percent);
                            percent = percent.toFixed(1);
                            
                            price = object.data.price;
                            price = LibNumber.PrintMoney(price);
                            if(!price.match(/\$/gi)) price = "$"+price;

                            name = object.name;
                
                            array.push({

                                name:       name,
                                value:      price,
                                percent:    percent,
                                color:      chartColors[count]

                            });

                            count++;

                        }

                        item.value[0].value.donutSections = array;


                    break;
                    case'txt':

                        // {
            
                        //     boxCall:'txt',
                        //     boxWidth:"50%",
                        //     boxHeight:225,
                        //     value:[
            
                        //         {
                        //             className:"Medium",
                        //             txt:"$0.00",
                        //             style:{
            
                        //                 //fontSize:"100px",
                        //                 textAlign:"center"
            
                        //             }
                        //         },
            
                        //         {
                        //             className:"Note",
                        //             txt:"Monthly Expenses",
                        //             style:{
            
                        //                 //fontSize:"13px",
                        //                 textAlign:"center"
            
                        //             }
                        //         }
            
            
                        //     ]
            
            
                        // }

                        //item.value[0].value[0].txt = total_expenses_indirects;


                    break;
                    default:
                    }




                    switch(item.value[1].boxCall)
                    {
                    case'txt':

                        // {
            
                        //     boxCall:'txt',
                        //     boxWidth:"50%",
                        //     boxHeight:225,
                        //     value:[
            
                        //         {
                        //             className:"Medium",
                        //             txt:"$0.00",
                        //             style:{
            
                        //                 //fontSize:"100px",
                        //                 textAlign:"center"
            
                        //             }
                        //         },
            
                        //         {
                        //             className:"Note",
                        //             txt:"Monthly Expenses",
                        //             style:{
            
                        //                 //fontSize:"13px",
                        //                 textAlign:"center"
            
                        //             }
                        //         }
            
            
                        //     ]
            
            
                        // }

                        item.value[1].value[0].txt = display_total_expenses_indirects;


                    break;
                    default:
                    }




                    //console.log(JSON.stringify(array));


                break;
                case'ledger':


                    if(item.value[0].name.match(/total/gi))
                    {
                        total = 0;
                        for(object of chartSections)
                        {
                            price = object.data.price;
                            price = price.replace(/\$/gi,"");
                            price = parseFloat(price);
                            
                            total = total + price;

                        }
                        total = total.toFixed(2);
                        total = LibNumber.PrintMoney(total);
                        if(!total.match(/\$/gi)) total = "$"+total;

                        item.value[0].value = total;

                    }else{



                        if(item.colors && item.colors !== undefined)
                        {

                            //bullet ledger

                            switch(item.colors)
                            {
                            case'ChartColorsA':
            
                                bulletColors = ChartColorsA;
                            break;
                            case'ChartColorsB':
            
                                bulletColors = ChartColorsB;
                            break;
                            case'ChartColorsC':
            
                                bulletColors = ChartColorsC;
                            break;
                            default:
                            }
            

                        }

                        array = [];
                        count = 0;
                        for(object of chartSections)
                        {
                            percent = object.data.percent;
                            percent = parseFloat(percent);
                            percent = percent.toFixed(1);
                            
                            price = object.data.price;
                            price = LibNumber.PrintMoney(price);
                            if(!price.match(/\$/gi)) price = "$"+price;

                            name = object.name;
                            //name = "( "+percent+"% ) "+object.name;
                            value = price;
                            bullet = {

                                color:bulletColors[count]
                            }
                
                            array.push({

                                name:       name,
                                value:      value,
                                percent:    percent,
                                bullet:     bullet

                            });


                            count++;

                        }
                        item.value = array;


                    }


                break;
                default:
                }

                items.push(item);

            }

        }



        reportItems = reportItems.concat(items);


    }//--if






    //console.log("reportItems: "+JSON.stringify(reportItems,null,2));






    //#############################################################
    //#############################################################

    var R = [];


 
    //--------------------------
    // report - top
    //--------------------------

    // R.push({

    //     call:'top',
    //     name:"Overhead", // Created from pathname
    //     controls:[


    //         {

    //             name:"Edit",
    //             // form:{

    //             //     submitCall:'calculate',
    //             //     postTo:apiDomain+apiCall,
    //             //     data:newTemplateExpenseForm,
    //             //     flyr:{

    //             //         hashbang:"#!new",
    //             //         type:"form",
    //             //         styl:{
    //             //             maxWidth:500,
    //             //             fieldType:'composed'   //multi,field,labelfield
    //             //         }

    //             //     }   

    //             // }

    //             // form:{

    //             //     submitCall:'calculate',
    //             //     postTo:apiDomain+"alter-calc-dash",
    //             //     data:VPincome,
    //             //     flyr:{
    //             //         type:"form",
    //             //         styl:{
    //             //             maxWidth:500,
    //             //             fieldType:'field'
    //             //         }
    //             //     }

    //             // }


    //             form:{

    //                 submitCall:'calculate',
    //                 postTo:apiDomain+"alter-calc-dash",
    //                 data:VPincome,
    //                 flyr:{
    //                     type:"form",
    //                     styl:{
    //                         maxWidth:500,
    //                         fieldType:'field'
    //                     }
    //                 }

    //             }


    //         }




    //     ]

    // });





    //--------------------------
    // Report - items
    //--------------------------

    R.push({

        call:'report',
        items:reportItems

    });





    return R;

}



export default Index;
