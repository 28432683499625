
const Merge = (props) => {


    var newFrm; 
    var newData;

    var obj;
    var indx = 0;
    var count;

    var itm;

    //console.log("PROPS: "+JSON.stringify(props.captured,null,2));
    
    var lastTabIndex = 100;

    for(var frm of props.formArr)
    {
        
        switch(frm.section)
        {
        case'costfields':

            //console.log("frm.data: "+JSON.stringify(frm.data,null,2));

            newFrm = {};
            newFrm['section'] = 'costfields';
            newData = [];
            count = 0;
            for(obj of frm.data)
            {
                // console.log("good IN: "+JSON.stringify(obj,null,2));
                // console.log("cost_of_good: "+JSON.stringify(props.captured['cost_of_goods'][count],null,2));

                // console.log("LABEL: "+obj.label.text);
                // console.log("RATE: "+obj.rate.text);
                // console.log("QTYHRS: "+obj.qtyhrs.text);

                // console.log("COG - TITLE: "+props.captured['cost_of_goods'][count]['title']);
                // console.log("COG - RATE: "+props.captured['cost_of_goods'][count]['rate']);
                // console.log("COG - QTYHRS: "+props.captured['cost_of_goods'][count]['qtyhrs']);
                // console.log("COG - IS LABOR: "+props.captured['cost_of_goods'][count]['isLabor']);

                // //alert('Merge cog check');


                obj.label.text = props.captured['cost_of_goods'][count]['title'];
                obj.rate.text = props.captured['cost_of_goods'][count]['rate'];
                obj.qtyhrs.text = props.captured['cost_of_goods'][count]['qtyhrs'];


                if(props.captured['cost_of_goods'][count]['isLabor'] === undefined)
                {
                    obj.rate.isLabor = false;
                }else{
                    obj.rate.isLabor = props.captured['cost_of_goods'][count]['isLabor'];
                    obj.rate.laborId = props.captured['cost_of_goods'][count]['laborId'];
                }
         

                obj.rate.tabIndex = lastTabIndex;
                obj.qtyhrs.tabIndex = lastTabIndex + 1;

                //console.log("Merge - good OUT: "+JSON.stringify(obj,null,2));
                if(count === frm.data.length-1)
                {
                    //console.log(JSON.stringify(obj,null,));
                    lastTabIndex = obj.qtyhrs.tabIndex;
                    //alert('lastCostfieldTabIndex = '+lastCostfieldTabIndex);

                }else{

                    lastTabIndex = lastTabIndex + 2;
                }

 

                newData.push(obj); 
                count++;
            }
            newFrm['data'] = newData;
            props.formArr.splice(indx,1,newFrm);

            //console.log("captured -- cost_of_goods -- "+JSON.stringify(newData,null,2));
            

        break;
        case'bluntfields':


            //console.log(JSON.stringify(frm.data,null,2));

            newFrm = {};
            newFrm['section'] = 'bluntfields';
            newData = [];
            obj = {};
            for(obj of frm.data)
            {
                
    
                /*
                if(v.from === 'calcby' 
                && obj.field.id === 'calcby'
                )
                {   
                    obj.field.text = "0";
                    newData.push(obj); 

                    if(props.captured[obj.field.id] 
                    && props.captured[obj.field.id] !== undefined
                    )
                    {
                        obj.field.text = props.captured[obj.field.id]['value'];

                    }
                    newData.push(obj); 

                }else{

                    if(props.captured[obj.field.id] 
                    && props.captured[obj.field.id] !== undefined
                    )
                    {
                        obj.field.text = props.captured[obj.field.id]['value'];

                    }
                    newData.push(obj); 

                }

                */


                // console.log("obj: "+JSON.stringify(obj,null,2));
                // console.log("field.id..."+JSON.stringify(props.captured[obj.field.id]));


                if(props.captured[obj.field.id]
                && props.captured[obj.field.id] !== undefined
                )
                {
                
                    // console.log("field value..."+props.captured[obj.field.id]["value"]);
                    obj.field.text = props.captured[obj.field.id]['value'];

                    lastTabIndex = parseInt(lastTabIndex) + 1;
                    obj.field.tabIndex = lastTabIndex;

                    if(props.captured[obj.field.id] === 'calcby')
                    {
                        //reset calcby info
                        switch(props.captured[obj.field.id]['call'])
                        {
                        case'markup':
                            obj.display = 'percent';
                            obj.field.fieldType = 'percent';
                        break;
                        default:
                            obj.display = props.captured[obj.field.id]['call'];
                            obj.field.fieldType = props.captured[obj.field.id]['call'];
                        }

                    }

                }



                //debits

                if(props.captured['debits'] 
                && props.captured['debits'] !== undefined
                )
                {
                    if(Object.prototype.toString.call(props.captured['debits']) === '[object Array]')
                    {
                        for(itm of props.captured.debits)
                        {
                            if(itm.id === obj.field.id)
                            {
                                obj.field.text = itm.value;

                                lastTabIndex = parseInt(lastTabIndex) + 1;
                                obj.field.tabIndex = lastTabIndex;
                                break;

                            }
                        }

                    }

                }
                newData.push(obj); 
               

            }
            newFrm['data'] = newData;
            props.formArr.splice(indx,1,newFrm);
            //console.log("newData..."+JSON.stringify(newData,null,2));
        
        break;
        default:
        break;
        }//==

        indx++;

    }

    // console.log(JSON.stringify(props.formArr,null,2));
    // alert('Merge - checking newArr');


    return props.formArr;


}

export default Merge;