// import { 
//     useSelector, 
//     //useDispatch 
// } 
// from 'react-redux';
// //import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    //useHistory,   
    //useParams,



} from "react-router-dom";


import {

    BuildOverhead,
    BuildExpense,
    BuildEmpl,
    BuildOwnr

} from './Builds';


const Build = (props) => {



    // console.log('Flyr - Build - props: '+JSON.stringify(props,null,2));


    // -- redux -- //
    //const dispatch = useDispatch();
    //const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));



    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();

 
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);



    //-----------------------------------------
    //-----------------------------------------

    var pathArr = pathname.split("/");
    var pathCount = pathArr.length;

    var currIndex = parseInt(pathCount)-1;
    var categoryName = pathArr[currIndex];
    var currentPage = pathArr[currIndex];


    //var categoryIndex = parseInt(pathCount)-2;
    //var nextIndex = categoryIndex + 1;

    //var categoryCount = currIndex;

    //console.log("categoryName: "+categoryName);


    var R = [];
    var items




    //#############################################################
    //#############################################################
    //set from props

    //console.log("FLYR REPORT Build - props: "+JSON.stringify(props,null,2));

    if(props.data.name && props.data.name !== undefined)
    { 
        categoryName = props.data.name;
    }





    //#############################################################
    //#############################################################

    //--------------------------
    // Catalog - Top
    //--------------------------

    R.push({

        call:'top',
        name:categoryName, // Created from pathname
        controls:[

            // {

            //     name:"Create Template",
            //     // form:{

            //     //     submitCall:'calculate',
            //     //     postTo:apiDomain+apiCall,
            //     //     data:newTemplateExpenseForm,
            //     //     flyr:{

            //     //         hashbang:"#!new",
            //     //         type:"form",
            //     //         styl:{
            //     //             maxWidth:500,
            //     //             fieldType:'composed'   //multi,field,labelfield
            //     //         }

            //     //     }   

            //     // }

            // }

        ]

    });







    // //#############################################################
    // //#############################################################

    // //--------------------------
    // // Catalog - Bar
    // //--------------------------

    // R.push({

    //     call:'bar',
    //     items: [

    //         {

    //             name:"Title 1"

    //         },

    //         {

    //             name:"Actions"

    //         }

    //     ]
        
    // });
    // //#############################################################
    // //#############################################################

    // //--------------------------
    // // Catalog - Items
    // //--------------------------


    // items = [];

    // if(VPreportDefaults && VPreportDefaults !== undefined)
    // {
    //     for(item of VPreportDefaults['defaultItems'])
    //     {
    //         items.push(item);
    //     }

    // }

    // R.push({

    //     call:"items",
    //     items:items

    // });





    //#############################################################
    //#############################################################

    //--------------------------
    // Catalog - Items
    //--------------------------

    items = [];

    switch(currentPage.toLowerCase())
    {
    case'overhead':

        items = BuildOverhead(props);
    break;
    case'expenses':   

        items = BuildExpense(props);
    break;
    case'employees':  

        items = BuildEmpl(props);
    break;
    case'owners':   

        items = BuildOwnr(props);
    break;
    default:
    }//==


    R.push({

        call:"items",
        items:items

    });

    return R;

}



export default Build;
