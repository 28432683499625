import ComposeFields from './Compose/Fields';
import ComposeLabelFields from './Compose/LabelFields';
import ComposeBluntFields from './Compose/BluntFields';
import ComposeCostFields from './Compose/CostFields';
//import ComposeCalcby from './Compose/Calcby';

const FormComposer = (props) => {


    let R = [];

    //console.log("PROPS DATA: "+JSON.stringify(props.data,null,2));
  

    var keyname;

    //var key;
    //var value;

    var data; 
    var f;

    var object;
    //var obj;

    //var array;
    //var title;   
    //var group; 
    //var def;
    //var val;


    var bluntfields;
    var labfields;

    // console.log("COMPOSER DATA: "+JSON.stringify(props.data,null,2));
    // console.log("COMPOSER STYL: "+JSON.stringify(props.styl,null,2));





    for(object of props.array)
    {
        switch(object.styl.fieldType)
        {
        case'heading':


            R.push({

                section:"heading",
                data:object.data

            });

        break;
        case'header':


            R.push({

                section:"header",
                data:object.data

            });

        break;
        case'headline':


            R.push({

                section:"headline",
                data:object.data

            });

        break;
        case'spacer':


            R.push({

                section:"spacer",
                data:object.data

            });


        break;
        case'separator':


            R.push({

                section:"separator",
                data:object.data

            });


        break;
        case'graphictext':


            R.push({

                section:"graphictext",
                data:object.data

            });


        break;
        case'pricepercent':


            R.push({

                section:"pricepercent",
                data:object.data

            });


        break;
        case'ledgerplus':


            R.push({

                section:"ledgerplus",
                data:object.data

            });


        break;
        case'ledger':


            R.push({

                section:"ledger",
                data:object.data,
                styling:object.styling

            });


        break;
        case'grid':

            R.push({

                section:"grid",
                data:object.data

            });

        break;
        case'titlegrid':


            R.push({

                section:"titlegrid",
                data:object.data

            });


        break;
        case'field':


            for(keyname in object.data)
            {
                if(object.data[keyname].print === undefined 
                )
                {
                    switch(keyname)
                    {
                    case'name':

            
                        R.push({

                            section:"titleline",
                            data:{
        
                                text : object.data[keyname]
                            }
        
                        });
        

                    break;
                    case'value':
            
                        switch(Object.prototype.toString.call(object.data[keyname]))
                        {
                        case'[object Array]':
                        break;
                        case'[object Object]':

                            data = ComposeFields(object.data[keyname]);
                            //console.log(JSON.stringify(data,null,2));
                            for(f of data)
                            {
                                // console.log(JSON.stringify(f,null,2));
                                R.push(f);
                            }
                            

                        break;
                        case'[object String]':
                        break;
                        default:
                        }
            
                    break;
                    default:
                    break;
                    }//==


                }else{


                    // console.log("utils/Flyr/Form/FormComposer - has print");

                }


            }//forend



        break;
        case'labelfield':


            for(keyname in object.data)
            {
                if(object.data[keyname].print === undefined 
                )
                {
                    switch(keyname)
                    {
                    case'name':

            
                        R.push({

                            section:"titleline",
                            data:{
        
                                text : object.data[keyname]
                            }
        
                        });
        

                    break;
                    case'value':
            
                        switch(Object.prototype.toString.call(object.data[keyname]))
                        {
                        case'[object Array]':
                        break;
                        case'[object Object]':

                            labfields = ComposeLabelFields(object.data[keyname]);

                            R.push({

                                section :'labelfields',
                                data    :labfields

                            });
            
                        break;
                        case'[object String]':
                        break;
                        default:
                        }
            
                    break;
                    default:
                    break;
                    }//==


                }else{


                    // console.log("utils/Flyr/Form/FormComposer - has print");

                }


            }//for

        break;
        case'bluntfield':



            // //--------------------------------
            // //profit
            // //--------------------------------

            // {


            //     display : 'percent',

            //     label: {

            //         "text"               	    :"Profit"
            //     },

            //     field: {


            //         "fieldtype"               	:"text",
            //         "required"					:required_calcby,

            //         "class"                   	:'',
            //         "id"                        :'calcby',
            //         "hint"                    	:"",

            //         "text"                     	:def_calcby,

            //         "linecolor"                 :"#00ff00",


            //         "keyboardType"            	:"",
            //         "autocorrect"             	:"false",
            //         "autocapitalizationType"  	:"none",


            //         //"secure"                  :true,
            //         //"returnKeyType"           :"done"


            //     }




            // }





            for(keyname in object.data)
            {
                if(object.data[keyname].print === undefined 
                )
                {
                    switch(keyname)
                    {
                    case'name':

            
                        R.push({

                            section:"titleline",
                            data:{
        
                                text : object.data[keyname]
                            }
        
                        });
        

                    break;
                    case'value':
            
                        switch(Object.prototype.toString.call(object.data[keyname]))
                        {
                        case'[object Array]':
                        break;
                        case'[object Object]':

                            bluntfields = ComposeBluntFields(object.data[keyname]);

                            R.push({

                                section :'bluntfields',
                                data    :bluntfields

                            });
            
                        break;
                        case'[object String]':
                        break;
                        default:
                        }
            
                    break;
                    default:
                    break;
                    }//==


                }else{


                    // console.log("utils/Flyr/Form/FormComposer - has print");

                }


            }//for






        break;
        case'costfield':


            // //--------------------------------
            // //labor
            // //--------------------------------

            // {

            //     label: {

            //         "text"               	    :"Labor"
            //     },

            //     rate: {


            //         "fieldtype"               	:"text",
            //         "required"					:required_rate_labor,

            //         "class"                   	:'',
            //         "id"                      	:'rate_labor',
            //         "hint"                    	:"",

            //         "text"                     	:def_rate_labor,



            //         "keyboardType"            	:"",
            //         "autocorrect"             	:"false",
            //         "autocapitalizationType"  	:"none",


            //         //"secure"                  :true,
            //         //"returnKeyType"           :"done"


            //     },

            //     qtyhrs: {


            //         "fieldtype"               	:"text",
            //         "required"					:required_qtyhrs_labor,

            //         "class"                   	:'',
            //         "id"                      	:'qtyhrs_labor',
            //         "hint"                    	:"",

            //         "text"                     	:def_qtyhrs_labor,



            //         "keyboardType"            	:"",
            //         "autocorrect"             	:"false",
            //         "autocapitalizationType"  	:"none",


            //         //"secure"                  :true,
            //         //"returnKeyType"           :"done"


            //     }


            // },




            for(keyname in object.data)
            {
                if(object.data[keyname].print === undefined 
                )
                {
                    switch(keyname)
                    {
                    case'name':

            
                        R.push({

                            section:"titleline",
                            data:{
        
                                text : object.data[keyname]
                            }
        
                        });
        

                    break;
                    case'value':
            
                        switch(Object.prototype.toString.call(object.data[keyname]))
                        {
                        case'[object Array]':
                        break;
                        case'[object Object]':

                            bluntfields = ComposeCostFields(object.data[keyname]);

                            R.push({

                                section :'costfields',
                                data    :bluntfields

                            });
            
                        break;
                        case'[object String]':
                        break;
                        default:
                        }
            
                    break;
                    default:
                    break;
                    }//==


                }else{


                    // console.log("utils/Flyr/Form/FormComposer - has print");

                }


            }//for




        break;
        case'calcby':



            // //------------------------------------
            // //calcby
            // //------------------------------------
            // {

            //     "section"   :"calcby",
            //     "data"      :{

            //         label:  "Calculate By",
            //         array:  [

            //             {
            //                 call:"markup",
            //                 bgPosition:"-0px -100px",
                            
            //             },   
                    
                    
            //             {
            //                 call:"price",
            //                 bgPosition:"-0px -50px",
                            
                    
            //             },
                    
            //             {
            //                 call:"percent",
            //                 bgPosition:"-0px -0px",
                            
                    
            //             }
                

            //         ]

            //     }

            
            // },




            R.push({

                section:"calcby",
                data:object.data

            });






        break;
        case'iconbutton':
        case'button':
        case'link':



            //------------------------------------
            //button
            //------------------------------------

            // {
            //     "section"   :"button",
            //     "data"      :{

            //         "text"  :'Test Dispatch',
            //         "class" :'button',
            //         "funct"	:{

            //             "name":"FormSubmit",
            //             "object":false

            //         }
                    
            //     }

            // },

            //------------------------------------
            //link
            //------------------------------------

            // {
            //     "section"   :"link",
            //     "data"      :{

            //         "text"  :'Forgot Password',
            //         "class" :'btn-link',
            //         "funct"	:{

            //             "name":"Run_Query",
            //             "object":{

            //                 "case":"changezone",
            //                 "replace":false,
            //                 "pathto":"/forgot",
            //                 "qvars":false

            //             }


            //         }
                    
            //     }

            // },
                    



            R.push({

                section:object.styl.fieldType,
                data:object.data

            });




        break;
        default:

            // console.log(":::::::::::\n"+JSON.stringify(props.data,null,2));

            R = object.data;

        }


    }//forend





    //console.log(JSON.stringify(R,null,2));
    return R;


};

export default FormComposer;
