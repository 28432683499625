


import React, { 

    useState, 
    useEffect,
    //useRef

} from 'react';



//import 'app-style/price-form/style.css';
//import '../../StyleSheets/Readout.css';
import 'app-components/Zone/_StyleSheets/Readout.css';




export const FFiconbutton = (props) => {


    //var _FUNC = props.data.funct['name'] + "()";
    //var _FUNC = props.data.funct['name'] + "('"+props.data.funct['object']+"')";
    //var _RUN = new Function(_FUNC);

    const [buttonStyle, setButtonStyle] = useState({});

   //----------------------------------------------------------



   
    // Note: the empty deps array [] means
    // this useEffect will run once
    // similar to componentDidMount()
    useEffect(() => {

        setButtonStyle({

            bgImage:    props.data.style.bgImage,
            bgPosition: props.data.style.bgPositionOut,
            bgColor:    props.data.style.bgColorOut,
            txtColor:   props.data.style.txtColorOut,
            brColor:    props.data.style.brColorOut,

        });

    },[props])

    






    const handleSetStyle = (v) => {

        
        if(v.call === 'over')
        { 

            setButtonStyle({

                bgImage:    props.data.style.bgImage,
                bgPosition: props.data.style.bgPositionOver,
                bgColor:    props.data.style.bgColorOver,
                txtColor:   props.data.style.txtColorOver,
                brColor:    props.data.style.brColorOver
    
            });
    

        }else{

            setButtonStyle({

                bgImage:    props.data.style.bgImage,
                bgPosition: props.data.style.bgPositionOut,
                bgColor:    props.data.style.bgColorOut,
                txtColor:   props.data.style.txtColorOut,
                brColor:    props.data.style.brColorOut

            });
    
        }//==


    }



    return (

        <div 
        key={props.indx+"_fficonbutton"} 
        id={props.indx+"_fficonbutton"}
        className = "icon-button"
        style={{

            backgroundColor     :buttonStyle.bgColor,
            width               :"80%",
            margin              :"0 0 0 10%",
            color               :buttonStyle.txtColor,
            border              :"3px solid "+buttonStyle.brColor,
            //borderRadius        :"0px"

        }}
        >



            <div 
            id={props.indx+"_fficonbutton_mask"}
            className = "mask"

            onClick={() => props.handle({

                call        :"button",
                actionCall  :"submit",
                data        :props.data
    
            })}
    
            onMouseEnter={() => handleSetStyle({
    
                call        :"over",
            })}
    
            onMouseOut={() => handleSetStyle({
    
                call        :"out"
    
            })}
            > 
            </div>



            <div 
            id={props.indx+"_fficonbutton_spr"}
            className = "spr"
            style={{

                backgroundPosition: buttonStyle.bgPosition,
            }}
            > 
            </div>

            <div
            id={props.indx+"_fficonbutton_txt"}
            className = "txt"
            style={{

                color: buttonStyle.txtColor

            }}
            >
                {props.data.text}

            </div>

            
        </div>

    )

}









