import styled from "styled-components";


export const DonutChart = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "1px solid lightgray",

    width:              props.width || "100%",
    height:             props.height || "100%",
    minHeight:          props.minHeight || "100px",

    

    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "0 0 0 0",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "20px",
    fontWeight:         props.fontWeight  || "500",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration  || "none",
    textColor:          props.textColor || "black"



    // border-bottom:${props => props.border};
    // border-left:${props => props.border};
    // border-right:${props => props.border};




    // cursor:pointer;



    // @media (max-width: 300px) {

    //     height:80px;
    // }



    // &:hover {

    //     background-color:#caffca;
    //     text-decoration:underline;
    // }


    // .Bars {

    //     background-color:transparent;
    //     width:100%;
    //     height:375px;
    //     position:absolute;
    //     top:0px;
    //     left:0px;
    //     z-index:3;

    // }
  



}))`


    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    height:${props => props.height};
    min-height:${props => props.minHeight};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;


    font-family:${props => props.fontFamily};
    font-size:${props => props.fontSize};
    font-weight:${props => props.fontWeight};
    text-align:${props => props.textAlign};
    text-decoration:${props => props.textDecoration};
    color:${props => props.textColor};



`;




    //############################################################
    //############################################################

    export const Title = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "white",
        border:             props.border || "1px solid lightgray",

        width:              props.width || "100%",
        height:             props.height || "auto",
        minHeight:          props.minHeight || "15px",

        margin:             props.margin || "0 0 0 0",
        padding:            props.padding || "10px 0 8px 0",

        fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
        fontSize:           props.fontSize || "20px",
        fontWeight:         props.fontWeight  || "500",
        textAlign:          props.textAlign || "center",
        textDecoration:     props.textDecoration  || "none",
        textColor:          props.textColor || "black"


    }))`


        background-color:${props => props.backgroundColor};


        width:${props => props.width};
        height:${props => props.height};
        min-height:${props => props.minHeight};

        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;
        float:left;

        overflow:hidden;

        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        text-align:${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.textColor};



    `;






    //############################################################
    //############################################################

    export const StaticParts = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",

        width:              props.width || "100%",
        height:             props.height || "auto",
        minHeight:          props.minHeight || "100px",

        margin:             props.margin || "0 0 0 0",
        padding:            props.padding || "0 0 0 0",



        // donut stuff

        donutSize:                      props.donutSize || "250px",
        donutHalfSize:                  props.donutHalfSize || "125px",
        donutCenterSize:                props.donutCenterSize || "125px",
        donutCenterTopLeft:             props.donutCenterTopLeft || "62.5px",
        donutCenterBackgroundColor:     props.donutCenterBackgroundColor || "#edeef1", //"#394264",
        donutCenterBorderRadius:        props.donutCenterBorderRadius || "100%",




        // centerFont

        fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
        fontSize:           props.fontSize || "50px",
        fontWeight:         props.fontWeight  || "550",
        textAlign:          props.textAlign || "center",
        textDecoration:     props.textDecoration  || "none",
        textColor:          props.textColor || "white"



        // .clip {

        //     border-radius: 50%;
        //     clip: rect(0px, 200px, 200px, 100px);
        //     width: 100%;
        //     height: 100%;
        //     position: absolute;

        // }
        
        // .item {

        //     border-radius: 50%;
        //     clip: rect(0px, 100px, 200px, 0px);
        //     width: 100%;
        //     height: 100%;
        //     position: absolute;
        //     font-family: monospace;
        //     font-size: 1.5rem;
        // }
        



    }))`


        background-color:${props => props.backgroundColor};

        width:${props => props.width};
        height:${props => props.height};
        min-height:${props => props.minHeight};
    
        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;
        float:left;

        overflow:hidden;


        .block {

            background:transparent;  
            border-radius:0px;  

            width:100%;
            margin:0 0 0 0;

            position:relative;
            float: left;

            overflow: hidden;
        }
        
        .donut-chart-block {
            overflow: hidden;
        }
        
        .donut-chart {

            width:${props => props.donutSize};
            height:${props => props.donutSize};

            position: relative;
            margin: 0 auto;
            border-radius: 100%
        }
        
        .donut-chart .center {

            background:${props => props.donutCenterBackgroundColor};
            border-radius:${props => props.donutCenterBorderRadius};

            width:${props => props.donutCenterSize};
            height:${props => props.donutCenterSize};

            position: absolute;
            top:${props => props.donutCenterTopLeft};
            left:${props => props.donutCenterTopLeft};
         
        }

            .donut-chart .center .txt {

                background-color:transparent;

                width:96%;
                height:auto;

                margin:calc(50% - 33px) 0 0 2%;
                padding:4px 0 4px 0;

                position:relative;
                float:left;

                font-family:${props => props.fontFamily};
                font-size:${props => props.fontSize};
                font-weight:${props => props.fontWeight};
                text-align:${props => props.textAlign};
                text-decoration:${props => props.textDecoration};
                color:${props => props.textColor};

            }



        
        .clip {

            border-radius: 50%;
            clip: rect(0px, ${props => props.donutSize}, ${props => props.donutSize}, ${props => props.donutHalfSize});
            width: 100%;
            height: 100%;
            position: absolute;

        }
        
        .item {

            border-radius: 50%;
            clip: rect(0px, ${props => props.donutHalfSize}, ${props => props.donutSize}, 0px);
            width: 100%;
            height: 100%;
            position: absolute;
            font-family: monospace;
            font-size: 1.5rem;
        }
        
        

        .segment {

            transform: rotate(0deg);

        }
        
            .segment .item {
                background-color: #11A8AB;
                transform: rotate(76deg);
            }
        





    `;


















