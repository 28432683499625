import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';



/*

import { 
    //Lorem, 
    //TestImages,
    //TestVideos,
    //SpecialImages,
    //NavigationData 
} from 'app-components/Prime/Data';


// import {

//     //TextCard1,
//     //TextCard2,
//     //TextCard3,

//     //ImageTextCard1,
//     //ImageTextCard2,
//     //ImageTextCard3


// } from 'app-components/Zone/Builds/Samples/Card';



*/



// import { Settings } from 'app-components/Prime/Data.js';

import { 

    VPformTemplateOwnr,
    //VPformTemplateEmpl,

    //VPcompanyInfo, 
    VPexpenses,
    VPincome,   
    VPoverhead, 

    VPownrsYearly,
    VPownrsMonthly,
    //VPownrsDirect,
    //VPownrsIndirect,
    //VPownrs,
    VPownr,

    //VPempls,
    VPempl,

    //VPindirectLabor,
    //VPidlEmployee


} from 'app-components/Prime/VPdata/form.js';



import { LibBase } from "library";




import ApiResources from 'utils/components/Pages/ApiResources';



const SceneInfo = () => {



    //-------------------------------------------

    var apiDomain;
    var hostname;
    //var hostname = window.location.hostname;//returns the domain name of the web host
    var protocol = window.location.protocol;//returns the web protocol used (http: or https:)

    if(window.location.hostname.match(/192.168/gi))
    {
        hostname = ApiResources.network['3001'].vpc;
        apiDomain = protocol + "//" + hostname + "/";
    }
    else
    if(window.location.hostname.match(/localhost/gi))
    {
        hostname = ApiResources.local['3001'].vpc;
        apiDomain = protocol + "//" + hostname + "/";
    }else{
        hostname = ApiResources.aws['x'].vpc;
        apiDomain = "https://" + hostname + "/";
    }//=

    //-------------------------------------------

    //console.log("API DOMAIN: "+apiDomain);

    //const apiCall = 'alter-calc-test';
    const apiCall = 'alter-calc-dash';



    // -- redux -- //
    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));



    //var calc_overhead = {};

    //var actual_monthly_expenses;
    //var projected_monthly_sales;
    //var overhead_percent;
    var owners = {};   
    var employees = {};

    var ownrResults = {};
    var emplResults = {};
    
    //actual_monthly_expenses = VPmonthlyExpenses.value;
    //projected_monthly_sales = VPmonthlySales.value;
    //projected_monthly_sales = VPoverhead.settings.value.projected_monthly_sales;
    //overhead_percent = 75.5 + "%";





    var keyname;
    var kn;
    var item;
    //var name;
    var value;
    var newValue;


    //const IDLemployee = Object.assign({}, VPidlEmployee);
    //const IDLemployee = JSON.parse(JSON.stringify(VPidlEmployee));


    var templateOwnrForm;
    var newTemplateOwnrForm;

    var template_ownr;

    var array;
    var object;
    var obj;
    var val;

    var i;


    var pgraph;
    var noDelete;
    var confirmDelete;



    var isOkay;
    isOkay = false;
    if(zoneObj.calc_dash 
    && zoneObj.calc_dash !== undefined
    )
    {
        if(Object.keys(zoneObj.calc_dash.form_items).length > 0
        && Object.keys(zoneObj.calc_dash.result_items).length > 0
        )
        {
            isOkay = true;
        }

    }
    if(isOkay)
    {


        //console.log("FORM ITEMS: "+JSON.stringify(zoneObj.calc_dash.form_items));

        for(keyname in zoneObj.calc_dash.form_items)
        {


            // -- owners -- //

            if(keyname.match(/ownr/gi))
            {
                item = zoneObj.calc_dash.form_items[keyname];
                value = LibBase.CloneIt(VPownr.value);//Deep Clone
                newValue = {};
                for(kn in value)
                {
                    if(kn === 'ownr_call') 
                    {
                    }else{
                        newValue[kn] = item.value[kn];       
                    }
                    
                }
                owners[keyname] = {
                    name:item.name,
                    value:newValue
                };



 

                item = zoneObj.calc_dash.result_items[keyname];
                if(Object.prototype.toString.call(item) === '[object Object]')
                {
                    value = LibBase.CloneIt(item);

                    value.id = keyname;
                    ownrResults[keyname] = value;

                }

            
            }




            // -- employees -- //

            else
            if(keyname.match(/empl/gi))
            {

                item = zoneObj.calc_dash.form_items[keyname];
                value = LibBase.CloneIt(VPempl.value);//Deep Clone
                newValue = {};
                for(kn in value)
                {
                    if(kn === 'empl_call') 
                    {
                    }else{
                        newValue[kn] = item.value[kn];       
                    }
                    
                }
                employees[keyname] = {
                    name:item.name,
                    value:newValue
                };


 

                item = zoneObj.calc_dash.result_items[keyname];
                if(Object.prototype.toString.call(item) === '[object Object]')
                {
                    value = LibBase.CloneIt(item);

                    value.id = keyname;
                    emplResults[keyname] = value;

                }
 

            }




            // -- other overhead -- //

            else
            {
    
                item = zoneObj.calc_dash.form_items[keyname];


                if(keyname.match(/expense-/gi))
                {
                    VPoverhead[keyname] = {
                        name:item.name,
                        value:item.value
                    };

                }else{

                    value = LibBase.CloneIt(VPoverhead[keyname].value);//Deep Clone
                    newValue = {};
                    for(kn in value)
                    {
                        newValue[kn] = item.value[kn];
                    }
                    VPoverhead[keyname] = {
                        name:item.name,
                        value:newValue
                    };

                }


            }

        }// -- for

        


        VPexpenses.value.monthly_expenses.value = zoneObj.calc_dash.result_items['totals'].total;
        VPincome.value.projected_monthly_sales.value = zoneObj.calc_dash.form_items['settings'].value.projected_monthly_sales.value;


        //overhead_percent = zoneObj.calc_dash.result_items['overhead'].overhead;
        //overhead_percent = parseFloat(overhead_percent) 
        //overhead_percent = overhead_percent.toFixed(1) + "%";



        //console.log("IDL EMPLOYEES: "+JSON.stringify(idl_employees,null,2));

    
        /*
        for(keyname in zoneObj.calc_dash.result_items)
        {

            console.log("KEYNAME: "+keyname);

            item = zoneObj.calc_dash.result_items[keyname];
            console.log(JSON.stringify(item,null,2));

        
        }// -- for
        */







        //------------------------------------------
        // -- owners - totals - cards 
        //------------------------------------------

        var total_ownr_salary_pretax_yearly = zoneObj.calc_dash.result_items['totals'].total_ownr_salary_pretax_yearly;
        var total_ownr_taxes_yearly = zoneObj.calc_dash.result_items['totals'].total_ownr_taxes_yearly;
        var total_ownr_ira_yearly = zoneObj.calc_dash.result_items['totals'].total_ownr_ira_yearly;
        var total_ownr_health_insurance_yearly = zoneObj.calc_dash.result_items['totals'].total_ownr_health_insurance_yearly;
        var total_ownr_burden_yearly = zoneObj.calc_dash.result_items['totals'].total_ownr_burden_yearly;

        if(isNaN(total_ownr_salary_pretax_yearly)) total_ownr_salary_pretax_yearly = 0;
        if(isNaN(total_ownr_taxes_yearly)) total_ownr_taxes_yearly = 0;
        if(isNaN(total_ownr_ira_yearly)) total_ownr_ira_yearly = 0;
        if(isNaN(total_ownr_health_insurance_yearly)) total_ownr_health_insurance_yearly = 0;
        if(isNaN(total_ownr_burden_yearly)) total_ownr_burden_yearly = 0;

        VPownrsYearly.value.total_ownr_salary_pretax_yearly.value = total_ownr_salary_pretax_yearly;
        VPownrsYearly.value.total_ownr_taxes_yearly.value = total_ownr_taxes_yearly;      
        VPownrsYearly.value.total_ownr_ira_yearly.value = total_ownr_ira_yearly;
        VPownrsYearly.value.total_ownr_health_insurance_yearly.value = total_ownr_health_insurance_yearly;
        VPownrsYearly.value.total_ownr_burden_yearly.value = total_ownr_burden_yearly;

        
        

        var total_ownr_salary_pretax_monthly = zoneObj.calc_dash.result_items['totals'].total_ownr_salary_pretax_monthly;
        var total_ownr_taxes_monthly = zoneObj.calc_dash.result_items['totals'].total_ownr_taxes_monthly;
        var total_ownr_ira_monthly = zoneObj.calc_dash.result_items['totals'].total_ownr_ira_monthly;
        var total_ownr_health_insurance_monthly = zoneObj.calc_dash.result_items['totals'].total_ownr_health_insurance_monthly;
        var total_ownr_burden_monthly = zoneObj.calc_dash.result_items['totals'].total_ownr_burden_monthly;

        if(isNaN(total_ownr_salary_pretax_monthly)) total_ownr_salary_pretax_monthly = 0;
        if(isNaN(total_ownr_taxes_monthly)) total_ownr_taxes_monthly = 0;
        if(isNaN(total_ownr_ira_monthly)) total_ownr_ira_monthly = 0;
        if(isNaN(total_ownr_health_insurance_monthly)) total_ownr_health_insurance_monthly = 0;
        if(isNaN(total_ownr_burden_monthly)) total_ownr_burden_monthly = 0;

        VPownrsMonthly.value.total_ownr_salary_pretax_monthly.value = total_ownr_salary_pretax_monthly;
        VPownrsMonthly.value.total_ownr_taxes_monthly.value = total_ownr_taxes_monthly;      
        VPownrsMonthly.value.total_ownr_ira_monthly.value = total_ownr_ira_monthly;
        VPownrsMonthly.value.total_ownr_health_insurance_monthly.value = total_ownr_health_insurance_monthly;
        VPownrsMonthly.value.total_ownr_burden_monthly.value = total_ownr_burden_monthly;




    }// -- if

    //console.log("_overhead A: "+JSON.stringify(_overhead,null,2));

    //_overhead = VPoverhead;
    //console.log("_overhead B: "+JSON.stringify(_overhead,null,2));





    

    //#############################################################
    //#############################################################

    var R = [];
    //var index;

    //--------------------------
    // Catalog - Top
    //--------------------------

    newTemplateOwnrForm = LibBase.CloneIt(VPformTemplateOwnr);//Deep Clone

    R.push({

        call:'top',
        name:"Owners",
        controls:[

            {

                name:"Create Owner",
                form:{

                    submitCall:'calculate',
                    postTo:apiDomain+apiCall,
                    data:newTemplateOwnrForm,
                    flyr:{

                        hashbang:"#!new",
                        type:"form",
                        styl:{
                            maxWidth:600,
                            fieldType:'composed'   //multi,field,labelfield
                        }

                    }   

                }

            }

        ]

    });












    //#############################################################
    //#############################################################

    //--------------------------
    // Catalog - Bar
    //--------------------------

    R.push({

        call:'bar',
        items: [

            {

                name:"Name"

            },

            {

                name:"Actions"

            }

        ]
        
    });






    


    //#############################################################
    //#############################################################

    //--------------------------
    // Catalog - Items
    //--------------------------

    var catalogItems = [];

    noDelete = false;
    if(Object.keys(owners).length === 1) noDelete = true;

    //index = 0;
    for(keyname in owners)
    {

        switch(keyname)
        {
        case'settings':
        case'work_schedule':
        break;
        default:



            

            //-----------------------------
            //pgraph
            //-----------------------------

            pgraph = LibBase.CloneIt(owners[keyname].value);
            for(kn in pgraph)
            {
                switch(kn)
                {
                case'ownr_labor_percent':

                    pgraph[kn].value = pgraph[kn].value +"/"+ (100 - pgraph[kn].value);             
                break;
                default:
                }
            }

            



            //-----------------------------
            //Set Delete Action
            //-----------------------------

            if(noDelete)
            {
                confirmDelete = false;
            }else{

                confirmDelete = {

                    hashbang:"#!delete",//#!somename or false
                    call:'delete',
                    message:[

                        {
                            "call":"heading",
                            "text":"Confirm" 
                        },
                        {
                            "call":"span",
                            //"text":"Update was Successfull!!" 
                            "text":'Do you want to delete owner "'+owners[keyname].name+'" ?',
                        }
                
                    ],

                    submitCall:'calculate',
                    postTo:apiDomain+apiCall,
                    keyname:keyname,
                    data:owners[keyname],
                    flyr:{
                        type:"confirm",
                        styl:{
                            maxWidth:525,
                            fieldType:'labelfield'
                        }
                    }

                }

            }



            //-----------------------------
            //Owner Form 
            //-----------------------------

            //console.log(keyname + " : " + JSON.stringify(owners[keyname],null,2));


            var tabIndex = 100;

            template_ownr = [];
            templateOwnrForm = LibBase.CloneIt(VPformTemplateOwnr);//Deep Clone
            for(object of templateOwnrForm)
            {
                switch(object['section'])
                {
                case'titleline':

                    object.data.text = owners[keyname].name;

                    template_ownr.push({
    
                        section :object.section,
                        data    :object.data
    
                    });
    
    
                break;
                case'fields':
    

                    /*
                    //==========================================
                    //fields
                    //==========================================
                    {
    
                        "section"   :"fields",
                        "data"      :[
    
    
                            //--------------------------------
                            //
                            //--------------------------------
                            {
    
                                "fieldtype"               	:"text",
                                "required"					:required_name,
    
                                "class"                   	:'',
                                "id"                      	:'ownr_name',
                                "hint"                    	:"Name",
    
                                "text"                     	:def_name,
    
                                "keyboardType"            	:"",
                                "autocorrect"             	:"false",
                                "autocapitalizationType"  	:"none",
    
    
                                //"secure"                  :true,
                                //"returnKeyType"           :"done"
    
    
                            },
    
    
                        ]
    
                        
    
                    }
    
                    */
    
    
                    array = [];
                    for(obj of object.data)
                    {
                        switch(obj.fieldtype)
                        {
                        case'percent':
    
                            val = obj.text;
                            if(obj.id.match(/ownr_/gi))
                            {
                                val = owners[keyname].value[obj.id].value;
                            }
                            if(val !== '' 
                            && val !== undefined 
                            && !isNaN(val))
                            {
                                val = parseFloat(val).toFixed(1);
                            }else{
                                val = 0.0;
                            }
                            obj.text = val;
    
                        break;
                        case'price':
    
                            val = obj.text;
                            if(obj.id.match(/ownr_/gi))
                            {
                                val = owners[keyname].value[obj.id].value;
                            }
                            if(val !== '' 
                            && val !== undefined 
                            && !isNaN(val))
                            {
                                val = parseFloat(val).toFixed(2);
                            }else{
                                val = 0.0;
                            }
                            obj.text = val;
    
                        break;
                        default:

                            val = obj.text;
                            if(obj.id.match(/ownr_/gi))
                            {
                                val = owners[keyname].value[obj.id].value;
                            }
                            obj.field.text = val;

                            obj.field.tabIndex = tabIndex;
                            tabIndex = parseInt(tabIndex) + 1;


                        }
    
                        array.push(obj);
    
                    }//==
    
                
                    template_ownr.push({
    
                        section :object.section,
                        data    :array
    
                    });
    
    
                break;
                case'labelfields':
    
    
    
                    /*
                    //--------------------------------
                    //
                    //--------------------------------
    
                    {
    
                        label: {
    
                            "text"               	    :"Name"
                        },
    
                        field: {
    
    
                            "fieldtype"               	:"standard",
                            "required"					:true,
    
                            "class"                   	:'',
                            "id"                      	:'name',
                            "hint"                    	:"",
    
                            "text"                     	:"",
    
    
    
                            "keyboardType"            	:"",
                            "autocorrect"             	:"false",
                            "autocapitalizationType"  	:"none",
    
    
                            //"secure"                  :true,
                            //"returnKeyType"           :"done"
    
    
                        },
    
                    },
                    */
    
                    array = [];
                    for(obj of object.data)
                    {
                        switch(obj.field.fieldtype)
                        {
                        case'percent':
    
                            val = obj.field.text;
                            if(obj.field.id.match(/ownr_/gi))
                            {
                                val = owners[keyname].value[obj.field.id].value;
                            }
                            if(val !== '' 
                            && val !== undefined 
                            && !isNaN(val))
                            {
                                val = parseFloat(val).toFixed(1);
                            }else{
                                val = 0.0;
                            }
                            obj.field.text = val;

                            obj.field.tabIndex = tabIndex;
                            tabIndex = parseInt(tabIndex) + 1;
    
                        break;
                        case'price':
    
                            val = obj.field.text;
                            if(obj.field.id.match(/ownr_/gi))
                            {
                                val = owners[keyname].value[obj.field.id].value;
                            }
                            if(val !== '' 
                            && val !== undefined 
                            && !isNaN(val))
                            {
                                val = parseFloat(val).toFixed(2);
                            }else{
                                val = 0.0;
                            }
                            obj.field.text = val;

                            obj.field.tabIndex = tabIndex;
                            tabIndex = parseInt(tabIndex) + 1;
    
                        break;
                        default:

                            val = obj.field.text;
                            if(obj.field.id.match(/ownr_/gi))
                            {
                                val = owners[keyname].value[obj.field.id].value;
                            }
                            obj.field.text = val;

                            obj.field.tabIndex = tabIndex;
                            tabIndex = parseInt(tabIndex) + 1;
                        }
    
                        array.push(obj);
    
                    }//==
    
                    
                    template_ownr.push({
    
                        section :object.section,
                        data    :array
    
                    });
    

                break;
                case'checkboxes':
                case'radiobuttons':
                case'checkboxes-edit':
                case'radiobuttons-edit':



                    /*

                    "ownr_labor_direct": {
                        "name": "Direct Labor",
                        "required": true,
                        "default": "",
                        "print": "checkboxes",
                        "value": "Design"
                    },
                    "ownr_labor_indirect": {
                        "name": "Indirect Labor",
                        "required": true,
                        "default": "",
                        "print": "checkboxes",
                        "value": ""
                    },



                    //==========================================
                    //checkboxes
                    //==========================================
                    {
                        "section"   :"checkboxes",
                        "data"      :{
                            
                            "call"      :'chk',
                            "required"	:true,
                            "name"      :'ownr_labor_direct',
                            "array"     :["Design","Production","Installation"],
                            "choice"    :[]

                        }

                    },
                    */

               
                
                    val = object.data.choice;
                    if(object.data.name.match(/ownr_/gi))
                    {
                     
                        //console.log(val);

                        if(owners[keyname].value[object.data.name].value !== '' 
                        && owners[keyname].value[object.data.name].value !== undefined
                        )
                        {
                            val = owners[keyname].value[object.data.name].value;

                            if(val.match(/,/gi)) 
                            {
                                val = val.split(",");

                            }
                            else
                            {
                                val = [val];
                            }                  

                        }else{
                            
                            val = [];
                        }

                    }

                    for(i=0; i < val.length; i++)
                    {
                        if(val[i] !== undefined)
                        {
                            object.data.array[i] = val[i];
                        }
                    }
                    object.data.choice = val;

                    //console.log("VAL: "+val);
                    //console.log("CHOICE VAL: "+object.data.choice);
                
                    template_ownr.push({
    
                        section :object.section,
                        data    :object.data
    
                    });
    

                break;
                case'slider':
                case'dualslider':


                    /*
                    "ownr_labor_percent": {
                        "name": "Direct/Indirect Labor %",
                        "required": true,
                        "default": "",
                        "print": "dualslider",
                        "value": "70"
                    },



                    //==========================================
                    //dualslider
                    //==========================================
                    {

                        "section"   :"dualslider",
                        "data"      :{
                            
                            required :true,
                            id       :'ownr_labor_percent',
                            value    :50,
                            info     :{

                                title           :'Direct Labor',
                                titleB          :'Indirect Labor',
                                bandColor       :'#0000cc',
                                bandOpacity     :"0.5",
                                useStickyX      :true,
                                stickyIncrement :10,
                                matchAB         :false

                            }


                        }

                    },
                    */


                    val = object.data.value;
                    if(object.data.id.match(/ownr_/gi))
                    {
                        val = owners[keyname].value[object.data.id].value;
                        if(val === undefined) val = 50;
                    
                    }
                    object.data.value = val;

            

                    template_ownr.push({
    
                        section :object.section,
                        data    :object.data
    
                    });

                break;
                default:
    
                    template_ownr.push(object);
                }
    
    
            }// -- for 






            //console.log("TEMPLATE OWNR: "+JSON.stringify(template_ownr,null,2));

            
            //---------------------------------------

            catalogItems.push({
        
                heading:owners[keyname].name,
                bullet:false,
                stripe:true,
                //pgraph:owners[keyname].value,
                pgraph:pgraph,
                image:false,
                button:false,
                controls:{

                    form:{

                        hashbang:false,//#!somename or false
                        call:'edit',
                        submitCall:'calculate',
                        postTo:apiDomain+apiCall,
                        keyname:keyname,
                        data:template_ownr,
                        flyr:{
                            type:"form",
                            styl:{
                                maxWidth:600,
                                fieldType:'labelfield'
                            }
                        }


                    },
                    chart:false,
                    print:{

                        hashbang:false,//#!somename or false
                        call:'print',
                        keyname:keyname,
                        data:owners[keyname],
                        flyr:{
                            type:"print",
                            styl:{
                                maxWidth:600,
                                fieldType:'composed'
                            }
                        }

                    },
                    report:{

                        hashbang:false,//#!somename or false
                        call:'report',
                        keyname:keyname,
                        data:ownrResults[keyname],
                        flyr:{
                            type:"report",
                            styl:{
                                maxWidth:600,
                                fieldType:'composed'
                            }
                        }

                    },
                    confirm:confirmDelete


                }
            
            });

            //index++

        break;
        }


    }//endfor


    R.push({

        call:"items",
        items:catalogItems

    });

    return R;


}



export default SceneInfo;
