import React, { 

    useState, 
    useEffect,
    //useRef

} from 'react';



import {

    Summary,
    SummaryTop,
    SummaryLine

} from '../../Styled';



export const FScomplete = (props) => {




    console.log("FScomplete props : "+JSON.stringify(props,null,2));


    //-------------------------------
    // state/effect - data
    //-------------------------------

    const [data, setData] = useState([]);

    useEffect(() => {


        if(props.data && data.length === 0)
        {


            var array = [];
   
            //var authUser;
            var accountUser;

            //var auth;
            var account;
            //var newAuth;
            //var newAccount;

            //var items;
            var exists, accountId, email, createdAt, status, sendEmail;
            var showExtras, hasUser, newUser;



            //auth = false;
            account = false;

            //newAuth = false;
            //newAccount = false;

            //items = false;


            exists = false;

            accountId = "N/A";
            email = "N/A";
            status = "N/A";



            showExtras = true;

            hasUser = "M/A";
            newUser = "N/A";






            if(!props.data.auth_account.error)
            {
                // authUser = props.data.auth_account.result.authUser;
                // if(!authUser.error)
                // {
                //     auth = authUser.result.Items[0];   
                // }
    
                accountUser = props.data.auth_account.result.accountUser;
                if(!accountUser.error)
                {
                    account = accountUser.result; 
                }
        
            }
            if(account.Count > 0) 
            {
                accountId       = account.Items[0].accountId;
                email           = account.Items[0].email;
                createdAt       = account.Items[0].createdAt;
                status          = "Account Already Exists";
                sendEmail       = "No";
                hasUser         = "Yes";
                newUser         = "No";

            }



            if(props.data.summary) 
            {

                // {
                //     "exists": false,
                //     "accountId": "tpc",
                //     "email": "tpc@theprofitcalculator.com",
                //     "status": "Account was created successfully",
                //     "hasUser": false,
                //     "newUser": true,
                //     "sendEmail": {
                //         "error": false,
                //         "result": "Email was sent successfully(TEST)"
                //     }
                // }

                // ob.summary['sendEmail'] = {


                //     customer:{
                
                //         error	:customer_error,
                //         status	:customer_status,
                //         message :customer_message			
                //     },

                //     admin:{

                //         error	:admin_error,
                //         status	:admin_status,
                //         message :admin_message				
                //     }

                // }



                exists = props.data.summary.exists;
                if(!exists)
                {

                    accountId           = props.data.summary.accountId;
                    email               = props.data.summary.email;
                    createdAt           = props.data.summary.createdAt;
                    status              = props.data.summary.status;

                    sendEmail           = props.data.summary.sendEmail;
                  
                    hasUser             = props.data.summary.hasUser;
                    newUser             = props.data.summary.newUser;

                }

  

            }
            
            
            array.push({

                text:"Account ID: " + accountId

            });
            array.push({

                text:"Email: " + email

            });
            array.push({

                text:"Created At: " + createdAt

            });
            array.push({

                text:"Status: " + status

            });
            array.push({

                text:"Message: " + sendEmail.customer.message

            });


            if(showExtras)
            {

                array.push({

                    text:"Has User: " + hasUser

                });

                array.push({

                    text:"New User: " + newUser

                });



                if(Object.prototype.toString.call(sendEmail) !== '[object Object]')
                {

                    array.push({

                        text:"Send Email: " + sendEmail
        
                    });

                }else{

                    // customer email

                    array.push({

                        text:"Customer Email Error: " + sendEmail.customer.error
        
                    });
                    array.push({

                        text:"Customer Email Status: " + sendEmail.customer.status
        
                    });
                    array.push({

                        text:"Customer Email Message: " + sendEmail.customer.message
        
                    });


                    // admin email

                    array.push({

                        text:"Admin Email Error: " + sendEmail.admin.error
        
                    });
                    array.push({

                        text:"Admin Email Status: " + sendEmail.admin.status
        
                    });
                    array.push({

                        text:"Admin Email Message: " + sendEmail.admin.message
        
                    });


                }



            }

            setData(array);

            
        

            // // -- auth_account -- //

            // if(!props.data.auth_account.error)
            // {
            //     // authUser = props.data.auth_account.result.authUser;
            //     // if(!authUser.error)
            //     // {
            //     //     auth = authUser.result.Items[0];   
            //     // }
    
            //     accountUser = props.data.auth_account.result.accountUser;
            //     if(!accountUser.error)
            //     {
            //         account = accountUser.result; 
            //     }
        
            // }

            // if(account.Count > 0) 
            // {
            //     accountId = account.Items[0].accountId;
            //     email = account.Items[0].email;

            //     exists = true;

            //     status = "Account Already Exists";

            // }


            // if(!exists)
            // {

            //     // -- new_auth_account -- //

            //     if(!props.data.new_auth_account.error)
            //     {
            //         // authUser = props.data.auth_account_insert.result.authUser;
            //         // if(!authUser.error)
            //         // {
            //         //     newAuth = authUser.result.Items[0];   
            //         // }
        
            //         accountUser = props.data.new_auth_account.result.accountUser;
            //         if(!accountUser.error)
            //         {
            //             if(!accountUser.result.error)
            //             {
            //                 newAccount = accountUser.result.result; 
            //             }
            //         }
            
            //     }

            //     if(newAccount.length > 0)
            //     { 
            //         accountId = newAccount[0].accountId;
            //         email = newAccount[0].email;
            //     }




            //     // -- items -- //

            //     if(!props.data.items.error)
            //     {
            //         if(!props.data.items.result.error)
            //         {   
            //             items = props.data.items.result.result
            //         }
            //     }


            //     if(items && items.length > 0)
            //     {
            //         status = "Records were added";
            //     }


            // }//==


            // array.push({

            //     text:"Account ID: " + accountId

            // });
            // array.push({

            //     text:"Email: " + email

            // });
            // array.push({

            //     text:"Status: " + status

            // });

            // setData(array);





        }

    


    },
    [
        props,
        data

    ])










    return (<div>


        <Summary>

            <SummaryTop>

                <div className="Title">

                    Account Sign Up

                </div>
        
            </SummaryTop>


            {data.map((obj, i) => (


                <SummaryLine
                key={i}
                >
                    <div className="Line">

                        {obj.text}

                    </div>
                </SummaryLine>

            ))}


        </Summary>


    </div>);

}





