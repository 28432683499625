
export const ExpensesIndirects = [

    //-----------------------------------
    // Ledger
    //-----------------------------------

    {

        "print"		    :'bar',
        "name"		    :'Monthly',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'ledger',
        "name"		    :"Ledger",
        "value"		    :[

            {
                name:"Total",
                value:"$10000.00"

            }

        ],
        "required"	    :"",
        "default"	    :""


    },

    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"10px"
        },
        "required"	    :"",
        "default"	    :""


    },



    
    // -----------------------------------
    // Report Box - Chart
    // -----------------------------------


    //bar
    // {

    //     "print"		    :'bar',
    //     "name"		    :'Expense',
    //     "value"		    :"",
    //     "required"	    :"",
    //     "default"	    :""


    // },

    //spacer
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"10px"
        },
        "required"	    :"",
        "default"	    :""


    },
    // //chart
    {


        "print":'svg_donut_chart',
        "name":'SVG Donut Chart',
        "value":{

            backgroundColor:"transparent",
            width:"100%",
            height:"300px", // set a fixed height or auto for full expanded
            ring:{

                size:"10",
                color:"#d2d3d4"

            },
            hole:{
                size:0.5,
                // color:"#191e28",
                // outlineColor:"#191e28",
                color:"#fff",
                outlineColor:"#fff",
                textColor:"#000",
                textSize:"27px"
            },
            items: [ 

                {
                    "name": "Expenses ( 78.9% )",
                    "value": "$5,500.00",
                    "percent": "25"
                },
                {
                    "name": "Owner's Indirects ( 15.8% )",
                    "value": "$1,100.00",
                    "percent": "50"
                },
                {
                    "name": "Employee's Indirects ( 15.8% )",
                    "value": "$1,100.00",
                    "percent": "25"
                }
            ],
            colors: "ChartColorsA"



        },
        "required"	    :"",
        "default"	    :"",



    },
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"25px"
        },
        "required"	    :"",
        "default"	    :""


    },










    //-----------------------------------
    // Ledger
    //-----------------------------------

    // {

    //     "print"		    :'bar',
    //     "name"		    :'Amount',
    //     "value"		    :"",
    //     "required"	    :"",
    //     "default"	    :""


    // },
    {

        "print"		    :'ledger',
        "name"		    :"Ledger",
        "value"		    :[


            {
                name:"Expenses",
                value:"$400.00"

            },
            {
                name:"Owner's Indirects",
                value:"$100.00"
            },
            {
                name:"Employee's Indirects",
                value:"$100.00"
            }


        ],
        "required"	    :"",
        "default"	    :"",
        "colors"        : "ChartColorsA"

    },

    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"20px"
        },
        "required"	    :"",
        "default"	    :""


    },






    //-----------------------------------
    // Txt
    //-----------------------------------

    // {

    //     "print"		    :'bar',
    //     "name"		    :'Labor Hours',
    //     "value"		    :"",
    //     "required"	    :"",
    //     "default"	    :""


    // },
    {

        "print"		    :'txt',
        "name"		    :'Txt',
        "value"		    :[

            {

                // boxCall:'txt',
                // boxWidth:"100%",
                // boxHeight:230,

                className:"Note",
                txt:'***NOTE: Indirects = Any indirect portion of the Owners and Employees Salaries including Healthcare, IRA contributions etc.',
                style:{

                    //fontSize:"24px",
                    textAlign:"center"

                }

            }

        ],
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"20px"
        },
        "required"	    :"",
        "default"	    :""


    },













];


















