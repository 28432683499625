
import React, { 

    useState, 
    useEffect,
    //useRef

} from 'react';


import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    //useHistory,   
    //useParams,

} from "react-router-dom";




// import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider';
// import useScreenOrientation from 'utils/hooks/ScreenOrientation';
import useDeviceDetect from 'utils/hooks/DeviceDetect';




//import 'app-style/price-form/style.css';
//import '../../StyleSheets/PriceForm.css';
import 'app-components/Zone/_StyleSheets/PriceForm.css';

import {
    LibString
} from 'library';



// import SVGTrash from '../../Svgs/trash.svg';
// import SVGTrashOver from '../../Svgs/trash_over.svg';






export const FFbluntfield = (props) => {


    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();

    
    var pathname = location.pathname;
    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);


    // -- redux -- //
    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);



    // -- Device Detect -- //

    const DeviceDetection = useDeviceDetect();
    //console.log("FFbluntfield - DeviceDetection " + JSON.stringify(DeviceDetection));




    //=============================================
    // calcId and calcName
    //=============================================

    // var query = location.search;
    // if(query.charAt(0) === '?') 
    //     query = query.substring(1,query.length);

    // query = decodeURIComponent(query);

    // var queryArr = query.split("&");

    // const [calcId] = useState(queryArr[0].split("=")[1]);
    // //const [calcName] = useState(queryArr[1].split("=")[1]);






    //=============================================
    // field
    //=============================================

    const [fieldVal, setFieldVal] = useState(0);
    const [fieldStyl, setFieldStyl] = useState({});
    const [fieldDisplay, setFieldDisplay] = useState('');
    const [labelDisplay, setLabelDisplay] = useState('');


    useEffect(() => {

      
        var fixedNumber;

        var fontStyle = 'normal';
        var fontColor = "#000";

        let calcId = props.calcId
        let data = props.data;
        let keyname;
        let val;

        if(props.data.field.id === 'calcby') // check calcby for proper display
        {
       
            // var calcs,calc;
            // calcs = JSON.parse(JSON.stringify(zoneObj['calcs']));

            var calcby = props.calcby;
            //alert(props.calcby);


            var currentCalcbyCall;
            var currentCalcbyValue;

            if(zoneObj.calcs && calcId !== 'calc')
            {
                var calcs,calc;

                calcs = JSON.parse(JSON.stringify(zoneObj.calcs));
                if(calcId && calcId !== undefined)
                {
                    calc = calcs[calcId];
                    if(calc && calc !== undefined)
                    {
                        currentCalcbyCall = calc.data.value.calcby.call;
                        currentCalcbyValue = calc.data.value.calcby.value;
                    }

                }
        
            }else{

                // CalcInputs.calcby = {

                //     "call"      :'percent',
                //     'title'     :'Net Profit', //Net Profit, Selling Price, Markup Multiplier
                //     'value'     :0.0

                // };
            }





            for(keyname in data)
            {
                switch(keyname)
                {
                case'display':

                    switch(calcby)
                    {
                    case'price':

                        setFieldDisplay('price');
                    break;
                    case'percent':

                        setFieldDisplay('percent');
                    break;
                    case'markup':

                        setFieldDisplay('markup');
                    break;
                    default:

                        setFieldDisplay(data[keyname]);
                    break;
                    }
    
                break;
                case'label':


                    switch(calcby)
                    {
                    case'price':

                        setLabelDisplay('Selling Price');
                    break;
                    case'percent':

                        setLabelDisplay('Profit Margin');//Net Profit - Profit Margin
                    break;
                    case'markup':

                        setLabelDisplay('Markup Multiplier');
                    break;
                    default:

                        setLabelDisplay(data[keyname].text);
                    break;
                    }


                break;
                case'field':



                    val = data[keyname].text;
                    if(val === '' 
                    || val === undefined)
                    {
                        val = 0; 
                    }

                    switch(calcby)
                    {
                    case'price':

                        fixedNumber = 2;
                        if(currentCalcbyCall === 'price')
                        {
                            val = parseFloat(currentCalcbyValue).toFixed(fixedNumber);
                        }else{
                            val = parseFloat("0.00").toFixed(fixedNumber);
                        }
                    break;
                    case'percent':


                        fixedNumber = 1;
                        if(currentCalcbyCall === 'percent')
                        {
                            val = parseFloat(currentCalcbyValue).toFixed(fixedNumber);
                        }else{
                            val = parseFloat("10").toFixed(fixedNumber);
                        }
                    break;
                    case'markup':

                        fixedNumber = 1;
                        if(currentCalcbyCall === 'markup')
                        {
                            val = parseFloat(currentCalcbyValue).toFixed(fixedNumber);
                        }else{
                            val = parseFloat("2.2").toFixed(fixedNumber);
                        }
                    break;
                    default:

                        fixedNumber = 1;
                        val = parseFloat(val).toFixed(fixedNumber);
                    break;
                    }


                    setFieldVal(val);

                break;
                default:
                break;
                }//==

            }



        }else{


            for(keyname in data)
            {

                switch(keyname)
                {
                case'display':

                    //console.log(keyname+":  "+data[keyname]);
                    setFieldDisplay(data[keyname]);
                break;
                case'label':

                    setLabelDisplay(data[keyname].text);
                break;
                case'field':


                    // console.log(" ============= bluntfield ============= ")

                    // console.log("keyname..."+keyname);
                    // console.log("data..."+JSON.stringify(data[keyname],null,2));



                    switch(data['display'])
                    {
                    case'price':
                    case'percent':

                        switch(data['display'])
                        {
                        case'price':

                            fixedNumber = 2;
                        break;
                        default:
                            fixedNumber = 1;
                        }

                        val = data[keyname].text;
                        if(val === '' 
                        || val === undefined)
                        {
                            val = 0; 
                        }
                        val = parseFloat(val).toFixed(fixedNumber);
                    break;
                    case'wholenumber':

                        val = data[keyname].text;
                        if(val === '' 
                        || val === undefined)
                        {
                            val = 0; 
                        }
                        val = parseInt(val);
                        val = Math.round(val);

                    
                    break;
                    default:
                    }//==


                    setFieldVal(val);

                break;
                default:
                break;
                }//==


            }
        

        }
      

        
        //----------------------------------------

        setFieldStyl({
            "bgColor":"transparent",
            "fontStyle":fontStyle,
            "fontColor":fontColor
        });


        var problemArr = props.problemArr;
        for(var i=0; i < problemArr.length; i++)
        {
            if(props.ij === problemArr[i]['key'])
            {
                setFieldStyl({
                    "bgColor":"lightyellow",
                    "fontStyle":fontStyle,
                    "fontColor":fontColor
                });
                break;
            }
    
        }//== i

 
     },[
         props,
         zoneObj
    ])
 
 
  

     
    //<input name="firstName" onChange={e => setFirstName(e.target.value)} />
     


    // -- handle -- //

    // function handleOverOut(hndl) {

    //     //console.log(hndl.index);

    //     var img;
    //     img = document.getElementById("svg-"+hndl.index);

    //     switch(hndl.call)
    //     {
    //     case'over':

    //         switch(hndl.svg)
    //         {
    //         case'trash':

    //             img.src = SVGTrashOver;
    //         break;
    //         default:
    //         }


    //     break;
    //     default:

    //         switch(hndl.svg)
    //         {
    //         case'trash':

    //             img.src = SVGTrash;
    //         break;
    //         default:
    //         }

    //     }

 
    // }




    // -- handle -- //
    
    function handleFocus(target) {

        target.select();
    }

    function handleBlur(e) {

        var targetVal;
        var maxlength;
        var fixedNumber;
        var newVal;

        maxlength = 4;
        fixedNumber = 1;
        if(fieldDisplay === 'price')
        {
            
            maxlength = 8;
            fixedNumber = 2;

        }
        if(fieldDisplay === 'wholenumber')
        {
            
            maxlength = 8;
            fixedNumber = 0;

        }

        targetVal = e.target.value;
        if(targetVal === '' 
        || targetVal === undefined
        || isNaN(targetVal)
        )
        {
            targetVal = 0; 
        }
    
        targetVal = LibString.InputMaxlength({
            str        :targetVal,
            maxlength  :maxlength
        });


        if(fieldDisplay === 'wholenumber')
        {
            newVal = parseInt(targetVal);
            newVal = Math.round(newVal);
        }else{
            newVal = parseFloat(targetVal).toFixed(fixedNumber);
        }
        setFieldVal(newVal);


    

    }
    
    function handleChange(e) {

        var targetVal;
        var maxlength;
        var fixedNumber;
        var newVal;


        maxlength = 4;
        fixedNumber = 1;
        if(fieldDisplay === 'price'){
            
            maxlength = 10;
            fixedNumber = 2;

        }
        if(fieldDisplay === 'wholenumber')
        {
            
            maxlength = 8;
            fixedNumber = 0;

        }


    
        targetVal = e.target.value;
        if(targetVal === '' 
        || targetVal === undefined
        || isNaN(targetVal)
        )
        {
            targetVal = 0; 
        }

        targetVal = LibString.InputMaxlength({
            str        :targetVal,
            maxlength  :maxlength
        });
        setFieldVal(targetVal);
      
        if(fieldDisplay === 'wholenumber')
        {
            newVal = parseInt(targetVal);
            newVal = Math.round(newVal);
        }else{
            newVal = parseFloat(targetVal).toFixed(fixedNumber);
        }

 

        //setFieldVal(targetVal);
        //newVal = parseFloat(targetVal).toFixed(fixedNumber);

        props.handle({

            call        :'capture',
            fieldCall   :"bluntfield",
            key         :props.ij,
            id          :e.target.id,
            data        :props.data,
            value       :newVal
        
        })


    }






    return (


        <div className="frm">




            {
            //------------------------------------
            //BLUNTFIELD
            //------------------------------------

  
            <div 
            key={props.ij}
            className="bluntfield"
            style={{

                marginBottom:props.marBot

            }}
            >


                <div 
                className="label"
                >


                    {labelDisplay}


                </div>




                {
                DeviceDetection.isMobile
                ?
                
                    fieldDisplay === "percent" 
                    ?

                        <div>
                            <div
                            className="symbol"
                            style={{
                                //backgroundColor:fieldStyl.bgColor,
                                //width:fieldStyl.width
                            }}
                            >

                                %

                            </div>



                            <div 
                            className="small-wrap"
                            >
                                <div>
                                <input
                                    //type="text"
                                    type="number" pattern="[0-9]*"
                                    className="input-tag"
                                    style={{
                                        backgroundColor:fieldStyl.bgColor,
                                        fontStyle:fieldStyl.fontStyle,
                                        color:fieldStyl.fontColor
                                    }}
                    
                                    id={props.data.field.id}
                                    value={fieldVal}
                                    placeholder={props.data.field.hint}
                                    maxLength={5}
                                    tabIndex={props.data.field.tabIndex}

                                    onChange={handleChange}
                                    onFocus = {event => {
                                        // event properties must be copied to use async
                                        const target = event.target;
                                        setTimeout(() => handleFocus(target), 0);
                                    }}
                                    onBlur={handleBlur} 
                                    onKeyPress = {event => {
                                        // event properties must be copied to use async
                                        setTimeout(() => props.handleMovement({

                                            event       :event,
                                            section     :"bluntfields",
                                            index       :props.ij
                
                                        }), 0);
                                    }}
        
                            
                                />
                                </div>
                                
                            </div>


                            <div
                            className="colorline"
                            style={{
                                backgroundColor:props.data.field.linecolor,
                                color:props.data.field.linecolor
                            }}
                            >

                                L

                            </div>

                        </div>


                    :
                    fieldDisplay === "price" 
                    ?

                        <div
                        style={{

                            marginTop: "0px",

                        }}
                        >

                            <div 
                            className="large-wrap"
                            >


                                <div>
                                <input
                                    //type="text"
                                    type="number" pattern="[0-9]*"
                                    className="input-tag"
                                    style={{
                                        backgroundColor:fieldStyl.bgColor,
                                        fontStyle:fieldStyl.fontStyle,
                                        color:fieldStyl.fontColor
                                    }}
                    
                                    id={props.data.field.id}
                                    value={fieldVal}
                                    placeholder={props.data.field.hint}
                                    maxLength={10}
                                    tabIndex={props.data.field.tabIndex}

                                    onChange={handleChange}
                                    onFocus = {event => {
                                        // event properties must be copied to use async
                                        const target = event.target;
                                        setTimeout(() => handleFocus(target), 0);
                                    }}
                                    onBlur={handleBlur} 
                                    onKeyPress = {event => {
                                        // event properties must be copied to use async
                                        setTimeout(() => props.handleMovement({

                                            event       :event,
                                            section     :"bluntfields",
                                            index       :props.ij
                
                                        }), 0);
                                    }}


                                />
                                </div>
                                
                            </div>


                            <div
                            className="colorline"
                            style={{
                                backgroundColor:props.data.field.linecolor,
                                color:props.data.field.linecolor
                            }}
                            >

                                L

                            </div>



                            <div
                            className="symbol"
                            style={{
                                //backgroundColor:fieldStyl.bgColor,
                                //width:fieldStyl.width
                            }}
                            >

                                $

                            </div>


                        </div>


                    :
                    fieldDisplay === "markup" 
                    ?

                        <div>


                            <div 
                            className="small-wrap"
                            style={{

                                marginRight:"42px"
                            
                            }}
                            >


                                <div>
                                <input
                                    //type="text"
                                    type="number" pattern="[0-9]*"
                                    className="input-tag"
                                    style={{
                                        backgroundColor:fieldStyl.bgColor,
                                        fontStyle:fieldStyl.fontStyle,
                                        color:fieldStyl.fontColor
                                    
                                    }}
                    
                                    id={props.data.field.id}
                                    value={fieldVal}
                                    placeholder={props.data.field.hint}
                                    maxLength={3}
                                    tabIndex={props.data.field.tabIndex}

                                    onChange={handleChange}
                                    onFocus = {event => {
                                        // event properties must be copied to use async
                                        const target = event.target;
                                        setTimeout(() => handleFocus(target), 0);
                                    }}
                                    onBlur={handleBlur} 
                                    onKeyPress = {event => {
                                        // event properties must be copied to use async
                                        setTimeout(() => props.handleMovement({

                                            event       :event,
                                            section     :"bluntfields",
                                            index       :props.ij
                
                                        }), 0);
                                    }}
                                    


                                />
                                </div>
                                
                            </div>


                            <div
                            className="colorline"
                            style={{
                                backgroundColor:props.data.field.linecolor,
                                color:props.data.field.linecolor
                            }}
                            >

                                L

                            </div>


                            <div
                            className="symbol"
                            style={{
                                //backgroundColor:fieldStyl.bgColor,
                                //width:fieldStyl.width
                            }}
                            >

                                X

                            </div>



                        </div>


                    :
                    fieldDisplay === "wholenumber" 
                    ?

                        <div>


                            <div 
                            className="small-wrap"
                            style={{

                                marginRight:"42px"
                            
                            }}
                            >


                                <div>
                                <input
                                    //type="text"
                                    type="number" pattern="[0-9]*"
                                    className="input-tag"
                                    style={{
                                        backgroundColor:fieldStyl.bgColor,
                                        fontStyle:fieldStyl.fontStyle,
                                        color:fieldStyl.fontColor
                                    
                                    }}
                    
                                    id={props.data.field.id}
                                    value={fieldVal}
                                    placeholder={props.data.field.hint}
                                    maxLength={7}
                                    tabIndex={props.data.field.tabIndex}

                                    onChange={handleChange}
                                    onFocus = {event => {
                                        // event properties must be copied to use async
                                        const target = event.target;
                                        setTimeout(() => handleFocus(target), 0);
                                    }}
                                    onBlur={handleBlur} 
                                    //onKeyPress={props.handleMovement}
                                    onKeyPress = {event => {
                                        // event properties must be copied to use async
                                        setTimeout(() => props.handleMovement({

                                            event       :event,
                                            section     :"bluntfields",
                                            index       :props.ij
                
                                        }), 0);
                                    }}


                                />
                                </div>
                                
                            </div>


                            <div
                            className="colorline"
                            style={{
                                backgroundColor:props.data.field.linecolor,
                                color:props.data.field.linecolor
                            }}
                            >

                                L

                            </div>


                            <div
                            className="symbol"
                            style={{
                                //backgroundColor:fieldStyl.bgColor,
                                //width:fieldStyl.width
                            }}
                            >

                                

                            </div>



                        </div>


                    :null
                    


                //#########################################################################

                : //NOT MOBILE

                //#########################################################################


                    
                    // props.data.field.id !== 'calcby' && props.data.field.id !== 'overhead'
                    // ?


                    //     <div 
                    //     className="controller"
                    //     >
                    //         <div 
                    //         id={"button-"+props.ij}                  
                    //         className="button"

                    //         onClick={(v) => props.handle({

                    //             call        :"delete",
                    //             data        :props.data,
                    //             fieldCall   :'costfield',
                    //             key         :props.ij
                    
                    //         })}

                    //         onMouseOver={(v) => handleOverOut({

                    //             call:"over",
                    //             index:props.ij,
                    //             svg:"trash"

                    //         })}
                    //         onMouseOut={(v) => handleOverOut({

                    //             call:"out",
                    //             index:props.ij,
                    //             svg:"trash"

                    //         })}

                    //         >

                    //             <img
                    //             id={"svg-"+props.ij}
                    //             className="icon"
                    //             src={SVGTrash} 
                    //             alt="del" />

                                
                    //         </div>

                    //     </div>


                    // :null
                
            


                
                    fieldDisplay === "percent" 
                    ?

                        <div>
                            <div
                            className="symbol"
                            style={{
                                //backgroundColor:fieldStyl.bgColor,
                                //width:fieldStyl.width
                            }}
                            >

                                %

                            </div>



                            <div 
                            className="small-wrap"
                            >
                                <div>
                                <input
                                    type="text"
                                    className="input-tag"
                                    style={{
                                        backgroundColor:fieldStyl.bgColor,
                                        fontStyle:fieldStyl.fontStyle,
                                        color:fieldStyl.fontColor
                                    }}
                    
                                    id={props.data.field.id}
                                    value={fieldVal}
                                    placeholder={props.data.field.hint}
                                    maxLength={5}
                                    tabIndex={props.data.field.tabIndex}

                                    onChange={handleChange}
                                    onFocus = {event => {
                                        // event properties must be copied to use async
                                        const target = event.target;
                                        setTimeout(() => handleFocus(target), 0);
                                    }}
                                    onBlur={handleBlur} 
                                    onKeyPress = {event => {
                                        // event properties must be copied to use async
                                        setTimeout(() => props.handleMovement({

                                            event       :event,
                                            section     :"bluntfields",
                                            index       :props.ij
                
                                        }), 0);
                                    }}
        
                            
                                />
                                </div>
                                
                            </div>


                            <div
                            className="colorline"
                            style={{
                                backgroundColor:props.data.field.linecolor,
                                color:props.data.field.linecolor
                            }}
                            >

                                L

                            </div>

                        </div>


                    :
                    fieldDisplay === "price" 
                    ?

                        <div
                        style={{

                            marginTop: "0px",

                        }}
                        >

                            <div 
                            className="large-wrap"
                            >


                                <div>
                                <input
                                    type="text"
                                    className="input-tag"
                                    style={{
                                        backgroundColor:fieldStyl.bgColor,
                                        fontStyle:fieldStyl.fontStyle,
                                        color:fieldStyl.fontColor
                                    }}
                    
                                    id={props.data.field.id}
                                    value={fieldVal}
                                    placeholder={props.data.field.hint}
                                    maxLength={10}
                                    tabIndex={props.data.field.tabIndex}

                                    onChange={handleChange}
                                    onFocus = {event => {
                                        // event properties must be copied to use async
                                        const target = event.target;
                                        setTimeout(() => handleFocus(target), 0);
                                    }}
                                    onBlur={handleBlur}  
                                    onKeyPress = {event => {
                                        // event properties must be copied to use async
                                        setTimeout(() => props.handleMovement({

                                            event       :event,
                                            section     :"bluntfields",
                                            index       :props.ij
                
                                        }), 0);
                                    }}


                                />
                                </div>
                                
                            </div>


                            <div
                            className="colorline"
                            style={{
                                backgroundColor:props.data.field.linecolor,
                                color:props.data.field.linecolor
                            }}
                            >

                                L

                            </div>



                            <div
                            className="symbol"
                            style={{
                                //backgroundColor:fieldStyl.bgColor,
                                //width:fieldStyl.width
                            }}
                            >

                                $

                            </div>


                        </div>


                    :
                    fieldDisplay === "markup" 
                    ?

                        <div>


                            <div 
                            className="small-wrap"
                            style={{

                                marginRight:"42px"
                            
                            }}
                            >


                                <div>
                                <input
                                    type="text"
                                    className="input-tag"
                                    style={{
                                        backgroundColor:fieldStyl.bgColor,
                                        fontStyle:fieldStyl.fontStyle,
                                        color:fieldStyl.fontColor
                                    
                                    }}
                    
                                    id={props.data.field.id}
                                    value={fieldVal}
                                    placeholder={props.data.field.hint}
                                    maxLength={3}
                                    tabIndex={props.data.field.tabIndex}
                                    
                                    onChange={handleChange}
                                    onFocus = {event => {
                                        // event properties must be copied to use async
                                        const target = event.target;
                                        setTimeout(() => handleFocus(target), 0);
                                    }}
                                    onBlur={handleBlur}  
                                    onKeyPress = {event => {
                                        // event properties must be copied to use async
                                        setTimeout(() => props.handleMovement({

                                            event       :event,
                                            section     :"bluntfields",
                                            index       :props.ij
                
                                        }), 0);
                                    }}


                                />
                                </div>
                                
                            </div>


                            <div
                            className="colorline"
                            style={{
                                backgroundColor:props.data.field.linecolor,
                                color:props.data.field.linecolor
                            }}
                            >

                                L

                            </div>


                            <div
                            className="symbol"
                            style={{
                                //backgroundColor:fieldStyl.bgColor,
                                //width:fieldStyl.width
                            }}
                            >

                                X

                            </div>



                        </div>


                    :
                    fieldDisplay === "wholenumber" 
                    ?

                        <div>


                            <div 
                            className="small-wrap"
                            style={{

                                marginRight:"42px"
                            
                            }}
                            >


                                <div>
                                <input
                                    type="text"
                                    className="input-tag"
                                    style={{
                                        backgroundColor:fieldStyl.bgColor,
                                        fontStyle:fieldStyl.fontStyle,
                                        color:fieldStyl.fontColor
                                    
                                    }}
                    
                                    id={props.data.field.id}
                                    value={fieldVal}
                                    placeholder={props.data.field.hint}
                                    maxLength={7}
                                    tabIndex={props.data.field.tabIndex}

                                    onChange={handleChange}
                                    onFocus = {event => {
                                        // event properties must be copied to use async
                                        const target = event.target;
                                        setTimeout(() => handleFocus(target), 0);
                                    }}
                                    onBlur={handleBlur} 
                                    //onKeyPress={props.handleMovement}
                                    // onKeyPress={props.handleMovement({

                                    //     id          :props.data.field.id,
                                    //     value       :fieldVal,
                                    //     hint        :props.data.field.hint,
                                    //     tabIndex    :props.data.tabIndex

                                    // })}
                                    onKeyPress = {event => {
                                        // event properties must be copied to use async
                                        setTimeout(() => props.handleMovement({

                                            event       :event,
                                            section     :"bluntfields",
                                            index       :props.ij
                
                                        }), 0);
                                    }}



                                />
                                </div>
                                
                            </div>


                            <div
                            className="colorline"
                            style={{
                                backgroundColor:props.data.field.linecolor,
                                color:props.data.field.linecolor
                            }}
                            >

                                L

                            </div>


                            <div
                            className="symbol"
                            style={{
                                //backgroundColor:fieldStyl.bgColor,
                                //width:fieldStyl.width
                            }}
                            >

                                

                            </div>



                        </div>


             
                    



                :null


                }



            </div>



            //-------------------------------------
            }



        </div>

    )


}




