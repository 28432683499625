import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';

import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'


import { 

    Gauge,
    //DonutOverlay,
    DonutChart,
    Parts

} from './Styled';



import { 

    GaugeColors,
    GaugeColorsB,

    ChartColorsA, 
    ChartColorsB, 
    ChartColorsC 

} from 'utils/components/Charts/ChartColors';




export const Gauge3 = (props) => {


    // console.log("HrzBarChart")
    // console.log(JSON.stringify(props,null,2));


    // -- window dims -- //
    const { 
        width,
        //height 
    } = useWindowDimensions();


    //=============================================
    // refs
    //=============================================

    // -- ref -- //
    const refChart = useRef(null);
    const refContent = useRef(null);

    

    //=============================================
    // state/effect - isLoaded
    //=============================================

    const [isLoaded, setIsLoaded] = useState(false);
    const [currentWidth, setCurrentWidth] = useState(false);  
    const [styl, setStyl] = useState({            
        
        backgroundColor:props.data.backgroundColor,
        width:props.data.width,
        figureMargin:"0 0 0 0",
        svgHeight:props.data.height,
        svgMargin:props.data.margin,
        circle:{

            xy:21,                  //21,
            r:15.91549430918954    //15.91549430918954
        },
        ring:props.data.ring,
        hole:props.data.hole

    });
    const [items, setItems] = useState([]);
    const [showHoleText, setShowHoleText] = useState(false);
    const [showChartKey, setShowChartKey] = useState(false);
    // const [chartLabel, setChartLabel] = useState('');

    const [gaugeData, setGaugeData] = useState(false);






    useEffect(() => {


        const  max = 75;
        const firstOffset = -(max/2);

        var chartHeight;
        var donutSize;
        var gaugeSize;
        var gaugeOffCenter;
        var gaugeRotation = -100;


        var newItems;

        var labelledby;
        var titleId, title;
        var descId, desc;
        var stroke, strokeDasharray, strokeDashoffset;
        var label;
   
        var array;
        var name, value, percent, decimal, difPercent, percentOfMax;
        var i;

        var total = 0;


        var strokeColors = GaugeColors;



        if(props.data.colors 
        && Object.prototype.toString.call(props.data.colors) === '[object Array]')
        {
            strokeColors = props.data.colors;
        }
        else
        if(Object.prototype.toString.call(props.data.colors) === '[object String]')
        {

            switch(props.data.colors)
            {                
            case'GaugeColors':

                strokeColors = GaugeColors;
            break;
            case'GaugeColorsB':

                strokeColors = GaugeColorsB;
            break;
            case'ChartColorsA':

                strokeColors = ChartColorsA;
            break;
            case'ChartColorsB':

                strokeColors = ChartColorsB;
            break;
            case'ChartColorsC':

                strokeColors = ChartColorsC;
            break;
            default:
            }

        }


    



        if(!isLoaded)
        {
            setIsLoaded(true);
            setShowChartKey(false);

            chartHeight = props.data.height;
            if(props.lessThanThresh) 
            {
                chartHeight = "auto";

            }


            donutSize = props.data.width.replace(/px/gi,"");
            gaugeSize = donutSize * (0.7);
            //gaugeSize = (donutSize - 75);
            gaugeOffCenter = (gaugeSize/2)-7.5;


        
            // -- set styl -- //
            setStyl({

                backgroundColor:props.data.backgroundColor,
                width:props.data.width,

                figureMargin:"0 0 0 0",
                svgHeight:chartHeight,
                svgMargin:props.data.margin,
                circle:styl.circle,
                gauge:{

                    width:gaugeSize+"px",
                    height:gaugeSize+"px",
                    top:"25px",
                    left:"calc(50% - "+gaugeOffCenter+"px)",
                },
                donut:{

                    width:donutSize+"px",
                    height:donutSize+"px",
                    margin:"0 0 0 calc(50% - "+(donutSize/2)+"px)",
                },
                ring:props.data.ring,
                hole:props.data.hole

            });
            


            // -- items sampler -- //

            // setItems([


            //     {
            //         labelledby:"donut-segment-1-title donut-segment-1-desc",

            //         titleId:"donut-segment-1-title",
            //         title:'Dynamic Description 1',

            //         descId:"donut-segment-1-desc",
            //         desc:'Dynamic Description 1',

            //         stroke:strokeColors[0],
            //         strokeDasharray:"80 20",
            //         strokeDashoffset:"25",

            //     },
            //     {

            //         titleId:"donut-segment-1-title",
            //         title:'Dynamic Title 2',

            //         descId:"donut-segment-1-desc",
            //         desc:'Dynamic Description 2',

            //         stroke:strokeColors[1],
            //         strokeDasharray:"20 80",
            //         strokeDashoffset:"-55"

            //     }


            // ]);



            newItems = [];
            if(Object.prototype.toString.call(props.data.items) === '[object Array]')
            {
            
                array = props.data.items;
                for(i=0; i < array.length; i++)
                {
                
                    //console.log(JSON.stringify(array[i],null,2));

                    name = array[i].name;
                    value = array[i].value;
                    percent = array[i].percent;
                    percent = parseFloat(percent);
                    //difPercent = (100 - percent);

                    decimal = percent/100;
                    percentOfMax = max * decimal;
                    difPercent = (100 - percentOfMax);

                    if(i===0)
                    {
                        if(percent < 50)
                        {

                            gaugeRotation = -72;

                        }
                        else
                        if(percent > 50)
                        {

                            gaugeRotation = 50;

                        }

                    }


                    labelledby = "donut-segment-"+i+"-title donut-segment-1-desc";

                    titleId = "donut-segment-"+i+"-title";
                    title = "Title "+i;

                    descId = "donut-segment-"+i+"-desc";
                    desc = "Desc "+i;
                 
                    stroke = strokeColors[i];
                    strokeDasharray = percentOfMax+" "+difPercent;
                
                    if(i===0)
                    {
                        strokeDashoffset = firstOffset;
                    }else{
                        //strokeDashoffset = -`${100 - preSegmentsTotalLength + firstSegmentOffset}`;
                        //strokeDashoffset = 175 + (50-total);
                        //strokeDashoffset = 187.5 + (50-total);
                        strokeDashoffset = 212.5 + (50-total);
                    }

                    total = total + percentOfMax;



                    label = percent+"%";
                    title = name+" "+value+" ( "+percentOfMax+"% )";
                    desc = label;



                    // console.log("-----------------"
                    //     +"\npercent: "+percent
                    //     +"\ndifPercent: "+difPercent

                    //     +"\n---VARS---"

                    //     +"\nfirstOffset: "+firstOffset
                    //     +"\ntotal: "+total
                      
                    //     +"\n---STROKE---"
                    //     +"\nstroke: "+stroke
                    //     +"\nstrokeDasharray: "+strokeDasharray
                    //     +"\nstrokeDashoffset: "+strokeDashoffset
                        
                    // )

         
                    newItems.push({
     
                        labelledby:labelledby,

                        titleId:titleId,
                        title:title,

                        descId:descId,
                        desc:desc,

                        stroke:stroke,
                        strokeDasharray:strokeDasharray,
                        strokeDashoffset:strokeDashoffset,

                        label:label


                    });
    

                }

            }

            setItems(newItems);
            setShowHoleText(true);
            setGaugeData({

                rotate:"rotate("+gaugeRotation+",250,290)"

            });



        }else{

            if(!currentWidth || width !== currentWidth)
            {
                setCurrentWidth(width);

                //https://www.varsitytutors.com/intermediate_geometry-help/how-to-find-the-angle-for-a-percentage-of-a-circle



                // -- reset styl on width change -- //

                chartHeight = props.data.height;
                if(props.lessThanThresh) 
                {
                    chartHeight = "auto";

                }

                donutSize = props.data.width.replace(/px/gi,"");
                gaugeSize = donutSize * (0.7);
                //gaugeSize = (donutSize - 75);
                gaugeOffCenter = (gaugeSize/2)-7.5;


                setStyl({

                    backgroundColor:props.data.backgroundColor,
                    width:props.data.width,

                    figureMargin:"0 0 0 0",
                    svgHeight:chartHeight,
                    svgMargin:props.data.margin,
                    circle:styl.circle,
                    gauge:{

                        width:gaugeSize+"px",
                        height:gaugeSize+"px",
                        top:"25px",
                        left:"calc(50% - "+gaugeOffCenter+"px)",
                    },
                    donut:{

                        width:donutSize+"px",
                        height:donutSize+"px",
                        margin:"0 0 0 calc(50% - "+(donutSize/2)+"px)",
                    },
                    ring:props.data.ring,
                    hole:props.data.hole

                });




            }




            newItems = [];
            if(Object.prototype.toString.call(props.data.items) === '[object Array]')
            {


                array = props.data.items;
                for(i=0; i < array.length; i++)
                {
                
                    //console.log(JSON.stringify(array[i],null,2));

                    name = array[i].name;
                    value = array[i].value;
                    percent = array[i].percent;
                    percent = parseFloat(percent);
                    //difPercent = (100 - percent);

                    decimal = percent/100;
                    percentOfMax = max * decimal;
                    difPercent = (100 - percentOfMax);

                    if(i===0)
                    {
                        if(percent < 50)
                        {

                            gaugeRotation = -30;

                        }
                        else
                        if(percent > 50)
                        {

                            gaugeRotation = 50;

                        }

                    }


                    labelledby = "donut-segment-"+i+"-title donut-segment-1-desc";

                    titleId = "donut-segment-"+i+"-title";
                    title = "Title "+i;

                    descId = "donut-segment-"+i+"-desc";
                    desc = "Desc "+i;
                 
                    stroke = strokeColors[i];
                    strokeDasharray = percentOfMax+" "+difPercent;
                
                    if(i===0)
                    {
                        strokeDashoffset = firstOffset;
                    }else{
                        //strokeDashoffset = -`${100 - preSegmentsTotalLength + firstSegmentOffset}`;
                        //strokeDashoffset = 175 + (50-total);
                        //strokeDashoffset = 187.5 + (50-total);
                        strokeDashoffset = 212.5 + (50-total);
                    }

                    total = total + percentOfMax;



                    label = percent+"%";
                    title = name+" "+value+" ( "+percentOfMax+"% )";
                    desc = label;



                    // console.log("-----------------"
                    //     +"\npercent: "+percent
                    //     +"\ndifPercent: "+difPercent

                    //     +"\n---VARS---"

                    //     +"\nfirstOffset: "+firstOffset
                    //     +"\ntotal: "+total
                      
                    //     +"\n---STROKE---"
                    //     +"\nstroke: "+stroke
                    //     +"\nstrokeDasharray: "+strokeDasharray
                    //     +"\nstrokeDashoffset: "+strokeDashoffset
                        
                    // )

         
                    newItems.push({
     
                        labelledby:labelledby,

                        titleId:titleId,
                        title:title,

                        descId:descId,
                        desc:desc,

                        stroke:stroke,
                        strokeDasharray:strokeDasharray,
                        strokeDashoffset:strokeDashoffset,

                        label:label


                    });
    

                }

            }

            setItems(newItems);
            setShowHoleText(true);
            setGaugeData({

                rotate:"rotate("+gaugeRotation+",250,290)"

            });








        }
        //console.log("SvgGaugeChart - props: "+JSON.stringify(props,null,2));

        // "data": {
        //     "backgroundColor": "transparent",
        //     "width": "100%",
        //     "height": "auto",
        //     "ring": {
        //       "size": "6",
        //       "color": "lightyellow"
        //     },
        //     "hole": {
        //       "size": 0.5,
        //       "color": "#fff",
        //       "outlineColor": "#fff",
        //       "textColor": "#000",
        //       "textSize": "27px"
        //     },
        //     "items": [

            // {
            //     "name": "Rent / Mortgage ( 80% )",
            //     "value": "$5,500.00",
            //     "percent": "80"
            // },
            // {
            //     "name": "Utilities ( 20% )",
            //     "value": "$1,100.00",
            //     "percent": "20"
            // }

        //     ],
        //     "colors": [
        //       "#07a2e3",
        //       "#20b2aa",
        //       "#fde23e",
        //       "#f16e23",
        //       "#57d9ff",
        //       "#937e88",
        //       "#20b2aa",
        //       "#ccff00",
        //       "lightblue",
        //       "lightgreen",
        //       "yellow",
        //       "pink",
        //       "lavender"
        //     ]
        //   }
        // }




    },
    [
        props,
        width,currentWidth,
        isLoaded,
        refChart,
        refContent,
        styl

    ])








    // const handleClick = (hndl) => {

    //     //alert(JSON.stringify(hndl,null,2));
    //     setChartLabel(hndl.label);

    // };



    // const handleOverOut = (hndl) => {

    //     //alert(JSON.stringify(hndl,null,2));
    //     setChartLabel(hndl.label);

    // };








    if(!isLoaded)
    {

        return(

            <div></div>
        )


    }else{


        return (

            <div>


                <Gauge
                width={styl.gauge.width}
                height={styl.gauge.height}
                top={styl.gauge.top}
                left={styl.gauge.left}
                > 

                    <svg 
                    width="100%" 
                    height="100%" 
                    viewBox="0 0 500 500" 
                    className="donut" 
                    role="img"
                    preserveAspectRatio="none"
                    >

                        {/* <path 
                            fill="rgb(100,100,100)"
                            d="M250,45 c134.941,0,245,110.059,245,245.001c0,59.819-21.64,114.733-57.474,157.354l-68.017-57.072 c22.838-27.161,36.629-62.158,36.629-100.281c0-85.997-70.141-156.139-156.139-156.139c-85.998,0-156.138,70.142-156.138,156.139	c0,38.123,13.792,73.12,36.628,100.281l-68.016,57.072C26.64,404.734,5,349.82,5,290.001C5,155.059,115.059,45,250,45L250,45z"
                        />  */}

                        <circle 
                            cx="250" 
                            cy="290" 
                            r="20" 
                            stroke="black" 
                            strokeWidth="3" 
                            fill="black"
                        /> 

                        <path 
                            fill="rgb(0,0,0)"
                            d="M250.001,132.5l6.499,19.262v189.235c0,3.56-2.941,6.503-6.5,6.503c-3.575,0-6.5-2.927-6.5-6.503V151.762L250.001,132.5	L250.001,132.5z"
                            //transform="rotate(-130,250,290)"
                            transform={gaugeData.rotate}
                        />
                        

                        {/* <line 
                            x1="200" y1="290" x2="300" y2="290" 
                            stroke="rgb(255,0,0)"
                            strokeWidth="2"
                        />

                        <line
                            x1="250" y1="340" x2="250" y2="240" 
                            stroke="rgb(255,0,0)"
                            strokeWidth="2"
                        /> */}


                    </svg> 

                </Gauge>







                <DonutChart
                ref={refChart}
                //backgroundColor={"yellow"}
                width={styl.donut.width}
                height={styl.donut.height}
                margin={styl.donut.margin}
                >


                    <Parts
                    //backgroundColor={"red"}
                    figureMargin={styl.figureMargin}
                    svgHeight={styl.svgHeight}
                    svgMargin={styl.svgMargin}
                    >

                        <figure>

                            <div 
                            className="figure-content">
                                

                                <svg 
                                width="100%" height="100%" 
                                viewBox="0 0 42 42" 
                                className="donut" 
                                aria-labelledby="beers-title beers-desc" 
                                role="img">


                                    {/* <title id="beers-title">Beers in My Cellar</title>
                                    <desc id="beers-desc">Donut chart showing 10 total beers. Two beers are Imperial India Pale Ales, four beers are Belgian Quadrupels, and three are Russian Imperial Stouts. The last remaining beer is unlabeled.</desc> */}


                                    {/* hole*/}
                                    <circle className="donut-hole" 
                                    cx={styl.circle.xy}
                                    cy={styl.circle.xy} 
                                    r={styl.circle.r}  
                                    fill={styl.hole.color} 
                                    role="presentation"
                                    >
                                    </circle>

                                    {/* ring */}
                                    <circle className="donut-ring" 
                                    cx={styl.circle.xy}
                                    cy={styl.circle.xy} 
                                    r={styl.circle.r}   
                                    fill="transparent" 
                                    stroke={styl.ring.color} 
                                    strokeWidth={styl.ring.size} 
                                    role="presentation"
                                    >
                                    </circle>
                                


                                    {/* segments */}

                                    {/* 
                                    

                                    strokeDashOffset - set the position of the segement in relation to the others

                                    examples... 

                                    2 sections 50/50 = section 1( 25 ) section 2(-25)

                                    2 sections 40/60 = section 1( 25 ) section 2(-15)
                                    2 sections 60/40 = section 1( 25 ) section 2(-35)

                                    2 sections 30/70 = section 1( 25 ) section 2(-5)
                                    2 sections 70/30 = section 1( 25 ) section 2(-45)

                                    2 sections 20/80 = section 1( 25 ) section 2(5)
                                    2 sections 80/20 = section 1( 25 ) section 2(-55)

                                    2 sections 10/90 = section 1( 25 ) section 2(15) 
                                    2 sections 90/10 = section 1( 25 ) section 2(-65)
                                
                            
                                    
                                    */}



                                    {items.map((itm, i) => (



                                        <g
                                        key={i}
                                        className={'segment-container'}
                                        // onClick={(v) => handleClick({

                                        //     label:itm.label

                                        // })}
                                        // onMouseEnter={() => handleOverOut({

                                        //     label:itm.label
    
                                        // })}
                                        // onMouseOut={() => handleOverOut({
    
                                        //     label:''
    
                                        // })}
                                        
                                        >

        
                                        {i===0
                                        ?

                                            <circle className="donut-segment" 
                                            cx={styl.circle.xy}
                                            cy={styl.circle.xy} 
                                            r={styl.circle.r}  
                                            fill="transparent" 
                                            stroke={itm.stroke}
                                            strokeWidth={styl.ring.size}
                                            strokeDasharray={itm.strokeDasharray} 
                                            strokeDashoffset={itm.strokeDashoffset} 
                                            aria-labelledby={itm.labelledby}
                                            >

                                                {/* <title id={itm.titleId}>{itm.title}</title>
                                                <desc id={itm.descId}>{itm.desc}</desc> */}
                                            </circle>

                                    
                                        :

                                            <circle className="donut-segment" 
                                            cx={styl.circle.xy}
                                            cy={styl.circle.xy} 
                                            r={styl.circle.r}  
                                            fill="transparent" 
                                            stroke={itm.stroke}
                                            strokeWidth={styl.ring.size}
                                            strokeDasharray={itm.strokeDasharray} 
                                            strokeDashoffset={itm.strokeDashoffset} 
                                            >
                                                {/* <title id={itm.titleId}>{itm.title}</title>
                                                <desc id={itm.descId}>{itm.desc}</desc> */}
                                            </circle>
                    

                                        }                   
                                        </g>


                                    ))}




                                    {/* Chart Text */}
                                    {
                                    showHoleText
                                    ?
                                        // <g className="chart-text">
                                        //     <text x="50%" y="50%" className="chart-number">
                                        //     10
                                        //     </text>
                                        //     <text x="50%" y="50%" className="chart-label">
                                        //     Beers
                                        //     </text>
                                        // </g>

                                        // <g className="chart-text">
            
                                        //     <text 
                                        //     className="chart-center-label"                                 
                                        //     x="50%" y="50%" 
                                        //     >
                                        //         {chartLabel}

                                        //     </text>

                                        // </g>


                                        <div></div>


                                    :null
                                    }






                                </svg>

                            </div>



                            {   
                            showChartKey
                            ?
                            
                                <figcaption className="figure-key">

                                    <p className="sr-only">Donut chart showing 10 total beers. Two beers are Imperial India Pale Ales, four beers are Belgian Quadrupels, and three are Russian Imperial Stouts. The last remaining beer is unlabeled.</p>

                                    <ul className="figure-key-list" aria-hidden="true" role="presentation">
                                        <li>
                                            <span className="shape-circle shape-fuschia"></span> Belgian Quadrupels (4)
                                        </li>
                                        <li>
                                            <span className="shape-circle shape-lemon-lime"></span> Imperial India Pale Ales (2)
                                        </li>
                                        <li>
                                            <span className="shape-circle shape-blue"></span> Russian Imperial Stouts (3)
                                        </li>
                                    </ul>

                                </figcaption> 

                    
                            :null
                            
                            }


                        </figure>

                    </Parts>

                </DonutChart>

            </div>


        )    

    }





}




