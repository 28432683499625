// import { 
//     useSelector, 
//     //useDispatch 
// } 
// from 'react-redux';
// //import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';



// import { Settings } from 'app-components/Prime/Data.js';
// import ApiResources from 'utils/components/Pages/ApiResources';


const SceneInfo = (props) => {



    var R = [];
    //var index;

    var top;
    var bar;   
    var catalogItems;

    // var accountName;
    // var accountValue;



    //#############################################################
    //#############################################################

    top = {

        call:'top',
        name:"Large Look 5 (Black-N-White)",
        controls:[

            // {

            //     name:"Go Back",
            //     // form:{

            //     //     submitCall:'account',
            //     //     postTo:apiDomain+apiCall,
            //     //     data:VPaccount.company,
            //     //     flyr:{
            //     //         type:"form",
            //     //         styl:{
            //     //             maxWidth:475,
            //     //             fieldType:'field'
            //     //         }
            //     //     }
             

            //     // }

            //     hndl:{

            //         call:'readout',
            //         data:false
            //     }

            // }

        ]

    }


    bar = {

        call:'bar',
        items: [

            {

                name:"-"

            },

            {

                name:"-"

            }

        ]
        
    }



    // console.log("Props: "+JSON.stringify(props.goods,null,2));

    // catalogItems = [

    //     {
                
    //         heading:"Glossary",
    //         bullet:false,
    //         stripe:true,
    //         pgraph:"View, Edit or Create Custom Calculators",
    //         image:false,
    //         button:false,
    //         data:{

    //             id:"Glossary",
    //             name:"Glossary"

    //         }
        
    //     },

    //     {
                
    //         heading:"Formulas",
    //         bullet:false,
    //         stripe:true,
    //         pgraph:'View templates or save templates to "My Calculators".',
    //         image:false,
    //         button:false,
    //         data:{

    //             id:"Formulas",
    //             name:"Formulas"

    //         }
        
    //     },

    //     {
                
    //         heading:"Icons",
    //         bullet:false,
    //         stripe:true,
    //         pgraph:'View templates or save templates to "My Calculators".',
    //         image:false,
    //         button:false,
    //         data:{

    //             id:"Icons",
    //             name:"Icons"

    //         }
        
    //     }



    // ];




    var tileColor;
    //tileColor = "#322a61";//purple
    //tileColor = "darkslateblue";//purple
    tileColor = "transparent";//purple



    var iconColor;
    iconColor = "#7e77ad";//purple
    iconColor = "#4cc1bb";//teal
    //iconColor = "#7fe5b2";
    //iconColor = "#edeef1"; // "#c7c9cf"; //"#a2a6af";
    iconColor = "#454d5f"; // "#c7c9cf"; //"#a2a6af";


    var darklite = "dark";


    catalogItems = [

        {
                
            heading:"Account",
            bullet:false,
            stripe:true,
            pgraph:"View, Edit or Create Custom Calculators",
            image:false,
            button:false,
            data:{

                id:"Account",
                name:"Account",
                graphic:{
                    tileColor:tileColor,
                    iconColor:iconColor,
                    darklite:darklite
                }

            }
        
        },

        {
                
            heading:"Schedule",
            bullet:false,
            stripe:true,
            pgraph:'View templates or save templates to "My Calculators".',
            image:false,
            button:false,
            data:{

                id:"Schedule",
                name:"Schedule",
                graphic:{
                    tileColor:tileColor,
                    iconColor:iconColor,
                    darklite:darklite
                }

            }
        
        },

        {
                
            heading:"Overhead",
            bullet:false,
            stripe:true,
            pgraph:'View templates or save templates to "My Calculators".',
            image:false,
            button:false,
            data:{

                id:"Overhead",
                name:"Overhead",
                graphic:{
                    tileColor:tileColor,
                    iconColor:iconColor,
                    darklite:darklite
                }


            }
        
        },



        {
                
            heading:"Expenses",
            bullet:false,
            stripe:true,
            pgraph:'View templates or save templates to "My Calculators".',
            image:false,
            button:false,
            data:{

                id:"Expenses",
                name:"Expenses",
                graphic:{
                    tileColor:tileColor,
                    iconColor:iconColor,
                    darklite:darklite
                }

            }
        
        },

        {
                
            heading:"Owners",
            bullet:false,
            stripe:true,
            pgraph:'View templates or save templates to "My Calculators".',
            image:false,
            button:false,
            data:{

                id:"Owners",
                name:"Owners",
                graphic:{
                    tileColor:tileColor,
                    iconColor:iconColor,
                    darklite:darklite
                }

            }
        
        },


        {
                
            heading:"Employees",
            bullet:false,
            stripe:true,
            pgraph:'View templates or save templates to "My Calculators".',
            image:false,
            button:false,
            data:{

                id:"Employees",
                name:"Employees",
                graphic:{
                    tileColor:tileColor,
                    iconColor:iconColor,
                    darklite:darklite
                }
        
            }
        
        },





    ];


    R.push([top,bar,{

        call:"items",
        items:catalogItems
    
    }]);




    return R;

}



export default SceneInfo;
