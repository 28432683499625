// rec-calc-overhead-result-totals


//yearly
//total_ownr_salary_pretax_yearly   				: total_ownr_salary_pretax_yearly,	
//total_ownr_taxes_yearly                     	    : total_ownr_taxes_yearly,	
//total_ownr_ira_yearly							: total_ownr_ira_yearly,	
//total_ownr_health_insurance_yearly         		: total_ownr_health_insurance_yearly,




const VPownrsYearly = {


    "name":"Owners Yearly",
    "value":{


        "total_ownr_salary_pretax_yearly" : {

            "print"		:'price',
            "name"		:'total_ownr_salary_pretax_yearly',
            "value"		:0,
            "required"	:true,
            "default"	:0
        },

        "total_ownr_health_insurance_yearly" : {

            "print"		:'price',
            "name"		:'total_ownr_health_insurance_yearly',
            "value"		:0,
            "required"	:true,
            "default"	:0
        },

        "total_ownr_ira_yearly" : {

            "print"		:'price',
            "name"		:'total_ownr_ira_yearly',
            "value"		:0,
            "required"	:true,
            "default"	:0

        },

        "total_ownr_taxes_yearly" : {

            "print"		:'price',
            "name"		:'total_ownr_taxes_yearly',
            "value"		:0,
            "required"	:true,
            "default"	:0

        }, 

        "total_ownr_burden_yearly" : {

            "print"		:'price',
            "name"		:'total_ownr_burden_yearly',
            "value"		:0,
            "required"	:true,
            "default"	:0

        }, 




    }



};






const VPownrsMonthly = {



    "name":"Owners Monthly",
    "value":{


        "total_ownr_salary_pretax_monthly" : {

            "print"		:'price',
            "name"		:'total_ownr_salary_pretax_monthly',
            "value"		:0,
            "required"	:true,
            "default"	:0
        },

        "total_ownr_health_insurance_monthly" : {

            "print"		:'price',
            "name"		:'total_ownr_health_insurance_monthly',
            "value"		:0,
            "required"	:true,
            "default"	:0
        },

        "total_ownr_ira_monthly" : {

            "print"		:'price',
            "name"		:'total_ownr_ira_monthly',
            "value"		:0,
            "required"	:true,
            "default"	:0

        },

        "total_ownr_taxes_monthly" : {

            "print"		:'price',
            "name"		:'total_ownr_taxes_monthly',
            "value"		:0,
            "required"	:true,
            "default"	:0

        }, 


        "total_ownr_burden_monthly" : {

            "print"		:'price',
            "name"		:'total_ownr_burden_monthly',
            "value"		:0,
            "required"	:true,
            "default"	:0

        }, 



    }



};









const VPownrsDirect = {


    "name":"Owners",
    "value":{

     
        /*
        "ownr_salary_pretax_yearly_indirect" : {

            "print"		:'price',
            "name"		:'Salary Pretax Yearly (Indirect)',
            "value"		:0,
            "required"	:true,
            "default"	:0
        },
        */


        "ownr_salary_pretax_monthly_indirect" : {

            "print"		:'price',
            "name"		:'ownr_salary_pretax_monthly_indirect',
            "value"		:0,
            "required"	:true,
            "default"	:0
        },

        "ownr_health_insurance_monthly" : {

            "print"		:'price',
            "name"		:'ownr_health_insurance_monthly',
            "value"		:0,
            "required"	:true,
            "default"	:0
        },

        "ownr_ira_monthly_indirect" : {

            "print"		:'price',
            "name"		:'ownr_ira_monthly_indirect',
            "value"		:0,
            "required"	:true,
            "default"	:0

        },

        "ownr_taxes_monthly_indirect" : {

            "print"		:'price',
            "name"		:'ownr_taxes_monthly_indirect',
            "value"		:0,
            "required"	:true,
            "default"	:0

        }, 



    }



};







const VPownrsIndirect = {


    "name":"Owners",
    "value":{

     
        /*
        "ownr_salary_pretax_yearly_indirect" : {

            "print"		:'price',
            "name"		:'Salary Pretax Yearly (Indirect)',
            "value"		:0,
            "required"	:true,
            "default"	:0
        },
        */


        "ownr_salary_pretax_monthly_indirect" : {

            "print"		:'price',
            "name"		:'ownr_salary_pretax_monthly_indirect',
            "value"		:0,
            "required"	:true,
            "default"	:0
        },

        "ownr_health_insurance_monthly" : {

            "print"		:'price',
            "name"		:'ownr_health_insurance_monthly',
            "value"		:0,
            "required"	:true,
            "default"	:0
        },

        "ownr_ira_monthly_indirect" : {

            "print"		:'price',
            "name"		:'ownr_ira_monthly_indirect',
            "value"		:0,
            "required"	:true,
            "default"	:0

        },

        "ownr_taxes_monthly_indirect" : {

            "print"		:'price',
            "name"		:'ownr_taxes_monthly_indirect',
            "value"		:0,
            "required"	:true,
            "default"	:0

        }, 



    }



};

// rec-calc-overhead-result-totals


const VPownrs = {


    "name":"Owners",
    "value":{

     
        "ownr_salary_pretax_yearly_indirect" : {

            "print"		:'price',
            "name"		:'ownr_salary_pretax_yearly_indirect',
            "value"		:0,
            "required"	:true,
            "default"	:0
        },


        "ownr_salary_pretax_monthly_indirect" : {

            "print"		:'price',
            "name"		:'ownr_salary_pretax_monthly_indirect',
            "value"		:0,
            "required"	:true,
            "default"	:0
        },

        "ownr_health_insurance_monthly" : {

            "print"		:'price',
            "name"		:'ownr_health_insurance_monthly',
            "value"		:0,
            "required"	:true,
            "default"	:0
        },

        "ownr_ira_monthly_indirect" : {

            "print"		:'price',
            "name"		:'ownr_ira_monthly_indirect',
            "value"		:0,
            "required"	:true,
            "default"	:0

        },

        "ownr_taxes_monthly_indirect" : {

            "print"		:'price',
            "name"		:'ownr_taxes_monthly_indirect',
            "value"		:0,
            "required"	:true,
            "default"	:0

        }, 



    }



};






/*

const VPowner = {


    "name": "New Owner",
    "value": {

        
        "ownr_call":{//used for adding new = filter adds call stored

            "name": "Call",
            "print": "hidden",
            "default": "new",
            "value": "new",
            "required": false

        },
        
        "ownr_name": {
            "name": "Name",
            "print": "standard",
            "default": "",
            "value": "",
            "required": true
        },
        "ownr_hourly_rate": {
            "name": "Hourly Rate",
            "print": "price",
            "default": 18.00,
            "value": 18,
            "required": true
        },
        "ownr_burden_weekly_hours": {
            "name": "Weekly Hours Burden",
            "print": "standard",
            "default": 40,
            "value": 40,
            "required": true
        },
        "ownr_vacation_weeks": {
            "name": "Vacation Weeks",
            "print": "standard",
            "default": 2,
            "value": 2,
            "required": true
        },
        "ownr_health_insurance_monthly": {
            "name": "Health Insurance Monthly",
            "print": "price",
            "default": 200.00,
            "value": 200.00,
            "required": true
        },
        "ownr_ira_percent": {
            "name": "IRA %",
            "print": "percent",
            "default": 2.0,
            "value": 2.0,
            "required": true
        },
        "ownr_tax_comp_percent": {
            "name": "Tax Comp %",
            "print": "percent",
            "default": 20.0,
            "value": 20.0,
            "required": true
        }


    }


};


*/




const VPownr = {


    "name": "New Owner",
    "value": {


        "ownr_name": {
            "name": "Name",
            "print": "standard",
            "default": "John Doe",
            "value": "John Doe",
            "required": true

        },
        "ownr_salary_pretax": {
            "name": "Salary Pretax",
            "print": "price",
            "default": 50000,
            "value": 50000,
            "required": true
        },


        // -- Tax -- //

        "ownr_taxrate_percent": {
            "name": "Tax Comp %",
            "print": "percent",
            "default": 20.0,
            "value": 20.0,
            "required": true
        },





        // -- Time - Hours Weekly - DualSlider % -- //

        "ownr_hours_weekly": {
            "name": "Hours Weekly",
            "print": "standard",
            "default": 0,//use companyInfo as the default
            "value": 40,
            "required": true
        },

        "ownr_labor_percent": {
            "name": "Direct/Indirect Labor %",
            "print": "dualslider",//slider or dualslider - for dualslider value = valueA
            "default": 50,
            "value": 50,
            "required": true
        },




        
        // -- Owner Labor - Design,Production,Installation / Sales,Business Management,Office -- //

        "ownr_labor_direct": { 
            "name": "Direct Labor",
            "print": "checkboxes-edit",
            "default": '',
            "value": 'Design,Production',
            "required": true
        },

        "ownr_labor_indirect": { 
            "name": "Indirect Labor",
            "print": "checkboxes-edti",
            "default": '',
            "value": '',
            "required": true
        },





        // -- Benefits -- //

        "ownr_health_insurance_monthly": {
            "name": "Health Insurance Monthly",
            "print": "price",
            "default": 500.00,
            "value": 500.00,
            "required": true
        },
        "ownr_ira_percent": {
            "name": "IRA %",
            "print": "percent",
            "default": 2.0,
            "value": 2.0,
            "required": true
        },
        "ownr_vacation_weeks": {
            "name": "Vacation Weeks",
            "print": "standard",
            "default": 2,
            "value": 2,
            "required": true
        }


    }


};








export {

    VPownrsYearly,
    VPownrsMonthly,
    VPownrsDirect,
    VPownrsIndirect,
    VPownrs,
    VPownr

};



