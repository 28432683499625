// import { 
//     useSelector, 
//     //useDispatch 
// } 
// from 'react-redux';
// //import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';



// import { Settings } from 'app-components/Prime/Data.js';
// import ApiResources from 'utils/components/Pages/ApiResources';


const SceneInfo = (props) => {



    var R = [];
    //var index;

    var top;
    var bar;   
    var catalogItems;

    // var accountName;
    // var accountValue;



    //#############################################################
    //#############################################################

    top = {

        call:'top',
        name:"Settings",
        controls:[

            // {

            //     name:"Go Back",
            //     // form:{

            //     //     submitCall:'account',
            //     //     postTo:apiDomain+apiCall,
            //     //     data:VPaccount.company,
            //     //     flyr:{
            //     //         type:"form",
            //     //         styl:{
            //     //             maxWidth:475,
            //     //             fieldType:'field'
            //     //         }
            //     //     }
             

            //     // }

            //     hndl:{

            //         call:'readout',
            //         data:false
            //     }

            // }

        ]

    }


    bar = {

        call:'bar',
        items: [

            {

                name:"-"

            },

            {

                name:"-"

            }

        ]
        
    }



    // console.log("Props: "+JSON.stringify(props.goods,null,2));



    var iconColor, darklite;

    darklite = "dark";
    
    iconColor = "#7e77ad";//purple
    iconColor = "#4cc1bb";//teal
    iconColor = "#454d5f"; // "#c7c9cf"; //"#a2a6af";

    //grays
    iconColor = "#6a707e"; // #7c828f #8f949f 
    //iconColor = "#7c828f";
    //iconColor = "#8f949f";


    catalogItems = [

        {
                
            heading:"Account",
            bullet:false,
            stripe:true,
            pgraph:'View/Edit "Email", "Password" and "Company Information"',
            image:false,
            button:false,
            data:{

                id:"Account",
                name:"Account",
                graphic:{
                    color:iconColor,
                    darklite:darklite
                }


            }
        
        },

        {
                
            heading:"Schedule",
            bullet:false,
            stripe:true,
            pgraph:'View/Edit "Work Schedule"',
            image:false,
            button:false,
            data:{

                id:"Schedule",
                name:"Schedule",
                graphic:{
                    color:iconColor,
                    darklite:darklite
                }

            }
        
        },

        {
                
            heading:"Overhead",
            bullet:false,
            stripe:true,
            pgraph:'View/Edit "Overhead Percentage" and "Projected Monthly Sales"',
            image:false,
            button:false,
            data:{

                id:"Overhead",
                name:"Overhead",
                graphic:{
                    color:iconColor,
                    darklite:darklite
                }

            }
        
        },



        {
                
            heading:"Expenses",
            bullet:false,
            stripe:true,
            pgraph:'View, Create and Edit Expense(s)',
            image:false,
            button:false,
            data:{

                id:"Expenses",
                name:"Expenses",
                graphic:{
                    color:iconColor,
                    darklite:darklite
                }

            }
        
        },

        {
                
            heading:"Owners",
            bullet:false,
            stripe:true,
            pgraph:'View, Create and Edit Owner(s)',
            image:false,
            button:false,
            data:{

                id:"Owners",
                name:"Owners",
                graphic:{
                    color:iconColor,
                    darklite:darklite
                }

            }
        
        },


        {
                
            heading:"Employees",
            bullet:false,
            stripe:true,
            pgraph:'View, Create and Edit Employee(s)',
            image:false,
            button:false,
            data:{

                id:"Employees",
                name:"Employees",
                graphic:{
                    color:iconColor,
                    darklite:darklite
                }

        
            }
        
        },





    ];



    R.push([top,bar,{

        call:"items",
        items:catalogItems
    
    }]);




    return R;

}



export default SceneInfo;




/*

{
"name": "Account",
"value": "http://site.site/Account/",
"position": "left",
"mainWidth":"100%"
},
{
"name": "Schedule",
"value": "http://site.site/Schedule/",
"position": "left",
"mainWidth":"100%"
},
{
"name": "Overhead",
"value": "http://site.site/Overhead/",
"position": "left",
"mainWidth":"100%"
},
{
"name": "Expenses",
"value": "http://site.site/Expenses/",
"position": "left",
"mainWidth":"100%"
},
{
"name": "Owners",
"value": "http://site.site/Owners/",
"position": "left",
"mainWidth":"100%"
},
{
"name": "Employees",
"value": "http://site.site/Employees/",
"position": "left",
"mainWidth":"100%"
}


*/