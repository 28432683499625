import styled from "styled-components";


//#############################################################
//#############################################################


export const CatalogTileDark = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#191e28",
    border:             props.border || "1px solid #454d5f",

    width:              props.width || "calc(100% - 0px)",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "0 0 2.5px 0",


    titleFontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    titleFontSize:           props.fontSize || "20px",
    titleFontWeight:         props.fontWeight  || "550",
    titleTextAlign:          props.textAlign || "left",
    titleTextDecoration:     props.textDecoration  || "none",
    titleColor:              props.color || "white", //#20b2aa #00cc66

    descrFontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    descrFontSize:           props.fontSize || "14px",
    descrFontWeight:         props.fontWeight  || "500",
    descrTextAlign:          props.textAlign || "left",
    descrTextDecoration:     props.textDecoration  || "none",
    descrColor:              props.color || "#a2a6af",


    letterFontFamily:         props.letterFontFamily || 'Verdana, Arial, Helvetica, sans-serif',
    letterFontSize:           props.letterFontSize || "28px",
    letterFontWeight:         props.letterFontWeight  || "500",
    letterTextAlign:          props.letterTextAlign || "center",
    letterTextDecoration:     props.letterTextDecoration  || "none",
    letterColor:              props.letterColor || "white",


    buttonFontFamily:         props.buttonFontFamily || 'Arial, Helvetica, sans-serif',
    buttonFontSize:           props.buttonFontSize || "14px",
    buttonFontWeight:         props.buttonFontWeight  || "500",
    buttonTextAlign:          props.buttonTextAlign || "center",
    buttonTextDecoration:     props.buttonTextDecoration  || "none",
    buttonTextColor:          props.buttonTextColor || "white"


    // word-wrap: break-word;

    // #EDEEF1


}))`


    background-color:${props => props.backgroundColor};
    border-bottom:${props => props.border};
    border-radius:0px;

    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;
    cursor:default;

    @media (max-width: 600px) {

        height:auto;
        padding-bottom:40px;
    }


    &:hover {

        background-color:#141820;
        border-bottom:1px solid #454d5f;
    }



    .Controls {

        background-color:transparent;

        width:auto;
        height:35px;
        padding:0 0 0 0;
        margin:0 0 0 0;
        position:absolute;
        z-index:2;        
        top:14px;
        right:0px;

        cursor:default;
    }

        .Controls .Button {

            background-color:transparent;
            border:1px solid transparent;
            border-radius:10px;
    
            width:25px;
            height:auto;
            padding:5px 3px 5px 3px;
            margin:0 8px 0 0;
            position:relative;
            float:left;
    
            cursor:pointer;
    
            font-family:${props => props.buttonFontFamily};
            font-size:${props => props.buttonFontSize};
            font-weight:${props => props.buttonFontWeight};
            text-align:${props => props.buttonTextAlign};
            text-decoration:${props => props.buttonTextDecoration};
            color:${props => props.buttonTextColor};


            &:hover {

                background-color:#454d5f;
                border:1px solid #454d5f;
        
            }

            
        }

        .Controls .Button:hover > .Dot {
            
            background-color:white; 
        }
    

            .Controls .Button .Dot {

                background-color:gray;
                border-radius:50%;
        
                width:7px;
                height:7px;
                padding:0 0 0 0;
                margin:4px auto 4px auto;
                
                cursor:pointer;
                
            }







    .Favorite {

        background-color:transparent;

        width:auto;
        height:auto;
        padding:0 0 0 0;
        margin:0 0 0 0;
        position:absolute;
        z-index:1px;
        top:24.5px;
        right:65px;

        cursor:pointer;

    }


    @media (max-width: 600px) {


        .Favorite {

            top:calc(100% - 30px);
            right:13px;

        }


    }








    .Left {

        background-color:transparent;
        border-radius:7px;

        width:55px;
        height:55px;
        padding:0 0 0 0;
        margin:10px 0 10px 10px;
        position:relative;
        float:left;

        cursor:pointer;
    }

    .Right {

        background-color:transparent;

        width:calc(100% - 75px);
        height:auto;
        padding:0 0 0 0;
        margin:7px 0 0 0;
        position:relative;
        float:left;

        cursor:pointer;

    }

    &:hover > .Right .Title {
            
        text-decoration:underline; 
    }




    @media (max-width: 600px) {

        .Left {

            width:60px;
            height:60px;

        }

        .Right {

            width:calc(100% - 150px);
        }


    }





    .GraphicTile {

        background-color:transparent;

        width:70%;
        height:70%;
        padding:0 0 0 0;
        margin:15% 0 0 15%;
        position:relative;
        float:left;
        cursor:pointer;
    }



    .Graphic {

        background-color:transparent;

        width:100%;
        height:100%;
        padding:0 0 0 0;
        margin:0 0 0 0;
        position:relative;
        float:left;
        cursor:pointer;
    }



    .Circle {

        background-color:#00cc66;
        border-radius:100%;

        width:90%;
        height:90%;
        padding:0 0 0 0;
        margin:5% 0 0 5%;
        position:relative;
        float:left;
        cursor:pointer;
    }






        .Circle .Letter {

            background-color:transparent;

            width:100%;
            height:auto;
            padding:0 0 0 0;
            margin:calc(50% - 16px) 0 0 0;

            cursor:pointer;

            font-family:${props => props.letterFontFamily};
            font-size:${props => props.letterFontSize};
            font-weight:${props => props.letterFontWeight};
            text-align:${props => props.letterTextAlign};
            text-decoration:${props => props.letterTextDecoration};
            color:${props => props.letterColor};


        }





    .Title {

        background-color:transparent;

        width:100%;
        height:auto;
        padding:0 0 4px 0;
        margin:10px 10px 0 15px;
        position:relative;
        float:left;

        cursor:pointer;

        font-family:${props => props.titleFontFamily};
        font-size:${props => props.titleFontSize};
        font-weight:${props => props.titleFontWeight};
        text-align:${props => props.titleTextAlign};
        text-decoration:${props => props.titleTextDecoration};
        color:${props => props.titleColor};




    }






    .Descr {

        background-color:transparent;

        width:100%;
        height:auto;
        padding:0 0 4px 0;
        margin:0 10px 0 15px;
        position:relative;
        float:left;

        cursor:pointer;

        font-family:${props => props.descrFontFamily};
        font-size:${props => props.descrFontSize};
        font-weight:${props => props.descrFontWeight};
        text-align:${props => props.descrTextAlign};
        text-decoration:${props => props.descrTextDecoration};
        color:${props => props.descrColor};


        word-wrap: break-word;


    }




    @media (max-width: 600px) {

        .Title {

            margin-top:7px;
            font-size:20px;
            
        }

        .Descr {

            font-size:16px;
            
        }

    }













`;


























