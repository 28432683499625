import styled from "styled-components";


export const CatalogCalcItem = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "white",
    border:             props.border || "1px solid lightgray",

    width:              props.width || "calc(100% - 2px)",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 0",
    padding:            props.padding || "0 0 0 0",


    fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    fontSize:           props.fontSize || "18px",
    fontWeight:         props.fontWeight  || "500",
    textAlign:          props.textAlign || "left",
    textDecoration:     props.textDecoration  || "none",
    textColor:          props.textColor || "black",


    valueFontFamily:         props.valueFontFamily || 'Arial, Helvetica, sans-serif',
    valueFontSize:           props.valueFontSize || "16px",
    valueFontWeight:         props.valueFontWeight  || "500",
    valueTextAlign:          props.valueTextAlign || "left",
    valueTextDecoration:     props.valueTextDecoration  || "none",
    valueTextColor:          props.valueTextColor || "black"


}))`


    background-color:${props => props.backgroundColor};
    border-bottom:${props => props.border};
    border-left:${props => props.border};
    border-right:${props => props.border};

    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;

    cursor:pointer;

    @media (max-width: 300px) {

        height:80px;
    }


    &:hover {

        background-color:#caffca;
        text-decoration:underline;
    }






    .Name {

        background-color:transparent;

        width:calc(40% - 15px);
        height:auto;
        padding:12px 0 10px 15px;
        margin:0 10px 0 0;
        position:relative;
        float:left;

        cursor:pointer;

        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        text-align:${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.textColor};

    }


    &:hover > .Name {
            
        color:#191e28;
        text-decoration:underline;
    }




    .Grid {

        background-color:transparent;

        width:calc(50% - 0px);
        height:auto;
        padding:0 0 0 0;
        margin:0 0 0 0;
        position:relative;
        float:right;

    }



        .Grid .Left {

            background-color:transparent;
            border-left:1px solid lightgray;

            width:calc(50% - 12px);
            height:auto;
            padding:14px 0 12px 10px;
            margin:0 0 0 0;

            position:relative;
            float:left;


            cursor:pointer;

            font-family:${props => props.valueFontFamily};
            font-size:${props => props.valueFontSize};
            font-weight:${props => props.valueFontWeight};
            text-align:${props => props.valueTextAlign};
            text-decoration:${props => props.valueTextDecoration};
            color:${props => props.valueTextColor};



        }


        .Grid .Right {

            background-color:transparent;
            border-left:1px solid lightgray;

            width:calc(50% - 12px);
            height:auto;
            padding:14px 0 12px 10px;
            margin:0 0 0 0;

            position:relative;
            float:left;

            font-family:${props => props.valueFontFamily};
            font-size:${props => props.valueFontSize};
            font-weight:${props => props.valueFontWeight};
            text-align:${props => props.valueTextAlign};
            text-decoration:${props => props.valueTextDecoration};
            color:${props => props.valueTextColor};



        }


            .Grid .Right .Price {

                background-color:transparent;
               
                width:(100% - 60px);
                height:auto;
                padding:0 0 0 0;
                margin:0 0 0 0;

                position:relative;
                float:left;

                cursor:pointer;

                font-family:${props => props.valueFontFamily};
                font-size:${props => props.valueFontSize};
                font-weight:${props => props.valueFontWeight};
                text-align:${props => props.valueTextAlign};
                text-decoration:${props => props.valueTextDecoration};
                color:${props => props.valueTextColor};


            }


            .Grid .Right .Percent {

                background-color:transparent;
                border-left:2px solid black;
                border-right:2px solid black;
                border-radius:18px;

                width:60px;
                height:auto;
                padding:0 0 0 0;
                margin:0 0 0 5px;

                position:relative;
                float:left;

                cursor:pointer;

                font-family:${props => props.valueFontFamily};
                font-size:${props => props.valueFontSize};
                font-weight:${props => props.valueFontWeight};
                text-align:center;
                text-decoration:${props => props.valueTextDecoration};
                color:${props => props.valueTextColor};


            }






`;


