// import { 
//     useSelector, 
//     //useDispatch 
// } 
// from 'react-redux';
// //import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';




import { LibArray } from "library";


// import ApiResources from 'utils/components/Pages/ApiResources';


const SceneInfo = (props) => {



    // //-------------------------------------------

    // var apiDomain;
    // var hostname;
    // //var hostname = window.location.hostname;//returns the domain name of the web host
    // var protocol = window.location.protocol;//returns the web protocol used (http: or https:)

    // if(window.location.hostname.match(/192.168/gi))
    // {
    //     hostname = ApiResources.network['3001'].vpc;
    //     apiDomain = protocol + "//" + hostname + "/";
    // }
    // else
    // if(window.location.hostname.match(/localhost/gi))
    // {
    //     hostname = ApiResources.local['3001'].vpc;
    //     apiDomain = protocol + "//" + hostname + "/";
    // }else{
    //     hostname = ApiResources.aws['x'].vpc;
    //     apiDomain = "https://" + hostname + "/";
    // }//=

    // //-------------------------------------------

    // //console.log("API DOMAIN: "+apiDomain);


    // //const apiCall = 'alter-calc-test';
    // const apiCall = 'alter-calc-dash';


    // -- redux -- //
    //const dispatch = useDispatch();
    //const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));




    var R = [];
    // var index;

    var keyname;
    //var kn;
    var item;
    var captured;
    var array;
    var value;
    // var newValue;

    // var a,b,c,d;
    // var total;
    // var grand_total;


    // var chartSections;
    // var object;
    // var percent;

    // var pgraph;


    // var obj;









    //#############################################################
    //#############################################################

    //--------------------------
    // Catalog - Top
    //--------------------------


    R.push({

        call:'top',
        name:"Glossary",
        controls:[

            // {

            //     name:"Create Term",
            //     // form:{

            //     //     submitCall:'calculate',
            //     //     postTo:apiDomain+apiCall,
            //     //     data:newTemplateExpenseForm,
            //     //     flyr:{

            //     //         hashbang:"#!new",
            //     //         type:"form",
            //     //         styl:{
            //     //             maxWidth:500,
            //     //             fieldType:'composed'   //multi,field,labelfield
            //     //         }

            //     //     }   

            //     // }

            // }

        ]

    });



    //#############################################################
    //#############################################################

    //--------------------------
    // Catalog - Bar
    //--------------------------

    // R.push({

    //     call:'bar',
    //     items: [

    //         {

    //             name:"Term"

    //         },

    //         // {

    //         //     name:"Actions"

    //         // }

    //     ]
        
    // });



    //#############################################################
    //#############################################################

    //--------------------------
    // Catalog - Template Calcs
    //--------------------------

    var catalogItems = [];

    if(props.zoneObj['knowledge_base'] 
    && props.zoneObj['knowledge_base']!== undefined)
    {
        if(props.zoneObj['knowledge_base'].glossary 
        && props.zoneObj['knowledge_base'].glossary !== undefined)
        {


    
            // var glossary = [
        
            //     {
            //         "recid": "glossary-s-sales-commission",
            //         "siteuser": "theprofitcalculator.com-admin",
            //         "location": "",
            //         "privacy": "",
            //         "subtype": "",
            //         "user": "admin",
            //         "url": "",
            //         "name": "",
            //         "cueprint": "",
            //         "image": "",
            //         "caption": "",
            //         "data": {
                //         "descr": "Percentage of the revenue that is paid to a salesperson for obtaining or cultivating a sale. Sales Commission is optional and can be 0% ",
                //         "term": "Sales Commission",
                //         "alphabet": "S"
            //         },
            //         "tree": "",
            //         "reckey": "rec-glossary-s-sales-commission",
            //         "type": "glossary"
            //     },
            //     {
            //         "recid": "glossary-v-vinyl-profit",
            //         "siteuser": "theprofitcalculator.com-admin",
            //         "location": "",
            //         "privacy": "",
            //         "subtype": "",
            //         "user": "admin",
            //         "url": "",
            //         "name": "",
            //         "cueprint": "",
            //         "image": "",
            //         "caption": "",
            //         "data": {
                //         "descr": "Vinyl Profit = Net Profit",
                //         "term": "Vinyl Profit",
                //         "alphabet": "V"
            //         },
            //         "tree": "",
            //         "reckey": "rec-glossary-v-vinyl-profit",
            //         "type": "glossary"
            //     }
            // ]
        



            var glossary = JSON.parse(JSON.stringify(props.zoneObj['knowledge_base'].glossary));


            // console.log("ObjPrototype: "+Object.prototype.toString.call(glossary));
            // console.log("Scene Info - glossary: "+JSON.stringify(glossary,null,2));
         


            // var keyname, item;
            // var array, value;
            
            captured = [];
            array = [];
            for(keyname in glossary)
            {
                item = glossary[keyname].data;
                if(item.usage 
                && item.usage !== undefined 
                && Object.prototype.toString.call(item.usage) === '[object String]'
                )
                {
                    //console.log("USAGE: "+item.usage);
                    if(item.usage.match(/,/gi))
                    {
                        item.usage = item.usage.split(",");
                    }else{
                        if(item.usage.toUpperCase() !== "NONE")
                        {
                            item.usage = [item.usage];
                        }else{
                            item.usage = [];  
                        }
                    }

                }else{
                    item.usage = [];
                }

                // item.usage = [

                //     "Overhead",
                //     "Expenses",
                //     "Owner",
                //     "Employee"

                // ];
                if(!LibArray.InArray(item.term,captured))
                {
                    captured.push(item.term);
                    array.push(item);
                }

            }
            captured.sort();

            for(value of captured)
            {
                for(item of array)
                {
                    if(item.term === value)
                    {
                        catalogItems.push(item);
                        break;

                    }
                }
            }


            // for(value of array)
            // {
            //     catalogItems.push({

            //         category:value,
            //         descr:"View the templates for "+value

            //     });
            // }

        }


    }


    R.push({

        call:"glossary",
        items:catalogItems

    });

    return R;


}



export default SceneInfo;
