import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';


import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    useHistory,   
    //useParams,

} from "react-router-dom";

import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'


import {

    //---------------
    // Stage
    //---------------

    Stage,
    ScrollBox,
    // Area,
    // AreaTitle,



    //---------------
    // Layout
    //---------------

    MenuAlfa,
    Menu,
    MenuControls,

    Topbar,

    Side,   
    Main,

    // Catalog,
    //     CatalogTop,
    //         CatalogTopName,
    //         CatalogTopControls,

    //     CatalogBar,
    //     CatalogLineItem,

        // CatalogItem,
        //     CatalogItemLineName,
        //     CatalogItemControls,

    
    // Rows,
    // FullRow,
    // IndentRow,

    // Box,

    Msg,

    // Tile,
    // TileName,
    // TileTop,
    // TileControls
    

} from 'app-components/Zone/_Styled';




//import Loader from 'utils/components/Process/Loader';
//import Messngr from 'utils/components/Process/Messngr';


import Navigator from 'utils/components/Content/Navigator';
import SideNavInfo from 'utils/components/Pages/SceneInfo/Trials/SideNav/SceneInfo';

import { VPreportList } from 'app-components/Prime/VPdata/reports';

import SubCategory from './SubCategory';
import Report from './Report';


import { LibArray } from "library";


const Index = (props) => {



    // -- window dims -- //
    const { 
        width,
        height 
    } = useWindowDimensions();
    


    // -- redux -- //
    const zoneObj = useSelector(state => state.zone);


    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    const history = useHistory();

    
 
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);





    //=============================================
    // state/effect - traffic
    //=============================================

    const [showMsg, setShowMsg] = useState("Category - Traffic");
    const [traffic, setTraffic] = useState(false);
    // const [loaderStuff] = useState({

    //     box:{

    //         backgroundColor:'darkblue',
    //         border:false,
    //         width:false,
    //         height:false,
    //         top:false,
    //         left:false

    //     },

    //     loader:{

    //         class       :'sm-loader-circle',
    //         lowlight    : "0.5em solid rgba(250,250,250,0.2)",
    //         highlight   : "0.5em solid #00ff00"

    //     }

    // });



    useEffect(() => {

        var reports;
        var msg = false;
        var traf = false;

        var pathArr;
        var pathCount;

        var mainIndex;
        var currIndex;

        var mainCategoryName;
        var categoryName;
    
        //var categoryIndex;
        //var nextIndex;

        var categoryCount;
        //var regexCategoryName;

        //var keyname;
        var array;

        var item, itemB;
        var search, found;

        //var group, groupName;


        //console.log("Has Traffic - Category Component");
        //console.log("Category - Reports - Review");

        reports = JSON.parse(JSON.stringify(VPreportList));
        //console.log(JSON.stringify(reports,null,2))

        if(Object.keys(reports).length > 0)
        {
            //console.log("Has Reports - further - review");
            msg = "Has Reports - further - review"

            if(pathname.match(/Reports\/Default/gi))
            {
                //console.log('No Reports - Show Default Report');
                //msg = 'No Templates - Show Default Calulators';
                msg = false;
                traf = 'Report';
            
            }else{

                pathArr = pathname.split("/");
                pathCount = pathArr.length;

                currIndex = parseInt(pathCount)-1;
                categoryName = pathArr[currIndex];


                //categoryIndex = parseInt(pathCount)-2;
                //nextIndex = categoryIndex + 1;
            
                categoryCount = currIndex;

                // //regexCategoryName = new RegExp(categoryName,"gi");

                mainCategoryName = false;
                if(categoryCount > 1)
                {
                    //console.log("categoryCount: "+categoryCount);
            
                    mainIndex = parseInt(pathCount)-2;
                    mainCategoryName = pathArr[mainIndex];
            
                    //console.log("mainCategoryName: "+mainCategoryName);
                    
                }//==
            

                // alert("Category Component"
                // +"\ncurrIndex: "+currIndex
                // //+"\nnextIndex: "+nextIndex
                // //+"\ncategoryIndex: "+categoryIndex
                // +"\ncategoryName: "+categoryName
                // );

                //alert(pathname+"\npathCount: "+pathArr.length+"\nlastPath: "+categoryName);
                //alert("pathCount: "+pathCount+"\ncategoryIndex: "+categoryIndex+"\ncategoryName: "+categoryName);


                var getKeys,getKey;
                var getSearch = false;
                getKeys = Object.keys(VPreportList.GetList);
                for(getKey of getKeys)
                {
                    if(getKey === categoryName)
                    {
                        getSearch = VPreportList.GetList[getKey].getSearch;
        
                    }//if
        
                }//for 


                //console.log("getSearch: "+getSearch+"\nmainCategoryName: "+mainCategoryName+"\ncategoryName: "+categoryName);

        
                found = false;
                msg = false;
                traf = 'SubCategory';
                if(VPreportList && VPreportList !== undefined)
                {
    
                    array = [];
                    for(item of VPreportList.main)
                    {

                        //console.log("item.sub: "+item.sub);

                        if(item.sub)
                        {

                            search = item.sub.replace(/_/gi," ");
                            if(getSearch && LibArray.InArray(getKeys,search))
                            {
                                //console.log("Report Traffic - getSearch: "+getSearch+"\ncategoryName: "+categoryName);
                                if(categoryName.toLowerCase() !== getSearch.toLowerCase())
                                {
                                    console.log("GetSubs");
                                    found = true;
                                }else{
                                    console.log("The Report");
                                    found = false;
                                }
                                break;

                            }
                            else
                            if(search === categoryName)
                            {
                                for(itemB of VPreportList[item.sub])
                                {
                                    if(!LibArray.InArray(itemB.name,array)
                                    )
                                    {
                                        array.push(itemB.name);
                                    }
                                }
                                //alert("keyname: "+keyname+"\n"+item.sub);
                                found = true;
                                break;
                            }
                            else
                            if(mainCategoryName)
                            {
                                //console.log(mainCategoryName);
                                found = false;
                                break;
                            }
                            

                        }
                    
        
                    }//for






                    // if(categoryCount > 0)
                    // {
                    //     groupName = categoryName.replace(/ /gi,"_");
                    //     //groupName = groupName.toLowerCase();
        
                    //     //console.log(keyname+"\n"+groupName);
                    //     if(keyname === 'main')
                    //     {
                    //         alert(keyname+"\n"+groupName);
                    //         group = VPreportList[groupName];
                    //         for(item of group)
                    //         {
                    //             if(!item.sub) traf = "Report";
                    //             if(!LibArray.InArray(item.name,array))
                    //             {
                    //                 array.push(item.name);
                    //             }
                    //         }
        
                    //     }
        
        
                    // }else{
        
                    //     group = VPreportList['main'];
                    //     for(item of group)
                    //     {

                    //         //if(!item.sub) traf = "Report";
                    //         if(!LibArray.InArray(item.name,array))
                    //         {
                    //             array.push(item.name);
                    //         }
                    //     }
                    // }
        






                    // for(keyname in VPreportList)
                    // {

                    //     if(categoryCount > 0)
                    //     {
                    //         groupName = categoryName.replace(/ /gi,"_");
                    //         //groupName = groupName.toLowerCase();
            
                    //         //console.log(keyname+"\n"+groupName);
                    //         if(keyname === 'main')
                    //         {
                    //             alert(keyname+"\n"+groupName);
                    //             group = VPreportList[groupName];
                    //             for(item of group)
                    //             {
                    //                 if(!item.sub) traf = "Report";
                    //                 if(!LibArray.InArray(item.name,array))
                    //                 {
                    //                     array.push(item.name);
                    //                 }
                    //             }
            
                    //         }
            
            
                    //     }else{
            
                    //         group = VPreportList['main'];
                    //         for(item of group)
                    //         {

                    //             //if(!item.sub) traf = "Report";
                    //             if(!LibArray.InArray(item.name,array))
                    //             {
                    //                 array.push(item.name);
                    //             }
                    //         }
                    //     }
            
            
                    // }



                    if(!found) traf="Report";
                    console.log(traf);
                    array.sort();
            
                }

   

            }


        }else{

            //console.log("No Reports - end - review");
            msg = "No Reports - end - review";

            if(pathname.match(/Reports\/Default/gi))
            {
                console.log('No Reports - Show Default Report');
                //msg = 'No Templates - Show Default Calulators';
                msg = false;
                traf = 'Report';
            
            }

        }  
        //alert(traf);
        if(msg) setShowMsg(msg);
        if(traf) setTraffic(traf);


    




    },
    [

        pathname,
        zoneObj,
        traffic,
        history

    ])






    //=============================================
    // refs
    //=============================================

    // const [rowRefs] = useState([]);
    // const setRowRef = (ref) => {

    //     if(ref && ref !== null && ref !== undefined)
    //     {
    //         //console.log("ref::::"+ref.id);
    //         var found;
    //         found = false;
    //         for(var i=0; i < rowRefs.length; i++)
    //         {
    //             if(rowRefs[i].id === ref.id) found = true;
    //         }
    //         if(!found) rowRefs.push(ref);
    //         //console.log(boxRefs.length);

    //     }

    // };



    // -- ref -- //
    const refScrollbox = useRef(null);
    const refTopbar = useRef(null); 
    const refSide = useRef(null);
    const refMain= useRef(null);


    // -- state -- //
    const [styling,setStyling] = useState({

        topbar:{

            width:false,
            margin:false
        },     
        scrollbox:{
            
            width:false,
            height:false
        },
        main:{

            width:false,
            marginRight:false
        }

    });
    const [threshold] = useState(949);
    

    useEffect(() => {

    
        if(!traffic)
        {

            var mainWidth;
            var mainMargin;

            var mar;
            var maxMar;
            //alert(refMain.current.offsetWidth);
            if(refScrollbox.current.offsetWidth > 750)
            {

                mar = (refScrollbox.current.offsetWidth - refMain.current.offsetWidth)/2;
                if(refSide && refSide.current)
                {
                    maxMar = 50;
                    //console.log("mar"+mar);
                    if(mar > maxMar && refScrollbox.current.offsetWidth > refMain.current.offsetWidth+maxMar*2) mar = maxMar;
                    
                }
                //console.log("above");
                mainWidth = 750 + "px";
                mainMargin = "0 0 50px " +mar+"px";


                
            }else{


                if(refScrollbox.current.offsetWidth < 751)
                {
                    //console.log("above");
                    mainWidth = "100%";
                    mainMargin = "0 0 0 0";
    
                }else{

                    console.log("below");
                    mainWidth = "100%";
                    mainMargin = "0 0 50px 0px";

                }

            }



            var topbarObj;
            topbarObj = {

                width:"calc(100% - 0px)",
                margin:"0 0 0 0"

            };
            var scrollboxObj;
            scrollboxObj = {

                width:"calc(100% - 0px)",
                height:"calc(100% - "+refTopbar.current.offsetHeight+"px)",
                margin:"0 0 50px 0"

            };
            if(refSide && refSide.current)
            {

                topbarObj = {
                
                    width:"calc(100% - "+refSide.current.offsetWidth+"px)",
                    margin:"0 0 0 "+refSide.current.offsetWidth+"px"
                }
                scrollboxObj = {
                
                    width:"calc(100% - "+refSide.current.offsetWidth+"px)",
                    height:"calc(100% - "+refTopbar.current.offsetHeight+"px)",
                    margin:"0 0 50px "+refSide.current.offsetWidth+"px"
                }

            }
            setStyling({

                topbar:topbarObj,
                scrollbox:scrollboxObj,
                main:{
        
                    width       :mainWidth,
                    margin      :mainMargin
                }
        
            });

        }

        //console.log("scrollbox w: "+refScrollbox.current.offsetWidth);





    },
    [

        width,
        height,

        refSide,
        refMain,
        refScrollbox,
        refTopbar,

        traffic

    ])


    

    //=============================================
    // state/effect - showMenu
    //=============================================

    // -- state -- //
    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {

        if(width > threshold && showMenu)
        {
            setShowMenu(false);

        }
    },
    [

        width,
        threshold,
        showMenu
    ])




    //=============================================
    // state/effect - breadcrumbs
    //=============================================

    // -- state -- //
    const [breadcrumbs, setBreadcrumbs] = useState([]);


    useEffect(() => {

        var pathArr = pathname.split("/");
        var crumbs = ["Home","/"];
        var i;
        for(i=0; i < pathArr.length; i++)
        {
            if(i < pathArr.length-1)
            {
                crumbs.push(pathArr[i]);
                crumbs.push("/");
            }else{
                crumbs.push(pathArr[i]);
            }

        }
        setBreadcrumbs(crumbs);

    },
    [

        pathname

    ])






    //=============================================
    // handle
    //=============================================

    const handle = (hndl) => {


        switch(hndl.call)
        {
        case'breadcrumb':

            var pathArr = pathname.split("/");
            var url = "/";

            if(hndl.data !== 'Home')
            {
                var i;
                for(i=0; i < pathArr.length; i++)
                {
                    if(pathArr[i] === hndl.data)
                    {
                        url = url + hndl.data;
                        break;
                    }else{
                        url = url + pathArr[i]+"/";
                    }
                }

            }

            //if(url.charAt(url.length-1) === '/') 
                //url = url.substring(0,url.length-1);

            if(url.charAt(url.length-1) !== '/') url = url + "/";

            history.push(url);

        break;
        case'menu':

            switch(hndl.data)
            {
            case'close':
                setShowMenu(false);
            break;
            default:
                setShowMenu(true);
            }
        break;
        default:
        }


    }







    if(!traffic) 
    {

        //return <div className="area-title">Loading...</div>

        return (


            <Stage>

                <Side
                ref={refSide}
                >
                </Side>


                <Topbar
                ref={refTopbar}
                width={styling.topbar.width} 
                margin={styling.topbar.margin}
                >
                </Topbar>
                

                <ScrollBox
                ref={refScrollbox}
                width={styling.scrollbox.width} 
                height={styling.scrollbox.height} 
                margin={styling.scrollbox.margin}
                >

                    <Main
                    ref={refMain}
                    width={styling.main.width} 
                    margin = {styling.main.margin}
                    >

                        {/* <Loader 
                            alfaBgColor={"transparent"}
                            styling={loaderStuff}
                        /> */}

                        
                    </Main>    

                </ScrollBox>


            </Stage>




        )

    } 
    else 
    {


        return (<div>


            {          
            traffic === 'SubCategory'
            ?

                <SubCategory 
                primeProps={props.primeProps}
                />

            :
            traffic === 'Report'
            ?

                <Report 
                primeProps={props.primeProps}
                />

            :

                
                <Stage>


                    {width < threshold && showMenu
                    ?

                        <div>

                            <MenuAlfa
                            onClick={(v) => handle({

                                call:'menu',
                                data:"close"

                            })}
                            ></MenuAlfa>
                            <Menu
                            >
                                <MenuControls>

                                    <div className="Title">

                                        {"Menu"}

                                    </div>

                                    <div 
                                    className="Button"
                                    onClick={(v) => handle({

                                        call:'menu',
                                        data:"close"

                                    })}
                                    >

                                        {"X"}
                                                                        
                                    </div>

                                </MenuControls>

                                <Navigator

                                    handle      = {(v) => props.handle(v)} 
                                    data        = {SideNavInfo['dashboard_sidenav']}
                                    refid       = {"dashboard_sidenav"}

                                    viewHeight  = {false}
                                    databind    = {props.databind}

                                />

                            </Menu>


                        </div>


                    :null}


                    {width > threshold
                    ?

                        <Side
                        ref={refSide}
                        >
                            <Navigator

                                handle      = {(v) => props.handle(v)} 
                                data        = {SideNavInfo['dashboard_sidenav']}
                                refid       = {"dashboard_sidenav"}

                                viewHeight  = {false}
                                databind    = {props.databind}

                            />

                        </Side>


                    :null}



                    <Topbar
                    ref={refTopbar}
                    width={styling.topbar.width} 
                    margin={styling.topbar.margin}
                    >

                        <div
                        className={"Left"}
                        >


                            {
                            width < threshold
                            ?
                                <div
                                className={"MenuButton"}
                                onClick={(v) => handle({

                                    call:'menu',
                                    data:"open"

                                })}
                                >
                                    <div className={"Slice"}></div>
                                    <div className={"Slice"}></div>
                                    <div className={"Slice"}></div>

                                </div>

                            :null
                            }



                            {breadcrumbs.map((crumb, i) => (

                                <div
                                key={i}
                                className={"Button"}
                                onClick={(v) => handle({

                                    call:'breadcrumb',
                                    data:crumb

                                })}
                                >
                                    {crumb}

                                </div>

                            ))}


                        </div>


                        <div
                        className={"Right"}
                        >
                        </div>


                    </Topbar>



                    <ScrollBox
                    ref={refScrollbox}
                    width={styling.scrollbox.width} 
                    height={styling.scrollbox.height} 
                    margin={styling.scrollbox.margin}
                    >

                        <Main
                        ref={refMain}
                        width={styling.main.width} 
                        margin = {styling.main.margin}
                        >
                            <Msg>


                                {         
                                showMsg
                                ?

                                    <div>

                                       {showMsg} 

                                    </div>

                                :

                                    <div>

                                       No Msg

                                    </div>


                                }


                            </Msg>

                        </Main>  

                    </ScrollBox>

                </Stage>



            }




        </div>)


    }

}

export default Index;


