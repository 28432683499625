
import styled from "styled-components";
//import px2vw from "utils/px2vw";

/*
https://styled-components.com/docs


Over 200 classes were generated for component styled.div with the id of "sc-AxirZ".
Consider using the attrs method, together with a style object for frequently changed styles.
Example:

const Component = styled.div.attrs({
    style: ({ background }) => {
        background,
    },
})`width: 100%;`

<Component />




calc(100% - 300px)


TM - Notes 

- PrimeScrollBox calc(100% - 120px) 120px equals the HeaderNav height + InfoBar height
- Use vh - viewport height - to set the stage height

   height:${props => props.height};


*/


const PrimeStage = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#191e28",//#fcfcfc
    backgroundImage:    props.backgroundImage || "url(/_resources/logos/tpc_profit_pie_gray.svg)",
    backgroundRepeat:   props.backgroundRepeat || "no-repeat",
    backgroundPosition: props.backgroundPosition || "50% 45%",
    backgroundSize:     props.backgroundSize || "100px 100px",

    width:              props.width || "100%",
    height:             props.height || "100vh",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0"



}))`

    background-color:${props => props.backgroundColor};
    background-image:${props => props.backgroundImage};
    background-repeat:${props => props.backgroundRepeat};
    background-position:${props => props.backgroundPosition};
    background-size:${props => props.backgroundSize};

    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};


    position:relative;
    float:left;

    display:block;
    overflow:hidden;

`;



const PrimeScrollBox = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#f9f9f9",
    width:              props.width || "100%",
    height:             props.height || "calc(100% - 50px)",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0"


}))`

    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};


    position:relative;   
    float:left;

    overflow-x:hidden;
    overflow-y:scroll;

`;



const PrimeArea = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    width:              props.width || "100%",
    height:             props.height || "auto",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0"


}))`

    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    min-height: 500px;
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;   
    float:left;

    overflow:hidden;

`;



const PrimeAreaTitle = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#edeef1",
    width:              props.width || "100%",
    height:             props.height || "auto",
    padding:            props.padding || "8px 0 10px 0",
    margin:             props.margin  || "0 0 25px 0"


}))`

    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};


    position:relative;   
    float:left;

    display:block;
    overflow:hidden;

    font-family:"Verdana", sans-serif;      
    font-size: 16px;
    font-weight: normal;
    text-align: center;
    text-decoration:none;
    color:#1A1926;

    border:1px solid lightgray;
`;






const PrimeWrapper = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    width:              props.width || "85%",
    height:             props.height || "auto",
    padding:            props.padding || "14px 0 14px 0",
    margin:             props.margin  || "auto"


}))`

    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};
    position:relative;


`;


const PrimeBar = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    width:              props.width || "100%",
    height:             props.height || "auto",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0",

    position:           props.position || "absolute",
    top:                props.top || "0px",
    left:               props.left || "0px",
    zIndex:             props.zIndex || "2px"




}))`

    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:${props => props.position};
    top:${props => props.top};
    left:${props => props.left};
    z-index:${props => props.zIndex};

`;




const PrimeGroup = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "lightseagreen",
    width:              props.width || "100%",
    height:             props.height || "auto",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0"
  
  
  }))`
  
    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};
  
    font-size: calc(10px + 2vmin);
    color: white;
  
  
    position:relative;
    float:left;
  
  `;
  
  
  const PrimeMember = styled.div.attrs(props => ({
  
    backgroundColor:    props.backgroundColor || "powderblue",
    width:              props.width || "100%",
    height:             props.height || "auto",
    padding:            props.padding || "0 0 0 0",
    margin:             props.margin  || "0 0 0 0"
  
  
  }))`
  
    background-color:${props => props.backgroundColor};
    width:${props => props.width};
    height:${props => props.height};
    padding:${props => props.padding};
    margin:${props => props.margin};
  
    font-size: calc(10px + 2vmin);
    color: white;
  
  
    position:relative;
    float:left;
  
  `;
  
  
  




export {

    PrimeStage,
    PrimeScrollBox,  
    PrimeArea,
    PrimeAreaTitle,
    PrimeWrapper,
    PrimeBar,

    PrimeGroup,
    PrimeMember

}
