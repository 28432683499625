import React, { 

    useState, 
    useEffect,
    //useRef

} from 'react';


//import 'app-style/price-form/style.css';
//import '../../StyleSheets/PriceForm.css';
import 'app-components/Zone/_StyleSheets/PriceForm.css';


export const FFlabelfield = (props) => {


    // -- state -- //

    const [fieldVal, setFieldVal] = useState('');
    const [fieldStyl, setFieldStyl] = useState({});

    useEffect(() => {

        var fontStyle = 'normal';
        var fontColor = "#000";

        let data = props.data;

        for(var keyname in data)
        {
            switch(keyname)
            {
            case'label':
            break;
            case'field':

                setFieldVal(data[keyname].text);

                if(data[keyname].fieldtype === 'select')
                {
                    if(data[keyname].hint === data[keyname].text 
                    || data[keyname].text === ''
                    )
                    {
                        fontStyle = 'italic';
                        fontColor = '#777';
                    }

                }//==

            break;
            default:
            break;
            }//==

        }

        setFieldStyl({
            "bgColor":"transparent",
            "fontStyle":fontStyle,
            "fontColor":fontColor
        });

        var problemArr = props.problemArr;
        for(var i=0; i < problemArr.length; i++)
        {
            if(props.ij === problemArr[i]['key'])
            {
                setFieldStyl({
                    "bgColor":"lightyellow",
                    "fontStyle":fontStyle,
                    "fontColor":fontColor
                });
                break;
            }
    
        }
 
 
     },[props])
 
 
   
     
    //<input name="firstName" onChange={e => setFirstName(e.target.value)} />
     

      
    // -- handle -- //
  
    function handleFocus(target) {

        target.select();
    }

    function handleBlur(e) {

        //console.log(e.target.value);
        //e.target.select();

        var targetVal;
        targetVal = e.target.value;
        if(targetVal === '' 
        || targetVal === undefined
        || isNaN(targetVal)
        )
        {
            targetVal = 0; 
        }
  
        targetVal = parseFloat(targetVal);

        var fixedNumber;
        fixedNumber = 0;
        var newVal = targetVal.toFixed(fixedNumber);

        setFieldVal(newVal);

    }

    function handleChange(e) {


        if(e.target.type.match(/select/gi))
        {
            var newStyl = fieldStyl;

            if(e.target.value !== props.data.field.hint)
            {
                newStyl['fontStyle'] = 'normal';
                newStyl['fontColor'] = '#000';
            }else{
                newStyl['fontStyle'] = 'italic';
                newStyl['fontColor'] = '#777';
            }

            setFieldStyl(newStyl);
        }

    
        var targetVal;
        targetVal = e.target.value;
        if(targetVal === '' 
        || targetVal === undefined
        || isNaN(targetVal)
        )
        {
            targetVal = 0; 
        }
        //setFieldVal(e.target.value);
        targetVal = parseFloat(targetVal);

        var fixedNumber;
        fixedNumber = 0;
        var newVal = targetVal.toFixed(fixedNumber);

        setFieldVal(newVal);


        props.onChange({

            call        :'capture',
            fieldCall   :"labelfield",
            key         :props.ij,
            id          :e.target.id,
            data        :props.data,
            value       :newVal

        });



    }




    return (


        <div className="frm">


            {
            //------------------------------------
            //HIDDEN
            //------------------------------------

            props.data.field.fieldtype === "hidden" ? 


                <div 
                key={props.ij}
                className="input-wrap"
                >

                    <input
                        type="hidden"
                        className="input-tag"
                        id={props.data.id}
                        value={fieldVal}          
                        placeholder={props.data.hint}
                        onChange={handleChange}
        
                    />

                </div>



            : null


            //-------------------------------------
            }




            {
            //------------------------------------
            //TEXT FIELD
            //------------------------------------

            props.data.field.fieldtype === "text" ? 


                <div 
                key={props.ij}
                className="labelfield"
                style={{
                    backgroundColor:'transparent'
                }}
                >

                    <div 
                    className="label"
                    >

                        {props.data.label.text}

                    </div>




                    <div 
                    className="field-wrap"
                    >

                        <input
                            type="text"
                            className="input-tag"
                            style={{
                                backgroundColor:fieldStyl.bgColor,
                                fontStyle:fieldStyl.fontStyle,
                                color:fieldStyl.fontColor
                            }}
            
                            id={props.data.field.id}
                            value={fieldVal}
                            placeholder={props.data.field.hint}
                            onFocus = {event => {
                                // event properties must be copied to use async
                                const target = event.target;
                                setTimeout(() => handleFocus(target), 0);
                            }}
                            onBlur={handleBlur}  
                            onChange={handleChange}


                        />

                   
                    </div>

                </div>



            : null


            //-------------------------------------
            }





            {
            //------------------------------------
            //TEXTAREA
            //------------------------------------

            props.data.field.fieldtype === "textarea" ? 
 
                <div 
                key={props.ij}
                className="labelfield"
                style={{
                    backgroundColor:'transparent'
                }}

                >



                    <div 
                    className="label-ta"
                    >

                        {props.data.label.text}

                    </div>



                    <div 
                    className="field-wrap"
                    >

                        <textarea
                        className="ta-tag"
                        style={{
                            backgroundColor:fieldStyl.bgColor,
                            fontStyle:fieldStyl.fontStyle,
                            color:fieldStyl.fontColor

                        }}

                        id={props.data.field.id}
                        placeholder={props.data.field.hint}
                        value={fieldVal}
                        onFocus = {event => {
                            // event properties must be copied to use async
                            const target = event.target;
                            setTimeout(() => handleFocus(target), 0);
                        }}
                        onBlur={handleBlur}  
                        onChange={handleChange}
                        >
                        </textarea>

                    </div>
                    
                </div>
            
            : null


            //-------------------------------------
            }





            {
            //------------------------------------
            //SELECT
            //------------------------------------

            props.data.field.fieldtype === "select" ? 
 
                <div 
                key={props.ij}
                className="labelfield"
                style={{
                    backgroundColor:'transparent'
                }}

                >



                    <div 
                    className="label"
                    >

                        {props.data.label.text}

                    </div>




                    <div 
                    className="field-wrap"
                    >

                        <select

                        className="sel-tag"
                        style={{
                            backgroundColor:fieldStyl.bgColor,
                            fontStyle:fieldStyl.fontStyle,
                            color:fieldStyl.fontColor
                        }}

                        id={props.data.field.id}
                        placeholder={props.data.field.hint}
                        value={fieldVal}
                        onChange={handleChange}
                        
                
                        /*
                        onFocus = {event => {
                            // event properties must be copied to use async
                            const target = event.target;
                            setTimeout(() => handleFocus(target), 0);
                        }}
                        */
                        //onBlur={handleBlur}
                        
        
                        >

                                {
                                //====================================			
                                //first option
                                //====================================
                                }

                                <option 
                                className="opt"       
                                key={props.data.field.hint} 
                                value={props.data.field.hint}
                                >

                                    {props.data.field.hint}

                                </option>
                            

                
                                {
                                //====================================			
                                //options
                                //====================================


                                props.data.field.array.map((option) => (

                                    <option 
                                    className="opt"                     
                                    key={option} 
                                    value={option}

                                    >

                                        {option}

                                    </option>
                                ))

                                }


                        </select>


                    </div>

                </div>
            
            : null


            //-------------------------------------
            }







        </div>

    )


}
