
/*
import { 

    //VPcompanyInfo,
    //VPrates,
    //VPexpenses,
    //VPincome,
    VPoverhead,

    //VPowners,
    VPowner,

    //VPemployees,
    VPemployee,


} from 'app-components/Prime/VPdata/form.js';

import { LibArray, LibBase } from 'library';

*/


const InitItems = (props) => {


	/**
	 * 
	 * 	
		avoiding the react-redux mutation error 

		https://www.samanthaming.com/tidbits/70-3-ways-to-clone-objects
	
		JSON.stringify/parse only work with Number and String and Object literal without function or Symbol properties.
		deepClone work with all types, function and Symbol are copied by reference.

	 *
	 * 
	 */

	//const zoneObj = Object.assign({}, props.zoneObj);
	//const zoneObj = JSON.parse(JSON.stringify(props.zoneObj));


	//var array = LibBase.ParseIt(props.array);


	var processName = false;

	var formItems = props.form_items;
	var resultItems = {};


	console.log("::::::::::"+JSON.stringify(formItems,null,2));


	const OWNRS = {};
	const EMPLS = {};

	var rawOutput = {};	

	var delItems = {};

	var item;
	var keyname;
	var kn;
	var k;
	var object;
	var name;
	var value;
	var inp;

	//var newName;	
	var newVal;
	var newInp;

	//var call;
	//var number;
	//var randnum;

	
	//var index;
	//var keys = [];
	//var key;
	//var nv = [];


	//var formKeys;


    var isOkay;
    isOkay = false;

	if(Object.keys(formItems).length > 0
	//&& Object.keys(result_items).length > 0
	)
	{
        isOkay = true;
     

    }
    if(isOkay)
    {


		for(keyname in formItems)
		{

			//console.log(keyname);

			//item = zoneObj.calc_dash.form_items[keyname];
			//item = Object.assign({}, zoneObj.calc_dash.form_items[keyname]);
			item = JSON.parse(JSON.stringify(formItems[keyname]));//avoids the react-redux mutation error


			if(keyname.match(/ownr/gi))
			{

				//idl_employees[keyname] = zoneObj.calc_dash.form_items[keyname];
				//order card item based on Prime VPdata

				OWNRS[keyname] = {
					name:item.name,
					value:item.value
				};

			}
			else
			if(keyname.match(/empl/gi))
			{

				//idl_employees[keyname] = zoneObj.calc_dash.form_items[keyname];
				//order card item based on Prime VPdata


				EMPLS[keyname] = {
					name:item.name,
					value:item.value
				};


			}
	


		}// -- for



	}// -- if


	//console.log(JSON.stringify(OWNRS,null,2));
	//alert('check OWNRS')
	






	//------------------------
	//REFER
    //------------------------
    
	var projected_monthly_sales = 50000;


	//------------------------
	//WORK SCHEDULE
	//------------------------

	var work_weeks = 52;
	var work_days = 5;
	var work_hours = 8;



	//------------------------
	//OWNER
	//------------------------

	//var owners_salary_pretax = 75000;
    //var owners_taxrate_percent = 30;
    
	//var owners_hours_indirect = 40;
    //var owners_hours_direct = 0;
    
    //var owners_health_insurance_monthly = 1000  
	//var owners_ira_percent = 2;
	//var owners_vacation_weeks = 2;
	 
          
    
	//------------------------
	//FACILITY INPUTS
	//------------------------

	var total_facility = 0;

		var fac_rent_mortgage = 3500;
		var fac_utilities = 735;
		var fac_phone_internet = 350;
		var fac_misc = 15;


	//------------------------
	//BUSINESS INPUTS
	//------------------------

	var total_business = 0;

		var biz_insurance = 1000;
		var biz_accounting = 100;
		var biz_legal = 200;
		var biz_licenses = 1000;
		var biz_loans = 1985;
		var biz_advertising = 200;
		var biz_website = 100;
		var biz_misc = 15;


	//--------------------------
	//VEHICLES INPUTS
	//--------------------------

	var total_vehicles = 0;

		var veh_vehicles = 1500;
		var veh_gas = 500;
		var veh_insurance = 200;
		var veh_maintenance = 335;
		var veh_misc = 15;

	//------------------------
	//EQUIP/SOFTWARE INPUTS
	//------------------------

	var total_equipsoftware = 0;

		var es_digital_printer = 300;
		var es_laminator = 100;
		var es_plotter = 100;
		var es_computers = 500;
		var es_software_subscriptions = 235;
		var es_misc = 15;









	//########################################################################
	//########################################################################
    //########################################################################
	
	var ownr;

	// -- inputs -- //
	var ownr_name;

	var ownr_salary_pretax;  
	var ownr_taxrate_percent;      
		
	var ownr_hours_weekly;
	var ownr_labor_percent;  
			
	var ownr_labor_direct;   
	var ownr_labor_indirect;   
		
	var ownr_health_insurance_monthly; 
	var ownr_ira_percent;              
	var ownr_vacation_weeks;
	
	
	// -- formulas -- //
	
	var ownr_labor_percent_direct;
	var ownr_labor_percent_indirect;
	
	var ownr_direct_labor_decimal;
			
	var ownr_hours_direct;
	var ownr_hours_indirect;



	
	var ownr_salary_pretax_monthly;
	
	
	var ownr_working_weeks;
	
	var ownr_burden_hours_yearly;
	
	var ownr_direct_hours_yearly;
	var ownr_indirect_hours_yearly;
	
	
	
	var ownr_hours_direct_percent;
	var ownr_hours_direct_percent_whole;
		
	var ownr_hours_indirect_percent;
	var ownr_hours_indirect_percent_whole;
	  

	var ownr_taxrate_decimal;
	var ownr_taxrate_reciprocal;
	
	var ownr_st;
	var ownr_taxes_yearly;
	var ownr_taxes_monthly;
			

	
	var ownr_taxes_monthly_direct;  
	var ownr_taxes_monthly_indirect;
	

	var ownr_health_insurance_yearly;
	



	var ownr_ira_decimal;
	var ownr_ira_yearly;
	var ownr_ira_monthly;

	var ownr_ira_monthly_direct;  
	var ownr_ira_monthly_indirect;
	

	var ownr_sti;
	var ownr_sti_weekly;



	
	
	
	//indirect
	var ownr_sti_weekly_indirect;
	var ownr_sti_yearly_indirect;

	//direct
	var ownr_sti_weekly_direct;
	var ownr_sti_hourly_direct;

	var ownr_sti_yearly_direct;
	var ownr_sti_monthly_direct;
	
			

	var ownr_salary_pretax_monthly_indirect;

	var ownr_sti_monthly_indirect;
	
	var ownr_salary_overhead;
	

    var flagcase;	
	var flag;
	

	var ownr_burden_yearly_total;
	var ownr_burden_monthly_total;
	


    

	var total_ownr_salary_pretax_monthly = 0;
	var total_ownr_salary_pretax_monthly_indirect = 0;

	var total_ownr_taxes_monthly_direct = 0;
	var total_ownr_taxes_monthly_indirect = 0;

	var total_ownr_ira_monthly_direct = 0;
	var total_ownr_ira_monthly_indirect = 0;


	var total_ownr_taxes_monthly = 0;
	var total_ownr_ira_monthly = 0;
	var total_ownr_health_insurance_monthly = 0;


	var total_ownr_salary_pretax_yearly = 0;
	var total_ownr_taxes_yearly = 0;
	var total_ownr_ira_yearly = 0;
	var total_ownr_health_insurance_yearly = 0;

	var total_ownr_burden_yearly = 0;
	var total_ownr_burden_monthly = 0;


	var total_ownrs_direct = 0;
	var total_ownrs_indirect = 0;
	var total_ownrs = 0;



    for(keyname in OWNRS)
    {
        console.log(keyname);

        ownr = OWNRS[keyname];


        
        // -- inputs -- //
		ownr_name 						= ownr.value['ownr_name'].value;
		
        ownr_salary_pretax 				= parseFloat(ownr.value["ownr_salary_pretax"].value);  
        ownr_taxrate_percent 			= parseFloat(ownr.value["ownr_taxrate_percent"].value);      
    
        ownr_hours_weekly 		        = parseFloat(ownr.value["ownr_hours_weekly"].value);
        ownr_labor_percent              = parseFloat(ownr.value["ownr_labor_percent"].value);  
        
        ownr_labor_direct               = ownr.value["ownr_labor_direct"].value;   
        ownr_labor_indirect             = ownr.value["ownr_labor_indirect"].value;  

        ownr_health_insurance_monthly 	= parseFloat(ownr.value["ownr_health_insurance_monthly"].value); 
        ownr_ira_percent 				= parseFloat(ownr.value["ownr_ira_percent"].value);              
        ownr_vacation_weeks 			= parseFloat(ownr.value["ownr_vacation_weeks"].value);  





        // -- formulas -- //

        ownr_labor_percent_direct       = ownr_labor_percent;
        ownr_labor_percent_indirect     = 100 - parseFloat(ownr_labor_percent);

        ownr_direct_labor_decimal       = parseFloat(ownr_labor_percent)/100;
        
        ownr_hours_direct               = parseFloat(ownr_direct_labor_decimal) * parseFloat(ownr_hours_weekly);
	    ownr_hours_indirect             = parseFloat(ownr_hours_weekly) - parseFloat(ownr_hours_direct);

        ownr_salary_pretax_monthly      = parseFloat(ownr_salary_pretax)/12;

        //-- 

        ownr_working_weeks              = parseFloat(work_weeks) - parseFloat(ownr_vacation_weeks);

        ownr_burden_hours_yearly        = parseFloat(work_weeks) * parseFloat(ownr_hours_weekly);

        ownr_direct_hours_yearly        = parseFloat(ownr_working_weeks) * parseFloat(ownr_hours_direct);
        ownr_indirect_hours_yearly      = parseFloat(ownr_burden_hours_yearly) * parseFloat(ownr_direct_hours_yearly);


        //-- 

        ownr_hours_direct_percent           = parseFloat(ownr_hours_direct) / parseFloat(ownr_hours_weekly);
        ownr_hours_direct_percent_whole     = parseFloat(ownr_hours_direct_percent) * 100;
    
        ownr_hours_indirect_percent         = parseFloat(ownr_hours_indirect) / parseFloat(ownr_hours_weekly);
        ownr_hours_indirect_percent_whole   = parseFloat(ownr_hours_indirect_percent) * 100;
    

  
        //---------------------------------
        //owners tax
        //---------------------------------

        ownr_taxrate_decimal            = parseFloat(ownr_taxrate_percent)/100;
        ownr_taxrate_reciprocal         = 1 - parseFloat(ownr_taxrate_decimal);

        ownr_st                         = parseFloat(ownr_salary_pretax) / parseFloat(ownr_taxrate_reciprocal);
        ownr_taxes_yearly               = parseFloat(ownr_st) - parseFloat(ownr_salary_pretax);
        ownr_taxes_monthly              = parseFloat(ownr_taxes_yearly)/12;
        
        ownr_taxes_monthly_direct       = parseFloat(ownr_taxes_monthly) * parseFloat(ownr_hours_direct_percent);  
        ownr_taxes_monthly_indirect     = parseFloat(ownr_taxes_monthly) * parseFloat(ownr_hours_indirect_percent);
 


        //---------------------------------
        // owners health insurance
        //---------------------------------

        ownr_health_insurance_monthly = parseFloat(ownr_health_insurance_monthly);
        ownr_health_insurance_yearly  = parseFloat(ownr_health_insurance_monthly) * 12;

        
        //---------------------------------
        // owners ira
        //---------------------------------

        ownr_ira_decimal            = parseFloat(ownr_ira_percent)/100;
        ownr_ira_yearly             = parseFloat(ownr_salary_pretax) * (ownr_ira_decimal);
        ownr_ira_monthly            = parseFloat(ownr_ira_yearly) / 12;

        ownr_ira_monthly_direct     = parseFloat(ownr_ira_monthly) * parseFloat(ownr_hours_direct_percent);  
        ownr_ira_monthly_indirect   = parseFloat(ownr_ira_monthly) * parseFloat(ownr_hours_indirect_percent);



        //--------------------------------------------
        // owners sti = salary taxes ira
        //--------------------------------------------

        ownr_sti                  = parseFloat(ownr_salary_pretax) + parseFloat(ownr_taxes_yearly) + parseFloat(ownr_ira_yearly);

        ownr_sti_weekly           = parseFloat(ownr_sti) / parseFloat(ownr_working_weeks);

        //indirect
        ownr_sti_weekly_indirect  = parseFloat(ownr_sti_weekly) * parseFloat(ownr_hours_indirect_percent);	
        ownr_sti_yearly_indirect  = parseFloat(ownr_sti_weekly_indirect) * parseFloat(ownr_working_weeks);


        //direct
        ownr_sti_weekly_direct    = parseFloat(ownr_sti_weekly) * parseFloat(ownr_hours_direct_percent);
        ownr_sti_hourly_direct    = parseFloat(ownr_sti_weekly_direct) / parseFloat(ownr_hours_direct);
        
        ownr_sti_yearly_direct    = parseFloat(ownr_sti_weekly_direct) * parseFloat(ownr_working_weeks);
        ownr_sti_monthly_direct   = parseFloat(ownr_sti_yearly_direct) /12;
        
        
        
        //--------------------------------------------
        // ownr_salary_pretax_monthly_indirect
        //--------------------------------------------
        
        ownr_salary_pretax_monthly_indirect = parseFloat(ownr_salary_pretax_monthly) * parseFloat(ownr_hours_indirect_percent);
        
        
        //--------------------------------------------
        // verify sti
        //--------------------------------------------
        
        ownr_sti_monthly_indirect = parseFloat(ownr_taxes_monthly_indirect) + parseFloat(ownr_ira_monthly_indirect) + parseFloat(ownr_salary_pretax_monthly_indirect);
        
        


        //-------------------------
        //EVEN
        //-------------------------
        
        if(ownr_hours_direct_percent_whole === 50)
        {
            flagcase = 'even';

            //flag message: 
        
            console.log(""

                +"Even."

            );

            flag = "";
            flag += "<br/>Even."



            ownr_salary_overhead = parseFloat(ownr_salary_pretax_monthly_indirect);




        }
        //-------------------------
        //LESS
        //-------------------------
        else
        if(ownr_hours_direct_percent_whole < 50)
        {
            flagcase = 'less';

            //flag message: 
        
            console.log(""

                +"\nThe owner spends the majority of their time performing indirect labor."
                +"\nAll of the owners salary will be considered an Overhead expense." 
                +"\nGraphic Design, Production and Installation direct labor employee rates will need to be created."

            );

            flag = "";
            flag += "<br/>The owner spends the majority of their time performing indirect labor."
            flag += "<br/>All of the owners salary will be considered an Overhead expense." 
            flag += "<br/>Graphic Design, Production and Installation direct labor employee rates will need to be created."



            ownr_salary_overhead = parseFloat(ownr_salary_pretax_monthly);




        }
        //-------------------------
        //GREATER
        //-------------------------
        else
        if(ownr_hours_direct_percent_whole > 50)
        {
            flagcase = 'greater';


            //flag message: 
        
            console.log(""

            +"The owner spends the majority of their time performing direct labor."
            +"\n"+ownr_hours_indirect_percent_whole+"%  of the owners salary will be considered an Overhead expense."
            +"\nGraphic Design, Production and Installation rates will use the owners Direct Labor rate."

            );

            flag = "";
            flag += "<br/>The owner spends the majority of their time performing direct labor."
            flag += "<br/>"+ownr_hours_indirect_percent_whole+"% of the owners salary will be considered an Overhead expense.." 
            flag += "<br/>Graphic Design, Production and Installation rates will use the owners Direct Labor rate."


            ownr_salary_overhead = parseFloat(ownr_salary_pretax_monthly_indirect);


        }//==




       	//--------------------------------------------
        // totals
        //--------------------------------------------
        
        ownr_burden_yearly_total = parseFloat(ownr_salary_pretax) + parseFloat(ownr_taxes_yearly) + parseFloat(ownr_ira_yearly) + parseFloat(ownr_health_insurance_yearly);
		ownr_burden_monthly_total = parseFloat(ownr_salary_pretax_monthly ) + parseFloat(ownr_taxes_monthly) + parseFloat(ownr_ira_monthly) + parseFloat(ownr_health_insurance_monthly);




        
        //console.log(JSON.stringify(ownr,null,2));


        rawOutput[keyname] = {

            name:ownr_name,
            value:{

                "ownr_name" 						:ownr_name,

                "ownr_salary_pretax" 				:ownr_salary_pretax,
                "ownr_taxrate_percent" 				:ownr_taxrate_percent,  
            
                "ownr_hours_weekly" 		       	:ownr_hours_weekly, 
                "ownr_labor_percent"             	:ownr_labor_percent, 
                
                "ownr_labor_direct"             	:ownr_labor_direct,  
                "ownr_labor_indirect"          		:ownr_labor_indirect,  
            
                "ownr_health_insurance_monthly" 	:ownr_health_insurance_monthly,	
                "ownr_ira_percent"					:ownr_ira_percent,
				"ownr_vacation_weeks" 				:ownr_vacation_weeks,	
        
        

                "ownr_sti"							: ownr_sti,
                "ownr_sti_yearly_indirect"			: ownr_sti_yearly_indirect,
                "ownr_sti_hourly_direct"			: ownr_sti_hourly_direct,
                "ownr_sti_monthly_direct"			: ownr_sti_monthly_direct,
                "ownr_sti_monthly_indirect"			: ownr_sti_monthly_indirect,
                "ownr_sti_weekly"					: ownr_sti_weekly,
                "ownr_sti_yearly_direct"			: ownr_sti_yearly_direct,
                "ownr_sti_weekly_direct"			: ownr_sti_weekly_direct,
                "ownr_sti_weekly_indirect"			: ownr_sti_weekly_indirect,
                "ownr_st"							: ownr_st,


                "ownr_ira_yearly"					: ownr_ira_yearly,
                "ownr_ira_monthly"					: ownr_ira_monthly,
                "ownr_ira_monthly_direct"			: ownr_ira_monthly_direct,
                "ownr_ira_monthly_indirect"			: ownr_ira_monthly_indirect,
                "ownr_ira_decimal"					: ownr_ira_decimal,


                "ownr_health_insurance_yearly"		: ownr_health_insurance_yearly,
                //"ownr_health_insurance_monthly"		: ownr_health_insurance_monthly,


                
				'ownr_labor_percent_direct' 		:ownr_labor_percent_direct,
				'ownr_labor_percent_indirect'		:ownr_labor_percent_indirect,
			  	'ownr_indirect_hours_yearly' 		:ownr_indirect_hours_yearly,



                "ownr_working_weeks"				: ownr_working_weeks,
                
                "ownr_hours_direct_percent_whole"	: ownr_hours_direct_percent_whole,
                "ownr_hours_direct_percent"			: ownr_hours_direct_percent,
                "ownr_hours_indirect_percent_whole"	: ownr_hours_indirect_percent_whole,
                "ownr_hours_indirect_percent"		: ownr_hours_indirect_percent,


                "ownr_salary_pretax_monthly"		: ownr_salary_pretax_monthly,
                "ownr_salary_overhead"				: ownr_salary_overhead,

                "ownr_taxes_yearly"					: ownr_taxes_yearly,
                "ownr_taxes_monthly"				: ownr_taxes_monthly,
                "ownr_taxes_monthly_direct"			: ownr_taxes_monthly_direct,
                "ownr_taxes_monthly_indirect"		: ownr_taxes_monthly_indirect,
                "ownr_taxrate_reciprocal"			: ownr_taxrate_reciprocal,
                "ownr_taxrate_decimal"				: ownr_taxrate_decimal,


                "ownr_flag_case"					: flagcase,
				"ownr_flag"							: flag,
				





            }


        }



	
		// -- totals -- //
	
        total_ownr_salary_pretax_monthly   			= parseFloat(total_ownr_salary_pretax_monthly) + parseFloat(ownr_salary_pretax_monthly);	
		total_ownr_salary_pretax_monthly_indirect   = parseFloat(total_ownr_salary_pretax_monthly_indirect) + parseFloat(ownr_salary_pretax_monthly_indirect);
	
        total_ownr_taxes_monthly_direct          	= parseFloat(total_ownr_taxes_monthly_direct) + parseFloat(ownr_taxes_monthly_direct);	
		total_ownr_taxes_monthly_indirect           = parseFloat(total_ownr_taxes_monthly_indirect) + parseFloat(ownr_taxes_monthly_indirect);
	
        total_ownr_ira_monthly_direct             	= parseFloat(total_ownr_ira_monthly_direct) + parseFloat(ownr_ira_monthly_direct);	
		total_ownr_ira_monthly_indirect             = parseFloat(total_ownr_ira_monthly_indirect) + parseFloat(ownr_ira_monthly_indirect);
		

		total_ownr_taxes_monthly          			= parseFloat(total_ownr_taxes_monthly) + parseFloat(ownr_taxes_monthly);
        total_ownr_ira_monthly             			= parseFloat(total_ownr_ira_monthly) + parseFloat(ownr_ira_monthly);	
        total_ownr_health_insurance_monthly         = parseFloat(total_ownr_health_insurance_monthly)  + parseFloat(ownr_health_insurance_monthly);
	
		total_ownr_burden_monthly					= parseFloat(total_ownr_burden_monthly) + parseFloat(ownr_burden_monthly_total);
  

		// -- yearly -- //
        total_ownr_salary_pretax_yearly   			= parseFloat(total_ownr_salary_pretax_yearly) + parseFloat(ownr_salary_pretax);	
        total_ownr_taxes_yearly                     = parseFloat(total_ownr_taxes_yearly) + parseFloat(ownr_taxes_yearly);	
        total_ownr_ira_yearly						= parseFloat(total_ownr_ira_yearly) + parseFloat(ownr_ira_yearly);	
        total_ownr_health_insurance_yearly         	= parseFloat(total_ownr_health_insurance_yearly)  + parseFloat(ownr_health_insurance_yearly);
	
		total_ownr_burden_yearly					= parseFloat(total_ownr_burden_yearly) + parseFloat(ownr_burden_yearly_total);
  


    }//for i
    //======

	// TOTAL OWNRS
	total_ownrs_direct 		= parseFloat(total_ownr_salary_pretax_monthly) + parseFloat(total_ownr_taxes_monthly_direct) + parseFloat(total_ownr_ira_monthly_direct);
	total_ownrs_indirect 	= parseFloat(total_ownr_salary_pretax_monthly_indirect) + parseFloat(total_ownr_taxes_monthly_indirect) + parseFloat(total_ownr_ira_monthly_indirect) + parseFloat(total_ownr_health_insurance_monthly);

	total_ownrs 			= parseFloat(total_ownrs_direct) + parseFloat(total_ownrs_indirect);






	//########################################################################
	//########################################################################
	//########################################################################

	//=======================
	//set inputs
	//=======================

	for(keyname in formItems)
	{
		newVal = {};
		object = formItems[keyname];
		name = object['name'];
		value = object['value'];
		for(kn in value)
		{

			newInp = {};
			inp = value[kn];
			for(k in inp)
			{

				newInp[k] = inp[k];
				newInp['required'] = true;
		
				switch(kn)
				{

				case'projected_monthly_sales': 		newInp['default'] = projected_monthly_sales; break;
		
				case'work_weeks': 					newInp['default'] = work_weeks; break;
				case'work_days': 					newInp['default'] = work_days; break;
				case'work_hours': 					newInp['default'] = work_hours; break;


				case'fac_rent_mortgage': 			newInp['default'] = fac_rent_mortgage; break;
				case'fac_utilities': 				newInp['default'] = fac_utilities; break;
				case'fac_phone_internet': 			newInp['default'] = fac_phone_internet; break;
				case'fac_misc': 					newInp['default'] = fac_misc; break;

				case'biz_insurance': 				newInp['default'] = biz_insurance; break;
				case'biz_accounting': 				newInp['default'] = biz_accounting; break;
				case'biz_legal': 					newInp['default'] = biz_legal; break;
				case'biz_licenses': 				newInp['default'] = biz_licenses; break;					
				case'biz_loans': 					newInp['default'] = biz_loans; break;
				case'biz_advertising': 				newInp['default'] = biz_advertising; break;
				case'biz_website': 					newInp['default'] = biz_website; break;
				case'biz_misc': 					newInp['default'] = biz_misc; break;


				case'veh_vehicles': 				newInp['default'] = veh_vehicles; break;
				case'veh_gas': 						newInp['default'] = veh_gas; break;
				case'veh_insurance': 				newInp['default'] = veh_insurance; break;
				case'veh_maintenance': 				newInp['default'] = veh_maintenance; break;
				case'veh_misc': 					newInp['default'] = veh_misc; break;


				case'es_digital_printer': 			newInp['default'] = es_digital_printer; break;
				case'es_laminator': 				newInp['default'] = es_laminator; break;
				case'es_plotter': 					newInp['default'] = es_plotter; break;
				case'es_computers': 				newInp['default'] = es_computers; break;
				case'es_software_subscriptions': 	newInp['default'] = es_software_subscriptions; break;
				case'es_misc': 						newInp['default'] = es_misc; break;

				default:newInp['default'] = "";
				}


			}

			newVal[kn] = newInp;


		}//for in
		//=======
		

		formItems[keyname] = {

			"name"		:name,
			"value"		:newVal
		};

		
	}//for in
	//=======


	



	/*

	var number;
	var i;
	var idl;
	var newIdl;
	var newObj;

	console.log(JSON.stringify(IDLS,null,2));

	if(IDLS 
	&& IDLS != undefined
	)
	{
		for(keyname in IDLS)
		{
			number = i+1;
			idl = IDLS[keyname];
			formItems[keyname] = idl;
		}

	}//has
	//====

	*/

	

	/*

	console.log("FORM ITEMS : "+JSON.stringify(formItems,null,2));
	for(keyname in formItems)
	{
		console.log("rec-calc-dash-form-"+keyname)

	}//==
	//return false;

	*/








	


	//########################################################################
	//########################################################################
	//########################################################################


    // -- settings -- //
	projected_monthly_sales 		= formItems.settings.value["projected_monthly_sales"].value;

	
    // -- schedule -- //
	work_weeks 						= formItems.work_schedule.value["work_weeks"].value;
	work_days 						= formItems.work_schedule.value["work_days"].value;
	work_hours 						= formItems.work_schedule.value["work_hours"].value;




	//------------------------
	//FACILITY
	//------------------------

	fac_rent_mortgage 				= formItems.facility.value["fac_rent_mortgage"].value;
	fac_utilities 					= formItems.facility.value["fac_utilities"].value;
	fac_phone_internet 				= formItems.facility.value["fac_phone_internet"].value;
	fac_misc 						= formItems.facility.value["fac_misc"].value;




	//------------------------
	//BUSINESS
	//------------------------

	biz_insurance 					= formItems.business.value["biz_insurance"].value;
	biz_accounting 					= formItems.business.value["biz_accounting"].value;
	biz_legal 						= formItems.business.value["biz_legal"].value;
	biz_licenses 					= formItems.business.value["biz_licenses"].value;
	biz_loans 						= formItems.business.value["biz_loans"].value;
	biz_advertising 				= formItems.business.value["biz_advertising"].value;
	biz_website 					= formItems.business.value["biz_website"].value;
	biz_misc 						= formItems.business.value["biz_misc"].value;



	//--------------------------
	//VEHICLES
	//--------------------------

	veh_vehicles 					= formItems.vehicles.value["veh_vehicles"].value;
	veh_gas 						= formItems.vehicles.value["veh_gas"].value;
	veh_insurance 					= formItems.vehicles.value["veh_insurance"].value;
	veh_maintenance 				= formItems.vehicles.value["veh_maintenance"].value;
	veh_misc 						= formItems.vehicles.value["veh_misc"].value;




	//---------------------------
	//EQUIPMENT / SOFTWARE
	//---------------------------

	es_digital_printer 				= formItems.equipment_software.value["es_digital_printer"].value;
	es_laminator 					= formItems.equipment_software.value["es_laminator"].value;
	es_plotter 						= formItems.equipment_software.value["es_plotter"].value;
	es_computers 					= formItems.equipment_software.value["es_computers"].value;
	es_software_subscriptions 		= formItems.equipment_software.value["es_software_subscriptions"].value;
	es_misc 						= formItems.equipment_software.value["es_misc"].value;







	//########################################################################
	//########################################################################
	//########################################################################
	//INDIRECT LABOR INPUTS


	/*
	//var idls = calcobj['idls'];

	var def_idls = "yes";

	var idls = idls_obj['formdata'];

	if(zoneObj['idls'] 
	&& zoneObj['idls'] != undefined
	)
	{

		var idls = zoneObj['idls'];
		var def_idls = "No";
	
	}//has
	//====

	*/




	//########################################################################
	//########################################################################
	//########################################################################


	var total_weekly_hours;
	

	projected_monthly_sales = parseFloat(projected_monthly_sales);


	total_weekly_hours = (work_days) * (work_hours)






	//########################################################################
	//########################################################################
	//########################################################################



	//########################################################################

	//var total_facility;
	//var total_business;
	//var total_vehicles;
	//var total_equipsoftware;


	// -- IDLS -- //


	var empl_name;
        
	var empl_hourly_rate;              
	var empl_hours_weekly; 
	
	var empl_labor_percent; 
	 
	var empl_labor_direct;   
	var empl_labor_indirect;  
	
	var empl_vacation_weeks;           
	var empl_health_insurance_monthly; 
	var empl_tax_comp_percent;         
	var empl_ira_percent;              
	
	
	
	// -- formulas -- //
	
	var empl_labor_percent_direct;
	var empl_labor_percent_indirect;
	
	var empl_direct_labor_decimal;
	
	var empl_hours_direct;
	var empl_hours_indirect;
	
	var empl_working_weeks;
	var empl_burden_hours_yearly;
	
	var empl_direct_hours_yearly;
	var empl_indirect_hours_yearly;
	
	
	
	// --
	
	var empl_tax_comp_decimal;
	var empl_ira_decimal;
	
	var empl_tax_hourly;
	var empl_ira_hourly;
	
	var empl_health_insurance_yearly;
	var empl_health_insurance_weekly;
	var empl_health_insurance_hourly;
	
	
	// --
	var empl_direct_hourly_rate;
	var empl_indirect_hourly_rate;
	
	
	
	// --
	var empl_direct_salary_yearly;
	var empl_indirect_salary_yearly;
	var empl_direct_indirect_salary_yearly_total;
	
	var empl_direct_salary_monthly;
	var empl_indirect_salary_monthly;
	var empl_direct_indirect_salary_monthly_total;
	
	
	
	// --
	var empl_direct_tax_yearly;
	var empl_indirect_tax_yearly;
	var empl_direct_indirect_tax_yearly_total;
	
	var empl_direct_tax_monthly;
	var empl_indirect_tax_monthly;
	var empl_direct_indirect_tax_monthly_total;
	
	
	// --
	var empl_direct_ira_yearly;
	var empl_indirect_ira_yearly;
	var empl_direct_indirect_ira_yearly_total;
	
	var empl_direct_ira_monthly;
	var empl_indirect_ira_monthly; // in overhead
	var empl_direct_indirect_ira_monthly_total;
	
	
	// --
	var empl_yearly_salary_w_health_insurance;
	var empl_burden_yearly_total;
	var empl_burden_monthly_total;
	
	
	
        
	var empl;
	

	
	var total_ira;													
	var total_health_insurance;
	var total_tax;

	var total_empl_direct_salary_monthly = 0;
	var total_empl_indirect_salary_monthly = 0;

	var total_empl_direct_tax_monthly = 0;
	var total_empl_indirect_tax_monthly = 0; 

	var total_empl_direct_ira_monthly = 0;
	var total_empl_indirect_ira_monthly = 0;

	var total_empl_health_insurance_monthly = 0;
	var total_empl_direct_indirect_salary_monthly   = 0;
	var total_empl_direct_indirect_tax_monthly = 0;
	var total_empl_direct_indirect_ira_monthly = 0;

	var total_empl_health_insurance_yearly = 0;
	var total_empl_direct_indirect_salary_yearly   	= 0;
	var total_empl_direct_indirect_tax_yearly   	= 0;
	var total_empl_direct_indirect_ira_yearly   	= 0;
	
	var total_empl_burden_yearly = 0;
	var total_empl_burden_monthly = 0;

	var total_empls_direct;
	var total_empls_indirect;
	var total_empls;

	var total;
	
	var overhead_decimal;
	var overhead;





	//FACILITY
	total_facility = parseFloat(fac_rent_mortgage) + parseFloat(fac_utilities) + parseFloat(fac_phone_internet) + parseFloat(fac_misc);
	total_facility = parseFloat(total_facility);														
	

	//BUSINESS
	total_business = parseFloat(biz_insurance) + parseFloat(biz_accounting) + parseFloat(biz_legal) + parseFloat(biz_licenses) + parseFloat(biz_loans) + parseFloat(biz_advertising) + parseFloat(biz_website) + parseFloat(biz_misc);
	total_business = parseFloat(total_business);														

	
	//VEHICLES
	total_vehicles = parseFloat(veh_vehicles) + parseFloat(veh_gas) + parseFloat(veh_insurance) + parseFloat(veh_maintenance) + parseFloat(veh_misc);
	total_vehicles = parseFloat(total_vehicles);

	

	//EQUIPMENT / SOFTWARE
	total_equipsoftware = parseFloat(es_digital_printer) + parseFloat(es_laminator) + parseFloat(es_plotter) + parseFloat(es_computers) + parseFloat(es_software_subscriptions) + parseFloat(es_misc);
	total_equipsoftware = parseFloat(total_equipsoftware);



	


	console.log(JSON.stringify(EMPLS,null,2));
	//return false;

	for(keyname in EMPLS)
	{
		console.log(keyname);

		empl = EMPLS[keyname];


        // -- inputs -- //

        empl_name 						= empl.value['empl_name'].value;
        
		empl_hourly_rate 				= parseFloat(empl.value["empl_hourly_rate"].value);              
		empl_hours_weekly 				= parseFloat(empl.value["empl_hours_weekly"].value); 
		
		empl_labor_percent              = parseFloat(empl.value["empl_labor_percent"].value); 
		 
        empl_labor_direct               = empl.value["empl_labor_direct"].value;   
        empl_labor_indirect             = empl.value["empl_labor_indirect"].value;  

		empl_vacation_weeks 			= parseFloat(empl.value["empl_vacation_weeks"].value);           
		empl_health_insurance_monthly 	= parseFloat(empl.value["empl_health_insurance_monthly"].value); 
		empl_tax_comp_percent 			= parseFloat(empl.value["empl_tax_comp_percent"].value);         
		empl_ira_percent 				= parseFloat(empl.value["empl_ira_percent"].value);              



		// -- formulas -- //

		empl_labor_percent_direct       = empl_labor_percent;
        empl_labor_percent_indirect     = 100 - parseFloat(empl_labor_percent);

        empl_direct_labor_decimal       = parseFloat(empl_labor_percent)/100;
		
        empl_hours_direct               = parseFloat(empl_direct_labor_decimal) * parseFloat(empl_hours_weekly);
	    empl_hours_indirect             = parseFloat(empl_hours_weekly) - parseFloat(empl_hours_direct);

		empl_working_weeks 				= parseFloat(work_weeks) - parseFloat(empl_vacation_weeks);
		empl_burden_hours_yearly 		= parseFloat(work_weeks) * parseFloat(empl_hours_weekly);

        empl_direct_hours_yearly        = parseFloat(empl_working_weeks) * parseFloat(empl_hours_direct);
        empl_indirect_hours_yearly      = parseFloat(empl_burden_hours_yearly) - parseFloat(empl_direct_hours_yearly);



		// --

		empl_tax_comp_decimal = parseFloat(empl_tax_comp_percent)/100;
		empl_ira_decimal = parseFloat(empl_ira_percent)/100;

		empl_tax_hourly = parseFloat(empl_hourly_rate) * parseFloat(empl_tax_comp_decimal);
		empl_ira_hourly = parseFloat(empl_hourly_rate) * parseFloat(empl_ira_decimal);

		empl_health_insurance_yearly = parseFloat(empl_health_insurance_monthly) * 12;
		empl_health_insurance_weekly = parseFloat(empl_health_insurance_yearly) / parseFloat(empl_working_weeks);
		empl_health_insurance_hourly = parseFloat(empl_health_insurance_weekly) / parseFloat(empl_hours_weekly);
		


		// --
		empl_direct_hourly_rate = parseFloat(empl_hourly_rate) + parseFloat(empl_tax_hourly) + parseFloat(empl_ira_hourly);
		empl_indirect_hourly_rate = parseFloat(empl_hourly_rate) + parseFloat(empl_tax_hourly) + parseFloat(empl_ira_hourly) + parseFloat(empl_health_insurance_hourly);



		// --
		empl_direct_salary_yearly = parseFloat(empl_hourly_rate) * parseFloat(empl_direct_hours_yearly);
		empl_indirect_salary_yearly = parseFloat(empl_hourly_rate) * parseFloat(empl_indirect_hours_yearly);
		empl_direct_indirect_salary_yearly_total = parseFloat(empl_direct_salary_yearly) + parseFloat(empl_indirect_salary_yearly);

		empl_direct_salary_monthly = parseFloat(empl_direct_salary_yearly)/12;
		empl_indirect_salary_monthly = parseFloat(empl_indirect_salary_yearly)/12; // in overhead
		empl_direct_indirect_salary_monthly_total = parseFloat(empl_direct_salary_monthly) + parseFloat(empl_indirect_salary_monthly);



		// --
		empl_direct_tax_yearly = parseFloat(empl_direct_hours_yearly) * parseFloat(empl_tax_hourly);
		empl_indirect_tax_yearly = parseFloat(empl_indirect_hours_yearly) * parseFloat(empl_tax_hourly);
		empl_direct_indirect_tax_yearly_total = parseFloat(empl_direct_tax_yearly) + parseFloat(empl_indirect_tax_yearly);

		empl_direct_tax_monthly = parseFloat(empl_direct_tax_yearly)/12;
		empl_indirect_tax_monthly = parseFloat(empl_indirect_tax_yearly)/12; // in overhead
		empl_direct_indirect_tax_monthly_total = parseFloat(empl_direct_tax_monthly) + parseFloat(empl_indirect_tax_monthly);


		// --
		empl_direct_ira_yearly = parseFloat(empl_direct_hours_yearly) * parseFloat(empl_ira_hourly);
		empl_indirect_ira_yearly = parseFloat(empl_indirect_hours_yearly) * parseFloat(empl_ira_hourly);
		empl_direct_indirect_ira_yearly_total = parseFloat(empl_direct_ira_yearly) + parseFloat(empl_indirect_ira_yearly);

		empl_direct_ira_monthly = parseFloat(empl_direct_ira_yearly)/12;
		empl_indirect_ira_monthly = parseFloat(empl_indirect_ira_yearly)/12; // in overhead
		empl_direct_indirect_ira_monthly_total = parseFloat(empl_direct_ira_monthly) + parseFloat(empl_indirect_ira_monthly);

		
		// --
		empl_yearly_salary_w_health_insurance = parseFloat(empl_health_insurance_yearly) + parseFloat(empl_direct_indirect_salary_yearly_total);
		empl_burden_yearly_total = parseFloat(empl_health_insurance_yearly) + parseFloat(empl_direct_indirect_salary_yearly_total) + parseFloat(empl_direct_indirect_tax_yearly_total) + parseFloat(empl_direct_indirect_ira_yearly_total);
		empl_burden_monthly_total = parseFloat(empl_health_insurance_monthly) + parseFloat(empl_direct_indirect_salary_monthly_total) + parseFloat(empl_direct_indirect_tax_monthly_total) + parseFloat(empl_direct_indirect_ira_monthly_total);




		//console.log(JSON.stringify(idl,null,2));



		rawOutput[keyname] = {

			name:empl_name,
			value:{


				empl_name								: empl_name,
        
				empl_hourly_rate						: empl_hourly_rate,              
				empl_hours_weekly						: empl_hours_weekly, 
				
				empl_labor_percent						: empl_labor_percent, 
				 
				empl_labor_direct						: empl_labor_direct,   
				empl_labor_indirect						: empl_labor_indirect,  
				
				empl_vacation_weeks						: empl_vacation_weeks,           
				empl_health_insurance_monthly 			: empl_health_insurance_monthly, 
				empl_tax_comp_percent					: empl_tax_comp_percent,         
				empl_ira_percent						: empl_ira_percent,              
				
				
				
				// -- formulas -- //
				
				empl_labor_percent_direct				: empl_labor_percent_direct,
				empl_labor_percent_indirect				: empl_labor_percent_indirect,
				
				empl_direct_labor_decimal				: empl_direct_labor_decimal,
				
				empl_hours_direct						: empl_hours_direct,
				empl_hours_indirect						: empl_hours_indirect,
				
				empl_working_weeks						: empl_working_weeks,
				empl_burden_hours_yearly				: empl_burden_hours_yearly,
				
				empl_direct_hours_yearly				: empl_direct_hours_yearly,
				empl_indirect_hours_yearly				: empl_indirect_hours_yearly,
				
				
				
				// --
				
				empl_tax_comp_decimal					: empl_tax_comp_decimal,
				empl_ira_decimal						: empl_ira_decimal,
				
				empl_tax_hourly							: empl_tax_hourly,
				empl_ira_hourly							: empl_ira_hourly,
				
				empl_health_insurance_yearly			: empl_health_insurance_yearly,
				empl_health_insurance_weekly			: empl_health_insurance_weekly,
				empl_health_insurance_hourly			: empl_health_insurance_hourly,
				
				
				// --
				empl_direct_hourly_rate					: empl_direct_hourly_rate,
				empl_indirect_hourly_rate				: empl_indirect_hourly_rate,
				
				
				
				// --
				empl_direct_salary_yearly				: empl_direct_salary_yearly,
				empl_indirect_salary_yearly				: empl_indirect_salary_yearly,
				empl_direct_indirect_salary_yearly_total: empl_direct_indirect_salary_yearly_total,
				
				empl_direct_salary_monthly				: empl_direct_salary_monthly,
				empl_indirect_salary_monthly			: empl_indirect_salary_monthly,
				empl_direct_indirect_salary_monthly_total: empl_direct_indirect_salary_monthly_total,
				
				
				
				// --
				empl_direct_tax_yearly					: empl_direct_tax_yearly,
				empl_indirect_tax_yearly				: empl_indirect_tax_yearly,
				empl_direct_indirect_tax_yearly_total	: empl_direct_indirect_tax_yearly_total,
				
				empl_direct_tax_monthly					: empl_direct_tax_monthly,
				empl_indirect_tax_monthly				: empl_indirect_tax_monthly,
				empl_direct_indirect_tax_monthly_total	: empl_direct_indirect_tax_monthly_total,
				
				
				// --
				empl_direct_ira_yearly					: empl_direct_ira_yearly,
				empl_indirect_ira_yearly				: empl_indirect_ira_yearly,
				empl_direct_indirect_ira_yearly_total	: empl_direct_indirect_ira_yearly_total,
				
				empl_direct_ira_monthly					: empl_direct_ira_monthly,
				empl_indirect_ira_monthly				: empl_indirect_ira_monthly, // in overhead
				empl_direct_indirect_ira_monthly_total	: empl_direct_indirect_ira_monthly_total,
				
				
				// --
				empl_yearly_salary_w_health_insurance	: empl_yearly_salary_w_health_insurance,
				empl_burden_yearly_total				: empl_burden_yearly_total,
				empl_burden_monthly_total				: empl_burden_monthly_total,
				
				
				


			}



		}




		// -- totals -- //

        total_empl_direct_salary_monthly   			= parseFloat(total_empl_direct_salary_monthly) + parseFloat(empl_direct_salary_monthly);
        total_empl_direct_tax_monthly         		= parseFloat(total_empl_direct_tax_monthly) + parseFloat(empl_direct_tax_monthly);
        total_empl_direct_ira_monthly           	= parseFloat(total_empl_direct_ira_monthly) + parseFloat(empl_direct_ira_monthly);
 

        total_empl_indirect_salary_monthly   		= parseFloat(total_empl_indirect_salary_monthly) + parseFloat(empl_indirect_salary_monthly);
        total_empl_indirect_tax_monthly         	= parseFloat(total_empl_indirect_tax_monthly) + parseFloat(empl_indirect_tax_monthly);
        total_empl_indirect_ira_monthly           	= parseFloat(total_empl_indirect_ira_monthly) + parseFloat(empl_indirect_ira_monthly);
        total_empl_health_insurance_monthly         = parseFloat(total_empl_health_insurance_monthly)  + parseFloat(empl_health_insurance_monthly);
	
		total_empl_direct_indirect_salary_monthly   = parseFloat(total_empl_direct_indirect_salary_monthly) + parseFloat(empl_direct_indirect_salary_monthly_total);
		total_empl_direct_indirect_tax_monthly   	= parseFloat(total_empl_direct_indirect_tax_monthly) + parseFloat(empl_direct_indirect_tax_monthly_total);
		total_empl_direct_indirect_ira_monthly   	= parseFloat(total_empl_direct_indirect_ira_monthly) + parseFloat(empl_direct_indirect_ira_monthly_total);
	
        total_empl_burden_monthly 					= parseFloat(total_empl_burden_monthly) + parseFloat(empl_burden_monthly_total);


        total_empl_health_insurance_yearly          = parseFloat(total_empl_health_insurance_yearly)  + parseFloat(empl_health_insurance_yearly);
		total_empl_direct_indirect_salary_yearly   	= parseFloat(total_empl_direct_indirect_salary_yearly) + parseFloat(empl_direct_indirect_salary_yearly_total);
		total_empl_direct_indirect_tax_yearly   	= parseFloat(total_empl_direct_indirect_tax_yearly) + parseFloat(empl_direct_indirect_tax_yearly_total);
		total_empl_direct_indirect_ira_yearly   	= parseFloat(total_empl_direct_indirect_ira_yearly) + parseFloat(empl_direct_indirect_ira_yearly_total);

        total_empl_burden_yearly 					= parseFloat(total_empl_burden_yearly) + parseFloat(empl_burden_yearly_total);

	
	}//for i
	//======


	// TOTAL EMPLS
	total_empls_direct 		= parseFloat(total_empl_direct_salary_monthly) + parseFloat(total_empl_direct_tax_monthly) + parseFloat(total_empl_direct_ira_monthly);
	total_empls_indirect 	= parseFloat(total_empl_indirect_salary_monthly) + parseFloat(total_empl_indirect_tax_monthly) + parseFloat(total_empl_indirect_ira_monthly) + parseFloat(total_empl_health_insurance_monthly);

	total_empls 			= parseFloat(total_empls_direct) + parseFloat(total_empls_indirect);


	//IRA
	total_ira = parseFloat(total_ownr_ira_monthly_indirect) + parseFloat(total_empl_indirect_ira_monthly);		
	

	//HEALTH INSURANCE													
	total_health_insurance = parseFloat(total_ownr_health_insurance_monthly) + parseFloat(total_empl_health_insurance_monthly);													
	
		
	//TAX
	total_tax = parseFloat(total_ownr_taxes_monthly_indirect) + parseFloat(total_empl_indirect_tax_monthly);
			

	//TOTAL
	total = parseFloat(total_ownrs_indirect) + parseFloat(total_empls_indirect) + parseFloat(total_facility) + parseFloat(total_business) + parseFloat(total_vehicles) + parseFloat(total_equipsoftware);
	total = parseFloat(total);



	rawOutput["totals"] = {

		total											: total,
		total_weekly_hours								: total_weekly_hours,
		
		total_health_insurance							: total_health_insurance,
		total_ira										: total_ira,
		total_tax										: total_tax,
		
		total_business									: total_business,
		total_facility									: total_facility,
		total_vehicles									: total_vehicles,
		total_equipsoftware								: total_equipsoftware,



		//------------
		//ownr
		//------------

		//monthly
		total_ownr_salary_pretax_monthly		  		: total_ownr_salary_pretax_monthly,
		total_ownr_salary_pretax_monthly_indirect  		: total_ownr_salary_pretax_monthly_indirect,
		
		total_ownr_taxes_monthly_direct          		: total_ownr_taxes_monthly_direct,
		total_ownr_taxes_monthly_indirect          		: total_ownr_taxes_monthly_indirect,
		
		total_ownr_ira_monthly_direct            		: total_ownr_ira_monthly_direct,
		total_ownr_ira_monthly_indirect            		: total_ownr_ira_monthly_indirect,


		total_ownr_taxes_monthly          				: total_ownr_taxes_monthly,
        total_ownr_ira_monthly             				: total_ownr_ira_monthly,
		total_ownr_health_insurance_monthly        		: total_ownr_health_insurance_monthly,

		total_ownr_burden_monthly           			:total_ownr_burden_monthly,


		total_ownr_salary_pretax_yearly   				: total_ownr_salary_pretax_yearly,	
		total_ownr_taxes_yearly                     	: total_ownr_taxes_yearly,	
		total_ownr_ira_yearly							: total_ownr_ira_yearly,
		total_ownr_health_insurance_yearly         		: total_ownr_health_insurance_yearly,
		
		total_ownr_burden_yearly           				:total_ownr_burden_yearly,
		


		//totals
		total_ownrs_direct								: total_ownrs_direct,
		total_ownrs_indirect							: total_ownrs_indirect,
		total_ownrs										: total_ownrs,



		//------------
		//empl
		//-----------

		//monthly
		total_empl_direct_salary_monthly   				: total_empl_direct_salary_monthly,
        total_empl_indirect_salary_monthly   			: total_empl_indirect_salary_monthly,		

		total_empl_direct_tax_monthly         			: total_empl_direct_tax_monthly,
        total_empl_indirect_tax_monthly         		: total_empl_indirect_tax_monthly,

        total_empl_direct_ira_monthly           		: total_empl_direct_ira_monthly,
		total_empl_indirect_ira_monthly           		: total_empl_indirect_ira_monthly,
		
		total_empl_health_insurance_monthly         	: total_empl_health_insurance_monthly,


		total_empl_direct_indirect_salary_monthly   	:total_empl_direct_indirect_salary_monthly,
		total_empl_direct_indirect_tax_monthly   		:total_empl_direct_indirect_tax_monthly,
		total_empl_direct_indirect_ira_monthly   		:total_empl_direct_indirect_ira_monthly,	


		//yearly
		total_empl_health_insurance_yearly         		:total_empl_health_insurance_yearly,
		total_empl_direct_indirect_salary_yearly   		:total_empl_direct_indirect_salary_yearly,
		total_empl_direct_indirect_tax_yearly   		:total_empl_direct_indirect_tax_yearly,
		total_empl_direct_indirect_ira_yearly   		:total_empl_direct_indirect_ira_yearly,
		total_empl_burden_yearly           				:total_empl_burden_yearly,


	


		// -- totals -- //

		total_empls_direct								: total_empls_direct,
		total_empls_indirect							: total_empls_indirect,
		total_empls										: total_empls




	}

	
	//OVERHEAD
	overhead_decimal = parseFloat(total)/parseFloat(projected_monthly_sales);
	overhead = parseFloat(overhead_decimal)*100;


	rawOutput['overhead'] = {
		"overhead"			: overhead,
		"overhead_decimal"	: overhead_decimal
	};





	
	/*


	"rawOutput": {

		"empl-1": {
		"name": "Jane Doe",
		"value": {
			"empl_"health_insurance_weekly": "115.38",
			"empl_"burden_hours_yearly": "1560.00",
			"empl_"vacation_weeks": "2.00",
			"empl_"tax_comp_percent": "20.00",
			"empl_"yearly_salary_w_tax_ira": "38064.00",
			"empl_"ira_percent": "2.00",
			"empl_"burden_tax_hourly": "4.00",
			"empl_"hourly_rate": "20.00",
			"empl_"burden_ira_yearly": "624.00",
			"empl_"burden_ira_monthly": "52.00",
			"empl_"burden_tax_yearly": "6240.00",
			"empl_"health_insurance_hourly": "3.85",
			"empl_"burden_rate_w_tax_ira_hi_hourly": "28.25",
			"empl_"hourly_rate_w_tax_ira": "24.40",
			"empl_"burden_ira_hourly": "0.40",
			"empl_"burden_rate_w_tax_ira_hi_yearly": "44064.00",
			"empl_"ira_decimal": "0.02",
			"empl_"name": "Jane Doe",
			"empl_"burden_rate_w_tax_ira_hi_monthly": "3672.00",
			"empl_"burden_weekly_hours": "30.00",
			"empl_"health_insurance_yearly": "6000.00",
			"empl_"monthly_salary_w_tax_ira": "3172.00",
			"empl_"tax_comp_decimal": "0.20",
			"empl_"working_weeks": "50.00",
			"empl_"health_insurance_monthly": "500.00",
			"empl_"weekly_salary_w_tax_ira": "732.00",
			"empl_"burden_tax_monthly": "520.00",
			"empl_"rate_monthly": "2600.00",
			"empl_"rate_yearly": "31200.00"
		}
		},


		"owners": {
		"owners_sti": 72428.57142857143,
		"owners_sti_yearly_indirect": 18107.14285714286,
		"owners_sti_hourly_direct": 36.214285714285715,
		"owners_sti_monthly_direct": 4526.785714285715,
		"owners_sti_monthly_indirect": 1508.9285714285716,
		"owners_sti_weekly": 1448.5714285714287,
		"owners_sti_yearly_direct": 54321.42857142858,
		"owners_sti_weekly_direct": 1086.4285714285716,
		"owners_sti_weekly_indirect": 362.14285714285717,
		"owners_st": 71428.57142857143,
		"owners_ira_yearly": 1000,
		"owners_ira_monthly": 83.33333333333333,
		"owners_ira_monthly_direct": 62.5,
		"owners_ira_monthly_indirect": 20.833333333333332,
		"owners_ira_decimal": 0.02,
		"owners_working_weeks": 50,
		"owners_hours_direct_percent_whole": 75,
		"owners_hours_direct_percent": 0.75,
		"owners_hours_indirect_percent_whole": 25,
		"owners_hours_indirect_percent": 0.25,
		"owners_salary_pretax_monthly": 4166.666666666667,
		"owners_salary_overhead": 1041.6666666666667,
		"owners_taxes_yearly": 21428.571428571435,
		"owners_taxes_monthly": 1785.7142857142862,
		"owners_taxes_monthly_direct": 1339.2857142857147,
		"owners_taxes_monthly_indirect": 446.42857142857156,
		"owners_taxrate_reciprocal": 0.7,
		"owners_taxrate_decimal": 0.3,
		"owners_flag_case": "greater",
		"owners_flag_message": "<br/>The owner spends the majority of their time performing direct labor.<br/>25% of the owners salary will be considered an Overhead expense..<br/>Graphic Design, Production and Installation rates will use the owners Direct Labor rate."
		},
		"idl-1": {
		"name": "Jane Doe",
		"value": {
			"empl_name": "Jane Doe",
			"empl_working_weeks": 50,
			"empl_vacation_weeks": 2,
			"empl_burden_hours_yearly": 1560,
			"empl_burden_weekly_hours": 30,
			"empl_rate_yearly": 31200,
			"empl_rate_monthly": 2600,
			"empl_hourly_rate": 20,
			"empl_yearly_salary_w_tax_ira": 38064,
			"empl_monthly_salary_w_tax_ira": 3172,
			"empl_weekly_salary_w_tax_ira": 732,
			"empl_hourly_rate_w_tax_ira": 24.4,
			"empl_burden_rate_w_tax_ira_hi_yearly": 44064,
			"empl_burden_rate_w_tax_ira_hi_monthly": 3672,
			"empl_burden_rate_w_tax_ira_hi_hourly": 28.246153846153845,
			"empl_burden_tax_yearly": 6240,
			"empl_burden_tax_monthly": 520,
			"empl_burden_tax_hourly": 4,
			"empl_tax_comp_percent": 20,
			"empl_tax_comp_decimal": 0.2,
			"empl_ira_percent": 2,
			"empl_ira_decimal": 0.02,
			"empl_burden_ira_yearly": 624,
			"empl_burden_ira_monthly": 52,
			"empl_burden_ira_hourly": 0.4,
			"empl_health_insurance_yearly": 6000,
			"empl_health_insurance_monthly": 500,
			"empl_health_insurance_weekly": 115.38461538461539,
			"empl_health_insurance_hourly": 3.8461538461538463
		}
		},
		"totals": {
		"total": 11748.928571428572,
		"total_weekly_hours": 40,
		"total_health_insurance": 1000,
		"total_ira": 72.83333333333333,
		"total_tax": 966.4285714285716,
		"total_business": 1738,
		"total_facility": 2610,
		"total_vehicles": 1010,
		"total_equipsoftware": 710,
		"total_empl_rate_yearly": 31200,
		"total_empl_rate_monthly": 2600,
		"total_empl_health_insurance_monthly": 500,
		"total_empl_burden_ira_monthly": 52,
		"total_empl_burden_tax_monthly": 520
		},
		"overhead": {
		"overhead": 39.16309523809524,
		"overhead_decimal": 0.3916309523809524
		}



	*/



	

	var n;
	for(keyname in rawOutput)
	{
		item = Object.assign({}, rawOutput[keyname]);
		//console.log("KEYNAME: "+keyname);
		//console.log(JSON.stringify(item,null,2));

		if(keyname.match(/ownr/gi)
		|| keyname.match(/empl/gi)
		)
		{
			value = item.value;
			newVal = {};
			for(kn in value)
			{
				newVal[kn] = value[kn];

				switch(kn)
				{
				case'ownr_name':
				case'ownr_labor_direct':
				case'ownr_labor_indirect':
				case'empl_name':
				case'empl_labor_direct':
				case'empl_labor_indirect':
				break;
				default:

					n = Number(value[kn]);
					if(isNaN(n) || n === false || n === true
					)
					{
						newVal[kn] = value[kn];
					}else{
						//alert(kn+ ' a number');
						newVal[kn] = n.toFixed(2);
					}//==

				}//==switch
			

			}
			resultItems[keyname] = {
				name:item.name,
				value:newVal
			};


		}
		else
		{

			newVal = {};
			for(kn in item)
			{
				n = Number(item[kn]);
				if(isNaN(n) || n === false || n === true
				)
				{
					newVal[kn] = item[kn];
				}else{
					//alert(kn+ ' a number');
					newVal[kn] = n.toFixed(2);
				}//==

			}
			resultItems[keyname] = newVal;
	


		}

	}// -- for

	


	

	return {

		call			:props.call,
		currentKey		:props.currentKey,
		processName		:processName,
		//rawOutput		:rawOutput,
		OWNRS			:OWNRS,
		EMPLS			:EMPLS,
		formItems		:formItems,
		resultItems     :resultItems,
		delItems		:delItems,

	};

	

}


export default InitItems;

