const VPformTemplateEmpl = [


    //==========================================
    //titleline
    //==========================================
    {

        "section"   :"titleline",
        "data"      :{

            "text"  :"New Employee"// or name

        }


    },

    //==========================================
    //labelfields
    //==========================================
    {

        "section"   :"labelfields",
        "data"      :[


            //--------------------------------
            //
            //--------------------------------

            {

                label: {

                    "text"               	    :"Name"
                },

                field: {


                    "fieldtype"               	:"standard",
                    "required"					:true,

                    "class"                   	:'',
                    "id"                      	:'empl_name',
                    "hint"                    	:"",

                    "text"                     	:"",



                    "keyboardType"            	:"",
                    "autocorrect"             	:"false",
                    "autocapitalizationType"  	:"none",


                    //"secure"                  :true,
                    //"returnKeyType"           :"done"


                },

            },





            //--------------------------------
            //
            //--------------------------------

            {

                label: {

                    "text"               	    :"Hourly Rate"
                },

                field: {


                    "fieldtype"               	:"price",
                    "required"					:true,

                    "class"                   	:'',
                    "id"                      	:'empl_hourly_rate',
                    "hint"                    	:"",

                    "text"                     	:18.00,



                    "keyboardType"            	:"",
                    "autocorrect"             	:"false",
                    "autocapitalizationType"  	:"none",


                    //"secure"                  :true,
                    //"returnKeyType"           :"done"


                }

            }

        ]

        
    },

    //==========================================
    //spacer
    //==========================================
    {
        "section"   :"spacer",
        "data"      :{

            "name"		:'spacer-one',
            "color"   	:'transparent',
            "height"  	:10

        }

    },











    //#####################################################
    //#####################################################

    //==========================================
    //bar
    //==========================================
    {

        "section"   :"headline",
        "data"      :{

            "text"  :'Time'

        }


    },

    //==========================================
    //labelfields
    //==========================================
    {

        "section"   :"labelfields",
        "data"      :[


            //--------------------------------
            //
            //--------------------------------

            {

                label: {

                    "text"               	    :"Hours Per Week"
                },

                field: {


                    "fieldtype"               	:"standard",
                    "required"					:true,

                    "class"                   	:'',
                    "id"                      	:'empl_hours_weekly',
                    "hint"                    	:"",

                    "text"                     	:40,



                    "keyboardType"            	:"",
                    "autocorrect"             	:"false",
                    "autocapitalizationType"  	:"none",


                    //"secure"                  :true,
                    //"returnKeyType"           :"done"


                },

            }


        ]

        
    },

    //==========================================
    //spacer
    //==========================================
    {
        "section"   :"spacer",
        "data"      :{

            "name"		:'spacer-dualslider',
            "color"   	:'transparent',
            "height"  	:30

        }

    },

    //==========================================
    //dualslider
    //==========================================
    {

        "section"   :"dualslider",
        "data"      :{
            
            required :true,
            id       :'empl_labor_percent',
            value    :50,
            info     :{

                title           :'Direct Labor',
                titleB          :'Indirect Labor',
                bandColor       :'#00ff00',
                bandOpacity     :"0.4",
                useStickyX      :true,
                stickyIncrement :10,
                matchAB         :false

            }


        }


    },
    //==========================================
    //spacer
    //==========================================
    {
        "section"   :"spacer",
        "data"      :{

            "name"		:'spacer-dualslider',
            "color"   	:'transparent',
            "height"  	:30

        }

    },







    //#####################################################
    //#####################################################

    /*
    //==========================================
    //bar
    //==========================================
    {

        "section"   :"bar",
        "data"      :{

            "text"  :"Employee Type"

        }

    },
    */
    
    //==========================================
    //headline
    //==========================================
    {
        "section"   :"headline",
        "data"      :{

            "text"  :'Direct Labor'

        }
    },
    //==========================================
    //checkboxes TM:
    //==========================================
    {
        "section"   :"checkboxes-edit",
        "data"      :{
            
            "call"      :'chk',
            "required"	:true,
            "name"      :'empl_labor_direct',
            "array"     :["Design","Production","Installation"],
            "choice"    :[]

        }

    },


    //==========================================
    //headline
    //==========================================
    {
        "section"   :"headline",
        "data"      :{

            "text"  :'Indirect Labor'

        }

    },
    //==========================================
    //checkboxes TM:
    //==========================================
    {

        "section"   :"checkboxes-edit",
        "data"      :{
            
            "call"      :'chk',
            "required"	:true,
            "name"      :'empl_labor_indirect',
            "array"     :["Sales","Business Management","Office"],
            "choice"    :[]

        }

    },
    //==========================================
    //spacer
    //==========================================
    {
        "section"   :"spacer",
        "data"      :{

            "name"		:'spacer-checkboxes',
            "color"   	:'transparent',
            "height"  	:10

        }

    },










    //#####################################################
    //#####################################################

    //==========================================
    //bar
    //==========================================
    {

        "section"   :"headline",
        "data"      :{

            "text"  :'Tax'

        }


    },

    //==========================================
    //labelfields
    //==========================================
    {

        "section"   :"labelfields",
        "data"      :[


            //--------------------------------
            //
            //--------------------------------

            {

                label: {

                    "text"               	    :"Tax Comp %"
                },

                field: {


                    "fieldtype"               	:"percent",
                    "required"					:true,

                    "class"                   	:'',
                    "id"                      	:'empl_tax_comp_percent',
                    "hint"                    	:"",

                    "text"                     	:20.0,



                    "keyboardType"            	:"",
                    "autocorrect"             	:"false",
                    "autocapitalizationType"  	:"none",


                    //"secure"                  :true,
                    //"returnKeyType"           :"done"


                },

            }


        ]

        
    },

    //==========================================
    //spacer
    //==========================================
    {
        "section"   :"spacer",
        "data"      :{

            "name"		:'spacer-tax',
            "color"   	:'transparent',
            "height"  	:25

        }

    },









    //#####################################################
    //#####################################################

    //==========================================
    //benefits
    //==========================================
    {

        "section"   :"headline",
        "data"      :{

            "text"  :'Benefits'

        }


    },

    //==========================================
    //labelfields
    //==========================================
    {

        "section"   :"labelfields",
        "data"      :[


            //--------------------------------
            // Health Insurance
            //--------------------------------
            {

                label: {

                    "text"               	    :"Health Ins. Monthly"
                },

                field: {


                    "fieldtype"               	:"price",
                    "required"					:true,

                    "class"                   	:'',
                    "id"                      	:'empl_health_insurance_monthly',
                    "hint"                    	:"",

                    "text"                     	:200.00,



                    "keyboardType"            	:"",
                    "autocorrect"             	:"false",
                    "autocapitalizationType"  	:"none",


                    //"secure"                  :true,
                    //"returnKeyType"           :"done"


                },

            },

            //--------------------------------
            // IRA
            //--------------------------------

            {

                label: {

                    "text"               	    :"IRA %"
                },

                field: {


                    "fieldtype"               	:"percent",
                    "required"					:true,

                    "class"                   	:'',
                    "id"                      	:'empl_ira_percent',
                    "hint"                    	:"",

                    "text"                     	:2.0,



                    "keyboardType"            	:"",
                    "autocorrect"             	:"false",
                    "autocapitalizationType"  	:"none",


                    //"secure"                  :true,
                    //"returnKeyType"           :"done"


                },

            },


            //--------------------------------
            // Vacation Sick
            //--------------------------------

            {

                label: {

                    "text"               	    :"Vacation/Sick Weeks"
                },

                field: {


                    "fieldtype"               	:"standard",
                    "required"					:true,

                    "class"                   	:'',
                    "id"                      	:'empl_vacation_weeks',
                    "hint"                    	:"",

                    "text"                     	:2,



                    "keyboardType"            	:"",
                    "autocorrect"             	:"false",
                    "autocapitalizationType"  	:"none",


                    //"secure"                  :true,
                    //"returnKeyType"           :"done"


                },

            }




        ]

        
    },
    //==========================================
    //spacer
    //==========================================
    {
        "section"   :"spacer",
        "data"      :{

            "name"		:'spacer-dualslider',
            "color"   	:'transparent',
            "height"  	:40

        }

    },




    
];


export {

    VPformTemplateEmpl

}

