import styled from "styled-components";


export const Box = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "white",

    width:              props.width || "100%",
    height:             props.height || "auto",
    margin:             props.margin || "35px 0 100px 0",
    padding:            props.padding || "0 0 0 0",

    border:             props.border || "1px solid lightgray"

}))`


    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    height:${props => props.height};
    min-height:250px;

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;



    @media (max-width: 1050px) {

        margin:20px 0 100px 0;

    }



`;
