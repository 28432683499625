
import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';



/*

import { 
    //Lorem, 
    TestImages,
    //TestVideos,
    //SpecialImages,
    //NavigationData 
} from 'app-components/Prime/Data';


// import {

//     //TextCard1,
//     //TextCard2,
//     //TextCard3,

//     //ImageTextCard1,
//     //ImageTextCard2,
//     //ImageTextCard3


// } from 'app-components/Zone/Builds/Samples/Card';




*/




// import { Settings } from 'app-components/Prime/Data.js';


import { 

    //VPcompanyInfo, 
    VPexpenses,
    VPincome,   
    //VPoverhead, 


    VPownrsYearly,
    VPownrsMonthly,
    //VPownrsDirect,
    //VPownrsIndirect,
    VPownrs,
    //VPownr

    VPemplsYearly, 
    VPemplsMonthly, 
    //VPemplsDirect,
    //VPemplsIndirect,
    VPempls,
    //VPempl


} from 'app-components/Prime/VPdata/form.js';




import { 

    VPRoverhead,  
    VPRtotals,
    VPRhourlyRates,
    VPRdirectHours,

    //VPRownersST,
    //VPRownersHealthIns,
    //VPRownersIRA,
    //VPRownersWeekHours,
    //VPRownersSalary,   
    //VPRownersTaxes,
    //VPRownersFlag,

    VPRownrFilter,
    VPRemplFilter



} from 'app-components/Prime/VPdata/results.js';




import { LibArray, LibBase } from "library";




// import ApiResources from 'utils/components/Pages/ApiResources';


const SceneInfo = () => {


    // //-------------------------------------------

    // var apiDomain;
    // var hostname;
    // //var hostname = window.location.hostname;//returns the domain name of the web host
    // var protocol = window.location.protocol;//returns the web protocol used (http: or https:)

    // if(window.location.hostname.match(/192.168/gi))
    // {
    //     hostname = ApiResources.network['3001'].vpc;
    //     apiDomain = protocol + "//" + hostname + "/";
    // }
    // else
    // if(window.location.hostname.match(/localhost/gi))
    // {
    //     hostname = ApiResources.local['3001'].vpc;
    //     apiDomain = protocol + "//" + hostname + "/";
    // }else{
    //     hostname = ApiResources.aws['x'].vpc;
    //     apiDomain = "https://" + hostname + "/";
    // }//=

    // //-------------------------------------------

    // //console.log("API DOMAIN: "+apiDomain);





    // -- redux -- //
    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));



    //var calc_overhead = {};




    //var actual_monthly_expenses;
    //var projected_monthly_sales;
    //var overhead_percent;
    //var idl_employees = {};

    //actual_monthly_expenses = VPmonthlyExpenses.value;
    //projected_monthly_sales = VPmonthlySales.value;
    //projected_monthly_sales = VPoverhead.settings.value.projected_monthly_sales;
    //overhead_percent = 75.5 + "%";




    //var overhead_percent;
    var _OWNRS = {};   
    var _EMPLS = {};

    //overhead_percent = 0.0 + "%";

    //var cards_overhead = LibBase.CloneIt(VPoverhead);
    var cards_owners = LibBase.CloneIt(VPownrs);
    var cards_owners_yearly = LibBase.CloneIt(VPownrsYearly);
    var cards_owners_monthly = LibBase.CloneIt(VPownrsMonthly);

    var cards_employees = LibBase.CloneIt(VPempls);
    var cards_employees_yearly = LibBase.CloneIt(VPemplsYearly);
    var cards_employees_monthly = LibBase.CloneIt(VPemplsMonthly);




    //var index;
    var keyname;
    var kn;
    var item;
    var itemKeys;
    var value;
    var newValue;

    var a,b,c,d;

    var totals;
    var total;
    var grand_total;


    var chartSections;
    var object;
    var percent;

    var pgraph;
 



    var piePerc;
    var pieSlice;
    var pieChart = {

        items: [ 

            {
                name:"Owners",
                value:5
            },
            {
                name:"Employees",
                value:5
            },
            {
                name:"Facility",
                value:20
            },    
            {
                name:"Business",
                value:14
            },
            {
                name:"Vehicles",
                value:12
            },
            {
                name:"Equip. Software",
                value:30
            },
            {
                name:"Indirect Labor",
                value:14
            }

        ],

        colors: [
            "#fde23e",
            "#f16e23", 
            "#57d9ff",
            "#937e88",
            "#20b2aa",
            "#ccff00"
        ],
        hole:{
            color:"#181b21",
            outlineColor:"#181b21",
            textColor:"white"
        }


    };







 


    var isOkay;
    isOkay = false;
    if(zoneObj.calc_dash 
    && zoneObj.calc_dash !== undefined
    )
    {
        if(Object.keys(zoneObj.calc_dash.form_items).length > 0
        && Object.keys(zoneObj.calc_dash.result_items).length > 0
        )
        {
            isOkay = true;
        }

    }
    if(isOkay)
    {

        /*

        //console.log("FORM ITEMS: "+JSON.stringify(zoneObj.calc_dash.form_items));

        for(keyname in zoneObj.calc_dash.form_items)
        {
            if(keyname.match(/idl/gi))
            {

                //idl_employees[keyname] = zoneObj.calc_dash.form_items[keyname];

                //order card item based on Prime VPdata
                item = zoneObj.calc_dash.form_items[keyname];
          
                value = VPidlEmployee.value;
                newValue = {};
                for(kn in value)
                {
                    newValue[kn] = item.value[kn];
                }
                idl_employees[keyname] = {
                    name:item.name,
                    value:newValue
                };
                

            }
            else
            {
                //console.log("KEYNAME: "+keyname);

                item = zoneObj.calc_dash.form_items[keyname];
                //console.log(JSON.stringify(item,null,2));

                value = VPoverhead[keyname].value;
                newValue = {};
                for(kn in value)
                {
                    newValue[kn] = item.value[kn];
                }
                VPoverhead[keyname] = {
                    name:item.name,
                    value:newValue
                };
 

            }

        }// -- for

        


        VPexpenses.value.monthly_expenses.value = zoneObj.calc_dash.result_items['totals'].total;
        VPincome.value.monthly_sales.value = zoneObj.calc_dash.form_items['settings'].value.projected_monthly_sales.value;


        //overhead_percent = zoneObj.calc_dash.result_items['overhead'].overhead;
        //overhead_percent = parseFloat(overhead_percent) 
        //overhead_percent = overhead_percent.toFixed(1) + "%";



        //console.log("IDL EMPLOYEES: "+JSON.stringify(idl_employees,null,2));

    
        */
        

        //console.log("RESULT ITEMS...... ");

        for(keyname in zoneObj.calc_dash.result_items)
        {
            //console.log("KEYNAME: "+keyname);

            //item = zoneObj.calc_dash.result_items[keyname];
            //console.log(JSON.stringify(item,null,2));

            grand_total = zoneObj.calc_dash.result_items['totals'].total;
            grand_total = parseFloat(grand_total);
    



            // -- owners -- //

            if(keyname.match(/ownr/gi))
            {

                //idl_employees[keyname] = zoneObj.calc_dash.form_items[keyname];

                //order card item based on Prime VPdata
                //item = zoneObj.calc_dash.form_items[keyname];
          

                item = zoneObj.calc_dash.result_items[keyname];
                //console.log(JSON.stringify(item,null,2));

                //itemKeys = Object.keys(item);
                //console.log("ITEM KEYS: "+JSON.stringify(itemKeys,null,2));



                //value = VPRownrFilter.value;
                value = LibBase.CloneIt(VPRownrFilter.value);
                newValue = {};
                for(kn in value)
                {
                    newValue[kn] = {

                        print       :value[kn].print,
                        name        :value[kn].name,
                        value       :item.value[kn],
                        required    :value[kn].required,
                        default     :""
                    }
                  
                }
                _OWNRS[keyname] = {

                    name    :keyname,
                    value   :newValue

                };
                //console.log("OWNRS: "+JSON.stringify(_OWNRS,null,2));




            }




            // -- employess -- //

            else
            if(keyname.match(/empl/gi))
            {

      
                //order card item based on Prime VPdata
                //item = zoneObj.calc_dash.form_items[keyname];
          

                item = zoneObj.calc_dash.result_items[keyname];
                //console.log(JSON.stringify(item,null,2));

                //itemKeys = Object.keys(item);
                //console.log("ITEM KEYS: "+JSON.stringify(itemKeys,null,2));



                //value = VPRemplFilter.value;
                value = LibBase.CloneIt(VPRemplFilter.value);
                newValue = {};
                for(kn in value)
                {
                    newValue[kn] = {

                        print       :value[kn].print,
                        name        :value[kn].name,
                        value       :item.value[kn],
                        required    :value[kn].required,
                        default     :""
                    }
                  
                }
                _EMPLS[keyname] = {

                    name    :keyname,
                    value   :newValue

                };
                //console.log("EMPLS: "+JSON.stringify(_EMPLS,null,2));




            }


            // -- overhead -- //

            else
            {
                //console.log("KEYNAME: "+keyname);


                item = zoneObj.calc_dash.result_items[keyname];
                //console.log(JSON.stringify(item,null,2));

                itemKeys = Object.keys(item);
                //console.log("ITEM KEYS: "+JSON.stringify(itemKeys,null,2));

    

                switch(keyname)
                {
                case'overhead':


                    //console.log(VPRoverhead.value);
      
            
                    //value = VPRoverhead.value;
                    value = LibBase.CloneIt(VPRoverhead.value);
                    newValue = {};
                    for(kn in value)
                    {
                        if(LibArray.InArray(kn,itemKeys))
                        {

                            newValue[kn] = {

                                print       :value[kn].print,
                                name        :value[kn].name,
                                value       :item[kn],
                                required    :value[kn].required,
                                default     :""
                            }
                            
                        }
                    }
                    VPRoverhead.value = newValue;
                    //console.log("OVERHEAD: "+JSON.stringify(VPRoverhead,null,2));


                break;

                case'hourly_direct_rates':


                    //console.log(VPRoverhead.value);
      
            
                    //value = VPRoverhead.value;
                    value = LibBase.CloneIt(VPRhourlyRates.value);
                    newValue = {};
                    for(kn in value)
                    {
                        if(LibArray.InArray(kn,itemKeys))
                        {

                            newValue[kn] = {

                                print       :value[kn].print,
                                name        :value[kn].name,
                                value       :item[kn],
                                required    :value[kn].required,
                                default     :""
                            }
                            
                        }
                    }
                    VPRhourlyRates.value = newValue;
                    //console.log("OVERHEAD: "+JSON.stringify(VPRoverhead,null,2));


                break;


                case'hours':

                    //console.log(VPRdirectHours.value);
                    //console.log("hours item: "+JSON.stringify(item,null,2));


                    //direct hours

                    item = item.direct;
                    itemKeys = Object.keys(item);
                    //console.log("ITEM KEYS: "+JSON.stringify(itemKeys,null,2));
    
                    value = LibBase.CloneIt(VPRdirectHours.value);
                    newValue = {};
                    for(kn in value)
                    {
                        if(LibArray.InArray(kn,itemKeys))
                        {

                            newValue[kn] = {

                                print       :value[kn].print,
                                name        :value[kn].name,
                                value       :item[kn],
                                required    :value[kn].required,
                                default     :""
                            }
                            
                        }
                    }
                    VPRdirectHours.value = newValue;
                    //console.log("OVERHEAD: "+JSON.stringify(VPRoverhead,null,2));






                break;



                /*
                case'owners':

                    //console.log("ITEM KEYS: "+JSON.stringify(itemKeys,null,2));


                    var flag;
                    var flagRegx

                    value = VPRownersFlag.value;
                    newValue = {};
                    for(kn in value)
                    {
                        if(LibArray.InArray(kn,itemKeys))
                        {
                            flagRegx = new RegExp("<br/>","gi");
                            flag = item[kn].replace(flagRegx,"");

                            newValue[kn] = {

                                print       :value[kn].print,
                                name        :value[kn].name,
                                value       :flag,
                                required    :value[kn].required,
                                default     :""
                            }
                            
                        }
                    }
                    VPRownersFlag.value = newValue;
                    console.log("OWNERS FLAG: "+JSON.stringify(VPRownersFlag,null,2));



                    value = VPRownersST.value;
                    newValue = {};
                    for(kn in value)
                    {
                        if(LibArray.InArray(kn,itemKeys))
                        {
                            newValue[kn] = {

                                print       :value[kn].print,
                                name        :value[kn].name,
                                value       :item[kn],
                                required    :value[kn].required,
                                default     :""
                            }
                            
                        }
                    }
                    VPRownersST.value = newValue;
                    //console.log("OWNERS ST: "+JSON.stringify(VPRownersST,null,2));



              
                    value = VPRownersIRA.value;
                    newValue = {};
                    for(kn in value)
                    {
                        if(LibArray.InArray(kn,itemKeys))
                        {
                            newValue[kn] = {

                                print       :value[kn].print,
                                name        :value[kn].name,
                                value       :item[kn],
                                required    :value[kn].required,
                                default     :""
                            }
                            
                        }
                    }
                    VPRownersIRA.value = newValue;
                    //console.log("OWNERS IRA: "+JSON.stringify(VPRownersIRA,null,2));



                    value = VPRownersHealthIns.value;
                    newValue = {};
                    for(kn in value)
                    {
                        if(LibArray.InArray(kn,itemKeys))
                        {
                            newValue[kn] = {

                                print       :value[kn].print,
                                name        :value[kn].name,
                                value       :item[kn],
                                required    :value[kn].required,
                                default     :""
                            }
                            
                        }
                    }
                    VPRownersHealthIns.value = newValue;
                    //console.log("OWNERS IRA: "+JSON.stringify(VPRownersIRA,null,2));




                    value = VPRownersWeekHours.value;
                    newValue = {};
                    for(kn in value)
                    {
                        if(LibArray.InArray(kn,itemKeys))
                        {
                            newValue[kn] = {

                                print       :value[kn].print,
                                name        :value[kn].name,
                                value       :item[kn],
                                required    :value[kn].required,
                                default     :""
                            }
                            
                        }
                    }
                    VPRownersWeekHours.value = newValue;
                    //console.log("OWNERS WEEK HOURS: "+JSON.stringify(VPRownersWeekHours,null,2));
   


                    value = VPRownersSalary.value;
                    newValue = {};
                    for(kn in value)
                    {
                        if(LibArray.InArray(kn,itemKeys))
                        {
                            newValue[kn] = {

                                print       :value[kn].print,
                                name        :value[kn].name,
                                value       :item[kn],
                                required    :value[kn].required,
                                default     :""
                            }
                            
                        }
                    }
                    VPRownersSalary.value = newValue;
                    //console.log("OWNERS SALARY: "+JSON.stringify(VPRownersSalary,null,2));




                    value = VPRownersTaxes.value;
                    newValue = {};
                    for(kn in value)
                    {
                        if(LibArray.InArray(kn,itemKeys))
                        {
                            newValue[kn] = {

                                print       :value[kn].print,
                                name        :value[kn].name,
                                value       :item[kn],
                                required    :value[kn].required,
                                default     :""
                            }
                            
                        }
                    }
                    VPRownersTaxes.value = newValue;
                    //console.log("OWNERS TAXES: "+JSON.stringify(VPRownersTaxes,null,2));






                break;
                case'totals':

                    value = VPRtotals.value;
                    newValue = {};
                    for(kn in value)
                    {
                        if(LibArray.InArray(kn,itemKeys))
                        {
                            newValue[kn] = {

                                print       :value[kn].print,
                                name        :value[kn].name,
                                value       :item[kn],
                                required    :value[kn].required,
                                default     :""
                            }
                            
                        }
                    }
                    VPRtotals.value = newValue;
                    //console.log("TOTALS: "+JSON.stringify(VPRtotals,null,2));



                break;
                */



                default:
                }//===



            }


        
        }// -- for
        




        //------------------------------------------
        // -- totals
        //------------------------------------------

        /*
        "totals": {

            "total"                                     : "14022.38",
            "total_weekly_hours"                        : "40.00",
        
            "total_ira"                                 : "61.07",
            "total_tax"                                 : "801.14",
            "total_health_insurance"                    : "700.00",

            "total_business"                            : "1743.50",
            "total_facility"                            : "2610.00",
            "total_equipsoftware"                       : "710.00",
            "total_vehicles"                            : "1010.00",

            "total_ownr_health_insurance_monthly"       : "500.00",
            "total_ownr_taxes_monthly_indirect"         : "357.14",
            "total_ownr_ira_monthly_indirect"           : "16.67",
            "total_ownr_salary_pretax_monthly_indirect" : "4166.67",
        

            "total_empl_indirect_ira_monthly"           : "44.40",
            "total_empl_health_insurance_monthly"       : "200.00",
            "total_empl_indirect_salary_monthly"        : "2220.00",
            "total_empl_indirect_tax_monthly"           : "444.00",

        }
        */



        VPexpenses.value.monthly_expenses.value = grand_total;
        VPincome.value.projected_monthly_sales.value = zoneObj.calc_dash.form_items['settings'].value.projected_monthly_sales.value;


        totals = zoneObj.calc_dash.result_items.totals;

        //value = VPRoverhead.value;
        value = LibBase.CloneIt(VPRtotals.value);
        newValue = {};
        for(kn in value)
        {
            if(LibArray.InArray(kn,Object.keys(totals)))
            {
                newValue[kn] = {

                    print       :value[kn].print,
                    name        :value[kn].name,
                    value       :totals[kn],
                    required    :value[kn].required,
                    default     :""
                }
                
            }

        }
        VPRtotals.value = newValue;
        //console.log("VPRtotals: "+JSON.stringify(VPRtotals,null,2));
    
    








        //------------------------------------------
        // -- owners - totals - cards 
        //------------------------------------------

        var total_ownr_salary_pretax_yearly = zoneObj.calc_dash.result_items['totals'].total_ownr_salary_pretax_yearly;
        var total_ownr_taxes_yearly = zoneObj.calc_dash.result_items['totals'].total_ownr_taxes_yearly;
        var total_ownr_ira_yearly = zoneObj.calc_dash.result_items['totals'].total_ownr_ira_yearly;
        var total_ownr_health_insurance_yearly = zoneObj.calc_dash.result_items['totals'].total_ownr_health_insurance_yearly;
        var total_ownr_burden_yearly = zoneObj.calc_dash.result_items['totals'].total_ownr_burden_yearly;

        if(isNaN(total_ownr_salary_pretax_yearly)) total_ownr_salary_pretax_yearly = 0;
        if(isNaN(total_ownr_taxes_yearly)) total_ownr_taxes_yearly = 0;
        if(isNaN(total_ownr_ira_yearly)) total_ownr_ira_yearly = 0;
        if(isNaN(total_ownr_health_insurance_yearly)) total_ownr_health_insurance_yearly = 0;
        if(isNaN(total_ownr_burden_yearly)) total_ownr_burden_yearly = 0;

        cards_owners_yearly.value.total_ownr_salary_pretax_yearly.value = total_ownr_salary_pretax_yearly;
        cards_owners_yearly.value.total_ownr_taxes_yearly.value = total_ownr_taxes_yearly;      
        cards_owners_yearly.value.total_ownr_ira_yearly.value = total_ownr_ira_yearly;
        cards_owners_yearly.value.total_ownr_health_insurance_yearly.value = total_ownr_health_insurance_yearly;
        cards_owners_yearly.value.total_ownr_burden_yearly.value = total_ownr_burden_yearly;

        
        
        var total_ownr_salary_pretax_monthly = zoneObj.calc_dash.result_items['totals'].total_ownr_salary_pretax_monthly;
        var total_ownr_taxes_monthly = zoneObj.calc_dash.result_items['totals'].total_ownr_taxes_monthly;
        var total_ownr_ira_monthly = zoneObj.calc_dash.result_items['totals'].total_ownr_ira_monthly;
        var total_ownr_health_insurance_monthly = zoneObj.calc_dash.result_items['totals'].total_ownr_health_insurance_monthly;
        var total_ownr_burden_monthly = zoneObj.calc_dash.result_items['totals'].total_ownr_burden_monthly;

        if(isNaN(total_ownr_salary_pretax_monthly)) total_ownr_salary_pretax_monthly = 0;
        if(isNaN(total_ownr_taxes_monthly)) total_ownr_taxes_monthly = 0;
        if(isNaN(total_ownr_ira_monthly)) total_ownr_ira_monthly = 0;
        if(isNaN(total_ownr_health_insurance_monthly)) total_ownr_health_insurance_monthly = 0;
        if(isNaN(total_ownr_burden_monthly)) total_ownr_burden_monthly = 0;

        cards_owners_monthly.value.total_ownr_salary_pretax_monthly.value = total_ownr_salary_pretax_monthly;
        cards_owners_monthly.value.total_ownr_taxes_monthly.value = total_ownr_taxes_monthly;      
        cards_owners_monthly.value.total_ownr_ira_monthly.value = total_ownr_ira_monthly;
        cards_owners_monthly.value.total_ownr_health_insurance_monthly.value = total_ownr_health_insurance_monthly;
        cards_owners_monthly.value.total_ownr_burden_monthly.value = total_ownr_burden_monthly;







        //------------------------------------------
        // -- owners - totals - cards 
        //------------------------------------------

        var total_ownr_salary_pretax_yearly_indirect
        var total_ownr_salary_pretax_monthly_indirect;
        //var total_ownr_health_insurance_monthly;
        var total_ownr_ira_monthly_indirect;
        var total_ownr_taxes_monthly_indirect;  

        total_ownr_salary_pretax_monthly_indirect = zoneObj.calc_dash.result_items['totals'].total_ownr_salary_pretax_monthly_indirect;
        total_ownr_health_insurance_monthly = zoneObj.calc_dash.result_items['totals'].total_ownr_health_insurance_monthly;
        total_ownr_ira_monthly_indirect = zoneObj.calc_dash.result_items['totals'].total_ownr_ira_monthly_indirect;
        total_ownr_taxes_monthly_indirect = zoneObj.calc_dash.result_items['totals'].total_ownr_taxes_monthly_indirect;  

        total_ownr_salary_pretax_yearly_indirect = parseFloat(total_ownr_salary_pretax_monthly_indirect) * 12;


        if(isNaN(total_ownr_salary_pretax_yearly_indirect)) total_ownr_salary_pretax_yearly_indirect = 0;
        if(isNaN(total_ownr_salary_pretax_monthly_indirect)) total_ownr_salary_pretax_monthly_indirect = 0;
        if(isNaN(total_ownr_health_insurance_monthly)) total_ownr_health_insurance_monthly = 0;
        if(isNaN(total_ownr_ira_monthly_indirect)) total_ownr_ira_monthly_indirect = 0;
        if(isNaN(total_ownr_taxes_monthly_indirect)) total_ownr_taxes_monthly_indirect = 0;


        cards_owners.value.ownr_salary_pretax_yearly_indirect.value = total_ownr_salary_pretax_yearly_indirect;
        cards_owners.value.ownr_salary_pretax_monthly_indirect.value = total_ownr_salary_pretax_monthly_indirect;
        cards_owners.value.ownr_health_insurance_monthly.value = total_ownr_health_insurance_monthly;
        cards_owners.value.ownr_ira_monthly_indirect.value = total_ownr_ira_monthly_indirect;
        cards_owners.value.ownr_taxes_monthly_indirect.value = total_ownr_taxes_monthly_indirect;



        // -- card total - indirect labor -- //

        total = 0;
        if(total_ownr_salary_pretax_monthly_indirect !== undefined
        && total_ownr_health_insurance_monthly !== undefined 
        && total_ownr_ira_monthly_indirect !== undefined
        && total_ownr_taxes_monthly_indirect !== undefined
        )
        {
            a = total_ownr_salary_pretax_monthly_indirect;
            b = total_ownr_health_insurance_monthly;
            c = total_ownr_ira_monthly_indirect;
            d = total_ownr_taxes_monthly_indirect;

            total = parseFloat(a) + parseFloat(b) + parseFloat(c) + parseFloat(d);

        }

        piePerc = ( parseFloat(total)/parseFloat(grand_total) ) * 100;
        if(isNaN(piePerc)) piePerc = 0;
        piePerc = piePerc.toFixed(1);
        piePerc = parseFloat(piePerc);

        cards_owners.value['total_percent'] = {
    
            name: "Total Monthly (Overhead %)",
            default: 0,
            print: "percent",
            value: piePerc,
            required: true

        };
        cards_owners.value['total'] = {

            name: "Total Monthly (Overhead $)",
            default: 0,
            print: "price",
            value: total,
            required: true

        };

        // -- set pieChart data -- //

        pieSlice = pieChart.items[pieChart.items.length-1];
        if(pieSlice.value !== piePerc)
        {
            pieSlice.name = "Owners Summary";      
            pieSlice.value = piePerc;

        }
        









        //------------------------------------------
        // -- employees - totals - cards 
        //------------------------------------------


        // -- yearly -- //
        var total_empl_direct_indirect_salary_yearly = zoneObj.calc_dash.result_items['totals'].total_empl_direct_indirect_salary_yearly;
        var total_empl_direct_indirect_tax_yearly = zoneObj.calc_dash.result_items['totals'].total_empl_direct_indirect_tax_yearly;
        var total_empl_direct_indirect_ira_yearly = zoneObj.calc_dash.result_items['totals'].total_empl_direct_indirect_ira_yearly;
        var total_empl_health_insurance_yearly = zoneObj.calc_dash.result_items['totals'].total_empl_health_insurance_yearly;
        var total_empl_burden_yearly = zoneObj.calc_dash.result_items['totals'].total_empl_burden_yearly;

        if(isNaN(total_empl_direct_indirect_salary_yearly)) total_empl_direct_indirect_salary_yearly = 0;
        if(isNaN(total_empl_direct_indirect_tax_yearly)) total_empl_direct_indirect_tax_yearly = 0;
        if(isNaN(total_empl_direct_indirect_ira_yearly)) total_empl_direct_indirect_ira_yearly = 0;
        if(isNaN(total_empl_health_insurance_yearly)) total_empl_health_insurance_yearly = 0;
        if(isNaN(total_empl_burden_yearly)) total_empl_burden_yearly = 0;

        cards_employees_yearly.value.total_empl_direct_indirect_salary_yearly.value = total_empl_direct_indirect_salary_yearly;
        cards_employees_yearly.value.total_empl_direct_indirect_tax_yearly.value = total_empl_direct_indirect_tax_yearly;      
        cards_employees_yearly.value.total_empl_direct_indirect_ira_yearly.value = total_empl_direct_indirect_ira_yearly;
        cards_employees_yearly.value.total_empl_health_insurance_yearly.value = total_empl_health_insurance_yearly;
        cards_employees_yearly.value.total_empl_burden_yearly.value = total_empl_burden_yearly;


        
        // -- monthly -- //
        var total_empl_direct_indirect_salary_monthly = zoneObj.calc_dash.result_items['totals'].total_empl_direct_indirect_salary_monthly;
        var total_empl_direct_indirect_tax_monthly = zoneObj.calc_dash.result_items['totals'].total_empl_direct_indirect_tax_monthly;
        var total_empl_direct_indirect_ira_monthly = zoneObj.calc_dash.result_items['totals'].total_empl_direct_indirect_ira_monthly;
        var total_empl_health_insurance_monthly = zoneObj.calc_dash.result_items['totals'].total_empl_health_insurance_monthly;
        var total_empl_burden_monthly = zoneObj.calc_dash.result_items['totals'].total_empl_burden_monthly;

        if(isNaN(total_empl_direct_indirect_salary_monthly)) total_empl_direct_indirect_salary_monthly = 0;
        if(isNaN(total_empl_direct_indirect_tax_monthly)) total_empl_direct_indirect_tax_monthly = 0;
        if(isNaN(total_empl_direct_indirect_ira_monthly)) total_empl_direct_indirect_ira_monthly = 0;
        if(isNaN(total_empl_health_insurance_monthly)) total_empl_health_insurance_monthly = 0;
        if(isNaN(total_empl_burden_monthly)) total_empl_burden_monthly = 0;

        cards_employees_monthly.value.total_empl_direct_indirect_salary_monthly.value = total_empl_direct_indirect_salary_monthly;
        cards_employees_monthly.value.total_empl_direct_indirect_tax_monthly.value = total_empl_direct_indirect_tax_monthly;      
        cards_employees_monthly.value.total_empl_direct_indirect_ira_monthly.value = total_empl_direct_indirect_ira_monthly;
        cards_employees_monthly.value.total_empl_health_insurance_monthly.value = total_empl_health_insurance_monthly;
        cards_employees_monthly.value.total_empl_burden_monthly.value = total_empl_burden_monthly;





        //------------------------------------------
        // -- employees - totals - cards 
        //------------------------------------------

        var total_empl_indirect_salary_yearly;
        var total_empl_indirect_salary_monthly;
        var total_empl_indirect_tax_monthly;
        var total_empl_indirect_ira_monthly;
        //var total_empl_health_insurance_monthly;

        total_empl_indirect_salary_monthly = zoneObj.calc_dash.result_items['totals'].total_empl_indirect_salary_monthly;
        total_empl_indirect_tax_monthly = zoneObj.calc_dash.result_items['totals'].total_empl_indirect_tax_monthly;
        total_empl_indirect_ira_monthly = zoneObj.calc_dash.result_items['totals'].total_empl_indirect_ira_monthly;
        total_empl_health_insurance_monthly = zoneObj.calc_dash.result_items['totals'].total_empl_health_insurance_monthly;
   
        total_empl_indirect_salary_yearly = parseFloat(total_empl_indirect_salary_monthly) * 12;

        if(isNaN(total_empl_indirect_salary_yearly)) total_empl_indirect_salary_yearly = 0;
        if(isNaN(total_empl_indirect_salary_monthly)) total_empl_indirect_salary_monthly = 0;
        if(isNaN(total_empl_indirect_tax_monthly)) total_empl_indirect_tax_monthly = 0;
        if(isNaN(total_empl_indirect_ira_monthly)) total_empl_indirect_ira_monthly = 0;
        if(isNaN(total_empl_health_insurance_monthly)) total_empl_health_insurance_monthly = 0;
     
        cards_employees.value.empl_indirect_salary_yearly.value = total_empl_indirect_salary_yearly;
        cards_employees.value.empl_indirect_salary_monthly.value = total_empl_indirect_salary_monthly;
        cards_employees.value.empl_indirect_tax_monthly.value = total_empl_indirect_tax_monthly;      
        cards_employees.value.empl_indirect_ira_monthly.value = total_empl_indirect_ira_monthly;
        cards_employees.value.empl_health_insurance_monthly.value = total_empl_health_insurance_monthly;




        // -- card total - indirect labor -- //

        total = 0;
        if(total_empl_indirect_salary_monthly !== undefined
        && total_empl_indirect_ira_monthly !== undefined
        && total_empl_indirect_tax_monthly !== undefined        
        && total_empl_health_insurance_monthly !== undefined 
        )
        {
            a = total_empl_indirect_salary_monthly;
            b = total_empl_indirect_ira_monthly;
            c = total_empl_indirect_tax_monthly;        
            d = total_empl_health_insurance_monthly;

            total = parseFloat(a) + parseFloat(b) + parseFloat(c) + parseFloat(d);

        }

        piePerc = ( parseFloat(total)/parseFloat(grand_total) ) * 100;
        if(isNaN(piePerc)) piePerc = 0;
        piePerc = piePerc.toFixed(1);
        piePerc = parseFloat(piePerc);

        cards_employees.value['total_percent'] = {
    
            name: "Total Monthly (Overhead %)",
            default: 0,
            print: "percent",
            value: piePerc,
            required: true

        };
        cards_employees.value['total'] = {

            name: "Total Monthly (Overhead $)",
            default: 0,
            print: "price",
            value: total,
            required: true

        };

        // -- set pieChart data -- //

        pieSlice = pieChart.items[pieChart.items.length-1];
        if(pieSlice.value !== piePerc)
        {
            pieSlice.name = "Employees Summary";      
            pieSlice.value = piePerc;

        }
        


        



        
    }// -- if

    //console.log("_overhead A: "+JSON.stringify(_overhead,null,2));

    //_overhead = VPoverhead;
    //console.log("_overhead B: "+JSON.stringify(_overhead,null,2));





    var R = [];
    //var index;
    //index = 0;



    //============================================
    //OVERHEAD
    //============================================

    R.push({

        component: "NameValueCard",
        styl:{

            call        : 'full',
            class       : 'FullRow',

            marginTop   : "0px",
            marginBottom: "0px"
        },

        info:{

            thumb:{

                diff: false
            },

            cellBorder: {
                boolean       :false,
                thickness     :0,//px
                color         :"lightgray"  
            },

            gutter:{

                boolean: false,
                size: 0
            },
            thresh:false
            
        },

        items:[

            {

                heading:VPRoverhead.name,
                bullet:{

                    styl:{

                        color:"#f00"
                    }
                    
                },
                stripe:true,
                pgraph:VPRoverhead.value,
                image:false,
                button:false,
                controls:{

                    form:false,
                    chart:{

                        data:VPRoverhead,
                        flyr:{
                            type:"chart",
                            styl:{
                                maxWidth:900
                            }
                        }

                    }

                }
            
            }
            
        ]

    });

        





    //============================================
    //HOURLY RATES
    //============================================

    R.push({

        component: "NameValueCard",
        styl:{

            call        : 'full',
            class       : 'FullRow',

            marginTop   : "0px",
            marginBottom: "0px"
        },

        info:{

            thumb:{

                diff: false
            },

            cellBorder: {
                boolean       :false,
                thickness     :0,//px
                color         :"lightgray"  
            },

            gutter:{

                boolean: false,
                size: 0
            },
            thresh:false
            
        },

        items:[

            {

                heading:VPRhourlyRates.name,
                bullet:{

                    styl:{

                        color:"#fc0"
                    }
                    
                },
                stripe:true,
                pgraph:VPRhourlyRates.value,
                image:false,
                button:false,
                controls:{

                    form:false,
                    chart:{

                        data:VPRhourlyRates,
                        flyr:{
                            type:"chart",
                            styl:{
                                maxWidth:900
                            }
                        }

                    }

                }
            
            }
            
        ]

    });





    //============================================
    //Direct Hours
    //============================================

    R.push({

        component: "NameValueCard",
        styl:{

            call        : 'full',
            class       : 'FullRow',

            marginTop   : "0px",
            marginBottom: "0px"
        },

        info:{

            thumb:{

                diff: false
            },

            cellBorder: {
                boolean       :false,
                thickness     :0,//px
                color         :"lightgray"  
            },

            gutter:{

                boolean: false,
                size: 0
            },
            thresh:false
            
        },

        items:[

            {

                heading:VPRdirectHours.name,
                bullet:{

                    styl:{

                        color:"#fc0"
                    }
                    
                },
                stripe:true,
                pgraph:VPRdirectHours.value,
                image:false,
                button:false,
                controls:{

                    form:false,
                    chart:{

                        data:VPRdirectHours,
                        flyr:{
                            type:"chart",
                            styl:{
                                maxWidth:900
                            }
                        }

                    }

                }
            
            }
            
        ]

    });








    //============================================
    //TOTALS
    //============================================

    R.push({

        component: "NameValueCard",
        styl:{

            call        : 'full',
            class       : 'FullRow',

            marginTop   : "0px",
            marginBottom: "0px"
        },

        info:{

            thumb:{

                diff: false
            },

            cellBorder: {
                boolean       :false,
                thickness     :0,//px
                color         :"lightgray"  
            },

            gutter:{

                boolean: false,
                size: 0
            },
            thresh:false
            
        },

        items:[

            {

                heading:VPRtotals.name,
                bullet:{

                    styl:{

                        color:"royalblue"
                    }
                    
                },
                stripe:true,
                pgraph:VPRtotals.value,
                image:false,
                button:false,
                controls:{

                    form:false,
                    chart:{

                        data:VPRtotals,
                        flyr:{
                            type:"chart",
                            styl:{
                                maxWidth:900
                            }
                        }

                    }

                }
            
            }
            
        ]

    });












    //============================================
    //TITLE
    //============================================

    
    //--------------------------
    // Decor - Title
    //--------------------------

    R.push({


        component: "DecorCard",
        styl:{

            call        : 'indent',
            class       : 'IndentRow',

            marginTop   : "0px",
            marginBottom: "0px"
        },

        info:{

            decor:{

                call        :"Text",

                boxStyle    :{

                    backgroundColor: "transparent",
                    border:"0",
                    margin: "25px 20px 0 20px"

                },

                textStyle   :{

                    backgroundColor:    "transparent",
                    margin:             "0 0 0 0",
                    padding:            "0 0 0 0",

                    fontSize:           "32px",
                    fontWeight:         "bold",
                    fontColor:          "#454d5f",
                    textAlign:          "center",
                    textDecoration:     "none"
                }

            },

            thumb:{

                diff: false
            },

            cellBorder: {
                boolean       :false,
                thickness     :0,//px
                color         :"lightgray"  
            },

            gutter:{

                boolean: false,
                size: 0
            },
            thresh:{

                across1:501,
                across2:801,
                across3:1050
            }
            
        },

        items:[

            {

                heading:"Owners",
                stripe:false,
                pgraph:"",
                image:false,
                button:false
            
            }
            
        ]

    });


    //============================================
    //OWNRS - Totals
    //============================================


    //--------------------------
    // Owners
    //--------------------------

    if(cards_owners)
    { 
        pgraph = cards_owners.value;
        //console.log("PGRPAH: "+JSON.stringify(pgraph,null,2));
        if(pgraph['total'] && pgraph['total'] !== undefined)
        {

            total = 0;
            chartSections = [];

            grand_total = pgraph['total'].value;
            if(!isNaN(grand_total)) grand_total = parseFloat(grand_total);
        
            for(kn in pgraph)
            {

                object = pgraph[kn];

                if(!isNaN(object.value)) value = parseFloat(object.value);
                else value = object.value;

                switch(kn)
                {
                case'ownr_rate_yearly':
                case'total_percent':
                case'total':
                break;
                default:

                    total = value;
                    
                    percent = ( parseFloat(total)/parseFloat(grand_total) ) * 100;
                    percent = percent.toFixed(1);
                    percent = parseFloat(percent);

                    // -- set chart sections -- //

                    chartSections.push({

                        name    :object.name,
                        value   :percent

                    });

                   
                }

            }

        }

    }//==




    //============================================
    //Owners Summary
    //============================================

    R.push({

        component: "NameValueCard",
        styl:{

            call        : 'full',
            class       : 'FullRow',

            marginTop   : "0px",
            marginBottom: "0px"
        },

        info:{

            thumb:{

                diff: false
            },

            cellBorder: {
                boolean       :false,
                thickness     :0,//px
                color         :"lightgray"  
            },

            gutter:{

                boolean: false,
                size: 0
            },
            thresh:{

                across1:501,
                across2:801,
                across3:1050
            }
            
        },


        items:[
        
            {

                heading:cards_owners.name,
                bullet:{

                    styl:{

                        //color:Settings.pieChart.colors[Settings.pieChart.colors.length-1]
                        color:"purple"
                    }
                    
                },
                stripe:true,
                pgraph:cards_owners.value,
                image:false,
                button:false,
                controls:{

                    form:{

                        url:'Owners'  

                    },


                    /*
                    form:{
                        data:{

                            name:"owners Summary",
                            value:owners,
                            actions:{
                                add:true,
                                delete:true,        
                                edit:true,
                                chart:false
                            }

                        },
                        flyr:{
                            type:"cards",
                            styl:{
                                maxWidth:550,
                            }
                        }
                    },
                    */


                    chart:{

                        data:{
                            name:cards_owners.name,
                            value:chartSections
                        },
                        flyr:{
                            type:"chart",
                            styl:{
                                maxWidth:700
                            }
                        }

                    }

                }
            
            }
            
        ]

    });





    //============================================
    //Owners Yearly Summary
    //============================================

    R.push({

        component: "NameValueCard",
        styl:{

            call        : 'full',
            class       : 'FullRow',

            marginTop   : "0px",
            marginBottom: "0px"
        },

        info:{

            thumb:{

                diff: false
            },

            cellBorder: {
                boolean       :false,
                thickness     :0,//px
                color         :"lightgray"  
            },

            gutter:{

                boolean: false,
                size: 0
            },
            thresh:{

                across1:501,
                across2:801,
                across3:1050
            }
            
        },


        items:[
        
            {

                heading:cards_owners_yearly.name,
                bullet:{

                    styl:{

                        //color:Settings.pieChart.colors[Settings.pieChart.colors.length-1]
                        color:"purple"
                    }
                    
                },
                stripe:true,
                pgraph:cards_owners_yearly.value,
                image:false,
                button:false,
                controls:{

                    form:{

                        url:'Owners'  

                    },


                    /*
                    form:{
                        data:{

                            name:"owners Summary",
                            value:owners,
                            actions:{
                                add:true,
                                delete:true,        
                                edit:true,
                                chart:false
                            }

                        },
                        flyr:{
                            type:"cards",
                            styl:{
                                maxWidth:550,
                            }
                        }
                    },
                    */


                    chart:{

                        data:{
                            name:cards_owners.name,
                            value:chartSections
                        },
                        flyr:{
                            type:"chart",
                            styl:{
                                maxWidth:700
                            }
                        }

                    }

                }
            
            }
            
        ]

    });




    //============================================
    //Owners Monthly Summary
    //============================================

    R.push({

        component: "NameValueCard",
        styl:{

            call        : 'full',
            class       : 'FullRow',

            marginTop   : "0px",
            marginBottom: "0px"
        },

        info:{

            thumb:{

                diff: false
            },

            cellBorder: {
                boolean       :false,
                thickness     :0,//px
                color         :"lightgray"  
            },

            gutter:{

                boolean: false,
                size: 0
            },
            thresh:{

                across1:501,
                across2:801,
                across3:1050
            }
            
        },


        items:[
        
            {

                heading:cards_owners_monthly.name,
                bullet:{

                    styl:{

                        //color:Settings.pieChart.colors[Settings.pieChart.colors.length-1]
                        color:"purple"
                    }
                    
                },
                stripe:true,
                pgraph:cards_owners_monthly.value,
                image:false,
                button:false,
                controls:{

                    form:{

                        url:'Owners'  

                    },


                    /*
                    form:{
                        data:{

                            name:"owners Summary",
                            value:owners,
                            actions:{
                                add:true,
                                delete:true,        
                                edit:true,
                                chart:false
                            }

                        },
                        flyr:{
                            type:"cards",
                            styl:{
                                maxWidth:550,
                            }
                        }
                    },
                    */


                    chart:{

                        data:{
                            name:cards_owners.name,
                            value:chartSections
                        },
                        flyr:{
                            type:"chart",
                            styl:{
                                maxWidth:700
                            }
                        }

                    }

                }
            
            }
            
        ]

    });











    //============================================
    //OWNRS
    //============================================

    for(keyname in _OWNRS)
    {

        R.push({

            component: "NameValueCard",
            styl:{
    
                call        : 'full',
                class       : 'FullRow',
    
                marginTop   : "0px",
                marginBottom: "0px"
            },
    
            info:{
    
                thumb:{
    
                    diff: false
                },
    
                cellBorder: {
                    boolean       :false,
                    thickness     :0,//px
                    color         :"lightgray"  
                },
    
                gutter:{
    
                    boolean: false,
                    size: 0
                },
                thresh:false
                
            },
    
            items:[
    
                {
    
                    heading:_OWNRS[keyname].name,
                    bullet:{
    
                        styl:{
    
                            color:"aqua"
                        }
                        
                    },
                    stripe:true,
                    pgraph:_OWNRS[keyname].value,
                    image:false,
                    button:false,
                    controls:{
    
                        form:false,
                        chart:{
    
                            data:_OWNRS[keyname],
                            flyr:{
                                type:"chart",
                                styl:{
                                    maxWidth:900
                                }
                            }
    
                        }
    
                    }
                
                }
                
            ]
    
        });



    }//for in









    //============================================
    //TITLE
    //============================================


    //--------------------------
    // Decor - Title
    //--------------------------

    R.push({


        component: "DecorCard",
        styl:{

            call        : 'indent',
            class       : 'IndentRow',

            marginTop   : "0px",
            marginBottom: "0px"
        },

        info:{

            decor:{

                call        :"Text",

                boxStyle    :{

                    backgroundColor: "transparent",
                    border:"0",
                    margin: "25px 20px 0 20px"

                },

                textStyle   :{

                    backgroundColor:    "transparent",
                    margin:             "0 0 0 0",
                    padding:            "0 0 0 0",

                    fontSize:           "32px",
                    fontWeight:         "bold",
                    fontColor:          "#454d5f",
                    textAlign:          "center",
                    textDecoration:     "none"
                }

            },

            thumb:{

                diff: false
            },

            cellBorder: {
                boolean       :false,
                thickness     :0,//px
                color         :"lightgray"  
            },

            gutter:{

                boolean: false,
                size: 0
            },
            thresh:{

                across1:501,
                across2:801,
                across3:1050
            }
            
        },

        items:[

            {

                heading:"Employees",
                stripe:false,
                pgraph:"",
                image:false,
                button:false
            
            }
            
        ]

    });



    //============================================
    //EMPLS - Totals
    //============================================

    //--------------------------
    // Employees
    //--------------------------

    if(cards_employees)
    { 
        pgraph = cards_employees.value;
        //console.log("PGRPAH: "+JSON.stringify(pgraph,null,2));
        if(pgraph['total'] && pgraph['total'] !== undefined)
        {

            total = 0;
            chartSections = [];

            grand_total = pgraph['total'].value;
            if(!isNaN(grand_total)) grand_total = parseFloat(grand_total);
        
            for(kn in pgraph)
            {

                object = pgraph[kn];

                if(!isNaN(object.value)) value = parseFloat(object.value);
                else value = object.value;

                switch(kn)
                {
                case'empl_rate_yearly':
                case'total_percent':
                case'total':
                break;
                default:

                    total = value;
                    
                    percent = ( parseFloat(total)/parseFloat(grand_total) ) * 100;
                    percent = percent.toFixed(1);
                    percent = parseFloat(percent);

                    // -- set chart sections -- //

                    chartSections.push({

                        name    :object.name,
                        value   :percent

                    });

                   
                }

            }

        }

    }//==





    R.push({

        component: "NameValueCard",
        styl:{

            call        : 'full',
            class       : 'FullRow',

            marginTop   : "0px",
            marginBottom: "0px"
        },

        info:{

            thumb:{

                diff: false
            },

            cellBorder: {
                boolean       :false,
                thickness     :0,//px
                color         :"lightgray"  
            },

            gutter:{

                boolean: false,
                size: 0
            },
            thresh:{

                across1:501,
                across2:801,
                across3:1050
            }
            
        },


        items:[
        
            {

                heading:cards_employees.name + '<span style="font-size:14px; margin:-10px 0 0 5px;">(Optional)</span>',
                bullet:{

                    styl:{

                        //color:Settings.pieChart.colors[Settings.pieChart.colors.length-1]
                        color:"purple"
                    }
                    
                },
                stripe:true,
                pgraph:cards_employees.value,
                image:false,
                button:false,
                controls:{

                    form:{

                        url:'Employees'  

                    },


                    /*
                    form:{
                        data:{

                            name:"Employees Summary",
                            value:employees,
                            actions:{
                                add:true,
                                delete:true,        
                                edit:true,
                                chart:false
                            }

                        },
                        flyr:{
                            type:"cards",
                            styl:{
                                maxWidth:550,
                            }
                        }
                    },
                    */


                    chart:{

                        data:{
                            name:cards_employees.name,
                            value:chartSections
                        },
                        flyr:{
                            type:"chart",
                            styl:{
                                maxWidth:700
                            }
                        }

                    }

                }
            
            }
            
        ]

    });










    //============================================
    //Employees Yearly Summary
    //============================================

    R.push({

        component: "NameValueCard",
        styl:{

            call        : 'full',
            class       : 'FullRow',

            marginTop   : "0px",
            marginBottom: "0px"
        },

        info:{

            thumb:{

                diff: false
            },

            cellBorder: {
                boolean       :false,
                thickness     :0,//px
                color         :"lightgray"  
            },

            gutter:{

                boolean: false,
                size: 0
            },
            thresh:{

                across1:501,
                across2:801,
                across3:1050
            }
            
        },


        items:[
        
            {

                heading:cards_employees_yearly.name,
                bullet:{

                    styl:{

                        //color:Settings.pieChart.colors[Settings.pieChart.colors.length-1]
                        color:"purple"
                    }
                    
                },
                stripe:true,
                pgraph:cards_employees_yearly.value,
                image:false,
                button:false,
                controls:{

                    form:{

                        url:'Employees'  

                    },


                    /*
                    form:{
                        data:{

                            name:"owners Summary",
                            value:owners,
                            actions:{
                                add:true,
                                delete:true,        
                                edit:true,
                                chart:false
                            }

                        },
                        flyr:{
                            type:"cards",
                            styl:{
                                maxWidth:550,
                            }
                        }
                    },
                    */


                    chart:{

                        data:{
                            name:cards_owners.name,
                            value:chartSections
                        },
                        flyr:{
                            type:"chart",
                            styl:{
                                maxWidth:700
                            }
                        }

                    }

                }
            
            }
            
        ]

    });




    //============================================
    //Employees Monthly Summary
    //============================================

    R.push({

        component: "NameValueCard",
        styl:{

            call        : 'full',
            class       : 'FullRow',

            marginTop   : "0px",
            marginBottom: "0px"
        },

        info:{

            thumb:{

                diff: false
            },

            cellBorder: {
                boolean       :false,
                thickness     :0,//px
                color         :"lightgray"  
            },

            gutter:{

                boolean: false,
                size: 0
            },
            thresh:{

                across1:501,
                across2:801,
                across3:1050
            }
            
        },


        items:[
        
            {

                heading:cards_employees_monthly.name,
                bullet:{

                    styl:{

                        //color:Settings.pieChart.colors[Settings.pieChart.colors.length-1]
                        color:"purple"
                    }
                    
                },
                stripe:true,
                pgraph:cards_employees_monthly.value,
                image:false,
                button:false,
                controls:{

                    form:{

                        url:'Employees'  

                    },


                    /*
                    form:{
                        data:{

                            name:"owners Summary",
                            value:owners,
                            actions:{
                                add:true,
                                delete:true,        
                                edit:true,
                                chart:false
                            }

                        },
                        flyr:{
                            type:"cards",
                            styl:{
                                maxWidth:550,
                            }
                        }
                    },
                    */


                    chart:{

                        data:{
                            name:cards_owners.name,
                            value:chartSections
                        },
                        flyr:{
                            type:"chart",
                            styl:{
                                maxWidth:700
                            }
                        }

                    }

                }
            
            }
            
        ]

    });








    //============================================
    //EMPLS
    //============================================

    for(keyname in _EMPLS)
    {

        R.push({

            component: "NameValueCard",
            styl:{
    
                call        : 'full',
                class       : 'FullRow',
    
                marginTop   : "0px",
                marginBottom: "0px"
            },
    
            info:{
    
                thumb:{
    
                    diff: false
                },
    
                cellBorder: {
                    boolean       :false,
                    thickness     :0,//px
                    color         :"lightgray"  
                },
    
                gutter:{
    
                    boolean: false,
                    size: 0
                },
                thresh:false
                
            },
    
            items:[
    
                {
    
                    heading:_EMPLS[keyname].name,
                    bullet:{
    
                        styl:{
    
                            color:"aqua"
                        }
                        
                    },
                    stripe:true,
                    pgraph:_EMPLS[keyname].value,
                    image:false,
                    button:false,
                    controls:{
    
                        form:false,
                        chart:{
    
                            data:_EMPLS[keyname],
                            flyr:{
                                type:"chart",
                                styl:{
                                    maxWidth:900
                                }
                            }
    
                        }
    
                    }
                
                }
                
            ]
    
        });



    }//for in

    




    return R;


}



export default SceneInfo;








/*
 

  "empl-1": {
    "name": "Jeff Beck",
    "value": {
      "empl_vacation_weeks": "2.00",
      "empl_direct_indirect_ira_monthly_total": "62.40",
      "empl_burden_hours_yearly": "2080.00",
      "empl_name": "Jeff Beck",
      "empl_direct_hourly_rate": "21.96",
      "empl_labor_direct": "Design,Production",
      "empl_labor_percent_indirect": "30.00",
      "empl_tax_hourly": "3.60",
      "empl_hours_indirect": "12.00",
      "empl_direct_ira_monthly": "18.00",
      "empl_indirect_tax_yearly": "5328.00",
      "empl_direct_indirect_tax_monthly_total": "624.00",
      "empl_tax_comp_decimal": "0.20",
      "empl_hours_direct": "28.00",
      "empl_yearly_salary_w_health_insurance": "39840.00",
      "empl_tax_comp_percent": "20.00",
      "empl_direct_ira_yearly": "216.00",
      "empl_direct_hours_yearly": "600.00",
      "empl_indirect_hours_yearly": "1480.00",
      "empl_working_weeks": "50.00",
      "empl_labor_percent": "70.00",
      "empl_health_insurance_weekly": "48.00",
      "empl_indirect_salary_monthly": "2220.00",
      "empl_direct_indirect_ira_yearly_total": "748.80",
      "empl_direct_indirect_salary_yearly_total": "37440.00",
      "empl_health_insurance_monthly": "200.00",
      "empl_direct_salary_monthly": "900.00",
      "empl_indirect_ira_monthly": "44.40",
      "empl_direct_tax_yearly": "2160.00",
      "empl_indirect_ira_yearlly": "532.80",
      "empl_labor_percent_direct": "70.00",
      "empl_direct_salary_yearly": "10800.00",
      "empl_hourly_rate": "18.00",
      "empl_indirect_salary_yearly": "26640.00",
      "empl_direct_tax_monthly": "180.00",
      "empl_direct_labor_decimal": "0.70",
      "empl_health_insurance_yearly": "2400.00",
      "empl_ira_decimal": "0.02",
      "empl_hours_weekly": "40.00",
      "empl_ira_percent": "2.00",
      "empl_labor_indirect": "Sales,Office",
      "empl_health_insurance_hourly": "1.20",
      "empl_ira_hourly": "0.36",
      "empl_direct_indirect_salary_monthly_total": "3120.00",
      "empl_burden_yearly_total": "48076.80",
      "empl_indirect_hourly_rate": "23.16",
      "empl_direct_indirect_tax_yearly_total": "7488.00",
      "empl_indirect_tax_monthly": "444.00"
    }
  },
  "overhead": {
    "overhead": "40.06",
    "overhead_decimal": "0.40"
  },
  "ownr-1": {
    "name": "Thom M",
    "value": {
      "ownr_hours_weekly": "40.00",
      "ownr_sti_yearly_direct": "57942.86",
      "ownr_name": "Thom M",
      "ownr_sti_monthly_indirect": "1207.14",
      "ownr_labor_indirect": "Sales,Business Management,Office",
      "ownr_sti": "72428.57",
      "ownr_hours_indirect_percent_whole": "20.00",
      "ownr_labor_percent_direct": "80.00",
      "ownr_taxes_monthly_direct": "1428.57",
      "ownr_ira_monthly_indirect": "16.67",
      "ownr_taxes_monthly_indirect": "357.14",
      "ownr_ira_yearly": "1000.00",
      "ownr_ira_monthly_direct": "66.67",
      "ownr_labor_direct": "Design,Production,Installation",
      "ownr_taxes_monthly": "1785.71",
      "ownr_labor_percent_indirect": "20.00",
      "ownr_hours_direct_percent_whole": "80.00",
      "ownr_labor_percent": "80.00",
      "ownr_ira_monthly": "83.33",
      "ownr_vacation_weeks": "2.00",
      "ownr_ira_percent": "2.00",
      "ownr_sti_monthly_direct": "4828.57",
      "ownr_hours_indirect_percent": "0.20",
      "ownr_taxes_yearly": "21428.57",
      "ownr_sti_weekly_direct": "1158.86",
      "ownr_sti_weekly_indirect": "289.71",
      "ownr_salary_pretax": "50000.00",
      "ownr_flag": "<br/>The owner spends the majority of their time performing direct labor.<br/>20% of the owners salary will be considered an Overhead expense..<br/>Graphic Design, Production and Installation rates will use the owners Direct Labor rate.",
      "ownr_taxrate_percent": "30.00",
      "ownr_health_insurance_yearly": "6000.00",
      "ownr_indirect_hours_yearly": "1680.00",
      "ownr_taxrate_reciprocal": "0.70",
      "ownr_ira_decimal": "0.02",
      "ownr_salary_pretax_monthly": "4166.67",
      "ownr_st": "71428.57",
      "ownr_working_weeks": "50.00",
      "ownr_hours_direct_percent": "0.80",
      "ownr_health_insurance_monthly": "500.00",
      "ownr_sti_weekly": "1448.57",
      "ownr_taxrate_decimal": "0.30",
      "ownr_flag_case": "greater",
      "ownr_sti_hourly_direct": "36.21",
      "ownr_salary_overhead": "833.33",
      "ownr_sti_yearly_indirect": "14485.71"
    }
  },
  "totals": {
    "total_business": "1743.50",
    "total_ira": "61.07",
    "total_ownr_health_insurance_monthly": "500.00",
    "total_empl_indirect_salary_monthly": "2220.00",
    "total_weekly_hours": "40.00",
    "total_vehicles": "1010.00",
    "total_empl_indirect_ira_monthly": "44.40",
    "total_facility": "2610.00",
    "total_tax": "801.14",
    "total": "14022.38",
    "total_ownr_taxes_monthly_indirect": "357.14",
    "total_ownr_ira_monthly_indirect": "16.67",
    "total_ownr_salary_pretax_monthly_indirect": "4166.67",
    "total_health_insurance": "700.00",
    "total_empl_health_insurance_monthly": "200.00",
    "total_equipsoftware": "710.00",
    "total_empl_indirect_tax_monthly": "444.00"
  }
}






*/