export const ChartColorsA = [

    "#07a2e3",
    "#20b2aa",
    "#caffca",
    
    "#fde23e",
    "#f16e23", 
    "#57d9ff",
    "#937e88",
    "#20b2aa",
    "#ccff00",

    "lightblue",
    "pink",
    "lavender",
    "lightgreen",
    "yellow"
];


export const ChartColorsB = [


    //Green
    "#8cc63f",
    "#62bb46",
    "#00a651",
    
    //Purple
    "#b0abd5",
    "#8781bd",
    "#645faa",
    "#524fa1",
    "#472f92",
    
    //DK Blue
    "#95b6df",
    "#6c8cc7",
    "#436ab3",
    "#034ea2",
    
    //Lt Blue
    "#8ed8f8",
    "#66bae8",
    "#12b0e6",
    "#0089cf",
    
    //Teal
    "#64afc1",
    "#33899b",
    "#006b76"

];


export const ChartColorsC = [

    "lightseagreen",
    "royalblue",
    "lightblue",
    "lightslateblue",

    "lightgreen",

    "purple",
    "lavender",
    "magenta",
    "pink"

];





export const GaugeColors = [

    "#454d5f",
    "white",     
    "#07a2e3",

];



export const GaugeColorsB = [

    "#07a2e3",   
    "#454d5f" 

];





/*

Green
#8cc63f
#62bb46
#00a651

Purple
#b0abd5
#8781bd
#645faa
#524fa1
#472f92

DK Blue
#95b6df
#6c8cc7
#436ab3
#034ea2

Lt Blue
#8ed8f8
#66bae8
#12b0e6
#0089cf

Teal
#64afc1
#33899b
#006b76



*/





