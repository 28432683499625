import styled from "styled-components";
//import px2vw from "utils/px2vw";



export const ProcAlfa = styled.div.attrs(props => ({


    backgroundColor:    props.backgroundColor || "#1a1926",//#fcfcfc
    border:             props.border || "0",
    borderRadius:       props.borderRadius || "0",

    percent:           props.percent || "60", 
    decimal:           props.decimal || "0.6",


    width:            props.width || "100%", 
    height:           props.height || "100%",

    zIndex:           props.zIndex || "1",
    top:              props.top || "0px", 
    left:             props.left || "0px"


}))`
  

    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    border-radius:${props => props.borderRadius};

    width:${props => props.width};
    height:${props => props.height};

    padding :0 0 0 0; 
    margin  :0 0 0 0;

    position:absolute;   
    z-index:${props => props.zIndex};
    top:${props => props.top};
    left:${props => props.left};

    -ms-filter: "progid:pxImageTransform.Microsoft.Alpha(Opacity=${props => props.percent})";
    filter : alpha(opacity=${props => props.percent});
    -moz-opacity : ${props => props.decimal};
    -khtml-opacity : ${props => props.decimal};
    opacity : ${props => props.decimal};


`;


export const ProcBase = styled.div.attrs(props => ({


    backgroundColor:    props.backgroundColor || "transparent",//#fcfcfc
    border:             props.border || "0",
    borderRadius:       props.borderRadius || "0",

    width:            props.width || "100%", 
    height:           props.height || "100%",

    zIndex:           props.zIndex || "2", 
    top:              props.top || "0", 
    left:             props.left || "0"


}))`
  

    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    border-radius:${props => props.borderRadius};


    width:${props => props.width};
    height:${props => props.height};

    padding :0 0 0 0; 
    margin  :0 0 0 0;


    position:absolute;   
    z-index:${props => props.zIndex};
    top:${props => props.top};
    left:${props => props.left};


    overflow-x:hidden;
    overflow-y:auto;



`;





export const ProcMenu = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "white", //"#191e28", //"#EDEEF1",

    width:              props.width || "calc(100% - 25px)",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 0",

    top:                props.top || "52px",


    buttonFontFamily:         props.buttonFontFamily || 'Arial, Helvetica, sans-serif',
    buttonFontSize:           props.buttonFontSize || "16px",
    buttonFontWeight:         props.buttonFontWeight  || "500",
    buttonTextAlign:          props.buttonTextAlign || "left",
    buttonTextDecoration:     props.buttonTextDecoration  || "none",
    buttonTextColor:          props.buttonTextColor || "black"



    // border-top:1px solid lightgray;
    // border-bottom:1px solid lightgray; 



}))`

    background-color:${props => props.backgroundColor};
    border:1px solid lightgray;


    max-width:225px;
    min-height:150px;
    width:${props => props.width};
    height:${props => props.height};

    padding:0 0 0 0;
    margin:${props => props.margin};

    position:absolute;
    top:${props => props.top};
    right:0px;
    z-index:7;

    @media (max-width: 1024px) {

        background-color:white;
        border:1px solid #454d5f;
    }


    .Button {

        background-color:transparent;

        border-top:1px solid lightgray;
        border-bottom:1px solid lightgray;

        width:calc(100% - 10px);
        height:auto;
        padding:10px 0 10px 10px;
        margin:0 0 0 0;
        position:relative;
        float:left;

        cursor:pointer;

        font-family:${props => props.buttonFontFamily};
        font-size:${props => props.buttonFontSize};
        font-weight:${props => props.buttonFontWeight};
        text-align:${props => props.buttonTextAlign};
        text-decoration:${props => props.buttonTextDecoration};
        color:${props => props.buttonTextColor};

        &:hover{

            color:#07a2e3;

        }


    }


`;





export const ProcMessage = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#fff", //"#191e28", //"#EDEEF1",

    width:              props.width || "400px",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 calc(50% - 200px)",

    zIndex:             props.zIndex || "8",
    top:                props.top || "100px", 
    left:               props.left || "0px",


    messageFontFamily:         props.messageFontFamily || 'Arial, Helvetica, sans-serif',
    messageFontSize:           props.messageFontSize || "18px",
    messageFontWeight:         props.messageFontWeight  || "500",
    messageTextAlign:          props.messageTextAlign || "center",
    messageTextDecoration:     props.messageTextDecoration  || "none",
    messageTextColor:          props.messageTextColor || "black",

    buttonFontFamily:         props.buttonFontFamily || 'Arial, Helvetica, sans-serif',
    buttonFontSize:           props.buttonFontSize || "16px",
    buttonFontWeight:         props.buttonFontWeight  || "500",
    buttonTextAlign:          props.buttonTextAlign || "center",
    buttonTextDecoration:     props.buttonTextDecoration  || "none",
    buttonTextColor:          props.buttonTextColor || "white"


    // border-top:1px solid lightgray;
    // border-bottom:1px solid lightgray; 



}))`

    background-color:${props => props.backgroundColor};
    border:2px solid #fff;
    border-radius:7px;

    min-height:auto;
    width:${props => props.width};
    height:${props => props.height};

    padding:0 0 50px 0;
    margin:${props => props.margin};

    position:absolute;
    z-index:${props => props.zIndex};
    top:${props => props.top};
    left:${props => props.left};



    @media (max-width: 1024px) {

        width:350px;
        margin:0 0 0 calc(50% - 175px);

    }


    @media (max-width: 400px) {

        width:300px;
        margin:0 0 0 calc(50% - 150px);


    }




    .Message {

        background-color:transparent;

        width:calc(100% - 30px);
        height:auto;
        padding:10px 0 10px 10px;
        margin:25px 0 20px 0;
        position:relative;
        float:left;

        cursor:default;

        font-family:${props => props.messageFontFamily};
        font-size:${props => props.messageFontSize};
        font-weight:${props => props.messageFontWeight};
        text-align:${props => props.messageTextAlign};
        text-decoration:${props => props.messageTextDecoration};
        color:${props => props.messageTextColor};

    }



    .SaveButton {

        background-color:#fc0;
        border:1px solid #fc0;
        border-radius:5px;

        width:calc(100% - 150px);
        height:auto;
        padding:11px 0 8px 0;
        margin:2.5px 0 2.5px 75px;
        position:relative;
        float:left;

        cursor:pointer;

        font-family:${props => props.buttonFontFamily};
        font-size:${props => props.buttonFontSize};
        font-weight:${props => props.buttonFontWeight};
        text-align:${props => props.buttonTextAlign};
        text-decoration:${props => props.buttonTextDecoration};
        color:#000;

        &:hover{

            background-color:lightgreen;
            border:1px solid lightgreen;
            color:#000;

        }


    }

    @media (max-width: 1024px) {

        .SaveButton {

            width:calc(100% - 75px);
            margin:2.5px 0 2.5px 37.5px;

        }

    }




    .CancelButton {

        background-color:#07a2e3;
        border:1px solid #07a2e3;
        border-radius:5px;

        width:calc(100% - 150px);
        height:auto;
        padding:11px 0 8px 0;
        margin:2.5px 0 2.5px 75px;
        position:relative;
        float:left;

        cursor:pointer;

        font-family:${props => props.buttonFontFamily};
        font-size:${props => props.buttonFontSize};
        font-weight:${props => props.buttonFontWeight};
        text-align:${props => props.buttonTextAlign};
        text-decoration:${props => props.buttonTextDecoration};
        color:#fff;

        &:hover{

            background-color:#0291cd;
            border:1px solid #0291cd;
            color:#fff;

        }


    }

    @media (max-width: 1024px) {

        .CancelButton {

            width:calc(100% - 75px);
            margin:2.5px 0 2.5px 37.5px;

        }

    }



`;







export const ProcForm = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#fcfcfc", //"#191e28", //"#EDEEF1",

    width:              props.width || "400px",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 calc(50% - 200px)",

    zIndex:             props.zIndex || "8",
    top:                props.top || "100px", 
    left:               props.left || "0px",


    messageFontFamily:         props.messageFontFamily || 'Arial, Helvetica, sans-serif',
    messageFontSize:           props.messageFontSize || "20px",
    messageFontWeight:         props.messageFontWeight  || "500",
    messageTextAlign:          props.messageTextAlign || "left",
    messageTextDecoration:     props.messageTextDecoration  || "none",
    messageTextColor:          props.messageTextColor || "black",

    buttonFontFamily:         props.buttonFontFamily || 'Arial, Helvetica, sans-serif',
    buttonFontSize:           props.buttonFontSize || "16px",
    buttonFontWeight:         props.buttonFontWeight  || "500",
    buttonTextAlign:          props.buttonTextAlign || "center",
    buttonTextDecoration:     props.buttonTextDecoration  || "none",
    buttonTextColor:          props.buttonTextColor || "black"


    // border-top:1px solid lightgray;
    // border-bottom:1px solid lightgray; 



}))`

    background-color:${props => props.backgroundColor};
    border:2px solid #fcfcfc;
    border-radius:7px;

    min-height:auto;
    width:${props => props.width};
    height:${props => props.height};

    padding:0 0 40px 0;
    margin:${props => props.margin};

    position:absolute;
    z-index:${props => props.zIndex};
    top:${props => props.top};
    left:${props => props.left};


    @media (max-width: 1024px) {

        width:350px;
        margin:0 0 0 calc(50% - 175px);

    }


    @media (max-width: 400px) {

        width:300px;
        margin:0 0 0 calc(50% - 150px);


    }





    .Message {

        background-color:transparent;

        width:calc(100% - 40px);
        height:auto;
        padding:10px 0 0 0;
        margin:10px 0 0 20px;
        position:relative;
        float:left;

        cursor:default;

        font-family:${props => props.messageFontFamily};
        font-size:${props => props.messageFontSize};
        font-weight:${props => props.messageFontWeight};
        text-align:${props => props.messageTextAlign};
        text-decoration:${props => props.messageTextDecoration};
        color:${props => props.messageTextColor};

    }



    .SaveButton {

        background-color:#fc0;
        border:1px solid #fc0;
        border-radius:5px;

        width:calc(100% - 150px);
        height:auto;
        padding:11px 0 8px 0;
        margin:2.5px 0 2.5px 75px;
        position:relative;
        float:left;

        cursor:pointer;

        font-family:${props => props.buttonFontFamily};
        font-size:${props => props.buttonFontSize};
        font-weight:${props => props.buttonFontWeight};
        text-align:${props => props.buttonTextAlign};
        text-decoration:${props => props.buttonTextDecoration};
        color:#000;

        &:hover{

            background-color:lightgreen;
            border:1px solid lightgreen;
            color:#000;

        }


    }

    @media (max-width: 1024px) {

        .SaveButton {

            width:calc(100% - 75px);
            margin:2.5px 0 2.5px 37.5px;

        }

    }




    .CancelButton {

        background-color:#07a2e3;
        border:1px solid #07a2e3;
        border-radius:5px;

        width:calc(100% - 150px);
        height:auto;
        padding:11px 0 8px 0;
        margin:2.5px 0 2.5px 75px;
        position:relative;
        float:left;

        cursor:pointer;

        font-family:${props => props.buttonFontFamily};
        font-size:${props => props.buttonFontSize};
        font-weight:${props => props.buttonFontWeight};
        text-align:${props => props.buttonTextAlign};
        text-decoration:${props => props.buttonTextDecoration};
        color:#fff;

        &:hover{

            background-color:#0291cd;
            border:1px solid #0291cd;
            color:#fff;

        }


    }

    @media (max-width: 1024px) {

        .CancelButton {

            width:calc(100% - 75px);
            margin:2.5px 0 2.5px 37.5px;

        }

    }




     
    .Frm {
    
    
        background-color:transparent;
    
        width:calc(100% - 40px);
        height:auto;
        
        position:relative;	
        float:left;
        margin:5px 0 10px 20px;
        padding:0 0 0 0;
        overflow:visible;
    
    
    }

        .Frm .error-message{
            
            background-color:transparent;
        
            width:calc(100% - 2px);
            height:auto;
            padding:7px 0 5px 0;
            margin:10px 0 10px 1px;
            float:left;
            overflow:visible;

            font-family:Arial, Helvetica, sans-serif;
            font-size:14px;
            font-weight:normal;
            color:darkred;
            text-align:left;

            display:none;

        
        }



    

        .Frm .input-wrap{
        
            background-color:white;
        
            width:100%;
            height:auto;
            padding:0 0 0 0;
            margin:5px 0 5px 0;
            float:left;
            overflow:visible;
        
            border:1px solid #000;
            border-radius:3px;
        
        }
        
            .Frm .input-wrap .input-tag{
            
                background-color:white;
            
                width:98%;
                height:auto;
                
                padding:13px 0 10px 2%;
                margin:0 0 0 0;
                float:left;
            
                font-family:Arial, Helvetica, sans-serif;
                font-size:16px;
                font-weight:normal;
                color:#000;
                text-align:left;
            
                border:0px solid #FFF;
            
            
            }
            .Frm .input-wrap .input-tag-empty{
            
                
                background-color:white;
            
                width:98%;
                height:auto;
                
                padding:13px 0 10px 2%;
                margin:0 0 0 0;
                float:left;
            
            
                font-family:Arial, Helvetica, sans-serif;
                font-size:16px;
                font-weight:normal;
                color:#777;
                text-align:left;
                font-style:italic;
            
                border:0px solid #FFF;
            
            }
        
        







        .Frm .ta-wrap{


            width:100%;
            height:auto;
            padding:0;
            margin:5px 0 25px 0; 
            float:left;
            overflow:hidden;

            border:1px solid #000;
            border-radius:3px;


            
        }


            .Frm .ta-wrap .ta-tag{
            
            
                background-color: #FFF;
            
            
                width:98%;
                height:100px;
                padding:3px 0 0 2%;
                margin:0 0 3px 0;
                float:left;
                
                font-family:Verdana, Arial, Helvetica, sans-serif;
                font-size:14px;
                font-weight:normal;
                color:#000;
                text-align:left;
                
                font-style:normal;
            
            
                border:0px solid #000;
                border-radius:3px;


                resize: none;

            
            
            }
            .Frm .ta-wrap .ta-tag-empty{
            
                
                background-color: #FFF;
            
                width:98%;
                height:100px;
                padding:3px 0 0 2%;
                margin:0 0 3px 0;
                float:left;
            
            
                font-family:Verdana, Geneva, sans-serif;
                font-size:14px;
                font-weight:normal;
                color:#777;
                text-align:left;
            
                font-style:italic;

                border:0px solid #000;
                border-radius:3px;


                resize: none;


            
            }
            .Frm .ta-wrap .character-countdown{
            
            
                width:318px;
                height:25px;
                padding:0 0 0 0;
                margin:0 0 0 7px; 
                float:left;
                
                font-family:Verdana, Arial, Helvetica, sans-serif;
                font-size:10px;
                font-weight:normal;
                color:#FFF;
                text-align:left;
            }
            .Frm .ta-wrap .character-countdown .input-tag{
            
                font-family:Verdana, Arial, Helvetica, sans-serif;
                font-size:11px;
                font-weight:bold;
                color:#000;
                text-align:center;
            }
            
            








    .Frm .container {

        background-color:transparent;
        border:1px solid #454d5f;


        width:calc(100% - 50px);
        height:auto;
        margin:20px 0 25px 25px;
        padding:10px 0 10px 0;
        position:relative;
        float:left;
    

    }



        .Frm .clicker {

            background-color:transparent;

            width:90%;
            height:40px;
            margin:0 0 8px 5%;
            padding:0 0 0 0;
        
            position:relative;
            float:left;
        
        
            
        }
        
        
            .Frm .clicker .btn {
        
                background-color:purple;  
        
                width:110px;
                height:auto;
                margin: 0 0 0 0;
                padding:10px 0 10px 0;
        
        
                position:absolute;
                z-index:1;
        
                top:0;
                left:0;
        
                font-size: 16px;
                font-weight: 600;
                text-align:center;
                color:white;
        
                border-radius:10px;
        
        
            }
        
        
        
        
            .Frm .clicker .click-txt {
        
                background-color:transparent;
        
                width:auto;
                height:50px;
                margin: 0 0 0 0;
                padding:12px 0 11px 0;
        
        
                position:absolute;
                z-index:2;
        
                top:0;
                left:45px;
        
                font-family:Arial, Helvetica, sans-serif;
                font-size: 17px;
                font-weight: normal;
                text-align:left;
                color:black;
        
        
                /*border-radius:10;*/
        
                cursor:pointer;
        
        
                }
        
        
        
                .Frm .clicker .click-txtinp{
            
                
                background-color:white;
            
                width:98%;
                height:auto;
                
                padding:11px 0 11px 2%;
                margin:0 0 0 0;
                float:left;
            
            
                font-family:Verdana, Geneva, sans-serif;
                font-size:14px;
                font-weight:normal;
                color:#777;
                text-align:left;
                font-style:italic;
            
                border:0px solid #FFF;
            
            }
        
            
            
            
            
                .Frm .clicker .rad{
            
                    /*horizontal-align: left;*/
            
                    background-color:black;
                    width:26px;
                    height:26px;
                
                    margin: 0 0 0 0;
                    padding:3px;
        
        
                    position:absolute;
                    z-index:1;
            
                    top:5px;
                    left:0;
            
            
                    border-radius:30px;
        
                    cursor:pointer;
        
        
                }
            
                .Frm .clicker .rad-inner {
            
                    background-color:white;
                    width:20px;
                    height:20px;
                    margin: 0 0 0 0;
                    padding: 0 0 0 0;
                    
                    position:relative;
                    float:left;
            
                    border-radius: 30px;
            
                    border-color: white;
                    border-width: 3px;
                    border-style: solid;
        
        
        
            
            
                }
            
        
        
        
                .Frm .chk{
        
                /*horizontal-align: left;*/
            
                background-color:black;
                width:26px;
                height:26px;
                
                margin: 0 0 0 0;
                padding:3px;
        
        
        
                position:absolute;
                z-index:1;
        
                top:5px;
                left:0;
                
        
                
                cursor:pointer;
        
                
            }
        
            .Frm .chk-inner {
        
                background-color:white;
                width:20px;
                height:20px;
                margin: 0 0 0 0;
                padding: 0 0 0 0;
                
                position:relative;
                float:left;
        
        
                border-color: white;
                border-width: 3px;
                border-style: solid;
        
            }
        
        














`;







export const ProcLoader = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent", //"#191e28", //"#EDEEF1",

    width:              props.width || "100%",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 0",

    zIndex:             props.zIndex || "8",
    top:                props.top || "200px", 
    left:               props.left || "0px",


    messageFontFamily:         props.messageFontFamily || 'Arial, Helvetica, sans-serif',
    messageFontSize:           props.messageFontSize || "20px",
    messageFontWeight:         props.messageFontWeight  || "500",
    messageTextAlign:          props.messageTextAlign || "center",
    messageTextDecoration:     props.messageTextDecoration  || "none",
    messageTextColor:          props.messageTextColor || "black",

    // border-top:1px solid lightgray;
    // border-bottom:1px solid lightgray; 


}))`

    background-color:${props => props.backgroundColor};
    border-radius: 4px;

    min-height:300px;
    width:${props => props.width};
    height:${props => props.height};

    padding:0 0 50px 0;
    margin:${props => props.margin};

    position:absolute;
    z-index:${props => props.zIndex};
    top:${props => props.top};
    left:${props => props.left};


    @media (max-width: 1024px) {

        background-color:transparent;

        border:0;
        border-radius:0;

    }


    @media (max-width: 400px) {

        width:300px;
        margin:0 0 0 calc(50% - 150px);


    }





    .Message {

        background-color:transparent;

        width:calc(100% - 20px);
        height:auto;
        padding:10px 0 10px 10px;
        margin:25px 0 20px 0;
        position:relative;
        float:left;

        cursor:default;

        font-family:${props => props.messageFontFamily};
        font-size:${props => props.messageFontSize};
        font-weight:${props => props.messageFontWeight};
        text-align:${props => props.messageTextAlign};
        text-decoration:${props => props.messageTextDecoration};
        color:${props => props.messageTextColor};

    }





`;





