export const DIRECT_LABOR_HOURS = (props) => {

	let svgWidth = props.svgWidth || "100%";
	let svgHeight = props.svgHeight || "100%";
	let svgViewBox = props.svgViewBox || "0 0 500 500";
	let svgPreserveAspectRatio = props.svgPreserveAspectRatio || "none";
	let svgStyle = props.svgStyle || {background:"transparent"};


	let iconColor = props.iconColor || "rgb(0,0,0)";


	return(
		<div>
		<svg xmlns="http://www.w3.org/2000/svg"
			width={svgWidth}
			height={svgHeight}
			viewBox={svgViewBox}
			preserveAspectRatio={svgPreserveAspectRatio}
			style={svgStyle}
		>
	
			<path 
				fill={iconColor}
				d="M390.446,247.932v4.157c0,2.365-1.564,4.297-3.484,4.297h-42.018 c-1.92,0-3.484-1.932-3.484-4.297v-4.157c0-2.365,1.564-4.298,3.484-4.298h42.018C388.882,243.634,390.446,245.566,390.446,247.932 L390.446,247.932z M252.087,341.435h-4.155c-2.365,0-4.298,1.566-4.298,3.484v42.02c0,1.918,1.933,3.484,4.298,3.484h4.155	c2.367,0,4.299-1.566,4.299-3.484v-42.02C256.386,343.001,254.454,341.435,252.087,341.435L252.087,341.435z M192.692,343.981 l-12.829,22.223l4.674,2.695l12.829-22.219L192.692,343.981L192.692,343.981z M153.387,302.733l-22.222,12.828l2.698,4.674
				l22.22-12.828L153.387,302.733L153.387,302.733z M109.573,252.089c0,2.365,1.565,4.297,3.484,4.297h42.02 c1.918,0,3.483-1.932,3.483-4.297v-4.157c0-2.365-1.565-4.298-3.483-4.298h-42.02c-1.919,0-3.484,1.933-3.484,4.298V252.089 L109.573,252.089z M192.692,156.039l4.674-2.699l-12.829-22.22l-4.674,2.697L192.692,156.039L192.692,156.039z M153.387,197.287 l2.696-4.673l-22.22-12.829l-2.698,4.673L153.387,197.287L153.387,197.287z M343.981,307.329l22.223,12.828l2.695-4.674 l-22.221-12.828L343.981,307.329L343.981,307.329z M302.733,346.634l12.828,22.223l4.674-2.699l-12.83-22.219L302.733,346.634	L302.733,346.634z M346.634,197.287l22.223-12.829l-2.699-4.673l-22.219,12.829L346.634,197.287L346.634,197.287z M250,396.827 c-80.869,0-146.827-65.951-146.827-146.828c0-71.415,51.435-131.18,119.146-144.188V85.527h0.002V74.742	C137.656,88.099,72.573,161.687,72.573,249.999c0,97.725,79.689,177.43,177.427,177.43s177.427-79.705,177.427-177.43 c0-55.73-25.928-105.59-66.328-138.158l-15.512,26.865c31.336,26.961,51.24,66.873,51.24,111.293	C396.827,330.876,330.868,396.827,250,396.827L250,396.827z M348.931,102.837C320.636,83.738,286.583,72.571,250,72.571	c-4.25,0-8.462,0.158-12.639,0.455v27.54c-0.001,0-0.002,0-0.003,0l-0.003,149.833c0.211,6.795,5.813,12.27,12.655,12.27 c7.201,0,9.783-5.449,12.311-9.824L348.931,102.837L348.931,102.837z"
			/>


		</svg>
		</div>

	);//return




}