import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';



import { 

    VPaccount

} from 'app-components/Prime/VPdata/form.js';


import ApiResources from 'utils/components/Pages/ApiResources';


const SceneInfo = () => {



    //-------------------------------------------

    var apiDomain;
    var hostname;
    //var hostname = window.location.hostname;//returns the domain name of the web host
    var protocol = window.location.protocol;//returns the web protocol used (http: or https:)

    if(window.location.hostname.match(/192.168/gi))
    {
        hostname = ApiResources.network['3001'].vpc;
        apiDomain = protocol + "//" + hostname + "/";
    }
    else
    if(window.location.hostname.match(/localhost/gi))
    {
        hostname = ApiResources.local['3001'].vpc;
        apiDomain = protocol + "//" + hostname + "/";
    }else{
        hostname = ApiResources.aws['x'].vpc;
        apiDomain = "https://" + hostname + "/";
    }//=

    //-------------------------------------------

    //console.log("API DOMAIN: "+apiDomain);


    const apiCall = "tpc-update";



    // -- redux -- //
    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));




    var keyname;
    // var kn;
    // var item;
    // //var name;
    // var value;
    // var newValue;

    var isOkay;
    var auth_account;


    //================================================
    // account
    //================================================

    VPaccount['login'].value.email.value = '';

    VPaccount['company'].value.company.value = '';
    VPaccount['company'].value.email.value = '';
    VPaccount['company'].value.address.value = '';
    VPaccount['company'].value.city.value = '';
    VPaccount['company'].value.state.value = '';
    VPaccount['company'].value.country.value = 'US';
    VPaccount['company'].value.zipcode.value = '';
    VPaccount['company'].value.phone.value = '';



    var emailForm = {

        "name"     : "Change Email",
        "value"    :{
    
            "email" : {
    
                "print"		:'text',
                "name"		:'Email',
                "value"		:"",
                "required"	:true,
                "default"	:""
            },
            "new_email" : {
    
                "print"		:'text',
                "name"		:'New Email',
                "value"		:"",
                "required"	:true,
                "default"	:""
            }

    
        }
    

    };


    var pwordForm = {

        "name"     : "Change Password",
        "value"    :{
    
            "old_password" : {
    
                "print"		:'password',
                "name"		:'Old Password',
                "value"		:"",
                "required"	:true,
                "default"	:""
            },
            "password" : {
    
                "print"		:'password',
                "name"		:'Password',
                "value"		:"",
                "required"	:true,
                "default"	:""
            },
            "retype_password" : {
    
                "print"		:'password',
                "name"		:'Retype Password',
                "value"		:"",
                "required"	:true,
                "default"	:""
            }


    
        }
    

    };






    isOkay = false;
    if(zoneObj.auth_account 
    && zoneObj.auth_account !== undefined
    )
    {
        if(Object.keys(zoneObj.auth_account).length > 0
        )
        {
            isOkay = true;
        }

    }
    if(isOkay)
    {

        auth_account = JSON.parse(JSON.stringify(zoneObj.auth_account));


        emailForm.value.email.value = auth_account.auth.sk;


        VPaccount['login'].value.email.value = auth_account.auth.sk;
        VPaccount['company'].value.email.value = auth_account.account.sk;
        if(auth_account.account.data !== '')
        {
            VPaccount['company'].value.company.value = auth_account.account.data.company;
            VPaccount['company'].value.email.value = auth_account.account.data.email;
            VPaccount['company'].value.address.value = auth_account.account.data.address;
            VPaccount['company'].value.city.value = auth_account.account.data.city;
            VPaccount['company'].value.state.value = auth_account.account.data.state;
            VPaccount['company'].value.country.value = auth_account.account.data.country;
            VPaccount['company'].value.zipcode.value = auth_account.account.data.zipcode;
            VPaccount['company'].value.phone.value = auth_account.account.data.phone;
            
        }


        //console.log("ACCOUNT: "+JSON.stringify(zoneObj.account));

        // for(keyname in zoneObj.auth_account)
        // {

        //     //console.log("KEYNAME: "+keyname);

        //     item = zoneObj.auth_account[keyname];
        //     console.log(JSON.stringify(item,null,2));

        //     value = VPaccount[keyname].value;
        //     newValue = {};
        //     for(kn in value)
        //     {
        //         newValue[kn] = item.value[kn];
        //     }
        //     VPaccount[keyname] = {
        //         name:item.name,
        //         value:newValue
        //     };


        // }// -- for


    }// -- if


 



    var R = [];
    //var index;



    var top;
    var bar;   
    var catalogItems;

    var accountName;
    var accountValue;

    var buttonForm;
    var count;
    var fieldType;




    //#############################################################
    //#############################################################

    top = {

        call:'top',
        name:"Company",
        controls:[

            {

                name:"Edit Company",
                form:{

                    submitCall:'account',
                    postTo:apiDomain+apiCall,
                    data:VPaccount.company,
                    flyr:{
                        type:"form",
                        styl:{
                            maxWidth:600,
                            fieldType:'field'
                        }
                    }
             

                }

            }

        ]

    }


    bar = {

        call:'barNameValue',
        items: [

            {

                name:"Name"

            },

            {

                name:"Value"

            }

        ]
        
    }


    catalogItems = [];
    for(keyname in VPaccount.company.value)
    {

        accountName = VPaccount.company.value[keyname].name;
        accountValue = VPaccount.company.value[keyname].value;
        if(accountValue === '')
        {
            accountValue = VPaccount.company.value[keyname].default;
        }


        //console.log(accountName+"\n"+accountValue);


        catalogItems.push({
                        
            heading:accountName,
            bullet:false,
            stripe:true,
            pgraph:accountValue,
            image:false,
            button:false,
            controls:{

                form:{

                    submitCall:'account',
                    postTo:apiDomain+apiCall,
                    data:VPaccount.company,
                    flyr:{
                        type:"form",
                        styl:{
                            maxWidth:600,
                            fieldType:'field'
                        }
                    }

                },
                chart:false

            }
        
        });

    }


    R.push([top,bar,{

        call:"itemsNameValue",
        items:catalogItems
    
    }]);








    //#############################################################
    //#############################################################

    top = {

        call:'top',
        name:"Login",
        controls:[

            // {

            //     name:"Edit Login",
            //     form:{

            //         submitCall:'account',
            //         postTo:apiDomain+apiCall,
            //         data:VPaccount.login,
            //         flyr:{
            //             type:"form",
            //             styl:{
            //                 maxWidth:475,
            //                 fieldType:'field'
            //             }
            //         }
             

            //     }

            // }

        ]

    }


    bar = {

        call:'barNameValue',
        items: [

            {

                name:"Name"

            },

            {

                name:"Value"

            }

        ]
        
    }


    count = 0;
    catalogItems = [];
    for(keyname in VPaccount.login.value)
    {

        accountName = VPaccount.login.value[keyname].name;
        accountValue = VPaccount.login.value[keyname].value;
        if(accountValue === '')
        {
            accountValue = VPaccount.login.value[keyname].default;
        }


        //console.log(accountName+"\n"+accountValue);


        switch(count)
        {
        case 0:
            buttonForm = emailForm;
            fieldType = 'field';
        break;
        case 1:
            buttonForm = pwordForm;
            fieldType = 'labelfield';
        break;
        default:
        }


        catalogItems.push({
                        
            heading:accountName,
            bullet:false,
            stripe:true,
            pgraph:accountValue,
            image:false,
            button:{

                submitCall:'account',
                postTo:apiDomain+apiCall,
                data:buttonForm,
                flyr:{
                    type:"form",
                    styl:{
                        maxWidth:600,
                        fieldType:fieldType
                    }
                }

            },
            controls:{

                form:{

                    submitCall:'account',
                    postTo:apiDomain+apiCall,
                    data:buttonForm,
                    flyr:{
                        type:"form",
                        styl:{
                            maxWidth:600,
                            fieldType:fieldType
                        }
                    }

                },
                chart:false

            }
        
        });



        count++;

    }


    R.push([top,bar,{

        call:"itemsNameValue",
        items:catalogItems
    
    }]);







    return R;

}



export default SceneInfo;
