import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    //useHistory,   
    //useParams,



} from "react-router-dom";


import { VPreportList } from 'app-components/Prime/VPdata/reports';
import { LibArray } from "library";


import Calculation from 'app-components/Zone/Calculator/Calculation';



const SceneInfo = (props) => {


    const zoneObj = props.zoneObj;


    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();

 
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);



    //-----------------------------------------
    //-----------------------------------------

    var pathArr = pathname.split("/");
    var pathCount = pathArr.length;

    var currIndex = parseInt(pathCount)-1;
    var categoryName = pathArr[currIndex];

    //var categoryIndex = parseInt(pathCount)-2;
    //var nextIndex = categoryIndex + 1;

    var categoryCount = currIndex;


    var mainCategoryName = false;
    if(categoryCount > 1)
    {
        //console.log("categoryCount: "+categoryCount);

        var mainIndex = parseInt(pathCount)-2;
        mainCategoryName = pathArr[mainIndex];

        //console.log("mainCategoryName: "+mainCategoryName);
        
    }






    var R = [];
    // var index;

    var keyname;
    //var kn;
    var item;
    var array;
    var value;
    var group, groupName;
    // var newValue;

    // var a,b,c,d;
    // var total;
    // var grand_total;


    // var chartSections;
    //var object;
    // var percent;

    // var pgraph;


    // var obj;


    //------------------------------------------

    //console.log("mainCategoryName: "+mainCategoryName);
    //console.log("categoryName: "+categoryName);


    var isCalc = false;
    var myCalculation;

    var regxSearch;
    var getKeys,getKey;
    var getSearch = false;
    getKeys = Object.keys(VPreportList.GetList);
    for(getKey of getKeys)
    {

        if(getKey === mainCategoryName)
        {
            getSearch = VPreportList.GetList[getKey].getSearch;
        }
        else
        if(getKey === categoryName)
        {
            getSearch = VPreportList.GetList[getKey].getSearch;

        }//if

    }//for 






    //#############################################################
    //#############################################################

    //--------------------------
    // Catalog - Top
    //--------------------------

    R.push({

        call:'top',
        name:categoryName, // Created from pathname
        controls:[

            // {

            //     name:"Create Template",
            //     // form:{

            //     //     submitCall:'calculate',
            //     //     postTo:apiDomain+apiCall,
            //     //     data:newTemplateExpenseForm,
            //     //     flyr:{

            //     //         hashbang:"#!new",
            //     //         type:"form",
            //     //         styl:{
            //     //             maxWidth:500,
            //     //             fieldType:'composed'   //multi,field,labelfield
            //     //         }

            //     //     }   

            //     // }

            // }

        ]

    });













    //#############################################################
    //#############################################################


    //----------------------------
    // Catalog - Summary
    //----------------------------

    var summaryItems;

    var hourly_direct_rates, hours; 
    var totals;

    var Direct_Hours_Daily;
    var Direct_Hours_Weekly;
    var Direct_Labor_Rate_High;
    var Direct_Labor_Rate_Low;

    var Salary;
    var Taxes;
    var IRA;
    var Healthcare;
    var Total_Indirect;




    var isOkay;
    isOkay = false;
    if(zoneObj.calc_dash 
    && zoneObj.calc_dash !== undefined
    )
    {
        if(Object.keys(zoneObj.calc_dash.form_items).length > 0
        && Object.keys(zoneObj.calc_dash.result_items).length > 0
        )
        {
            isOkay = true;
        }

    }
    if(isOkay)
    {

        // "hourly_direct_rates": {
        //     "ownr_average": "33.28",
        //     "empl_average": "21.96",
        //     "empl_lowest": "21.96",
        //     "highest": "33.28",
        //     "ownr_lowest": "33.28",
        //     "lowest": "21.96",
        //     "empl_highest": "21.96",
        //     "ownr_highest": "33.28"
        //   },
        //   "hours": {
        //     "direct": {
        //       "total_ownr_daily": 4,
        //       "grand_total_daily": 8,
        //       "total_ownr_weekly": 20,
        //       "total_empl_daily": 4,
        //       "total_empl_weekly": 20,
        //       "grand_total_weekly": 40
        //     }
        //  },


        // "totals": {
        //     "total_ira": "97.40",
        //     "total_ownr_ira_monthly": "130.00",
        //     "total_weekly_hours": "40.00",
        //     "total_empl_direct_indirect_salary_yearly": "37440.00",
        //     "total_ownr_health_insurance_yearly": "12000.00",
        //     "total_empl_indirect_ira_monthly": "32.40",
        //     "total_expenses": "3740.00",
        //     "total_ownr_salary_pretax_monthly": "4333.33",
        //     "total_ownrs_indirect": "3773.33",
        //     "total_ownr_burden_monthly": "6546.67",
        //     "total": "9689.73",
        //     "total_ownrs": "8713.33",
        //     "total_health_insurance": "1200.00",
        //     "total_empl_health_insurance_monthly": "200.00",
        //     "total_empls": "4006.40",
        //     "total_empl_direct_indirect_salary_monthly": "3120.00",
        //     "total_ownr_salary_pretax_yearly": "52000.00",
        //     "total_empl_indirect_tax_monthly": "324.00",
        //     "total_empl_health_insurance_yearly": "2400.00",
        //     "total_empl_direct_indirect_ira_yearly": "748.80",
        //     "total_ownr_ira_yearly": "1560.00",
        //     "total_empl_direct_indirect_tax_monthly": "624.00",
        //     "total_ownr_health_insurance_monthly": "1000.00",
        //     "total_empl_indirect_salary_monthly": "1620.00",
        //     "total_empl_direct_indirect_ira_monthly": "62.40",
        //     "total_ownr_taxes_monthly": "1083.33",
        //     "total_empl_burden_yearly": "48076.80",
        //     "total_empls_direct": "1830.00",
        //     "total_tax": "865.67",
        //     "total_empl_direct_ira_monthly": "30.00",
        //     "total_empl_direct_indirect_tax_yearly": "7488.00",
        //     "total_ownr_taxes_monthly_direct": "541.67",
        //     "total_ownr_taxes_monthly_indirect": "541.67",
        //     "total_ownr_ira_monthly_indirect": "65.00",
        //     "total_ownr_taxes_yearly": "13000.00",
        //     "total_empl_burden_monthly": "4006.40",
        //     "total_ownr_salary_pretax_monthly_indirect": "2166.67",
        //     "total_ownr_burden_yearly": "78560.00",
        //     "total_empls_indirect": "2176.40",
        //     "total_ownrs_direct": "4940.00",
        //     "total_empl_direct_salary_monthly": "1500.00",
        //     "total_empl_direct_tax_monthly": "300.00",
        //     "total_ownr_ira_monthly_direct": "65.00"
        //   }


        hourly_direct_rates = zoneObj.calc_dash.result_items['hourly_direct_rates'];
        hours = zoneObj.calc_dash.result_items['hours'];

        totals = zoneObj.calc_dash.result_items['totals'];

        console.log("hourly_direct_rates: "+JSON.stringify(hourly_direct_rates,null,2));
        console.log("hours: "+JSON.stringify(hours,null,2));

    }


    


    //=================================================
    // Summary

    switch(categoryName)
    {
    case'Owners':

        Direct_Hours_Daily          = "0";
        Direct_Hours_Weekly         = "0";
        Direct_Labor_Rate_High      = "$0.00";
        Direct_Labor_Rate_Low       = "$0.00";

        Salary                      = "$0.00";
        Taxes                       = "$0.00";
        IRA                         = "$0.00";
        Healthcare                  = "$0.00";
        Total_Indirect              = "$0.00";

        if(isOkay)
        {
            Direct_Hours_Daily          = hours.direct.total_ownr_daily;
            Direct_Hours_Weekly         = hours.direct.total_ownr_weekly;
            Direct_Labor_Rate_High      = "$"+hourly_direct_rates.ownr_highest;
            Direct_Labor_Rate_Low       = "$"+hourly_direct_rates.ownr_lowest;

            Salary                      = "$"+totals.total_ownr_salary_pretax_monthly_indirect;
            Taxes                       = "$"+totals.total_ownr_taxes_monthly_indirect;
            IRA                         = "$"+totals.total_ownr_ira_monthly_indirect;
            Healthcare                  = "$"+totals.total_ownr_health_insurance_monthly;
            Total_Indirect              = "$"+totals.total_ownrs_indirect;
        }



        // // var ownr_hours_weekly = "";
        // //var ownr_sti_yearly_direct = 0;
        // // var ownr_name = "";
        // // var ownr_sti_monthly_indirect = "0.00";
        // var ownr_labor_indirect = "N/A";
        // // var ownr_hours_indirect_percent_whole = "0.00";
        // var ownr_labor_percent_direct = "$0.00";
        // // var ownr_taxes_monthly_direct = "0.00";
        // // var ownr_ira_monthly_indirect = "0.00";
        // // var ownr_labor_percent = "0.00";
        // var ownr_ira_monthly = "$0.00";
        // // var ownr_vacation_weeks = "0";
        // // var ownr_ira_percent = "0.00";
        // // var ownr_sti_monthly_direct = "0.00";
        // // var ownr_hours_indirect_percent = "0.00";
        // var ownr_taxes_yearly = "$0.00";
        // // var ownr_sti_weekly_direct = "0.00";
        // var ownr_salary_pretax = "$0.00";
        // var ownr_flag = "";
        // // var ownr_taxrate_percent = "0.00";
        // var ownr_health_insurance_yearly = "$0.00";
        // // var ownr_indirect_hours_yearly = "0.00";
        // // var ownr_taxrate_reciprocal = "0.00";
        // // var ownr_salary_pretax_monthly = "0.00";
        // // var ownr_working_weeks = "0.00";
        // // var ownr_hours_direct_percent = "0.00";
        // // var ownr_taxrate_decimal = "0.30";
        // // var ownr_hours_indirect = "0.0";
        // // var ownr_sti = "0.00";
        // // var ownr_taxes_monthly_indirect = "0.00";
        // var ownr_ira_yearly = "$0.00";
        // // var ownr_ira_monthly_direct = "0.00";
        // var ownr_labor_direct = "N/A";
        // // var ownr_taxes_monthly = "0.00";
        // var ownr_labor_percent_indirect = "$0.00";
        // // var ownr_hours_direct_percent_whole = "0.00";
        // // var ownr_sti_weekly_indirect = "0.00";
        // // var ownr_billable_hours_weekly = "0.00";
        // // var ownr_ira_decimal = "0.00";
        // // var ownr_billable_hours_daily = "0.00";
        // // var ownr_st = "0.00";
        // var ownr_health_insurance_monthly = "$0.00";
        // // var ownr_sti_weekly = "0.00";
        // // var ownr_hours_direct = "0.0";
        // // var ownr_sti_hourly_direct_flag = "Okay";
        // // var ownr_flag_case = "greater";
        // var ownr_sti_hourly_direct = "$0.00";
        // // var ownr_salary_overhead = "0.00";
        // //var ownr_sti_yearly_indirect = 0;


        // var ownr_direct_hours_weekly = "$0.00";

        // //var ownr_total_hours_weekly = "0.0";

        // var ownr_yearly_total = "$0.00";






        // Direct Labor Summary
        //-----------------------------------
        // Report Box - Graphic and Ledger
        //-----------------------------------

        summaryItems = [
            
        
            {

                "print"		    :'bar',
                "name"		    :'Direct Labor Summary',
                "value"		    :"",
                "required"	    :"",
                "default"	    :""


            },
            {

                "print"		    :'spacer',
                "name"		    :'',
                "value"		    :{

                    backgroundColor:"transparent",
                    height:"20px"
                },
                "required"	    :"",
                "default"	    :""


            },
            {

                "print"		    :'report_box',
                "name"		    :'Report Box',
                "value"		    :[

                    {

                        boxCall:'graphic',
                        boxWidth:"35%",
                        boxHeight:250,

                        svgCall:"ownr_direct",
                        svgWidth:230,
                        svgHeight:230,

                    },




                    {
                        boxCall:'ledger',
                        boxWidth:"65%",
                        boxHeight:275,
                        value:[


                            {
                                name:"Direct Hours Daily",
                                value:Direct_Hours_Daily
                            },
                            {
                                name:"Direct Hours Weekly",
                                value:Direct_Hours_Weekly
                            },
                            {
                                name:"Direct Labor Rate High",
                                value:Direct_Labor_Rate_High
                
                            },
                            {
                                name:"Direct Labor Rate Low",
                                value:Direct_Labor_Rate_Low
                            }
                

                        ]

                    },



                    // {
                    //     boxCall:'ledger',
                    //     boxWidth:"50%",
                    //     boxHeight:275,
                    //     value:[


                    //         {
                    //             name:"Direct Hours Weekly",
                    //             value:"20hrs"
                    //         },
                    //         {
                    //             name:"Direct Hours Daily",
                    //             value:"5hrs"
                    //         },
                    //         {
                    //             name:"Highest Rate",
                    //             value:"$0.00"
                
                    //         },
                    //         {
                    //             name:"Lowest Rate",
                    //             value:"$0.00"
                    //         }
                


                    //     ]

                    // }



                ],
                "required"	    :"",
                "default"	    :""


            },
            {

                "print"		    :'spacer',
                "name"		    :'',
                "value"		    :{

                    backgroundColor:"transparent",
                    height:"25px"
                },
                "required"	    :"",
                "default"	    :""


            }
        ];



        R.push({

            call:"report",
            items:summaryItems

        });







        // Indirect Labor Totals
        //-----------------------------------
        // Report Box - Graphic and Ledger
        //-----------------------------------

        summaryItems = [
            
        
            {

                "print"		    :'bar',
                "name"		    :'Indirect Labor Totals',
                "value"		    :"",
                "required"	    :"",
                "default"	    :""


            },
            {

                "print"		    :'spacer',
                "name"		    :'',
                "value"		    :{

                    backgroundColor:"transparent",
                    height:"20px"
                },
                "required"	    :"",
                "default"	    :""


            },
            {

                "print"		    :'report_box',
                "name"		    :'Report Box',
                "value"		    :[

                    {

                        boxCall:'graphic',
                        boxWidth:"35%",
                        boxHeight:250,

                        svgCall:"ownr_indirect",
                        svgWidth:230,
                        svgHeight:230,

                    },




                    {
                        boxCall:'ledger',
                        boxWidth:"65%",
                        boxHeight:275,
                        value:[


                            {
                                name:"Indirect Monthly Salaries",
                                value:Salary
                            },
                            {
                                name:"Indirect Monthly Taxes",
                                value:Taxes
                            },
                            {
                                name:"Indirect Monthly IRA",
                                value:IRA
                
                            },
                            {
                                name:"Indirect Monthly Healthcare",
                                value:Healthcare
                            },
                
                            {
                                name:"Indirect Monthly Total",
                                value:Total_Indirect
                            }
                

                        ]

                    }




                ],
                "required"	    :"",
                "default"	    :""


            },
            {

                "print"		    :'spacer',
                "name"		    :'',
                "value"		    :{

                    backgroundColor:"transparent",
                    height:"25px"
                },
                "required"	    :"",
                "default"	    :""


            }
        ];


  
        R.push({

            call:"report",
            items:summaryItems

        });



    break;
    case'Employees':



        Direct_Hours_Daily          = "0";
        Direct_Hours_Weekly         = "0";
        Direct_Labor_Rate_High      = "$0.00";
        Direct_Labor_Rate_Low       = "$0.00";

        Salary                      = "$0.00";
        Taxes                       = "$0.00";
        IRA                         = "$0.00";
        Healthcare                  = "$0.00";
        Total_Indirect              = "$0.00";

        if(isOkay)
        {
            Direct_Hours_Daily          = hours.direct.total_empl_daily;
            Direct_Hours_Weekly         = hours.direct.total_empl_weekly;
            Direct_Labor_Rate_High      = "$"+hourly_direct_rates.empl_highest;
            Direct_Labor_Rate_Low       = "$"+hourly_direct_rates.empl_lowest;

            Salary                      = "$"+totals.total_empl_indirect_salary_monthly;
            Taxes                       = "$"+totals.total_empl_indirect_tax_monthly;
            IRA                         = "$"+totals.total_empl_indirect_ira_monthly;
            Healthcare                  = "$"+totals.total_empl_health_insurance_monthly;
            Total_Indirect              = "$"+totals.total_empls_indirect;
        }





        // var empl_vacation_weeks = "0";
        // var empl_direct_indirect_ira_monthly_total = "$0.00";
        // // var empl_burden_hours_yearly;
        // // var empl_name;
        // var empl_direct_hourly_rate = "$0.00";
        // var empl_labor_direct = "N/A";
        // var empl_labor_percent_indirect = "$0.00";
        // // var empl_tax_hourly;
        // // var empl_indirect_ira_yearly;
        // var empl_hours_indirect = "0";
        // var empl_hours_direct = "0";
        // //var empl_yearly_salary_w_health_insurance = "0.00";
        // // var empl_tax_comp_percent;
        // // var empl_direct_ira_yearly;
        // // var empl_direct_hours_yearly;
        // // var empl_indirect_hours_yearly;
        // // var empl_working_weeks;
        // // var empl_labor_percent;
        // // var empl_health_insurance_weekly;
        // // var empl_indirect_salary_monthly;
        // var empl_direct_indirect_ira_yearly_total = "$0.00";
        // var empl_direct_indirect_salary_yearly_total = "$0.00";
        // var empl_health_insurance_monthly = "$0.00";
        // // var empl_direct_salary_monthly;
        // // var empl_indirect_ira_monthly;
        // // var empl_direct_tax_yearly;
        // // var empl_billable_hours_daily;
        // var empl_labor_percent_direct = "$0.00";
        // // var empl_direct_salary_yearly;
        // var empl_hourly_rate = "$0.00";
        // // var empl_indirect_salary_yearly;
        // // var empl_direct_tax_monthly;
        // // var empl_direct_labor_decimal;
        // var empl_health_insurance_yearly = "$0.00";
        // // var empl_ira_decimal;
        // // var empl_burden_monthly_total;
        // // var empl_hours_weekly;
        // // var empl_ira_percent;
        // var empl_labor_indirect = "N/A";
        // // var empl_health_insurance_hourly;
        // var empl_billable_hours_weekly = '0';
        // // var empl_ira_hourly;
        // // var empl_direct_indirect_salary_monthly_total;
        // var empl_burden_yearly_total = "$0.00"
        // // var empl_indirect_hourly_rate;
        // var empl_direct_indirect_tax_yearly_total = "$0.00";
        // // var empl_indirect_tax_monthly;
        // // var empl_direct_ira_monthly;
        // // var empl_indirect_tax_yearly;
        // // var empl_direct_indirect_tax_monthly_total;
        // // var empl_tax_comp_decimal;
        
        
        // var empl_total_hours_weekly = "0.0";






        //Direct Labor Summary
        //-----------------------------------
        // Report Box - Graphic and Ledger 
        //-----------------------------------

        summaryItems = [
        
        
            {

                "print"		    :'bar',
                "name"		    :'Direct Labor Summary',
                "value"		    :"",
                "required"	    :"",
                "default"	    :""


            },
            {

                "print"		    :'spacer',
                "name"		    :'',
                "value"		    :{

                    backgroundColor:"transparent",
                    height:"20px"
                },
                "required"	    :"",
                "default"	    :""


            },
            {

                "print"		    :'report_box',
                "name"		    :'Report Box',
                "value"		    :[

                    {

                        boxCall:'graphic',
                        boxWidth:"35%",
                        boxHeight:250,

                        svgCall:"empl_direct",
                        svgWidth:230,
                        svgHeight:230,

                    },

                    {
                        boxCall:'ledger',
                        boxWidth:"65%",
                        boxHeight:275,
                        value:[

                            {
                                name:"Direct Hours Daily",
                                value:Direct_Hours_Daily
                            },
                            {
                                name:"Direct Hours Weekly",
                                value:Direct_Hours_Weekly
                            },
                            {
                                name:"Direct Labor Rate High",
                                value:Direct_Labor_Rate_High
                
                            },
                            {
                                name:"Direct Labor Rate Low",
                                value:Direct_Labor_Rate_Low
                            }

                        ]

                    }



                ],
                "required"	    :"",
                "default"	    :""


            },
            {

                "print"		    :'spacer',
                "name"		    :'',
                "value"		    :{

                    backgroundColor:"transparent",
                    height:"25px"
                },
                "required"	    :"",
                "default"	    :""


            }

        ];



        R.push({

            call:"report",
            items:summaryItems

        });






        // Indirect Labor Summary
        //-----------------------------------
        // Report Box - Graphic and Ledger
        //-----------------------------------

        summaryItems = [
        
        
            {

                "print"		    :'bar',
                "name"		    :'Indirect Labor Totals',
                "value"		    :"",
                "required"	    :"",
                "default"	    :""


            },
            {

                "print"		    :'spacer',
                "name"		    :'',
                "value"		    :{

                    backgroundColor:"transparent",
                    height:"20px"
                },
                "required"	    :"",
                "default"	    :""


            },
            {

                "print"		    :'report_box',
                "name"		    :'Report Box',
                "value"		    :[

                    {

                        boxCall:'graphic',
                        boxWidth:"35%",
                        boxHeight:250,

                        svgCall:"empl_indirect",
                        svgWidth:230,
                        svgHeight:230,

                    },

                    {
                        boxCall:'ledger',
                        boxWidth:"65%",
                        boxHeight:275,
                        value:[

                            {
                                name:"Indirect Monthly Salaries",
                                value:Salary
                            },
                            {
                                name:"Indirect Monthly Taxes",
                                value:Taxes
                            },
                            {
                                name:"Indirect Monthly IRA",
                                value:IRA
                
                            },
                            {
                                name:"Indirect Monthly Healthcare",
                                value:Healthcare
                            },
                
                            {
                                name:"Indirect Monthly Total",
                                value:Total_Indirect
                            }
   

                        ]

                    }



                ],
                "required"	    :"",
                "default"	    :""


            },
            {

                "print"		    :'spacer',
                "name"		    :'',
                "value"		    :{

                    backgroundColor:"transparent",
                    height:"25px"
                },
                "required"	    :"",
                "default"	    :""


            }

        ];



        R.push({

            call:"report",
            items:summaryItems

        });


    break;
    default:
    }
















    //#############################################################
    //#############################################################


    if(getSearch === 'calculator-')
    {


        //--------------------------
        // Catalog - Bar
        //--------------------------

        R.push({

            call:'barNameGrid',
            items: [

                {

                    name:"Name"

                },

                {

                    name:"Price"

                },

                {

                    name:"Profit"

                }



            ]
            
        });




    }else{

        //--------------------------
        // Catalog - Bar
        //--------------------------

        R.push({

            call:'barLineItem',
            items: [

                {

                    name:categoryName

                },

                {

                    name:"Actions"

                }

            ]
            
        });


    }





    //#############################################################
    //#############################################################


    //----------------------------
    // Catalog - Items
    //----------------------------

    var catalogItems = [];



    //==================================================
    // Lines
    
    if(getSearch)
    {

        console.log("SubCategory SceneInfo - getSearch: "+getSearch);

        if(mainCategoryName)
        {

            console.log("Main: "+getSearch);

            if(mainCategoryName.toLowerCase() !== getSearch)
            {

                regxSearch = new RegExp(getSearch,"gi");
                array = [];

                if(zoneObj.calc_dash && zoneObj.calc_dash !== undefined)
                {
                    for(keyname in zoneObj.calc_dash.form_items)
                    {
                        if(keyname.match(regxSearch))
                        {
                            //console.log(keyname);

                            array.push({

                                keyname:keyname,
                                name: zoneObj.calc_dash.form_items[keyname].name
                            })

                        }

                    }//for


                }//==

            }

        }
        else
        if(categoryName.toLowerCase() !== getSearch)
        {

            console.log("GetSubs");

            //console.log("zoneObj: "+JSON.stringify(zoneObj,null,2));
            //console.log("zoneObj.calc_dash: "+JSON.stringify(zoneObj.calc_dash,null,2));

            //console.log("zoneObj.calcs: "+JSON.stringify(zoneObj.calcs,null,2));

            if(getSearch === 'calculator-')
            {

                isCalc = true;

                regxSearch = new RegExp(getSearch,"gi");
                array = [];

                if(zoneObj.calcs && zoneObj.calcs !== undefined)
                {
                    for(keyname in zoneObj.calcs)
                    {

                        myCalculation = Calculation({

                            zoneObj:zoneObj,
                            categoryName:zoneObj.calcs[keyname].data.name

                        });
                        console.log(zoneObj.calcs[keyname].data.name + " - myCalculation: "+JSON.stringify(myCalculation,null,2));

                        array.push({

                            keyname:keyname,
                            name: zoneObj.calcs[keyname].data.name,
                            price:myCalculation.selling_price.total,
                            profit:myCalculation.price_percent.profit,
                            profit_percent:myCalculation.price_percent.profit_percent+"%",

                        })


                    }//for

                }//==


                for(value of array)
                {
                    catalogItems.push({
    
                        keyname         :value.keyname,
                        category        :value.name,
                        price           :value.price,
                        profit          :value.profit,
                        profit_percent  :value.profit_percent
    
                    });
                }

           

            }else{


                regxSearch = new RegExp(getSearch,"gi");
                array = [];

                if(zoneObj.calc_dash && zoneObj.calc_dash !== undefined)
                {
                    for(keyname in zoneObj.calc_dash.form_items)
                    {
                        if(keyname.match(regxSearch))
                        {
                            //console.log(keyname);

                            array.push({

                                keyname:keyname,
                                name: zoneObj.calc_dash.form_items[keyname].name
                            })

                        }

                    }//for


                }//==



                for(value of array)
                {
                    catalogItems.push({
    
                        keyname     :value.keyname,
                        category    :value.name
    
                    });
                }

           
            }




        }


    }else{


        if(VPreportList && VPreportList !== undefined)
        {
            array = [];
            for(keyname in VPreportList)
            {
                if(categoryCount > 0)
                {
                    groupName = categoryName.replace(/ /gi,"_");
                    //groupName = groupName.toLowerCase();

                    if(keyname === groupName)
                    {
                        group = VPreportList[groupName];
                        for(item of group)
                        {
                            if(!LibArray.InArray(item.name,array))
                            {
                                array.push(item.name);

                            }
                        }

                    }


                }else{

                    group = VPreportList['main'];
                    for(item of group)
                    {
                        if(!LibArray.InArray(item.name,array))
                        {
                            array.push(item.name);
                        }
                    }
                }

            }

            //alert(JSON.stringify(array));

            //array.sort();
            for(value of array)
            {
                catalogItems.push({

                    keyname:value,
                    category:value

                });
            }

        }



    }//==





    if(isCalc)
    {

        //console.log("isCalc");

        R.push({

            call:"calcItem",
            items:catalogItems

        });

    }else{

        R.push({

            call:"itemsLineItem",
            items:catalogItems

        });

    }



    return R;





}



export default SceneInfo;
