import {
    //LibArray,
    LibDate
} from 'library';

import Merge from './Merge';

const Handler = (props) => {


    var array;
    var object,obj,value;

    var cog;
    var good;

    var idarr;
    var column;
    var call;

    var ij;
    var indx;

    var scrollbox;
    var scrollpos;

    var newArr;


    var i,j;

    // var min,max;

    var inum,jnum;
    var frm;


    var debits;
    var debit;

    // var index;
    // var captured;


    var name,rate;


    var timestamp, randnum, new_recid;







    switch(props.call)
    {
    case'button':


        //console.log(props.actionCall);

        switch(props.actionCall)
        {
        case"options":


            if(props.show)
            {
                props.handleState({call:'hashData',val:"#!"});
                props.handleState({call:'showOptions',val:true});
        
                if(props.location.hash !== "#!")
                { 
                    //history.push("#!");
                    props.handleRoute({call:'push',val:"#!"});

                }

            }
            else //hide
            {

                newArr = Merge({

                    from      :props.actionCall,
                    formArr   :props.formArr,
                    captured  :props.captured
        
                });
        

                if(props.action === 'capture')
                {


                    console.log("Options");
                    console.log(JSON.stringify(props,null,2));
                    console.log(""
                    
                        +"\nkey: "+props.key
                        +"\nid: "+props.id
                        +"\nvalue: "+props.value
                    )

                
                    ij = props.key.split("-");
                    indx = ij[1];

                    idarr = props.id.split("_");
                    column = idarr[0];
                    call = idarr[1];

                    if(column === 'options')
                    {
                        props.captured['options']['data'][call]['value'] = props.value;

                    }//==

                    console.log(JSON.stringify(props.captured['options'],null,2));




                }
                else
                {

                    scrollbox = document.getElementById('scrollbox');
                    scrollpos = scrollbox.scrollTop;
            
                    if(props.location.hash === "#!")
                    {
                        //history.goBack(); 
                        props.handleRoute({call:"goBack",val:""});
                    }
            
                    props.handleState({call:'hashData',val:false});
                    props.handleState({call:'processSubmit',val:false});
                    props.handleState({call:'showOptions',val:false});
        
                    scrollbox.scrollTop = scrollpos;
            
                    if(props.action === 'save')
                    {
                        //alert('save');
                        props.captured['options']['active'] = 1;
                        props.handleState({call:'activeOptions',val:1});
                    }else{
                        props.handleState({call:'activeOptions',val:0});
                    }
                }
            }
            

        break;
        case'submit':


            newArr = Merge({

                from      :props.actionCall,
                formArr   :props.formArr,
                captured  :props.captured

            });
            props.handleState({call:'runDispatch',val:true});//sets pricing_CalcInputs to zoneObj

            //-------------------------------------------------
            // add to captured

            // console.log("props.formArr: "+JSON.stringify(props.formArr,null,2));
            // ///console.log("props.captured: "+JSON.stringify(props.captured,null,2));
            // alert('submit - check captured');


            // SEE: _ref documents within this folder


            var calcby = document.getElementById('calcby').value;
            var overhead = document.getElementById('overhead').value;

            props.captured.calcby.value = calcby;
            props.captured.overhead.value = overhead;




            var targetId;
            //var rate;
            var qtyhrs;
       
            for(object of props.formArr)
            {
                switch(object.section)
                {
                case'costfields':

                    for(obj of object.data)
                    {
                        targetId = obj.rate.id.split("_")[1];

                        // var rateElem = document.getElementById(obj.rate.id);
                        // alert("isLabor: "+rateElem.isLabor);

                        rate = document.getElementById(obj.rate.id).value;
                        qtyhrs = document.getElementById(obj.qtyhrs.id).value;

                        array = props.captured.cost_of_goods;
                        for(i=0; i < array.length; i++)
                        {
                            if(array[i].call === targetId)
                            {
                                props.captured.cost_of_goods[i].rate = rate;
                                props.captured.cost_of_goods[i].qtyhrs = qtyhrs;
                                //props.captured.cost_of_goods[i].isLabor = isLabor;
                                break;
                            }
                        }

                    }//endfor

                break;
                case'bluntfields':


                    for(obj of object.data)
                    {
                        targetId = obj.field.id;
                        value = document.getElementById(obj.field.id).value;
                     
                        array = props.captured.debits;
                        for(i=0; i < array.length; i++)
                        {
                            if(array[i].id === targetId)
                            {
                                props.captured.debits[i].value = value;
                                break;
                            }
                        }

                    }//endfor



                break;
                default:
                }

            }//endfor


            // console.log("props.captured: "+JSON.stringify(props.captured,null,2));
            // alert('check captured');

            props.handleState({call:'captured',val:props.captured});

            //-------------------------------------------------
      
            //props.handleState({call:'hashData',val:"#!"});
            props.handleState({call:'processSubmit',val:true});

     
            //console.log("LOCATION: "+JSON.stringify(location,null,2));
            //console.log("HISTORY: "+JSON.stringify(history,null,2));

            if(props.location.hash !== "#!") 
            {
                var query = props.location.search;
                //props.history.push("#!");
                props.handleRoute({call:"push",val:query+"#!Calculator~readout"});

            }

        
        break;
        default:

            alert('button');
        break;
        }//==


    break;
    case'calcby':

        if(props.captured['calcby']['call'] 
        && props.captured['calcby']['call'] !== undefined)
        {
            props.captured['calcby']['call'] = props.choice;
        }//==

        newArr = Merge({

            from      :props.call,
            formArr   :props.formArr,
            captured  :props.captured

        });

        //setFormArr(newArr);
        //setCalcby(props.choice);


        // console.log("calcby newArr -- "+JSON.stringify(newArr,null,2));
        // alert('handler - calcby');

        props.handleState({call:'formArr',val:newArr});
        props.handleState({call:'calcby',val:props.choice});
        //props.handleState({call:'runDispatch',val:true});

    break;
    case'capture':


        switch(props.fieldCall)
        {
        case'costfield':

            
            // console.log("Cost Field");
            // console.log(JSON.stringify(v,null,2));
            // console.log(""
            
            //     +"\nkey: "+props.key
            //     +"\nid: "+props.id
            //     +"\nvalue: "+props.value
            // )
            

            ij = props.key.split("-");
            indx = ij[1];

            idarr = props.id.split("_");
            column = idarr[0];
            call = idarr[1];


            //console.log("column: "+column+"\ncall: "+call+"\nvalue: "+props.value);

            
            if(props.captured['cost_of_goods'] 
            && props.captured['cost_of_goods'] !== undefined)
            {
                cog = props.captured['cost_of_goods'];

                //alert(LibBase.ObjectType(cog));
                //console.log(JSON.stringify(cog,null,2));

                for(good of cog)
                {
                    if(good['call'] === call)
                    {
                        good[column] = props.value;
                    }

                }//==
                
                //console.log(JSON.stringify(cog,null,2));
                props.captured['cost_of_goods'] = cog;

            }//==

            //alert('cog: '+cog)


        break;
        case'bluntfield':

            
            // console.log("Blunt Field");
            // console.log(JSON.stringify(props.id,null,2));
            // console.log(""
            
            //     +"\nkey: "+props.key
            //     +"\nid: "+props.id
            //     +"\nvalue: "+props.value
            // );

            // alert('bluntfield');
            

            ij = props.key.split("-");
            indx = ij[1];

            if(props.captured[props.id] 
            && props.captured[props.id] !== undefined)
            {

                if(Object.prototype.toString.call(props.captured[props.id]) === '[object Object]') 
                {
                    props.captured[props.id]['value'] = props.value;
    
                    console.log(Object.prototype.toString.call(props.captured[props.id]));
                    console.log(JSON.stringify(props.captured[props.id]));

                }//== 
        

            }else{

                array = [];
                debits = props.captured['debits'];
                for(debit of debits)
                {
                    if(debit.id === props.id)
                    {
                        debit.value = props.value;

                    }
                    array.push(debit);
                }
                props.captured['debits'] = array;

          
            }//==

        break;
        default:
        }//==

        newArr = Merge({

            from      :props.call,
            formArr   :props.formArr,
            captured  :props.captured

        });
        props.handleState({call:'formArr',val:newArr});

    break;
    case'add':

        
        // alert('add');
        // alert("\n"+props.data.index+"\n"+props.data.fieldCall);

        switch(props.data.fieldCall)
        {
        case'costfield':

            var isLabor = false;
            var laborId = false;

            switch(props.data.type)
            {
            case'labor':

                name = "Labor-"+props.data.name;
                rate = props.data.value;
                isLabor = true;
                laborId = props.data.id;
            break;
            default:

                name = "Good";
                rate = "0.00";
            }//type



            // alert(""

            //     +"\nAdd Costfield"

            //     +"\nprops.data.index: "+props.data.index
            //     +"\nprops.data.fieldCall: "+props.data.fieldCall
            //     +"\nprops.data.type: "+props.data.type
            //     +"\nprops.data.name: "+props.data.name
            //     +"\nprops.data.value: "+props.data.value

            //     +"\nprops.formArr: "+props.formArr.length
            //     +"\nprops.captured: "+props.captured.cost_of_goods.length

            //     +"\nname: "+name
            //     +"\nrate: "+rate

            //     +"\nisLabor: "+isLabor
            //     +"\nlaborId: "+laborId

            // );
            // return false;


            // Add New tabIndex here - update on Merge function


            var tabIndex = false;
            var prevObj;
            var dataObj;
            var lastFieldIndex = false;

            prevObj = props.formArr[parseInt(props.data.index)-1];
            if(prevObj.data)
            {
                if(Object.prototype.toString.call(prevObj.data) === '[object Array]')
                {
                    lastFieldIndex = parseInt(prevObj.data.length)-1;
                    dataObj = prevObj.data[lastFieldIndex];
                    if(dataObj.qtyhrs)
                    {
                        tabIndex = dataObj.qtyhrs.tabIndex;
                        tabIndex = parseInt(tabIndex) + 1;
                    }
                    // console.log(""
                    //    +"\ndataObj  : "+JSON.stringify(dataObj,null,2)
                    //    +"\ntabIndex : "+tabIndex
                    // );

                    // alert("Check costfield - prev dataObj");

                
                }

            }


            if(tabIndex)
            {

                new_recid = 99;
                for(i=0; i < props.formArr.length; i++)
                {
                    frm = props.formArr[i];
                    //console.log("add--data---"+JSON.stringify(frm.data,null,2));


                    if(i === parseInt(props.data.index)-1)
                    {
                    
                        //----------------------------------------------------------
            
                        timestamp = LibDate.Timestamp('noformat');
                        randnum = Math.floor(Math.random() * 9000 + 1000);

                        new_recid = parseInt(timestamp) + randnum;
                        new_recid = new_recid.toString();
                        new_recid = new_recid.replace(/-/g,'');


                        if(name.toLowerCase() === "good") name = name+" "+new_recid
                        //-----------------------------------------------------------
                        
                        frm.data.push({

                            "label": {
                                "text": name
                            },
                            "rate": {
                                "fieldtype": "text",
                                "required": false,
                                "class": "",
                                "id": "rate_good-"+new_recid,
                                "hint": "",
                                "text": rate,
                                "linecolor": "yellow",
                                "isLabor":isLabor,
                                "keyboardType": "",
                                "autocorrect": "false",
                                "autocapitalizationType": "none",
                                "tabIndex":tabIndex
                            },
                            "qtyhrs": {
                                "fieldtype": "text",
                                "required": false,
                                "class": "",
                                "id": "qtyhrs_good-"+new_recid,
                                "hint": "",
                                "text": "0.0",
                                "keyboardType": "",
                                "autocorrect": "false",
                                "autocapitalizationType": "none",
                                "tabIndex":tabIndex + 1
                            }

                        });



                    }

                    props.formArr[i] = frm;
                }
        
                //console.log("formArr---"+JSON.stringify(props.formArr,null,2));




                if(props.captured['cost_of_goods'] 
                && props.captured['cost_of_goods'] !== undefined)
                {

                    cog = props.captured['cost_of_goods'];
                    cog.push({

                        'call'      :'good-'+new_recid, 
                        'title'     :name, 
                        'rate'      :parseFloat(rate).toFixed(2), 
                        'qtyhrs'    :0.0,  
                        'isLabor'   :isLabor,
                        'laborId'   :laborId,
                        'other'     :{'label':'--', 'data':{'brand':'','name':''}},

                    });
                    //console.log(JSON.stringify(cog,null,2));
                    props.captured['cost_of_goods'] = cog;

                }//==


            }//okay




        break;
        case'bluntfield':


            new_recid = 99;
            for(i=0; i < props.formArr.length; i++)
            {
                frm = props.formArr[i];

                // console.log(props.data.index);     
                // console.log("add--bluntfield---"+JSON.stringify(frm.data,null,2));

                if(i === parseInt(props.data.index)-1)
                {

                    // console.log(props.data.index);   
                    // console.log("add--object type---"+Object.prototype.toString.call(frm.data));
                    // console.log("add--bluntfield---"+JSON.stringify(frm.data,null,2));
    
                    //----------------------------------------------------------
          
                    timestamp = LibDate.Timestamp('noformat');
                    randnum = Math.floor(Math.random() * 9000 + 1000);

                    new_recid = parseInt(timestamp) + randnum;
                    new_recid = new_recid.toString();
                    new_recid = new_recid.replace(/-/g,'');

                    //-----------------------------------------------------------

                    frm.data.push({
                        "display": "percent",
                        "label": {
                          "text": "Debit "+new_recid
                        },
                        "field": {
                          "fieldtype": "percent",
                          "required": false,
                          "class": "",
                          "id": "debit-"+new_recid,
                          "hint": "",
                          "text": "0.0",
                          "linecolor": "orange",
                          "keyboardType": "",
                          "autocorrect": "false",
                          "autocapitalizationType": "none"
                        }
                    });


                }


                props.formArr[i] = frm;
            }

            //console.log("formArr---"+JSON.stringify(props.formArr,null,2));




            if(props.captured['debits'] 
            && props.captured['debits'] !== undefined)
            {

                debits = props.captured['debits'];
                inum = parseInt(debits.length) + 1;
                debits.push({

                    'id'        :'debit'+inum,    
                    'title'     :'Debit '+inum, 
                    'value'     :0.0

                });
                props.captured['debits'] = debits;

            }//==



        break;
        default:
        }//==


        // console.log("formArr:"+JSON.stringify(props.formArr,null,2));
        // console.log("captured:"+JSON.stringify(props.captured,null,2));
        // alert("check add");


        newArr = Merge({

            from      :props.call,
            formArr   :props.formArr,
            captured  :props.captured

        });
        props.handleState({call:'formArr',val:newArr});
        props.handleState({call:'runDispatch',val:true});




        //-------------------
        //close LaborMenu
        //-------------------

        if(props.data.fieldCall === 'costfield' && props.data.type === 'labor')
        {
            if(props.location.hash === "#!Calculator~labormenu")
            { 
                props.handleState({call:'showLaborMenu',val:false});
                //history.goBack(); 
                props.handleRoute({call:"goBack",val:""});

                props.handleState({call:'reposScrollbox',val:{

                    scrollpos:props.data.scrollpos
                    
                }});

            }
        }


    break;
    case'edit':




        switch(props.from)
        {
        case'laborMenu':


            ij = props.data.index.split("-");
            inum = ij[0];
            jnum = ij[1];


            // index:props.data.index,
            // fieldCall:'costfield',
            // type:'labor',
            // name:cell.name,
            // value:cell.value,
            // scrollpos:props.data.scrollpos


            switch(props.data.fieldCall)
            {
            case'costfield':

                // console.log(JSON.stringify(props.data,null,2));
                // alert('labor edit costfield');

    

                for(i=0; i < props.formArr.length; i++)
                {
                    frm = props.formArr[i];
                    //newArr = [];
                    if(parseInt(i) === parseInt(inum)
                    )
                    {   
                        //console.log('edit 1');
                        for(j=0; j < frm.data.length; j++)
                        {
                            //console.log('edit 2');
                            if(parseInt(j) === parseInt(jnum))
                            {

                                // console.log(JSON.stringify(JSON.parse(JSON.stringify(frm.data[j])),null,2));   
                                // alert('edit 3')

                                frm.data[j].label.text = "Labor-"+props.data.name;
                                frm.data[j].rate.text = props.data.value;
                                //newArr.push(frm.data[j]); 
                                //console.log("j---"+j)
                            }
                        }
                

                    }
                    //frm.data = newArr; 
                    props.formArr[i] = frm;
                }
        
                //console.log("formArr---"+JSON.stringify(props.formArr,null,2));




                if(props.captured['cost_of_goods'] 
                && props.captured['cost_of_goods'] !== undefined)
                {

                    array = [];
                    cog = props.captured['cost_of_goods'];
            
                    //alert(LibBase.ObjectType(cog));
                    //console.log(JSON.stringify(cog,null,2));

                    props.captured['cost_of_goods'] = [];
                    for(i=0; i < cog.length; i++)
                    {
                        //console.log(i+" ==== "+jnum);
                        good = cog[i];  
                        if(parseInt(i) === parseInt(jnum)
                        )
                        {
                            good.title = "Labor-"+props.data.name;
                            good.rate = props.data.value;
                            good.laborId = props.data.id;

                            //alert(good);
                        }
                        array.push(good);

                    }
                    //console.log(JSON.stringify(cog,null,2));
                    props.captured['cost_of_goods'] = array;
                    

                }//==
                
            
            break;
            case'bluntfield':
            break;
            default:
            }//==


            props.handleState({call:'showLaborMenu',val:false});
            //history.goBack(); 
            props.handleRoute({call:"goBack",val:""});


        break;
        default:


            ij = props.key.split("-");
            inum = ij[0];
            jnum = ij[1];

            switch(props.fieldCall)
            {
            case'costfield':


                //console.log(JSON.stringify(props,null,2));
                //alert('real edit costfield');

                for(i=0; i < props.formArr.length; i++)
                {
                    frm = props.formArr[i];
                    //newArr = [];
                    if(parseInt(i) === parseInt(inum)
                    )
                    {   
                        //console.log('edit 1');
                        for(j=0; j < frm.data.length; j++)
                        {
                            //console.log('edit 2');
                            if(parseInt(j) === parseInt(jnum))
                            {
                                //console.log('edit 3')
                                frm.data[j].label.text = props.value;
                                //newArr.push(frm.data[j]); 
                                //console.log("j---"+j)
                            }
                        }
                
    
                    }
                    //frm.data = newArr; 
                    props.formArr[i] = frm;
                }
        
                //console.log("formArr---"+JSON.stringify(props.formArr,null,2));




                if(props.captured['cost_of_goods'] 
                && props.captured['cost_of_goods'] !== undefined)
                {

                    array = [];
                    cog = props.captured['cost_of_goods'];
            
                    //alert(LibBase.ObjectType(cog));
                    //console.log(JSON.stringify(cog,null,2));

                    props.captured['cost_of_goods'] = [];
                    for(i=0; i < cog.length; i++)
                    {
                        //console.log(i+" ==== "+jnum);
                        good = cog[i];  
                        if(parseInt(i) === parseInt(jnum)
                        )
                        {
                            good.title = props.value
                            //console.log(good);
                        }
                        array.push(good);

                    }
                    //console.log(JSON.stringify(cog,null,2));
                    props.captured['cost_of_goods'] = array;
                    

                }//==
                
            
            break;
            case'bluntfield':
            break;
            default:
            }//==



        }




        // console.log("formArr:"+JSON.stringify(props.formArr,null,2));
        // console.log("captured:"+JSON.stringify(props.captured,null,2));
        // alert("check edit");



        newArr = Merge({

            from      :props.call,
            formArr   :props.formArr,
            captured  :props.captured

        });
        props.handleState({call:'formArr',val:newArr});
        props.handleState({call:'runDispatch',val:true});



    break;
    case'delete':


        ij = props.key.split("-");
        inum = ij[0];
        jnum = ij[1];

        //alert(jnum);


        console.log(inum+"\n"+jnum);


        switch(props.fieldCall)
        {
        case'costfield':


            //alert(props.fieldCall);

            // console.log("----"+JSON.stringify(props.formArr,null,2));

            for(i=0; i < props.formArr.length; i++)
            {
                frm = props.formArr[i];
                if(parseInt(i) === parseInt(inum)
                )
                {   
                    if(frm.data.length > 1)
                    {
                        // newArr = [];
                        // for(j=0; j < frm.data.length; j++)
                        // {
                        //     if(parseInt(j) !== parseInt(jnum))
                        //     {
                        //         newArr.push(frm.data[j]); 
                        //         console.log("j---"+j)
                        //     }
                        // }
                        // frm.data = newArr;

                        frm.data.splice(jnum,1);

                    }

                }
                props.formArr[i] = frm;
            }
      
            //console.log("formArr---"+JSON.stringify(props.formArr,null,2));



            if(props.captured['cost_of_goods'] 
            && props.captured['cost_of_goods'] !== undefined)
            {

                array = [];
                cog = props.captured['cost_of_goods'];
          
                //alert(LibBase.ObjectType(cog));
                //console.log(JSON.stringify(cog,null,2));

                if(cog.length > 1)
                {
                   // props.captured['cost_of_goods'] = [];
                    // for(i=0; i < cog.length; i++)
                    // {
                    //     //console.log(i+" ==== "+jnum);
                    //     good = cog[i];  
                    //     if(parseInt(i) !== parseInt(jnum)
                    //     )
                    //     {
                    //         //console.log(good);
                    //         array.push(good);
                    //     }
                    // }

                    cog.splice(jnum,1);


                    //console.log(JSON.stringify(cog,null,2));
                    props.captured['cost_of_goods'] = cog;
                }


            }//==
                

        break;
        case'bluntfield':




            //alert(props.fieldCall);

            // console.log("----"+JSON.stringify(props.formArr,null,2));

            // for(i=0; i < props.formArr.length; i++)
            // {
            //     frm = props.formArr[i];
            //     if(parseInt(i) === parseInt(inum)
            //     )
            //     {   
            //         if(frm.data.length > 1)
            //         {
            //             newArr = [];
            //             for(j=0; j < frm.data.length; j++)
            //             {
            //                 if(parseInt(j) !== parseInt(jnum))
            //                 {
            //                     newArr.push(frm.data[j]); 
            //                     //console.log("j---"+j)
            //                 }
            //             }
            //             frm.data = newArr;

            //         }

            //     }
            //     props.formArr[i] = frm;
            // }
      
            // //console.log("formArr---"+JSON.stringify(props.formArr,null,2));




            // if(props.captured['debits'] 
            // && props.captured['debits'] !== undefined)
            // {

            //     array = [];
            //     debits = props.captured['debits'];
        
            //     console.log(JSON.stringify(debits,null,2));    
            //     alert("delete debits");

            //     if(debits.length > 1)
            //     {
            //         props.captured['debits'] = [];
            //         for(i=0; i < debits.length; i++)
            //         {
            //             console.log(i+" ==== "+jnum);
            //             debit = debits[i];  
            //             if(parseInt(i) !== parseInt(jnum)
            //             )
            //             {
            //                 array.push(debit);
            //             }
            //         }
            //         //console.log(JSON.stringify(debits,null,2));
            //         props.captured['debits'] = array;
            //     }


            // }//==



        break;
        default:
        }//==

        props.handleState({call:'formArr',val:props.formArr});
        props.handleState({call:'captured',val:props.captured});

        newArr = Merge({

            from      :props.call,
            formArr   :props.formArr,
            captured  :props.captured

        });
        // console.log(JSON.stringify(newArr,null,2));
        // alert('delete - newArr')

        props.handleState({call:'formArr',val:newArr});
        props.handleState({call:'runDispatch',val:true});



    break;
    case'error':

        props.handleState({call:'hashData',val:false});
        props.handleState({call:'processSubmit',val:false});

        if(props.location.hash === "#!")
        {
            //history.goBack();  
            props.handleRoute({call:'goBack',val:""});

        }
        props.handleState({call:'problemArr',val:props.errors});


        scrollbox = document.getElementById('scrollbox');

        if(props.errors
        && props.errors !== null
        && props.errors !== undefined
        )
        {
            if(props.errors.length > 0)
            {

                var keystr = props.errors[0]['key'].toString();
                var firstkey = keystr;
                if(firstkey.match(/-/gi))
                {
                    indx = firstkey.split("-")[0];
                }else{
                    indx = firstkey;
                }//==

                var formSection = document.getElementById("formSection_"+indx);

                //alert(formSection+" "+indx);

                if(formSection !== null)
                {
                    //alert(formSection.offsetTop);
                    scrollbox.scrollTop = formSection.offsetTop;
                }else{
                    scrollbox.scrollTop = 0;
                }//==

            
            }else{

                scrollbox.scrollTop = 0;

            }
            
        }

        

    break;
    case'complete':



        newArr = Merge({

            from      :props.call,
            formArr   :props.formArr,
            captured  :props.captured

        });

        props.handleState({call:'hashData',val:"#!"});
        props.handleState({call:'processSubmit',val:true});
     
        //console.log("LOCATION: "+JSON.stringify(location,null,2));
        //console.log("HISTORY: "+JSON.stringify(history,null,2));

        if(props.location.hash !== "#!") 
        {
            //props.history.push("#!");
            props.handleRoute({call:"push",val:"#!"});

        }



    

    break;
    case'showCalcMenu':


        //alert('calc menu')

        // console.log("READOUT::::::"+JSON.stringify(props.data,null,2));
        // alert(JSON.stringify(props.calcObj,null,2));


        if(props.location.hash === "#!Calculator~readout~menu")
        { 
            props.handleState({call:'showCalcMenu',val:false});
            props.handleRoute({call:"goBack",val:""});

        }
        else
        if(props.location.hash === "#!Calculator~readout")
        { 
            props.handleState({call:'showCalcMenu',val:true});
            props.handleRoute({call:"push",val:props.location.search+"#!Calculator~readout~menu"});
            
        }
        else
        if(props.location.hash === "#!Calculator~menu")
        { 
            props.handleState({call:'showCalcMenu',val:false});
            //history.goBack(); 
            props.handleRoute({call:"goBack",val:""});

        }else{
            
            props.handleState({call:'showCalcMenu',val:true});
            if(props.location.hash !== "#!Calculator~menu") 
            {
                props.handleRoute({call:"push",val:props.location.search+"#!Calculator~menu"});
            }
        }



    break;
    case'showLaborMenu':

        //alert('labor menu');

        // console.log("READOUT::::::"+JSON.stringify(props.data,null,2));
        // alert(JSON.stringify(props.calcObj,null,2));

        if(props.location.hash === "#!Calculator~labormenu")
        { 
            props.handleState({call:'showLaborMenu',val:false});
            //history.goBack(); 
            props.handleRoute({call:"goBack",val:""});

        }else{

            //alert(props.refScrollbox.current.scrollTop);
            props.data.scrollpos = props.refScrollbox.current.scrollTop;
                     
            props.handleState({call:'showLaborMenu',val:props.data});
            if(props.location.hash !== "#!Calculator~labormenu") 
            {
                props.handleRoute({call:"push",val:props.location.search+"#!Calculator~labormenu"});
            }
        }

    break;
    case'saveas':

        //alert('saveas')

        // console.log("READOUT::::::"+JSON.stringify(props.data,null,2));
        // alert(JSON.stringify(props.calcObj,null,2));

        if(props.location.hash === "#!Calculator~menu")
        { 
            props.handleState({call:'showCalcMenu',val:false});
            //history.goBack(); 
            props.handleRoute({call:"goBack",val:""});

        }else{
            
            props.handleState({call:'showCalcMenu',val:true});
            if(props.location.hash !== "#!Calculator~menu") 
            {
                props.handleRoute({call:"push",val:props.location.search+"#!Calculator~menu"});
            }
        }

        //props.handleState({call:'wasFetched',val:false});
        props.handleState({call:'runFetch',val:true});
        props.handleState({call:'runHistory',val:{

            call:"redirect",
            url:"/Pricing",
            duration:300
           
        }});
    

    break;
    case'save':


        // console.log("SAVE::::::"+JSON.stringify(props.data,null,2));
        // alert(JSON.stringify(props.calcObj,null,2));

        if(props.location.hash === "#!Calculator~menu")
        { 
            props.handleState({call:'showCalcMenu',val:false});
            //history.goBack(); 
            props.handleRoute({call:"goBack",val:""});

        }else{
            
            props.handleState({call:'showCalcMenu',val:true});
            if(props.location.hash !== "#!Calculator~menu") 
            {
                props.handleRoute({call:"push",val:props.location.search+"#!Calculator~menu"});
            }
        }

        props.handleState({call:'runFetch',val:true});



    break;
    case'readout':


        // console.log("READOUT::::::"+JSON.stringify(props.data,null,2));
        // alert(JSON.stringify(props.calcObj,null,2));

        props.handleState({call:'readout',val:props.data});
        if(props.location.hash === "#!Calculator~readout" && !props.data)
        {
            props.handleState({call:'processSubmit',val:false});
            //history.goBack(); 
            props.handleRoute({call:"goBack",val:""});
        }

    break;
    case'timeout':


        console.log(props.message);

    break;
    case'history':

        //alert(props.data.action);
        props.handleRoute({call:props.data.action,val:""});

    break;
    case'currentField':

        props.handleCurrentField({call:"set",val:props.data});
    break;
    default:

   
        // remove all flyrs and reset
    
        scrollbox = document.getElementById('scrollbox');
        scrollpos = 0;
        if(scrollbox) scrollpos = scrollbox.scrollTop;



        props.handleState({call:'hashData',val:false});
        props.handleState({call:'showOptions',val:false});   
        props.handleState({call:'processSubmit',val:false});
        props.handleState({call:'processComplete',val:false});
        props.handleState({call:'problemArr',val:[]});

        switch(props.action)
        {
        case'reload':

            //window.location.reload();//regular dom 
            props.handleRoute({call:"reload",val:""});
        break;
        case'redirect':

            //history.replace("/");
            props.handleRoute({call:"redirect",val:"/"});
        break;
        default:

            if(props.location.hash === "#!")
            {
                //history.goBack(); 
                props.handleRoute({call:"goBack",val:""});
            }


        break;
        }//==


        if(scrollbox) scrollbox.scrollTop = scrollpos;


    break;
    }//==


}


export default Handler;

