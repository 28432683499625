// import { CalcInputs } from '../../Calculate';

const ComposeCostFields = (props) => {

    var R = [];
    var fieldtype;
    // var hint;
    var def;
    var val;
    // var text;

    var key;
    var value;
    var rate;
    var qtyhrs;
  

    // console.log("CalcInputs "+JSON.stringify(CalcInputs,null,2));
    // console.log("Props "+JSON.stringify(props,null,2));



    switch(Object.prototype.toString.call(props))
    {
    case'[object Array]':
    break;
    case'[object Object]':



        //console.log("Composer In - PROPS: "+JSON.stringify(props,null,2))

        for(var keyname in props)
        {

            fieldtype = props[keyname].print;

       
            switch(fieldtype)
            {
            case'standard': fieldtype = 'text'; break;
            //case'price': fieldtype = 'text'; break;
            //'percent': fieldtype = 'text'; break;
            default:
            }


            switch(fieldtype)
            {
            case'headline':


                /*
                "section"   :"headline",
                "data"      :{
        
                    "text"  :'How did you hear about Power Digital Media'
        
                }
                */


                R.push({

                    section:"headline",
                    data:{

                        text:props[keyname].value

                    }

                });


            break;
            default:


                // hint = props[keyname].name;

                // def = props[keyname].default;
                value = props[keyname].value;


                for(key in value)
                {
                    def = parseFloat(value[key].default);
                    val = parseFloat(value[key].value);

                    switch(value[key].print)
                    {
                    case'price':

                        def = parseFloat(def);
                        val = parseFloat(val);

                        def = def.toFixed(2);
                        val = val.toFixed(2);

                        if(isNaN(def)) def = 0;
                        if(isNaN(val)) val = 0;

                        rate = val;


                    break;
                    case'percent':

                        def = parseFloat(def);
                        val = parseFloat(val);

                        def = def.toFixed(1);
                        val = val.toFixed(1);

                        if(isNaN(def)) def = 0;
                        if(isNaN(val)) val = 0;

                        qtyhrs = val;

                    break;
                    default:
                    }

                }


 


                R.push({


                    label:{
    
                        text    :props[keyname].name
    
                    },
                    rate: {


                        "fieldtype"                 :props[keyname].print,
                        "required"					:props[keyname].required,

                        "class"                   	:'',
                        "id"                      	:"rate_"+keyname,
                        "hint"                    	:"",

                        "text"                     	:rate,


                        "linecolor"                 :props[keyname].linecolor,
                        "isLabor"                   :props[keyname].isLabor,


                        "keyboardType"            	:"",
                        "autocorrect"             	:"false",
                        "autocapitalizationType"  	:"none",


                        //"secure"                  :true,
                        //"returnKeyType"           :"done"


                    },
                    qtyhrs: {


                        "fieldtype"                 :props[keyname].print,
                        "required"					:props[keyname].required,

                        "class"                   	:'',
                        "id"                      	:"qtyhrs_"+keyname,
                        "hint"                    	:"",

                        "text"                     	:qtyhrs,


                        "keyboardType"            	:"",
                        "autocorrect"             	:"false",
                        "autocapitalizationType"  	:"none",


                        //"secure"                  :true,
                        //"returnKeyType"           :"done"


                    }
                    
    
                });

            break;
            }



        }



    break;
    case'[object String]':
    break;
    default:
    }

    //console.log("Composer Out: "+JSON.stringify(R,null,2))

    return R;


}
export default ComposeCostFields;






// //--------------------------------
// //labor
// //--------------------------------

// {

//     label: {

//         "text"               	    :"Labor"
//     },

//     rate: {


//         "fieldtype"               	:"text",
//         "required"					:required_rate_labor,

//         "class"                   	:'',
//         "id"                      	:'rate_labor',
//         "hint"                    	:"",

//         "text"                     	:def_rate_labor,



//         "keyboardType"            	:"",
//         "autocorrect"             	:"false",
//         "autocapitalizationType"  	:"none",


//         //"secure"                  :true,
//         //"returnKeyType"           :"done"


//     },

//     qtyhrs: {


//         "fieldtype"               	:"text",
//         "required"					:required_qtyhrs_labor,

//         "class"                   	:'',
//         "id"                      	:'qtyhrs_labor',
//         "hint"                    	:"",

//         "text"                     	:def_qtyhrs_labor,



//         "keyboardType"            	:"",
//         "autocorrect"             	:"false",
//         "autocapitalizationType"  	:"none",


//         //"secure"                  :true,
//         //"returnKeyType"           :"done"


//     }


// },

