const Index = (props) => {


    let R = [];
  
    var call
    var data; 
    var array;
    var object;
    // var title;   
    // var group; 
    // var def;
    // var val;

    // var keyname;
    // var id;


    var tabIndex = 100;


    var Compose = props.array;
    
    for(var cmp of Compose)
    {
        call = cmp['section'];
        data = cmp['data'];

        switch(call)
        {
        case'fields':

            array = [];
            for(object of data)
            {
                object.tabIndex = tabIndex;
                array.push(object);

                console.log(object.id+"-"+tabIndex);
                tabIndex = parseInt(tabIndex) + 1;
                
            }

            R.push({

                section :call,
                data    :array

            });


            // R.push({

            //     section :"spacer",
            //     data    :{

            //         "name"		:"after-"+call,
            //         "color"   	:'transparent',
            //         "height"  	:50

            //     }

            // });


        break;
        case'labelfields':


            // for(keyname in data)
            // {

            //     title = data[keyname]['title'];

            //     R.push({

            //         section:"titleline",
            //         data:{

            //             text    :title
            //         }

            //     });

            //     group = data[keyname]['group'];
            //     array = [];
            //     for(id in group)
            //     {

            //         def = group[id].default;
            //         val = group[id].value;

            //         switch(group[id].print)
            //         {
            //         case'price':
                
            //             def = parseFloat(def);
            //             val = parseFloat(val);

            //             def = def.toFixed(2);
            //             val = val.toFixed(2);
            //         break;
            //         case'percent':

            //             def = parseFloat(def);
            //             val = parseFloat(val);

            //             def = def.toFixed(1);
            //             val = val.toFixed(1);
            //         break;
            //         default:
            //         }



            //         array.push({

            //             label:{

            //                 text    :group[id].title

            //             },
            //             field:{

            //                 //"fieldtype"                 :"text",
            //                 "fieldtype"                 :group[id].print,
            //                 "required"					:group[id].required,

            //                 "class"                   	:'',
            //                 "id"                      	:id,
            //                 "hint"                    	:"",

            //                 "text"                     	:def,

            //                 "keyboardType"            	:"",
            //                 "autocorrect"             	:"false",
            //                 "autocapitalizationType"  	:"none",

            //                 "tabIndex"                  :tabIndex

            //                 //"secure"                  :true,
            //                 //"returnKeyType"           :"done"

            //             }



            //         });


            //         console.log(id+"-"+tabIndex);
            //         tabIndex = parseInt(tabIndex) + 1;

                
            //     }


            //     R.push({

            //         section :call,
            //         data    :array

            //     });

            //     R.push({

            //         section :"spacer",
            //         data    :{

            //             "name"		:"after-"+call,
            //             "color"   	:'transparent',
            //             "height"  	:50

            //         }

            //     });


            // }


        break;
        default:

            R.push({

                section :call,
                data    :data

            });

        break;
        }




    }

    return R;


    


};

export default Index;


