
import { LibArray, LibString } from 'library';



const Handler = (props) => {

    //console.log(JSON.stringify(props,null,2));
    //console.log("Hander Call: "+props.call);



    var currentFlyr = props.zoneObj.currentFlyr;
    var scrollbox;
    var scrollpos;
    var flyrBase;
    var indx;
    var tempArr;
    var inum,jnum;
    var formObj;
    var lineItem;
    var newLabel,newId;
    var captured;
    var index,min,max;

    var object;
    var frm;
    var newArr;
    var i,j;
    var ij;
    var newChoice;



    // console.log("== HANDLER ==");
    // console.log(props.call);
    // console.log(currentFlyr);

    //alert("Flyr Handler - "+props.call);



    switch(props.call)
    {
    case'flyrHeight':

        props.handleState({

            call:props.call,
            value:props.data

        });
        
    break;
    case'adjustContentsHeight':

        // contents are set

        //console.log("CONTENTS HEIGHT: "+props.data.h);

        //setFlyrHeight(1000);//causes a reset
        //setContentsHeight(props.data.h);//causes a reset 

        props.handleState({call:'contentsHeight',value:props.data.h});
        

    break;
    case'handleDispatch':

        props.handleState({call:'handleDispatch',value:{

            action  : props.data.action,
            key     : props.data.key,
            value   : props.data.value

        }});

    break;
    case'setFormArr':

        props.handleState({call:'handleDispatch',value:{

            action:"UPDATE_ZONE",
            key: currentFlyr + "_formArr",
            value: props.data

        }});
        
    break;
    case'reload':

        props.handleState({call:'handleCloseFlyr',value:true});
        setTimeout(function(){

            props.handleRoute({call:'reload',value:true});

        },20);
    break;
    case'loginRequired':

        props.handleState({call:'loginRequired',value:true});
    break;
    case'viewPasswords':


        if(props.viewPasswords) 
        {
            props.handleState({call:'viewPasswords',value:false});
        }else{
            props.handleState({call:'viewPasswords',value:true});
        }
    break;
    case'formSubmit':


        //alert('utils/components/Flyr/Handler - formSubmit');

        switch(props.submitCall)
        {
        case'account':

            props.handleState({call:'handleDispatch',value:{

                action  :"UPDATE_ZONE",
                key     :currentFlyr + "_runAccount",
                value   :true

            }});
        break;
        case'calculate':

            props.handleState({call:'handleDispatch',value:{

                action  :"UPDATE_ZONE",
                key     :currentFlyr + "_runCalculate",
                value   :true

            }});
        break;
        default:

            props.handleState({call:'handleDispatch',value:{

                action:"UPDATE_ZONE",
                key: currentFlyr + "_runSubmit",
                value: true

            }});

            props.handleState({call:'handleDispatch',value:{

                action  :"UPDATE_ZONE",
                key     :currentFlyr + "_output",
                value   :props.data
    
            }});
    
        }


        //alert("POST TO: "+props.postTo);

        props.handleState({call:'handleDispatch',value:{

            action:"UPDATE_ZONE",
            key: currentFlyr + "_postTo",
            value: props.postTo

        }});
        props.handleState({call:'handleDispatch',value:{

            action:"UPDATE_ZONE",
            key: currentFlyr + "_currentKey",
            value: props.currentKey

        }});


    break;
    case'capture':


    
        tempArr = [];
        for(formObj of props.zoneObj[currentFlyr + "_formArr"])
        {
            tempArr.push(formObj);
        }


        //kill it - to avoid mutation error
        props.handleState({call:'handleDispatch',value:{

            action:"UPDATE_ZONE",
            key: currentFlyr + "_formArr",
            value: []

        }});



        switch(props.fieldCall)
        {
        case'clicker-edit':


            console.log("clicker-edit");
            console.log(props.newValue);

            switch(props.action)
            {
            case'change':

                frm = tempArr[props.key];
                newArr = props.newArray;
                newChoice = [];
                for(i=0; i < newArr.length; i++)
                {
                    object = newArr[i];
                    if(object['innerColor'] === 'orangered') 
                    {
                        console.log("clickerVal: "+object['clickerVal']);
                        console.log("newValue: "+props.newValue);

                        newChoice.push(props.newValue);

                    }//==

                }//== i


                //alert(props.newIndex+" - "+props.newValue);

                frm['data']['choice'] = newChoice;
                frm['data']['changeMode'] = {

                    index: props.newIndex,
                    value: props.newValue

                };

                tempArr[props.key] = frm;
                //props.handleState({call:'formArr',value:tempArr});

                // console.log("tempArr: "+JSON.stringify(tempArr,null,2));
                // alert("newChoice: "+JSON.stringify(newChoice,null,2));




            break;
            case'blur':

                frm = tempArr[props.key];
                newArr = props.newArray;
                newChoice = [];
                for(i=0; i < newArr.length; i++)
                {
                    object = newArr[i];
                    if(object['innerColor'] === 'orangered') 
                    {
                        newChoice.push(object['clickerVal']);

                    }//==

                }//== i



                //alert(props.newIndex+" "+props.newValue);
                if(props.newIndex !== undefined) 
                    frm['data']['array'][props.newIndex] = props.newValue;

                frm['data']['choice'] = newChoice;


                //alert("FRM: "+JSON.stringify(frm,null,2));

                tempArr[props.key] = frm;
                //props.handleState({call:'formArr',value:tempArr});
            break;
            case'click':

                frm = tempArr[props.key];
                newArr = props.newArray;
                newChoice = [];
                for(i=0; i < newArr.length; i++)
                {
                    object = newArr[i];
                    if(object['innerColor'] === 'orangered') 
                    {
                        newChoice.push(object['clickerVal']);

                    }//==

                }//== i

                frm['data']['choice'] = newChoice;

                tempArr[props.key] = frm;
                //props.handleState({call:'formArr',value:tempArr});
            break;
            default:
            }

        
        break;
        case'clicker':


            //console.log("Clicker");


            frm = tempArr[props.key];

            newArr = props.newValue;

            newChoice = [];
            for(i=0; i < newArr.length; i++)
            {
                object = newArr[i];
                if(object['innerColor'] === 'orangered') 
                {
                    newChoice.push(object['clickerVal']);

                }//==

            }//== i

            frm['data']['choice'] = newChoice;


            tempArr[props.key] = frm;
            //props.handleState({call:'formArr',value:tempArr});

        
        break;
        case'slider':
        case'dualslider':


            //call        :'capture',
            //fieldCall   :"dualslider",
            //key         :props.indx,
            //data        :props.data,
            //newValue    :hndl.valueA

            //console.log("Slider / DualSlider" + props.newValue);
            frm = tempArr[props.key];
            //console.log("FRM: "+JSON.stringify(frm,null,2));
            frm['data']['value'] = props.newValue;

            tempArr[props.key] = frm;
          

        break;
        case'field':

            //console.log("Field");
            //console.log(props['key']);
            //console.log(props['id']);

            ij = props.key.split("-");
        
            frm = tempArr[ij[0]];
            if(frm.section === 'fields')
            {

                frm.data[ij[1]]['text'] = props.newValue;

                //var fields = frm.data;
                //var field = fields[ij[1]];
                //field['text'] = props.newValue;
                
                tempArr[ij[0]] = frm;
                //props.handleState({call:'formArr',value:tempArr});

            }


        break;
        case'labelfield':

            //console.log("Label Field");
            //console.log(props['key']);
            //console.log(props['id']);
            //console.log("VALUE: "+props.newValue);

            ij = props.key.split("-");
        
            frm = tempArr[ij[0]];
            if(frm.section === 'labelfields')
            {

                frm.data[ij[1]].field.text = props.newValue;

                tempArr[ij[0]] = frm;
                //props.handleState({call:'formArr',value:tempArr});

            }

        break;
        case'editfield':

            // console.log("Edit Field");
            // console.log(props['key']);
            // console.log(props['id']);
            // console.log("VALUE: "+props.newValue);

            ij = props.key.split("-");
            frm = tempArr[ij[0]];
            if(frm.section === 'editfields')
            {
                frm.data[ij[1]].field.text = props.newValue;
                tempArr[ij[0]] = frm;
                //props.handleState({call:'formArr',value:tempArr});
            }

        break;
        default:
        }//===
    


        //console.log("tempArr: "+JSON.stringify(tempArr,null,2));


        //reset it - after edit
        props.handleState({call:'handleDispatch',value:{

            action:"UPDATE_ZONE",
            key: currentFlyr + "_formArr",
            value: tempArr

        }});

        

    break;
    case'add':

        
        // console.log("FIELD CALL: "+props.fieldCall);

        tempArr = [];
        for(formObj of props.zoneObj[currentFlyr + "_formArr"])
        {
            tempArr.push(formObj);
        }


        //kill it - to avoid mutation error
        props.handleState({call:'handleDispatch',value:{

            action:"UPDATE_ZONE",
            key: currentFlyr + "_formArr",
            value: []

        }});


        switch(props.data.fieldCall)
        {
        case'editfield':

            indx = 99;
            for(i=0; i < tempArr.length; i++)
            {
                frm = tempArr[i];
                //console.log("add--data---"+JSON.stringify(frm.data,null,2));
                if(i === parseInt(props.data.index)-1)
                {
            
                    console.log("add--data---"+JSON.stringify(frm.data,null,2));

                    captured = [];
                    inum = parseInt(frm.data.length) + 1;
                    for(lineItem of frm.data)
                    {
                        if(lineItem.field.id.match(/_field_/gi))
                        {
                            jnum = lineItem.field.id.split("_field_")[0];
                            if(!LibArray.InArray(captured,jnum))
                            {
                                captured.push(parseInt(jnum));
                            }
                        }

                    }

                    if(LibArray.InArray(captured,parseInt(inum))
                    )
                    {
                        min = parseInt(inum) + 3;
                        max = parseInt(min) + 100;
                        index = Math.floor(min + Math.random()*(max + 1 - min))

                        min = 1;
                        max = 9;
                        index = index + Math.floor(min + Math.random()*(max + 1 - min))

                    }else{

                        index = inum;
                    }

                    //alert(index);

                    

                    frm.data.push({

                        "label": {
                            "text": "Line Item"
                          },
                          "field": {
                            "fieldtype": "price",
                            "required": true,
                            "class": "",
                            "id": "expense_field_"+index,
                            "hint": "",
                            "text": "0.00",
                            "keyboardType": "",
                            "autocorrect": "false",
                            "autocapitalizationType": "none"
                          }

                    });


                }//endif

                tempArr[i] = frm;

            }//endfor



        break;
        default:
        }//==


        // console.log("tempArr:"+JSON.stringify(tempArr,null,2));
        // alert("check add");

        //reset it - after edit
        props.handleState({call:'handleDispatch',value:{

            action:"UPDATE_ZONE",
            key: currentFlyr + "_formArr",
            value: tempArr

        }});


    break;
    case'edit':


        console.log("FIELD CALL: "+props.fieldCall);
  
        tempArr = [];
        for(formObj of props.zoneObj[currentFlyr + "_formArr"])
        {
            tempArr.push(formObj);
        }


        //kill it - to avoid mutation error
        props.handleState({call:'handleDispatch',value:{

            action:"UPDATE_ZONE",
            key: currentFlyr + "_formArr",
            value: []

        }});


        switch(props.fieldCall)
        {
        case'editfield':


            ij = props.key.split("-");
            inum = ij[0];
            jnum = ij[1];



            console.log("Edit Field");
            console.log(props['key']);
            console.log(props['id']);
            //console.log("props: "+JSON.stringify(props,null,2));
            console.log("props.data: "+props.data);
            console.log("props.value: "+props.value);


            // {
            //     "label": {
            //       "text": "Hello"
            //     },
            //     "field": {
            //       "fieldtype": "price",
            //       "required": true,
            //       "class": "",
            //       "id": "expense_ira_percent",
            //       "hint": "",
            //       "text": "250.00",
            //       "keyboardType": "",
            //       "autocorrect": "false",
            //       "autocapitalizationType": "none"
            //     }
            // }


            frm = tempArr[ij[0]];
            if(frm.section === 'editfields')
            {
                //frm.data[ij[0]].field.text = props.newValue;
                console.log("label 0 ?  : "+JSON.stringify(frm.data[ij[0]],null,2));

                newLabel = props.value;
                newLabel = newLabel.replace(/-/gi," ");
                newLabel = newLabel.replace(/_/gi," ");
                newLabel = LibString.CapitalizeWords(newLabel);

                newId = "expense_"+props.value.toLowerCase();
                newId = newId.replace(/\//gi,"");
                newId = newId.replace(/\./gi,"");
                newId = newId.replace(/ /gi,"_");
                newId = newId.replace(/__/gi,"_");
                newId = newId.replace(/___/gi,"_");

                frm.data[jnum].label.text = newLabel;
                frm.data[jnum].field.id = newId;
                tempArr[inum] = frm;
                //props.handleState({call:'formArr',value:tempArr});

            }

       
        break;
        case'edittitle':


            inum = props.key;
     
            // console.log("Edit Title");
            // console.log(props['key']);
            // console.log(props['id']);
            // //console.log("props: "+JSON.stringify(props,null,2));
            // console.log("props.value: "+props.value);

            // {
            //     "section": "edittitle",
            //     "data": {
            //       "text": "New Expense"
            //     }
            // }

          
            frm = tempArr[inum];
            if(frm.section === 'edittitle')
            {
                //frm.data[ij[0]].field.text = props.newValue;
                console.log("title 0 ?  : "+JSON.stringify(frm.data,null,2));

                newLabel = props.value;
                newLabel = newLabel.replace(/-/gi," ");
                newLabel = newLabel.replace(/_/gi," ");
                newLabel = LibString.CapitalizeWords(newLabel);
                
                frm.data.text = newLabel;
                tempArr[inum] = frm;
                //props.handleState({call:'formArr',value:tempArr});

            }

       
        break;
        default:
        }//==



        // console.log("tempArr:"+JSON.stringify(tempArr,null,2));
        // alert("check edit");


        //reset it - after edit
        props.handleState({call:'handleDispatch',value:{

            action:"UPDATE_ZONE",
            key: currentFlyr + "_formArr",
            value: tempArr

        }});


    break;
    case'delete':


        ij = props.key.split("-");
        inum = ij[0];
        jnum = ij[1];

        //alert(jnum);


        console.log(inum+"\n"+jnum);
        console.log("FIELD CALL: "+props.fieldCall);
  

        tempArr = [];
        for(formObj of props.zoneObj[currentFlyr + "_formArr"])
        {
            tempArr.push(formObj);
        }


        //kill it - to avoid mutation error
        props.handleState({call:'handleDispatch',value:{

            action:"UPDATE_ZONE",
            key: currentFlyr + "_formArr",
            value: []

        }});


        switch(props.fieldCall)
        {
        case'editfield':

            for(i=0; i < tempArr.length; i++)
            {
                frm = tempArr[i];
                //console.log("delete--data---"+JSON.stringify(frm.data,null,2));

                if(frm.data.length > 1)
                {

                    if(i === parseInt(inum))
                    {
                        //console.log("delete--data---"+JSON.stringify(frm.data,null,2));
                        
                        newArr = [];
                        for(j=0; j < frm.data.length; j++)
                        {
                            if(j === parseInt(jnum))
                            {
                                console.log(j+" "+jnum);
                                console.log("remove: "+JSON.stringify(frm.data[j],null,2));
                            }else{
                                newArr.push(frm.data[j]);
                            }
                        }
                        frm.data = newArr;

                    }//endif

                }

                tempArr[i] = frm;
            }//endfor


        break;
        default:
        }//==


        // console.log("tempArr:"+JSON.stringify(tempArr,null,2));
        // alert("check delete");

        //reset it - after edit
        props.handleState({call:'handleDispatch',value:{

            action:"UPDATE_ZONE",
            key: currentFlyr + "_formArr",
            value: tempArr

        }});


    break;
    case'resetProblemArr':

        console.log(JSON.stringify(props.errors,null,2));

        props.handleState({call:'problemArr',value:props.errors});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_problemArr",
            value   :props.errors

        }});


    break;
    case'clear':


        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_problemArr",
            value   :[]

        }});


    break;
    case'output':

        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runAccount",
            value   :false

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runCalculate",
            value   :false

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runOutput",
            value   :true

        }});

        //alert('output data....'+JSON.stringify(props.data));

        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_output",
            value   :props.data

        }});


        
    break;
    case'error':



        //props.handleState({call:'hashData',value:false});
        //props.handleState({call:'processSubmit',value:false});
        //props.handleState({call:'processError',value:true});
        //props.handleState({call:'errorType',value:props.type});
        //props.handleState({call:'problemArr',value:props.errors});

        //console.log(props.type);
        //console.log(Object.prototype.toString.call(props.errors));
        console.log("ERROR - PROBLEM ARR: "+JSON.stringify(props.errors));

     
        
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runSubmit",
            value   :false

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runComplete",
            value   :false

        }});

        
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runAccount",
            value   :false

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runCalculate",
            value   :false

        }});


        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runError",
            value   :true

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_errorType",
            value   :props.type

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_problemArr",
            value   :props.errors

        }});




        // -- adjust scroller -- //

        scrollbox = document.getElementById('flyrBase');

        if(props.errors
        && props.errors !== null
        && props.errors !== undefined
        )
        {
            if(props.errors.length > 0)
            {

                var keystr = props.errors[0]['key'].toString();
                var firstkey = keystr;
                if(firstkey.match(/-/gi))
                {
                    indx = firstkey.split("-")[0];
                }else{
                    indx = firstkey;
                }//==

                var formSection = document.getElementById("formSection_"+indx);

                //alert(formSection+" "+indx);

                if(formSection !== null)
                {
                    //alert(formSection.offsetTop);
                    scrollbox.scrollTop = formSection.offsetTop;
                }else{
                    scrollbox.scrollTop = 0;
                }//==

            
            }else{

                scrollbox.scrollTop = 0;

            }
            
        }



    break;
    case'submit':
      
        //props.handleState({call:'hashData',value:"#!"});
        //props.handleState({call:'processSubmit',value:true});
    
        //console.log("LOCATION: "+JSON.stringify(location,null,2));
        //console.log("HISTORY: "+JSON.stringify(history,null,2));

        /*
        if(props.location.hash !== "#!") 
        {
            //props.history.push("#!");
            props.handleRoute({call:"push",value:"#!"});
        }
        */


        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runSubmit",
            value   :true

        }});



    break;
    case'complete':

        //props.handleState({call:'hashData',value:"#!"});
        //props.handleState({call:'processComplete',value:true});
     
        //console.log("LOCATION: "+JSON.stringify(location,null,2));
        //console.log("HISTORY: "+JSON.stringify(history,null,2));

        /*
        if(props.location.hash !== "#!") 
        {
            //props.history.push("#!");
            props.handleRoute({call:"push",value:"#!"});

        }
        */




        // -- KILL: formArr - problemArr - errorType -- //

        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_formArr",
            value   :[]

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_problemArr",
            value   :[]

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_errorType",
            value   :''

        }});


        // -- KILL : account - calculate - output - postTo - completeMessage  -- //


        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runAccount",
            value   :false

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runCalculate",
            value   :false

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runSubmit",
            value   :false

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"DELETE_ZONE",
            key     :currentFlyr + "_output",
            value   :false

        }});
        props.handleState({call:'handleDispatch',value:{

            action:"DELETE_ZONE",
            key: currentFlyr + "_postTo",
            value: false

        }});
        props.handleState({call:'handleDispatch',value:{

            action:"DELETE_ZONE",
            key: currentFlyr + "_currentKey",
            value: false

        }});


        
        // -- SET: complete -- //

        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runComplete",
            value   :true

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_completeMessage",
            value   :props.message

        }});



        if(props.reset)
        {
            //alert('resetting after complete');
            props.handleState({call:'handleDispatch',value:{

                action  :"UPDATE_ZONE",
                key     :props.reset.key,
                value   :props.reset.value

            }});

        }



        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :'recalculate',
            value   :true

        }});




    break;
    case'timeout':


        // -- CLEAR = account - calculate - submit - error - errorType -- //

        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runAccount",
            value   :false

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runCalculate",
            value   :false

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runSubmit",
            value   :false

        }});
        // props.handleState({call:'handleDispatch',value:{

        //     action  :"UPDATE_ZONE",
        //     key     :currentFlyr + "_runError",
        //     value   :false

        // }});

        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_errorType",
            value   :''

        }});




        // -- prime handler -- //

        //alert('timeout - primeHandle');

        props.handleState({call:'primeHandle',value:{

            handle  :'messngr',
            action  :'hideMessngr'

        }});


        if(!props.zoneObj[currentFlyr+"_runError"])
        {

            props.handleState({call:'handleDispatch',value:{

                action  :"UPDATE_ZONE",
                key     :currentFlyr + "_runError",
                value   :false

            }});

            props.handleRoute({call:"goBack",value:""});


        }
        


    break;
    case'goback':

        props.handleRoute({call:"goBack",value:""});

    break;
    default:




        console.log("Flyr Handler Default");


        // reset 

        if(document.getElementById('scrollbox')
        && document.getElementById('scrollbox') !== undefined
        )
        {
            scrollbox = document.getElementById('scrollbox');
            scrollpos = scrollbox.scrollTop;
            //scrollpos = 0;
            scrollbox.scrollTop = scrollpos;

        }

        if(document.getElementById('flyrBase')
        && document.getElementById('flyrBase') !== undefined
        )
        {
            flyrBase = document.getElementById('flyrBase');
            flyrBase.scrollTop = 0;
        }








        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runSubmit",
            value   :false

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runError",
            value   :false

        }});




        // -- formArr - problemArr - errorType -- //

        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_formArr",
            value   :[]

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_problemArr",
            value   :[]

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_errorType",
            value   :''

        }});





        // -- account - calculate - output - postTo - completeMessage  -- //

        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runAccount",
            value   :false

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runCalculate",
            value   :false

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"UPDATE_ZONE",
            key     :currentFlyr + "_runOutput",
            value   :false

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"DELETE_ZONE",
            key     :currentFlyr + "_output",
            value   :false

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"DELETE_ZONE",
            key     :currentFlyr + "_postTo",
            value   :false

        }});
        props.handleState({call:'handleDispatch',value:{

            action:"DELETE_ZONE",
            key: currentFlyr + "_currentKey",
            value: false

        }});
        props.handleState({call:'handleDispatch',value:{

            action  :"DELETE_ZONE",
            key     :currentFlyr + "_completeMessage",
            value   :false

        }});







        if(props.zoneObj[currentFlyr + "_runComplete"])
        {

            props.handleState({call:'handleDispatch',value:{

                action  :"UPDATE_ZONE",
                key     :currentFlyr + "_runComplete",
                value   :false
    
            }});


            //close flyr
            props.handleState({

                call:"handleCloseFlyr",
                value:''
    
            });

        }else{

            props.handleState({call:'handleDispatch',value:{

                action  :"UPDATE_ZONE",
                key     :currentFlyr + "_runComplete",
                value   :false
    
            }});

        }



        if(props.call === 'closeFlyr')
        {
            props.handleState({call:'handleCloseFlyr',value:false});

        }

    break;
    }//==


}


export default Handler;

