
import React, { 

    useState, 
    useEffect,
    useRef

} from 'react';


import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider';

import { useLiqGrid } from 'utils/hooks/LiquidFormula/LiqGrid';


import 'app-style/alfabase.css';//alfa,base,tab,tab-x,flank
import StylFlyr from './Flyr.module.css';

import Html5Vid from './Html5';
import YouTubeVid from './YouTube';

import { LibElem } from 'library';


import CreateMarkup from 'utils/components/Universal/CreateMarkup';
//import CreateLink from 'utils/components/Universal/CreateLink';





const Index = (props) => {
   

    /*

    handle      = {(v) => handle(v)}       
    data       = {imageData}

    */




    //console.log("PROPS DATA :::::::::"+JSON.stringify(props.data,null,2));
    //alert('props');



    // -- window dims -- //
    const { width } = useWindowDimensions();


    // -- router -- //
    //let match = useRouteMatch();
    //let location = useLocation();
    //let history = useHistory();


    // -- redux -- //

    //const dispatch = useDispatch();
    //const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));

    //if(zoneObj['handle'] && zoneObj['handle'] !== undefined) alert(zoneObj['handle']);



    // -- state -- //
    const [highZ, setHighZ] = useState(0)

    useEffect(() => {
        
        //setFlyrHeight(flyrRef.current.offsetHeight);
        if(highZ === 0)
        {
            var z = LibElem.HighestZ();
            setHighZ(z)

        }

    }, [highZ])





    // -- ref -- //
    const baseRef = useRef(null)
    const flankRef = useRef(null)
    const flyrRef = useRef(null)
    const thmbRef = useRef(null)
    


    // const cellRefs = [];
    // const setRef = (ref) => {

    //     if(ref && ref !== null && ref !== undefined)
    //     {
    //         //console.log("ref::::"+ref.id);
    //         var found;
    //         found = false;
    //         for(var i=0; i < cellRefs.length; i++)
    //         {
    //             if(cellRefs[i].id === ref.id) found = true;
    //         }
    //         if(!found) cellRefs.push(ref);
    //         //console.log(cellRefs.length);

    //     }

    // };
      


    // -- state -- //
    const [baseScrollHeight, setBaseScrollHeight] = useState(0)
    const [flyrHeight, setFlyrHeight] = useState(10)
    const [thmbWidth, setThmbWidth] = useState(640)
    const [thmbHeight, setThmbHeight] = useState(480)


    // -- multi refs -- //
    const [cellRefs] = useState([]);
    const setRef = (ref) => {

        if(ref && ref !== null && ref !== undefined)
        {
            //console.log("ref::::"+ref.id);
            var found;
            found = false;
            for(var i=0; i < cellRefs.length; i++)
            {
                if(cellRefs[i].id === ref.id) found = true;
            }
            if(!found) cellRefs.push(ref);
            //console.log(cellRefs.length);

        }

    };


    useEffect(() => {

        setThmbWidth(thmbRef.current.offsetWidth);
        setThmbHeight(thmbRef.current.offsetHeight)

 
        if(flyrHeight < 50)
        {

            setBaseScrollHeight(baseRef.current.scrollHeight);
            setFlyrHeight(flyrRef.current.offsetHeight)


            if(cellRefs.length === props.data.items.length)
            {
                var total = 0;
                for(var i=0; i < cellRefs.length; i++)
                {
                    if(cellRefs[i] 
                    && cellRefs[i] !== null 
                    && cellRefs[i] !== undefined
                    )
                    {
                        total = total + cellRefs[i].offsetHeight;
                    }
                }
                //flyrRef.current.height = total + "px";
                //flankRef.current.height = total + "px";

                setFlyrHeight(total+100);

            }
            
            if(props.data.items.length > 1 && cellRefs.length > 0)
            {
                //position = props.data.position;

                //var flyrCell = cellRefs[position];
                //console.log("POSITION::: "+position+" \noffsetTop: "+flyrCell.offsetTop);

                var flyrCell;
                flyrCell = cellRefs[0];

                for(var cell of cellRefs)
                {
                    //console.log(cell.id+"==="+props.data.id);
                    if(cell.id.toString() === props.data.id.toString())
                    {
                        flyrCell = cell;
                        break;
                    }
                }
                //alert(props.data.id);
                baseRef.current.scrollTop = flyrCell.offsetTop;

            }

            if(flyrHeight < baseRef.current.scrollHeight-200) setFlyrHeight(baseRef.current.scrollHeight);




        }//==



    },[

        props,
        cellRefs,
        flyrHeight,
        baseScrollHeight
    ])

   





    //console.log(JSON.stringify(props.items));
    //alert("checking utils/FGrid/Flyr")


    var maxWidth = 900;
    if(width < maxWidth + 50)
    {
       maxWidth = width;

    }//==




    
    
    //###########################################################
    //###########################################################

    var gridObj;
    gridObj = useLiqGrid(props.data.items, {


        call:'vid', //fixed, flex
        gridType: "ImageOnly", //ImageOnly, ImageText

        gridBgColor:'transparent',
        gridCall: "flyr",  //grid_column_cell or grid_cell or flyr
        modCall: 'fitTo',
        thumb: {

            call    : "default", //flex_height or default
            diff    : 0

        },    
        cellBorder: {
            "boolean"       :true,
            "thickness"     :1,//px
            "color"         :"gray"  
        },
        gutter: {
            "boolean"   :true,
            "size"      :0
        },
        thresh: {
            "refWidth"    :maxWidth,   //number or false = default screen width
            "across1"     :501,
            "across2"     :801,
            "across3"     :1051
        }


    });

    const items = gridObj['items'];

    //console.log(JSON.stringify(items,null,2));
    //alert("checking utils/FGrid/Flyr")

    


    





    //###########################################################
    //###########################################################

    var flyrPadBottomInt = 80;
    var flyrPadBottom = flyrPadBottomInt + "px";

     var flyrWInt;
     var flyrW;

     var flyrH;

     var flyrMarl;

     var flyrMartInt;
     var flyrMart;

     var tabMartInt;
     var tabMart;



     flyrWInt = maxWidth;
     flyrMarl = (width - flyrWInt)/2 + "px";

     if(width < flyrWInt+50)
     {
        flyrWInt = width;
        flyrMarl = 0 + "px";
     }//==

     flyrW = flyrWInt + "px";


    //-------------------------------------------

    flyrMartInt = 50;
    tabMartInt = 0;

    flyrMart = flyrMartInt + 'px';
    tabMart = tabMartInt + "px";

    //-------------------------------------------

    var flankLeftWidthInt;
    var flankLeftWidth;
    var flankLeftMarl;
       
    var flankRightWidthInt;
    var flankRightWidth;
    var flankRightMarl;

    var flankVertHeight;


    flankLeftWidthInt = (width - flyrWInt)/2;
    flankLeftWidth = flankLeftWidthInt + "px";
    flankLeftMarl = "0px";
       
    flankRightWidthInt = (width - flyrWInt)/2;
    flankRightWidth = flankRightWidthInt + "px";
    flankRightMarl = flankLeftWidthInt + flyrWInt + "px";


    //flyrH = baseScrollHeight - (tabHeight+flyrPadBottomInt) + "px";
    flyrH = flyrHeight + (0) + "px";
    flankVertHeight = flyrHeight + (100) + "px";




/*



    const VideoEnded = () => {


        console.log('video ended');


    }


*/




    const VID = items.data[0];


    console.log("VID: "+JSON.stringify(VID,null,2));




    if(items.length === 0)
    {


        return (


            <div>

                
                <div 
                className="alfa"
                style={{

                    zIndex:highZ+1

                }}
                ></div>


                <div 
                className="base"
                style={{

                    zIndex:highZ+2

                }}
                >

                    Loading...


                </div>


            </div>

        );



    
    }
    else
    {


        return (


            <div>


                <div 
                className="alfa"
                style={{

                    zIndex:highZ+1

                }}
                ></div>

                <div 
                className="tab"
                style={{

                    backgroundColor : 'white', 
                    width           : flyrW,
                    height          : "50px",
                    margin          : "0 0 0 0",
                    top             : tabMart,
                    left            : flyrMarl,
                    zIndex          : highZ+3

                }}
                >


                    <div 
                    className="tab-x" 
                    onClick={() => props.handle({

                        call:'hide'

                    })}
                    >  

                        X

                    </div>


                </div>


                <div 
                id="flyrBase"
                ref={baseRef}
                className="base"
                style={{

                    zIndex:highZ+2
                    
                }}
                >


                    <div 
                    id="flankleft"
                    ref={flankRef}
                    onClick={() => props.handle({

                        call:'hide'

                    })}
                    className="flank" 
                    style={{

                        //backgroundColor : 'blue', 
                        width           : flankLeftWidth,
                        height          : flankVertHeight,
                        left            : flankLeftMarl
                    

                    }}
                    >
                    </div>


                    <div 
                    id="flankright"
                    onClick={() => props.handle({

                        call:'hide'

                    })}
                    className="flank" 
                    style={{

                        //backgroundColor : 'red', 
                        width           : flankRightWidth,
                        height          : flankVertHeight,
                        left            : flankRightMarl

                    }}
                    >
                    </div>





                    <div 
                    id="flyr"
                    ref={flyrRef}
                    className={StylFlyr.VidFlyr} 
                    style={{

                        backgroundColor : 'white', 
                        width           : flyrW,
                        height          : flyrH,
                        margin          : flyrMart+" 0 0 "+flyrMarl,
                        padding         : "0 0 "+flyrPadBottom+" 0"

                    }}
                    >


                        <div 
                        className={StylFlyr.Grid}
                        style={{

                            //backgroundColor : "lightyellow", 
                            //width           : liqItems.grid.width,
                            //height          : liqItems.grid.height, 

                        }}

                        >

                            {
                            VID && VID !== undefined
                            ?

                                <div 
                                ref={setRef}
                                key={0}   
                                id={VID.id}
                                className={StylFlyr.Cell} 
                                style={{

                                    //backgroundColor : box.bgColor, 
                                    width           : items.cell.width,
                                    height          : items.cell.height,
                                    //height          : "300px",
                                    //margin          : items.cell.margin,
                                    //border          : items.cell.border
                            
                        
                                }}
                        
                                >


                                    <div 
                                    ref={thmbRef}
                                    className={StylFlyr.Thmb}  
                                    style={{

                                        //backgroundColor:"magenta",
                                        width:"100%",
                                        height:"480px",

                                        //width:items.cell.thumb.width,
                                        //height:items.cell.thumb.height,
                                        //height:VID.thumb.h,
                                        //height:VID.image.maxHeight,


                                        //margin:items.cell.thumb.margin,
                                        overflow:"hidden"

                            
                                    }}
                                    >

                                        {

                                        VID.video.site === 'youtube'
                                        ?
                                        
                                            <YouTubeVid 
                                            
                                                call='basic'
                                                id="flyrVid"
                                                vidTitle={VID.title}
                                                vidId={VID.video.id}
                                                vidSrc=""           
                                                vidAutoPlay = 'autoplay'
                                                vidControls = 'controls'
                                                vidWidth={thmbWidth+"px"}
                                                vidHeight={thmbHeight+"px"}
                                            
                                            />



                                        :
                                        VID.video.type === 'internal'
                                        ?
                                        
                                            <Html5Vid
                                                         
                                                call=""
                                                id="flyrVid"
                                                vidTitle={VID.title}
                                                vidId = ""
                                                vidSrc={VID.video.src}
                                                vidAutoPlay = 'autoplay'
                                                vidControls = 'controls'
                                                vidWidth={thmbWidth+"px"}
                                                vidHeight={thmbHeight+"px"}

                                            />

                                        :null

                                        
                                        }




                                    </div>



                                    {
                                    props.data.gridType === 'ImageText' ? 


                                        <div>
                                            <div className={StylFlyr.Title}>

                                                {/* {VID.title} */}
                                                <div dangerouslySetInnerHTML={CreateMarkup(VID.title)} />

                                            </div>
                                            <div className={StylFlyr.Text}>

                                                {/* {VID.text} */}
                                                <div dangerouslySetInnerHTML={CreateMarkup(VID.text)} />

                                            </div>
                                        </div>

                                    : null
                                    }



                                </div>


                            :null
                            }


                        </div>




                    </div>

                </div>


            </div>

        );


    }



}


export default Index;





/*

  inputRefs = [];
  
    setRef = (ref) => {
        this.inputRefs.push(ref);
    };
    
    focusInput = (id) => this.inputRefs[id].focus();
    
    render() {
        return (
        <div>
            {this.state.data.map(({ name }) => (
            <Hello 
                placeholder={name} 
                ref={this.setRef} 
                key={name} />
            ))}
            <Button onClick={this.focusInput} id={0}>focus input 1</Button>
            <Button onClick={this.focusInput} id={1}>focus input 2</Button>
        </div>
        );
    }
    }

*/