/*
     
----------------------
Flex or Fixed
----------------------

MAX - 4 across

 "across1"     :501,        
 "across2"     :801,          
 "across3"     :1050


MAX - 3 across

 "across1"     :501,        
 "across2"     :801,          
 "across3"     :10000


MAX - 2 across

 "across1"     :501,        
 "across2"     :10000,          
 "across3"     :10000



 */




const SceneInfo = [


    /*

    {

        styl:{

            call:"indent",
            class:"IndentRowB",

            marginTop:"10px",
            marginBottom:"0"

    
        },
        call:'TxtComponent',
        info:{

            call:'flex', //fixed, flex
            gridType: "Newspaper", //Newspaper
            gridBgColor:'transparent',
            gridCall: "grid_cell",  //grid_cell or grid_column_cell
            cellCall: "default",  //container or default 
            modCall: 'fill_div',
            thumb: {
    
                call    : "default", //flex_height or default
                diff    : 0
    
            },    
            cellBorder: {
                "boolean"       :true,
                "thickness"     :1,//px
                "color"         :"gray"  
            },
            gutter: {
                "boolean"   :true,
                "size"      :20
            },
            thresh: {
                "refWidth"    :false,   //number or false = default screen width
                "across1"     :501,
                "across2"     :801,
                "across3"     :10000
            }



        },

        items:[{

            heading:"Form Scene",
            pgraph:''

        }]



    },


    */



    {
        styl:{

            call:"full",
            class:"FullRow",

            marginTop:"10px",
            marginBottom:"100px"

        },
        call:'FormComponent',
        info:'',
        items:[]

    }


];



export default SceneInfo;
