import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';


import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    //useHistory,   
    //useParams,



} from "react-router-dom";

// import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'

import { createPortal } from 'react-dom';
import usePortal from 'utils/hooks/Portal';



// import Navigator from 'utils/components/Content/Navigator';
// import SideNavInfo from 'utils/components/Pages/SceneInfo/Trials/SideNav/SceneInfo';




//=====================================================


import {

    ProcAlfa,
    ProcBase,
    ProcForm

} from '../../Styled';





const Index = (props) => {



    const portalTarget = usePortal("stage");



    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();

    
 
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);



    // -- window dims -- //
    // const { 
    //     width,
    //     height 
    // } = useWindowDimensions();



    // -- redux -- //
    // const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);


 

    //=============================================
    // ref
    //=============================================

    const refForm = useRef(null);

    /*
    const rowRefs = [];
    const setRowRef = (ref) => {

        if(ref && ref !== null && ref !== undefined)
        {
            //console.log("ref::::"+ref.id);
            var found;
            found = false;
            for(var i=0; i < rowRefs.length; i++)
            {
                if(rowRefs[i].id === ref.id) found = true;
            }
            if(!found) rowRefs.push(ref);
            console.log(rowRefs.length);
            console.log(rowRefs[0]);

        }

    };
    */




    //=============================================
    // state/effect - display
    //=============================================

    const [isLoaded, setIsLoaded] = useState(false);
    const [fromTop, setFromTop] = useState("0px");
    //const [fieldArr, setFieldArr] = useState([]);
    const [fieldStyl, setFieldStyl] = useState({

        input:{},
        textarea:{}
        
    });

    const [inputVal, setInputVal] = useState('');
    const [textAreaVal, setTextAreaVal] = useState('');

    useEffect(() => {

        if(!isLoaded)
        {
            setIsLoaded(true);

            var fontStyle = 'italic';
            var fontColor = "#999";

            if(props.data.name && props.data.name !== undefined)
            {
                fontStyle = 'normal';
                fontColor = "#000";

            }
            if(props.data.descr && props.data.descr !== undefined)
            {
                fontStyle = 'normal';
                fontColor = "#000";

            }

            setFieldStyl({

                input:{

                    "bgColor"       :"white",
                    "fontStyle"     :fontStyle,
                    "fontColor"     :fontColor
                },
                textarea:{

                    "bgColor"       :"white",
                    "fontStyle"     :fontStyle,
                    "fontColor"     :fontColor
                }

            });
            setInputVal(props.data.name);
            setTextAreaVal(props.data.descr);





            if(refForm.current.offsetHeight > 0)
            {
                var fromTop = 50;

                // if(props.styling)
                // {
                //     if(props.styling.top)
                //     {
                //         fromTop = props.styling.top.replace("px");
                //         fromTop = fromTop.replace("%");
                //         fromTop = fromTop.replace("em");
                //         fromTop = parseInt(fromTop) - (refForm.current.offsetHeight/4);
                //         if(fromTop < 0) fromTop = 0;
                //     }
                // }
                
                fromTop = (props.height - refForm.current.offsetHeight)/2.5;
                if(fromTop < 25) fromTop = 25;

                setFromTop(fromTop+"px");


            }

    
        }//isLoaded
  
    },
    [
        props,
        zoneObj,
        isLoaded,
    
    ])








    //=============================================
    // handle
    //=============================================

    function handleFocus(target) {

        target.select();
    }

    function handleBlur(e) {

        //console.log(e.target.value);
        //e.target.select();

    }

    function handleChange(e) {


        //console.log("TARGET INDX: ", e.indx);
        //console.log("TARGET VALUE: ", e.target.value);
        //console.log("PLACEHOLDER: ", e.target.placeholder);
        //console.log("TARGET ID: ", e.target.id);
        //console.log("TARGET TYPE: ", e.target.type);
        //console.log("TARGET HINT: ", props.data.hint);

        //var i;
        var hint;
    
        switch(e.target.id)
        {
        case'save_name':

            hint = "Calculator Name";

            if(e.target.value !== hint && e.target.value !== '')
            {
                fieldStyl.input['fontStyle'] = 'normal';
                fieldStyl.input['fontColor'] = '#000';
            }else{
                fieldStyl.input['fontStyle'] = 'italic';
                fieldStyl.input['fontColor'] = '#999';
            }

            setFieldStyl(fieldStyl);
            setInputVal(e.target.value);  
        break;
        case'save_descr':


            hint = "Calculator Description";

            if(e.target.value !== hint && e.target.value !== '')
            {
                fieldStyl.textarea['fontStyle'] = 'normal';
                fieldStyl.textarea['fontColor'] = '#000';
            }else{
                fieldStyl.textarea['fontStyle'] = 'italic';
                fieldStyl.textarea['fontColor'] = '#999';
            }

            setFieldStyl(fieldStyl);
            setTextAreaVal(e.target.value);  
        break;
        default:
        }

   
        // props.handle({

        //     call        :'capture',
        //     fieldCall   :"field",
        //     key         :props.ij,
        //     id          :props.data.id,
        //     data        :props.data,
        //     newValue    :e.target.value
    
        // })
        

    }







    return createPortal(<div>



        <ProcAlfa zIndex={"23"}></ProcAlfa>
        <ProcBase zIndex={"24"}>

            <ProcBase zIndex={"2"}
            onClick={(v) => props.handle({

                call:'cancel'

            })}
            >
            </ProcBase>
            <ProcForm 
            
                ref={refForm}
                top={fromTop}
                zIndex={"3"}
            
            >


                <div className="Message">

                    Rename

                </div>

                <div className="Frm">


                    <div 
                    id="error_message"
                    className="error-message"
                    >
                        Please Fix Inputs
                    </div>


                    <div 
                    className="input-wrap"
                    style={{
                        backgroundColor:fieldStyl.input.bgColor
                    }}

                    >

                        <input
                            type="text"
                            className="input-tag"
                            style={{
                                backgroundColor:fieldStyl.input.bgColor,
                                fontStyle:fieldStyl.input.fontStyle,
                                color:fieldStyl.input.fontColor
                            }}

                            id={"save_name"}
                            value={inputVal}
                            placeholder={"Calculator Name"}

        
                            //onFocus={handleFocus}

                            /*
                            onFocus = {event => {
                                // event properties must be copied to use async
                                const target = event.target;
                                setTimeout(() => target.select(), 0);
                            }}
                            */

                            onFocus = {event => {
                                // event properties must be copied to use async
                                const target = event.target;
                                setTimeout(() => handleFocus(target), 0);
                            }}
                            onBlur={handleBlur}  
                            //onChange={handleChange}
                            onChange={event => {

                                handleChange({

                                    target:event.target

                                })

                            }}


                        />

                    </div>



                    <div 
                    className="ta-wrap"
                    style={{
                        backgroundColor:fieldStyl.textarea.bgColor
                    }}

                    >

                        <textarea
                        className="ta-tag"
                        style={{
                            backgroundColor:fieldStyl.textarea.bgColor,
                            fontStyle:fieldStyl.textarea.fontStyle,
                            color:fieldStyl.textarea.fontColor
                        }}

                        id={"save_descr"}
                        placeholder={"Calculator Description"}
                        value={textAreaVal}


                            onFocus = {event => {
                                // event properties must be copied to use async
                                const target = event.target;
                                setTimeout(() => handleFocus(target), 0);
                            }}
                            //onBlur={handleBlur}  
                            //onChange={handleChange}
                            onChange={event => {

                                handleChange({

                                    target:event.target,
                                    indx:props.ij

                                })

                            }}

                        >
                        </textarea>
                    
                    </div>
                
    

                </div>



                <div 
                className="SaveButton"
                onClick={(v) => props.handle({

                    call:'rename',
                    action:'process'
                })}
                >
                    Save
                </div>

                <div 
                className="CancelButton"
                onClick={(v) => props.handle({

                    call:'cancel'

                })}
                >
                    Cancel

                </div>


            </ProcForm>


        </ProcBase>
        

    </div>, portalTarget);



}

export default Index;


