


const SceneInfo = [





    //##########################################################
    //##########################################################
    //##########################################################

    // AllText TM:


    {
        "styl": {
            "call": "indent",
            "class": "IndentRow",
            "marginTop": "25px",
            "marginBottom": "200px"
        },
        "call": "TxtComponent",
        "info": {
                "call": "flex",
                "gridType": "AllText",
                "gridBgColor": "transparent",
                "gridCall": "grid_cell",
                "cellCall": "default",
                "modCall": "fill_div",
                "thumb": {
                "call": "default",
                "diff": "0"
            },
            "cellBorder": {
                "boolean": true,
                "thickness": 1,
                "color": "gray"
            },
            "gutter": {
                "boolean": "0",
                "size": "0"
            },
            "thresh": {
                "refWidth": false,
                "across1": "501",
                "across2": "801",
                "across3": "1051"
            }
        },
        "items": [



            {
                "call": "cke",
                "data": {
                    "heading": "Sample",
                    "pgraph": "<strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
                },
                "style":{

                    "heading":{

                        "fontFamily": 'Arial, Helvetica, sans-serif',
                        "fontSize": "50px",
                        "fontWeight": "600",
                        "textAlign": "left",
                        "textDecoration": "none",
                        "color": "black"
                    
                      },
                    "pgraph":{
                        "fontFamily": 'Arial, Helvetica, sans-serif',
                        "fontSize": "20px",
                        "fontWeight": "500",
                        "textAlign": "left",
                        "textDecoration": "none",
                        "color": "black"
                      }
                    
                }

            },

            {
                "call": "cke",
                "data": {
                    "heading": "Sample",
                    "pgraph": "<strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
                },
                "style":{

                    "heading":{

                        "fontFamily": 'Arial, Helvetica, sans-serif',
                        "fontSize": "40px",
                        "fontWeight": "600",
                        "textAlign": "left",
                        "textDecoration": "none",
                        "color": "black"
                    
                      },
                    "pgraph":{
                        "fontFamily": 'Arial, Helvetica, sans-serif',
                        "fontSize": "18px",
                        "fontWeight": "500",
                        "textAlign": "left",
                        "textDecoration": "none",
                        "color": "black"
                      }
                    
                }

            },


            {
                "call": "cke",
                "data": {
                    "heading": "Sample",
                    "pgraph": "<strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
                },
                "style":{

                    "heading":{

                        "fontFamily": 'Arial, Helvetica, sans-serif',
                        "fontSize": "32px",
                        "fontWeight": "600",
                        "textAlign": "left",
                        "textDecoration": "none",
                        "color": "black"
                    
                      },
                    "pgraph":{
                        "fontFamily": 'Arial, Helvetica, sans-serif',
                        "fontSize": "16px",
                        "fontWeight": "500",
                        "textAlign": "left",
                        "textDecoration": "none",
                        "color": "black"
                      }
                    
                }

            },


            
            {
                "call": "cke",
                "data": {
                    "heading": "Sample",
                    "pgraph": "<strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
                },
                "style":{

                    "heading":{

                        "fontFamily": 'Arial, Helvetica, sans-serif',
                        "fontSize": "24px",
                        "fontWeight": "600",
                        "textAlign": "left",
                        "textDecoration": "none",
                        "color": "black"
                    
                      },
                    "pgraph":{
                        "fontFamily": 'Arial, Helvetica, sans-serif',
                        "fontSize": "14px",
                        "fontWeight": "500",
                        "textAlign": "left",
                        "textDecoration": "none",
                        "color": "black"
                      }
                    
                }

            },


            

            {
                "call": "cke",
                "data": {
                    "heading": "Sample",
                    "pgraph": "<strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
                },
                "style":{

                    "heading":{

                        "fontFamily": 'Arial, Helvetica, sans-serif',
                        "fontSize": "20px",
                        "fontWeight": "600",
                        "textAlign": "left",
                        "textDecoration": "none",
                        "color": "black"
                    
                      },
                    "pgraph":{
                        "fontFamily": 'Arial, Helvetica, sans-serif',
                        "fontSize": "12px",
                        "fontWeight": "500",
                        "textAlign": "left",
                        "textDecoration": "none",
                        "color": "black"
                      }
                    
                }

            },





            {
                "call": "namevals",
                "data": [
                    {
                    "name": "Facility",
                    "value": "List all facility costs"
                    },
                    {
                    "name": "Business",
                    "value": "List all business cost"
                    },
                    {
                    "name": "Vehicles",
                    "value": "List cost of vehicles including gas, insurance and lease or buy monthly payments"
                    },

                    {
                    "name": "Equip. Software",
                    "value": "List costs of computers, printers, laminators and any internet subscriptions."
                    }
                ]
            },




            {
                "call": "namevals",
                "data": [
                    {
                    "name": "MyFakeName-4000",
                    "value": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque"
                    },
                    {
                    "name": "MyFakeName-fakeName-is-on",
                    "value": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque"
                    },
                    {
                    "name": "MyFakeName",
                    "value": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque"
                    }
                ]
            },








            // {
            //     "call": "links",
            //     "data": [
            //         {
            //         "name": "Landing Page",
            //         "value": "http://site.site/LandingPage",
            //         "position": "left",
            //         "mainWidth":"100%"
            //         },
            //         {
            //         "name": "Billboard",
            //         "value": "http://site.site/Billboard",
            //         "position": "center",
            //         "mainWidth":"100%"
            //         },
            //         {
            //         "name": "PDM",
            //         "value": "http://powerdigitalmedia.net",
            //         "position": "right",
            //         "mainWidth":"98%"
            //         }
            //     ]
            // },

            // {
            //     "call": "buttons",
            //     "data": [
            //         {
            //         "name": "PDM",
            //         "value": "http://powerdigitalmedia.net",
            //         "position": "left",
            //         "mainWidth":"100%"
            //         },
            //         {
            //         "name": "Billboards",
            //         "value": "http://site.site/Billboard",
            //         "position": "center",
            //         "mainWidth":"100%"
            //         },
            //         {
            //         "name": "PDM",
            //         "value": "http://powerdigitalmedia.net",
            //         "position": "right",
            //         "mainWidth":"98%"
            //         }
            //     ]
            // },

            {
                "call": "style",
                "data": {

                    "button": {
                        "backgroundColorOver": "#fc0",
                        "backgroundColorOut": "#0291cd",
                        "borderOver": "1px solid black",
                        "borderOut": "1px solid #0291cd",
                        "textColorOver":"black",
                        "textColorOut": "white"
                    },
                    "link": {
                        "textColorOver": "orange",
                        "textColorOut": "#0291cd"
                    }
                }
            }


        ]
    }







];


export default SceneInfo;






/*

{
    "call": "collage",
    "gridType": "Fixed",
    "gridBgColor": "transparent",
    "gridCall": "grid_cell",
    "cellCall": "container",
    "modCall": "fill_div",
    "cells": {
      "call": "default",
      "total": 1,
      "across": 1
    },
    "thumb": {
      "call": "default",
      "diff": "0",
      "bgColor": "black"
    },
    "cellBorder": {
      "boolean": false,
      "thickness": 0,
      "color": "lightgray"
    },
    "gutter": {
      "boolean": "0",
      "size": "0"
    },
    "thresh": {
      "refWidth": false,
      "across1": "501",
      "across2": "801",
      "across3": "1051"
    },
    "mask": {
      "boolean": false,
      "message": {
        "boolean": false,
        "backgroundColor": "#4CAF50",
        "textColor": "white"
      }
    },
    "opacity": 1,
    "webkitFilter": "grayscale(0%)",
    "filter": "grayscale(0%)",
    "view": "fullScreen",
    "viewHeight": "613px",
    "thumbHInt": 723
}


*/

