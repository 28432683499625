
import React, { 

    useState, 
    useEffect,
    //useRef

} from 'react';

import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';

import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    //useHistory,   
    //useParams,

} from "react-router-dom";




import { 
    LibForm, 
    //LibString 
} from 'library';

// import BuildItems from '../BuildItems';

import ApiResources from 'utils/components/Pages/ApiResources';


//------------------------
//Axios Stuff
//------------------------

import axios from "axios";

const queryString = params =>
    Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join("&")

const createUrl = (url, queryOptions) => {

    if(Object.keys(queryOptions).length > 0)
    {
        url = url + "?" + queryString(queryOptions);
    }
    return url

}

const createPostParams = (bodyOptions) => {

    var ret;
    var params = "";
    var count = 0;
    for(var keyname in bodyOptions)
    {
        if(count === 0)
        {
            params += keyname+"="+bodyOptions[keyname];
        } else {
            params += "&"+keyname+"="+bodyOptions[keyname];
        }
        count++;
    }

    ret = params;
    if(ret === "") ret = false;
    return ret;

}




export const Submit = (props) => {


    var apiDomain;
    var hostname;
    //var hostname = window.location.hostname;//returns the domain name of the web host
    var protocol = window.location.protocol;//returns the web protocol used (http: or https:)


    if(window.location.hostname.match(/192.168/gi))
    {
        hostname = ApiResources.network['3001'].vpc;
        apiDomain = protocol + "//" + hostname + "/";
    }
    else
    if(window.location.hostname.match(/localhost/gi))
    {
        hostname = ApiResources.local['3001'].vpc;
        apiDomain = protocol + "//" + hostname + "/";
    }else{
        hostname = ApiResources.aws['x'].vpc;
        apiDomain = "https://" + hostname + "/";
    }//=








    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();

    const hashbang = location.hash;



    // -- redux -- //
    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
	//console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));



    // -- state -- //
    const [inProcess, setInProcess] = useState(false)

    useEffect(() => {


        const runAxios = (eff) => {


            let url = eff.resrc;
            let options = eff.options;


            //let result;

            let axiosParams;
            if(options.method.match(/GET/gi))
            {
                axiosParams = {
                    method: "GET",
                    url: createUrl(url, options.query),
                }
                
            }
            else
            if(options.method.match(/POST/gi))
            {

                axiosParams = {

                    method: "POST",
                    url: createUrl(url, options.query),
                    data: createPostParams(options.body),
                    headers: {

                        //'Content-Type': "application/json",
                        'Content-Type'  : 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                        //'Authorization' : 'Bearer '+localStorage.accessToken
                    },
                    // responseType:'json'
                }

            }

            // Content-Type
            //     'application/x-www-form-urlencoded' 
            //     'application/json' 
            
            // responseType 
            //     'arraybuffer', 
            //     'blob', 
            //     'document', 
            //     'json', 
            //     'text', 
            //     'stream'


            axios(axiosParams)
            .then(response => {

                // console.log("response: "+JSON.stringify(response,null,2));
                // console.log("response.data: "+response.data);
                // alert(response.data);

                return response.data;

            })
            .then(data =>  {

                console.log("Object prototype: "+Object.prototype.toString.call(data));
                console.log("data.success: "+data.success);
                console.log("data.result: "+data.result);
                console.log("data.result: "+JSON.stringify(data.result,null,2));



                // alert('Dashboard/Account - success');

                // if(props.key.match(/MSZ/gi))
                // {

                //     console.log("MSZ RESULT: "+JSON.stringify(result,null,2));
                //     //alert('success');


                // }

                /*
                result={
                    "success": true,
                    "result": {
                        "tokenError": {
                            "name": "TokenExpiredError",
                            "message": "jwt expired",
                            "expiredAt": "2020-12-27T18:13:18.000Z"
                        },
                        "authorized": false,
                        "user": false
                    }
                }

                */
 
                //alert(data.success);
            
                if(data.success)
                {
                    reset = false;
                    //alert("reset: "+reset);


                    props.handle({

                        call    :"submit",
                        success:data.success.result,
                        message :data.success.message,
                        //message :"SUCCESS: Process was successfull!!" 
                        data:data.result,
                        reset   :reset
                
                    });


                    // switch(props.call)
                    // {
                    // case'delete':

                    //     props.handle({

                    //         call    :"completeConfirm",
                    //         //message :"SUCCESS: Process was successfull!!"
                    //         message :data.success.message,
                    //         reset   :reset
                    
                    //     });

                    // break;
                    // default:

                    //     //alert('complete');
                    //     props.handle({

                    //         call    :"complete",
                    //         //message :"SUCCESS: Process was successfull!!"
                    //         message :data.success.message,
                    //         reset   :reset
                    
                    //     });
                    // }



                }else{

                    //alert("Account axios error - 1.2");
                    // props.handle({

                    //     call    :"error",
                    //     message :"SUCCESS: No Data",
                    //     reset   :false
                
                    // });


                    // ::: Testing :::

                    props.handle({

                        call    :"submit",
                        success:"FAILED",
                        message :"SUCCESS: No Data",
                        reset   :false
                
                    });




                }


            })
            .catch(error =>  {


                console.log("FAILED");
                console.log(error);


                //alert("Account error - 1.1");
                props.handle({

                    call       :"error",
                    type       :'process',
                    errors     :[{

                        "key"       :"0-process",
                        "section"   :"process",
                        "message"   :error.message
    
                    }]
        
                });


            });




        };//runAxios
        
        //#############################################################################
        //#############################################################################

        /**
         * 
         * 
         * To block multiple hits to the server 
         * 
         * 1) Set response to "processing" or "not null" once fetch begins - this prevents a function rerun
         * 2) Keep loading set to true until fetch is complete
         * 3) On page component check that loading is "false" before calling dispatch
         * 
         * 
         * NOTE: There are two version async and non async - no differcene I see between the two
         * use the non async version
         * 
         * 
         */





        if(!inProcess)
        {

            setInProcess(true);


            var isOkay = false;
            var okar = [];  
            var ear = [];

            var frm;
            var label;
            var field;
            var array;
            var object;
            var err;

            //var indx;
            var i;
            var j;

            var newVal;


            //console.log(JSON.stringify(formArr,null,2));
            //alert('submit check');



            switch(props.call)
            {
            case'delete':
                isOkay = true;
            break;
            default:


                for(i=0; i < props.formArr.length; i++)
                {
                    
                    frm = props.formArr[i];

                    switch(frm.section)
                    { 
                    case'header':
                    case'headline':
                    case'bar':


                        okar.push({

                            "section":frm.section,

                            "name":frm.section,
                            "value":frm.data.text

                        })


                    break; 
                    case'checkboxes':
                    case'radiobuttons':


                        if(frm.data.required)
                        {
                            if(frm.data.choice.length === 0)
                            {
                                ear.push({

                                    "key"       :i,
                                    "section"   :frm.section,
                                    "name"      :frm.data.name,
                                    "message"   :frm.data.name+" (Please Choose)"
                            
                                });
                            }
                        
                        }//== if


                        newVal = frm.data.choice;
                        if(Object.prototype.toString.call(newVal) === '[object Array]') newVal = frm.data.choice.join();

                        okar.push({

                            "section":frm.section,

                            "name":frm.data.name,
                            "value":newVal

                        })

                    break;
                    case'slider':
                    case'dualslider':


                        //console.log("DATA: "+JSON.stringify(frm.data,null,2));

                        if(frm.data.required)
                        {
                            if(frm.data.value === '')
                            {
                                ear.push({

                                    "key"       :i,
                                    "section"   :frm.section,
                                    "name"      :frm.data.name,
                                    "message"   :frm.data.name+" (Please Select)"
                            
                                });
                            }
                        
                        }//== if


                        okar.push({

                            "section":frm.section,

                            "name":frm.data.id,
                            "value":frm.data.value

                        })

                    break;
                    case'fields':


                    
                        array = frm.data;
                        for(j=0; j < array.length; j++)
                        {

                        
                            field = array[j];
                            if(field.required)
                            {

                                //console.log(field.text+" === "+field.hint);


                                if(field.text === ''
                                || field.text === field.hint
                                || field.text === undefined
                                )
                                {

                                    ear.push({

                                        "key"       :i+"-"+j,
                                        "section"   :frm.section,
                                        "name"      :field.id,
                                        "message"   :field.hint+" (Required)"
                                
                                    });


                                }//== if
                                else
                                {

                                    if(field.id.match(/email/gi)
                                    )
                                    {
                                        err = LibForm.Validate_Email(field.text,field.hint)
                                        if(err)
                                        {
                                            ear.push({

                                                "key"       :i+"-"+j,
                                                "section"   :frm.section,
                                                "name"      :field.id,
                                                "message"   :field.hint+" (Invalid)"
                                        
                                            });
                                            
                                        }
                                    
                                    }

                                }

                            }//== if




                            okar.push({

                                "section":"field",

                                "fieldtype":field.fieldtype,
                                "hint":field.hint, 

                                "name":field.id,
                                "value":field.text

                            })



                        }//== j


                    break;
                    case'labelfields':




                        //console.log(JSON.stringify(frm.data,null,2));
                        //alert("Check frm.data");
                    

                        array = frm.data;
                        for(j=0; j < array.length; j++)
                        {

                    
                            label = array[j].label.text;
                            field = array[j].field;
                            if(field.required)
                            {
                                //console.log(field.text+" === "+field.hint);

                            
                                if(field.text === ''
                                || field.text === field.hint
                                || field.text === undefined
                                )
                                {
                                    ear.push({

                                        "key"       :i+"-"+j,
                                        "section"   :frm.section,
                                        "name"      :field.id,
                                        "message"   :label+" (Required)"
                                
                                    });
                                }

                            }//== if


                            okar.push({

                                "section":"labelfield",

                                "label":label,

                                "fieldtype":field.fieldtype,
                                "hint":field.hint, 

                                "name":field.id,
                                "value":field.text

                            })


                        }//== j



                    break;
                    default:
                    break;
                    }//==


                }//== i



                //var laborTypeCount = 0;

                props.handle({call:"clear"});
                if(ear.length > 0)
                {
                    //console.log("ERROR ARRAY: "+JSON.stringify(ear,null,2));
                    
                    //alert('Account before axios error - 1.1');
                    props.handle({

                        call       :"error",
                        type       :'process',
                        errors     :ear
            
                    });


                    // var matchDirectLabor = new RegExp("_labor_direct","gi");
                    // var matchIndirectLabor = new RegExp("_labor_indirect","gi");

                    // array = JSON.parse(JSON.stringify(ear));
                    // for(i=0; i < array.length; i++)
                    // {
                    //     if(array[i].name.match(matchDirectLabor) 
                    //     )
                    //     {
                    //         ear[i].message = "Direct Labor(Please Choose)";
                    //         laborTypeCount = laborTypeCount + 1;
                    //     }
                    //     if(array[i].name.match(matchIndirectLabor) 
                    //     )
                    //     {
                    //         ear[i].message = "Indirect Labor(Please Choose)";
                    //         laborTypeCount = laborTypeCount + 1;
                    //     }

                    // }//==for


                    // if(laborTypeCount === 1 && ear.length === 1)
                    // {
                    //     isOkay = true;
                    // }else{

                    //     if(laborTypeCount === 1)
                    //     {
                    //         for(i=0; i < array.length; i++)
                    //         {
                    //             if(array[i].name.match(matchDirectLabor)){ ear.splice(i,1); break; }
                    //             if(array[i].name.match(matchIndirectLabor)){ ear.splice(i,1); break; }
                                
                    //         }//==for

                    //     }
                    //     if(laborTypeCount === 2)
                    //     {
                    //         for(i=0; i < array.length; i++)
                    //         {
                    //             if(array[i].name.match(matchDirectLabor)){ ear[i].message = "Labor Type(Choose One}"; }
                    //             if(array[i].name.match(matchIndirectLabor)){ ear[i].message = ""; }
                                
                    //         }//==for

                    //     }


                    //     //alert('Account before axios error - 1.1');
                    //     props.handle({

                    //         call       :"error",
                    //         type       :'process',
                    //         errors     :ear
                
                    //     });

                    // }


                }
                else
                {
                    isOkay = true;
                }


            }// -- props.call 


       

            if(isOkay)
            {
                var editCall;    
                var editName;
                var reset; 
                var items,item;
                var _VARS;
                var testOutput;

                var accountType,accountData;


                //items = okar;
                items = [];
                for(object of okar)
                {
                    if(object.section === 'field')
                    {
                        items.push({

                            name:object.name,
                            value:object.value

                        });
                    }
                }


                accountType = props.walkChoice;
                accountData = {};
                for(item of items)
                {
                    if(item.name !== 'pword')
                    {
                        accountData[item.name] = item.value;
                    }
                }

          



                var regxAccount = new RegExp("account","gi");
               
                if(props.postTo || props.postTo !== undefined) 
                {
                    if(Object.prototype.toString.call(props.postTo) === '[object String]')
                    {
                        if(props.postTo.match(regxAccount)) 
                        {
                            editCall = "account";         
                        }
                    }
 
                }//==


                reset = false;

                switch(editCall)
                {
                case'account':

                    editName = 'EDIT "Dashboard" ';
                    reset = {
                        key:'account',
                    };
                break;
                default:

                    editName = 'Form Submit';
                }//==



                //var view = JSON.stringify(okar,null,2);
                //console.log("VIEW: "+view);


                // props.handle({

                //     call       :"submitCred",
                //     type       :'process',
                //     errors     :okar
        
                // });


                // console.log(JSON.stringify(okar,null,2));
                // alert('view Account Before Submit');
   



                //  items = BuildItems({

                //     hashbang    :hashbang,
                //     zoneObj     :Object.assign({},zoneObj),        
                //     currentKey  :props.currentKey,
                //     call        :props.call,
                //     array       :okar

                // });
                // // console.log(JSON.stringify(items,null,2));
                // // alert('view Account Before Submit');
              


                // if(items.processName 
                // && items.processName !== undefined) 
                //     editName = 'EDIT "'+LibString.CapitalizeWords(items.processName)+'" ';

                // console.log("Edit Name: "+editName);



     

                testOutput = false;
                if(testOutput)
                {

                    //console.log("POST TO: "+props.postTo);


                    // _VARS = JSON.stringify({

                    //     key         :props.currentKey,  
                    //     call        :props.call,
                    //     action_name :editName,
                    //     local_items :items
                        
                    // });
                    // console.log(_VARS);
                    // // return false;

                    var keyname;
                    var html = '';
                    var str;


                    switch(props.call)
                    {
                    case'delete':


                        for(keyname in items)
                        {
                            html += "<h2>"+keyname+"</h2>";
            
                            str = items[keyname];
                            str = JSON.stringify(str,null,2);
                            str = str.replace(/\n/gi,"<br/>&nbsp;");
            
                            html += str;

                        }//==
                
                        props.handle({

                            call:"showReadr",
                            data :{

                                data:{
                                    name:"Readr",
                                    value:html
                                },
                                flyr:{
                                    type:'print',
                                    styl:{
                                        maxWidth:750
                                    }
                                }

                            }
            
                        });


                    break;
                    default:

                        // props.handle({

                        //     call        :"output",
                        //     message     :"SUCCESS: Run Output Here",
                        //     data        :{
            
                        //         call    :"print",
                        //         data    :items
                        //     }
            
                        // });



                        html += "<h2>CheckCred - Data</h2>";
        
                        // str = items[keyname];
                        // str = JSON.stringify(str,null,2);
                        // str = str.replace(/\n/gi,"<br/>&nbsp;");
        
                        // html += str;

                        html += "Walk Choice: "+props.walkChoice;
                        html += "<br/>";
                        html += "<pre>" + JSON.stringify(items,null,2) + "</pre>";

                
                        props.handle({

                            call:"showReadr",
                            data :{

                                data:{
                                    name:"Readr",
                                    value:html
                                },
                                flyr:{
                                    type:'print',
                                    styl:{
                                        maxWidth:750
                                    }
                                }

                            }
            
                        });




                    }//== switch


                }
                else
                {


                    // _VARS = JSON.stringify({

                    //     key             :props.currentKey,  
                    //     call            :props.call,
                    //     action_name     :editName,
                    //     local_items     :items,
                    //     account_type    :props.walkChoice
                        
                    // });


                    _VARS = JSON.stringify({

                        account:{
                            type:accountType,
                            data:accountData
                        },
                        local_items:items,
                        
                    });


                    // ::: Testing :::

                    // console.log(JSON.stringify(JSON.parse(_VARS),null,2));
                    // //console.log(JSON.stringify(items,null,2));
                    // props.handle({

                    //     call    :"submit",
                    //     message :"TEST: Submit - Pre Post Vars Check",
                    //     reset   :false
                
                    // });
                    // alert("Submit - Testing");
                    // return false;



                    var site,email,pword,accountId;


                    if(apiDomain.match(/localhost/))
                    {
                        site = apiDomain;
                    }else{
                        site = window.location.hostname;
                    }


                    // hostname = ApiResources.aws['x'].vpc;
                    // apiDomain = "https://" + hostname + "/";
                    //var resrc = apiDomain + "fetch-siteplan";
                    //var resrc = apiDomain + "fetch-blueprint";


                    var resrc = apiDomain + "tpc-signup-walk-submit";
                    //if(!props.postTo || props.postTo !== undefined) resrc = props.postTo;
                    //alert("RESRC: "+resrc);


                    site = 'theprofitcalculator.com';
                    email = false;
                    pword = false;
                    accountId = false;

                    // if(window.location.hostname)
                    // {
                    //     site = window.location.hostname;
                    // }
                    // if(localStorage.accessEmail && localStorage.accessEmail !== undefined)
                    // {
                    //     email = localStorage.accessEmail;
                    // }
        
                    for(item of items)
                    {

                        //console.log("ITEM: "+JSON.stringify(item,null,2));

                        if(item.name === 'email')
                        {
                            email = item.value;

                        }
                        if(item.name === 'pword')
                        {
                            pword = item.value;

                        }
                        if(item.name === 'accountId')
                        {
                            accountId = item.value;

                        }

                    }// ==  for


                    // alert("Item");




        
                    // var href = window.location.href;                //returns the href (URL) of the current page
                    // var host = window.location.host;                //returns the domain with port number
                    // var hostname = window.location.hostname;        //returns the domain name of the web host
                    // var pathname = window.location.pathname;        //returns the path and filename of the current page
                    // var protocol = window.location.protocol;        //returns the web protocol used (http: or https:)
                    // var assign = window.location.assign;            //loads a new document
                    
                    // alert(""
                    // +"\nlocation: "+JSON.stringify(location,null,2)
                    // +"\nhostname: "+window.location.hostname
                    // +"\nsite: "+site
                    // +"\nemail: "+email
                    // +"\naccessEmail: "+localStorage.accessEmail
                    // +"\naccessToken: "+localStorage.accessToken 
                    // +"\nrefreshToken: " +localStorage.refreshToken
                    // );
        
                
                    runAxios({
        
                        resrc:resrc,
                        options:{
        
                            method:"POST",
                            body:{

                                site        :site,
                                email       :email,
                                pword       :pword,
                                accountId   :accountId,
                                // tree     :"app",
                                // route    :'app',
                                vars        :_VARS,
                                action      :editName

                            },
                            query:{}
        
                        }
        
                            
                    });


                }// -- run

                    
            }//== isOkay


        }
        else
        {

            console.log("Axios - Account Processing...");


        }//-- inProcess


   
    }, [

        hashbang,
        inProcess,
        zoneObj,
        props,
        apiDomain
 
    ])


    return <div></div>


}



