import React, { 

    //useState, 
    //useEffect, 
    //useRef 

} from 'react';

// import { 
//     useSelector, 
//     //useDispatch 
// } 
// from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';

import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation, 
    useHistory,  
    //useParams

} from "react-router-dom";

// import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider';
// import { useAuthentication } from 'utils/hooks/Authentication';





/*
import {

    //Header,
    Footer,
    //InfoBar

} from 'app-components/Prime/Commons';
*/


// import {

//     Nav,
//     //SubNav,

// } from '../../Navigation';

import {

    Stage,
    ScrollBox,
    Area,
    AreaTitle,
    //PrintOut

} from '../Styled';

//import StylScene from '../StylScene.module.css';

//import Loader from 'utils/components/Process/Loader';



import Logo from 'utils/components/Logo';




export const AuthActions = (props) => {


    // -- router -- //
    const location = useLocation(); 
    const history = useHistory();
    //let match = useRouteMatch();
    //let params = useParams();
 
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);


    // -- window dims -- //
    //const { height } = useWindowDimensions();

    // -- authentication -- //
    //const auth = useAuthentication();
    //console.log("AUTH: "+auth);




    // -- redux -- //
    //const dispatch = useDispatch();
    //const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));
    

    // -- ref -- //
    //const refA = useRef(null);
    //const refB = useRef(null);
    //const refC = useRef(null);



    // -- state -- //
    //const [isLoaded, setIsLoaded] = useState(false);
    //const [info, setInfo] = useState(false);

    //const [stageTopHeight, setStageTopHeight] = useState(50);
    //const [stageHeight, setStageHeight] = useState(50)
    //const [scrollboxHeight, setScrollboxHeight] = useState(50);

    // useEffect(() => {

    //     //https://stackoverflow.com/questions/58773210/trying-to-use-cleanup-function-in-useeffect-hook-to-cleanup-img-onload


    //     //var primeControlsInt = document.getElementById("primeControls").offsetHeight;

    //     //var diff = refA.current.offsetHeight + refB.current.offsetHeight + refC.current.offsetHeight;
    
    //     //alert(refA.current.offsetHeight+"\n"+refB.current.offsetHeight+"\n"+refC.current.offsetHeight);

    //     //var stageHeight = (height - refC.current.offsetHeight) - 7 + "px";
    //     //setStageHeight(stageHeight);

    //     //var scrollboxHeight = (height - diff) - (primeControlsInt + 0) + "px";
    //     //setScrollboxHeight(scrollboxHeight);

    //     //console.log(JSON.stringify(zoneObj,null,2));



    //     if(!auth)
    //     {
    //         //alert("auth: "+auth);

    //         //var basename = zoneObj.basename;
    //         var url = '/Login/';
    //         //var url = basename + 'AuthArea/Login';
    //         //var url = "/templates/AuthArea/FormLogin";
    //         //history.replace(url);
    //         history.push(url);
    //         //history.goBack();
    //         //window.location.replace(url);//regular dom
            
    //     }else{

    //         // console.log(auth);

    //         // RESULT: {
    //         //     "success": true,
    //         //     "result": {
    //         //       "tokenError": false,
    //         //       "authorized": true,
    //         //       "user": {
    //         //         "name": "powerdigitalmedia.net~z21hawwuy29t",
    //         //         "email": "powerdigitalmedia.net@gmail.com",
    //         //         "iat": 1624267607,
    //         //         "exp": 1624271207
    //         //       }
    //         //     }
    //         // }


    //         // if(isLoaded)
    //         // {

    //             //setIsLoaded(true);

    //             // var info = JSON.stringify(auth,null,2);
    //             // info = "<pre>"+info+"</pre>";
    //             // setInfo(info);


    //         //}


    //     }

        
    
    // },[

    //     location,
    //     history,
    //     height,
    //     zoneObj,
    //     auth,
    //     //isLoaded
    // ])



    const handleUrl = (hndl) => {


        var url = false;

        switch(hndl.call)
        {
        case'login':

            url = "/Login/";
        break;
        case'forgot':

            url = "/Forgot/";
        break; 
        case'SignUpWalk':

            url = "/SignUpWalk/";
        break;
        case'SignUpForm':

            url = "/SignUpForm/";
        break;
        case'cancel':

            url = "/CancelForm/";
        break;
        case'delete':

            url = "/DeleteForm/";
        break;
        default:
            url = hndl.url;
        }

        if(url) history.push(url);


    };






    return (

        <Stage
        //height={stageHeight}
        >

            <ScrollBox
            //height={scrollboxHeight}
            >


                <div
                style={{
                    backgroundColor:"gray",
                    width:"auto",            
                    height:"auto",
                    margin:"0 auto 0 auto",
                    padding:"0 0 0 0",         
                    position:"relative",
                    floar:"left"


                }}

                onClick={(v) => handleUrl({
                    call:'url', 
                    action:"push", 
                    url:"/"
                })}

                >

                    <Logo 

                        call="theprofitcalculator"

                    />

                </div>




                <Area>

                    <AreaTitle> Auth Actions </AreaTitle>

  

                    <div style={{

                        backgroundColor:"transparent",
                        width:"95%",
                        height:"auto",
                        minHeight:"300px",
                        margin:"25px auto 25px auto",
                        padding:"40px 0 40px 0",

                    }}>



                        <div 
                        style={{

                            backgroundColor:"transparent",
                            width:"100%",
                            height:"auto",
                            margin:"5px 0 5px 0",
                            padding:"0 0 0 0",
                            position:"relative",
                            float:"left",

                            fontFamily:'Arial, Helvetica, sans-serif',
                            fontSize: "24px",
                            fontWeight:"500",
                            textAlign: "center",
                            textDecoration: "none",
                            color:"#edeef1",

                            cursor:'pointer'


                        }}

                        onClick={(v)=>handleUrl({

                            call:'login'

                        })}
                        
                
                        >

                            Login Form

                        </div>




                        <div 
                        style={{

                            backgroundColor:"transparent",
                            width:"100%",
                            height:"auto",
                            margin:"5px 0 5px 0",
                            padding:"0 0 0 0",
                            position:"relative",
                            float:"left",

                            fontFamily:'Arial, Helvetica, sans-serif',
                            fontSize: "24px",
                            fontWeight:"500",
                            textAlign: "center",
                            textDecoration: "none",
                            color:"#edeef1",

                            cursor:'pointer'


                        }}

                        onClick={(v)=>handleUrl({

                            call:'forgot'

                        })}
                        
                
                        >

                            Forgot Form

                        </div>






                        <div 
                        style={{

                            backgroundColor:"transparent",
                            width:"100%",
                            height:"auto",
                            margin:"5px 0 5px 0",
                            padding:"0 0 0 0",
                            position:"relative",
                            float:"left",

                            fontFamily:'Arial, Helvetica, sans-serif',
                            fontSize: "24px",
                            fontWeight:"500",
                            textAlign: "center",
                            textDecoration: "none",
                            color:"#edeef1",

                            cursor:'pointer'


                        }}

                        onClick={(v)=>handleUrl({

                            call:'SignUpWalk'

                        })}
                        
                
                        >

                            Sign Up Walkthrough

                        </div>





                        <div 
                        style={{

                            backgroundColor:"transparent",
                            width:"100%",
                            height:"auto",
                            margin:"5px 0 5px 0",
                            padding:"0 0 0 0",
                            position:"relative",
                            float:"left",

                            fontFamily:'Arial, Helvetica, sans-serif',
                            fontSize: "24px",
                            fontWeight:"500",
                            textAlign: "center",
                            textDecoration: "none",
                            color:"#edeef1",

                            cursor:'pointer'


                        }}

                        onClick={(v)=>handleUrl({

                            call:'cancel'

                        })}
                        
                
                        >

                            Cancel Form

                        </div>


                        <div 
                        style={{

                            backgroundColor:"transparent",
                            width:"100%",
                            height:"auto",
                            margin:"5px 0 5px 0",
                            padding:"0 0 0 0",
                            position:"relative",
                            float:"left",

                            fontFamily:'Arial, Helvetica, sans-serif',
                            fontSize: "24px",
                            fontWeight:"500",
                            textAlign: "center",
                            textDecoration: "none",
                            color:"#edeef1",

                            cursor:'pointer'

                        }}

                        onClick={(v)=>handleUrl({

                            call:'delete'

                        })}
                        

                        >

                            Delete Form

                        </div>


                    </div>


                </Area>

            </ScrollBox>

        </Stage>

    )


}


