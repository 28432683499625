const ComposeBluntFields = (props) => {


    var R = [];
    var fieldtype;
    // var hint;
    var def;
    var val;
    var text;




    // console.log("Bluntfields -- "+JSON.stringify(props,null,2));




    switch(Object.prototype.toString.call(props))
    {
    case'[object Array]':
    break;
    case'[object Object]':



        //console.log("PROPS: "+JSON.stringify(props,null,2))

        for(var keyname in props)
        {

            fieldtype = props[keyname].print;

       
            switch(fieldtype)
            {
            case'standard': fieldtype = 'text'; break;
            //case'price': fieldtype = 'text'; break;
            //'percent': fieldtype = 'text'; break;
            default:
            }


            switch(fieldtype)
            {
            case'headline':


                /*
                "section"   :"headline",
                "data"      :{
        
                    "text"  :'How did you hear about Power Digital Media'
        
                }
                */


                R.push({

                    section:"headline",
                    data:{

                        text:props[keyname].value

                    }

                });


            break;
            default:

                // hint = props[keyname].name;

                def = props[keyname].default;
                val = props[keyname].value;

 
                switch(props[keyname].print)
                {
                case'price':
            
                    def = parseFloat(def);
                    val = parseFloat(val);

                    def = def.toFixed(2);
                    val = val.toFixed(2);

                    if(isNaN(def)) def = 0;
                    if(isNaN(val)) val = 0;

                break;
                case'percent':

                    def = parseFloat(def);
                    val = parseFloat(val);

                    def = def.toFixed(1);
                    val = val.toFixed(1);

                    if(isNaN(def)) def = 0;
                    if(isNaN(val)) val = 0;

                break;
                default:
                }

                if(val !== '') 
                    text = val;
                else
                    text = def;


                R.push({


                    display : 'percent',

                    label:{
    
                        text    :props[keyname].name
    
                    },
                    field:{
    
                        //"fieldtype"               :"text",
                        "fieldtype"                 :props[keyname].print,
                        "required"					:props[keyname].required,
    
                        "class"                   	:'',
                        "id"                      	:keyname,
                        "hint"                    	:"",
    
                        "text"                     	:text,

                        "linecolor"                 :props[keyname].linecolor,
    
                        "keyboardType"            	:"",
                        "autocorrect"             	:"false",
                        "autocapitalizationType"  	:"none",
    
                        //"secure"                  :true,
                        //"returnKeyType"           :"done"
    
                    }
    
    
                });

            break;
            }



        }



    break;
    case'[object String]':
    break;
    default:
    }


    return R;


}
export default ComposeBluntFields;





// //--------------------------------
// //profit
// //--------------------------------

// {


//     display : 'percent',

//     label: {

//         "text"               	    :"Profit"
//     },

//     field: {


//         "fieldtype"               	:"text",
//         "required"					:required_calcby,

//         "class"                   	:'',
//         "id"                        :'calcby',
//         "hint"                    	:"",

//         "text"                     	:def_calcby,

//         "linecolor"                 :"#00ff00",


//         "keyboardType"            	:"",
//         "autocorrect"             	:"false",
//         "autocapitalizationType"  	:"none",


//         //"secure"                  :true,
//         //"returnKeyType"           :"done"


//     }




// }

