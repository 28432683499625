import React, { 

    useState, 
    useEffect, 
    //useRef 

} from 'react';

import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,   
    //useParams


} from "react-router-dom";


import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider';



import FixedGrid from './FixedGrid';
import FlexGrid from './FlexGrid';
import FilmStrip from './FilmStrip';
import Collage from './Collage';



import {

    LibArray

} from 'library';




const Index = (props) => {


    //console.log(JSON.stringify(props,null,2));

    const { width } = useWindowDimensions();



    // -- router -- //
    const location = useLocation(); 
    //let match = useRouteMatch();
    //let params = useParams();


    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);





    // -- state -- //
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [hashData, setHashData] = useState(false);


    useEffect(() => {


        setHashData(false);
        
        //console.log(JSON.stringify(images,null,2));
        //alert("Flex Height");
    
    
        /*
        alert(""
            +"\n"
            +`PUBLIC: ${process.env.PUBLIC_URL}`
            +"\n"
            +"WINDOW LOCATION ORIGIN: "+window.location.origin
            +"\n"
            +"WINDOW LOCATION HREF: "+window.location.href 
        );
        */
        
        
        let posArr = [];
        let tempArr = [];
        let shuffle = false;

        const Complete = (array) => {


            //console.log(":::"+location.pathname+":::");
            //console.log("Images Finished Loading (Fluid)")

            //console.log(JSON.stringify(array,null,2));
            //alert('images finished loading');


            if(array.length > 0)
            {
                setIsLoaded(true);
                setItems(array);

            }else{
                setIsLoaded(true);
                setError({message:"Process Image (FAILED)"});
            }
        
        }



        var skip;
        skip = false;
        if(props.info.call === 'vid') 
            skip = true;


        if(items.length === 0
        && !skip
        )
        {     
            //console.log(JSON.stringify(props.items,null,2));
            //alert("props.items - "+props.items.length);

            var images = props.items;
            images.map(item => (
            
                item.image !== undefined
                ?
                    updateData(item)

                :null
            
            ))
            function updateData(item) {
            

                if(item.image.src.match(/http/g))
                {
                     
                    posArr.push(item.id);
                    tempArr.push({
            
                        //position    :item.position,
            
                        id          :item.id,
                        title       :item.title,
                        text        :item.text,
                        bgColor     :item.bgColor,
                        image:{
            
                            src:item.image.src,
                            w:item.image.w,
                            h:item.image.h
                        }
            
                    })
            
                    if(tempArr.length === images.length)
                    {
                        //posArr.sort();

                        //posArr.sort(); // Sort by item.id SEE above
                        if(shuffle) posArr = LibArray.ShuffleArray(posArr);
            
                        //console.log("POS: "+posArr.length)
                        //console.log("TEMP: "+tempArr.length)
                        //console.log("TEST LEN: "+testData.length)
                    
            
                        var array = [];
                        for(var i=0; i < posArr.length; i++)
                        {
                            for(var j=0; j < tempArr.length; j++)
                            {
                                //console.log(posArr[i]+" === "+tempArr[j]['id']);
            
                                if(posArr[i] === tempArr[j]['id'])
                                {
                                
                                    array.push(tempArr[j]);
                                    //console.log("break");
                                    break;
            
                                }//==
            
                            }//==
            
                        }//==
            
                        Complete(array);
            
                    }//==
            


                }
                else
                {
                    
    
                    //console.log("ENV PUBLIC URL: "+process.env.PUBLIC_URL);
                
                    var sourceurl = `${process.env.PUBLIC_URL}` + item.image.src;
                    //var sourceurl = `${process.env.PUBLIC_URL}` + item.image.src;
                    //var sourceurl = bucketPath + item.image.src;

                    //console.log("BUCKET PATH: "+bucketPath);
                    //console.log("IMAGE URL: "+imageUrl);
                    //console.log("SOURCEURL: "+sourceurl);

                
                    var img = new Image();
                    img.error = function(){
                        console.log("IMAGE ERROR");
                    };
                    img.onload = function(){
                
                        //console.log("IMAGE IS LOADED");
                
                        //console.log('My width is: ', img.naturalWidth);
                        //console.log('My height is: ', img.naturalHeight);
                
                        var imgW = img.naturalWidth;
                        var imgH = img.naturalHeight;
                
                    
                        posArr.push(item.position);
                        tempArr.push({
                
                            position    :item.position,
                
                            id          :item.id,
                            title       :item.title,
                            text        :item.text,
                            bgColor     :item.bgColor,
                            image:{
                
                                src:item.image.src,
                                w:imgW,
                                h:imgH
                            }
                
                        })
                
                
                        if(tempArr.length === images.length)
                        {
                            
                            //posArr.sort(); // Sort by item.id SEE above
                            if(shuffle) posArr = LibArray.ShuffleArray(posArr);

                            //console.log("POS: "+posArr.length)
                            //console.log("TEMP: "+tempArr.length)
                            //console.log("TEST LEN: "+testData.length)
                        
                
                            var array = [];
                            for(var i=0; i < posArr.length; i++)
                            {
                                for(var j=0; j < tempArr.length; j++)
                                {
                                    //console.log(posArr[i]+" === "+tempArr[j]['position']);
                
                                    if(posArr[i] === tempArr[j]['position'])
                                    {
                                    
                                        array.push(tempArr[j]);
                                        //console.log("break");
                                        break;
                
                                    }//==
                
                                }//==
                
                            }//==
                
                            Complete(array);
                
                        }//==
                
                
                
                    };
                    img.setAttribute("crossOrigin","anonymous");
                    img.setAttribute("src",sourceurl);



                }//--- 


            
            }


        }
        else
        {


            setIsLoaded(true);
            //setItems(props.items);
            if(props.info.call === 'vid')
            {
                setItems(props.items);
            }else{

                // -- reset items if mismatch -- //
                if(items[0].image.src !== props.items[0].image.src)
                {
                    setItems([]);
                }

            }


        }// -- if items



    },
    [

        location, 
        hashData,     
        items,      
        props

    ])








    // -- vars -- //

    var ssg;
    var gridMaxWidth;  
    ssg = false;
    gridMaxWidth = "1920px";
    if(location.search.match(/\?/gi))
    {
        var qr = location.search;
        if(qr.charAt(0) === '?') 
            qr = qr.substring(1,qr.length);

        if(qr === 'ssg=yes') 
        {
            ssg = true;
            gridMaxWidth = "1680px";
        }
    }


    var call;
    call = props.info.call;
    if(ssg) call = 'fixed';

    if(props.info.call === 'filmstrip') call = 'filmstrip';
    if(props.info.call === 'collage') call = 'collage';



    //const info = props.info;
    const info = JSON.parse(JSON.stringify(props.info));
    info.gridMaxWidth = gridMaxWidth;
    info.ssg = ssg;


    //alert(call + " --- " +props.refid);

    if(props.refid 
    && props.refid !== undefined
    && props.refid !== null
    )
    {
        if(document.getElementById(props.refid))
        {
            const ref = document.getElementById(props.refid);

            var refDecimal = (parseInt(ref.offsetWidth)/width);
            var newWidth  = width * refDecimal;

            info.thresh.refWidth = newWidth;
        }
        //console.log(props.refid+"\n"+ref.offsetWidth+"\n"+refDecimal+"\n"+newWidth);

        info.gridClass = "B";
     
        if(call === 'fixed') 
        {
            info.cellClass = "A";
            if(info.thresh.across3 > 9999) 
            {
                info.cellClass = "B";
                if(props.refid.match(/pagerow/)) info.cellClass = "B2";

            }
            if(info.thresh.across2 > 9999) info.cellClass = "C";

        }



        if(items.length === 2) 
        {
            info.cellClass = "D";
            if(width < 550) 
            {
                info.cellClass = "C";
            }

        }
    
        if(items.length === 1) 
        {
            info.cellClass = "C";

        }


    }//==


 



    if(error) 
    {
        return (

            <div 
            style={{

                width:"100%",
                height:"auto",
                padding:"15px 0 15px 0",
                textAlign:"center",
                color:"white"

            }}
            >
                    Error: {error.message}

            </div>

        )



    } 
    else 
    if(!isLoaded) 
    {

        return (
        
             <div 
             style={{

                width:"100%",
                height:"auto",
                padding:"15px 0 15px 0",
                textAlign:"center",
                color:"white"

             }}
             >
                 Loading...

            </div>
        )


    } 
    else 
    {



        return (

            <div>

                {
                call === 'flex' ?

                    <FlexGrid 
                
                        handle  = {props.handle} 
                        items   = {items}
                        info    = {info}

                    />

                :
                call === 'fixed' ?

                    <FixedGrid 

                        handle  = {props.handle} 
                        items   = {items}
                        info    = {info}
                    
                    />

                :
                call === 'filmstrip' ?

                    <FilmStrip 

                        handle  = {props.handle} 
                        items   = {items}
                        info    = {info}
                    
                    />

                :
                call === 'collage' ?

                    <Collage

                        handle      = {props.handle} 
                        items       = {items}
                        info        = {info}

                    />

                :
                null
                }



            </div>

        )


    }



}
export default Index


