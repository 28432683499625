/*
----------------------
Flex or Vid
----------------------

MAX - 4 across

 "across1"     :501,        
 "across2"     :801,          
 "across3"     :1050


MAX - 3 across

 "across1"     :501,        
 "across2"     :801,          
 "across3"     :10000


MAX - 2 across

 "across1"     :501,        
 "across2"     :10000,          
 "across3"     :10000

 */




import { 
    //Lorem, 
    //TestImages,
    //SpecialImages,
    //NavigationData 
} from 'app-components/Prime/Data';









const VidImageOnly = (props) => {



    var object = {

        styl:{

            call:"full",
            class:"FullRow",

            marginTop:"0px",
            marginBottom:"0px"

        },
        call:'VidGrid',
        info:{

            call:'vid', 

            gridType: "ImageOnly", //ImageOnly, ImageText
            gridBgColor:'transparent',
            gridCall: "grid_cell",  //grid_cell or grid_column_cell
            cellCall: "container",  //container or default 

            modCall: 'fill_div',
            thumb: {

                call    : "default", //flex_height or default
                diff    : 4

            },    
            cellBorder: {
                "boolean"       :false,
                "thickness"     :1,//px
                "color"         :"gray"  
            },
            gutter: {
                "boolean"   :false,
                "size"      :0
            },
            thresh: {
                "refWidth"    :false,   //number or false = default screen width
                "across1"     :501,
                "across2"     :801,   // number or 99999 to max out
                "across3"     :10000
            },

            mask:{

                boolean:true,
                message:{

                    boolean:true,
                    backgroundColor:"#4CAF50",
                    textColor:"white"

                }

            }



        },

        items:[]


    };



    object.styl.call = props.styl.call;
    object.styl.class = props.styl.class;
    object.styl.marginTop = props.styl.marginTop;
    object.styl.marginBottom = props.styl.marginBottom;



    if(props.info.thumb.diff) object.info.thumb.diff = props.info.thumb.diff;

    if(props.info.cellBorder && props.info.cellBorder !== undefined)
    {
        if(props.info.cellBorder.boolean)
        { 
            object.info.cellBorder.boolean = props.info.cellBorder.boolean;
            object.info.cellBorder.thickness = props.info.cellBorder.thickness;
            object.info.cellBorder.color = props.info.cellBorder.color;
        }
    }

    if(props.info.gutter.boolean)
    { 
        object.info.gutter.boolean = props.info.gutter.boolean;
        object.info.gutter.size = props.info.gutter.size;
    }

    if(props.info.thresh) object.info.thresh.across1 = props.info.thresh.across1;
    if(props.info.thresh) object.info.thresh.across2 = props.info.thresh.across2;
    if(props.info.thresh) object.info.thresh.across3 = props.info.thresh.across3;

    object.items = props.items;




    return object;


}





const VidImageText = (props) => {



    var object = {



        styl:{

            call:"full",
            class:"FullRow",

            marginTop:"0px",
            marginBottom:"0px"
    
        },
        call:'VidGrid',
        info:{

            call:'vid', 

            gridType: "ImageText", //ImageOnly, ImageText
            gridBgColor:'transparent',
            gridCall: "grid_cell",  //grid_cell or grid_column_cell
            cellCall: "container",  //container or default 

            modCall: 'fill_div',
            thumb: {

                call    : "default", //flex_height or default
                diff    : 10

            },    
            cellBorder: {
                "boolean"       :true,
                "thickness"     :1,//px
                "color"         :"gray"  
            },
            gutter: {
                "boolean"   :true,
                "size"      :10
            },
            thresh: {
                "refWidth"    :false,   //number or false = default screen width
                "across1"     :501,
                "across2"     :801,
                "across3"     :1050
            },

            mask:{

                boolean:true,
                message:{

                    boolean:true,
                    backgroundColor:"#4CAF50",
                    textColor:"white"

                }

            }




        },

        items:[]


    };


    object.styl.call = props.styl.call;
    object.styl.class = props.styl.class;
    object.styl.marginTop = props.styl.marginTop;
    object.styl.marginBottom = props.styl.marginBottom;



    if(props.info.thumb.diff) object.info.thumb.diff = props.info.thumb.diff;

    if(props.info.cellBorder && props.info.cellBorder !== undefined)
    {
        if(props.info.cellBorder.boolean)
        { 
            object.info.cellBorder.boolean = props.info.cellBorder.boolean;
            object.info.cellBorder.thickness = props.info.cellBorder.thickness;
            object.info.cellBorder.color = props.info.cellBorder.color;
        }
    }

    if(props.info.gutter.boolean)
    { 
        object.info.gutter.boolean = props.info.gutter.boolean;
        object.info.gutter.size = props.info.gutter.size;
    }

    if(props.info.thresh) object.info.thresh.across1 = props.info.thresh.across1;
    if(props.info.thresh) object.info.thresh.across2 = props.info.thresh.across2;
    if(props.info.thresh) object.info.thresh.across3 = props.info.thresh.across3;

    object.items = props.items;


    return object;


}








export {

    VidImageOnly,
    VidImageText

}


