/*
----------------------
Flex or Fixed
----------------------

MAX - 4 across

 "across1"     :501,        
 "across2"     :801,          
 "across3"     :1050


MAX - 3 across

 "across1"     :501,        
 "across2"     :801,          
 "across3"     :10000


MAX - 2 across

 "across1"     :501,        
 "across2"     :10000,          
 "across3"     :10000




{

    styl:{

        call:"indent",
        class:"IndentRow",

        marginTop:"12px",
        marginBottom:"12px"
    
    },
    call:'FGrid',
    info:{

        call:'filmstrip', //fixed, flex, filmstrip
        gridType: "ImageOnly", //ImageOnly, ImageTextCard
        gridBgColor:'transparent',
        gridCall: "filmstrip",  //grid_column_cell, grid_cell, flyr, filmstrip
        modCall: 'fitTo',
        thumb: {

            call    : "flex_height", //flex_height or default
            diff    : 0

        },    
        cellBorder: {
            "boolean"       :true,
            "thickness"     :1,//px
            "color"         :"gray"  
        },
        gutter: {
            "boolean"   :true,
            "size"      :0
        },
        thresh: {
            "refWidth"    :false,   //number or false = default screen width
            "across1"     :10000,
            "across2"     :10000,
            "across3"     :10000
        
        },
        mask:{

            boolean:false,
            message:{

                boolean:true,
                backgroundColor:"#4CAF50",
                TextCardColor:"white",
                TextCard:"My Message"

            }

        }
    


    },

    items:[

        TestImages[0]
        
    ]

}




Common Edits 

    styl.call           = indent or full
    styl.class          = FullRow or IndentRow or IndentRowB
    styl.marginTop      = "px";
    styl.marginBottom   = "px";

    info.thumb.diff     = integer

    info.gutter.boolean = true or false
    info.gutter.size    = integer

    info.thresh.across1 = "501 or 10000"; 2 images or 1
    info.thresh.across2 = "801 or 10000"; 3 images or 2
    info.thresh.across3 = "1050 or 10000"; 4 images or 3

    info.mask.boolean                   = true or false;
    info.mask.message.boolean           = true or false;
    info.mask.message.backgroundColor   = color
    info.mask.message.TextCardColor         = color
    info.mask.message.TextCard              = 'Your Message';


    items = array of Txt or Img or Vid




 */



import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';



import { 
    //Lorem, 
    TestImages,
    //TestVideos,
    //SpecialImages,
    //NavigationData 
} from 'app-components/Prime/Data';


// import {

//     //TextCard1,
//     //TextCard2,
//     //TextCard3,

//     //ImageTextCard1,
//     //ImageTextCard2,
//     //ImageTextCard3


// } from 'app-components/Zone/Builds/Samples/Card';






import { Settings } from 'app-components/Prime/Data.js';

import { 

    //VPcompanyInfo, 
    VPexpenses,
    VPincome,   
    VPoverhead, 

    //VPownrs,
    VPownr,

    //VPempls,
    VPempl,

    //VPindirectLabor,
    //VPidlEmployee

} from 'app-components/Prime/VPdata/form.js';


import { LibBase } from "library";



const SceneInfo = () => {





    // -- redux -- //
    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));


    var overhead_percent;


    var owners = {};
    var employees = {};

    var keyname;
    var kn;
    var item;
    //var name;
    var value;
    var newValue;

    var chartData;

    

    var isOkay;
    isOkay = false;
    if(zoneObj.calc_dash 
    && zoneObj.calc_dash !== undefined
    )
    {
        if(Object.keys(zoneObj.calc_dash.form_items).length > 0
        && Object.keys(zoneObj.calc_dash.result_items).length > 0
        )
        {
            isOkay = true;
        }

    }
    if(isOkay)
    {


        //console.log("FORM ITEMS: "+JSON.stringify(zoneObj.calc_dash.form_items));

        for(keyname in zoneObj.calc_dash.form_items)
        {



            // -- owners -- //

            if(keyname.match(/ownr/gi))
            {

                //idl_employees[keyname] = zoneObj.calc_dash.form_items[keyname];

                
                //order card item based on Prime VPdata
                item = zoneObj.calc_dash.form_items[keyname];
          
                value = VPownr.value;
                newValue = {};
                for(kn in value)
                {
                    if(kn === 'ownr_call') 
                    {
                    }else{
                        newValue[kn] = item.value[kn];       
                    }
                    
                }
                owners[keyname] = {
                    name:item.name,
                    value:newValue
                };
                

            }




            // -- employees -- //

            else
            if(keyname.match(/empl/gi))
            {

                //idl_employees[keyname] = zoneObj.calc_dash.form_items[keyname];

                
                //order card item based on Prime VPdata
                //item = zoneObj.calc_dash.form_items[keyname];
                item = LibBase.CloneIt(zoneObj.calc_dash.form_items[keyname]);//Deep Clone
          
                value = VPempl.value;
                //value = LibBase.CloneIt(VPidlEmployee.value);
                newValue = {};
                for(kn in value)
                {
                    newValue[kn] = item.value[kn];
                }
                employees[keyname] = {
                    name:item.name,
                    value:newValue
                };
                



            }



            // -- overhead -- //

            else
            {
                console.log("KEYNAME: "+keyname);


                if(zoneObj.calc_dash.form_items && zoneObj.calc_dash.form_items !== undefined)
                {
                    if(zoneObj.calc_dash.form_items[keyname] 
                    && zoneObj.calc_dash.form_items[keyname] !== undefined
                    )
                    {
    
                        item = zoneObj.calc_dash.form_items[keyname];
                        //console.log(JSON.stringify(item,null,2));

                        if(VPoverhead[keyname]
                        && VPoverhead[keyname] !== undefined
                        )
                        {

                            value = VPoverhead[keyname].value;
                            newValue = {};
                            for(kn in value)
                            {
                                newValue[kn] = item.value[kn];
                            }
                            VPoverhead[keyname] = {
                                name:item.name,
                                value:newValue
                            };

                        }
            

                    }

                }


            }

        }// -- for

        
        VPexpenses.value.monthly_expenses.value = zoneObj.calc_dash.result_items['totals'].total;
        VPincome.value.projected_monthly_sales.value = zoneObj.calc_dash.form_items['settings'].value.projected_monthly_sales.value;

        overhead_percent = zoneObj.calc_dash.result_items['overhead'].overhead;
        overhead_percent = parseFloat(overhead_percent) 
        overhead_percent = overhead_percent.toFixed(1) + "%";


        /*
        for(keyname in zoneObj.calc_dash.result_items)
        {

            console.log("KEYNAME: "+keyname);

            item = zoneObj.calc_dash.result_items[keyname];
            console.log(JSON.stringify(item,null,2));

        
        }// -- for
        */


    }// -- if

    //console.log("_overhead A: "+JSON.stringify(_overhead,null,2));

    //_overhead = VPoverhead;
    //console.log("_overhead B: "+JSON.stringify(_overhead,null,2));



    console.log(overhead_percent);



    var R = [];
    //var index;


    
    //--------------------------
    // Decor - Title
    //--------------------------

    R.push({


        component: "DecorCard",
        styl:{

            call        : 'indent',
            class       : 'IndentRow',

            marginTop   : "0px",
            marginBottom: "0px"
        },

        info:{

            decor:{

                call        :"Text",

                boxStyle    :{

                    backgroundColor: "transparent",
                    border:"0px",
                    width:"100%",
                    padding: "0 0 0 0",     
                    margin: "40px 20px 0 20px",

                },

                textStyle   :{

                    backgroundColor:    "transparent",
                    margin:             "0 0 0 0",
                    padding:            "0 0 0 0",

                    fontSize:           "50px",
                    fontWeight:         "bold",
                    fontColor:          "#454d5f",
                    textAlign:          "center",
                    textDecoration:     "none"
                }

            },

            thumb:{

                diff: false
            },

            cellBorder: {
                boolean       :false,
                thickness     :0,//px
                color         :"lightgray"  
            },

            gutter:{

                boolean: false,
                size: 0
            },
            thresh:{

                across1:501,
                across2:801,
                across3:1050
            }
            
        },

        items:[

            {

                heading:"Bar Graph Sample",
                stripe:false,
                pgraph:"",
                image:false,
                button:false
            
            }
            
        ]

    });






    //--------------------------
    // BarChart
    //--------------------------

    R.push({


        component: "DecorCard",
        styl:{

            call        : 'indent',
            class       : 'IndentRow',

            marginTop   : "0px",
            marginBottom: "0px"
        },

        info:{


            decor:{

                call        :"Chart",

                boxStyle    :{

                    backgroundColor: "transparent",
                    border:"0",
                    width:"100%",
                    padding: "0 0 0 0",     
                    margin: "15px 20px 15px 20px",


                },

                textStyle   :{

                    backgroundColor:    "transparent",
                    margin:             "0 0 0 0",
                    padding:            "0 0 0 0",

                    fontSize:           "140px",
                    fontWeight:         "bold",
                    fontColor:          "#454d5f",
                    textAlign:          "center",
                    textDecoration:     "none"
                },

                canvas:{

                    backgroundColor:"transparent",
                    border:"0",
                    borderRadius:"0",
                    width:"96%",
                    margin:"15px auto 18px auto",
    
                    drawCheckerBoard: false,
                    chart:{
                        call:'bar',
                        data:Settings.barChart
                    }


                }

            },

            thumb:{

                diff: false
            },

            cellBorder: {
                boolean       :false,
                thickness     :0,//px
                color         :"lightgray"  
            },

            gutter:{

                boolean: false,
                size: 0
            },
            thresh:{

                across1:501,
                across2:801,
                across3:1050
            }
            
        },

        items:[

            {

                heading:"Graphic Overview",
                stripe:true,
                pgraph:false,
                image:{

                    src :TestImages[0].image.src,
                    w   :TestImages[0].image.w,
                    h   :TestImages[0].image.h
            
                },
                button:false
            
            }
        ]

    });










    //##################################################################
    //##################################################################


    //--------------------------
    // Decor - Title
    //--------------------------

    R.push({


        component: "DecorCard",
        styl:{

            call        : 'indent',
            class       : 'IndentRow',

            marginTop   : "0px",
            marginBottom: "0px"
        },

        info:{

            decor:{

                call        :"Text",

                boxStyle    :{

                    backgroundColor: "transparent",
                    border:"0px",
                    width:"100%",
                    padding: "0 0 0 0",     
                    margin: "40px 20px 0 20px",

                },

                textStyle   :{

                    backgroundColor:    "transparent",
                    margin:             "0 0 0 0",
                    padding:            "0 0 0 0",

                    fontSize:           "50px",
                    fontWeight:         "bold",
                    fontColor:          "#454d5f",
                    textAlign:          "center",
                    textDecoration:     "none"
                }

            },

            thumb:{

                diff: false
            },

            cellBorder: {
                boolean       :false,
                thickness     :0,//px
                color         :"lightgray"  
            },

            gutter:{

                boolean: false,
                size: 0
            },
            thresh:{

                across1:501,
                across2:801,
                across3:1050
            }
            
        },

        items:[

            {

                heading:"Doughnut Chart Sample 1",
                stripe:false,
                pgraph:"",
                image:false,
                button:false
            
            }
            
        ]

    });






    //--------------------------
    // BarChart - doughnut
    //--------------------------

    chartData = {

        items: [ 

            {
                name:"Classical Music",
                value:10
            },
            {
                name:"Alternative Rock",
                value:20
            },    
            {
                name:"Pop",
                value:30
            },
            {
                name:"Jazz",
                value:10
            },
            {
                name:"Hard Rock",
                value:16
            },
            {
                name:"Heavy Metal",
                value:14
            }

        ],

        colors: [
            "#fde23e",
            "#f16e23", 
            "#57d9ff",
            "#937e88",
            "#20b2aa",
            "#ccff00",
            "lightblue",
            "lightgreen",
            "yellow",
            "pink",
            "lavender"
        ],
        hole:{
            color:"#191e28",
            outlineColor:"#191e28",
            textColor:"white"
        }

    }






    R.push({


        component: "DecorCard",
        styl:{

            call        : 'indent',
            class       : 'IndentRow',

            marginTop   : "0px",
            marginBottom: "0px"
        },

        info:{


            decor:{

                call        :"Chart",

                boxStyle    :{

                    backgroundColor: "transparent",
                    border:"0",
                    width:"100%",
                    padding: "0 0 0 0",     
                    margin: "15px 20px 15px 20px",


                },

                textStyle   :{

                    backgroundColor:    "transparent",
                    margin:             "0 0 0 0",
                    padding:            "0 0 0 0",

                    fontSize:           "140px",
                    fontWeight:         "bold",
                    fontColor:          "#454d5f",
                    textAlign:          "center",
                    textDecoration:     "none"
                },

                canvas:{

                    backgroundColor:"transparent",
                    border:"0",
                    borderRadius:"0",
                    width:"96%",
                    margin:"15px auto 18px auto",
    
                    drawCheckerBoard: false,
                    chart:{
                        call:'doughnut',
                        data:chartData
                    }


                }

            },

            thumb:{

                diff: false
            },

            cellBorder: {
                boolean       :false,
                thickness     :0,//px
                color         :"lightgray"  
            },

            gutter:{

                boolean: false,
                size: 0
            },
            thresh:{

                across1:501,
                across2:801,
                across3:1050
            }
            
        },

        items:[

            {

                heading:"Graphic Overview",
                stripe:true,
                pgraph:false,
                image:{

                    src :TestImages[0].image.src,
                    w   :TestImages[0].image.w,
                    h   :TestImages[0].image.h
            
                },
                button:false
            
            }
        ]

    });











    //##################################################################
    //##################################################################


    //--------------------------
    // Decor - Title
    //--------------------------

    R.push({


        component: "DecorCard",
        styl:{

            call        : 'indent',
            class       : 'IndentRow',

            marginTop   : "0px",
            marginBottom: "0px"
        },

        info:{

            decor:{

                call        :"Text",

                boxStyle    :{

                    backgroundColor: "transparent",
                    border:"0px",
                    width:"100%",
                    padding: "0 0 0 0",     
                    margin: "40px 20px 0 20px",

                },

                textStyle   :{

                    backgroundColor:    "transparent",
                    margin:             "0 0 0 0",
                    padding:            "0 0 0 0",

                    fontSize:           "50px",
                    fontWeight:         "bold",
                    fontColor:          "#454d5f",
                    textAlign:          "center",
                    textDecoration:     "none"
                }

            },

            thumb:{

                diff: false
            },

            cellBorder: {
                boolean       :false,
                thickness     :0,//px
                color         :"lightgray"  
            },

            gutter:{

                boolean: false,
                size: 0
            },
            thresh:{

                across1:501,
                across2:801,
                across3:1050
            }
            
        },

        items:[

            {

                heading:"Doughnut Chart Sample 2",
                stripe:false,
                pgraph:"",
                image:false,
                button:false
            
            }
            
        ]

    });






    //--------------------------
    // BarChart - doughnut
    //--------------------------


    chartData = {

        items: [ 

            {
                name:"Classical Music",
                value:80
            },
            {
                name:"Alternative Rock",
                value:20
            }
        ],

        colors: [

            "#fde23e",
            "#f16e23", 
            "#57d9ff",
            "#937e88",
            "#20b2aa",
            "#ccff00",
            "lightblue",
            "lightgreen",
            "yellow",
            "pink",
            "lavender"

        ],
        hole:{
            color:"#edeef1",
            outlineColor:"#edeef1",
            textColor:"black"
        }

    }



    R.push({


        component: "DecorCard",
        styl:{

            call        : 'indent',
            class       : 'IndentRow',

            marginTop   : "0px",
            marginBottom: "0px"
        },

        info:{


            decor:{

                call        :"Chart",

                boxStyle    :{

                    backgroundColor: "transparent",
                    border:"0",
                    width:"100%",
                    padding: "0 0 0 0",     
                    margin: "15px 20px 15px 20px",


                },

                textStyle   :{

                    backgroundColor:    "transparent",
                    margin:             "0 0 0 0",
                    padding:            "0 0 0 0",

                    fontSize:           "140px",
                    fontWeight:         "bold",
                    fontColor:          "#454d5f",
                    textAlign:          "center",
                    textDecoration:     "none"
                },

                canvas:{

                    backgroundColor:"transparent",
                    border:"0",
                    borderRadius:"0",
                    width:"96%",
                    margin:"15px auto 18px auto",
    
                    drawCheckerBoard: false,
                    chart:{
                        call:'doughnut',
                        data:chartData
                    }


                }

            },

            thumb:{

                diff: false
            },

            cellBorder: {
                boolean       :false,
                thickness     :0,//px
                color         :"lightgray"  
            },

            gutter:{

                boolean: false,
                size: 0
            },
            thresh:{

                across1:501,
                across2:801,
                across3:1050
            }
            
        },

        items:[

            {

                heading:"Graphic Overview",
                stripe:true,
                pgraph:false,
                image:{

                    src :TestImages[0].image.src,
                    w   :TestImages[0].image.w,
                    h   :TestImages[0].image.h
            
                },
                button:false
            
            }
        ]

    });








    //##################################################################
    //##################################################################

    //--------------------------
    // Decor - Title
    //--------------------------

    R.push({


        component: "DecorCard",
        styl:{

            call        : 'indent',
            class       : 'IndentRow',

            marginTop   : "0px",
            marginBottom: "0px"
        },

        info:{

            decor:{

                call        :"Text",

                boxStyle    :{

                    backgroundColor: "transparent",
                    border:"0px",
                    width:"100%",
                    padding: "0 0 0 0",     
                    margin: "40px 20px 0 20px",

                },

                textStyle   :{

                    backgroundColor:    "transparent",
                    margin:             "0 0 0 0",
                    padding:            "0 0 0 0",

                    fontSize:           "50px",
                    fontWeight:         "bold",
                    fontColor:          "#454d5f",
                    textAlign:          "center",
                    textDecoration:     "none"
                }

            },

            thumb:{

                diff: false
            },

            cellBorder: {
                boolean       :false,
                thickness     :0,//px
                color         :"lightgray"  
            },

            gutter:{

                boolean: false,
                size: 0
            },
            thresh:{

                across1:501,
                across2:801,
                across3:1050
            }
            
        },

        items:[

            {

                heading:"Pie Chart Sample",
                stripe:false,
                pgraph:"",
                image:false,
                button:false
            
            }
            
        ]

    });






    //--------------------------
    // PieChart - pie
    //--------------------------

    R.push({


        component: "DecorCard",
        styl:{

            call        : 'indent',
            class       : 'IndentRow',

            marginTop   : "0px",
            marginBottom: "0px"
        },

        info:{


            decor:{

                call        :"Chart",

                boxStyle    :{

                    backgroundColor: "transparent",
                    border:"0",
                    width:"100%",
                    padding: "0 0 0 0",     
                    margin: "15px 20px 15px 20px",


                },

                textStyle   :{

                    backgroundColor:    "transparent",
                    margin:             "0 0 0 0",
                    padding:            "0 0 0 0",

                    fontSize:           "140px",
                    fontWeight:         "bold",
                    fontColor:          "#454d5f",
                    textAlign:          "center",
                    textDecoration:     "none"
                },

                canvas:{

                    backgroundColor:"transparent",
                    border:"0",
                    borderRadius:"0",
                    width:"96%",
                    margin:"15px auto 18px auto",
    
                    drawCheckerBoard: false,
                    chart:{
                        call:'pie',
                        data:Settings.pieChart
                    }


                }

            },

            thumb:{

                diff: false
            },

            cellBorder: {
                boolean       :false,
                thickness     :0,//px
                color         :"lightgray"  
            },

            gutter:{

                boolean: false,
                size: 0
            },
            thresh:{

                across1:501,
                across2:801,
                across3:1050
            }
            
        },

        items:[

            {

                heading:"Graphic Overview",
                stripe:true,
                pgraph:false,
                image:{

                    src :TestImages[0].image.src,
                    w   :TestImages[0].image.w,
                    h   :TestImages[0].image.h
            
                },
                button:false
            
            }
        ]

    });



    return R;


}



export default SceneInfo;
