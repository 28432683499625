import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';

import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';

import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation, 
    //useHistory,  
    //useParams

} from "react-router-dom";

//import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider';
//import { useAuthentication } from 'utils/hooks/Authentication';





/*
import {

    //Header,
    Footer,
    //InfoBar

} from 'app-components/Prime/Commons';
*/


// import {

//     Nav,
//     //SubNav,

// } from '../../Navigation';

import {

    Stage,
    ScrollBox,
    Area,
    AreaTitle,
    PrintOut

} from '../Styled';



//import StylScene from '../StylScene.module.css';

import Loader from 'utils/components/Process/Loader';





export const AuthAccountStatus = (props) => {


    // -- router -- //
    const location = useLocation(); 
    //const history = useHistory();
    //let match = useRouteMatch();
    //let params = useParams();
 
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);


    // -- window dims -- //
    //const { height } = useWindowDimensions();

    // -- authentication -- //
    //const auth = useAuthentication();
    //console.log("AUTH: "+auth);




    // -- redux -- //
    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));
    

    // -- ref -- //
    const refA = useRef(null);
    const refB = useRef(null);
    //const refC = useRef(null);



    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);
    //const [info, setInfo] = useState(false);

    //const [stageTopHeight, setStageTopHeight] = useState(50);
    //const [stageHeight, setStageHeight] = useState(50)
    //const [scrollboxHeight, setScrollboxHeight] = useState(50);

    useEffect(() => {

        //https://stackoverflow.com/questions/58773210/trying-to-use-cleanup-function-in-useeffect-hook-to-cleanup-img-onload


        //var primeControlsInt = document.getElementById("primeControls").offsetHeight;

        //var diff = refA.current.offsetHeight + refB.current.offsetHeight + refC.current.offsetHeight;
    
        //alert(refA.current.offsetHeight+"\n"+refB.current.offsetHeight+"\n"+refC.current.offsetHeight);

        //var stageHeight = (height - refC.current.offsetHeight) - 7 + "px";
        //setStageHeight(stageHeight);

        //var scrollboxHeight = (height - diff) - (primeControlsInt + 0) + "px";
        //setScrollboxHeight(scrollboxHeight);

        //console.log(JSON.stringify(zoneObj,null,2));



        if(!isLoaded)
        {
            setIsLoaded(true);
        }

        // if(!auth)
        // {
        //     //alert("auth: "+auth);

        //     //var basename = zoneObj.basename;
        //     var url = '/Login/';
        //     //var url = basename + 'AuthArea/Login';
        //     //var url = "/templates/AuthArea/FormLogin";
        //     //history.replace(url);

        //     history.push(url);

        //     //history.goBack();
        //     //window.location.replace(url);//regular dom
            
        // }else{

        //     // console.log(auth);

        //     // RESULT: {
        //     //     "success": true,
        //     //     "result": {
        //     //       "tokenError": false,
        //     //       "authorized": true,
        //     //       "user": {
        //     //         "name": "powerdigitalmedia.net~z21hawwuy29t",
        //     //         "email": "powerdigitalmedia.net@gmail.com",
        //     //         "iat": 1624267607,
        //     //         "exp": 1624271207
        //     //       }
        //     //     }
        //     // }


        //     // if(isLoaded)
        //     // {

        //         //setIsLoaded(true);

        //         // var info = JSON.stringify(auth,null,2);
        //         // info = "<pre>"+info+"</pre>";
        //         // setInfo(info);


        //     //}


        // }

        
    
    },[

        isLoaded,
        //location,
        //history,
        //height,
        zoneObj,
        //auth,
        //isLoaded
    ])





    // if(auth && auth === 'inProcess')
    // {

    //     return (

    //         <Stage
    //         //height={stageHeight}
    //         >

    //             <div ref={refA}>
    //             </div>
    //             <div ref={refB}>
    //             </div>

    //             <ScrollBox
    //             //height={scrollboxHeight}
    //             >
                
    //                 <Area
    //                 maxWidth={1200+"px"}
    //                 width={"96%"}
    //                 >

    //                     {/*
    //                     <div id={"LoadingNPreset"}>

    //                         <div 
    //                         style={{

    //                             width:"100%",
    //                             height:"auto",
    //                             padding:"15px 0 15px 0",
    //                             margin:"50px auto",
    //                             textAlign:"center",
    //                             color:"black"

    //                         }}
    //                         >
    //                             Loading...
    //                         </div>

    //                     </div>
    //                     */}


    //                     <Loader 

    //                         call={"pageLoader"}
                        
    //                     />


    //                 </Area>
                

    //                  {/* <Footer /> */}


    //             </ScrollBox>


    //         </Stage>

    //     )


  
    // }
    // else
    if(!isLoaded)
    {

        return (

            <Stage
            //height={stageHeight}
            >

                <div ref={refA}>
                </div>
                <div ref={refB}>
                </div>

                <ScrollBox
                //height={scrollboxHeight}
                >
                    <Area
                    maxWidth={1200+"px"}
                    width={"96%"}
                    >


                        {/*
                        <div id={"LoadingNPreset"}>

                            <div 
                            style={{

                                width:"100%",
                                height:"auto",
                                padding:"15px 0 15px 0",
                                margin:"50px auto",
                                textAlign:"center",
                                color:"black"

                            }}
                            >
                                No Authentication
                            </div>

                        </div>
                        */}



                        <Loader 

                            call={"pageLoader"}
                        
                        />

                    </Area>


                     {/* <Footer /> */}


                </ScrollBox>

            </Stage>

        )


  

    }
    else
    {
    //allowed



        return (

            <Stage
            //height={stageHeight}
            >

                <div ref={refA}>
                </div>
                <div ref={refB}>
                </div>

                <ScrollBox
                //height={scrollboxHeight}
                >
                    <Area>

                        <AreaTitle> Account Status </AreaTitle>

                        <PrintOut>

                            {"Account Status"}

                        </PrintOut>

                    </Area>


                     {/* <Footer /> */}


                </ScrollBox>

            </Stage>

        )


    }



}


