import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';



import { 

    StaticDonutChart

} from 'utils/components/Charts/CssChart';




const Index = (props) => {


    // -- ref -- //
    const refA = useRef(null);


    // -- state -- //
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);


    useEffect(() => {

        if(!isLoaded)
        {
            setIsLoaded(true);

        }else{

            //setData(props.data);


            var marginTop = "10px";
            var marginLeft = "0px";
            if(props.data.boxHeight 
            && props.data.boxHeight !== undefined
            )
            {
                var boxHeightInt = props.data.boxHeight;
                var donutSizeInt = props.data.value.donutSize.replace(/px/gi,"");

                marginTop = (boxHeightInt - donutSizeInt)/2 - (5) + "px";
                //marginLeft = "calc(50% - "+(refA.current.offsetWidth/2)+"px";
                //console.log("DonutChart -  marginTop: "+marginTop);

            }


            if(props.lessThanThresh)
            {
                marginTop = "20px";
                marginLeft = "calc(50% - "+(refA.current.offsetWidth/2)+"px)";
                
            }

            setData({

                id:props.id,
                margin:marginTop+" 0 0 "+marginLeft
         
            });




        }
        console.log("donutChartData: "+JSON.stringify(props.data.value,null,2));
    },
    [
        props,
        isLoaded
    ])



    return (


        <div
        ref={refA}
        style={{
            width:"100%",
            height:"auto",
            margin:data.margin
        }}
        >
            <StaticDonutChart
            data={props.data.value}
            >
            </StaticDonutChart>

        </div>
    

    )


}

export default Index;


