import React, { 

    useState, 
    //useEffect, 
    //useRef 

} from 'react';


import {

    LabelField

} from '../Styled';

import {

    ArrowButton

} from '../Styled/ArrowButton';



const Index = (props) => {



    //=============================================
    // state/effect - showMenu
    //=============================================

    const [arrowColor, setArrowColor] = useState("#454d5f");

    const handleOverOut = (hndl) => {

        setArrowColor(hndl.arrowColor);

    }



    return (<div>


        <LabelField>


            <div className={"Label"}>

                {/* SORT BY: */}

            </div>


            <div className={"Field"}
            onClick={(v) => props.handle({

                call:"showMenu",
                data:""

            })}
            >

                <div className={"dropdown"}>


                    <div className={"mask"}
                    onMouseEnter={() => handleOverOut({

                        id          :"arrowButtonDown",
                        call        :"over",
                        bgColor     :"white",
                        borderColor :"white",
                        arrowColor  :"#07a2e3"
            
                    })}
                
                    onMouseOut={() => handleOverOut({
            
                        id          :"arrowButtonDown",
                        call        :"out",
                        bgColor     :"white",
                        borderColor :"white",
                        arrowColor  :"#1a1926"
            
                    })}
                    >
                    </div>




                    <div className={"text"}>

                        {props.data.text}

                    </div>

                    <div className={"arrow"}>


                        <ArrowButton
                        id={"arrowButtonDown"}
                        arrowColor={arrowColor}
                        > 
                            <div className={"arrowbox-alfa"}></div>
                            <div id={"arrowButtonDown_bg"} className={"arrowbox-bg"}></div>
                            <div className={"arrowbox-mask"}
                            ></div>

                            <div className={"arrowbox-base"}>

                                <div id={"arrowButtonDown_arrow1"} className={"arrow-down"}></div>
                            
                            </div>

                        </ArrowButton>


                    </div>

                </div>

            </div>



        </LabelField>
                                                

    </div>)

}

export default Index;









// <ArrowButton
// id={"arrowButtonRight"}
// > 

//     <div className={"arrowbox-alfa"}></div>
//     <div id={"arrowButtonRight_bg"} className={"arrowbox-bg"}></div>
//     <div className={"arrowbox-mask"}

//         onClick={() => handle({

//             call        :"scrollMove",
//             direction   :"right"
            
//         })}

//         onMouseEnter={() => handleOverOut({

//             id          :"arrowButtonRight",
//             call        :"over",
//             bgColor     :"white",
//             borderColor :"white",
//             arrowColor  :"#07a2e3"

//         })}

//         onMouseOut={() => handleOverOut({

//             id          :"arrowButtonRight",
//             call        :"out",
//             bgColor     :"white",
//             borderColor :"white",
//             arrowColor  :"#1a1926"

//         })}

//     ></div>

//     <div className={"arrowbox-base"}>

//         <div id={"arrowButtonRight_arrow1"} className={"arrow-right"}></div>
    
//     </div>


// </ArrowButton>



