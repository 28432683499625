import styled from "styled-components";


export const CatalogTop = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "1px solid lightgray",

    width:              props.width || "100%",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 10px 0",
    padding:            props.padding || "0 0 0 0"

}))`


    background-color:${props => props.backgroundColor};

    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;


`;



    export const CatalogTopName = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",
        border:             props.border || "1px solid lightgray",

        width:              props.width || "auto",
        height:             props.height || "auto",
        margin:             props.margin || "4px 0 0 0",
        padding:            props.padding || "0 0 0 0",



        fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
        fontSize:           props.fontSize || "40px",
        fontWeight:         props.fontWeight  || "550",
        textAlign:          props.textAlign || "left",
        textDecoration:     props.textDecoration  || "none",
        color:              props.color || "black"


    }))`


        background-color:${props => props.backgroundColor};

        width:${props => props.width};
        height:${props => props.height};
  
        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;
        float:left;

        overflow:hidden;

        font-family:${props => props.fontFamily};
        font-size:${props => props.fontSize};
        font-weight:${props => props.fontWeight};
        text-align:${props => props.textAlign};
        text-decoration:${props => props.textDecoration};
        color:${props => props.color};


        @media (max-width: 475px) {

            font-size:30px;
            margin-top:7px;
        }


    `;



    export const CatalogTopControls = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",
        border:             props.border || "1px solid lightgray",

        width:              props.width || "auto",
        height:             props.height || "auto",
        margin:             props.margin || "0 0 0 0",
        padding:            props.padding || "0 0 0 0",


        fontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
        fontSize:           props.fontSize || "18px",
        fontWeight:         props.fontWeight  || "550",
        textAlign:          props.textAlign || "center",
        textDecoration:     props.textDecoration  || "none",
        color:              props.color || "white",


        //button
        buttonMinWidth:                     props.buttonMinWidth || "125px",

        buttonBackgroundColor:              props.buttonBackgroundColor || "#191e28",
        buttonBorder:                       props.buttonBorder || "1px solid #191e28",
        buttonTextColor:                    props.buttonTextColor || "white",

        buttonBackgroundColorHover:         props.buttonBackgroundColorHover || "#fc0",
        buttonBorderHover:                  props.buttonBorderHover || "1px solid #fc0",
        buttonTextColorHover:               props.buttonTextColorHover || "black"


    }))`


        background-color:${props => props.backgroundColor};

        width:${props => props.width};
        height:${props => props.height};

        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;
        float:right;

        overflow:hidden;




        .Button {

            background-color:${props => props.buttonBackgroundColor};
            border:${props => props.buttonBorder};
            border-radius:7px;
    
            min-width:${props => props.buttonMinWidth};
            width:auto;
            height:auto;
            padding:9px 15px 8px 15px;
            margin:4px 0 0 0;
            position:relative;
            float:right;
    
            cursor:pointer;
    
            font-family:${props => props.fontFamily};
            font-size:${props => props.fontSize};
            font-weight:${props => props.fontWeight};
            text-align:${props => props.textAlign};
            text-decoration:${props => props.textDecoration};
            color:${props => props.buttonTextColor};
    
            &:hover{
    
                background-color:${props => props.buttonBackgroundColorHover};
                color:${props => props.buttonTextColorHover};
            
            }



            @media (max-width: 475px) {

                font-size:16px;
                margin-top:7px;
                min-width:100px;
            }


    
    
    
        }
    
        .ButtonMask {
    
            background-color:transparent;
    
            width:30px;
            height:30px;
            padding:0 0 0 0;
            margin:0 0 0 0;
            position:absolute;
            top:0px;
            left:0px;
            z-index:1;
    
            cursor:pointer;
    
        }
    
    
        .Img {
    
            width:30px;
            height:30px;
    
        }
    


    `;








    export const CatalogTopLogo = styled.div.attrs(props => ({

        backgroundColor:    props.backgroundColor || "transparent",
        border:             props.border || "1px solid lightgray",

        width:              props.width || "100%",
        height:             props.height || "auto",
        margin:             props.margin || "0 0 0 0",
        padding:            props.padding || "0 0 0 0",

    }))`


        background-color:${props => props.backgroundColor};

        width:${props => props.width};
        height:${props => props.height};

        padding:${props => props.padding};
        margin:${props => props.margin};

        position:relative;
        float:left;

        overflow:hidden;


        .Icon {

            background-color:transparent;
            width:75px;
            height:75px;
            padding:0 0 0 0;
            margin:0 0 0 0;
            position:relative;
            float:left;

        }

        .Text {

            background-color:transparent;
            width:calc(100% - 100px);
            height:auto;
            padding:0 0 0 0;
            margin:15px 0 0 15px;
            position:relative;
            float:left;

        }



    `;



