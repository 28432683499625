import React, { 

    useState, 
    useEffect, 
    useRef 

} from 'react';


import { 
    useSelector, 
    useDispatch 
} 
from 'react-redux';
import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    useHistory,   
    //useParams,



} from "react-router-dom";

import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'
import useDeviceDetect from 'utils/hooks/DeviceDetect';
import useKeyPress from 'utils/hooks/KeyPress';


//=====================================================

import 'app-style/formstuff.css';
//import './StyleSheets/PriceForm.css';
import 'app-components/Zone/_StyleSheets/PriceForm.css';


import { CalcInputs } from './Calculate';
// import { 
//     //FormComposeMain, 
//     FormComposeOptions

// } from './FormCompose';


import InitFormData from './InitFormData';
import FormComposer from './FormComposer';



import { 
    FFheading,
    FFheader, 
    FFheadline,
    FFspacer,
    //FFtitleline,
    FFtitlegrid,
    FFseparator,
    FFbutton,
    FFiconbutton,
    FFcalcby,
    FFcheckbutton,
    FFcostfield,
    FFbluntfield,
    //FFlabelfield
    FFgrid
} from './FormFields';


import Handler from './Handler';
// import FlyrOptions from './FlyrOptions';
import FormSubmit from './FormSubmit';
import FormComplete from './FormComplete';


import Readout from './Readout';

//import CalcMenu from './CalcMenu';
//import LaborMenu from './LaborMenu';



import Loading from 'app-components/Zone/_Generic/Loading';
import Message from 'app-components/Zone/_Generic/Message';
//import Flanks from 'app-components/Zone/Calculator/_Generic/Flanks';



import CalcMenu from './Portals/CalcMenu';
import LaborMenu from './Portals/LaborMenu';


//import { AlterCalcs } from 'app-components/Zone/_Connect/Calcs/Alter';
import { FetchCalcs } from 'app-components/Zone/_Connect/Calcs/Fetch';

import { TimeoutHistory } from './TimeoutHistory';




const Index = (props) => {



    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    const history = useHistory();

    
 
    var pathname = location.pathname;
    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);




    // -- Window Dimensions -- //

    const { 
        width,
        height 
    } = useWindowDimensions();



    // -- Device Detect -- //

    const DeviceDetection = useDeviceDetect();
    //console.log("FFcostfield - DeviceDetection " + JSON.stringify(DeviceDetection));
    let isMobile = DeviceDetection.isMobile;





    // -- redux -- //
    const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);


 

    //=============================================
    // ref
    //=============================================
    //const refMain= useRef(null);

    
    // const rowRefs = [];
    // const setRowRef = (ref) => {

    //     if(ref && ref !== null && ref !== undefined)
    //     {
    //         //console.log("ref::::"+ref.id);
    //         var found;
    //         found = false;
    //         for(var i=0; i < rowRefs.length; i++)
    //         {
    //             if(rowRefs[i].id === ref.id) found = true;
    //         }
    //         if(!found) rowRefs.push(ref);
    //         console.log(rowRefs.length);
    //         console.log(rowRefs[0]);

    //     }

    // };
    






    //=============================================
    // state/effect - calcId calcName
    //=============================================

    // var query = location.search;
    // if(query.charAt(0) === '?')
    //     query = query.substring(1,query.length);

    // query = decodeURIComponent(query);
    // var queryArr = ["id=nocalc","name=No Calc"];
    // if(query.match(/&/gi))
    // {
    //     queryArr = query.split("&");
    // }
    // const [calcId] = useState(queryArr[0].split("=")[1]);
    // const [calcName] = useState(queryArr[1].split("=")[1]);



    // var squiggleStr;
    // var squiggleArr=["calc","The Profit Calculator"];
    // if(pathname.match(/Calculator~/gi))
    // {
    //     squiggleStr = pathname.split("Calculator~")[1];
    //     if(squiggleStr.charAt(squiggleStr.length-1) === '/') 
    //     squiggleStr = squiggleStr.substring(0,squiggleStr.length-1);
    //     squiggleArr = squiggleStr.split("~");

    //     // console.log(JSON.stringify(squiggleArr,null,2));

    // }

    // const [calcId] = useState(squiggleArr[0]);
    // const [calcName] = useState(squiggleArr[1]);






    //=============================================
    // state/effect - isLoaded - fetchAll
    //=============================================

    const [threshold] = useState(1050);
    const [isLoaded, setIsLoaded] = useState(false);

    const [hashData, setHashData] = useState(false);

    const [showMessage, setShowMessage] = useState(false);
    const [showCalcMenu, setShowCalcMenu] = useState(false);
    const [showLaborMenu, setShowLaborMenu] = useState(false);
    const [showReadout, setShowReadout] = useState(false);
    const [activeOptions, setActiveOptions] = useState(0);

    const [runHistory, setRunHistory] = useState(false);//use for after saveas fetched
    const [runFetch, setRunFetch] = useState(false);
    const [wasFetched, setWasFetched] = useState(false);
    const [fetchData] = useState({

        call:"fetchAll",
        recid:false//used if getting a specific

    });



 
    useEffect(() => {

        if(!isLoaded)
        {
            setIsLoaded(true);

            if(!zoneObj['calcs'] && !wasFetched)
            {

                //setRunFetch(true);

            }

        }



        if(hashData === "#!" && location.hash === "")
        {
            setHashData(false);
            setProcessSubmit(false); 
            //setShowOptions(false);  
        } 


        if(location.hash === "#!Calculator~readout" && showReadout && showCalcMenu && !showLaborMenu)
        {
            setShowCalcMenu(false);
     
        } 

        if(location.hash === "#!Calculator" && showReadout && !showCalcMenu && !showLaborMenu)
        {
            setProcessSubmit(false);
            setShowReadout(false);
     
        } 

        if(location.hash === "#!Calculator" && !showReadout && showCalcMenu && !showLaborMenu)
        { 

            setShowCalcMenu(false);

        }

        if(location.hash === "#!Calculator" && !showReadout && !showCalcMenu && showLaborMenu)
        { 

            setShowLaborMenu(false);

        }



    },
    [
        props,
        
        location,
    
        isLoaded,
        zoneObj,
        wasFetched,


        hashData,

        showCalcMenu,
        showLaborMenu,
        showReadout



    ]);






    





    //=============================================
    // state/effect - calcObj
    //=============================================

    const[calcObj, setCalcObj] = useState(false);
    useEffect(() => {


        let obj = false;
        let calcId = props.calcId;

        if(zoneObj.calc_dash && !calcObj)
        {
            // calcObj = CalcInfo(props);
            // console.log("calcObj: "+JSON.stringify(calcObj,null,2));
            if(zoneObj.calc_dash.result_items)
            {
                obj = {};
    
                    var overhead_percent;
                    overhead_percent = zoneObj.calc_dash.result_items['overhead'].overhead;
                    overhead_percent = parseFloat(overhead_percent); 
                    //overhead_percent = overhead_percent.toFixed(1) + "%";
    
                obj['overhead_percent'] = overhead_percent;



                if(zoneObj.calcs && calcId !== 'calc')
                {
                    var calcs,calc;

                    calcs = JSON.parse(JSON.stringify(zoneObj.calcs));
                    calc = calcs[calcId];
                    obj['calcby'] = calc.data.value.calcby;
                    obj['cost_of_goods'] = calc.data.value.cost_of_goods;
                    obj['debits'] = calc.data.value.debits;
                    if(calc.data.value.quantity) 
                    {
                        obj['quantity'] = calc.data.value.quantity;
                    }else{
                        obj['quantity'] = {

                            'id'        :'quantity',
                            'title'     :'Quantity', 
                            'value'     :1

                        };                    
                    }
                    //alert(JSON.stringify(calc,null,2));

            
                }else{

                    CalcInputs.calcby = {

                        "call"      :'percent',
                        'title'     :'Net Profit', //Net Profit, Selling Price, Markup Multiplier
                        'value'     :0.0

                    };
                    CalcInputs.cost_of_goods = [

                        {
                            'call'      :'good-1', 
                            'title'     :'Good 1', 
                            'rate'      :0.00, 
                            'qtyhrs'    :0.0, 
                            'isLabor'   :false, 
                            'other'     :{'label':'--', 'data':{'brand':'','name':''}},
                            
                        },

                        {
                            'call'      :'good-2', 
                            'title'     :'Good 2', 
                            'rate'      :0.00, 
                            'qtyhrs'    :0.0,  
                            'isLabor'   :false,
                            'other'     :{'label':'--', 'data':{'brand':'','name':''}},
                            
                        },

                        {
                            'call'      :'good-3',
                            'title'     :'Good 3', 
                            'rate'      :0.00, 
                            'qtyhrs'    :0.0, 
                            'isLabor'   :false, 
                            'other'     :{'label':'--','data':{'brand':'','name':''}},
                            
                        }


                    ];
                    CalcInputs.debits = [

                        {
                            'id'        :'commission',    
                            'title'     :'Commission', 
                            'value'     :0.0

                        }

                        // {

                        //     'id'        :'fees',       
                        //     'title'     :'Fees', 
                        //     'value'     :0.0

                        // }

                    ];

                    CalcInputs.quantity = {

                        'id'        :'quantity',
                        'title'     :'Quantity', 
                        'value'     :1

                    };



                }
                //-------------------------------------------------

                //console.log("obj: "+JSON.stringify(obj,null,2));

                setCalcObj(obj);

            }

        }         
    

    },
    [
        props,
        location,
        isLoaded,
        zoneObj,  
        calcObj

    ])





    //=============================================
    // handle
    //=============================================

    const handle = (hndl) => {


        switch(hndl.call)
        {
        case'hash':

            setHashData(hndl.data);
        break;
        case'history':


            switch(hndl.data.action)
            {
            case'goBack':

                history.goBack();
            break;
            default:
            };

        break;
        case'fetched'://SUCCESS

            //alert('fetched');
            //console.log(hndl.message);
            // setShowMessage({

            //     call:"showMessage",
            //     message:"Calcs were fetched",
            //     duration:1500

            // });
            setRunFetch(false);
            setWasFetched(true);


            var calcs,resultCalcs;
            var keyname;
            if(fetchData.call === 'fetch' && zoneObj['calcs'])
            {
                calcs = JSON.parse(JSON.stringify(zoneObj['calcs']));
                resultCalcs = JSON.parse(JSON.stringify( hndl.result.calcs));
                for(keyname in resultCalcs)
                {
                    calcs[keyname] = resultCalcs[keyname]
                }
            }else{
                //calcs = hndl.result.calcs;
                calcs = JSON.parse(JSON.stringify( hndl.result.calcs));
            }

            if(zoneObj['calcs'] && zoneObj['calcs'] !== undefined)
            {

                dispatch(ModifyZoneStore({

                    type:"DELETE_ZONE",//UPDATE_ZONE or DELETE_ZONE
                    item:{
                        key     :"calcs",
                        value   :false
                    }
        
                })); 

            }
            dispatch(ModifyZoneStore({

                type:"UPDATE_ZONE",//UPDATE_ZONE or DELETE_ZONE
                item:{
                    key     :"calcs",
                    value   :calcs
                }
    
            })); 




        break;
        case'error':
    
            //alert("fetch error...\n"+JSON.stringify(hndl));
            setShowMessage({

                call:"showMessage",
                message:hndl.message,
                duration:1500

            });

        break;
        case'done':

            setShowMessage(false);
            setRunFetch(false);
            setWasFetched(true);
        break;
        default:
        }


    }







    //=============================================
    // state / effect - form stuff
    //=============================================

    const refForm = useRef(null);

    const [processSubmit, setProcessSubmit] = useState(false); 
    const [processComplete, setProcessComplete] = useState(false); 
    const [formArr, setFormArr] = useState([]); 
    const [problemArr, setProblemArr] = useState([]);
    const [captured, setCaptured] = useState({});
    const [calcby, setCalcby] = useState('');

    useEffect(() => {


        var frm,dat;

        var keyname;
        // var rateColumn;
        // var qtyhrsColumn;
    
        var tempArr = [];
        var i,j;


        var quantity;
        var overhead;
        var overhead_percent;

        var newArr = [];
        var newFrm; 
        var newData;


        // if(Object.keys(captured).length === 0) alert('not captured')

        if(isLoaded && formArr.length === 0)
        {



            // -- initial -- //


            //alert(JSON.stringify(location,null,2));

            //--------------------------------------------------
            if(props.calcId === 'calc') 
            {
                InitFormData.array[0].data.text = props.calcName;
            }else{
                InitFormData.array[0].data.text = props.calcName;
            }
            //----------------------------------------------------

            var emptyGoods = false;
            var emptyDebits = false;
            if(CalcInputs.cost_of_goods.length === 0) emptyGoods = true;
            if(CalcInputs.debits.length === 0) emptyDebits = true;

            var initArr = FormComposer(InitFormData);
            //console.log("FormComposer::::"+JSON.stringify(initArr,null,2));

            for(i=0; i < initArr.length; i++)
            {
                //console.log(object['section'] + "\n" + JSON.stringify(object['data'],null,2));
                frm = initArr[i];
                switch(frm.section)
                {
                case'bluntfields':

                    for(j=0; j < frm.data.length; j++)
                    {
                        dat = frm.data[j];
                        //console.log("dat.field.id: "+dat.field.id);

                        switch(dat.field.id)
                        {
                        case'calcby':

                            CalcInputs['calcby'].value = dat.field.text;
                        break;
                        case'overhead':

                            CalcInputs['overhead'].value = dat.field.text;
                        break;
                        case'quantity':

                            CalcInputs['quantity'].value = dat.field.text;
                        break;
                        default:


                            //console.log(JSON.stringify(CalcInputs.debits,null,2));

                            if(emptyDebits && CalcInputs.debits.length !== 2)
                            {
                                CalcInputs['debits'].push({

                                    'id'        :dat.field.id,    
                                    'title'     :dat.label.text, 
                                    'value'     :0.0
                                });
                            }

                    
                        }
        
                    }//== j


                break;
                case'costfields':

                    //console.log("frm.data "+JSON.stringify(frm.data,null,2));
                    
                    for(dat of frm.data)
                    {

                        // console.log("rate id: "+dat.rate.id);
                        // console.log("qtyhrs id: "+dat.qtyhrs.id)

                        console.log("init dat: "+JSON.stringify(dat,null,2));

                        keyname = dat.rate.id.split("_")[1];
                        if(emptyGoods && CalcInputs.cost_of_goods.length !== 3)
                        {
                            CalcInputs['cost_of_goods'].push({

                                'call'      :keyname, 
                                'title'     :dat.label.text, 
                                'rate'      :dat.rate.text, 
                                'qtyhrs'    :dat.qtyhrs.text, 
                                'isLabor'   :dat.rate.isLabor, 
                                'other'     :{'label':'--', 'data':{'brand':'','name':''}},
                                    
                            });

                        }

                    }//== j

                break;
                default:
                }

                // console.log("CalcInputs: "+JSON.stringify(CalcInputs,null,2));
                // console.log("frm: "+JSON.stringify(frm,null,2));

                tempArr.push(frm);
    
            }//== i

            // setFormArr(tempArr);





            // -- capture -- //


            if(Object.keys(captured).length === 0)
            {
       
                // if(calcObj.calcby)
                // {
                //     var calcby_percent;
                //     calcby_percent = parseFloat(calcObj.calcby_percent);
                //     calcby_percent = calcby_percent.toFixed(1);
                //     CalcInputs.calcby.value = calcby_percent;
                // }
                if(calcObj.overhead_percent)
                {
                    overhead_percent = parseFloat(calcObj.overhead_percent);
                    overhead_percent = overhead_percent.toFixed(1);
                    CalcInputs.overhead.value = overhead_percent;
                }

                if(calcObj.calcby)          CalcInputs.calcby = calcObj.calcby;
                if(calcObj.cost_of_goods)   CalcInputs.cost_of_goods = calcObj.cost_of_goods;      
                if(calcObj.debits)          CalcInputs.debits = calcObj.debits;
                if(calcObj.quantity)        CalcInputs.quantity = calcObj.quantity;

                setCaptured(CalcInputs);


            }

            // if(calcby === '')
            // { 
            //     setCalcby('percent');
            //     captured['calcby'] = {
            //         call:'percent',
            //         title:"Net Profit",
            //         value:0
            //     };
                

            // }//== 




            // -- set -- //

            var tabIndex = 100;
            for(frm of tempArr)
            {
                switch(frm.section)
                {
                case'costfields':
        
  
                    //========================================
                    //cost_of_goods
                    //========================================
                
                    var cog = CalcInputs['cost_of_goods'];
                    var good;
                    var isLabor;

                    newFrm = {};
                    newFrm['section'] = 'costfields'
                    newData = [];

                    

                    for(good of cog)
                    {
                        // 'call'      :'good-1', 
                        // 'title'     :'Good 1', 
                        // 'rate'      :0.00, 
                        // 'qtyhrs'    :0.0,  
                        // 'other'     :{'label':'--', 'data':{'brand':'','name':''}},


                        if(good.isLabor === undefined) 
                            isLabor = false;
                        else
                            isLabor = good.isLabor;

                        //----------------------------------
                        newData.push({
                            "label": {
                                "text": good.title
                            },
                            "rate": {
                                "fieldtype": "text",
                                "required": true,
                                "class": "",
                                "id": "rate_"+good.call,
                                "hint": "",
                                "text": good.rate,
                                "linecolor": "yellow",
                                "isLabor" : isLabor,
                                "keyboardType": "",
                                "autocorrect": "false",
                                "autocapitalizationType": "none",
                                "tabIndex": tabIndex
                            },
                            "qtyhrs": {
                                "fieldtype": "text",
                                "required": true,
                                "class": "",
                                "id": "qtyhrs_"+good.call,
                                "hint": "",
                                "text": good.qtyhrs,
                                "keyboardType": "",
                                "autocorrect": "false",
                                "autocapitalizationType": "none",
                                "tabIndex": tabIndex + 1
                            }
                        });

                        tabIndex = tabIndex + 2;

                        //----------------------------------
                
            
                        //console.log("captured -- cost_of_goods -- "+JSON.stringify(newData,null,2));
                        
                    }//for cog

                    newFrm['data'] = newData;
                    newArr.push(newFrm);


                break;
                case'bluntfields':
        

                    var dataElem;
                    for(dataElem of frm.data)
                    {

                        if(dataElem.field.id === 'calcby')
                        {
    
                            //========================================
                            //calcby
                            //========================================

                            // "calcby": {

                            //     "call"      :'percent',
                            //     'title'     :'Net Profit', //Net Profit, Price, Markup Multiplier
                            //     'value'     :0.0
                                
                            // },

                            var calcby = CalcInputs['calcby'];

                            setCalcby(calcby.call);

                                
                            //console.log(JSON.stringify(frm.data,null,2));

                            newFrm = {};
                            newFrm['section'] = 'bluntfields';
                            newData = [];
                            //------------------------------------
                            newData.push({
                                "display": calcby.call,
                                "label": {
                                    "text": calcby.title
                                },
                                "field": {
                                    "fieldtype": calcby.call,
                                    "required": true,
                                    "class": "",
                                    "id": "calcby",
                                    "hint": "",
                                    "text": calcby.value,
                                    "linecolor": "#00e070",
                                    "keyboardType": "",
                                    "autocorrect": "false",
                                    "autocapitalizationType": "none",
                                    "tabIndex": tabIndex
                                }
                            });

                            tabIndex = tabIndex + 1;

                            //------------------------------------
                            newFrm['data'] = newData;
                            newArr.push(newFrm);
                            break;
                        }
                        else
                        if(dataElem.field.id === 'overhead')
                        {

                            //========================================
                            //overhead
                            //========================================

                            // "overhead":{

                            //     'id'        :'overhead',
                            //     'title'     :'Overhead', 
                            //     'value'     :0.0
                            // },
                        
                            overhead = CalcInputs['overhead'];
                            overhead_percent = overhead.value;


                            newFrm = {};
                            newFrm['section'] = 'bluntfields';
                            newData = [];
                            //------------------------------------
                            newData.push({
                                "display": "percent",
                                "label": {
                                    "text": overhead.title
                                },
                                "field": {
                                    "fieldtype": "percent",
                                    "required": false,
                                    "class": "",
                                    "id": overhead.id,
                                    "hint": "",
                                    "text": overhead_percent,
                                    "linecolor": "red",
                                    "keyboardType": "",
                                    "autocorrect": "false",
                                    "autocapitalizationType": "none",
                                    "tabIndex": tabIndex
                                }
                            });

                            tabIndex = tabIndex + 1;

                            //------------------------------------
                            newFrm['data'] = newData;
                            newArr.push(newFrm);
                            break;
                        }
                        else
                        if(dataElem.field.id === 'quantity')
                        {

                            //========================================
                            //quantity
                            //========================================

                            // "quantity":{

                            //     'id'        :'quantity',
                            //     'title'     :'Quantity', 
                            //     'value'     :1
                            // },


                            if(CalcInputs['quantity'] !== undefined)
                            {

                                quantity = CalcInputs['quantity'];
                                
                            
                                newFrm = {};
                                newFrm['section'] = 'bluntfields';
                                newData = [];
                                //------------------------------------
                                newData.push({
                                    "display": "wholenumber",
                                    "label": {
                                        "text": quantity.title
                                    },
                                    "field": {
                                        "fieldtype": "wholenumber",
                                        "required": false,
                                        "class": "",
                                        "id": quantity.id,
                                        "hint": "",
                                        "text": quantity.value,
                                        "linecolor": "#687484", //"#68748f",
                                        "keyboardType": "",
                                        "autocorrect": "false",
                                        "autocapitalizationType": "none",
                                        "tabIndex": tabIndex
                                    }
                                });

                                tabIndex = tabIndex + 1;

                                //------------------------------------
                                newFrm['data'] = newData;
                                newArr.push(newFrm);
                                break;


                            }
                        

                        }
                        else
                        {


                            //========================================
                            //debits
                            //========================================
                            // {
                            //     'id'        :'commission',    
                            //     'title'     :'Commission', 
                            //     'value'     :0.0

                            // },

                            // {

                            //     'id'        :'fees',       
                            //     'title'     :'Fees', 
                            //     'value'     :0.0

                            // }






                            newFrm = {};
                            newFrm['section'] = 'bluntfields';
                            newData = []; 
                            
                            var debits = CalcInputs['debits'];
                            var debit;
                            for(debit of debits)
                            {

                                switch(debit.id)
                                {
                                case'commission':
                                //case'fees':

                                    //------------------------------------
                                    newData.push({
                                        "display": "percent",
                                        "label": {
                                            "text": debit.title
                                        },
                                        "field": {
                                            "fieldtype": "percent",
                                            "required": false,
                                            "class": "",
                                            "id": debit.id,
                                            "hint": "",
                                            "text": debit.value,
                                            "linecolor": "orange",
                                            "keyboardType": "",
                                            "autocorrect": "false",
                                            "autocapitalizationType": "none",
                                            "tabIndex": tabIndex
                                        }
                                    });

                                    tabIndex = tabIndex + 1;

                                    //------------------------------------
                                    newFrm['data'] = newData;

                                break;
                                default:
                                }



                                //console.log("-------"+JSON.stringify(newData,null,2));
                             
                            }//for debits
                            newArr.push(newFrm);
                            break;
                        }



                    }


                break;
                default:

                    newArr.push(frm);
                break;
                }//==



            }//tempArr

            //console.log('captured '+JSON.stringify(captured,null,2));
            setFormArr(newArr);
          
        }//formArr.length




    },[

    
        props,

        zoneObj,
        calcObj,
   
        location,

        isLoaded,
        formArr,
        captured,
        calcby,

        refForm


    ])






    //=============================================
    // handleForm
    //=============================================

    const [currentField, setCurrentField] = useState(false);

    const handleForm = (hndl) => {

        //alert('handleForm'+JSON.stringify(hndl,null,2));

        hndl['refScrollbox'] = props.refScrollbox;
        hndl['location'] = location;
        hndl['formArr'] = formArr;
        hndl['captured'] = captured;
        hndl['handleState'] = (v) => {

            if(v.call === 'hashData')           setHashData(v.val);
            // if(v.call === 'showOptions')        setShowOptions(v.val);     
            if(v.call === 'processSubmit')      setProcessSubmit(v.val);
            if(v.call === 'processComplete')    setProcessComplete(v.val);
            if(v.call === 'formArr')            setFormArr(v.val);
            if(v.call === 'problemArr')         setProblemArr(v.val);
            if(v.call === 'captured')           setCaptured(v.val);
            if(v.call === 'calcby')             setCalcby(v.val);
            if(v.call === 'activeOptions')      setActiveOptions(v.val);
            if(v.call === 'readout')
            {    
                //alert('readout')
                setShowReadout(v.val);
                props.refScrollbox.current.scrollTop = 0;
          
            }
            if(v.call === 'showCalcMenu')       setShowCalcMenu(v.val);
            if(v.call === 'showLaborMenu')      setShowLaborMenu(v.val);

            //if(v.call === 'runFetch')           setRunFetch(v.val);
            //if(v.call === 'wasFetched')         setWasFetched(v.val);
         
            if(v.call === 'runHistory')         setRunHistory(v.val);
            if(v.call === 'runDispatch')        handleDispatch(v.val);
            if(v.call === 'reposScrollbox')     handleScrollbox(v.val);
            if(v.call === 'runFetch')           handleFetch(v.val);   

        };
        hndl['handleRoute'] = (v) => {
    
            switch(v.call)
            {
            case'doubleBack':

                //alert(props.fromReport);
                history.goBack();
                history.goBack();
                if(props.fromReport) history.goBack();
                handleScrollbox({scrollpos:0});
            break;
            case'goBack':
    
                history.goBack();
            break;
            case'reload':
    
                window.location.reload();//regular dom 
            break;
            case'redirect':
    
                history.replace(v.val);
            break;
            case'push':
    
                history.push(v.val);
            break;
            default:
            }
    
        }// == 


        hndl['handleCurrentField'] = (v) => {

            if(v.call === 'set') setCurrentField(v.val);

        }// == 



        Handler(hndl);



    }






    //=============================================
    // handleDispatch
    //=============================================

    const handleDispatch = (hndl) => {

        // console.log("CalcInputs: "+JSON.stringify(CalcInputs));
        // alert('handleDispatch');

        var inputs = JSON.parse(JSON.stringify(CalcInputs));

        dispatch(ModifyZoneStore({

            type:"UPDATE_ZONE",
            item:{
                key:"pricing_CalcInputs",
                value:inputs
            }

        })); 

        //setIsMerged(false);//this will reinitiate the merge after updating zone


        //handleMerge();

    }





    //=============================================
    // handleScrollbox
    //=============================================

    const handleScrollbox = (hndl) => {

        //alert('handleScrollbox: '+hndl.scrollpos);
        props.refScrollbox.current.scrollTop = hndl.scrollpos;

    }




    //=============================================
    // handleFetch
    //=============================================

    const handleFetch = (hndl) => {

        setShowCalcMenu(false);

        props.handle({

            call:"refetch",
            data:''

        });

    }





    //=============================================
    // size and position
    //=============================================

    const [formStyle, setFormStyle] = useState({

        backgroundColor : "#191E28", 
        width           : "650px",
        //height          : dfHeight, 
        margin          : "10px 0 150px 30px",
        padding         : "0 0 50px 0",
        //padding         : "0 20px 50px 20px",
        //border          : "1px solid #191E28",
        border          : "1px solid lightgray",
        borderRadius    : "10px"

    });
 
    useEffect(() => {


        var dfWidthInt;
        var dfWidth;
    
        //var dfHeightInt;
        //var dfHeight;
    
        //var dfMarlInt;
        var dfMarl;
        var dfBorder;
    
        dfWidthInt = 650;
        dfWidth = dfWidthInt + "px";
        dfBorder = "0px solid #1a1926";
    
        dfMarl = "0px";
        //dfMarl = "calc(50% - "+(dfWidthInt+40)/2+"px)";
        //if(width > 1200) dfMarl = 150 + "px";


        formStyle.margin = "10px 0 150px "+dfMarl;
        //formStyle.padding = "0 20px 50px 20px";
        formStyle.padding = "0 0 50px 0";
        formStyle.borderRadius = "10px";
    

            if(width < threshold)
            {
                dfWidthInt = 650;
                dfWidth = dfWidthInt + "px";
                dfBorder = "0px solid white";

                dfMarl = "0px";
                dfMarl = "calc(50% - "+(dfWidthInt)/2+"px)";
                //if(width > 1200) dfMarl = 150 + "px";


                // dfMarlInt = ( width - formWidthInt )/2; 
                // dfMarl = dfMarlInt + "px";


           
            }

            if(width < 700)
            {
                dfWidthInt = width - 20;
                dfWidth = dfWidthInt + "px";
                dfBorder = "0px solid white";

                // dfMarlInt = 25;
                // dfMarl = dfMarlInt + "px";

                dfMarl = "0px";
                dfMarl = "calc(50% - "+(dfWidthInt)/2+"px)";
                //if(width > 1200) dfMarl = 150 + "px";


                formStyle.margin = "0 0 100px 0";
                formStyle.padding = "0 10px 50px 10px";
                //formStyle.padding = "0 0 50px 0";
                formStyle.borderRadius = "0px";
    
            }



        formStyle.width = dfWidth;
        formStyle.border = dfBorder;
        setFormStyle(formStyle);  


    },[

        width,
        threshold,
        refForm,
        formStyle
    ]);






    //=============================================
    // handleMovement
    //=============================================

    function handleMovement(hndl) {

        //console.log("hndl.event.key: "+hndl.event.key);

        //var activeLabelId = false;
        var elem = false;
        var formObj, dataObj;
        var find;
        var found = false;
        //var nextTab = 10000;
        var count;

        var ijArr;
        var el;
        var fromTop;


        if(hndl.call === 'laborMenu')
        {

            //alert(JSON.stringify(hndl,null,2));


            //-----------------------------
            // find
            //-----------------------------

            var i = 0;
            var j;
            for(formObj of formArr)
            {

                //console.log("SECTION: "+formObj.section);

                if(formObj.section === hndl.section)
                {
                    if(hndl.section === 'costfields')
                    {
                        j = 0;
                        for(dataObj of formObj.data)
                        {
                            document.getElementById(dataObj.rate.id).blur();
                            find = parseInt(dataObj.rate.tabIndex) + 1;
                        
                            if(i+"-"+j === hndl.data.index) 
                            {
                                //alert(JSON.stringify(dataObj.rate,null,2));
                                break;
                            }
                            j++;
                
                        }  
                    
                    }
                    else
                    if(hndl.section === 'bluntfields')
                    {
                        j = 0;
                        for(dataObj of formObj.data)
                        {
                            //console.log("dataObj:::"+JSON.stringify(dataObj,null,2));
            
                            document.getElementById(dataObj.field.id).blur();
                            find = parseInt(dataObj.field.tabIndex) + 1;

                            if(i+"-"+j === hndl.data.index) 
                            {
                                //alert(JSON.stringify(dataObj.rate,null,2));
                                break;
                            }
                            j++;
                        }  

                    }
                    // else
                    // if(hndl.section === 'button')
                    // {

                    //     // found = true;
                    //     // break;

                    // }


                }

                if(find) break;

                i++;
            }
            //alert(find);



            //-----------------------------
            // found
            //-----------------------------

            if(find)
            {

                //alert(find);


                count = 0;
                for(formObj of formArr)
                {
                    //console.log("SECTION: "+formObj.section);

                    if(formObj.section === 'costfields')
                    {

                        if(Object.prototype.toString.call(formObj.data) === '[object Array]')
                        {

                            for(dataObj of formObj.data)
                            {

                                if(dataObj.label)
                                {
                                    if(parseInt(dataObj.label.tabIndex) === find)
                                    {
                                        found = {
                                            section:formObj.section,
                                            data:dataObj.label,
                                            index:count
                                        };
                                        break;
                                    }

                                }
                                if(dataObj.rate)
                                {
                                    if(parseInt(dataObj.rate.tabIndex) === find)
                                    {
                                        found = {
                                            section:formObj.section,
                                            data:dataObj.rate,
                                            index:count
                                        };
                                        break;
                                    }

                                }

                                if(dataObj.qtyhrs)
                                {
                                    if(parseInt(dataObj.qtyhrs.tabIndex) === find)
                                    {
                                        found = {
                                            section:formObj.section,
                                            data:dataObj.qtyhrs,
                                            index:count
                                        };
                                        break;
                                    }


                                }



                            }  

                        }

                    
                    }
                    else
                    if(formObj.section === 'bluntfields')
                    {

                        if(Object.prototype.toString.call(formObj.data) === '[object Array]')
                        {
                            for(dataObj of formObj.data)
                            {
                                //console.log("dataObj:::"+JSON.stringify(dataObj,null,2));

                                if(dataObj.field)
                                {
                                    if(parseInt(dataObj.field.tabIndex) === find)
                                    {
                                        found = {
                                            section:formObj.section,
                                            data:dataObj.field,
                                            index:count
                                        };
                                        break;
                                    }

                                }

                            }  

                        }

                    }
                    else
                    if(formObj.section === 'button')
                    {

                        // found = true;
                        // break;

                    }
                    count++;

                    if(found) break;
                }


                if(found)
                {
                    //console.log("found: "+JSON.stringify(found,null,2));

                    switch(found.section)
                    {
                    case'costfields':

                        if(found.data.id.match(/label/gi))
                        { 
                            //console.log("costfields label - "+JSON.stringify(found.data,null,2));
                            //elem = document.getElementById(found.data.id);
                            // activeLabelId = found.data.id;

                        }
                        if(found.data.id.match(/rate/gi))
                        { 
                            //console.log("costfields rate - "+JSON.stringify(found.data,null,2));
                            elem = document.getElementById(found.data.id);

                        }
                        if(found.data.id.match(/qtyhrs/gi))
                        {
                            //console.log("costfields - qtyhrs: "+JSON.stringify(found.data,null,2));
                            elem = document.getElementById(found.data.id);

                        }

                    break;
                    case'bluntfields':

                        if(found.data.id)
                        {
                            //alert("bluntfield: "+JSON.stringify(found.data,null,2));
                            elem = document.getElementById(found.data.id);

                        }

                    break;
                    default:
                    }


                    if(elem) 
                    {
                        elem.focus();
                        //console.log("offsetTop: "+elem.offsetTop);

                        //alert(elem.id);


                        ijArr = hndl.data.index.split("-");
                        if(found.section === 'costfields')
                        {
                            el = document.getElementById("formSection_"+found.index);
                            fromTop = el.offsetTop;
                            if(ijArr[1] !== 0)
                            {
                                fromTop = fromTop + (25 * ijArr[1]);
                            }
                            //alert(el.offsetTop+" "+fromTop)
                            props.refScrollbox.current.scrollTop = fromTop;
                        }
                   
                        if(found.section === 'bluntfields')
                        {    
                            el = document.getElementById("formSection_"+found.index);
                            if(elem.id === 'calcby') 
                            {
                                fromTop = el.offsetTop - 150;
                                //alert(el.offsetTop+" "+fromTop)
                            }else{
                                fromTop = el.offsetTop - 40;
                                //alert(el.offsetTop+" "+fromTop)
                            }
                            props.refScrollbox.current.scrollTop = fromTop; 
  
                        }


                        if(found.section === 'button')
                        {    
                            el = document.getElementById("formSection_"+found.index);
 
                            fromTop = el.offsetTop - 40;
                            alert(el.offsetTop+" "+fromTop)
                            props.refScrollbox.current.scrollTop = fromTop; 
                        
                        }


                    }//==
                
                }//found


            }//find



            // alert("LABOR MENU - MOVE TO THE NEXT"

            //     +"\nhndl.section: "+hndl.section
            //     +"\nhndl.call: "+hndl.call
            //     +"\nhndl.data: "+JSON.stringify(hndl.data,null,2)
            //     +"\nfind:"+find
            //     +"\nfound: "+JSON.stringify(found,null,2)

            // );


        }
        else
        if(hndl.event.key.toUpperCase() === 'ENTER')
        {
            //console.log(JSON.stringify(formArr,null,2));

            // alert("MOVE TO THE NEXT"

            //     +"\nhndl.section: "+hndl.section
            //     +"\nhndl.event.key: "+hndl.event.key
            //     +"\nhndl.event.target.id: "+hndl.event.target.id
            //     +"\nhndl.event.target.value: "+hndl.event.target.value
            //     +"\nhndl.event.target.tabIndex: "+hndl.event.target.tabIndex

            // );


            // {
            //     "label": {
            //       "text": "Good 1"
            //     },
            //     "rate": {
            //       "fieldtype": "text",
            //       "required": true,
            //       "class": "",
            //       "id": "rate_good-1",
            //       "hint": "",
            //       "text": 0,
            //       "linecolor": "yellow",
            //       "isLabor": false,
            //       "keyboardType": "",
            //       "autocorrect": "false",
            //       "autocapitalizationType": "none"
            //     },
            //     "qtyhrs": {
            //       "fieldtype": "text",
            //       "required": true,
            //       "class": "",
            //       "id": "qtyhrs_good-1",
            //       "hint": "",
            //       "text": 0,
            //       "keyboardType": "",
            //       "autocorrect": "false",
            //       "autocapitalizationType": "none",
            //       "tabIndex":0
            //     }
            // },




            // {
            //     "display": "percent",
            //     "label": {
            //       "text": "Net Profit"
            //     },
            //     "field": {
            //       "fieldtype": "percent",
            //       "required": true,
            //       "class": "",
            //       "id": "calcby",
            //       "hint": "",
            //       "text": "25.0",
            //       "linecolor": "#00e070",
            //       "keyboardType": "",
            //       "autocorrect": "false",
            //       "autocapitalizationType": "none"
            //     }
            // }



            //-----------------------------
            // find
            //-----------------------------

            for(formObj of formArr)
            {
                //console.log("SECTION: "+formObj.section);

                if(formObj.section === hndl.section)
                {
                    if(hndl.section === 'costfields')
                    {
                        for(dataObj of formObj.data)
                        {
                            if(dataObj.label.id === hndl.event.target.id)
                            { 
                                //alert(dataObj.label.id +" === "+hndl.event.target.id);
                                document.getElementById(dataObj.label.id).blur();
                                find = parseInt(hndl.event.target.tabIndex) + 1;
                                break;
                            }
                            if(dataObj.rate.id === hndl.event.target.id)
                            { 
                                document.getElementById(dataObj.rate.id).blur();
                                find = parseInt(hndl.event.target.tabIndex) + 1;
                                break;
                            }
                            if(dataObj.qtyhrs.id === hndl.event.target.id)
                            {
                                document.getElementById(dataObj.qtyhrs.id).blur();
                                find = parseInt(hndl.event.target.tabIndex) + 1;
                                break;
                            }

                        }  
                    
                    }
                    else
                    if(hndl.section === 'bluntfields')
                    {
                        for(dataObj of formObj.data)
                        {
                            //console.log("dataObj:::"+JSON.stringify(dataObj,null,2));
                            if(dataObj.field.id === hndl.event.target.id)
                            {
                                document.getElementById(dataObj.field.id).blur();
                                find = parseInt(hndl.event.target.tabIndex) + 1;
                                break;
                            }
                        }  

                    }
                    // else
                    // if(hndl.section === 'button')
                    // {

                    //     // found = true;
                    //     // break;

                    // }


                }

                if(find) break;
            }





            //-----------------------------
            // found
            //-----------------------------

            if(find)
            {

                //alert(find);

                count = 0;
                for(formObj of formArr)
                {
                    //console.log("SECTION: "+formObj.section);

                    if(formObj.section === 'costfields')
                    {

                        if(Object.prototype.toString.call(formObj.data) === '[object Array]')
                        {

                            for(dataObj of formObj.data)
                            {

                                if(dataObj.label)
                                {
                                    if(parseInt(dataObj.label.tabIndex) === find)
                                    {
                                        found = {
                                            section:formObj.section,
                                            data:dataObj.label,
                                            index:count
                                        };
                                        break;
                                    }

                                }
                                if(dataObj.rate)
                                {
                                    if(parseInt(dataObj.rate.tabIndex) === find)
                                    {
                                        found = {
                                            section:formObj.section,
                                            data:dataObj.rate,
                                            index:count
                                        };
                                        break;
                                    }

                                }

                                if(dataObj.qtyhrs)
                                {
                                    if(parseInt(dataObj.qtyhrs.tabIndex) === find)
                                    {
                                        found = {
                                            section:formObj.section,
                                            data:dataObj.qtyhrs,
                                            index:count
                                        };
                                        break;
                                    }


                                }



                            }  

                        }

                    
                    }
                    else
                    if(formObj.section === 'bluntfields')
                    {

                        if(Object.prototype.toString.call(formObj.data) === '[object Array]')
                        {
                            for(dataObj of formObj.data)
                            {
                                //console.log("dataObj:::"+JSON.stringify(dataObj,null,2));

                                if(dataObj.field)
                                {
                                    if(parseInt(dataObj.field.tabIndex) === find)
                                    {
                                        found = {
                                            section:formObj.section,
                                            data:dataObj.field,
                                            index:count
                                        };
                                        break;
                                    }

                                }

                            }  

                        }

                    }
                    else
                    if(formObj.section === 'button')
                    {

                        found = {
                            section:formObj.section,
                            data:dataObj.field,
                            index:count
                        };
                        // break;

                    }

                    count++;

                    if(found) break;

       
                }


                if(found)
                {
                    //console.log("found: "+JSON.stringify(found,null,2));

                    switch(found.section)
                    {
                    case'costfields':

                        if(found.data.id.match(/label/gi))
                        { 
                            //console.log("costfields label - "+JSON.stringify(found.data,null,2));
                            //elem = document.getElementById(found.data.id);
                            // activeLabelId = found.data.id;

                        }
                        if(found.data.id.match(/rate/gi))
                        { 
                            //console.log("costfields rate - "+JSON.stringify(found.data,null,2));
                            elem = document.getElementById(found.data.id);

                        }
                        if(found.data.id.match(/qtyhrs/gi))
                        {
                            //console.log("costfields - qtyhrs: "+JSON.stringify(found.data,null,2));
                            elem = document.getElementById(found.data.id);

                        }

                    break;
                    case'bluntfields':

                        if(found.data.id)
                        {
                            //alert("bluntfield: "+JSON.stringify(found.data,null,2));
                            elem = document.getElementById(found.data.id);

                        }

                    break;
                    default:
                    }

                    if(elem) 
                    {
                        elem.focus();
                        //console.log("offsetTop: "+elem.offsetTop);

                        //alert(elem.id);

                        ijArr = hndl.index.split("-");
                        if(found.section === 'costfields')
                        {
                            el = document.getElementById("formSection_"+found.index);
                            fromTop = el.offsetTop;
                            if(ijArr[1] !== 0)
                            {
                                fromTop = fromTop + (25 * ijArr[1]);
                            }
                            //alert(el.offsetTop+" "+fromTop)
                            props.refScrollbox.current.scrollTop = fromTop;
                        }
                        


                        if(found.section === 'bluntfields')
                        {    
                            el = document.getElementById("formSection_"+found.index);
                            if(elem.id === 'calcby') 
                            {
                                fromTop = el.offsetTop - 150;
                                //alert(el.offsetTop+" "+fromTop)
                            }else{
                                fromTop = el.offsetTop - 40;
                                //alert(el.offsetTop+" "+fromTop)
                            }
                            props.refScrollbox.current.scrollTop = fromTop; 
                        
                        }

                        if(found.section === 'button')
                        {    
                            el = document.getElementById("formSection_"+found.index);
 
                            fromTop = el.offsetTop - 40;
                            alert(el.offsetTop+" "+fromTop)
                            props.refScrollbox.current.scrollTop = fromTop; 
                        
                        }

                    }//==


                }else{

                    // alert('not found');
                    // el = document.getElementById("bottomSection");
                    // fromTop = el.offsetTop - 40;
                    // alert(fromTop);
                    // props.refScrollbox.current.scrollTop = fromTop; 

                }//found

            }//find




            // alert("MOVE TO THE NEXT"

            //     +"\nhndl.section: "+hndl.section
            //     +"\nhndl.event.key: "+hndl.event.key
            //     +"\nhndl.event.target.id: "+hndl.event.target.id
            //     +"\nhndl.event.target.value: "+hndl.event.target.value
            //     +"\nhndl.event.target.tabIndex: "+hndl.event.target.tabIndex

            //     +"\nfind:"+find
            //     +"\nfound: "+JSON.stringify(found,null,2)

            // );


        }//keypress ENTER





        // if(e.key === 'y')
        // {
        // 	alert('The sky is your starting point!')
        // }
        // else 
        // if(e.key === 'n') 
        // {
        //     alert('The sky is your limit👀')
        // }



    }








    //=============================================
    // keyPress
    //=============================================

    const [currentFlyr, setCurrentFlyr] = useState(false);

    useKeyPress('Enter', () => {

        if(showLaborMenu) setCurrentFlyr("laborMenu")
        if(location.hash === "#!Calculator~labormenu" && showLaborMenu && currentFlyr)
        {
            console.log('Close "Labor Menu 1" and move to next input');

            handleMovement({

                call:'laborMenu',
                data:showLaborMenu,
                section:"costfields"

            });
            setCurrentFlyr(false);
            setShowLaborMenu(false);       
            history.goBack();

        } 

    });





    if(!isLoaded) 
    {

        //return <div className="area-title">Loading...</div>


        return (<div>


            <div 
            ref={refForm}
            key={"price_form"}
            className="PriceForm"
            style={{


                // backgroundColor : "#191E28", 
                // width           : dfWidth,
                // //height          : dfHeight, 
                // margin          : "10px 0 50px "+dfMarl,
                // padding         : "0 20px 50px 20px",
                // border          : dfBorder,
                // borderRadius    : "10px"

                backgroundColor : formStyle.backgroundColor, 
                width           : formStyle.width,
                //height          : formStyle.height, 
                margin          : formStyle.margin,
                padding         : formStyle.padding,
                border          : formStyle.border,
                borderRadius    : formStyle.borderRadius


            }}

            ></div>


        </div>)

    } 
    else 
    {


        return (<div>


            {/* {
            //------------------------------------
            //FLYR
            //------------------------------------

            showOptions ? 

                <FlyrOptions

                    width={width} 
                    height={height} 
                    handle={(v) => handle(v)} 
                    optionsData={FormComposeOptions}
                    captured={captured}
            
                    //func={() => history.replace("")}
            
                /> 

            : null


            //-------------------------------------
            } */}


            {
            //------------------------------------
            //FLYR
            //------------------------------------

            processSubmit 
            ? 

                <FormSubmit

                    width={width} 
                    height={height} 
                    handle={(v) => handleForm(v)}   
                    formArr={formArr}
                    captured={captured}
            
            
                /> 

            : null


            //-------------------------------------
            }


            {
            //------------------------------------
            //
            //------------------------------------

            processComplete 
            ? 

                <FormComplete

                    width={width} 
                    height={height} 
                    handle={(v) => handleForm(v)}   
                    formArr={formArr}
                    captured={captured}
            
                /> 

            : null


            //-------------------------------------
            }









            {
            //------------------------------------
            //
            //------------------------------------

            showCalcMenu 
            ? 

                // <CalcMenu

                //     primeProps={props.primeProps}
                //     handle={(v) => handleForm(v)}   
                //     width={width} 
                //     height={height} 
                //     formArr={formArr}
                //     captured={captured}
                //     calcId={props.calcId}
            
                // /> 



                <CalcMenu

                    primeProps={props.primeProps}
                    handle={(v) => handleForm(v)}   
                    //width={width} 
                    //height={height} 
                    formArr={formArr}
                    captured={captured}
                    calcId={props.calcId}
            
                /> 


            : null


            //-------------------------------------
            }


            {
            //--------------------------------------
            runFetch
            ?
                <div>

                    <Loading />
                    <FetchCalcs 

                        primeProps={props.primeProps}
                        handle={handle}
                        //duration={2500}
                        data={fetchData}
                        // formArr={props.formArr}
                        // captured={props.captured}

                    />

                </div>

            :null
            //-------------------------------------------
            }


            {
            //--------------------------------------
            runHistory
            ?
                <div>

                    {
                    // <Loading />
                    }
                    <TimeoutHistory

                        primeProps={props.primeProps}
                        handle={handle}
                        //duration={2500}
                        data={runHistory}
                        // formArr={props.formArr}
                        // captured={props.captured}

                    />

                </div>

            :null
            //-------------------------------------------
            }





            {
            //-------------------------------------------
            showMessage
            ?
                

                <Message 

                    handle={handle}
                    call={showMessage.call}
                    message={showMessage.message}
                    duration={showMessage.duration}
                    data={props.data}
                    styling={props.styling}

                />


            :null
            //----------------------------------------------
            }



            {
            //-------------------------------------------
            showLaborMenu
            ?
                
                // <LaborMenu

                //     primeProps={props.primeProps}
                //     handle={(v) => handleForm(v)}   
                //     data={showLaborMenu}
                    
                // /> 


                <LaborMenu

                    primeProps={props.primeProps}
                    handle={(v) => handleForm(v)}   
                    data={showLaborMenu}
                    
                /> 



            :null
            //----------------------------------------------
            }








            {
            showReadout
            ?

                <Readout 

                    calcId={props.calcId}
                    calcName={props.calcName}
                    refScrollbox={props.refScrollbox}

                    handle={(v) => handleForm(v)}
                    data={showReadout}

                    width={width} 
                    height={height} 
                    formArr={formArr}
                    captured={captured}
                
                />

            :


                <div>
                    {/* <div>
                    {
                    //------------------------------------
                    //
                    //------------------------------------

                    showCalcMenu 
                    ? 

                        // <CalcMenu

                        //     primeProps={props.primeProps}
                        //     handle={(v) => handleForm(v)}   
                        //     width={width} 
                        //     height={height} 
                        //     formArr={formArr}
                        //     captured={captured}
                        //     calcId={props.calcId}
                    
                        // /> 



                        <CalcMenu

                            primeProps={props.primeProps}
                            handle={(v) => handleForm(v)}   
                            //width={width} 
                            //height={height} 
                            formArr={formArr}
                            captured={captured}
                            calcId={props.calcId}
                    
                        /> 


                    : null


                    //-------------------------------------
                    }


                    {
                    //--------------------------------------
                    runFetch
                    ?
                        <div>

                            <Loading />
                            <FetchCalcs 

                                primeProps={props.primeProps}
                                handle={handle}
                                //duration={2500}
                                data={fetchData}
                                // formArr={props.formArr}
                                // captured={props.captured}

                            />

                        </div>

                    :null
                    //-------------------------------------------
                    }


                    {
                    //--------------------------------------
                    runHistory
                    ?
                        <div>

                            {
                            // <Loading />
                            }
                            <TimeoutHistory

                                primeProps={props.primeProps}
                                handle={handle}
                                //duration={2500}
                                data={runHistory}
                                // formArr={props.formArr}
                                // captured={props.captured}

                            />

                        </div>

                    :null
                    //-------------------------------------------
                    }





                    {
                    //-------------------------------------------
                    showMessage
                    ?
                        

                        <Message 

                            handle={handle}
                            call={showMessage.call}
                            message={showMessage.message}
                            duration={showMessage.duration}
                            data={props.data}
                            styling={props.styling}

                        />


                    :null
                    //----------------------------------------------
                    }



                    {
                    //-------------------------------------------
                    showLaborMenu
                    ?
                        
                        // <LaborMenu

                        //     primeProps={props.primeProps}
                        //     handle={(v) => handleForm(v)}   
                        //     data={showLaborMenu}
                            
                        // /> 


                        <LaborMenu

                            primeProps={props.primeProps}
                            handle={(v) => handleForm(v)}   
                            data={showLaborMenu}
                            
                        /> 



                    :null
                    //----------------------------------------------
                    }
                    </div> */}




                    <div 
                    ref={refForm}
                    key={"price_form"}
                    className="PriceForm"
                    style={{

                        // backgroundColor : "#191E28", 
                        // width           : dfWidth,
                        // //height          : dfHeight, 
                        // margin          : "10px 0 50px "+dfMarl,
                        // padding         : "0 20px 50px 20px",
                        // border          : dfBorder,
                        // borderRadius    : "10px"


                        backgroundColor : formStyle.backgroundColor, 
                        width           : formStyle.width,
                        //height          : formStyle.height, 
                        margin          : formStyle.margin,
                        padding         : formStyle.padding,
                        border          : formStyle.border,
                        borderRadius    : formStyle.borderRadius

                    }}
                    >


                        <div className='controlPanel'>



                            {props.calcId === 'calc'
                            ?
            
                                <div
                                id={"menuButton"}
                                className="menuButton"
                                onClick={() => handleForm({

                                    call        :"showCalcMenu",
                                    data        :"saveas"
                                
                                })}
                                >
                                    {"SAVE AS"}

                                </div>

                    
                            :
                        
                                <div
                                id={"menuButton"}
                                className="menuButton"
                                onClick={() => handleForm({

                                    call        :"showCalcMenu",
                                    data        :false
                                
                                })}
                                >
                                    {"SAVE"}

                                </div>

                            }




                            <div
                            id={"menuButton"}
                            className="menuButton"
                            onClick={() => handle({

                                call:"history",
                                data:{
                                    action:"goBack"
                                }
                            
                            })}
                            >
                                {"BACK"}

                            </div>




                        </div>


                        {/* {
                        //------------------------------------
                        //
                        //------------------------------------

                        showCalcMenu 
                        ? 

                            <CalcMenu

                                primeProps={props.primeProps}
                                handle={(v) => handleForm(v)}   
                                width={width} 
                                height={height} 
                                formArr={formArr}
                                captured={captured}
                                calcId={props.calcId}
                        
                            /> 

                        : null


                        //-------------------------------------
                        }


                        {
                        //--------------------------------------
                        runFetch
                        ?
                            <div>

                                <Loading />
                                <FetchCalcs 

                                    primeProps={props.primeProps}
                                    handle={handle}
                                    //duration={2500}
                                    data={fetchData}
                                    // formArr={props.formArr}
                                    // captured={props.captured}

                                />

                            </div>

                        :null
                        //-------------------------------------------
                        }


                        {
                        //--------------------------------------
                        runHistory
                        ?
                            <div>

                                {
                                // <Loading />
                                }
                                <TimeoutHistory

                                    primeProps={props.primeProps}
                                    handle={handle}
                                    //duration={2500}
                                    data={runHistory}
                                    // formArr={props.formArr}
                                    // captured={props.captured}

                                />

                            </div>

                        :null
                        //-------------------------------------------
                        }





                        {
                        //-------------------------------------------
                        showMessage
                        ?
                            

                            <Message 

                                handle={handle}
                                call={showMessage.call}
                                message={showMessage.message}
                                duration={showMessage.duration}
                                data={props.data}
                                styling={props.styling}

                            />


                        :null
                        //----------------------------------------------
                        }



                        {
                        //-------------------------------------------
                        showLaborMenu
                        ?
                            
                            <LaborMenu

                                primeProps={props.primeProps}
                                handle={(v) => handleForm(v)}   
                                data={showLaborMenu}
                                
                            /> 


                        :null
                        //----------------------------------------------
                        } */}






                        {/**************
                        <div>
                            <code>

                                {FormCompose.map((data, i) => (

                                        <div 
                                        key={i}
                                        >
                                            {data.section}

                                        </div>

                                ))}
                            
                            </code>

                        </div>
                        **************/}






                        <div>
                        
                            {formArr.map((frm, i) => (


                                <div
                                key={i}
                                id={"formSection_"+i}
                                style={{

                                    backgroundColor : "transparent",
                                    width           : "100%",
                                    height          : "auto",
                                    position        : "relative",
                                    float           : "left"

                                }}

                                >




                                    {
                                    //------------------------------------
                                    //heading ( data = {} )
                                    //------------------------------------
                                    frm.section === "heading" 
                                    ? 

                                        <FFheading

                                            indx={i}
                                            data={frm.data} 
                                            width={width}

                                        />

                                    : null

                                    }



                                    {
                                    //------------------------------------
                                    //header ( data = {} )
                                    //------------------------------------
                                    frm.section === "header" 
                                    ? 

                                        <FFheader 

                                            indx={i}
                                            data={frm.data} 

                                        />

                                    : null
                                    
                                    }


                                    {
                                    //------------------------------------
                                    //headline ( data = {} )
                                    //------------------------------------
                                    frm.section === "headline" 
                                    ? 

                                        <FFheadline 

                                            indx={i}
                                            data={frm.data} 

                                        />

                                    : null

                                    }


                                    {
                                    //------------------------------------
                                    //spacer ( data = {} )
                                    //------------------------------------
                                    frm.section === "spacer" 
                                    ? 

                                        <FFspacer

                                            indx={i}
                                            data={frm.data} 

                                        />

                                    : null

                                    }




                                    {
                                    //------------------------------------
                                    //titlegrid ( data = {} )
                                    //------------------------------------
                                    frm.section === "titlegrid" 
                                    ? 

                                        <FFtitlegrid

                                            indx={i}
                                            data={frm.data} 

                                        />

                                    : null

                                    }



                                    {
                                    //------------------------------------
                                    //separator ( data = {} )
                                    //------------------------------------
                                    frm.section === "separator" 
                                    ? 

                                        <FFseparator

                                            indx={i}
                                            data={frm.data} 

                                        />

                                    : null

                                    }




                                    {
                                    //------------------------------------
                                    //grid ( data = {} )
                                    //------------------------------------
                                    frm.section === "grid" 
                                    ? 

                                        <FFgrid

                                            indx={i}
                                            data={frm.data} 
                                            handle={(v)=>handleForm(v)}   

                                        />

                                    : null

                                    }




                                    {
                                    //------------------------------------
                                    //button ( data = {} )
                                    //------------------------------------
                                    frm.section === "button" 
                                    ? 

                                        <FFbutton

                                            indx={i}
                                            data={frm.data} 
                                            handle={(v)=>handleForm(v)}   

                                        />

                                    : null

                                    }







                                    {
                                    //------------------------------------
                                    //button ( data = {} )
                                    //------------------------------------
                                    frm.section === "iconbutton" 
                                    ? 

                                        <FFiconbutton

                                            indx={i}
                                            data={frm.data} 
                                            handle={(v)=>handleForm(v)} 
                    
                                        />

                                    : null

                                    }



                                    {
                                    //------------------------------------
                                    //calcby ( data = {} )
                                    //------------------------------------
                                    frm.section === "calcby" 
                                    ? 

                                        <FFcalcby

                                            indx={i}
                                            data={frm.data} 
                                            handle={(v)=>handleForm(v)}  
                                            calcby={calcby}
                                            

                                        />

                                    : null

                                    }



                                    {
                                    //------------------------------------
                                    //checkbuttons ( data = {} )
                                    //------------------------------------
                                    frm.section === "checkbutton" 
                                    ? 

                                        <FFcheckbutton

                                            indx={i}
                                            data={frm.data}
                                            handle={(v)=>handleForm(v)}
                                            location={location}
                                            history={history}
                                            active={activeOptions}
                                        


                                        />

                                    : null
                                
                                    //-------------------------------------
                                    }




                                    {
                                    //------------------------------------
                                    //costfields ( data = [] )
                                    //------------------------------------
                                    frm.section === "costfields" 
                                    ? 

                                        <div>
                                        {frm.data.map((data, j) => (

                                            <div
                                            key={"data_"+i+"-"+j}
                                            >

                                                {/* <div
                                                key={"datacheck_"+i+"-"+j}
                                                style={{

                                                    backgroundColor:"lightyellow",
                                                    width:"100%",
                                                    height:"auto",
                                                    position:"relative",
                                                    float:"left",
                                                    color:"blue",
                                                    fontSize:"14px"
                                                }}
                                                >
                                                    {data.label.text}

                                                </div> */}

                                                <div
                                                key={i+"-"+j}
                                                id={"costfield_"+i+"-"+j}
                                                >
                                                    <FFcostfield

                                                        ij={i+"-"+j}
                                                        data={data}
                                                        handle={(v)=>handleForm(v)}
                                                        problemArr={problemArr}
                                                        currentField={currentField}

                                                        handleMovement={(v)=>handleMovement(v)}
                                        
                                                    
                                                    />

                                                </div>

                                            </div>


                                        ))}
                                        </div>

                                    

                                    : null
                                
                                    //-------------------------------------
                                    }








                                    {
                                    //------------------------------------
                                    //bluntfields ( data = [] )
                                    //------------------------------------
                                    frm.section === "bluntfields" 
                                    ? 


                                        <div>
                                        {frm.data.map((data, j) => (


                                            frm.data.length === (j-1)
                                            ?

                                                <div
                                                key={i+"-"+j}
                                                >
                                                    <FFbluntfield

                                                        ij={i+"-"+j}
                                                        data={data}
                                                        marBot="0px"
                                                        handle={(v)=>handleForm(v)}
                                                        problemArr={problemArr}
                                                        calcby={calcby}
                                                        calcId={props.calcId}


                                                        handleMovement={(v)=>handleMovement(v)}
                                                    

                                                    />

                                                </div>


                                            : //NOT LAST

                                                <div
                                                key={i+"-"+j}
                                                >
                                                    <FFbluntfield

                                                        ij={i+"-"+j}
                                                        data={data}
                                                        marBot="7px"
                                                        handle={(v)=>handleForm(v)}
                                                        problemArr={problemArr}
                                                        calcby={calcby}
                                                        calcId={props.calcId}


                                                        handleMovement={(v)=>handleMovement(v)}
                                                    

                                                    />

                                                </div>



                                        ))}
                                        </div>


                                    : null
                                
                                    //-------------------------------------
                                    }


                                </div>


                            ))}





                            <div
                            key={"bottomSection"}
                            id={"bottomSection"}
                            style={{

                                backgroundColor : "transparent",
                                width           : "100%",
                                height          : "auto",
                                position        : "relative",
                                float           : "left"

                            }}
                            >

                                {
                                //------------------------------------
                                //spacer ( isMobile )
                                //------------------------------------
                                isMobile
                                ? 

                                    <FFspacer

                                        indx={99}
                                        data={{

                                            "name"		:"spacer",
                                            "color"   	:'#454D5F',
                                            "height"  	:'150px'
                        
                                        }} 

                                    />


                                : null
                                }


                            </div>


            
                        </div>

                    </div>


                </div>

            }


        </div>)


    }

}

export default Index;


