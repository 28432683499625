

const VPRemplFilter = {

    "name"     : "Employee Filter",
    "value"    :{

        
        "empl_name": {

            "print"		:'standard',
            "name"		:'Name',
            "value"		:"TEMP NAME",
            "required"	:true,
            "default"	:""
        },


        // -- rates --//

        "empl_hourly_rate": {

            "print"		:'price',
            "name"		:'Hourly Rate',
            "value"		:"40",
            "required"	:true,
            "default"	:""
        },
        "empl_direct_hourly_rate": {

            "print"		:'price',
            "name"		:'Direct Hourly Rate',
            "value"		:"2",
            "required"	:true,
            "default"	:""
        },
        "empl_indirect_hourly_rate": {

            "print"		:'price',
            "name"		:'Indirect Hourly Rate',
            "value"		:"2",
            "required"	:true,
            "default"	:""
        },


        "empl_labor_burden_hourly_rate": {

            "print"		:'price',
            "name"		:'Labor Burden Hourly Rate',
            "value"		:"2",
            "required"	:true,
            "default"	:""
        },



        // -- weeks --//

        "empl_working_weeks": {

            "print"		:'standard',
            "name"		:'Working Weeks',
            "value"		:"40",
            "required"	:true,
            "default"	:""
        },
        "empl_working_months": {

            "print"		:'standard',
            "name"		:'Working Months',
            "value"		:"12",
            "required"	:true,
            "default"	:""
        },
        "empl_vacation_weeks": {

            "print"		:'standard',
            "name"		:'Vacation Weeks',
            "value"		:"2",
            "required"	:true,
            "default"	:""
        },




        // -- labor --//

        "empl_labor_direct": {

            "print"		:'standard',
            "name"		:'Direct Labor',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "empl_labor_indirect": {

            "print"		:'standard',
            "name"		:'Indirect Labor',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },




        // -- labor percent/decimal --//

        "empl_labor_percent": {

            "print"		:'percent',
            "name"		:'Direct Labor %',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "empl_labor_percent_direct": {

            "print"		:'percent',
            "name"		:'Direct Labor %',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "empl_labor_percent_indirect": {

            "print"		:'percent',
            "name"		:'Indirect Labor %',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },

        
        "empl_direct_labor_decimal": {

            "print"		:'standard',
            "name"		:'Direct Labor Decimal',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },






        // -- hours -- //

        "empl_hours_weekly": {

            "print"		:'standard',
            "name"		:'Hours Weekly',
            "value"		:"0",
            "required"	:true,
            "default"	:""
        },

        "empl_hours_direct": {

            "print"		:'standard',
            "name"		:'Hours Direct',
            "value"		:"0",
            "required"	:true,
            "default"	:""
        },

        "empl_hours_indirect": {

            "print"		:'standard',
            "name"		:'Hours Indirect',
            "value"		:"0",
            "required"	:true,
            "default"	:""
        },
        



        "empl_direct_hours_yearly": {

            "print"		:'standard',
            "name"		:'Direct Hours Yearly',
            "value"		:"0",
            "required"	:true,
            "default"	:""
        },

        "empl_indirect_hours_yearly": {

            "print"		:'standard',
            "name"		:'Indirect Hours Yearly',
            "value"		:"0",
            "required"	:true,
            "default"	:""
        },

        "empl_burden_hours_yearly": {

            "print"		:'standard',
            "name"		:'Hours Yearly (Burden)',
            "value"		:"0",
            "required"	:true,
            "default"	:""
        },
        



        // -- billable -- //

        "empl_billable_hours_weekly": {

            "print"		:'standard',
            "name"		:'Billable Hours Weekly',
            "value"		:"40",
            "required"	:true,
            "default"	:""
        },

        "empl_billable_hours_daily": {

            "print"		:'standard',
            "name"		:'Billable Hours Daily',
            "value"		:"40",
            "required"	:true,
            "default"	:""
        },

        "empl_billable_hours_monthly": {

            "print"		:'standard',
            "name"		:'Billable Hours Monthly',
            "value"		:"40",
            "required"	:true,
            "default"	:""
        },


        "empl_billable_hours_yearly": {

            "print"		:'standard',
            "name"		:'Billable Hours Yearly',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },








        // -- salary -- //

        "empl_direct_salary_yearly": {

            "print"		:'price',
            "name"		:'Direct Salary Yearly',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "empl_indirect_salary_yearly": {

            "print"		:'price',
            "name"		:'Indirect Salary Yearly',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "empl_direct_indirect_salary_yearly_total": {

            "print"		:'price',
            "name"		:'Direct/Indirect Salary Yearly Total',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },


        "empl_direct_salary_monthly": {

            "print"		:'price',
            "name"		:'Direct Salary Monthly',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "empl_indirect_salary_monthly": {

            "print"		:'price',
            "name"		:'Indirect Salary Monthly',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "empl_direct_indirect_salary_monthly_total": {

            "print"		:'price',
            "name"		:'Direct/Indirect Salary Monthly Total',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },








        // -- tax -- //

        
        "empl_tax_hourly": {

            "print"		:'percent',
            "name"		:'Tax Hourly',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "empl_tax_comp_percent": {

            "print"		:'percent',
            "name"		:'Tax Comp %',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "empl_tax_comp_decimal": {

            "print"		:'standard',
            "name"		:'Tax Comp Decimal',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        

        "empl_direct_tax_yearly": {

            "print"		:'price',
            "name"		:'Direct Tax Yearly',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "empl_indirect_tax_yearly": {

            "print"		:'price',
            "name"		:'Indirect Tax Yearly',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "empl_direct_indirect_tax_yearly_total": {

            "print"		:'price',
            "name"		:'Direct/Indirect Tax Yearly Total',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },



        "empl_direct_tax_monthly": {

            "print"		:'price',
            "name"		:'Direct Tax Monthly',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "empl_indirect_tax_monthly": {

            "print"		:'price',
            "name"		:'Indirect Tax Monthly',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "empl_direct_indirect_tax_monthly_total": {

            "print"		:'price',
            "name"		:'Direct/Indirect Tax Monthly Total',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },






        // -- ira -- //


        "empl_ira_percent": {

            "print"		:'percent',
            "name"		:'IRA Percent',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "empl_ira_decimal": {

            "print"		:'standard',
            "name"		:'IRA Decimal',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },




        "empl_ira_hourly": {

            "print"		:'price',
            "name"		:'IRA Hourly',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },


        "empl_direct_ira_yearly": {

            "print"		:'price',
            "name"		:'Direct IRA Yearly',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "empl_indirect_ira_yearly": {

            "print"		:'price',
            "name"		:'Indirect IRA Yearly',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "empl_direct_indirect_ira_yearly_total": {

            "print"		:'price',
            "name"		:'Direct/Indirect IRA Yearly Total',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },


        "empl_direct_ira_monthly": {

            "print"		:'price',
            "name"		:'Direct IRA Monthly',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "empl_indirect_ira_monthly": {

            "print"		:'price',
            "name"		:'Indirect IRA Monthly',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "empl_direct_indirect_ira_monthly_total": {

            "print"		:'price',
            "name"		:'Direct/Indirect IRA Monthly Total',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },




        // -- Health Ins. -- //

        "empl_health_insurance_yearly": {

            "print"		:'price',
            "name"		:'Health Ins. Yearly',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "empl_health_insurance_monthly": {

            "print"		:'price',
            "name"		:'Health Ins. Monthly',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "empl_health_insurance_weekly": {

            "print"		:'price',
            "name"		:'Health Ins. Weekly',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "empl_health_insurance_hourly": {

            "print"		:'price',
            "name"		:'Health Ins. Hourly',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },





        // -- totals -- //

        "empl_yearly_salary_w_health_insurance": {

            "print"		:'price',
            "name"		:'Yearly Salary w/ Health Insurance',
            "value"		:"",
            "required"	:true,
            "default"	:""
        },
        "empl_burden_yearly_total": {

            "print"		:'price',
            "name"		:'Burden Yearly Total',
            "value"		:"",
            "required"	:true,
            "default"	:""
        }




    }


    
};


export {

    VPRemplFilter
}







/*

"empl-1": {
    "name": "Jeff Beck",
    "value": {

        "empl_name": "Jeff Beck", 
        
        "empl_hourly_rate": "18.00",
        "empl_direct_hourly_rate": "21.96",  
        "empl_indirect_hourly_rate": "23.16",

        "empl_working_weeks": "50.00",
        "empl_vacation_weeks": "2.00",

        "empl_labor_direct": "Design,Production",
        "empl_labor_indirect": "Sales,Office",

        "empl_labor_percent": "70.00", 
        "empl_labor_percent_direct": "70.00",
        "empl_labor_percent_indirect": "30.00",
  
        "empl_direct_labor_decimal": "0.70",



        "empl_hours_weekly": "40.00",
        "empl_hours_direct": "28.00",       
        "empl_hours_indirect": "12.00",

        "empl_direct_hours_yearly": "600.00",
        "empl_indirect_hours_yearly": "1480.00",

        "empl_burden_hours_yearly": "2080.00",



        "empl_direct_salary_yearly": "10800.00",
        "empl_indirect_salary_yearly": "26640.00", 
        "empl_direct_indirect_salary_yearly_total": "37440.00",
     
        "empl_direct_salary_monthly": "900.00",
        "empl_indirect_salary_monthly": "2220.00",
        "empl_direct_indirect_salary_monthly_total": "3120.00",



        "empl_tax_hourly": "3.60",
        "empl_tax_comp_percent": "20.00",
        "empl_tax_comp_decimal": "0.20",

        "empl_direct_tax_yearly": "2160.00",
        "empl_indirect_tax_yearly": "5328.00",
        "empl_direct_indirect_tax_yearly_total": "7488.00",     
        
        "empl_direct_tax_monthly": "180.00",   
        "empl_indirect_tax_monthly": "444.00",
        "empl_direct_indirect_tax_monthly_total": "624.00",
           

        "empl_ira_percent": "2.00",
        "empl_ira_decimal": "0.02",
        "empl_ira_hourly": "0.36",

        "empl_direct_ira_yearly": "216.00",
        "empl_indirect_ira_yearlly": "532.80",   
        "empl_direct_indirect_ira_yearly_total": "748.80",

        "empl_direct_ira_monthly": "18.00",
        "empl_indirect_ira_monthly": "44.40",
        "empl_direct_indirect_ira_monthly_total": "62.40",
 


        "empl_health_insurance_yearly": "2400.00",
        "empl_health_insurance_monthly": "200.00",
        "empl_health_insurance_weekly": "48.00",
        "empl_health_insurance_hourly": "1.20",


        "empl_yearly_salary_w_health_insurance": "39840.00",

        "empl_burden_yearly_total": "48076.80",


    }
},


*/