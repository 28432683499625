import styled from "styled-components";
//import px2vw from "utils/px2vw";

/*
https://styled-components.com/docs


https://stackoverflow.com/questions/55906668/converting-css-with-nested-classes-to-styled-components-problem


Over 200 classes were generated for component styled.div with the id of "sc-AxirZ".
Consider using the attrs method, together with a style object for frequently changed styles.
Example:

const Component = styled.div.attrs({
    style: ({ background }) => {
        background,
    },
})`width: 100%;`

<Component />




calc(100% - 300px)


TM - Notes 

- ScrollBox calc(100% - 120px) 120px equals the HeaderNav height + InfoBar height
- Use vh - viewport height - to set the stage height




https://stackoverflow.com/questions/1216114/how-can-i-make-a-div-stick-to-the-top-of-the-screen-once-its-been-scrolled-to




*/




export const Container = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "#fff", //"#191e28", //"#EDEEF1",

    border:             props.border || "1px solid lightgray",
    borderRadius:       props.borderRadius || "7px",

    width:              props.width || "100%",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 100px 0",
    padding:            props.padding || "10px 0 20px 0",

    // zIndex:             props.zIndex || "3", 
    // top:                props.top || "0", 
    // left:               props.left || "0",


    titleFontFamily:         props.titleFontFamily || 'Arial, Helvetica, sans-serif',
    titleFontSize:           props.titleFontSize || "24px",
    titleFontWeight:         props.titleFontWeight  || "500",
    titleTextAlign:          props.titleTextAlign || "left",
    titleTextDecoration:     props.titleTextDecoration  || "none",
    titleTextColor:          props.titleTextColor || "#000",


    xButtonFontFamily:         props.xButtonFontFamily || 'Arial, Helvetica, sans-serif',
    xButtonFontSize:           props.xButtonFontSize || "24px",
    xButtonFontWeight:         props.xButtonFontWeight  || "500",
    xButtonTextAlign:          props.xButtonTextAlign || "center",
    xButtonTextDecoration:     props.xButtonTextDecoration  || "none",
    xButtonTextColor:          props.xButtonTextColor || "black",


    labelFontFamily:         props.labelFontFamily || 'Arial, Helvetica, sans-serif',
    labelFontSize:           props.labelFontSize || "18px",
    labelFontWeight:         props.labelFontWeight  || "500",
    labelTextAlign:          props.labelTextAlign || "left",
    labelTextDecoration:     props.labelTextDecoration  || "none",
    labelTextColor:          props.labelTextColor || "#000",

    cellFontFamily:         props.cellFontFamily || 'Arial, Helvetica, sans-serif',
    cellFontSize:           props.cellFontSize || "16px",
    cellFontWeight:         props.cellFontWeight  || "500",
    cellTextAlign:          props.cellTextAlign || "left",
    cellTextDecoration:     props.cellTextDecoration  || "none",
    cellTextColor:          props.cellTextColor || "black",
    



    headingFontFamily:         props.headingFontFamily || 'Arial, Helvetica, sans-serif',
    headingFontSize:           props.headingFontSize || "22px",
    headingFontWeight:         props.headingFontWeight  || "550",
    headingTextAlign:          props.headingTextAlign || "center",
    headingTextDecoration:     props.headingTextDecoration  || "none",
    headingTextColor:          props.headingTextColor || "#000",

    pgraphFontFamily:         props.pgraphFontFamily || 'Arial, Helvetica, sans-serif',
    pgraphFontSize:           props.pgraphFontSize || "16px",
    pgraphFontWeight:         props.pgraphFontWeight  || "500",
    pgraphTextAlign:          props.pgraphTextAlign || "center",
    pgraphTextDecoration:     props.pgraphTextDecoration  || "none",
    pgraphTextColor:          props.pgraphTextColor || "#000",



    listFontFamily:         props.listFontFamily || 'Arial, Helvetica, sans-serif',
    listFontSize:           props.listFontSize || "16px",
    listFontWeight:         props.listFontWeight  || "500",
    listTextAlign:          props.listTextAlign || "left",
    listTextDecoration:     props.listTextDecoration  || "none",
    listTextColor:          props.listTextColor || "lightgray",



    navFontFamily:         props.navFontFamily || 'Arial, Helvetica, sans-serif',
    navFontSize:           props.navFontSize || "18px",
    navFontWeight:         props.navFontWeight  || "550",
    navTextAlign:          props.navTextAlign || "center",
    navTextDecoration:     props.navTextDecoration  || "none",
    navTextColor:          props.navTextColor || "lightgray",


    buttonFontFamily:         props.buttonFontFamily || 'Arial, Helvetica, sans-serif',
    buttonFontSize:           props.buttonFontSize || "16px",
    buttonFontWeight:         props.buttonFontWeight  || "550",
    buttonTextAlign:          props.buttonTextAlign || "center",
    buttonTextDecoration:     props.buttonTextDecoration  || "none",
    buttonTextColor:          props.buttonTextColor || "white"




    // border-top:1px solid lightgray;
    // border-bottom:1px solid lightgray; 

    // #90ee90 #caffca


    // position:relative;
    // float:left;

    // position:absolute;
    // z-index:${props => props.zIndex};
    // top:${props => props.top};
    // left:${props => props.left};



    // @media (max-width: 100000px) {

    //     .Bod {

    //         width:450px;
    //         margin-left:calc(50% - 225px);
    //     }

    // }


    // @media (max-width: 600px) {

    //     .Bod {

    //         border-left:0;
    //         border-right:0;

    //         width:100%;
    //         margin-left:calc(50% - 50%);
 
    //     }

    // }




}))`

    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    border-radius:${props => props.borderRadius};

    min-height:150px;
    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    @media (max-width: 100000px) {

        width:450px;
        margin-left:calc(50% - 225px);

    }

    @media (max-width: 600px) {


        background-color:transparent;

        border-left:0;
        border-right:0;

        width:100%;
        margin-left:calc(50% - 50%);
    }



    .Bar {

        background-color:transparent;
        border-bottom:1px solid lightgray;

        width:100%;
        height:40px;
        padding:0 0 0 0;
        margin:0 0 0 0;
        position:relative;
        float:left;

        cursor:default;

    }


        .Bar .Title {

            background-color:transparent;

            width:auto;
            height:auto;
            padding:0 0 0 0;
            margin:10px 0 0 17px;
            position:relative;
            float:left;

            cursor:default;

            font-family:${props => props.titleFontFamily};
            font-size:${props => props.titleFontSize};
            font-weight:${props => props.titleFontWeight};
            text-align:${props => props.titleTextAlign};
            text-decoration:${props => props.titleTextDecoration};
            color:${props => props.titleTextColor};

        }


        .Bar .Xbutton {

            background-color:transparent;

            width:40px;
            height:auto;
            padding:8px 0 4px 0;
            margin:0 5px 0 0;
            position:relative;
            float:right;

            cursor:pointer;

            font-family:${props => props.xButtonFontFamily};
            font-size:${props => props.xButtonFontSize};
            font-weight:${props => props.xButtonFontWeight};
            text-align:${props => props.xButtonTextAlign};
            text-decoration:${props => props.xButtonTextDecoration};
            color:${props => props.xButtonTextColor};

            &:hover{

                color:orangered;

            }


        }






    .Ctrls {

        background-color:transparent;
    
        width:96%;
        height:auto;
        min-height:50px;
    
        padding:0 0 0 0;
        margin:0 0 25px 2%;
        position:relative;
        float:left;

        cursor:default;

        
    }


        .Ctrls .Button {

            background-color:#454d5f;
            border-radius:15px;

            width:200px;
            height:auto;
            padding:0 0 0 0;
            margin:5px 0 5px calc(50% - 100px);
            position:relative;
            float:left;

            cursor:pointer;

            &:hover{

                background-color: #07a2e3;

            }
        }



        .Ctrls .Button .Name {

            background-color:transparent;

            width:100%;
            height:auto;
            padding:12px 0 9px 0;
            margin:0 0 0 0;
            position:relative;
            float:left;

            cursor:pointer;

            font-family:${props => props.buttonFontFamily};
            font-size:${props => props.buttonFontSize};
            font-weight:${props => props.buttonFontWeight};
            text-align:${props => props.buttonTextAlign};
            text-decoration:${props => props.buttonTextDecoration};
            color:${props => props.buttonTextColor};


        }





        


    .Frm {

        background-color:white;
        width:98%;
        height:auto;
        min-height:10px;
    
        padding:0 0 0 0;
        margin:7px 0 10px 1.5%;
        position:relative;
        float:left;

        cursor:default;
        
    }



    .Err {

        background-color:pink;

        width:88%;
        height:auto;
        min-height:10px;
    
        padding:8px 0 4px 0;
        margin:7px 0 7px 6%;
        position:relative;
        float:left;

        cursor:default;
        
    }
        .Err .Line {

            background-color:transparent;

            width:100%;
            height:auto;
            padding:0 0 3px 0;
            margin:0 0 0 0;
            position:relative;
            float:left;

            cursor:default;

            font-family:${props => props.pgraphFontFamily};
            font-size:14px;
            font-weight:500;
            text-align:center;
            text-decoration:none;
            color:black;

            line-height:1.5;

        }




    .Review {

        background-color:#fff;
        border:1px solid lightgray;

        width:88%;
        height:auto;
        min-height:10px;
    
        padding:8px 0 32px 0;
        margin:7px 0 7px 6%;
        position:relative;
        float:left;

        cursor:default;
        
    }
        .Review .Line {

            background-color:transparent;
            border-bottom:1px solid lightgray;

            width:calc(100% - 30px);
            height:auto;
            padding:10px 0 5px 0;
            margin:0 0 0 15px;
            position:relative;
            float:left;

            cursor:default;

            font-family:${props => props.pgraphFontFamily};
            font-size:14px;
            font-weight:500;
            text-align:left;
            text-decoration:none;
            color:black;

            line-height:1.5;

        }





    .Bod {

        background-color:${props => props.backgroundColor};
        width:100%;
        height:auto;
        min-height:10px;
    
        padding:0 0 0 0;
        margin:0 0 0 0;
        position:relative;
        float:left;

        cursor:default;
      
    }


    @media (max-width: 100000px) {

        .Bod {

            width:450px;
            margin-left:calc(50% - 225px);
        }

    }


    @media (max-width: 600px) {

        .Bod {

            border-left:0;
            border-right:0;

            width:100%;
            margin-left:calc(50% - 50%);
 
        }

    }

    

        .Bod .Graphic {

            background-color:#191e28;

            width:calc(100% - 40px);
            height:100px;
            padding:0 0 0 0;
            margin:20px 0 0 20px;
            position:relative;
            float:left;
        }


        .Bod .Image {

            background-color:transparent;
   
            width:200px;
            height:auto;
            padding:0 0 0 0;
            margin:0 0 0 0;
            position:relative;
            float:left;
        }


        .Bod .Heading {

            background-color:transparent;

            width:calc(100% - 0px);
            height:auto;
            padding:0 0 3px 0;
            margin:15px 0 0 0;
            position:relative;
            float:left;

            cursor:default;

            font-family:${props => props.headingFontFamily};
            font-size:${props => props.headingFontSize};
            font-weight:${props => props.headingFontWeight};
            text-align:${props => props.headingTextAlign};
            text-decoration:${props => props.headingTextDecoration};
            color:${props => props.headingTextColor};

        }

        .Bod .Pgraph {

            background-color:transparent;

            width:calc(100% - 0px);
            height:auto;
            padding:0 0 3px 0;
            margin:0 0 0 0;
            position:relative;
            float:left;

            cursor:default;

            font-family:${props => props.pgraphFontFamily};
            font-size:${props => props.pgraphFontSize};
            font-weight:${props => props.pgraphFontWeight};
            text-align:${props => props.pgraphTextAlign};
            text-decoration:${props => props.pgraphTextDecoration};
            color:${props => props.pgraphTextColor};


            line-height:1.5;

        }


        .Bod .List {

            background-color:transparent;

            width:calc(100% - 100px);
            height:auto;
            padding:0 0 3px 50px;
            margin:7px 0 0 0;
            position:relative;
            float:left;

            cursor:default;
            line-height:1.5;

        }



            .Bod .List .ListItem {

                background-color:transparent;

                width:100%;
                height:auto;
                padding:0 0 0 0;
                margin:7px 0 0 0;
                position:relative;
                float:left;
                cursor:default;
            }


                .Bod .List .ListItem .Bullet {

                    background-color:#edeef1;
                    border-radius:20px;

                    width:10px;
                    height:10px;

                    padding:0 0 0 0;
                    margin:7.5px 12px 0 0;
                    position:relative;
                    float:left;
                    cursor:default;

                }


                .Bod .List .ListItem .Number {

                    background-color:transparent;

                    width:auto;
                    height:auto;
                    padding:0 0 0 0;
                    margin:0 12px 0 0;
                    position:relative;
                    float:left;

                    cursor:default;

                    font-family:${props => props.listFontFamily};
                    font-size:${props => props.listFontSize};
                    font-weight:600;
                    text-align:${props => props.listTextAlign};
                    text-decoration:${props => props.listTextDecoration};
                    color:#edeef1;


                    line-height:1.5;

                }


                .Bod .List .ListItem .Text {

                    background-color:transparent;

                    width:calc(100% - 30px);
                    height:auto;
                    padding:0 0 3px 0;
                    margin:0 0 0 0;
                    position:relative;
                    float:left;

                    cursor:default;

                    font-family:${props => props.listFontFamily};
                    font-size:${props => props.listFontSize};
                    font-weight:${props => props.listFontWeight};
                    text-align:${props => props.listTextAlign};
                    text-decoration:${props => props.listTextDecoration};
                    color:${props => props.listTextColor};

                    line-height:1.5;

                }




        .Bod .Nav {

            background-color:transparent;

            width:calc(100% - 60px);
            height:auto;
            padding:0 0 3px 30px;
            margin:15px 0 0 0;
            position:relative;
            float:left;

            cursor:default;
            line-height:1.5;

        }


            .Bod .Nav .NavLink {

                background-color:transparent;

                width:auto;
                height:auto;
                padding:0 0 3px 0;
                margin:0 0 5px calc(50% - 45px);
                position:relative;
                float:left;

                cursor:pointer;

                font-family:${props => props.navFontFamily};
                font-size:16px;
                font-weight:500;
                text-align:center;
                text-decoration:underline;
                color:#454d5f;

                line-height:1.5;

                &:hover{

                    text-decoration:underline;           
                    color:#07a2e3;
    

                }


            }





            .Bod .Nav .NavButton {

                background-color:#fc0;
                border-radius:15px;

                width:200px;
                height:auto;
                padding:7px 0 5px 0;
                margin:0 0 5px calc(50% - 100px);
                position:relative;
                float:left;

                cursor:pointer;

                font-family:${props => props.navFontFamily};
                font-size:${props => props.navFontSize};
                font-weight:${props => props.navFontWeight};
                text-align:${props => props.navTextAlign};
                text-decoration:${props => props.navTextDecoration};
                color:#000;

                &:hover{

                    background-color:#07a2e3;           
                    color:#fff;
    

                }

                -webkit-user-select: none; /* Safari */        
                -moz-user-select: none; /* Firefox */
                -ms-user-select: none; /* IE10+/Edge */
                user-select: none; /* Standard */

            }










        .Bod .Label {

            background-color:#edeef1;
            border:1px solid lightgray;
            border-radius:0px;

            width:calc(100% - 10px);
            height:auto;
            padding:10px 0 8px 10px;
            margin:0 0 0 0;
            position:relative;
            float:left;

            cursor:default;

            font-family:${props => props.labelFontFamily};
            font-size:${props => props.labelFontSize};
            font-weight:${props => props.labelFontWeight};
            text-align:${props => props.labelTextAlign};
            text-decoration:${props => props.labelTextDecoration};
            color:${props => props.labelTextColor};

        }



        .Bod .Grid {

            background-color:transparent;
            width:calc(100% - 10px);
            height:auto;
            padding:0 0 0 0;
            margin:5px 0 30px 5px;
            position:relative;
            float:left;
   
        }

        .Bod .Grid .Cell {

            background-color:transparent;
            border-bottom:1px solid lightgray;

            width:100%;
            height:auto;
            padding:0 0 0 0;
            margin:0 0 0 0;
            position:relative;
            float:left;

            cursor:pointer;

            &:hover{

                background-color: #caffca;

            }


        }


            .Bod .Grid .Cell .Name {

                background-color:transparent;

                width:auto;
                height:auto;
                padding:11px 0 7px 10px;
                margin:0 0 0 0;
                position:relative;
                float:left;

                cursor:pointer;

                font-family:${props => props.cellFontFamily};
                font-size:${props => props.cellFontSize};
                font-weight:${props => props.cellFontWeight};
                text-align:${props => props.cellTextAlign};
                text-decoration:${props => props.cellTextDecoration};
                color:${props => props.cellTextColor};


            }


            .Bod .Grid .Cell .Value {

                background-color:transparent;

                width:130px;
                height:auto;
                padding:11px 10px 7px 0;
                margin:0 0 0 0;
                position:relative;
                float:right;

                cursor:pointer;

                font-family:${props => props.cellFontFamily};
                font-size:${props => props.cellFontSize};
                font-weight:${props => props.cellFontWeight};
                text-align:right;
                text-decoration:${props => props.cellTextDecoration};
                color:${props => props.cellTextColor};


            }









        .Bod .PrevNext {

            background-color:transparent;

            width:calc(100% - 58px);
            height:auto;
            padding:0 0 0 0;
            margin:15px 0 0 31px;
            position:relative;
            float:left;

            cursor:default;
            line-height:1.5;

        }


            .Bod .PrevNext .Button {

                background-color:transparent;
                border-radius:7px;

                width:auto;
                height:auto;
                padding:0 0 0 0;
                margin:0 0 5px 0;
                position:relative;
                float:left;

                cursor:pointer;

                font-family:${props => props.navFontFamily};
                font-size:16px;
                font-weight:500;
                text-align:center;
                text-decoration:none;
                color:#454d5f;

                line-height:1.5;

                &:hover{

                    background-color:transparent;
                    text-decoration:none;           
                    color:#07a2e3;
    
                }


            }


















`;

