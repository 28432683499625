export const test = [


    // -----------------------------------
    // TemperatureGauge
    // -----------------------------------

    //bar
    {

        "print"		    :'bar',
        "name"		    :'Temperature Gauge',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },

    //spacer
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"20px"
        },
        "required"	    :"",
        "default"	    :""


    },

    // //chart
    {


        "print"		    :'temperature_gauge',
        "name"		    :'Temperature Gauge',
        "value"		    :{

            backgroundColor:"transparent",
            gaugeSize:400,
            margin:"0 0 0 0",

            gaugeBackgroundColor : false, //"#454d5f",
            gaugeBackgroundWidth : false, //85,

            gaugePercentageColor : "#c00",
            gaugePercentageWidth : false, //75,
            gaugePercent : 45.5,
  
            needleCenterColor : false, //"black",
            needleColor : false, //"black",
            needleSize : false, //"165",
            needleRotation  : -100,

            colors: "GaugeColorsB"

        },
        "required"	    :"",
        "default"	    :"",



    },

    //spacer
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"20px"
        },
        "required"	    :"",
        "default"	    :""


    },



    

    




    // -----------------------------------
    // Report Box - Gauge 4
    // -----------------------------------


    //bar
    {

        "print"		    :'bar',
        "name"		    :'Temperature Gauge',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },

    //spacer
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"20px"
        },
        "required"	    :"",
        "default"	    :""


    },


    //report_box
    {

        "print"		    :'report_box',
        "name"		    :'Report Box',
        "value"		    :[

            {

                boxCall:'temperature_gauge',
                boxWidth:"45%",
                boxHeight:225,
                value:{


                    backgroundColor:"transparent",
                    gaugeSize:225,
                    margin:"0 0 0 0",

                    gaugeBackgroundColor : false, //"#454d5f",
                    gaugeBackgroundWidth : false, //85,
    
                    gaugePercentageColor : "#c00",
                    gaugePercentageWidth : false, //75,
                    gaugePercent : 45.5,
          
                    needleCenterColor : false, //"black",
                    needleColor : false, //"black",
                    needleSize : false, //"165",
                    needleRotation  : -100,

                    colors: "GaugeColorsB"
         

                }
          
        

            },



            {

                boxCall:'txt',
                boxWidth:"55%",
                boxHeight:225,
                value:[

                    {
                        className:"Big",
                        txt:"45.5%",
                        style:{

                            //fontSize:"100px",
                            textAlign:"center"

                        }
                    },

                    {
                        className:"Note",
                        txt:"Expenses / Sales = Overhead Percentage",
                        style:{

                            //fontSize:"13px",
                            textAlign:"center"

                        }
                    }


                ]


            }



        ],
        "required"	    :"",
        "default"	    :""


    },

    //spacer
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"20px"
        },
        "required"	    :"",
        "default"	    :""


    },

















    // -----------------------------------
    // Gauge 4
    // -----------------------------------

    //bar
    {

        "print"		    :'bar',
        "name"		    :'Gauge 4',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },

    //spacer
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"20px"
        },
        "required"	    :"",
        "default"	    :""


    },

    // //chart
    {


        "print"		    :'gauge4',
        "name"		    :'Gauge4',
        "value"		    :{

            backgroundColor:"transparent",
            gaugeSize:400,
            margin:"0 0 0 0",

            gaugeBackgroundColor : false, //"#454d5f",
            gaugeBackgroundWidth : false, //85,

            gaugePercentageColor : "#c00",
            gaugePercentageWidth : false, //75,
            gaugePercent : 60,
  
            needleCenterColor : false, //"black",
            needleColor : false, //"black",
            needleSize : false, //"165",
            needleRotation  : -100,

            colors: "GaugeColorsB"

        },
        "required"	    :"",
        "default"	    :"",



    },

    //spacer
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"20px"
        },
        "required"	    :"",
        "default"	    :""


    },



    

    




    // -----------------------------------
    // Report Box - Gauge 4
    // -----------------------------------


    //bar
    {

        "print"		    :'bar',
        "name"		    :'Gauge 4',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },

    //spacer
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"20px"
        },
        "required"	    :"",
        "default"	    :""


    },


    //report_box
    {

        "print"		    :'report_box',
        "name"		    :'Report Box',
        "value"		    :[

            {

                boxCall:'gauge4',
                boxWidth:"45%",
                boxHeight:225,
                value:{


                    backgroundColor:"transparent",
                    gaugeSize:225,
                    margin:"0 0 0 0",

                    gaugeBackgroundColor : false, //"#454d5f",
                    gaugeBackgroundWidth : false, //85,
    
                    gaugePercentageColor : "#c00",
                    gaugePercentageWidth : false, //75,
                    gaugePercent : 60,
          
                    needleCenterColor : false, //"black",
                    needleColor : false, //"black",
                    needleSize : false, //"165",
                    needleRotation  : -100,

                    colors: "GaugeColorsB"
         

                }
          
        

            },



            {

                boxCall:'txt',
                boxWidth:"55%",
                boxHeight:225,
                value:[

                    {
                        className:"Big",
                        txt:"45.5%",
                        style:{

                            //fontSize:"100px",
                            textAlign:"center"

                        }
                    },

                    {
                        className:"Note",
                        txt:"Expenses / Sales = Overhead Percentage",
                        style:{

                            //fontSize:"13px",
                            textAlign:"center"

                        }
                    }


                ]


            }



        ],
        "required"	    :"",
        "default"	    :""


    },

    //spacer
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"20px"
        },
        "required"	    :"",
        "default"	    :""


    },











    // -----------------------------------
    // Gauge 3
    // -----------------------------------

    //bar
    {

        "print"		    :'bar',
        "name"		    :'Gauge 3',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },

    //spacer
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"20px"
        },
        "required"	    :"",
        "default"	    :""


    },

    // //chart
    {


        "print"		    :'gauge3',
        "name"		    :'Gauge3',
        "value"		    :{

            backgroundColor:"transparent",
            width:"400px",
            height:"400px",// match this to boxHeight
            margin:"0 0 0 0",
            ring:{

                size:"6",
                color:"#fff" //"#d2d3d4"

            },
            hole:{
                size:0.5,
                // color:"#191e28",
                // outlineColor:"#191e28",
                color:"#fff",
                outlineColor:"#fff",
                textColor:"#000",
                textSize:"27px"
            },
            items: [ 

                {
                    "name": "Rent / Mortgage ( 78.9% )",
                    "value": "$5,500.00",
                    "percent": "25"
                },
                {
                    "name": "Utilities ( 15.8% )",
                    "value": "$1,100.00",
                    "percent": "75"
                },
                // {
                //     "name": "Phone / Internet ( 5.0% )",
                //     "value": "$350.00",
                //     "percent": "37.5"
                // }

            ],
            colors: "GaugeColorsB"



        },
        "required"	    :"",
        "default"	    :"",



    },

    //spacer
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"20px"
        },
        "required"	    :"",
        "default"	    :""


    },



    




    // -----------------------------------
    // Report Box - Gauge 3
    // -----------------------------------


    //bar
    {

        "print"		    :'bar',
        "name"		    :'Gauge 3',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },

    //spacer
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"20px"
        },
        "required"	    :"",
        "default"	    :""


    },


    //report_box
    {

        "print"		    :'report_box',
        "name"		    :'Report Box',
        "value"		    :[

            {

                boxCall:'gauge3',
                boxWidth:"45%",
                boxHeight:225,
                value:{


                    backgroundColor:"transparent",
                    width:"250px",
                    height:"250px",// match this to boxHeight
                    margin:"0 0 0 0",
                    ring:{
    
                        size:"7",
                        color:"white" //"#d2d3d4"
    
                    },
                    hole:{
                        size:0.5,
                        // color:"#191e28",
                        // outlineColor:"#191e28",
                        color:"white",
                        outlineColor:"white",
                        textColor:"#000",
                        textSize:"27px"
                    },
                    items: [ 


                        {
                            "name": "Above 50 ( 37.5% )",
                            "value": "$1,100.00",
                            "percent": "25"
                        },
                        {
                            "name": "Blank Section (25%)",
                            "value": "25",
                            "percent": "75"
                        }


                    ],
                    colors: "GaugeColorsB"
    
    

                }
          
        

            },



            {

                boxCall:'txt',
                boxWidth:"55%",
                boxHeight:250,
                value:[

                    {
                        className:"Big",
                        txt:"45.5%",
                        style:{

                            //fontSize:"100px",
                            textAlign:"center"

                        }
                    },

                    {
                        className:"Note",
                        txt:"Expenses / Sales = Overhead Percentage",
                        style:{

                            //fontSize:"13px",
                            textAlign:"center"

                        }
                    }


                ]


            }



        ],
        "required"	    :"",
        "default"	    :""


    },

    //spacer
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"20px"
        },
        "required"	    :"",
        "default"	    :""


    },










    // -----------------------------------
    // Gauge 2
    // -----------------------------------


    //bar
    {

        "print"		    :'bar',
        "name"		    :'Gauge 2',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },

    //spacer
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"20px"
        },
        "required"	    :"",
        "default"	    :""


    },

    // //chart
    {


        "print"		    :'gauge2',
        "name"		    :'Gauge2',
        "value"		    :{

            backgroundColor:"transparent",
            width:"400px",
            height:"400px",// match this to boxHeight
            margin:"0 0 0 0",
            ring:{

                size:"8",
                color:"#454d5f" //"#d2d3d4"

            },
            hole:{
                size:0.5,
                // color:"#191e28",
                // outlineColor:"#191e28",
                color:"#fff",
                outlineColor:"#fff",
                textColor:"#000",
                textSize:"27px"
            },
            items: [ 

                {
                    "name": "Rent / Mortgage ( 78.9% )",
                    "value": "$5,500.00",
                    "percent": "37.5"
                },
                {
                    "name": "Utilities ( 15.8% )",
                    "value": "$1,100.00",
                    "percent": "25"
                },
                {
                    "name": "Phone / Internet ( 5.0% )",
                    "value": "$350.00",
                    "percent": "37.5"
                }

            ],
            colors: "GaugeColors"



        },
        "required"	    :"",
        "default"	    :"",



    },

    //spacer
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"20px"
        },
        "required"	    :"",
        "default"	    :""


    },










    // -----------------------------------
    // Report Box - Gauge 2
    // -----------------------------------


    //bar
    {

        "print"		    :'bar',
        "name"		    :'Gauge 2',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },

    //spacer
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"20px"
        },
        "required"	    :"",
        "default"	    :""


    },


    //report_box
    {

        "print"		    :'report_box',
        "name"		    :'Report Box',
        "value"		    :[

            {

                boxCall:'gauge2',
                boxWidth:"45%",
                boxHeight:225,
                value:{


                    backgroundColor:"transparent",
                    width:"250px",
                    height:"250px",// match this to boxHeight
                    margin:"0 0 0 0",
                    ring:{
    
                        size:"7",
                        color:"white" //"#d2d3d4"
    
                    },
                    hole:{
                        size:0.5,
                        // color:"#191e28",
                        // outlineColor:"#191e28",
                        color:"white",
                        outlineColor:"white",
                        textColor:"#000",
                        textSize:"27px"
                    },
                    items: [ 


                        {
                            "name": "Above 50 ( 37.5% )",
                            "value": "$1,100.00",
                            "percent": "37.55"
                        },
                        {
                            "name": "Blank Section (25%)",
                            "value": "25",
                            "percent": "25"
                        },
                        {
                            "name": "Below 50 ( 37.5% )",
                            "value": "$1,100.00",
                            "percent": "37.55"
                        }


    

                    ],
                    colors: "GaugeColors"
    
    

                }
          
        

            },



            {

                boxCall:'txt',
                boxWidth:"55%",
                boxHeight:250,
                value:[

                    {
                        className:"Big",
                        txt:"45.5%",
                        style:{

                            //fontSize:"100px",
                            textAlign:"center"

                        }
                    },

                    {
                        className:"Note",
                        txt:"Expenses / Sales = Overhead Percentage",
                        style:{

                            //fontSize:"13px",
                            textAlign:"center"

                        }
                    }


                ]


            }



        ],
        "required"	    :"",
        "default"	    :""


    },

    //spacer
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"20px"
        },
        "required"	    :"",
        "default"	    :""


    },










    // -----------------------------------
    // Gauge 1
    // -----------------------------------


    //bar
    {

        "print"		    :'bar',
        "name"		    :'Gauge 1',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },

    //spacer
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"20px"
        },
        "required"	    :"",
        "default"	    :""


    },

    // //chart
    {


        "print"		    :'gauge1',
        "name"		    :'Gauge1',
        "value"		    :{

            backgroundColor:"transparent",
            width:"100%",
            height:"400px",// match this to boxHeight
            margin:"0 0 0 0",
            gauge:{


                mask:{

                    height:"25%",
                    bottom:"0px"
                }

            },
            ring:{

                size:"5",
                //color:"#d2d3d4"
                color:"#454d5f"

            },
            hole:{
                size:0.5,
                // color:"#191e28",
                // outlineColor:"#191e28",
                color:"#fff",
                outlineColor:"#fff",
                textColor:"#000",
                textSize:"27px"
            },
            items: [ 

                {
                    "name": "Above 50 ( 37.5% )",
                    "value": "$1,100.00",
                    "percent": "37.59"
                },
                {
                    "name": "Blank Section (25%)",
                    "value": "25",
                    "percent": "25"
                },
                {
                    "name": "Below 50 ( 37.5% )",
                    "value": "$1,100.00",
                    "percent": "37.59"
                }


            ],
            colors: "GaugeColors"



        },
        "required"	    :"",
        "default"	    :"",



    },

    //spacer
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"20px"
        },
        "required"	    :"",
        "default"	    :""


    },










    // -----------------------------------
    // Report Box - Gauge 1 
    // -----------------------------------


    //bar
    {

        "print"		    :'bar',
        "name"		    :'Gauge 1',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },

    //spacer
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"20px"
        },
        "required"	    :"",
        "default"	    :""


    },


    //report_box
    {

        "print"		    :'report_box',
        "name"		    :'Report Box',
        "value"		    :[

            {

                boxCall:'gauge1',
                boxWidth:"45%",
                boxHeight:225,
                value:{


                    backgroundColor:"transparent",
                    width:"100%",
                    height:"225px",// match this to boxHeight
                    margin:"0 0 0 0",
                    gauge:{

                        mask:{

                            height:"15%",
                            bottom:"0px"
                        }

                    },
                    ring:{
    
                        size:"9",
                        color:"#454d5f" //"#d2d3d4"
    
                    },
                    hole:{
                        size:0.5,
                        // color:"#191e28",
                        // outlineColor:"#191e28",
                        color:"white",
                        outlineColor:"white",
                        textColor:"#000",
                        textSize:"27px"
                    },
                    items: [ 


                        {
                            "name": "Utilities ( 20% )",
                            "value": "$1,100.00",
                            "percent": "37.5"
                        },
                        {
                            "name": "Rent / Mortgage ( 80% )",
                            "value": "$5,500.00",
                            "percent": "25"
                        },
                        {
                            "name": "Utilities ( 20% )",
                            "value": "$1,100.00",
                            "percent": "37.5"
                        }

    

                    ],
                    colors: "GaugeColors"
    
    

                }
          
        

            },



            {

                boxCall:'txt',
                boxWidth:"55%",
                boxHeight:250,
                value:[

                    {
                        className:"Big",
                        txt:"45.5%",
                        style:{

                            //fontSize:"100px",
                            textAlign:"center"

                        }
                    },

                    {
                        className:"Note",
                        txt:"Expenses / Sales = Overhead Percentage",
                        style:{

                            //fontSize:"13px",
                            textAlign:"center"

                        }
                    }


                ]


            }



        ],
        "required"	    :"",
        "default"	    :""


    },

    //spacer
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"20px"
        },
        "required"	    :"",
        "default"	    :""


    },

























    // -----------------------------------
    // Svg Chart
    // -----------------------------------


    //bar
    {

        "print"		    :'bar',
        "name"		    :'SVGChart - Donut',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },

    //spacer
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"20px"
        },
        "required"	    :"",
        "default"	    :""


    },

    // //chart
    {


        "print"		    :'svg_donut_chart',
        "name"		    :'SVG Donut Chart',
        "value"		    :{

            backgroundColor:"transparent",
            width:"100%",
            height:"400px", // set a fixed height to control overflow
            ring:{

                size:"10",
                color:"#d2d3d4"

            },
            hole:{
                size:0.5,
                // color:"#191e28",
                // outlineColor:"#191e28",
                color:"#fff",
                outlineColor:"#fff",
                textColor:"#000",
                textSize:"27px"
            },
            items: [ 

                {
                    "name": "Rent / Mortgage ( 78.9% )",
                    "value": "$5,500.00",
                    "percent": "78.9"
                },
                {
                    "name": "Utilities ( 15.8% )",
                    "value": "$1,100.00",
                    "percent": "15.8"
                },
                {
                    "name": "Phone / Internet ( 5.0% )",
                    "value": "$350.00",
                    "percent": "5.0"
                },
                {
                    "name": "Misc ( 0.3% )",
                    "value": "$20.00",
                    "percent": "0.3"
                }


            ],
            colors: [
    


                "#07a2e3",
                "#20b2aa",
                "#caffca",

                "#fde23e",
                "#f16e23", 
                "#57d9ff",
                "#937e88",
                "#20b2aa",
                "#ccff00",

                "lightblue",
                "lightgreen",
                "yellow",
                "pink",
                "lavender"
    
            ]



        },
        "required"	    :"",
        "default"	    :"",



    },

    //spacer
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"20px"
        },
        "required"	    :"",
        "default"	    :""


    },









    // -----------------------------------
    // Report Box - SvgChart - donut
    // -----------------------------------


    //bar
    {

        "print"		    :'bar',
        "name"		    :'SVGChart - Donut',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },

    //spacer
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"20px"
        },
        "required"	    :"",
        "default"	    :""


    },


    //report_box
    {

        "print"		    :'report_box',
        "name"		    :'Report Box',
        "value"		    :[

            {

                boxCall:'svg_donut_chart',
                boxWidth:"45%",
                boxHeight:225,
                value:{


                    backgroundColor:"transparent",
                    width:"100%",
                    height:"225px", // match this to boxHeight
                    ring:{
    
                        size:"10",
                        color:"#d2d3d4"
    
                    },
                    hole:{
                        size:0.5,
                        // color:"#191e28",
                        // outlineColor:"#191e28",
                        color:"#edeef1",
                        outlineColor:"#edeef1",
                        textColor:"#000",
                        textSize:"27px"
                    },
                    items: [ 


                        {
                            "name": "Rent / Mortgage ( 80% )",
                            "value": "$5,500.00",
                            "percent": "80"
                        },
                        {
                            "name": "Utilities ( 20% )",
                            "value": "$1,100.00",
                            "percent": "20"
                        }
 
    

                    ],
                    colors: [
            

        
                        "royalblue",
                        "lightgreen",
                        "lightseagreen",
                        "#caffca",
        
                        "#07a2e3",
                        "#20b2aa",
        
                        "#fde23e",
                        "#f16e23", 
                        "#57d9ff",
                        "#937e88",
                        "#20b2aa",
                        "#ccff00",
        
                        "lightblue",
                        "lightgreen",
                        "yellow",
                        "pink",
                        "lavender"
            
                    ]
    
    

                }
          
        

            },



            {

                boxCall:'txt',
                boxWidth:"55%",
                boxHeight:250,
                value:[

                    {
                        className:"Big",
                        txt:"45.5%",
                        style:{

                            //fontSize:"100px",
                            textAlign:"center"

                        }
                    },

                    {
                        className:"Note",
                        txt:"Expenses / Sales = Overhead Percentage",
                        style:{

                            //fontSize:"13px",
                            textAlign:"center"

                        }
                    }


                ]


            }



        ],
        "required"	    :"",
        "default"	    :""


    },

    //spacer
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"20px"
        },
        "required"	    :"",
        "default"	    :""


    },























    // -----------------------------------
    // Canvas Chart
    // -----------------------------------

    {

        "print"		    :'bar',
        "name"		    :'Report',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"20px"
        },
        "required"	    :"",
        "default"	    :""


    },
    


    //chart
    {


        "print"		    :'canvas_chart',
        "name"		    :'The Donut Chart',
        "value"		    :[

            {

                backgroundColor:"transparent",
                border:"0",
                borderRadius:"0",
                width:"100%",
                margin:15+"px auto 0 auto",
                //maxHeight:( (225 - 0) - 5),
                drawCheckerBoard: false,
                chart:{
                    call:'doughnut',
                    data: {
            
                        items: [ 
            
                            {
                                name:"Classical Music",
                                value:20
                            },
                            {
                                name:"Hard Rock",
                                value:80
                            }
                        ],
                
                        colors: [
                
                            "#07a2e3",
                            "#20b2aa",
    
    
                            "#fde23e",
                            "#f16e23", 
                            "#57d9ff",
                            "#937e88",
                            "#20b2aa",
                            "#ccff00",
    
                            "lightblue",
                            "lightgreen",
                            "yellow",
                            "pink",
                            "lavender"
                
                        ],
                        hole:{
                            size:0.5,
                            // color:"#191e28",
                            // outlineColor:"#191e28",
                            color:"#fff",
                            outlineColor:"#fff",
                            textColor:"#000",
                            textSize:"27px"
                        }
    
    
                    }
    

                }

            }
    
        ],
        "required"	    :"",
        "default"	    :"",



    },
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"0px"
        },
        "required"	    :"",
        "default"	    :""


    },






    // -----------------------------------
    // Report Box - Canvas Chart
    // -----------------------------------

    {

        "print"		    :'bar',
        "name"		    :'Report',
        "value"		    :"",
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"20px"
        },
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'report_box',
        "name"		    :'Report Box',
        "value"		    :[

            {

                boxCall:'canvas_chart',
                boxWidth:"45%",
                boxHeight:225,
                value:{

                    backgroundColor:"transparent",
                    border:"0",
                    borderRadius:"0",
                    width:"100%",
                    margin:15+"px auto 0 auto",
                    //maxHeight:( (225 - 0) - 5),
                    drawCheckerBoard: false,
                    chart:{
                        call:'doughnut',
                        data: {
                
                            items: [ 
                
                                {
                                    name:"Classical Music",
                                    value:20
                                },
                                {
                                    name:"Hard Rock",
                                    value:80
                                }
                            ],
                    
                            colors: [
                    
                                "#07a2e3",
                                "#20b2aa",
        
        
                                "#fde23e",
                                "#f16e23", 
                                "#57d9ff",
                                "#937e88",
                                "#20b2aa",
                                "#ccff00",
        
                                "lightblue",
                                "lightgreen",
                                "yellow",
                                "pink",
                                "lavender"
                    
                            ],
                            hole:{
                                size:0.5,
                                // color:"#191e28",
                                // outlineColor:"#191e28",
                                color:"#fff",
                                outlineColor:"#fff",
                                textColor:"#000",
                                textSize:"27px"
                            }
        
        
                        }
        
    
                    }
        

                }
        

            },



            {

                boxCall:'txt',
                boxWidth:"55%",
                boxHeight:250,
                value:[

                    {
                        className:"Big",
                        txt:"45.5%",
                        style:{

                            //fontSize:"100px",
                            textAlign:"center"

                        }
                    },

                    {
                        className:"Note",
                        txt:"Expenses / Sales = Overhead Percentage",
                        style:{

                            //fontSize:"13px",
                            textAlign:"center"

                        }
                    }


                ]


            }



        ],
        "required"	    :"",
        "default"	    :""


    },
    {

        "print"		    :'spacer',
        "name"		    :'',
        "value"		    :{

            backgroundColor:"transparent",
            height:"0px"
        },
        "required"	    :"",
        "default"	    :""


    },










    // //-----------------------------------
    // // Report Box - Donut and Big Txt
    // //-----------------------------------

    // {

    //     "print"		    :'bar',
    //     "name"		    :'Donut and Big Txt',
    //     "value"		    :"",
    //     "required"	    :"",
    //     "default"	    :""


    // },
    // {

    //     "print"		    :'report_box',
    //     "name"		    :'Report Box',
    //     "value"		    :[

    //         {

    //             boxCall:'donutChart',
    //             boxWidth:"45%",
    //             boxHeight:250,
    //             value:{

    //                 donutType:                      "static",
    //                 donutSize:                      "200px",
    //                 donutHalfSize:                  "100px",
    //                 donutCenterSize:                "100px",
    //                 donutCenterTopLeft:             "50px",
    //                 donutCenterBackgroundColor:     "#edeef1", //"#edeef1", //"#394264",
    //                 donutCenterBorderRadius:        "100%",
    //                 donutSections:[

    //                     {
    //                         percent :"50",
    //                         color   :"royalblue"
    //                         // rotate      :"0",
    //                         // itemRotate  :"76",
            
    //                     },
        
    //                     {
    //                         percent :"50",
    //                         color   :"lightblue"
    //                         // rotate      :"215",
    //                         // itemRotate  :"113",
    //                     }
        
    
    //                 ]

    //             }
        


    //         },


    //         {

    //             boxCall:'txt',
    //             boxWidth:"55%",
    //             boxHeight:250,
    //             value:[

    //                 {
    //                     className:"Big",
    //                     txt:"45.5%",
    //                     style:{

    //                         //fontSize:"100px",
    //                         textAlign:"center"

    //                     }
    //                 },

    //                 {
    //                     className:"Note",
    //                     txt:"Expenses / Sales = Overhead Percentage",
    //                     style:{

    //                         //fontSize:"13px",
    //                         textAlign:"center"

    //                     }
    //                 }


    //             ]


    //         }



    //     ],
    //     "required"	    :"",
    //     "default"	    :""


    // },
    // {

    //     "print"		    :'spacer',
    //     "name"		    :'',
    //     "value"		    :{

    //         backgroundColor:"transparent",
    //         height:"0px"
    //     },
    //     "required"	    :"",
    //     "default"	    :""


    // },





    // //-----------------------------------
    // // Report Box - Donut and Ledger
    // //-----------------------------------

    // {

    //     "print"		    :'bar',
    //     "name"		    :'Donut and Ledger',
    //     "value"		    :"",
    //     "required"	    :"",
    //     "default"	    :""


    // },
    // {

    //     "print"		    :'report_box',
    //     "name"		    :'Report Box',
    //     "value"		    :[

    //         {

    //             boxCall:'donutChart',
    //             boxWidth:"45%",
    //             boxHeight:275,
    //             value:{

    //                 donutType:                      "static",
    //                 donutSize:                      "250px",
    //                 donutHalfSize:                  "125px",
    //                 donutCenterSize:                "150px",
    //                 donutCenterTopLeft:             "50px",
    //                 donutCenterBackgroundColor:     "#394264", //"#edeef1", //"#394264",
    //                 donutCenterBorderRadius:        "100%",
    //                 donutSections:[

    //                     {
    //                         percent :"25",
    //                         color   :"royalblue"
    //                         // rotate      :"0",
    //                         // itemRotate  :"76",
            
    //                     },
        
    //                     {
    //                         percent :"25",
    //                         color   :"lightblue"
    //                         // rotate      :"215",
    //                         // itemRotate  :"113",
    //                     },


    //                     {
    //                         percent :"25",
    //                         color   :"lightseagreen"
    //                         // rotate      :"0",
    //                         // itemRotate  :"76",
            
    //                     },
        
    //                     {
    //                         percent :"25",
    //                         color   :"lightgreen"
    //                         // rotate      :"215",
    //                         // itemRotate  :"113",
    //                     }
        
    
    //                 ]

    //             }
        


    //         },


    //         {
    //             boxCall:'ledger',
    //             boxWidth:"55%",
    //             boxHeight:275,
    //             value:[

    //                 {
    //                     name:"Daily",
    //                     value:"10.0 hrs"
    //                 },
    //                 {
    //                     name:"Weekly",
    //                     value:"50.0 hrs"
    //                 },
    //                 {
    //                     name:"Monthly",
    //                     value:"200.0 hrs"
    //                 },
    //                 {
    //                     name:"Yearly",
    //                     value:"2400.0 hrs"
    //                 }
    
    //             ]

    //         }



    //     ],
    //     "required"	    :"",
    //     "default"	    :""


    // },
    // {

    //     "print"		    :'spacer',
    //     "name"		    :'',
    //     "value"		    :{

    //         backgroundColor:"transparent",
    //         height:"0px"
    //     },
    //     "required"	    :"",
    //     "default"	    :""


    // },







    // //-----------------------------------
    // // Report Box - Graphic and Ledger
    // //-----------------------------------

    // {

    //     "print"		    :'bar',
    //     "name"		    :'Labor Hours',
    //     "value"		    :"",
    //     "required"	    :"",
    //     "default"	    :""


    // },
    // {

    //     "print"		    :'report_box',
    //     "name"		    :'Report Box',
    //     "value"		    :[

    //         {

    //             boxCall:'graphic',
    //             boxWidth:"40%",
    //             boxHeight:230,

    //             svgCall:"clock",
    //             svgWidth:230,
    //             svgHeight:230,

    //         },

    //         {
    //             boxCall:'ledger',
    //             boxWidth:"60%",
    //             boxHeight:230,
    //             value:[

    //                 {
    //                     name:"Daily",
    //                     value:"10.0 hrs"
    //                 },
    //                 {
    //                     name:"Weekly",
    //                     value:"50.0 hrs"
    //                 },
    //                 {
    //                     name:"Monthly",
    //                     value:"200.0 hrs"
    //                 },
    //                 {
    //                     name:"Yearly",
    //                     value:"2400.0 hrs"
    //                 }
    
    //             ]

    //         }



    //     ],
    //     "required"	    :"",
    //     "default"	    :""


    // },
    // {

    //     "print"		    :'spacer',
    //     "name"		    :'',
    //     "value"		    :{

    //         backgroundColor:"transparent",
    //         height:"30px"
    //     },
    //     "required"	    :"",
    //     "default"	    :""


    // },









    // //-----------------------------------
    // // Static DonutChart
    // //-----------------------------------

    // // bar
    // {

    //     "print"		    :'bar',
    //     "name"		    :'Static Donut Chart',
    //     "value"		    :"",
    //     "required"	    :"",
    //     "default"	    :"",

    // },


    // //spacer
    // {

    //     "print"		    :'spacer',
    //     "name"		    :'',
    //     "value"		    :{

    //         backgroundColor:"transparent",
    //         height:"30px"
    //     },
    //     "required"	    :"",
    //     "default"	    :""

    // },



    // //chart
    // {


    //     "print"		    :'donutChart',
    //     "name"		    :'The Donut Chart',
    //     "value"		    :[

    //         {
    //             donutType:                      "static",
    //             donutSize:                      "300px",
    //             donutHalfSize:                  "150px",
    //             donutCenterSize:                "150px",
    //             donutCenterTopLeft:             "75px",
    //             donutCenterBackgroundColor:     "#edeef1", //"#edeef1", //"#394264",
    //             donutCenterBorderRadius:        "100%",
    //             donutSections:[

    //                 {
    //                     percent :"15",
    //                     color   :"royalblue"
    //                     // rotate      :"0",
    //                     // itemRotate  :"76",
        
    //                 },
    
    //                 {
    //                     percent :"35",
    //                     color   :"lightseagreen" 
    //                     // rotate      :"76",
    //                     // itemRotate  :"140",
                            
    //                 },
    
    //                 {
    //                     percent :"10",
    //                     color   :"lightgreen"
    //                     // rotate      :"215",
    //                     // itemRotate  :"113",
    //                 },
    
    //                 {
    //                     percent :"40",
    //                     color   :"lightblue"              
    //                     // rotate      :"-32",
    //                     // itemRotate  :"32",
    //                 }
    
    //             ]

    //         }
    //     ],
    //     "required"	    :"",
    //     "default"	    :"",



    // },


    // //spacer
    // {

    //     "print"		    :'spacer',
    //     "name"		    :'',
    //     "value"		    :{

    //         backgroundColor:"transparent",
    //         height:"50px"
    //     },
    //     "required"	    :"",
    //     "default"	    :""

    // },







    // //-----------------------------------
    // // Static DonutChart
    // //-----------------------------------

    // // bar
    // {

    //     "print"		    :'bar',
    //     "name"		    :'Static Donut Chart',
    //     "value"		    :"",
    //     "required"	    :"",
    //     "default"	    :"",

    // },


    // //spacer
    // {

    //     "print"		    :'spacer',
    //     "name"		    :'',
    //     "value"		    :{

    //         backgroundColor:"transparent",
    //         height:"30px"
    //     },
    //     "required"	    :"",
    //     "default"	    :""

    // },



    // //chart
    // {


    //     "print"		    :'donutChart',
    //     "name"		    :'The Donut Chart',
    //     "value"		    :[

    //         {
    //             donutType:                      "static",
    //             donutSize:                      "250px",
    //             donutHalfSize:                  "125px",
    //             donutCenterSize:                "125px",
    //             donutCenterTopLeft:             "62.5px",
    //             donutCenterBackgroundColor:     "#394264", //"#edeef1", //"#394264",
    //             donutCenterBorderRadius:        "100%",
    //             donutSections:[

    //                 {
    //                     percent :"25",
    //                     color   :"royalblue"
    //                     // rotate      :"0",
    //                     // itemRotate  :"76",
        
    //                 },
    
    //                 {
    //                     percent :"25",
    //                     color   :"lightseagreen" 
    //                     // rotate      :"76",
    //                     // itemRotate  :"140",
                            
    //                 },
    
    //                 {
    //                     percent :"25",
    //                     color   :"lightgreen"
    //                     // rotate      :"215",
    //                     // itemRotate  :"113",
    //                 },
    
    //                 {
    //                     percent :"25",
    //                     color   :"lightblue"              
    //                     // rotate      :"-32",
    //                     // itemRotate  :"32",
    //                 }
    //             ]

    //         }
    //     ],
    //     "required"	    :"",
    //     "default"	    :"",



    // },


    // //spacer
    // {

    //     "print"		    :'spacer',
    //     "name"		    :'',
    //     "value"		    :{

    //         backgroundColor:"transparent",
    //         height:"50px"
    //     },
    //     "required"	    :"",
    //     "default"	    :""

    // },











    // //-----------------------------------
    // // BarChart - Single - Vertical
    // //-----------------------------------

    // // bar
    // {

    //     "print"		    :'bar',
    //     "name"		    :'BarChart - Single - Vertical',
    //     "value"		    :"",
    //     "required"	    :"",
    //     "default"	    :"",

    // },



    // //spacer
    // {

    //     "print"		    :'spacer',
    //     "name"		    :'',
    //     "value"		    :{

    //         backgroundColor:"transparent",
    //         height:"30px"
    //     },
    //     "required"	    :"",
    //     "default"	    :""

    // },




    // //chart
    // {


    //     "print"		    :'singleBarChart',
    //     "name"		    :'The Chart',
    //     "value"		    :[

    //         {
    //             chartType:"vertical",
    //             chartLines:true,
    //             data:[

    //                 {
    //                     name:'Item 1',
    //                     value:"2500.00",
    //                     percent:55,
    //                 },

    //                 {
    //                     name:'Item 2',
    //                     value:"2500.00",
    //                     percent:20,
    //                 },

    //                 {
    //                     name:'Item 3',
    //                     value:"2500.00",
    //                     percent:30,
    //                 },

    //                 {
    //                     name:'Item 4',
    //                     value:"2500.00",
    //                     percent:45,
    //                 },

    //                 {
    //                     name:'Item 5',
    //                     value:"2500.00",
    //                     percent:18,
    //                 },

    //                 {
    //                     name:'Item 6',
    //                     value:"2500.00",
    //                     percent:30,
    //                 },

    //                 {
    //                     name:'Item 7',
    //                     value:"2500.00",
    //                     percent:10,
    //                 }

    //             ]


    //         }
    //     ],
    //     "required"	    :"",
    //     "default"	    :"",



    // },


    // //spacer
    // {

    //     "print"		    :'spacer',
    //     "name"		    :'',
    //     "value"		    :{

    //         backgroundColor:"transparent",
    //         height:"50px"
    //     },
    //     "required"	    :"",
    //     "default"	    :""

    // },









    // //-----------------------------------
    // // BarChart - Single - Horizontal
    // //-----------------------------------

    // // bar
    // {

    //     "print"		    :'bar',
    //     "name"		    :'BarChart - Single - Horizontal',
    //     "value"		    :"",
    //     "required"	    :"",
    //     "default"	    :"",

    // },



    // //spacer
    // {

    //     "print"		    :'spacer',
    //     "name"		    :'',
    //     "value"		    :{

    //         backgroundColor:"transparent",
    //         height:"30px"
    //     },
    //     "required"	    :"",
    //     "default"	    :""

    // },



    // //chart
    // {


    //     "print"		    :'singleBarChart',
    //     "name"		    :'The Chart',
    //     "value"		    :[

    //         {
    //             chartType:"horizontal",
    //             chartLines:true,
    //             data:[

    //                 {
    //                     name:'Item 1',
    //                     value:"2500.00",
    //                     percent:80,
    //                 },

    //                 {
    //                     name:'Item 2',
    //                     value:"2500.00",
    //                     percent:20,
    //                 },

    //                 // {
    //                 //     name:'Item 3',
    //                 //     value:"2500.00",
    //                 //     percent:30,
    //                 // }

    //             ]

    //         }
    //     ],
    //     "required"	    :"",
    //     "default"	    :"",



    // },


    // //spacer
    // {

    //     "print"		    :'spacer',
    //     "name"		    :'',
    //     "value"		    :{

    //         backgroundColor:"transparent",
    //         height:"50px"
    //     },
    //     "required"	    :"",
    //     "default"	    :""

    // },







    // //-----------------------------------
    // // BarChart - single - Horizontal
    // //-----------------------------------

    // // bar
    // {

    //     "print"		    :'bar',
    //     "name"		    :'BarChart - Single - Horizontal',
    //     "value"		    :"",
    //     "required"	    :"",
    //     "default"	    :"",

    // },


    // //spacer
    // {

    //     "print"		    :'spacer',
    //     "name"		    :'',
    //     "value"		    :{

    //         backgroundColor:"transparent",
    //         height:"30px"
    //     },
    //     "required"	    :"",
    //     "default"	    :""

    // },



    // //chart
    // {


    //     "print"		    :'singleBarChart',
    //     "name"		    :'The Chart',
    //     "value"		    :[

    //         {
    //             chartType:"horizontal",
    //             chartLines:true,
    //             data:[

    //                 {
    //                     name:'Facility',
    //                     value:"2500.00",
    //                     percent:55,
    //                 },

    //                 {
    //                     name:'Business',
    //                     value:"2500.00",
    //                     percent:20,
    //                 },

    //                 {
    //                     name:'Computer Software',
    //                     value:"2500.00",
    //                     percent:30,
    //                 },

    //                 {
    //                     name:'Something Else Long Title',
    //                     value:"2500.00",
    //                     percent:10,
    //                 },

    //                 {
    //                     name:'Short Title',
    //                     value:"2500.00",
    //                     percent:5,
    //                 }


    //             ]

    //         }
    //     ],
    //     "required"	    :"",
    //     "default"	    :"",



    // },


    // //spacer
    // {

    //     "print"		    :'spacer',
    //     "name"		    :'',
    //     "value"		    :{

    //         backgroundColor:"transparent",
    //         height:"50px"
    //     },
    //     "required"	    :"",
    //     "default"	    :""

    // },








    // //-----------------------------------
    // // Ledger
    // //-----------------------------------

    // {

    //     "print"		    :'bar',
    //     "name"		    :'Ledger',
    //     "value"		    :"",
    //     "required"	    :"",
    //     "default"	    :""


    // },
    // {

    //     "print"		    :'ledger',
    //     "name"		    :"Ledger",
    //     "value"		    :[


    //         {
    //             name:"One",
    //             value:"$100.00"
    //         },
    //         {
    //             name:"Two",
    //             value:"$200.00"
    //         },
    //         {
    //             name:"Three",
    //             value:"$300.00"
    //         },
    //         {
    //             name:"Four",
    //             value:"$400.00"

    //         }


    //     ],
    //     "required"	    :"",
    //     "default"	    :""


    // },

    // {

    //     "print"		    :'spacer',
    //     "name"		    :'',
    //     "value"		    :{

    //         backgroundColor:"transparent",
    //         height:"50px"
    //     },
    //     "required"	    :"",
    //     "default"	    :""


    // },




    // //-----------------------------------
    // // Graphic and Ledger
    // //-----------------------------------

    // {

    //     "print"		    :'bar',
    //     "name"		    :'Graphic and Ledger',
    //     "value"		    :"",
    //     "required"	    :"",
    //     "default"	    :""


    // },
    // {

    //     "print"		    :'graphic',
    //     "name"		    :'Graphic',
    //     "value"		    :[

    //         {
    //             svgCall:"billable",
    //             svgWidth:200,
    //             svgHeight:200,

    //             boxWidth:"50%",
    //             boxHeight:220,
    //         },

    //         {
    //             svgCall:"calendar",
    //             svgWidth:200,
    //             svgHeight:200,

    //             boxWidth:"50%",
    //             boxHeight:220,

    //         }


    //     ],
    //     "required"	    :"",
    //     "default"	    :""


    // },
    // {

    //     "print"		    :'ledger',
    //     "name"		    :"Ledger",
    //     "value"		    :[


    //         {
    //             name:"One",
    //             value:"$100.00"
    //         },
    //         {
    //             name:"Two",
    //             value:"$200.00"
    //         },
    //         {
    //             name:"Three",
    //             value:"$300.00"
    //         },
    //         {
    //             name:"Four",
    //             value:"$400.00"

    //         }


    //     ],
    //     "required"	    :"",
    //     "default"	    :""


    // },

    // {

    //     "print"		    :'spacer',
    //     "name"		    :'',
    //     "value"		    :{

    //         backgroundColor:"transparent",
    //         height:"50px"
    //     },
    //     "required"	    :"",
    //     "default"	    :""


    // },







    // //-----------------------------------
    // // Graphic and BarChart
    // //-----------------------------------

    // {

    //     "print"		    :'bar',
    //     "name"		    :'Graphic and BarChart',
    //     "value"		    :"",
    //     "required"	    :"",
    //     "default"	    :""


    // },
    // {

    //     "print"		    :'graphic',
    //     "name"		    :'Graphic',
    //     "value"		    :[

    //         {
    //             svgCall:"billable",
    //             svgWidth:200,
    //             svgHeight:200,

    //             boxWidth:"50%",
    //             boxHeight:220,

    //         }

    //     ],
    //     "required"	    :"",
    //     "default"	    :""


    // },
    // {

    //     "print"		    :'spacer',
    //     "name"		    :'',
    //     "value"		    :{

    //         backgroundColor:"transparent",
    //         height:"50px"
    //     },
    //     "required"	    :"",
    //     "default"	    :""


    // },







    // //-----------------------------------
    // // Dual Graphic
    // //-----------------------------------

    // {

    //     "print"		    :'bar',
    //     "name"		    :'Dual Graphic',
    //     "value"		    :"",
    //     "required"	    :"",
    //     "default"	    :""


    // },
    // {

    //     "print"		    :'graphic',
    //     "name"		    :'Graphic',
    //     "value"		    :[

    //         {
    //             svgCall:"billable",
    //             svgWidth:200,
    //             svgHeight:200,

    //             boxWidth:"50%",
    //             boxHeight:220,

    //         },

    //         {
    //             svgCall:"healthcare",
    //             svgWidth:200,
    //             svgHeight:200,

    //             boxWidth:"50%",
    //             boxHeight:220,

    //         }

    //     ],
    //     "required"	    :"",
    //     "default"	    :""


    // },
    // {

    //     "print"		    :'spacer',
    //     "name"		    :'',
    //     "value"		    :{

    //         backgroundColor:"transparent",
    //         height:"50px"
    //     },
    //     "required"	    :"",
    //     "default"	    :""


    // },





    // //-----------------------------------
    // // Triple Graphic
    // //-----------------------------------

    // {

    //     "print"		    :'bar',
    //     "name"		    :'Triple Graphic',
    //     "value"		    :"",
    //     "required"	    :"",
    //     "default"	    :""


    // },
    // {

    //     "print"		    :'graphic',
    //     "name"		    :'Graphic',
    //     "value"		    :[

    //         {
    //             svgCall:"billable",
    //             svgWidth:200,
    //             svgHeight:200,

    //             boxWidth:"33%",
    //             boxHeight:220,

    //         },


    //         {
    //             svgCall:"calendar",
    //             svgWidth:200,
    //             svgHeight:200,

    //             boxWidth:"33%",
    //             boxHeight:220,

    //         },

    //         {
    //             svgCall:"healthcare",
    //             svgWidth:200,
    //             svgHeight:200,

    //             boxWidth:"33%",
    //             boxHeight:220,

    //         }

    //     ],
    //     "required"	    :"",
    //     "default"	    :""


    // },
    // {

    //     "print"		    :'spacer',
    //     "name"		    :'',
    //     "value"		    :{

    //         backgroundColor:"transparent",
    //         height:"50px"
    //     },
    //     "required"	    :"",
    //     "default"	    :""


    // },






    // //-----------------------------------
    // // Quadruple Graphic
    // //-----------------------------------

    // {

    //     "print"		    :'bar',
    //     "name"		    :'Quadruple Graphic',
    //     "value"		    :"",
    //     "required"	    :"",
    //     "default"	    :""


    // },
    // {

    //     "print"		    :'graphic',
    //     "name"		    :'Graphic',
    //     "value"		    :[

    //         {
    //             svgCall:"billable",
    //             svgWidth:100,
    //             svgHeight:100,

    //             boxWidth:"25%",
    //             boxHeight:120,

    //         },


    //         {
    //             svgCall:"calendar",
    //             svgWidth:100,
    //             svgHeight:100,

    //             boxWidth:"25%",
    //             boxHeight:120,

    //         },

    //         {
    //             svgCall:"healthcare",
    //             svgWidth:100,
    //             svgHeight:100,

    //             boxWidth:"25%",
    //             boxHeight:120,

    //         },


    //         {
    //             svgCall:"ira",
    //             svgWidth:100,
    //             svgHeight:100,

    //             boxWidth:"25%",
    //             boxHeight:120,

    //         }


    //     ],
    //     "required"	    :"",
    //     "default"	    :""


    // },
    // {

    //     "print"		    :'spacer',
    //     "name"		    :'',
    //     "value"		    :{

    //         backgroundColor:"transparent",
    //         height:"50px"
    //     },
    //     "required"	    :"",
    //     "default"	    :""


    // },

















    // //-----------------------------------
    // // BarChart - Double - Horizontal
    // //-----------------------------------

    // // bar
    // {

    //     "print"		    :'bar',
    //     "name"		    :'BarChart - Double - Horizontal',
    //     "value"		    :"",
    //     "required"	    :"",
    //     "default"	    :"",

    // },


    // //spacer
    // {

    //     "print"		    :'spacer',
    //     "name"		    :'',
    //     "value"		    :{

    //         backgroundColor:"transparent",
    //         height:"30px"
    //     },
    //     "required"	    :"",
    //     "default"	    :""

    // },


    // //barChart
    // {


    //     "print"		    :'doubleBarChart',
    //     "name"		    :'',
    //     "value"		    :[

    //         {
    //             chartType:"horizontal",
    //             chartLines:true,
    //             data:[

    //                 {
    //                     name:'Item 1',
    //                     value:"2500.00",
    //                     percent:55,
    //                 },

    //                 {
    //                     name:'Item 2',
    //                     value:"2500.00",
    //                     percent:20,
    //                 },

    //                 {
    //                     name:'Item 3',
    //                     value:"2500.00",
    //                     percent:30,
    //                 }

    //             ]

    //         },

    //         {
    //             chartType:"horizontal",
    //             chartLines:true,
    //             data:[

    //                 {
    //                     name:'Item 1',
    //                     value:"2500.00",
    //                     percent:55,
    //                 },

    //                 {
    //                     name:'Item 2',
    //                     value:"2500.00",
    //                     percent:20,
    //                 },

    //                 {
    //                     name:'Item 3',
    //                     value:"2500.00",
    //                     percent:30,
    //                 },

    //                 {
    //                     name:'Item 4',
    //                     value:"2500.00",
    //                     percent:10,
    //                 },

    //                 {
    //                     name:'Item 5',
    //                     value:"2500.00",
    //                     percent:5,
    //                 }


    //             ]

    //         }

    //     ],
    //     "required"	    :"",
    //     "default"	    :"",



    // },


    // //spacer
    // {

    //     "print"		    :'spacer',
    //     "name"		    :'',
    //     "value"		    :{

    //         backgroundColor:"transparent",
    //         height:"50px"
    //     },
    //     "required"	    :"",
    //     "default"	    :""

    // },










    // //-----------------------------------
    // // BarChart - Double - Vertical
    // //-----------------------------------

    // // bar
    // {

    //     "print"		    :'bar',
    //     "name"		    :'BarChart - Double - Vertical',
    //     "value"		    :"",
    //     "required"	    :"",
    //     "default"	    :"",

    // },


    // //spacer
    // {

    //     "print"		    :'spacer',
    //     "name"		    :'',
    //     "value"		    :{

    //         backgroundColor:"transparent",
    //         height:"30px"
    //     },
    //     "required"	    :"",
    //     "default"	    :""

    // },


    // //barChart
    // {


    //     "print"		    :'doubleBarChart',
    //     "name"		    :'',
    //     "value"		    :[

    //         {
    //             chartType:"vertical",
    //             chartLines:true,
    //             data:[

    //                 {
    //                     name:'Item 1',
    //                     value:"2500.00",
    //                     percent:55,
    //                 },

    //                 {
    //                     name:'Item 2',
    //                     value:"2500.00",
    //                     percent:20,
    //                 },

    //                 {
    //                     name:'Item 3',
    //                     value:"2500.00",
    //                     percent:30,
    //                 },

    //                 {
    //                     name:'Item 4',
    //                     value:"2500.00",
    //                     percent:45,
    //                 },

    //                 {
    //                     name:'Item 5',
    //                     value:"2500.00",
    //                     percent:18,
    //                 },

    //                 {
    //                     name:'Item 6',
    //                     value:"2500.00",
    //                     percent:30,
    //                 },

    //                 {
    //                     name:'Item 7',
    //                     value:"2500.00",
    //                     percent:10,
    //                 }

    //             ]




    //         },

    //         {
    //             chartType:"vertical",
    //             chartLines:true,
    //             data:[

    //                 {
    //                     name:'Item 1',
    //                     value:"2500.00",
    //                     percent:55,
    //                 },

    //                 {
    //                     name:'Item 2',
    //                     value:"2500.00",
    //                     percent:20,
    //                 },

    //                 {
    //                     name:'Item 3',
    //                     value:"2500.00",
    //                     percent:30,
    //                 },

    //                 {
    //                     name:'Item 4',
    //                     value:"2500.00",
    //                     percent:45,
    //                 },

    //                 {
    //                     name:'Item 5',
    //                     value:"2500.00",
    //                     percent:18,
    //                 }


    //             ]

    //         }
    //     ],
    //     "required"	    :"",
    //     "default"	    :"",



    // },


    // //spacer
    // {

    //     "print"		    :'spacer',
    //     "name"		    :'',
    //     "value"		    :{

    //         backgroundColor:"transparent",
    //         height:"50px"
    //     },
    //     "required"	    :"",
    //     "default"	    :""

    // },










];













