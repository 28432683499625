import React, { 

    useState, 
    useEffect,
    useRef

} from 'react';



// import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider';
// import useScreenOrientation from 'utils/hooks/ScreenOrientation';
import useDeviceDetect from 'utils/hooks/DeviceDetect';



//import 'app-style/price-form/style.css';
//import '../../StyleSheets/PriceForm.css';
import 'app-components/Zone/_StyleSheets/PriceForm.css';

import SVGTrash from '../../Svgs/trash.svg';
import SVGTrashOver from '../../Svgs/trash_over.svg';


import {
    LibString
} from 'library';


export const FFcostfield = (props) => {


    // console.log("##################");
    // console.log("COSTFIELD")
    // console.log("##################");
    // console.log(JSON.stringify(props.data,null,2));




    // -- Device Detect -- //
    const DeviceDetection = useDeviceDetect();
    //console.log("FFcostfield - DeviceDetection " + JSON.stringify(DeviceDetection));
    let isMobile = DeviceDetection.isMobile;



    const refLabelInput = useRef(null);


    //=========================================
    //state / effect
    //=========================================

    const [labelVal, setLabelVal] = useState('');   
    const [labelValShort, setLabelValShort] = useState('');
    const [labelValLong, setLabelValLong] = useState('');
    
    const [rateVal, setRateVal] = useState(0);
    const [qtyhrsVal, setQtyhrsVal] = useState(0);
    const [fieldStyl, setFieldStyl] = useState({});

    const [isLabor, setIsLabor] = useState(false);


    useEffect(() => {

        var fontStyle = 'normal';
        var fontColor = "#000";

        let data = props.data;


        //console.log("data; "+JSON.stringify(data,null,2));

        for(var keyname in data)
        {
            switch(keyname)
            {
            case'label':

                var label = data[keyname].text;
                setLabelVal(label);
                var shortLabel = LibString.InputMaxlength({
                    str       :label,
                    maxlength :10
                });
                if(label.length > 10) shortLabel = shortLabel+"...";
                setLabelValShort(shortLabel);



                setLabelValLong(label);
            break;
            case'rate':

                var rate = parseFloat(data[keyname].text).toFixed(2);
                setRateVal(rate);


                //alert(data[keyname].isLabor);
                var isLabor = data[keyname].isLabor;
                if(isLabor)
                {
                    //console.log("data; "+JSON.stringify(data,null,2));
                    setIsLabor(isLabor);  

                }
            break;
            case'qtyhrs':

                var qtyhrs = parseFloat(data[keyname].text).toFixed(1);
                setQtyhrsVal(qtyhrs);
            break;
            default:
            break;
            }//==

        }

        setFieldStyl({
            "bgColor":"transparent",
            "fontStyle":fontStyle,
            "fontColor":fontColor
        });


        var problemArr = props.problemArr;
        for(var i=0; i < problemArr.length; i++)
        {
            if(props.ij === problemArr[i]['key'])
            {
                setFieldStyl({
                    "bgColor":"lightyellow",
                    "fontStyle":fontStyle,
                    "fontColor":fontColor
                });
                break;
            }
    
        }//== i
 
  
 
     },[props])
 
 

    //<input name="firstName" onChange={e => setFirstName(e.target.value)} />
     




    //=========================================
    //handle
    //=========================================

    function handleOverOut(hndl) {

        //console.log(hndl.index);

        var img;
        img = document.getElementById("svg-"+hndl.index);

        switch(hndl.call)
        {
        case'over':

            switch(hndl.svg)
            {
            case'trash':

                img.src = SVGTrashOver;
            break;
            default:
            }


        break;
        default:

            switch(hndl.svg)
            {
            case'trash':

                img.src = SVGTrash;
            break;
            default:
            }

        }

 
    }


    function handleFocus(target) {

        target.select();

        if(target.id.match(/rate/gi))
        {
            if(props.data['rate'].isLabor)
            {
                props.handle({

                    call:"showLaborMenu",
                    data:{
        
                        index:props.ij,
                        fieldCall:'labor',
                        from:"input"
                    }
        
                });

            }//==

        }//==



    }

    function handleBlur(e) {

        var targetVal;
        var newVal;

        targetVal = e.target.value;
        if(targetVal === '' 
        || targetVal === undefined
        || isNaN(targetVal)
        )
        {
            targetVal = 0; 
        }

        if(e.target.id.match(/rate/gi)) 
        {
            targetVal = LibString.InputMaxlength({
                str       :targetVal,
                maxlength :7
            });
            newVal = parseFloat(targetVal).toFixed(2);
            setRateVal(newVal);



            if(props.data['rate'].isLabor)
            {
                props.handle({

                    call:"showLaborMenu",
                    data:{
        
                        index:props.ij,
                        fieldCall:'labor',
                        from:"input"
                    }
        
                })

            }




        }
        if(e.target.id.match(/qtyhrs/gi)) 
        {
        
            targetVal = LibString.InputMaxlength({
                str       :targetVal,
                maxlength :4
            });
            newVal = parseFloat(targetVal).toFixed(1);
            setQtyhrsVal(newVal);
        }

        
    }
    
    function handleChange(e) {

        if(e.target.id.match(/rate/gi)) setRateVal(e.target.value);
        if(e.target.id.match(/qtyhrs/gi)) setQtyhrsVal(e.target.value);
        
        var targetVal;
        var newVal;

  
        targetVal = e.target.value;
        // console.log("targetVal..."+targetVal);
        // console.log("rateVal..."+rateVal);
        // console.log("qtyhrsVal..."+qtyhrsVal);


        if(targetVal === '' 
        || targetVal === undefined
        || isNaN(targetVal)
        )
        {
            targetVal = 0; 
        }


        if(e.target.id.match(/rate/gi)) 
        {
            targetVal = LibString.InputMaxlength({
                str       :targetVal,
                maxlength :7
            });
            setRateVal(targetVal); 
            newVal = parseFloat(targetVal).toFixed(2);
  
        }
        if(e.target.id.match(/qtyhrs/gi)) 
        {
            targetVal = LibString.InputMaxlength({
                str       :targetVal,
                maxlength :4 
            });
            setQtyhrsVal(targetVal);
            newVal = parseFloat(targetVal).toFixed(1);
        }


        //console.log("newVal..."+newVal);


        props.handle({

            call        :'capture',
            fieldCall   :"costfield",
            key         :props.ij,
            id          :e.target.id,
            data        :props.data,
            value       :newVal
        
        })
    }


    // var tabIndex = props.ij.split("-")[1];
    // tabIndex = parseInt(tabIndex) + 1;

    // var firstTab = tabIndex;
    // var secondTab = parseInt(tabIndex) + 1;

    //console.log("FIRST TAB: "+firstTab+"\nSECOND TAB: "+secondTab);





    //=========================================
    //state / effect
    //=========================================

    const [labelInputView, setLabelInputView] = useState(false);
    const [labelInputSelect, setLabelInputSelect] = useState(false);

    useEffect(() => {
 
        if(labelInputView && !labelInputSelect)
        {
            setLabelInputSelect(true);

            if(isLabor)
            {
                refLabelInput.current.focus();
            }else{
                if(!isMobile) refLabelInput.current.select();          
            }

        }

        setLabelVal(props.data.label.text);

     },[

        isMobile,
        props,
        labelInputView,
        labelInputSelect,
        refLabelInput,
        isLabor

    ])
 







    //=========================================
    //showLaborMenu
    //=========================================

    const showLaborMenu = () => {

        props.handle({

            call:"showLaborMenu",
            data:{

                index:props.ij,
                fieldCall:'labor',
                from:"input"
            }

        })

    }







    //=========================================
    //showLabelInput
    //=========================================

    function showLabelInput(hndl) {

        //console.log(JSON.stringify(hndl,null,2));
        setLabelInputView(true);

    }

    function hideLabelInput() {

        //console.log(JSON.stringify(hndl,null,2));
        setLabelInputSelect(false);   
        setLabelInputView(false);
        setLabelVal(labelValShort);
    }




    function handleChangeLabel(e) {

        //console.log("ChangeLabel");

        if(e.target.id.match(/labelInput/gi)) setLabelVal(e.target.value);
     
        var shortVal = '';
        var longVal = '';
        var targetVal;
        targetVal = e.target.value;
        if(targetVal === undefined
        || targetVal === '' 
        // || isNaN(targetVal)
        )
        {
            targetVal = ''; 
        }


        if(e.target.id.match(/labelInput/gi)) 
        {
            longVal = targetVal;
            shortVal = LibString.InputMaxlength({
                str       :targetVal,
                maxlength :10
            });
            if(longVal.length > 10) shortVal = shortVal+"...";
            //console.log(shortVal);
            setLabelValShort(shortVal+".."); 
            setLabelValLong(longVal);
            setLabelVal(longVal);
        }

    }


    function handleFocusLabel(target) {

        target.select();
    }

    function handleBlurLabel(e) {

        var targetVal;
        targetVal = e.target.value;
        if(targetVal === undefined
        || targetVal === '' 
        // || isNaN(targetVal)
        )
        {
            targetVal = 'Good'; 
        }

        // console.log(targetVal);
        // console.log(labelValShort);
        // console.log(labelValLong);
        // console.log(labelVal);


        //alert(e.target.value)
        //setLabelReadView(false);
        setLabelInputSelect(false);   
        setLabelInputView(false);
        setLabelVal(labelValShort);



        if(!isLabor)
        {

            props.handle({

                call        :'edit',
                fieldCall   :"costfield",
                key         :props.ij,
                id          :e.target.id,
                data        :props.data,
                value       :labelValLong
            
            });

        }


 
    }



    function handleKeyPress(e){

        //alert(e.key);
        if(e.key.toUpperCase() === 'ENTER')
        {
            handleBlurLabel(e);
        }

    }








    return (


        <div className="frm">

            {
            isMobile
            ?



                //------------------------------------
                //COST FIELD
                //------------------------------------

                <div 
                key={props.ij}
                className="costfield"
                >


                    {
                    labelInputView
                    ?

                        <div>


                            {
                            isLabor
                            ?

                                <div 
                                className={"labelView"}
                                >

                                    <div 
                                    className="label-x"
                                    onClick={(v) => hideLabelInput()}
                                    >
                                        X

                                    </div>

                                    <div
                                    className="label-wrap"
                                    >

                                        <input
                                            ref={refLabelInput}
                                            type="text"
                                            inputMode="none"
                                            
                                            className="input-tag"
                                            style={{
                                                //backgroundColor:fieldStyl.bgColor,
                                                //fontStyle:fieldStyl.fontStyle,
                                                //color:fieldStyl.fontColor
                                            }}

                                            id={"labelInput-"+props.ij}
                                            value={labelVal}
                                            placeholder={props.data.label.text}
                                            //tabIndex={props.data.label.tabIndex}

                                            readOnly={true}
                                            //onChange={handleChangeLabel}
                                            // onFocus = {event => {
                                            //     // event properties must be copied to use async
                                            //     const target = event.target;
                                            //     setTimeout(() => handleFocusLabel(target), 0);//set with a timeout to fix safari browser
                                            // }}
                                            onBlur={handleBlurLabel}
                                            onKeyPress = {handleKeyPress}

                                
                                        />

                                    </div>

                                </div>



                            :


                                <div 
                                className={"labelEnter"}
                                >

                                    <div 
                                    className="label-x"
                                    onClick={(v) => hideLabelInput()}
                                    >
                                        X

                                    </div>


                                    <div
                                    className="label-wrap"
                                    >

                                        <input
                                            ref={refLabelInput}
                                            type="text"
                                            inputMode="text"

                                            className="input-tag"
                                            style={{
                                                //backgroundColor:fieldStyl.bgColor,
                                                //fontStyle:fieldStyl.fontStyle,
                                                //color:fieldStyl.fontColor
                                            }}

                                            id={"labelInput-"+props.ij}
                                            value={labelVal}
                                            placeholder={props.data.label.text}
                                            //tabIndex={props.data.label.tabIndex}

                                            onChange={handleChangeLabel}
                                            onFocus = {event => {
                                                // event properties must be copied to use async
                                                const target = event.target;
                                                setTimeout(() => handleFocusLabel(target), 0);//set with a timeout to fix safari browser
                                            }}
                                            onBlur={handleBlurLabel} 
                                            // onKeyPress = {event => {
                                            //     // event properties must be copied to use async
                                            //     setTimeout(() => props.handleMovement({
            
                                            //         event       :event,
                                            //         Ssection     :"costfields"
                        
                                            //     }), 0);
                                            // }}
                                            onKeyPress = {handleKeyPress}

                                
                                        />

                                    </div>

                                </div>



                            }




                            <div 
                            className="label"
                            onClick={(v) => showLabelInput()}
                            >
                                {labelValShort}

                            </div>




                        </div>



                    :



                        <div 
                        className="label"
                        onClick={(v) => showLabelInput()}
                        >
                            {labelValShort}

                        </div>
                    
                    }


                    {/* <div 
                    className="label"
                    onClick={(v) => showLabelInput({

                        call        :"edit",
                        data        :props.data,
                        fieldCall   :'costfield',
                        key         :props.ij
            
                    })}
                    >
                        {props.data.label.text}

                    </div> */}



                    <div 
                    className="controller"
                    >
                        <div 
                        id={"button-"+props.ij}                  
                        className="button"

                        onClick={(v) => props.handle({

                            call        :"delete",
                            data        :props.data,
                            fieldCall   :'costfield',
                            key         :props.ij
                
                        })}

                        onMouseOver={(v) => handleOverOut({

                            call:"over",
                            index:props.ij,
                            svg:"trash"

                        })}
                        onMouseOut={(v) => handleOverOut({

                            call:"out",
                            index:props.ij,
                            svg:"trash"

                        })}

                        >

                            <img
                            id={"svg-"+props.ij}
                            className="icon"
                            src={SVGTrash} 
                            alt="del" />

                            
                        </div>

                    </div>


                    <div 
                    className="qtyhrs-wrap"
                    >

                        <input
                            //type="text"
                            type="number" pattern="[0-9]*"
                            inputMode="numeric"

                            className="input-tag"
                            style={{
                                //backgroundColor:fieldStyl.bgColor,
                                //fontStyle:fieldStyl.fontStyle,
                                //color:fieldStyl.fontColor
                            }}
            
                            id={props.data.qtyhrs.id}
                            value={qtyhrsVal}
                            placeholder={props.data.qtyhrs.hint}
                            tabIndex={props.data.qtyhrs.tabIndex}

                            onChange={handleChange}
                            onFocus = {event => {
                                // event properties must be copied to use async
                                const target = event.target;
                                setTimeout(() => handleFocus(target), 0);//set with a timeout to fix safari browser
                            }}
                            onBlur={handleBlur} 
                            onKeyPress = {event => {
                                // event properties must be copied to use async
                                setTimeout(() => props.handleMovement({

                                    event       :event,
                                    section     :"costfields",
                                    index       :props.ij
        
                                }), 0);
                            }}

                            //tabIndex={secondTab}

                        />

                    
                    </div>







                    <div
                    className="by"
                    style={{
                        //backgroundColor:fieldStyl.bgColor,
                        //width:fieldStyl.width
                    }}
                    >

                        X 

                    </div>







                    <div 
                    className="rate-wrap"
                    >


                        <div className="symbol">

                            $

                        </div>



                        <div>
                        {
                        isLabor
                        ?


                            <input
                                //type="text"
                                type="number" pattern="[0-9]*"
                                inputMode="none"
                                readOnly={true}


                                className="input-tag"
                                style={{
                                    backgroundColor:fieldStyl.bgColor,
                                    fontStyle:fieldStyl.fontStyle,
                                    color:fieldStyl.fontColor
                                }}
                
                                id={props.data.rate.id}
                                value={rateVal}
                                placeholder={props.data.rate.hint}
                                tabIndex={props.data.rate.tabIndex}

                                onChange={showLaborMenu}
                                //onClick={showLaborMenu}
                                onFocus={showLaborMenu}
                                onKeyPress = {event => {
                                    // event properties must be copied to use async
                                    setTimeout(() => props.handleMovement({

                                        event       :event,
                                        section     :"costfields",
                                        index       :props.ij
        
            
                                    }), 0);
                                }}



                                //tabIndex={firstTab}

                
                            />

                        :


                            <input
                                //type="text"
                                type="number" pattern="[0-9]*"
                                inputMode="numeric"

                                className="input-tag"
                                style={{
                                    backgroundColor:fieldStyl.bgColor,
                                    fontStyle:fieldStyl.fontStyle,
                                    color:fieldStyl.fontColor
                                }}
                
                                id={props.data.rate.id}
                                value={rateVal}
                                placeholder={props.data.rate.hint}
                                tabIndex={props.data.rate.tabIndex}

                                onChange={handleChange}
                                onFocus = {event => {
                                    // event properties must be copied to use async
                                    const target = event.target;
                                    setTimeout(() => handleFocus(target), 0);//set with a timeout to fix safari browser
                                }}
                                onBlur={handleBlur}
                                onKeyPress = {event => {
                                    // event properties must be copied to use async
                                    setTimeout(() => props.handleMovement({

                                        event       :event,
                                        section     :"costfields",
                                        index       :props.ij
        
            
                                    }), 0);
                                }}


                                //tabIndex={firstTab}

                
                            />


                        }
                        </div>



                        
                    </div>


                    <div
                    className="colorline"
                    style={{
                        backgroundColor:props.data.rate.linecolor,
                        color:props.data.rate.linecolor
                    }}
                    >

                        L

                    </div>










                </div>

                //-------------------------------------




            //####################################################################

            :// NOT MOBILE

            //####################################################################




                //------------------------------------
                //COST FIELD
                //------------------------------------

                <div 
                key={props.ij}
                className="costfield"
                >


            

                    {
                    labelInputView
                    ?

                        <div>


                            {
                            isLabor
                            ?

                                <div 
                                className={"labelView"}
                                >


                                    <div 
                                    className="label-x"
                                    onClick={(v) => hideLabelInput()}
                                    >
                                        X

                                    </div>

                                    <div
                                    className="label-wrap"
                                    >

                                        <input
                                            ref={refLabelInput}
                                            type="text"
                                            className="input-tag"
                                            style={{
                                                //backgroundColor:fieldStyl.bgColor,
                                                //fontStyle:fieldStyl.fontStyle,
                                                //color:fieldStyl.fontColor
                                            }}

                                            id={"labelInput-"+props.ij}
                                            value={labelVal}
                                            placeholder={props.data.label.text}
                                            //tabIndex={props.data.label.tabIndex}

                                            readOnly={true}
                                            //onChange={handleChangeLabel}
                                            // onFocus = {event => {
                                            //     // event properties must be copied to use async
                                            //     const target = event.target;
                                            //     setTimeout(() => handleFocusLabel(target), 0);//set with a timeout to fix safari browser
                                            // }}
                                            onBlur={handleBlurLabel}
                                            onKeyPress = {handleKeyPress}

                                
                                        />

                                    </div>

                                </div>



                            :


                                <div 
                                className={"labelEnter"}
                                >

                                    <div 
                                    className="label-x"
                                    onClick={(v) => hideLabelInput()}
                                    >
                                        X

                                    </div>


                                    <div
                                    className="label-wrap"
                                    >

                                        <input
                                            ref={refLabelInput}
                                            type="text"
                                            className="input-tag"
                                            style={{
                                                //backgroundColor:fieldStyl.bgColor,
                                                //fontStyle:fieldStyl.fontStyle,
                                                //color:fieldStyl.fontColor
                                            }}

                                            id={"labelInput-"+props.ij}
                                            value={labelVal}
                                            placeholder={props.data.label.text}
                                            //tabIndex={props.data.label.tabIndex}
                           
                                            onChange={handleChangeLabel}
                                            onFocus = {event => {
                                                // event properties must be copied to use async
                                                const target = event.target;
                                                setTimeout(() => handleFocusLabel(target), 0);//set with a timeout to fix safari browser
                                            }}
                                            onBlur={handleBlurLabel} 
                                            // onKeyPress = {event => {
                                            //     // event properties must be copied to use async
                                            //     setTimeout(() => props.handleMovement({
            
                                            //         event       :event,
                                            //         Ssection     :"costfields"
                        
                                            //     }), 0);
                                            // }}

                                            onKeyPress = {handleKeyPress}

                                
                                        />

                                    </div>

                                </div>



                            }




                            <div 
                            className="label"
                            onClick={(v) => showLabelInput()}
                            >
                                {labelValShort}

                            </div>




                        </div>



                    :



                        <div 
                        className="label"
                        onClick={(v) => showLabelInput()}
                        >
                            {labelValShort}

                        </div>
                    
                    }


                    {/* <div 
                    className="label"
                    onClick={(v) => showLabelInput({

                        call        :"edit",
                        data        :props.data,
                        fieldCall   :'costfield',
                        key         :props.ij
            
                    })}
                    >
                        {props.data.label.text}

                    </div> */}



                    <div 
                    className="controller"
                    >
                        <div 
                        id={"button-"+props.ij}                  
                        className="button"

                        onClick={(v) => props.handle({

                            call        :"delete",
                            data        :props.data,
                            fieldCall   :'costfield',
                            key         :props.ij
                
                        })}

                        onMouseOver={(v) => handleOverOut({

                            call:"over",
                            index:props.ij,
                            svg:"trash"

                        })}
                        onMouseOut={(v) => handleOverOut({

                            call:"out",
                            index:props.ij,
                            svg:"trash"

                        })}

                        >

                            <img
                            id={"svg-"+props.ij}
                            className="icon"
                            src={SVGTrash} 
                            alt="del" />

                            
                        </div>

                    </div>


                    <div 
                    className="qtyhrs-wrap"
                    >

                        <input
                            type="text"
                            //type="number" pattern="[0-9]*"
                            className="input-tag"
                            style={{
                                //backgroundColor:fieldStyl.bgColor,
                                //fontStyle:fieldStyl.fontStyle,
                                //color:fieldStyl.fontColor
                            }}
            
                            id={props.data.qtyhrs.id}
                            value={qtyhrsVal}
                            placeholder={props.data.qtyhrs.hint}
                            tabIndex={props.data.qtyhrs.tabIndex}

                            onChange={handleChange}
                            onFocus = {event => {
                                // event properties must be copied to use async
                                const target = event.target;
                                setTimeout(() => handleFocus(target), 0);//set with a timeout to fix safari browser
                            }}
                            onBlur={handleBlur}
                            onKeyPress = {event => {
                                // event properties must be copied to use async
                                setTimeout(() => props.handleMovement({

                                    event       :event,
                                    section     :"costfields",
                                    index       :props.ij
        
        
                                }), 0);
                            }}

                            //tabIndex={secondTab}

                        />

                    
                    </div>







                    <div
                    className="by"
                    style={{
                        //backgroundColor:fieldStyl.bgColor,
                        //width:fieldStyl.width
                    }}
                    >

                        X 

                    </div>







                    <div 
                    className="rate-wrap"
                    >


                        <div className="symbol">

                            $

                        </div>



                        <div>
                        {
                        isLabor
                        ?


                            <input
                                type="text"
                                //type="number" pattern="[0-9]*"
                                className="input-tag"
                                style={{
                                    backgroundColor:fieldStyl.bgColor,
                                    fontStyle:fieldStyl.fontStyle,
                                    color:fieldStyl.fontColor
                                }}
                
                                id={props.data.rate.id}
                                value={rateVal}
                                placeholder={props.data.rate.hint}
                                tabIndex={props.data.rate.tabIndex}

                                onChange={showLaborMenu}
                                onClick={showLaborMenu}
                                onFocus={showLaborMenu}
                                // onKeyPress = {event => {
                                //     // event properties must be copied to use async
                                //     setTimeout(() => props.handleMovement({

                                //         event       :event,
                                //         section     :"costfields"
            
                                //     }), 0);
                                // }}


                                //tabIndex={firstTab}

                
                            />

                        :


                            <input
                                type="text"
                                //type="number" pattern="[0-9]*"

                                className="input-tag"
                                style={{
                                    backgroundColor:fieldStyl.bgColor,
                                    fontStyle:fieldStyl.fontStyle,
                                    color:fieldStyl.fontColor
                                }}
                
                                id={props.data.rate.id}
                                value={rateVal}
                                placeholder={props.data.rate.hint}
                                tabIndex={props.data.rate.tabIndex}

                                onChange={handleChange}
                                onFocus = {event => {
                                    // event properties must be copied to use async
                                    const target = event.target;
                                    setTimeout(() => handleFocus(target), 0);//set with a timeout to fix safari browser
                                }}
                                onBlur={handleBlur} 
                                onKeyPress = {event => {
                                    // event properties must be copied to use async
                                    setTimeout(() => props.handleMovement({

                                        event       :event,
                                        section     :"costfields",
                                        index       :props.ij
        
            
                                    }), 0);
                                }}


                                //tabIndex={firstTab}

                
                            />


                        }
                        </div>



                        
                    </div>


                    <div
                    className="colorline"
                    style={{
                        backgroundColor:props.data.rate.linecolor,
                        color:props.data.rate.linecolor
                    }}
                    >

                        L

                    </div>



                </div>

                //-------------------------------------



            }



        </div>

    )


}

