import React, { 

    useState, 
    useEffect, 
    //useRef 

} from 'react';


import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    //useHistory,   
    //useParams,



} from "react-router-dom";

import { useWindowDimensions } from 'utils/hooks/WindowDimensionsProvider'



import { createPortal } from 'react-dom';
import usePortal from 'utils/hooks/Portal';




// import Navigator from 'utils/components/Content/Navigator';
// import SideNavInfo from 'utils/components/Pages/SceneInfo/Trials/SideNav/SceneInfo';




//=====================================================


import {

    MenuAlfa,
    MenuBase,   
    Menu

} from './Styled';



import { AlterCalcs } from 'app-components/Zone/_Connect/Calcs/Alter';
//import { FetchCalcs } from 'app-components/Zone/_Connect/Calcs/Fetch';

import { LibDate } from 'library';


import Loading from 'app-components/Zone/_Generic/Loading';
import Message from 'app-components/Zone/_Generic/Message';
//import Flanks from 'app-components/Zone/_Generic/Flanks';

import SaveForm from './SaveForm';
import { Timeout } from './Timeout';


const Index = (props) => {



    const portalTarget = usePortal("stage");


    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();

    
 
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);



    // -- window dims -- //
    const { 
        width,
        height 
    } = useWindowDimensions();



    // -- redux -- //
    // const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);


    //=============================================
    // state/effect - display
    //=============================================

    const [isLoaded, setIsLoaded] = useState(false);
    const [hide, setHide] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [showSaveForm, setShowSaveForm] = useState(false);
    const [runSave, setRunSave] = useState(false);
    const [saveData, setSaveData] = useState(false);
    const [runTimeout, setRunTimeout] = useState(false);
    const [currentProcess, setCurrentProcess] = useState(false);

    useEffect(() => {

        if(!isLoaded)
        {
            setIsLoaded(true);

            if(props.calcId === 'calc')
            {
                setShowSaveForm(true);
            }

        }
  
    },
    [
        width,height,
        props,
        zoneObj,
        isLoaded,
    
    ])









    //=============================================
    // handle
    //=============================================

    const handle = (hndl) => {


        switch(hndl.call)
        {
        case'complete':
        case'completeConfirm':
        case'error':

            //console.log(hndl.message);

            setRunSave(false); 
            switch(hndl.call) 
            {
            case'error':

                setShowMessage({

                    call:"showMessage",
                    message:hndl.message

                });  
                setRunTimeout(1500);  
            break;
            default:
                
                // setShowMessage({

                //     call:"showMessage",
                //     message:hndl.message

                // });  
                setRunTimeout(20); 

            }
            setShowSaveForm(false);


        break;
        case'done':
            //alert('done');
            setRunSave(false);
            setShowMessage(false);
            setShowSaveForm(false);

            if(currentProcess === 'saveas')
            {
                props.handle({

                    call:"saveas",
                    data:false
        
                });

            }else{

                // props.handle({

                //     call:"showCalcMenu",
                //     data:false
        
                // });

                props.handle({

                    call:"save",
                    data:false
        
                });

            }
        break;
        case'save':
        case'saveas':

            var message;
            var saveName;
            var saveDescr;
            var errorMessage, errorMessageDupe;

            var calcs,calc;

            switch(hndl.call)
            {
            case'save':
              
                switch(hndl.action)
                {
                case'process':


                    var calcId = props.calcId;
                    //var calcName = props.calcName;

                    // console.log(zoneObj['calcs']);
                    // alert(calcId);
                    
                    calcs = JSON.parse(JSON.stringify(zoneObj['calcs']));
                    if(calcs[calcId] && calcs[calcId] !== undefined)
                    {
                        calc = calcs[calcId];
                        calc.data.value = props.captured;

                        // console.log("Calc: "+JSON.stringify(calc,null,2));
                        // alert("calc");

                        setSaveData({

                            action_call :"save",
                            action_name :"Save Calculator",
                            item:calc

                        });
                        setShowMessage(false);                
                        setRunSave(true);  
                        setHide(true); 

                    }
                    else
                    {

                        alert('No calcId '+calcId)
                    }

                 
                break;
                default:
                    setShowMessage({

                        call:"showConfirm",
                        message:'Would you like to "Save" calculator settings?',
                        actionCall :"save"
        
                    });
                    setHide(true);
                
                }
            break;
            case'saveas':
               
                switch(hndl.action)
                {
                case'process':


                    setCurrentProcess('saveas');

                    // props.handle({

                    //     call:"showCalcMenu",
                    //     data:false
            
                    // })

                    //console.log("captured: "+JSON.stringify(props.captured,null,2));

 
                    saveName = document.getElementById("save_name");
                    saveDescr = document.getElementById("save_descr");
                    errorMessage = document.getElementById("error_message");
                    errorMessageDupe = document.getElementById("error_message_duplicate");


                    saveName.style.backgroundColor = 'white';
                    saveDescr.style.backgroundColor = 'white';
                    errorMessage.style.display = "none";
                    errorMessageDupe.style.display = "none";

                    
                    if(saveName.value === '' || saveDescr.value === '')
                    {

                        message = '';
                        if(saveName.value === '' || saveName.value === 'Calculator Name') 
                        {
                            message = message + 'Please type "Name"';
                            saveName.style.backgroundColor = 'lightyellow';
                        }
                        if(saveDescr.value === '' || saveDescr.value === 'Calculator Description') 
                        {

                            if(message !== '') 
                                message = 'Please type "Description"';
                            else 
                                message = message + ' and "Description"';

                            saveDescr.style.backgroundColor = 'lightyellow';
                        }
                        errorMessage.style.display = "inline";
                        //errorMessage.innerHtml = message;

                        // setShowMessage({

                        //     call:"showMessage",
                        //     message:message,
                        //     duration:false
            
                        // }); 


                    }
                    else
                    {
                        let okay = true;
                        if(zoneObj.calcs && zoneObj.calcs !== undefined)
                        {
                            var calcKey;
                            for(calcKey in zoneObj.calcs)
                            {
                                calc = zoneObj.calcs[calcKey];
                                if(calc.data.name.toUpperCase() === saveName.value.toUpperCase())
                                {
                                    okay = false;
                                    break;
                                }
                
                            }
                
                        }
                        if(!okay)
                        {
                
                            //alert('not okay')

                            message = message + 'Duplicate "Name"';
                            saveName.style.backgroundColor = 'lightyellow';
                    
                            errorMessageDupe.style.display = "inline";
                            //errorMessageDupe.innerHtml = message;
    
                
                        }else{
                
    
                            //----------------------------------------------------------
                            var timestamp;
                            var randnum;
                            var new_recid;
                        
            
                            timestamp = LibDate.Timestamp('noformat');
                            randnum = Math.floor(Math.random() * 9000 + 1000);

                            new_recid = parseInt(timestamp) + randnum;
                            new_recid = new_recid.toString();
                            new_recid = new_recid.replace(/-/g,'');
                            //-----------------------------------------------------------

                            var new_value;
                            new_value = JSON.parse(JSON.stringify(props.captured));

                            setSaveData({

                                action_call :"saveas",
                                action_name :"Calculator Save As",
                                item:{
                                    reckey:"rec-calculator-"+new_recid,
                                    recid:new_recid,
                                    name:saveName.value,
                                    data:{
                                        name:saveName.value,
                                        value:new_value,
                                        favorite:5,
                                        description:saveDescr.value
                                    }
                                
                                }


                            });
                            setRunSave(true);
                            setShowSaveForm(false);
                            setHide(true); 
    
                        }


                    }//==

               

                break;
                default:
                    setShowSaveForm(true);
                    setHide(true);
                }
            break;
            default:
            }

        break;
        case'cancel':

            setShowMessage(false);
            setShowSaveForm(false);
            props.handle({

                call:"showCalcMenu",
                data:false


            });

            // if(props.calcId === 'calc')
            // {
            //     props.handle({

            //         call:"showCalcMenu",
            //         data:false
        
            //     });

            // }


        break;
        default:
        }

  
    }






    return createPortal(<div>

        {
        runSave
        ?

            <div>

                <Loading />
                <AlterCalcs 

                    primeProps={props.primeProps}
                    handle={handle}
                    duration={2500}
                    data={saveData}
                    formArr={props.formArr}
                    captured={props.captured}
                />

            </div>

        :null
        }

        {
        showMessage
        ?
            <Message
                
                handle={handle}
                call={showMessage.call}
                message={showMessage.message}
                actionCall={showMessage.actionCall}
                //data={props.data}

            />

        :null
        }

        {
        showSaveForm
        ?
            <SaveForm 

                handle={handle}
                //data={props.data}
            
            />

        :null
        }


        {
        runTimeout
        ?
            <Timeout
                
                handle={handle}
                duration={runTimeout}
     
            />

        :null
        }





        {
        !runSave && !showMessage && !showSaveForm && !runTimeout 
        ?

            <MenuAlfa zIndex={"2"}></MenuAlfa>

        :null
        }
        <MenuBase
        //backgroundColor={"lightblue"}
        zIndex={"3"}
        >

            <MenuBase
            //backgroundColor={"gray"}
            zIndex={"1"}
            onClick={(v) => props.handle({

                call:"showCalcMenu",
                data:false

            })}
            ></MenuBase>


            {
            props.calcId !== 'calc' && !hide
            ?

                 <Menu
                 zIndex={"4"}
                 >

                    <div 
                    className="Button"
                    onClick={(v) => handle({


                        call:'save',
                        action:'default'


                    })}
                    >
                        Save

                    </div>

                    <div 
                    className="Button"
                    onClick={(v) => handle({

                        call:'saveas',
                        action:'default'


                    })}
                    >
                        Save As New

                    </div>
            
                </Menu>

            :null
            }


        </MenuBase>



    </div>, portalTarget);


}

export default Index;


