import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    //useHistory,   
    //useParams,



} from "react-router-dom";




/*

import { 
    //Lorem, 
    //TestImages,
    //TestVideos,
    //SpecialImages,
    //NavigationData 
} from 'app-components/Prime/Data';


// import {

//     //TextCard1,
//     //TextCard2,
//     //TextCard3,

//     //ImageTextCard1,
//     //ImageTextCard2,
//     //ImageTextCard3


// } from 'app-components/Zone/Builds/Samples/Card';



*/

//import { Settings } from 'app-components/Prime/Data.js';



import { 
    LibSystem
} from 'library';



const SceneInfo = (props) => {


    // -- redux -- //
    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));


    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();

 
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);

    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);



    //-----------------------------------------
    //-----------------------------------------

    var pathArr = pathname.split("/");
    var pathCount = pathArr.length;

    var currIndex = parseInt(pathCount)-1;
    var categoryName = pathArr[currIndex];

    var categoryKey = categoryName.replace(/ /gi,"");

    //var categoryIndex = parseInt(pathCount)-2;
    //var nextIndex = categoryIndex + 1;



    //var categoryCount = currIndex;
    // var mainCategoryName = false;
    // if(categoryCount > 1)
    // {
    //     //console.log("categoryCount: "+categoryCount);

    //     var mainIndex = parseInt(pathCount)-2;
    //     mainCategoryName = pathArr[mainIndex];

    //     //console.log("mainCategoryName: "+mainCategoryName);
        
    // }



    let isDynamic;
    isDynamic = props.isDynamic;

    //alert("isDynamic: "+isDynamic);



    // var index;

    // var keyname;
    // var kn;
    // var item;
    // var value;
    // var newValue;

    // var a,b,c,d;
    // var total;
    // var grand_total;


    // //var chartSections;
    // // var object;
    // // var percent;
    // // var count;

    // //var pgraph;


    // var obj;



    var R = [];


    var keyname;
    var pages;
    var page,pageName;
    var pageCaption;
    var pageData;
    var pageRows;
    //var item,itm,count;
    var row,count;

    var catalogItems;



    switch(props.display)
    {
    case'print':

        var printValue;
        printValue = "";
        printValue += "";

            // if(zoneObj.calc_dash)
            // {
            //     printValue += "Result Items";
            //     printValue += JSON.stringify(zoneObj.calc_dash.result_items,null,2)

            //     printValue += "Form Items";
            //     printValue += JSON.stringify(zoneObj.calc_dash.form_items,null,2)

            // }//==

            printValue += "categoryName: "+categoryName;
            printValue += "No Data";

        printValue += "";


        if(zoneObj['knowledge_base_page'] 
        && zoneObj['knowledge_base_page']!== undefined)
        {
            if(zoneObj['knowledge_base_page'].pages 
            && zoneObj['knowledge_base_page'].pages !== undefined)
            {


            
                // console.log("Props: "+JSON.stringify(props.goods,null,2));
            
                // var keyname;
                // var pages;
                // var page,pageName;
                // var pageCaption;
                // var pageData;

                pages = zoneObj['knowledge_base_page'].pages;
                //console.log("Pages:........... "+JSON.stringify(pages,null,2));


                printValue = "categoryName: "+categoryName;
                //printValue = JSON.stringify(pages,null,2);

                for(keyname in pages)
                {
                    page = pages[keyname];

                    pageName = page.name;
                    pageName = LibSystem.RemoveExt(pageName);
                    pageName = LibSystem.FileDisplay(pageName);

                    

                    pageCaption = LibSystem.FileDisplay(page.caption);

                    pageData = page.data;
                    if(Object.prototype.toString.call(pageData) === '[object String]')
                    {
                        pageData = JSON.parse(page.data);
                    }

                    if(page.tree === categoryName)
                    {
                        printValue += "\npageName: "+pageName;
                        printValue += "\npageCaption: "+pageCaption;
                        printValue += "\npageData: "+JSON.stringify(pageData,null,2);

                        categoryName = pageName;
                        break;
                    }


                    // catalogItems.push({
                    
                    //     heading:pageName,
                    //     bullet:false,
                    //     // bullet:{
            
                    //     //     backgroundColor:bulletColor,
                    //     //     textColor:letterColor
            
                    //     // },
                    //     stripe:true,
                    //     pgraph:pageCaption,
                    //     image:false,
                    //     button:false,
                    //     data:{
            
                    //         id:pageName,
                    //         name:pageName
            
                    //     }
                    
                    // });


                }

        
            }

        }




        //#############################################################
        //#############################################################

    
        //--------------------------
        // report - top
        //--------------------------
      
        R.push({

            call:'top',
            name:categoryName, 
            controls:[


                // {

                //     name:"Edit",
                //     // form:{

                //     //     submitCall:'calculate',
                //     //     postTo:apiDomain+apiCall,
                //     //     data:newTemplateExpenseForm,
                //     //     flyr:{

                //     //         hashbang:"#!new",
                //     //         type:"form",
                //     //         styl:{
                //     //             maxWidth:500,
                //     //             fieldType:'composed'   //multi,field,labelfield
                //     //         }

                //     //     }   

                //     // }

                //     // form:{

                //     //     submitCall:'calculate',
                //     //     postTo:apiDomain+"alter-calc-dash",
                //     //     data:VPincome,
                //     //     flyr:{
                //     //         type:"form",
                //     //         styl:{
                //     //             maxWidth:500,
                //     //             fieldType:'field'
                //     //         }
                //     //     }

                //     // }


                //     // form:{

                //     //     submitCall:'calculate',
                //     //     postTo:apiDomain+"alter-calc-dash",
                //     //     data:VPincome,
                //     //     flyr:{
                //     //         type:"form",
                //     //         styl:{
                //     //             maxWidth:500,
                //     //             fieldType:'field'
                //     //         }
                //     //     }

                //     // }


                // }




            ]

        });




        //--------------------------
        // Print
        //--------------------------

        R.push({

            call:'print',
            value:printValue

        });

    break;
    default:





        //--------------------------
        // report - top
        //--------------------------



        var word,wordArray,wordString,wrd,wrdArray;
        //word = 'ThisIsTheString4Split'.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");


        if(categoryName.match(/_/gi))
        {
            wordString = '';
            wordArray = categoryName.split("_");

            count = 0;
            for(wrd of wordArray)
            {
                if(count === 0)
                {
                    wrdArray = wrd.split("-");
                    wordString = wrdArray[wrdArray.length-1];
                    //wrdString = wrdString.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
                }else{
                    //if(wrd && wrd !== undefined) wrdString = wrdString + " " + wrd.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
                    wordString = wordString + " " + wrd;
                }
                count++;
                
            }
            word = wordString;
        }else{
            word = categoryName.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
        }


        catalogItems = [];
        catalogItems.push({

            call:'top',
            name:word, 
            controls:[]

        });


        if(isDynamic)
        {
            pageRows = [];


            //----------------------------
            // pages
            //----------------------------

            // if(zoneObj['knowledge_base_page'] 
            // && zoneObj['knowledge_base_page']!== undefined)
            // {
            //     if(zoneObj['knowledge_base_page'].pages 
            //     && zoneObj['knowledge_base_page'].pages !== undefined)
            //     {

            //         // console.log("Props: "+JSON.stringify(props.goods,null,2));

            //         pages = zoneObj['knowledge_base_page'].pages;
            //         //console.log("Pages:........... "+JSON.stringify(pages,null,2));

            //         for(keyname in pages)
            //         {
            //             page = pages[keyname];

            //             if(page.tree === categoryName)
            //             {
            //                 pageName = page.name;
            //                 pageName = LibSystem.RemoveExt(pageName);
            //                 pageName = LibSystem.FileDisplay(pageName);
            //                 pageCaption = LibSystem.FileDisplay(page.caption);

            //                 pageData = page.data;
            //                 if(Object.prototype.toString.call(pageData) === '[object String]')
            //                 {
            //                     pageData = JSON.parse(page.data);
                            
            //                 }

            //                 //console.log("pageData:"+JSON.stringify(pageData,null,2));
            //                 count = 0;
            //                 for(row of pageData)
            //                 {
            //                     if(count > 0)
            //                     {
            //                         // for(itm of item.data)
            //                         // {
            //                         //     console.log("itm:"+JSON.stringify(itm,null,2));
            //                         //     pageRows.push(itm) 
            //                         // }  

            //                         // console.log("row: "+JSON.stringify(row,null,2));
            //                         pageRows.push(row.data[0]);
            //                     }
            //                     count++;
            //                 }

            //                 categoryName = pageName;
            //                 //break;
            //             }

            //         }

            //     }

            // }




            //----------------------------
            // page
            //----------------------------

            if(localStorage.editMode)
            {

                if(zoneObj['knowledge_base_page'] 
                && zoneObj['knowledge_base_page'] !== undefined)
                {
                    if(zoneObj['knowledge_base_page'].page 
                    && zoneObj['knowledge_base_page'].page !== undefined)
                    {

                    
                        page = zoneObj['knowledge_base_page'].page;
                        if(page.tree === categoryName)
                        {
                            pageName = page.name;
                            pageName = LibSystem.RemoveExt(pageName);
                            pageName = LibSystem.FileDisplay(pageName);
                            
                            pageCaption = LibSystem.FileDisplay(page.caption);

                            pageData = page.data;
                            if(Object.prototype.toString.call(pageData) === '[object String]')
                            {
                                pageData = JSON.parse(page.data);
                            
                            }

                            //console.log("pageData:"+JSON.stringify(pageData,null,2));
                            count = 0;
                            for(row of pageData)
                            {
                                if(count > 0)
                                {
                                    // for(itm of item.data)
                                    // {
                                    //     console.log("itm:"+JSON.stringify(itm,null,2));
                                    //     pageRows.push(itm) 
                                    // }  

                                    // console.log("row: "+JSON.stringify(row,null,2));
                                    pageRows.push(row.data[0]);
                                }
                                count++;
                            }

                            categoryName = pageName;
                        
                        } 

                    }

                }


            }else{



                if(zoneObj['knowledge_base_'+categoryKey] 
                && zoneObj['knowledge_base_'+categoryKey] !== undefined)
                {
                    if(zoneObj['knowledge_base_'+categoryKey].scene 
                    && zoneObj['knowledge_base_'+categoryKey].scene !== undefined)
                    {
                    


                        // {
                        //     "currentScene": {
                        //       "sceneRoute": "KnowledgeBase/Introduction",
                        //       "sceneName": "Introduction",
                        //       "name": "Introduction_sys20210906159663id_.json",
                        //       "reckey": "rec-blog-entry-20210906159663",
                        //       "compName": "KnowledgeBase",
                        //       "recid": "20210906159663",
                        //       "routeName": "KnowledgeBase",
                        //       "sceneCompName": "Introduction"
                        //     },
                        //     "sceneName": "KnowledgeBase",
                        //     "sceneInfo": [
                        //       {
                        //         "styl": {
                        //           "call": "full",
                        //           "class": "FullRow",
                        //           "marginTop": "0px",
                        //           "marginBottom": "0px"
                        //         },
                        //         "call": "TxtComponent",
                        //         "info": {
                        //           "call": "flex",
                        //           "gridType": "AllText",
                        //           "gridBgColor": "transparent",
                        //           "gridCall": "grid_cell",
                        //           "cellCall": "default",
                        //           "modCall": "fill_div",
                        //           "thumb": {
                        //             "call": "default",
                        //             "diff": "0"
                        //           },
                        //           "cellBorder": {
                        //             "boolean": true,
                        //             "thickness": 1,
                        //             "color": "gray"
                        //           },
                        //           "gutter": {
                        //             "boolean": "0",
                        //             "size": "0"
                        //           },
                        //           "thresh": {
                        //             "refWidth": false,
                        //             "across1": "501",
                        //             "across2": "801",
                        //             "across3": "1051"
                        //           }
                        //         },
                        //         "items": [
                        //           {
                        //             "call": "cke",
                        //             "data": [
                        //               {
                        //                 "call": "pgraph",
                        //                 "data": " <div style=\"background-color:transparent; width:100%; height:15px;\"> </div> "
                        //               },
                        //               {
                        //                 "call": "heading",
                        //                 "data": "The Profit Calculator"
                        //               },
                        //               {
                        //                 "call": "pgraph",
                        //                 "data": "The Profit Calculator organizes the various financial elements required to start, run, maintain and grow a successful company. A moderate understanding of financial principals will be necessary to apply the system."
                        //               },


                        // .....



                        page = zoneObj['knowledge_base_'+categoryKey].scene;
                        //console.log(JSON.stringify(page.currentScene,null,2) + "\n" + categoryName)
                        if(page.currentScene.sceneName === categoryName
                        || page.currentScene.sceneCompName === categoryName
                        )
                        {
                            //console.log(JSON.stringify(page,null,2));

                            pageName = page.currentScene.sceneName;
                            pageName = LibSystem.RemoveExt(pageName);
                            pageName = LibSystem.FileDisplay(pageName);
                            
                            pageCaption = LibSystem.FileDisplay(page.currentScene.sceneName);

                            pageRows = page.sceneInfo;

                            categoryName = pageName;
                      
                        }

                    }

                }

            }



            var skip = false;
            if(pageRows.length === 0 || skip)
            {

                pageRows = [


                    // {
                    //     "styl": {
                    //         "call": "indent",
                    //         "class": "IndentRow",
                    //         "marginTop": "25px",
                    //         "marginBottom": "200px"
                    //     },
                    //     "call": "TxtComponent",
                    //     "info": {
                    //             "call": "flex",
                    //             "gridType": "AllText",
                    //             "gridBgColor": "transparent",
                    //             "gridCall": "grid_cell",
                    //             "cellCall": "default",
                    //             "modCall": "fill_div",
                    //             "thumb": {
                    //             "call": "default",
                    //             "diff": "0"
                    //         },
                    //         "cellBorder": {
                    //             "boolean": true,
                    //             "thickness": 1,
                    //             "color": "gray"
                    //         },
                    //         "gutter": {
                    //             "boolean": "0",
                    //             "size": "0"
                    //         },
                    //         "thresh": {
                    //             "refWidth": false,
                    //             "across1": "501",
                    //             "across2": "801",
                    //             "across3": "1051"
                    //         }
                    //     },
                    //     "items": [
                
                
                
                    //         {
                    //             "call": "cke",
                    //             "data": {
                    //                 "heading": "Loading..."+categoryName,
                    //                 "pgraph": ""
                    //             },
                    //             "style":{
                
                    //                 "heading":{
                
                    //                     "fontFamily": 'Arial, Helvetica, sans-serif',
                    //                     "fontSize": "30px",
                    //                     "fontWeight": "600",
                    //                     "textAlign": "left",
                    //                     "textDecoration": "none",
                    //                     "color": "black"
                                    
                    //                 },
                    //                 "pgraph":{
                    //                     "fontFamily": 'Arial, Helvetica, sans-serif',
                    //                     "fontSize": "20px",
                    //                     "fontWeight": "500",
                    //                     "textAlign": "left",
                    //                     "textDecoration": "none",
                    //                     "color": "black"
                    //                 }
                                    
                    //             }
                
                    //         }
            
                    //     ]

                    // }



                    //##########################################################
                    //##########################################################
                    //##########################################################
                
                    // AllText TM:
                
                
                    // {
                    //     "styl": {
                    //         "call": "indent",
                    //         "class": "IndentRow",
                    //         "marginTop": "25px",
                    //         "marginBottom": "200px"
                    //     },
                    //     "call": "TxtComponent",
                    //     "info": {
                    //             "call": "flex",
                    //             "gridType": "AllText",
                    //             "gridBgColor": "transparent",
                    //             "gridCall": "grid_cell",
                    //             "cellCall": "default",
                    //             "modCall": "fill_div",
                    //             "thumb": {
                    //             "call": "default",
                    //             "diff": "0"
                    //         },
                    //         "cellBorder": {
                    //             "boolean": true,
                    //             "thickness": 1,
                    //             "color": "gray"
                    //         },
                    //         "gutter": {
                    //             "boolean": "0",
                    //             "size": "0"
                    //         },
                    //         "thresh": {
                    //             "refWidth": false,
                    //             "across1": "501",
                    //             "across2": "801",
                    //             "across3": "1051"
                    //         }
                    //     },
                    //     "items": [
                
                
                
                    //         {
                    //             "call": "cke",
                    //             "data": {
                    //                 "heading": categoryName,
                    //                 "pgraph": "<strong>Show</strong> dynamic content here"
                    //             },
                    //             "style":{
                
                    //                 "heading":{
                
                    //                     "fontFamily": 'Arial, Helvetica, sans-serif',
                    //                     "fontSize": "50px",
                    //                     "fontWeight": "600",
                    //                     "textAlign": "left",
                    //                     "textDecoration": "none",
                    //                     "color": "black"
                                    
                    //                 },
                    //                 "pgraph":{
                    //                     "fontFamily": 'Arial, Helvetica, sans-serif',
                    //                     "fontSize": "20px",
                    //                     "fontWeight": "500",
                    //                     "textAlign": "left",
                    //                     "textDecoration": "none",
                    //                     "color": "black"
                    //                 }
                                    
                    //             }
                
                    //         },


                    //         {
                    //             "call": "cke",
                    //             "data": {
                    //                 "heading": categoryName,
                    //                 "pgraph": "<strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
                    //             },
                    //             "style":{
                
                    //                 "heading":{
                
                    //                     "fontFamily": 'Arial, Helvetica, sans-serif',
                    //                     "fontSize": "40px",
                    //                     "fontWeight": "600",
                    //                     "textAlign": "left",
                    //                     "textDecoration": "none",
                    //                     "color": "black"
                                    
                    //                 },
                    //                 "pgraph":{
                    //                     "fontFamily": 'Arial, Helvetica, sans-serif',
                    //                     "fontSize": "18px",
                    //                     "fontWeight": "500",
                    //                     "textAlign": "left",
                    //                     "textDecoration": "none",
                    //                     "color": "black"
                    //                 }
                                    
                    //             }
                
                    //         },



                    //         {
                    //             "call": "namevals",
                    //             "data": [
                    //                 {
                    //                 "name": "Facility",
                    //                 "value": "List all facility costs"
                    //                 },
                    //                 {
                    //                 "name": "Business",
                    //                 "value": "List all business cost"
                    //                 },
                    //                 {
                    //                 "name": "Vehicles",
                    //                 "value": "List cost of vehicles including gas, insurance and lease or buy monthly payments"
                    //                 },
                
                    //                 {
                    //                 "name": "Equip. Software",
                    //                 "value": "List costs of computers, printers, laminators and any internet subscriptions."
                    //                 }
                    //             ]
                    //         },
                
                
                
                
                    //         {
                    //             "call": "namevals",
                    //             "data": [
                    //                 {
                    //                 "name": "MyFakeName-4000",
                    //                 "value": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque"
                    //                 },
                    //                 {
                    //                 "name": "MyFakeName-fakeName-is-on",
                    //                 "value": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque"
                    //                 },
                    //                 {
                    //                 "name": "MyFakeName",
                    //                 "value": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque"
                    //                 }
                    //             ]
                    //         },

                

                    //     ]

                    // }

                ];

            }




            //console.log("pageRows: "+JSON.stringify(pageRows,null,2));

            //R = pageRows;
            R = {

                call:'dynamic',
                catalogItems:catalogItems,
                pageRows:pageRows
            };



        }else{




            // if(localStorage.editMode)
            // {



            // }



            if(zoneObj['knowledge_base_page'] 
            && zoneObj['knowledge_base_page']!== undefined)
            {
                if(zoneObj['knowledge_base_page'].pages 
                && zoneObj['knowledge_base_page'].pages !== undefined)
                {

                    // console.log("Props: "+JSON.stringify(props.goods,null,2));
                

                    pages = zoneObj['knowledge_base_page'].pages;
                    //console.log("Pages:........... "+JSON.stringify(pages,null,2));

                    for(keyname in pages)
                    {
                        page = pages[keyname];

                        pageName = page.name;
                        pageName = LibSystem.RemoveExt(pageName);
                        pageName = LibSystem.FileDisplay(pageName);

                    
                        pageCaption = LibSystem.FileDisplay(page.caption);

                        pageData = page.data;
                        if(Object.prototype.toString.call(pageData) === '[object String]')
                        {
                            pageData = JSON.parse(page.data);
                        }

                        if(page.tree === categoryName)
                        {
                            categoryName = pageName;
                            break;
                        }

                    }

            
                }

            }





            let pageRows = [


                //##########################################################
                //##########################################################
                //##########################################################
            
                // AllText TM:
            
            
                {
                    "styl": {
                        "call": "indent",
                        "class": "IndentRow",
                        "marginTop": "25px",
                        "marginBottom": "200px"
                    },
                    "call": "TxtComponent",
                    "info": {
                            "call": "flex",
                            "gridType": "AllText",
                            "gridBgColor": "transparent",
                            "gridCall": "grid_cell",
                            "cellCall": "default",
                            "modCall": "fill_div",
                            "thumb": {
                            "call": "default",
                            "diff": "0"
                        },
                        "cellBorder": {
                            "boolean": true,
                            "thickness": 1,
                            "color": "gray"
                        },
                        "gutter": {
                            "boolean": "0",
                            "size": "0"
                        },
                        "thresh": {
                            "refWidth": false,
                            "across1": "501",
                            "across2": "801",
                            "across3": "1051"
                        }
                    },
                    "items": [
            
            
            
                        {
                            "call": "cke",
                            "data": {
                                "heading": categoryName,
                                "pgraph": "<strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
                            },
                            "style":{
            
                                "heading":{
            
                                    "fontFamily": 'Arial, Helvetica, sans-serif',
                                    "fontSize": "50px",
                                    "fontWeight": "600",
                                    "textAlign": "left",
                                    "textDecoration": "none",
                                    "color": "black"
                                
                                },
                                "pgraph":{
                                    "fontFamily": 'Arial, Helvetica, sans-serif',
                                    "fontSize": "20px",
                                    "fontWeight": "500",
                                    "textAlign": "left",
                                    "textDecoration": "none",
                                    "color": "black"
                                }
                                
                            }
            
                        },
            
                        {
                            "call": "cke",
                            "data": {
                                "heading": categoryName,
                                "pgraph": "<strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
                            },
                            "style":{
            
                                "heading":{
            
                                    "fontFamily": 'Arial, Helvetica, sans-serif',
                                    "fontSize": "40px",
                                    "fontWeight": "600",
                                    "textAlign": "left",
                                    "textDecoration": "none",
                                    "color": "black"
                                
                                },
                                "pgraph":{
                                    "fontFamily": 'Arial, Helvetica, sans-serif',
                                    "fontSize": "18px",
                                    "fontWeight": "500",
                                    "textAlign": "left",
                                    "textDecoration": "none",
                                    "color": "black"
                                }
                                
                            }
            
                        },
            
            
                        {
                            "call": "cke",
                            "data": {
                                "heading": categoryName,
                                "pgraph": "<strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
                            },
                            "style":{
            
                                "heading":{
            
                                    "fontFamily": 'Arial, Helvetica, sans-serif',
                                    "fontSize": "32px",
                                    "fontWeight": "600",
                                    "textAlign": "left",
                                    "textDecoration": "none",
                                    "color": "black"
                                
                                },
                                "pgraph":{
                                    "fontFamily": 'Arial, Helvetica, sans-serif',
                                    "fontSize": "16px",
                                    "fontWeight": "500",
                                    "textAlign": "left",
                                    "textDecoration": "none",
                                    "color": "black"
                                }
                                
                            }
            
                        },
            
            
                        
                        {
                            "call": "cke",
                            "data": {
                                "heading": categoryName,
                                "pgraph": "<strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
                            },
                            "style":{
            
                                "heading":{
            
                                    "fontFamily": 'Arial, Helvetica, sans-serif',
                                    "fontSize": "24px",
                                    "fontWeight": "600",
                                    "textAlign": "left",
                                    "textDecoration": "none",
                                    "color": "black"
                                
                                },
                                "pgraph":{
                                    "fontFamily": 'Arial, Helvetica, sans-serif',
                                    "fontSize": "14px",
                                    "fontWeight": "500",
                                    "textAlign": "left",
                                    "textDecoration": "none",
                                    "color": "black"
                                }
                                
                            }
            
                        },
            
            
                        
            
                        {
                            "call": "cke",
                            "data": {
                                "heading": categoryName,
                                "pgraph": "<strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
                            },
                            "style":{
            
                                "heading":{
            
                                    "fontFamily": 'Arial, Helvetica, sans-serif',
                                    "fontSize": "20px",
                                    "fontWeight": "600",
                                    "textAlign": "left",
                                    "textDecoration": "none",
                                    "color": "black"
                                
                                },
                                "pgraph":{
                                    "fontFamily": 'Arial, Helvetica, sans-serif',
                                    "fontSize": "12px",
                                    "fontWeight": "500",
                                    "textAlign": "left",
                                    "textDecoration": "none",
                                    "color": "black"
                                }
                                
                            }
            
                        },
            
            
            
            
            
                        {
                            "call": "namevals",
                            "data": [
                                {
                                "name": "Facility",
                                "value": "List all facility costs"
                                },
                                {
                                "name": "Business",
                                "value": "List all business cost"
                                },
                                {
                                "name": "Vehicles",
                                "value": "List cost of vehicles including gas, insurance and lease or buy monthly payments"
                                },
            
                                {
                                "name": "Equip. Software",
                                "value": "List costs of computers, printers, laminators and any internet subscriptions."
                                }
                            ]
                        },
            
            
            
            
                        {
                            "call": "namevals",
                            "data": [
                                {
                                "name": "MyFakeName-4000",
                                "value": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque"
                                },
                                {
                                "name": "MyFakeName-fakeName-is-on",
                                "value": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque"
                                },
                                {
                                "name": "MyFakeName",
                                "value": "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam, sed iure blanditiis voluptatum nulla quidem minus quam tempora obcaecati necessitatibus inventore! Vitae totam quam pariatur facilis fugit maxime adipisci eaque"
                                }
                            ]
                        },
            
            
            
            
            
            
            
            
                        // {
                        //     "call": "links",
                        //     "data": [
                        //         {
                        //         "name": "Landing Page",
                        //         "value": "http://site.site/LandingPage",
                        //         "position": "left",
                        //         "mainWidth":"100%"
                        //         },
                        //         {
                        //         "name": "Billboard",
                        //         "value": "http://site.site/Billboard",
                        //         "position": "center",
                        //         "mainWidth":"100%"
                        //         },
                        //         {
                        //         "name": "PDM",
                        //         "value": "http://powerdigitalmedia.net",
                        //         "position": "right",
                        //         "mainWidth":"98%"
                        //         }
                        //     ]
                        // },
            
                        // {
                        //     "call": "buttons",
                        //     "data": [
                        //         {
                        //         "name": "PDM",
                        //         "value": "http://powerdigitalmedia.net",
                        //         "position": "left",
                        //         "mainWidth":"100%"
                        //         },
                        //         {
                        //         "name": "Billboards",
                        //         "value": "http://site.site/Billboard",
                        //         "position": "center",
                        //         "mainWidth":"100%"
                        //         },
                        //         {
                        //         "name": "PDM",
                        //         "value": "http://powerdigitalmedia.net",
                        //         "position": "right",
                        //         "mainWidth":"98%"
                        //         }
                        //     ]
                        // },
            
                        // {
                        //     "call": "style",
                        //     "data": {
            
                        //         "button": {
                        //             "backgroundColorOver": "#fc0",
                        //             "backgroundColorOut": "#0291cd",
                        //             "borderOver": "1px solid black",
                        //             "borderOut": "1px solid #0291cd",
                        //             "textColorOver":"black",
                        //             "textColorOut": "white"
                        //         },
                        //         "link": {
                        //             "textColorOver": "orange",
                        //             "textColorOut": "#0291cd"
                        //         }
                        //     }
                        // }
            
            
                    ]
                }
            
            
        
            
            ];
            



            //R = pageRows;

            R = {

                call:'dynamic',
                catalogItems:catalogItems,
                pageRows:pageRows
            };

   
        }// == isDynamic



    }


    //console.log("R: "+JSON.stringify(R,null,2));


    return R;
    
}



export default SceneInfo;
