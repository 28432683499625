
//######################################################################
//######################################################################
//######################################################################
//defaults


//=====================================================
//=====================================================

//-------------------------
//fields
//-------------------------

//var def_name = '';
var def_email = '';
var def_pword = '';
var def_accountId = '';

// var def_company = '';	
// var def_phone = '';
// var def_message = '';
// var def_band = '';



var use_defaults = true;


//=====================================================
//=====================================================

if(use_defaults)
{


    //-------------------------
    //fields
    //-------------------------

    //def_name = 'Tom M';
    //def_email = 'powerdigitalmedia.net@gmail.com';
    //def_pword = 'password';
    //def_accountId = '';
    // def_company = '';
    // def_phone = "555-444-3333";
    // def_message = 'This is a test message';
    // def_band = '';


    //-------------------------
    //captcha
    //-------------------------

    //def_captcha = "1234";//dynamically retrieved




}//==










const Composed = [


    {

        "call":"header",
        "data":{

            "text":"Sign Up Walk"
        }

    },






    //##########################################################################
    //##########################################################################
    //##########################################################################
    //customer info


    //==========================================
    //bar
    //==========================================
    // {

    //     "section"  :"bar",
    //     "data"      :{

    //         "text"  :"User Info."

    //     }


    // },
    
    
    //==========================================
    //fields
    //==========================================
    {

        "section"  :"fields",
        "data"      :[




            // //--------------------------------
            // //
            // //--------------------------------
            // {


            //     "fieldtype"               	:"text",
            //     "required"					:true,

            //     "class"                   	:'',
            //     "id"                      	:'name',
            //     "hint"                    	:"Name",

            //     "text"                     	:def_name,


            //     "tabIndex"                   :"",
            //     "keyboardType"            	:"",
            //     "autocorrect"             	:"false",
            //     "autocapitalizationType"  	:"none",


            //     //"secure"                  :true,
            //     //"returnKeyType"           :"done"


            // },




            //--------------------------------
            //
            //--------------------------------
            {


                "fieldtype"               	:"text",
                "required"					:true,

                "class"                   	:'',
                "id"                      	:'email',
                "hint"                    	:"Email",

                "text"                     	:def_email,

                "tabIndex"                  :"",
                "keyboardType"            	:"email",
                "autocorrect"             	:"false",
                "autocapitalizationType"  	:"none",


                //"secure"                  :true,
                //"returnKeyType"           :"done"


            },





            // //--------------------------------
            // //
            // //--------------------------------
            // {


            //     "fieldtype"               	:"text",
            //     "required"					:false,

            //     "class"                   	:'',
            //     "id"                      	:'company',
            //     "hint"                    	:"Company ( Optional )",

            //     "text"                     	:"",


            //     "tabIndex"                   :"",
            //     "keyboardType"            	:"",
            //     "autocorrect"             	:"false",
            //     "autocapitalizationType"  	:"none",


            //     //"secure"                  :true,
            //     //"returnKeyType"           :"done"


            // },




            
        
            
            //--------------------------------
            //password
            //--------------------------------
            {


                "fieldtype"               	:"password",
                "required"					:true,

                "class"                   	:'',
                "id"                      	:'pword',
                "hint"                    	:"Password",

                "text"                     	:def_pword,

                "tabIndex"                  :"",
                "keyboardType"            	:"",
                "autocorrect"             	:"false",
                "autocapitalizationType"  	:"none",


                //"secure"                  :true,
                //"returnKeyType"           :"done"


            },




            
            //--------------------------------
            //accountId
            //--------------------------------
            {


                "fieldtype"               	:"text",
                "required"					:true,

                "class"                   	:'',
                "id"                      	:'accountId',
                "hint"                    	:"Account ID",

                "text"                     	:def_accountId,

                "tabIndex"                  :"",
                "keyboardType"            	:"",
                "autocorrect"             	:"false",
                "autocapitalizationType"  	:"none",


                //"secure"                  :true,
                //"returnKeyType"           :"done"


            },
            



            




            // //--------------------------------
            // //
            // //--------------------------------

            // {


            //     "fieldtype"               	:"select",
            //     "required"					:true,

            //     "class"                   	:'',
            //     "id"                      	:'band',
            //     "hint"                    	:"Choose Band",

            //     "text"                     	:[],
            //     "array"                     :[

            //         "Van Halen",
            //         "AC/DC",
            //         "Led Zeppelin",
            //         "Aerosmith",
            //         "Black Sabbath",
            //         "Judas Priest"
                
            //     ],

            //     "tabIndex"                   :"",
            //     "keyboardType"            	:"",
            //     "autocorrect"             	:"false",
            //     "autocapitalizationType"  	:"none",


            //     //"secure"                  :true,
            //     //"returnKeyType"           :"done"


            // },
    





            


            // //--------------------------------
            // //
            // //--------------------------------
            // {


            //     "fieldtype"               	:"phone",
            //     "required"					:false,

            //     "class"                   	:'',
            //     "id"                      	:'phone',
            //     "hint"                    	:"Phone",

            //     "text"                     	:"",
            
            //     "tabIndex"                   :"",
            //     "keyboardType"            	:"",
            //     "autocorrect"             	:"false",
            //     "autocapitalizationType"  	:"none",


            //     //"secure"                  :true,
            //     //"returnKeyType"           :"done"


            // }


        ]

        

    },



    // {
    //     "section"  :"spacer",
    //     "data"      :{

    //         "name"		:'info-end',
    //         "color"   	:'transparent',
    //         "height"  	:50

    //     }

    // },












    



    // //##########################################################################
    // //##########################################################################
    // //##########################################################################
    // //questionnaire



    // //==========================================
    // //bar
    // //==========================================
    // {

    //     "section"  :"bar",
    //     "data"      :{

    //         "text"  :"Info."

    //     }


    // },
    






    // //==========================================
    // //headline
    // //==========================================
    // {

    //     "section"  :"headline",
    //     "data"      :{

    //         "text"  :'How did you hear about Power Digital Media'

    //     }


    // },

    // //==========================================
    // //radiobuttons
    // //==========================================
    // {

    //     "section"  :"radiobuttons",
    //     "data"      :{
            
    //         "section"     :'rad',
    //         "required"	:true,
    //         "name"      :'hearabout',
    //         "array"     :[
    
    //             "Job Post",
    //             "Internet Search",
    //             "Linked In",
    //             "Other"
    //         ],
    //         "choice"    :["Internet Search"]

    //     }


    // },




    // //==========================================
    // //headline
    // //==========================================
    // {

    //     "section"  :"headline",
    //     "data"      :{

    //         "text"  :'What type of work do you need completed?'

    //     }


    // },

    // //==========================================
    // //checkboxes
    // //==========================================
    // {

    //     "section"  :"radiobuttons",
    //     "data"      :{
            
    //         "section"     :'rad',
    //         "required"	:true,
    //         "name"      :'typeofwork',
    //         "array"     :[

    //             "Consulting",
            
    //             "Full Stack",
    //             "Frontend ",
    //             "Backend",
            
    //             "Not Sure"
            
    //         ],
    //         "choice"    :['Full Stack']

    //     }


    // },



    

    // //==========================================
    // //headline
    // //==========================================
    // {

    //     "section"  :"headline",
    //     "data"      :{

    //         "text"  :'What skills or technologies you are looking for? Check all that apply.'

    //     }


    // },


    // //==========================================
    // //checkboxes
    // //==========================================
    // {

    //     "section"  :"checkboxes",
    //     "data"      :{
            
    //         "section"     :'chk',
    //         "required"	:false,
    //         "name"      :'skills',
    //         "array"     :[

    //             "Javascript",
    //             "Node.js",
    //             "Php",
    //             "Python",
    //             "AWS"
            
    //         ],
    //         "choice"    :["Node.js","Aws"]

    //     }


    // },


    







    // //##########################################################################
    // //##########################################################################
    // //##########################################################################
    // //message


    // //==========================================
    // //headline
    // //==========================================
    // {

    //     "section"  :"headline",
    //     "data"      :{

    //         "text"  :'Message'

    //     }


    // },

    // //==========================================
    // //fields
    // //==========================================
    // {

    //     "section"  :"fields",
    //     "data"      :[



    //         //--------------------------------
    //         //
    //         //--------------------------------
    //         {


    //             "fieldtype"               	:"textarea",
    //             "required"					:true,

    //             "class"                   	:'',
    //             "id"                      	:'message',
    //             "hint"                    	:"Message",

    //             "text"                     	:def_message,



    //             "keyboardType"            	:"",
    //             "autocorrect"             	:"false",
    //             "autocapitalizationType"  	:"none",


    //             //"secure"                  :true,
    //             //"returnKeyType"           :"done"


    //         }



    //     ]

        

    // },


    // {
    //     "section"  :"spacer",
    //     "data"      :{

    //         "name"		:"after-message",
    //         "color"   	:'transparent',
    //         "height"  	:25

    //     }

    // },













    //##########################################################################
    //##########################################################################
    //##########################################################################
    //captcha


    // //==========================================
    // //headline
    // //==========================================
    // {

    //     "section"  :"headline",
    //     "data"      :{

    //         "text"  :'Enter Confirmation Code'

    //     }


    // },


    // //==========================================
    // //captcha
    // //==========================================

    // {
    //     "section"  :"captcha",
    //     "data"      :{

    //         "required"	:required_captcha,
    //         "capnum"	:def_captcha

    //     }


    // },






    // {
    //     "section"  :"spacer",
    //     "data"      :{

    //         "name"		:"before-button",
    //         "color"   	:'transparent',
    //         "height"  	:50

    //     }

    // },


    




    //##########################################################################
    //##########################################################################
    //##########################################################################
    //button

    // {
    //     "section"  :"button",
    //     "data"      :{

    //         "text"  :'Submit',
    //         "class" :'btn-submit',
    //         "funct"	:{

    //             "name":"FormSubmit",
    //             "object":false

    //         },
    //         "action":'submit'
            
    //     }

    // },




    /*

    //##########################################################################
    //##########################################################################
    //##########################################################################
    //link

    {
        "section"  :"link",
        "data"      :{

            "text"  :'Forgot Password',
            "class" :'btn-link',
            "funct"	:{

                "name":"Run_Query",
                "object":{

                    "case":"changezone",
                    "replace":false,
                    "pathto":"/forgot",
                    "qvars":false

                }


            }
            
        }

    },


    */

    
    // {
    //     "section"  :"spacer",
    //     "data"      :{

    //         "name"		:"bottom",
    //         "color"   	:'transparent',
    //         "height"  	:10

    //     }

    // }




];



export default Composed;



