
import React, { 

    useState, 
    useEffect,
    useRef

} from 'react';


import { 
    useSelector, 
    //useDispatch 
} 
from 'react-redux';
//import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';


import {
    //BrowserRouter as Router,
    //Switch,
    //Route,
    //Link,

    //useRouteMatch,
    useLocation,
    //useHistory,   
    //useParams,



} from "react-router-dom";




// -- form component -- //
import FormComponent from 'utils/components/Form';
//import Handler from 'utils/myComponents/Form/Handler';



// --  -- //

import CreateMarkup from '../../CreateMarkup';

import { Print } from './Styled';
//import FormBuild from './FormBuild';
import FormComposer from './FormComposer';





const Index = (props) => {


    // -- router -- //
    //let params = useParams();
    //let match = useRouteMatch();
    const location = useLocation();
    //const history = useHistory();

    
    var pathname = location.pathname;

    if(pathname.charAt(0) === '/') 
        pathname = pathname.substring(1,pathname.length);


    if(pathname.charAt(pathname.length-1) === '/') 
        pathname = pathname.substring(0,pathname.length-1);





    // -- redux -- //
    //const dispatch = useDispatch();
    const zoneObj = useSelector(state => state.zone);
    //console.log("zoneObj: "+JSON.stringify(zoneObj, null, 2));


    const currentFlyr = zoneObj.currentFlyr;
    var formArr = [];
    var problemArr = [];
    //var processSubmit;
    //var processComplete;
    //var processError;
    //var errorType;


    formArr         = zoneObj[currentFlyr + "_formArr"];
    problemArr      = zoneObj[currentFlyr + "_problemArr"];  
    //processSubmit   = zoneObj[currentFlyr + "_processSubmit"];
    //processComplete = zoneObj[currentFlyr + "_processComplete"];
    //processError    = zoneObj[currentFlyr + "_processError"];
    //errorType       = zoneObj[currentFlyr + "_errorType"];


    if(Object.prototype.toString.call(formArr).match(/undefined/gi))
    {
        formArr = [];
    }
    //console.log("FORM ARR: "+JSON.stringify(formArr,null,2));


    if(Object.prototype.toString.call(problemArr).match(/undefined/gi))
    {
        problemArr = [];
    }
    //console.log("PROBLEM ARR: "+JSON.stringify(problemArr,null,2));









    // -- ref -- //
    const refA = useRef(null);
    const formRef = useRef(null);




    //=============================================

    //=============================================

    // -- state -- //
    const [printOut, setPrintOut] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);


    useEffect(() => {


        if(!isLoaded)
        {

            //console.log("DATA: "+JSON.stringify(props.data,null,2));
            //console.log("STYL: "+JSON.stringify(props.styl,null,2));

            //alert('checking data and styl')

            var composed = FormComposer({

                data:props.data,
                styl:props.styl

            });

            //console.log("COMPOSED: "+JSON.stringify(composed,null,2));
            //alert('isLoaded');

            setIsLoaded(true);

            //let printOut;
            //printOut = JSON.stringify(props.data,null,2);
            //printOut = printOut.replace(/\n/gi,"<br/>");
            setPrintOut(false);


            if(zoneObj[currentFlyr + "_formArr"].length === 0)
            {
                var printArr = [];
                var newArr = [];
                var frm;
        
                for(var i=0; i < composed.length; i++)
                {
                    //console.log(object['section'] + "\n" + JSON.stringify(object['data'],null,2));
                    frm = composed[i];
                    newArr.push(frm);

                    frm = JSON.stringify(frm,null,2);
                    frm = frm.replace(/\n/gi,"<br/>");
                    printArr.push(frm);
        
                }//== i

                //console.log("New Arr: "+newArr);
                //setFormArr(newArr);

                //console.log("PrintArr: "+printArr);  
                //setPrintOut(printArr);


                props.handle({

                    call:"setFormArr",
                    data:newArr

                });


                //console.log("COMPOSED: "+JSON.stringify(newArr,null,2));
                //alert('no formArr');
        
            }

            //console.log("PRINT H: "+refA.current.offsetHeight);
            props.handle({

                call:"adjustContentsHeight",
                data:{

                    ref:refA,
                    h:refA.current.offsetHeight

                }

            })


            /*
            if(zoneObj[currentFlyr + "_reload"])
            {
                props.handle({

                    call:"handleDispatch",
                    data:{

                        action  :"DELETE_ZONE",
                        key     :currentFlyr + "_reload",
                        value   :false
                    }

                })

            }
            */

        }


    },[

        props,

        //dispatch,
        isLoaded,     
        refA,

        location,
        //hashData,
        formRef,
        //formArr,
        //problemArr,
        zoneObj,
        currentFlyr

    ])








    // //=============================================
    // // tabdFormArr
    // //=============================================

    // // -- state -- //
    // const [tabdFormArr, setTabdFormArr] = useState([]);
    // useEffect(() => {



    //     var initArr = zoneObj[currentFlyr + "_formArr"];
    //     if(Object.prototype.toString.call(zoneObj[currentFlyr + "_formArr"]).match(/undefined/gi))
    //     {
    //         initArr = [];
    //     }
    //     console.log("INIT ARR: "+JSON.stringify(formArr,null,2));
    

    //     if(initArr.length > 0)
    //     {
    //         var array = [];
    //         var formObj;
    //         var dataArr, dataObj;
    //         var tabIndex = 100;
    //         var count = 0;
    //         var subCount;
    //         for(formObj of initArr)
    //         {

    //             switch(formObj.section)
    //             {
    //             case'labelfields':

    //                 console.log(":::Label Fields:::")
    //                 console.log(JSON.stringify(formObj.data,null,2));

    //                 // :::Label Fields:::
    //                 // [
    //                 //   {
    //                 //     "label": {
    //                 //       "text": "Weeks Per Year"
    //                 //     },
    //                 //     "field": {
    //                 //       "fieldtype": "standard",
    //                 //       "required": true,
    //                 //       "class": "",
    //                 //       "id": "work_weeks",
    //                 //       "hint": "",
    //                 //       "text": "48",
    //                 //       "keyboardType": "",
    //                 //       "autocorrect": "false",
    //                 //       "autocapitalizationType": "none"
    //                 //     }
    //                 //   },
    //                 //   {
    //                 //     "label": {
    //                 //       "text": "Days Per Week"
    //                 //     },
    //                 //     "field": {
    //                 //       "fieldtype": "standard",
    //                 //       "required": true,
    //                 //       "class": "",
    //                 //       "id": "work_days",
    //                 //       "hint": "",
    //                 //       "text": "4",
    //                 //       "keyboardType": "",
    //                 //       "autocorrect": "false",
    //                 //       "autocapitalizationType": "none"
    //                 //     }
    //                 //   },
    //                 //   {
    //                 //     "label": {
    //                 //       "text": "Hours Per Day"
    //                 //     },
    //                 //     "field": {
    //                 //       "fieldtype": "standard",
    //                 //       "required": true,
    //                 //       "class": "",
    //                 //       "id": "work_hours",
    //                 //       "hint": "",
    //                 //       "text": "7",
    //                 //       "keyboardType": "",
    //                 //       "autocorrect": "false",
    //                 //       "autocapitalizationType": "none"
    //                 //     }
    //                 //   }
    //                 // ]


    //                 dataArr = [];
    //                 subCount = 0;
    //                 for(dataObj of formObj.data)
    //                 {

    //                     console.log(JSON.stringify(dataObj,null,2));
    //                     dataObj.field.tabIndex = tabIndex + subCount;
    //                     subCount++;
    //                 }
    //                 formObj.data[count] = dataArr;


    //             break;
    //             default:
    //             }
    //             array.push(formObj);
    //             count++;
                
    //         }

    //         console.log(":::::"+JSON.stringify(array,null,2));
    //         setTabdFormArr(array)

    //     }

    // },[

    //     zoneObj,
    //     currentFlyr

    // ])










    if(printOut)
    {

        return (

            <Print
            ref={refA}
            backgrounColor={"lightyellow"}
            >
    
                <div dangerouslySetInnerHTML={CreateMarkup(printOut)} />
    
            </Print>
    
        );


    }
    else
    {

        return (

            <div>

                <Print
                ref={refA}
                >
                    
                    <FormComponent
                    
                        refid           = {"flyr"}    
                        info            = {{}}  
                        items           = {formArr} 
                        //items         = {tabdFormArr}
                        handle          = {(v) => props.handle(v)} 
                        problemArr      = {problemArr}
                        border          = {"0"}
                        viewPasswords   = {props.viewPasswords}

                        handleMovement  = {(v) => props.handleMovement(v)} 
                   
          
                    />

                </Print>
                

            </div>
                    
        );
    

    }




}


export default Index;





/*

  inputRefs = [];
  
    setRef = (ref) => {
        this.inputRefs.push(ref);
    };
    
    focusInput = (id) => this.inputRefs[id].focus();
    
    render() {
        return (
        <div>
            {this.state.data.map(({ name }) => (
            <Hello 
                placeholder={name} 
                ref={this.setRef} 
                key={name} />
            ))}
            <Button onClick={this.focusInput} id={0}>focus input 1</Button>
            <Button onClick={this.focusInput} id={1}>focus input 2</Button>
        </div>
        );
    }
    }

*/