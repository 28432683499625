/*
----------------------
Flex or Fixed
----------------------

MAX - 4 across

 "across1"     :501,        
 "across2"     :801,          
 "across3"     :1050


MAX - 3 across

 "across1"     :501,        
 "across2"     :801,          
 "across3"     :10000


MAX - 2 across

 "across1"     :501,        
 "across2"     :10000,          
 "across3"     :10000




{

    styl:{

        call:"indent",
        class:"IndentRow",

        marginTop:"12px",
        marginBottom:"12px"
    
    },
    call:'FGrid',
    info:{

        call:'filmstrip', //fixed, flex, filmstrip
        gridType: "ImageOnly", //ImageOnly, ImageText
        gridBgColor:'transparent',
        gridCall: "filmstrip",  //grid_column_cell, grid_cell, flyr, filmstrip
        modCall: 'fitTo',
        thumb: {

            call    : "flex_height", //flex_height or default
            diff    : 0

        },    
        cellBorder: {
            "boolean"       :true,
            "thickness"     :1,//px
            "color"         :"gray"  
        },
        gutter: {
            "boolean"   :true,
            "size"      :0
        },
        thresh: {
            "refWidth"    :false,   //number or false = default screen width
            "across1"     :10000,
            "across2"     :10000,
            "across3"     :10000
        
        },
        mask:{

            boolean:false,
            message:{

                boolean:true,
                backgroundColor:"#4CAF50",
                textColor:"white",
                text:"My Message"

            }

        }
    


    },

    items:[

        TestImages[0]
        
    ]

}




Common Edits 

    styl.call           = indent or full
    styl.class          = FullRow or IndentRow or IndentRowB
    styl.marginTop      = "px";
    styl.marginBottom   = "px";

    info.thumb.diff     = integer

    info.gutter.boolean = true or false
    info.gutter.size    = integer

    info.thresh.across1 = "501 or 10000"; 2 images or 1
    info.thresh.across2 = "801 or 10000"; 3 images or 2
    info.thresh.across3 = "1050 or 10000"; 4 images or 3

    info.mask.boolean                   = true or false;
    info.mask.message.boolean           = true or false;
    info.mask.message.backgroundColor   = color
    info.mask.message.textColor         = color
    info.mask.message.text              = 'Your Message';


    items = array of Txt or Img or Vid




 */

import {

    Card,
    InfoCard,
    GraphicCard,
    NameValueCard,
    SpecInputCard,
    DecorCard

} from './Builds/Card';



import {

    Newspaper,
    SingleTextBox,
    ImageTextBox,
    TextBox

} from './Builds/Txt';


import {

    FixedImageOnly,
    FixedImageText,
    FlexImageOnly,
    FlexImageText,
    FilmStrip,
    //Band

} from './Builds/Img';



import {

    VidImageOnly,
    VidImageText


} from './Builds/Vid';




const SceneBuild = (props) => {



    var propsIsObject = false;
    var array;
    if(Object.prototype.toString.call(props) === '[object Object]')
    {
        propsIsObject = true;
        array = props.array;
    }else{
        array = props;
    }

    const R = [];

    var row;
    for(var object of array)
    {


        switch(object['component'])
        {


        //Card

        case'Card':

            row = Card(object);
        break;
        case'InfoCard':

            row = InfoCard(object);
        break;
        case'GraphicCard':

            row = GraphicCard(object);
        break;    
        case'NameValueCard':

            row = NameValueCard(object);
        break;
        case'SpecInputCard':

            row = SpecInputCard(object);
        break;
        case'DecorCard':

            row = DecorCard(object);
        break;




        //Txt

        case'Newspaper':


            row = Newspaper(object);
        break;
        case'SingleTextBox':

            row = SingleTextBox(object);
        break;
        case'TextBox':

            row = TextBox(object);
        break;
        case'ImageTextBox':
     
            row = ImageTextBox(object);
        break;


        //Img


        case'FixedImageOnly':

            row = FixedImageOnly(object);
        break;
        case'FixedImageText':

            row = FixedImageText(object);
        break;
        case'FlexImageOnly':

            row = FlexImageOnly(object);
        break;
        case'FlexImageText':


            row = FlexImageText(object);
        break;
        case'FilmStrip':

            row = FilmStrip(object);
        break;



        //Vid

        case'VidImageOnly':

            row = VidImageOnly(object);
        break;
        case'VidImageText':

            row = VidImageText(object);
        break;


        //report
        case'Report':

            object.call = 'Report';
            row = object;
        break;
        default:
        }//==


        R.push(row);

    }




    if(propsIsObject)
    {
        return {
            boolean:props.boolean,
            array:R
        }
    }else{
        return R;
    }




}


export default SceneBuild;




/*


row.styl.call = object.styl.call;
row.styl.class = object.styl.class;
row.styl.marginTop = object.styl.marginTop;


if(object.info.thumb.diff) row.info.thumb.diff = object.info.thumb.diff;

if(object.info.gutter.boolean)
{ 
    row.info.gutter.boolean = object.info.gutter.boolean;
    row.info.gutter.size = object.info.gutter.size;
}

if(object.info.thresh) row.info.thresh.across1 = object.info.thresh.across1;
if(object.info.thresh) row.info.thresh.across2 = object.info.thresh.across2;
if(object.info.thresh) row.info.thresh.across3 = object.info.thresh.across3;

row.items = object.items;


*/
