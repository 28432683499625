import styled from "styled-components";



export const Alfa = styled.div.attrs(props => ({


    backgroundColor:    props.backgroundColor || "#1a1926",//#fcfcfc
    border:             props.border || "0",
    borderRadius:       props.borderRadius || "0",

    percent:           props.percent || "60", 
    decimal:           props.decimal || "0.6",


    width:            props.width || "100%", 
    height:           props.height || "100%",

    zIndex:           props.zIndex || "1",
    top:              props.top || "0px", 
    left:             props.left || "0px"


}))`
  

    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    border-radius:${props => props.borderRadius};

    width:${props => props.width};
    height:${props => props.height};

    padding :0 0 0 0; 
    margin  :0 0 0 0;

    position:absolute;   
    z-index:${props => props.zIndex};
    top:${props => props.top};
    left:${props => props.left};

    -ms-filter: "progid:pxImageTransform.Microsoft.Alpha(Opacity=${props => props.percent})";
    filter : alpha(opacity=${props => props.percent});
    -moz-opacity : ${props => props.decimal};
    -khtml-opacity : ${props => props.decimal};
    opacity : ${props => props.decimal};


`;


export const Base = styled.div.attrs(props => ({


    backgroundColor:    props.backgroundColor || "transparent",//#fcfcfc
    border:             props.border || "0",
    borderRadius:       props.borderRadius || "0",

    width:            props.width || "100%", 
    height:           props.height || "100%",

    zIndex:           props.zIndex || "2", 
    top:              props.top || "0", 
    left:             props.left || "0"


}))`
  

    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    border-radius:${props => props.borderRadius};


    width:${props => props.width};
    height:${props => props.height};

    padding :0 0 0 0; 
    margin  :0 0 0 0;


    position:absolute;   
    z-index:${props => props.zIndex};
    top:${props => props.top};
    left:${props => props.left};


    overflow-x:hidden;
    overflow-y:auto;


`;






export const Menu = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "white", //"#191e28", //"#EDEEF1",

    width:              props.width || "calc(100% - 25px)",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 0 0",

    // top:                props.top || "103px",
    // left:               props.left || "92px",

    top:                props.top || "48px",
    right:              props.right || "6px",



    buttonFontFamily:         props.buttonFontFamily || 'Arial, Helvetica, sans-serif',
    buttonFontSize:           props.buttonFontSize || "16px",
    buttonFontWeight:         props.buttonFontWeight  || "500",
    buttonTextAlign:          props.buttonTextAlign || "left",
    buttonTextDecoration:     props.buttonTextDecoration  || "none",
    buttonTextColor:          props.buttonTextColor || "black"



    // border-top:1px solid lightgray;
    // border-bottom:1px solid lightgray; 



}))`

    background-color:${props => props.backgroundColor};
    border:1px solid lightgray;


    max-width:208px;
    min-height:150px;
    width:${props => props.width};
    height:${props => props.height};

    padding:0 0 0 0;
    margin:${props => props.margin};

    position:absolute;
    top:${props => props.top};
    right:${props => props.right};
    z-index:7;

    @media (max-width: 1024px) {

        background-color:white;
        border:1px solid #454d5f;
    }

    @media (max-width: 400px) {

        width:195px;

    }





    .Button {

        background-color:transparent;

        border-top:1px solid lightgray;
        border-bottom:1px solid lightgray;

        width:calc(100% - 10px);
        height:auto;
        padding:10px 0 10px 10px;
        margin:0 0 0 0;
        position:relative;
        float:left;

        cursor:pointer;

        font-family:${props => props.buttonFontFamily};
        font-size:${props => props.buttonFontSize};
        font-weight:${props => props.buttonFontWeight};
        text-align:${props => props.buttonTextAlign};
        text-decoration:${props => props.buttonTextDecoration};
        color:${props => props.buttonTextColor};

        &:hover{

            color:#07a2e3;

        }


    }





`;








export const LabelField = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "transparent",
    border:             props.border || "1px solid lightgray",

    width:              props.width || "calc(100% - 0px)",
    height:             props.height || "auto",
    margin:             props.margin || "0 5px 0 0",
    padding:            props.padding || "0 0 0 0",


    labelFontFamily:         props.labelFontFamily || 'Arial, Helvetica, sans-serif',
    labelFontSize:           props.labelFontSize || "14px",
    labelFontWeight:         props.labelFontWeight  || "550",
    labelTextAlign:          props.labelTextAlign || "left",
    labelTextDecoration:     props.labelTextDecoration  || "none",
    labelColor:              props.labelColor || "black",

    inputFontFamily:         props.inputFontFamily || 'Arial, Helvetica, sans-serif',
    inputFontSize:           props.inputFontSize || "14px",
    inputFontWeight:         props.inputFontWeight  || "500",
    inputFontStyle:          props.inputFontStyle || "normal", //italic   
    inputTextAlign:          props.inputTextAlign || "left",
    inputTextDecoration:     props.inputTextDecoration  || "none",
    inputColor:              props.inputColor || "#000", // #777

    titleWidth:         props.titleWidth || "calc(30% - 10px)",
    fieldWidth:         props.fieldWidth || "calc(69% - 11px)",



    //border:${props => props.border};

}))`


    background-color:${props => props.backgroundColor};


    width:${props => props.width};
    height:${props => props.height};
 
    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;




    .Label {

        background-color:transparent;

        width:auto;
        height:auto;
        padding:12px 0 10px 0;
        margin:6px 0 0 0;

        position:relative;
        float:left;

        font-family:${props => props.labelFontFamily};
        font-size:${props => props.labelFontSize};
        font-weight:${props => props.labelFontWeight};
        text-align:${props => props.labelTextAlign};
        text-decoration:${props => props.labelTextDecoration};
        color:${props => props.labelColor};


    }


    .Field {

        background-color:transparent;
    
        width:215px;
        height:auto;
        padding:0 0 0 0;
        margin:0 0 0 0;

        position:relative;
        float:left;

        cursor:pointer;


    }

    @media (max-width: 400px) {

        .Field {

            width:200px;
        }
    }




        .Field .dropdown {

            background-color:white;
            border:2px solid #edeef1;
            border-radius:7px;           

            width:100%;
            height:auto;
            margin:5px 4px 5px 0;
            padding:0 0 0 0;
            position:relative;
            float:left;
            overflow:visible;

        }



            .Field .dropdown .mask {

                background-color:transparent;
                border-radius:7px; 
         
                width:100%;
                height:100%;
                margin:0 0 0 0;
                padding:0 0 0 0;
                position:absolute;
                top:0px;
                left:0px;
                z-index:10;

            }





            .Field .dropdown .text {

                background-color:white;
                border-radius:7px; 

                width:calc(100% - 50px);
                height:auto;
                margin:0 0 0 0;
                padding:10px 0 8px 10px;
                position:relative;
                float:left;

                font-family:${props => props.inputFontFamily};
                font-size:${props => props.inputFontSize};
                font-weight:${props => props.inputFontWeight};
                font-style:${props => props.inputFontStyle};
                text-align:${props => props.inputTextAlign};
                text-decoration:${props => props.inputTextDecoration};
                color:${props => props.inputColor};

            }

            .Field .dropdown .arrow {

                background-color:white;
                border-top-right-radius:5px; 
                border-bottom-right-radius:5px; 

                width:23px;
                height:25px;
                margin:0 0 0 0;
                padding:12px 3px 0 10px;
                position:relative;
                float:right;
    

            }




        .Field .sel-wrap{

            background-color:white;
            border:2px solid black;
            border-radius:7px;           

            width:100%;
            height:auto;
            margin:5px 4px 5px 0;
            padding:0 0 0 0;
            position:relative;
            float:left;
            overflow:visible;

        }


            .Field .sel-wrap .sel-tag{
            
                background-color:transparent;
                border:0px solid white;
                border-radius:7px;

                width:100%;
                height:auto;
                margin:0 0 0 0;
                padding:13px 0 10px 5%;
                position:relative;
                float:left;

                font-family:Arial, Helvetica, sans-serif;
                font-size:16px;
                font-weight:normal;
                color:#777;
                text-align:left;
                font-style:italic;
            
            }

                .Field .sel-wrap .sel-tag .opt{
                
                    background-color:white;

                    width:100%;
                    height:auto;
                    float:left;
                    margin:0 0 0 0;
                    padding:5px 0 5px 5%;
            
                    font-family:Arial, Helvetica, sans-serif;
                    font-size:16px;
                    font-weight:normal;
                    font-style:normal;
                    color:#000;
                    text-align:left;
                
                }

            

    






`;






