import styled from "styled-components";


export const CatalogTileGlossary = styled.div.attrs(props => ({

    backgroundColor:    props.backgroundColor || "white",
    border:             props.border || "1px solid gray",

    width:              props.width || "calc(100% - 32px)",
    height:             props.height || "auto",
    margin:             props.margin || "0 0 15px 0",
    padding:            props.padding || "13px 15px 13px 15px",


    titleFontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    titleFontSize:           props.fontSize || "20px",
    titleFontWeight:         props.fontWeight  || "550",
    titleTextAlign:          props.textAlign || "left",
    titleTextDecoration:     props.textDecoration  || "none",
    titleColor:              props.color || "#0291cd", //#20b2aa #00cc66 #454d5f

    descrFontFamily:         props.fontFamily || 'Arial, Helvetica, sans-serif',
    descrFontSize:           props.fontSize || "16px",
    descrFontWeight:         props.fontWeight  || "500",
    descrTextAlign:          props.textAlign || "left",
    descrTextDecoration:     props.textDecoration  || "none",
    descrColor:              props.color || "#000",


    letterFontFamily:         props.letterFontFamily || 'Verdana, Arial, Helvetica, sans-serif',
    letterFontSize:           props.letterFontSize || "28px",
    letterFontWeight:         props.letterFontWeight  || "500",
    letterTextAlign:          props.letterTextAlign || "center",
    letterTextDecoration:     props.letterTextDecoration  || "none",
    letterColor:              props.letterColor || "#fff",



    usageTitleFontFamily:         props.usageTitleFontFamily || 'Arial, Helvetica, sans-serif',
    usageTitleFontSize:           props.usageTitleFontSize || "14px",
    usageTitleFontWeight:         props.usageTitleFontWeight  || "500",
    usageTitleTextAlign:          props.usageTitleTextAlign || "left",
    usageTitleTextDecoration:     props.usageTitleTextDecoration  || "none",
    usageTitleTextColor:          props.usageTitleTextColor || "#575e6f",


    buttonFontFamily:         props.buttonFontFamily || 'Arial, Helvetica, sans-serif',
    buttonFontSize:           props.buttonFontSize || "14px",
    buttonFontWeight:         props.buttonFontWeight  || "500",
    buttonTextAlign:          props.buttonTextAlign || "center",
    buttonTextDecoration:     props.buttonTextDecoration  || "none",
    buttonTextColor:          props.buttonTextColor || "#575e6f"


    // word-wrap: break-word;

    // #EDEEF1


    // &:hover {

    //     background-color:#edeef1;
    //     border:1px solid gray;

    // }


}))`


    background-color:${props => props.backgroundColor};
    border:${props => props.border};
    border-radius:4px;

    width:${props => props.width};
    height:${props => props.height};

    padding:${props => props.padding};
    margin:${props => props.margin};

    position:relative;
    float:left;

    overflow:hidden;
    cursor:default;

    @media (max-width: 600px) {

        height:auto;
        padding-bottom:25px;
    }





    .Title {

        background-color:transparent;

        width:100%;
        height:auto;
        padding:0 0 3px 0;
        margin:0 10px 0 0;
        position:relative;
        float:left;

        cursor:pointer;

        font-family:${props => props.titleFontFamily};
        font-size:${props => props.titleFontSize};
        font-weight:${props => props.titleFontWeight};
        text-align:${props => props.titleTextAlign};
        text-decoration:${props => props.titleTextDecoration};
        color:${props => props.titleColor};


        &:hover {

            text-decoration:underline;

        }


    }


    .Descr {

        background-color:transparent;

        width:100%;
        height:auto;
        padding:0 0 4px 0;
        margin:0 10px 10px 0;
        position:relative;
        float:left;

        cursor:default;

        font-family:${props => props.descrFontFamily};
        font-size:${props => props.descrFontSize};
        font-weight:${props => props.descrFontWeight};
        text-align:${props => props.descrTextAlign};
        text-decoration:${props => props.descrTextDecoration};
        color:${props => props.descrColor};

        line-height:1.4;
        word-wrap: break-word;


    }

    @media (max-width: 600px) {

        .Title {

            margin-top:7px;
            font-size:20px;
            
        }

        .Descr {

            font-size:16px;
            
        }

    }





    .Usage {

        background-color:transparent;

        width:100%;
        height:auto;
        padding:0 0 0 0;
        margin:5px 0 0 0;
        position:relative;
        float:left;

        cursor:default;
    }



        .Usage .Title {

            background-color:transparent;

            width:auto;
            height:auto;
            padding:0 0 0 .;
            margin:0 5px 0 0;
            position:relative;
            float:left;

            cursor:default;

            font-family:${props => props.usageTitleFontFamily};
            font-size:${props => props.usageTitleFontSize};
            font-weight:${props => props.usageTitleFontWeight};
            text-align:${props => props.usageTitleTextAlign};
            text-decoration:${props => props.usageTitleTextDecoration};
            color:${props => props.usageTitleTextColor};
  
        }



        .Usage .Button {

            background-color:transparent;
            border:1px solid transparent;
            border-radius:7px;
    
            width:auto;
            height:auto;
            padding:0 0 0 .;
            margin:0 7px 0 0;
            position:relative;
            float:left;
    
            cursor:pointer;
    
            font-family:${props => props.buttonFontFamily};
            font-size:${props => props.buttonFontSize};
            font-weight:${props => props.buttonFontWeight};
            text-align:${props => props.buttonTextAlign};
            text-decoration:${props => props.buttonTextDecoration};
            color:${props => props.buttonTextColor};


            &:hover {

                text-decoration:underline;    
                color:#0291cd;

            }

            
        }






`;







