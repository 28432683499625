// import { 
//     useSelector, 
//     //useDispatch 
// } 
// from 'react-redux';
// //import { ModifyZoneStore } from 'app-store/actions/ModifyZoneStore';



// import { Settings } from 'app-components/Prime/Data.js';
// import ApiResources from 'utils/components/Pages/ApiResources';


const SceneInfo = (props) => {



    var R = [];
    //var index;

    var top;
    var bar;   
    var catalogItems;

    // var accountName;
    // var accountValue;



    //#############################################################
    //#############################################################

    top = {

        call:'top',
        name:"The Profit Calc.",
        controls:[

            // {

            //     name:"Go Back",
            //     // form:{

            //     //     submitCall:'account',
            //     //     postTo:apiDomain+apiCall,
            //     //     data:VPaccount.company,
            //     //     flyr:{
            //     //         type:"form",
            //     //         styl:{
            //     //             maxWidth:475,
            //     //             fieldType:'field'
            //     //         }
            //     //     }
             

            //     // }

            //     hndl:{

            //         call:'readout',
            //         data:false
            //     }

            // }

        ]

    }


    bar = {

        call:'bar',
        items: [

            {

                name:"-"

            },

            {

                name:"-"

            }

        ]
        
    }



    // console.log("Props: "+JSON.stringify(props.goods,null,2));




    var tileColor;
    tileColor = "transparent"; //"#00cc66";//purple

    var iconColor;
    iconColor = "#7e77ad";//purple
    iconColor = "#4cc1bb";//teal
    iconColor = "#7fe5b2";
    iconColor = "#edeef1"; // "#c7c9cf"; //"#a2a6af";
    //iconColor = "#191e28"; // "#c7c9cf"; //"#a2a6af";

    var darklite = "dark";


    catalogItems = [

        // {
                
        //     heading:"Account",
        //     bullet:false,
        //     stripe:true,
        //     pgraph:"View, Edit or Create Custom Calculators",
        //     image:false,
        //     button:false,
        //     data:{

        //         id:"Settings/Account",
        //         name:"Account"

        //     }
        
        // },

        {
                
            heading:"Calculator",
            bullet:false,
            stripe:true,
            pgraph:'View "Calculator" and/or Save as "Pricing Calculator"',
            image:false,
            button:false,
            data:{

                id:"Calculator",
                name:"Calculator",
                graphic:{
                    tileColor:tileColor,
                    iconColor:iconColor,
                    darklite:darklite
                }
            }
        
        },


        {
                
            heading:"Pricing",
            bullet:false,
            stripe:true,
            pgraph:'View/Edit/Create "Pricing Calculators" and/or Preview "Pricing List"',
            image:false,
            button:false,
            data:{

                id:"Pricing",
                name:"Pricing",
                graphic:{
                    tileColor:tileColor,
                    iconColor:iconColor,
                    darklite:darklite
                }
            }
        
        },


        {
                
            heading:"Settings",
            bullet:false,
            stripe:true,
            pgraph:'View/Edit "Account", "Overhead", "Expenses"  etc.',
            image:false,
            button:false,
            data:{

                id:"Settings",
                name:"Settings",
                graphic:{
                    tileColor:tileColor,
                    iconColor:iconColor,
                    darklite:darklite
                }

            }
        
        },



        {
                
            heading:"Reports",
            bullet:false,
            stripe:true,
            pgraph:'View Report(s)',
            image:false,
            button:false,
            data:{

                id:"Reports",
                name:"Reports",
                graphic:{
                    tileColor:tileColor,
                    iconColor:iconColor,
                    darklite:darklite
                }

            }
        
        },

        {
                
            heading:"Knowledge Base",
            bullet:false,
            stripe:true,
            pgraph:'View "Quick Start", "Guides", "Glossary" and more',
            image:false,
            button:false,
            data:{

                id:"Knowledge Base",
                name:"Knowledge Base",
                graphic:{
                    tileColor:tileColor,
                    iconColor:iconColor,
                    darklite:darklite
                }
            }
        
        }



    ];



    R.push([top,bar,{

        call:"items",
        items:catalogItems
    
    }]);




    return R;

}



export default SceneInfo;




/*

{
"name": "Account",
"value": "http://site.site/Account/",
"position": "left",
"mainWidth":"100%"
},
{
"name": "Schedule",
"value": "http://site.site/Schedule/",
"position": "left",
"mainWidth":"100%"
},
{
"name": "Overhead",
"value": "http://site.site/Overhead/",
"position": "left",
"mainWidth":"100%"
},
{
"name": "Expenses",
"value": "http://site.site/Expenses/",
"position": "left",
"mainWidth":"100%"
},
{
"name": "Owners",
"value": "http://site.site/Owners/",
"position": "left",
"mainWidth":"100%"
},
{
"name": "Employees",
"value": "http://site.site/Employees/",
"position": "left",
"mainWidth":"100%"
}


*/